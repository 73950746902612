import { Grid, createTheme, useMediaQuery, Modal } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import { sideMenuGradient } from "../common/materials/LinearGradient";
import Footer from "../components/footer/footer";
import LanguageChooser from "../components/shared/languageChooser";
import IRouterComponentProps from "../models/IRouterComponentProps";
import ConfirmNewPassword from "./login/ConfirmNewPassword";
import ForgotPassword from "./login/ForgotPassword";
import LoginForm from "./login/LoginForm";
import RegisterForm from "./login/RegisterForm";
import ViewRegisterModal from "./ViewVideoEnlargeModal";
import Alert from "../common/elements/Alert";
import SubdomainFooter from "../components/footer/SubdomainFooter";
import Promotional from "./login/Promotional";
import Flotingicon from "../components/products/Flotingicon";
import SubdomainPageFooter from "../components/footer/SubdomainPageFooter";
import PasswordResetViaOtp from "./login/passwordReset/PasswordResetViaOtp";
import LoginWithEmail from "./login/passwordReset/LoginWithEmail";
import LoginWithOtp from "./login/passwordReset/LoginWithOtp";

interface IProps extends IRouterComponentProps {
  t?: any;
}

interface ButtonProps {
  selected?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  border-radius: 3px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  margin-right: 5px;
  &:hover {
    background: ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${(props) => props.theme.colors.base.white};
      color: ${(props) => props.theme.colors.base.black};
    `}

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    align-items: center;
  }
`;

const StyledContainer = styled(Grid)`
  .logoText {
    font-size: 1.5em;
  }
  .returnToWebsite {
    font-size: 18px;
  }
`;

const StyledResetPaddingContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled(Grid)`
  display: inline-block;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 90%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 20%;
    }
  }
  display: flex;
  align-items: center;
`;

const StyledLogoWrapperContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  background: ${(props) => props.theme.colors.base.darkBlue};
  background: ${sideMenuGradient};
`;

const StyledFooterContainer = styled(Grid)`
  padding: 0 !important;
`;

const StyledLogoImage = styled.img`
  width: 10% !important;
`;

const StyledTextContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-family: SF-Display-Medium;
`;
const StyledBannerContianer = styled(Grid)`
  display: flex;
  position: relative;
`;

const StyledBanContent = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  position: absolute;
  font-weight: bold;
  top: 15%;
  left: 15%;

  .firstLine {
    font-size: 1.5em;
    padding-bottom: 0.1em;
  }
  .secondLine {
    font-size: 1em;
    margin-top: 1em;
  }
  @media (width: 768px) {
    top: 20% !important;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    left: 4%;
    top: 15%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    left: 2%;
    top: 20%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: 1em;
`;

const StyledBanBorder = styled(Grid)`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.white};
  opacity: 0.3;
`;
const StyledBidButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 8px;
  font-style: italic;
  background: ${(props) => props.theme.colors.base.textColor};

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    padding: 0.3em;
    border-radius: 4px;
  }

  // border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledTypeImgContainer = styled(Grid)`
  text-align: center;
  justify-content: center;
  margin: 2em 0em;

  .width-6em {
    width: 6em;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .width-6em {
      width: 2em;
    }
  }
`;

const StyledAuctionTypeImage = styled.img`
  height: 8em;
  width: 8em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 4em;
    width: 4em;
  }
`;

const StyledCompanyInfo = styled(Grid)`
  margin: 2em 0em;
  justify-content: center;
  .alignCenter {
    text-align: center;
  }
`;

const StyledComInfoImage = styled.img`
  height: 90%;
  width: 70%;
  text-align: center;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 100%;
    width: 100%;
  }
`;

const StyledCompContent = styled(Grid)`
  font-size: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
  }
`;

const StyledTitle = styled.div`
  font-weight: bold;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
`;
const StyledName = styled.div`
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.base.textColor};
  font-weight: bold;
  margin-top: 0.4em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
    margin-top: 0.2em;
  }

  @media (width: 320px) {
    font-size: 0.6em;
  }
`;
const StyledBorderBottom = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.textColor};
`;
const StyledDescription = styled.div`
  margin-top: 0.6em;
  text-align: justify;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 0.2em;
    font-size: 0.6em;
  }
  @media (width: 320px) {
    font-size: 0.5em;
  }
`;

const StyledAuctionCon = styled(Grid)`
  position: relative;
  justify-content: center;

  img {
    height: 350px;
    width: 100%;
  }

  .paddingLeftRight {
    padding: 0em 1em;
    width: 100%;
  }
  .content {
    position: absolute;
    top: 25%;
    left: 10%;
  }

  .content .border-bottom {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.textColor};
  }

  @media (width: 768px) {
    .content {
      top: 30% !important;
    }
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      height: 100%;
      width: 100%;
    }

    .paddingLeftRight {
      padding: 0em 0.5em;
      width: 100%;
    }
    .content {
      top: 10%;
      left: 5%;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    .content {
      left: 3%;
    }
  }
`;

const StyledRelevantPeople = styled(Grid)`
  justify-content: center;
  margin-top: 2em;

  .positionRelative {
    position: relative;
  }
  .content {
    position: absolute;
    top: 25%;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 0.5em;
    .content {
      position: relative;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
    .content {
      position: relative;
    }
  }
`;
const StyledContImage = styled.img`
  height: 100%;
  width: 100%;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 100px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 100%;
    width: 100%;
  }
  @media (width: 600px) {
    height: 140%;
    width: 100%;
  }
`;

const StyledRelevateImage = styled.img`
  height: 250px;
  width: 90%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  float: right;
  padding: 8px;
  display: flex;
`;

const StyledCompanyName = styled.div`
  color: ${(props) => props.theme.colors.base.white};
  margin-left: 0.5em;
  font-size: 1.5em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    argin-left: 0.4em;
    font-size: 1.2em;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

const StyledDaimondCon = styled(Grid)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledDaimondsText = styled.span`
  font-size: 1.5em;
  margin-right: 1em;
  color: ${(props) => props.theme.colors.base.textColor};
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;
const StyledDaimondsTextMobile = styled.div`
  font-size: 2em;
  color: ${(props) => props.theme.colors.base.textColor};
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const Takeyama = (props: IProps) => {
  const { t } = props;

  const theme = createTheme({});
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("sm", "xl"));
  const [onPressSignUp, setOnPressSignUp] = useState(false);
  const [onPressLogin, setOnPressLogin] = useState(false);
  const [onPressAboutUs, setOnPressAboutUs] = useState(false);
  const [onPressForgotPassword, setOnPressForgotPassword] = useState(false);
  const [onsuccessfulRegister, setOnsuccessfulRegister] = useState(false);
  const [onConfirmPassword, setOnConfirmPassword] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const isSmallDevices = useMediaQuery(theme.breakpoints.down("xs"));
  const [onPromotional, setOnPromotional] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
    const [showLoginWithOTPPopup, setShowLoginWithOTPPopup] = useState(false);

  const unSource: any = useRef<any>();

  let userEmailvalue = useRef<any>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("un");
    unSource.current = userName;

    if (userName !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
    }
  }, []);
  const returnToWebsite = () => {
    props.history.push("/");
  };

  const loginHandler = () => {
    setOnPressLogin(true);
    setOnPressSignUp(false);
    setOnPressAboutUs(false);
        setShowLoginWithOTPPopup(false);
  };

  const onClose = () => {
    setOnPressLogin(false);
    setOnPressSignUp(false);
    setOnPressForgotPassword(false);
    setOnsuccessfulRegister(false);
    setOnConfirmPassword(false);
    setOnPressAboutUs(false);
    // setOnPromotional(false);
        setShowLoginWithOTPPopup(false);
  };

  const onCloseForgotPassword = () => {
    setOnPressForgotPassword(false);
    setOnConfirmPassword(true);
  };

  const signUpHandler = () => {
    setOnPressLogin(false);
    setOnPressSignUp(true);
    setOnPressAboutUs(false);
        setShowLoginWithOTPPopup(false);
  };

  const aboutUsHelper = () => {
    setOnPressLogin(false);
    setOnPressSignUp(false);
    setOnPressAboutUs(true);
    props.history.push("/AboutUs");
  };

  const forgotPasswordModalHandler = () => {
    setOnPressLogin(false);
    setOnPressForgotPassword(true);
        setShowLoginWithOTPPopup(false);
  };

  const regiterModalHandler = () => {
    setOnPressSignUp(false);
    setOnsuccessfulRegister(true);
        setShowLoginWithOTPPopup(false);
  };

  const showSuccessfulToast = () => {
    //props.showSuccessfulToast();
    const { t } = props;
    setAlertType("success");
    setShowMessage(
      `${t("common:profile:forgotPassword:validation:invalidOTP")}`
    );
    setOpenToast(true);
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      // props.showTimeoutToast();
      // setOnPressSignUp(false);
      setOnPressSignUp(false);
      setAlertType("error");
      setShowMessage(
        `${t("common:timeOut:somethingWrong")} ${t("common:timeOut:tryAgain")}`
      );
      setOpenToast(true);
    }
  };
  const renderResetPasswordPopup = () => {
    return (
      <StyledModal
        open={showResetPasswordPopup}
        onClose={() => {
          setShowResetPasswordPopup(false);
        }}
      >
        <LoginWithEmail
          closeModal={() => {
            setShowResetPasswordPopup(false);
          }}
          isFromSubDomain={false}
          renderOTPPopup={() => {
            setShowOTPPopup(true);
            setShowResetPasswordPopup(false);
          }}
          subdomainfunc={rhandlechangePlanModal}
        />
      </StyledModal>
    );
  };

  const renderOTPPopup = () => {
    return (
      <StyledModal open={showOTPPopup}>
        <PasswordResetViaOtp
          closeOtpModal={() => {
            setShowOTPPopup(false);
          }}
          openPasswordPop={() => {
            setShowResetPasswordPopup(true);
          }}
        />
      </StyledModal>
    );
  };

  const loginButtonHandler = () => {
    if (unSource.current !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
      loginHandler();
    }
  };
    const loginWithOtpModalHandler = () => {
      setOnPressLogin(false);
      setShowLoginWithOTPPopup(true);
    };

    const updateValue = (value: any) => {
      userEmailvalue.current = value;
    };
  
  let isFaxContinerActive: any = false;
  let isEmailContinerActive: any = false;
  
  const rhandlechangePlanModal = (val: string) => {
    //console.log("hellow");
  };

  return (
    <StyledContainer container={true} spacing={0}>
      <StyledLogoWrapperContainer container={true} spacing={0}>
        <StyledResetPaddingContainer item xs={12}>
          <StyledLogoWrapper item xs={5} sm={6} lg={4}>
            {/* <StyledLogoImage src="/images/sub-domain/mjLogo.png"></StyledLogoImage> */}
            <StyledCompanyName>
              {t("common:takeyama:banner:companyName")}
            </StyledCompanyName>
          </StyledLogoWrapper>
          <Grid item xs={7} sm={6} lg={8} className="buttonsContainer">
            <StyledButtonWrapper>
              <StyledButton selected={true} onClick={loginButtonHandler}>
                {t("common:header:buttons:login")}
              </StyledButton>
              <LanguageChooser {...props} />
            </StyledButtonWrapper>
          </Grid>
        </StyledResetPaddingContainer>
      </StyledLogoWrapperContainer>
      <StyledBannerContianer item={true} xs={12}>
        <StyledContImage src="/images/sub-domain/bannernew.gif" />
        <StyledBanContent item xs={7} sm={7} md={7} lg={6} xl={6}>
          <div className="firstLine">
            {t("common:takeyama:banner:companyName")}
          </div>
          <StyledBanBorder />
          <div className="secondLine">
            {t("common:takeyama:banner:companyDescription")}
          </div>
          <StyledButtonContainer>
            <StyledBidButton onClick={loginButtonHandler} className="isLogin">
              {t("common:takeyama:banner:button")}
            </StyledBidButton>
          </StyledButtonContainer>
        </StyledBanContent>
      </StyledBannerContianer>
      <StyledTypeImgContainer item xs={12} container spacing={0}>
        <Grid container spacing={0} item xs={12}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledDaimondCon>
                <StyledDaimondsText>
                  {t("common:takeyama:daimodsInfo:daimond")}
                </StyledDaimondsText>
                <StyledAuctionTypeImage
                  className="width-6em"
                  src="/images/sub-domain/daimond.png"
                />
              </StyledDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledAuctionTypeImage
                  className="width-6em"
                  src="/images/sub-domain/daimond.png"
                />
                <StyledDaimondsTextMobile>
                  {t("common:takeyama:daimodsInfo:daimond")}
                </StyledDaimondsTextMobile>
              </span>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledDaimondCon>
                <StyledDaimondsText>
                  {t("common:takeyama:daimodsInfo:auction")}
                </StyledDaimondsText>
                <StyledAuctionTypeImage src="/images/sub-domain/auction.png" />
              </StyledDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledAuctionTypeImage src="/images/sub-domain/auction.png" />
                <StyledDaimondsTextMobile>
                  {t("common:takeyama:daimodsInfo:auction")}
                </StyledDaimondsTextMobile>
              </span>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledDaimondCon>
                <StyledDaimondsText>
                  {t("common:takeyama:daimodsInfo:jewellery")}
                </StyledDaimondsText>
                <StyledAuctionTypeImage src="/images/sub-domain/jewellery.png" />
              </StyledDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledAuctionTypeImage src="/images/sub-domain/jewellery.png" />
                <StyledDaimondsTextMobile>
                  {t("common:takeyama:daimodsInfo:jewellery")}
                </StyledDaimondsTextMobile>
              </span>
            )}
          </Grid>
        </Grid>
      </StyledTypeImgContainer>
      <StyledCompanyInfo
        item
        xs={12}
        container
        spacing={0}
        className="justifyContent"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={11}
          container
          spacing={0}
        >
          <Grid item xs={5} className="alignCenter">
            <StyledComInfoImage src="/images/sub-domain/company-info.gif" />
          </Grid>
          <StyledCompContent item xs={7}>
            <StyledTitle>{t("common:takeyama:companyInfo:about")}</StyledTitle>
            <StyledName>{t("common:takeyama:banner:companyName")}</StyledName>
            <StyledBorderBottom />
            <StyledDescription>
              {t("common:takeyama:companyInfo:companyDescription")}
            </StyledDescription>
          </StyledCompContent>
        </Grid>
      </StyledCompanyInfo>
      {/* <StyledAuctionCon item xs={12} container spacing={0}>
        <div className="paddingLeftRight">
          <img src="/images/sub-domain/auction-img.PNG" />
          <Grid item xs={5} className="content">
            <StyledTitle>
              {t("common:takeyama:auctionInfo:auction")}
            </StyledTitle>
            <StyledName>
              {t("common:takeyama:auctionInfo:importantDaimond")}
            </StyledName>
            <StyledBorderBottom />
            <StyledDescription>
              {t("common:takeyama:auctionInfo:auctionDescription")}
            </StyledDescription>
          </Grid>
        </div>
      </StyledAuctionCon> */}

      <StyledFooterContainer item={true} xs={12} className="footerContainer">
        <SubdomainPageFooter
          t={t}
          {...props}
          officeNumber={t("common:takeyama:footer:officeNumber")}
          faxNumber={t("common:takeyama:footer:faxNumber")}
          officeAddress={t("common:takeyama:footer:officeAddress")}
          emailId={t("common:takeyama:footer:emailId")}
          isFaxContinerActive={isFaxContinerActive}
          isEmailContinerActive={isEmailContinerActive}
        />
      </StyledFooterContainer>
      <Flotingicon />
      {onPressLogin === true && (
        <StyledModal
          open={onPressLogin}
          onClose={onClose}
          className="loginModal"
        >
          <LoginForm
            forgotPasswordModal={forgotPasswordModalHandler}
            {...props}
            registerModal={signUpHandler}
            isFromSubDomain={true}
            renderLoginWithOTPPopup={loginWithOtpModalHandler}
            userEmailvalue={updateValue}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}
      {showLoginWithOTPPopup === true && (
        <StyledModal open={showLoginWithOTPPopup}>
          <LoginWithOtp
            {...props}
            isFromSubDomain={true}
            forgotPasswordModal={forgotPasswordModalHandler}
            registerModal={signUpHandler}
            oncloseModal={() => {
              onClose();
            }}
            loginButtonHandler={loginButtonHandler}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}

      {onPressForgotPassword === true && (
        <StyledModal
          open={onPressForgotPassword}
          onClose={onClose}
          className="forgotPasswordModal"
        >
          <ForgotPassword t={t} closeModal={onCloseForgotPassword} />
        </StyledModal>
      )}
      {onConfirmPassword === true && (
        <StyledModal open={onConfirmPassword}>
          <ConfirmNewPassword
            t={t}
            closeModal={() => onClose()}
            showSuccessfulToast={showSuccessfulToast}
          />
        </StyledModal>
      )}
      {onPressSignUp === true && (
        <StyledModal open={onPressSignUp} className="registerModal">
          <RegisterForm
            t={t}
            closeModal={() => onClose()}
            onSuccessfulRegisterModal={regiterModalHandler}
            loginModal={loginHandler}
            apiTimeout={timeout}
          />
        </StyledModal>
      )}
      {onsuccessfulRegister === true && (
        <StyledModal
          open={onsuccessfulRegister}
          onClose={() => onClose()}
          className="registerModal"
        >
          <ViewRegisterModal openRegisterModal={() => onClose()} t={t} />
        </StyledModal>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {/* {onPromotional === true &&
                <StyledModal open={onPromotional} onClose={onClose}>
                    <Promotional {...props} closeModal={onClose}/>
                </StyledModal>
            } */}
      {showResetPasswordPopup && renderResetPasswordPopup()}
      {showOTPPopup && renderOTPPopup()}
    </StyledContainer>
  );
};

export default Takeyama;
