import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import i18n from '../common/localization/i18next';
import styled from 'styled-components';

const StyledNetworkDetector = styled.div`
  @media (min-width: 1024px) {
    // margin-top: 40px;
  }
`;
const StyledNetworkDetectorPra = styled.p`
  @media (min-width: 1024px) {
    margin-top: 40px;
  }
`;
export default function (ComposedComponent: any) {
  class NetworkDetector extends Component {
    state = {
      isDisconnected: false,
      reloadPage: false,
      width: 0,
      windowWidth: window.innerWidth,
      height: 0
    }

    componentDidMount() {

      this.updateWindowDimensions();
      window.addEventListener("resize", this.updateWindowDimensions);

      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);

      PubSub.subscribe("ProfileStatus", (msg: any, data: any) => {
        this.setState({
          reloadPage: !this.state.reloadPage
        })
      });

    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
      PubSub.unsubscribe('ProfileStatus');
      window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(
          () => {
            fetch('//google.com', {
              mode: 'no-cors',
              })
              .then(() => {
              PubSub.publish("InternetStatus", "Yes");
              this.setState({ isDisconnected: false }, () => {
                return clearInterval(webPing)
              });
              }).catch(() => {
                PubSub.publish("InternetStatus", "No");
                this.setState({ isDisconnected: true })
              })
          }, 2000);
        return;
      }
      PubSub.publish("InternetStatus", "No");
      return this.setState({ isDisconnected: true });
    }

    render() {
      
      const { isDisconnected } = this.state;
      const isProfileComplete = localStorage.getItem("profileComplete")
      const userType = localStorage.getItem('isNormalUser');
      
      var token = localStorage.getItem("id_token");
      
      return (
        <StyledNetworkDetector>
          {isDisconnected && (<div style={{ marginTop: this.state.width < 960 ? 55 : 0, fontSize: '15px', lineHeight: '35px', color: '#fff', width: '100%', textAlign: 'center', backgroundColor: 'red'}}>
              <StyledNetworkDetectorPra>{i18n.t('common:network:internetConnection')}</StyledNetworkDetectorPra>
            </div>)
          }
          {token === null ? <div /> : (isProfileComplete === 'true' ? <div></div> : (userType === "normal" ? <div /> : <div style={{ marginTop: this.state.width < 960 ? 55 : 0 , fontSize: '15px', lineHeight: '35px', color: '#fff', width: '100%', textAlign: 'center', backgroundColor: 'red' }}>
            <StyledNetworkDetectorPra>{i18n.t('common:network:compeleteProfile')}</StyledNetworkDetectorPra></div>))
          }
          
          <ComposedComponent {...this.props} />
        </StyledNetworkDetector>
      );
    }
  }

  return NetworkDetector;
}