import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import EllipsisText from '../../../common/elements/EllipsisText';

const StyledComponent = styled(Grid)`
    outline: none;
    .secondaryLabel{
        font-size: 2em;
        font-weight: bold;
        color: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    }
    .text{
        font-size: 1em;
        font-weight: bold;
        padding: 10px;
        color: ${(props) => props.theme.colors.base.grey}; 
    }
    .paddingBottom{
        padding-bottom: 20px;
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 2em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 500px;
    }
`;

const StyledTextWrapper = styled.div`
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 2em;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        font-size: 3em;
    }
`;

const StyledWinnerWrapper = styled(Grid)`
    text-align: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 1em 1em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 1em 2em;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 1em 2em;
    }
`;

export interface IProps {
  t: any;
  name: string;
  closeModal: () => void;
}

const ViewWinnerModal = React.forwardRef((props: IProps, ref) => {
    const { t, closeModal, name} = props;

    useEffect(() => {
        // const d : any= new Date();
        // let ms: any = d.getTime();
        // console.log("congratulations popup description", { ms, name });
        if(name === ''){
            closeModal();
        }
        setTimeout(() => {
            closeModal();
        }, 1800);
    });
    return (
        <StyledComponent tabIndex={-1}>
            <StyledWrapper container spacing={0}>
                <StyledWinnerWrapper item md={12} xs={12} lg={12}>
                    <StyledTextWrapper className="fontBold">{t('common:home:modal:congratulations')}</StyledTextWrapper>
                    <div className="secondaryLabel paddingBottom">{t('common:home:modal:mazal')}</div>
                    <div className="text">{t('common:home:modal:text')}</div>
                    <div className="text paddingBottom">
                        {/* {name} */}
                        <EllipsisText
                            text={name}
                            length={80}
                            tail={"..."}
                          />
                        </div>
                </StyledWinnerWrapper>
            </StyledWrapper>
        </StyledComponent>
    );
})

export default ViewWinnerModal;