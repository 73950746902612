import { InputLabel } from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../common/materials/colors";

export const StyledUploadInput = styled.div`
  height: auto;
  overflow: hidden;
  min-height: 1.1876em;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #c3c3c3;
  border-radius: 5px;
  padding: 16px 32px 14px 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    border-color: ${Colors.black};
  }
`;
export const StyledInputLabel = styled(InputLabel)`
  margin: 0;
`;

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  padding: 1em 0 1em 0.8em;
`;
