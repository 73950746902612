import React from 'react'

const Privacy = () => {
  return (
    <>
      <div style={{ width: "100%", height: "auto", justifyContent: 'center', padding: '20px', }}>
        <div style={{ color: "#00569B", textDecorationColor: "#00569B", textDecoration: "underline", textDecorationColor: "black", textAlign: "center", fontSize: "26px", fontWeight: "900", fontFamily: "sans-serif" }}>PRIVACY POLICY</div>
        <div style={{ color: "#00569B", textAlign: "center", fontSize: "22px", fontWeight: "bold", marginBottom: "30px", fontWeight: "bold" }}>Zipang Auctions</div>
        <p style={{ paddingBottom: "5px" }}>ZIPANG AUCTIONS is a platform established solely for the purpose of introducing the seller’s goods to the Bidder in the best possible way so that Bidders have full transparency and disclosure of the condition and specifications of goods offered.</p>
        <p style={{ paddingBottom: "30px" }}>Zipang Auctions values your privacy and is committed to ensuring the confidentiality of your information. This privacy policy outlines how we collect, use, and protect your data while using our platform.</p>
        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "20px" }}>Information Collection</div>
        <p>We collect information solely for the purpose of facilitating auctions and ensuring transparency between sellers and bidders. The information collected may include:</p>
        <ul>
          <li>Personal details required for registration, such as name, email address, and contact information.</li>
          <li>Goods-related information, including specifications and conditions of the products offered.</li>
          <li>In specific cases related to diamond/jewelry auctions, we may provide expert opinions from our in-house Graduate Gemologist upon bidder request.</li>
        </ul>

        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Information Collection</div>
        <p>The information collected is utilized for the following purposes:</p>
        <ul>
          <li>Facilitating auctions and enabling communication between sellers and bidders.</li>
          <li>Providing expert opinions on diamond/jewelry items upon bidder request.</li>
          <li>Maintaining the integrity of the auction process by adhering to industry standards and ensuring goods' authenticity.</li>
          <li>Incorporating the Mix panel to track the number of users logging into our system provides statistical insights into the activity on Zipang Auctions.</li>
        </ul>

        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Information Sharing</div>
        <p>We do not share personal information with third-party online advertisers or external websites/apps. Information is only shared within our platform for the purposes outlined above.</p>
        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Goods Delivery and Billing</div>
        <p>Goods offered for sale are delivered only to addresses within Japan and billed to Japanese companies with valid Kobutsu Licenses. </p>
        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Conflict-Free Guarantee</div>
        <p>Sellers guarantee that the goods offered are conflict-free and comply with industry standards. Any discrepancies must be reported within 24 hours of receipt.</p>
        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Dispute Resolution</div>
        <p>We adhere to common trade and industry standards and reserve the right to investigate any breaches. Breaches could result in account termination or other penalties.</p>


        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Disclaimer </div>
        <p>While we strive to protect the interests of our users, Zipang Auctions shall not be held liable for issues arising from the auction process, including but not limited to treatments in diamonds not disclosed or doubts regarding diamond origin. </p>

        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Expenses and Charges </div>
        <p>Additional charges such as freight and custom charges are the responsibility of the bidder unless otherwise stipulated in specific agreements with sellers. </p>

        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Termination of Agreement </div>
        <p>Any breach of industry standards may result in account termination, and legal action may be taken where necessary. </p>

        <div style={{ color: "#00569B", fontSize: "20px", fontWeight: "900", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>Changes to the Policy </div>
        <p>Zipang Auctions reserves the right to update and modify this privacy policy. Users will be notified of any significant changes. </p>
        <div style={{ color: "black", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px" }}>
          <p>By using our platform, you agree to the terms outlined in this privacy policy.  </p>
        </div>

        <div style={{ color: "grey", fontFamily: "sans-serif", paddingBottom: "10px", paddingTop: "10px", textAlign: 'end' }}>
          <p>Last Updated on 10th January 2024 </p>
        </div>
      </div>
    </>
  )
}

export default Privacy