import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../../redux/reducer';
import { selectJewelleryItemPerPage } from '../../../redux/slice/commanPagination/commanPaginationSlice';
import { AppDispatch } from '../../../redux/store';

const StyledPageSizeSelectorContainer = styled.div`
  border: none;
  select {
    padding: 0.8em 0.5em;
    margin: 0.3em;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    color: ${(props) => props.theme.colors.base.grey};
    width: 100%;

    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.5em 0.3em;
      margin: 0.2em;
    }
  }
`;

export interface Iprops {
    t: any;
    setItemsPerPageHandler: (pageSize : number) => void;
}

const ActionTenderPagesizeComman = (props: Iprops) => {
  const { t, setItemsPerPageHandler } = props;
  const pageCount = [10, 20, 50, 100];
  const items = `${t("common:preview:pagination:itemsPerPage")}`;
  const pageSizeHandler = (e: any) => {
    const pageSize = parseInt(e.target.value);
    setItemsPerPageHandler(pageSize);
  };
  let [selection, setSelection] = useState(10);

  const dispatch: AppDispatch = useDispatch();
  
  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectJewelleryItemPerPage(state)
  );
  useEffect(() => {
    // if (localStorage.getItem("jwlitemPerPageSelected") !== null) {
    //   let savedCount: any = localStorage.getItem("jwlitemPerPageSelected");
    //   setSelection(parseInt(savedCount));
    // } else {
    //   localStorage.setItem("jwlitemPerPageSelected", "10");
    // }
     if (selectItemPerPageValue !== null) {
       let savedCount: any = selectItemPerPageValue;
       setSelection(savedCount);
     } else {
       setSelection(selectItemPerPageValue);
     }
  }, []);
  return (
    <StyledPageSizeSelectorContainer>
      <select onChange={pageSizeHandler}>
        {pageCount.map((count, index) => (
          <option
            key={index}
            selected={count === selection ? true : false}
            value={count}
          >{`${count} ${items}`}</option>
        ))}
      </select>
    </StyledPageSizeSelectorContainer>
  );
};

export default ActionTenderPagesizeComman;