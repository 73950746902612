import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme } from "@material-ui/core/styles";

import IRouterComponentProps from '../models/IRouterComponentProps';
import { sideMenuGradient } from '../common/materials/LinearGradient';
import Footer from '../components/footer/footer';
import Flotingicon from '../components/products/Flotingicon';

const StyledContainer = styled(Grid)`
    .logoText {
        font-size: 1.5em;
    }
    .returnToWebsite {
        font-size: 18px;
    }
`;

const StyledResetPaddingContainer = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled(Grid)`
    display: inline-block;
    width: 100%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        img {
            width: 90%;
        };  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        img {
            width: 15%;
        };
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        img {
            width: 15%;
        };
    }
    display: flex;
    align-items: center;
`;

const StyledTextContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    color: ${(props) => props.theme.colors.base.white};
    font-family: SF-Display-Medium;
`;

const StyledLogoWrapperContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background: ${(props) => props.theme.colors.base.darkBlue};
    background: ${sideMenuGradient}
`;

const StyledFooterContainer = styled(Grid)`
    padding: 0 !important;
`;

const StyledLogoImage = styled.img`
    height: 2em;
    width: 1em;
`;

const StyledBannerContianer = styled(Grid)`
    height: 300px;
    width:100%;
    background-image: url(/images/banner/slide_4.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledBanContent = styled(Grid)`
    color:${(props) => props.theme.colors.base.white};
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.5em;
    }
`;

const StyledImg = styled.img`
    height:100%;
    width:100%;
`;

const StyledImgWrapper = styled(Grid)`
    text-align:center;
`;


const StyledFContWrapper = styled.div`
    display:flex;
    justify-content:center;
`;

const StyledFirstContent = styled.div`
    background: ${(props) => props.theme.colors.base.textColor};
    font-size: 1.5em;
    padding: 0.5em;
    width:80%;
    margin: -1em 0em 0em 8.5em;
    border-radius: 0.5em;
    font-style: italic;
    color: white;
    font-weight: bold;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width:100%;
        margin: -2em 0em 0em 0em;
    } 
`;

const StyledThirdContent = styled.div`
    padding: 0.3em 0.3em 3em 0.3em;
    font-size: 1.5em;
    border-radius:0.5em;
    background: #cbdcef;

    p::first-letter {
        font-size: 120%;
      }
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
    }
`;

const StyledComapnyDetail = styled(Grid)`
    .alignCenter{
        text-align:center !important;
        display:flex;
        justify-content:center;
    }
    padding:1em 1em 1em 0em;
    
    background: ${(props) => props.theme.colors.base.textColor};
    
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding:0.5em 0.5em 0.5em 0em;
    }
`;
const StyledCoFounder = styled(Grid)`
    padding:1em 1em 1em 0em;
`;

const StyledLabel = styled(Grid)`
    background: ${(props) => props.theme.colors.base.textColor};
    color:${(props) => props.theme.colors.base.white};
    padding:0.5em;
    font-weight: bold;
    text-align: center;
    width: 10%;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 30%;
    }
`;

const StyledCompanyHeader = styled.div`
    background:${(props) => props.theme.colors.base.white};
    color:${(props) => props.theme.colors.base.textColor};
    padding:0.5em;
    font-weight: bold;
    text-align: center;
    width: 10%;    
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 30%;
    }
`;

const StyledComContWrapper = styled(Grid)`
    font-size:1.2em;
    color:${(props) => props.theme.colors.base.white};
    margin:1em 0em;
`;
const StyledConLabel = styled(Grid)`
    color:${(props) => props.theme.colors.base.white};
    font-weight:bold;
    text-align:left;
`;
const StyledContent = styled(Grid)`
    text-align:left;
  
`;
const StyledConContainer = styled(Grid)`
    padding:5px;
`;
const StyledCoImage = styled.img`
    width:250px;
    height:200px;
`;

const StyledCoFoContnet = styled(Grid)`
    font-size:1.2em;
`;
const StyledCoFoWrapper = styled(Grid)`
    justify-content:center;
    text-align:center;  
`;

const StyledPadd = styled(Grid)`
    padding: 0em 2em 0em 2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        margin-top: 1em;
    }
`;

const StyledCoFoFirstLine = styled.div`
    font-weight: bold;
`;
const StyledCoFoThirdLine = styled.div`
    
`;

const StyledComBorder = styled(Grid)`
    border-bottom: 0.5px solid ${(props) => props.theme.colors.base.grey};
`;
const StyledBorder = styled(Grid)`
    border-bottom: 2px solid ${(props) => props.theme.colors.base.black};
    margin-top:10px;
    margin-bottom:10px;
`;
const StyledFounderText = styled.div` 
    color: ${(props) => props.theme.colors.base.white};
    background: ${(props) => props.theme.colors.base.textColor};
    width: 10%;    
    font-weight: bold;
    padding: 0.5em;
    text-align: center;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 30%;
    }
`;

const StyledFounerContainer = styled(Grid)`
    margin-top:-1em;
    z-index:-1;
    padding:0em 5em 0em 5em;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding:0em;
    }
`;
const StyledFounderRCon = styled(Grid)`
    padding-left:1em;
    margin-top:2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding:0em;
        margin:0em;
    }
`;
const StyledFounderMain = styled(Grid)`
    background:${(props) => props.theme.colors.base.white};
    padding:1em 0em;
    z-index:1;
`;
interface IProps extends IRouterComponentProps {
    t?: any;
}

const AboutUs = (props: IProps) => {
    const { t } = props;
    const theme = createTheme({});
    const notSmallDevices = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
    useEffect(() => {
        topFunction();
    }, [])
    function topFunction() {
      document.body.scrollTop = 0; 
      document.documentElement.scrollTop = 0; 
    }
    const returnToWebsite = () => {
        props.history.push('/')
    }

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledLogoWrapperContainer container={true} spacing={0}>
                <StyledResetPaddingContainer item xs={12}>
                    /* <StyledLogoWrapper item xs={3} onClick={returnToWebsite}>
                        <StyledLogoImage src="/images/logo.png" />
                        {notSmallDevices &&
                            <StyledTextContainer className="logoText">{t('common:header:logoText')}</StyledTextContainer>
                        }
                    </StyledLogoWrapper> */
                    <StyledTextContainer className="returnToWebsite" onClick={returnToWebsite}>
                        {t('common:kycFrom:faq:returnToWebsite')}
                    </StyledTextContainer>
                </StyledResetPaddingContainer>
            </StyledLogoWrapperContainer>
            <StyledBannerContianer item={true} xs={12}>
                <StyledBanContent item xs={12} sm={12} md={12} lg={5} xl={5}>{t('common:aboutUs:founder:founderSecondText')}</StyledBanContent>
            </StyledBannerContianer>
            <StyledFounderMain item={true} xs={12}>
                <Grid container={true} spacing={0}>
                    <Grid item xs={12}>
                        <StyledFounderText>{t('common:aboutUs:founder:founderAndCEO')}</StyledFounderText>
                    </Grid>
                    <StyledFounerContainer container spacing={0}>
                        <StyledImgWrapper item={true} xs={12} sm={12} md={12} lg={4} xl={4}>
                            <StyledImg src="/images/aboutUs/monika.jpg" />
                        </StyledImgWrapper>
                        <StyledFounderRCon item xs={12} sm={12} md={12} lg={8} xl={8}>
                            <StyledFContWrapper>
                                <StyledThirdContent><p>{t('common:aboutUs:founder:founderThirdText')}</p></StyledThirdContent>
                            </StyledFContWrapper>
                            <StyledFContWrapper>
                                <StyledFirstContent>{t('common:aboutUs:founder:founderFirstText')}</StyledFirstContent>
                            </StyledFContWrapper>
                        </StyledFounderRCon>
                    </StyledFounerContainer>
                </Grid>
            </StyledFounderMain>
            <StyledComapnyDetail item xs={12}>
                <StyledCompanyHeader>{t('common:aboutUs:company:companyProfie')}</StyledCompanyHeader>
                <Grid container spacing={0} className="alignCenter">
                    <Grid item={true} xs={12} sm={12} md={12} lg={8} xl={8}>
                        <StyledComContWrapper container spacing={0}>
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:tradeLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:tradeValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:HeaderQuaLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:HeaderValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:EstablishedLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:EstablishedValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:CEOLable')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:CEOValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:mainBusinessLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:mainBusinessValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:corporateLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:corporateValue')}</StyledContent>
                            </StyledConContainer>
                            <StyledComBorder xs={12} item />
                            <StyledConContainer item container xs={12} spacing={0}>
                                <StyledConLabel item xs={5}>{t('common:aboutUs:company:bankLabel')}</StyledConLabel>
                                <StyledContent item xs={7}>{t('common:aboutUs:company:bankValue')}</StyledContent>
                            </StyledConContainer>
                        </StyledComContWrapper>
                    </Grid>
                </Grid>
            </StyledComapnyDetail>
            <StyledCoFounder item xs={12}>
                <Grid container={true} spacing={0}>
                    <StyledLabel>{t('common:aboutUs:coFounders:coFounder')}</StyledLabel>
                    <StyledCoFoWrapper container spacing={0}>
                        <StyledCoFoContnet xs={10} item container spacing={0}>
                            <StyledPadd item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <StyledCoImage src="/images/aboutUs/kanishk.jpg" />
                                <StyledCoFoFirstLine>{t('common:aboutUs:coFounders:kanishk:firstLine')}</StyledCoFoFirstLine>

                                <StyledBorder />
                                <StyledCoFoThirdLine>{t('common:aboutUs:coFounders:kanishk:thirdLine')}</StyledCoFoThirdLine>
                            </StyledPadd>
                            <StyledPadd item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <StyledCoImage src="/images/aboutUs/pradeep.jpg" />
                                <StyledCoFoFirstLine>{t('common:aboutUs:coFounders:pradeep:firstLine')}</StyledCoFoFirstLine>

                                <StyledBorder />
                                <StyledCoFoThirdLine>{t('common:aboutUs:coFounders:pradeep:thirdLine')}</StyledCoFoThirdLine>
                            </StyledPadd>
                            <StyledPadd item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <StyledCoImage src="/images/aboutUs/shobhit.jpg" />
                                <StyledCoFoFirstLine>{t('common:aboutUs:coFounders:shobhit:firstLine')}</StyledCoFoFirstLine>

                                <StyledBorder />
                                <StyledCoFoThirdLine>{t('common:aboutUs:coFounders:shobhit:thirdLine')}</StyledCoFoThirdLine>
                            </StyledPadd>
                        </StyledCoFoContnet>
                    </StyledCoFoWrapper>
                </Grid>
            </StyledCoFounder>
            <StyledFooterContainer item={true} xs={12} className="footerContainer">
                <Footer/>
            </StyledFooterContainer>
             <Flotingicon/>           
        </StyledContainer>
    )
}

export default AboutUs;