import React, { ReactNode } from "react";
import Carousel from "react-material-ui-carousel";

const GenericCarousel = ({
  children,
  indicators,
  navButtonsAlwaysVisible,
  onChange,
}: {
  children: ReactNode;
  indicators: boolean;
  navButtonsAlwaysVisible: boolean;
  onChange: (e: any) => void;
}) => {
  return (
    <Carousel
      navButtonsAlwaysVisible={navButtonsAlwaysVisible}
      autoPlay={true}
      interval={4000}
      indicators={indicators}
      onChange={onChange}
    >
      {children}
    </Carousel>
  );
};

export default GenericCarousel;
