import * as React from 'react';
import { StyledInquiryImage } from '../../details/inquiry/InquiryDetailsCss';
import styled from 'styled-components';
import { Grid, makeStyles } from '@material-ui/core';
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { buttonGradient } from '../../../../common/materials/LinearGradient';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const StyledButton = styled.button`
  border: none;
  &:hover {
    border: none;
    cursor: pointer;
  }
`;
const StyledMainGrid = styled(Grid)`
  .MuiTooltip-tooltip {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border-radius: 10px 10px 10px 10px;
  }
  .MuiTooltip-arrow {
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
`;

const StyledPopup = styled.div`
  width: 270px;
  color: white;
  padding: 10px;
  font-size: 1em;

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 200px;
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 240px;
    font-size: 0.8em;
  }
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButtonContainer = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  border-radius: 5px;
  width: 30%;
  padding: 5px;
  cursor: pointer;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  margin-left: 5px;
`;
const StyledGridContainer = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 10px;
`;
export interface IProps {
  oninquiryFun: any;
}

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    cursor: "pointer",
    color: "blue",
  },
  tooltip: {
    fontSize: "12px",
    backgroundColor: "blue",
    borderRadius: "35px 30px 0 30px"
  },
}));

const TooltipContant = (props: JSX.IntrinsicAttributes & TooltipProps) => {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

export default function BasicPopover(props: IProps) {
  const [open, setOpen] = React.useState(true);
  const { t } = useTranslation();
  const handleTooltipClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    
    setTimeout(() => {
      setOpen(false);
    }, 3000);
    
  }, []);

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <StyledMainGrid>
      <TooltipContant
        PopperProps={{
          disablePortal: true,
        }}
        placement="left-start"
        arrow={true}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        // disableHoverListener
        disableTouchListener
        leaveDelay={1000}
        title={
          <StyledPopup>
            <span style={{ fontSize: "1.2em" }}>
              {t("common:inquiry:inquiryText")}
            </span>

            <StyledButtonContainer onClick={props.oninquiryFun}>
              {t("common:inquiry:Inquiry")}
            </StyledButtonContainer>
          </StyledPopup>
        }
      >
        <StyledGridContainer>
          <StyledButton onClick={handleTooltipOpen}>
            <StyledInquiryImage src="/images/Inquiry.PNG" />
          </StyledButton>
        </StyledGridContainer>
      </TooltipContant>
    </StyledMainGrid>
  );
}