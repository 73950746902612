import { Box, Checkbox, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CancelIcon from "@material-ui/icons/Cancel";
import ReCaptcha from "../../../pages/kyc/components/ReCaptcha";
import { Card, Divider, Typography } from "@material-ui/core";
import JSZip from "jszip";
import { v4 as uuidv4 } from "uuid";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import AuthService from "../../auth/AuthService";
import { addInventory, awsGetUrl, templateDownload } from "../../../common/config/app.endpoints";
import Axios from "axios";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import Alert from "../../../common/elements/Alert";
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoIcon from '@material-ui/icons/Info';
import TooltipInfo from "../../../common/elements/TooltipInfo";
import { useTranslation } from "react-i18next";
import CircularWithValueLabel from "./ProcessingBar";

const StyledComponent = styled(Grid)`
  outline: none;
  padding: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  .textHeadmain {
    font-size: 14px;
    font-weight: 700;
  }

  .text {
    display: block;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .textHead {
    font-size: 13px;
    font-weight: 600;
  }

  .hide-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }
  @media screen and (max-width: 768px) {
    .textHead {
      font-size: 14px;
      font-weight: 600;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 98%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 98%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 850px;
  }
`;

const StyledLoaderComponent = styled(Grid)`
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;

  .textHeadmain {
    font-size: 14px;
    font-weight: 700;
  }

  .text {
    display: block;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .textHead {
    font-size: 13px;
    font-weight: 600;
  }

  .hide-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 1px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
  }
  @media screen and (max-width: 768px) {
    .textHead {
      font-size: 14px;
      font-weight: 600;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 98%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 98%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 800px;
  }
`;
const StyledHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 5px;
  background: white;
`;
const StyledWrapper = styled(Box)`
  overflow: auto;
  height: 330px;
`;
const StyledBrowseButton = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  border: none;
  font-size: 1em;
  background: #d3d3d3;
  padding: 5px;
`;

const StyledCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    flex-direction: row;
    align-items: center;
  }
`;

const StyledInputContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5;
    margin-bottom: 5px;
     margin-Top: 5px;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
        flex-direction: Column;
        padding: 10;
        align-items: start;
    }
`;

const StyledMainLabel = styled.span`
  display: flex;
  width: 100%;
  justify-content: left;
  font-weight: 700;
  font-size: 32px;
  color: #2660ad;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
   font-size: 26px;
  }
`;

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    font-weight:700;
    padding: 0.3em;
    margin: 0.2em;
    width: 100px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;
const StyledCaptchContiner = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
        flex-direction: column;
    }
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
`;


export interface IProps {
    onCloseModal: () => void;
}
const MAX_FILE_SIZE: any = 1.5 * 1024 * 1024 * 1024; // 1.5GB in bytes
// let MAX_FILE_SIZE = 1 * 1024 * 1024; // 1MB in bytes

const AddInventoryModal = React.forwardRef((props: IProps, ref) => {
    const [notARobot, setNotARobot] = useState(false);
    const [loader, setLoader] = useState(false);
    const [mediasZipFile, setMediasZipfile] = useState(null);
    const [checkState, setCheckState] = useState({} as any);
    const [checkShippingState, setCheckShippingState] = useState({} as any);
    const [excelFile, setExcelFile] = useState({} as any);
    const [parcelType, setParcelType] = useState({} as any);
    const [errorMessage, setErrorMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [openToast, setOpenToast] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    let hiddenMediaFileInput: any = useRef(null);
    let hiddenDiamondExlFileInput: any = useRef(null);
    let hiddenParcelExlFileInput: any = useRef(null);
    let hiddenJewlleryExlFileInput: any = useRef(null);
    const Auth = new AuthService();
    let { t } = useTranslation()

    useEffect(() => {
        setErrorMessage('')
        setCheckState({
            Shipping_for_Zipang_Auction: false,
            Annual_Auction: false,
            Sell_Now: false,
        });
        setCheckShippingState({
            HongKong_Office: false,
            Tokyo_Office: false,
        });
        setParcelType({
            Diamond: false,
            Parcel: false,
            Jewellery: false,
        });
    }, []);

    //checkBox selection service or shipping
    const handleCheckBoxClick = (e: any) => {
        const targetName = e.target.name;
        setCheckState({
            ...checkState,
            [targetName]: !checkState[targetName],
        });
    };
    const handleCheckBoxShippingClick = (e: any) => {
        const targetName = e.target.name;
        setCheckShippingState({
            ...checkShippingState,
            [targetName]: !checkShippingState[targetName],
        });
    };

    const handleExcelfileChange = (e: any) => {
        const files = e.target.files;
        const targetName = e.target.name;
        let doc: any = document;
        switch (targetName) {
            case "Diamond":
                doc.getElementById("fileDiamond").innerText = files[0].name;
                setParcelType({
                    ...parcelType,
                    [targetName]: !parcelType[targetName],
                });
                break;
            case "Parcel":
                doc.getElementById("fileParcel").innerText = files[0].name;
                setParcelType({
                    ...parcelType,
                    [targetName]: !parcelType[targetName],
                });
                break;
            case "Jewellery":
                doc.getElementById("fileJewellery").innerText = files[0].name;
                setParcelType({
                    ...parcelType,
                    [targetName]: !parcelType[targetName],
                });
                break;
            default:
                break;
        }
        setExcelFile({
            ...excelFile,
            [targetName]: files,
        });
    };

    const handleChange = (event: any) => {
        const file = event.target.files[0];
        if (file && file.size <= MAX_FILE_SIZE) {
            if (file) {
                let doc: any = document;
                doc.getElementById("fileDisplay").innerText = file.name;
            }
            setMediasZipfile(file);
        } else {
            setErrorMessage('File size exceeds the maximum limit (1.5GB).');
        }
    };

    const handleNotARobot = () => {
        setNotARobot(!notARobot);
    };



    const onFinalSubmit = async () => {
        setErrorMessage('');
        const zip = new JSZip();
        let keys: any = Object.keys(excelFile);
        var uuid = uuidv4();
        let apiPayload = {};

        const shippingSelectedValues = Object.keys(checkShippingState).filter((key: any) => checkShippingState[key] === true);
        const serviceSelectedValues = Object.keys(checkState).filter((key: any) => checkState[key] === true);
        const parcelCategory = Object.keys(parcelType).filter((key: any) => parcelType[key] === true);

        const isCheckedService = Object.values(checkShippingState).includes(true);
        const isCheckedShipping = Object.values(checkState).includes(true);

        if (!isCheckedShipping) {
            setErrorMessage(`${t("common:newCommanTrans:addInventoryObj:serviceValueError")}`);
            return true
        }

        if (!isCheckedService) {
            setErrorMessage(`${t("common:newCommanTrans:addInventoryObj:shippingOfficeError")}`);
            return true
        }

        if (keys.length === 0) {
            setErrorMessage(`${t("common:newCommanTrans:addInventoryObj:uploadExcelFileError")}`);
            return true
        }

        if (!notARobot) {
            setErrorMessage("Captcha is required.");
            return true
        }
        // Create a new JSZip instance
        let presignedUrl: any;
        let zipPath: any;
        let userUniqeId = localStorage.getItem('userUniqueId');

        if (keys.length > 0) {
            let zipName = `zip_File_${uuid}.zip`
            let awsDirPath = {
                "path": `user/seller_inventory/${userUniqeId}/${zipName}`
            }
            zipPath = awsDirPath.path;
            setLoader(true);
            const { response } = await getPresignedUrl(awsDirPath);
            presignedUrl = response;
            if (response !== undefined) {
                // Add the Excel files to the zip
                if (mediasZipFile !== null) {
                    if (mediasZipFile && mediasZipFile !== null) {
                        const file: any = mediasZipFile;
                        const mediaZipBlob = await fetch(URL.createObjectURL(mediasZipFile)).then(
                            (res) => res.blob()
                        );
                        zip.file(`${file?.name}`, mediaZipBlob);
                    }
                }

                for (let i = 0; i < keys.length; i++) {
                    let fileToBeZipped: any = excelFile[keys[i]][0];
                    const excelBlob = await fetch(URL.createObjectURL(fileToBeZipped)).then((res) => res.blob());
                    zip.file(`${keys[i]}_${fileToBeZipped?.name}`, excelBlob);

                }
                let zippdedFileObject: any;

                // Generate the zip file
                await zip.generateAsync({ type: 'blob' }).then((zipBlob: any) => {
                    zippdedFileObject = new File([zipBlob], zipName, {
                        type: zipBlob.type,
                    });
                });
                // Perform the upload if the file size is within the limit
                await Axios.put(presignedUrl, zippdedFileObject, {
                    headers: {
                        'Content-Type': zippdedFileObject.type,
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    },
                })
                    .then((response: any) => {
                        if (response.status === 200) {
                            apiPayload = {
                                shipping: shippingSelectedValues,
                                services: serviceSelectedValues,
                                mediaLink: zipPath,
                                parcelTypes: parcelCategory
                            }
                            postInventoryData(apiPayload);
                        } else {
                            setLoader(false);
                            setAlertType("error");
                            setShowMessage(
                                "Something went wrong."
                            );
                            setOpenToast(true);
                        }
                    })
                    .catch(error => {
                        console.error('Error uploading file:', error.message);
                    });
                setTimeout(() => {
                    setOpenToast(false);
                }, 3000);

            } else {
                setErrorMessage("Not able to upload on AWS.");
            }
        } else {
            setErrorMessage("Please select atleast one excel file!");
            return true
        }
    };

    const getPresignedUrl = async (payload: any) => {
        const response = await new Promise(async (res) => {
            const callBack = (status: any, data: any) => {
                res({ status, data });
            };
            Auth.postRequest(`${awsGetUrl}`, payload, callBack);
        });
        const { data, status } = response as { data: any; status: number };
        return { response: data, status: status };
    };

    const postInventoryData = (data: any) => {
        if (!oAuth()) {
            // props.history.push("/");
        } else {
            Auth.postRequest(
                addInventory,
                data,
                (status: number, response: any) => {
                    if (status === 200) {
                        setLoader(false);
                        setAlertType("success");
                        setShowMessage(
                            "Data sent successfully."
                        );
                        setOpenToast(true);
                        setTimeout(() => {
                            setOpenToast(false);
                            props.onCloseModal();
                        }, 1200);
                    } else {
                        setErrorMessage("Something went wrong.");
                    }
                }
            );
        }
    }
    const templateExcelDownloadHandler = (parcelType: any) => {
        Auth.getRequestForExcel(
            `${templateDownload}?excelType=${parcelType}`,
            (status: any, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        const url = window.URL.createObjectURL(
                            new Blob([response], {
                                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            })
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `${parcelType}_products.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                    setAlertType("error");
                    setShowMessage(`${t("common:invalidRequest")}`
                    );
                    setOpenToast(true);
                    setTimeout(() => {
                        setOpenToast(false);
                    }, 1200);
                }
            }
        );
    }

    return (
        <>
            <div style={{
                position: 'relative',
                zIndex: 1,
                filter: progress !== 0 ? 'blur(1px)' : 'blur(0px)',
            }}>
                <StyledComponent className="hide-scrollbar">
                    {loader && <div className="loader">
                        <LoadingSpinner />
                    </div>
                    }
                    <StyledHeaderContainer>
                        <StyledMainLabel>{t("common:newCommanTrans:addInventory")}</StyledMainLabel>
                        <div
                            style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "right",
                            }}
                        >
                            {loader ? <CancelIcon
                                style={{ cursor: "pointer", color: 'grey' }}
                            /> :
                                <CancelIcon
                                    onClick={props.onCloseModal}
                                    style={{ cursor: "pointer" }}
                                />
                            }
                        </div>
                    </StyledHeaderContainer>
                    <StyledWrapper>
                        <div>

                            <StyledCheckBox>
                                <span
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600
                                    }}
                                >
                                    {t("common:newCommanTrans:addInventoryObj:service")}
                                </span>
                                <div>
                                    <Checkbox
                                        name="Shipping_for_Zipang_Auction"
                                        id="Shipping_for_Zipang_Auction"
                                        checked={!!checkState["Shipping_for_Zipang_Auction"]}
                                        onClick={handleCheckBoxClick}
                                    />
                                    {t("common:newCommanTrans:addInventoryObj:shippingForZipangAuction")}
                                </div>
                                <div>
                                    <Checkbox
                                        name="Annual_Auction"
                                        id="Annual_Auction"
                                        checked={!!checkState["Annual_Auction"]}
                                        onClick={handleCheckBoxClick}
                                    />
                                    {t("common:newCommanTrans:addInventoryObj:daysBeta365")}
                                </div>
                                <div>
                                    <Checkbox
                                        name="Sell_Now"
                                        id="Sell_Now"
                                        checked={!!checkState["Sell_Now"]}
                                        onClick={handleCheckBoxClick}
                                    />
                                    {t("common:newCommanTrans:addInventoryObj:sellNow")}
                                </div>
                            </StyledCheckBox>
                            <StyledCheckBox>
                                <span
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600
                                    }}
                                >
                                    {t("common:newCommanTrans:addInventoryObj:shipping")}
                                </span>
                                <div>
                                    <Checkbox
                                        name="HongKong_Office"
                                        id="HongKong_Office"
                                        checked={!!checkShippingState["HongKong_Office"]}
                                        onClick={handleCheckBoxShippingClick}
                                    />
                                    {t("common:newCommanTrans:addInventoryObj:hongKongOffice")}
                                </div>
                                <div>
                                    <Checkbox
                                        name="Tokyo_Office"
                                        id="Tokyo_Office"
                                        checked={!!checkShippingState["Tokyo_Office"]}
                                        onClick={handleCheckBoxShippingClick}
                                    />
                                    {t("common:newCommanTrans:addInventoryObj:tokyoOffice")}
                                </div>
                            </StyledCheckBox>
                        </div>
                        <div>
                            <StyledMessage>{errorMessage}</StyledMessage>
                        </div>
                        <div style={{ marginTop: "10px" }}>


                            <Grid container>
                                <Grid item xs={12} lg={5} xl={5} style={{ height: "120px" }}>
                                    <Card
                                        style={{ height: "150px", margin: 5, alignItems: "center" }}
                                    >
                                        <Typography
                                            color="initial"
                                            style={{ fontWeight: 600, fontSize: 22 }}
                                            gutterBottom
                                        >
                                            {t("common:newCommanTrans:addInventoryObj:addMediaFiles")}
                                        </Typography>
                                        <Divider variant="fullWidth" />

                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: 'center',
                                                padding: 10,
                                                marginTop: 10,
                                            }}
                                        >
                                            <>
                                                <div
                                                    style={{
                                                        width: "90%",
                                                        border: "1px solid lightgrey",
                                                        display: "flex",
                                                        margin: "auto",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                        borderRadius: "5px",
                                                    }}
                                                    onClick={() => {
                                                        hiddenMediaFileInput.current.click();
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            padding: 5,
                                                        }}
                                                        id="fileDisplay"
                                                        className="text"
                                                    >
                                                        {t("common:newCommanTrans:addInventoryObj:addZipFile")}
                                                    </span>
                                                    <StyledBrowseButton className="button-upload">
                                                        {t("common:newCommanTrans:addInventoryObj:browse")}
                                                    </StyledBrowseButton>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
                                                    onChange={handleChange}
                                                    ref={hiddenMediaFileInput}
                                                    style={{ display: "none" }} // Make the file input element invisible
                                                />
                                            </>
                                            <TooltipInfo message={t("common:newCommanTrans:addInventoryObj:zipSupportNotice")}>
                                                <InfoIcon style={{ color: "lightgrey", cursor: 'pointer', fontSize: '28px' }} />
                                            </TooltipInfo>

                                        </div>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} lg={7} xl={7} style={{ height: "190px" }}>
                                    <Card style={{ height: "auto", margin: 2, padding: '10px' }}>
                                        <Typography
                                            color="initial"
                                            style={{ fontWeight: 600, fontSize: 22 }}
                                            gutterBottom
                                        >
                                            {t("common:newCommanTrans:addInventoryObj:addFromExcel")}
                                        </Typography>
                                        <Typography
                                            color="initial"
                                            style={{ color: "red", fontWeight: 500, fontSize: 10 }}
                                            gutterBottom
                                        >
                                            {t("common:newCommanTrans:addInventoryObj:templateReferenceNotice")}
                                        </Typography>

                                        <Divider variant="fullWidth" />

                                        <StyledInputContainer
                                        >
                                            <div>
                                                <span>{t("common:newCommanTrans:addInventoryObj:templateDiamond")}</span> <GetAppIcon style={{ color: "#2660ad", cursor: 'pointer' }} onClick={() => templateExcelDownloadHandler('DIAMOND')} />
                                            </div>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div
                                                    style={{
                                                        width: 250,
                                                        border: "1px solid lightgrey",
                                                        display: "flex",
                                                        margin: "auto",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                        borderRadius: "5px",
                                                    }}
                                                    onClick={() => {
                                                        hiddenDiamondExlFileInput.current.click();
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            padding: 5,
                                                        }}
                                                        id="fileDiamond"
                                                        className="text"
                                                    >
                                                        {t("common:newCommanTrans:addInventoryObj:chooseExcelFile")}
                                                    </span>
                                                    <StyledBrowseButton className="button-upload">
                                                        {t("common:newCommanTrans:addInventoryObj:browse")}
                                                    </StyledBrowseButton>
                                                </div>
                                                <input
                                                    id="Diamond"
                                                    name="Diamond"
                                                    type="file"
                                                    accept=".xls, .xlsx"
                                                    onChange={handleExcelfileChange}
                                                    ref={hiddenDiamondExlFileInput}
                                                    style={{ display: "none" }} // Make the file input element invisible
                                                />
                                            </div>
                                        </StyledInputContainer>
                                        <StyledInputContainer
                                        >
                                            <div>
                                                <span>{t("common:newCommanTrans:addInventoryObj:templateParcel")}</span> <GetAppIcon style={{ color: "#2660ad", cursor: 'pointer' }} onClick={() => templateExcelDownloadHandler('PARCEL')} />
                                            </div>

                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div
                                                    style={{
                                                        width: 250,
                                                        border: "1px solid lightgrey",
                                                        display: "flex",
                                                        margin: "auto",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                        borderRadius: "5px",
                                                    }}
                                                    onClick={() => {
                                                        hiddenParcelExlFileInput.current.click();
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            padding: 5,
                                                        }}
                                                        id="fileParcel"
                                                        className="text"
                                                    >
                                                        {t("common:newCommanTrans:addInventoryObj:chooseExcelFile")}
                                                    </span>
                                                    <StyledBrowseButton className="button-upload">
                                                        {t("common:newCommanTrans:addInventoryObj:browse")}
                                                    </StyledBrowseButton>
                                                </div>
                                                <input
                                                    id="Parcel"
                                                    name="Parcel"
                                                    type="file"
                                                    accept=".xls, .xlsx"
                                                    onChange={handleExcelfileChange}
                                                    ref={hiddenParcelExlFileInput}
                                                    style={{ display: "none" }} // Make the file input element invisible
                                                />
                                            </div>
                                        </StyledInputContainer>
                                        <StyledInputContainer>
                                            <div>
                                                <span>{t("common:newCommanTrans:addInventoryObj:templateJewellery")}</span> <GetAppIcon style={{ color: "#2660ad", cursor: 'pointer' }} onClick={() => templateExcelDownloadHandler('JEWELLERY')} />
                                            </div>
                                            <div style={{ display: "flex", gap: "10px" }}>
                                                <div
                                                    style={{
                                                        width: 250,
                                                        border: "1px solid lightgrey",
                                                        display: "flex",
                                                        margin: "auto",
                                                        justifyContent: "space-between",
                                                        cursor: "pointer",
                                                        borderRadius: "5px",
                                                    }}
                                                    onClick={() => {
                                                        hiddenJewlleryExlFileInput.current.click();
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            padding: 5,
                                                        }}
                                                        id="fileJewellery"
                                                        className="text"
                                                    >
                                                        {t("common:newCommanTrans:addInventoryObj:chooseExcelFile")}
                                                    </span>
                                                    <StyledBrowseButton className="button-upload">
                                                        {t("common:newCommanTrans:addInventoryObj:browse")}
                                                    </StyledBrowseButton>
                                                </div>
                                                <input
                                                    id="Jewellery"
                                                    name="Jewellery"
                                                    type="file"
                                                    accept=".xls, .xlsx"
                                                    onChange={handleExcelfileChange}
                                                    ref={hiddenJewlleryExlFileInput}
                                                    style={{ display: "none" }} // Make the file input element invisible
                                                />
                                            </div>
                                        </StyledInputContainer>
                                    </Card>
                                </Grid>
                            </Grid>
                        </div>
                    </StyledWrapper>
                    <Box style={{
                        display: 'flex',
                        width: '100%',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}>

                        <StyledCaptchContiner>
                            <div className="right-group">
                                <ReCaptcha setIsVerfied={handleNotARobot} />
                            </div>
                            <div className="right-group">
                                {loader ? <StyledButton style={{ background: 'lightgrey', color: 'black', fontWeight: 700 }}>{t("common:newCommanTrans:addInventoryObj:submit")}</StyledButton> : <StyledButton onClick={onFinalSubmit}>Submit</StyledButton>}
                            </div>
                        </StyledCaptchContiner>
                    </Box>
                    {openToast && (
                        <Alert showAlertMessage={showMessage} alertType={alertType} />
                    )}
                </StyledComponent>
            </div>
            <StyledLoaderComponent style={{
                position: 'absolute',
                zIndex: progress !== 0 ? 2 : 0,
                width: '850px',
                height: '526px',
                background: 'transparent'

            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '850px',
                    height: '526px',
                    alignItems: 'center'
                }}>

                    {progress !== 0 &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', background: 'white', padding: '20px' }}>
                            <div>
                                <CircularWithValueLabel progress={progress} />
                            </div>
                            <div>
                                <span style={{ fontSize: '1em', fontWeight: 700, color: 'black' }}>File Uploading In Progress...</span>
                            </div>
                        </div>
                    }
                </div>
            </StyledLoaderComponent>
        </>


    );
});

export default AddInventoryModal;
