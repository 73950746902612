import React from 'react';
import styled from 'styled-components';
import { StyledButton, StyledComponent } from '../../common/materials/GenericStyleComponent';

const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;
    padding: 1em; 
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 630px;
    }
`;

const StyledTitle = styled.div`
    color: ${(props) => props.theme.colors.base.grey};
    text-align: center;
    margin: 1em;
    font-size: 1.5em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
      font-size: 1.5em;
    }
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    margin: 20px 0;
`;

const StyledChangedPlan = styled.span`
`;

const StyledTitleText = styled.div`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 16px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 18px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        font-size: 24px;
}
`;

interface IProps {
    t?: any;
    closeModal: () => void;
}

const ChangePlan = (props: IProps) => {
    const { t } = props;

    return (
        <StyledComponent tabIndex={-1}>
            <form name="changePlan">
                <StyledContainerWrapper>
                    <StyledTitle> <StyledTitleText>
                        <span>{t('common:profile:changePlan:receiveYourRequest')} <StyledChangedPlan className="fontBold">{t('common:profile:changePlan:changeThePlan')}. </StyledChangedPlan></span>
                        <div>{t('common:profile:changePlan:getInTouch')} </div>
                    </StyledTitleText>
                    </StyledTitle>
                    <StyledButtonContainer>
                        <StyledButton onClick={() => props.closeModal()}>
                            {t('common:button:ok')}
                        </StyledButton>
                    </StyledButtonContainer>
                </StyledContainerWrapper>
            </form>
        </StyledComponent>
    )
}

export default ChangePlan;