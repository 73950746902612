import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  backgroundGradient,
  buttonGradient,
} from "../../../../common/materials/LinearGradient";
import { StyledCardContainer, StyledModal } from "../../../../common/materials/GenericStyleComponent";
import EllipsisText from "../../../../common/elements/EllipsisText";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { Checkbox } from "@material-ui/core";
import ReactPlayer from "react-player";
import { openJewelleryCertificateLinkPopup } from "../../../../common/GenericFunctions";
import {
  convertedValue,
  convertedbuyItNowValue,
  getMyLatestStoredBids,
} from "../../../products/preview/TenderGenericFunc";
import { StyledCloseicon } from "../../../products/preview/TendorPreviewView";
import { StyledCurrentBid } from "./DiamondGridBox";
import moment from "moment";
import Countdown from "react-countdown";
import PubSub from "pubsub-js";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import {
  annualSelectAllIndexes,
  annualSelectAllInGridView,
} from "../../../../redux/slice/annual/commanAnnualSlice";
import { StyledBuyitNow } from "../DiamondProductStyle";
import BuyItNowconfirmation from "../BuyItNow/BuyItNowconfirmation";
const faHeartProps = faHeart as IconProp;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};

  display: flex;
  align-items: center;
  height: 90px;
  font-size: 0.7em;
  color: ${(props) => props.theme.colors.base.white};

  .height {
    height: 100%;
  }
`;

const StyledWishContainer = styled(Grid)`
  background: ${backgroundGradient};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  position: relative;
`;

const StyledNotStartedContainer = styled(Grid)`
  cursor: default;
  background: ${backgroundGradient};
  &:hover {
    border: 1px solid ${backgroundGradient};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledTotalSubProducts = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colors.base.lightGrey};
  width: 100%;
  text-align: center;
  opacity: 0.5;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
  padding: 5px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

const StyledfooterContaneir = styled(Grid)`
  display: flex;
  grid-gap: 20px;
`;

const StyledLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledValueConatiner = styled.div`
  text-align: center;
`;

const StyledBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  display: flex;
`;

const StyledContainer = styled(Grid)``;

const StyledContDaimond = styled.div`
  padding: 0.4em;
  font-size: 1.2em;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.8em;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

interface IProps extends IRouterComponentProps {
  t: any;
  details: any;
  perId: string;
  auctionId: string;
  serialNo: string | number;
  code: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  flour: string;
  polish: string;
  watched: boolean | null;
  polishAbbreviation: string;
  symmetry: string;
  lab: string;
  caratMinimumPrice: any;
  preBidPrice: any;
  unwatchedList: any;
  note: boolean | null | string | number;
  medias: any;
  auctionDate: any;
  remarks: string;
  parcelType: string;
  subItems: [];
  shape: string;
  sieve: string;
  noOfPieces: number | string | null;
  productDescription: string;
  currency: any;
  exchangeRates: any;
  rapnetPercentage: any;
  certificate: string;
  isPage: any;
  addToWatchList: any;
  jewelleryDetails: any;
  setShowBidPopupBidModal: any;
  currentIndex: any;
  currentBidPrice: any;
  myLatestBid: any;
  auctionUserIds: any;
  auctionUserId: any;
  items: any;
  rowColor: any;
  sortingValue: (price: any, val: any) => void;
  boxColor?: (items: any) => string;
  bulkBidHandler?: (val: any[]) => void;
  tenderSecondHighestBid: any;
  myLatestBidForProduct?: any;
  renderAutomaticBidPopupInMobile: (val: any) => void;
  sellerAuctionUserId: any;
  previousHighestBidder: any;
  renderReadOnlyUserToast: any;
  productEndDate: any;
  productStartDate: any;
  productExtended?: any;
  getExtendedTime: (val: any) => void;
  holdPreviousBidder: any;
  rightClickEvent: (e: any, val: any, valdate: any) => void;
  buyItNow: string | number;
}

let seconds: any = "";
let caratMinPrice: string = "";
let bidAmount: string = "";
let state: string | null = "";
let conversionCaratMinPrice: string = "";
let conversionBidAmount: string = "";
let date: string | string[] = "";

let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";
let direction: string = "desc";
let gridIndex: any = [];
let isReadOnlyUser: any;
let auctioneerUserIdforBidding: any;
let annualJoinedAuctions: any = [];

const JewelleryGridBoxAllProduct = (props: IProps) => {
  const [disableButton, setDisableButton] = useState(false);
  const [boxIndex, setBoxIndex] = useState(props.currentIndex);
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [isBuyItNowModal, setIsBuyItNowModal] = useState(false);
  const [gridIndexArr, setGridIndexArr] = useState<any[]>([]);
  let {
    t,
    medias,
    watched,
    serialNo,
    perId,
    auctionId,
    code,
    caratMinimumPrice,
    preBidPrice,
    remarks,
    subItems,
    currency,
    exchangeRates,
    jewelleryDetails,
    setShowBidPopupBidModal,
    currentIndex,
    items,
    rowColor,
    currentBidPrice,
    myLatestBidForProduct,
    auctionUserId,
    renderAutomaticBidPopupInMobile,
    lab,
    certificate,
    sellerAuctionUserId,
    previousHighestBidder,
    renderReadOnlyUserToast,
    productEndDate,
    productStartDate,
    productExtended,
    getExtendedTime,
    holdPreviousBidder,
    rightClickEvent,
    bulkBidHandler,
  } = props;
  caratMinPrice = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  bidAmount = new Intl.NumberFormat("us-en").format(preBidPrice);

  const selectedItemIndexes: any = useSelector((state: RootState) =>
    annualSelectAllIndexes(state)
  );
  const isSelectedCheckBox: any = useSelector((state: RootState) =>
    annualSelectAllInGridView(state)
  );

  useEffect(() => {
    caratMinPrice = "";
    bidAmount = "";
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    const rediractProductId: any = localStorage.getItem(
      "currentJewelleryProductIdOfBidNow"
    );

    if (rediractProductId !== null) {
      renderAutomaticBidPopupInMobile(items);
    }
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (items.category === "Jewellery") {
          auctioneerUserIdforBidding = items.auctionUserId;
          if (sellerAuctionUserId === items.auctionUserId) {
            setIsAuctionUserId(true);
          }
        }
      });
    } else {
      setIsAuctionUserId(false);
    }

    setDisableButton(false);
    return () => localStorage.removeItem("pathname");
  }, []);

  useEffect(() => {
    if (gridIndex.length < 10) {
      gridIndex = selectedItemIndexes;
      setGridIndexArr(gridIndex);
      bulkBidHandler?.(gridIndex);
    } else if (!isSelectedCheckBox) {
      gridIndex = [];
      setGridIndexArr(gridIndex);
      bulkBidHandler?.(gridIndex);
    }
  }, [isSelectedCheckBox]);

  if (
    caratMinimumPrice === null ||
    caratMinPrice === "NaN" ||
    caratMinPrice === "0"
  ) {
    caratMinPrice = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = new Intl.NumberFormat("us-en").format(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionCaratMinPrice = "";
    }
    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;
  }

  if (preBidPrice === null || bidAmount === "NaN" || bidAmount === "0") {
    bidAmount = "-";
    conversionBidAmount = "";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(preBidPrice * exchangeRates[0]["rate"])
      );
      conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
    } else {
      conversionBidAmount = "";
    }
    bidAmount = `${currency["symbol"]}${bidAmount}`;
  }

  if (state !== null) {
    state = localStorage.getItem("auctionState");
  }

  const onPress = (auctionId: string, perId: string, auctionDate: string) => {
    date = productStartDate.split(" ", 2);
    props.history.push(
      `/365products/details/${auctionId}/${auctionUserId}/${perId}/${date[0]}/${false}?auctionType=Jewellery`
    );
    PubSub.publish("resetAnnualGridBoxIndex", []);
  };

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return <StyledProductImage src={`${medias[0].presignedUrl}`} alt="" />;
  };

  const addProductToWatch = () => {
    props.addToWatchList(perId, auctionId, serialNo);
  };

  const removeProductFromList = () => {
    props.unwatchedList(perId, auctionId);
  };

  if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const getProductDescription = () => {
    return `${code} ${jewelleryType} ${jewelleryCondition} ${jewelleryDescription}`;
  };

  const TenderPlaceBidPopup = () => {
    setShowBidPopupBidModal(boxIndex);
  };

  const toggleCheckboxValue = (e: any, indexOfProduct: any) => {
    var rowId = indexOfProduct;
    var checked = e.target.checked;
    PubSub.subscribe("resetAnnualGridBoxIndex", (msg: any, data: any) => {
      gridIndex = data;
      setGridIndexArr(gridIndex);
    });
    if (checked) {
      let newArray = [...gridIndex, rowId];
      gridIndex = newArray;
      setGridIndexArr(gridIndex);
    } else {
      gridIndex = gridIndex.filter((x: any) => x != rowId);
      setGridIndexArr(gridIndex);
    }
    bulkBidHandler?.(gridIndex);
  };

  const sortingFuction = (price: any, value: any) => {
    props.sortingValue(price, value);
    if (value === "desc") {
      direction = "asc";
    } else {
      direction = "desc";
    }
  };
  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );

  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  const renderCheckBox = () => {
    annualJoinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    annualJoinedAuctions = JSON.parse(annualJoinedAuctions);
    let userAuctioneerId: any = "";
    if (annualJoinedAuctions !== null) {
      annualJoinedAuctions.filter((items: any, index: number) => {
        if (items.category === "Jewellery") {
          userAuctioneerId = items.auctionUserId;
        }
      });
    }
    if (sellerAuctionUserId === auctionUserId) {
      return (
        <Checkbox
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          disabled
        />
      );
    } else {
      return (
        <Checkbox
          id={perId}
          name={perId}
          checked={gridIndex.includes(currentIndex)}
          color="primary"
          style={{ color: "white" }}
          value={currentIndex}
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          onClick={(e) => toggleCheckboxValue(e, currentIndex)}
        />
      );
    }
  };

  const renderBidButton = () => {
    annualJoinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    annualJoinedAuctions = JSON.parse(annualJoinedAuctions);
    let userAuctioneerId: any = "";
    if (annualJoinedAuctions !== null) {
      annualJoinedAuctions.filter((items: any, index: number) => {
        if (items.category === "Jewellery") {
          userAuctioneerId = items.auctionUserId;
        }
      });
    }
    if (sellerAuctionUserId === auctionUserId) {
      return (
        <StyledButton
          disabled
          style={{ background: "lightgray", color: "black" }}
        >
          {t("common:Tender:button:placeBid")}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton onClick={TenderPlaceBidPopup}>
          {t("common:Tender:button:placeBid")}
        </StyledButton>
      );
    }
  };

  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        let page: any = localStorage.getItem(
          "jwlcurrentPageOfTenderAllProducts"
        );
        page = parseInt(page);
        getExtendedTime(page);
        getExtendedTime(page);
        return <span>00:00:00:00</span>;
      }
    } else {
      const minutesInt = parseInt(minutes);
      if (days === "00" && hours === "00" && minutesInt <= 30) {
        return (
          <span style={{ color: "red" }}>
            {days}:{hours}:{minutes}:{seconds}
          </span>
        );
      } else {
        return (
          <span>
            {days}:{hours}:{minutes}:{seconds}
          </span>
        );
      }
    }
  };

  const productTimeLeft = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
        }
      }
    }
    return (
      <Countdown
        date={Date.now() + seconds}
        className="countdownText"
        renderer={renderer}
        zeroPadTime={2}
      />
    );
  };

  let PlayerSlideMeMo = useMemo(() => {
    return (
      <StyledProductImageContainer
        item={true}
        xs={9}
        onClick={() => onPress(auctionId, perId, productStartDate)}
      >
        {medias === null ||
          medias === undefined ||
          medias[0] === undefined ||
          medias[0] === null ||
          medias[0].presignedUrl === null ||
          medias[0].mediaType === "V360" ? (
          <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
        ) : (
          <>
            {medias[0].mediaType === "PHOTO" ? (
              <ShowImage />
            ) : (
              <div>
                {medias[0].mediaType.thumbnailPresignedUrl === null ||
                  medias[0].mediaType.presignedUrl === null ? (
                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                ) : (
                  <PlayerSlide url={`${medias[0].presignedUrl}`} />
                )}
              </div>
            )}
          </>
        )}
        <StyledTotalSubProducts>
          {subItems !== undefined && subItems.length > 0 && (
            <span>
              {subItems.length} {t("common:preview:products")}
            </span>
          )}
        </StyledTotalSubProducts>
      </StyledProductImageContainer>
    );
  }, [items]);


  const renderBuyItNowConfirmationModal = () => {
    return (
      <StyledModal
        open={isBuyItNowModal}
        onClose={() => {
          setIsBuyItNowModal(false);
        }}
      >
        <BuyItNowconfirmation
          closeModal={() => {
            setIsBuyItNowModal(false);
          }}
          itemDetails={items}
          auctionUserId={props.auctionUserIds}
        />
      </StyledModal>
    );
  };

  return (
    <Grid>
      {perId !== undefined && (
        <StyledCardContainer
          id={serialNo.toString()}
          onContextMenu={(event: any) =>
            rightClickEvent(event, perId, productStartDate)
          }
        >
          <Grid container={true} spacing={0} className="borderBottomBlue">
            <StyledTitleWrapper item={true} xs={10}>
              <Grid container={true} spacing={0} className="height">
                <Grid item={true} xs={2}>
                  {isReadOnlyUser ? (
                    <Checkbox
                      inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                      disabled
                    />
                  ) : (
                    <>
                      {isAuctionUserId ? (
                        <Checkbox
                          inputProps={{
                            "aria-label": "uncontrolled-checkbox",
                          }}
                          disabled
                        />
                      ) : (
                        renderCheckBox()
                      )}
                    </>
                  )}
                </Grid>
                <Grid
                  item={true}
                  xs={10}
                  onClick={() => onPress(auctionId, perId, productStartDate)}
                >
                  <div>
                    <StyledContDaimond>
                      <EllipsisText
                        text={getProductDescription()}
                        length={68}
                        tail={"..."}
                      />
                    </StyledContDaimond>
                  </div>
                </Grid>
                <StyledRemarks item={true} xs={12}>
                  <div>
                    <StyledRemarkLbl>
                      {t("common:preview:perItemDescription:remarks") + ":"}
                      {props.remarks !== "" &&
                        props.remarks !== undefined &&
                        props.remarks !== null ? (
                        <>
                          <EllipsisText
                            text={remarks}
                            length={25}
                            tail={"..."}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </StyledRemarkLbl>
                  </div>
                </StyledRemarks>
              </Grid>
            </StyledTitleWrapper>
            <Grid container xs={2}>
              {watched === false || watched === undefined ? (
                <StyledContainer item={true} xs={12}>
                  <div>
                    {isReadOnlyUser ? (
                      <StyledNotStartedContainer
                        item={true}
                        xs={12}
                        onClick={() =>
                          renderReadOnlyUserToast(sellerAuctionUserId)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          className="unwatch imageColor"
                        />
                      </StyledNotStartedContainer>
                    ) : (isAuctionUserId === false ||
                      sellerAuctionUserId === undefined) &&
                      disableButton === false ? (
                      <StyledWishContainer
                        item={true}
                        xs={12}
                        onClick={(e) => {
                          addProductToWatch();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          className="unwatch"
                        />
                      </StyledWishContainer>
                    ) : (
                      <StyledNotStartedContainer
                        item={true}
                        xs={12}
                        onClick={() =>
                          renderReadOnlyUserToast(sellerAuctionUserId)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          className="unwatch imageColor"
                        />
                      </StyledNotStartedContainer>
                    )}
                  </div>
                </StyledContainer>
              ) : (
                <StyledContainer item={true} xs={12}>
                  <div>
                    <StyledWishContainer
                      item={true}
                      xs={12}
                      onClick={(e) => {
                        removeProductFromList();
                      }}
                    >
                      <FontAwesomeIcon icon={faHeartProps} className="watch" />
                    </StyledWishContainer>
                  </div>
                </StyledContainer>
              )}
              <StyledContainer item={true} xs={12}>
                <StyledNotStartedContainer
                  item={true}
                  xs={12}
                  onClick={() => onPress(auctionId, perId, productStartDate)}
                >
                  <StyledCloseicon src="/images/noteIcons/whiteNotes.png" />
                </StyledNotStartedContainer>
              </StyledContainer>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper
                item={true}
                xs={12}
                onClick={() => sortingFuction("currentBidPrice", direction)}
              >
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:Tender:tableColumnNames:liveHighestBid")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  <span className="fontBold textWrap">
                    {convertedValue(
                      currency,
                      currentBidPrice,
                      exchangeRates[0]["rate"]
                    )}
                  </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold"></StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <Grid item={true} xs={3} className="borderRightBlue">
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper
                    onClick={() => sortingFuction("serialNo", direction)}
                  >
                    {t("common:preview:productsList:serialNo")}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper style={{ marginTop: "10px" }}>
                  <StyledSerialNumberLabelWrapper
                    onClick={() => sortingFuction("lab", direction)}
                  >
                    {lab}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {lab !== "AGT" &&
                      lab !== "Agt" &&
                      lab !== "" &&
                      lab !== undefined ? (
                      <a
                        href="#/"
                        onClick={() =>
                          openJewelleryCertificateLinkPopup(lab, certificate)
                        }
                        className="moveToLandingPageLink"
                      >
                        {certificate}
                      </a>
                    ) : (
                      <>
                        <span style={{ color: "black" }}>{certificate}</span>
                      </>
                    )}
                  </StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper style={{ fontSize: "0.6em" }}>
                    {t("common:newCommanTrans:timeleft")}
                  </StyledSerialNumberLabelWrapper>                 
                    <StyledSerialNumberLabelWrapper
                      style={{ fontSize: "0.6em" }}
                    >
                      {productTimeLeft(productEndDate)}
                    </StyledSerialNumberLabelWrapper>
                </StyledWrapper>
                <StyledWrapper>
                  <span style={{ color: "#E14843" }}>
                    {convertedbuyItNowValue(
                      currency,
                      props.buyItNow,
                      exchangeRates[0]["rate"]
                    )}
                  </span>
                  <div>
                    {props.buyItNow === 0 ||
                      isAuctionUserId ||
                      isReadOnlyUser ||
                      props.buyItNow < props.currentBidPrice ? (
                      <StyledBuyitNow
                        style={{
                          color: "black",
                          backgroundColor: "lightgrey",
                        }}
                      >
                        {t("common:newCommanTrans:buyNow")}
                      </StyledBuyitNow>
                    ) : (
                      <StyledBuyitNow
                        onClick={() => {
                          setIsBuyItNowModal(true);
                        }}
                      >
                        {t("common:newCommanTrans:buyNow")}
                      </StyledBuyitNow>
                    )}
                  </div>
                </StyledWrapper>
              </Grid>
              {PlayerSlideMeMo}
            </Grid>
            <StyledCurrentBid
              className="display fontSize"
              changeColor={props.boxColor ? props.boxColor(items) : rowColor}
            >
              <Grid
                container={true}
                xs={12}
                spacing={0}
                style={{ padding: "8px" }}
              >
                <StyledfooterContaneir item={true} xs={9}>
                  <Grid
                    container={true}
                    spacing={0}
                    style={{ fontSize: "0.7em" }}
                  >
                    <Grid item={true} xs={12} style={{ display: "flex" }}>
                      <span
                        className="fontBold textWrap"
                        onClick={() =>
                          sortingFuction("caratMinimumPrice", direction)
                        }
                      >
                        Starting Price:
                      </span>
                      <span className="fontBold">
                        {caratMinPrice} {conversionCaratMinPrice}
                      </span>
                    </Grid>
                    <Grid item={true} xs={12} style={{ display: "flex" }}>
                      <span className="fontBold textWrap">
                        {t("common:Tender:tableColumnNames:yourBid")}
                      </span>
                      <span className="fontBold textWrap">
                        {handelMyBidsLabel(
                          myLatestBidForProduct,
                          perId,
                          currency,
                          exchangeRates[0]["rate"]
                        )}
                      </span>
                    </Grid>
                  </Grid>
                </StyledfooterContaneir>
                <Grid item={true} xs={3} style={{ display: "grid" }}>
                  {isAuctionUserId ? (
                    <StyledButton
                      onClick={() =>
                        renderReadOnlyUserToast(sellerAuctionUserId)
                      }
                      style={{ background: "lightgray", color: "black" }}
                    >
                      {t("common:Tender:button:placeBid")}
                    </StyledButton>
                  ) : (
                    <>
                      {isReadOnlyUser ? (
                        <StyledButton
                          style={{ background: "gray" }}
                          onClick={() =>
                            renderReadOnlyUserToast(sellerAuctionUserId)
                          }
                        >
                          {t("common:Tender:button:placeBid")}
                        </StyledButton>
                      ) : (
                        <>{renderBidButton()}</>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </StyledCurrentBid>
          </Grid>
          {isBuyItNowModal && renderBuyItNowConfirmationModal()}
        </StyledCardContainer>
      )}
    </Grid>
  );
};

export default JewelleryGridBoxAllProduct;
