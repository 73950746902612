import { Box } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import styled from "styled-components";
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'; //Buyer

const StyledLogo = styled.img`
  width: 50px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 34px;
    display: flex;
  }
`;

const StyledBox = styled.div`
  height: 380px;
  background-color: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 5px 2px #d92b3c;
  border-radius: 20px;
  padding: 20px;
  ${(props) => props.theme.breakpoints.only("xs")} {
    height: 380px;
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 320px;
    width: 500px;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    height: 355px;
    width: 500px;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 350px;
    width: 500px;
  }
  @media (width: 1280px) {
    height: 380px;
    width: 500px;
  }
  @media (width: 1368px) {
    height: 355px;
    width: 500px;
  }
  @media (width: 320px) {
    height: 500px;
    width: 300px;
  }
  @media (width: 280px) {
    height: auto;
    width: 260px;
  }
`;
const StyledLabel = styled.span`
  text-align: left;
  font-weight: 600;
  // font: normal normal 600 32px/43px Segoe UI;
  color: #d92b3c;
  text-transform: uppercase;
  opacity: 1;
  font-size: 1.4em;
  ${(props) => props.theme.breakpoints.only("lg")} {
    font-size: 1.4em;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    font-size: 1.8em;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.2em;
  }

  @media (width: 320px) {
    font-size: 1em;
  }
  @media (width: 280px) {
    font-size: 0.8em !important;
  }
`;
const StyledSubLabel = styled(Typography)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.2em;
  }
   ${(props) => props.theme.breakpoints.only("lg")} {
    font-size: 1.4em;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    font-size: 1.6em;
  }

  @media (width: 320px) {
    font-size: 0.8em;
  }
  @media (width: 280px) {
    font-size: 0.6em !important;
  }
`;
const StyledSideLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
`;

interface Iprops {
  data: any;
}

export default (props: Iprops) => {
  let { t } = useTranslation();
  let { data } = props;
  var settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {data.map((item: any, index: number) => {
          return <div key={index}>
            <Box style={{
              padding: "20px",
              display: 'flex',
              alignItems: "center",
              justifyContent: "center",
              fontFamily: 'Playfair Display'
            }}>
              <StyledBox>
                <StyledSideLogoContainer>
                  {item.isLink ?
                    <StyledLogo src={item.link} alt="worldIcon" />
                    :
                    <GroupOutlinedIcon style={{ color: "#D92B3C", fontSize: "40px" }} />
                  }
                </StyledSideLogoContainer>
                <div style={{ display: "grid", gap: "20px" }}>
                  <div>
                    <StyledLabel>{t(`${item.title}`)}</StyledLabel>

                  </div>
                  <div>
                    <StyledSubLabel>
                      {t(`${item.subHeading1}`)}
                    </StyledSubLabel>
                  </div>
                </div>
              </StyledBox>
            </Box>
          </div>
        }
        )}
      </Slider>
    </div>
  );
}

