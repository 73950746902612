import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import AuctionsView from './AuctionsView';
import IRouterComponentProps from '../../../models/IRouterComponentProps';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

interface IProducts {
    id: number | string;
    name: string;
    brandName?: string;
    price: number;
    serialNumber: number;
    preBiddingPrice: number;
}

interface IProps extends IRouterComponentProps {
    t?: any;
    selectedPage?: boolean;
    currentRecords?: IProducts;
    value: (value: boolean) => void;
}

interface IState {
    products: [];
    totalProducts: string;
    openToast: boolean;
    showAlertMessage: string;
}

export default class AuctionList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            products: [],
            totalProducts: '',
            openToast: false,
            showAlertMessage: ''
        };
    }

    setProducts(products: any) {
        this.setState(() => ({
            products
        }))
    }

    componentDidMount() {
        this.setProducts(this.props.currentRecords);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.currentRecords !== this.props.currentRecords) {
            this.setProducts(this.props.currentRecords);
        }
    }

    value = (value: boolean) => {
        if (value === true) {
            this.props.value(true);
        }
    }

    render() {
        return (
            <StyledGrid container={true} spacing={0}>
                {
                    this.state.products.map((product: any, index: number) => {
                        return <Grid key={index} item={true} xs={12} sm={6} md={6} lg={3} xl={3}>
                            <AuctionsView product={product} {...this.props} value={this.value} />
                        </Grid>
                    })
                }
            </StyledGrid>
        )
    }
}