import React from "react";

import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const StyledComponent = styled(Grid)`
  outline: none;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 2em 1em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 600px;
    height: 450px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 60vw;
    height: 95vh;
  }
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  padding: 20px 20px;
`;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    height: 300px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    height: 100%;
  }
`;

interface IProps {
  videoUrl: string;
}

const VideoModal = (props: IProps, ref: any) => {
  const { videoUrl } = props;

  return (
    <div>
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <StyledParticipateWrapper item md={12} xs={12} lg={12}>
            <StyledFrame src={`${videoUrl}&sr=100`} />
          </StyledParticipateWrapper>
        </StyledWrapper>
      </StyledComponent>
    </div>
  );
};

export default React.forwardRef(VideoModal);
