import React, { useState } from "react";

const Cross = ({
  filled = false,
  classes,
}: {
  filled?: boolean;
  classes?: string;
}) => {
  const [mouseOver, setMouseOver] = useState(filled);

  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };

  return (
    <svg
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOver}
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      className={classes}
    >
      {mouseOver ? (
        <g
          id="Group_217"
          data-name="Group 217"
          transform="translate(-2329 -1149)"
        >
          <g
            id="Group_177"
            data-name="Group 177"
            transform="translate(2328.717 1148.717)"
          >
            <circle
              id="Ellipse_245"
              data-name="Ellipse 245"
              cx="12.5"
              cy="12.5"
              r="12.5"
              transform="translate(0.283 0.283)"
              fill="#293139"
            />
          </g>
          <g
            id="Group_178"
            data-name="Group 178"
            transform="translate(2336.752 1156.752)"
          >
            <g
              id="Rectangle_681"
              data-name="Rectangle 681"
              transform="translate(0.517 0) rotate(45)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.3"
            >
              <rect width="12.418" height="0.73" stroke="none" />
              <rect
                x="-0.15"
                y="-0.15"
                width="12.718"
                height="1.03"
                fill="none"
              />
            </g>
            <g
              id="Rectangle_682"
              data-name="Rectangle 682"
              transform="translate(9.297 0.517) rotate(135)"
              fill="#fff"
              stroke="#fff"
              stroke-width="0.3"
            >
              <rect width="12.418" height="0.73" stroke="none" />
              <rect
                x="-0.15"
                y="-0.15"
                width="12.718"
                height="1.03"
                fill="none"
              />
            </g>
          </g>
        </g>
      ) : (
        <g
          id="Group_218"
          data-name="Group 218"
          transform="translate(-2329 -1149)"
        >
          <g
            id="Group_177"
            data-name="Group 177"
            transform="translate(2328.717 1148.717)"
          >
            <g
              id="Ellipse_245"
              data-name="Ellipse 245"
              transform="translate(0.283 0.283)"
              fill="#fff"
              stroke="#293139"
              stroke-width="1"
            >
              <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
              <circle cx="12.5" cy="12.5" r="12" fill="none" />
            </g>
          </g>
          <g
            id="Group_178"
            data-name="Group 178"
            transform="translate(2336.752 1156.752)"
          >
            <g
              id="Rectangle_681"
              data-name="Rectangle 681"
              transform="translate(0.517 0) rotate(45)"
              fill="#293139"
              stroke="#293139"
              stroke-width="0.3"
            >
              <rect width="12.418" height="0.73" stroke="none" />
              <rect
                x="-0.15"
                y="-0.15"
                width="12.718"
                height="1.03"
                fill="none"
              />
            </g>
            <g
              id="Rectangle_682"
              data-name="Rectangle 682"
              transform="translate(9.297 0.517) rotate(135)"
              fill="#293139"
              stroke="#293139"
              stroke-width="0.3"
            >
              <rect width="12.418" height="0.73" stroke="none" />
              <rect
                x="-0.15"
                y="-0.15"
                width="12.718"
                height="1.03"
                fill="none"
              />
            </g>
          </g>
        </g>
      )}
    </svg>
  );
};

export default Cross;
