const ColorsList = [
    {
        id: '1',
        name: 'Fancy',
        selectedValue: false
    }, {
        id: '2',
        name: 'D',
        selectedValue: false
    }, {
        id: '3',
        name: 'E',
        selectedValue: false
    }, {
        id: '4',
        name: 'F',
        selectedValue: false
    }, {
        id: '5',
        name: 'G',
        selectedValue: false
    }, {
        id: '6',
        name: 'H',
        selectedValue: false
    }, {
        id: '7',
        name: 'I',
        selectedValue: false
    }, {
        id: '8',
        name: 'J',
        selectedValue: false
    }, {
        id: '9',
        name: 'K',
        selectedValue: false
    }, {
        id: '10',
        name: 'L',
        selectedValue: false
    }, {
        id: '11',
        name: 'M',
        selectedValue: false
    }, {
        id: '12',
        name: 'UNDER N',
        selectedValue: false
    }, {
        id: '13',
        name: 'UNDER S',
        selectedValue: false
    }
];

export default ColorsList;