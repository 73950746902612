const ParcelTypeColorsList = [
    {
        id: '1',
        name: 'Fancy',
        selectedValue: false
    }, {
        id: '2',
        name: 'D',
        selectedValue: false
    }, {
        id: '3',
        name: 'E',
        selectedValue: false
    }, {
        id: '4',
        name: 'F',
        selectedValue: false
    }, {
        id: '5',
        name: 'G',
        selectedValue: false
    }, {
        id: '6',
        name: 'H',
        selectedValue: false
    }, {
        id: '7',
        name: 'I',
        selectedValue: false
    }, {
        id: '8',
        name: 'J',
        selectedValue: false
    }, {
        id: '9',
        name: 'K',
        selectedValue: false
    }, {
        id: '10',
        name: 'L',
        selectedValue: false
    }, {
        id: '11',
        name: 'M',
        selectedValue: false
    }, {
        id: '12',
        name: 'UNDER N',
        selectedValue: false
    }, {
        id: '13',
        name: 'UNDER S',
        selectedValue: false
    }, {
        id: '14',
        name: 'Pink',
        selectedValue: false
    }, {
        id: '15',
        name: 'Brown',
        selectedValue: false
    }, {
        id: '16',
        name: 'Yellow',
        selectedValue: false
    }, {
        id: '17',
        name: 'White',
        selectedValue: false
    }, {
        id: '18',
        name: 'Natts',
        selectedValue: false
    }, {
        id: '19',
        name: 'LC',
        selectedValue: false
    }, {
        id: '20',
        name: 'TLB',
        selectedValue: false
    }, {
        id: '21',
        name: 'BG',
        selectedValue: false
    }, {
        id: '22',
        name: 'LB',
        selectedValue: false
    }, {
        id: '23',
        name: 'TTLB',
        selectedValue: false
    }, {
        id: '24',
        name: 'TP',
        selectedValue: false
    }, {
        id: '25',
        name: 'TLC',
        selectedValue: false
    }, {
        id: '26',
        name: 'TTLC',
        selectedValue: false
    },{
        id: '27',
        name: 'OWLB',
        selectedValue: false
    },
];

export default ParcelTypeColorsList;