const ClarityList = [
    {
        id: '1',
        name: 'FL',
        selectedValue: false
    }, {
        id: '2',
        name: 'IF',
        selectedValue: false
    }, {
        id: '3',
        name: 'VVS-1',
        selectedValue: false
    }, {
        id: '4',
        name: 'VVS-2',
        selectedValue: false
    },  {
        id: '5',
        name: 'VS-1',
        selectedValue: false
    }, {
        id: '6',
        name: 'VS-2',
        selectedValue: false
    },  {
        id: '7',
        name: 'SI-1',
        selectedValue: false
    }, {
        id: '8',
        name: 'SI-2',
        selectedValue: false
    }, {
        id: '9',
        name: 'SI-3',
        selectedValue: false
    },{
        id: '10',
        name: 'I-1',
        selectedValue: false
    }, {
        id: '11',
        name: 'I-2',
        selectedValue: false
    }, {
        id: '12',
        name: 'I-3',
        selectedValue: false
    }
];

export default ClarityList;