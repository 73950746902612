import React, { useState } from 'react';
import Styled from 'styled-components';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { handleInputChange } from 'react-select/src/utils';

const StyledCategoryContainer = Styled.div`
    margin-top: 2em;
    margin-bottom: 1em;
    border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledTitle = Styled.div`
    color: ${(props) => props.theme.colors.base.veryDarkGrey};
`;

const StyledHorizontalList = styled(Grid)`
    display: flex;
    padding: 0.5em;
    justify-content: center;
    width: 100%;
`;

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    align-items: center;
    width: 150px;
    justify-content: center;
    &:hover {
        cursor: pointer;
    }

    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 100px;
    }
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        width: 100px;
    }
`;

const StyledCategoryImage = Styled.img`
    height: 2.2em;
`;

const StyledCategoryName = Styled.span`
    font-size: 0.8em;
`;

interface IProps {
    t: any;
    handleConponets: (categroyType: String) => void;
    homeCategoryIcon?: any;
}

const CategoryCarousal = (props: IProps) => {
    const { t, handleConponets } = props;

    const [daimondsColor, setDaimondsColor] = useState(true);
    const [homeColor, setHomeColor] = useState(false);
    const [jewelleryColor, setJewelleryColor] = useState(false);

    const categoryClick = (categoryType: String) => {
        if (categoryType !== null && categoryType !== undefined) {
            if (categoryType === 'DIAMODS') {
                //localStorage.setItem('categoryType', 'DIAMODS');
                handleConponets("DIAMODS");
                setDaimondsColor(true);
                setJewelleryColor(false);
                setHomeColor(false);
            } else if (categoryType === 'BAGS') {
                //localStorage.setItem('categoryType', 'BAGS');
            } else if (categoryType === 'BAGS') {
                //localStorage.setItem('categoryType', 'ACCESSORIES');
            } else if (categoryType === 'ACCESSORIES') {
                //localStorage.setItem('categoryType', 'WATCH');
            } else if (categoryType === 'SHOES') {
                //localStorage.setItem('categoryType', 'SHOES');
            } else if (categoryType === 'JEWELLERY') {
                handleConponets("JEWELLERY");
                setJewelleryColor(true);
                setDaimondsColor(false);
                setHomeColor(false);
            } else if (categoryType === 'HOME') {               
                handleConponets("HOME");
                setHomeColor(true);
                setJewelleryColor(false);
                setDaimondsColor(false);
            }else {
                //todo handle other condition
            }
        }
    }

    return (
        <div>
            <StyledCategoryContainer>
                <StyledTitle className="fontBold separator">{t('common:preview:carousalTitle')}</StyledTitle>
                <StyledHorizontalList container={true} spacing={0}>
                {/* <Grid item={true} onClick={() => categoryClick('HOME')}>
                        <StyledWrapper>
                            <div>
                                {homeColor === true ?
                                    <StyledCategoryImage src="/images/category/home/allDarkBlue.png" />
                                    :
                                    <StyledCategoryImage src="/images/category/home/allLightBlue.png"/> 
                                }
                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:home')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid> */}
                    <Grid item={true} onClick={() => categoryClick('DIAMODS')}>
                        <StyledWrapper>
                            <div>
                                {daimondsColor === true ?
                                    <StyledCategoryImage src="/images/category/active/diamondsDark.png" />
                                    :
                                    <StyledCategoryImage src="/images/category/diamonds_color.jpg"/>
                                }

                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:diamonds')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid>
                     <Grid item={true} onClick={() => categoryClick('JEWELLERY')}>
                        <StyledWrapper>
                            <div>
                                {jewelleryColor === true ?
                                    <StyledCategoryImage src="/images/category/active/jewelleryDark.png" />
                                    :
                                    <StyledCategoryImage src="/images/category/jewellery_color.jpg" />
                                }
                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:jewellery')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid>
                    <Grid item={true} onClick={() => categoryClick('BAGS')}>
                        <StyledWrapper>
                            <div>
                                <StyledCategoryImage src="/images/category/bags_grey.jpg" />
                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:bags')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid>
                    <Grid item={true} onClick={() => categoryClick('ACCESSORIES')}>
                        <StyledWrapper>
                            <div>
                                <StyledCategoryImage src="/images/category/accessories_grey.jpg" />
                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:accessories')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid>
                    <Grid item={true} onClick={() => categoryClick('SHOES')}>
                        <StyledWrapper>
                            <div>
                                <StyledCategoryImage src="/images/category/shoes_grey.jpg" />
                            </div>
                            <StyledCategoryName>{t('common:landingPage:category:shoes')}</StyledCategoryName>
                        </StyledWrapper>
                    </Grid>                   
                </StyledHorizontalList>
            </StyledCategoryContainer>
        </div>
    )
}

export default CategoryCarousal;