import React, { ReactNode } from "react";
import styled from "styled-components";

interface ImageCardProps {
  imageUrl: string;
  text: ReactNode;
  heading: string;
  textTop?: boolean;
}

interface TextProps {
    top: boolean;
}

const ImageCardContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: 30px;
  box-shadow: black 0px 8px 18px 0px;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;

  ${ImageCardContainer}:hover & {
    transform: scale(1.1);
  }
`;

const CardText = styled.div<TextProps>`
  position: absolute;
  bottom: ${(props) => (props.top ? "auto" : "10%")};
  top: ${(props) => (props.top ? "10%" : "auto")};
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s ease;
  textalign: center;
`;

const CardHover = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  color: black;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  ${ImageCardContainer}:hover & {
    opacity: 1;
  }
`;

const ImageCard: React.FC<ImageCardProps> = ({ imageUrl, heading, text, textTop = false }) => {
  return (
    <ImageCardContainer>
      <CardImage src={imageUrl} alt="Card" />
      <CardText top={textTop} style={{ padding: "10px" }}>
        {heading}
      </CardText>
      <CardHover>{text}</CardHover>
    </ImageCardContainer>
  );
};

export default ImageCard;
