import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import Styled, { css } from "styled-components";
import FieldSet from "../../../common/elements/FieldSet";
import {
  StyledButton,
  StyledWrapper,
} from "../../../common/materials/GenericStyleComponent";
import AuthService from "../../auth/AuthService";
import { searchAuctionFilterUrl } from "../../../common/config/app.endpoints";
import { buttonGradient } from "../../../common/materials/LinearGradient";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import ParcelTypeShapesList from "./ParcelTypeShapesList";
import ParcelTypeColorsList from "./ParcelTypeColorsList.";
import { StyledMessage } from "./ProductsFilter";

const StyledFilterGrid = styled(Grid)``;

const StyledButtonContainer = styled(Grid)`
  text-align: center;
`;

const StyledGridWrapper = styled.div`
  margin-top: 2em;
  input {
    color: ${(props) => props.theme.colors.base.black};
  }
`;

const StyledFilterPriceWrapper = styled(Grid)``;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledLabel = styled.div`
  text-align: left;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
`;

const StyledValue = styled.input`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  padding: 7px;
  width: 100%;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.8em;
`;

const StyledFilterWeightContainer = styled(Grid)``;

const StyledHorizontalList = Styled.ul`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    text-align: center;
`;

interface IName {
  isSelected: any;
}

const StyledName = Styled.div<IName>`
    border: 1px solid ${(props) =>
      props.theme.colors.base.buttonGradientFirstColor};
    border-radius: 2px;
    font-size: 1em;
    padding: 0.2em 0.5em;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    cursor: pointer;
    &:hover {
        background: ${(props) =>
          props.theme.colors.base.buttonGradientFirstColor};
        background: ${buttonGradient}
        color: ${(props) => props.theme.colors.base.white};
    }
    ${(props) =>
      props.isSelected &&
      css`
        background: ${(props) =>
          props.theme.colors.base.buttonGradientFirstColor};
        background: ${buttonGradient};
        color: ${(props) => props.theme.colors.base.white};
      `}
`;

const StyledShapeImage = Styled("img")`
    height: 2.8em;
    width: 2.8em;
`;

const StyledShapeName = Styled.div`
    font-size: 0.8em;
`;

const StyledShapeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  width: 110px;
  align-items: center;
  justify-content: center;
  @media (width: 320px) {
    width: 90px;
  }
`;

export const StyledWrapperParcelType = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  align-items: center;
  justify-content: center;
`;
interface IProps extends IRouterComponentProps {
  t: any;
  filteredParcelAuctions: any;
  auctionId: string | null;
  annualAuction?: boolean;
  isPreviewPage?: boolean;
}

interface IState {
  shapes: any;
  parcelTypeArr: any;
  colors: any;
  fromWeight: string;
  toWeight: string;
  startingCaratPrice: string;
  endingCaratPrice: string;
  startingTotalPrice: string;
  endingTotalPrice: string;
  opened: boolean;
  temp: any;
  loadData: boolean;
  message: string;
}

var filteredParcelValues: any = "";

export default class ParcelItemFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      shapes: [],
      parcelTypeArr: [],
      colors: [],
      fromWeight: "",
      toWeight: "",
      startingCaratPrice: "",
      endingCaratPrice: "",
      startingTotalPrice: "",
      endingTotalPrice: "",
      opened: false,
      temp: [],
      loadData: false,
      message: "",
    };
  }

  Auth = new AuthService();
  array: any = [];

  componentDidMount() {
    filteredParcelValues = localStorage.getItem("filteredParcelValues");
    filteredParcelValues = JSON.parse(filteredParcelValues);
    if (filteredParcelValues === null) {
      ParcelTypeShapesList.map((shape: any, index: number) => {
        ParcelTypeShapesList[index].selectedValue = false;
        return this.setState({
          temp: null,
        });
      });
      ParcelTypeColorsList.map((color: any, index: number) => {
        return (ParcelTypeColorsList[index].selectedValue = false);
      });
    } else {
      filteredParcelValues.map((item: any) => {
        if (
          item.name === "caratMinimumPrice" ||
          item.name === "caratMinimumPrice,subItems.caratMinimumPrice"
        ) {
          if (item.gte !== undefined) {
            this.setState({ startingCaratPrice: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ endingCaratPrice: item.lte });
          }
        }
        if (
          item.name === "startingPrice" ||
          item.name === "startingPrice,subItems.startingPrice"
        ) {
          if (item.gte !== undefined) {
            this.setState({ startingTotalPrice: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ endingTotalPrice: item.lte });
          }
        }
        if (item.name === "carat" || item.name === "carat,subItems.carat") {
          if (item.gte !== undefined) {
            this.setState({ fromWeight: item.gte });
          }
          if (item.lte !== undefined) {
            this.setState({ toWeight: item.lte });
          }
        }
      });
    }
  }

  shapeName(shape: any, index: number) {
    ParcelTypeShapesList[index].selectedValue = !shape.selectedValue;
    this.setState({
      temp: null,
    });
  }

  selectedColor = (color: any, index: number) => {
    ParcelTypeColorsList[index].selectedValue = !color.selectedValue;
    this.setState({
      temp: null,
    });
  };

  handleChange = (e: any) => {
    const targeName = e.target.name;
    const targetValue = e.target.value;
    if (targeName === "fromWeight") {
      this.setState({ fromWeight: targetValue });
    }
    if (targeName === "toWeight") {
      this.setState({ toWeight: targetValue });
    }
    if (targeName === "fromCarat") {
      this.setState({ startingCaratPrice: targetValue });
    }
    if (targeName === "toCarat") {
      this.setState({ endingCaratPrice: targetValue });
    }
    if (targeName === "fromPrice") {
      this.setState({ startingTotalPrice: targetValue });
    }
    if (targeName === "toPrice") {
      this.setState({ endingTotalPrice: targetValue });
    }
  };

  handleSubmit(e: any) {
    e.preventDefault();
    this.setState({
      loadData: true,
      message: "",
    });
    let fromWeight = parseFloat(this.state.fromWeight);
    fromWeight = fromWeight - 0.0001;
    let toWeight = parseFloat(this.state.toWeight);
    toWeight = toWeight + 0.0001;

    let startingCaratPrice = parseFloat(this.state.startingCaratPrice);
    let endingCaratPrice = parseFloat(this.state.endingCaratPrice);
    let startingTotalPrice = parseFloat(this.state.startingTotalPrice);
    let endingTotalPrice = parseFloat(this.state.endingTotalPrice);
    let localArray: any = [];

    let shapes: any = ParcelTypeShapesList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => item.value);

    let colors: any = ParcelTypeColorsList.filter((item: any) => {
      return item.selectedValue === true;
    }).map((item: any) => {
      if (item.name === "UNDER N") {
        item.name = "UNDER N";
      }
      if (item.name === "UNDER S") {
        item.name = "UNDER S";
      }
      return item.name.toUpperCase();
    });
    ParcelTypeColorsList.map((item: any) => {
      if (item.name === "N") {
        item.name = "UNDER N";
      }
      if (item.name === "S") {
        item.name = "UNDER S";
      }
      return item.name.toUpperCase();
    });

    if (shapes.length > 0) {
      localArray.push({
        match: "in",
        name:
          this.props.annualAuction !== undefined
            ? "multiShape,subItems.multiShape"
            : "multiShape",
        value: shapes,
      });
    }
    if (colors.length > 0) {
      localArray.push({
        match: "in",
        name:
          this.props.annualAuction !== undefined
            ? "multiColor,subItems.multiColor"
            : "multiColor",
        value: colors,
      });
    }
    if (this.state.fromWeight !== "" && this.state.toWeight === "") {
      this.setState({
        message: `Please enter to weight`,
      });
    }
    if (this.state.toWeight !== "" && this.state.fromWeight === "") {
      this.setState({
        message: `Please enter from weight`,
      });
    }
    if (this.state.toWeight !== "" && this.state.fromWeight !== "") {
      if (parseFloat(this.state.toWeight) > parseFloat(this.state.fromWeight)) {
        localArray.push({
          match: "between",
          name: "carat",
          gte: fromWeight,
          lte: toWeight,
        });
      } else if (
        parseFloat(this.state.fromWeight) > parseFloat(this.state.toWeight)
      ) {
        this.setState({
          message: `"To" weight should be greater than "From" weight`,
        });
      }
    }
    if (
      this.state.startingCaratPrice !== "" &&
      this.state.endingCaratPrice === ""
    ) {
      this.setState({
        message: `Please enter to carat`,
      });
    }
    if (
      this.state.endingCaratPrice !== "" &&
      this.state.startingCaratPrice === ""
    ) {
      this.setState({
        message: `Please enter from carat`,
      });
    }
    if (
      this.state.endingCaratPrice !== "" &&
      this.state.startingCaratPrice !== ""
    ) {
      if (
        parseInt(this.state.endingCaratPrice) >
        parseInt(this.state.startingCaratPrice)
      ) {
        localArray.push({
          match: "between",
          name:
            this.props.annualAuction !== undefined
              ? "caratMinimumPrice,subItems.caratMinimumPrice"
              : "caratMinimumPrice",
          gte: startingCaratPrice,
          lte: endingCaratPrice,
        });
      } else if (
        parseInt(this.state.endingCaratPrice) <
        parseInt(this.state.startingCaratPrice)
      ) {
        this.setState({
          message: `"To" carat should be greater than "From" carat`,
        });
      }
    }
    if (
      this.state.startingTotalPrice !== "" &&
      this.state.endingTotalPrice === ""
    ) {
      this.setState({
        message: `Please enter to Price`,
      });
    }
    if (
      this.state.endingTotalPrice !== "" &&
      this.state.startingTotalPrice === ""
    ) {
      this.setState({
        message: `Please enter from Price`,
      });
    }
    if (
      this.state.endingTotalPrice !== "" &&
      this.state.startingTotalPrice !== ""
    ) {
      if (
        parseInt(this.state.endingTotalPrice) >
        parseInt(this.state.startingTotalPrice)
      ) {
        localArray.push({
          match: "between",
          name:
            this.props.annualAuction !== undefined
              ? "startingPrice,subItems.startingPrice"
              : "startingPrice",
          gte: startingTotalPrice,
          lte: endingTotalPrice,
        });
      } else if (
        parseInt(this.state.endingTotalPrice) <
        parseInt(this.state.startingTotalPrice)
      ) {
        this.setState({
          message: `"To" price should be greater than "From" price`,
        });
      }
    }
    let values = localArray;
    localArray.map((item: any, index: number) => {
      if (item.name === "auctionId") {
        localArray.splice(index, 1);
      }
    });
    let andArr = [];
    andArr.push({
      match: "exact",
      name: "parcelType",
      value: "Parcel",
    });
    const data = {
      filters: {
        or: localArray,
        and: andArr,
      },
      page: {
        start: 0,
        count: 20,
        sort: {
          orders: [
            {
              property:
                this.props.annualAuction === undefined
                  ? "serialNo"
                  : "productEndDate",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.isPreviewPage &&
      (this.props.auctionId === null || filteredParcelValues === null)
    ) {
      if (localArray.length > 0) {
        if (!oAuth()) {
          this.props.history.push("/");
        } else {
          this.Auth.postRequest(
            searchAuctionFilterUrl,
            data,
            (status: any, response: any) => {
              if (status === 200) {
                this.props.filteredParcelAuctions(response, true, values);
                this.setState({
                  loadData: false,
                });
              } else {
                this.setState({
                  loadData: false,
                });
              }
            }
          );
        }
      } else {
        this.setState({
          loadData: false,
        });
        this.props.filteredParcelAuctions(null, false, null);
      }
    } else {
      this.setState({
        loadData: false,
      });
      this.props.filteredParcelAuctions("", true, values);
    }
  }

  resetValue(e: any) {
    localStorage.removeItem("filteredParcelValues");
    e.preventDefault();
    ParcelTypeColorsList.map((color: any, index: number) => {
      return (ParcelTypeColorsList[index].selectedValue = false);
    });
    ParcelTypeShapesList.map((shape: any, index: number) => {
      return (ParcelTypeShapesList[index].selectedValue = false);
    });

    this.setState({
      loadData: false,
      startingCaratPrice: "",
      endingCaratPrice: "",
      startingTotalPrice: "",
      endingTotalPrice: "",
      fromWeight: "",
      toWeight: "",
      message: "",
    });
    localStorage.removeItem("filteredParcelValues");

    this.props.filteredParcelAuctions(null, false, null);
  }

  render() {
    const { t } = this.props;
    return (
      <StyledGridWrapper>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <StyledFilterGrid container={true} spacing={2}>
            <Grid item={true} xs={12}>
              <FieldSet legend={t("common:preview:filterName:shape")}>
                <StyledHorizontalList>
                  {ParcelTypeShapesList.map((shape, index) => {
                    return (
                      <StyledShapeWrapper
                        key={index}
                        onClick={() => this.shapeName(shape, index)}
                      >
                        <div>
                          {shape.selectedValue ? (
                            <StyledShapeImage
                              src={`/images/shapes/${shape.image.colored}`}
                            />
                          ) : (
                            <StyledShapeImage
                              src={`/images/shapes/${shape.image.grey}`}
                            />
                          )}
                        </div>
                        <StyledShapeName>{shape.name}</StyledShapeName>
                      </StyledShapeWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12}>
              <FieldSet legend={t("common:preview:filterName:color")}>
                <StyledHorizontalList>
                  {ParcelTypeColorsList.map((color: any, index: number) => {
                    return (
                      <StyledWrapper key={index}>
                        <StyledName
                          isSelected={color.selectedValue}
                          onClick={() => this.selectedColor(color, index)}
                        >
                          {color.name}
                        </StyledName>
                      </StyledWrapper>
                    );
                  })}
                </StyledHorizontalList>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12}>
              <StyledMessage>{this.state.message}</StyledMessage>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={8} xl={8}>
              <FieldSet legend={t("common:preview:filterName:price")}>
                <StyledFilterPriceWrapper container={true} spacing={0}>
                  <Grid item={true} xs={6}>
                    <StyledFilterPriceWrapper container={true} spacing={0}>
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel className="fontBold">
                          <span>&#165;</span>
                          {t("common:preview:filters:price:ct")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          max="1000000"
                          step="any"
                          name="fromCarat"
                          id="fromCarat"
                          value={this.state.startingCaratPrice}
                          placeholder={`${t("common:preview:filters:from")}`}
                          onChange={this.handleChange.bind(this)}
                        />
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          max="1000000"
                          step="any"
                          name="toCarat"
                          id="toCarat"
                          value={this.state.endingCaratPrice}
                          placeholder={`${t("common:preview:filters:to")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                    </StyledFilterPriceWrapper>
                  </Grid>
                  <Grid item={true} xs={6}>
                    <StyledFilterPriceWrapper container={true} spacing={0}>
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel className="fontBold">
                          {t("common:preview:filters:price:total")}
                        </StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          min="0"
                          max="1000000"
                          type="number"
                          step="any"
                          name="fromPrice"
                          id="fromPrice"
                          value={this.state.startingTotalPrice}
                          placeholder={`${t("common:preview:filters:from")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          min="0"
                          max="1000000"
                          type="number"
                          step="any"
                          name="toPrice"
                          id="toPrice"
                          value={this.state.endingTotalPrice}
                          placeholder={`${t("common:preview:filters:to")}`}
                          onChange={this.handleChange}
                        />
                      </StyledGrid>
                    </StyledFilterPriceWrapper>
                  </Grid>
                </StyledFilterPriceWrapper>
              </FieldSet>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={4} xl={4}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                  <FieldSet legend={t("common:preview:filterName:weight")}>
                    <StyledFilterWeightContainer container={true} spacing={0}>
                      <StyledGrid item={true} xs={12}>
                        <StyledLabel className="fontBold">CT</StyledLabel>
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          step="any"
                          max="1000000"
                          name="fromWeight"
                          id="fromWeight"
                          placeholder={`${t("common:preview:filters:from")}`}
                          onChange={this.handleChange.bind(this)}
                          value={this.state.fromWeight}
                        />
                      </StyledGrid>
                      <StyledGrid item={true} xs={6} md={6}>
                        <StyledValue
                          type="number"
                          min="0"
                          step="any"
                          max="1000000"
                          name="toWeight"
                          id="toWeight"
                          placeholder={`${t("common:preview:filters:to")}`}
                          onChange={this.handleChange.bind(this)}
                          value={this.state.toWeight}
                        />
                      </StyledGrid>
                    </StyledFilterWeightContainer>
                  </FieldSet>
                </Grid>
              </Grid>
            </Grid>
            <StyledButtonContainer item={true} xs={12}>
              <StyledButton onSubmit={this.handleSubmit.bind(this)}>
                {t("common:button:search")}
              </StyledButton>
              <StyledButton onClick={this.resetValue.bind(this)}>
                {t("common:button:reset")}
              </StyledButton>
            </StyledButtonContainer>
          </StyledFilterGrid>
        </form>
        {this.state.loadData === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </StyledGridWrapper>
    );
  }
}
