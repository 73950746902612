import React from "react";
import styled from "styled-components";

import { Button, ButtonBase } from "@material-ui/core";
import Colors from "../materials/colors";

const StyleBaseButton = styled(ButtonBase) <{ fill?: boolean }>`
  border-radius: 50%;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: ${Colors.darkBlue};
    svg * {
      fill: ${(props) => (props.fill ? "white" : "default")};
    }
  }
`;

const StyledButton = styled(Button) <{ elem: string }>`
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  white-space: pre;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: white;
    color: ${(props) =>
    props.elem === "contained"
      ? "#014a99"
      : props.elem === "contained-dark"
        ? props.theme.colors.base.darkBlue
        : props.elem === "contained-black"
          ? props.theme.colors.base.black
          : props.elem === "contained-dark-Red"
            ? "#d92b3c"
            : "white"};

    border-color: ${(props) =>
    props.elem === "contained"
      ? "#014a99"
      : props.elem === "contained-dark"
        ? props.theme.colors.base.darkBlue
        : props.elem === "contained-black"
          ? props.theme.colors.base.black
          : "white"};
  }

  border: 2px solid transparent;
  background-color: ${(props) =>
    props.elem === "contained"
      ? "#014a99"
      : props.elem === "contained-dark"
        ? props.theme.colors.base.darkBlue
        : props.elem === "contained-black"
          ? props.theme.colors.base.black
          : props.elem === "contained-dark-Red"
            ? "#d92b3c"
            : "white"};

  color: ${(props) =>
    props.variant === "contained"
      ? "white"
      : props.theme.colors.base.fieldSetBlueColor};
  border-radius: 0;
`;

const ButtonComp = ({
  type,
  children,
  className,
  ...props
}: {
  disabled?: boolean;
  type: any;
  wide?: boolean;
  widePadding?: boolean;
  className?: string;
  children: React.ReactNode;
  noMargin?: boolean;
  onClick?: (e: any) => any;
  isMobile?: boolean;
}) => {
  const { wide = false, onClick, noMargin, isMobile, disabled } = props;
  let button;

  const widePadding = "0.8em";
  const wideWidth = isMobile ? "65vw" : "15vw";

  let styles = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
  };

  let languageStyles = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
  };

  let styleswhite = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
    color: "grey",
    background: "white",
    height: "fit-content",
  };

  let stylesblack = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
    color: "white",
    background: "black",
    height: "fit-content",
    borderRadius: "5px",
  };

  let stylesred = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
    color: "white",
    background: "#d92b3c",
    height: "fit-content",
    borderRadius: "5px",
  };

  let stylesredRegister = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginLeft: "1em",
    marginRight: "1em",
    marginBottom: isMobile ? "1em" : 0,
    color: "white",
    background: "#d92b3c",
    height: "fit-content",
    borderRadius: "5px",
    width: "100%",
  };
  let stylesRedLogin = {
    minWidth: wide ? wideWidth : "default",
    padding: wide ? widePadding : "default",
    marginTop: 0,
    marginBottom: isMobile ? "1em" : 0,
    color: "white",
    background: "#d92b3c",
    height: "fit-content",
    borderRadius: "5px",
    width: "100%",
  };

  if (noMargin) {
    styles = { ...styles, marginLeft: "0", marginRight: "0" };
  }

  switch (type) {
    case "contained":
      button = (
        <StyledButton
          disabled={disabled}
          elem={type}
          className={className}
          variant="contained"
          onClick={onClick}
          {...props}
          style={styles}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-dark":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          elem={type}
          className={className}
          style={styles}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-dark-Red":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          elem={type}
          className={className}
          style={styles}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-black":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={styles}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-white":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={styleswhite}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-new-black":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={stylesblack}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-red":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={stylesred}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-register-red":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={stylesredRegister}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "contained-login-red":
      button = (
        <StyledButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          elem={type}
          style={stylesRedLogin}
          variant="contained"
          {...props}
        >
          {children}
        </StyledButton>
      );
      break;
    case "icon":
      button = (
        <StyleBaseButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          style={styles}
          fill={true}
          {...props}
        >
          {children}
        </StyleBaseButton>
      );
      break;
    case "languageicon":
      button = (
        <StyleBaseButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          style={languageStyles}
          fill={true}
          {...props}
        >
          {children}
        </StyleBaseButton>
      );
      break;
    default:
      button = (
        <StyleBaseButton
          disabled={disabled}
          onClick={onClick}
          className={className}
          fill={false}
          style={styles}
          {...props}
        >
          {children}
        </StyleBaseButton>
      );
  }

  return button;
};

export default React.memo(ButtonComp);
