import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import { MuiThemeProvider, useTheme } from "@material-ui/core/styles";
import update from "immutability-helper";
import moment from "moment";
import Countdown from "react-countdown";
import { createTheme, IconButton, Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import copy from "copy-to-clipboard";
import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { StyledActionsContainer, StyledBackBtn, StyledBackImageContianer } from "./DiamondWatchlistBiddingCompnent";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import AuthService from "../../components/auth/AuthService";
import { useTranslation } from "react-i18next";
import { getAuctionEndTimeUrl, getBidInfo, getMyLatestBidsForUser, getMyWatchedProduct, productDownload, productUrl, tendorBidUrl } from "../../common/config/app.endpoints";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import { openJewelleryCertificateLinkPopup } from "../../common/GenericFunctions";
import EllipsisText from "../../common/elements/EllipsisText";
import { convertedValue, getMyLatestStoredBids, getReturnMyLatestStoredBid } from "../../components/products/preview/TenderGenericFunc";
import { StyledButton, StyledModal } from "../../common/materials/GenericStyleComponent";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import ViewEndedModal from "../../components/products/auction/ViewEndedModal";
import BlockUserModal from "../../components/products/BlockUser/BlockUserModal";
import Alert from "../../common/elements/Alert";
import { StyledHeaderContainer } from "../../components/products/preview/TendorAuctionView";
import ActionTenderPagesize from "../../components/core-grid/grid-actions/ActionTenderPagesize";
import { StyledButtonView, Styledview } from "../../components/products/preview/TendorPreviewView";
import ViewTenderExtendedModal from "../../components/products/auction/ViewTenderExtendedModal";
import CoreDataGridOfTenderJwl from "../../components/products/preview/tenderMobileComponets/CoreDataGridOfTenderJwl";
import JwlTenderSlider from "../../components/products/preview/bidPopupComponent/JwlTenderSlider";
import JwlBulkBidPopup from "../../components/products/preview/bidPopupComponent/JwlBulkBidPopup";
import { StyledTextWrapper } from "../../components/products/preview/JewelleryTendorPreviewView";
import { match } from "react-router";
import PubSub from 'pubsub-js';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { resetJewelleryCurrentApiStartCount, resetJewelleryItemPerPage, selectJewelleryCurrentApiStartCount, selectJewelleryItemPerPage, setJewelleryCurrentApiStartCount, setJewelleryItemPerPage } from "../../redux/slice/commanPagination/commanPaginationSlice";
import { AppDispatch } from "../../redux/store";
import { resetIsGridView, resetTenderGridProductWatchlistIndexes, resetTenderjewelleryWatchlistSelectAll, selectGridView, setIsGridView, setTenderGridProductWatchlistIndexes, setTenderjewelleryWatchlistSelectAll } from "../../redux/slice/annual/commanAnnualSlice";
import { StyledSelectAllButtonHeader } from "../../components/365Auction/component/DiamondProductStyle";
import TenderListOfProductwatchlist from "../../components/products/preview/tenderMobileComponets/TenderListOfProductwatchlist";
import AlertReadOnlyUser from "../../common/elements/AlertReadOnlyUser";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;

const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;



const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  .MuiPaper-root {
    width: 100%;
    border: 1px solid lightgray;
  }
  .MuiTableCell-root {
    padding: 7px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: #014a99;
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    // position: relative;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;
const StyledBtnContainer = styled.div`
  text-align: center;
`;

const StyledCoreDataGridContainer = styled(Grid)``;
const StyledActionBarContainer = styled(Grid)`
align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

export const StyledButtonGray = styled.button`
  color: ${(props) => props.theme.colors.base.black};
  border: none;
  font-size: 0.8em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.gray};
  border: 1px solid ${(props) => props.theme.colors.base.white};
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;


const StyledTimeGridContainer = styled(Grid)`
  padding: 10px;
  justify-content: flex-end;
`;

const StyledTimer = styled.div`
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: #014a99;

  .countdownText {
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
    margin-top: 0.5em;
  }
`;

const StyledNameContent = styled(Grid)`
  text-align: end;
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledName = styled(Grid)`
  dispaly: flex;
  padding: 10px;
  @media (min-width: 1024px) {
    text-align: end;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    // margin-top: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface DetailParams {
  auctionId: string;
  auctionUserId: any;
  auctionDate: any;
}
export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  dataObject: any;
  data: any;
  removeFromWatchlist: any;
  handleBackBtns: any;
  auctionState: any;
}
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 10;
let rowSelectedData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let seconds: any = "0";

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let body: any = [];
let auctionName: string = "";

let details: any;
let SelectedTableData: any;
let length: any;
let timeLeftLableColor: boolean= false;
let auctionsState: any;
const columnIndexes = {
  serialNo: 0,
  productId: 1,
  auctionUserId: 2,
  productCode: 4,
  lab: 5,
  certificate: 6,
  description: 7,
  currency: 8,
  remarks: 9,
  notes: 10,
  peicePerCt: 11,
  myLatestBids: 12,
  currentBidPrice: 13,
  noOfBids: 14,
  rowColor: 15,
  exchangeRate: 16,
  noOfBidders: 19,
  currentBidValue: 17,
  hieghestBidValue: 18,
  watched: 20,
  sellerAuctionUserId: 21,
  previousHighestBidder: 23,
  holdPreviousBidder: 24,
};
let date: string | string[] = "";
let auctionDate: any;
let auctionStateforPopup: string;
let auctionTenderExtended: boolean = false;
let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];
let isReadOnlyUser: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
let isAuctionUserId: boolean;
const JewelleryWatchlistBiddingComponet = (props: IProductViewProps) => {
  let { dataObject, handleBackBtns, auctionState } = props;
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });

  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openEndedModal, setOpenEndedModal] = useState(false);
  const [auctionTenderState, setAuctionTenderState] = useState("");
  // const [myLatestBidForProduct, setMyLatestBidForProduct] = useState([]);
  const [showTenderSlider, setshowTenderSlider] = useState(false);
  const [openTenderExtended, setOpenTenderExtended] = useState(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState(false);

  const [isDataRefresh, setIsDataRefresh] = useState(false);
  const [isDataTableRefresh, setIsDataTableRefresh] = useState(false);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [firstTimeExetendPopup, setFirstTimeExetendPopup] =
    useState<boolean>(false);

  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const Auth = new AuthService();
  const { t } = useTranslation();
  const eventSource: any = useRef<any>();
  const maxRetryCount = 12;
  const countRef = useRef(0);
  const jwlItemPerPageRef = useRef(10);
  const selectJwlPageUseRef = useRef(0);
  const [shouldGetAllData, setShouldGetAllData] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const isGridView: any = useSelector((state: RootState) =>
    selectGridView(state)
  );

  useEffect(() => {
    setIsLoading(true);
    dispatch(resetTenderGridProductWatchlistIndexes());
    dispatch(resetTenderjewelleryWatchlistSelectAll());
    //data table api calling    
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    localStorage.removeItem("currentPageOfTenderAllProductsdiam");
    localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("auctionUserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("currentStart");
    getAuctionEndTime(props.match.params.auctionId);
    //note: we are removing jwlcurrentPageOfTenderAllProductsWatchlist from localstorage since we already loaded that page in "getTendorAucProducts()"
    localStorage.removeItem("jwlcurrentPageOfTenderAllProductsWatchlist");
    getMyLatestBids(props.match.params.auctionId);
    getAllUpdatedData();
    // refresh bid value
    dispatch(resetJewelleryItemPerPage());
    dispatch(resetJewelleryCurrentApiStartCount());
    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current.close !== undefined) {
          eventSource.current.close();
        }
      }
      auctionName = "";
    };
  }, []);
  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectJewelleryCurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectJewelleryItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "jwlapiStartCount",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    getTendorAucProducts(
      props.match.params.auctionId,
      selectcurrentApiStartCountValue
    );
  }, [selectcurrentApiStartCountValue, selectItemPerPageValue]);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };


  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(getBidInfo);
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData.alive) {
          //This will listen only for events
        } else {
          if (props.match.params.auctionId === updatedRowData.auctionId) {
            updateRowIndex = auctionProductResult.findIndex(
              (product: any) => product.perId === updatedRowData.perId
            );
            const mapping: any = {};
            if (
              auctionProductResult !== undefined &&
              auctionProductResult.length > 0
            ) {
              auctionProductResult.map((items: any) => {
                mapping[items.perId] = {
                  watched: items.watched,
                };
              });
            }

            var updatedArr = update(auctionProductResult, {
              $splice: [[updateRowIndex, 1, updatedRowData]],
            });

            const arr = [...updatedArr];
            // auctionProductResult = updatedArr;
            arr.map((items: any) => {
              if (mapping[items.perId] !== undefined) {
                items.watched = mapping[items.perId].watched;
              }
            });

            auctionProductResult = arr;
            setAuctionProducts(auctionProductResult);
            setShouldGetAllData(false);
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const getAuctionEndTime = (auctionId: string, callBack?: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getAuctionEndTimeUrl}/${auctionId}/end_time`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            auctionsState = response.state;
            setFirstTimeExetendPopup(response.tenderExtended);
            getLeftTime(response.endDate);
            setAuctionTenderState(response.state);
            timeLeftLableColor = response.tenderExtended;
            // if (callBack !== undefined) {
            //   callBack(response);
            // }
            if (response.state === "Ended") {
              setOpenEndedModal(true);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //Note: Get my All LatestBid using this Api
  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            // setMyLatestBidForProduct(response);
            myLatestBidForProduct = response;
            onGridSelectionChange(0);
            PubSub.publish("resetGridBoxIndex", []);
            PubSub.publish("jwlTenderSelectAllCheckBoxWatchlist", false);
            setIsSelected(false);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const getTendorAucProducts = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    if (filteredValues !== undefined) {
      if (filteredValues === null || filteredValues.length === 0) {
        filter = null;
      } else {
        filteredValues.map((item: any, index: number) => {
          if (item.name === "auctionId") {
            filteredValues.splice(index, 1);
          }
        });
        filter = filteredValues;
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }

    getBody(auctionId, currentPage, endCount, isLoadPreSelectedPage);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    localStorage.setItem(
      "jwlcurrentPageOfTenderAllProductsWatchlist",
      currentPage.toString()
    );

    if (localStorage.getItem("currentPdPage") !== null) {
      let pageCount: any = localStorage.getItem("currentPdPage");
      // let start = (parseInt(pageCount) - 1) * endCount;
      let start = parseInt(pageCount) - 1;
      if (start <= -1) {
        page = currentPage;
      } else {
        page = start;
      }
      localStorage.removeItem("currentPdPage");
    } else {
      page = currentPage;
    }

    startCount = page * endCount;

    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    // getCurenntPerId = localStorage.getItem("currentPDItem");

    //handle drop downfilter value
    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }
    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      filter === null &&
      (filteredValues == null ||
        filteredValues === undefined ||
        filteredValues === "null" ||
        filteredValues.length === 0)
    ) {
      payload = {
        filters: {
          and: [],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      body = filteredValues ? filteredValues : filter;
      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });
      payload = {
        filters: {
          or: body,
          and: [],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    }

    let val = localStorage.getItem("optionValue");

    if (val !== "" && val !== "null" && val != null) {

      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
          and: [],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(payload);
  };

  const callApi = (payload: any) => {
    setIsTableExpanded(false);
    setIsLoading(true);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${getMyWatchedProduct}/${props.match.params.auctionId}/watchlist`,
        payload,
        (status: any, response: any) => {
          // setIsLoading(false);
          if (status === 200) {
            if (startCount !== 0 &&
              response.results.length === 0) {
              dispatch(resetJewelleryCurrentApiStartCount());
              selectJwlPageUseRef.current = 0;
              localStorage.setItem("currentStart", '0');
            } else {
              setAuctionProducts(response.results);
              totalElement = response.totalElements;
              setIsLoading(false);
              auctionName = response?.results[0]?.auctionName;
              auctionState = response?.results[0]?.auctionState;
              auctionProductResult = response.results;
              if (response.results.length > 0) {
                auctionDate = response?.results[0]?.auctionStartDate;
                date = auctionDate.split(" ", 2);
              }
              setAuctionProducts(response.results);
              setTimeout(() => {
                setIsLoading(false);
                setIsDataTableRefresh(!isDataTableRefresh);
              }, 3000);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const handleBidClick = (tableData: any) => {
    rowSelectedData = tableData.rowData;
    rowSelectedData = tableData.rowData;
    SelectedTableData = tableData.tableData;
    getProductsDetails(auctionProducts);
    details = tableData.rowIndex;
    setshowTenderSlider(true);
  };

  //rediract from bid now button of Jewellery product details page -------

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentJewelleryProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data;
      SelectedTableData = auctionProducts;
      details = indexOfProduct;
      if (details !== -1) {
        setshowTenderSlider(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  const getProductsDetails = (product: any) => {
    length = product.length;
    product.map((items: any, index: any) => {
      if (items.perId === rowSelectedData[columnIndexes.productId]) {
        details = index;
      }
    });
    return details;
  };

  //index of Mobile ui component
  const getBoxIndex = (boxIndex: any) => {
    length = auctionProducts.length;
    details = boxIndex;
    setshowTenderSlider(true);
  };
  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("jwlauctionUserId", props.match.params.auctionUserId);
    props.history.push(
      `/preview/tender/jewellery/details/${auctionId}/${perId}/${date[0]}?isAuction=false&uniqid=${props.match.params.auctionUserId}`
    );
    dispatch(resetJewelleryItemPerPage());
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("jwlauctionUserId", props.match.params.auctionUserId);
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    window.open(
      `/${languageCode}/preview/tender/jewellery/details/${auctionId}/${perId}/${date[0]}?isAuction=false&uniqid=${props.match.params.auctionUserId}`,
      "_blank"
    );
    dispatch(resetJewelleryItemPerPage());
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    if (isTableExpanded) {
      heightOfInnerTable = h - 370;
    } else {
      heightOfInnerTable = h - 250;
    }
    if (auctionProducts.length > 2) {
      return `${heightOfInnerTable}px`;
    } else {
      return `auto`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,

    isRowSelectable: (dataIndex: any, selectedRows: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    onChangePage: (currentPage: number) => {
      setIsLoading(true);
      localStorage.setItem(
        "currentStart",
        JSON.stringify(currentPage * jwlItemPerPageRef.current)
      );
      dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
    },
    onSearchChange: (searchTxt: any) => {
      //
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (row[columnIndexes.previousHighestBidder] === undefined) {
        if (row[columnIndexes.holdPreviousBidder] === undefined) {
          if (
            row[columnIndexes.auctionUserId] !==
            props.match.params.auctionUserId
          ) {
            return { style: { background: "white" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        } else {
          if (
            props.match.params.auctionUserId ===
            row[columnIndexes.holdPreviousBidder]
          ) {
            return { style: { background: "#ffeb9c" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        }
      } else {
        if (
          props.match.params.auctionUserId ===
          row[columnIndexes.previousHighestBidder]
        ) {
          return { style: { background: "#FBE4D5" } };
        } else {
          return { style: { background: row[columnIndexes.rowColor] } };
        }
      }
    },
    onColumnSortChange: (price: any, direction: any) => {
      let order = "";
      if (isSort === false) {
        order = "desc";
      } else {
        order = "asc";
      }
      setIsSort(!isSort);
      sort(price, order);
      // LiveBidSorter(price, order);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      indexsForBulkBidItems = rowsSelected;
      setOpenToast(false);
      indexsForBulkBidItems.map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = auctionProducts[indexs];
        if (sellerAuctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (props.match.params.auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (auctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length >= 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const { auctionUserId, rowColor, previousHighestBidder, holdPreviousBidder } = items;
    if (previousHighestBidder === undefined) {
      if (holdPreviousBidder === undefined) {
        if (auctionUserId !== props.match.params.auctionUserId) {
          return "white";
        } else {
          return rowColor;
        }
      } else {
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return "#ffeb9c";
        } else {
          return rowColor;
        }
      }
    } else {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return "#FBE4D5";
      } else {
        return rowColor;
      }
    }
  };

  const getDisableRowIndex = () => {
    return auctionProducts.map((items: any, index: number) => {
      if (auctionState !== "NotStarted") {
        if (isReadOnlyUser) {
          return index;
        } else {
          if (items.sellerAuctionUserId === props.match.params.auctionUserId) {
            return index;
          } else if (items.previousHighestBidder !== undefined) {
            if (
              props.match.params.auctionUserId === items.previousHighestBidder
            ) {
              return index;
            }
          } else if (items.holdPreviousBidder !== undefined) {
            if (props.match.params.auctionUserId === items.holdPreviousBidder) {
              return index;
            }
          } else if (items.auctionUserId === props.match.params.auctionUserId) {
            return index;
          }
        }
      } else {
        return index;
      }
    });
  };

  //server side sorting
  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (isSort === false) {
      order = "desc";
    } else {
      order = "asc";
    }
    setIsSort(!isSort);
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  //sorting
  const sortData = (values: any, direction: any) => {
    let tempArr: any = [{ property: values, direction: direction }];

    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }


    if (selectcurrentApiStartCountValue === 0) {
      getTendorAucProducts(props.match.params.auctionId, 0);
    } else {
      dispatch(resetJewelleryCurrentApiStartCount());
    }
    selectJwlPageUseRef.current = 0;
    localStorage.setItem("currentStart", '0');
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: true,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "70px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon icon={faEditProps} style={{ color: "#014a99" }} />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                  props.match.params.auctionUserId ? (
                  <>
                    {getWatchedProduct(
                      tableMeta.rowData[columnIndexes.watched],
                      tableMeta.rowData[columnIndexes.productId],
                      props.match.params.auctionId,
                      tableMeta.rowData[1]
                    )}
                  </>
                ) : (
                  <FontAwesomeIcon icon={faHeartProps} style={{ color: "gray" }} />
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(
                    tableMeta.rowData[columnIndexes.productId],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:sku")}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, tableMeta.rowData[1])
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            tableMeta.rowData[columnIndexes.lab] !== "AGT" &&
            tableMeta.rowData[columnIndexes.lab] !== "agt" &&
            tableMeta.rowData[columnIndexes.lab] !== "" &&
            tableMeta.rowData[columnIndexes.lab] !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openJewelleryCertificateLinkPopup(
                    tableMeta.rowData[columnIndexes.lab],
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "jewelleryDetails",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <EllipsisText
              text={getDescription(value)}
              length={70}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        display: false,
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={getRemarks(tableMeta.rowData[columnIndexes.remarks])}
              length={30}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:jewelleryPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:tableColumnNames:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      tableMeta.rowData[columnIndexes.productId],
                      tableMeta.rowData[columnIndexes.currency],
                      tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                    )}
                </div>
              ) : (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      tableMeta.rowData[columnIndexes.productId],
                      tableMeta.rowData[columnIndexes.currency],
                      tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                    )}
                </div>
              )}
              {getBidNowButtonDisable(
                tableMeta.rowData[columnIndexes.sellerAuctionUserId],
                props.match.params.auctionUserId,
                tableMeta.rowData[columnIndexes.auctionUserId],
                tableMeta,
                getReturnMyLatestStoredBid(
                  myLatestBidForProduct,
                  tableMeta.rowData[columnIndexes.productId]
                ),
                tableMeta.rowData[columnIndexes.currentBidPrice],
                tableMeta.rowData[columnIndexes.productId],
                tableMeta.rowData[columnIndexes.previousHighestBidder],
                tableMeta.rowData[columnIndexes.holdPreviousBidder]
              )}
            </>
          );
        },
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "noOfBidders",
      label: `${t("common:newCommanTrans:noOfBiddersBids")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(
                tableMeta.rowData[columnIndexes.noOfBids],
                tableMeta.rowData[columnIndexes.noOfBidders]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "myLatestBid",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "tenderSecondHighestBid",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentproductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.myLatestBid;
    });
    // renderAutomaticBidPopup(tableDataForUIBox);
    if (dataObject?.auctionState !== "NotStarted") {
      if (isReadOnlyUser) {
        return (
          <StyledBtnContainer onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        if (sellerId === auctionUserIdForProduct) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else if (holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        } else if (previousBidderId !== undefined) {
          if (props.match.params.auctionUserId === previousBidderId) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        } else if (tableAuctionUserId === props.match.params.auctionUserId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else if (mybidPriceforProduct[0] > currentBidPriceForProduct) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          if (tableAuctionUserId === props.match.params.auctionUserId) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {" "}
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        }
      }
    } else {
      return (
        <StyledBtnContainer>
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    }
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  const shareButtonAction = (perId: any, auctionDate: any) => {
    const { t } = props;
    let languageCode = localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/jewellery/${perId}/${date[0]}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  const getRemarks = (remarks: any) => {
    if (remarks !== "" && remarks !== undefined && remarks !== null) {
      return remarks;
    } else {
      return "";
    }
  };

  const getDescription = (jewelleryDetails: any) => {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }

    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  // Tender bulk bid popup
  const bulkBidPopupHandler = () => {
    setShowBulkBidPopup(true);
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any
  ) => {
    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: props.match.params.auctionUserId,
        auctionId: props.match.params.auctionId,
        productId: productId,
      },
    ];
    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data);
  };

  const bulkBidHandler = (values: any[], myBidBulkValues: any) => {
    myLatestBidForProduct = myBidBulkValues;
    bidProduct(values);
    onGridSelectionChange(0);
    dispatch(resetTenderGridProductWatchlistIndexes());
    dispatch(resetTenderjewelleryWatchlistSelectAll());
    PubSub.publish("resetGridBoxIndex", []);
  };

  const bidProduct = (payloadBid: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        tendorBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            setIsDataRefresh(!isDataRefresh);
            getMyLatestBids(props.match.params.auctionId);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const exportExcel = () => {
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${props.match.params.auctionId}&auctionType=Jewellery&auctionProductsType=watched`,
      { ids: '' },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      }
    );
  }

  const getLeftTime = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let hour: any = "";
    let minutes: any = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          //let start: any = moment(dateTime);
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          //let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          //var timeDiff = start - end;
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    setTimeout(() => {
      getLeftTime(startDate);
    }, 1000);
  };

  //get time
  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getTimePopup();
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const getTimePopup = () => {
     if (auctionsState === "Started" && firstTimeExetendPopup === false) {
       setOpenTenderExtended(true);
        timeLeftLableColor = true;
     }
    setTimeout(() => {
      getAuctionEndTime(props.match.params.auctionId);
    }, 2600);
  };

  const TenderauctionEndedModal = () => {
    props.history.push("/auction");
    dispatch(resetJewelleryItemPerPage());
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  const TenderTimeExtended = () => {
    getAllUpdatedData();
    getTendorAucProducts(props.match.params.auctionId, 0);
    setOpenTenderExtended(false);
  };

  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("jwlcurrentPageOfTenderAllProductsWatchlist", "0");
    setCurrentPage(1);
    setItemsPerPage(itemsPerPage);
    jwlItemPerPageRef.current = itemsPerPage;
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("itemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("currentStart", JSON.stringify(start));
    // let Page: any = localStorage.getItem(
    //   "jwlcurrentPageOfTenderAllProductsWatchlist"
    // );
    selectJwlPageUseRef.current = start;
    // getBody(props.match.params.auctionId, parseInt(Page), endCount);
    let currentPage = start / itemsPerPage;
    PubSub.publish("resetGridBoxIndex", []);
    onGridSelectionChange(0);
    dispatch(resetTenderGridProductWatchlistIndexes());
    dispatch(resetTenderjewelleryWatchlistSelectAll());
    dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
  };

  const closebidPopup = () => {
    setshowTenderSlider(false);
    setShowBulkBidPopup(false);
    PubSub.publish("resetGridBoxIndex", []);
  };

  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  let isAuctionPage: any = true;
  let isCategoryPageType: any = false;

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  //get Watched Product
  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    return (
      <FontAwesomeIcon
        icon={faHeartProps}
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => {
          removeProductFromList(productId, auctionId);
        }}
      />
    );
  };
  const addToWatchList = (
    perId: any,
    auctionId: any,
    serialNo: any
  ) => {
    // setDisableButton(true);
    // props.removeFromWatchlist(perId, auctionId, serialNo);
  };

  const removeProductFromList = (perId: any, auctionId: any) => {
    setOnAction(true);
    setOpenToast(false);
    setIsLoading(true);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${perId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            setshowTenderSlider(false);
            getTendorAucProducts(props.match.params.auctionId, selectJwlPageUseRef.current);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const listGridview = () => {
    selectJwlPageUseRef.current = startCount;
    dispatch(setIsGridView({ gridView: true }));
  };

  const Gridview = () => {
    dispatch(resetIsGridView());
  };

  const selectAllInGridView = () => {
    //calling select all function in grid View     
    let arr: any = [];
    auctionProducts.map((productVal: any, index: any) => {
      if (arr.length < 10) {
        if (isReadOnlyUser) {
          //
        } else {
          if (productVal.sellerAuctionUserId === props.match.params.auctionUserId) {
            //
          } else if (productVal.holdPreviousBidder !== undefined) {
            if (props.match.params.auctionUserId === productVal.holdPreviousBidder) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.previousBidderId !== undefined) {
            if (props.match.params.auctionUserId === productVal.previousBidderId) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.auctionUserId === props.match.params.auctionUserId) {
            //
          } else {
            if (productVal.auctionUserId === props.match.params.auctionUserId) {
              //
            } else {
              arr = [...arr, index];
            }
          }
        }
      }
    })

    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetAnnualGridBoxIndex", []);
      arr = [];
      dispatch(resetTenderGridProductWatchlistIndexes());
      dispatch(resetTenderjewelleryWatchlistSelectAll());
    } else {
      setIsSelected(true);
      dispatch(setTenderGridProductWatchlistIndexes({ indexes: arr }));
      dispatch(setTenderjewelleryWatchlistSelectAll({ isSelected: true }));
    }
  }

  return (
    <Grid>
      {isLoading === true ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <StyledLayoutGridMain>
          {isTopElementHide ? null : (
            <Accordion
              onChange={handleChangeAcco("panel1")}
              style={{
                position: "fixed",
                zIndex: 1,
                right: "0",
                marginTop: "6px",
                animation: "fadeMe 1s",
              }}
              className="AccordionHeader"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                }
              ></AccordionSummary>
              <div style={{ width: "100%", display: "block" }}>
                <StyledHeaderContainer container={true} spacing={0}>
                  <StyledCoreDataGridContainer container={true} spacing={0}>
                    <StyledActionBarContainer
                      container={true}
                      spacing={0}
                      className="coreActionBarContainer"
                    >
                      <Grid container={true} xs={12} lg={3} xl={2}>
                        <StyledBackImageContianer>
                          <StyledBackBtn
                            src="/images/controls/left_arrow_round_edges.png"
                            onClick={handleBackBtns}
                          />
                        </StyledBackImageContianer>
                      </Grid>
                      <StyledActionsContainer
                        item={true}
                        xs={12}
                        lg={9}
                        xl={10}
                      >
                        {auctionState === "NotStarted" ? (
                          ""
                        ) : (
                          <>
                            {notSmallDevices ? (
                              <>
                                {isGridView === true ? (
                                  <StyledSelectAllButtonHeader
                                    onClick={selectAllInGridView}
                                  >
                                    {t("common:newCommanTrans:selectAll")}
                                  </StyledSelectAllButtonHeader>
                                ) : null}
                              </>
                            ) : (
                              <StyledSelectAllButtonHeader
                                onClick={selectAllInGridView}
                              >
                                {t("common:newCommanTrans:selectAll")}
                              </StyledSelectAllButtonHeader>
                            )}
                          </>
                        )}
                        {showBulkBidButton === true ? (
                          <StyledButtonHeader onClick={bulkBidPopupHandler}>
                            {t("common:preview:tendor:bulkBid")}
                          </StyledButtonHeader>
                        ) : (
                          <></>
                        )}
                        <StyledButtonHeader onClick={exportExcel}>
                          {t("common:preview:pagination:export")}
                        </StyledButtonHeader>
                        <ActionTenderPagesize
                          setItemsPerPageHandler={setItemsPerPageHandler}
                          t={t}
                        />
                        {notSmallDevices ? (
                          <>
                            {isGridView === true ? (
                              <StyledButtonView onClick={Gridview}>
                                <Styledview src="/images/view/listView.png" />
                              </StyledButtonView>
                            ) : (
                              <StyledButtonView onClick={listGridview}>
                                <Styledview src="/images/view/gridview.jpeg" />
                              </StyledButtonView>
                            )}
                          </>
                        ) : null}
                      </StyledActionsContainer>
                    </StyledActionBarContainer>
                  </StyledCoreDataGridContainer>
                </StyledHeaderContainer>
              </div>
            </Accordion>
          )}
          <StyledLayoutGrid container={true}>
            <>
              <Grid
                item={true}
                xs={12}
                style={{ paddingTop: isTableExpanded ? "160px" : "20px" }}
              >
                <Grid
                  container={true}
                  spacing={0}
                  className="actionContainer"
                  style={{ padding: "0px 0px 0 8px" }}
                >
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <StyledName>
                      {isDataTableRefresh ? (
                        <StyledNameContent>
                          <EllipsisText
                            text={auctionName}
                            length={28}
                            tail={"..."}
                          />
                        </StyledNameContent>
                      ) : (
                        <StyledNameContent>
                          <EllipsisText
                            text={auctionName}
                            length={28}
                            tail={"..."}
                          />
                        </StyledNameContent>
                      )}
                    </StyledName>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    {auctionState === "Started" ? (
                      <>
                        {seconds >= 0 && (
                          <StyledTimeGridContainer container={true} spacing={0}>
                            {timeLeftLableColor ? (
                              <StyledTimer style={{ color: "red" }}>
                                <span>
                                  {t("common:preview:tendor:timeLeft")}{" "}
                                </span>
                                <Countdown
                                  date={Date.now() + seconds}
                                  className="countdownText"
                                  renderer={renderer}
                                  zeroPadTime={2}
                                />
                              </StyledTimer>
                            ) : (
                              <StyledTimer>
                                <span>
                                  {t("common:preview:tendor:timeLeft")}{" "}
                                </span>
                                <Countdown
                                  date={Date.now() + seconds}
                                  className="countdownText"
                                  renderer={renderer}
                                  zeroPadTime={2}
                                />
                              </StyledTimer>
                            )}
                          </StyledTimeGridContainer>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {isGridView === false && notSmallDevices && (
                <MuiThemeProvider theme={getMuiTheme()}>
                  {isDataTableRefresh ? (
                    <MUIDataTable
                      title={""}
                      data={auctionProducts}
                      columns={columns}
                      options={options}
                    />
                  ) : (
                    <MUIDataTable
                      title={""}
                      data={auctionProducts}
                      columns={columns}
                      options={options}
                    />
                  )}
                </MuiThemeProvider>
              )}

              {isGridView === true
                ? notSmallDevices && (
                    <>
                      <CoreDataGridOfTenderJwl
                        t={t}
                        data={auctionProducts}
                        totalElements={totalElement}
                        itemPerPage={itemPerPage}
                        value={getUpdatedProducts}
                        noProductFound={handleMyBids}
                        jwlItemPerPageRef={jwlItemPerPageRef.current}
                        selectJwlPageUseRef={selectJwlPageUseRef.current}
                      >
                        {auctionProducts.length > 0 ? (
                          <TenderListOfProductwatchlist
                            {...props}
                            watchlist={addToWatchList}
                            unWatchlist={removeProductFromList}
                            setShowBidPopupBidModal={getBoxIndex}
                            auctionName={auctionName}
                            auctionDate={date[0]}
                            t={t}
                            isAuctionPage={isAuctionPage}
                            isCategoryPageType={isCategoryPageType}
                            auctionUserId={props.match.params.auctionUserId}
                            boxColor={boxColor}
                            sortingValue={filterSort}
                            bulkBidIndexHandler={onGridSelectionChange}
                            myForProductAllProduct={myLatestBidForProduct}
                            renderAutomaticBidPopupInMobile={
                              renderAutomaticBidPopupInMobile
                            }
                            renderReadOnlyUserToast={renderReadOnlyUserToast}
                            rightClickEvent={rightClickEvent}
                          />
                        ) : (
                          <Grid container={true} spacing={0}>
                            <StyledTextWrapper item xs={12}>
                              {t("common:preview:noProductFound")}
                            </StyledTextWrapper>
                          </Grid>
                        )}
                      </CoreDataGridOfTenderJwl>
                    </>
                  )
                : null}
              {notSmallDevices || (
                <>
                  <CoreDataGridOfTenderJwl
                    t={t}
                    data={auctionProducts}
                    totalElements={totalElement}
                    itemPerPage={itemPerPage}
                    value={getUpdatedProducts}
                    noProductFound={handleMyBids}
                    jwlItemPerPageRef={jwlItemPerPageRef.current}
                    selectJwlPageUseRef={selectJwlPageUseRef.current}
                  >
                    {auctionProducts.length > 0 ? (
                      <TenderListOfProductwatchlist
                        {...props}
                        watchlist={addToWatchList}
                        unWatchlist={removeProductFromList}
                        setShowBidPopupBidModal={getBoxIndex}
                        auctionName={auctionName}
                        auctionDate={date[0]}
                        t={t}
                        isAuctionPage={isAuctionPage}
                        isCategoryPageType={isCategoryPageType}
                        auctionUserId={props.match.params.auctionUserId}
                        sortingValue={filterSort}
                        boxColor={boxColor}
                        bulkBidIndexHandler={onGridSelectionChange}
                        myForProductAllProduct={myLatestBidForProduct}
                        renderAutomaticBidPopupInMobile={
                          renderAutomaticBidPopupInMobile
                        }
                        renderReadOnlyUserToast={renderReadOnlyUserToast}
                        rightClickEvent={rightClickEvent}
                      />
                    ) : (
                      <Grid container={true} spacing={0}>
                        <StyledTextWrapper item xs={12}>
                          {t("common:preview:noProductFound")}
                        </StyledTextWrapper>
                      </Grid>
                    )}
                  </CoreDataGridOfTenderJwl>
                </>
              )}
            </>
            <Menu
              keepMounted
              open={mouseState.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                mouseState.mouseY !== null && mouseState.mouseX !== null
                  ? { top: mouseState.mouseY, left: mouseState.mouseX }
                  : undefined
              }
            >
              <MenuItem
                onClick={() =>
                  openDetailsView(
                    props.match.params.auctionId,
                    currentProductId,
                    props.match.params.auctionDate
                  )
                }
              >
                Details
              </MenuItem>
            </Menu>
            {openToast === true && (
              <Alert
                showAlertMessage={showAlertMessage}
                alertType={alertType}
              />
            )}
            {onAction === true && (
              <div className="loading">
                <LoadingSpinner />
              </div>
            )}

            {userBlock === true && (
              <StyledModal open={userBlock}>
                <BlockUserModal t={t} {...props} closeModal={closeModal} />
              </StyledModal>
            )}

            {openEndedModal === true && (
              <StyledModal
                open={openEndedModal}
                onClose={TenderauctionEndedModal}
              >
                <ViewEndedModal
                  t={t}
                  {...props}
                  closeModal={TenderauctionEndedModal}
                />
              </StyledModal>
            )}

            {openTenderExtended === true && (
              <StyledModal
                open={openTenderExtended}
                onClose={TenderTimeExtended}
              >
                <ViewTenderExtendedModal
                  t={t}
                  {...props}
                  closeModal={TenderTimeExtended}
                />
              </StyledModal>
            )}

            {showTenderSlider === true && (
              <StyledModal open={showTenderSlider} onClose={closebidPopup}>
                <JwlTenderSlider
                  t={t}
                  {...props}
                  items={auctionProducts}
                  currentIndex={details}
                  sec={seconds}
                  timeRender={renderer}
                  PopupClose={closebidPopup}
                  handleBtnValue={handleBtnValue}
                  isFirstButtonDisables={false}
                  auctionDate={date[0]}
                  totalElements={totalElement}
                  endCount={endCount}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  boxColor={boxColor}
                  watchedFunction={addToWatchList}
                  unwatched={removeProductFromList}
                />
              </StyledModal>
            )}
            {showBulkBidPopup === true && (
              <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                <JwlBulkBidPopup
                  {...props}
                  items={auctionProducts}
                  popupClose={closebidPopup}
                  handleBtnValue={bulkBidHandler}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  productIndexs={indexsForBulkBidItems}
                  myLatestBidForProducts={myLatestBidForProduct}
                />
              </StyledModal>
            )}
            {openReadOnlyUserToast === true && (
              <AlertReadOnlyUser
                isReadOnlyUser={isReadOnlyUser}
                isSeller={isAuctionUserId}
              />
            )}
          </StyledLayoutGrid>
        </StyledLayoutGridMain>
      )}
    </Grid>
  );
};

export default JewelleryWatchlistBiddingComponet;
