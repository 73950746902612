import {
  Box,
  Button,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { preferredlanguageUrl } from "../common/config/app.endpoints";
import Alert from "../common/elements/Alert";
import { DialogProvider, useDialog } from "../common/elements/Dialog/Dialog";
import Colors from "../common/materials/colors";
import AuthService from "../components/auth/AuthService";
import { ZipangForgotPassword } from "../pages/LandingPage/Header/Header";
import { joinedAuction, updateStore } from "../pages/LandingPage/Header/SignIn";
import { login, resendOTP, signInOtop } from "../pages/LandingPage/Services";
import { changeApplicationLanguage } from "../utils/utility";
import ChangePlaneRediractionModal from "./login/ChangePlaneRediractionModal";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import {
  StyledLink,
  StyledTimerText,
  StyledGlowstarfooter,
} from "../pages/LandingPage/Header/StyledComponent";
import { StyledModal } from "../common/materials/GenericStyleComponent";
import ZipangAdvModal from "../pages/LandingPage/Dialogs/ZipangAdvModal";
import styled from "styled-components";

const StyledLoginContainer = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding-top: 15%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding-top: 10%;
  }
  padding-top: 20px;
`;


const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    color: Colors.grey,
  },
  containerPadding: {
    padding: "1em",
  },
  zipangContainer: {
    zIndex: 2,
    paddingTop: "0.8em",
  },
  zipangImgContainer: {
    height: "100vh",
    width: "100vw",
    position: "absolute",
    zIndex: 1,
    top: "0",
    left: "0",
    right: "0",
  },
  zipangContainerImg: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  zipangLogoImgContainer: {
    zIndex: 2,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    background: Colors.white,
    border: Colors.grey,
    "&:hover": {
      background: Colors.white,
    },
    "&:blur": {
      background: Colors.white,
    },
    "&:focus": {
      background: Colors.white,
    },
  },
  margin: {
    margin: theme.spacing(1),
    background: "white",
  },
  mobMargin: {
    margin: theme.spacing(1),
    background: "white",
  },
  signInButton: {
    width: "44px",
    height: "40px",
    position: "relative",
  },
  wideButtonContainer: {
    margin: "4px",
  },
  mobWideButtonContainer: {
    margin: "4px",
  },
  wideButton: {
    width: "100%",
    padding: "0.6em",
    borderRadius: "0",
    backgroundColor: Colors.grey,
    color:'white',
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      outline: "none",
    },
  },
  styledMessage: {
    color: Colors.grey,
    margin: "8px",
  },
  link: {
    paddingTop: "0.1em",
    color: Colors.grey,
    margin: "0 0.3em",
    background: "none",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  p: {
    marginBottom: "0rem",
  },
  bottomContainer: {
    paddingTop: "1em",
  },
  logoText: {
    marginTop: "0.8em",
    color: Colors.white,
    fontStyle: "italic",
  },
}));


const HandleChangePlan = ({
  subdomainName,
  email,
}: {
  subdomainName: string;
  email: string;
}) => {
  const [createDialog, closeDialog] = useDialog();
  useEffect(() => {
    createDialog({
      children: (
        <ChangePlaneRediractionModal
          subdomainName={subdomainName}
          updateValue={email}
        />
      ),
      maxWidth: "sm",
      open: true,
    });
  }, []);
  return null;
};

const getEmailFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("un") || urlParams.get("email");
};

const GlowStar = () => {
  const { t } = useTranslation();
  localStorage.setItem("paymentConditionAgree", "false");
  window.document.title = "GlowStar Auctions";
  const isMobile = useMediaQuery("(max-width: 768px)");
  const emailRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const otpRef = useRef<any>(null);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState(getEmailFromUrlParams());

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setEmail(value);
  };

  const changeLanguage = (lng: string) => {
    var lang: any = "";
    if (lng === "en") {
      lang = "en";
      window.localStorage.setItem("language", "EN");
    } else if (lng === "zh_CN") {
      lang = "zh_CN";
      window.localStorage.setItem("language", "zh_CN");
    } else {
      lang = "jp";
      window.localStorage.setItem("language", "日本語");
    }
    var token = localStorage.getItem("id_token");
    if (token !== null) {
      const Auth = new AuthService();
      if (token === null) {
        history.push("/");
      } else {
        Auth.postRequest(
          `${preferredlanguageUrl}/${lang}`,
          "data",
          (status: number, response: any) => {
            changeApplicationLanguage(lng);
          }
        );
      }
    } else {
      changeApplicationLanguage(lng);
    }
    // handleClose();
  };

  const [message, setMessage] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [subdomainName, setSubdomainName] = React.useState("");
  const [forgotPassword, setEnableForgotPassword] = React.useState(false);
  const [showPassword, setShowPassword] = useState<Boolean>(false);
  const [showSignInWithOTP, setShowSignInWithOTP] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [resetEnabled, setResetEnabled] = React.useState(true);
  const [timer, setTimer] = React.useState(30);
  const [createDialog, setCloseDialog] = useState(false);

  const Auth = new AuthService();
  const validateEmail = (email: string) =>
    new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(email);

  const emailValidations = (email: string, password: string) => {
    const validEmail = validateEmail(email);
    if (!validEmail || email === "") {
      return `${t("common:register:errorMessage:newonEmail")}`;
    } else if (email === "" && password === "") {
      return `${t("common:allValidations:emptyFields")}`;
    } else if (password === "") {
      return `${t("common:login:validation:invalidEmailorPassword")}`;
    }
    return "";
  };

  const handleLogin = async () => {
    const username = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const errorMessage = emailValidations(username, password);
    setMessage(errorMessage);
    //temp zipang logo fix
    localStorage.setItem("isGlowstarDomain", "true");
    if (!errorMessage) {
      const { status, response } = await login({ username, password });
      localStorage.setItem("email", username);
      processLoginResponse(status, response);
    }
  };

  const handleLoginWithOTP = async () => {
    const email = emailRef.current?.value;
    const otp = otpRef.current.value;
    console.log({
      username: email,
      password: otp,
      type: "OTP",
    });

    const { status, response } = await signInOtop({
      username: email,
      password: otp,
      type: "OTP",
    });
    if (status === 200) {
      Auth.setToken(response.token);
      localStorage.setItem("otpAuth", "true");
    }
    processLoginResponse(status, response);
  };

  const processLoginResponse = (status: number, response: any) => {
    if (status === 200) {
      updateStore(dispatch);
      // call joined auction function here. add the function to the services method and add callback here.
      joinedAuction(history);
      return;
    }
    if (status > 200 && status < 500) {
      if (response[0].code !== undefined) {
        if (response[0].code === "KB409001") {
          setMessage(
            `${t("common:register:errorMessage:profileVerification")}`
          );
        } else if (response[0].code === "KB412004") {
          setMessage(
            `${t("common:login:validation:newinvalidEmailorPassword")}`
          );
        } else if (response[0].code === "KB401002") {
          setMessage(`${t("common:login:validation:registeredAsAuctioneer")}`);
        } else if (response[0].code === "KB412003") {
          setMessage(
            `${t("common:profile:forgotPassword:validation:newinvalidOTP")}`
          );
        } else if (response[0].code === "KB404001") {
          setMessage(
            `${t("common:login:validation:newinvalidEmailorPassword")}`
          );
        } else if (response[0].code === "KB401004") {
          setMessage(``);
          localStorage.setItem("changePlanEmail", emailRef.current?.value);
          setSubdomainName(response[0].field);
        } else if (response[0].code === "KB401005") {
          setMessage(`You don't have any invitation to access.`);
        } else {
          setMessage(`${t("common:invalidRequest")}`);
        }
      }
    } else if (status === 500) {
      setMessage(response[0].message);
    }
  };

  const handleSendCode = async (email: string) => {
    setIsLoading(true);
    const { status, response } = await resendOTP(email);
    if (status > 200 && status < 500) {
      if (response[0].code === "KB404001") {
        setIsLoading(false);
        setMessage(
          `${t("common:profile:forgotPassword:validation:notRegisteredEmail")}`
        );
        return false;
      }
    }
    setIsLoading(false);
    return true;
  };

  const handleReSendCode = async () => {
    setResetEnabled(false);
    const email = emailRef.current?.value || localStorage.getItem("email");
    if (!email) {
      setMessage(`${t("common:register:errorMessage:onEmail")}`);
      return;
    }
    const success = await handleSendCode(email);
    if (success) {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  };

  useEffect(() => {
    if (!resetEnabled) {
      setTimeout(() => {
        setResetEnabled(true);
      }, 30000);
    }
  }, [resetEnabled]);

  useEffect(() => {
    if (timer === 0) {
      setResetEnabled(true);
    }
    if (!resetEnabled) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [resetEnabled, timer]);

  const handleAdvModal = () => {
    return (
      <StyledModal
        open={createDialog}
        onClose={() => {
          setCloseDialog(false);
        }}
        className="handleAdvModal"
      >
        <ZipangAdvModal
          close={() => {
            setCloseDialog(false);
          }}
        />
      </StyledModal>
    );
  };

  return (
    <DialogProvider>
      <ZipangForgotPassword
        show={forgotPassword}
        onClose={() => setEnableForgotPassword(false)}
        setMessage={setAlertMessage}
      />
      {subdomainName && (
        <HandleChangePlan
          subdomainName={subdomainName}
          email={emailRef.current.value}
        />
      )}
      {alertMessage && <Alert showAlertMessage={alertMessage} alertType={""} />}
      <Grid
        container
        classes={classes}
        className={classes.containerPadding}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          className={classes.zipangImgContainer}
          style={{ color: "white" }}
        >
          {/* <StyledGlowstarBackgroundImg alt="" src={imgSrc} /> */}
        </Grid>
        <Grid
          container
          className={classes.zipangLogoImgContainer}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {isMobile ? (
            <img
              alt=""
              width="280px"
              height="80px"
              src={"/images/sub-domain/glowstar.png"}
            />
          ) : (
            <img
              alt=""
              width="400px"
              height="80px"
              src={"/images/sub-domain/glowstar.png"}
            />
          )}
        </Grid>
        <Grid
          className={classes.zipangContainer}
          container
          justifyContent="center"
          alignItems="center"
        >
          <StyledLoginContainer xs={12} sm={10} lg={4}>
            <Grid>
              <FormControl
                fullWidth
                className={isMobile ? classes.mobMargin : classes.margin}
                variant="filled"
              >
                <TextField
                  InputProps={{ className: classes.input }}
                  type="email"
                  label={t("common:login:labels:email")}
                  inputRef={emailRef}
                  variant="outlined"
                  id="email"
                  value={email}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </FormControl>
              {!showSignInWithOTP ? (
                <FormControl
                  fullWidth
                  className={isMobile ? classes.mobMargin : classes.margin}
                  variant="filled"
                >
                  <TextField
                    InputProps={{
                      className: classes.input,
                      endAdornment: (
                        <>
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                setShowPassword(!showPassword);
                              }}
                              onMouseDown={() => {
                                setShowPassword(!showPassword);
                              }}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    type={showPassword ? "text" : "password"}
                    label={t("common:login:labels:password")}
                    inputRef={passwordRef}
                    variant="outlined"
                    id="password"
                    autoComplete="off"
                  />
                </FormControl>
              ) : (
                <Grid
                  container
                  justifyContent="center"
                  style={{ flexDirection: "column" }}
                >
                  <Typography
                    align="center"
                    style={{ fontSize: "0.8em", marginBottom: "0px" }}
                    paragraph
                  >
                    {t("common:newLandingPage:otpText")}
                  </Typography>
                  <FormControl
                    fullWidth
                    className={isMobile ? classes.mobMargin : classes.margin}
                    variant="filled"
                  >
                    <TextField
                      InputProps={{ className: classes.input }}
                      label={t("common:profile:forgotPassword:labels:otp")}
                      type="text"
                      required
                      inputRef={otpRef}
                      variant="outlined"
                      id="new-password"
                      autoComplete="off"
                    />
                  </FormControl>
                </Grid>
              )}
              <p className={classes.styledMessage}>{message}</p>
            </Grid>{" "}
            {showSignInWithOTP && (
              <Grid
                container
                className={
                  isMobile
                    ? classes.mobWideButtonContainer
                    : classes.wideButtonContainer
                }
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                {resetEnabled && (
                  <StyledLink onClick={handleReSendCode}>
                    {t("common:button:resendOtp")}
                  </StyledLink>
                )}
                {!resetEnabled && (
                  <StyledTimerText>
                    {" "}
                    {t("common:button:resendOtpText")} {timer} &nbsp;
                    {t("common:button:resendOtpSeconds")}
                  </StyledTimerText>
                )}
              </Grid>
            )}
            <Grid
              container
              className={
                isMobile
                  ? classes.mobWideButtonContainer
                  : classes.wideButtonContainer
              }
              style={{ zIndex: 2 }}
            >
              {!showSignInWithOTP ? (
                <Button
                  onClick={handleLogin}
                  variant="contained"
                  className={classes.wideButton}
                  style={{ zIndex: 2 }}
                >
                  {t("common:button:signIn")}
                </Button>
              ) : (
                <Button
                  onClick={handleLoginWithOTP}
                  variant="contained"
                  className={classes.wideButton}
                  style={{ zIndex: 2 }}
                >
                  {t("common:button:signIn")}
                </Button>
              )}
            </Grid>
            <Grid
              container
              classes={classes}
              justifyContent="center"
              alignItems="center"
              style={{ zIndex: 2, marginTop: "10px" }}
            >
              <button
                className={classes.link}
                onClick={() => changeLanguage("en")}
              >
                English
              </button>
              <button
                className={classes.link}
                onClick={() => changeLanguage("jp")}
              >
                日本語
              </button>
              <button
                className={classes.link}
                onClick={() => changeLanguage("zh_CN")}
              >
                中文
              </button>
            </Grid>
          </StyledLoginContainer>

          <StyledGlowstarfooter>
            <Grid
              container
              style={{
                borderTop: "2px solid grey",
                zIndex: 0,
                justifyContent: "center",
              }}
            >
              <Grid xs={12} item>
                <Box
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    zIndex: 1,
                  }}
                >
                  <Typography className="fontLight">
                    CC-7070, CC-7070, Bharat Diamond Bourse Internal Rd, G Block
                  </Typography>
                  <Typography className="fontLight">
                    BKC, Bandra Kurla Complex, Bandra East, Mumbai,
                  </Typography>
                  <Typography className="fontLight">
                    Maharashtra 400051
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </StyledGlowstarfooter>
        </Grid>
      </Grid>
      {createDialog && handleAdvModal()}
    </DialogProvider>
  );
};

export default GlowStar;
