import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import ScrollFix from "../../components/ScrollFix/ScrollFix";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
  justify-content: center;
  width: 100%;
  .headingFontSize {
    font-size: 20px;
  }
  .updatedDate {
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  -webkit-box-shadow: 0px 0px 8px 0px
    ${(props) => props.theme.colors.base.lightGrey};
  -moz-box-shadow: 0px 0px 8px 0px
    ${(props) => props.theme.colors.base.lightGrey};
  box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledHeadingContainer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 30px;
  padding-bottom: 40px;
`;

const StyledStaticHeight = styled.div`
  width: 100%;
  height: 20px;
`;

const StyledText = styled.div`
  font-size: 16px;
`;

const ZipangTermsAndConditionPage = () => {
  return (
    <Grid container={true} spacing={0}>
      <ScrollFix />
      <StyledGrid item={true} xs={12}>
        <StyledWrapper item={true} xs={10} md={10} sm={10} lg={6} xl={6}>
          <Grid container={true} spacing={0}>
            <StyledHeadingContainer>
              <u>Terms & Conditions</u>
            </StyledHeadingContainer>
            <p>
              Zipang Auctions is a platform established solely for the purpose
              of introducing the seller’s goods to the Bidder in the best
              possible way so that Bidders have full transparency and disclosure
              of the condition and specifications of goods offered.
            </p>
            <p>
              ジパングオークション
              は売主様のお品物を可能な限り最良の方法で買主様にご紹介し、お品物の状態、仕様について買主様が完全な透明性をもった情報開示を受けることができるようにするという目的のみのために作られたプラットフォームです。
              Zipang Auctions
              是一个以最佳方式向投标人介绍卖家的商品而建立的平台，以便投标人有最高透明度得知所提供商品的状况和规格。
            </p>
            <p>
              No personal information is shared with any third party online
              advertiser or website or app as part of any such activity. Zipang
              Auctions does not provide any information relating to your usage
              to such website operators or network advertisers.
            </p>
            <p>
              そのような活動の一部として、第三者のオンライン広告主やウェブサイトやアプリと個人情報が共有されることはありません。ジパングオークションは、そのような
              ウェブサイト運営者やネットワーク広告主に対して、あなたの利用に関する情報を提供しません。
              不会与任何第三方如广告商或网站或应用程序共享任何在此活动提供的个人信息。
              Zipang Auctions
              不会向此类网站运营商或网络广告商提供任何与您有关的信息。
            </p>

            <p>
              Zipang Auctions serves solely as a B2B auction portal to enable
              sellers from Japan to auction their products to the best of their
              ability.
            </p>
            <p>
              ジパングオークションは、競売人が商品を最高の状態でオークションするためのレンタルポータルとしての役割のみを提供します。
              Zipang Auctions 仅作为 B2B
              拍卖平台，使日本卖家能够尽其所能拍卖他们的产品。
            </p>

            <p>
              By agreement to the terms, the Seller guarantees the goods offered
              by him/her their company, are conflict free, based on personal
              knowledge and/or written guarantees provided by the suppliers of
              the goods, each and all.
            </p>
            <p>
              規約に合意いただくことにより、売主様には、個人または企業としてご提供いただいたダイアモンドが、売主様ご自身の知る限り対立に関与していない、もしくはお品物の各サプライヤーから提供された保証書にそのように記載されている旨を保証いただくことになります。
              通过同意这些条款，卖方保证他/她的公司提供的货物，基于个人認知上和货物供应商所提供的每个及所有书面保证，是无冲突的。
            </p>
            <p>
              For Diamond/Jewellery Auctions, Zipang Auctions shall also
              provide, to the best of their expertise, their own opinion by an
              in-house Graduate Gemologist, to the best of his/her knowledge,
              upon such request from Bidder. Zipang Auctions provides this
              service as pro bono to their registered members per request and
              shall not be held liable for it.
            </p>
            <p>
              ジパングオークション
              では、買主様からのご依頼に応じて最良の範囲において、社内の宝石鑑定師の持てる知識を最大に活用した独自の見解も提供するものとします。
              ジパングオークション
              では登録会員の皆様からのご要望に応じた無料サービスとしてこれを行うものであり、これに関して責任を問われることはないものとします。
              於钻石/珠宝拍卖，Zipang Auctions的内部研究宝石学家
              应尽其专业知识，按投标人的要求,提供专业的意见。 Zipang Auctions
              应向其注册会员提供此意見意见，但对此不承担任何责任。
            </p>

            <p>
              Despite Zipang Auctions doing its best to protect its overseas
              Bidders, Bidder notes that the goods offered for sale will only be
              delivered to the Japan’s Address and billed to the Japanese
              company with Valid Kobutsu License only.
            </p>
            <p>
              ジパングオークションは海外の入札者を保護するために最善を尽くしますが、出品された商品は日本住所にしか納品されず、有効な古物ライセンスを持つ日本企業にしか請求されないことに入札者は注意してください。
              Zipang Auctions
              尽最大努力保护其海外投标人，但投标人指出，所提供的出售商品只会被运送到日本地址，并仅向持有有效
              Kobutsu 许可证的日本公司收费。
            </p>

            <p>
              As a best effort to overseas bidders, it is possible to bid for
              customers residing in Japan or Sponsors.
            </p>
            <p>
              海外の入札者は日本在住の古物免許を保有する業者、若しくは主催者での代理での入札が可能です。
              本公司可以为海外投标人、居住在日本的客户或赞助商投标。
            </p>
            <p className="fontBold headingFontSize">
              Condition for participation in auction from overseas customers
              <br />
              海外顧客からのオークション参加の条件 海外客户参与拍卖的条件。
            </p>

            <p>
              １．Overseas customers must deposit of USD2,000- when
              participating in the Zipang Auction. The security deposit will be
              offset when the auction is settled.
            </p>
            <p>
              １．海外顧客の参加者は２０００米ドルを保証金として主催者に預けて頂きます。
              保証金は清算時に相殺が可能ですが次回からの保証金として持ち越すことが可能です。
              海外客户参加拍卖时需缴纳USD2,000-保证金。保证金将在拍卖结算时抵消。
            </p>

            <p>
              ２．Delivary of the successful bid items will basically be
              received in Japan.However customer wish to receive it overseas, it
              is posshible to send it by collect shipment after payment.
            </p>
            <p>
              2.
              落札された商品の受け取りは基本的に日本での受け取りにすなります。
              然しながら海外にて受け取りを希望する場合、代金知梁後にコレクトシップ面とにより受け取る事が可能です。
              中标物品基本在日本发货品及收取货品。
              但是客户希望在海外收到它，可以在通过付款后到付方式发送。
            </p>

            <p>
              Zipang Auctions shall follow the rules and instructions stipulated
              by local police license ( 古物証明書) to buy goods from the public
              and shall do all necessary investigations within its power to
              ensure the goods are delivered with permission of the true owner
              and under his/her direct instruction/will to sell the item.
            </p>
            <p>
              ジパングオークション
              は地域の警察許可証（古物証明書）で規定された規則および指示に従って民間からの商品の買い付けを行うものとし、その力の範疇において商品が必ず
              真の保有者の許可の下、かつ当人からの商品販売の直接指示の下に届けられるようにするために必要な全ての調査を行うものとします。
              Zipang Auctions
              应遵循当地警方许可（古物证明书）规定的规则和指示从公众购买物品，并应在其权力范围内进行所有必要的调查，以确保物品在真正的所有者的许可下并在他/她的领导下交付直接指示/将出售物品。
            </p>
            <p>
              All matters in question shall be investigated by Bidder prior to
              purchase agreement, after which the transaction is final and
              irreversible, except in cases such as treatment in the diamond
              that has not been disclosed or doubts regarding the Origin (
              Natural / Laboratory grown / Coating etc. ) of the diamond.
            </p>
            <p>
              すべての懸念事項は、購入契約の前に入札者が調査するものとします。その後、取引は最終的で不可逆的に成立するものとします。ただし、開示されていないダイヤモンドの処理（人工ダイヤ/コーティングなど）に関する疑いがある場合を除きます
              。
              投标人应在达成购买协议之前看清所有相关事宜，交易后不可撤销，除未披露钻石的处理方式或对原产地（天然/实验室培育/涂层等）有疑问
              的钻石。 .
            </p>
            <p className="fontBold headingFontSize">
              Below are important terms you should be aware of before placing
              any bids / offering goods for sale :
              <br />
              ご入札や販売の前にご留意いただきたい重要規約を以下に記載いたします。
              以下是您在投标/提供待售商品之前应了解的重要条款：
            </p>

            <p>
              1. Zipang Auctions scans diamonds using v360 machines boasting
              latest technology digital imagery available. Goods shall be
              scanned by Zipang Auctions seller`s expense. Zipang Auctions
              reserves the right to negotiate and reach agreements with sellers
              according to the number of items and urgency stipulated by Seller.
            </p>
            <p>
              ジパングオークション では最新のデジタル画像技術を誇る v360
              機器を用いてダイアモンド
              をスキャンいたします。アップロードに選定されたお品物はスキャンし、リストに入れアップロードいたします。ビジネス上の理由のみによって拒否されたその他のお品物のスキャンは売主様のご要望に応じて売主様のご負担にて行います。
              ジパングオークション
              では、お品物の数および売主様のお決めになった緊急度に従って売主様と交渉を行い合意を行う権利を留保します。
              Zipang Auctions 使用拥有最新技术的 v360
              机器扫描钻石。商品扫描由Zipang Auctions 卖家承担费用。Zipang
              Auctions保留根据卖家规定的拍品数量和紧急程度与卖家达成协议的权利。
            </p>
            <p>2. The seller commission is 3% and photo fee 500 yen/ pce</p>
            <p>
              出品手数料は3％、写真代は出品者に別途追加で１ピースあたり５００円の請求をさせていただきます。
              卖家的佣金是3%，拍照费是500日元/张。
            </p>
            <p>
              3. The exchange rate is fixed as on the first day of preview.
              Thereafter all the bidding until invoice is done on the same
              exchange rate. Payment for the generated invoice is due within 3
              business days of notice via bank Wire. Failure to submit payment
              by the due date will result in a late fee of 1% and could result
              in additional handling fees.
            </p>
            <p>
              為替レートは内覧会初日に固定されます。以後、インボイスまでの入札はすべて同じ為替レートで行われます。すべての懸念事項は、購入契約の前に入札者が調査するものとします。その後、取引は最終的で不可逆的に成立するものとします。ただし、開示されていないダイヤモンドの処理など（人工ダイヤ/コーティングなど）に関する疑いがある場合を除きます
              。
              汇率以预览第一天的汇率而定。此后所有投标直至发票均按相同汇率完成。发付款应在收到发票通知后
              3 个工作日内通过银行电汇支付。未能在到期日之前付款将导致 1%
              的罚金，并可能导致额外的手续费。
            </p>
            <p>
              4. Freight charges, custom charges and any other additional costs
              are born by bidder. Any discrepancies between the merchandise
              listed on the invoice and the actual merchandise received must be
              reported to within 24 hours of receipt.
            </p>
            <p>
              4.
              実際に御受け取りいただいた商品が請求書に記載の商品と異なる場合にはお受け取りから24時間以内に必ずご連絡ください。
              运费、海关费用和任何其他额外费用由投标人承担。发票上列出的商品与收到的实际商品之间的任何差异必须在收到后
              24 小时内报告。
            </p>

            <p>
              5. Any breach of common trade and industry standards found by
              Zipang Auctions on both/any side of the deal, will not be
              tolerated and could result in account termination, notwithstanding
              lawsuits and other penalties.
            </p>
            <p>
              5. 売却、買取いずれの取り引きに関しても、ジパングオークション
              が商業、業界規定の違反を発見した場合、当該取り引きは認められず、法令その他の罰則に加え、アカウントを停止させていただくことがございます。
              Zipang Auctions
              在交易双方/任何一方发现的任何违反共同贸易和行业标准的行为都不会被容忍，会作出诉讼和其他处罚,并且可能导致账户终止。
            </p>

            <StyledText className="updatedDate">
              Last Updated on: 08 february, 2023
            </StyledText>
          </Grid>
        </StyledWrapper>
      </StyledGrid>
    </Grid>
  );
};

export default ZipangTermsAndConditionPage;
