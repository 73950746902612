import React from "react";
import { CardContent, DescriptionProps } from "./DescriptionCard";

const MobileDescriptionList = ({
  descriptionCards,
}: {
  descriptionCards: DescriptionProps[];
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        width: "100vw",
        paddingBottom: "1em",
      }}
    >
      {descriptionCards.map((card) => (
        <div style={{ minWidth: "60vw", marginRight: "1em" }}>
          <CardContent {...card} />
        </div>
      ))}
    </div>
  );
};

export default MobileDescriptionList;
