import { Grid, makeStyles, Menu, MenuList } from "@material-ui/core";
import React, { useRef } from "react";
import { StyledMenuItem } from "./StyledComponent";

import Globe from "../Static/Icons/Globe";
import { useHistory } from "react-router-dom";
import AuthService from "../../../components/auth/AuthService";
import { preferredlanguageUrl } from "../../../common/config/app.endpoints";
import { changeApplicationLanguage } from "../../../utils/utility";
import Button from "../../../common/elements/Button";
import Colors from "../../../common/materials/colors";
import styled from "styled-components";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  languageContainer: {
    position: "absolute",
    top: "110px",
  },
  paper: {
    borderTop: `4px solid ${Colors.darkBlue}`,
  },
  upperCase: {
    textTransform: "uppercase",
    textAlign: "center",
    fontWeight: "bold",
  },
});


const StyledLogo = styled.img`
  width: 30px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 24px;
    display: flex;
  }
`;


interface Iprops{
  isZipangLandingPage?: boolean;
}
const LanguageSelector = (props: Iprops) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuSpace = useRef(null);
  const history = useHistory();
  const classes = useStyles();

  const handleClick = () => {
    setAnchorEl(menuSpace.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    var lang: any = "";
    if (lng === "en") {
      lang = "en";
      window.localStorage.setItem("language", "EN");
    } else if (lng === "zh_CN") {
      lang = "zh_CN";
      window.localStorage.setItem("language", "zh_CN");
    } else {
      lang = "jp";
      window.localStorage.setItem("language", "日本語");
    }
    var token = localStorage.getItem("id_token");
    if (token !== null) {
      const Auth = new AuthService();
      if (token === null) {
        history.push("/");
      } else {
        Auth.postRequest(
          `${preferredlanguageUrl}/${lang}`,
          "data",
          (status: number, response: any) => {
            changeApplicationLanguage(lng);
          }
        );
      }
    } else {
      changeApplicationLanguage(lng);
    }
    handleClose();
  };

  return (
    <Grid container classes={classes}>
      <Button type="languageicon">
        {props.isZipangLandingPage ? (
          <StyledLogo
            src="https://d1xs39ogteakr9.cloudfront.net/zipang/languageSelect.png"
            alt="worldIcon#1"
            onClick={handleClick}
          />
        ) : (
          <Globe onClick={handleClick} />
        )}
      </Button>
      <div className={classes.languageContainer} ref={menuSpace}></div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        classes={classes}
      >
        <MenuList>
          <StyledMenuItem
            className={classes.upperCase}
            onClick={() => changeLanguage("en")}
          >
            English
          </StyledMenuItem>
          <StyledMenuItem
            className={classes.upperCase}
            onClick={() => changeLanguage("jp")}
          >
            日本語
          </StyledMenuItem>
          <StyledMenuItem
            className={classes.upperCase}
            onClick={() => changeLanguage("zh_CN")}
          >
            中文
          </StyledMenuItem>
        </MenuList>
      </Menu>
    </Grid>
  );
};

export default LanguageSelector;
