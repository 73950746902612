import  styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Modal, TextareaAutosize } from "@material-ui/core";

export const StyledTextareaAutosize = styled(TextareaAutosize)`
  width: 100%;
`;
export const StyledConHeader = styled.div`
  font-size: 20px;
  width: 100%;
  background: $ ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding: 5px;
  color: white;
`;
export const StyldedContainerMargin = styled(Grid)`
  margin-top: 20px;
  .alignRight {
    text-align: right;
  }
`;

export const StyledValidationMessages = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 14px;
  margin: 15px 0px;
  margin-left: 1.2em;
`;

export const StyledAttachmentImg = styled(Grid)`
  text-align: end;
  margin-right: 30px;
`;

export const StyledChooseFileCon = styled(Grid)`
  margin-left: 40px;
`;

export const StyledInquiryContainer = styled.div`
  position: fixed;
  width: 25px;
  height: 20px;
  right: 10px;
  top: 82%;
  height: auto;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 40px;
    height: 40px;
    right: 10px;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: 40px;
    height: 40px;
    right: 10px;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 40px;
    height: 40px;
    right: 10px;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    width: 80px;
    height: 80px;
    top: 80%;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 80px;
    height: 80px;
  }
`;

export const StyledInquiryImage = styled("img")`
  width: 100%;
  height: auto;
`;

export const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 1em 1em 1em 0;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 800px;
  }
  @media (width: 768px) {
    width: 600px;
  }
  @media (width: 540px) {
    width: 500px;
  }
`;

export const StyledPromotaionImage = styled.img`
  height: 100%;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 100%;
    width: 100%;
  }
`;
export const StyledComponent = styled(Grid)`
  outline: none;
`;

export const StyledWrapper = styled(Grid)`
`;

export const StyledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  margin-left: 1.2em;
  height: 24px;
  flex-direction: column;
`;
export const StyledText = styled(Grid)`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  margin-left: 1.2em;
`;

export const StyledInquiryWrapper = styled(Grid)`
`;

export const StyledTextWrapper = styled(Grid)`
    width: 100%;
    // text-align: center;
    margin-left: 10px;
    font-size: 1em;
    padding: 0.8em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

export const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledAttachmentLegend = styled.legend`
  margin-left: 20px;
  padding: 0.3em;
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.grey};
  font-weight: bold;
  width: auto;
`;
export const StyledAttachmentFieldSet = styled.fieldset`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  display: flex;
  border-radius: 8px;
  height: 70px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
`;
interface IButtonProps {
  isSubmit?: boolean;
}

export const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.5em;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

export const StyledSubmitButton = styled.div`
  display: flex;
  width: 100px;
  float: right;
  position: sticky;
  top: 65%;
`;
export const StyledCloseButton = styled.div`
  display: flex;
  width: auto;
  float: right;
  margin-left: 35px;
`;

export const StyledLegendField = styled.div`
    margin: 0.2em;
    display: flex;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        justify-content: space-between;
    }
    border-radius: 10px;
`;

export const StyledFieldLegend = styled.div`
  margin-left: 15px;
  padding: 0.4em;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 55%;
  }
  font-weight: bold;
  @media (width: 320px) {
    font-size: 0.8em;
  }
`;

export const StyledTextWrapperon = styled.div`
    padding: 0.2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 45%;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    } 
`;

export const StyledSelects = styled.div`
    fieldset {
        border: none;
        border-radius: 30px;
    }

    .MuiInputLabel-formControl{
        // color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }

   .MuiOutlinedInput-root {
        border-radius: 30px;   
        border: none;
   }

   .MuiInputLabel-outlined {
        transform: translate(14px, 20px) scale(1);
        &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
        }
   }
   .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        background : ${(props) => props.theme.colors.base.transparent};
    } 
    
    .MuiSelect-outlined {
        background : ${(props) => props.theme.colors.base.transparent};
    }    
`;

export const StyledSelectContainer = styled.div`
    width: 100%;
`;

export const StyledAttachmentContainer = styled(Grid)`
  flex-direction: column;
  border-radius: 10px;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor} input {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .estrikeColor {
    color: red;
  }
  font-size: 12px;
`;

export const StyledReplyBox = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
  }
`;

interface IDisaleDetails {
  isDisabled?: boolean;
}

export const StyledTextInputs = styled.div<IDisaleDetails>`
  bottom: 0;
  display: flex;
  width: -webkit-fill-available;
  fieldset {
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
    border-radius: 30px;
  }
  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInput-underline::after {
    border-bottom: solid 2px
      ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInputLabel-outlined {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    transform: translate(14px, 16px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: ${(props) => props.theme.colors.base.red};
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 30px;
    background-color: ${(props) => props.theme.colors.base.yellow};
  }
  outline: none;
  margin: 1em;
  color: ${(props) => props.theme.colors.base.lightgrey};
  .MuiOutlinedInput-input {
    padding: 14px;
  }
  legend {
    color: ${(props) => props.theme.colors.base.blue};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    border-width: 1px;
  }
  .MuiSelect-select:focus {
    background-color: ${(props) => props.theme.colors.base.white};
  }
  .modeOfCommunication {
    margin: 2em;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.isDisabled &&
    css`
      .MuiInputLabel-formControl {
        color: ${(props) => props.theme.colors.base.grey};
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.grey};
      }

      .MuiSelect-select:focus {
        background-color: ${(props) => props.theme.colors.base.grey};
      }
    `}
`;

export const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  margin-top:10px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-root {
    padding: 10px;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }
  .linkCss {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 20px 10px;
  }
`;

export const StyledLink = styled.span`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
  cursor: pointer;
`;
export const StyledTimeGridContainer = styled(Grid)`
  padding: 10px;
  justify-content: flex-end;
`;