import {
  advertiseUrl,
  clockNotInSyncUrl,
  contactUsUrl,
  endedProductPublicApi,
  forgotPasswordUrl,
  getCity,
  getState,
  joinedAuctionsUrl,
  productPublicApi,
  resetPasswordUrl,
  sendLoginOTP,
  signupUrl,
  tokenUrl,
} from "../../common/config/app.endpoints";
import AuthService from "../../components/auth/AuthService";

const Auth = new AuthService();

export const getStates = async (countryId: string) => {
  const response = await Auth.getRequestWithoutAuthorization(
    `${getState}?id=${countryId}`
  );
  return response.data;
};

export const getCities = async (stateId: string) => {
  const response = await Auth.getRequestWithoutAuthorization(
    `${getCity}?id=${stateId}`
  );
  return response.data;
};

export const getProducts = async () => {
  const response = await Auth.getRequestWithoutAuthorization(productPublicApi);
  return response.data;
};

export const getEndedProducts = async () => {
  const response = await Auth.getRequestWithoutAuthorization(
    endedProductPublicApi
  );
  return response.data;
};

export const login = async (payload: { username: any; password: any }) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(tokenUrl, payload, callBack);
  });

  const { data, status } = response as { data: any; status: number };

  if (status === 200) {
    Auth.setToken(data.token);
    localStorage.setItem("otpAuth", "false");
    localStorage.removeItem("changePlanEmail");
  }
  return { response: data, status: status };
};

export const forgotPassword = async (email: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequestWithoutAuthorization(
      `${forgotPasswordUrl}?email=${email}`,
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const resendOTP = async (email: any) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(
      `${sendLoginOTP}?un=${email}`,
      "data",
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const signUp = async (formData: any) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(signupUrl, formData, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const signInOtop = async (formData: any) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(tokenUrl, formData, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const contactUs = async (formData: any) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(contactUsUrl, formData, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const getAdverstisements = async () => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequestWithoutAuthorization(advertiseUrl, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const resetPassword = async (
  email: string,
  newPassword: string,
  otp: string
) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequestWithoutAuthorization(
      `${resetPasswordUrl}?email=${email}&newPassword=${newPassword}&otp=${otp}`,
      "data",
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const joinedAuctions = async () => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequest(joinedAuctionsUrl, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const clockNotInSync = async (time: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(`${clockNotInSyncUrl}${time}`, "data", callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};
