import {
  Grid,
  MenuItem,
  Button,
  Paper,
  FormControl,
  Link,
} from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../../common/materials/colors";
import { CommonHorizontalPadding } from "../StyledComponents";
import { Box } from "@mui/material";

export const HeaderContainer = styled(CommonHorizontalPadding)`
  position: sticky;
  top: 0;
  z-index: 1002;
  background-color: ${Colors.white};
  display: flex;
  align-items: center;
  padding: 0px;
`;

export const StyledLogoBackground = styled.span`
  background-color: ${Colors.white};
`;

export const StyledLeftAlignedContainer = styled.div`
  // display: flex;
  text-align: center;
  // justify-content: space-between;
  font-size: 1.7em;
  font-weight: 500;
`;

export const StyledRightAlignedContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const StyledCenterContainer = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const HiddenButton = styled.button`
  visibility: hidden;
  display: none;
`;

export const StyledButton = styled(Button)`
  text-transform: capitalize;
  font-weight: 600;
  &:focus {
    outline: none;
  }
`;

export const StyledRelativeComponent = styled.div`
  position: relative;
`;

export const StyledMenuItem = styled(MenuItem)`
  border-bottom: 1px solid ${Colors.white};
  margin: 0 1em;
  padding: 0.5em 0;
  font-size: 1em;
  font-weight: bold;
  &:last-child {
    border: none;
  }
`;
export const StyledMenuContainer = styled.div`
  position: absolute;
  top: 70px;
`;

export const StyledSignInContainer = styled(Paper)`
  padding: 2em;
  width: 30vw;
`;

export const StyledTextFieldWrapper = styled(FormControl)`
  margin: 0 0 1em;
`;

export const StyledOTPWrapper = styled(Grid)<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? "1em" : "1em 3em 1em")};
`;
export const StyledOTPDigitWrapper = styled(Grid)`
  padding-right: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  input &:last-child {
    padding: 0;
  }
`;

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  padding: 0.5em 0;
`;

export const StyledInfoMessage = styled(StyledMessage)`
  color: ${(props) => props.theme.colors.base.green};
`;

export const StyledFlag = styled.img`
  cursor: pointer;
  width: 2em;
  height: 2em;
`;

export const StyledLogoContainer = styled.div`
  background-color: ${Colors.white};
  box-shadow: 1px 3px 5px #ddd;
`;

export const StyledLink = styled(Link)`
  cursor: pointer;
`;

export const StyledTimerText = styled.p`
  font-size: 0.8em;
`;
export const StyledLogoImage = styled.img`
  width: 1.5em;
  height: 1.5em;
`;

export const StyledQRImage = styled.img`
  width: 4em;
  height: 4em;
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: 8em;
    height: 8em;
  }
`;

export const StyledBackgroundImg = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  @media screen and (max-width: 380px) {
    height: 140vh;
  }
  @media (min-width: 380px) and (max-width: 400px) {
    height: 110vh;
  }
  @media screen and (width: 280px) {
    height: 160vh;
  }
  @media screen and (width: 320px) {
    height: 150vh;
  }
  @media screen and (width: 540px) {
    height: 120vh;
  }
`;


export const Styledfooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px;
  @media screen and (max-width: 400px) {
    position: relative;
  }
  @media screen and (width: 540px) {
    position: relative;
  }
`;

export const StyledGlowstarfooter = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding: 10px;
  @media screen and (max-width: 400px) {
    position: fixed;
  }
  @media screen and (width: 540px) {
    position: relative;
  }
`;


export const StyledGlowstarBackgroundImg = styled.img`
  width: 100%;
  height: 100%;
  display: flex;
  @media screen and (max-width: 380px) {
    height: -webkit-fill-available;
  }
  @media (min-width: 380px) and (max-width: 400px) {
    height: -webkit-fill-available;
  }
  @media screen and (width: 280px) {
    height: -webkit-fill-available;
  }
  @media screen and (width: 320px) {
    height: -webkit-fill-available;
  }
  @media screen and (width: 540px) {
    height: -webkit-fill-available;
  }
`;

export const StyledAddressContainer = styled(Box)`
  text-align: end;
  padding: 10px;
  @media screen and (max-width: 500px) {
    text-align: start;
  }
`;
