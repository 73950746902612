import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faInfoCircleProps = faInfoCircle as IconProp;
const StyledComponent = styled(Grid)`
    outline: none;
    .iconStyle {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
        margin-right: 8px;
        margin-left: 5px;
    }
    .text {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 2em;
        margin-bottom: 10px;
        font-family: Calibre-Regular;
        color: ${(props) => props.theme.colors.base.grey};
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 1em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
    }
`;

const StyledTextWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledGrid = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;

const StyledWaringWrapper = styled(Grid)`
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;

const StyledContainerWrapper = styled(Grid)`
    display: flex;
    width: 100%;
    margin-bottom: 10px;
`;

export interface IProps extends IRouterComponentProps {
    t: any;
    modalType: any;
}

const ServerTime = (props: IProps) => {
    const { t, modalType } = props;
    
    return (
        <StyledComponent tabIndex={-1}>
            <StyledWrapper container spacing={0}>
                <StyledGrid item md={12} xs={12} lg={12}>
                    {modalType === 'restrintForJoin' ?
                        <StyledContainerWrapper>
                            <FontAwesomeIcon icon={faInfoCircleProps} className="iconStyle" />
                            <StyledTextWrapper className="fontBold">{t('common:restrictToJoinAuction')}</StyledTextWrapper>
                        </StyledContainerWrapper>
                        :
                        <StyledWaringWrapper>
                            <div className="fontBold text">{t('common:warning:firstLine')}<span>&nbsp;</span></div>
                            <StyledTextWrapper>{t('common:warning:secondLine')}</StyledTextWrapper>
                        </StyledWaringWrapper>
                    }
                </StyledGrid>
            </StyledWrapper>
        </StyledComponent>
    );
}

export default ServerTime;