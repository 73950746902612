import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import ProductCard from "./cards/ProductCard";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

interface IProps {
  currentRecords: any[];
  onCardClickHandler: (product: any) => void;
  fetchAuctionProducts: () => void;
  selectedExportId: any[];
  selectItemInGridView: (id: any) => void;
  handleWatchlist: (
    auctionId: string,
    productId: string,
    isWatch: boolean
  ) => void;
  isSellPage: boolean;
  handleNegotiate: (x: any) => void;
}

const MyPageProductsList = (props: IProps) => {
  const {
    currentRecords,
    onCardClickHandler,
    fetchAuctionProducts,
    selectedExportId,
    selectItemInGridView,
    handleWatchlist,
    isSellPage,
    handleNegotiate
  } = props;

  return (
    <StyledGrid container={true} spacing={0}>
      {currentRecords.length > 0 &&
        currentRecords.map((dataObj: any, index: number) => {
          return (
            <Grid
              key={dataObj.perId}
              item={true}
              xs={12}
              sm={6}
              md={6}
              lg={3}
              xl={3}
            >
              <ProductCard
                key={dataObj.perId}
                index={index}
                selectedExportId={selectedExportId}
                selectItemInGridView={selectItemInGridView}
                listItemData={dataObj}
                onCardClickHandler={onCardClickHandler}
                fetchProducts={fetchAuctionProducts}
                handleWatchlist={handleWatchlist}
                isSellPage={isSellPage}
                handleNegotiate={handleNegotiate}
              />
            </Grid>
          );
        })}
    </StyledGrid>
  );
};

export default MyPageProductsList;
