import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { StyledComponent } from '../../common/materials/GenericStyleComponent';
import {  dateBackgroundGradient } from '../../common/materials/LinearGradient';
import PromotionalCarousal from '../../components/category-carousal/PromotionalCarousal';


const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;
    padding: 0.4em; 
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 580px;
    }
    .onClickButton {
        cursor: default;
        background: ${dateBackgroundGradient};
    }
    .alignRight {
        text-align:right;
    }
`;

export const StyledPromotaionImage = styled.img`
    height:100%;
    width:90%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        height:100%;
        width:90%;
    }
`;

const StyledCloseicon = styled.img`
    height:1.4em;
    width:1.4em;
    &:hover {
        cursor: pointer;
    }
`;
interface IProps {
    closeModal: () => void;
}
let pdfLocalization: string = "";

const Promotional = (props: IProps) => {
    let language = localStorage.getItem("language");
    if (language === "EN") {
      pdfLocalization = "/images/igt_Images/hokuyuDiamondAuction1.png";
      pdfLocalization = "/images/igt_Images/hokuyuJewelryAuction2.png";
      pdfLocalization = "/images/igt_Images/dialusterJewelryAuction.png";
      pdfLocalization = "/images/igt_Images/takeyamaAuction.png";
      pdfLocalization = "/images/igt_Images/ijt1.png";
      pdfLocalization = "/images/igt_Images/ijt2.png";
      pdfLocalization = "/images/igt_Images/mjdiams1.png";
    } else {
      pdfLocalization = "/images/igt_Images/Auction_jp.png";
    }
    
    const handleCancel = () => {
        props.closeModal();
    }    
    return (
        <StyledComponent >
            <StyledContainerWrapper>
                <Grid item={true} xs={12} md={12}>
                    <div className="alignRight" onClick={handleCancel}>
                        <StyledCloseicon src="/images/close-button2.ico">
                        </StyledCloseicon>
                    </div>
                    <PromotionalCarousal imgs={pdfLocalization}/>
                    {/* <StyledPromotaionImage src={pdfLocalization}></StyledPromotaionImage> */}
                </Grid>
            </StyledContainerWrapper>
        </StyledComponent>
    )
}

export default Promotional;