import decode from "jwt-decode";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { domainName } from "../../common/config/app.endpoints";
let decoded: any = "";
let ifCompanyIdExist: any = {};
let subdomain: any = String;
let finalDomainName: any = "";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      if (error.response.data.errors[0].code === "KB403001") {
        PubSub.publish("Deactivate", error.response.status);
        return null;
      }
    }
    return Promise.reject(error);
  }
);

export default class AuthService {
  constructor() {
    this.setToken = this.setToken.bind(this);
    this.loggedIn = this.loggedIn.bind(this);
    this.logout = this.logout.bind(this);
    this.getRequest = this.getRequest.bind(this);
    this.postRequest = this.postRequest.bind(this);
    this.postRequestForKYC = this.postRequestForKYC.bind(this);
    this.getRequestWithoutAuthorization =
      this.getRequestWithoutAuthorization.bind(this);
    this.getRequestForDownload = this.getRequestForDownload.bind(this);
    subdomain = window.location.hostname;
    finalDomainName = `${domainName}`.replace(/(^\w+:|^)\/\//, "");
  }

  forPublicApisHeader() {
    subdomain = subdomain.replace(`.${finalDomainName}`, "");
    if (subdomain !== null && subdomain !== "") {
      if (subdomain.toString().toLowerCase().startsWith("www.")) {
        subdomain = subdomain.substring(4);
      } else if (subdomain.toString().toLowerCase() === "www") {
        subdomain = "";
      }
    }
    if (subdomain.includes(".") || subdomain.includes("localhost")) {
      if (subdomain === "sysquareind.localhost") {
        localStorage.setItem("isNormalUser", "subdomain");
        subdomain = "sysquareind";
      } else {
        localStorage.setItem("isNormalUser", "normal");
      }
    } else {
      localStorage.setItem("isNormalUser", "subdomain");
    }
    if (subdomain.includes("myauctionsdemo.in")){
      return {
        "Content-Type": "application/json",
      };
    }else{
      return {
        "Content-Type": "application/json",
        subdomain: subdomain,
      };
    }
  }
  forPublicApisHeaders() {
    subdomain = subdomain.replace(`.${finalDomainName}`, "");
    if (subdomain !== null && subdomain !== "") {
      if (subdomain.toString().toLowerCase().startsWith("www.")) {
        subdomain = subdomain.substring(4);
      } else if (subdomain.toString().toLowerCase() === "www") {
        subdomain = "";
      }
    }
    if (subdomain.includes(".") || subdomain.includes("localhost")) {
      if (subdomain === "sysquareind.localhost") {
        localStorage.setItem("isNormalUser", "subdomain");
        subdomain = "sysquareind";
      } else {
        localStorage.setItem("isNormalUser", "normal");
      }
    } else {
      localStorage.setItem("isNormalUser", "subdomain");
    }

    if (subdomain.includes("myauctionsdemo.in")) {
      return {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
      };
    } else {
      return {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.getToken(),
        subdomain: subdomain,
      };
    }
    
  }

  companyId() {
    var token = localStorage.getItem("id_token");
    if (token !== null) {
      decoded = jwt_decode(token);
      decoded = decoded.companyId;
      return decoded;
    }
  }

  headerObjectWithAuthorization() {
    subdomain = subdomain.replace(`${finalDomainName}`, "");
    if (subdomain.lastIndexOf(".") === subdomain.length - 1) {
      subdomain = subdomain.substring(0, subdomain.length - 1);
    }

    if (subdomain !== null && subdomain !== "") {
      if (subdomain.toString().toLowerCase().startsWith("www.")) {
        subdomain = subdomain.substring(4);
      } else if (subdomain.toString().toLowerCase() === "www") {
        subdomain = "";
      }
    }

    localStorage.setItem("subDomain", subdomain);
    ifCompanyIdExist = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getToken(),
    };
    if (
      decoded !== undefined &&
      subdomain !== undefined &&
      subdomain !== "" &&
      subdomain !== window.location.hostname
    ) {
      Object.assign(ifCompanyIdExist, {
        company_id: this.companyId(),
        subdomain: subdomain,
      });
    } else if (
      decoded === undefined &&
      subdomain !== undefined &&
      subdomain !== window.location.hostname &&
      subdomain !== ""
    ) {
      Object.assign(ifCompanyIdExist, { subdomain: subdomain });
    } else if (
      decoded !== undefined &&
      subdomain === undefined &&
      subdomain === "" &&
      subdomain === window.location.hostname
    ) {
      Object.assign(ifCompanyIdExist, {
        company_id: this.companyId(),
      });
    } else {
      Object.assign(ifCompanyIdExist);
    }
    return ifCompanyIdExist;
  }

  setToken(idToken: any) {
    if (idToken !== undefined && idToken !== null) {
      window.localStorage.setItem("id_token", idToken);
    }
  }

  getToken() {
    return localStorage.getItem("id_token");
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token: any) {
    try {
      const decoded: any = decode(token);
      if (decoded.exp < Date.now() / 1000) {
        // Check if token is expired.
        localStorage.clear();
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  logout() {
    PubSub.publish("ProfileStatus", "Yes");
    localStorage.setItem("profileComplete", "false");
    localStorage.clear();
  }

  getRequest(url: string, callback: any) {
    axios({
      method: "get",
      url: `${url}`,
      timeout: 30000,
      headers: this.headerObjectWithAuthorization(),
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        this.passErrorMessage(error, callback);
      });
  }

  forinquiryApisHeaders() {
    return { ...this.forPublicApisHeaders(), type: "formData" };
  }

  inquiryPostRequest(url: string, data: any, callback: any) {
    axios({
      method: "post",
      url: `${url}`,
      data: data,
      headers: this.forinquiryApisHeaders(),
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        this.passErrorArray(error, callback);
      });
  }

  postRequest(url: string, data: any, callback: any) {
    axios({
      method: "post",
      timeout: 40000,
      url: `${url}`,
      data: JSON.stringify(data),
      headers: this.headerObjectWithAuthorization(),
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        this.passErrorArray(error, callback);
      });
  }

  postRequestForExcel(url: string, data: any, callback: any) {
    axios({
      method: "post",
      timeout: 30000,
      url: `${url}`,
      data: JSON.stringify(data),
      headers: this.headerObjectWithAuthorization(),
      responseType: "arraybuffer",
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        this.passErrorArray(error, callback);
      });
  }

  getRequestForExcel(url: string, callback: any) {
    axios({
      method: "get",
      url: `${url}`,
      timeout: 30000,
      responseType: "arraybuffer",
      headers: this.headerObjectWithAuthorization(),
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        this.passErrorMessage(error, callback);
      });
  }

  getRequestWithoutAuthorization(url: string, callback?: any) {
    return axios({
      method: "get",
      url: `${url}`,
      headers: this.forPublicApisHeader(),
    })
      .then((response: any) => {
        callback && callback(response.status, response.data);
        return response;
      })
      .catch((error) => {
        callback && this.passErrorArray(error, callback);
        return error;
      });
  }

  postRequestWithoutAuthorization(url: string, data: any, callback?: any) {
    return axios({
      method: "post",
      url: `${url}`,
      data: JSON.stringify(data),
      headers: this.forPublicApisHeader(),
    })
      .then((response: any) => {
        callback && callback(response.status, response.data);
        return response;
      })
      .catch((error) => {
        callback && this.passErrorArray(error, callback);
        return error;
      });
  }

  postRequestForKYC(url: string, data: any, callback: any) {
    axios({
      method: "post",
      url: `${url}`,
      data: data,
      headers: {
        "Content-Type": "applicationmultipart/form-data",
        type: "formData",
        Accept: "application/json",
      },
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        var errormessages = [];
        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            if (error.response.data.errors !== undefined) {
              for (var i = 0; i < error.response.data.errors.length; i++) {
                errormessages.push(error.response.data.errors[i]);
              }
              return callback(error.response.status, errormessages);
            }
          }
        }
      });
  }
  async postRequestForSellPage(url: string, data: any, callback: any) {
    return axios({
      method: "post",
      url: `${url}`,
      data: data,
      headers: {
        "Content-Type": 'multipart/form-data',
        type: "formData",
        Accept: "application/json",
      },
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        var errormessages = [];
        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            if (error.response.data.errors !== undefined) {
              for (var i = 0; i < error.response.data.errors.length; i++) {
                errormessages.push(error.response.data.errors[i]);
              }
              return callback(error.response.status, errormessages);
            }
          }
        }
      });
  }

  async getRequestForDownload(url: string, callback: any) {
    await axios({
      method: "get",
      url: `${url}`,
      headers: this.headerObjectWithAuthorization(),
      responseType: "blob",
    })
      .then((response: any) => {
        return callback(response.status, response.data);
      })
      .catch((error) => {
        if (
          error.response !== undefined &&
          error.response.request !== undefined
        ) {
          if (error.response.request.statusText !== undefined) {
            var errorMessage = error.response.request.statusText;
            return callback(error.response.status, errorMessage);
          }
        } else {
          return callback(505, error);
        }
      });
  }

  passErrorMessage(error: any, callback: any) {
    if (error !== undefined) {
      var errormessages = [];
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.errors !== undefined
      ) {
        for (var i = 0; i < error.response.data.errors.length; i++) {
          errormessages.push(error.response.data.errors[i].message);
        }
        return callback(error.response.status, errormessages);
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.error !== undefined
      ) {
        errormessages.push(error.response.data.error.message);

        return callback(error.response.status, errormessages);
      } else {
        return callback(505, error);
      }
    }
  }

  passErrorArray(error: any, callback: any) {
    if (error !== undefined) {
      var errormessages = [];
      if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.errors !== undefined
      ) {
        for (var i = 0; i < error.response.data.errors.length; i++) {
          errormessages.push(error.response.data.errors[i]);
        }
        return callback(error.response.status, errormessages);
      } else if (
        error.response !== undefined &&
        error.response.data !== undefined &&
        error.response.data.error !== undefined
      ) {
        errormessages.push(error.response.data.error);

        return callback(error.response.status, errormessages);
      } else {
        return callback(505, error);
      }
    }
  }
}
