import { annualAuctionProductsPageNumber, annualBuybid } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";

const Auth = new AuthService();

export const postbuyitNowData = async (data: any) => {
  return (await new Promise((res) => {
    const callback = (status: number, data: any) => {
      res({ response: data.data, status });
    };
    Auth.postRequest(annualBuybid, data, callback);
  })) as unknown as Promise<{
    response: any;
    status: number;
  }>;
};



export const getPaginationRequest = async (auctionId: number | string, productId: string, stringId: string) => {
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequest(`${annualAuctionProductsPageNumber}?auctionId=${auctionId}&productId=${productId}&serialNo=${stringId}`, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};