import Grid from "@material-ui/core/Grid";
import Modal from "@material-ui/core/Modal";
import { backgroundGradient } from "./LinearGradient";
import styled, { css } from "styled-components";

export const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    cursor: pointer;
    border: none;
    font-size: 0.8em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
    }
`;

interface IDisaleDetails {
    isDisabled?: boolean;
}

export const StyledTextInput = styled.div<IDisaleDetails>`
    fieldset {
        border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
        border-radius: 30px;
    }
    .MuiInputLabel-formControl{
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }
    .MuiInput-underline::after {
        border-bottom : solid 2px ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }
    .MuiInputBase-root {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }
    .MuiInputLabel-outlined {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
        transform: translate(14px, 16px) scale(1);
        &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
        }
    }
    .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
        color: ${(props) => props.theme.colors.base.red};
    }
    .MuiOutlinedInput-input:-webkit-autofill {
        border-radius: 30px;
        background-color: ${(props) => props.theme.colors.base.yellow};
    }
    outline: none;
    margin: 1em;
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    .MuiOutlinedInput-input {
        padding: 14px;
    }
    legend {
        color: ${(props) => props.theme.colors.base.blue};
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
        border-width: 1px;
    }
    .MuiSelect-select:focus {
        background-color:${(props) => props.theme.colors.base.white};
    }
    .modeOfCommunication {
        margin: 2em;
    }
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    ${props => props.isDisabled && css`
    .MuiInputLabel-formControl{
        color: ${(props) => props.theme.colors.base.grey};
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.grey};
    }

    .MuiSelect-select:focus {
        background-color:${(props) => props.theme.colors.base.grey};
    }
    `}
`;

export const StyledShipingTextInput = styled.div<IDisaleDetails>`
  fieldset {
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
    border-radius: 10px;
  }
  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInput-underline::after {
    border-bottom: solid 2px
      ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInputBase-root {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .MuiInputLabel-outlined {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    transform: translate(14px, 16px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
  .MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: ${(props) => props.theme.colors.base.red};
  }
  .MuiOutlinedInput-input:-webkit-autofill {
    border-radius: 30px;
    background-color: ${(props) => props.theme.colors.base.yellow};
  }
  outline: none;
  margin: 1em;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  .MuiOutlinedInput-input {
    padding: 14px;
  }
  legend {
    color: ${(props) => props.theme.colors.base.blue};
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    border-width: 1px;
  }
  .MuiSelect-select:focus {
    background-color: ${(props) => props.theme.colors.base.white};
  }
  .modeOfCommunication {
    margin: 2em;
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
        props.isDisabled &&
        css`
      .MuiInputLabel-formControl {
        color: ${(props) => props.theme.colors.base.grey};
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.grey};
      }

      .MuiSelect-select:focus {
        background-color: ${(props) => props.theme.colors.base.grey};
      }
    `}
`;


export const StyledSelect = styled.div`
  margin: 1em;

  fieldset {
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
    border-radius: 30px;
  }

  .MuiInputLabel-formControl {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }

  .MuiOutlinedInput-root {
    border-radius: 30px;
    //border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }

  .MuiInputLabel-outlined {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    transform: translate(14px, 20px) scale(1);
    &.MuiInputLabel-shrink {
      transform: translate(14px, -6px) scale(0.75);
    }
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    background: ${(props) => props.theme.colors.base.transparent};
  }

  .MuiSelect-outlined {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    background: ${(props) => props.theme.colors.base.transparent};
  }
  .MuiSelect-selectMenu {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    background: ${(props) => props.theme.colors.base.transparent};
  }
`;

export const StyledTitle = styled.div`
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    text-align: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        margin: 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        margin: 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        margin: 1em;
    }
`;

export const StyledTitleText = styled.div`

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 16px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 18px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        font-size: 24px;
    }
`;

export const StyledFieldLegend = styled.div`
  margin-left: 15px;
  padding: 0.4em;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 55%;
  }
  font-weight: bold;
  @media (width: 320px) {
    font-size: 0.8em;
  }
`;

export const StyledLegendField = styled.div`
    margin: 0.2em;
    display: flex;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        justify-content: space-between;
    }
    border-radius: 10px;
`;

export const StyledTextWrapper = styled.div`
    padding: 0.2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 45%;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
    } 
`;

export const StyledComponent = styled.div`
    outline: none;
`;

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
`;

export const StyledModal = styled(Modal)`
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }
`;

export const StyledCardContainer = styled.div`
    border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    margin: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    .borderRightBlue {
        border-right: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .borderBottomBlue {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .secondaryLabel {
        color: ${(props) => props.theme.colors.base.darkGrey};
        font-size: 0.7em;
        display: flex;
        align-items: center;
        margin-left: 5px;
        justify-content: center;
    }
    .secondaryLabelTwo {
        margin-top: 0.4em;
        color: ${(props) => props.theme.colors.base.darkGrey};
        font-size: 0.7em;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
    .display {
        display: flex;
        justify-content: center;
    }
    .textWrap {
        white-space: nowrap; 
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .unwatch {
        width: 1em;
        height: 1em;
        text-align: center;
        color: ${(props) => props.theme.colors.base.lightGrey};
    }
    .watch {
        width: 1em;
        height: 1em;
        text-align: center;
        color: ${(props) => props.theme.colors.base.red};
    }
    .imageColor {
        color: ${(props) => props.theme.colors.base.white};
    }
    .serialno {
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
`;

export const StyledPreviewCardContainer = styled.div<{ reserved: any }>`
    border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    margin: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    .borderRightBlue {
        border-right: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .borderBottomBlue {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .secondaryLabel {
        color: ${(props) => props.theme.colors.base.darkGrey};
        font-size: 0.7em;
        display: flex;
        align-items: center;
        margin-left: 5px;
        justify-content: center;
    }
    .secondaryLabelTwo {
        margin-top: 0.4em;
        color: ${(props) => props.theme.colors.base.darkGrey};
        font-size: 0.7em;
        display: flex;
        align-items: center;
        margin-left: 5px;
    }
    .display {
        display: flex;
        justify-content: center;
    }
    .textWrap {
        white-space: nowrap; 
        display: flex;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .unwatch {
        width: 1em;
        height: 1em;
        text-align: center;
        color: ${(props) => props.theme.colors.base.lightGrey};
    }
    .watch {
        width: 1em;
        height: 1em;
        text-align: center;
        color: ${(props) => props.theme.colors.base.red};
    }
    .imageColor {
        color: ${(props) => props.theme.colors.base.white};
    }
    .serialno {
        height: 150px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: ${(props) => (!props?.reserved ? "url(/images/reserve/reserveBadge0.png)" : "")};
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 7em;
        z-index: 1;
        pointer-events: none;
    }
`;

export const StyledLiveAuctionContainer = styled(Grid)`
    flex-grow: 1;
    flex-basis: 16%;
    border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    margin: 25px;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    .centerContent {
        text-align: center;
    }
    .borderRightBlue {
        border-right: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .borderBottomBlue {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .secondaryLabel {
        color: ${(props) => props.theme.colors.base.black};
        font-size: 0.8em;
    }
    .notesContainer {
        padding-bottom: 20px;
    }
    .posRelative {
        position: relative;        
    }
    .posAbsolute {
        position: absolute;
        top: -10px;
        width: 100%;
        background: ${(props) => props.theme.colors.base.white};
    }
    .wishListContainer{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 5px;
    }
    .display{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .fontSize{
        font-size: 2em;
    }
    .labelFontSize{
        font-size: 18px;
    }
    .secondaryLabelFontSize{
        font-size: 16px;
    }
    .notDisablebutton {
        background: ${backgroundGradient};
        cursor: pointer;
    }
    .disableButton {
        cursor: default;
        background: ${(props) => props.theme.colors.base.lightGrey};
    }
`;