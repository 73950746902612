import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import {
  StyledComponent,
  StyledTitleText,
  StyledTextInput,
  StyledModal,
} from "../common/materials/GenericStyleComponent";
import {
  dateBackgroundGradient,
  buttonGradient,
} from "../common/materials/LinearGradient";
import ConfirmationPopupWebsite from "../components/products/ConfirmationPopup";
import { submitFreePersonalized } from "../common/config/app.endpoints";
import AuthService from "../components/auth/AuthService";

const StyledLoginContainer = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 480px;
  }
  .isLogin {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  padding: 0.5em 1em;

  font-size: 2.5em;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const StyledForgotPassword = styled.div`
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
  cursor: pointer;
  padding-top: 1.5em;
  padding-left: 1.5em;
  font-weight: bold;
`;

const StyledRegisterWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  padding: 0.5em;
  padding-left: 1.5em;
  padding-bottom: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
`;

const StyledRegisterNow = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledPasswordCon = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 14px;
  input {
    padding-right: 2em !important;
  }
  i {
    position: absolute;
    top: 34%;
    right: 2%;
    cursor: pointer;
  }
`;

export interface IProps {
  t?: any;
  closePopup: any;
}

const SubdomainForm = (props: IProps) => {
  const { t, closePopup } = props;
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [userFName, setUserFName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const Auth = new AuthService();
  
  const handleClosePopup = () => {
    setConfirmationPopUp(false);
    closePopup();
  };

  const handleChange = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "name") {
      setUserFName(targeValue);
    }
    if (targeName === "email") {
      setUserEmail(targeValue);
    }
    if (targeName === "Companyname") {
      setUserCompanyName(targeValue);
    }
    if (targeName === "Address") {
      setAddress(targeValue.trim());
    }
  };

  const handleOpenPopup = async (e: any) => {
    e.preventDefault();
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(userEmail.trim());
    if (
      userFName.trim() === "" &&
      userCompanyName.trim() === "" &&
      userEmail.trim() === "" &&
      address.trim() === ""
    ) {
      setValidationMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (userFName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onFName")}`);
    } else if (email !== true) {
      setValidationMessage(`${t("common:register:errorMessage:onEmail")}`);
    } else if (userCompanyName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onCompanyName")}`);
    } else if (address.trim() === "") {
      setValidationMessage("Please fill Address");
    } else {
      setValidationMessage("");
      const obj = {
        name: userFName,
        email: userEmail,
        companyName: userCompanyName,
        address: address,
      };
      Auth.postRequest(
        submitFreePersonalized,
        obj,
        (status: number, response: any) => {
          if (status === 200) {
            setConfirmationPopUp(true);
          }
        }
      );
    }
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledLoginContainer>
        <StyledTitle className="fontBold">
          <StyledTitleText style={{ textAlign: "center", color: "#6da7e7" }}>
            {t("common:FreePersonalizedWebsites:websiteForm:EnterDetails")}
          </StyledTitleText>
        </StyledTitle>
        <StyledMessage>{validationMessage}</StyledMessage>
        <StyledTextInput>
          <StyledPasswordCon className="pass-wrapper">
            <TextField
              required
              fullWidth
              name="name"
              id="name"
              label={t("common:FreePersonalizedWebsites:websiteForm:Name")}
              type={"text"}
              variant="outlined"
              value={userFName}
              onChange={handleChange}
            />
          </StyledPasswordCon>
        </StyledTextInput>
        <StyledTextInput>
          <TextField
            required
            fullWidth
            name="email"
            id="name"
            label={t("common:login:labels:email")}
            type="text"
            variant="outlined"
            value={userEmail}
            onChange={handleChange}
          />
        </StyledTextInput>
        <StyledTextInput>
          <TextField
            required
            fullWidth
            name="Companyname"
            id="Companyname"
            label={t("common:FreePersonalizedWebsites:websiteForm:CompanyName")}
            type="text"
            variant="outlined"
            value={userCompanyName}
            onChange={handleChange}
          />
        </StyledTextInput>
        <StyledTextInput>
          <TextField
            required
            fullWidth
            name="Address"
            id="Address"
            label={t("common:FreePersonalizedWebsites:websiteForm:Address")}
            type="text"
            variant="outlined"
            value={address}
            onChange={handleChange}
          />
        </StyledTextInput>

        <StyledButtonContainer>
          <StyledButton onClick={handleOpenPopup}>
            {" "}
            {t("common:kycFrom:submit")}
          </StyledButton>
        </StyledButtonContainer>
      </StyledLoginContainer>

      {confirmationPopUp === true && (
        <StyledModal open={confirmationPopUp} onClose={handleClosePopup}>
          <ConfirmationPopupWebsite {...props} closeModal={handleClosePopup} />
        </StyledModal>
      )}
    </StyledComponent>
  );
};

export default SubdomainForm;
