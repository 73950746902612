import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import { ProductCategory } from "../../../enums/productEnum";

interface ICategory {
  defaultSelected: string;
  selected: string;
  loadPreviousSelected: boolean;
}

interface ICategoryState {
  preview: ICategory;
  auction: ICategory;
  invoice: ICategory;
}

const initialState: ICategoryState = {
  preview: {
    defaultSelected: ProductCategory.DIAMONDS,
    selected: "",
    loadPreviousSelected: false,
  },
  auction: {
    defaultSelected: ProductCategory.DIAMONDS,
    selected: "",
    loadPreviousSelected: false,
  },
  invoice: {
    defaultSelected: ProductCategory.DIAMONDS,
    selected: "",
    loadPreviousSelected: false,
  },
};

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    setPreviewCategory(state, action: PayloadAction<{ category: string }>) {
      state.preview.selected = action.payload.category;
    },
    setAuctionCategory(state, action: PayloadAction<{ category: string }>) {
      state.auction.selected = action.payload.category;
    },
    setInvoiceCategory(state, action: PayloadAction<{ category: string }>) {
      state.invoice.selected = action.payload.category;
    },
  },
});

export const { setPreviewCategory, setAuctionCategory, setInvoiceCategory } =
  categorySlice.actions;

export default categorySlice.reducer;

const getCategoryNameToLoad = (categoryObj: any) => {
  let categoryToLoad = categoryObj.defaultSelected;
  if (categoryObj.loadPreviousSelected) {
    categoryToLoad =
      categoryObj.selected !== ""
        ? categoryObj.selected
        : categoryObj.defaultSelected;
  }
  return categoryToLoad;
};

// selectors
export const selectPreviewCategory = (state: RootState) => {
  return getCategoryNameToLoad(state.category.preview);
};

export const selectAuctionCategory = (state: RootState) => {
  return getCategoryNameToLoad(state.category.auction);
};

export const selectInvoiceCategory = (state: RootState) => {
  return getCategoryNameToLoad(state.category.invoice);
};
