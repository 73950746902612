import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollSpy() {
  const { pathname } = useLocation();

  const hash = window.location.hash.replace("#", "");
  useEffect(() => {
    const inView = document.getElementById(hash);
    if (!inView) return;
    const headerOffset = 60;
    const elementPosition = inView.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }, [pathname, hash]);

  return null;
}
