import React from "react";
import { StyledContainer, StyledLink } from "./StyledComponents";
import Grid, { GridTypeMap } from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

interface IProps {
  label?: string;
  children?: any;
  color?: string;
  pageLink?: string;
  LinkComponent?: any;
  isMobile?: boolean;
  alignItems?: string;
  justifyContent?: string;
  className?: string;
  container?: boolean;
  ref?: React.Ref<any>;
  id?: string;
}

const useStyles = makeStyles({
  maxWidthLg: {
    paddingRight: 0,
  },
  container: {
    marginBottom: "1em",
  },
  marginRight: {
    marginRight: "1em",
  },
});

const Section = ({
  label,
  color,
  children,
  pageLink,
  isMobile,
  alignItems,
  justifyContent,
  container,
  LinkComponent,
  ref,
  id,
  ...props
}: IProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <StyledContainer id={id} ref={ref} item xs={12} color={color} {...props}>
      <Container classes={isMobile ? classes : {}}>
        <Grid
          classes={classes}
          container
          justifyContent="space-between"
          alignContent="center"
        >
          {label && (
            <strong
              style={{
                textTransform: "capitalize",
                paddingLeft: isMobile ? 0 : "1%",
                fontSize: isMobile ? "1.2em" : "1em",
                fontWeight: 'bolder'
              }}
            >
              {label}
            </strong>
          )}
          {pageLink && (
            <StyledLink className={classes.marginRight} to={pageLink}>
              <strong>{t("common:Tender:tableColumnNames:ShowAll")}</strong>
            </StyledLink>
          )}
          {LinkComponent && <LinkComponent />}
        </Grid>
        <Grid container>{children}</Grid>
      </Container>
    </StyledContainer>
  );
};

export default Section;
