import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import ProductsListGrid from "./ProductsListGrid";
import IRouterComponentProps from "../../../models/IRouterComponentProps";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    min-height: 200px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    min-height: 400px;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    min-height: 600px;
  }
`;


interface IProducts {
  id: number | string;
  name: string;
  brandName?: string;
  price: number;
  serialNumber: number;
  preBiddingPrice: number;
}

interface IProps {
  t: any;
  selectedPage?: boolean;
  history?: any;
  currentRecords?: IProducts;
  watchlist: any;
  auctionDate: any;
  removeFromWatchlist: any;
  auctionName: string;
  dataSort: (price: any, val: any) => void;
  selectedExportId: any[];
  selectItemInGridView: (id: any) => void;
  prebidCall: any;
}

interface IState {
  products: [];
  openToast: boolean;
  showAlertMessage: string;
}

export interface IProductsProps extends IRouterComponentProps {
  t?: any;
  currentRecords?: IProducts;
}

export default class ProductListView extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      products: [],
      openToast: false,
      showAlertMessage: "",
    };
  }

  setProducts(products: any) {
    this.setState(() => ({
      products,
    }));
  }

  componentDidMount() {
    this.setProducts(this.props.currentRecords);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentRecords !== this.props.currentRecords) {
      this.setProducts(prevProps.data);
    }
  }

  showAlert = (message: string) => {
    this.setState({
      openToast: true,
      showAlertMessage: message,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <StyledGrid container={true} spacing={0}>
        {this.state.products.length > 0 &&
          this.state.products.map((product: any, index: number) => {
            return (
              <Grid
                key={product.perId}
                item={true}
                xs={12}
                sm={6}
                md={6}
                lg={3}
                xl={2}
              >
                <ProductsListGrid
                  {...this.props}
                  {...product}
                  items={product}
                  index={index}
                  t={t}
                  auctionDate={this.props.auctionDate}
                  showAlert={this.showAlert}
                  addToWatchList={this.props.watchlist}
                  removeFromWatchlist={this.props.removeFromWatchlist}
                  sortingValue={this.props.dataSort}
                  selectedExportId={this.props.selectedExportId}
                  selectItemInGridView={this.props.selectItemInGridView}
                  prebidCall={this.props.prebidCall}
                />
              </Grid>
            );
          })}
      </StyledGrid>
    );
  }
}
