import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { StyledButton } from '../../../common/materials/GenericStyleComponent';
import { useTranslation } from 'react-i18next';

const StyledComponent = styled(Grid)`
    outline: none;
    .text {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 2em;
        margin-bottom: 10px;
        font-family: Calibre-Regular;
        color: ${(props) => props.theme.colors.base.grey};
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 1.5em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
    }
`;

const StyledTextWrapper = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1.5em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledAuctionEndedWrapper = styled(Grid)`
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;

export interface IProps extends IRouterComponentProps {
    t?: any;
    closeModal: () => void;
}

const ReadOnlyUserModal = React.forwardRef((props: IProps, ref) => {
  const { closeModal } = props;
  const { t } = useTranslation();
  const onClickOkButton = () => {
    closeModal();
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {" "}You are the read only user.
            </StyledTextWrapper>
          <StyledButton onClick={onClickOkButton}>
            {t("common:button:ok")}
          </StyledButton>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
});

export default ReadOnlyUserModal;