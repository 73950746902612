import * as React from "react";

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
    >
      <g
        id="Group_429"
        data-name="Group 429"
        transform="translate(-1121 -1411)"
      >
        <circle
          id="Ellipse_247"
          data-name="Ellipse 247"
          cx="22"
          cy="22"
          r="22"
          transform="translate(1121 1411)"
          fill="#0a4e9a"
          opacity="0.001"
        />
        <g id="Menu" transform="translate(1100 1388)">
          <rect
            id="Rectangle_175"
            data-name="Rectangle 175"
            width="16"
            height="16"
            transform="translate(35 37)"
            fill="none"
          />
          <path
            id="Union_4"
            data-name="Union 4"
            d="M0,14V12H8v2ZM0,8V6H16V8ZM0,2V0H16V2Z"
            transform="translate(35 38)"
            fill="#293139"
          />
        </g>
      </g>
    </svg>
  );
};

export default Menu;
