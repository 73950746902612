import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import moment from "moment-timezone";
import GoogleEventComponent from "./googleEventComponent/GoogleEventComponent";

const StyledComponent = styled(Grid)`
  outline: none;
  .heading {
    color: ${(props) => props.theme.colors.base.white};
    font-size: 1em;
    font-weight: bold;
    border-top: 1px solid ${(props) => props.theme.colors.base.white};
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    padding: 0.5em 0.5em;
  }
  .previewHeading {
    color: ${(props) => props.theme.colors.base.white};
    font-size: 1em;
    font-weight: bold;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    padding: 0.5em 0.5em;
  }
  .time {
    margin-top: 0.2em;
    justify-content: center;
    padding: 0.5em;
    font-size: 1em;
    border-top: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    text-align: center;
    font-weight: bold;
  }
  .nameContainer {
    text-align: center;
    padding: 0.5em;
    font-size: 1em;
    font-weight: bold;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .border {
    border-left: 0.5px solid
      ${(props) => props.theme.colors.base.buttonGradientThirdColor};
    border-bottom: 0.5px solid
      ${(props) => props.theme.colors.base.buttonGradientThirdColor};
  }
  .auctionBorder {
    border-bottom: 0.5px solid
      ${(props) => props.theme.colors.base.buttonGradientThirdColor};
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  border-radius: 8px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.scrollerColor};
    border-radius: 5px;
  }
  max-height: 600px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    width: 450px;
  }
`;

const StyledContainer = styled(Grid)`
  text-align: center;
`;

const StyledDateWrapper = styled.div`
  position: sticky;
  top: 0;
  font-size: 1.8em;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 0.5em 0em;
  font-family: Calibre-Regular;
  background: rgba(1, 74, 153, 1);
  color: ${(props) => props.theme.colors.base.white};
`;

const StyledHeadingContainer = styled(Grid)`
  display: flex;
`;

const StyledDetailsWrapper = styled(Grid)`
  text-align: center;
`;

const StyledHeadingWrapper = styled(Grid)``;

export interface IProps {
  t: any;
  onClose: () => void;
  auctions: any;
  previews: any;
  selectedDate: string;
  redirectionPreviweHandler: (product: any, selectedTimeValue: any) => void;
  redirectionAuctionHandler: (auction: any, selectedTimeValue: any) => void;
  appointmentList: any;
}

let timezone: any = "";
let date = "";
let time: any = "";

let selectedTime: string = "";
const SelectedDatePreviewAuction = (props: IProps) => {
  const getAuction = (items: any) => {
    const { t } = props;

    if (items.time === "Full Day") {
      time = t("common:home:heading:fullDay");
    } else {
      date = props.selectedDate + " " + items.time;
      let auctionTime = moment.utc(date);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (auctionTime !== null || auctionTime !== undefined) {
        let result = moment.tz(auctionTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          time = splitTime[0];
        }
      }
    }
  };

  const getPreviews = (items: any) => {
    const { t } = props;
    if (items.time === "Full Day") {
      time = t("common:home:heading:fullDay");
    } else {
      date = props.selectedDate + " " + items.time;
      let previewTime = moment.utc(date);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          time = splitTime[0];
        }
      }
    }
  };

  const renderAppointmentSection = () => {
    if (props.appointmentList !== null) {
      return props.appointmentList?.map((product: any, index: number) => {
        let splitDateTime = product.start_time.split("T", 2);
        return (
          <Grid key={index} className="border">
            <div style={{ display: "flex", alignItems: "center" }}>
              <Grid
                className="nameContainer"
                item
                xs={12}
                style={{
                  color: "rgba(1, 74, 153, 1)",
                  cursor: "pointer",
                }}
              >
                {product.name}
              </Grid>
            </div>
            <div className="time">
              {splitDateTime[0]}
            </div>
          </Grid>
        );
      });
    }
  };

  const { t } = props;
  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledDetailsWrapper item md={12} xs={12} lg={12}>
          <StyledDateWrapper>{props.selectedDate}</StyledDateWrapper>
          <StyledHeadingContainer item xs={12}></StyledHeadingContainer>
          <StyledHeadingWrapper container>
            {props.auctions.length > 0 && (
              <StyledContainer item xs={12}>
                <Grid className="heading" item xs={12}>
                  {t("common:home:heading:auction")}
                </Grid>
                {props.auctions.map((product: any, index: number) => {
                  getAuction(product);
                  return (
                    <Grid key={index}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Grid
                          item
                          xs={1}
                          style={{
                            color: "rgba(1, 74, 153, 1)",
                            cursor: "pointer",
                          }}
                        >
                          <GoogleEventComponent selectedEvent={product} />
                        </Grid>
                        <Grid
                          className="nameContainer"
                          item
                          xs={11}
                          style={{
                            color: "rgba(1, 74, 153, 1)",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            props.redirectionAuctionHandler(
                              product,
                              selectedTime
                            )
                          }
                        >
                          {product.name}
                        </Grid>
                      </div>
                      <div
                        className="time auctionBorder"
                      >
                        {time}
                      </div>
                    </Grid>
                  );
                })}
              </StyledContainer>
            )}
            {props.previews.length > 0 && (
              <StyledContainer item xs={12}>
                <Grid className="previewHeading" item xs={12}>
                  {t("common:home:heading:preview")}
                </Grid>
                <div>
                  {props.previews.map((product: any, index: number) => {
                    getPreviews(product);
                    return (
                      <Grid key={index} className="border">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Grid
                            item
                            xs={1}
                            style={{
                              color: "rgba(1, 74, 153, 1)",
                              cursor: "pointer",
                            }}
                          >
                            <GoogleEventComponent selectedEvent={product} />
                          </Grid>
                          <Grid
                            className="nameContainer"
                            item
                            xs={11}
                            style={{
                              color: "rgba(1, 74, 153, 1)",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              props.redirectionPreviweHandler(
                                product,
                                selectedTime
                              )
                            }
                          >
                            {product.name}
                          </Grid>
                        </div>
                        <div className="time">{time}</div>
                      </Grid>
                    );
                  })}
                </div>
              </StyledContainer>
            )}
            {props.appointmentList !== null &&
              props.appointmentList.length > 0 && (
                <StyledContainer item xs={12}>
                  <Grid className="previewHeading" item xs={12}>
                    {t("common:home:legend:appointment")}
                  </Grid>
                  <div>{renderAppointmentSection()}</div>
                </StyledContainer>
              )}
          </StyledHeadingWrapper>
        </StyledDetailsWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
};

export default React.memo(SelectedDatePreviewAuction);
