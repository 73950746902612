import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FilterListIcon from "@material-ui/icons/FilterList";
import GradingList from "../../products/preview/GradingList";
import ColorsList from "../../products/preview/ColorsList";
import ShapeList from "./ShapeList";
import { useTranslation } from "react-i18next";
const styles: any = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

interface Iprops {
  resetfilter: (id: any) => void;
  filteredpropduct: (isboolean: boolean, data: any) => void;
  iswatchlist: any;
  isSellPage: boolean;
}
let localArray: any = [];

export default function FilterPopup(props: Iprops) {
  let { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    lab: "",
    desc: "",
    color: "",
    remarks: "",
    shape: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleReset = () => {
    setData({
      lab: "",
      desc: "",
      color: "",
      remarks: "",
      shape: "",
    });
    localArray = [];
    props.resetfilter(0);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const localArray: any = [];

    const addFilter = (name: any, value: any, match = "in") => {
      localArray.push({ match, name, value });
    };

    const { lab, color, remarks, shape, desc } = data;
    const { iswatchlist } = props;

    if (lab !== "") {
      const labField =
        iswatchlist === "WatchList&Prebid"
          ? "product.lab,product.subItems.lab"
          : "lab,subItems.lab";
      const labArray = lab.split(",").map((item) => item.trim());
      addFilter(labField, labArray);
    }

    if (color !== "") {
      const colorArray = color.split(",").map((item) => {
        if (["UNDER N", "UNDER S", "Fancy"].includes(item.trim())) {
          return item.trim().toUpperCase();
        }
        return item.trim();
      });
      const colorField =
        iswatchlist === "WatchList&Prebid"
          ? "product.color,product.subItems.color,product.multiColor,product.subItems.multiColor"
          : "color,subItems.color,multiColor,subItems.multiColor";
      addFilter(colorField, colorArray, "in");
    }

    if (remarks !== "") {
      const remarksField =
        iswatchlist === "WatchList&Prebid"
          ? "product.remarks,product.subItems.remarks"
          : "remarks,subItems.remarks";
      addFilter(remarksField, remarks.trim(), "anywhere");
    }

    if (shape !== "") {
      const objVal = ShapeList.find((element) => element.value === shape);
      const shapeArray = shape.split(",").map((item) => item.toUpperCase().trim());

      if (objVal) {
        shapeArray.push(objVal.abbreviationValue);
      }

      const shapeField =
        iswatchlist === "WatchList&Prebid"
          ? "product.shape,product.subItems.shape,product.multiShape,product.subItems.multiShape"
          : "shape,subItems.shape,multiShape,subItems.multiShape";
      addFilter(shapeField, shapeArray, "in");
    }

    if (desc !== "") {
      const descField =
        iswatchlist === "WatchList&Prebid"
          ? "product.productSearchDescription"
          : "productSearchDescription";
      addFilter(descField, desc.trim(), "anywhere");
    }
    localStorage.setItem("myPagefilteredValues", JSON.stringify(localArray));
    props.filteredpropduct(true, localArray);

  };

  return (
    <div>
      <FilterListIcon style={{ cursor: "pointer" }} onClick={handleClickOpen} />
      <Dialog onClose={handleClose} open={open} scroll={"paper"}>
        <DialogTitle onClose={handleClose}>{t("common:newCommanTrans:mypage:filters")}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                value={data.lab}
                onChange={(e) => {
                  setData({ ...data, lab: e.target.value });
                }}
                fullWidth
                select
                label={t("common:Tender:tableColumnNames:lab")}
              >
                {GradingList.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                value={data.desc}
                onChange={(e) => {
                  setData({ ...data, desc: e.target.value });
                }}
                fullWidth
                label={t("common:newCommanTrans:productdescription")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={data.color}
                onChange={(e) => {
                  setData({ ...data, color: e.target.value });
                }}
                fullWidth
                select
                label={t("common:Tender:tableColumnNames:color")}
              >
                {ColorsList.map((option: any) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                value={data.remarks}
                onChange={(e) => {
                  setData({ ...data, remarks: e.target.value });
                }}
                fullWidth
                label={t("common:Tender:tableColumnNames:remark")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                value={data.shape}
                onChange={(e) => {
                  setData({ ...data, shape: e.target.value });
                }}
                fullWidth
                select
                label={t("common:Tender:tableColumnNames:shape")}
              >
                {ShapeList.map((option: any) => (
                  <MenuItem key={option.name} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              localStorage.removeItem("myPagefilteredValues");
              handleReset();
            }}
          >
            {t("common:newCommanTrans:mypage:reset")}
          </Button>
          <Button autoFocus onClick={handleSubmit} color="primary">
            {t("common:newCommanTrans:mypage:filter")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
