import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducer";
import api from "../../../services/ApiService";
import { INegotiateHoldProductRequest, IPendingProductsRequest, IPendingProductState, IRequestByFilterPending } from "./pendingProductSliceInterface";
import { negotiateAllpendingProduct, negotiatependingProduct, negotiatependingProductPostAPI } from "../../../common/config/app.endpoints";
import AuthService from "../../../components/auth/AuthService";



const initialState: IPendingProductState = {
  loading: false,
  error: "",
  pendingProductList: [],
};

export let productList: [];
const Auth = new AuthService();


export const negotiateHoldProduct = createAsyncThunk<
  INegotiateHoldProductRequest,
  Partial<INegotiateHoldProductRequest>,
  { rejectValue: any }
>("pendingproduct/negotiateHoldProduct", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(negotiatependingProductPostAPI, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPendingProductList = createAsyncThunk<
  IPendingProductsRequest,
  Partial<IPendingProductsRequest>,
  { rejectValue: any }
>(
  "pendingproduct/getPendingProductList",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { auctionId, sortField, sortOrder } = requestObj;
      let url = "";
      const isEndpointPublic = auctionId === null ? false : true;
      if (isEndpointPublic) {
        url = `${negotiatependingProduct}/${auctionId}/products`;
      } else {
        // url = `${negotiateAllpendingProduct}`;
        url = `${negotiateAllpendingProduct}?sortField=${sortField}&sortOrder=${sortOrder}`;
      }
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPendingProductSortingList = createAsyncThunk<
  IRequestByFilterPending,
  Partial<IRequestByFilterPending>,
  { rejectValue: any }
>(
  "pendingproduct/getPendingProductSortingList",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { sortField, sortOrder } = requestObj;
      let url = "";
      url = `${negotiateAllpendingProduct}?sortField=${sortField}&sortOrder=${sortOrder}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPendingProductListReact = (requestObj: any) => {
  const { auctionId, sortField, sortOrder } = requestObj;
  const isEndpointPublic = auctionId === null ? false : true;
  let url = "";
  if (isEndpointPublic) {
    url = `${negotiatependingProduct}/${auctionId}/products`;
  } else {
    url = `${negotiateAllpendingProduct}?sortField=${sortField}&sortOrder=${sortOrder}`;
  }
  Auth.getRequest(url, (status: number, response: any) => {
    if (status === 200) {
      productList = response;
    }
  });
};

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};


const pendingProductsSlice = createSlice({
  name: "pendingproduct",
  initialState,
  reducers: {
    resetPendingProductList(state, action: PayloadAction) {
      state.pendingProductList = [];
    },
  },
  extraReducers: (builder: any) => {
    // Get All Pending Product
    builder.addCase(getPendingProductList.pending, genericPendingCaseHandler);
    builder.addCase(
      getPendingProductList.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.pendingProductList = productList;
      }
    );
    builder.addCase(
      getPendingProductList.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || "Error";
        state.loading = false;
      }
    );
    builder.addCase(getPendingProductSortingList.pending, genericPendingCaseHandler);
    builder.addCase(
      getPendingProductSortingList.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.pendingProductList = action.payload;
      }
    );
    builder.addCase(
      getPendingProductSortingList.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || "Error";
        state.loading = false;
      }
    );
  },
});

export const {
  resetPendingProductList
} = pendingProductsSlice.actions;

export default pendingProductsSlice.reducer;

// selectors
export const selectPendingProductsLoading = (state: RootState) =>
  state.pendingproduct.loading;

export const selectPendingProductList = (state: RootState) =>
  state.pendingproduct.pendingProductList;