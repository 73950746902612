const LanguageConstants = {
  //JP: "日本語",
  JP: "JP",
  EN: "EN",
  zh_CN: "zh_CN",
};

export const LanguageCode = {
  CODE_SMALL_EN: "en",
  CODE_SMALL_JP: "jp",
  CODE_SMALL_ZH_CN: "ZH_CH",
  CODE_CAPS_EN: "EN",
  CODE_CAPS_JP: "JP",
  CODE_CAPS_ZH_CN: "zh_CN",
};

export default LanguageConstants;
