import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api from "../../../services/ApiService";

import { advertisementUrl } from "../../../common/config/app.endpoints";
import { IAdsListPayload, IAdvertisementState } from "./adsSliceInterface";

const initialState: IAdvertisementState = {
  loading: false,
  error: "",
  advertisementList: [],
  totalElements: 0,
};

export const getAdvertisementList = createAsyncThunk(
  "ads/getAdvertisementList",
  async (data, thunkAPI) => {
    try {
      const response = await api.get(advertisementUrl);
      return {
        results: response.data.results,
        totalElements: response.data.totalElements,
      };
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      //return rejectWithValue(err.response.data);
      return error;
    }
  }
);

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const advertisementSlice = createSlice({
  name: "advertisement",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Get All Joined Auctions
    builder.addCase(getAdvertisementList.pending, genericPendingCaseHandler);
    builder.addCase(
      getAdvertisementList.fulfilled,
      (state: any, action: PayloadAction<IAdsListPayload>) => {
        state.loading = false;
        state.advertisementList = action.payload.results;
        state.totalElements = action.payload.totalElements;
      }
    );
    builder.addCase(
      getAdvertisementList.rejected,
      (state: any, action: any) => {
        state.error = action.error?.message || "Error";
        state.loading = false;
      }
    );
  },
});

export default advertisementSlice.reducer;

// selectors
export const selectAdvertisementLoading = (state: RootState) =>
  state.advertisement.loading;
export const selectAdvertisementList = (state: RootState) =>
  state.advertisement.advertisementList;
