import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { Box, Typography, Button } from "@mui/material";
const StyledComponent = styled(Grid)`
  outline: none;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;


interface Iprops {
  paddleNumber: any;
  closeModal: () => void;
}
function PaddleNumberModal(props: Iprops) {
  let { closeModal, paddleNumber } = props;
  return (
    <StyledComponent>
      <Box
        sx={{
          width: "300px",
          height: "400px",
          background: "white",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" fontWeight={600}>
          Your Paddle number is
        </Typography>
        <Box
          sx={{
            background: "url(/images/paddel/paddelSvg.svg)",
            height: "200px",
            width: "250px",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            mx: "auto",
            my: "40px",
            backgroundPosition: "center center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight={600}
            sx={{ marginTop: "35px", marginLeft: "11px" }}
          >
            {paddleNumber === 0 ? "-" : paddleNumber}
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{ width: "30%", mx: "auto" }}
          onClick={closeModal}
        >
          OK
        </Button>
      </Box>
    </StyledComponent>
  );
}

export default PaddleNumberModal;
