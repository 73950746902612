import React, { useState } from "react";
import {
  StyledBorderContainer,
  StyledContainer,
  StyledDescriptionContainer,
  StyledHeading,
  StyledImage,
  StyledImageContainer,
  StyledLink,
} from "./StyledComponent";
import { LandingPagePlayFairFont } from "../StyledComponents";
import Arrow from "../Static/Icons/Arrow";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  redirectToOtherDomain,
  setRedirectPathPostAuth,
  toggleLoginPopup,
} from "../Utils";
import { useMediaQuery } from "@material-ui/core";

export interface DescriptionProps {
  heading?: string;
  description?: string;
  imageSrc: string;
  link?: string;
  redirect?: boolean;
  redirectToOtherDomain?: boolean;
}

export const CardContent = ({
  heading,
  description,
  imageSrc,
  link,
  redirect,
}: DescriptionProps) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    if (link?.includes("zipang")) {
      return redirectToOtherDomain("zipang");
    }
    if (link?.includes("auctioneer")) {
      return redirectToOtherDomain("auctioneer");
    }
    if (redirect) {
      link && history.push(link);
    } else {
      link && setRedirectPathPostAuth(link);
      toggleLoginPopup();
    }
  };

  return (
    <>
      <StyledBorderContainer onClick={handleClick}>
        <StyledImageContainer>
          <StyledImage loading="lazy" src={imageSrc}></StyledImage>
          <LandingPagePlayFairFont>
            <StyledHeading>{heading}</StyledHeading>
          </LandingPagePlayFairFont>
          {link && (
            <StyledLink onClick={handleClick}>
              <Arrow direction="right" filled />
            </StyledLink>
          )}
        </StyledImageContainer>
      </StyledBorderContainer>
      {description && (
        <StyledDescriptionContainer>
          {t(description)}
        </StyledDescriptionContainer>
      )}
    </>
  );
};

const DescriptionCard = (props: DescriptionProps) => {
  return (
    <StyledContainer item xs={12} sm={6} md={4}>
      <CardContent {...props} />
    </StyledContainer>
  );
};

export default DescriptionCard;
