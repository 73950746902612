import React from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from "../layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import DetailsCarousel, {
  StyledGrid,
  StyledNotAvailableImage,
  StyledProductImage,
} from "./details/DetailsCarousel";
import DetailsNotes from "./details/DetailsNotes";
import AuthService from "../auth/AuthService";
import {
  auctionProductUrl,
  productUrl,
  watchlistUrl,
} from "../../common/config/app.endpoints";
import PerItemDescription from "./details/PerItemDescription";
import Alert from "../../common/elements/Alert";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import ApiTimeout from "../../modules/ApiTimeout";
import AddorRemoveWatchlist from "./preview/AddorRemoveWatchlist";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import BlockUserModal from "./BlockUser/BlockUserModal";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import { buttonGradient } from "../../common/materials/LinearGradient";
import ShareProductLinkView from "./ShareProductLinkView";
import copy from "copy-to-clipboard";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { StyledInquiryContainer } from "./details/inquiry/InquiryDetailsCss";
import ProductDetailsInquiry from "./details/inquiry/ProductDetailsInquiry";
import BasicPopover from "./preview/exratemarquee/BasicPopover";
import ProductDescription from "../shared/ProductDescription";
import BidNowButtonComponet from "./preview/BidNowButtonComponet";
import { getBiddingProductDetails } from "../../redux/slice/commonProduct/commonProductDetailsSlice";
import { Box } from "@mui/material";
import { getRapnetPercentage } from "../../common/GenericFunctions";
import ProductCarouselNew from "./newProductDetails/container/ProductCarouselNew";
// import { count } from 'node:console';

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
  padding: 50px 70px;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    padding: 30px 8px 30px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 8px;
  }
`;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;
const StyledBackBtnContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  .spanText {
    font-size: 1em;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;
const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 5px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 600px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 5px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 10px;
  }
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
  justify-content: end;
  align-items: center;
  display: flex;
}
`;

const StyledLabel = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  padding-left: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.5em;
  text-align: center;
`;

const StyledArrowWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.1em;
  padding: 0.5em;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledTooltipContainer = styled(Grid)``;

const StyledTooltipText = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 12px;
`;

export const StyledBidContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.darkGrey};
`;
export const StyledMyBidsButton = styled(Grid)`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: 50px;
`;
export const StyledMyBidButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.8em;
  padding: 0.4em;
  border-radius: 5px;
  width: 80%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
export const StyledMyBidButtons = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.Black};
  border: none;
  font-size: 0.8em;
  padding: 0.4em;
  border-radius: 5px;
  width: 80%;
  background: ${(props) => props.theme.colors.base.lightgrey};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

export const StyledPriceSection = styled(Box)`
  font-size: 2em;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 1.2em;
  }
`;

const StyledPrebidingContainer = styled(Box)`
  color: #2660ad;
  border: 4px solid #2660ad;
  background: #bcd2e8;
  padding: 10px;
  margin: 10px;
  font-size: 1.2em;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 1em;
  }
`;
interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: string;
}

interface IState {
  items: any;
  openToast: boolean;
  showAlertMessage: string;
  index: number;
  isLoading: boolean;
  alertType: string;
  auctionProducts: Array<any>;
  totalElements: string | number;
  apiTimeout: boolean;
  userBlock: boolean;
  openShareModal: boolean;
  isPDView: boolean;
  count: number;
  fullData: any;
  openInquiry: boolean;
  showTenderSlider: boolean;
}

interface IProductDetailsViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let auctionDate: string = "";
let productList: [] = [];
let filteredValues: any = "";
let joinedAuctions: any = [];
let body: any = "";
let data = {};
var filter: [] | null | string = [];
var productID: any = "";
let isRedirectFromAuctionPage: any;
let isAuctionEnd: any;
let isAuctioneerUserId: any = "";
let isReadOnlyUser: any;
let redirectfromAdmin: any;
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    cursor: "pointer",
    color: "#2d75c2",
  },
  tooltip: {
    fontSize: "12px",
    backgroundColor: "#2d75c2",
  },
}));

const TooltipContant = (props: JSX.IntrinsicAttributes & TooltipProps) => {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
};

// var subItemArr = TempArr;
export default class TendorDetailsViewNew extends React.Component<
  IProductDetailsViewProps,
  IState
> {
  constructor(props: IProductDetailsViewProps) {
    super(props);
    this.state = {
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
      userBlock: false,
      openShareModal: false,
      isPDView: true,
      count: 0,
      fullData: "",
      openInquiry: false,
      showTenderSlider: false,
    };
  }

  Auth = new AuthService();

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    data = {};
    body = "";
    filter = [];
    if (this.props.match.params.auctionDate !== undefined) {
      auctionDate = this.props.match.params.auctionDate;
    }
    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);
    if (filteredValues !== null) {
      filteredValues.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          filteredValues.splice(index, 1);
        }
        return true;
      });
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);

    this.auctionProducts();
    this.getDetails(this.props.match.params.productId);

    window.addEventListener("keydown", this.keyHandling);

    const urlParams = new URLSearchParams(window.location.search);
    isRedirectFromAuctionPage = urlParams.get("isAuction");
    isAuctioneerUserId = urlParams.get("uniqid");
    isAuctionEnd = urlParams.get("isAuctionend");
    redirectfromAdmin = urlParams.get("redirectFromPreview");
  }

  componentWillUnmount() {
    this.setState({
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: "",
      apiTimeout: false,
    });
    localStorage.removeItem("pathname");
    window.removeEventListener("keydown", this.keyHandling);
  }

  auctionProducts() {
    this.setState({
      openToast: false,
    });
    body =
      filteredValues === "null" || filteredValues === null
        ? filter
        : filteredValues;
    body.push({
      match: "exact",
      name: "auctionId",
      value: this.props.match.params.auctionId,
    });
    data = {
      filters: {
        and: body,
      },
      page: {
        start: 0,
        count: 100,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Diamonds",
            },
          ],
        },
        page: {
          start: 0,
          count: 20,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
      localStorage.removeItem("optionValue");
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "notUpdatedProducts");
    }
  }

  getUpdatedProducts = (records: number) => {
    data = {
      filters: {
        and: body,
      },
      page: {
        start: records,
        count: 100,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Diamonds",
            },
          ],
        },
        page: {
          start: records,
          count: 20,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "updatedProducts");
    }
  };

  callApi(data: any, product: string) {
    const { t } = this.props;
    var url = "";
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      url =
        watchlistUrl +
        `?auctionId=${this.props.match.params.auctionId}&auctionType=Diamonds`;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      redirectfromAdmin = urlParams.get("redirectFromPreview");
      console.log(redirectfromAdmin);
      if (redirectfromAdmin === "true") {
        url =
          auctionProductUrl +
          `?auctionId=${this.props.match.params.auctionId}&redirectFromPreview=true`;
      } else {
        url =
          auctionProductUrl + `?auctionId=${this.props.match.params.auctionId}`;
      }
    }
    this.Auth.postRequest(`${url}`, data, (status: any, response: any) => {
      if (status === 200) {
        if (product === "updatedProducts") {
          var local: any = {};
          local = response.results;
          this.state.auctionProducts.push.apply(
            this.state.auctionProducts,
            local
          );
        } else {
          this.setState({
            auctionProducts: response.results,
          });
        }
        if (response.results.length === 0) {
          if (
            isAuctionEnd === null ||
            isAuctionEnd === undefined ||
            isAuctionEnd === ""
          ) {
            this.props.history.push("/preview");
          }
        }
        this.setState({
          totalElements: response.totalElements,
        });
        this.getProducts(this.state.auctionProducts);
      } else if (status === 403) {
        this.setState({ userBlock: true });
      } else if (status === 404) {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:invalidRequest")}`,
          isLoading: false,
        });
      } else if (status === 505) {
        this.setState({
          apiTimeout: true,
        });
      } else {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:somethingWentWrong")}`,
          isLoading: false,
        });
      }
    });
  }

  getProducts(products: any) {
    productList = products;
    if (this.state.auctionProducts.length < this.state.totalElements) {
      this.getUpdatedProducts(this.state.auctionProducts.length);
    } else {
      this.checkProductId();
    }
  }

  checkProductId() {
    if (productList !== null) {
      productList.map((product: any, index: number) => {
        if (product.perId === this.props.match.params.productId) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          productID = product.perId;
        }
        return true;
      });
    }
  }

  getDetails = (productId: string | number) => {
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.getRequest(
        `${productUrl}/${productId}/detail`,
        (status: number, response: any) => {
          localStorage.setItem("auctionState", response.auctionState);
          this.setState({
            items: "",
          });
          if (status === 200) {
            if (response.subItems) {
              var rows = [];

              for (var i = 0; i < response.subItems.length; i++) {
                let obj = response.subItems[i];
                if (obj.medias !== undefined) {
                  for (var j = 0; j < obj.medias.length; j++) {
                    rows.push(obj.medias[j]);
                  }
                }
              }

              var objModify = response;
              objModify.medias = objModify.medias.concat(rows);

              this.setState({
                items: objModify,
                isLoading: false,
                fullData: response,
                count: 0,
              });
            } else {
              this.setState({
                items: response,
                isLoading: false,
                fullData: response,
                count: 0,
              });
            }
          } else if (status === 403) {
            this.setState({ userBlock: true });
          } else if (status === 505) {
            this.setState({
              apiTimeout: true,
              isLoading: false,
            });
          } else {
            this.setState({
              alertType: "error",
              openToast: true,
              showAlertMessage: response,
              isLoading: false,
            });
          }
        }
      );
    }
  };

  keyHandling = (e: any) => {
    if (e.keyCode === 37) {
      this.previousButton();
    } else if (e.keyCode === 39) {
      this.nextArrow();
    }
  };

  previousButton = () => {
    this.setState({
      openToast: false,
    });
    this.state.auctionProducts.map((product: any, index: number) => {
      if (this.state.index > 0) {
        var updatedIndex = this.state.index - 1;
        if (updatedIndex === index) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          localStorage.setItem("currentProductIdOfBidNow", product.perId);
          if (
            this.props.match.path ===
            "/watchlist/details/:auctionId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else if (
            this.props.match.path ===
            "/preview/tender/details/:auctionId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/preview/tender/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else {
            this.props.history.push({
              pathname: `/home/tendor/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
            });
          }
        }
      }
      return true;
    });
  };

  handleBackBtn = () => {
    if (this.state.items && this.state.items.serialNo) {
      localStorage.setItem(
        "currentPDItem",
        JSON.stringify(this.state.items.serialNo)
      );
      let filteredValue = this.state.auctionProducts.filter(
        (value) => value.serialNo === this.state.items.serialNo
      );
      localStorage.setItem(
        "currentactivePdPage",
        JSON.stringify(this.state.items.serialNo)
      );

      let index = this.state.auctionProducts.indexOf(filteredValue[0]);
      // login to check if user has increased items per page

      if (localStorage.getItem("tenderitemsPerPage")) {
        let savedCount: any = localStorage.getItem("tenderitemsPerPage");
        let endCount: number = parseInt(savedCount);
        let sum = (index + 1) / endCount;
        let page = Math.ceil(sum);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      } else if (localStorage.getItem("itemPerPageSelected")) {
        let savedCount: any = localStorage.getItem("itemPerPageSelected");
        let endCount: number = parseInt(savedCount);
        let sum = (index + 1) / endCount;
        let page = Math.ceil(sum);
        localStorage.setItem("currentactivePdPage", JSON.stringify(page));
      } else {
        let page = Math.ceil((index + 1) / 10);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      }
    }

    if (this.state.items && this.state.items.parcelType === "Diamond") {
      localStorage.setItem("tabSelectedVal", "1");
    } else {
      localStorage.setItem("tabSelectedVal", "2");
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 0;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }
    this.props.history.push(`/preview`);

    localStorage.removeItem("currentProductIdOfBidNow");
    if (isRedirectFromAuctionPage !== undefined) {
      if (isRedirectFromAuctionPage === "true") {
        this.props.history.push({
          pathname: `/auction/tender/details/${this.props.match.params.auctionId}/${isAuctioneerUserId}`,
        });
      } else {
        if (isRedirectFromAuctionPage === "false") {
          if (redirectfromAdmin === "true") {
            this.props.history.push({
              pathname: `/preview/tendor/products/${this.props.match.params.auctionId}/${isAuctioneerUserId}/${this.state.items.auctionName}/${auctionDate}?source=admin`,
            });
          } else {
            this.props.history.push({
              pathname: `/preview/tendor/products/${this.props.match.params.auctionId}/${isAuctioneerUserId}/${this.state.items.auctionName}/${auctionDate}`,
            });
          }
        } else {
          this.props.history.push({
            pathname: `/preview/tendor/products/${this.props.match.params.auctionId}/${isAuctioneerUserId}/${this.state.items.auctionName}/${auctionDate}`,
          });
        }
      }
    }
  };

  bidNowRedirection = async () => {
    if (this.state.items && this.state.items.serialNo) {
      localStorage.setItem(
        "currentPDItem",
        JSON.stringify(this.state.items.serialNo)
      );
      let filteredValue = this.state.auctionProducts.filter(
        (value) => value.serialNo === this.state.items.serialNo
      );

      let index = this.state.auctionProducts.indexOf(filteredValue[0]);
      // login to check if user has increased items per page

      if (localStorage.getItem("tenderitemsPerPage")) {
        let savedCount: any = localStorage.getItem("tenderitemsPerPage");
        let endCount: number = parseInt(savedCount);
        let sum = (index + 1) / endCount;
        let page = Math.ceil(sum);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      } else {
        let page = Math.ceil((index + 1) / 10);
        localStorage.setItem("currentPdPage", JSON.stringify(page));
      }
    }
    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 0;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
      localStorage.setItem("currentPd", JSON.stringify(page));
    }
    localStorage.setItem(
      "currentProductIdOfBidNow",
      this.props.match.params.productId
    );
    let userIdforAuction = localStorage.getItem("userId");
    //single productApi calling
    let singleProductPayload: any = {
      productId: this.props.match.params.productId,
      auctionId: this.props.match.params.auctionId,
    };
    await getBiddingProductDetails(singleProductPayload);
    await this.renderAuctionPage(userIdforAuction);
  };

  renderAuctionPage = (userIdforAuction: any) => {
    if (userIdforAuction !== null) {
      this.props.history.push({
        pathname: `/auction/tender/details/${this.props.match.params.auctionId}/${userIdforAuction}`,
      });
    } else {
      this.props.history.push({
        pathname: `/auction/tender/details/${this.props.match.params.auctionId}/${isAuctioneerUserId}`,
      });
    }
  };

  nextArrow = () => {
    this.setState({
      openToast: false,
    });
    var updatedIndex = this.state.index + 1;
    this.state.auctionProducts.map((product: any, index: number) => {
      if (updatedIndex === index) {
        this.setState({
          index: index,
        });
        if (
          this.state.auctionProducts.length === index + 1 &&
          this.state.totalElements !== this.state.auctionProducts.length
        ) {
          if (updatedIndex < this.state.totalElements) {
            this.getUpdatedProducts(index + 1);
          }
        }
        this.getDetails(product.perId);
        localStorage.setItem("currentProductIdOfBidNow", product.perId);
        if (
          this.props.match.path ===
          "/watchlist/details/:auctionId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
          });
        } else if (
          this.props.match.path ===
          "/preview/tender/details/:auctionId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/preview/tender/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
          });
        } else {
          this.props.history.push({
            pathname: `/home/tendor/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
          });
        }
      }
      return true;
    });
  };

  nextButtonSubItem = () => {
    if (this.state.fullData.subItems.length > this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count],
        count: this.state.count + 1,
      });
    }
  };

  previousButtonSubItem = () => {
    if (this.state.count === 1) {
      this.setState({
        items: this.state.fullData,
        count: this.state.count - 1,
      });
    } else if (this.state.fullData.subItems.length === this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count - 2],
        count: this.state.count - 1,
      });
    } else {
      if (this.state.count > 0) {
        this.setState({
          items: this.state.fullData.subItems[this.state.count - 2],
          count: this.state.count - 1,
        });
      }
    }
  };

  timeout = (timeout: boolean) => {
    if (timeout === true) {
      this.setState({
        apiTimeout: true,
      });
    }
  };

  alertType = (alertType: string, openToast: boolean, showMessage: string) => {
    this.setState({
      alertType: alertType,
      openToast: openToast,
      showAlertMessage: showMessage,
    });
  };

  blockCloseModal = () => {
    this.Auth.logout();
    this.props.history.replace("/");
  };

  shareButtonAction() {
    const { t } = this.props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${this.props.match.params.productId}/${auctionDate}/detail`
    );

    this.setState({
      alertType: "success",
      openToast: true,
      showAlertMessage: `${t("common:preview:linkCopied")}`,
      isLoading: false,
    });
  }

  onCloseModal = () => {
    this.setState({
      openShareModal: false,
      openInquiry: false,
    });
  };

  onCopyLink = () => {
    this.setState({
      openShareModal: false,
    });
  };
  getProductDescForParcelTypeDiamond = () => {
    const { t } = this.props;
    return `${this.state.fullData.code} ${this.state.fullData.carat} ${t(
      "common:preview:ct"
    )} ${this.state.fullData.color}${" "} ${
      this.state.fullData.clarity
    }${" "} ${this.state.fullData.cutGrade}${" "} ${
      this.state.fullData.flour
    } ${t("common:preview:pol")}=${this.state.fullData.polish} ${t(
      "common:preview:sym"
    )}=${this.state.fullData.symmetry}`;
  };

  getProductDesc = () => {
    const sieveType =
      this.state.fullData.sieve !== undefined &&
      this.state.fullData.sieve === "-"
        ? ""
        : this.state.fullData.sieve;
    const productShape =
      this.state.fullData.shape !== undefined ? this.state.fullData.shape : "";
    return `${this.state.fullData.code}${" "}${
      this.state.fullData.productDescription
    }${" "}${
      this.state.fullData.color
    }${" "}${productShape}${" "}${sieveType}${" "}${
      this.state.fullData.carat
    } ${"Ct"}`;
  };
  oninquiry = () => {
    this.setState({
      openInquiry: true,
    });
  };

  getMediasForCarosuel = () => {
    const medias = [...this.state.items.medias];
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        } else {
          // medias.splice(i, 1);
          // medias.unshift(mediaItem);
        }
      }
    }
    return medias;
  };

  convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        const rate = exchangeRate?.[0]?.["rate"] ?? 1;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  render() {
    const { t } = this.props;
    return (
      <AppLayout {...this.props}>
        {this.state.apiTimeout === true ? (
          <ApiTimeout t={t} />
        ) : (
          <Grid>
            {this.state.isLoading === true ? (
              <div className="loader">
                <LoadingSpinner />
              </div>
            ) : (
              <StyledLayoutGrid container={true} spacing={0}>
                <StyledGridWrapper item={true} xs={12} xl={12} lg={12} md={12}>
                  <StyledGridWrapper
                    item={true}
                    xs={12}
                    className="StyledGridWrapper"
                  >
                    <Grid
                      container={true}
                      spacing={0}
                      className="actionContainer"
                    >
                      <StyledBackBtnContainer
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={8}
                      >
                        <StyledBackBtn
                          onClick={this.handleBackBtn}
                          src="/images/controls/left_arrow_round_edges.png"
                        />
                        <span className="spanText" onClick={this.handleBackBtn}>
                          {t("common:preview:showAllProduct")}
                        </span>
                      </StyledBackBtnContainer>
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <StyledWrapper container={true} spacing={0}>
                          <Grid item xs={8} sm={6} md={6} lg={6}>
                            <Grid container={true} spacing={0}>
                              {this.state.items !== "" &&
                                this.state.count === 0 && (
                                  <StyledTooltipContainer item={true} xs={6}>
                                    <TooltipContant
                                      placement="top"
                                      disableTouchListener
                                      title={t(
                                        "common:preview:productDetail:tendorDiamondshareTooltip"
                                      )}
                                    >
                                      <StyledTooltipText>
                                        <StyledButton
                                          onClick={this.shareButtonAction.bind(
                                            this
                                          )}
                                        >
                                          {t(
                                            "common:preview:productDetail:share"
                                          )}
                                        </StyledButton>
                                      </StyledTooltipText>
                                    </TooltipContant>
                                  </StyledTooltipContainer>
                                )}

                              <Grid item={true} xs={6}>
                                {this.state.items !== "" &&
                                  this.state.count === 0 && (
                                    <TooltipContant
                                      placement="top"
                                      disableTouchListener
                                      title={t(
                                        "common:preview:productDetail:watchTooltip"
                                      )}
                                    >
                                      <StyledTooltipText>
                                        <AddorRemoveWatchlist
                                          {...this.props}
                                          t={t}
                                          {...this.state.items}
                                          getDetails={this.getDetails}
                                          alertType={this.alertType}
                                          apiTimeout={this.timeout}
                                          isReadOnlyUser={isReadOnlyUser}
                                          isSeller={
                                            redirectfromAdmin === "true"
                                              ? true
                                              : false
                                          }
                                        />
                                      </StyledTooltipText>
                                    </TooltipContant>
                                  )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <StyledArrowWrapper item xs={4} sm={6} md={6} lg={6}>
                            {this.state.index === 0 ? (
                              <StyledArrowIcons
                                onClick={this.previousButton}
                                src="/images/controls/left_arrow_round_edges_disabled.png"
                              />
                            ) : (
                              <StyledArrowIcons
                                onClick={this.previousButton}
                                src="/images/controls/left_arrow_round_edges.png"
                              />
                            )}
                            {productList.length - 1 === this.state.index ? (
                              <StyledArrowIcons
                                onClick={this.nextArrow}
                                src="/images/controls/right_arrow_round_edges_disabled.png"
                              />
                            ) : (
                              <StyledArrowIcons
                                onClick={this.nextArrow}
                                src="/images/controls/right_arrow_round_edges.png"
                              />
                            )}
                          </StyledArrowWrapper>
                        </StyledWrapper>
                      </Grid>
                      <StyledTitleContainer
                        item={true}
                        xs={12}
                        sm={12}
                        md={12}
                        lg={9}
                      >
                        {this.state.items !== "" &&
                        this.state.items.parcelType === "Diamond" ? (
                          <StyledBrandName>
                            <ProductDescription
                              truncateLength={90}
                              data={this.state.items}
                              isTendor={false}
                            />
                          </StyledBrandName>
                        ) : (
                          <StyledBrandName>
                            <ProductDescription
                              truncateLength={90}
                              data={this.state.items}
                              isTendor={false}
                            />
                          </StyledBrandName>
                        )}
                      </StyledTitleContainer>{" "}
                      <StyledName item={true} xs={12} sm={12} md={12} lg={3}>
                        {this.state.items?.auctionName === undefined
                          ? " "
                          : this.state.items?.auctionName}
                      </StyledName>
                    </Grid>
                  </StyledGridWrapper>
                </StyledGridWrapper>
                <Grid item={true} xs={12}>
                  <StyledContentWrapper container={true} spacing={0}>
                    <StyledProductCarousalColumn
                      item={true}
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      xl={6}
                    >
                      {this.state.items.medias !== undefined &&
                      this.state.items.medias.length > 0 ? (
                        <ProductCarouselNew
                          medias={this.getMediasForCarosuel()}
                          productData={this.state.items}
                        />
                      ) : (
                        <StyledGrid container={true} spacing={0}>
                          <StyledNotAvailableImage>
                            {this.state.items.parcelType === "Diamond" ? (
                              <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                            ) : (
                              <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
                            )}
                          </StyledNotAvailableImage>
                        </StyledGrid>
                      )}
                    </StyledProductCarousalColumn>
                    <Grid item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Grid item={true} xs={12} sm={12}>
                        <Grid container={true} spacing={0}>
                          <Grid item={true} xs={12} xl={8} lg={8}>
                            {this.state.items !== "" && (
                              <DetailsNotes
                                t={t}
                                {...this.props}
                                {...this.state.items}
                                count={this.state.count}
                                apiTimeout={this.timeout}
                                noteSaved={() =>
                                  this.getDetails(
                                    this.props.match.params.productId
                                  )
                                }
                                isSeller={
                                  redirectfromAdmin === "true" ? true : false
                                }
                                auctionType={this.state.fullData.auctionType}
                                isReadOnlyUser={isReadOnlyUser}
                              />
                            )}
                            {this.state.fullData.note !== "" && (
                              <StyledLabel>
                                {this.state.fullData.note}
                              </StyledLabel>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item={true} xs={12}>
                          <Grid
                            item={true}
                            xs={12}
                            md={12}
                            xl={8}
                            lg={8}
                            spacing={0}
                          >
                            <StyledPrebidingContainer>
                              <div
                                style={{
                                  fontSize: "1.4em",
                                  fontWeight: "bold",
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5px",
                                }}
                              >
                                <span>
                                  {" "}
                                  {t(
                                    "common:preview:perItemDescription:startingPrice"
                                  )}{" "}
                                </span>
                                {this.state.items !== undefined &&
                                  this.state.items !== null && (
                                    <span>
                                      {this.convertedPlaceHolderValue(
                                        this.state.items.currency,
                                        this.state.items.caratMinimumPrice,
                                        this.state.items?.exchangeRates
                                      )}
                                    </span>
                                  )}
                              </div>
                              <div>
                                {this.state.items !== undefined &&
                                  this.state.items !== null && (
                                    <span>
                                      {getRapnetPercentage(
                                        this.state.items.rapnetPercentage,
                                        this.state.items?.exchangeRates,
                                        this.state.items?.currency,
                                        this.state.items?.caratMinimumPrice,
                                        this.state.items?.rapnetPrice
                                      )}
                                    </span>
                                  )}
                              </div>
                              <div>
                                <span>Amount: </span>
                                {this.state.items !== undefined &&
                                  this.state.items !== null && (
                                    <span>
                                      {this.convertedPlaceHolderValue(
                                        this.state.items.currency,
                                        this.state.items.startingPrice,
                                        this.state.items.exchangeRates
                                      )}
                                    </span>
                                  )}
                              </div>
                              <div>
                                <Grid
                                  style={{
                                    justifyContent: "end",
                                    display: "flex",
                                    marginTop: "10px",
                                  }}
                                >
                                  <BidNowButtonComponet
                                    {...this.props}
                                    {...this.state.items}
                                    bidNowRedirection={this.bidNowRedirection}
                                    isReadOnlyUser={isReadOnlyUser}
                                  />
                                </Grid>
                              </div>
                            </StyledPrebidingContainer>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledContentWrapper>
                </Grid>
                <Grid item={true} xs={12}>
                  <StyledContentWrapper container={true} spacing={0}>
                    <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid item={true} xs={12}>
                        <StyledWrapperID item={true} xs={12}>
                          <StyledItemDesc item={true} xs={12}>
                            {t("common:preview:productDetail:itemDescription")}
                            {": "}
                            {this.state.items !== "" &&
                            this.state.items.parcelType === "Diamond" ? (
                              <StyledProductName>
                                <ProductDescription
                                  truncateLength={90}
                                  data={this.state.items}
                                  isTendor={false}
                                />
                              </StyledProductName>
                            ) : (
                              <StyledProductName>
                                <ProductDescription
                                  truncateLength={90}
                                  data={this.state.items}
                                  isTendor={false}
                                />
                              </StyledProductName>
                            )}
                          </StyledItemDesc>
                          {this.state.fullData.subItems != null && (
                            <>
                              {this.state.count === 0 ? (
                                <StyledArrowIconsID
                                  onClick={this.previousButtonSubItem}
                                  src="/images/controls/left_arrow_round_edges_disabled.png"
                                />
                              ) : (
                                <StyledArrowIconsID
                                  onClick={this.previousButtonSubItem}
                                  src="/images/controls/left_arrow_round_edges.png"
                                />
                              )}
                              {this.state.fullData.subItems.length ===
                              this.state.count ? (
                                <StyledArrowIconsID
                                  onClick={this.nextButtonSubItem}
                                  src="/images/controls/right_arrow_round_edges_disabled.png"
                                />
                              ) : (
                                <StyledArrowIconsID
                                  onClick={this.nextButtonSubItem}
                                  src="/images/controls/right_arrow_round_edges.png"
                                />
                              )}
                            </>
                          )}
                        </StyledWrapperID>
                        {this.state.items !== "" && (
                          <PerItemDescription
                            t={t}
                            {...this.state.items}
                            auctionDate={auctionDate}
                            isPDView={this.state.isPDView}
                            onClickSignIn={() => {
                              this.props.history.replace("/");
                            }}
                          />
                        )}
                        <StyledInquiryContainer>
                          <BasicPopover oninquiryFun={this.oninquiry} />
                        </StyledInquiryContainer>
                      </Grid>
                    </Grid>
                  </StyledContentWrapper>
                </Grid>
              </StyledLayoutGrid>
            )}
          </Grid>
        )}

        {this.state.openToast === true && (
          <Alert
            showAlertMessage={this.state.showAlertMessage}
            alertType={this.state.alertType}
          />
        )}
        {this.state.userBlock === true && (
          <StyledModal open={this.state.userBlock}>
            <BlockUserModal
              t={t}
              {...this.props}
              closeModal={this.blockCloseModal}
            />
          </StyledModal>
        )}
        {this.state.openShareModal === true && (
          <StyledModal
            open={this.state.openShareModal}
            onClose={this.onCloseModal}
          >
            <ShareProductLinkView
              onCopyModal={this.onCopyLink}
              onCancelModal={this.onCloseModal}
              t={t}
            />
          </StyledModal>
        )}

        {this.state.openInquiry === true && (
          <StyledModal
            open={this.state.openInquiry}
            onClose={this.onCloseModal}
          >
            <ProductDetailsInquiry
              t={t}
              data={this.state.items}
              closeModal={this.onCloseModal}
              auctionId={this.props.match.params.auctionId}
            />
          </StyledModal>
        )}
      </AppLayout>
    );
  }
}
