const Colors = {
  white: "#FFFFFF",
  black: "#000000",
  red: "#E14843",
  blue: "#0000FF",
  transparent: "#ffffff00",
  yellow: "#FFFF00",
  grey: "#62696f",
  darkGrey: "#717171",
  lightGrey: "#DCDCDC",
  veryLightGrey: "#e4e4e4",
  veryVeryLightGrey: "#F6F6F6",
  grey1: "#F4F4F4",
  veryDarkGrey: "#333333",
  fieldSetBorderColor: "#dadada",
  fieldSetBlueColor: "#014a99",
  darkBlue: "#014a99",
  lightBlue: "#4f96e2",
  veryLightBlue: "#d9ebff",
  textColor: "#014a99",
  bannnerFirstColor: "#9da9bd",
  bannerSecondColor: "#85a1c8",
  bannerThirdColor: "#6faef4",
  gradientDarkBlueColor: "#5097e2",
  gradientLightBlueColor: "#80bcfd",
  hoverTextFieldColor: "#0474ec",
  buttonGradientFirstColor: "#2660AD",
  buttonGradientSecondColor: "rgba(105,170,240,1)",
  buttonGradientThirdColor: "rgba(128,187,253,1)",
  borderColor: "#f1f1f1",
  bannerbackground: "#303E4E",
  backgroundColorofList: "#daebeb",
  scrollerColor: "#A9A8A8",
  green: "#1E6F30",
  veryLightBlueAboutUs: "#cbdcef",
  bidBtnBackground: "#2F5597",
  tableHeader: "#5B9BD5",
  tableRowBackground: "#D8D8D8",
  gradientDarkMixBlueColor: "#2660ad",
  newgradientDarkMixBlueColor: "#2660AD",
};

export default Colors;