import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import ItemsCarousel from 'react-items-carousel';

import ProductInSlider from './ProductInSlider';

const StyledIconContainer = styled.span`
    width: 33px;
    height: 33px;
    margin-left: 10px;
    display: inline-block;
    &.rightIcon {
        margin-left: 0px;
        margin-right: 5px;
    }
`;

const StyledIcon = styled('img')`
    width: 100%;
    height: auto;
    cursor: pointer;
`;

const PrevButton = () => {
    return (
        <StyledIconContainer>
            <StyledIcon src='/images/controls/left_arrow_round_edges.png' />
        </StyledIconContainer>
    )
};
const NextButton = () => {
    return (
        <StyledIconContainer className="rightIcon">
            <StyledIcon src='/images/controls/right_arrow_round_edges.png' />
        </StyledIconContainer>
    )
};

export interface IProps {
    t: any;
    productLists: Array<Object>;
    joinedAuctions: any;
    onParticipate: (auctionId: string, auctionDate: string, productId: string, auctionSubdomain: string, parcelType: string, auctionType: string) => void;
}

export default (props: IProps) => {
    const { t } = props;
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const chevronWidth = 40;
    let numberOfCardsCount = 1;

    const theme = useTheme();
    const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumDevice = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isExtraLargeDevice = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

    if (isExtraSmallDevice) {
        numberOfCardsCount = 1;
    } else if (isSmallDevice) {
        numberOfCardsCount = 1;
    } else if (isMediumDevice) {
        numberOfCardsCount = 3;
    } else if (isLargeDevice) {
        numberOfCardsCount = 4;
    } else if (isExtraLargeDevice) {
        numberOfCardsCount = 5;
    }

    const onPress = (auctionId: string, auctionDate: string, productId: string, auctionSubdomain: string,parcelType: string,auctionType: string) => {
        props.onParticipate(auctionId, auctionDate, productId, auctionSubdomain, parcelType, auctionType);
    }

    return (
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            <ItemsCarousel
                requestToChangeActive={setActiveItemIndex}
                activeItemIndex={activeItemIndex}
                numberOfCards={numberOfCardsCount}
                leftChevron={PrevButton()}
                rightChevron={NextButton()}
                chevronWidth={chevronWidth}
                infiniteLoop={true}
                outsideChevron
            >
                {props.productLists.map((product: any, index: number) => {
                    return <Grid key={index}>
                        <ProductInSlider {...product} t={t} joinedAuctions={props.joinedAuctions} onParticipate={onPress} items = {product}/>
                    </Grid>
                })}
            </ItemsCarousel>
        </div>

    );
};
