import React from "react";

import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { GlassMagnifier } from "react-image-magnifiers";
import { Carousel } from "react-responsive-carousel";
import { ParcelType } from "../../../../../enums/productEnum";
import ReactPlayer from "react-player";
import { useMediaQuery, useTheme } from "@material-ui/core";


const StyledComponent = styled(Grid)`
  outline: none;
  .carousel .slider.animated {
    align-items: center;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
   padding: 10px
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 0.2em 0.2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 600px;
    height: 450px;
  }
  @media (min-width: 1124px) and (max-width: 1240px) {
   height: 550px;
  }
  
  @media (min-width: 768px) and (max-width: 1023.95px){
    width: 600px;
    height: auto;
  }
  
  @media (min-width: 1400px) and (max-width: 1920px) {
    height: calc(100% - -200px) !important;
    width: 565px;
  } 
  
  @media (min-width: 1920px) and (max-width: 2000px){
    height: 630px;
    width: 600px;
  }
  @media (width: 1024px) {
    width: 500px !important;
    height: auto;
  }
  @media  (min-width: 1210px) and (max-width: 1378px) {
    width: 355px !important;
    height: auto;
  }
  @media  (min-width: 1380px) and (max-width: 1399px) {
    height: 630px;
    width: 620px;
  }
`;

const StyledCorouselWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  position: relative;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .carousel .slide iframe {
      width: calc(100% - -120px) !important;
    }
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 410px !important;
    .carousel .slide iframe {
      width: calc(100% - -100px) !important;
    }
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    width: 440px;
    .carousel .slide iframe {
      width: calc(100% - -100px) !important;
    }
  }  
  ${(props) => props.theme.breakpoints.only("xl")} {
    width: 550px;
  }

  @media (width: 1024px) {
   width: 470px !important;
  }

  @media (min-width: 700px) and (max-width: 959.95px) {
    .carousel .slide iframe {
      width: 100% !important;
    }
  }
  .carousel.carousel-slider {
    overflow: visible;
    position: relative;
  }
  .carousel .slider-wrapper {
    overflow: visible;
  }
  .carousel .slide {
    visibility: hidden;
    background: ${(props) => props.theme.colors.base.white};
  }
  .carousel .slide.selected {
    visibility: visible;
  }
  .fWstHB {
    padding-top: 0px;
  }
  @media (min-width: 1124px) and (max-width: 1240px) {
   width: 350px !important;
  }

  @media (min-width: 1400px) and (max-width: 1920px) {
    height: calc(100% - -200px) !important;
    width: 535px;
  }
  @media (min-width: 1920px) and (max-width: 2000px){
    height: 620px;
    width: 580px !important;
  }
  @media  (min-width: 1210px) and (max-width: 1378px) {
    width: 350px !important;
    height: 550px;
  }
  
`;

const StyledArrowWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  width: 30px;
  cursor: pointer;
`;

const StyledArrows = styled("img")``;

const StyledWrapperGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  padding: 5px;
`;

const StyledGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  height: 100%;
  display: block;
  justify-content: center;
`;

const StyledProductImage = styled("img")`
  width: 100%;
`;

const StyledNotAvailableImage = styled.div`
  justify-content: center;
`;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 500px;
  position: relative;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 290px;
  }

  ${(props) => props.theme.breakpoints.only("md")} {
    height: 470px;
    margin: 0 20px 20px !important;
  }

  ${(props) => props.theme.breakpoints.only("lg")} {
    height: 500px;
  }
  @media (min-width: 1400px) and (max-width: 1920px) {
    height: 551px;
  }
  @media (min-width: 700px) and (max-width: 1020px) {
    height: 360px;
    margin: 0 60px !important;
  }

  @media (width: 768px) {
    height: 290px;
  }
  @media (width: 820px) {
    height: 290px;
    margin: 0 40px !important;
  }
`;

interface IProps {
  medias: any;
  onClose: any;
  parcelType: any;
  activeIndex: number;
}

const ZoomedCarousel = (props: IProps, ref: any) => {
  const { medias, parcelType, activeIndex } = props;

  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
      style={{ maxHeight: "90vh" }}
    />
  );
  const isParcelTypeDiamond = () => {
    return parcelType === ParcelType.DIAMOND;
  };
  const isParcelTypeJewellery = () => {
    return parcelType === ParcelType.JEWELLERY;
  };

  const checkMedias = () => {
    return medias !== undefined && medias !== null && medias.length > 0;
  };

  const renderArrowPrev = (
    onClickHandler: () => void,
    hasPrev: boolean,
    label: string
  ) => {
    return (
      <StyledArrowWrapper
        onClick={onClickHandler}
        title={label}
        style={{ left: 0 }}
      >
        <StyledArrows
          src={`/images/controls/${hasPrev
            ? "left_arrow_round_edges.png"
            : "left_arrow_round_edges_disabled.png"
            }`}
        />
      </StyledArrowWrapper>
    );
  };

  const renderArrowNext = (
    onClickHandler: () => void,
    hasNext: boolean,
    label: string
  ) => {
    return (
      <StyledArrowWrapper
        onClick={onClickHandler}
        title={label}
        style={{ right: 0 }}
      >
        <StyledArrows
          src={`/images/controls/${hasNext
            ? "right_arrow_round_edges.png"
            : "right_arrow_round_edges_disabled.png"
            }`}
        />
      </StyledArrowWrapper>
    );
  };

  const renderImageNotAvailable = () => {
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledNotAvailableImage>
          <StyledProductImage
            src={`/images/${isParcelTypeDiamond() || isParcelTypeJewellery()
              ? "default-diamond.png"
              : "default-parcel.png"
              }`}
          ></StyledProductImage>
        </StyledNotAvailableImage>
      </StyledGrid>
    );
  };


  return (
    <>
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          {checkMedias() ? (
            <StyledGrid container={true} spacing={0}>
              <StyledCorouselWrapper>
                <Carousel
                  showStatus={false}
                  showThumbs={false}
                  showIndicators={false}
                  thumbWidth={60}
                  transitionTime={0}
                  swipeable={false}
                  renderArrowPrev={renderArrowPrev}
                  renderArrowNext={renderArrowNext}
                  selectedItem={activeIndex}
                >
                  {medias.map((item: any, index: number) => (
                    <StyledWrapperGrid key={index}>
                      {item.mediaType === "PHOTO" ? (
                        <div>
                          <GlassMagnifier
                            imageSrc={`${item.thumbnailPresignedUrl}`}
                            magnifierSize="50%"
                            largeImageSrc={`${item.thumbnailPresignedUrl}`}
                          />
                        </div>
                      ) : (
                        <div>
                          {item?.mediaType === "VIDEO" ? (
                            <Grid container={true} spacing={0}>
                              <PlayerSlide url={`${item.presignedUrl}`} />
                            </Grid>
                          ) : (
                            <Grid container={true} spacing={0} style={{ justifyContent: 'center' }}>
                              {notSmallDevices ? (
                                <StyledFrame
                                  src={`${item.presignedUrl}&sr=100`}
                                  loading="lazy"
                                />
                              ) : (
                                <div id="new-wrapfreamDetailsCarousel">
                                  <StyledFrame
                                    id="new-scaled-frameDetailsCarousel"
                                    src={`${item.presignedUrl}&sr=100`}
                                    style={{ margin: "0px" }}
                                  />
                                </div>
                              )}
                            </Grid>
                          )}
                        </div>
                      )}
                    </StyledWrapperGrid>
                  ))}
                </Carousel>
              </StyledCorouselWrapper>
            </StyledGrid>
          ) : (
            renderImageNotAvailable()
          )}
        </StyledWrapper>
      </StyledComponent>
    </>
  );
};

export default React.forwardRef(ZoomedCarousel);
