import React from "react";
import styled from "styled-components";
import { openCertificateLinkPopup } from "../../../../../common/GenericFunctions";

const StyledLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.colors.base.white};
  }
`;

interface IProps {
  auctionDetail: any;
}

const CertificationPopup = (props: IProps) => {
  const { auctionDetail } = props;
  // @ts-ignore
  const { lab, certificate, code } = { ...auctionDetail };

  if (lab !== "AGT" && lab !== "agt" && lab !== "" && lab !== undefined) {
    return (
      <StyledLink
        href="#"
        onClick={() => openCertificateLinkPopup(lab, certificate)}
      >
        {code}
      </StyledLink>
    );
  } else if (code) {
    return <>{code}</>;
  } else {
    return <>{""}</>;
  }
};

export default CertificationPopup;
