import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api from "../../../services/ApiService";

import {
  getAllJoinedAuctionsUrl,
  getHomeOngoingAuctionsUrl,
  calendarEventUrl,
  joinedAuctionsUrl,
  participateUrl,
  sendInviteeParticipationRequest as inviteeParticipationUrl,
  joinAuctionUrl,
  previewUrl,
  autoCompleteSearchProduct,
  searchAuctionFilterUrl,
  searchJewelleryAuctionFilterUrl,
  upcomingProducts,
} from "../../../common/config/app.endpoints";
import IErrorResponse from "../../interfaces/ErrorInterface";
import {
  IAuctionSliceInitialState,
  IParticipationRequest,
  IInviteeParticipationRequest,
  IJoinByAuctionIdRequest,
  IGetPreviewListRequest,
  IAuctionSearchRequest,
  ISearchAuctionsByFiltersRequest,
  IAuctionProductLikingsRequest,
  IUpcomingProductsRequest,
} from "./auctionSliceInterface";

import { ProductCategory } from "../../../enums/productEnum";

const initialState: IAuctionSliceInitialState = {
  loading: false,
  error: "",
  auctionUserId: "",
  preview: {
    listName: "auctionPreviewList",
    selectedAuction: {},
    basicSearchTerm: "",
  },
  calendarEvents: [],
  homeOnGoingAuctions: [],
  allJoinedAuctions: [],
  joinedAuctions: [],
  auctionPreviewList: {
    list: [],
    totalElements: 0,
  },
  auctionSearchList: {
    list: [],
    totalElements: 0,
  },
  auctionFilteredList: {
    list: [],
    totalElements: 0,
  },
  joinedAuctionsListByCategory: {
    list: [],
    totalElements: 0,
    isLoading: false,
  },
  auctionProductLikings: {
    list: [],
    totalElements: 0,
  },
  upcomingProducts: {
    list: [],
    totalElements: 0,
  },
};

export const getAllJoinedAuctions = createAsyncThunk(
  "auction/getAllJoinedAuctions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(getAllJoinedAuctionsUrl);
      return response.data.results;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getJoinedAuctions = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: IErrorResponse }
>("auction/getJoinedAuctions", async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(joinedAuctionsUrl);
    return response.data.results;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getHomeOngoingAuctions = createAsyncThunk(
  "auction/getHomeOngoingAuctions",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(getHomeOngoingAuctionsUrl);
      return response.data.results;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getCalendarEvents = createAsyncThunk(
  "auction/getCalendarEvents",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.get(calendarEventUrl);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendParticipationRequest = createAsyncThunk<
  IParticipationRequest,
  Partial<IParticipationRequest>,
  { rejectValue: IErrorResponse }
>(
  "auction/sendParticipationRequest",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { auctionId } = requestObj;
      const url = `${participateUrl}?auctionId=${auctionId}`;
      const response = await api.post(url);
      return response.data.results;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendInviteeParticipationRequest = createAsyncThunk<
  IInviteeParticipationRequest,
  Partial<IInviteeParticipationRequest>,
  { rejectValue: any }
>(
  "auction/sendInviteeParticipationRequest",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { auctionId } = requestObj;
      const url = `${inviteeParticipationUrl}?auctionId=${auctionId}`;
      const response = await api.post(url);
      return response.data.results;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendJoinByAuctionId = createAsyncThunk<
  IJoinByAuctionIdRequest,
  Partial<IJoinByAuctionIdRequest>,
  { rejectValue: any }
>("auction/sendJoinByAuctionId", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, code } = requestObj;
    const url = `${joinAuctionUrl}/${auctionId}/join`;
    const response = await api.post(url, code);
    return response.data.results;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getPreviewListByCategory = createAsyncThunk<
  IGetPreviewListRequest,
  Partial<IGetPreviewListRequest>,
  { rejectValue: any }
>(
  "auction/getPreviewListByCategory",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { categoryName } = requestObj;
      const url = `${previewUrl}?category=${categoryName}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendAuctionSearch = createAsyncThunk<
  IAuctionSearchRequest,
  Partial<IAuctionSearchRequest>,
  { rejectValue: any }
>("auction/sendAuctionSearch", async (requestObj, { rejectWithValue }) => {
  try {
    const response = await api.post(autoCompleteSearchProduct, requestObj);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const searchAuctionsByFilters = createAsyncThunk<
  ISearchAuctionsByFiltersRequest,
  Partial<ISearchAuctionsByFiltersRequest>,
  { rejectValue: any }
>(
  "auction/searchAuctionsByFilters",
  async (requestObj, { rejectWithValue }) => {
    const { category, query } = requestObj;
    const url =
      category === ProductCategory.JEWELLERY
        ? searchJewelleryAuctionFilterUrl
        : searchAuctionFilterUrl;
    try {
      const response = await api.post(url, query);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAuctionListByCategory = createAsyncThunk<
  IGetPreviewListRequest,
  Partial<IGetPreviewListRequest>,
  { rejectValue: any }
>(
  "auction/getAuctionListByCategory",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { categoryName } = requestObj;
      const url = `${joinedAuctionsUrl}?category=${categoryName}`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getAuctionProductLikings = createAsyncThunk<
  IAuctionProductLikingsRequest,
  Partial<IAuctionProductLikingsRequest>,
  { rejectValue: any }
>(
  "auction/getAuctionProductLikings",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { auctionId } = requestObj;
      const url = `${joinAuctionUrl}/${auctionId}/productLikings`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const getUpcomingProducts = createAsyncThunk<
  IUpcomingProductsRequest,
  Partial<IUpcomingProductsRequest>,
  { rejectValue: any }
>(
  "auction/getUpcomingProducts",
  async (requestObj, { rejectWithValue }) => {
    try {
      const { auctionId } = requestObj;
      const url = `${upcomingProducts}/${auctionId}/upcomingProducts`;
      const response = await api.get(url);
      return response.data;
    } catch (error) {
      const err: any = error;
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (state: any, action: any) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    setAuctionUserId(state, action: PayloadAction<{ auctionUserId: string }>) {
      const { auctionUserId } = action.payload;
      state.auctionUserId = auctionUserId;
    },
    setPreviewListName(state, action: PayloadAction<{ listName: string }>) {
      state.preview.listName = action.payload.listName;
    },
    setPreviewBasicSearchTerm(
      state,
      action: PayloadAction<{ basicSearchTerm: string }>
    ) {
      state.preview.basicSearchTerm = action.payload.basicSearchTerm;
    },
    setPreviewSelectedAuction(
      state,
      action: PayloadAction<{ selectedAuction: any }>
    ) {
      state.preview.selectedAuction = action.payload.selectedAuction;
    },
    resetAuctionUserId(state, action: PayloadAction) {
      state.auctionUserId = "";
    },
    resetAuctionPreviewList(state, action: PayloadAction) {
      state.auctionPreviewList = {
        list: [],
        totalElements: 0,
      };
    },
    resetAuctionSearchList(state, action: PayloadAction) {
      state.auctionSearchList = {
        list: [],
        totalElements: 0,
      };
    },
    resetPreviewBasicSearchTerm(state, action: PayloadAction) {
      state.preview.basicSearchTerm = "";
    },
  },
  extraReducers: (builder: any) => {
    // Get All Joined Auctions
    builder.addCase(getAllJoinedAuctions.pending, genericPendingCaseHandler);
    builder.addCase(
      getAllJoinedAuctions.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.allJoinedAuctions = action.payload;
      }
    );
    builder.addCase(getAllJoinedAuctions.rejected, genericRejectedCaseHandler);
    // Get Joined Auctions
    builder.addCase(getJoinedAuctions.pending, genericPendingCaseHandler);
    builder.addCase(
      getJoinedAuctions.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.joinedAuctions = action.payload;
      }
    );
    builder.addCase(getJoinedAuctions.rejected, genericRejectedCaseHandler);
    // Get calendar events
    builder.addCase(getCalendarEvents.pending, genericPendingCaseHandler);
    builder.addCase(
      getCalendarEvents.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.calendarEvents = action.payload;
      }
    );
    builder.addCase(getCalendarEvents.rejected, genericRejectedCaseHandler);
    // Get On Going Auctions
    builder.addCase(getHomeOngoingAuctions.pending, genericPendingCaseHandler);
    builder.addCase(
      getHomeOngoingAuctions.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.homeOnGoingAuctions = action.payload;
      }
    );
    builder.addCase(
      getHomeOngoingAuctions.rejected,
      genericRejectedCaseHandler
    );
    // sendParticipationRequest
    builder.addCase(
      sendParticipationRequest.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      sendParticipationRequest.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(
      sendParticipationRequest.rejected,
      genericRejectedCaseHandler
    );
    // sendInviteeParticipationRequest
    builder.addCase(
      sendInviteeParticipationRequest.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      sendInviteeParticipationRequest.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(
      sendInviteeParticipationRequest.rejected,
      genericRejectedCaseHandler
    );
    // Join auction by AuctionId
    builder.addCase(sendJoinByAuctionId.pending, genericPendingCaseHandler);
    builder.addCase(
      sendJoinByAuctionId.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        console.log("sendJoinByAuctionId.fulfilled - action", action)
      }
    );
    builder.addCase(sendJoinByAuctionId.rejected, genericRejectedCaseHandler);
    // getPreviewListByCategory
    builder.addCase(
      getPreviewListByCategory.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      getPreviewListByCategory.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.auctionPreviewList = {
          list: action.payload.results,
          totalElements: action.payload.totalElements,
        };
      }
    );
    builder.addCase(
      getPreviewListByCategory.rejected,
      genericRejectedCaseHandler
    );
    // sendAuctionSearch
    builder.addCase(sendAuctionSearch.pending, genericPendingCaseHandler);
    builder.addCase(sendAuctionSearch.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.auctionSearchList = {
        list: action.payload,
        totalElements: action.payload?.length,
      };
    });
    builder.addCase(sendAuctionSearch.rejected, genericRejectedCaseHandler);
    // searchAuctionsByFilters
    builder.addCase(searchAuctionsByFilters.pending, genericPendingCaseHandler);
    builder.addCase(
      searchAuctionsByFilters.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.auctionFilteredList = {
          list: action.payload,
          totalElements: action.payload?.length,
        };
      }
    );
    builder.addCase(
      searchAuctionsByFilters.rejected,
      genericRejectedCaseHandler
    );
    // getAuctionListByCategory
    builder.addCase(
      getAuctionListByCategory.pending,
      (state: any, action: PayloadAction) => {
        state.loading = true;
        state.error = "";
        state.joinedAuctionsListByCategory = {
          isLoading: true
        };
      }
    );
    builder.addCase(
      getAuctionListByCategory.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.joinedAuctionsListByCategory = {
          list: action.payload.results,
          totalElements: action.payload.totalElements,
          isLoading: false
        };
      }
    );
    builder.addCase(
      getAuctionListByCategory.rejected,
      (state: any, action: any) => {
        state.error = action.payload || "Error";
        state.loading = false;
        state.joinedAuctionsListByCategory = {
          isLoading: false
        };
      }
    );
    // Auction Product Likings
    builder.addCase(
      getAuctionProductLikings.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      getAuctionProductLikings.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.auctionProductLikings = {
          list: action.payload.results,
          totalElements: action.payload.totalElements,
        };
      }
    );
    builder.addCase(
      getAuctionProductLikings.rejected,
      genericRejectedCaseHandler
    );
    // getUpcomingProducts
    builder.addCase(
      getUpcomingProducts.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      getUpcomingProducts.fulfilled,
      (state: any, action: any) => {
        state.loading = false;
        state.upcomingProducts = {
          list: action.payload.results,
          totalElements: action.payload.totalElements,
        };
      }
    );
    builder.addCase(
      getUpcomingProducts.rejected,
      genericRejectedCaseHandler
    );
  },
});

export const {
  setAuctionUserId,
  setPreviewListName,
  setPreviewBasicSearchTerm,
  setPreviewSelectedAuction,
  resetAuctionUserId,
  resetAuctionPreviewList,
  resetAuctionSearchList,
  resetPreviewBasicSearchTerm,
} = auctionSlice.actions;
export default auctionSlice.reducer;

// selectors
export const selectAuctionLoading = (state: RootState) => state.auction.loading;
export const selectAuctionUserId = (state: RootState) =>
  state.auction.auctionUserId;
export const selectCalendarEvents = (state: RootState) =>
  state.auction.calendarEvents;
export const selectHomeOnGoingAuctions = (state: RootState) =>
  state.auction.homeOnGoingAuctions;
export const selectAllJoinedAuctions = (state: RootState) =>
  state.auction.allJoinedAuctions;
export const selectAuctionLists = (state: RootState) => {
  const lists: any = {
    auctionPreviewList: state.auction.auctionPreviewList,
    auctionSearchList: state.auction.auctionSearchList,
    auctionFilteredList: state.auction.auctionFilteredList,
  };
  return lists[state.auction.preview.listName];
};
export const selectPreviewSelectedAuction = (state: RootState) =>
  state.auction.preview.selectedAuction;
export const selectPreviewBasicSearchTerm = (state: RootState) =>
  state.auction.preview.basicSearchTerm;
export const selectJoinedAuctionsListByCategory = (state: RootState) =>
  state.auction.joinedAuctionsListByCategory;

export const selectAuctionProductLikings = (state: RootState) =>
  state.auction.auctionProductLikings;
export const selectUpcomingProducts = (state: RootState) =>
  state.auction.upcomingProducts;
