import React from "react";
import BasicAppRouter from "./basic/BasicAppRouter";

// the hoc
import { withTranslation } from "react-i18next";

export interface IAppRouterProps {
  t?: any;
  history?: any;
}

// const AppRouter =React.forwardRef((props: IAppRouterProps, ref)  => {
//   return withRedux ? <AppRoutes /> : <BasicAppRouter {...props} />;
// });


const AppRouter = React.forwardRef((props: IAppRouterProps, ref) => {
  return <BasicAppRouter {...props} />;
});
export default withTranslation()(AppRouter);
