import { Grid, Link } from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../common/materials/colors";
import { Box } from "@mui/material";

export const CommonHorizontalPadding = styled.div`
  width: 100%;
  padding: 1em 3em;
  @media (max-width: 1200px) {
    padding: 1em;
  }
`;

export const LandingPagePlayFairFont = styled.div`
  font-family: "Playfair Display";
`;

export const LandingPageOpenSansFont = styled.div`
  font-family: "OpenSans-Regular";
  overflow-x: hidden;
`;

export const StyledImageCommonContainer = styled.div<{
  minHeight?: string;
}>`
  min-height: ${(props) => props.minHeight || "300px"};
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const StyledImageCommonComponent = styled.img<{
  circle?: boolean;
  fullWidth?: boolean;
}>`
  width: ${(props) => (props.fullWidth ? "100%" : "default")};
  border-radius: ${(props) => (props.circle ? "50%" : "0")};
  max-width: 100%;
  max-height: 100%;
`;

export const StyledLinkBlue = styled(Link)`
  text-transform: capitalize;
  cursor: pointer;
  text-decoration: underline !important;
  color: ${Colors.textColor} !important;
`;

export const StyledImage = styled.img`
  width: 200px;
  height: 220px;
  ${(props) => props.theme.breakpoints.only("xs")} {
    width: 275px;
  }
`;

export const StyledQRBox = styled(Box)`
  height: 280px;
  border: 2px solid #0a3774;
  padding: 10px;
  ${(props) => props.theme.breakpoints.only("xs")} {
    height: auto;
    align-item: center;
  }
`;

export const StyledQRimageContainer = styled(Grid)`
  ${(props) => props.theme.breakpoints.only("xs")} {
    text-align: center;
  }
`;