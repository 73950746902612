import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const StyledItemContainer = styled(Grid)`
  border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  font-size: 0.8em;
  width: 95%;
  margin: 0 auto;
  min-height: 30px;
  display: flex;
  align-items: center;
  .moveToLandingPageLink {
    cursor: pointer !important;
    color: blue !important;
    text-decoration: underline !important;
  }
`;

const StyledHeading = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
`;

const StyledValue = styled(Grid)`
  font-size: 1em;
  max-height: 100px;
`;

interface IProps {
  label: string;
  value: any;
  children?: any;
}

const ItemDetail = (props: IProps) => {
  const { t } = useTranslation();
  const { label, value } = props;

  return (
    <StyledItemContainer item={true} xs={12}>
      <StyledHeading item={true} xs={6}>
        {t(label)}
      </StyledHeading>
      <StyledValue item={true} xs={6}>
        {props.children ? props.children : value}
      </StyledValue>
    </StyledItemContainer>
  );
};

interface IDetailsProps {
  isPDView: boolean;
  label: string;
  value: any;
  onClickSignIn: () => void;
}

export const ItemDetailWithSignIn = (props: IDetailsProps) => {
  const { t } = useTranslation();
  const { isPDView, label, value, onClickSignIn } = props;

  return (
    <StyledItemContainer item={true} xs={12}>
      <StyledHeading item={true} xs={6}>
        {t(label)}
      </StyledHeading>
      {isPDView ? (
        <StyledValue item={true} xs={6}>
          {value}
        </StyledValue>
      ) : (
        <a className="moveToLandingPageLink" onClick={onClickSignIn}>
          {t("common:preview:perItemDescription:signInToView")}
        </a>
      )}
    </StyledItemContainer>
  );
};

export default ItemDetail;
