import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import NegotiateConfirmation from "./NegotiateConfirmation";
import { getDetailsDescription } from "../TableProps";
import { negotiateHoldProduct } from "../../../redux/slice/pendingProductteppo/pendingProductsSlice";
import { AppDispatch } from "../../../redux/store";
import { useDispatch } from "react-redux";
import {
  StyledButton,
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledWrapper,
  StyledComponent,
} from "./NegotiatePopupStyle";
import ViewHoldProductWinnerModal from "./ViewHoldProductWinnerModal";

interface IProps {
  productDetails?: any;
  closeModal: () => void;
}

const DoYouWishNegotiatePopup = React.forwardRef((props: IProps, ref) => {
  const { productDetails } = props;
  const { t } = useTranslation();
  
  const dispatch: AppDispatch = useDispatch();
  
  const [showNegotiateConfirmationModal, setShowNegotiateConfirmationModal] =
    useState<boolean>(false);
  const [showViewHoldProductWinnerModal, setShowViewHoldProductWinnerModal] =
    useState<boolean>(false);

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    const { perId, auctionId } = productDetails;
    
    const formData = {
      negotiatePrice: 0,
      productDescription: getDetailsDescription(productDetails, t),
      perId: perId,
      accept: true,
      auctionId: auctionId
    };

    const resultAction: any = await dispatch(negotiateHoldProduct(formData));
    if (negotiateHoldProduct.fulfilled.match(resultAction)) {
      //fulfilled
      setShowNegotiateConfirmationModal(false);
      setShowViewHoldProductWinnerModal(true);     
    } else if (resultAction.payload) {
      setShowNegotiateConfirmationModal(false);
      setShowViewHoldProductWinnerModal(true);
    }
  };

  const { closeModal } = props;

  const renderNegotiateConfirmationModal = () => {
    return (
      <StyledModal open={showNegotiateConfirmationModal}>
        <NegotiateConfirmation
          closeModal={() => {
            setShowNegotiateConfirmationModal(false);
            closeModal();
          }}
          negotiableProductDetails={productDetails}
        />
      </StyledModal>
    );
  };

  const renderViewHoldProductWinnerModal = () => {
    return (
      <StyledModal open={showViewHoldProductWinnerModal}>
        <ViewHoldProductWinnerModal
          closeModal={() => {
            setShowViewHoldProductWinnerModal(false);
            closeModal();
          }}
          negotiableProductDetails={productDetails}
        />
      </StyledModal>
    );
  };
  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {t("common:pendingProduct:text:DoYouWishNegotiatePopupFirst")}
          </StyledTextWrapper>
          <StyledTextWrapper>
            {t("common:pendingProduct:text:DoYouWishNegotiatePopupSecond")}
          </StyledTextWrapper>
          <div style={{ display: "flex" }}>
            <StyledButton onClick={handleFormSubmit}>
              {t("common:pendingProduct:button:yes")}
            </StyledButton>
            <StyledButton
              onClick={() => setShowNegotiateConfirmationModal(true)}
            >
              {t("common:pendingProduct:button:no")}
            </StyledButton>
          </div>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
      {showNegotiateConfirmationModal && renderNegotiateConfirmationModal()}
      {showViewHoldProductWinnerModal && renderViewHoldProductWinnerModal()}
    </StyledComponent>
  );
});

export default DoYouWishNegotiatePopup;
