import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';

import AuthService from '../../auth/AuthService';
import { currentTimeUrl, joinAuctionUrl, participateUrl, previewUrl, sendInviteeParticipationRequest, autoCompleteSearchProduct } from '../../../common/config/app.endpoints';
import AuctionListView from './AuctionListView';
import CoreDataGridOfAuction from '../../core-grid/grid-data/CoreDataGridOfAuction';
import ViewParticipateModal from './ViewParticipateModal';
import Alert from '../../../common/elements/Alert';
import { buttonGradient, dateBackgroundGradient } from '../../../common/materials/LinearGradient';
import LoadingSpinner from '../../../common/elements/LoadingSpinner';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { oAuth } from '../../../routes/basic/BasicAppRouter';
import moment from 'moment';
import { getDiamondCategoryFromUrl, getTimeDifference, joinAuction } from '../../../common/GenericFunctions';
import BlockUserModal from '../BlockUser/BlockUserModal';
import { connect } from 'react-redux';
import { reSetIsMybids, reSetJwlIsMybids, reSetLiveAuctionPagination, reSetLiveCurrentApiStartCount } from '../../../redux/slice/commanPagination/commanPaginationSlice';

const StyledGrid = styled(Grid)`
`;

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

export const StyledDisabledMessage = styled(Grid)`
    color:  ${(props) => props.theme.colors.base.red};
    font-size: 0.8em;
    margin-left: 1.2em;
    margin-top: 1em;
`;

const StyledModal = styled(Modal)`
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;    
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }
`;

const StyledWrapper = styled(Grid)`
    outline: none;
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
        overflow-y: scroll;
        height: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        // padding: 0.8em;
        overflow-y: scroll;
        height: 400px;
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 1em;
        width: 400px;
    }
`;

const StyledParticipateWrapper = styled(Grid)`
    text-align: center;
`;


interface IButtonProps {
    participate?: boolean;
    join?: boolean;
    disableParticipateButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1.2em;
    text-align: center;
    font-family: Calibre-SemiBold;
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    ${props => props.participate && css`
        width: 90%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 50px;
        &:hover {
            border: 1px solid ${(props) => props.theme.colors.base.textColor};
            cursor: pointer;
        }
    `}
    ${props => props.join && css`
        width: 40%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 30px;
        margin-left: 10px;
        &:hover {
            border: 1px solid ${(props) => props.theme.colors.base.textColor};
            cursor: pointer;
        }
    `}
    ${props => props.disableParticipateButton && css`
        width: 90%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 50px;
        cursor: default;
        background: ${dateBackgroundGradient};
    `}
`;



const StyledMessage = styled(Grid)`
    color:  ${(props) => props.theme.colors.base.red};
    font-size: 1em;
    margin-left: 1.2em;
`;


export interface IState {
    products: [];
    openToast: boolean;
    showAlertMessage: string;
    openProductModal: boolean;
    openParticipateModal: boolean;
    message: string;
    invitationCode: string;
    loading: boolean;
    disabledParticipateMessage: string;
    alertType: string;
    openTimerModal: boolean;
    onSubmit: boolean;
    userBlock: boolean;
    // location: string;
    isShowTendor:string;
}

export interface IProps extends IRouterComponentProps {
  t?: any;
  apiTimeout: (timeout: boolean) => void;
  searchOptionValue: string;
  test: any;
}

var auctionDate: string | string[] = '';
var auctionId: number | string = '';
let timezone: any = '';
var isCallJoinApi:boolean = false;
let shortBreakTimer: any = '';
class ProductsGrid extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            products: [],
            openToast: false,
            showAlertMessage: '',
            openProductModal: false,
            openParticipateModal: false,
            message: '',
            invitationCode: '',
            loading: false,
            disabledParticipateMessage: '',
            alertType: '',
            openTimerModal: false,
            onSubmit: false,
            userBlock: false,
            // location: ''
            isShowTendor: ''
        }
    }
    
    auctionName = '';
    Auth = new AuthService();
    
    componentDidMount() {
        this.auctionName = '';
        this.setState({
            loading: true
        })

        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            let val = localStorage.getItem('optionValue')
            if (val !== "" && val !== 'null' && val != null) {
                this.setState({
                  loading: true,
                });
               localStorage.setItem('optionValue','');
               this.getPreviewList();
               joinAuction();
               getTimeDifference();
            } else {
                joinAuction();
                getTimeDifference();
                this.getPreviewList();
            }
        }

        PubSub.subscribe("optionValue", (msg: any, data: any) => {
            if (data !== "" && data != null) {
                this.getAutocompleteData(data)
            } else {
                joinAuction();
                getTimeDifference();
                this.getPreviewList();
            }
        });
        
        const { isAvailable } = getDiamondCategoryFromUrl(); 
        if(isAvailable){
            this.onClickParticipate();
        }

    }

    componentWillUnmount() {
        PubSub.unsubscribe('optionValue');
    }

    getAutocompleteData(value: any) {
        if (value !== '') {
            const data = {
                "filters": {
                    "or": [
                        {
                            "match": "anywhere",
                            "name": "remarks",
                            "value": value
                        },

                        {
                            "match": "anywhere",
                            "name": "productDescription",
                            "value": value
                        }
                    ],
                    "and": [
                        {
                        "match": "exact",
                        "name": "parcelType",
                        "value": "Diamond"
                        }
                    ]
                },
                "page": {
                    "start": 0,
                    "count": 20,
                    "sort": {
                        "orders": [
                            {
                                "property": "serialNo",
                                "direction": "ASC"
                            }
                        ]
                    }
                }
            }

            this.Auth.postRequest(autoCompleteSearchProduct, data, (status: any, response: any) => {  
                if (status === 200) {
                    this.setState({
                        products: response,
                        message: '',
                        loading: false
                    });
                    if (response.length > 0) {
                        
                        if (!oAuth()) {
                            this.props.history.push('/');
                        } else {
                            this.getTime();
                        }
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }
                }
                else if (status === 403) {
                    this.setState({ userBlock: true })
                }
                else if (status === 505) {
                    this.props.apiTimeout(true)
                }
                else {
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: response,
                        loading: false
                    })
                }
            })

        }
    }

    getPreviewList() {        
            this.Auth.getRequest(`${previewUrl}?category=${"Diamonds"}`, (status: any, response: any) => {
                if (status === 200) {
                    if (response.length > 0) {
                        this.setState({
                            products: response,
                            message: '',
                            loading: false
                        });
                        if (!oAuth()) {
                            this.props.history.push('/');
                        } else {
                            this.getTime();
                        }
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }
                }
                else if (status === 403) {
                    this.setState({ userBlock: true })
                }
                else if (status === 505) {
                    this.props.apiTimeout(true)
                }
                else {
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: response,
                        loading: false
                    })
                }
            })
        
    }

    getTime() {
        this.Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    var timePassedInMilliseconds = (Date.now() - response) / 1000;
                    if (timePassedInMilliseconds < -3 || timePassedInMilliseconds > 3) {
                        this.setState({
                            openTimerModal: true
                        })
                    }
                }
            }
        })
    }

    showListOfProduct = (productId: number | string, name: string, auctionStartDate: string, state: string, auctionType: string) => {
        this.setState({
            isShowTendor : auctionType
        })
        this.props.test();
        localStorage.setItem("auctionState", state);
        let previewTime = moment.utc(auctionStartDate);
        let hour: any = '';
        let time: any = '';
        let minutes: any = '';
        if (timezone !== null) {
            timezone = localStorage.getItem("timezoneName");
        }
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== 'Invalid date') {
            let splitDateTime = resultFormatter.split("T", 2);
            let splitTime: any = '';
            if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
            }
            else {
                splitTime = splitDateTime[1].split("-", 2);
            }
            auctionDate = splitDateTime[0];
            time = splitTime[0];
            let dateTime = `${auctionDate} ${time}`;
            let start: any = moment(dateTime);
            timezone = localStorage.getItem("timezoneName");
            let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
            hour = start.diff(end, 'hours');
            minutes = start.diff(end, 'minutes');
        }
        this.setState({
            message: ''
        })
        this.auctionName = name;
        auctionId = productId;
        var joinedAuctions: any = localStorage.getItem("joinedAuctions");
        joinedAuctions = JSON.parse(joinedAuctions);
        if (joinedAuctions !== null) {
            if (joinedAuctions.length > 0) {
                joinedAuctions.filter((items: any) => {
                    if (items.perId === productId) {
                         localStorage.setItem(
                           "auctioneerUserId",
                           items.auctionUserId
                         );
                        isCallJoinApi = false
                        localStorage.removeItem("filteredValues");
                        localStorage.setItem("filteredValues", "null");
                        localStorage.removeItem("filteredParcelValues");
                        localStorage.setItem("filteredParcelValues", "null");
                        
                        if (
                          auctionType !== undefined &&
                          auctionType !== "Live"
                        ) {
                            this.props.history.push({
                              pathname: `/preview/tendor/products/${auctionId}/${items.auctionUserId}/${this.auctionName}/${auctionDate}`,
                            });
                        } else {
                          this.props.history.push({
                            pathname: `/preview/products/${productId}/${this.auctionName}/${auctionDate}`,
                          });
                        }
                    }
                    else {
                        if (state === 'NotStarted' && hour <= 0 && minutes < 0) {
                        } else {
                            isCallJoinApi = true
                        }
                    }
                })
            }
            else {
                isCallJoinApi = true
            }
        }
        else {
            isCallJoinApi = true
        }

        if(isCallJoinApi === true){
            this.onClickJoin(auctionType)
        }

    }

    onClickJoin(auctionType: string) {
        const { t } = this.props;
        this.setState({
            onSubmit: true,
            openToast: false,
        })
        const data = {
            'code': ""
        }
        this.props.test();
        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            this.Auth.postRequest(`${joinAuctionUrl}/${auctionId}/join`, data, (status: number, response: any) => {
                if (status === 200) {
                    var auctionUserId = response.id;
                    localStorage.setItem("auctioneerUserId", auctionUserId);
                    shortBreakTimer = response.resumeDateTime;
                    this.setState({
                        openProductModal: false,
                        onSubmit: false,
                        openToast: false,
                    })
                    joinAuction();
                    localStorage.removeItem("filteredValues");
                    localStorage.setItem("filteredValues", "null");
                    localStorage.removeItem("filteredParcelValues");
                    localStorage.setItem("filteredParcelValues", "null");
                    
                    if(auctionType !== undefined && auctionType !== 'Live'){
                        this.props.history.push({ pathname: `/preview/tendor/products/${auctionId}/${auctionUserId}/${this.auctionName}/${auctionDate}` });
                    }else{
                        this.props.history.push({
                          pathname: `/preview/products/${auctionId}/${this.auctionName}/${auctionDate}`,
                        });
                    }
                    
                }
                else if (status > 200 && status < 500) {
                    if (status === 403) {
                        this.setState({ userBlock: true })
                    }

                    if (response[0].code !== undefined) {
                        if (response[0].code === "KB400003") {
                            this.setState({
                              onSubmit: false,
                              alertType: "error",
                              openToast: false,
                              showAlertMessage: `${t(
                                "common:allValidations:participate:alreadySentParticipationMail"
                              )}`,
                              openProductModal: true,
                            });
                        } else if (response[0].code === "KB409004") {
                          this.setState({
                            alertType: "error",
                            openToast: true,
                            showAlertMessage: `${t(
                              "common:allValidations:participate:alreadySentInviationMail"
                            )}`,
                            openProductModal: false,
                          });
                        }
                    }
                    else {
                        this.setState({
                            message: `${t('common:allValidations:join:mailSentFailed')}`,
                            invitationCode: '',
                            onSubmit: false,
                            openProductModal:true
                        })
                        
                    }
                }
                else if (status === 500) {
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: response[0].message,
                        loading: false,
                        openProductModal:false,
                        onSubmit:false
                    })
                }
                else {
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: `${t('common:somethingWentWrong')}`,
                        loading: false,
                        openProductModal:false,
                        onSubmit:false
                    })
                }
            })
        }
    }

    onCloseModal = () => {
        this.setState({
            message: '',
            openProductModal: false,
            openParticipateModal: false
        })
    }

    onClickParticipate() {
        const { t } = this.props;
        this.setState({
            onSubmit: true,
            openToast: false,
        })

        if (!oAuth()) {
            this.props.history.push('/');
        } else {

            var urlString = ""

            let userType = localStorage.getItem("userType");            
            const { isAvailable, auctionIdFromUrl } = getDiamondCategoryFromUrl();

            if(isAvailable){
                auctionId = `${auctionIdFromUrl}`;
            }
            urlString = userType === "SUBSCRIBED" || "SUB_DOMAIN" ? participateUrl : sendInviteeParticipationRequest

            this.Auth.postRequest(`${urlString}?auctionId=${auctionId}`, 'data', (status: any, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        this.setState({
                            openParticipateModal: true,
                            openProductModal: false,
                            onSubmit: false,
                            openToast: false,
                        });
                    }
                }
                else if (status > 200 && status < 500) {
                    if (status === 403) {
                        this.setState({ userBlock: true })
                    }
                    if (response[0].code !== undefined) {
                        if (response[0].code === "KB409003") {
                            this.setState({
                                message: `${t('common:allValidations:participate:alreadySentParticipationMail')}`,
                                onSubmit: false,
                                openProductModal:false,
                                alertType: "error",
                                openToast: true,
                                showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentParticipationMail"
                  )}`,
                            })
                        }

                        else if (response[0].code === "KB409004") {
                            this.setState({
                                message: `${t('common:allValidations:participate:alreadySentInviationMail')}`,
                                onSubmit: false,
                                alertType: "error",
                                openToast: true,
                                showAlertMessage: `Participation Mail Sent Already`,
                                openProductModal:false
                            })
                        }
                    }
                    else if (status === 404) {
                        this.setState({
                            message: `${t('common:invalidRequest')}`,
                            invitationCode: '',
                            onSubmit: false
                        })
                    }
                    else {
                        this.setState({
                            onSubmit: false
                        })
                    }
                }
                else {
                    this.setState({
                        openParticipateModal: false,
                        message: response[0].message,
                        onSubmit: false
                    })
                }
            })
        }
    }

    openParticipate() {
        this.setState({
            openParticipateModal: false
        })
    }

    handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'invitationCode') { this.setState({ invitationCode: e.target.value }) }
    }

    closeModal = () => {
        this.setState({
            openTimerModal: false
        })
    }

    value = () => {
        this.getPreviewList();
    }

    blockCloseModal = () => {
        this.Auth.logout();
        this.props.history.replace('/');
    }

    render() {
        const { t } = this.props;

        return (
            <StyledGrid container={true} spacing={0}>
                {this.state.loading === true ?
                    <div className="loader">
                        <LoadingSpinner />
                    </div>
                    :
                    <Grid item xs={12}>
                        {this.state.products.length > 0 ?
                            <CoreDataGridOfAuction t={t} data={this.state.products}>
                                <AuctionListView t={t} showListOfProduct={this.showListOfProduct} value={this.value} />
                            </CoreDataGridOfAuction>
                            :
                            <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
                        }
                    </Grid>
                }
                {this.state.openProductModal === true &&
                    <StyledModal open={this.state.openProductModal} onClose={this.onCloseModal}>
                        <StyledWrapper container spacing={0}>
                            <StyledMessage item xs={12}>
                                {/* {this.state.message} */}
                            </StyledMessage>
                            <StyledParticipateWrapper item md={12} xs={12} lg={12}>
                                <StyledTextWrapper>
                                    {t('common:preview:modal:participateDontHaveCode')}
                                    {t('common:preview:modal:forparticipation')}
                                </StyledTextWrapper>
                                {
                                    this.state.onSubmit === false ?
                                        <StyledButton participate={true} onClick={() => this.onClickParticipate()}>{t('common:button:participate')}</StyledButton>
                                        :
                                        <Grid>
                                            <StyledButton disableParticipateButton={this.state.onSubmit}>{t('common:button:participate')}</StyledButton>
                                        </Grid>
                                }
                            </StyledParticipateWrapper>
                            
                        </StyledWrapper>
                    </StyledModal>
                }
                {this.state.openParticipateModal === true &&
                    <StyledModal open={this.state.openParticipateModal} onClose={this.onCloseModal}>
                        <ViewParticipateModal openParticipateModal={() => this.openParticipate()} t={t} />
                    </StyledModal>
                }
                {this.state.openToast === true &&
                    <Alert showAlertMessage={this.state.showAlertMessage} alertType={this.state.alertType} />
                }
                { this.state.userBlock === true &&
                    <StyledModal open={this.state.userBlock}>
                        <BlockUserModal t={t}{...this.props} closeModal={this.blockCloseModal} />
                    </StyledModal>
                }
            </StyledGrid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
  test: () => {
    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());
    dispatch(reSetIsMybids());
    dispatch(reSetJwlIsMybids());
    localStorage.removeItem("selectedFilterSort");
  },
});
export default connect(null, mapDispatchToProps)(ProductsGrid);