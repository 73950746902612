import React from "react";
import styled from "styled-components";

interface IProps {
  isPrevDisabled: boolean;
  isNextDisabled: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

const SubNavigationControls = (props: IProps) => {
  const { isPrevDisabled, isNextDisabled, onPrevious, onNext } = props;

  const iconPath = "/images/controls/";
  const prevIcon = isPrevDisabled
    ? "left_arrow_round_edges_disabled.png"
    : "left_arrow_round_edges.png";
  const nextIcon = isNextDisabled
    ? "right_arrow_round_edges_disabled.png"
    : "right_arrow_round_edges.png";

  return (
    <>
      <StyledArrowIconsID
        onClick={onPrevious}
        src={`${iconPath}/${prevIcon}`}
      />
      <StyledArrowIconsID onClick={onNext} src={`${iconPath}/${nextIcon}`} />
    </>
  );
};

export default SubNavigationControls;
