import React, { useEffect, useState } from "react";
import AppLayout from "../layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";

import JewelleryProductAuction from "./component/JewelleryProductAuction";
import DiamondProductAuction from "./component/DiamondProductAuction";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import { useHistory, useParams } from "react-router";
import AuthService from "../auth/AuthService";
import {
  annualAuctionJoinedCall
} from "../../common/config/app.endpoints";
import Alert from "../../common/elements/Alert";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { reSetIsMybids, reSetJwlIsMybids } from "../../redux/slice/commanPagination/commanPaginationSlice";
import { setAnnualAuctionData, setJwlAnnualAuctionData } from "../../redux/slice/annual/commanAnnualSlice";
import { joinAnnualAuction } from "../../common/GenericFunctions";

interface IPendingProductProps extends IRouterComponentProps {
  // t: any;
}

let annualCategoryAuction = "Diamonds";
const ProductAuctionContainer = (props: IPendingProductProps) => {
  let history = useHistory();
  const Auth = new AuthService();
  const [isShowJewelley, setShowJewelley] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [showAlertMessage, setShowAlertMessage] = useState<string>("");
  const [alertType, setAlertType] = useState<string>("");

  // @ts-ignore
  const { auctionType } = useParams();
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (history.location.pathname === "/365products") {
      handleComponents("DIAMODS");
    } else if (history.location.pathname === "/365products/Diamonds" || history.location.pathname === "/365products/Diamond") {
      handleComponents("DIAMODS");
    } else if (history.location.pathname === "/365products/jewellery") {
      handleComponents("JEWELLERY");
    } else if (history.location.pathname === "/365products/Jewellery") {
      handleComponents("JEWELLERY");
    }
  }, [history.location.pathname]);

  useEffect(() => {
    localStorage.setItem("tabSelectedVal", "1");

    if (auctionType === undefined) {
      localStorage.removeItem("currentProductIdOfBidNow");
      localStorage.removeItem("currentJewelleryProductIdOfBidNow");
    }
  }, []);

  const handleComponents = (categoryType: String) => {
    if (categoryType === "DIAMODS") {
      annualCategoryAuction = "Diamonds";
      setShowJewelley(false);
      history.replace("/365products");
      dispatch(reSetJwlIsMybids());
    } else {
      annualCategoryAuction = "Jewellery";
      setShowJewelley(true);
      setIsLoading(true);
      dispatch(reSetIsMybids());
      history.replace("/365products/jewellery");
    }
    callApi();
  };

  const callApi = async () => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${annualAuctionJoinedCall}/${annualCategoryAuction}/join`,
        "",
        (status: any, response: any) => {
          if (status === 200) {
            if (response.category === "Diamonds") {
              dispatch(setAnnualAuctionData({ response }));
            } else {
              dispatch(setJwlAnnualAuctionData({ response }));
            }
            joinAnnualAuction()
            setIsLoading(false);
          }
        }
      );
    }
  };


  const renderLoderspiner = () => {
    return (
      <div className="loading">
        <LoadingSpinner />
      </div>
    );
  };

  return (
    <AppLayout {...props}>
      {isLoading ? (
        renderLoderspiner()
      ) : isShowJewelley ? (
        <JewelleryProductAuction
          {...props}
          setShowAlertMessage={setShowAlertMessage}
          setOpenToast={setOpenToast}
          setAlertType={setAlertType}
        />
      ) : (
        <DiamondProductAuction
          {...props}
          setShowAlertMessage={setShowAlertMessage}
          setOpenToast={setOpenToast}
          setAlertType={setAlertType}
        />
      )}
      {openToast === true && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
    </AppLayout>
  );
};

export default ProductAuctionContainer;
