import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import styled from 'styled-components';

const StyledOptionMenuWrapper = styled.div`
    height:20px;
    text-align: right;
`;

const StyledIcon = styled.img`
    width: 0.5em;
    height: 0.5em;
`;

export interface IProps {
    t?: any;
    deleteAddress: () => void;
    selectMarkasDefault: () => void;
}

export default function OptionMenuofShippingAddress(props: IProps) {
    const { t, selectMarkasDefault, deleteAddress } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectOptionforMarkDefault = () => {
        selectMarkasDefault();
        handleClose();
    }

    const selectOption = () => {
        deleteAddress();
        handleClose();
    }

    return (
        <StyledOptionMenuWrapper>
            <IconButton
                onClick={handleClick}>
                 <StyledIcon src="/images/option-menu.png" />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                <MenuItem onClick={() => selectOptionforMarkDefault()}>{t('common:profile:optionMenu:markAsDefault')}</MenuItem>
                <MenuItem onClick={() => selectOption()}>{t('common:profile:optionMenu:delete')}</MenuItem>
            </Menu>
        </StyledOptionMenuWrapper>
    );
}