import React from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { getDate, addMonths } from "date-fns";
import moment from "moment";
import { Modal } from "@material-ui/core";

import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/eventsCalendar.css";

import ja from "date-fns/locale/ja";
import en_US from "date-fns/locale/en-US";
import zh_CN from "date-fns/locale/zh-CN";

import AuthService from "../../auth/AuthService";
import {
  calendarEventUrl,
  joinAuctionUrl,
  participateUrl,
  sendInviteeParticipationRequest,
} from "../../../common/config/app.endpoints";
import SelectedDatePreviewAuction from "./SelectedDatePreviewAuction";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import ViewParticipateModal from "../preview/ViewParticipateModal";
import {
  getAppointmentData,
  joinAuction,
} from "../../../common/GenericFunctions";
import {
  buttonGradient,
  dateBackgroundGradient,
} from "../../../common/materials/LinearGradient";
import Alert from "../../../common/elements/Alert";

setDefaultLocale("ja");
registerLocale("ja", ja);
registerLocale("en-US", en_US);
registerLocale("zh-CN", zh_CN);

const StyledWrapper = styled(Grid)`
  .react-datepicker {
    display: block !important;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
`;

const StyledTextWrapper = styled(Grid)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 1em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

interface IButtonProps {
  participate?: boolean;
  join?: boolean;
  disableParticipateButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.participate &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
  ${(props) =>
    props.join &&
    css`
      width: 40%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 30px;
      margin-left: 10px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
    ${(props) =>
    props.disableParticipateButton &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      cursor: default;
      background: ${dateBackgroundGradient};
    `}
`;

const StyledWrapperParticipatePopup = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
    overflow-y: scroll;
    height: 250px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    overflow-y: scroll;
    height: 400px;
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1em;
    width: 380px;
  }
`;

const StyledBiddingPriceContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 6px;
  align-items: center;
  text-align: center;
  background: #2660ad;
  cursor: pointer;
`;
const StyledCalendarContainer = styled(Grid)`
  background: ${(props) => props.theme.colors.base.transparent};
  border-bottom: 1px solid #2660ad;
  border-radius: 50px;
  font-family: Calibre-SemiBold;
  padding: 0px 20px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    font-size: 1.6em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 2em;
  }
  color: #2660ad;
`;
export interface IProps extends IRouterComponentProps {
  t: any;
  joindAuctionsForCalendar: any;
  allPreviews: any;
  openAppointment: () => void;
}

export interface IState {
  startDate: Date;
  calendarProducts: Array<any>;
  previews: Array<any>;
  auctions: Array<any>;
  appointments: any;
  openModal: boolean;
  openToast: boolean;
  showAlertMessage: string;
  openProductModal: boolean;
  openParticipateModal: boolean;
  message: string;
  invitationCode: string;
  alertType: string;
  onSubmit: boolean;
  userBlock: boolean;
}

let localization: string = "";
var auctionId: number | string = "";
let previews: Array<any> = [];
let auctions: Array<any> = [];
let appointments: Array<any> = [];
let appointmentList: Array<any> = [];
export default class EventsCalendar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      startDate: new Date(),
      calendarProducts: [],
      previews: [],
      auctions: [],
      appointments: [],
      openModal: false,
      openToast: false,
      showAlertMessage: "",
      openProductModal: false,
      openParticipateModal: false,
      message: "",
      invitationCode: "",
      alertType: "",
      onSubmit: false,
      userBlock: false,
    };
  }

  Auth = new AuthService();

  highlightWithRanges: any = [];
  selectedDate: string = "";
  selectedDatetime: string = "";

  componentDidMount() {
    let language = localStorage.getItem("language");
    if (language === "EN") {
      localization = "en-US";
    } else if (language === "zh_CN") {
      localization = "zh_CN";
    } else {
      localization = "ja";
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.getRequest(
        calendarEventUrl,
        (status: number, response: any) => {
          if (status === 200) {
            var localcalendarArray: Array<Object> = [];
            var previewsArray: Array<Object> = [];
            var auctionArray: Array<Object> = [];
            Object.keys(response).map((key) => {
              var value = response[key];
              localcalendarArray.push({ key, value });
              this.setState({
                calendarProducts: localcalendarArray,
              });
            });
            this.state.calendarProducts.map((product: any, index: number) => {
              var key: Date = product.key;
              if (product.value.previews.length >= 1) {
                previewsArray.push(key);
                this.setState({
                  previews: previewsArray,
                });
              }
              if (product.value.auctions.length >= 1) {
                auctionArray.push(key);
                this.setState({
                  auctions: auctionArray,
                });
              }
            });
          }
        }
      );
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.onAppointment();
    }
  }

  componentWillUnmount() {
    this.setState({
      startDate: new Date(),
      calendarProducts: [],
      previews: [],
      auctions: [],
      appointments: [],
      openModal: false,
    });
    this.highlightWithRanges = [];
  }

  async onAppointment() {
    var appointmentArray: Array<Object> = [];
    let appointmentContext: any = await getAppointmentData();
    if (appointmentContext?.length !== 0) {
      appointmentList = appointmentContext;
      appointmentContext?.map((product: any, index: number) => {
        const datePattern = /\d{4}-\d{2}-\d{2}/;
        // Find the first occurrence of the date pattern in the string
        const match: any = product.start_time.match(datePattern);
        var key: Date = match[0];
        appointmentArray.push(key);
        this.setState({
          appointments: appointmentArray,
        });
      });
    }
  }

  handleChange = (date: Date | null) => {
    this.selectedDate = moment(date).format("YYYY-MM-DD");
    this.selectedDatetime = moment(date).format("YYYY-MM-DD HH:mm:ss");
    this.state.calendarProducts.map((product: any, index: number) => {
      var key: string = product.key;
      if (key === this.selectedDate) {
        this.setState({
          openModal: true,
        });
        auctions = product.value.auctions;
        previews = product.value.previews;
      }
    });
    appointmentList.map((product: any, index: number) => {
      const datePattern = /\d{4}-\d{2}-\d{2}/;
      // Find the first occurrence of the date pattern in the string
      const match: any = product.start_time.match(datePattern);

      var key: string = match[0];
      if (key === this.selectedDate) {
        this.setState({
          openModal: true,
        });
        appointments = appointmentList.filter((obj: any) => {
          const match: any = obj.start_time.match(datePattern);

          var objKey: string = match[0];
          return objKey === this.selectedDate;
        });
      }
    });
  };

  onClose() {
    this.setState({
      openModal: false,
      previews: [],
      auctions: [],
      appointments: [],
    });
    appointments = [];
    previews = [];
    auctions = [];
  }

  convertDateTimeInUserTimeZone = (item: any) => {
    let timezone: any = "";
    let time: string = "";
    let date: string = "";

    date = item?.previewStartDate;
    let userAuctionTime = moment.utc(date);
    if (timezone !== null) {
      timezone = localStorage.getItem("timezoneName");
    }
    if (userAuctionTime !== null || userAuctionTime !== undefined) {
      let result = moment.tz(userAuctionTime, timezone);
      time = result.format("YYYY-MM-DD HH:mm");
    }
    return time;
  };

  previweRedirecthandler = async (product: any) => {
    const { id, name, date } = product;
    let isPreviewStarted: boolean = false;
    let userDateTime = await this.convertDateTimeInUserTimeZone(product);
    //compare current date and current time with preview started date
    let current_time = moment().format("YYYY-MM-DD HH:mm");

    const auctionPreviewDate: any = new Date(userDateTime);
    const currentDateTime: any = new Date(current_time);

    if (auctionPreviewDate <= currentDateTime) {
      isPreviewStarted = true;
    }

    const { t } = this.props;
    auctionId = id;

    if (isPreviewStarted) {
      this.state.calendarProducts.map((product: any, index: number) => {
        var key: string = product.key;
        if (key === this.selectedDate) {
          const preViewData = this.props.joindAuctionsForCalendar.find(
            (item: any) => item.perId === id
          );
          if (preViewData !== undefined) {
            const allPreviewAuction = this.props.allPreviews.find(
              (item: any) => item.perId === id
            );
            localStorage.setItem("userId", preViewData.auctionUserId);
            if (allPreviewAuction !== undefined) {
              if (preViewData.category !== undefined) {
                if (preViewData.category === "Jewellery") {
                  if (
                    preViewData.auctionType !== undefined &&
                    preViewData.auctionType !== "Live"
                  ) {
                    this.props.history.push({
                      pathname: `/preview/tendor/jewellery/products/${auctionId}/${preViewData.auctionUserId}/${name}/${date}`,
                    });
                  } else {
                    this.props.history.push({
                      pathname: `/preview/jewelleyProducts/${auctionId}/${name}/${date}`,
                    });
                  }
                } else {
                  if (
                    preViewData.auctionType !== undefined &&
                    preViewData.auctionType !== "Live"
                  ) {
                    this.props.history.push({
                      pathname: `/preview/tendor/products/${id}/${preViewData.auctionUserId}/${name}/${date}`,
                    });
                  } else {
                    this.props.history.push({
                      pathname: `/preview/products/${auctionId}/${name}/${date}`,
                    });
                  }
                }
              }
            } else {
              this.setState({
                alertType: "error",
                showAlertMessage: `${t("common:home:previweNotStarted")}`,
                openToast: true,
              });
            }
          } else {
            this.onClickJoin(id);
          }
        }
      });
    } else {
      this.setState({
        alertType: "error",
        showAlertMessage: `${t("common:home:previweNotStarted")}`,
        openToast: true,
      });
    }
    setTimeout(() => {
      this.setState({
        openToast: false,
      });
    }, 2000);

    this.onClose();
  };

  auctionRedirecthandler = async (auctionproduct: any) => {
    const { id, name, date } = auctionproduct;
    // get preview item for preview started check
    let perviewValue = previews.find((element: any) => element.id === id);
    // get calendar Auction data
    let userDateTime = await this.convertDateTimeInUserTimeZone(perviewValue);
    let isPreviewStarted: boolean = false;
    //compare current date and current time with preview started date
    let current_time = moment().format("YYYY-MM-DD HH:mm");

    const auctionPreviewDate: any = new Date(userDateTime);
    const currentDateTime: any = new Date(current_time);

    if (auctionPreviewDate <= currentDateTime) {
      isPreviewStarted = true;
    }

    const { t } = this.props;
    auctionId = id;
    if (isPreviewStarted) {
      this.state.calendarProducts.map((product: any, index: number) => {
        var key: string = product.key;
        if (key === this.selectedDate) {
          const auctionData = this.props.joindAuctionsForCalendar.find(
            (item: any) => item.perId === id
          );
          if (auctionData !== undefined) {
            const preViewDataForAuction = this.props.allPreviews.find(
              (item: any) => item.perId === id
            );
            localStorage.setItem("userId", auctionData.auctionUserId);
            if (preViewDataForAuction !== undefined) {
              if (auctionData.category !== undefined) {
                if (auctionData.category === "Jewellery") {
                  if (
                    auctionData.auctionType !== undefined &&
                    auctionData.auctionType !== "Live"
                  ) {
                    if (auctionData.state === "Started") {
                      this.props.history.replace({
                        pathname: `/auction/tender/jewellery/details/${auctionId}/${auctionData.auctionUserId}`,
                      });
                    } else {
                      this.props.history.push({
                        pathname: `/preview/tendor/jewellery/products/${auctionId}/${auctionData.auctionUserId}/${auctionData.name}/${date}`,
                      });
                    }
                  } else {
                    if (auctionData.state === "Started") {
                      this.props.history.replace({
                        pathname: `/jewelleryAuction/details/${auctionId}/${name}/${auctionData.productCount}`,
                      });
                    } else {
                      this.props.history.push({
                        pathname: `/preview/jewelleyProducts/${auctionId}/${name}/${date}`,
                      });
                    }
                  }
                } else {
                  if (
                    auctionData.auctionType !== undefined &&
                    auctionData.auctionType !== "Live"
                  ) {
                    if (auctionData.state === "Started") {
                      this.props.history.replace({
                        pathname: `/auction/tender/details/${auctionId}/${auctionData.auctionUserId}`,
                      });
                    } else {
                      this.props.history.push({
                        pathname: `/preview/tendor/products/${id}/${auctionData.auctionUserId}/${auctionData.name}/${auctionData.date}`,
                      });
                    }
                  } else {
                    if (auctionData.state === "Started") {
                      this.props.history.replace({
                        pathname: `/auction/details/${auctionId}/${name}/${auctionData.productCount}`,
                      });
                    } else {
                      this.props.history.push({
                        pathname: `/preview/products/${auctionId}/${name}/${date}`,
                      });
                    }
                  }
                }
              }
            } else {
              this.setState({
                alertType: "error",
                showAlertMessage: `${t("common:home:previweNotStarted")}`,
                openToast: true,
              });
            }
          } else {
            this.onClickJoin(id);
          }
        }
      });
    } else {
      this.setState({
        alertType: "error",
        showAlertMessage: `${t("common:home:previweNotStarted")}`,
        openToast: true,
      });
    }
    setTimeout(() => {
      this.setState({
        openToast: false,
      });
    }, 2000);
    this.onClose();
  };

  onClickJoin = (auctionId: any) => {
    const { t } = this.props;
    const data = {
      code: "",
    };
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.postRequest(
        `${joinAuctionUrl}/${auctionId}/join`,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            this.setState({
              openProductModal: false,
              onSubmit: false,
              openToast: false,
            });
            joinAuction();
            this.setState({
              alertType: "error",
              showAlertMessage: `${t("common:home:previweNotStarted")}`,
              openToast: true,
            });
          } else if (status > 200 && status < 500) {
            if (status === 403) {
              this.setState({ userBlock: true });
            }
            if (response[0].code !== undefined) {
              if (response[0].code === "KB400003") {
                this.setState({
                  onSubmit: false,
                  alertType: "error",
                  openToast: false,
                  showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentParticipationMail"
                  )}`,
                  openProductModal: true,
                });
              } else if (response[0].code === "KB409004") {
                this.setState({
                  alertType: "error",
                  openToast: true,
                  showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentInviationMail"
                  )}`,
                  openProductModal: false,
                });
              }
            } else {
              this.setState({
                message: `${t("common:allValidations:join:mailSentFailed")}`,
                invitationCode: "",
                onSubmit: false,
                openProductModal: true,
              });
            }
          } else if (status === 500) {
            this.setState({
              alertType: "error",
              openToast: true,
              showAlertMessage: response[0].message,
              openProductModal: false,
              onSubmit: false,
            });
          } else {
            this.setState({
              alertType: "error",
              openToast: true,
              showAlertMessage: `${t("common:somethingWentWrong")}`,
              openProductModal: false,
              onSubmit: false,
            });
          }
        }
      );
    }
  };

  onClickParticipate() {
    const { t } = this.props;
    this.setState({
      onSubmit: true,
      openToast: false,
    });

    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      var urlString = "";

      let userType = localStorage.getItem("userType");

      urlString =
        userType === "SUBSCRIBED" || "SUB_DOMAIN"
          ? participateUrl
          : sendInviteeParticipationRequest;

      this.Auth.postRequest(
        `${urlString}?auctionId=${auctionId}`,
        "data",
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              this.setState({
                openParticipateModal: true,
                openProductModal: false,
                onSubmit: false,
                openToast: false,
              });
            }
          } else if (status > 200 && status < 500) {
            if (status === 403) {
              this.setState({ userBlock: true });
            }
            if (response[0].code !== undefined) {
              if (response[0].code === "KB409003") {
                this.setState({
                  message: `${t(
                    "common:allValidations:participate:alreadySentParticipationMail"
                  )}`,
                  onSubmit: false,
                  openProductModal: false,
                  alertType: "error",
                  openToast: true,
                  showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentParticipationMail"
                  )}`,
                });
              } else if (response[0].code === "KB409004") {
                this.setState({
                  message: `${t(
                    "common:allValidations:participate:alreadySentInviationMail"
                  )}`,
                  onSubmit: false,
                  alertType: "error",
                  openToast: true,
                  showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentInviationMail"
                  )}`,
                  openProductModal: false,
                });
              }
            } else if (status === 404) {
              this.setState({
                message: `${t("common:invalidRequest")}`,
                invitationCode: "",
                onSubmit: false,
              });
            } else {
              this.setState({
                onSubmit: false,
              });
            }
          } else {
            this.setState({
              openParticipateModal: false,
              message: response[0].message,
              onSubmit: false,
            });
          }
        }
      );
    }
    this.setState({
      openToast: false,
    });
  }

  onCloseModal = () => {
    this.setState({
      message: "",
      openProductModal: false,
      openParticipateModal: false,
    });
  };

  openParticipate() {
    this.setState({
      openParticipateModal: false,
    });
  }

  render() {
    const { t } = this.props;

    const renderDayContents = (day: any, date: any) => {
      return (
        <div>
          <div className="react-datepicker__day--custom">{getDate(date)}</div>
          <div className="legendWrapper">
            <div className="preview">&nbsp;</div>
            <div className="auction">&nbsp;</div>
            <div className="noAuction">&nbsp;</div>
            <div className="appointment">&nbsp;</div>
          </div>
        </div>
      );
    };
    if (
      this.state.previews.length !== 0 ||
      this.state.auctions.length !== 0 ||
      this.state.appointments.length !== 0
    ) {
      this.highlightWithRanges = [
        {
          "react-datepicker__day--highlighted-custom-preview":
            this.state.previews.map((day: any, index: number) => {
              return new Date(day);
            }),
        },
        {
          "react-datepicker__day--highlighted-custom-auction":
            this.state.auctions.map((day: any, index: number) => {
              return new Date(day);
            }),
        },
        {
          "react-datepicker__day--highlighted-custom-appointment":
            this.state.appointments.map((day: any, index: number) => {
              return new Date(day);
            }),
        },
      ];
    }
    return (
      <StyledWrapper item xs={12} sm={12} lg={12} md={10} xl={12}>
        <div style={{ display: "flex", gap: "20px", marginBottom: "15px" }}>
          <StyledCalendarContainer item xs={12} sm={10} lg={8} md={10} xl={8}>
            {t("common:home:upcomingAuctions")}
          </StyledCalendarContainer>
          <Grid item xs={12} sm={10} lg={4} md={12} xl={4}>
            {localStorage.getItem("subDomain") === "zipang" ? (
              <StyledBiddingPriceContainer onClick={this.props.openAppointment}>
                {t("common:home:requestAppointment")}
              </StyledBiddingPriceContainer>
            ) : null}
          </Grid>
        </div>
        {this.highlightWithRanges !== null && (
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChange}
            highlightDates={this.highlightWithRanges}
            locale={localization}
            renderDayContents={renderDayContents}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 3)}
            showDisabledMonthNavigation
            inline
          />
        )}

        {this.state.openModal === true && (
          <StyledModal
            open={this.state.openModal}
            onClose={() => this.onClose()}
          >
            <SelectedDatePreviewAuction
              onClose={() => this.onClose()}
              t={t}
              auctions={auctions}
              previews={previews}
              selectedDate={this.selectedDate}
              redirectionPreviweHandler={this.previweRedirecthandler}
              redirectionAuctionHandler={this.auctionRedirecthandler}
              appointmentList={appointments}
            />
          </StyledModal>
        )}
        {this.state.openProductModal === true && (
          <StyledModal
            open={this.state.openProductModal}
            onClose={this.onCloseModal}
          >
            <StyledWrapperParticipatePopup container spacing={0}>
              <StyledParticipateWrapper item md={12} xs={12} lg={12}>
                <StyledTextWrapper>
                  {t("common:preview:modal:participateDontHaveCode")}
                  {t("common:preview:modal:forparticipation")}
                </StyledTextWrapper>
                {this.state.onSubmit === false ? (
                  <StyledButton
                    participate={true}
                    onClick={() => this.onClickParticipate()}
                  >
                    {t("common:button:participate")}
                  </StyledButton>
                ) : (
                  <Grid>
                    <StyledButton
                      disableParticipateButton={this.state.onSubmit}
                    >
                      {t("common:button:participate")}
                    </StyledButton>
                  </Grid>
                )}
              </StyledParticipateWrapper>
            </StyledWrapperParticipatePopup>
          </StyledModal>
        )}
        {this.state.openParticipateModal === true && (
          <StyledModal
            open={this.state.openParticipateModal}
            onClose={this.onCloseModal}
          >
            <ViewParticipateModal
              openParticipateModal={() => this.openParticipate()}
              t={t}
            />
          </StyledModal>
        )}
        {this.state.openToast === true && (
          <Alert
            showAlertMessage={this.state.showAlertMessage}
            alertType={this.state.alertType}
          />
        )}
      </StyledWrapper>
    );
  }
}
