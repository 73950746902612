import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Countdown from "react-countdown";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import {
  backgroundGradient,
  dateBackgroundGradient,
} from "../../../common/materials/LinearGradient";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { currentTimeUrl } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import Modal from "@material-ui/core/Modal";
import ServerTime from "../views/ServerTime";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { StyledShortBreakTimer } from "../views/AuctionCardViewAtPreview";

import { StyledDateGridOngoing } from '../views/JewelleryAuctionCardViewAtPreview';
import EllipsisText from '../../../common/elements/EllipsisText';

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Box } from "@material-ui/core";
const faInfoCircleProps = faInfoCircle as IconProp;

const StyledContainer = styled(Grid)`
  cursor: pointer;
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  margin: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  .borderRightBlue {
    border-right: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .borderBottomBlue {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
`;

const StyledTitleContainer = styled(Grid)`
  background: ${backgroundGradient};
  padding: 0.3em;
  // height: 50px;
  display: flex;
  align-items: center;
  font-size: 1em;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 12px;
  }
`;

const StyledProductContainer = styled(Grid)`
  padding: 0.5em;
  text-align: left;
  .heading {
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.base.grey};
  }
  .productsValue {
    font-size: 1em;
  }
  .textWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 0.8em;
  }
`;

const StyledDateWrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.base.grey};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 10px;
  }
`;

const StyledDateContainer = styled(Grid)`
  margin: 10px;
  background: ${dateBackgroundGradient};
  border-radius: 2px;
`;

const StyledDateGrid = styled(Grid)`
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
`;

const StyledGrid = styled(Grid)`
  .countdownText {
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    font-weight: bold;
    font-size: 14px;
    width: 100%;
    text-align: right;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
  .technicalGlitchAuction {
    color: ${(props) => props.theme.colors.base.red};
  }
  .aboutToStartText {
    width: 100%;
    text-align: right;
  }
`;

const StyledModal = styled(Modal)`
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }
  .technicalGlitchAuction {
    color: ${(props) => props.theme.colors.base.red};
  }
  .aboutToStartText {
    width: 100%;
    text-align: right;
  }
`;

const StyledRightProductContainer = styled(Grid)`
    padding: 0.2em;
    text-align: right;
    margin-bottom: 2px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
    .heading {
        font-size : 0.8em;
        color: ${(props) => props.theme.colors.base.grey};
    } 
    .productsValue {
        font-size: 1em;
    }
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
        font-size : 0.7em;
    }
    ${(props) => props.theme.breakpoints.between("sm", "md")} {
       font-size : 0.8em;
    }
    ${(props) => props.theme.breakpoints.between("md", "lg")} {
        font-size : 0.8em;
    }
`;

const StyledImage = styled.img`
  height: 40px;
  width: 40px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 35px;
    width: 35px;
  }
`;

const StyledBidTitle = styled.span`
    font-size: 14px;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 10px;
    }
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        font-size: 10px;
    }
`;

interface IProps extends IRouterComponentProps {
  t?: any;
  product: any;
  value: any;
}

export const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    cursor: "pointer",
    color: theme.palette.common.black,
  },
  tooltip: {
    fontSize: "14px",
    backgroundColor: theme.palette.common.black,
  },
}));

const StyledTendorLbl = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.textColor};
    padding: 0.4em;
    text-align: center;
`;

export function PDFTooltip(props: JSX.IntrinsicAttributes & TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

let date: string | null = "";
let time: string | null = "";
let timezone: any = "";
let hour: number | null | string = "";
let seconds: any = "";
let minutes: any = "";
let timeDifference: any = "";
let isUpdateCon: boolean = false;

const AfterCountdownComplete = (props: IProps) => {
  const { t } = props;

  useEffect(() => {
    props.value(true);
    isUpdateCon = true;
  }, []);

  return <span></span>;
};

const JewelleryAuctionsView = (props: IProps) => {
  const [openTimerModal, setOpenTimerModal] = useState(false);

  const Auth = new AuthService();
  let { t } = props;

  useEffect(() => {
    date = "";
    time = "";
  }, []);

  if (timezone !== null) {
    timezone = localStorage.getItem("timezoneName");
  }
  if (
    props.product.productCount === null ||
    props.product.productCount === undefined
  ) {
    props.product.productCount = "-";
  }
  const GetShortBreakTimer = (stateTime: any, state: any) => {
    if (stateTime !== null || stateTime !== undefined) {
      let previewTime = moment.utc(stateTime);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let start: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let end: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          if (state === undefined || state === "Auction_Break") {
            var timeDiff = start - end;
            seconds = Math.round(timeDiff);
            {
              timeDifference[0] === "-"
                ? (seconds = seconds - timeDifference)
                : (seconds = seconds + timeDifference);
            }
          } else {
            if (hour === 0) {
              var timeDiff = start - end;
              seconds = Math.round(timeDiff);
              {
                timeDifference[0] === "-"
                  ? (seconds = seconds - timeDifference)
                  : (seconds = seconds + timeDifference);
              }
            }
          }
        }
      }
    }
  };

  if (
    props.product.state === undefined ||
    props.product.state === "Auction_Break"
  ) {
    GetShortBreakTimer(props.product.resumeDateTime, props.product.state);
  } else {
    GetShortBreakTimer(props.product.startDate, props.product.state);
  }

  const onPress = (auctionUserId: string) => {
    localStorage.setItem("userId", auctionUserId);
    if (
      props.product.state === "Started" ||
      props.product.state === "Auction_Break"
    ) {
      if (!oAuth()) {
        props.history.push("/");
      } else {
        Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              let timePassedInMilliseconds = (Date.now() - response) / 1000;
              localStorage.setItem(
                "notSyncedTime",
                JSON.stringify(timePassedInMilliseconds)
              );
              if (props.product.auctionType === "Live") {
                props.history.replace({
                  pathname: `/jewelleryAuction/details/${props.product.perId}/${props.product.name}/${props.product.productCount}`,
                });
              } else {
                props.history.replace({
                  pathname: `/auction/tender/jewellery/details/${props.product.perId}/${props.product.auctionUserId}`,
                });
              }
            }
          }
        });
      }
    }
  };

  const closeModal = () => {
    setOpenTimerModal(false);
  };

  return (
    <StyledGrid>
      <StyledContainer onClick={() => onPress(props.product.auctionUserId)}>
        <Grid container={true} spacing={0} className="borderBottomBlue">
          {/* <StyledTitleContainer item={true} xs={12} className="fontBold">{props.product.name}</StyledTitleContainer> */}
          <StyledTitleContainer xs={12} item container>
            {props.product.auctionType != undefined &&
            props.product.auctionType != null ? (
              <>
                {props.product.auctionType !== "Live" ? (
                  <>
                    <Grid item xs={8} lg={6} md={8} xl={8} className="fontBold">
                      <EllipsisText
                        text={props.product.name}
                        length={16}
                        tail={"..."}
                      />
                    </Grid>
                    <StyledTendorLbl
                      xs={4}
                      lg={6}
                      md={4}
                      xl={4}
                      style={{ padding: "0.4em", fontSize: "12px" }}
                    >
                      {t("common:preview:tendor:popcornBidding")}
                    </StyledTendorLbl>
                  </>
                ) : (
                  <>
                    <Grid item xs={7} className="fontBold">
                      <EllipsisText
                        text={props.product.name}
                        length={18}
                        tail={"..."}
                      />
                    </Grid>
                    <StyledTendorLbl xs={5}>
                      {t("common:preview:tendor:live")}
                    </StyledTendorLbl>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={7} className="fontBold">
                  <EllipsisText
                    text={props.product.name}
                    length={18}
                    tail={"..."}
                  />
                </Grid>
                <StyledTendorLbl xs={5}>
                  {t("common:preview:tendor:live")}
                </StyledTendorLbl>
              </>
            )}
          </StyledTitleContainer>
        </Grid>
        <Grid container={true} spacing={0}>
          <><StyledProductContainer item={true} xs={6}>
            <div className="heading fontBold">
              {t("common:preview:products")}
            </div>
            <div className="productsValue fontBold">
              {props.product.productCount} {t("common:preview:pieces")}
            </div>
          </StyledProductContainer>
          </>
          {props.product.state === 'Started' && <>
            <StyledRightProductContainer item={true} xs={6}>
              <Box boxShadow={3} style={{
                display: 'flex',
                borderRadius: '10px',
                justifyContent: 'center',
                height: '28px',
                padding: '5px',
                cursor: 'pointer',
                flexDirection: 'row',
                alignItems: 'flex-end',
                whiteSpace: 'pre',
              }}>
                <>
                  <StyledImage src="/images/assets/bid_Icon.png" />
                  <StyledBidTitle className="fontBold">{t('common:preview:bidButton')}</StyledBidTitle>
                </>
              </Box>
            </StyledRightProductContainer>
          </>}
        </Grid>
      </StyledContainer>
      <StyledDateContainer>
        {props.product.state === "Started" ? (
          <StyledDateGrid item xs={12}>
            <StyledDateWrapper className="fontBold">
              {t("common:ongoing")}
            </StyledDateWrapper>
          </StyledDateGrid>
        ) : (
          <div>
            {props.product.state === "Auction_Break" ? (
              <div>
                <StyledDateGrid item xs={12}>
                  <Grid item xs={7}>
                    <StyledDateWrapper className="fontBold">
                      {t("common:willResume")}
                    </StyledDateWrapper>
                  </Grid>
                  <StyledShortBreakTimer item xs={5}>
                    <Countdown
                      date={Date.now() + seconds}
                      className="countdownText"
                      daysInHours={false}
                    >
                      <AfterCountdownComplete {...props} />
                    </Countdown>
                  </StyledShortBreakTimer>
                </StyledDateGrid>
              </div>
            ) : (
              <Grid>
                {props.product.state === "Paused" ? (
                  <StyledDateGrid item xs={12}>
                    <StyledDateWrapper className="fontBold">
                      {t("common:paused")}
                    </StyledDateWrapper>
                  </StyledDateGrid>
                ) : (
                  <div>
                    {hour === 0 && minutes >= 0 ? (
                      <StyledDateGridOngoing
                        container
                        className="dateTimeStyle"
                      >
                        <Grid item xs={5}>
                          <Countdown
                            date={Date.now() + seconds}
                            className="countdownText"
                            daysInHours={true}
                          >
                            <AfterCountdownComplete {...props} />
                          </Countdown>
                        </Grid>
                        <Grid item xs={7}>
                          <StyledDateWrapper
                            className=" aboutToStartText"
                            style={{ textAlign: "right" }}
                          >
                            {t("common:abouttoStart")}
                          </StyledDateWrapper>
                        </Grid>
                      </StyledDateGridOngoing>
                    ) : (
                      <div>
                        {minutes < 0 && props.product.state === "NotStarted" ? (
                          <StyledDateGridOngoing item xs={12}>
                            <PDFTooltip
                              disableTouchListener
                              title={`${t(
                                "common:auction:technicalGlitch:firstLine"
                              )} ${t(
                                "common:auction:technicalGlitch:secondLine"
                              )}`}
                            >
                              <div>
                                <FontAwesomeIcon
                                  icon={faInfoCircleProps}
                                  className="technicalGlitchAuction"
                                />
                              </div>
                            </PDFTooltip>
                            <StyledDateWrapper
                              className="fontBold technicalGlitchAuction"
                              style={{ textAlign: "right" }}
                            >
                              {t("common:auction:notAbleToStart")}
                            </StyledDateWrapper>
                          </StyledDateGridOngoing>
                        ) : (
                          <StyledDateGrid item xs={12}>
                            <StyledDateWrapper className="fontBold">
                              {date}
                            </StyledDateWrapper>
                            <StyledDateWrapper className="fontBold">
                              {time}
                            </StyledDateWrapper>
                          </StyledDateGrid>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            )}
          </div>
        )}
      </StyledDateContainer>
      {openTimerModal === true && (
        <StyledModal open={openTimerModal} onClose={closeModal}>
          <ServerTime t={t} {...props} modalType="restrintForJoin" />
        </StyledModal>
      )}
    </StyledGrid>
  );
};

export default JewelleryAuctionsView;
