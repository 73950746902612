import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import styled, { css } from 'styled-components';

import { usePagination } from "@material-ui/lab/Pagination";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { buttonGradient } from '../../../common/materials/LinearGradient';

const StyledPaginationContainer = styled.div`
    text-align: center;
`;

const StyledList = styled.ul`
    list-style: none;
    padding: 5px;
    display: flex;
    justify-content: center;
`;

const StyledListItem = styled.li`
    &:last-child {
        border-right: 1px solid ${(props) => props.theme.colors.base.fieldSetBorderColor};
    }
`;

const StyledEmptyItem = styled.span`
    border-left: 1px solid ${(props) => props.theme.colors.base.fieldSetBorderColor};
    display: inline-block;
    min-height: 26px;
`;

interface IButtonProps {
    selected?: boolean;
    disabled?: boolean;
}

const StyledListButton = styled.button<IButtonProps>`
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBorderColor};
    padding: 5px 12px;
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    background: ${(props) => props.theme.colors.base.white};
    ${props => props.selected && css`
        background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
        color: ${(props) => props.theme.colors.base.white};
        border: none;
        padding: 6px 12px;
    `}
    ${props => props.disabled && css`
        color: ${(props) => props.theme.colors.base.lightGrey};
    `}
`;

export interface IProps {
    t: any;
    itemsPerPage: number;
    currentPage: number;
    totalElements: string | number | any;
    paginateCallback: (page: number) => void;
    callbackForApiCalling: (value: boolean, page: number) => void;
}

const ActionPaginationForProducts = (props: IProps) => {
    const { currentPage, itemsPerPage, paginateCallback, callbackForApiCalling, totalElements, t } = props;
    const theme = useTheme();
    const isSmallDevices = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const pageCount = Math.ceil(totalElements / itemsPerPage);
    const { items } = usePagination({
        count: pageCount,
        page: currentPage,
        boundaryCount: 1,
        defaultPage: 1,
        siblingCount: (isSmallDevices) ? 1 : 3,
        onChange: function (event: object, page: number) {
            paginateCallback(page);
            callbackForApiCalling(true, page);
        }
    });

    return (
        <StyledPaginationContainer>
            <nav>
                <StyledList>
                    {items.map(({ page, type, selected, ...item }, index) => {
                        let children = null;
                        if (type === "start-ellipsis" || type === "end-ellipsis") {
                            children = (
                                <StyledEmptyItem>…</StyledEmptyItem>
                            );
                        } else if (type === "page") {
                            children = (
                                <StyledListButton
                                    type="button"
                                    selected={selected}
                                    {...item}
                                >
                                    {page}
                                </StyledListButton>
                            );
                        } else {
                            let typeText = (type === 'previous') ? `${t('common:previous')}` : `${t('common:newCommanTrans.next')}`;
                            children = (
                                <StyledListButton className="fontBold" type="button" {...item}>
                                    {typeText}
                                </StyledListButton>
                            );
                        }
                        return <StyledListItem key={index}>{children}</StyledListItem>;
                    })}
                </StyledList>
            </nav>
        </StyledPaginationContainer>
    )
};

export default ActionPaginationForProducts;