import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { Card } from '@material-ui/core';
import SellDiamonds from './SellDiamonds';
import BuyDiamonds from './BuyDiamonds';

const StyledHomeCategoryImg = styled('img')`
    height: 7vh;
    ${props => props.theme.breakpoints.only('sm')} {
        height: 4vh;
    };
`;
const StyledTitleContainer = styled.h1`
    margin-top: 85px;
    font-size: 3em;
    text-align: center;
    color: ${(props) => props.theme.colors.base.textColor};
    padding: 0.5em 0;
    font-family: Calibre-Regular;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.1em;
        padding: 1em 0;
    }
    ${props => props.theme.breakpoints.only('sm')} {
        font-size: 1.6em;
    }
    ${props => props.theme.breakpoints.only('md')} {
        font-size: 2em;
    }
`;

const StyledCategoryName = styled.div`
    // padding: 10px 0;
    display: flex;
    align-items: end;
    font-family: Gilroy-Bold;
    color: black;
    font-weight: bold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.2em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 1.2em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {
        font-size: 1.2em;
    }
    @media (width: 2560px) {
        font-size: 2.4em;
    }
`;

const StyledCategoryContainer = styled(Grid)`
    display: flex;
    text-align: center;
    width: 100%
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        border: 1px solid ${(props) => props.theme.colors.base.borderColor}; 
        margin: 0;
        .categoryItem {
            padding: 0px 0;
        }
        .bb {
            border: 1px solid ${(props) => props.theme.colors.base.borderColor}; 
        }
        .br {
            border: 1px solid ${(props) => props.theme.colors.base.borderColor}; 
        }
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        .categoryItem {
            padding: 0px 0;
        }
        border: none;
        .bb {
            border: none;
        }
        .br {
            border: none;
        }
    }
`;

const StyledCategoryContainerWrapper = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerTextContainer = styled(Grid)`
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: contents;
`;

const TextStyle = styled(Grid)`
    width: 90%;
    height: 100%;
    color:${(props) => props.theme.colors.base.darkGrey};
    font-size: 2.2vh;
    font-family: Calibre-Regular;
    text-align: center;
`;

const StyledGrid = styled(Grid)`
    display: flex;
    justify-content: center;
`;
const StyledMainAuctionHandler = styled(Grid)`
    padding: 5px;
`;

const StyledAccordion = styled.div`
    .MuiPaper-elevation1 {
        box-shadow: none;
    }
    .MuiAccordionSummary-root {
        // min-height: 20px;
    }
    .MuiAccordionSummary-root.Mui-expanded {
        height: 15px !important;
        min-height: 50px !important;
    }
    .Mui-expanded {
        // min-height: 20px;
    }
`;

const StyledAuctionHandler = styled(Grid)`
    display: flex;
    background: transparent linear-gradient(45deg, #FFFFFF 0%, #0469D5 100%) 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
`;


interface IProps {
    t: any;
}

const CategoryAtLanding = (props: IProps) => {
    const { t } = props;
    return (
        <Grid container={true} spacing={0} className="categoryContainer" id="AuctionSections"> 
            <Grid item={true} xs={12} md={12}>
                <StyledTitleContainer>{t('common:landingPage:newHeading')}</StyledTitleContainer>
            </Grid>           
            <InnerTextContainer>
                <TextStyle item={true} xs={10} md={12}>{t('common:landingPage:text')}</TextStyle>
            </InnerTextContainer>
             <StyledGrid item={true} xs={12}>
                <StyledCategoryContainerWrapper item={true} xs={12}>
                    <StyledCategoryContainer container={true} spacing={0} className="categoryContainer" >
                        <StyledMainAuctionHandler item={true} xs={12} sm={12} lg={6} className="categoryItem bb br" >
                            <Card style={{width: '100%', color: 'lightgray'}}>
                                <div>
                                <StyledAuctionHandler>
                                    <StyledHomeCategoryImg src="/images/homePageLanding.png" />
                                    <StyledCategoryName>Sell Diamond</StyledCategoryName>
                                </StyledAuctionHandler>
                                <StyledAccordion>
                                    <SellDiamonds/>
                                </StyledAccordion>
                                </div>
                            </Card>
                        </StyledMainAuctionHandler>
                        <StyledMainAuctionHandler item={true} xs={12} sm={12} lg={6} className="categoryItem bb">
                            <Card style={{width: '100%', color: 'lightgray'}}>
                                <div>
                                <StyledAuctionHandler>
                                    <StyledHomeCategoryImg src="/images/homePageLanding.png" />
                                    <StyledCategoryName>Buy Diamond</StyledCategoryName>
                                </StyledAuctionHandler>
                                    <StyledAccordion><BuyDiamonds/></StyledAccordion>
                                </div>
                            </Card>
                        </StyledMainAuctionHandler>                       
                    </StyledCategoryContainer>
                </StyledCategoryContainerWrapper>
            </StyledGrid>
        </Grid>
    )
}

export default CategoryAtLanding