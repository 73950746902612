import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import IRouterComponentProps from "../../models/IRouterComponentProps";
import PaymentAgreementModal from "../../common/elements/PaymentAgreementModal";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import NewHeader from "../header/new-Header/NewHeader";

const StyledContainer = styled.div`
  margin: 0 auto;
  padding: 0;
  display: flex;
  overflow: auto;
`;

const StyledGridMainContainer = styled(Grid)`
  padding: 0px;
  flex-grow: 1;
  margin: 0px;
  width: calc(100% - 240px);
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 0px;
  }
`;

const ChildrenDiv = styled(Grid)`
  margin-top: 55px;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 55px;
    padding-left: 0 !important;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    margin-top: 25px;
  }
`;


export interface IAppLayoutProps extends IRouterComponentProps {
  children: any;
  t?: any;
}

const AppLayout = (props: IAppLayoutProps) => {
  const [agreementModalOpen, setAgreementModalOpen] = useState(false);
  const { children, t } = props;

  useEffect(() => {
    let isPaymentConditionAgree: any = localStorage.getItem(
      "paymentConditionAgree"
    );
    if (isPaymentConditionAgree === "false") {
      setAgreementModalOpen(true);
    }
  }, []);

  let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
  isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
  if (isShowDrawerIcon === null) {
    localStorage.setItem("isShowDrawerIcon", "false");
  }

  const renderPaymentAgreement = () => {
    return (
      <StyledModal open={agreementModalOpen} className="PaymentAgreementModal">
        <PaymentAgreementModal
          closeModal={() => {
            setAgreementModalOpen(false);
            localStorage.setItem("paymentConditionAgree", "true");
          }}
        />
      </StyledModal>
    );
  };

  return (
    <StyledContainer className="rootContainer">
      <NewHeader />
      <StyledGridMainContainer
        container={true}
        spacing={0}
        className="mainContainer"
      >
        <ChildrenDiv item={true} xs={12} className="contentContainer">
          {children}
        </ChildrenDiv>
      </StyledGridMainContainer>
      {agreementModalOpen && renderPaymentAgreement()}
    </StyledContainer>
  );
};

export default AppLayout;
