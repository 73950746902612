import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";

import AppLayout from "../components/layouts/AppLayout";
import IRouterComponentProps from "../models/IRouterComponentProps";
import VideoAtHome from "../components/products/VideoAtHome";
import CorouselListAtHome from "../components/products/home/CorouselListAtHome";
import EventsCalendar from "../components/products/home/EventsCalendar";
import {
  advertisementUrl,
  annualAuctionJoinedCall,
  b,
  baseUrlRedirect,
  domainName,
  getAllJoinedAuctionsUrl,
  getTimeZones,
  joinAuctionUrl,
  participateUrl,
  previewUrl,
  profileUrl,
  sendInviteeParticipationRequest,
} from "../common/config/app.endpoints";
import AuthService from "../components/auth/AuthService";
import ProductSlider from "../components/home-product-slider/home-slider";
import Alert from "../common/elements/Alert";
import LoadingSpinner from "../common/elements/LoadingSpinner";
import { dateBackgroundGradient } from "../common/materials/LinearGradient";
import ViewParticipateModal from "../components/products/preview/ViewParticipateModal";
import ApiTimeout from "./ApiTimeout";
import { StyledModal } from "../common/materials/GenericStyleComponent";
import { oAuth } from "../routes/basic/BasicAppRouter";
import { joinAuction } from "../common/GenericFunctions";
import BlockUserModal from "../components/products/BlockUser/BlockUserModal";
import NewPassword from "./login/passwordReset/NewPassword";
import SubdomainEditProfileForm from "../components/profile/SubdomainEditProfileForm";
import { useDispatch, useSelector } from "react-redux";
import {
  resetItemPerPage,
  resetCurrentApiStartCount,
  resetJewelleryItemPerPage,
  resetJewelleryCurrentApiStartCount,
  resetAnnualCurrentApiStartCount,
  resetAnnualItemPerPage,
  resetAnnualJewelleryCurrentApiStartCount,
  resetAnnualJewelleryItemPerPage,
  reSetLiveCurrentApiStartCount,
  reSetLiveAuctionPagination,
  reSetIsMybids,
  selectedParticipateId,
  resetParticipateProductId,
} from "../redux/slice/commanPagination/commanPaginationSlice";
import { AppDispatch } from "../redux/store";
import { RootState } from "../redux/reducer";
import InlineComponent from "../components/products/home/CalendlyEvent";
import {
  resetAllPreview,
  setAllPreview,
} from "../redux/slice/annual/commanAnnualSlice";
import PaddleNumberModal from "../common/elements/PaddleNumberModal";
import RightTabs from "../common/elements/RightTabs";

const StyledLayoutGrid = styled(Grid)`
  .advertisementText {
    font-family: Calibre-SemiBold;
    color: #2660ad;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      font-size: 10px;
    }
    ${(props) => props.theme.breakpoints.down("lg")} {
      font-size: 1.2em;
    }
    ${(props) => props.theme.breakpoints.between("md", "xl")} {
      font-size: 1.8em;
    }
  }
`;

const StyledTextWrapper = styled(Grid)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 2em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledGrid = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 20px 20px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "xl")} {
    padding: 50px 20px;
  }
`;

export const StyledAuctionItem = styled(Grid)``;

const StyledCalendarWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 100%;
    padding: 20px 20px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 95%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 95%;
  }
  padding: 50px 20px;
`;

const StyledLegendWrapper = styled.div`
  border-top: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  margin-top: 20px;
  padding: 5px 10px;
`;

const StyledLegendTitle = styled.div`
  color: ${(props) => props.theme.colors.base.textColor};
  padding: 5px 0;
`;

const StyledLegendGroup = styled.div`
  display: inline-block;
  padding-right: 20px;
`;

const StyledLegendColor = styled.span`
  width: 20px;
  height: 20px;
  display: inline-block;
  &.previewColor {
    background: ${(props) => props.theme.colors.base.gradientLightBlueColor};
  }
  &.auctionColor {
    background: ${(props) => props.theme.colors.base.textColor};
  }
  &.noAuctionColor {
    background: ${(props) => props.theme.colors.base.white};
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  &.appointment {
    background: ${(props) => props.theme.colors.base.red};
    border: 1px solid ${(props) => props.theme.colors.base.red};
  }
`;

const StyledLegendName = styled.span`
  color: ${(props) => props.theme.colors.base.black};
  display: inline-block;
  vertical-align: top;
  padding: 3px 10px;
  font-size: 0.8em;
`;

const StyledWrapper = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 400px;
  }
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    //border-right: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
`;

interface IButtonProps {
  participate?: boolean;
  join?: boolean;
  disableParticipateButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.participate &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
  ${(props) =>
    props.join &&
    css`
      width: 40%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 30px;
      margin-left: 10px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
    ${(props) =>
    props.disableParticipateButton &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      cursor: default;
      background: ${dateBackgroundGradient};
    `}
`;

const StyledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
`;

const StyledAdvertisementTitle = styled(Grid)`
  background: ${(props) => props.theme.colors.base.transparent};
  border-bottom: 1px solid #2660ad;
  border-radius: 50px;
  padding: 0em 20px;
`;

const StyledAdvertisementWrapper = styled(Grid)``;
export const StyledStickeyContainerTry = styled.div`
  position: fixed;
  right: 20px;
  height: auto;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.5em;
    top: 15%;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    font-size: 0.5em;
    top: 15%;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    font-size: 1em;
    top: 10%;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    font-size: 1em;
    right: 20px;
    top: 9%;
  }
  ${(props) => props.theme.breakpoints.up("lg")} {
    right: 20px;
    top: 7%;
  }
`;
export const StyledNotificationContainer = styled.div`
  right: 20px;
  height: auto;
  z-index: 99;
  cursor: pointer;
  top: 20%;
  color: ${(props) => props.theme.colors.base.white};
`;

export const StyledStickeyTextContainerTry = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 12px;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 14px;
    text-align: center;
  }
`;

interface IProps extends IRouterComponentProps {
  t: any;
}

let date: string | string[] = "";
let annualJoinAuctions: any = [];
const Home = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [advertisementProducts, setAdvertisementProducts] = useState([]);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openParticipateModal, setOpenParticipateModal] = useState(false);
  const [joinAuctions, setJoinedAuctions] = useState([]);
  const [auctionId, setAuctionId] = useState("");
  const [auctionSubDomain, setAuctionSubDomain] = useState("");
  const [openTimerModal, setOpenTimerModal] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [isFromOngingActions, setIsFromOngingActions] = useState(false);
  const [auctionName, setAuctionName] = useState("");
  const [productCount, setProductCount] = useState("");
  const [auctionUserId, setAuctionUserId] = useState("");
  const [startedPreviews, setStartedPreviews] = useState([]);
  const [showNewPasswordPopup, setShowNewPasswordPopup] = useState(false);
  const [isEditProfile, setIsEditProfile] = useState(false);
  const [profileData, setProfileData] = useState<any>();
  const [timeZone, setTimeZone] = useState();
  const [isCalendelyModal, setIsCalendelyModal] = useState<boolean>(false);
  const [isPaddle, setIsPaddle] = useState<boolean>(false);
  const Auth = new AuthService();
  const { t } = props;

  const dispatch: AppDispatch = useDispatch();

  var auctionIdF: string = "";
  var productIdF: string = "";
  var parcelTypeF: string = "";
  var categoryF: string = "";

  var auctionTypeF: string = "";

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectedParticipateId(state)
  );

  useEffect(() => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      setTimeout(() => {
        getTimeZone();
        getProfile();
        const defaultPasswordUpdated: any = localStorage.getItem(
          "defaultPasswordUpdated"
        );
        const isDefaultPasswordUpdated: boolean = JSON.parse(
          defaultPasswordUpdated
        );
        const otpAuth: any = localStorage.getItem("otpAuth");
        const isOtpAuth: boolean = JSON.parse(otpAuth);

        if (isDefaultPasswordUpdated !== null) {
          if (isDefaultPasswordUpdated) {
            setShowNewPasswordPopup(false);
          } else if (isOtpAuth !== true && isDefaultPasswordUpdated === false) {
            setShowNewPasswordPopup(true);
          } else if (isOtpAuth !== true) {
            setShowNewPasswordPopup(false);
          } else {
            setShowNewPasswordPopup(true);
          }
        }

        //My-page Re-direction 
        let isSellerBtnClick = localStorage.getItem('sellerBtnClick');
        if (isSellerBtnClick !== null) {
          if (isSellerBtnClick === "true") {
            props.history.push(`/my-page`);
          }
        }
      }, 3000);

      setIsLoading(true);
      setOpenToast(false);
      getJoinedAuctions();
      getAdvertisementProducts();
      getAllAuctionsPreview();
      localStorage.removeItem("currentProductIdOfBidNow");
      localStorage.removeItem("currentJewelleryProductIdOfBidNow");
      localStorage.removeItem("currentPDItem");
      localStorage.removeItem("filteredValues");
      localStorage.removeItem("onlyMybidsCurrentStart");
      localStorage.removeItem("optionValue");
      //clear
      localStorage.setItem("selectedFilterSort", "DEFAULT");
      localStorage.removeItem("annualdropDownFilterVal");
      dispatch(resetItemPerPage());
      dispatch(resetCurrentApiStartCount());
      dispatch(resetJewelleryItemPerPage());
      dispatch(resetJewelleryCurrentApiStartCount());
      dispatch(resetAnnualItemPerPage());
      dispatch(resetAnnualCurrentApiStartCount());
      dispatch(resetAnnualJewelleryItemPerPage());
      dispatch(resetAnnualJewelleryCurrentApiStartCount());
      dispatch(reSetLiveAuctionPagination());
      dispatch(reSetLiveCurrentApiStartCount());
      dispatch(reSetIsMybids());

      const urlProductAuctionFromEmail: any = localStorage.getItem(
        "urlProductAuctionFromEmail"
      );
      if (urlProductAuctionFromEmail !== null) {
        props.history.push(`${urlProductAuctionFromEmail}`);
      }

      //change plan url
      const openSubdomainProfilePage: any = localStorage.getItem(
        "changePlanUrlFromUrl"
      );
      if (openSubdomainProfilePage !== null) {
        if (openSubdomainProfilePage === "isChangePlan") {
          props.history.push(`/SubdomainProfile`);
        }
      }
    }
    return () => {
      setAdvertisementProducts([]);
      setOpenProductModal(false);
      dispatch(resetAllPreview());
      localStorage.removeItem("changePlanUrlFromUrl");
    };
  }, []);

  const getJoinedAuctions = () => {
    Auth.getRequest(getAllJoinedAuctionsUrl, (status: any, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          localStorage.setItem(
            "joinedAuctions",
            JSON.stringify(response.results)
          );
          setJoinedAuctions(response.results);
        } else {
          setIsLoading(false);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        setApiTimeout(true);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getAdvertisementProducts = () => {
    Auth.getRequest(advertisementUrl, (status: number, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          if (response.results.length > 0) {
            setAdvertisementProducts(response.results);
          }
          if (
            selectItemPerPageValue !== undefined &&
            selectItemPerPageValue !== ""
          ) {
            setIsLoading(true);
            let advertisementProductsData = response.results.filter(
              (item: any) => item.code === selectItemPerPageValue
            );
            let { auctionId, auctionStartDate, id, parcelType, auctionType } =
              advertisementProductsData[0];
            onParticipate(
              auctionId,
              auctionStartDate,
              id,
              "",
              parcelType,
              auctionType
            );
            setIsLoading(false);
            dispatch(resetParticipateProductId());
          }
          dispatch(resetParticipateProductId());
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setAdvertisementProducts([]);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        // setApiTimeout(true);
        setIsLoading(false);
        setAdvertisementProducts([]);
      } else {
        setOpenToast(false);
        setShowMessage(response);
        setAlertType("error");
        setIsLoading(false);
      }
    });
  };

  const renderSDEditForm = () => {
    setShowNewPasswordPopup(false);
    setIsEditProfile(true);
  };

  //get All Auction for preview check is preview started or not
  const getAllAuctionsPreview = () => {
    Auth.getRequest(previewUrl, (status: any, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          setStartedPreviews(response);
          dispatch(setAllPreview({ preview: response }));
        } else {
          setIsLoading(false);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        setApiTimeout(true);
      } else {
        setIsLoading(false);
      }
    });
  };

  // time zone
  const getTimeZone = () => {
    Auth.getRequest(getTimeZones, (status: number, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          setTimeZone(response);
        }
      }
    });
  };

  const getProfile = () => {
    Auth.getRequest(profileUrl, (status: number, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          setProfileData(response);
          // set paddle number or read only user value in localstorage
          localStorage.setItem("isReadOnlyUser", response.readOnlyUser);
          localStorage.setItem("paddleNumber", response.paddleNumber);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        setIsLoading(false);
        setApiTimeout(true);
      }
    });
  };

  const onActionClick = (
    id: string,
    state: string,
    auctionName: string,
    proCount: string,
    category: string,
    auctionType: string
  ) => {
    setAuctionId(id);
    categoryF = category;
    setIsFromOngingActions(true);
    setAuctionName(auctionName);
    setProductCount(proCount);
    setAuctionUserId(auctionUserId);
    if (auctionType === "Annual") {
      if (categoryF === "Jewellery") {
        props.history.push({
          pathname: `/365products/jewellery`,
        });
      } else {
        props.history.push({
          pathname: `/365products`,
        });
      }
    } else {
      if (joinAuctions.length > 0) {
        for (var i = 0; i < joinAuctions.length; i++) {
          let productsDetail: any = joinAuctions[i];
          setOpenProductModal(false);
          let myString: any;

          if (productsDetail.perId === id) {
            localStorage.setItem("userId", productsDetail.auctionUserId);
            const datePattern = /\d{4}-\d{2}-\d{2}/;

            // Find the first occurrence of the date pattern in the string
            const match = productsDetail?.startDate.match(datePattern);
            // Extract the date from the match array
            myString = match[0];
            if (productsDetail.auctionType === "Popcorn_Bidding") {
              if (state === "Started" || state === "Auction_Break") {
                if (categoryF === "Jewellery") {
                  props.history.replace({
                    pathname: `/auction/tender/jewellery/details/${id}/${productsDetail.auctionUserId}`,
                  });
                } else {
                  props.history.replace({
                    pathname: `/auction/tender/details/${id}/${productsDetail.auctionUserId}`,
                  });
                }
              } else {
                if (categoryF === "Jewellery") {
                  props.history.push({
                    pathname: `/preview/tendor/jewellery/products/${id}/${productsDetail.auctionUserId}/${productsDetail.name}/${myString}`,
                  });
                } else {
                  props.history.push({
                    pathname: `/preview/tendor/products/${id}/${productsDetail.auctionUserId}/${productsDetail.name}/${myString}`,
                  });
                }
              }
            } else {
              if (state === "Started" || state === "Auction_Break") {
                if (categoryF === "Jewellery") {
                  props.history.replace({
                    pathname: `/jewelleryAuction/details/${id}/${productsDetail.name}/${productsDetail.productCount}`,
                  });
                } else {
                  props.history.replace({
                    pathname: `/auction/details/${id}/${productsDetail.name}/${productsDetail.productCount}`,
                  });
                }
              } else {
                if (categoryF === "Jewellery") {
                  props.history.push({
                    pathname: `/preview/jewelleyProducts/${id}/${productsDetail.name}/${myString}`,
                  });
                } else {
                  props.history.push({
                    pathname: `/preview/products/${id}/${productsDetail.name}/${myString}`,
                  });
                }
              }

            }
          } else {
            setOpenProductModal(true);
          }
        }

      } else {
        setOpenProductModal(true);
      }
    }
  };

  const onParticipate = (
    id: string,
    auctionDate: string,
    perId: string,
    domain: string,
    parcelType: string,
    auctionType: string
  ) => {
    setAuctionId(id);
    auctionIdF = id;
    productIdF = perId;
    setAuctionSubDomain(domain);
    parcelTypeF = parcelType;
    auctionTypeF = auctionType;

    date = auctionDate.split(" ", 2);
    if (auctionTypeF === "Annual") {
      Auth.postRequest(
        `${annualAuctionJoinedCall}/${parcelType === "Jewellery" ? "Jewellery" : "Diamonds"
        }/join`,
        "",
        (status: any, response: any) => {
          if (status === 200) {
            var subdomain = localStorage.getItem("subDomain");

            if (domain !== undefined) {
              let splitUrl = domainName.replace(`${"//"}`, `//${domain}.`);
              b.baseUrl = `${splitUrl}`;
            } else {
              b.baseUrl = `${domainName}`;
            }
            if (
              subdomain !== domain &&
              subdomain !== null &&
              subdomain !== undefined &&
              subdomain !== "localhost"
            ) {
              props.history.replace({
                pathname: `/home/365products/details/${id}/${response?.auctionUserId}/${perId}/${date[0]}/${true}?auctionType=${parcelTypeF}`,
              });
            } else {
              props.history.replace({
                pathname: `/home/365products/details/${id}/${response?.auctionUserId}/${perId}/${date[0]}/${true}?auctionType=${parcelTypeF}`,
              });
            }
          }
        }
      );
    } else {
      if (joinAuctions.length > 0) {
        setOpenProductModal(false);
        let joinedAuctionData: any = joinAuctions.filter(
          (auctionObject) => auctionObject["perId"] === id
        );
        if (joinedAuctionData.length > 0) {
          let productsDetail: any = joinedAuctionData;
          if (productsDetail.perId === id) {
            var subdomain = localStorage.getItem("subDomain");
            if (
              subdomain !== domain &&
              subdomain !== null &&
              subdomain !== undefined
            ) {
              if (domain !== undefined) {
                let splitUrl = domainName.replace(`${"//"}`, `//${domain}.`);
                b.baseUrl = `${splitUrl}`;
              } else {
                b.baseUrl = `${domainName}`;
              }
              if (
                productsDetail.auctionType !== undefined &&
                productsDetail.auctionType !== "Live"
              ) {
                if (parcelTypeF === "Jewellery") {
                  let url = `${baseUrlRedirect.baseUrl}/home/tendor/jewellery/details/${id}/${perId}/${date[0]}?isAuction=false&uniqid=${productsDetail.auctionUserId}`;
                  window.location.replace(url);
                } else {
                  let url = `${baseUrlRedirect.baseUrl}/home/tendor/details/${id}/${perId}/${date[0]}?isAuction=false&uniqid=${productsDetail.auctionUserId}`;
                  window.location.replace(url);
                }
              } else {
                if (parcelTypeF === "Jewellery") {
                  let url = `${baseUrlRedirect.baseUrl}/home/jewellery/details/${id}/${perId}/${date[0]}`;
                  window.location.replace(url);
                } else {
                  let url = `${baseUrlRedirect.baseUrl}/home/details/${id}/${perId}/${date[0]}`;
                  window.location.replace(url);
                }
              }
            }
          } else {
            onClickJoin(auctionTypeF);
          }
          // break;
        } else {
          onClickJoin(auctionTypeF);
        }
      } else {
        onClickJoin(auctionTypeF);
      }
    }
  };

  const onClickJoin = (auctionType: any) => {
    const { t } = props;
    setOnSubmit(true);
    const data = {
      code: "",
    };
    Auth.postRequest(
      `${joinAuctionUrl}/${auctionIdF}/join`,
      data,
      (status: number, response: any) => {
        if (status === 200) {
          setOpenProductModal(false);
          setOnSubmit(false);
          joinAuction();
          var subdomain = localStorage.getItem("subDomain");
          setOpenProductModal(false);
          if (isFromOngingActions && response.id !== undefined) {
            localStorage.setItem("userId", response.id);

            if (auctionType === "Popcorn_Bidding") {
              if (categoryF === "Jewellery") {
                props.history.replace({
                  pathname: `/auction/tender/jewellery/details/${auctionId}/${productIdF}`,
                });
              } else {
                props.history.replace({
                  pathname: `/auction/tender/details/${auctionId}/${productIdF}`,
                });
              }
            } else {
              if (categoryF === "Jewellery") {
                props.history.replace({
                  pathname: `/jewelleryAuction/details/${auctionId}/${auctionName}/${productCount}`,
                });
              } else {
                props.history.replace({
                  pathname: `/auction/details/${auctionId}/${auctionName}/${productCount}`,
                });
              }
            }
          } else {
            if (
              subdomain !== auctionSubDomain &&
              subdomain !== null &&
              subdomain !== undefined
            ) {
              if (auctionSubDomain !== undefined) {
                let splitUrl = domainName.replace(
                  `${"//"}`,
                  `//${auctionSubDomain}.`
                );
                b.baseUrl = `${splitUrl}`;
              }
              if (auctionType === "Popcorn_Bidding") {
                if (parcelTypeF === "Jewellery") {
                  props.history.push({
                    pathname: `/home/tendor/jewellery/details/${auctionIdF}/${productIdF}/${date[0]}?isAuction=false&uniqid=${response.id}`,
                  });
                } else {
                  props.history.push({
                    pathname: `/home/tendor/details/${auctionIdF}/${productIdF}/${date[0]}?isAuction=false&uniqid=${response.id}`,
                  });
                }
              } else {
                if (parcelTypeF === "Jewellery") {
                  props.history.push(
                    `/home/jewellery/details/${auctionIdF}/${productIdF}/${date[0]}`
                  );
                } else {
                  props.history.push(
                    `/home/details/${auctionIdF}/${productIdF}/${date[0]}`
                  );
                }
              }
            } else {
              joinAuction();

              if (auctionType === "Popcorn_Bidding") {
                if (parcelTypeF === "Jewellery") {
                  props.history.push(
                    `/home/tendor/jewellery/details/${auctionIdF}/${productIdF}/${date[0]}?isAuction=false&uniqid=${response.id}`
                  );
                } else {
                  props.history.push({
                    pathname: `/home/tendor/details/${auctionIdF}/${productIdF}/${date[0]}?isAuction=false&uniqid=${response.id}`,
                  });
                }
              } else {
                if (parcelTypeF === "Jewellery") {
                  props.history.push(
                    `/home/jewellery/details/${auctionIdF}/${productIdF}/${date[0]}`
                  );
                } else {
                  props.history.push(
                    `/home/details/${auctionIdF}/${productIdF}/${date[0]}`
                  );
                }
              }
            }
          }
        } else if (status > 200 && status < 500) {
          if (status === 403) {
            setUserBlock(true);
          } else if (response[0].code !== undefined) {
            if (response[0].code === "KB400003") {
              setOpenProductModal(true);
              setOnSubmit(false);
            }
          } else {
            setMessage(
              `${t("common:allValidations:participate:mailSentFailed")}`
            );
            setOpenProductModal(true);
            setOnSubmit(false);
          }
        } else if (status === 505) {
          setApiTimeout(true);
          setIsLoading(false);
          setOnSubmit(false);
        } else if (status === 500) {
          setMessage(response[0].message);
          setOpenProductModal(false);
          setOnSubmit(false);
        } else {
          setMessage(`${t("common:somethingWentWrong")}`);
          setOnSubmit(false);
          setOpenProductModal(false);
        }
      }
    );
  };

  const onCloseModal = () => {
    setMessage("");
    setOpenProductModal(false);
    setOpenTimerModal(false);
    localStorage.removeItem("clockNotSynced");
  };

  const onClickParticipate = () => {
    const { t } = props;
    setOnSubmit(true);
    setOpenToast(false);
    var urlString = "";

    let userType = localStorage.getItem("userType");

    urlString =
      userType === "SUBSCRIBED" || "SUB_DOMAIN"
        ? participateUrl
        : sendInviteeParticipationRequest;

    Auth.postRequest(
      `${urlString}?auctionId=${auctionId}`,
      "data",
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            setOpenParticipateModal(true);
            setOpenProductModal(false);
            setOnSubmit(false);
          } else {
            setOnSubmit(false);
          }
        } else if (status > 200 && status < 500) {
          setOpenProductModal(false);
          if (status === 403) {
            setUserBlock(true);
          } else if (response[0].code !== undefined) {
            if (response[0].code === "KB409003") {
              setAlertType("error");
              setShowMessage(
                `${t(
                  "common:allValidations:participate:alreadySentParticipationMail"
                )}`
              );
              setOpenToast(true);
              setTimeout(() => {
                setOpenToast(false);
              }, 3000);
              setOnSubmit(false);
            } else if (response[0].code === "KB409004") {
              setMessage(
                `${t(
                  "common:allValidations:participate:alreadySentInviationMail"
                )}`
              );
              setOnSubmit(false);
            }
          } else if (status === 404) {
            setMessage(`${t("common:invalidRequest")}`);

            setOnSubmit(false);
          } else {
            setOnSubmit(false);
          }
        } else if (status === 500) {
          setOpenParticipateModal(false);
          setOnSubmit(false);
        } else {

          setMessage(`${t("common:somethingWentWrong")}`);
          setOnSubmit(false);
        }
      }
    );
  };

  const openParticipate = () => {
    setOpenParticipateModal(false);
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };
  const onOpenPaddleModal = () => {
    setIsPaddle(true);
    setTimeout(() => {
      setIsPaddle(false);
    }, 3000);
  };
  const renderPaddleNumberModal = () => {
    return (
      <StyledModal
        open={isPaddle}
        onClose={() => setIsPaddle(false)}
        className="PaddleNumber"
      >
        <PaddleNumberModal closeModal={() => setIsPaddle(false)} {...profileData} />
      </StyledModal>
    );
  };

  const renderCalendelyModal = () => {
    return (
      <StyledModal
        open={isCalendelyModal}
        onClose={() => {
          setIsCalendelyModal(false);
        }}
        className="renderCalendelyModal"
      >
        <InlineComponent />
      </StyledModal>
    );
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              <StyledLayoutGrid container={true} spacing={0}>
                <RightTabs />
                <StyledGrid
                  item={true}
                  xs={12}
                  md={10}
                  lg={6}
                  xl={6}
                  className="videoContainer"
                >
                  <VideoAtHome t={t} />
                  <StyledAuctionItem item={true} xs={12} md={12} lg={12}>
                    <CorouselListAtHome
                      {...props}
                      onActionClick={onActionClick}
                    />
                  </StyledAuctionItem>
                </StyledGrid>
                <Grid
                  item={true}
                  xs={12}
                  md={12}
                  lg={6}
                  xl={5}
                  className="calendarContainer"
                >
                  <StyledCalendarWrapper>
                    <EventsCalendar
                      {...props}
                      joindAuctionsForCalendar={joinAuctions}
                      allPreviews={startedPreviews}
                      openAppointment={() => {
                        setIsCalendelyModal(true);
                      }}
                    />
                    <StyledLegendWrapper>
                      <StyledLegendTitle>
                        {t("common:home:legend:legend")}
                      </StyledLegendTitle>
                      <StyledLegendGroup>
                        <StyledLegendColor className="previewColor"></StyledLegendColor>
                        <StyledLegendName className="fontBold">
                          {t("common:home:legend:preview")}
                        </StyledLegendName>
                      </StyledLegendGroup>
                      <StyledLegendGroup>
                        <StyledLegendColor className="auctionColor"></StyledLegendColor>
                        <StyledLegendName className="fontBold">
                          {t("common:home:legend:auction")}
                        </StyledLegendName>
                      </StyledLegendGroup>
                      <StyledLegendGroup>
                        <StyledLegendColor className="noAuctionColor"></StyledLegendColor>
                        <StyledLegendName className="fontBold">
                          {t("common:home:legend:noAuction")}
                        </StyledLegendName>
                      </StyledLegendGroup>
                      <StyledLegendGroup>
                        <StyledLegendColor className="appointment"></StyledLegendColor>
                        <StyledLegendName className="fontBold">
                          {t("common:home:legend:appointment")}
                        </StyledLegendName>
                      </StyledLegendGroup>
                    </StyledLegendWrapper>
                  </StyledCalendarWrapper>
                </Grid>
              </StyledLayoutGrid>
              <StyledLayoutGrid container={true} spacing={0}>
                <StyledGrid item={true} xs={12}>
                  <Grid spacing={0} container>
                    <StyledAdvertisementWrapper>
                      <StyledAdvertisementTitle className="advertisementText">
                        {t("common:home:advertisementHeading")}
                      </StyledAdvertisementTitle>
                    </StyledAdvertisementWrapper>
                  </Grid>
                  {advertisementProducts !== undefined ? (
                    <div>
                      {advertisementProducts.length > 0 ? (
                        <ProductSlider
                          t={t}
                          productLists={advertisementProducts}
                          onParticipate={onParticipate}
                          joinedAuctions={joinAuctions}
                        />
                      ) : (
                        <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
                          {t("common:home:noProductsAvailable")}
                        </StyledTextWrapper>
                      )}
                    </div>
                  ) : (
                    <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
                      {t("common:home:noProductsAvailable")}
                    </StyledTextWrapper>
                  )}
                </StyledGrid>
              </StyledLayoutGrid>
            </div>
          )}
        </Grid>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {openProductModal === true && (
        <StyledModal open={openProductModal} onClose={onCloseModal}>
          <StyledWrapper container spacing={0}>
            <StyledMessage item xs={12}>
              {/* {message} */}
            </StyledMessage>
            <StyledParticipateWrapper item md={12} xs={12} lg={12}>
              <StyledTextWrapper>
                {t("common:preview:modal:participateDontHaveCode")}
                {t("common:preview:modal:forparticipation")}
              </StyledTextWrapper>
              {onSubmit === false ? (
                <StyledButton participate={true} onClick={onClickParticipate}>
                  {t("common:button:participate")}
                </StyledButton>
              ) : (
                <Grid>
                  <StyledButton disableParticipateButton={onSubmit}>
                    {t("common:button:participate")}
                  </StyledButton>
                </Grid>
              )}
            </StyledParticipateWrapper>
            {onSubmit === true && (
              <div className="loading">
                <LoadingSpinner />
              </div>
            )}
          </StyledWrapper>
        </StyledModal>
      )}
      {openParticipateModal === true && (
        <StyledModal open={openParticipateModal} onClose={onCloseModal}>
          <ViewParticipateModal openParticipateModal={openParticipate} t={t} />
        </StyledModal>
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal {...props} closeModal={closeModal} />
        </StyledModal>
      )}

      {showNewPasswordPopup === true && (
        <StyledModal open={showNewPasswordPopup}>
          <NewPassword
            onClose={() => {
              setShowNewPasswordPopup(false);
            }}
            onShowPaddleNoModal={() => {
              getProfile();
              onOpenPaddleModal();
            }}
            subDomainPage={() => renderSDEditForm()}
          />
        </StyledModal>
      )}

      {isEditProfile && (
        <StyledModal
          open={isEditProfile}
          onClose={() => closeModal()}
          className="registerModal"
        >
          <SubdomainEditProfileForm
            {...profileData}
            {...props}
            closeModal={() => {
              setIsEditProfile(false);
              getProfile();
              onOpenPaddleModal();
            }}
            saveProfile={() => getProfile()}
            openPaddleModal={onOpenPaddleModal}
            timeZone={timeZone}
          />
        </StyledModal>
      )}
      {isPaddle && renderPaddleNumberModal()}
      {isCalendelyModal && renderCalendelyModal()}
    </AppLayout>
  );
};

export default Home;
