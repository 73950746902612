import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import IRouterComponentProps from '../../../models/IRouterComponentProps';

import { IconProp } from "@fortawesome/fontawesome-svg-core";
const faInfoCircleProps = faInfoCircle as IconProp;

const StyledComponent = styled(Grid)`
    outline: none;
    .iconStyle {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
        margin-right: 8px;
        margin-bottom: 7px; 
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 1em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
    }
`;

const StyledTextWrapper = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledGrid = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;

const StyledHoldWrapper = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
`;

export interface IProps extends IRouterComponentProps {
    t: any;
    closeModal: () => void;
}

const ViewHoldModal = React.forwardRef((props: IProps, ref) => {
    const { t, closeModal } = props;
    
    useEffect(() => {
        setTimeout(() => {
            closeModal();
        }, 1700);
    });

    return (
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <StyledGrid item md={12} xs={12} lg={12}>
            <StyledHoldWrapper>
              <FontAwesomeIcon icon={faInfoCircleProps} className="iconStyle" />
              <StyledTextWrapper className="fontBold">
                {t("common:auction:auctionHold:firstLine")}
              </StyledTextWrapper>
            </StyledHoldWrapper>
            <StyledTextWrapper>
              {t("common:auction:auctionHold:secondLine")}
            </StyledTextWrapper>
          </StyledGrid>
        </StyledWrapper>
      </StyledComponent>
    );
});

export default ViewHoldModal;