import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { buttonGradient } from "../../../common/materials/LinearGradient";

const StyledComponent = styled(Grid)`
  outline: none;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 2em 1em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 500px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 400px;
  }
`;

const StyledTextWrapper = styled.div`
  font-size: 1.5em;
  padding-bottom: 2em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledPrebidTextWrapper = styled.div`
  font-size: 1.5em;
  font-family: Calibre-SemiBold;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  padding: 1em 2em;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 0.5em 0.5em;
  padding-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  width: 80px;
  border-radius: 10px;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

export interface IProps {
  t: any;
  onConfirmModal: () => void;
  onCancelModal: () => void;
  biddingAmount: String;
  message: String;
  conversionAmount: String;
  caratAmount: string;
}

const PreBidConfirmationPopup = (props: IProps) => {
  const {
    t,
    onConfirmModal,
    onCancelModal,
    biddingAmount,
    message,
    conversionAmount,
    caratAmount,
  } = props;
  console.log("caratAmount--------", caratAmount);

  const onClickShare = () => {
    onConfirmModal();
  };

  const onClickCancel = () => {
    onCancelModal();
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledParticipateWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper style={{ paddingBottom: "2px" }}>
            {message}
            <StyledPrebidTextWrapper>
              {" "}
              {biddingAmount} {conversionAmount}{" "}
            </StyledPrebidTextWrapper>
          </StyledTextWrapper>
          {caratAmount !== "" ? (
            <StyledPrebidTextWrapper>
              {" "}
              Amount = {caratAmount}
            </StyledPrebidTextWrapper>
          ) : null}
          <StyledButton onClick={onClickShare}>
            {t("common:kycFrom:yes")}
          </StyledButton>
          <StyledButton onClick={onClickCancel}>
            {t("common:kycFrom:no")}
          </StyledButton>
        </StyledParticipateWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
};

export default PreBidConfirmationPopup;
