import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { buttonGradient, sideMenuGradient } from '../../common/materials/LinearGradient';
import { useHistory } from 'react-router-dom';

const CorouselDiv = styled(Grid)`
  width: 100%;
  height: auto;
  margin-top: 95px;
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(105,105,105,1) 100%, rgba(255,255,255,1) 100%);
  }
`;

const SlideShowImageStyle = styled('img')`
    width: 100%;
    background: ${(props) => props.theme.colors.base.bannerbackground};
    height: auto;
`;

const StyledBannerContent = styled.span`
    position: absolute;
    top: 38%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 60%;   
`;
const StyledBannerContentSecond = styled.span`
  position: absolute;
  top: 30%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 60%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    top: 16%;
    height: 50%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "lg")} {
    top: 25%;
    height: 35%;
  }
  @media (width: 280px) {
    font-size: 0.7em;
    top: 22%;
    height: 100%;
  }
  @media (width: 320px) {
    top: 17%;
    font-size: 0.8em;
    height: 100%;
  }
`;

const StyledFirstText = styled.span`
    color:${(props) => props.theme.colors.base.white}; 
    width: 100%;
    font-family: Calibre-Regular;
    text-align: center;
    left: 5%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1em;       
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 2em;
    }
`;
const StyledBannerFirstText = styled.span`
    color:${(props) => props.theme.colors.base.white}; 
    width: 100%;
    font-family: Calibre-Regular;
    text-align: center;
    left: 5%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1em;       
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 2em;
    }
`;
const StyledBannerSecondText = styled.span`
    color:${(props) => props.theme.colors.base.white}; 
    width: 100%;
    font-family: Calibre-Regular;
    text-align: center;
    left: 5%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.8em;
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 1.8em;
    }
`;
const StyledBannerFirstTexts = styled.span`
  color: ${(props) => props.theme.colors.base.white};
  width: 100%;
  font-family: Calibre-Regular;
  text-align: center;
  left: 5%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "lg")} {
    font-size: 1.2em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 3.4em;
    font-weight: bold;
  }
`;

const StyledBannerFirstbtn = styled.div`
    color:${(props) => props.theme.colors.base.white}; 
    width: 100%;
    font-family: Calibre-Regular;
    text-align: center;
    // left: 5%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1em;       
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 2em;
    }
`;

const StyledSecondFirstSecondBannerText = styled.span`
    color:  ${(props) => props.theme.colors.base.bannnerFirstColor}; 
    left: 5%;
    text-align: center;
    font-family: Calibre-Bold;
    font-weight: bold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;       
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1.5em;  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 3.5em;        
    }
`;

const StyledSecondThirdFourthBannerText = styled.span`
    color: ${(props) => props.theme.colors.base.bannerSecondColor}; 
    left: 5%;
    text-align: center;
    font-family: Calibre-Bold;
    font-weight: bold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;       
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1.5em;  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 3.5em;        
    }
`;

const StyledThirdText = styled.h1`
    color: ${(props) => props.theme.colors.base.bannerThirdColor}; 
    width: 100%;
    margin-left: 10px;
    padding-bottom: 1em;
    font-family: Calibre-SemiBold;
    color: #4f96e2;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;        
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 1em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 2em;
    }
`;

const StyledSwipeRight = styled.span`
    color: ${(props) => props.theme.colors.base.bannerThirdColor};
    width: 100%;
    text-align: right;
    font-family: Calibre-SemiBold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;        
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 1em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 1.5em;     
    }
`;

const StyledSwipeRightWrapper = styled.div`
    width: 95%;
    display: flex;
    align-items: center; 
    justify-content: flex-end;
`;

const StyledThirdTextWrapper = styled.div`
    width: 65%; 
    text-align: center;
`;

const StyledSecondTextWrapper = styled.div`
    width: 63%;
    text-align:center;
    display: flex;
    flex-direction: column;
`;
const StyledSecondTextWrapperFinal = styled.div`
    width: 100%;
    text-align:center;
    display: flex;
    flex-direction: column;
`;
const StyledArrow = styled.img`
`;

const StyledLeftButton = styled.button`
    position: absolute;
    z-index: 3;
    left: 2em;
    right:0;
    border: none;
    background: ${(props) => props.theme.colors.base.transparent};
    cursor: pointer;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 1.8em;
        height: 1.8em; 
        top: calc(50% - 0.8em);  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 3em;
        height: 3em;
        top: calc(50% - 2.5em);  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        width: 2.5em;
        height: 2.5em; 
        top: calc(50% - 3em); 
    }
`;

const StyledButton = styled.button`
    position: absolute;
    text-align: right;
    border: none;
    background: ${(props) => props.theme.colors.base.transparent};
    cursor: pointer;
    width: 3.5em;
    height: 3.5em;   
    margin-left: 10px;
    display: inline-block;
    &.rightIcon {
        margin-left: 0px;
        margin-right: 5px;
    }
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 1.8em;
        height: 1.8em; 
        top: calc(50% - 0.8em);  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 3em;
        height: 3em;
        top: calc(50% - 2.5em);  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        width: 2.5em;
        height: 2.5em;   
        top: calc(50% - 3em); 
    }
`;
interface ButtonProps {
    selected?: boolean;
};

const StyledBtn = styled.button<ButtonProps>`
  padding: 5px 5px;
  font-size: 0.8em;
  border-radius: 10px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.textColor};
  margin-right: 5px;
  background: #2d75c2;
  //   border: 1px solid ${(props) => props.theme.colors.base.textColor};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  color: ${(props) => props.theme.colors.base.white};
  @media (width: 280px) {
    font-size: 0.6em;
    padding: 2px 2px;
  }
`;
const StyledBannerbotton = styled.div` 
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: Calibre-Regular;
    cursor:pointer;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'lg')} {
        font-size: 1em;       
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 2em;
    }
    
`;

interface IProps {
    t: any;
}


const Banner = (props: IProps) => {
    const { t } = props;
    let history = useHistory();

    const handleClick = () => {
        history.push("/createfreewebsite");
    }
    return (
        <div>
            <CorouselDiv item={true}>
                <Carousel
                    showIndicators={false}
                    showStatus={false}
                    autoPlay={true}
                    transitionTime= {1000}
                    infiniteLoop
                    showThumbs={false}
                    renderArrowPrev={(onClickHandler, hasPrev) =>
                        hasPrev && (
                            <StyledLeftButton style={{ left: 5 }}
                                onClick={onClickHandler}
                                className="form-submit">
                                <StyledArrow src="/images/controls/left_arrow_round_edges.png" />
                            </StyledLeftButton>
                        )
                    }
                    renderArrowNext={(onClickHandler, hasNext) =>
                        hasNext && (
                            <StyledButton style={{ right: 5 }}
                                onClick={onClickHandler}
                                className="form-submit">
                                <StyledArrow src="/images/controls/right_arrow_round_edges.png" />
                            </StyledButton>
                        )
                    }>
                    <div>
                        <StyledBannerContentSecond>
                            <StyledSecondTextWrapperFinal >
                                <StyledBannerFirstTexts style={{marginTop:'12px'}}>{t('common:FreePersonalizedWebsites:text:textfirst')}</StyledBannerFirstTexts>
                                <StyledBannerSecondText >{t('common:FreePersonalizedWebsites:text:textsecond')}<br/>{t('common:FreePersonalizedWebsites:text:textSecondOne')}</StyledBannerSecondText>
                                {/* <StyledBannerSecondText style={{marginTop:'12px'}}>{t('common:FreePersonalizedWebsites:text:third')}<br/>{t('common:FreePersonalizedWebsites:text:four')}</StyledBannerSecondText> */}
                            <StyledBannerbotton style={{marginTop:'10px'}}>
                                <StyledBtn onClick={handleClick}> {t('common:FreePersonalizedWebsites:CreateWebSiteSecond')}</StyledBtn>
                            </StyledBannerbotton>
                            </StyledSecondTextWrapperFinal>
                        </StyledBannerContentSecond>
                    </div> 
                    <div>
                        <StyledBannerContent>
                            <StyledSecondTextWrapper>
                                <StyledFirstText>{t('common:landingPage:banner:fourth:firstText')}</StyledFirstText>
                                <StyledSecondFirstSecondBannerText>{t('common:landingPage:banner:fourth:secondText')}</StyledSecondFirstSecondBannerText>
                            </StyledSecondTextWrapper>
                            <StyledSwipeRightWrapper>
                                <StyledSwipeRight>{t('common:landingPage:banner:fourth:thirdText')} {'>'}</StyledSwipeRight>
                            </StyledSwipeRightWrapper>
                        </StyledBannerContent>
                        <SlideShowImageStyle src="/images/banner/slide_4_min.jpg" />
                    </div>
                    <div>
                        <StyledBannerContent>
                            <StyledSecondTextWrapper>
                                <StyledFirstText>{t('common:landingPage:banner:first:firstText')}</StyledFirstText>
                                <StyledSecondFirstSecondBannerText>{t('common:landingPage:banner:first:secondText')}</StyledSecondFirstSecondBannerText>
                            </StyledSecondTextWrapper>
                        </StyledBannerContent>
                        <SlideShowImageStyle src="/images/banner/slider_1_min.jpg" />
                    </div>
                    <div>
                        <StyledBannerContent>
                            <StyledSecondTextWrapper>
                                <StyledFirstText>{t('common:landingPage:banner:second:firstText')}</StyledFirstText>
                                <StyledSecondThirdFourthBannerText>{t('common:landingPage:banner:second:secondText')}</StyledSecondThirdFourthBannerText>
                            </StyledSecondTextWrapper>
                            <StyledThirdTextWrapper>
                                <StyledThirdText>{t('common:landingPage:banner:second:thirdText')}</StyledThirdText></StyledThirdTextWrapper>
                        </StyledBannerContent>
                        <SlideShowImageStyle src="/images/banner/slide_2_min.jpg" />
                    </div>
                    <div>
                        <StyledBannerContent>
                            <StyledSecondTextWrapper >
                                <StyledFirstText >{t('common:landingPage:banner:third:firstText')}</StyledFirstText>
                                <StyledSecondThirdFourthBannerText>{t('common:landingPage:banner:third:secondText')}</StyledSecondThirdFourthBannerText>
                            </StyledSecondTextWrapper>
                        </StyledBannerContent>
                        <SlideShowImageStyle src="/images/banner/slide_3-min.jpg" />
                    </div>                                    
                </Carousel>
            </CorouselDiv>
        </div>
    )
}

export default Banner;
