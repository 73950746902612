import React, { ChangeEvent, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { StyledTextInput, StyledButton, StyledComponent, StyledTitleText } from '../../common/materials/GenericStyleComponent';
import { resetPasswordUrl } from '../../common/config/app.endpoints';
import AuthService from '../../components/auth/AuthService';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import { dateBackgroundGradient } from '../../common/materials/LinearGradient';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faEyeProps = faEye as IconProp;

const faInfoCircleProps = faInfoCircle as IconProp;
const eye = <FontAwesomeIcon icon={faEyeProps} />;

const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;
    padding: 1em; 
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 450px;
    }
    .onClickButton {
        cursor: default;
        background: ${dateBackgroundGradient};
    }
`;

const StyledTitle = styled.h1`
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    text-align: center;
    margin: 1em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
      font-size: 1.5em;
    }
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    margin: 20px 0;
 `;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 12px;
    margin: 10px 0px;
    margin-left: 1.2em;
`;

const StyledNote = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.veryLightGrey};
`;

const StyledNoteText = styled.div`
    margin-left: 8px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.base.darkBlue};
`;

const StyledPasswordCon = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 14px;
input {
    padding-right:2em !important;
}
i {
    position: absolute;
    top: 34%;
    right: 2%;
    cursor:pointer;
  }
`;

interface IProps {
    t?: any;
    closeModal: any;
    showSuccessfulToast: () => void;
}

interface IState {
    onEnteredEmail: boolean;
    otp: string;
    newPassword: string;
    confirmPassword: string;
    message: string;
}

let email: null | string = '';

const ConfirmNewPassword = (props: IProps) => {
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const Auth = new AuthService();
    const { t } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targeName = e.target.name;
        const targeValue = e.target.value;
        if (targeName === 'otp') { setOtp(targeValue) }
        if (targeName === 'newPassword') { setNewPassword(targeValue) }
        if (targeName === 'confirmPassword') { setConfirmPassword(targeValue) }
    }

    const togglePasswordVisiblity = () => {
        if (showPassword) {
            setShowPassword(false);
        } else {
            setShowPassword(true)
        }
    };

    const updateNewPassword = (e: any) => {
        e.preventDefault();
        setIsLoading(true);
        let digits = /^[0-9]+$/;
        let newPasswordVariable = newPassword.trim();
        let confirmPasswordVariable = confirmPassword.trim();
        email = localStorage.getItem("email");
        if(email !== null){
            email = email.toLowerCase()
        }
        if (newPasswordVariable === '' || confirmPasswordVariable === '') {
            setIsLoading(false);
            setMessage(`${t('common:profile:forgotPassword:validation:enterPassword')}`);
        }
        else if (newPasswordVariable !== confirmPasswordVariable) {
            setIsLoading(false);
            setMessage(`${t('common:profile:forgotPassword:validation:enterPassword')}`);
        }
        else if (!otp.match(digits)) {
            setIsLoading(false);
            setMessage(`${t('common:profile:forgotPassword:validation:correctOTP')}`);
        }
        else {
            Auth.postRequestWithoutAuthorization(`${resetPasswordUrl}?email=${email}&newPassword=${newPasswordVariable}&otp=${otp}`, 'data', (status: number, response: any) => {
                if (status === 200) {
                    setIsLoading(false);
                    props.closeModal();
                    props.showSuccessfulToast();
                }
                else if (status > 200 && status < 500) {
                    if (response[0].code !== undefined) {
                        if (response[0].code === "KB412003") {
                            setIsLoading(false);
                            setMessage(`${t('common:profile:forgotPassword:validation:invalidOTP')}`);
                        }
                        else {
                            setIsLoading(false);
                            setMessage(response[0].message);
                        }
                    }
                    else {
                        setIsLoading(false);
                        setMessage(response[0].message);
                    }
                }
                else {
                    setIsLoading(false);
                    setMessage(response.data.message);
                }
            })
        }
    }

    const handleCancel = () => {
        props.closeModal();
    }

    return (
        <StyledComponent tabIndex={-1}>
            <form onSubmit={updateNewPassword} noValidate={true}>
                <StyledContainerWrapper>
                    <Grid item={true} xs={12} md={12}>
                        <StyledTitle className="fontBold"><StyledTitleText>{t('common:profile:forgotPassword:title')}</StyledTitleText></StyledTitle>
                    </Grid>
                    <Grid style={{ padding: '0.5em 1em' }}>
                        <StyledNote item={true} xs={12}>
                            <FontAwesomeIcon icon={faInfoCircleProps} />
                            <StyledNoteText>
                                {t('common:profile:forgotPassword:note:otpNote')}
                            </StyledNoteText>
                        </StyledNote>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <StyledMessage>
                            {message}
                        </StyledMessage>
                    </Grid>
                    <StyledTextInput>

                        <StyledPasswordCon className="pass-wrapper">
                            <TextField
                                fullWidth
                                required
                                autoFocus={true}
                                id="newPassword"
                                label={t('common:profile:forgotPassword:labels:newPassword')}
                                variant="outlined"
                                name="newPassword"
                                onChange={handleChange}
                                type={showPassword ? "text" : "password"}
                            />
                            <i onClick={togglePasswordVisiblity}>{eye}</i>
                        </StyledPasswordCon>
                    </StyledTextInput>
                    <StyledTextInput>
                        <TextField
                            fullWidth
                            required
                            id="confirmPassword"
                            label={t('common:profile:forgotPassword:labels:confirmPassword')}
                            variant="outlined"
                            type="password"
                            name="confirmPassword"
                            onChange={handleChange}
                        />
                    </StyledTextInput>
                    <StyledTextInput>
                        <TextField
                            fullWidth
                            required
                            id="otp"
                            label={t('common:profile:forgotPassword:labels:otp')}
                            variant="outlined"
                            name="otp"
                            inputProps={{ maxLength: 6 }}
                            onChange={handleChange}
                        />
                    </StyledTextInput>
                    <StyledButtonContainer>
                        {
                            isLoading === false ?
                                <StyledButton onSubmit={updateNewPassword}>
                                    {t('common:profile:forgotPassword:button:update')}
                                </StyledButton> :
                                <StyledButton className='onClickButton'>
                                    {t('common:profile:forgotPassword:button:update')}
                                </StyledButton>
                        }
                        <StyledButton onClick={handleCancel}>
                            {t('common:button:cancel')}
                        </StyledButton>
                    </StyledButtonContainer>
                </StyledContainerWrapper>
            </form>
            {isLoading === true &&
                <div className="loading">
                    <LoadingSpinner />
                </div>
            }
        </StyledComponent>
    )
}

export default ConfirmNewPassword;