import React, { useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
interface IProps {
  options: any;
  onChangeHandler: (optionsValue: string[]) => void;
  selectedOptions: any;
}

const MOCSelectCheckmark = (props: IProps) => {
  const { options, onChangeHandler, selectedOptions } = props;
  const [natureOfBussiness, setNatureOfBussiness] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    const val = typeof value === "string" ? value.split(",") : value;
    setNatureOfBussiness(val);
    onChangeHandler(val);
  };

  return (
    <div style={{ width: "-webkit-fill-available" }}>
      <FormControl style={{ width: "-webkit-fill-available" }}>
        <InputLabel
          id="demo-multiple-checkbox-label"
          shrink
          style={{ marginLeft: "15px" }}
        >
          {t("common:profile:legends:modeOfContact")}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          required
          autoFocus
          placeholder={t("common:profile:legends:modeOfContact")}
          input={<OutlinedInput placeholder={t("common:profile:legends:modeOfContact")}/>}
          defaultValue={selectedOptions}
          // value={natureOfBussiness}
          onChange={handleChange}
          renderValue={(selected: any) => selected.join(",")}
          MenuProps={MenuProps}
        >
          {options?.map((name: any) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={natureOfBussiness.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
export default MOCSelectCheckmark;
