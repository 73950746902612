import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faInfoCircleProps = faInfoCircle as IconProp;
interface IProps {
  message: string;
  icon?: any;
  iconClassName?: string;
  children?: any;
}

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    cursor: "pointer",
    color: "#2d75c2",
  },
  tooltip: {
    fontSize: "14px",
    backgroundColor: "#2d75c2",
  },
}));

function Wrapper(props: JSX.IntrinsicAttributes & TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}

const renderWithoutChildren = (props: IProps) => {
  const { message, icon, iconClassName } = props;
  return (
    <Wrapper disableTouchListener title={message}>
      <div>
        <FontAwesomeIcon
          icon={icon ? icon : faInfoCircleProps}
          className={iconClassName}
        />
      </div>
    </Wrapper>
  );
};

const renderWithChildren = (props: IProps) => {
  const { message } = props;
  return (
    <Wrapper disableTouchListener title={message}>
      <div>{props.children}</div>
    </Wrapper>
  );
};

const TooltipInfo = (props: IProps) => {
  return (
    <>
      {props.children
        ? renderWithChildren(props)
        : renderWithoutChildren(props)}
    </>
  );
};

export default TooltipInfo;
