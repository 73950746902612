import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import AuctionCardView from '../views/AuctionCardViewAtPreview';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

interface IProducts {
    id: number | string;
    name: string;
    brandName?: string;
    price: number;
    serialNumber: number;
    preBiddingPrice: number;
    auctionType:string;
}

interface IProps {
    t: any;
    selectedPage?: boolean;
    history?: any;
    currentRecords?: IProducts;
    value: (value: boolean) => void;
    showListOfProduct: (id: number | string, name: string, auctionStartDateString: string, state: string, auctionType: string) => void;
}

interface IState {
    products: [];
}

export default class AuctionListView extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            products: []
        };
    }

    setProducts(products: any) {
        this.setState(() => ({
            products
        }))
    }

    componentDidMount() {
        this.setProducts(this.props.currentRecords);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.currentRecords !== this.props.currentRecords) {
            this.setProducts(this.props.currentRecords);
        }
    }

    showListOfProduct = (productId: string | number, name: string, auctionStartDateString: string, state: string, auctionType: string) => {
        this.props.showListOfProduct(productId, name, auctionStartDateString, state, auctionType)
    }

    value = (value: boolean) => {
        if (value === true) {
            this.props.value(true);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <StyledGrid container={true} spacing={0}>
                {
                    this.state.products.length > 0 && this.state.products.map((product: any, index: number) => {
                        return <Grid key={index} item={true} xs={12} sm={6} md={6} lg={3} xl={3}>
                            <AuctionCardView {...product} t={t} showListOfProducts={this.showListOfProduct} value={this.value} />
                        </Grid>
                    })
                }
            </StyledGrid>
        )
    }
}