import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { backgroundGradient, sideMenuGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    justify-content: center;
    width: 100%;
   .collapse {
        background: ${sideMenuGradient};
        display: flex;
    }
    .iconColor {
        color: ${(props) => props.theme.colors.base.white};
    }
    .paddingforpdf {
        padding: 20px 0px 20px 10px;
    }
    .padding {
        padding: 10px 20px;
    }
    .shadow {
        -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey}; 
    }
`;

const StyledWrapper = styled(Grid)`
    padding: 20px;
    max-width: 1000px;
    -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledHeading = styled(Grid)`    
    width: 100%;
    text-align: center;
    color: ${backgroundGradient};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        margin-bottom: 30px;
        font-size: 20px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        margin-bottom: 30px;
        font-size: 36px;
    }   
`;

const StyledCollapsibleWrapper = styled(Grid)`
    margin: 10px;
    color: ${(props) => props.theme.colors.base.white};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 20px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 20px;
    } 
`;

const StyledCollapsibleContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;  
    color: ${(props) => props.theme.colors.base.black};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 18px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 18px;
    } 
`;

const StyledContainer = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        margin-top: 20px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        margin-top: 40px;
    } 
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        margin-top: 100px;
    } 
`;

const Collapsable = (props: IRouterComponentProps) => {
    const { t } = props;

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledGrid item={true} xs={12}>
                <StyledWrapper item={true} xs={12} md={12} lg={12}>
                    <Grid container={true} spacing={0}>
                        <StyledHeading item={true} xs={12} className="fontBold">{t('common:kycFrom:faq:heading')}</StyledHeading>
                    </Grid>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:myAuction:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:myAuction:answer')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:privacyInsured:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:privacyInsured:answer:firstLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:privacyInsured:answer:secondLine')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:whoIsAuctioneer:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:whoIsAuctioneer:answer')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:whoIsBidder:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:whoIsBidder:answer')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:answer:firstLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:answer:points:firstPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:answer:points:secondPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:answer:secondLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:howAuctioneerCreateAuctionWebsite:answer:thirdLine')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">{t('common:kycFrom:faq:documentRequiredForRegisteration:question')}</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:firstLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:points:firstPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:points:secondPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:points:thirdPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:points:fourthPoint')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:secondLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:thirdLine')}</StyledCollapsibleContainer>
                                    <StyledCollapsibleContainer>{t('common:kycFrom:faq:documentRequiredForRegisteration:answer:fourthLine')}</StyledCollapsibleContainer>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                </StyledWrapper>
            </StyledGrid>
        </StyledContainer>
    )
}

export default Collapsable;