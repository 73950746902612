import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import styled from "styled-components";
import LoadingSpinner from "../../../common/elements/Loader";

const StyledBoxContainer = styled(Box)`
  width: 500px;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  padding: 14px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
    padding: 5px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
`;

const InlineComponent = () => {
  let email: any = localStorage.getItem("email");
  const [loading, setLoading] = useState(true)
  const prefill = {
    email: email,
    name: email,
  };
  useEffect(()=>{
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  },[])

  useCalendlyEventListener({
    onEventScheduled: (e: any) =>{
      window.location.reload();
    },
  });

  return (
    <div>
      {loading ? (
        <StyledBoxContainer style={{height:'650px'}}>
          <LoadingSpinner />
        </StyledBoxContainer>
      ) : (
        <StyledBoxContainer>
          <InlineWidget
            url="https://calendly.com/myauctions"
            prefill={prefill}
          />
        </StyledBoxContainer>
      )}
    </div>
  );
};

export default InlineComponent;
