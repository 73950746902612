import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { backgroundGradient } from "../../../common/materials/LinearGradient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { StyledCardContainer } from "../../../common/materials/GenericStyleComponent";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import EllipsisText from "../../../common/elements/EllipsisText";
import ProductDescription from "../../shared/ProductDescription";
import ReactPlayer from "react-player";
import Countdown from "react-countdown";
import moment from "moment";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Menu, MenuItem } from "@material-ui/core";

const faHeartProps = faHeart as IconProp;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  display: flex;
  align-items: center;
  height: 90px;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.white};
  .height {
    height: 100%;
  }
`;

const StyledWishContainer = styled(Grid)`
  background: ${backgroundGradient};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  position: relative;
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 148px;
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
  padding: 5px;
  // padding-top: 15px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

const StyledLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledValueConatiner = styled.div`
  text-align: center;
`;

const StyledBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 1em;
`;

const StyledContDaimond = styled.div`
  padding: 0.4em;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  display: flex;
`;

interface IProps extends IRouterComponentProps {
  t: any;
  perId: string;
  auctionId: string;
  serialNo: string | number;
  code: string;
  watched: boolean | null;
  caratMinimumPrice: any;
  preBidPrice: any;
  showAlert: any;
  note: boolean | null | string | number;
  removeFromWatchlist: any;
  medias: Array<any>;
  startDate: string;
  currency: any;
  exchangeRates: any;
  jewelleryDetails: any;
  remarks: string;
  items: any;
  auctionType: any;
  lab: string;
  certificate: string;
  parcelType: any;
  productEndDate: any;
  annualProductState: any;
  productStartDate: any;
}

let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";
let caratMinPrice: string = "";

let bidAmount: string = "";
let conversionCaratMinPrice: string = "";
let conversionBidAmount: string = "";
let annualAuctionUserId: any;
let joinedAuctions: any = [];
let seconds: any = "";
const initialState = {
  mouseX: null,
  mouseY: null,
};

const JewelleryProductListGridatWatchlist = (props: IProps) => {
  const [disableButton, setDisableButton] = useState(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  useEffect(() => {
    caratMinPrice = "";
    bidAmount = "";

    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (items.category === "Jewellery") {
          annualAuctionUserId = items.auctionUserId;
        }
      });
    }
    return () => window.removeEventListener("click", removeProductFromList);
  }, []);

  let {
    t,
    startDate,
    medias,
    watched,
    serialNo,
    perId,
    auctionId,
    code,
    caratMinimumPrice,
    preBidPrice,
    currency,
    exchangeRates,
    jewelleryDetails,
    remarks,
    auctionType,
    lab,
    certificate,
    parcelType,
    productEndDate,
    annualProductState,
    productStartDate,
    items,
  } = props;

  caratMinPrice = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  bidAmount = new Intl.NumberFormat("us-en").format(preBidPrice);

  if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  let splitDateTime: any;
  if (auctionType === "Annual") {
    splitDateTime = productStartDate?.split(" ", 2);
  } else {
    splitDateTime = startDate?.split(" ", 2);
  }

  if (
    caratMinimumPrice === null ||
    caratMinPrice === "NaN" ||
    caratMinPrice === "0"
  ) {
    caratMinPrice = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = new Intl.NumberFormat("us-en").format(
        caratMinimumPrice * exchangeRates[0]["rate"]
      );
      conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionCaratMinPrice = "";
    }
    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;
  }

  if (preBidPrice === null || bidAmount === "NaN" || bidAmount === "0") {
    bidAmount = "-";
    conversionBidAmount = "";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode != currency["code"]) {
      let conversion = new Intl.NumberFormat("us-en").format(
        preBidPrice * exchangeRates[0]["rate"]
      );
      conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
    } else {
      conversionBidAmount = "";
    }
    bidAmount = `${currency["symbol"]}${bidAmount}`;
  }

  const onPress = (auctionId: string, perId: string, auctionDate: string) => {
    if (auctionType === "Popcorn_Bidding") {
      props.history.push(
        `/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}`
      );
    } else if (auctionType === "Annual") {
      props.history.push(
        `/365products/details/${auctionId}/${annualAuctionUserId}/${perId}/${auctionDate}?auctionType=${parcelType}`
      );
    } else {
      props.history.push(
        `/watchlist/jewellery/details/${auctionId}/${perId}/${auctionDate}`
      );
    }
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {

    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";

    if (auctionType === "Popcorn_Bidding") {
      window.open(
        `/${languageCode}/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    } else if (auctionType === "Annual") {
      window.open(
        `/${languageCode}/365products/details/${auctionId}/${annualAuctionUserId}/${perId}/${auctionDate}/${false}?auctionType=${parcelType}`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/watchlist/jewellery/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    }
  };

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return <StyledProductImage src={`${medias[0].presignedUrl}`} alt="" />;
  };

  const removeProductFromList = () => {
    props.removeFromWatchlist(perId, auctionId, serialNo);
  };

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );
  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const productTimeLeft = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let hour: any = "";
    let minutes: any = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          // eslint-disable-next-line no-lone-blocks
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    return (
      <Countdown
        date={Date.now() + seconds}
        className="countdownText"
        renderer={renderer}
        zeroPadTime={2}
      />
    );
  };

  const rightClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  let PlayerSlideMeMo = useMemo(() => {
    return (
      <StyledProductImageContainer item={true} xs={8}>
        {medias === null ||
          medias === undefined ||
          medias[0] === undefined ||
          medias[0] === null ||
          medias[0].presignedUrl === null ||
          medias[0].mediaType === "V360" ? (
          <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
        ) : (
          <>
            {medias[0].mediaType === "PHOTO" ? (
              <ShowImage />
            ) : (
              <PlayerSlide url={`${medias[0].presignedUrl}`} />
            )}
          </>
        )}
      </StyledProductImageContainer>
    );
  }, [items]);

  const renderWatchlistBox = () => {
    if (auctionType === "Annual") {
      return (
        <StyledCardContainer>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            <StyledTitleWrapper
              item={true}
              xs={10}
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <Grid container={true} spacing={0} className="height">
                <Grid item={true} xs={12}>
                  <div>
                    <StyledContDaimond>
                      <ProductDescription
                        truncateLength={100}
                        data={props.items}
                        isTendor={false}
                      />
                    </StyledContDaimond>
                  </div>
                </Grid>
                <StyledRemarks item={true} xs={12}>
                  <div>
                    <StyledRemarkLbl>
                      {t("common:preview:perItemDescription:remarks") + ":"}
                      {props.remarks !== "" &&
                        props.remarks !== undefined &&
                        props.remarks !== null ? (
                        <>
                          <EllipsisText
                            text={remarks}
                            length={25}
                            tail={"..."}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </StyledRemarkLbl>
                  </div>
                </StyledRemarks>
              </Grid>
            </StyledTitleWrapper>
            {watched === true && disableButton === false ? (
              <StyledWishContainer
                item={true}
                xs={2}
                onClick={(e) => {
                  e.preventDefault();
                  removeProductFromList();
                }}
              >
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            ) : (
              <StyledWishContainer item={true} xs={2}>
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            )}
          </Grid>
          <Grid
            container={true}
            spacing={0}
            onClick={() => onPress(auctionId, perId, splitDateTime[0])}
          >
            <Grid
              container={true}
              spacing={0}
              className="borderBottomBlue"
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  <span className="fontBold textWrap">
                    {caratMinPrice} {conversionCaratMinPrice}
                  </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={4} className="borderRightBlue">
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {t("common:preview:productsList:serialNo")}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {lab}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {certificate}
                  </StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper
                    style={{ color: "black", fontSize: "0.5em" }}
                  >
                    {annualProductState === "Started"
                      ? `${t("common:newCommanTrans:timeleft")}:`
                      : ""}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {annualProductState === "Started"
                      ? productTimeLeft(productEndDate)
                      : ""}
                  </StyledValueConatiner>
                </StyledWrapper>
              </Grid>

              {PlayerSlideMeMo}
            </Grid>
          </Grid>
        </StyledCardContainer>
      );
    } else if (auctionType === "Popcorn_Bidding") {
      return (
        <StyledCardContainer>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            <StyledTitleWrapper
              item={true}
              xs={10}
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <Grid container={true} spacing={0} className="height">
                <Grid item={true} xs={12}>
                  <div>
                    <StyledContDaimond>
                      <ProductDescription
                        truncateLength={50}
                        data={props.items}
                        isTendor={false}
                      />
                    </StyledContDaimond>
                  </div>
                </Grid>
                <StyledRemarks item={true} xs={12}>
                  <div>
                    <StyledRemarkLbl>
                      {t("common:preview:perItemDescription:remarks") + ":"}
                      {props.remarks !== "" &&
                        props.remarks !== undefined &&
                        props.remarks !== null ? (
                        <>
                          <EllipsisText
                            text={remarks}
                            length={25}
                            tail={"..."}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </StyledRemarkLbl>
                  </div>
                </StyledRemarks>
              </Grid>
            </StyledTitleWrapper>
            {watched === true && disableButton === false ? (
              <StyledWishContainer
                item={true}
                xs={2}
                onClick={(e) => {
                  e.preventDefault();
                  removeProductFromList();
                }}
              >
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            ) : (
              <StyledWishContainer item={true} xs={2}>
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            )}
          </Grid>
          <Grid
            container={true}
            spacing={0}
            onClick={() => onPress(auctionId, perId, splitDateTime[0])}
          >
            <Grid
              container={true}
              spacing={0}
              className="borderBottomBlue"
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  <span className="fontBold textWrap">
                    {caratMinPrice} {conversionCaratMinPrice}
                  </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={3} className="borderRightBlue">
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {t("common:preview:productsList:serialNo")}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {lab}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {certificate}
                  </StyledValueConatiner>
                </StyledWrapper>
              </Grid>
              {PlayerSlideMeMo}
            </Grid>
          </Grid>
        </StyledCardContainer>
      );
    } else {
      return (
        <StyledCardContainer>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            <StyledTitleWrapper
              item={true}
              xs={10}
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <Grid container={true} spacing={0} className="height">
                <Grid item={true} xs={12}>
                  <div>
                    <StyledContDaimond>
                      <ProductDescription
                        truncateLength={50}
                        data={props.items}
                        isTendor={false}
                      />
                    </StyledContDaimond>
                  </div>
                </Grid>
                <StyledRemarks item={true} xs={12}>
                  <div>
                    <StyledRemarkLbl>
                      {t("common:preview:perItemDescription:remarks") + ":"}
                      {props.remarks !== "" &&
                        props.remarks !== undefined &&
                        props.remarks !== null ? (
                        <>
                          <EllipsisText
                            text={remarks}
                            length={20}
                            tail={"..."}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </StyledRemarkLbl>
                  </div>
                </StyledRemarks>
              </Grid>
            </StyledTitleWrapper>
            {watched === true && disableButton === false ? (
              <StyledWishContainer
                item={true}
                xs={2}
                onClick={(e) => {
                  e.preventDefault();
                  removeProductFromList();
                }}
              >
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            ) : (
              <StyledWishContainer item={true} xs={2}>
                <FontAwesomeIcon icon={faHeartProps} className="watch" />
              </StyledWishContainer>
            )}
          </Grid>
          <Grid
            container={true}
            spacing={0}
            onClick={() => onPress(auctionId, perId, splitDateTime[0])}
          >
            <Grid
              container={true}
              spacing={0}
              className="borderBottomBlue"
              onClick={() => onPress(auctionId, perId, splitDateTime[0])}
            >
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  <span className="fontBold textWrap">
                    {caratMinPrice} {conversionCaratMinPrice}
                  </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <Grid item={true} xs={3} className="borderRightBlue">
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {t("common:preview:productsList:serialNo")}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper>
                    {lab}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {certificate}
                  </StyledValueConatiner>
                </StyledWrapper>
              </Grid>
              {PlayerSlideMeMo}
            </Grid>
            <Grid container={true} spacing={0}>
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:preBiddingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  <span className="fontBold textWrap">
                    {bidAmount} {conversionBidAmount}
                  </span>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
          </Grid>
        </StyledCardContainer>
      );
    }
  };
  return (
    <Grid onContextMenu={rightClickEvent}>
      {renderWatchlistBox()}
      <Menu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => openDetailsView(auctionId, perId, splitDateTime[0])}
        >
          Details
        </MenuItem>
      </Menu>
    </Grid>
  );
};

export default JewelleryProductListGridatWatchlist;
