import React, { useState } from "react";

import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { GlassMagnifier } from "react-image-magnifiers";
import { Dialog, DialogContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const StyledComponent = styled(Grid)`
  outline: none;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 0.2em 0.2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 600px;
    height: 450px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 700px;
    height: 650;
  }
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  padding: 10px 20px;
`;

interface IProps {
  imageUrl: string;
  onClose: any;
}

const VideoModal = (props: IProps, ref: any) => {
  const { imageUrl, onClose } = props;
  const [onLargeDblImage, setOnLargeDblImage] = useState(false);

  function imageFullScreen() {
    return (
      <>
        <Dialog
          fullScreen
          open={onLargeDblImage}
          onClose={() => {
            setOnLargeDblImage(false);
          }}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setOnLargeDblImage(false);
            }}
            aria-label="close"
            sx={{
              position: "absolute",
              right: "10px",
              top: "5px",
              color: 'white',
              // backgroundColor:'white'
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt="img"
              style={{
                width: '60%',
                maxWidth: "95vw",
                maxHeight: "95vh",
              }}
              src={`${imageUrl}`}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <div>
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <StyledParticipateWrapper
            item
            md={12}
            xs={12}
            lg={12}
            onClick={() => {
              setOnLargeDblImage(true);
            }}
          >
            <GlassMagnifier
              imageSrc={`${imageUrl}`}
              magnifierSize="40%"
              largeImageSrc={`${imageUrl}`}
            />
          </StyledParticipateWrapper>
        </StyledWrapper>
      </StyledComponent>
      {onLargeDblImage && imageFullScreen()}
    </div>
  );
};

export default React.forwardRef(VideoModal);
