import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { productUrl } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { buttonGradient } from "../../../common/materials/LinearGradient";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";

const StyledWishContainer = styled(Grid)`
  display: flex;
  width: auto;
  justify-content: space-around;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.3em;
  width: 100%;
  border-radius: 5px;
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .notAvailable {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.white};
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    }
  }
`;

const StyledWatchlist = styled(Grid)`
  padding: 0.5em 0em;
  text-align: left;
  padding: 5px;
`;

const StyledWishlistIcon = styled("img")`
  width: 20px;
  height: 20px;
  padding: 0.2em;
`;

const StyledWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledWrapperIcon = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

interface IProps extends IRouterComponentProps {
  t: any;
  auctionId: string;
  perId: string;
  serialNo: string;
  sellerAuctionUserId: any;
  auctionState: string;
  watched: boolean;
  getDetails: (productId: string) => void;
  alertType: (
    alertType: string,
    isOpenToast: boolean,
    showMessage: string
  ) => void;
  apiTimeout: (apiTimeout: boolean) => void;
  auctionType: any;
  isSeller: boolean;
  isReadOnlyUser: boolean;
}

let redirectFromLive: any;
const AddorRemoveWatchlist = (props: IProps) => {
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const {
    t,
    auctionId,
    perId,
    serialNo,
    watched,
    sellerAuctionUserId,
    getDetails,
    alertType,
    apiTimeout,
    auctionType,
    isReadOnlyUser,
  } = props;

  useEffect(() => {
    if (props.isSeller) {
      setIsAuctionUserId(true);
    }
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    const urlParams = new URLSearchParams(window.location.search);
    redirectFromLive = urlParams.get("redirectFromLive");
    if (redirectFromLive === "true") {
      setDisableButton(true);
    }
    return () => setIsAuctionUserId(false);
  }, []);

  const removeFromWatchlist = (perId: string, auctionId: string) => {
    setDisableButton(true);
    const Auth = new AuthService();
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${perId}/unwatch`,
        "data",
        (status: number, response: any) => {
          if (status === 200) {
            getDetails(perId);
            alertType("success", true, `${t("trans:removedFromWatchlistSuccessfully")}`);
          } else if (status === 505) {
            props.apiTimeout(true);
          } else if (status === 412) {
            setTimeout(() => {
              props.history.push("/preview");
            }, 2000);
            alertType("error", true, response);
          } else if (status === 404) {
            alertType("error", true, `${t("common:invalidRequest")}`);
          } else {
            alertType("error", true, `${t("common:somethingWentWrong")}`);
          }
        }
      );
    }
  };

  const addToWatchList = (
    perId: string,
    auctionId: string,
    serialNo: string | number
  ) => {
    setDisableButton(true);
    const Auth = new AuthService();
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${perId}/watch`,
        "data",
        (status: number, response: any) => {
          if (status === 200) {
            getDetails(perId);
            alertType("success", true, `${t("trans:addToWatchlistSuccessfully")}`);
          } else if (status === 404) {
            alertType("error", true, `${t("common:invalidRequest")}`);
          } else if (status === 505) {
            apiTimeout(true);
          } else {
            alertType("error", true, `${t("common:somethingWentWrong")}`);
          }
        }
      );
    }
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  return (
    <StyledWrapper container={true} spacing={0}>
      {watched === true ? (
        <StyledButton>
          {auctionType === "Popcorn_Bidding" ? (
            <div>
              {isReadOnlyUser ? (
                <StyledWishContainer
                  className="notAvailable"
                  onClick={() => {
                    renderReadOnlyUserToast();
                  }}
                >
                  <StyledWrapperIcon item xs={3}>
                    <StyledWishlistIcon src="/images/heart-grey.png" />
                  </StyledWrapperIcon>
                  <StyledWatchlist item xs={9}>
                    {t("common:preview:addedToWatchList")}
                  </StyledWatchlist>
                </StyledWishContainer>
              ) : (isAuctionUserId === false ||
                  sellerAuctionUserId === undefined) &&
                disableButton === false ? (
                <StyledWishContainer
                  onClick={(e) => {
                    e.preventDefault();
                    return removeFromWatchlist(perId, auctionId);
                  }}
                >
                  <StyledWrapperIcon item xs={3}>
                    <StyledWishlistIcon src="/images/heart.png" />
                  </StyledWrapperIcon>
                  <StyledWatchlist item xs={9}>
                    {t("common:preview:addWatchList")}
                  </StyledWatchlist>
                </StyledWishContainer>
              ) : (
                <StyledWishContainer
                  className="notAvailable"
                  onClick={() => {
                    renderReadOnlyUserToast();
                  }}
                >
                  <StyledWrapperIcon item xs={3}>
                    <StyledWishlistIcon src="/images/heart.png" />
                  </StyledWrapperIcon>
                  <StyledWatchlist item xs={9}>
                    {t("common:preview:addWatchList")}
                  </StyledWatchlist>
                </StyledWishContainer>
              )}
            </div>
          ) : (
            <>
              <div>
                {isReadOnlyUser ? (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (isAuctionUserId === false ||
                    sellerAuctionUserId === undefined) &&
                  disableButton === false ? (
                  <StyledWishContainer
                    onClick={(e) => {
                      e.preventDefault();
                      return removeFromWatchlist(perId, auctionId);
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                )}
              </div>
            </>
          )}
        </StyledButton>
      ) : (
        <StyledButton>
          {auctionType === "Popcorn_Bidding" ? (
            <>
              <div>
                {isReadOnlyUser ? (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (isAuctionUserId === false ||
                    sellerAuctionUserId === undefined) &&
                  disableButton === false ? (
                  <StyledWishContainer
                    onClick={(e) => {
                      e.preventDefault();
                      return addToWatchList(perId, auctionId, serialNo);
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                )}
              </div>
            </>
          ) : (
            <>
              <div>
                {isReadOnlyUser ? (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (isAuctionUserId === false ||
                    sellerAuctionUserId === undefined) &&
                  disableButton === false ? (
                  <StyledWishContainer
                    onClick={(e) => {
                      e.preventDefault();
                      return addToWatchList(perId, auctionId, serialNo);
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                ) : (
                  <StyledWishContainer
                    className="notAvailable"
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    <StyledWrapperIcon item xs={3}>
                      <StyledWishlistIcon src="/images/heart-grey.png" />
                    </StyledWrapperIcon>
                    <StyledWatchlist item xs={9}>
                      {t("common:preview:addedToWatchList")}
                    </StyledWatchlist>
                  </StyledWishContainer>
                )}
              </div>
            </>
          )}
        </StyledButton>
      )}
      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
    </StyledWrapper>
  );
};

export default AddorRemoveWatchlist;
