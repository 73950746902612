export const MsgCode = {
  SCHEDULED_EXTENDED_PRODUCTS: "SCHEDULED_EXTENDED_PRODUCTS",
  MARK_END_MESSAGE: "ANNUAL_EXTENDED_PRODUCT_MARK_END_MESSAGE",
  MARKED_END_MESSAGE: "ANNUAL_EXTENDED_PRODUCT_MARKED_END_MESSAGE",
  PRODUCTS_AVAILABLE: "NEW_ANNUAL_PRODUCTS_AVAILABLE",
  ANNUAL_PRODUCTS_SOLD: "ANNUAL_PRODUCTS_SOLD",
  ANNUAL_PRODUCTS_UPDATES_AVAILABLE: "ANNUAL_PRODUCTS_UPDATES_AVAILABLE",
  USER_REMOVED_FROM_AUCTION: "USER_REMOVED_FROM_AUCTION",
  READ_ONLY_USER: "READ_ONLY_USER",
  USER_DEACTIVATED: "USER_DEACTIVATED",
};
