import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import Colors from "../../common/materials/colors";
import { LandingPagePlayFairFont } from "../LandingPage/StyledComponents";

const NumberHeading = ({ number }: { number: number }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        marginBottom: "2em",
      }}
    >
      <span
        style={{
          padding: "0.8em 0.8em",
          borderRadius: "50%",
          background: Colors.black,
          color: Colors.white,
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <span
          style={{
            lineHeight: "8px",
            marginTop: "3px",
          }}
        >
          {number}
        </span>
      </span>
      <div
        style={{
          borderTop: `2px solid ${Colors.black}`,
          width: "5em",
          marginLeft: "0.3em",
        }}
      ></div>
    </div>
  );
};
const useStyles = makeStyles({
  container: {
    padding: "2em 0 !important",
  },
});
const WhyUs = (props: any) => {
  const classes = useStyles();
  const { t } = props;
  const isMobile = useMediaQuery("(max-width: 600px)");

  const pointers = [
    {
      heading: t("common:whyUs:1:title"),
      points: [
        t("common:whyUs:1:1"),
        t("common:whyUs:1:2"),
        t("common:whyUs:1:3"),
        t("common:whyUs:1:4"),
      ],
    },
    {
      heading: t("common:whyUs:2:title"),
      points: [
        t("common:whyUs:2:1"),
        t("common:whyUs:2:2"),
        t("common:whyUs:2:3"),
        t("common:whyUs:2:4"),
      ],
    },
    {
      heading: t("common:whyUs:3:title"),
      points: [
        t("common:whyUs:3:1"),
        t("common:whyUs:3:2"),
        t("common:whyUs:3:3"),
        t("common:whyUs:3:4"),
      ],
    },
  ];

  return (
    <Grid container classes={classes}>
      {pointers.map((pointer: any, index: number) => {
        return (
          <Grid
            xs={12}
            sm={4}
            item
            style={{ padding: isMobile ? "0" : "0em 4em 0 0.5em" }}
          >
            <NumberHeading number={index + 1} />
            <Typography variant="h4">
              <LandingPagePlayFairFont>
                {pointer.heading}
              </LandingPagePlayFairFont>
            </Typography>
            <ul
              style={{
                margin: 0,
                paddingTop: "1em",
                paddingLeft: isMobile ? "1em" : 0,
                paddingBottom: isMobile ? "1em" : 0,
              }}
            >
              {pointer.points.map((point: any) => (
                <li>{point}</li>
              ))}
            </ul>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default WhyUs;
