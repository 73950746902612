import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import moment from 'moment-timezone';
import Countdown from 'react-countdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { backgroundGradient, dateBackgroundGradient } from '../../../common/materials/LinearGradient';
import { PDFTooltip } from '../auction/AuctionsView';

import { StyledDateGridOngoing } from './JewelleryAuctionCardViewAtPreview';
import EllipsisText from '../../../common/elements/EllipsisText';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faInfoCircleProps = faInfoCircle as IconProp;

const StyledContainer = styled.div`
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    margin: 10px;
    border-radius: 2px;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    .borderRightBlue {
        border-right: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .borderBottomBlue {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
`;

const StyledTitleContainer = styled(Grid)`
  background: ${backgroundGradient};
  padding: 0.3em;
  display: flex;
  align-items: center;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 11px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 13px;
  }
`;

const StyledTendorLbl = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  color: ${(props) => props.theme.colors.base.textColor};
  padding: 0.4em;
  text-align: center;
`;

const StyledProductContainer = styled(Grid)`
    padding: 0.5em;
    text-align: left;
    .heading {
        font-size : 0.8em;
        color: ${(props) => props.theme.colors.base.grey};
    } 
    .productsValue {
        font-size: 1em;
    }
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
        font-size : 0.7em;
    }
    ${(props) => props.theme.breakpoints.between("sm", "md")} {
       font-size : 0.8em;
    }
    ${(props) => props.theme.breakpoints.between("md", "lg")} {
        font-size : 0.8em;
    }
`;

const StyledDateWrapper = styled.div`
    font-size: 14px;
    color: ${(props) => props.theme.colors.base.grey};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 10px;
    }
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        font-size: 10px;
    }
`;

const StyledDateContainer = styled(Grid)`
    margin: 10px;
    background: ${dateBackgroundGradient};
    border-radius: 2px;
`;

export const StyledDateGrid = styled(Grid)`
    width: 100%;
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: space-between;
`;

export const StyledShortBreakTimer = styled(Grid)`
  text-align: end;
`;


const StyledGrid = styled(Grid)`
    .countdownText {
        color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        text-align: right;
    }
    .dateTimeStyle {
        width: 100%;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: center;
    }
    .technicalGlitchAuction {
        color: ${(props) => props.theme.colors.base.red};
    }
`;

interface IProps {
    t: any;
    perId: number;
    name: string;
    state: string;
    productCount: string | number;
    startDate: any;
    auctionType: string;
    resumeDateTime: any;
    value: (value: boolean) => void;
    showListOfProducts: (id: number | string, name: string, startDate: string, state: string, auctionType: string) => void;
}

let date: string | null = '';
let time: string | null = '';
let timezone: any = '';
let hour: any = '';
let seconds: any = '';
let minutes: any = '';
let timeDifference: any = '';

const AfterCountdownComplete = (props: IProps) => {
    const { t } = props;
    useEffect(() => {
        props.value(true);
    }, []);

    return (
        <Grid item xs={12}>
            <StyledDateWrapper className="fontBold">{t('common:ongoing')}</StyledDateWrapper>
        </Grid>
    )
}

const ShortBreakTimer = (props: IProps) => {
    useEffect(() => {
        props.value(true);
    }, []);

    return (
        <span></span>
    )
}
const AuctionCardView = (props: IProps) => {
    let { perId, t, name, productCount, startDate, state, auctionType, resumeDateTime} = props;
    useEffect(() => {        
        date = '';
        time = '';
        
    }, []);    

    const GetShortBreakTimer = (stateTime: any , state: any) => {
        if (stateTime !== null || stateTime !== undefined) {
          let previewTime = moment.utc(stateTime);
          if (timezone !== null) {
            timezone = localStorage.getItem("timezoneName");
          }
          if (previewTime !== null || previewTime !== undefined) {
            let result = moment.tz(previewTime, timezone);
            let resultFormatter = result.format();
            if (resultFormatter !== "Invalid date") {
              let splitDateTime = resultFormatter.split("T", 2);
              let splitTime: any = "";
              if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
              } else {
                splitTime = splitDateTime[1].split("-", 2);
              }
              date = splitDateTime[0];
              time = splitTime[0];
              let dateTime = `${date} ${time}`;
              let start: any = moment(dateTime);
              timezone = localStorage.getItem("timezoneName");
              let end: any = moment(
                new Date().toLocaleString("en-US", { timeZone: timezone })
              );
              hour = start.diff(end, "hours");
              minutes = start.diff(end, "minutes");
              timeDifference = localStorage.getItem("timeDifference");
              timeDifference = timeDifference / 1000;
              timeDifference = parseInt(timeDifference);
              if (state === undefined || state === "Auction_Break"){
                var timeDiff = start - end;
                seconds = Math.round(timeDiff);
                {
                  timeDifference[0] === "-"
                    ? (seconds = seconds - timeDifference)
                    : (seconds = seconds + timeDifference);
                }
              }else{
                  if (hour === 0) {
                    var timeDiff = start - end;
                    seconds = Math.round(timeDiff);
                    {
                      timeDifference[0] === "-"
                        ? (seconds = seconds - timeDifference)
                        : (seconds = seconds + timeDifference);
                    }
                  }
              }
                
            }
          }
        }
    };

    if (state === undefined || state === "Auction_Break") {
      GetShortBreakTimer(resumeDateTime, state);
    } else {
      GetShortBreakTimer(startDate, state);
    }

    if (productCount === null) {
        productCount = '-';
    }


    return (
        <StyledGrid>
            <StyledContainer onClick={(e) => {
                e.preventDefault();
                return props.showListOfProducts(perId, name, startDate, state, auctionType)
            }}>
                <Grid container={true} spacing={0} className="borderBottomBlue">
                    <StyledTitleContainer xs={12} item container>
                        {auctionType !== undefined && auctionType != null ?
                            <>
                                {auctionType !== 'Live' ?
                                    <>
                                        <Grid item xs={8} lg={6} md={8} xl={8} className="fontBold"><EllipsisText text={name} length={16}tail={"..."}/></Grid>
                                        <StyledTendorLbl xs={4} lg={6} md={4} xl={4} style={{ padding: "0.4em", fontSize: "12px"}}>{t('common:preview:tendor:popcornBidding')}</StyledTendorLbl>
                                    </>
                                    :
                                    <>
                                        <Grid item xs={7} className="fontBold"><EllipsisText text={name} length={18}tail={"..."}/></Grid>
                                        <StyledTendorLbl xs={5}>{t('common:preview:tendor:live')}</StyledTendorLbl>
                                    </>
                                }
                            </> :
                            <>
                                <Grid item xs={7} className="fontBold"><EllipsisText text={name} length={18} tail={"..."}/></Grid>
                                <StyledTendorLbl xs={5}>{t('common:preview:tendor:live')}</StyledTendorLbl>
                            </>
                        }
                    </StyledTitleContainer>
                </Grid>
                <Grid container={true} spacing={0}>
                    <StyledProductContainer item={true} xs={6}>
                        <div className="heading fontBold">{t('common:preview:products')}</div>
                        <div className="productsValue fontBold">{productCount} {t('common:preview:pieces')}</div>
                    </StyledProductContainer>
                </Grid>
            </StyledContainer>
            <StyledDateContainer>
                {state === 'Started' ?
                    <StyledDateGrid item xs={12}>
                        <StyledDateWrapper className="fontBold">{t('common:ongoing')}</StyledDateWrapper>
                    </StyledDateGrid>
                    :
                    <div>
                        {state === 'Auction_Break' ?
                            <div>
                                <StyledDateGridOngoing item xs={12}>
                                    <Grid item xs={7}>
                                            <StyledDateWrapper className="fontBold">{t('common:willResume')}</StyledDateWrapper>
                                        </Grid>
                                        <StyledShortBreakTimer item xs={5}>
                                            <Countdown date={Date.now() + seconds} className="countdownText" daysInHours={false}>
                                                <ShortBreakTimer {...props} />
                                            </Countdown>
                                        </StyledShortBreakTimer>  
                                </StyledDateGridOngoing>
                            </div>                            
                            :
                            <div>
                                {state === 'Paused' ?
                                    <StyledDateGrid item xs={12}>
                                        <StyledDateWrapper className="fontBold">{t('common:paused')}</StyledDateWrapper>
                                    </StyledDateGrid>
                                    :
                                    <div>
                                        {hour === 0 && minutes >= 0 ?
                                            <StyledDateGridOngoing container className='dateTimeStyle'>
                                                <Grid item xs={5}>
                                                <Countdown date={Date.now() + seconds} className="countdownText" daysInHours={true}>
                                                        <AfterCountdownComplete {...props} />
                                                </Countdown>
                                                </Grid>
                                                <Grid item xs={7}>
                                                    <StyledDateWrapper className="fontBold aboutToStartText" style={{textAlign: "right"}}>{t('common:abouttoStart')}</StyledDateWrapper>
                                                </Grid>
                                            </StyledDateGridOngoing>
                                            :
                                            <div>
                                                {minutes < 0 && state === 'NotStarted' ?
                                                    <StyledDateGridOngoing item xs={12}>
                                                        <PDFTooltip disableTouchListener title={`${t('common:auction:technicalGlitch:firstLine')} ${t('common:auction:technicalGlitch:secondLine')}`}>
                                                            <div><FontAwesomeIcon icon={faInfoCircleProps} className="technicalGlitchAuction" /></div>
                                                        </PDFTooltip>
                                                        <StyledDateWrapper className="fontBold technicalGlitchAuction" style={{textAlign: "right"}}>{t('common:auction:notAbleToStart')}</StyledDateWrapper>
                                                    </StyledDateGridOngoing>
                                                    :
                                                    <StyledDateGrid item xs={12}>
                                                        <StyledDateWrapper className="fontBold">{date}</StyledDateWrapper>
                                                        <StyledDateWrapper className="fontBold">{time}</StyledDateWrapper>
                                                    </StyledDateGrid>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>

                }
            </StyledDateContainer>
        </StyledGrid>
    )
}

export default AuctionCardView;