import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import ButtonComp from "../../../common/elements/Button";
import HeaderMenu from "./HeaderMenu";
import {
  HeaderContainer,
  StyledLeftAlignedContainer,
  StyledRightAlignedContainer,
  StyledCenterContainer,
  StyledLogoBackground,
  StyledLogoContainer,
  HiddenButton,
} from "./StyledComponent";

import Logo from "../Static/Icons/Logo";

import HeaderSignInMenu, { getEmailFromUrlParams, SignIn } from "./SignIn";
import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import LanguageSelector from "./LanguageSelector";
import {
  Container,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Colors from "../../../common/materials/colors";
import Button from "../../../common/elements/Button";
import { LandingPagePlayFairFont } from "../StyledComponents";
import Cross from "../Static/Icons/Cross";
import SignUpDialog, { SignUpModalOptions } from "../Dialogs/SignUp";

const WebHeader = ({
  handleSellDiamonds,
  handleBuyDiamonds,
  goToHome,
  handleSignUpModal,
}: any) => {
  const { t } = useTranslation();
  return (
    <HeaderContainer className="headerContainer">
      <Container>
        <Grid container alignItems="center">
          <Grid xs={1} item>
            <StyledLogoBackground onClick={goToHome}>
              <Logo />
            </StyledLogoBackground>
          </Grid>
          <StyledLeftAlignedContainer xs={5} item>
            <HeaderMenu handleSellDiamonds={handleSellDiamonds} />
          </StyledLeftAlignedContainer>
          <StyledRightAlignedContainer
            xs={3}
            item
          ></StyledRightAlignedContainer>
          <StyledRightAlignedContainer xs={1} item>
            <LanguageSelector />
            <HeaderSignInMenu />
          </StyledRightAlignedContainer>
          <HiddenButton
            id="hiddenButton"
            onClick={handleSignUpModal}
          ></HiddenButton>
          <StyledCenterContainer xs={2} item>
            <ButtonComp onClick={handleBuyDiamonds} type="contained">
              {t("common:header:buttons:register")}
            </ButtonComp>
          </StyledCenterContainer>
        </Grid>
      </Container>
    </HeaderContainer>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "2em 1em",
    background: Colors.grey1,
    position: "relative",
  },
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
});

const handleMobileSignIn = (
  t: any,
  createDialog: any,
  classes: any,
  handleClose: any,
  showForgotPassword?: boolean,
  maxWidth?: string,
  setMessage?: any,
  onClose?: any
) => {
  createDialog({
    children: (
      <div className={classes.container}>
        <Button
          type=""
          onClick={handleClose}
          className={classes.cross}
          noMargin
        >
          <Cross filled />
        </Button>
        <p>
          {showForgotPassword ? null : (
            <Typography variant="h4" align="center">
              <LandingPagePlayFairFont>
                {t("common:signIntoYourAcc")}
              </LandingPagePlayFairFont>
            </Typography>
          )}
        </p>
        <SignIn
          handleClose={handleClose}
          setSuccessMessage={setMessage}
          showForgotPassword={showForgotPassword}
        />
      </div>
    ),
    maxWidth: maxWidth || "sm",
    fullWidth: true,
    open: true,
    onClose,
  });
};

export const ZipangForgotPassword = ({
  show,
  onClose,
  setMessage,
}: {
  setMessage?: any;
  onClose?: any;
  show?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createDialog, closeDialogue] = useDialog();

  const handleClose = () => {
    onClose && onClose();
    closeDialogue();
  };

  useEffect(() => {
    if (show) {
      handleMobileSignIn(
        t,
        createDialog,
        classes,
        handleClose,
        true,
        "xs",
        setMessage
      );
    } else {
      closeDialogue();
    }
  }, [show]);

  return null;
};
export const MobileSignIn = ({
  show,
  onClose,
}: {
  onClose?: any;
  show?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createDialog, closeDialogue] = useDialog();

  const handleClose = () => {
    onClose && onClose();
    closeDialogue();
  };

  useEffect(() => {
    show &&
      handleMobileSignIn(
        t,
        createDialog,
        classes,
        handleClose,
        false,
        "sm",
        null,
        onClose
      );
  }, [show]);

  return null;
};
let mobileSignInShown = false;
export const MobileHeader = ({
  handleBuyDiamonds,
  goToHome,
  greyBg,
  handleSignUpModal,
}: any) => {
  const { t } = useTranslation();

  const [showSignIn, setShowSignIn] = useState(false);

  useEffect(() => {
    const userEmail = getEmailFromUrlParams();
    if (userEmail && !mobileSignInShown) {
      setShowSignIn(true);
      mobileSignInShown = true;
    }
  }, []);

  const handleClose = () => {
    setShowSignIn(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        position: "relative",
        height: "60px",
        zIndex: 1001,
        background: greyBg ? Colors.lightGrey : "default",
      }}
    >
      <HiddenButton
        id="hiddenButton"
        onClick={handleSignUpModal}
      ></HiddenButton>
      <MobileSignIn show={showSignIn} onClose={handleClose} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ position: "absolute", top: 0, width: "100%" }}
      >
        <StyledLogoContainer
          style={{
            display: "inline-block",
            padding: "1em 2em",
          }}
        >
          <span onClick={goToHome}>
            <Logo height="40" width="60" />
          </span>
        </StyledLogoContainer>
        <ButtonComp onClick={handleBuyDiamonds} type="contained">
          {t("common:header:buttons:register")}
        </ButtonComp>
      </Grid>
    </Grid>
  );
};

// header
const Header = ({ greyBg }: { greyBg?: boolean }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [createDialog, closeDialog] = useDialog();

  const history = useHistory();

  const handleSellDiamonds = React.useCallback(() => {
    history.push("/sell");
  }, []);

  const goToHome = React.useCallback(() => {
    history.push("/");
  }, []);

  const handleBuyDiamonds = React.useCallback(
    (isMobile) => {
      createDialog({
        children: (
          <SignUpFormDialog
            isMobile={isMobile}
            onSignUp={closeDialog}
            close={closeDialog}
          />
        ),
        ...SignUpFormDialogOptions,
      });
    },
    [isMobile]
  );

  const handleSignUpModal = React.useCallback(
    (isMobile) => {
      createDialog({
        children: (
          <SignUpDialog
            onSignUp={() => handleBuyDiamonds(isMobile)}
            close={closeDialog}
          />
        ),
        ...SignUpModalOptions,
      });
    },
    [isMobile]
  );

  // useEffect(handleBuyDiamonds, []);

  return isMobile ? (
    <MobileHeader
      greyBg={greyBg}
      handleBuyDiamonds={handleBuyDiamonds}
      goToHome={goToHome}
      handleSignUpModal={handleSignUpModal}
    />
  ) : (
    <WebHeader
      handleBuyDiamonds={handleBuyDiamonds}
      handleSellDiamonds={handleSellDiamonds}
      goToHome={goToHome}
      handleSignUpModal={handleSignUpModal}
    />
  );
};

export default Header;
