import React, { useEffect } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { buttonGradient } from "../../common/materials/LinearGradient";
import IRouterComponentProps from "../../models/IRouterComponentProps";
const StyledComponent = styled(Grid)`
  outline: none;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1.6em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  text-align: center;
  padding: 1.5em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 600px;
  }
`;

const StyledTextWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 1.2em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  width: 80px;
  padding: 0.5em 0.5em;
  padding-top: 0.8em;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

export interface IProps {
  closeModal: () => void;
}

const ConfirmationPopupWebsite= React.forwardRef((props: IProps, ref) => {
  const { closeModal } = props;
  const onClickOkButton = () => {
    closeModal();
  };

  return (
    <StyledComponent>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <div>
            <span>
              <StyledTextWrapper>
                Thank You for Showing your interest in My Auctions .
              </StyledTextWrapper>
            </span>
            &nbsp;
          </div>
          <StyledTextWrapper>
            {" "}
            Your response has been recorded. 
          </StyledTextWrapper>
          <StyledButton onClick={onClickOkButton}>ok</StyledButton>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
});

export default ConfirmationPopupWebsite;
