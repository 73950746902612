import React from "react";
import DescriptionCard, { DescriptionProps } from "./DescriptionCard";
import { StyledFlexContainer } from "./StyledComponent";

const DescriptionList = ({
  descriptionCards,
}: {
  descriptionCards: DescriptionProps[];
}) => {
  return (
    <StyledFlexContainer container spacing={4}>
      {descriptionCards.map((card: DescriptionProps, cardId: number) => (
        <DescriptionCard key={cardId} {...card} />
      ))}
    </StyledFlexContainer>
  );
};

export default DescriptionList;
