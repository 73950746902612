import { productDownload } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";

const Auth = new AuthService();

export const exportExcel = (auctionProducts: any, auctionId: string) => {
  Auth.postRequestForExcel(
    `${productDownload}?auctionId=${auctionId}`,
    { ids: "" },
    (status: any, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Products.xlsx`);
          document.body.appendChild(link);
          link.click();
        }
      }
    }
  );
};

export const getDescription = (
  parcelType: any,
  productDescription: any,
  remarks: any
) => {
  if (parcelType !== "Parcel") {
    if (remarks !== "" && remarks !== undefined && remarks !== null) {
      return remarks;
    } else {
      return "-";
    }
  } else {
    if (
      productDescription !== "" &&
      productDescription !== undefined &&
      productDescription !== null
    ) {
      return productDescription;
    } else {
      return "-";
    }
  }
};

export const convertedValue = (
  currencyCodeVal: any,
  price: any,
  exchangeRate: any
) => {
  //get user currency code form local storeage
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  if (
    currencyCodeVal === undefined ||
    currencyCodeVal === null ||
    price === undefined ||
    price === null ||
    price === 0 ||
    exchangeRate === undefined ||
    exchangeRate === null
  ) {
    return `-`;
  } else {
    if (userCurrencyCode !== currencyCodeVal.code) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(price * exchangeRate)
      );
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
    } else {
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${userCurrencySymbol} ${conversions}`;
    }
  }
};


export const convertedbuyItNowValue = (
  currencyCodeVal: any,
  price: any,
  exchangeRate: any
) => {
  //get user currency code form local storeage
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  if (
    currencyCodeVal === undefined ||
    currencyCodeVal === null ||
    price === undefined ||
    price === null ||
    price === 0 ||
    exchangeRate === undefined ||
    exchangeRate === null
  ) {
    return `-`;
  } else {
    if (userCurrencyCode !== currencyCodeVal.code) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(price * exchangeRate)
      );
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${conversions} ${currencyCodeVal.symbol}`;
    } else {
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return ` ${conversions} ${userCurrencySymbol} `;
    }
  }
};


export const convertedRightBuyItNowValue = (
  currencyCodeVal: any,
  price: any,
  exchangeRate: any
) => {
  //get user currency code form local storeage
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  if (
    currencyCodeVal === undefined ||
    currencyCodeVal === null ||
    price === undefined ||
    price === null ||
    price === 0 ||
    exchangeRate === undefined ||
    exchangeRate === null
  ) {
    return `-`;
  } else {
    if (userCurrencyCode !== currencyCodeVal.code) {
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${currencyCodeVal.symbol} ${conversions}`;
    } else {
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${userCurrencySymbol} ${conversions}`;
    }
  }
};

export const convertedPrebidValue = (
  currencyCodeVal: any,
  price: any,
  exchangeRate: any
) => {
  //get user currency code form local storeage
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  if (
    currencyCodeVal === undefined ||
    currencyCodeVal === null ||
    price === undefined ||
    price === null ||
    price === 0 ||
    exchangeRate === undefined ||
    exchangeRate === null
  ) {
    return `-`;
  } else {
    if (userCurrencyCode !== currencyCodeVal.code) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(price * exchangeRate)
      );
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion}) /ct`;
    } else {
      let conversions = new Intl.NumberFormat("us-en").format(
        Math.round(price)
      );
      return `${userCurrencySymbol} ${conversions} /ct`;
    }
  }
};

export const getRapNet = (rapnetPercentage: any) => {
  if (
    rapnetPercentage === undefined ||
    rapnetPercentage === 0 ||
    rapnetPercentage === "0" ||
    rapnetPercentage === "0.0"
  ) {
    return `-`;
  } else {
    return rapnetPercentage;
  }
};

export const getRapnetPercentage = (rowData: any, currentBidPrice: any) => {
  let productPricePerCaratUSD: any;
  let rapoffValue: any;
  if (
    rowData.rapnetPercentage === undefined ||
    rowData.rapnetPercentage === 0
  ) {
    return "-";
  } else {
    if (rowData.currency.code === "JPY") {
      productPricePerCaratUSD = currentBidPrice * rowData.exchangeRates[0].rate;
    } else {
      productPricePerCaratUSD = currentBidPrice;
    }
    rapoffValue = (
      (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
      -100.0
    ).toFixed(2);
    return rapoffValue !== "NaN" ? rapoffValue : "-";
  }
};

export const getMyLatestStoredBids = (
  productBids: any,
  productIdFromData: any,
  currencyCodeVal?: any,
  exchangeRate?: any,
  otherID?: any
) => {
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
  if (otherID !== undefined || otherID !== null) {
    if (otherID[0] === productIdFromData) {
      return productBids?.map((items: any) => {
        const { productId, myLatestBid } = items;
        if (productId === productIdFromData) {
          if (userCurrencyCode !== currencyCodeVal.code) {
            let conversion = new Intl.NumberFormat("us-en").format(
              Math.round(myLatestBid * exchangeRate)
            );
            let conversions = new Intl.NumberFormat("us-en").format(
              Math.round(myLatestBid)
            );
            return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
          } else {
            let conversions = new Intl.NumberFormat("us-en").format(
              Math.round(myLatestBid)
            );
            return `${userCurrencySymbol} ${conversions}`;
          }
        }
      });
    } else {
      return "0";
    }
  }
};

export const getReturnMyLatestStoredBid = (
  productBids: any,
  productIdFromData: any
) => {
  return productBids?.map((items: any) => {
    const { productId, myLatestBid } = items;
    if (productId === productIdFromData) {
      return myLatestBid;
    }
  });
};

export const getRapoff = (rowData: any, currentBidPrice: any) => {
  let productPricePerCaratUSD: any;
  let rapoffValue: any;
  if (
    rowData.rapnetPercentage === undefined ||
    rowData.rapnetPercentage === null ||
    rowData.rapnetPercentage === 0 ||
    rowData.rapnetPercentage === '0' ||
    rowData.rapnetPercentage === "0.0"
  ) {
    return "-";
  } else {
    if (rowData.parcelType !== "Jewellery") {
      if (rowData.auctionState === "NotStarted") {
        if (rowData.preBidPrice === undefined || rowData.preBidPrice === 0) {
          return "-";
        }
        if (rowData.currency.code === "JPY") {
          productPricePerCaratUSD =
            rowData.preBidPrice * rowData.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = rowData.preBidPrice;
        }
        rapoffValue = (
          (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
          -100.0
        ).toFixed(2);

        return rapoffValue !== "NaN" ? rapoffValue : "-";
      } else {
        if (rowData.state !== "Available") {
          if (rowData.currency.code === "JPY") {
            productPricePerCaratUSD =
              rowData.currentBidPrice * rowData.exchangeRates[0].rate;
          } else {
            productPricePerCaratUSD = rowData.currentBidPrice;
          }
          rapoffValue = (
            (1 - productPricePerCaratUSD / rowData.rapnetPrice) *
            -100.0
          ).toFixed(2);

          return rapoffValue !== "NaN" ? rapoffValue : "-";
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  }
};
