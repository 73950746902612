import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import ReplyIcon from "@material-ui/icons/Reply";
import { TextField } from "@material-ui/core";
import {
  enquiryCommentsdata,
  getInquiryApiData,
} from "../common/config/app.endpoints";
import AuthService from "../components/auth/AuthService";
import { StyledTextInputs } from "../components/products/details/inquiry/InquiryDetailsCss";
import Alert from "../common/elements/Alert";
import IRouterComponentProps from "../models/IRouterComponentProps";
import { useDropzone } from "react-dropzone";
import { oAuth } from "../routes/basic/BasicAppRouter";
import LoadingSpinner from "../common/elements/LoadingSpinner";

const StyledComponent = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1.6em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.lightgrey};
  }
  border-radius: 20px 20px 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  clear: both;
`;

const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  overflow-wrap: anywhere;
  display: flex;
  flex-direction: column;
  width: 100%;
  // justify-content: center;
  // align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;

const StyledCommentSection = styled.div`
  padding: 10px;
  border-radius: 0 30px 30px 30px;
  float: right;
  display: grid;
`;

const StyledContents = styled.div`
  padding: 10px;
  border-radius: 0 30px 30px 30px;
  float: left;
  display: grid;
`;
const StyledContent = styled.div`
  background: lightsteelblue;
  padding: 10px;
  border-radius: 0 30px 30px 30px;
  float: left;
`;

const StyledFromContent = styled.div`
  background: cadetblue;
  padding: 10px;
  border-radius: 30px 30px 0px 30px;
  float: right;
`;

const StyledReply = styled.div`
  padding: 10px;
  justify-content: flex-end;
`;
const StyledReplyDate = styled.div`
  // float: right;
  font-size: 0.6em;
  text-align: end;
`;
const StyledLink = styled.button<IButtonProps>`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  background: transparent;
  border: none;
`;
const StyledButtonContainer = styled.div`
  text-align: center;
  margin: 5px 0;
  display: flex;
`;
interface IButtonProps {
  isSubmit?: boolean;
}
const StyledReplyDic = styled.div`
  font-size: 0.8em;
  font-family: Calibre-Regular;
  display: flex;
`;

const StyledApp = styled.img`
  cursor: pointer;
  width: 1em;
  height: 1em;
`;

const StyledChatBoxConatiner = styled(Grid)`
  overflow-x: auto;
  overflow-y: overlay;
  height: 500px;
  width: 500px;
  border-bottom: 1px solid lightgray;
  scroll-behavior: smooth;
  @media (width: 320px) {
    height: 400px;
    width: 100%;
  }
  @media (width: 360px) {
    height: 450px;
    width: 100%;
  }
  @media (width: 411px) {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 100%;
  }
`;
const StyledContainer = styled.div``;
const StyledProductContainer = styled(Grid)``;

const StyledAttachmentImg = styled(Grid)``;
const GelleryImage = styled.img`
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
  margin: 10px;
`;

const StyledMediaContainer = styled(Grid)`
  align-items: center;
  padding-bottom: 2px;
  .attachmentName {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledMedias = styled.img`
  width: 80px;
  height: 80px;
  margin-right: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.veryLightGrey};
`;

interface IProps extends IRouterComponentProps {
  t: any;
  closeModal: () => void;
  saveQuery: () => void;
  comment: any;
  userQueryId: any;
  fromId: any;
  activeData: any;
  commetUpdateIndex: number;
  handleClosePopup: any;
}

let isButtonSubmit = false;
let acceptedFileItems: any;

const BlockUserModal = (props: IProps) => {
  const { t, userQueryId, activeData, commetUpdateIndex } = props;
  const [text, setText] = useState("");
  const [enquiryId, setEnquiryId] = useState("");
  const [disable, setDisable] = useState(false);
  const Auth = new AuthService();
  const [validationMessage, setValidationMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentProductIndex, setCurrentProductIndex] =
    useState(commetUpdateIndex);
  const [enquiryObject, setEnquiryObject] = useState<any>(
    activeData[currentProductIndex]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
  });
  const [fileName, setFileNames] = useState("");
  const [images, setImages] = useState("");
  const [getSingleInquiryData, setGetSingleInquiryData] = useState<any>([]);

  useEffect(() => {
    getCommentsByInquiryId();
  }, [activeData]);

  const getCommentsByInquiryId = () => {
    const enquiryObj = activeData.find((item: any) => item.id === userQueryId);
    setEnquiryObject(enquiryObj);
  };

  useEffect(() => {
    setEnquiryObject(activeData[currentProductIndex]);
    setOpenToast(false);
    topFunction();
    getPerIDData();
    return props.handleClosePopup;
  }, []);

  const getPerIDData = () => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getInquiryApiData}?id=${userQueryId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setGetSingleInquiryData(response);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const topFunction = () => {
    const elmnt: any = document.getElementById("myDIV");
    elmnt.scrollTop = 100000;
  };

  const handleChange = async (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "text") {
      setText(targeValue);
    } else if (targeName === "enquiryId") {
      setEnquiryId(targeValue);
    }
    if (targeName === "images") {
      if (e.target.files[0] !== undefined && e.target.files[0] !== "") {
        let fileObj = e.target;
        let isMediaTypeImage: boolean = await getFileExt(fileObj);
        if (isMediaTypeImage) {
          if (getFileSize(fileObj.files[0])) {
            setImages(fileObj.files);
            acceptedFileItems = fileObj.files;
            for (let i = 0; i < acceptedFileItems.length; i++) {
              let file = acceptedFileItems.item(i);
              let filesName = file.name;
              let fileLength = acceptedFileItems.length - 1;
              setFileNames(
                `${filesName} ${fileLength === 0 ? "" : `+ ${fileLength}`}`
              );
            }
          } else {
            setAlertType("error");
            setShowMessage(`File size should be less than 5 MB.`);
            setOpenToast(true);
          }
        } else {
          setAlertType("error");
          setShowMessage(`File should be image.`);
          setOpenToast(true);
        }
        setTimeout(() => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  const getFileSize = (file: any) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize > 5000) {
      return false;
    } else {
      return true;
    }
  };
  const getFileExt = (image: any) => {
    const fileInput = image;
    const filePath = fileInput.value;

    const allowedExtensions: any = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.test(filePath)) {
      return false;
    } else {
      return true;
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    var formData = new FormData();
    setDisable(false);

    if (text === "") {
      setValidationMessage("error");
      setAlertType("error");
      setShowMessage(`${t("common:toastTraslation:replyText")}`);
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 2000);
    } else {
      setValidationMessage("");
      let apiform = {
        text: text,
        enquiryId: userQueryId,
      };
      var test = JSON.stringify(apiform);
      formData.append("comments", test);

      if (images !== "") {
        for (let i = 0; i < images.length; i++) {
          if (images.length <= 5) {
            formData.append("fileToUpload", images[i]);
          } else {
            setAlertType("error");
            setShowMessage("Attachment should be less than 5.");
            setOpenToast(true);
          }
        }
      } else {
        formData.append("fileToUpload", images);
      }
      callAPI(formData);
    }
  };

  const callAPI = (formData: any) => {
    isButtonSubmit = true;
    setIsLoading(true);
    Auth.inquiryPostRequest(
      `${enquiryCommentsdata}`,
      formData,
      (status: number) => {
        if (status === 200) {
          setCurrentProductIndex(commetUpdateIndex);
          props.saveQuery();
          getPerIDData();
          setFileNames("");
          setText("");
          topFunction();
          setIsLoading(false);
          setAlertType("success");
          setShowMessage(`${t("common:toastTraslation:messageSent")}`);
          setOpenToast(true);
        }
      }
    );
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledChatBoxConatiner id="myDIV">
        <div style={{ overflowWrap: "anywhere" }}>
          <StyledCommentSection style={{ paddingRight: "30px" }}>
            <div>
              {" "}
              <StyledFromContent>
                {enquiryObject?.description}
              </StyledFromContent>
            </div>
            <div>
              <StyledMediaContainer container={true} spacing={0}>
                {getSingleInquiryData.medias &&
                  getSingleInquiryData.medias.map(
                    (subMedia: any, index: number) => {
                      return (
                        <Grid item={true} key={index} title={subMedia?.name}>
                          <a href={subMedia?.presignedUrl} target="_blank">
                            <StyledMedias src={subMedia?.presignedUrl} />
                          </a>
                        </Grid>
                      );
                    }
                  )}
              </StyledMediaContainer>
            </div>
            <StyledReplyDate>
              {getSingleInquiryData?.createdDate}
              <StyledApp src="/images/userIcon.png" />
            </StyledReplyDate>
          </StyledCommentSection>
        </div>
        {getSingleInquiryData?.comments !== undefined &&
          getSingleInquiryData?.comments !== null && (
            <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
              {getSingleInquiryData?.comments.map((item: any) => {
                if (item.fromWebSite === true) {
                  return (
                    <StyledContainer>
                      <Grid container={true} spacing={0}>
                        <StyledProductContainer item={true} xs={12}>
                          <StyledCommentSection>
                            <div>
                              <StyledFromContent>{item.text}</StyledFromContent>
                            </div>
                            <div>
                              <StyledMediaContainer
                                container={true}
                                spacing={0}
                              >
                                {item.medias &&
                                  item.medias.map(
                                    (subMedia: any, index: number) => {
                                      return (
                                        <Grid
                                          item={true}
                                          key={index}
                                          title={subMedia?.name}
                                        >
                                          <a
                                            href={subMedia?.presignedUrl}
                                            target="_blank"
                                          >
                                            <StyledMedias
                                              src={subMedia?.presignedUrl}
                                            />
                                          </a>
                                        </Grid>
                                      );
                                    }
                                  )}
                              </StyledMediaContainer>
                            </div>
                            <StyledReplyDate>
                              {item.createDate}
                              <StyledApp src="/images/userIcon.png" />
                            </StyledReplyDate>
                          </StyledCommentSection>
                        </StyledProductContainer>
                      </Grid>
                    </StyledContainer>
                  );
                } else {
                  return (
                    <StyledContainer>
                      <Grid container={true} spacing={0}>
                        <StyledProductContainer item={true} xs={12}>
                          <StyledContents>
                            <div>
                              <StyledContent>{item.text}</StyledContent>
                            </div>
                            <div>
                              <StyledMediaContainer
                                container={true}
                                spacing={0}
                              >
                                {item.medias[0] !== null ? (
                                  <>
                                    {item?.medias &&
                                      item?.medias.map(
                                        (subMediaAdmin: any, index: number) => {
                                          return (
                                            <Grid
                                              item={true}
                                              key={index}
                                              title={subMediaAdmin?.name}
                                            >
                                              <a
                                                href={
                                                  subMediaAdmin?.presignedUrl
                                                }
                                                target="_blank"
                                              >
                                                <StyledMedias
                                                  src={
                                                    subMediaAdmin?.presignedUrl
                                                  }
                                                />
                                              </a>
                                            </Grid>
                                          );
                                        }
                                      )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </StyledMediaContainer>
                            </div>
                            <StyledReplyDic>
                              <StyledApp src="/images/myauction.ico" />
                              {item.createDate}
                            </StyledReplyDic>
                          </StyledContents>
                        </StyledProductContainer>
                      </Grid>
                    </StyledContainer>
                  );
                }
              })}
            </StyledAuctionEndedWrapper>
          )}
      </StyledChatBoxConatiner>
      {isLoading === true ? (
        <Grid>
          <form noValidate={true} onSubmit={handleFormSubmit}>
            <StyledReply>
              {fileName}
              <StyledButtonContainer>
                <StyledTextInputs>
                  <TextField
                    required
                    fullWidth
                    id="text"
                    disabled
                    type="text"
                    label=""
                    variant="outlined"
                    name="text"
                    value={text}
                    placeholder={t("common:inquiry:typeMessege")}
                    onChange={handleChange}
                  />
                  <StyledAttachmentImg
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input
                      {...getInputProps()}
                      multiple
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      disabled
                      id="com_logo"
                      type="file"
                      name="images"
                      onChange={handleChange}
                    />
                    <GelleryImage src="/images/attachment.jpeg" />
                  </StyledAttachmentImg>
                  <StyledLink style={{ color: "grey" }}>
                    <ReplyIcon
                      fontSize="inherit"
                      style={{ fontSize: "30px" }}
                    />
                  </StyledLink>
                </StyledTextInputs>
              </StyledButtonContainer>
            </StyledReply>
          </form>
        </Grid>
      ) : (
        <Grid>
          <form noValidate={true} onSubmit={handleFormSubmit}>
            <StyledReply>
              {fileName}
              <StyledButtonContainer>
                <StyledTextInputs>
                  <TextField
                    required
                    fullWidth
                    id="text"
                    type="text"
                    label=""
                    variant="outlined"
                    name="text"
                    value={text}
                    placeholder={t("common:inquiry:typeMessege")}
                    onChange={handleChange}
                  />
                  <StyledAttachmentImg
                    {...getRootProps({ className: "dropzone" })}
                  >
                    <input
                      {...getInputProps()}
                      multiple
                      accept="image/*"
                      id="com_logo"
                      type="file"
                      name="images"
                      onChange={handleChange}
                    />
                    <GelleryImage src="/images/attachment.jpeg" />
                  </StyledAttachmentImg>
                  {isButtonSubmit !== false ? (
                    <StyledLink
                      isSubmit={isButtonSubmit}
                      onSubmit={handleFormSubmit}
                    >
                      <ReplyIcon
                        fontSize="inherit"
                        style={{ fontSize: "30px" }}
                      />
                    </StyledLink>
                  ) : (
                    <StyledLink style={{ color: "grey" }}>
                      <ReplyIcon
                        fontSize="inherit"
                        style={{ fontSize: "30px" }}
                      />
                    </StyledLink>
                  )}
                </StyledTextInputs>
              </StyledButtonContainer>
            </StyledReply>
          </form>
        </Grid>
      )}

      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {isLoading === true && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
    </StyledComponent>
  );
};

export default BlockUserModal;
