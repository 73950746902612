import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import Grid from "@material-ui/core/Grid";
import Modal from '@material-ui/core/Modal';

import AuthService from '../../auth/AuthService';
import { joinAuctionUrl, joinedAuctionsUrl, participateUrl, searchAuctionFilterUrl, sendInviteeParticipationRequest } from '../../../common/config/app.endpoints';
import AuctionListView from './AuctionListView';
import CoreDataGridOfAuction from '../../core-grid/grid-data/CoreDataGridOfAuction';
import ViewParticipateModal from './ViewParticipateModal';
import Alert from '../../../common/elements/Alert';
import { buttonGradient, dateBackgroundGradient } from '../../../common/materials/LinearGradient';
import LoadingSpinner from '../../../common/elements/LoadingSpinner';
import { oAuth } from '../../../routes/basic/BasicAppRouter';
import moment from 'moment';
import { getTimeDifference, joinAuction } from '../../../common/GenericFunctions';
import BlockUserModal from '../BlockUser/BlockUserModal';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { connect } from 'react-redux';
import { reSetLiveAuctionPagination, reSetLiveCurrentApiStartCount } from '../../../redux/slice/commanPagination/commanPaginationSlice';

const StyledGrid = styled(Grid)`
`;

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

const StyledModal = styled(Modal)`
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;    
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }
`;

const StyledWrapper = styled(Grid)`
    outline: none;
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
        overflow-y: scroll;
        height: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 0.8em;
        overflow-y: scroll;
        height: 400px;
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 1em;
        width: 400px;
    }
`;

const StyledParticipateWrapper = styled(Grid)`
    text-align: center;
    // ${props => props.theme.breakpoints.between('xs', 'md')} {
    //     border-bottom: 2px solid ${(props) => props.theme.colors.base.lightGrey};
    //     padding: 1em ;
    // }
    // ${props => props.theme.breakpoints.between('lg', 'xl')} {
    //     border-right: 2px solid ${(props) => props.theme.colors.base.lightGrey};
    //     padding: 1em;
    // }
`;


interface IButtonProps {
    participate?: boolean;
    join?: boolean;
    disableParticipateButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1.2em;
    text-align: center;
    font-family: Calibre-SemiBold;
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    ${props => props.participate && css`
        width: 90%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 50px;
        &:hover {
            border: 1px solid ${(props) => props.theme.colors.base.textColor};
            cursor: pointer;
        }
    `}
    ${props => props.join && css`
        width: 40%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 30px;
        margin-left: 10px;
        &:hover {
            border: 1px solid ${(props) => props.theme.colors.base.textColor};
            cursor: pointer;
        }
    `}
    ${props => props.disableParticipateButton && css`
        width: 90%;
        padding: 0.5em 0.5em;
        padding-top: 0.8em;
        border-radius: 50px;
        cursor: default;
        background: ${dateBackgroundGradient};
    `}
`;



const StyledMessage = styled(Grid)`
    color:  ${(props) => props.theme.colors.base.red};
    font-size: 1em;
    margin-left: 1.2em;
`;


export interface IState {
    products: [];
    openToast: boolean;
    showAlertMessage: string;
    openProductModal: boolean;
    openParticipateModal: boolean;
    message: string;
    invitationCode: string;
    loading: boolean;
    disabledParticipateMessage: string;
    alertType: string;
    onSubmit: boolean;
    userBlock: boolean;
    isShowTendor:string;
}

export interface IProps extends IRouterComponentProps {
    t?: any;
    filteredAuctions: any;
    filteredValues: any;
    filteredParcelActions: any;
    filteredParcelValues: any;
    apiTimeout: (timeout: boolean) => void;
    test: any;
}

var auctionDate: string | string[] = '';
var auctionId: number | string = '';
let timezone: any = '';
var isCallJoinApi:boolean = false;
let shortBreakTimer: any;
class ProductsGrid extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
          products: [],
          openToast: false,
          showAlertMessage: "",
          openProductModal: false,
          openParticipateModal: false,
          message: "",
          invitationCode: "",
          loading: false,
          disabledParticipateMessage: "",
          alertType: "",
          onSubmit: false,
          userBlock: false,
          isShowTendor: '',
        };
    }

    auctionName = '';
    Auth = new AuthService();

    async componentDidMount() {
        this.auctionName = '';
        var filteredValues: any = localStorage.getItem("filteredValues");
        var filteredParcelValues: any = localStorage.getItem("filteredParcelValues");
        filteredValues = JSON.parse(filteredValues);
        filteredParcelValues = JSON.parse(filteredParcelValues);

        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            if (filteredValues !== null) {
                filteredValues.map((item: any, index: number) => {
                    if (item.name === "auctionId") {
                        filteredValues.splice(index, 1);
                    }
                })
                const data = {
                    "filters": {
                        "and": filteredValues
                    },
                    "page": {
                        "start": 0,
                        "count": 20,
                        "sort": {
                            "orders": [
                                {
                                    "property": "serialNo",
                                    "direction": "ASC"
                                }
                            ]
                        }
                    }
                }

                this.Auth.postRequest(searchAuctionFilterUrl, data, (status: any, response: any) => {
                    if (status === 200) {
                        this.setState({
                            loading: false,
                            products: response
                        })
                    }
                    else if (status === 403) {
                        this.setState({ userBlock: true })
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }
                })
            } else if (filteredParcelValues !== null) {
                filteredParcelValues.map((item: any, index: number) => {
                    if (item.name === "auctionId") {
                        filteredParcelValues.splice(index, 1);
                    }
                })
                const data = {
                    "filters": {
                        "and": filteredParcelValues
                    },
                    "page": {
                        "start": 0,
                        "count": 20,
                        "sort": {
                            "orders": [
                                {
                                    "property": "serialNo",
                                    "direction": "ASC"
                                }
                            ]
                        }
                    }
                }
                this.Auth.postRequest(searchAuctionFilterUrl, data, (status: any, response: any) => {
                    if (status === 200) {  
                        this.setState({
                            loading: false,
                            products: response
                        })
                    }
                    else if (status === 403) {
                        if (response[0].code === "KB404001") {
                            this.setState({ userBlock: true })
                        } else {
                            this.props.history.push('/');
                        }
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }
                })
            } else {
                getTimeDifference();
            }

            this.Auth.getRequest(joinedAuctionsUrl, (status: any, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        this.setState({
                            loading: false
                        })
                        localStorage.setItem("joinedAuctions", JSON.stringify(response.results));
                    }
                    else {
                        this.setState({
                            loading: false
                        })
                    }
                }
                else if (status === 403) { this.setState({ userBlock: true }) }
                else if (status === 505) {
                    this.props.apiTimeout(true);
                }
                else {
                    this.setState({
                        loading: false
                    })
                }
            })
        }
        this.getData();
    }

    value = () => {
        this.getData();
    }

    getData = () => {
        if (this.props.filteredAuctions !== null && this.props.filteredAuctions.length > 0) {
            this.setState({
                products: this.props.filteredAuctions
            })

        } else {
            if (this.props.filteredParcelActions !== null && this.props.filteredParcelActions.length > 0) {
                this.setState({
                    products: this.props.filteredParcelActions
                })
            }
        }

    }

    showListOfProduct = (productId: number | string, name: string, previewStartDate: string, state: string, auctionType: string) => {
        this.setState({
            isShowTendor : auctionType
        })
         this.props.test();
        localStorage.setItem("auctionState", state);
        let previewTime = moment.utc(previewStartDate);
        let hour: any = '';
        let time: any = '';
        let minutes: any = '';
        if (timezone !== null) {
            timezone = localStorage.getItem("timezoneName");
        }
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== 'Invalid date') {
            let splitDateTime = resultFormatter.split("T", 2);
            let splitTime: any = '';
            if (splitDateTime[1].includes("+")) {
                splitTime = splitDateTime[1].split("+", 2);
            }
            else {
                splitTime = splitDateTime[1].split("-", 2);
            }
            auctionDate = splitDateTime[0];
            time = splitTime[0];
            let dateTime = `${auctionDate} ${time}`;
            let start: any = moment(dateTime);
            timezone = localStorage.getItem("timezoneName");
            let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
            hour = start.diff(end, 'hours');
            minutes = start.diff(end, 'minutes');
        }
        this.setState({
            message: ''
        })
        this.auctionName = name;
        auctionId = productId;
        var joinedAuctions: any = localStorage.getItem("joinedAuctions");
        joinedAuctions = JSON.parse(joinedAuctions);
        if (joinedAuctions !== null) {
            if (joinedAuctions.length > 0) {
                joinedAuctions.filter((items: any) => {
                    if (items.perId === productId) {
                        localStorage.setItem("auctioneerUserId", items.auctionUserId);
                        isCallJoinApi = false
                        localStorage.removeItem("filteredValues");
                        localStorage.setItem("filteredValues", JSON.stringify(this.props.filteredValues));
                        localStorage.removeItem("filteredParcelValues");
                        localStorage.removeItem("dropDownFilterVal");
                        localStorage.setItem("filteredParcelValues", JSON.stringify(this.props.filteredParcelValues));

                        if(auctionType !== undefined && auctionType !== 'Live'){
                            this.props.history.push({
                              pathname: `/preview/tendor/products/${auctionId}/${items.auctionUserId}/${this.auctionName}/${auctionDate}`,
                            });
                        }else{
                            this.props.history.push({ pathname: `/preview/products/${productId}/${this.auctionName}/${auctionDate}` })    
                        }
                    }
                    else {
                        if (state === 'NotStarted' && hour <= 0 && minutes < 0) {
                        } else {
                            isCallJoinApi = true
                        }
                    }
                })
            }
            else {
                isCallJoinApi = true
            }
        }
        else {
            isCallJoinApi = true
        }
        if(isCallJoinApi === true){
            this.onClickJoin(auctionType)
        }
    }

    onClickJoin(auctionType: string) {
        const { t } = this.props;
        localStorage.removeItem("dropDownFilterVal");
        this.setState({
          onSubmit: true,
          openToast: false,
        });     
        this.props.test();   
        const data = {
            'code': ""
        }
        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            this.Auth.postRequest(`${joinAuctionUrl}/${auctionId}/join`, data, (status: number, response: any) => {
                if (status === 200) {
                    this.setState({
                      openProductModal: false,
                      onSubmit: false,
                      openToast: false,
                    });
                    joinAuction();
                    var auctionUserId = response.id;
                    shortBreakTimer = response.resumeDateTime;
                     if (this.props.filteredValues.length > 0 || this.props.filteredValues !== null) {
                                localStorage.removeItem("filteredValues");
                                localStorage.setItem("filteredValues", JSON.stringify(this.props.filteredValues));
                                localStorage.setItem("auctioneerUserId", auctionUserId);
                                 if(auctionType !== undefined && auctionType !== 'Live'){
                                    this.props.history.push({
                                      pathname: `/preview/tendor/products/${auctionId}/${auctionUserId}/${this.auctionName}/${auctionDate}`,
                                      state: {
                                        filteredValues:
                                          this.props.filteredValues,
                                      },
                                    });
                                 }else{
                                    this.props.history.push({
                                    pathname: `/preview/products/${auctionId}/${this.auctionName}/${auctionDate}`,
                                    state: {
                                        filteredValues: this.props.filteredValues
                                    }
                                })
                                 }
                            } else if (this.props.filteredParcelValues.length > 0 || this.props.filteredParcelValues !== null) {
                                localStorage.removeItem("filteredParcelValues");
                                localStorage.setItem("filteredParcelValues", JSON.stringify(this.props.filteredParcelValues));                                

                                if(auctionType !== undefined && auctionType !== 'Live'){
                                    this.props.history.push({
                                      pathname: `/preview/tendor/products/${auctionId}/${auctionUserId}/${this.auctionName}/${auctionDate}`,
                                      state: {
                                        filteredParcelValues:
                                          this.props.filteredParcelValues,
                                      },
                                    });
                                }else{
                                   this.props.history.push({
                                     pathname: `/preview/products/${auctionId}/${this.auctionName}/${auctionDate}`,
                                     state: {
                                       filteredParcelValues:
                                         this.props.filteredParcelValues,
                                     },
                                   });
                                }
                            }
                            else {
                                localStorage.removeItem("filteredValues");
                                localStorage.setItem("filteredValues", "null");
                                localStorage.removeItem("filteredParcelValues");
                                localStorage.setItem("filteredParcelValues", "null");
                                localStorage.removeItem("dropDownFilterVal");
                                if(auctionType !== undefined && auctionType !== 'Live'){
                                    this.props.history.push({
                                      pathname: `/preview/tendor/products/${auctionId}/${auctionUserId}/${this.auctionName}/${auctionDate}`
                                      
                                    });
                                }else{
                                   this.props.history.push({
                                     pathname: `/preview/products/${auctionId}/${this.auctionName}/${auctionDate}`,
                                   });
                                }
                            }
                }
                else if (status > 200 && status < 500) {
                    if (status === 403) {
                        this.setState({ userBlock: true })
                    }

                    if (response[0].code !== undefined) {
                        if (response[0].code === "KB400003") {
                          this.setState({
                            onSubmit: false,
                            openProductModal: true,
                          });
                        } else if (response[0].code === "KB409004") {
                          this.setState({
                            alertType: "error",
                            openToast: true,
                            showAlertMessage: `${t(
                              "common:allValidations:participate:alreadySentInviationMail"
                            )}`,
                            openProductModal: false,
                          });
                        }
                    }
                    else {
                        this.setState({
                            message: `${t('common:allValidations:join:mailSentFailed')}`,
                            invitationCode: '',
                            onSubmit: false,
                            openProductModal:false
                        })                        
                    }
                }
                else if (status === 500) {
                    
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: response[0].message,
                        loading: false,
                        openProductModal:false,
                        onSubmit:false
                    })
                }
                else {
                    this.setState({
                        alertType: "error",
                        openToast: true,
                        showAlertMessage: `${t('common:somethingWentWrong')}`,
                        loading: false,
                        openProductModal:false,
                        onSubmit:false
                    })
                }
            })
        }
    }

    onCloseModal = () => {
        this.setState({
            message: '',
            openProductModal: false,
            openParticipateModal: false
        })
    }

    onClickParticipate() {
        const { t } = this.props;
        this.setState({
          onSubmit: true,
          openToast: false,
        });
        if (!oAuth()) {
            this.props.history.push('/');
        } else {

            var urlString = ""

            let userType = localStorage.getItem("userType");

            urlString = userType === "SUBSCRIBED" || "SUB_DOMAIN" ? participateUrl : sendInviteeParticipationRequest

            this.Auth.postRequest(`${urlString}?auctionId=${auctionId}`, 'data', (status: any, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        this.setState({
                            openParticipateModal: true,
                            openProductModal: false,
                            onSubmit: false,
                            openToast: false,
                        });
                    }
                }
                else if (status > 200 && status < 500) {
                    if (status === 403) {
                        this.setState({ userBlock: true })
                    }
                    if (response[0].code !== undefined) {
                        if (response[0].code === "KB409003") {
                            this.setState({
                                message: `${t('common:allValidations:participate:alreadySentParticipationMail')}`,
                                onSubmit: false,
                                openProductModal:false,
                                alertType: "error",
                                openToast: true,
                                showAlertMessage: `${t(
                    "common:allValidations:participate:alreadySentParticipationMail"
                  )}`,
                            })
                        }
                        else if (response[0].code === "KB409004") {
                            this.setState({
                                onSubmit: false,
                                openProductModal:false,
                                alertType: "error",
                                openToast: true,
                                showAlertMessage: `Inviation Mail already Sent. `,
                            })
                        }
                    }
                    else if (status === 404) {
                        this.setState({
                            onSubmit: false,
                            message: `${t('common:invalidRequest')}`,
                            invitationCode: ''
                        })
                    }
                    else {
                        this.setState({
                            onSubmit: false,
                            message: `${t('common:invalidRequest')}`,
                        })
                    }
                }
                else {
                    this.setState({
                        onSubmit: false,
                        openParticipateModal: false,
                        message: response[0].message
                    })
                }
            })
        }
    }

    openParticipate() {
        this.setState({
            onSubmit: false,
            openParticipateModal: false
        })
    }

    handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'invitationCode') { this.setState({ invitationCode: e.target.value }) }
    }

    blockCloseModal = () => {
        this.Auth.logout();
        this.props.history.replace('/');
    }

    render() {
        const { t } = this.props;
        return (
            <StyledGrid container={true} spacing={0}>
                {this.state.loading === true ?
                    <div className="loader">
                        <LoadingSpinner />
                    </div>
                    :
                    <Grid item xs={12}>
                        {(this.props.filteredAuctions !== null || this.props.filteredParcelActions != null) &&
                            <div>
                                {this.props.filteredAuctions.length > 0 ?
                                    <CoreDataGridOfAuction t={t} data={this.props.filteredAuctions}>
                                        <AuctionListView t={t} showListOfProduct={this.showListOfProduct} value={this.value} />
                                    </CoreDataGridOfAuction>
                                    :
                                    <div>
                                        {(this.props.filteredParcelActions !== null && this.props.filteredParcelActions.length > 0) ?
                                            <CoreDataGridOfAuction t={t} data={this.props.filteredParcelActions}>
                                                <AuctionListView t={t} showListOfProduct={this.showListOfProduct} value={this.value} />
                                            </CoreDataGridOfAuction>
                                            :
                                            <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
                                        }
                                    </div>

                                }
                            </div>
                        }
                    </Grid>
                }
                {this.state.openProductModal === true &&
                    <StyledModal open={this.state.openProductModal} onClose={this.onCloseModal}>
                        <StyledWrapper container spacing={2}>
                            <StyledMessage item xs={12}>
                                {this.state.message}
                            </StyledMessage>
                            <StyledParticipateWrapper item md={12} xs={12} lg={12}>
                                <StyledTextWrapper>
                                    {t('common:preview:modal:participateDontHaveCode')}
                                    {t('common:preview:modal:forparticipation')}
                                </StyledTextWrapper>
                                {
                                    this.state.onSubmit === false ?
                                        <StyledButton participate={true} onClick={() => this.onClickParticipate()}>{t('common:button:participate')}</StyledButton>
                                        :
                                        <Grid>
                                            <StyledButton disableParticipateButton={this.state.onSubmit}>{t('common:button:participate')}</StyledButton>
                                        </Grid>
                                }
                            </StyledParticipateWrapper>
                        </StyledWrapper>
                    </StyledModal>
                }
                {this.state.openParticipateModal === true &&
                    <StyledModal open={this.state.openParticipateModal} onClose={this.onCloseModal}>
                        <ViewParticipateModal openParticipateModal={() => this.openParticipate()} t={t} />
                    </StyledModal>
                }
                {this.state.openToast === true &&
                    <Alert showAlertMessage={this.state.showAlertMessage} alertType={this.state.alertType} />
                }
                { this.state.userBlock === true &&
                    <StyledModal open={this.state.userBlock}>
                        <BlockUserModal t={t} {...this.props} closeModal={this.blockCloseModal} />
                    </StyledModal>
                }
            </StyledGrid>
        )
    }
}

const mapDispatchToProps = (dispatch: any) => ({
  test: () => {
    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());
  },
});
export default connect(null, mapDispatchToProps)(ProductsGrid);