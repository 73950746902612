const FlourList = [
  {
    name: "Faint",
    abbreviations: "F",
  },
  {
    name: "None",
    abbreviations: "N",
  },
  {
    name: "Medium Green",
    abbreviations: "MG",
  },
  {
    name: "Medium Red",
    abbreviations: "MR",
  },
  {
    name: "Medium Orange",
    abbreviations: "MO",
  },
  {
    name: "Medium Yellow",
    abbreviations: "MY",
  },
  {
    name: "Medium White",
    abbreviations: "MW",
  },
  {
    name: "Medium Blue",
    abbreviations: "MB",
  },
  {
    name: "Slight Green",
    abbreviations: "SIG",
  },
  {
    name: "Slight Red",
    abbreviations: "SIR",
  },
  {
    name: "Slight Orange",
    abbreviations: "SIO",
  },
  {
    name: "Slight Yellow",
    abbreviations: "SIY",
  },
  {
    name: "Slight White",
    abbreviations: "SIW",
  },
  {
    name: "Slight Blue",
    abbreviations: "SIB",
  },
  {
    name: "Strong Green",
    abbreviations: "SG",
  },
  {
    name: "Strong Red",
    abbreviations: "SR",
  },
  {
    name: "Strong Orange",
    abbreviations: "SO",
  },
  {
    name: "Strong Yellow",
    abbreviations: "SY",
  },
  {
    name: "Strong White",
    abbreviations: "SW",
  },
  {
    name: "Strong Blue",
    abbreviations: "SB",
  },
  {
    name: "Very Slight Green",
    abbreviations: "VSIG",
  },
  {
    name: "Very Slight Red",
    abbreviations: "VSIR",
  },
  {
    name: "Very Slight Orange",
    abbreviations: "VSIO",
  },
  {
    name: "Very Slight Yellow",
    abbreviations: "VSIY",
  },
  {
    name: "Very Slight White",
    abbreviations: "VSIW",
  },
  {
    name: "Very Slight Blue",
    abbreviations: "VSIB",
  },
  {
    name: "Very Strong Green",
    abbreviations: "VSG",
  },
  {
    name: "Very Strong Red",
    abbreviations: "VSR",
  },
  {
    name: "Very Strong Orange",
    abbreviations: "VSO",
  },
  {
    name: "Very Strong Yellow",
    abbreviations: "VSY",
  },
  {
    name: "Very Strong White",
    abbreviations: "VSW",
  },
  {
    name: "Very Strong Blue",
    abbreviations: "VSB",
  },
];

const ShapeList = [
  {
    name: "Asscher",
    abbreviations: "A",
  },
  {
    name: "Princess",
    abbreviations: "PR",
  },
  {
    name: "Marquize",
    abbreviations: "MQ",
  },
  {
    name: "Other",
    abbreviations: "O",
  },
  {
    name: "Trilliant",
    abbreviations: "TRL",
  },
  {
    name: "Radiant",
    abbreviations: "RAD",
  },
  {
    name: "Oval",
    abbreviations: "OV",
  },
  {
    name: "Heart shape",
    abbreviations: "HS",
  },
  {
    name: "cushion Brilliant",
    abbreviations: "CB",
  },
  {
    name: "Tapered Baguette",
    abbreviations: "TBAG",
  },
  {
    name: "Baguette",
    abbreviations: "BG",
  },
  {
    name: "Emerald Cut",
    abbreviations: "EM",
  },
  {
    name: "Peer shape",
    abbreviations: "PS",
  },
  {
    name: "Round brilliant",
    abbreviations: "RB",
  },
  {
    name: "Round",
    abbreviations: "RD",
  },
  {
    name: "Round brilliant modified",
    abbreviations: "RMB",
  },
  {
    name: "Rectangular",
    abbreviations: "RCT",
  },
  {
    name: "Unknown",
    abbreviations: "X",
  },
  {
    name: "Square",
    abbreviations: "SQ",
  },
  {
    name: "Fancy Mix",
    abbreviations: "FM",
  },
  {
    name: "CAR",
    abbreviations: "CAR",
  },
  {
    name: "CAR&BG",
    abbreviations: "CAR&BG",
  },
  {
    name: "Princess",
    abbreviations: "PR",
  },
  {
    name: "Marquize",
    abbreviations: "MQ",
  },
  {
    name: "Trillant",
    abbreviations: "TRL",
  },
  {
    name: "Radiant",
    abbreviations: "RAD",
  },
  {
    name: "Oval",
    abbreviations: "OV",
  },
  {
    name: "Heart shape",
    abbreviations: "HS",
  },
  {
    name: "Cushion brilliant",
    abbreviations: "CB",
  },
  {
    name: "Tapered Baguette",
    abbreviations: "TBAG",
  },
  {
    name: "Baguette",
    abbreviations: "BG",
  },
  {
    name: "Emerald cut",
    abbreviations: "EM",
  },
  {
    name: "Peer shape",
    abbreviations: "PS",
  },
  {
    name: "Round Brilliant",
    abbreviations: "RB",
  },
  {
    name: "Round",
    abbreviations: "RD",
  },
  {
    name: "Round Brilliant Modified",
    abbreviations: "RMB",
  },
  {
    name: "Rectangular",
    abbreviations: "RCT",
  },
  {
    name: "Unknown",
    abbreviations: "X",
  },
  {
    name: "Square",
    abbreviations: "SQ,TP",
  },
  {
    name: "RoseCut",
    abbreviations: "RC",
  },
  {
    name: "SingleCut",
    abbreviations: "SC",
  },
];

let ColorList = [
  {
    name: "Fancy",
    abbreviations: "FY",
  },
  {
    name: "D",
    abbreviations: "D",
  },
  {
    name: "E",
    abbreviations: "E",
  },
  {
    name: "F",
    abbreviations: "F",
  },
  {
    name: "G",
    abbreviations: "G",
  },

  {
    name: "H",
    abbreviations: "H",
  },
  {
    name: "I",
    abbreviations: "I",
  },
  {
    name: "J",
    abbreviations: "J",
  },
  {
    name: "K",
    abbreviations: "K",
  },
  {
    name: "L",
    abbreviations: "L",
  },

  {
    name: "Under N(XXX)",
    abbreviations: "UN",
  },
  {
    name: "Under S(XXX)",
    abbreviations: "US",
  },
  {
    name: "Under N",
    abbreviations: "UN",
  },
  {
    name: "Under S",
    abbreviations: "US",
  },
  {
    name: "Pink",
    abbreviations: "p",
  },
  {
    name: "Brown",
    abbreviations: "B",
  },
  {
    name: "G,F",
    abbreviations: "GF",
  },
  {
    name: "Yellow",
    abbreviations: "Y",
  },
  {
    name: "White",
    abbreviations: "W",
  },
  {
    name: "Natts",
    abbreviations: "N",
  },
  {
    name: "TLB",
    abbreviations: "TLB",
  },
  {
    name: "BG",
    abbreviations: "BG",
  },
  {
    name: "LB",
    abbreviations: "LB",
  },
  {
    name: "TTLB",
    abbreviations: "TTLB",
  },
  {
    name: "TP",
    abbreviations: "TP",
  },
  {
    name: "TLC",
    abbreviations: "TLC",
  },
  {
    name: "TTLC",
    abbreviations: "TTLC",
  },
  {
    name: "OWLB",
    abbreviations: "OWLB",
  },
];

let CutGradeList =[
  {
    name: "Excellent",
    abbreviations: "EX",
  },
  {
    name: "Fair",
    abbreviations: "FR",
  },
  {
    name: "Good",
    abbreviations: "GD",
  },
  {
    name: "Very Good",
    abbreviations: "VG",
  },
  {
    name: "Poor",
    abbreviations: "PR",
  },
  {
    name: "****",
    abbreviations: "****",
  },
]
export { FlourList, ShapeList, ColorList, CutGradeList };


