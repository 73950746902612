import { Grid, Modal } from "@material-ui/core";
import styled, { css } from "styled-components";
import { backgroundGradient } from "../../../../common/materials/LinearGradient";

export const StyledCardContainer = styled(Grid)`
  flex-grow: 1;
  flex-basis: 12%;
  border: 1px solid #e4e4e4;
  margin: 10px 25px 25px 25px;
  color: ${(props) => props.theme.colors.base.grey};
  .centerContent {
    text-align: center;
  }
  .borderRightBlue {
    border-right: 1px solid ${(props) => props.theme.colors.base.grey};
  }
  .borderBottomBlue {
    border-bottom: 1px solid ${(props) => props.theme.colors.base.grey};
  }
  .secondaryLabel {
    color: ${(props) => props.theme.colors.base.black};
    font-size: 0.8em;
  }
  .notesContainer {
    padding-bottom: 20px;
  }
  .posRelative {
    position: relative;
  }
  .posAbsolute {
    position: absolute;
    top: -10px;
    width: 100%;
    background: ${(props) => props.theme.colors.base.white};
  }
  .wishListContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
  }
  .display {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .fontSize {
    font-size: 1.6em;
  }
  .labelFontSize {
    font-size: 18px;
  }
  .secondaryLabelFontSize {
    font-size: 16px;
  }
  .notDisablebutton {
    background: white;
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
`;

export const StyledGrid = styled(Grid)`
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledWishlistIcon = styled("img")`
  width: 1em;
  height: 0.9em;
`;

export const StyledWatchListContainer = styled.div`
  padding: 10px;
`;

export const StyledWrapper = styled(Grid)`
  text-align: center;
  max-width: 1000px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    max-width: -webkit-fill-available;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    max-width: -webkit-fill-available;
  }
`;

export const StyledGridWrapper = styled(Grid)`
  width: 100%;
  background: ${backgroundGradient};
  padding: 10px;
  display: flex;
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledCurrentProductGridWrapper = styled(Grid)`
  font-size: 12px;
  background: ${backgroundGradient};
`;

export const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;

export const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
`;
export const ProductDescriptionContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
`;

export const StyledProductImageContainer = styled(Grid)`
  padding: 8px;
  min-height: 170px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    min-height: 130px;
  }
`;

export const StyledSeriesNumberContainer = styled(Grid)`
  padding: 5px;
  text-align: right;
`;

export const StyledDetailTitle = styled(Grid)`
  padding: 10px;
  font-size: 16px;
`;

export const StyledInformationDetailContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
  .centerValue {
    display: flex;
    align-items: center;
  }
`;

export const StyledNotesContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 0.8em;
  float: left;
`;

export const StyledNotesGridWrapper = styled(Grid)`
  display: flex;
  overflow-y: auto;
  max-height: 85px;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
  }
`;

export const StyledPriceContainer = styled(Grid)`
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding-top: 0em;
    padding-bottom: 0em;
    flex-direction: column;
  }
  @media (width: 540px) {
    flex-direction: row;
  }
`;

export const StyledNoteTextInputDiv = styled.div`
  color: ${(props) => props.theme.colors.base.grey};
  padding: 0em 0.5em;
  font-size: 0.8em;
`;

interface IChangeColorProps {
  changeColor?: String;
}

export const StyledCurrentBid = styled.div<IChangeColorProps>`
  color: ${(props) => props.theme.colors.base.black};
  background: ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.changeColor === "black" &&
    css`
      color: ${(props) => props.theme.colors.base.black};
    `}
  ${(props) =>
    props.changeColor === "green" &&
    css`
      color: ${(props) => props.theme.colors.base.green};
    `}

    ${(props) =>
    props.changeColor === "red" &&
    css`
      color: ${(props) => props.theme.colors.base.red};
    `}
`;

export const StyledAmountInput = styled.div`
  color: ${(props) => props.theme.colors.base.grey};
  padding: 0em 0.5em;
  font-size: 0.8em;
  float: left;
`;

export const StyledBiddingPriceContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 10px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${backgroundGradient};
  text-align: center;
  cursor: pointer;
`;

export const StyledDisableButton = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 10px;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${backgroundGradient};
  text-align: center;
  cursor: default;
  background: ${(props) => props.theme.colors.base.lightGrey};
`;

export const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

export const StyledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledBreadkPopup = styled(Grid)`
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  z-index: 1000;
  top: 10%;
  height: 100%;
`;
export const StyledComponent = styled(Grid)`
  outline: none;
  display: flex;
  justify-content: center;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

export const StyledWrapperPopUp = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 100%;
  text-align: center;

  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 275px;
    padding: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 700px;
  }
`;
export const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
export const StyledTextWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 1em;
  font-family: Calibre-Regular;
  padding: 0.5em;
  justify-content: center;
  margin-top: 0.5em;
  color: ${(props) => props.theme.colors.base.textColor};

  .secondLine {
    font-size: 1.5em;
    color: ${(props) => props.theme.colors.base.red};
  }

  .firstLine {
    font-size: 1.3em;
    color: ${(props) => props.theme.colors.base.red};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    .secondLine {
      font-size: 1em;
    }

    .firstLine {
      font-size: 0.8em;
    }
  }
`;

export const StyledTimer = styled.div`
  font-size: 2.5em;
  font-family: "Calibre-Regular";

  .countdownText {
    font-weight: bold;
    width: 100%;
    text-align: center;
  }
  .dateTimeStyle {
    width: 100%;
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
    margin-top: 0.5em;
  }
`;
export const StyledImage = styled.img`
  height: 80%;
  width: 80%;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 80%;
    width: 80%;
    margin: 1em 0em;
  }
`;
