import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Styled, { css } from 'styled-components';
import FieldSet from '../../../common/elements/FieldSet';
import { StyledButton, StyledWrapper } from '../../../common/materials/GenericStyleComponent';
import AuthService from '../../auth/AuthService';
import { searchJewelleryAuctionFilterUrl } from '../../../common/config/app.endpoints';
import { buttonGradient } from '../../../common/materials/LinearGradient';
import LoadingSpinner from '../../../common/elements/LoadingSpinner';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { oAuth } from '../../../routes/basic/BasicAppRouter';
import JewelleryType from './JewelleryType';
import JewelleryCondition from './JewelleyCondition';
import { StyledMessage } from './ProductsFilter';
import JewlleryGradingList from "./JewlleryGradingList";


const StyledFilterGrid = styled(Grid)``;

const StyledButtonContainer = styled(Grid)`
    text-align: center
`;

const StyledGridWrapper = styled.div`
    margin-top: 1em;
    input {
        color: ${(props) => props.theme.colors.base.black};
    }
`;

const StyledFilterPriceWrapper = styled(Grid)`
`;

const StyledGrid = styled(Grid)`
    padding: 5px;
`;

const StyledLabel = styled.div`
    text-align: left;
    font-size: 0.8em;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    font-weight: bold;
`;

const StyledValue = styled.input`
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    padding: 7px;
    width: 100%;
    border-radius: 2px;
    color: ${(props) => props.theme.colors.base.grey};
    font-size: 0.8em;
`;

const StyledHorizontalList = Styled.ul`
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    width: 100%;
    text-align: center;
`;

interface IName {
    isSelected: any;
}

const StyledName = Styled.div<IName>`
    border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border-radius: 2px;
    font-size: 1em;
    padding: 0.2em 0.5em;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    cursor: pointer;
    &:hover {
        background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
        background: ${buttonGradient}
        color: ${(props) => props.theme.colors.base.white};
    }
    ${props => props.isSelected && css`
        background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
        background: ${buttonGradient};
        color: ${(props) => props.theme.colors.base.white};
    `}
`;


const StyledShapeImage = Styled('img')`
    height: 2.8em;
    width: 2.8em;
`;

const StyledShapeName = Styled.div`
    font-size: 0.8em;
`;

const StyledShapeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    width: 110px;
    align-items: center;
    justify-content: center;
    @media (width: 320px) {
    width: 90px;
  }
`;


export const StyledWrapperParcelType = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    align-items: center;
    justify-content: center;
`;
interface IProps extends IRouterComponentProps {
    t: any;
    filterJewelleryAuction: any;
    auctionId: string | null;
    annualAuction?: boolean;
    isPreviewPage?: boolean;
}

interface IState {
    startingCaratPrice: string;
    endingCaratPrice: string;
    startingTotalPrice: string;
    endingTotalPrice: string;
    opened: boolean;
    temp: any;
    loadData: boolean;
    demo: any;
    message: string;
}

var filteredJewelleryValues: any = '';

export default class JewelleryFilter extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            startingCaratPrice: '',
            endingCaratPrice: '',
            startingTotalPrice: '',
            endingTotalPrice: '',
            opened: false,
            temp: [],
            loadData: false,
            demo: [],
            message: ''
        }
    }

    Auth = new AuthService();
    array: any = [];

    componentDidMount() {
        filteredJewelleryValues = localStorage.getItem("filteredJewelleryValues");
        filteredJewelleryValues = JSON.parse(filteredJewelleryValues);

        if (filteredJewelleryValues === null) {
            JewelleryType.map((jewellery: any, index: number) => {
                JewelleryType[index].selectedValue = false;
                return this.setState({
                    temp: null
                })
            });
            JewelleryCondition.map((color: any, index: number) => {
                return JewelleryCondition[index].selectedValue = false;
            });
            JewlleryGradingList.map((grading: any, index: number) => {
                return (JewlleryGradingList[index].selectedValue = false);
            });
        }
        else {
            filteredJewelleryValues.map((item: any) => {
                if (item.name === 'caratMinimumPrice') {
                    if (item.gte !== undefined) {
                        this.setState({ startingTotalPrice: item.gte })
                    }
                    if (item.lte !== undefined) {
                        this.setState({ endingTotalPrice: item.lte })
                    }
                }
                if (item.name === 'caratMinimumPrice') {
                    if (item.gte !== undefined) {
                        this.setState({ startingCaratPrice: item.gte })
                    }
                    if (item.lte !== undefined) {
                        this.setState({ endingCaratPrice: item.lte })
                    }
                }
            })

        }
    }

    jewelleryName(jewellery: any, index: number) {
        JewelleryType[index].selectedValue = !jewellery.selectedValue;
        this.setState({
            temp: null
        })
    }

    selectedCondition = (condition: any, index: number) => {
        JewelleryCondition[index].selectedValue = !condition.selectedValue;
        this.setState({
            temp: null
        })
    }

    selectedGrading(grading: any, index: number) {
        JewlleryGradingList[index].selectedValue = !grading.selectedValue;
        this.setState({
            temp: null
        })
    }

    handleChange = (e: any) => {
        const targeName = e.target.name;
        const targetValue = e.target.value;
        if (targeName === 'fromPrice') { this.setState({ startingTotalPrice: targetValue }) }
        if (targeName === 'toPrice') { this.setState({ endingTotalPrice: targetValue }) }
    }

    handleSubmit(e: any) {
        e.preventDefault();
        this.setState({
            loadData: true,
            message: "",
        });

        let startingTotalPrice = parseFloat(this.state.startingTotalPrice);
        let endingTotalPrice = parseFloat(this.state.endingTotalPrice);

        let localArray: any = [];

        let jewellery: any = JewelleryType
            .filter((item: any) => {
                return item.selectedValue === true;
            })
            .map((item: any) => item.value);


        let condition: any = JewelleryCondition
            .filter((item: any) => {
                return item.selectedValue === true;
            })
            .map((item: any) => {
                return item.name
            });

        let grading: any = JewlleryGradingList
            .filter((item: any) => {
                return item.selectedValue === true;
            })
            .map((item: any) => item.name);

        if (jewellery.length > 0) {
            localArray.push({ "match": "in", "name": "jewelleryDetails.jewelleryType", "value": jewellery })
        }
        if (condition.length > 0) {
            localArray.push({
              match: "in",
              name: "jewelleryDetails.jewelleryCondition",
              value: condition,
            });
        }

        if (grading.length > 0) {
            localArray.push({ "match": "in", "name": "lab", "value": grading })
        }

        if (this.state.endingTotalPrice !== '' && this.state.startingTotalPrice !== '') {
            if (
                parseInt(this.state.endingTotalPrice) <
                parseInt(this.state.startingTotalPrice)
            ) {
                this.setState({
                    message: `"To" price should be greater than "From" price`,
                });
            } else if (
                parseInt(this.state.endingTotalPrice) ===
                parseInt(this.state.startingTotalPrice)
            ) {
                this.setState({
                    message: `"To" price should not be equal "From" price`,
                });
            }
            else if (
                parseInt(this.state.endingTotalPrice) >
                parseInt(this.state.startingTotalPrice)
            ) {
                localArray.push({ "match": "between", "name": "caratMinimumPrice", "gte": startingTotalPrice, "lte": endingTotalPrice })
            }
        }

        let values = localArray;

        localArray.map((item: any, index: number) => {
            if (item.name === "auctionId") {
                localArray.splice(index, 1);
            }
        })
        let andArr = [];
        andArr.push({
            "match": "exact",
            "name": "parcelType",
            "value": "Jewellery"
        })
        const data = {
            "filters": {
                "or": localArray,
                "and": andArr
            },
            "page": {
                "start": 0,
                "count": 20,
                "sort": {
                    "orders": [
                        {
                            "property": this.props.annualAuction === undefined ? "serialNo" : "productEndDate",
                            "direction": "ASC"
                        }
                    ]
                }
            }
        }

        if (this.props.isPreviewPage && (this.props.auctionId === null || filteredJewelleryValues === null)) {
            if (localArray.length > 0) {
                if (!oAuth()) {
                    this.props.history.push('/');
                } else {
                    this.Auth.postRequest(searchJewelleryAuctionFilterUrl, data, (status: any, response: any) => {
                        if (status === 200) {
                            this.props.filterJewelleryAuction(response, true, values)
                            this.setState({
                                loadData: false,
                                message: ''
                            })
                        }
                        else {
                            this.setState({
                                loadData: false
                            })
                        }
                    })
                }
            }
            else {
                this.setState({ 
                    loadData: false
                })
            }
        }
        else {
            this.setState({
                loadData: false,
                message: ''
            })
            this.props.filterJewelleryAuction('', true, values);
        }

    }

    resetValue(e: any) {
        e.preventDefault();
        JewelleryCondition.map((color: any, index: number) => {
            return JewelleryCondition[index].selectedValue = false;
        })
        JewelleryType.map((jewellery: any, index: number) => {
            return JewelleryType[index].selectedValue = false;
        })
        JewlleryGradingList.map((grading: any, index: number) => {
            return (JewlleryGradingList[index].selectedValue = false);
        });
        this.setState({
            loadData: false,
            startingTotalPrice: '',
            endingTotalPrice: '',
            message: ''
        })
        localStorage.removeItem("filteredValues");
        localStorage.removeItem("filteredJewelleryValues");
        this.props.filterJewelleryAuction(null, false, null)
    }

    render() {
        const { t } = this.props;
        return (
            <StyledGridWrapper>
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <StyledFilterGrid container={true} spacing={2}>
                        <Grid item={true} xs={12}>
                            <FieldSet legend={t('common:preview:filterName:jewellery')}>
                                <StyledHorizontalList>
                                    {JewelleryType.map((jewellery, index) => {
                                        return <StyledShapeWrapper key={index} onClick={() => this.jewelleryName(jewellery, index)}>
                                            <div>
                                                {jewellery.selectedValue ? (
                                                    <StyledShapeImage src={`/images/jewelleryShapes/${jewellery.image.colored}`} />
                                                ) : (
                                                    <StyledShapeImage src={`/images/jewelleryShapes/${jewellery.image.grey}`} />
                                                )}
                                            </div>
                                            <StyledShapeName>{jewellery.name}</StyledShapeName>
                                        </StyledShapeWrapper>
                                    })}
                                </StyledHorizontalList>
                            </FieldSet>
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6} style={{ marginTop: "15px" }}>
                            <FieldSet legend={t('common:preview:filterName:jewelleryCondition')}>
                                <StyledHorizontalList>
                                    {JewelleryCondition.map((condition: any, index: number) => {
                                        return <StyledWrapper key={index}>
                                            <StyledName isSelected={condition.selectedValue} onClick={() => this.selectedCondition(condition, index)}>{condition.name}</StyledName>
                                        </StyledWrapper>
                                    })}
                                </StyledHorizontalList>
                            </FieldSet>
                        </Grid>
                        <Grid item={true} xs={12} sm={6} md={6} lg={4} xl={4}>
                            <Grid item={true} xs={12}>
                                <StyledMessage>
                                    {this.state.message}
                                </StyledMessage>
                            </Grid>
                            <FieldSet legend={t('common:preview:filterName:price')}>
                                <StyledFilterPriceWrapper container={true} spacing={0}>
                                    <Grid item={true} xs={12}>
                                        <StyledFilterPriceWrapper container={true} spacing={0}>
                                            <StyledGrid item={true} xs={12}>
                                                <StyledLabel className="fontBold">{t('common:preview:filters:price:total')}</StyledLabel>
                                            </StyledGrid>
                                            <StyledGrid item={true} xs={6} md={6}>
                                                <StyledValue min='0' max='1000000' type="number" step="any" name="fromPrice" id="fromPrice" value={this.state.startingTotalPrice} placeholder={(`${t('common:preview:filters:from')}`)} onChange={this.handleChange} />
                                            </StyledGrid>
                                            <StyledGrid item={true} xs={6} md={6}>
                                                <StyledValue min='0' max='1000000' type="number" step="any" name="toPrice" id="toPrice" value={this.state.endingTotalPrice} placeholder={(`${t('common:preview:filters:to')}`)} onChange={this.handleChange} />
                                            </StyledGrid>
                                        </StyledFilterPriceWrapper>
                                    </Grid>
                                </StyledFilterPriceWrapper>
                            </FieldSet>
                        </Grid>
                        <Grid item={true} xs={12} sm={12} md={4} lg={2} xl={2} style={{ marginTop: "15px" }}>
                            <FieldSet legend={t('common:preview:filterName:grading')}>
                                <StyledHorizontalList>
                                    {JewlleryGradingList.map((grading, index) => {
                                        return <StyledWrapper key={index} >
                                            <StyledName style={{ fontSize: "0.6em" }} isSelected={grading.selectedValue} onClick={() => this.selectedGrading(grading, index)} >{grading.name}</StyledName>
                                        </StyledWrapper>
                                    })}
                                </StyledHorizontalList>
                            </FieldSet>
                        </Grid>
                        <StyledButtonContainer item={true} xs={12}>
                            <StyledButton onSubmit={this.handleSubmit.bind(this)}>{t('common:button:search')}</StyledButton>
                            <StyledButton onClick={this.resetValue.bind(this)}>{t('common:button:reset')}</StyledButton>
                        </StyledButtonContainer>
                    </StyledFilterGrid>
                </form>
                {this.state.loadData === true &&
                    <div className="loading">
                        <LoadingSpinner />
                    </div>
                }
            </StyledGridWrapper>
        )
    }
}