import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { preferredlanguageUrl } from "../../../common/config/app.endpoints";
import Button from "../../../common/elements/Button";
import {
  DialogOption,
  useDialog,
} from "../../../common/elements/Dialog/Dialog";
import Colors from "../../../common/materials/colors";
import AuthService from "../../../components/auth/AuthService";
import { changeApplicationLanguage } from "../../../utils/utility";
import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { LandingPagePlayFairFont } from "../StyledComponents";
import { MobileHeader, MobileSignIn } from "./ZipangHeader";
import Cross from "../../LandingPage/Static/Icons/Cross";
import Chevron from "../../LandingPage/Static/Icons/Chevron";
import Globe from "../../LandingPage/Static/Icons/Globe";
import Menu from "../../LandingPage/Static/Icons/Menu";
import styled from "styled-components";
import { redirectToOtherDomain, setRedirectPathPostAuth } from "../Utils";

const StyledLinkContainer = styled(Link)`
  font-size: 1em;
  cursor: pointer;
  &:hover: {
    color: #d92b3c;
  }
`;

const useStyles = makeStyles({
  menu: {
    position: "relative",
    width: "99vw",
    overflowX: "hidden",
  },
  container: {
    padding: "0em 4em 0",
  },
  paddingV: {
    padding: "3em 0",
  },
  menuItem: {
    padding: "0.5em 0",
    marginLeft: "1em",
    borderBottom: `1px solid ${Colors.black}`,
  },
  scale: {
    transform: "scale(1.5)",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
  },
});

const MobileMenuModal = ({
  onClose,
  onLogin,
}: {
  onClose: () => any;
  onLogin: () => any;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [createDialog, closeDialog] = useDialog();

  const changeLanguage = (lng: string) => {
    var lang: any = "";
    if (lng === "en") {
      lang = "en";
      window.localStorage.setItem("language", "EN");
    } else if (lng === "zh_CN") {
      lang = "zh_CN";
      window.localStorage.setItem("language", "zh_CN");
    } else {
      lang = "jp";
      window.localStorage.setItem("language", "日本語");
    }
    var token = localStorage.getItem("id_token");
    if (token !== null) {
      const Auth = new AuthService();
      if (token === null) {
        history.push("/");
      } else {
        Auth.postRequest(
          `${preferredlanguageUrl}/${lang}`,
          "data",
          (status: number, response: any) => {
            changeApplicationLanguage(lng);
          }
        );
      }
    } else {
      changeApplicationLanguage(lng);
    }
    onClose();
  };

  const redirectTab = (str: any) => {
    onClose();
    setTimeout(() => {
      window.location.hash = str;
    }, 700);
  };


  const handleRedirect = (url: string) => {
    if (!url) {
      return;
    }
    if (url.includes("/team-page")) {
      history.push(url);
    }
    setRedirectPathPostAuth(url);
    onClose();
  };

  const redirectTeamTab = (str: any) => {
    setTimeout(() => {
      history.push("/team-page");
    }, 300);
  };

  const handleRegister = React.useCallback(() => {
    createDialog({
      children: (
        <SignUpFormDialog
          isMobile={true}
          onSignUp={closeDialog}
          close={closeDialog}
        />
      ),
      ...SignUpFormDialogOptions,
    });
  }, []);

  return (
    <Grid container className={classes.menu}>
      <MobileHeader handleBuyDiamonds={handleRegister} />
      <span
        onClick={onClose}
        style={{ position: "fixed", bottom: "1em", left: "1em" }}
      >
        <Cross filled classes={classes.scale} />
      </span>
      <Grid container justifyContent="center" className={classes.paddingV}>
        <Typography variant="h3">
          <LandingPagePlayFairFont>
            {t("common:menuText")}
          </LandingPagePlayFairFont>
        </Typography>
      </Grid>
      <Grid container classes={classes}></Grid>
      <Grid
        container
        classes={classes}
        style={{
          flexDirection: "column",
          textAlign: "center",
          fontSize: "1.2em",
        }}
      >
        <StyledLinkContainer
          onClick={() => {
            redirectTab("UPCOMMING");
          }}
        >
          {t("common:zipangLanding:tab:upcomingAuc")}
        </StyledLinkContainer>
        <StyledLinkContainer
          onClick={() => {
            redirectTab("SELLERS");
          }}
        >
          {t("common:zipangLanding:tab:sell")}
        </StyledLinkContainer>
        <StyledLinkContainer
          onClick={() => {
            redirectTab("BUYER");
          }}
        >
          {t("common:zipangLanding:tab:buy")}
        </StyledLinkContainer>
        <StyledLinkContainer
          onClick={() => handleRedirect('/team-page')}
        >
          {t("common:zipangLanding:tab:ourTeam")}
        </StyledLinkContainer>
        <StyledLinkContainer
          onClick={() => {
            redirectTab("CONTACT");
          }}
        >
          {t("common:zipangLanding:tab:contact")}
        </StyledLinkContainer>
      </Grid>
      <Grid container classes={classes}>
        <Accordion elevation={0} style={{ width: "100%" }}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<Chevron />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ margin: 0 }}
          >
            <Globe
              // onClick={handleClick}
              width="80"
              height="80"
              style={{ marginLeft: "-24px", height: "60px" }}
            />
          </AccordionSummary>
          <AccordionDetails style={{ width: "50vw" }}>
            <Grid container justifyContent="space-between">
              <strong onClick={() => changeLanguage("en")}>ENGLISH</strong>
              <strong onClick={() => changeLanguage("jp")}>日本語</strong>
              <strong onClick={() => changeLanguage("zh_CN")}>中文</strong>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

const MobilMenu = () => {
  const [createDialog, closeDialog] = useDialog();
  const [showSignIn, setShowSignIn] = useState(false);

  const handleLogin = () => setShowSignIn(true);
  const handleClose = () => setShowSignIn(false);

  const options = {
    fullScreen: true,
  } as unknown as DialogOption;
  const handleMenuClick = React.useCallback(() => {
    createDialog({
      children: <MobileMenuModal onClose={closeDialog} onLogin={handleLogin} />,
      ...options,
    });
  }, []);

  let { t } = useTranslation();

  return (
    <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '5px' }}>
      <MobileSignIn show={showSignIn} onClose={handleClose} />
      <Button type="null" onClick={handleMenuClick}>
        <Menu />
      </Button>
      <Button type="contained-red" onClick={handleLogin}>
        {t("common:header:buttons:loginNew")}
      </Button>
    </Grid>
  );
};

export default MobilMenu;
