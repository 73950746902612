import React, { Component, ChangeEvent } from 'react';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { changePasswordUrl } from '../../common/config/app.endpoints';
import { StyledButton, StyledTextInput, StyledTitle, StyledComponent, StyledTitleText } from '../../common/materials/GenericStyleComponent';
import AuthService from '../auth/AuthService';
import IRouterComponentProps from '../../models/IRouterComponentProps';
import { oAuth } from '../../routes/basic/BasicAppRouter';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faEyeProps = faEye as IconProp;

const eye = <FontAwesomeIcon icon={faEyeProps} />;

const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;   
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
        overflow-y: scroll;
        height: 300px;
        padding: 20px; 
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
        overflow-y: scroll;
        height: 400px;
        padding: 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
        padding: 1em; 
    }
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    margin: 20px 0;
 `;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 1em;
    min-height: 15px;
`;
const StyledPasswordCon = styled.div`
    position: relative;
    display: flex;
    margin-bottom: 14px;
input {
    padding-right:2em !important;
}
i {
    position: absolute;
    top: 34%;
    right: 2%;
    cursor:pointer;
  }
`;
interface IProps extends IRouterComponentProps {
    t?: any;
    closeModal: () => void;
    showAlert: () => void;
    apiTimeout: (timeout: boolean) => void;
}

interface IState {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    message: string;
    showPassword: boolean;
    showConPassword: boolean;
}

export default class ChangePassword extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);
        this.toggleConPassword = this.toggleConPassword.bind(this);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            message: '',
            showPassword: false,
            showConPassword: false
        }
    }

    Auth = new AuthService();

    handleChange(e: ChangeEvent<HTMLInputElement>) {
        const targeName = e.target.name;
        const targeValue = e.target.value;
        if (targeName === 'oldPassword') { this.setState({ oldPassword: targeValue.trim() }) }
        if (targeName === 'newPassword') { this.setState({ newPassword: targeValue.trim() }) }
        if (targeName === 'confirmPassword') { this.setState({ confirmPassword: targeValue.trim() }) }
    }

    togglePasswordVisiblity() {
        if (this.state.showPassword) {
            this.setState({
                showPassword: false
            })
        } else {
            this.setState({
                showPassword: true
            })
        }
    };
    toggleConPassword() {
        if (this.state.showConPassword) {
            this.setState({
                showConPassword: false
            })
        } else {
            this.setState({
                showConPassword: true
            })
        }
    };

    handleFormSubmit(e: any) {
        const { t } = this.props;
        e.preventDefault();
        if (this.state.newPassword === '' && this.state.oldPassword === '' && this.state.confirmPassword === '') {
            this.setMessage(`${t('common:allValidations:emptyFields')}`)
        }
        else if (this.state.oldPassword === '') {
            this.setMessage(`${t('common:profile:changePassword:validation:enterOldPassword')}`)
        }
        else if (this.state.newPassword === '') {
            this.setMessage(`${t('common:profile:changePassword:validation:enterNewPassword')}`)
        }
        else if (this.state.confirmPassword === '') {
            this.setMessage(`${t('common:profile:changePassword:validation:enterConfirmPassword')}`)
        }
        else if (this.state.newPassword !== this.state.confirmPassword) {
            this.setMessage(`${t('common:profile:validation:newAndConfirmPasswordNotMatch')}`)
        }
        else if (this.state.oldPassword === this.state.newPassword) {
            this.setMessage(`${t('common:profile:validation:newAndOldPasswordNotMatch')}`)
        }
        else {
            if (!oAuth()) {
                this.props.history.push('/');
            } else {
                this.Auth.postRequest(`${changePasswordUrl}oldPassword=${this.state.oldPassword}&newPassword=${this.state.newPassword}`, "data", (status: any, response: any) => {
                    if (status === 200) {
                        this.props.closeModal()
                        this.props.showAlert()
                    }
                    else if (status === 505) {
                        this.props.apiTimeout(true)
                    }
                    else if (status > 200 && status < 500) {
                        if (response[0].code === 'KB412001') {
                            this.setState({
                                message: `${t('common:profile:changePassword:validation:incorrectOldPassword')}`
                            })
                        }
                        else {
                            this.setState({
                                message: response[0].message
                            })
                        }
                    }
                    else {
                        this.setState({
                            message: `${t('common:somethingWentWrong')}`
                        })
                    }
                })
            }
        }
    }

    setMessage(msg?: any) {
        this.setState({
            message: msg ? msg : ''
        })
    }

    render() {
        const { t } = this.props;
        return (
            <StyledComponent tabIndex={-1} >
                <form name="changePassword" noValidate={true} onSubmit={this.handleFormSubmit.bind(this)}>
                    <StyledContainerWrapper>
                        <StyledTitle className="fontBold"><StyledTitleText>{t('common:profile:changePassword:title')}</StyledTitleText></StyledTitle>
                        <StyledMessage>
                            {this.state.message}
                        </StyledMessage>
                        <StyledTextInput>
                            <StyledPasswordCon className="pass-wrapper">
                                <TextField
                                    required
                                    fullWidth
                                    autoFocus={true}
                                    id="oldPassword"
                                    label={t('common:profile:changePassword:labels:oldPassword')}
                                    variant="outlined"
                                    type={this.state.showPassword ? "text" : "password"}
                                    name="oldPassword"
                                    onChange={this.handleChange.bind(this)}
                                />
                                <i onClick={this.togglePasswordVisiblity}>{eye}</i>
                            </StyledPasswordCon>
                        </StyledTextInput>
                        <StyledTextInput>
                            <StyledPasswordCon className="pass-wrapper">
                                <TextField
                                    required
                                    fullWidth
                                    id="newPassword"
                                    label={t('common:profile:changePassword:labels:newPassword')}
                                    variant="outlined"
                                    name="newPassword"
                                    type={this.state.showConPassword ? "text" : "password"}
                                    onChange={this.handleChange.bind(this)}
                                />
                                <i onClick={this.toggleConPassword}>{eye}</i>
                            </StyledPasswordCon>
                        </StyledTextInput>
                        <StyledTextInput>
                            <TextField
                                required
                                fullWidth
                                id="confirmPassword"
                                type="password"
                                label={t('common:profile:changePassword:labels:confirmPassword')}
                                variant="outlined"
                                name="confirmPassword"
                                onChange={this.handleChange.bind(this)}
                            />
                        </StyledTextInput>
                        <StyledButtonContainer>
                            <StyledButton onClick={this.handleFormSubmit.bind(this)} >
                                {t('common:button:save')}
                            </StyledButton>
                            <StyledButton onClick={() => this.props.closeModal()}>
                                {t('common:button:cancel')}
                            </StyledButton>
                        </StyledButtonContainer>
                    </StyledContainerWrapper>
                </form>
            </StyledComponent>
        )
    }
}