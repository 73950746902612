
import React from "react";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import Grid from '@material-ui/core/Grid';

const StyledComponent = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  overflow: auto;
  padding: 1em;
  border-radius:  20px 20px 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding-bottom: 10px;
  font-weight: bold;
`

const StyledContainer = styled(Grid)`
  align-items: center;
  padding-bottom: 2px;
  .attachmentName {
    text-decoration: underline;
    cursor: pointer;
  }
  
`;

const StyledImage = styled.img`
  width: 300px;
  text-align: center;
  padding: 2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
  border: 1px solid ${(props) => props.theme.colors.base.veryLightGrey};
  padding: 5px;
  margin-right: 10px;
`;

interface IProps {
  selectedAttachment: any;
  columnIndexes: any;
}

const AttachmentPopup = (props: IProps, ref: any) => {
  const {t} = useTranslation();
  const {selectedAttachment} = props;
  const {medias} = selectedAttachment;
  return (
    <StyledComponent tabIndex={-1}>
      <StyledTitle>{t("common:inquiry:fields:Attachment")}</StyledTitle>
      <StyledContainer container={true} spacing={0} >
        {medias && medias.map((item: any, index: number) => {
          return (
            <Grid item={true} key={index} title={item.name}>
              <a href={item.presignedUrl} target="_blank"><StyledImage src={item.presignedUrl} /></a>
            </Grid>
          );
        })}
         </StyledContainer>
      
    </StyledComponent>
  )
};

export default React.forwardRef(AttachmentPopup);