import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import JewelleryGridBox from "./JewelleryGridBox";
import DiamondGridBox from "./DiamondGridBox";
import DiamondGridBoxAllProduct from "./DiamondGridBoxAllProduct";
import JewelleryGridBoxAllProduct from "./JewelleryGridBoxAllProduct";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    min-height: 200px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    min-height: 400px;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    min-height: 600px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 2em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

interface IProducts {
  id: number | string;
  name: string;
  brandName?: string;
  price: number;
  serialNumber: number;
  preBiddingPrice: number;
}

interface IProps {
  t: any;
  selectedPage?: boolean;
  history?: any;
  currentRecords?: IProducts;
  auctionDate: any;
  auctionName: string;
  isAuctionPage?: boolean;
  isCategoryPageType?: boolean;
  setShowBidPopupBidModal: (values: any) => void;
  sortingValue: (price: any, val: any) => void;
  boxColor?: (items: any) => string;
  watchlist: any;
  unWatchlist: any;
  auctionUserId: any;
  bulkBidIndexHandler?: (valueOfIndex: any[]) => void;
  myForProductAllProduct?: any;
  renderAutomaticBidPopupInMobile: (val: any) => void;
  propsUserId?: any;
  renderReadOnlyUserToast: any;
  isRefreshData: (val: any) => void;
  rightClickEvent: (e: any, val: any, valdate: any) => void;
}

interface IState {
  products: [];
  openToast: boolean;
  showAlertMessage: string;
}

export interface IProductsProps extends IRouterComponentProps {
  t?: any;
  currentRecords?: IProducts;
}

let lengthOfProducts: any;
export default class ProductGridDataChildComponent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      products: [],
      openToast: false,
      showAlertMessage: "",
    };
  }

  setProducts(products: any) {
    this.setState(() => ({
      products,
    }));
  }

  componentDidMount() {
    this.setProducts(this.props.currentRecords);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentRecords !== this.props.currentRecords) {
      this.setProducts(prevProps.data);
    }
  }

  showAlert = (message: string) => {
    this.setState({
      openToast: true,
      showAlertMessage: message,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <StyledGrid container={true} spacing={0}>
        {this.state.products.length > 0 &&
          this.state.products.map((product: any, index: number) => {
            return (
              <Grid key={index} item={true} xs={12} sm={6} md={6} lg={3} xl={2}>
                {this.props.isCategoryPageType === true ? (
                  <DiamondGridBoxAllProduct
                    currentIndex={index}
                    {...this.props}
                    {...product}
                    items={product}
                    auctionData={this.state.products}
                    t={t}
                    auctionDate={this.props.auctionDate}
                    isPage={this.props.isAuctionPage}
                    setShowBidPopupBidModal={this.props.setShowBidPopupBidModal}
                    addToWatchList={this.props.watchlist}
                    unwatchedList={this.props.unWatchlist}
                    auctionUserIds={this.props.auctionUserId}
                    sortingValue={this.props.sortingValue}
                    boxColor={this.props.boxColor}
                    bulkBidHandler={this.props.bulkBidIndexHandler}
                    myLatestBidForProduct={this.props.myForProductAllProduct}
                    renderAutomaticBidPopupInMobile={
                      this.props.renderAutomaticBidPopupInMobile
                    }
                    renderReadOnlyUserToast={this.props.renderReadOnlyUserToast}
                    getExtendedTime={this.props.isRefreshData}
                    rightClickEvent={this.props.rightClickEvent}
                  />
                ) : (
                  <JewelleryGridBoxAllProduct
                    {...this.props}
                    {...product}
                    currentIndex={index}
                    t={t}
                    items={product}
                    sortingValue={this.props.sortingValue}
                    boxColor={this.props.boxColor}
                    auctionDate={this.props.auctionDate}
                    isPage={this.props.isAuctionPage}
                    unwatchedList={this.props.unWatchlist}
                    addToWatchList={this.props.watchlist}
                    setShowBidPopupBidModal={this.props.setShowBidPopupBidModal}
                    auctionUserIds={this.props.auctionUserId}
                    bulkBidHandler={this.props.bulkBidIndexHandler}
                    myLatestBidForProduct={this.props.myForProductAllProduct}
                    renderAutomaticBidPopupInMobile={
                      this.props.renderAutomaticBidPopupInMobile
                    }
                    renderReadOnlyUserToast={this.props.renderReadOnlyUserToast}
                    getExtendedTime={this.props.isRefreshData}
                    rightClickEvent={this.props.rightClickEvent}
                  />
                )}
              </Grid>
            );
          })}
      </StyledGrid>
    );
  }
}
