import Grid from "@material-ui/core/Grid";
import { Link } from "@mui/material";
import styled from "styled-components";

export const StyledFooterComponent = styled(Grid)`
  padding: 0;
`;

export const StyledFooterContainer = styled(Grid)<{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? "2em" : "3em 5em")};
`;

export const StyledFooterContainerdummy = styled(Grid)<{ isMobile?: boolean }>`
  // padding: ${(props) => (props.isMobile ? "2em" : "3em 5em")};
  color: white;
`;
export const StyledFooterLink = styled(Link)`
  display: block;
  padding: 0 0 1em 0;
  color: ${(props) => props.theme.colors.base.black};
  text-decoration: underline !important;
  text-align: left;
`;
export const StyledAnchor = styled.a`
  display: block;
  cursor: pointer;
  padding: 0 0 1em 0;
  color: ${(props) => props.theme.colors.base.black};
  text-decoration: underline !important;
  text-align: left;
`;
export const StyledFooterLinkContainer = styled.div`
  display: flex;
`;

export const StyledForm = styled.form`
  margin-bottom: 2em;
`;

export const StyledFormdummy = styled.form`
  margin-bottom: 1em;
  color: white;
`;

export const StyledGrid = styled(Grid)<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
  align-items: stretch;
`;

export const StyledLink = styled(Link)`
  margin: 0 1em;
`;

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 0.8em;
  margin-bottom: 1em;
`;
