import React, { useEffect, useRef, useState } from "react";
import AdContainer from "../Ads/Ads";
import ProductCard, { IProductCardProps } from "./ProductCard";
import { StyledBoxContainer, StyledListContainer } from "./StyledComponent";
import AnnualProductCard from "./AnnualProductCard";

export interface ProductListProps {
  productsList: Array<IProductCardProps>;
  ads: { link: string; imgSrc: string }[];
  isMobile: boolean;
  hideBidButton?: boolean;
  fixedHeightCards?: boolean;
  disableMobileView?: boolean;
  isZipang: boolean;
  isLandingPage: boolean;
}

const MobileProductList = ({
  fixedHeightCards,
  productsList,
  hideBidButton,
  ads,
  isZipang,
  isLandingPage
}: ProductListProps) => {
  const [productListInPairOfThree, setProductListInPairOfThree] = useState(
    [] as IProductCardProps[][]
  );

  const adId = useRef(0);

  useEffect(() => {
    const _productListInPairOfThree: IProductCardProps[][] = [];
    productsList.forEach((product, i) => {
      if (i % 2 === 0) {
        const slice = productsList.slice(i, i + 2);
        _productListInPairOfThree.push(slice);
      }
    });
    setProductListInPairOfThree(_productListInPairOfThree);
  }, [productsList]);

  const getAd = () => {
    const ad = ads[adId.current];
    if (ad) {
      adId.current += 1;
      return;
    }
    return null;
  };
  return (
    <StyledListContainer>
      {productListInPairOfThree.map((list, listId) => (
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "scroll",
              width: "100vw",
              marginBottom: "1em",
              paddingBottom: "1em",
            }}
          >
            {list.map((product, productId: number) => (
              <div style={{ marginLeft: "1em" }}>
                <>
                {isZipang ?
                  <AnnualProductCard
                    hideBidButton={hideBidButton}
                    key={productId}
                    {...product}
                    fixedHeightCards={fixedHeightCards}
                    isZipang={isZipang}
                      isLandingPage={isLandingPage}
                  /> :
                  <ProductCard
                    fixedHeightCards={fixedHeightCards}
                    hideBidButton={hideBidButton}
                    key={productId}
                    {...product}
                    isZipang={isZipang}
                  />
                }
                </>
              </div>
            ))}
          </div>
          {listId % 2 === 0 && <AdContainer {...ads[listId / 2]} />}
        </div>
      ))}
    </StyledListContainer>
  );
};
const ProductList = ({
  fixedHeightCards,
  productsList,
  ads,
  hideBidButton,
  isMobile = false,
  disableMobileView = false,
  isZipang,
  isLandingPage
}: ProductListProps) => {
  return (
    <StyledBoxContainer
      container
      item
      xs={12}
      spacing={4}
      className="product-list"
    >
      {isMobile && !disableMobileView ? (
        <MobileProductList
          fixedHeightCards={fixedHeightCards}
          productsList={productsList}
          hideBidButton={hideBidButton}
          ads={ads}
          isMobile={false}
          isZipang={isZipang}
          isLandingPage={isLandingPage}
        />
      ) : (
        productsList.map((product: any, productId: number) => {
          return (
            <>{isZipang ?
              <AnnualProductCard
                hideBidButton={hideBidButton}
                key={productId}
                {...product}
                fixedHeightCards={fixedHeightCards}
                isZipang={isZipang}
                isLandingPage={isLandingPage}
              /> :
              <ProductCard
                hideBidButton={hideBidButton}
                key={productId}
                {...product}
                fixedHeightCards={fixedHeightCards}
                isZipang={isZipang}
              />
            }
            </>
          );
        })
      )}
    </StyledBoxContainer>
  );
};

export default ProductList;
