import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/eventsCalendar.css";

import ja from "date-fns/locale/ja";
import en_US from "date-fns/locale/en-US";
import zh_CN from "date-fns/locale/zh-CN";

setDefaultLocale("en-US");
registerLocale("ja", ja);
registerLocale("en-US", en_US);
registerLocale("zh-CN", zh_CN);

const StyledWrapper = styled(Grid)`
  display: flex;
  .secondary {
    padding: 5px;
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      // width: 150px;
    }
  }
`;

const StyledGrid = styled(Grid)`
  margin: 5px;
`;

interface IProps {
  dates: any;
  t: any;
}
let localization: string = "";

const SelectDateCalendarForNotification = (props: IProps) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = props;

  useEffect(() => {
    let language = localStorage.getItem("language");
    if (language === "EN") {
      localization = "en-US";
    }else if (language ==="zh_CN") {
      localization = "zh_CN";
    } else {
      localization = "ja";
    }
  }, []);

  const onChangeStartDate = (date: any) => {
    setStartDate(date);
    props.dates(date, endDate);
  };

  const onChange = (date: any) => {
    setEndDate(date);
    props.dates(startDate, date);
  };

  return (
    <StyledWrapper>
      <StyledGrid title="This will clickable soon.">
        <DatePicker
          disabled
          selected={startDate}
          onChange={onChangeStartDate}
          locale={localization}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          showDisabledMonthNavigation
          placeholderText={t("common:invoice:filter:startDate")}
          className="secondary react-datepicker"
        />
      </StyledGrid>
      <StyledGrid title="This will clickable soon.">
        <DatePicker
          disabled
          selected={endDate}
          onChange={onChange}
          locale={localization}
          selectsEnd
          startDate={startDate}
          maxDate={new Date()}
          endDate={endDate}
          showDisabledMonthNavigation
          placeholderText={t("common:invoice:filter:endDate")}
          className="secondary react-datepicker"
        />
      </StyledGrid>
    </StyledWrapper>
  );
};

export default SelectDateCalendarForNotification;
