import React, { useEffect, useState } from 'react';
import "./styles.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import IRouterComponentProps from '../../models/IRouterComponentProps';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { sideMenuGradient } from '../../common/materials/LinearGradient';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import { getUserDetailPayment } from '../../common/config/app.endpoints';
import AuthService from '../../components/auth/AuthService';
import { match } from 'react-router';
import PubSub from 'pubsub-js';
import { buttonGradient } from '../../common/materials/LinearGradient';
import Dialog from '@material-ui/core/Dialog';

const stripePromise = loadStripe("pk_live_51IbnKeFRzcATvsXkbCDSUZa1MZ5i5czR0Bi7fq93b52RSsJ9CzNClPWe2r6ACy26AfPWR72eyrjUb6mnlop8q3qz00TtsYXYwe");

const StyledContainer = styled.div`
    width: 100%;   
`;

const StyledBoxContainer = styled.div`
  width: 80%;
  height: 50px;
  margin-left: 10%;
  margin-top: 5%;
  justify-content: space-between;
  display: flex;
`;

const StyledSubBoxContainer = styled.div`
  width: 45%;
  height: 50px;
  background:#F2EFEF;
  border-radius: 25px;
  flex-direction: column;
  display: flex;
  border-width: 1px;
  border-color: ${(props) => props.theme.colors.base.darkGrey};
  border-style: solid;
`;

const StyledTitle = styled.p`
    color: #5298E3;
    text-align: center;
    margin-top: 20px;
    font-family: Calibre-Regular;
    font-size: 4vh;
`;


const StyledBoxHeadingText = styled.span`
    font-size: 12px;
    color:${(props) => props.theme.colors.base.grey};
    padding-left: 20px;
    padding-top: 3px;
`;

const StyledBoxValueText = styled.span`
    font-size: 15px;
    color:#252525;
    padding-left: 20px;
    padding-top: 1px;
`;

const StyledCardContainer = styled.div`
  width: 80%;
  height: auto;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 50px;
`;


const StyledTextWrapper = styled.div`
    font-size: 1.5em;
    padding-bottom: 2em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

const StyledHeadingTextWrapper = styled.div`
    font-size: 1.5em;
    padding-bottom: 2em;
    font-family: Calibre-Regular;
    color: #5298E3;
    font-weight: bold;
`;

const StyledParticipateWrapper = styled(Grid)`
    text-align: center;
    padding: 1em 2em;
`;

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    font-size: 1.2em;
    padding: 0.5em 0.5em;
    padding-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    width: 80px;
    border-radius: 10px;
    font-family: Calibre-SemiBold;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;

const StyledBackground = styled.div`
    width: 100%;
    height: 100%;
    justify-content: center;
    background: url(/images/paymentbackground.png) no-repeat;
    background-position: center;
    background-size: cover;
`;

interface IProps extends IRouterComponentProps {
    t?: any;
}

interface Details {
    userId: string;
}

interface IProps extends IRouterComponentProps {
    match: match<Details>
}

const Auth = new AuthService();

const PaymentForm = (props: IProps) => {
  const { t } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [userFName, setUserFName] = useState("");
  const [userLName, setUserLName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [occupations, setOccupations] = useState("");
  const [amountToPay, setAmountToPay] = useState("");
  const [popUpHeading, setPopUpHeading] = useState("");
  const [popUpMessage, setPopUpMessage] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [isShowPopUp, setIsShowPopUp] = useState(false);
  const [apiStatus, setApiStatus] = useState('');

  useEffect(() => {
    getSignupFilledData();
  }, []);

  useEffect(() => {
    
    PubSub.subscribe("PaymentStatus", function (msg: any, data: any) {

      if (data === 200) {
        setApiStatus('200');
        setPopUpHeading(t('common:paymentForm:paymentSuccessful'));
        setPopUpMessage(t('common:paymentForm:paymentSuccessfullMessage'))
      } else {
        setApiStatus('400');
        setPopUpHeading(t('common:paymentForm:paymentFailed'));
        setPopUpMessage(t('common:paymentForm:paymentFailedMessage'))
      }

      setIsShowPopUp(true);

    });
  }, []);

  const getSignupFilledData = () => {
    
    setIsLoading(true);
    Auth.getRequestWithoutAuthorization(`${getUserDetailPayment}/${props.match.params.userId}/detail`, (status: number, response: any) => {
      if (status === 200) {
              
        if (response === "") {
              setApiStatus('200');
              setPopUpHeading("Error");
              setPopUpMessage("User does not exist.")
              setIsShowPopUp(true);
        }

                localStorage.setItem("userInfo", JSON.stringify(response));
              
                setUserFName(response.firstName);
                setUserLName(response.lastName);
                setUserEmail(response.email);
                setUserCompanyName(response.companyName);
                setOccupations(response.occupations.join(' & '));
                setAmountToPay(response.totalAmountToPay);
                setCurrencySymbol(response.currencySymbol);

            } else if (status === 409) {
              setApiStatus('200');
              setPopUpHeading("Payment Successful");
              setPopUpMessage("Thank you for using My Auctions always. You have done payment already.")
              setIsShowPopUp(true);
            }
          setIsLoading(false);
        })
  }
  
  const buttonOkPressed = () => {
    setIsShowPopUp(false);
    if (apiStatus === '200') {
      props.history.push('/');
    }
  }
  
  return (
    <div className="PaymentForm">     
      <div style={{ width: '100%', height: '100%', justifyContent: 'center' }}>
        <StyledBackground>
          <div style={{width: '100%', height: '50px'}}></div>
        <div className="product">
            <StyledContainer >
              <StyledTitle>{t('common:paymentForm:securePaymentGateway')}</StyledTitle>
              <StyledBoxContainer>
                <StyledSubBoxContainer>
                  <StyledBoxHeadingText>{t('common:kycFrom:fName')}</StyledBoxHeadingText>
                  <StyledBoxValueText>{userFName}</StyledBoxValueText>
                </StyledSubBoxContainer>
                <StyledSubBoxContainer>
                  <StyledBoxHeadingText>{t('common:kycFrom:lName')}</StyledBoxHeadingText>
                  <StyledBoxValueText>{userLName}</StyledBoxValueText>
                </StyledSubBoxContainer>
              </StyledBoxContainer>
              <StyledBoxContainer>
                <StyledSubBoxContainer style={{ width: '100%' }}>
                  <StyledBoxHeadingText>{t('common:profile:legends:email')}</StyledBoxHeadingText>
                  <StyledBoxValueText>{userEmail}</StyledBoxValueText>
                </StyledSubBoxContainer>
              </StyledBoxContainer>
              <StyledBoxContainer>
                <StyledSubBoxContainer>
                  <StyledBoxHeadingText>{t('common:register:labels:occupation')}</StyledBoxHeadingText>
                  <StyledBoxValueText>{occupations}</StyledBoxValueText>
                </StyledSubBoxContainer>
                <StyledSubBoxContainer>
                  <StyledBoxHeadingText>{t('common:profile:legends:companyName')}</StyledBoxHeadingText>
                  <StyledBoxValueText>{userCompanyName}</StyledBoxValueText>
                </StyledSubBoxContainer>
              </StyledBoxContainer>
              <StyledBoxContainer>
                <StyledSubBoxContainer style={{ width: '100%' }}>
                  <StyledBoxHeadingText>{t('common:paymentForm:amountToPay')}</StyledBoxHeadingText>
                  <StyledBoxValueText style={{ textAlign: 'center', fontSize: '18px' }}>{currencySymbol}{amountToPay}</StyledBoxValueText>
                </StyledSubBoxContainer>
              </StyledBoxContainer>
              <StyledCardContainer>
                <Elements stripe={stripePromise}>
                  <CheckoutForm />
                </Elements>
              </StyledCardContainer>
            </StyledContainer>
          </div>
          </StyledBackground>
      </div>
      {isShowPopUp === true &&
        <Dialog aria-labelledby="simple-dialog-title" open={true}>
          
        <StyledParticipateWrapper item md={12} xs={12} lg={12}>
          <StyledHeadingTextWrapper> {popUpHeading}</StyledHeadingTextWrapper>
                      <StyledTextWrapper> {popUpMessage}</StyledTextWrapper>
                      <StyledButton onClick={() => {
                        buttonOkPressed()
                      }}>{t('common:button:ok')}</StyledButton>
                  </StyledParticipateWrapper>
        </Dialog>
        }
      {
        isLoading === true &&
        <div className="loading">
            <LoadingSpinner />
        </div>
      }
    </div>
  );
};

export default PaymentForm;