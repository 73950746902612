import moment from "moment";
import { DateTimeEnum } from "../enums/dateTimeEnum";

export const getSplitDateTime = (dateToProcess: any, timezoneName: string) => {
  const previewTime = moment.utc(dateToProcess);
  const result = moment.tz(previewTime, timezoneName);
  const resultFormatter = result.format();
  if (resultFormatter !== DateTimeEnum.INVALIDDATE) {
    return resultFormatter.split("T", 2);
  }
};

export const getSplitTime = (dateToProcess: any, timezoneName: string) => {
  const splitDateTime = getSplitDateTime(dateToProcess, timezoneName);
  if (splitDateTime) {
    const splitTime = splitDateTime[1].includes("+")
      ? splitDateTime[1].split("+", 2)
      : splitDateTime[1].split("-", 2);
    return splitTime;
  }
};
export const getSplitDate = (dateToProcess: any, timezoneName: string) => {
  const previewTime = moment.utc(dateToProcess);
  const result = moment.tz(previewTime, timezoneName);
  const resultFormatter = result.format();
  if (resultFormatter !== DateTimeEnum.INVALIDDATE) {
    let dateSpit = resultFormatter.split("T", 2);
    return dateSpit[0];
  }
};

export const getSplitedTime = (dateToProcess: any, timezoneName: string) => {
  const splitDateTime = getSplitDateTime(dateToProcess, timezoneName);
  if (splitDateTime) {
    const splitTime = splitDateTime[1].includes("+")
      ? splitDateTime[1].split("+", 2)
      : splitDateTime[1].split("-", 2);
    return splitTime[0];
  }
};

export const getProcessedDate = (
  dateToProcess: any,
  timezoneName: string,
  replaceZ?: boolean
) => {
  const isReplaceZ = replaceZ ? replaceZ : false;
  const splitDateTime = getSplitDateTime(dateToProcess, timezoneName);
  const splitTime = getSplitTime(dateToProcess, timezoneName);
  const date = splitDateTime
    ? isReplaceZ
      ? splitDateTime[0].replace("Z", "")
      : splitDateTime[0]
    : "";
  const time = splitTime ? splitTime[0] : "";
  const dateTime = `${date} ${time}`;
  const start: any = moment(dateTime);
  const end: any = moment(
    new Date().toLocaleString("en-US", { timeZone: timezoneName })
  );
  const hour = start.diff(end, "hours");
  const minutes = start.diff(end, "minutes");
  return {
    DATE: date,
    TIME: time,
    DATETIME: dateTime,
    START: start,
    END: end,
    HOUR: hour,
    MINUTES: minutes,
  };
};
