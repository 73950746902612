import { Grid, createTheme, useMediaQuery, Modal } from "@material-ui/core";
import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { sideMenuGradient } from '../common/materials/LinearGradient';
import Footer from '../components/footer/footer';
import Flotingicon from "../components/products/Flotingicon";
import LanguageChooser from '../components/shared/languageChooser';
import PersonalizedSlider from "../components/sidebar/PersonalizedSlider";
import IRouterComponentProps from '../models/IRouterComponentProps';

import Promotional from "./login/Promotional";
interface IProps extends IRouterComponentProps {
    t?: any;
}

interface ButtonProps {
    selected?: boolean;
};

const StyledButton = styled.button<ButtonProps>`
    background: ${(props) => props.theme.colors.base.transparent}; 
    padding: 5px 5px;
    color: ${(props) => props.theme.colors.base.white};
    font-size : 1em;
    border-radius: 3px;
    font-family: SF-Display-Medium;
    outline: none;
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.base.white};
    margin-right: 5px;
    &:hover {
        background: ${(props) => props.theme.colors.base.white};
        color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    }
    ${props => props.selected && css`
        background: ${(props) => props.theme.colors.base.white};
        color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledContainer = styled(Grid)`
    .logoText {
        font-size: 1.5em;
    }
    .returnToWebsite {
        font-size: 18px;
    }
`;

const StyledResetPaddingContainer = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled(Grid)`
    display: inline-block;
    width: 100%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        img {
            width: 90%;
        };  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        img {
            width: 20%;
        };
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        img {
            width: 20%;
        };
    }
    display: flex;
    align-items: center;
`;

const StyledLogoWrapperContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background: ${(props) => props.theme.colors.base.darkBlue};
    background: ${sideMenuGradient};
`;

const StyledFooterContainer = styled(Grid)`
    padding: 0 !important;
`;

const StyledBannerContianer = styled(Grid)`
    display: flex;
    position:relative;
`;

const StyledBanContent = styled(Grid)`
    color:${(props) => props.theme.colors.base.white};
    position:absolute;
    font-weight: bold;
    top:15%;
    left:15%;
    
    .firstLine {
        font-size:1.2em;
        padding-bottom:0.1em;
    }
    .secondLine {
        font-size:1em;
        margin-top:1em;
    }
    @media (width: 768px) {
        top:20% !important;        
    }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        left:4%;
        top:15%;
        .firstLine {
            font-size:0.8em;
        }
        .secondLine {
            font-size:0.6em;
            margin-top:0.3em;
        }
    }

    ${props => props.theme.breakpoints.between('sm', 'md')} {
        left:2%;
        top:20%;
        .firstLine {
            font-size:0.8em;
        }
        .secondLine {
            font-size:0.6em;
            margin-top:0.3em;
        }
    }
`;

const StyledButtonContainer = styled.div`
    margin-top:1em;
`;

const StyledBanBorder = styled(Grid)`
    border-bottom: 2px solid ${(props) => props.theme.colors.base.white};
    opacity:0.3;
`;
const StyledBidButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    border-radius: 8px;
    font-style: italic;
    background: ${(props) => props.theme.colors.base.textColor};

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:0.8em;
        padding: 0.3em;
        border-radius: 4px;
    }
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;

const StyledTypeImgContainer = styled(Grid)`
    text-align:center;
    justify-content:center;
    margin:2em 0em;

    .width-6em{
        width:6em;
    }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        .width-6em{
            width:2em;
        }
    }
`;

const StyledAuctionTypeImage = styled.img`
    height:8em;
    width:8em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        height:4em;
        width:4em;
    }
    
`;
const StyledCompanyInfo = styled(Grid)`
    margin:2em 0em;
    justify-content: center;
    .alignCenter {
        text-align:center;
    }
`;

const StyledComInfoImage = styled.img`
    height:90%;
    width:70%;
    text-align:center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        height:100%;
        width:100%;
    }
`;

const StyledCompContent = styled(Grid)`
    font-size:1em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 0.5em;
    }
`;

const StyledTitle = styled.div`
    font-weight:bold;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:0.6em;
    }
`;

export const StyledName = styled.div`
    font-size:1.5em;
    color: ${(props) => props.theme.colors.base.textColor};
    font-weight:bold;
    margin-top:0.4em;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:0.7em;
        margin-top:0.2em;
    }

    @media (width: 320px) {
        font-size: 0.6em;     
    }
    
`;

export const StyledBorderBottom = styled.div`
    border-bottom:2px solid ${(props) => props.theme.colors.base.textColor};
`;

export const StyledDescription = styled.div`
    margin-top:0.6em;
    text-align:justify;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        margin-top:0.2em;
        font-size:0.5em;
    }
    @media (width: 320px) {
        font-size: 0.4em;     
    }
    @media (width: 280px) {
        font-size: 0.2em;     
    }
`;

const StyledAuctionCon = styled(Grid)`
    position:relative;
    justify-content:center;    
    
    img {
        height:350px;
        width:100%;
    }

    .paddingLeftRight {
        padding:0em 1em;
        width:100%;
    }
    .content {
        position:absolute;
        top: 25%;
        left: 10%;
    }
    
    .content .border-bottom {
        border-bottom:2px solid ${(props) => props.theme.colors.base.textColor};
    }

    @media (width: 768px) {
        .content {
            top: 30% !important;
        }
      }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        img {
            height:100%;
            width:100%;
        }
    
        .paddingLeftRight {
            padding:0em 0.5em;
            width:100%;
        }
        .content {
            top: 10%;
            left: 5%;
        }
    }

    ${props => props.theme.breakpoints.between('sm', 'md')} {
        .content {
            left:3%;
        }
    }
`;

const StyledRelevantPeople = styled(Grid)`
    justify-content:center;
    margin-top:2em;    
     .alignCenter {
        text-align:center;
    }
    .positionRelative {
        position:relative;
    }
    .content {
        position:absolute;
        top:25%;
    }
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 0.8em;
        .content {
            position:relative;
        }
    }

    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 0.5em;
        .content {
            position:relative;
        }   
    } 
`;
const StyledContImage = styled.img`
    height:100%;
    width:100%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        height:130px;
        width:100%;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        height:120%;
        width:100%;
    }  
`;

const StyledRelevateImage = styled.img`
    height:250px;
    width:90%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width:95%;
        height:100%
    }  
`;

const StyledButtonWrapper = styled.div`
    float: right;
    padding: 8px;
    display: flex;
`;
const StyledCompanyName = styled.div`
    color:${(props) => props.theme.colors.base.white};
    margin-left:0.5em;
    font-size:1.5em;

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        argin-left:0.4em;
        font-size:1.2em;
    }
`;

const StyledDaimondCon = styled(Grid)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledDaimondsText = styled.span`
    font-size:1.5em;
    margin-right:1em;
    color: ${(props) => props.theme.colors.base.textColor};
    font-weight: bold; 
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:0.8em;
    }
`;
const StyledDaimondsTextMobile = styled.div`
    font-size:2em;
    color: ${(props) => props.theme.colors.base.textColor};
    font-weight: bold; 
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:0.8em;
    }
`;
const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;
const SubDomain = (props: IProps) => {
    const { t } = props;
     const [onPromotional, setOnPromotional] = useState(false);

    const theme = createTheme({});
    const notSmallDevices = useMediaQuery(theme.breakpoints.between('sm', 'xl'));
    const isSmallDevices = useMediaQuery(theme.breakpoints.down('xs'));
    const returnToWebsite = () => {
        props.history.push('/')
    }
    const onClose = () => {
      setOnPromotional(false);
    };
    const loginHandler = () => {
        alert("Login Handler");
    }

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledLogoWrapperContainer container={true} spacing={0}>
                <StyledResetPaddingContainer item xs={12}>
                    <StyledLogoWrapper item xs={5} sm={6} lg={4}>
                        <StyledCompanyName>{t('common:subDomain:banner:companyName')}</StyledCompanyName>
                    </StyledLogoWrapper>
                    <Grid item xs={7} sm={6} lg={8} className="buttonsContainer">
                        <StyledButtonWrapper>
                            <StyledButton selected={true} onClick={loginHandler}>{t('common:header:buttons:login')}</StyledButton>
                            <LanguageChooser {...props} />
                        </StyledButtonWrapper>
                    </Grid>
                </StyledResetPaddingContainer>
            </StyledLogoWrapperContainer>
            <StyledBannerContianer item={true} xs={12}>
                <StyledContImage src="/images/sub-domain/bannernew.gif"/>
                <StyledBanContent item xs={7} sm={7} md={7} lg={6} xl={6}>
                    <div className="firstLine">{t('common:subDomain:banner:companyName')}</div>
                    <StyledBanBorder />
                    <div className="secondLine">{t('common:subDomain:banner:companyDescription')}</div>
                    <StyledButtonContainer>
                        <StyledBidButton onClick={loginHandler} className="isLogin">{t('common:subDomain:banner:button')}</StyledBidButton>
                    </StyledButtonContainer>
                </StyledBanContent>
            </StyledBannerContianer>
            <StyledTypeImgContainer item xs={12} container spacing={0}>
                <Grid container spacing={0} item xs={12}>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        {!isSmallDevices &&
                            <StyledDaimondCon>
                                <StyledDaimondsText>{t('common:subDomain:daimodsInfo:daimond')}</StyledDaimondsText>
                                <StyledAuctionTypeImage className="width-6em" src="/images/sub-domain/daimond.png" />
                            </StyledDaimondCon>
                        }
                        {isSmallDevices &&
                            <span>
                                <StyledAuctionTypeImage className="width-6em" src="/images/sub-domain/daimond.png" />
                                <StyledDaimondsTextMobile>{t('common:subDomain:daimodsInfo:daimond')}</StyledDaimondsTextMobile>
                            </span>
                        }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        {!isSmallDevices &&
                            <StyledDaimondCon>
                                <StyledDaimondsText>{t('common:subDomain:daimodsInfo:auction')}</StyledDaimondsText>
                                <StyledAuctionTypeImage src="/images/sub-domain/auction.png" />
                            </StyledDaimondCon>
                        }
                        {isSmallDevices &&
                            <span>
                                <StyledAuctionTypeImage src="/images/sub-domain/auction.png" />
                                <StyledDaimondsTextMobile>{t('common:subDomain:daimodsInfo:auction')}</StyledDaimondsTextMobile>
                            </span>
                        }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        {!isSmallDevices &&
                            <StyledDaimondCon>
                                <StyledDaimondsText>{t('common:subDomain:daimodsInfo:jewellery')}</StyledDaimondsText>
                                <StyledAuctionTypeImage src="/images/sub-domain/jewellery.png" />
                            </StyledDaimondCon>
                        }
                        {isSmallDevices &&
                            <span>
                                <StyledAuctionTypeImage src="/images/sub-domain/jewellery.png" />
                                <StyledDaimondsTextMobile>{t('common:subDomain:daimodsInfo:jewellery')}</StyledDaimondsTextMobile>
                            </span>
                        }
                    </Grid>
                </Grid>
            </StyledTypeImgContainer>
            <StyledCompanyInfo item xs={12} container spacing={0} className="justifyContent">
                <Grid item xs={12} sm={12} md={12} lg={11} xl={11} container spacing={0}>
                    <Grid item xs={5}  className="alignCenter">
                        <StyledComInfoImage src="/images/sub-domain/company-info.PNG" />
                    </Grid>
                    <StyledCompContent item xs={7}>
                        <StyledTitle>{t('common:subDomain:companyInfo:about')}</StyledTitle>
                        <StyledName >{t('common:subDomain:banner:companyName')}</StyledName>
                        <StyledBorderBottom />
                        <StyledDescription >{t('common:subDomain:companyInfo:companyDescription')}</StyledDescription>
                    </StyledCompContent>
                </Grid>
            </StyledCompanyInfo>
            <StyledAuctionCon item xs={12} container spacing={0}>
                <div className="paddingLeftRight">
                    <img src="/images/sub-domain/auction-img.PNG" />
                    <Grid item xs={6} className="content">
                        <StyledTitle>{t('common:subDomain:auctionInfo:auction')}</StyledTitle>
                        <StyledName >{t('common:subDomain:auctionInfo:importantDaimond')}</StyledName>
                        <StyledBorderBottom />
                        <StyledDescription>{t('common:subDomain:auctionInfo:auctionDescription')}</StyledDescription>
                    </Grid>
                </div>
            </StyledAuctionCon>
            <StyledRelevantPeople item xs={12} container spacing={0} className="justifyContent">
                <Grid item xs={12} sm={12} md={12} lg={11} xl={11} container spacing={0}>
                    <Grid item xs={5} >
                        <StyledRelevateImage src="/images/sub-domain/relevant-people.PNG" />
                    </Grid>
                    <Grid item xs={7} className="positionRelative">
                        <div className="content">
                            <StyledName>{t('common:subDomain:relevantInfo:meetRelevant')}</StyledName>
                            <StyledBorderBottom />
                            <StyledDescription >{t('common:subDomain:relevantInfo:relevantDescription')}</StyledDescription>
                        </div>
                    </Grid>
                </Grid>
            </StyledRelevantPeople>
            <StyledFooterContainer item={true} xs={12} className="footerContainer">
                <Footer/>
            </StyledFooterContainer>
            <PersonalizedSlider t={t} {...props} />
            {onPromotional === true &&
                <StyledModal open={onPromotional} onClose={onClose}>
                    <Promotional {...props} closeModal={onClose}/>
                </StyledModal>
            }
            <Flotingicon/>
        </StyledContainer>
    )
}

export default SubDomain;