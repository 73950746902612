import React from "react";
import { useTranslation } from "react-i18next";

import TooltipInfo from "../../../../common/elements/TooltipInfo";

import {
  StyledButton,
  StyledTooltipText,
} from "../../PreviewProductDetailsPageStyles";

interface IProps {
  productId: string;
  auctionDate: string;
  category: string;
  shareBtnHandler? : any;
}

const ShareButton = (props: IProps) => {
  const { t } = useTranslation();
  const { shareBtnHandler } = props;

  return (
    <TooltipInfo message={t("common:preview:productDetail:shareTooltip")}>
      <StyledTooltipText>
        <StyledButton onClick={shareBtnHandler}>
          {t("common:preview:productDetail:share")}
        </StyledButton>
      </StyledTooltipText>
    </TooltipInfo>
  );
};

export default ShareButton;
