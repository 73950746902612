import React from "react";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { buttonGradient } from "../../common/materials/LinearGradient";

const StyledGrid = styled(Grid)`
  text-align: center;
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledSorryTextWrapper = styled(Grid)`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 30px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 70px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 100px;
  }
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.darkGrey};
`;

const StyledTextWrapper = styled(Grid)`
  font-size: 30px;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledLinkWrapper = styled.div`
  text-align: center;
  margin-top: 50px;
  border-radius: 10px;
  background: ${buttonGradient};
  color: ${(props) => props.theme.colors.base.white};
  padding: 10px;
  font-size: 20px;
  width: 200px;
  &:hover {
    border: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
`;

interface IProps {}

const PageNotFound = (props: IProps) => {
  const { t } = useTranslation();
  return (
    <Grid container={true} spacing={0}>
      <StyledGrid>
        <StyledSorryTextWrapper>
          {t("common:pageNotFound:oops")}
        </StyledSorryTextWrapper>
        <StyledTextWrapper>
          {t("common:pageNotFound:sorryPageNotFound")}
        </StyledTextWrapper>
        <StyledLinkWrapper>
          <Link to="/">{t("common:pageNotFound:goToHome")}</Link>
        </StyledLinkWrapper>
      </StyledGrid>
    </Grid>
  );
};

export default PageNotFound;
