import React from 'react';
import Styled, { css } from 'styled-components';
import styled from 'styled-components';
import { MenuItem, Menu } from '@material-ui/core';

import { changeApplicationLanguage } from '../../utils/utility';
import AuthService from '../auth/AuthService';
import { preferredlanguageUrl } from '../../common/config/app.endpoints';
import IRouterComponentProps from '../../models/IRouterComponentProps';

const StyledLanguageChooserContainer = Styled.div`
	bottom: 10px;
	display: flex;
	align-items: center;
`;

interface ButtonProps {
	selected?: boolean;
};

const StyledButton = styled.button<ButtonProps>`
    background: ${(props) => props.theme.colors.base.transparent}; 
    padding: 5px 5px;
    color: ${(props) => props.theme.colors.base.white};
    font-size : 1em;
    border-radius: 3px;
    font-family: SF-Display-Medium;
    outline: none;
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.base.white};
    margin-right: 5px;
    &:hover {
        background: ${(props) => props.theme.colors.base.white};
        color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
    }
    ${props => props.selected && css`
        background: ${(props) => props.theme.colors.base.white};
        color: ${(props) => props.theme.colors.base.black};
    `}
	
`;

const StyledImage = styled.img`
	width: 1.3em;
	height: 1.3em; 
	padding: 5px;
`;
const StyledImageCopy = styled.img`
  width: 1.5em;
  height: 1.5em;
  padding: 3px;
`;
const StyledFlag = styled.img`
  cursor: pointer;
  width: 1.6em;
  height: 1.6em;
`;
interface IProps extends IRouterComponentProps {
	t?: any;
	isArrowColor?: boolean;
}
let Flagchooser: any = "";
let ln: any= '';

const LanguageChooser = (props: IProps) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const changeLanguage = (lng: string) => {
		var lang: any = '';
		if (lng === "en") {
      lang = "en";
      window.localStorage.setItem("language", "EN");
    } else if (lng === "zh_CN") {
      lang = "zh_CN";
      window.localStorage.setItem("language", "zh_CN");
    } else {
      lang = "jp";
      window.localStorage.setItem("language", "日本語");
    }
		var token = localStorage.getItem('id_token');
		if (token !== null) {
			const Auth = new AuthService();
			if (token === null) {
				props.history.push('/');
			} else {
				Auth.postRequest(`${preferredlanguageUrl}/${lang}`, "data", (status: number, response: any) => {
					changeApplicationLanguage(lng);
				})
			}
		}
		else {
			changeApplicationLanguage(lng);
		}
		handleClose();
	}

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	ln = localStorage.getItem("language");
	if (ln === "EN") {
		Flagchooser = "/images/languageChooser/en.png";
	} else {
		Flagchooser = "/images/languageChooser/jp.png";
	}

	return (
    <StyledLanguageChooserContainer>
      {localStorage.getItem("language") === null ? (
        <StyledButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <StyledFlag src="/images/languageChooser/jp.png" />
          <StyledImage src="/images/controls/down-arrow.png" />
        </StyledButton>
      ) : (
        <StyledButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <StyledFlag src={Flagchooser} />
          {props.isArrowColor ? (
            <StyledImageCopy src="/images/controls/blueDownArrow.png" />
          ) : (
            <StyledImage src="/images/controls/down-arrow.png" />
          )}
        </StyledButton>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => changeLanguage("en")}>
          <StyledFlag src="/images/languageChooser/en.png" />
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("jp")}>
          <StyledFlag src="/images/languageChooser/jp.png" />{" "}
        </MenuItem>
      </Menu>
    </StyledLanguageChooserContainer>
  );
}

export default (LanguageChooser);