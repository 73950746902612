import React from "react";
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { backgroundGradient, buttonGradient, dateBackgroundGradient } from "../../common/materials/LinearGradient";
import { StyledCardContainer } from "../../common/materials/GenericStyleComponent";
import EllipsisText from "../../common/elements/EllipsisText";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../redux/store";
import { useDispatch } from "react-redux";
import { setParticipateProductId } from "../../redux/slice/commanPagination/commanPaginationSlice";
const StyledAuctionName = styled(Grid)`
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  padding: 0.5em;
  display: flex;
  align-items: center;
  height: 70px;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.white};
  
  .height {
    height: 100%;
  }
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    text-align: center;
`;

const StyledAuctionNameWrapper = styled(Grid)`
  height: 100%;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
  font-size: 1em;
`;

const StyledLabelWrapper = styled.div`
    text-align: center;
    font-size: 0.8em;
`;

const StyledSecondContainer = styled(Grid)`
   height: 50px;
   display: flex;
   align-items: center;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;


interface IPartcipateProps {
  isParticipate?: boolean;
}

const StyledParticipateButton = styled.div<IPartcipateProps>`
    cursor: pointer;
    color: ${(props) => props.theme.colors.base.white};
    background: ${buttonGradient};
    border-radius: 2px;
    width: 90%;
    height: 40px;
    display: flex;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    ${props => props.isParticipate && css`
        cursor: default;
        color: black;
        background: ${dateBackgroundGradient};
    `}
`;

const StyledParticipateButtonWrapper = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledValueConatiner = styled.div`
    text-align: center;
`;

const StyledBidAmount = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const StyledContDaimond = styled.div`
  padding: 0.4em;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  display: flex;
`;

interface IProps {
  code: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  flour: string;
  polish: string;
  symmetryAbbreviation: string;
  caratMinimumPrice: number;
  auctionName: string;
  medias: any;
  auctionId: string;
  serialNo: string;
  id: string;
  auctionSubdomain: string;
  auctionStartDate: string;
  joinedAuctions: any;
  parcelType: string;
  auctionType: string;
  auction: any;
  onParticipate: any;
  jewelleryDetails: any;
  items: any;
  remarks: any;
  noOfPieces: any;
  productDescription: any;
  lab: string;
  certificate: string;
  productEndDate: any;
  shape: any;
  annualProductState: any;
  currency: any;
  exchangeRates: any;
}

let caratMinPrice: string = '';
let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";
let conversionStartingAmount: string = '';
let amountToBeShown: string = '';
let seconds: any = "";

const initialState = {
  mouseX: null,
  mouseY: null,
};



const ProductInSliderHomePage = (props: IProps) => {
  let auctiondemo: string = "";
  let { t } = useTranslation();
  const {
    code,
    parcelType,
    auction,
    auctionType,
    jewelleryDetails,
    carat,
    color,
    serialNo,
    clarity,
    cutGrade,
    flour,
    polish,
    symmetryAbbreviation,
    caratMinimumPrice,
    auctionName,
    medias,
    remarks,
    lab,
    certificate,
    shape,
    currency,
    exchangeRates,
  } = props;

  if (auction !== undefined) {
    if (auction.auctionType !== undefined) {
      auctiondemo = `${auctionType}`;
    }
  }

  caratMinPrice = new Intl.NumberFormat('us-en').format(caratMinimumPrice);

  if (caratMinimumPrice === null || caratMinPrice === 'NaN' || caratMinPrice === '0') {
    caratMinPrice = '-';
  } else {
    let conversionPriceS = new Intl.NumberFormat('us-en').format(Math.round(caratMinimumPrice * exchangeRates[0]['rate']));
    if (currency['code'] === "USD") {
      conversionStartingAmount = `(¥${conversionPriceS})`;
    } else {
      conversionStartingAmount = `($${conversionPriceS})`;
    }

    caratMinPrice = `${currency['symbol']}${caratMinPrice}`

    if (currency['code'] === "USD") {
      amountToBeShown = `¥${conversionPriceS} (${caratMinPrice})`;
    } else {
      amountToBeShown = `${caratMinPrice} ($${conversionPriceS})`;
    }
  }

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === 'PHOTO') {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return (
      <StyledProductImage src={`${medias[0].presignedUrl}`} alt='' />
    )
  }

  const JwlProductDescription = () => {
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (jewelleryDetails.jewelleryType !== undefined && jewelleryDetails.jewelleryType !== null) {
        jewelleryType = jewelleryDetails.jewelleryType;
      }

      if (jewelleryDetails.jewelleryCondition !== undefined && jewelleryDetails.jewelleryCondition !== null) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      }

      if (jewelleryDetails.jewelleryDescription !== undefined && jewelleryDetails.jewelleryDescription !== null) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      }
    }
    return `${code} ${jewelleryType} ${jewelleryCondition} ${jewelleryDescription}`;
  }

  const getProductDescription = () => {
    const CARAT: any = carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`;
    const COLOR: any = color === undefined ? "" : color;
    const CLARITY: any = clarity === undefined ? "" : clarity;
    const CUTGRADE: any = cutGrade === undefined ? "" : cutGrade;
    const FLOUR: any = flour === undefined ? "" : flour;
    const POLISH: any =
      polish === undefined ? "" : `${t("common:preview:pol")}=${polish}`;
    const SYMMETRY_ABBREVATION: any =
      symmetryAbbreviation === undefined
        ? ""
        : `${t("common:preview:sym")}=${symmetryAbbreviation}`;
    const PRODUCT_SHAPE: any =
      shape === undefined || shape === null ? "" : shape;
    return `${code} ${CARAT} ${COLOR} ${PRODUCT_SHAPE} ${CLARITY} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY_ABBREVATION} `;
  };

  const dispatch: AppDispatch = useDispatch();

  const participateButtonClickHandler = (productid: any) => {
    dispatch(setParticipateProductId({ id: productid }));
    props.onParticipate()
  }

  const renderDescriptionSection = () => {
    if (parcelType === "Jewellery") {
      return (
        <StyledTitleWrapper item={true} xs={12} >
          <Grid container={true} spacing={0} className="height">
            <Grid item={true} xs={12}>
              <div>
                <StyledContDaimond>
                  <EllipsisText
                    text={JwlProductDescription()}
                    length={70}
                    tail={"..."}
                  />
                </StyledContDaimond>
              </div>
            </Grid>
            <StyledRemarks item={true} xs={12}>
              <div>
                <StyledRemarkLbl>
                  {t("common:preview:perItemDescription:remarks") + ":"}
                  {remarks !== "" &&
                    remarks !== undefined &&
                    remarks !== null ? (
                    <>
                      <EllipsisText text={remarks} length={25} tail={"..."} />
                    </>
                  ) : (
                    ""
                  )}
                </StyledRemarkLbl>
              </div>
            </StyledRemarks>
          </Grid>
        </StyledTitleWrapper>
      );
    } else {
      return (
        <StyledTitleWrapper item={true} xs={12}>
          <EllipsisText
            text={getProductDescription()}
            length={70}
            tail={"..."}
          />
        </StyledTitleWrapper>
      );
    }
  };

  const renderTimeleftSection = () => {
    const isAnnual = auctionType === "Annual" ? true : false;
    if (isAnnual) {
      return (
        <Grid item={true} xs={3} className="borderRightBlue">
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {t("common:preview:productsList:serialNo")}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner>{serialNo}</StyledValueConatiner>
          </StyledWrapper>
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {lab}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner
              className="fontBold"
              style={{ textAlign: "center", fontSize: "0.5em" }}
            >
              {certificate}
            </StyledValueConatiner>
          </StyledWrapper>
        </Grid>
      );
    } else {
      return (
        <Grid item={true} xs={3} className="borderRightBlue">
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {t("common:preview:productsList:serialNo")}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner>{serialNo}</StyledValueConatiner>
          </StyledWrapper>
          <StyledWrapper>
            <StyledSerialNumberLabelWrapper>
              {lab}
            </StyledSerialNumberLabelWrapper>
            <StyledValueConatiner
              className="fontBold"
              style={{ textAlign: "center", fontSize: "0.5em" }}
            >
              {certificate}
            </StyledValueConatiner>
          </StyledWrapper>
        </Grid>
      );
    }
  }

  return (
    <>
      {parcelType === "Jewellery" ? (
        <StyledCardContainer>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            {renderDescriptionSection()}
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  {/* <span>&#165;</span> */}
                  {/* <span className="fontBold textWrap">{caratMinPrice} {conversionStartingAmount}</span> */}
                  <span className="fontBold textWrap">{amountToBeShown} </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold"></StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              {renderTimeleftSection()}
              <StyledProductImageContainer item={true} xs={9}>
                {medias === null ||
                  medias === undefined ||
                  medias[0] === undefined ||
                  medias[0] === null ||
                  medias[0].presignedUrl === null ||
                  (medias.length === 1 && medias[0].mediaType !== "PHOTO") ? (
                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                ) : (
                  <ShowImage />
                )}
              </StyledProductImageContainer>
            </Grid>
          </Grid>
          <StyledSecondContainer container={true} spacing={0}>
            <StyledAuctionNameWrapper
              item={true}
              xs={8}
              className="borderRightBlue"
            >
              <StyledAuctionName>
                {auctionType === "Annual"
                  ? `${auctionName} (${parcelType})`
                  : `${auctionName}`}
              </StyledAuctionName>
            </StyledAuctionNameWrapper>
            <StyledParticipateButtonWrapper item={true} xs={4}>
              <StyledParticipateButton
                className="fontBold"
                onClick={() =>{
                  // props.onParticipate()
                  participateButtonClickHandler(code);
                }}
              >
                {t("common:landingPage:list:participate")}
              </StyledParticipateButton>
            </StyledParticipateButtonWrapper>
          </StyledSecondContainer>
        </StyledCardContainer>
      ) : (
        <StyledCardContainer>
          <Grid container={true} spacing={0} className="borderBottomBlue">
            {renderDescriptionSection()}
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  {/* <span>&#165;</span> */}
                  {/* <span className="fontBold textWrap">{caratMinPrice}  {conversionStartingAmount}</span> */}
                  <span className="fontBold textWrap">{amountToBeShown} </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                    {t("common:preview:productsList:pct")}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              {renderTimeleftSection()}
              <StyledProductImageContainer item={true} xs={9}>
                {medias === null ||
                  medias === undefined ||
                  medias[0] === undefined ||
                  medias[0] === null ||
                  medias[0].presignedUrl === null ||
                  (medias.length === 1 && medias[0].mediaType !== "PHOTO") ? (
                  <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                ) : (
                  <ShowImage />
                )}
              </StyledProductImageContainer>
            </Grid>
          </Grid>
          <StyledSecondContainer container={true} spacing={0}>
            <StyledAuctionNameWrapper
              item={true}
              xs={8}
              className="borderRightBlue"
            >
              <StyledAuctionName>
                {auctionType === "Annual"
                  ? `${auctionName} (${parcelType})`
                  : `${auctionName}`}
              </StyledAuctionName>
            </StyledAuctionNameWrapper>
            <StyledParticipateButtonWrapper item={true} xs={4}>
              <StyledParticipateButton
                className="fontBold"
                onClick={() =>
                  // props.onParticipate()
                  participateButtonClickHandler(code)
                }
              >
                {t("common:landingPage:list:participate")}
              </StyledParticipateButton>
            </StyledParticipateButtonWrapper>
          </StyledSecondContainer>
        </StyledCardContainer>
      )}
    </>
  );
}
export default ProductInSliderHomePage;