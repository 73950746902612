import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";

const StyledContainer = styled(Grid)`
  padding: 10px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 1em;
  padding: 5px 0;
  display: inline-block;
`;

const StyledLabelConversion = styled.label`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
  display: inline-block;
`;

const StyledInput = styled.input`
  padding: 7px;
  width: 100%;
  text-align: right;
  border: none;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 0.8em;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledGrid = styled(Grid)`
  display: flex;
`;

interface IButtonProps {
  cancel?: boolean;
  solid?: boolean;
  disabled?: boolean;
}

const StyledTextWrapper = styled.div`
  padding: 2px 6px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.grey};
`;

const StyledButtonWrapper = styled(Grid)`
  display: flex;
`;

const StyledButton = styled.button<IButtonProps>`
  padding: 8px 12px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledCancelButton = styled.button<IButtonProps>`
  padding: 8px;
  width: 100px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

export {
  StyledContainer,
  StyledLabel,
  StyledLabelConversion,
  StyledInput,
  StyledInputWrapper,
  StyledGrid,
  StyledTextWrapper,
  StyledButtonWrapper,
  StyledButton,
  StyledCancelButton,
};
