import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const StyledMuiDataTableLayoutGrid = styled(Grid)`
  padding: 20px;
  margin-top: 20px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
  }
  .MuiTableCell-root {
    padding: 16px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .MuiTableHead-root {
    position: sticky;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

export const StyledMuiDataTableBtnContainer = styled.div`
  text-align: center;
  cursor: pointer;
`;