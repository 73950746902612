import React, { useRef } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Menu } from "@mui/material";
import { Button, makeStyles } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledMenuItem } from "../../../pages/LandingPage/Header/StyledComponent";
import Colors from "../../../common/materials/colors";

const StyledRelativeComponent = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
`;

const StyledButton = styled(Button)`
  color: #000f7e;
  text-transform: capitalize;
  font-weight: 600;
  &:focus {
    outline: none;
  }
`;

const StyledMenuContainer = styled.div``;
const faChevronDownProps = faChevronDown as IconProp;
const useStyles = makeStyles({
  paper: {
    borderTop: `4px solid ${Colors.darkBlue}`,
  },
});
interface Iprops{
  menuData: any;
}
const HeaderMenu = (props: Iprops) => {

  let { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuSpace = useRef(null);
  let history = useHistory();
  let headerMenuItems = useRef(null);


  const handleClick = (event: any) => {
    headerMenuItems.current = event.currentTarget.id;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (url: string) => {
    history.push(url);
    handleClose();
  };

  const classes = useStyles();

  const headerButtonHandler = async (routeName: string) => {
    history.push(routeName);
  };

  return (
    <StyledRelativeComponent>
      {props.menuData.map((item: any, index: any) => {
        return (
          <>
            {item.subNav === undefined ? (
              <StyledButton
                onClick={(e: any) => {
                  headerButtonHandler(item.path);
                }}
                className={
                  history.location.pathname === item.path ? "is-active" : ""
                }
              >
                <span>{t(item.title)}</span>&nbsp;
              </StyledButton>
            ) : (
              <StyledButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                key={index}
                onClick={handleClick}
                id={item.path}
                className={
                  history.location.pathname.includes(item.path)
                    ? "is-active"
                    : ""
                }
              >
                <span>{t(item.title)}</span>&nbsp;
                <FontAwesomeIcon
                  icon={faChevronDownProps}
                  style={{ color: Colors.bidBtnBackground }}
                />
              </StyledButton>
            )}
          </>
        );
      })}
      <StyledMenuContainer ref={menuSpace}></StyledMenuContainer>
      {props.menuData
        .filter((item: any) => item.path === headerMenuItems.current)
        .map((subItems: any, index: any) => {
          return (
            <div key={index}>
              {subItems.subNav === undefined || subItems.subNav === null ? (
                <></>
              ) : (
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  classes={classes}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{ mt: "35px", fontSize: "0.8em" }}
                >
                  {subItems.subNav && (
                    <>
                      {subItems.subNav.map((subItem: any, subIndex: any) => {
                        return (
                          <StyledMenuItem
                            key={subIndex}
                            className={
                              history.location.pathname.includes(subItem.path)
                                ? "is-active"
                                : ""
                            }
                            onClick={() => handleRedirect(subItem.path)}
                          >
                            {t(subItem.title)}
                          </StyledMenuItem>
                        );
                      })}
                    </>
                  )}
                </Menu>
              )}
            </div>
          );
        })}
    </StyledRelativeComponent>
  );
};

export default React.memo(HeaderMenu);
