import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useRef,
} from "react";
import { Dialog, DialogProps } from "@material-ui/core";

export type DialogOption = DialogProps; //Omit<DialogProps, "open">;

type ProviderContext = readonly [(option: DialogOption) => void, () => void];

const DialogContext = createContext<ProviderContext>(
  null as unknown as ProviderContext
);

// display only one dialog at a time.
// Multiple dialogs are not supported.
export const DialogProvider = ({ children }: PropsWithChildren<{}>) => {
  const [{ children: dialogChildren, ...props }, setDialogChildren] =
    React.useState({} as DialogOption);

  const createDialog = (dialogChildren: DialogOption) => {
    setDialogChildren({
      ...dialogChildren,
      open: true,
      onClose(event, reason) {
        if (reason === "backdropClick") {
          closeDialog();
        }
        dialogChildren.onClose && dialogChildren.onClose({}, "escapeKeyDown");
      },
    });
  };

  const closeDialog = () => {
    setDialogChildren({} as DialogOption);
  };

  const contextValue = useRef([createDialog, closeDialog] as const);
  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      <Dialog {...props}>{dialogChildren}</Dialog>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const result = useContext(DialogContext);
  if (!result) {
    throw new Error("Dialog context is only available inside its provider");
  }
  return result;
};
