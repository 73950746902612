import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import styled from "styled-components";
import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { useTranslation } from "react-i18next";

export const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  background: ${(props) => props.theme.colors.base.transparent};
  border-bottom: 5px solid ${(props) => props.theme.colors.base.grey};
`;

const StyledBtnContainer = styled(Grid)`
  .MuiGrid-container {
    display: none;
  }
  .MuiAppBar-colorPrimary {
    background: #ffffff;
    color: #000000;
  }
  .MuiPaper-elevation4 {
    box-shadow: none;
  }
  .MuiTabs-root {
    display: block;
  }
  .MuiTabs-fixed {
    width: auto;
  }
  .MuiTabs-indicator {
    background-color: ${(props) =>
      props.theme.colors.base.buttonGradientFirstColor};
    height: 3px;
  }
`;

const StyledBtnContent = styled(Grid)`
  text-align: center;
`;


interface IProps extends IRouterComponentProps {
  t: any;
  tabClick: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const CartSection = (props: IProps) => {
  let {t} = useTranslation()
  const classes = useStyles();
  useEffect(() => {
    let val: any = localStorage.getItem("tabcartSelectedVal");
    setValue(val);
  }, []);
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    props.tabClick(newValue);
  };

  return (
    <StyledBtnContainer container={true} spacing={0}>
      <StyledBtnContent item={true} xs={12}>
        <div className={classes.root}>
          <TabContext value={value}>
            <AppBar position="static">
              <TabList onChange={handleChange}>
                <Tab label={`${t("common:newCommanTrans:Bidder")}`} value="1" />
                <Tab label={`${t("common:newCommanTrans:Seller")}`} value="2" />
              </TabList>
            </AppBar>
          </TabContext>
        </div>
      </StyledBtnContent>
    </StyledBtnContainer>
  );
};

export default CartSection;
