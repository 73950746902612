import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import { publicApi, setToken } from "../../../services/ApiService";

import { tokenUrl, signupUrl } from "../../../common/config/app.endpoints";
import Auth from "../../../services/auth/AuthorizationService";
interface Token {
  token: string;
}
interface ILoginRequest {
  username: string;
  password: string;
}

interface ISignupRequest {}

const initialState = {
  loading: false,
  error: "",
  token: "",
  //isLoggedIn: false,
};

export const login = createAsyncThunk<
  ILoginRequest,
  Partial<ILoginRequest>,
  { rejectValue: any }
>("auth/login", async (data, { rejectWithValue }) => {
  try {
    const response = await publicApi.post(tokenUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const signup = createAsyncThunk<
  ISignupRequest,
  Partial<ISignupRequest>,
  { rejectValue: any }
>("auth/signup", async (data, { rejectWithValue }) => {
  try {
    const response = await publicApi.post(signupUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Note: As of now we are not using token from state.
    // We have token in localstorage and AuthorizationService is taking care of that.
    // setJwtToken(state, action: PayloadAction<Token>) {
    //   const data = action.payload;
    //   state.token = data.token;
    //   state.isLoggedIn = true;
    // },
    // logOut(state) {
    //   state.isLoggedIn = false;
    //   state.token = "";
    // },
    resetReduxState() {
      // Code handled on this action is present in reducer.ts.
      // Over there we are resetting the state to default
    },
  },
  extraReducers: (builder: any) => {
    // login
    builder.addCase(login.pending, genericPendingCaseHandler);
    builder.addCase(login.fulfilled, (state: any, action: any) => {
      state.loading = true;
      state.error = "";
      // Note: As of now we are not using token from state.
      // We have token in localstorage and AuthorizationService is taking care of that.
      //state.token = action.payload.token;
      // setToken(state.token);
      // Auth.setToken(state.token);

      const token = action.payload.token;

      // Note: setToken() function is setting token in API headers. If we dont set then API call just after login call gets failed.
      setToken(token);
      Auth.setToken(token);
    });
    builder.addCase(login.rejected, genericRejectedCaseHandler);
    // signup
    builder.addCase(signup.pending, genericPendingCaseHandler);
    builder.addCase(signup.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(signup.rejected, genericRejectedCaseHandler);
  },
});

export const { resetReduxState } = authSlice.actions;

export default authSlice.reducer;

// selectors
export const selectAuthLoading = (state: RootState) => state.auth.loading;
