import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import ProductListGridatWatchlist from './ProductListGridatWatchlist';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import JewelleryProductListGridatWatchlist from './JewelleryProductListGridatWatchlist';
import { StyledName } from './ProductListAtWatchlist';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

interface IProducts {
    id: number | string;
    name: string;
    brandName?: string;
    price: number;
    serialNumber: number;
    preBiddingPrice: number;
}

interface IProps extends IRouterComponentProps{
    t?: any;
    selectedPage?: boolean;
    currentRecords?: IProducts;
    removeFromWatchlist: any;
}

interface IState {
    products: [];
    openToast: boolean;
    showAlertMessage: string;
}

export default class JewelleryProductListAtWatchlist extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            products: [],
            openToast: false,
            showAlertMessage: ''
        };
    }

    setProducts(products: any) {
        this.setState(() => ({
            products
        }))
    }

    componentDidMount() {
        this.setProducts(this.props.currentRecords);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.currentRecords !== this.props.currentRecords) {
            this.setProducts(prevProps.data);
        }
    }

    showAlert = (message: string) => {
        this.setState({
            openToast: true,
            showAlertMessage: message
        })
    }

    render() {
        const { t } = this.props;
        return (
            <StyledGrid container={true} spacing={0}>
                <StyledName item={true} xs={12} lg={12}>{
                    this.state.products.slice(0, 1).map((product: any, index: number) => {
                        return product?.auctionName;
                    })
                }</StyledName>                
                {
                    this.state.products.map((product: any, index: number) => {
                        return <Grid key={index} item ={true} xs={12} sm={6} md={6} lg={3} xl={2}>
                            <JewelleryProductListGridatWatchlist {...this.props} {...product} t={t} showAlert={this.showAlert} removeFromWatchlist={this.props.removeFromWatchlist} items={product} />
                        </Grid>
                    })
                }
            </StyledGrid>
        )
    }
}