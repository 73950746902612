import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import {
  StyledButton,
  StyledComponent,
} from "../../../../common/materials/GenericStyleComponent";
import {
  backgroundGradient,
  dateBackgroundGradient,
} from "../../../../common/materials/LinearGradient";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ProductDescription from "../../../shared/ProductDescription";
import { convertedValue, getMyLatestStoredBids } from "../TenderGenericFunc";
import { useTranslation } from "react-i18next";
import update from "immutability-helper";
import LoopIcon from "@material-ui/icons/Loop";
import { useHistory } from "react-router-dom";
import { bidButtonHandler } from "./BiddingButtonService";
import { commasButtonRender } from "../../../../common/GenericFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faHeartProps = faHeart as IconProp;

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 450px;
  padding: 0.4em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 950px;
  }
  @media (width: 768px) {
    width: 730px;
  }

  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;

const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const BulkBidButtonContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 40px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
`;
const StyledButtonWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  background: ${(props) => props.theme.colors.base.white};
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 5px;
    padding: 2px 2px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 2px 2px;
    margin-top: 5px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    // padding: 2px 2px;
    font-size: 1em;
  }
`;
const StyledAuctionbidWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
`;
interface IProps {
  items?: any;
  popupClose: any;
  handleBtnValue: (
    values: any[],
    myBidBulkValues: any,
    productId: any,
    bidProductIndexs: any
  ) => void;
  auctionUserId: any;
  length: any;
  productIndexs?: any;
  auctionDate?: any;
  myLatestBidForProducts?: any;
  isJewellery: boolean;
}

let date: string | string[] = "";
let getUpdatedBidValue: any;
let productPricePerCaratUSD: any = 0;
let rapnetPercentage: any = 0;
let bulkBidValue: number = 0;
let getUpdatedRapValue: any;
let tempVal;
let rapPriceMapping: any = {
  perIdMap: {},
  rapnetPercentageMap: {},
};
const AnnualBidPopup = (props: IProps) => {
  let {
    items,
    productIndexs,
    popupClose,
    handleBtnValue,
    auctionUserId,
    myLatestBidForProducts,
    isJewellery,
  } = props;
  const { t } = useTranslation();

  const [bidPriceInputData, setBidPriceInputData] = useState<any>({});
  const [isAllowedBid, setIsAllowedBid] = useState<any>([]);
  const [bidProducts, setBidProduct] = useState<any>(items);

  let history = useHistory();
  const getBidValue = (e: any, value: any) => {
    //bids update in  productBidValue variable
    const targeName = e.target.name;
    const targeValue = value;
    const num = parseInt(targeValue, 10);

    if (!isNaN(num)) {
      const hasProperty = Object.keys(bidPriceInputData).includes(targeName);
      if (hasProperty === false) {
        bulkBidValue = 0;
        tempVal = 0;
      }

      if (hasProperty) {
        tempVal = bulkBidValue + num;
        bidPriceInputData[targeName] = tempVal;
        bulkBidValue = tempVal;
      } else {
        tempVal = 0;
        bidPriceInputData[targeName] = num;
        bulkBidValue = num;
      }
      //somehow update data
      setBidPriceInputData({ ...bidPriceInputData });
    }
    getRapnetPercentage(bulkBidValue, e);
  };

  const getRapnetPercentage = (price: any, e: any) => {
    const targetId = e.target.name;
    const targetName = e.target.name;
    const productIndex = parseInt(targetId, 10);
    rapPriceMapping.perIdMap[targetName] = price;

    const obj = items.find((item: any) => item.perId === targetId);
    if (productIndex !== undefined) {
      if (obj.rapnetPercentage === undefined || obj.rapnetPercentage === 0) {
      } else {
        const totalBidPrice =
          obj.currentBidPrice + rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] = parseFloat(
          ((1 - productPricePerCaratUSD / obj.rapnetPrice) * -100.0).toFixed(2)
        );
      }
    }
  };

  //bulk bid payload
  const bulkBidBtnValuehandle = () => {
    const requestData: any = [];
    const notAllowdBidArray: any = [];
    const requestMyBidData: any = [];
    const requestProductIds: any = [];
    const reuestProductIndexes: any = [];
    let userCompany = localStorage.getItem("companyName");
    productIndexs.map((rowIndex: any) => {
      let indexVal: any =
        rowIndex.index === undefined ? rowIndex : rowIndex.index;
      const bidValue = parseInt(bidPriceInputData[items[indexVal].perId], 10);
      const selfBidObject: any = myLatestBidForProducts?.find(
        (item: any) => item.productId === items[indexVal].perId
      );
      // todo 
      if (bidValue) {
        if (selfBidObject === undefined && bidValue >= 1) {
          const obj = {
            auctionUserId: auctionUserId,
            auctionId: items[indexVal].auctionId,
            productId: items[indexVal].perId,
            bidIncreasedBy: bidValue,
            bidPrice: items[indexVal].currentBidPrice,
            watched: bidProducts[rowIndex]?.watched ? true : false,
            companyName: userCompany,
            serialNo: bidProducts[rowIndex]?.serialNo,
          };

          const productIdObj = items[indexVal].perId;
          const myBidsValueObj = {
            productId: items[indexVal].perId,
            myLatestBid: items[indexVal].currentBidPrice + bidValue,
          };

          let updateRowIndex = myLatestBidForProducts.findIndex(
            (product: any) => product.productId === myBidsValueObj.productId
          );
          if (updateRowIndex === -1) {
            var myLatestBidForProductObjectData: any = [
              ...myLatestBidForProducts,
              myBidsValueObj,
            ];
            myLatestBidForProducts = myLatestBidForProductObjectData;
          } else {
            var updatedArray = update(myLatestBidForProducts, {
              $splice: [[updateRowIndex, 1, myBidsValueObj]],
            });
            myLatestBidForProducts = updatedArray;
          }

          requestData.push(obj);
          requestProductIds.push(productIdObj);
          requestMyBidData.push(myBidsValueObj);
          reuestProductIndexes.push(rowIndex);
        } else {
          if (selfBidObject.myLatestBid < (bidValue + items[indexVal].currentBidPrice)) {
            const obj = {
              auctionUserId: auctionUserId,
              auctionId: items[indexVal].auctionId,
              productId: items[indexVal].perId,
              bidIncreasedBy: bidValue,
              bidPrice: items[indexVal].currentBidPrice,
              watched: bidProducts[rowIndex]?.watched ? true : false,
              companyName: userCompany,
              serialNo: bidProducts[rowIndex]?.serialNo,
            };

            const productIdObj = items[indexVal].perId;
            const myBidsValueObj = {
              productId: items[indexVal].perId,
              myLatestBid: items[indexVal].currentBidPrice + bidValue,
            };

            let updateRowIndex = myLatestBidForProducts.findIndex(
              (product: any) => product.productId === myBidsValueObj.productId
            );
            if (updateRowIndex === -1) {
              var myLatestBidForProductObjectData: any = [
                ...myLatestBidForProducts,
                myBidsValueObj,
              ];
              myLatestBidForProducts = myLatestBidForProductObjectData;
            } else {
              var updatedArray = update(myLatestBidForProducts, {
                $splice: [[updateRowIndex, 1, myBidsValueObj]],
              });
              myLatestBidForProducts = updatedArray;
            }

            requestData.push(obj);
            requestProductIds.push(productIdObj);
            requestMyBidData.push(myBidsValueObj);
            reuestProductIndexes.push(rowIndex);
          } else {
            // need to handle red line condition
            notAllowdBidArray.push(items[indexVal].perId);
            console.log(bidValue, items[indexVal].perId);
          }
        }


      } else {
        const watchObj = {
          auctionUserId: auctionUserId,
          auctionId: items[indexVal].auctionId,
          productId: items[indexVal].perId,
          bidPrice: items[indexVal].currentBidPrice,
          watched: bidProducts[rowIndex]?.watched ? true : false,
          companyName: userCompany,
          serialNo: bidProducts[rowIndex]?.serialNo,
        };
        requestData.push(watchObj);
      }
    });
    setIsAllowedBid(notAllowdBidArray)

    if (notAllowdBidArray.length === 0) {
      handleBtnValue(
        requestData,
        myLatestBidForProducts,
        requestProductIds,
        reuestProductIndexes
      );
      popupClose();
    }
  };

  const getTableHight = () => {
    if (productIndexs.length > 4) {
      return 390;
    }
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    productStartDate: string,
    parcelType: string
  ) => {
    date = productStartDate.split(" ", 2);
    if (parcelType === "Jewellery") {
      history.push(
        `/365products/details/${auctionId}/${auctionUserId}/${perId}/${date[0]}/${false}?auctionType=Jewellery`
      );
    } else {
      history.push(
        `/365products/details/${auctionId}/${auctionUserId}/${perId}/${date[0]}/${false}`
      );
    }
  };

  const getbidButtonRender = (productObj: any, index: any) => {
    let { button1, button2, button3, isFirstBtnEnable } =
      bidButtonHandler(productObj);

    return (
      <StyledAuctionbidWrapper item xs={12}>
        <Grid item xs={4}>
          {isFirstBtnEnable ? (
            <StyledButton
              className="notDisablebutton"
              id={`${index}`}
              name={`${productObj.perId}`}
              onClick={(e) => getBidValue(e, button1)}
            >
              + {productObj.currency.symbol} {button1}
            </StyledButton>
          ) : (
            <StyledButton className="disableButton">
              + {productObj.currency.symbol} {button1}
            </StyledButton>
          )}
        </Grid>
        <Grid item xs={4}>
          <StyledButton
            className="notDisablebutton"
            id={`${index}`}
            name={`${productObj.perId}`}
            onClick={(e) => getBidValue(e, button2)}
          >
            + {productObj.currency.symbol} {button2}
          </StyledButton>
        </Grid>
        <Grid item xs={4}>
          <StyledButton
            className="notDisablebutton"
            name={`${productObj.perId}`}
            id={`${index}`}
            onClick={(e) => getBidValue(e, button3)}
          >
            + {productObj.currency.symbol} {button3}
          </StyledButton>
        </Grid>
      </StyledAuctionbidWrapper>
    );
  };

  const refreshButtonHandler = (
    productCurrentBid: any,
    currentProductId: any
  ) => {
    rapPriceMapping.perIdMap[currentProductId] = 0;
    const obj = items.find((item: any) => item.perId === currentProductId);
    if (currentProductId !== undefined) {
      if (obj.rapnetPercentage === undefined || obj.rapnetPercentage === 0) {
      } else {
        const totalBidPrice = rapPriceMapping.perIdMap[obj.perId];
        if (obj.currency.code === "JPY") {
          productPricePerCaratUSD = totalBidPrice * obj.exchangeRates[0].rate;
        } else {
          productPricePerCaratUSD = totalBidPrice;
        }
        rapPriceMapping.rapnetPercentageMap[obj.perId] = parseFloat(
          ((1 - productPricePerCaratUSD / obj.rapnetPrice) * -100.0).toFixed(2)
        );
      }
    }

    if (!isNaN(productCurrentBid)) {
      const hasProperty =
        Object.keys(bidPriceInputData).includes(currentProductId);
      if (hasProperty) {
        var tempVal = 0;
        bidPriceInputData[currentProductId] = tempVal;
        bulkBidValue = 0;
      } else {
        bidPriceInputData[currentProductId] = 0;
        bulkBidValue = 0;
      }
      setBidPriceInputData({ ...bidPriceInputData });
    }
  };

  const bidButtonRender = (items: any, index: number) => {
    let { button1, button2, button3 } =
      bidButtonHandler(items);
    if (items.sellerAuctionUserId === auctionUserId) {
      return (
        <StyledAuctionbidWrapper item xs={12}>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(button1)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(button2)}
            </StyledButton>
          </Grid>
          <Grid item xs={4}>
            <StyledButton className="disableButton">
              + {items.currency.symbol} {commasButtonRender(button3)}
            </StyledButton>
          </Grid>
        </StyledAuctionbidWrapper>
      );
    } else {
      return <>{getbidButtonRender(items, index)}</>;
    }
  };

  const bulkwatchlistHandler = () => {
    let newArr: any = [];
    if (bidProducts.filter((e: any) => e.watched === true).length > 0) {
      bidProducts.map((obj: any) => {
        if (obj.perId !== "") {
          newArr.push({
            ...obj,
            watched: false,
          });
        } else {
          newArr.push(obj);
        }
      });
    } else {
      bidProducts.map((obj: any) => {
        if (obj.perId !== "") {
          newArr.push({
            ...obj,
            watched: true,
          });
        } else {
          newArr.push(obj);
        }
      });
    }
    setBidProduct(newArr);
  };

  const handleWatchlist = async (
    product: any,
    proudctId: any,
    isWatch: boolean
  ) => {
    // let exist = product.some((obj: any) => obj.hasOwnProperty("watched"));
    const newArr: any = await product.map((obj: any, index: any) => {
      if (obj.perId === proudctId) {
        return {
          ...obj,
          watched: isWatch,
        };
      }
      return obj;
    });
    setBidProduct(newArr);
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProducts?.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProducts,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  return (
    <StyledComponent>
      <StyledContainerWrapper>
        <div
          style={{
            background: "lightgray",
            flexDirection: "row",
            display: "flex",
            height: "40px",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid lightgray",
          }}
        >
          <ProductCountContainer style={{ color: "#1e3c63" }}>
            {t("common:Tender:tableColumnNames:bulkBid")}
          </ProductCountContainer>
          <ProductNameContainer className="fontBold">
            <StyledCloseicon
              src="/images/close-button2.ico"
              onClick={popupClose}
            ></StyledCloseicon>
          </ProductNameContainer>
        </div>

        <TableContainer component={Paper} style={{ height: getTableHight() }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={{ border: "1px solid lightgray" }}>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:preview:productsList:serialNo")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                    minWidth: "200px",
                  }}
                >
                  {t("common:Tender:tableColumnNames:details")}
                </TableCell>
                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:liveHighestBid")}
                </TableCell>

                <TableCell
                  style={{
                    border: "1px solid lightgray",
                  }}
                >
                  {t("common:Tender:tableColumnNames:yourBidPrice")}
                </TableCell>
                {isJewellery ? (
                  <></>
                ) : (
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {t("common:Tender:tableColumnNames:rapOff")}
                  </TableCell>
                )}
                <TableCell
                >
                </TableCell>
                <TableCell>{/* refresh icon */}</TableCell>
                <TableCell>
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    title="Watched"
                    style={{
                      color:
                        bidProducts?.filter((e: any) => e.watched === true)
                          .length > 0
                          ? "grey"
                          : "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      bulkwatchlistHandler();
                    }}
                  />
                </TableCell>
                <TableCell>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productIndexs.slice(0, 10).map((row: any, index: any) => (
                <TableRow key={index} style={{ border: "1px solid lightgray" }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{
                      color: "#1e3c63",
                      textDecoration: "underline",
                      cursor: "pointer",
                      border: "1px solid lightgray",
                    }}
                    onClick={() =>
                      openProductDetail(
                        items[row.index === undefined ? row : row.index]
                          .auctionId,
                        items[row.index === undefined ? row : row.index].perId,
                        items[row.index === undefined ? row : row.index]
                          .productStartDate,
                        items[row.index === undefined ? row : row.index]
                          .parcelType
                      )
                    }
                  >
                    {items[row.index === undefined ? row : row.index].serialNo}
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                      minWidth: "200px",
                      color: isAllowedBid.includes(items[row.index === undefined ? row : row.index].perId) ? "red" : 'black'
                    }}
                  >
                    <ProductDescription
                      truncateLength={65}
                      data={items[row.index === undefined ? row : row.index]}
                      isTendor={true}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                      color: isAllowedBid.includes(items[row.index === undefined ? row : row.index].perId) ? "red" : 'black'
                    }}
                  >
                    {convertedValue(
                      items[row.index === undefined ? row : row.index].currency,
                      items[row.index === undefined ? row : row.index]
                        .currentBidPrice,
                      items[row.index === undefined ? row : row.index]
                        .exchangeRates[0].rate
                    )}
                  </TableCell>
                  <TableCell
                    style={{

                      border: "1px solid lightgray",
                      color: isAllowedBid.includes(items[row.index === undefined ? row : row.index].perId) ? "red" : 'black'
                    }}
                  >
                    {items[row.index === undefined ? row : row.index].myLatestBid !== undefined ? (
                      <>
                        {items[row.index === undefined ? row : row.index].myLatestBid === undefined || items[row.index === undefined ? row : row.index].myLatestBid === null
                          ? "-"
                          : handelMyBidsLabel(
                            items[row.index === undefined ? row : row.index].perId,
                            items[row.index === undefined ? row : row.index].currency,
                            items[row.index === undefined ? row : row.index].exchangeRates[0].rate
                          )}
                      </>) : (
                      <>-</>
                    )}
                  </TableCell>
                  {isJewellery ? (
                    <></>
                  ) : (
                    <TableCell
                      style={{
                        border: "1px solid lightgray",
                      }}
                    >
                      {rapPriceMapping.perIdMap[
                        items[row.index === undefined ? row : row.index].perId
                      ]
                        ? rapPriceMapping.rapnetPercentageMap[
                        items[row.index === undefined ? row : row.index]
                          .perId
                        ]
                        : items[row.index === undefined ? row : row.index]
                          .rapnetPercentage}
                    </TableCell>
                  )}
                  <TableCell
                    style={{
                      border: "1px solid lightgray",
                    }}
                  >
                    {convertedValue(
                      items[row.index === undefined ? row : row.index].currency,
                      (getUpdatedBidValue = parseInt(
                        bidPriceInputData[
                        items[row.index === undefined ? row : row.index].perId
                        ],
                        10
                      )
                        ? items[row.index === undefined ? row : row.index]
                          .currentBidPrice +
                        parseInt(
                          bidPriceInputData[
                          items[row.index === undefined ? row : row.index]
                            .perId
                          ],
                          10
                        )
                        : items[row.index === undefined ? row : row.index]
                          .currentBidPrice),
                      items[row.index === undefined ? row : row.index]
                        .exchangeRates[0].rate
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        refreshButtonHandler(
                          items[row.index === undefined ? row : row.index]
                            .currentBidPrice,
                          items[row.index === undefined ? row : row.index].perId
                        )
                      }
                    >
                      <LoopIcon />
                    </div>
                  </TableCell>
                  <TableCell>
                    <div style={{ cursor: "pointer" }}>
                      {bidProducts[row]?.watched ? (
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => {
                            handleWatchlist(
                              bidProducts,
                              items[row].perId,
                              false
                            );
                          }}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          style={{ color: "grey", cursor: "pointer" }}
                          onClick={() => {
                            handleWatchlist(
                              bidProducts,
                              items[row].perId,
                              true
                            );
                          }}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "250px",
                      border: "1px solid lightgray",
                    }}
                  >
                    <StyledButtonWrapper item xs={12}>
                      {bidButtonRender(
                        items[row.index === undefined ? row : row.index],
                        index
                      )}
                    </StyledButtonWrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <BulkBidButtonContainer>
          {isAllowedBid.length !== 0 && <div style={{ color: 'red', display: "flex", whiteSpace: "pre-line" }}>A higher bid for the highlighted stones already exists. Please cross your existing bid to place the bid.</div>}

          <StyledButton
            onClick={bulkBidBtnValuehandle}
            style={{ float: "right" }}
          >
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        </BulkBidButtonContainer>
      </StyledContainerWrapper>
    </StyledComponent>
  );
};
export default AnnualBidPopup;
