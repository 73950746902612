import React from "react";
const SignIn = (props: any) => {
  return (
    <span {...props} style={{ marginTop: "-4px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
        <path d="M11.875 20.9v-1.4H18.5q.1 0 .2-.1t.1-.2V5.4q0-.1-.1-.2t-.2-.1h-6.625V3.7H18.5q.7 0 1.2.5t.5 1.2v13.8q0 .7-.5 1.2t-1.2.5Zm-1.15-4.525L9.75 15.4l2.4-2.4H3.8v-1.4h8.35l-2.4-2.4.975-1 4.1 4.1Z" />
      </svg>
    </span>
  );
};

export default SignIn;
