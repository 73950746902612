import React, { useEffect, useState } from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";
import { match } from "react-router";
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import {
  StyledModal,
  StyledSelect,
  StyledTextInput,
} from "../common/materials/GenericStyleComponent";
import {
  backgroundGradient,
  buttonGradient,
} from "../common/materials/LinearGradient";
import UploadButton from "../common/materials/UploadButton";
import AuthService from "../components/auth/AuthService";
import LoadingSpinner from "../common/elements/LoadingSpinner";
import {
  kycFilledDataUrl,
  getCurrency,
  getCountry,
  getKYCSubdomains,
  getTimeZonePB,
} from "../common/config/app.endpoints";
import IRouterComponentProps from "../models/IRouterComponentProps";
import KycModal from "../components/kyc/KycModal";
import UploadIdentityCertificate, { useStyles } from "../common/materials/UploadIdentityCertificate";

import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import ja from "date-fns/locale/ja";
import en_US from "date-fns/locale/en-US";
import zh_CN from "date-fns/locale/zh-CN";
import MultipleSelectCheckmarks from "../common/elements/MultipleSelectCheckmarks";
import BankdocumentUploads from "../common/materials/BankDocumentUploads";
import CompanyRegistrationDocument from "../common/materials/CompanyRegistrationDocument";
import Alert from "../common/elements/Alert";

setDefaultLocale("en-US");
registerLocale("ja", ja);
registerLocale("en-US", en_US);
registerLocale("zh-CN", zh_CN);

const StyledHeader = styled.div`
  font-size: 30px;
  text-align: center;
  background: ${backgroundGradient};
  padding: 5px;
  color: white;
`;

const StyledPersonalBankFieldSet = styled.fieldset`
  border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 94%;
  margin-left: 20px;
  border-radius: 30px;
`;

const StyledLegends = styled.legend`
  margin-left: 20px;
  padding: 0.3em;
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  font-weight: bold;
  width: auto;
`;

const StyledGrid = styled(Grid)`
  padding: 0px 15px;
`;
const StyldedContainerMargin = styled(Grid)`
  margin-top: 20px;
`;
const StyledRadioGroup = styled(RadioGroup)`
  padding: 10px;
`;

const StyledConHeader = styled.div`
  font-size: 20px;
  width: 100%;
  background: ${backgroundGradient};
  padding: 5px;
  color: white;
`;
interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) =>
    props.isSubmit &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      border: 1px solid ${(props) => props.theme.colors.base.white};
      color: ${(props) => props.theme.colors.base.white};
      &:hover {
        cursor: default;
        border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
      }
    `}
`;
const StyledButtonContainer = styled.div`
  text-align: right;
  margin: 0px;
  margin-right: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
`;
const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-top: 2em;
  padding-left: 1em;
`;

const StyledCoantainerChild = styled(Grid)`
  border: 0.1px solid ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledCompanyLogoContainer = styled(Grid)`
  display: flex;
  align-items: center;
  //border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor}};
  border-radius: 30px;
  padding: 0.75em;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor} input {
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  }
  .estrikeColor {
    color: red;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    // margin-top: 1em;
  }
`;
const CaptchaConatiner = styled.div`
  width: 100%;
  margin-top: 1em;
  margin-left: 1em;
`;

const StyledEmailContainer = styled(Grid)`
  .emailBackground {
    width: 100%;
    border-radius: 30px;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
`;

const StyledKobustuLicenceContainer = styled(Grid)`
  padding: 14px;
  .asterik {
    color: ${(props) => props.theme.colors.base.red};
  }
`;

const StyledWrapperText = styled.span`
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const Auth = new AuthService();

interface Details {
  userId: string;
}

interface IProps extends IRouterComponentProps {
  match: match<Details>;
}

let obj: any = {};

let timeobjectVal: any;

let selectedHaskobutsulicense: any;
const KycForm = (props: IProps) => {
  useEffect(() => {
    getSignupFilledData();
    getTimeZone();
  }, []);
  let abc: any = [];

  const { t } = props;
  const theme = useTheme();
  const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonSubmit, setIsButtonSubmit] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isKubustuLicence, setIsKubustuLicence] = useState("none");
  const [isVerfied, setIsVerfied] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [userFName, setUserFName] = useState("");
  const [userLName, setUserLName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [userContactNo, setContactNo] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [userBillingAdd, setUserBillingAdd] = useState("");
  const [modeOfCommunication, setModeOfCommunication] = useState("");
  const [preferredLanguage, setPreferredLanguage] = useState("");
  const [timeZone, setTimezone] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [occupations, setOccupations] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [perId, setPerId] = useState("");
  const [registered, setRegisteredFrom] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [lastModifiedDate, setLastModifiedDate] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [bankName, setBankName] = useState("");
  const [swiftNo, setSwiftNo] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [subdomain, setSubdomain] = useState("");
  const [fileKL, setFileKL] = useState({ fileTypeName: "", fileValue: "" });  
  const [fileIC, setFileIC] = useState({ fileTypeName: "", fileValue: "" });
  const [fileICO, setFileICO] = useState({ fileTypeName: "", fileValue: "" });
  const [fileBD, setFileBD] = useState({ fileTypeName: "", fileValue: "" });
  const [invitationCode, setInvitationCode] = useState("");
  const [currencyList, setCurrencyList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    perId: "",
    name: "",
    code: "",
  });
  const [selectedtimeZoneList, setSelectedTimeZoneList] = useState({
    perId: ""
  });
  const [selectedCurrency, setSelectedCurrency] = useState({
    perId: "",
    name: "",
    code: "",
  });
  const [selectedCountrySet, setSelectedCountrySet] = useState({
    per_id: "",
    name: "",
    code: "",
  });
  const [selectedCurrencySet, setSelectedCurrencySet] = useState({
    per_id: "",
    name: "",
    code: "",
  });
  const [selectedTimeZoneBydefault, setSelectedTimeZoneBydefault] = useState({
    per_Id: "",
    name: "",
    code: "",
  });

  //Business_Detail
  const [
    refDateOfRegistrationCertificate,
    setRefDateOfRegistrationCertificate,
  ] = useState(new Date());
  const [refNatureOfBusiness, setRefNatureOfBusiness] = useState<string[]>([]);
  const [incorporationCertificateNo, setIncorporationCertificateNo] =
    useState("");
  //Name of files
  const [companyLogoFileName, setCompanyLogoFileName] = useState("");
  const [incorporationCertificateFileName, setIncorporationCertificateFileName] = useState("");
  const [bankDocumentFileName, setBankDocumentFileName] = useState("");
  const [companyRegistrationDocumentFileName, setCompanyRegistrationDocumentFileName] = useState("");
  const [kobutsuLicenseFileName, setKobutsuLicenseFileName] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  let saveAsDraft: boolean = false;
  let isSubdomain = null;
  const natureOfBusinessOptions = [
    "Diamonds",
    "Jewellery",
    "Watches",
    "Other_Luxury_Items",
  ];
  let optionsNatureOfBusinessValue: string[];

  const getSignupFilledData = () => {
    setIsLoading(true);
    Auth.getRequestWithoutAuthorization(
      `${kycFilledDataUrl}/admin/kyc/signup?id=${props.match.params.userId}`,
      (status: number, response: any) => {
        if (status === 200) {
          setIsLoading(false);
          obj = response;
          setPerId(response.per_id);
          setUserFName(response.first_name);
          setUserLName(response.last_name);
          setUserEmail(response.email);
          setContactNo(response.phone);
          setPreferredLanguage(response.preferred_language_code);
          setUserCompanyName(response.company);
          setOccupations(response.occupations);
          setUserBillingAdd(response.address);
          setState(response.state);
          setCity(response.city);
          if (response.timezone !== null) {
            setTimezone(response.timezone.name);
            setSelectedTimeZoneBydefault(response.timezone);
          }
          if (response.country_detail !== null) {
            setCountry(response.country_detail.name);
          }
          if (response.preferred_currency !== null) {
            setCurrency(response.preferred_currency.name);
          }
          setSelectedCountry(response.country_detail);
          setSelectedCountrySet(response.country_detail);
          if (response.preferred_currency != null) {
            setSelectedCurrency(response.preferred_currency);
          }
          if (response.preferred_currency != null) {
            setSelectedCurrencySet(response.preferred_currency);
          }
          setRegisteredFrom(response.registered_from);
          setLastModifiedDate(response.last_modified_date);
          setCreatedDate(response.created_date);
          if (response.subdomain != "null") {
            if (response.subdomain === "null") {
              setSubdomain("");
            } else {
              setSubdomain(response.subdomain);
            }
          }
          //bank details
          setAccountHolderName(response.bank_detail.account_holder_name);
          setAccountNo(response.bank_detail.account_number);
          setBankName(response.bank_detail.bank_name);
          if (response.bank_detail.bank_swift_no != "null") {
            if (response.bank_detail.bank_swift_no === "null") {
              setSwiftNo("");
            } else {
              setSwiftNo(response.bank_detail.bank_swift_no);
            }
          }
          setBankAddress(response.bank_detail.address);

          setFileKL(response.kobutsu_license);
          setIncorporationCertificateNo(
            response.business_details.incorporation_certificate_number
          );
          getRadioButtonenable(response.has_kobutsu_license);
          //file Name
          if (response.logo != null) {
            setCompanyLogoFileName(response.logo.name);
          }
          if (response.identity_certificate != null) {
            setIncorporationCertificateFileName(
              response.identity_certificate.name
            );
          }
          if (response.bank_document != null) {
            setBankDocumentFileName(response.bank_document.name);
          }
          if (response.business_details.incorporation_certificate != null) {
            setCompanyRegistrationDocumentFileName(
              response.business_details.incorporation_certificate.name
            );
          }
          if (response.kobutsu_license != null) {
            setKobutsuLicenseFileName(response.kobutsu_license.name);
          }
          if (response.business_details.nature_of_business != null) {
            abc.push(response.business_details.nature_of_business);
          }
          if (response.invitation_code != null) {
            setInvitationCode(response.invitation_code);
          }
        } else if (status > 200 && status < 500) {
          setIsLoading(false);
          if (response[0].code !== undefined) {
            setValidationMessage("Error_1");
          } else {
            setValidationMessage("Error_2");
          }
        } else {
          setIsLoading(false);
        }
      }
    );
    
    Auth.getRequestWithoutAuthorization(
      `${getCurrency}`,
      (status: number, response: any) => {
        if (status === 200) {
          setCurrencyList(response);
        }
      }
    );

    Auth.getRequestWithoutAuthorization(
      `${getCountry}`,
      (status: number, response: any) => {
        if (status === 200) {
          setCountryList(response);
        }
      }
    );
  };

  const onNatureOfBussinessChangeHandler = (options: string[]) => {
    setRefNatureOfBusiness(options);
    optionsNatureOfBusinessValue = options;
  };

  const getRadioButtonenable = (value: any) =>{
    if(value === undefined){
     return selectedHaskobutsulicense = "";
    }else{
      selectedHaskobutsulicense = value;
      return selectedHaskobutsulicense;
    }    
  }

  const handleChange = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "userFName") {
      setUserFName(targeValue);
    }
    if (targeName === "userLName") {
      setUserLName(targeValue);
    }
    if (targeName === "userEmail") {
      setUserEmail(targeValue);
    }
    if (targeName === "userCompanyName") {
      setUserCompanyName(targeValue);
    }
    if (targeName === "userContactNo") {
      setContactNo(targeValue.trim());
    }
    if (targeName === "preferredCountry") {
      var filterArray = countryList.filter(function (el) {
        return el["name"] === targeValue;
      });
      setSelectedCountry(filterArray[0]);
      setCountry(targeValue);
    }
    if (targeName === "userBillingAdd") {
      setUserBillingAdd(targeValue);
    }
    if (targeName === "modeOfComm") {
      setModeOfCommunication(targeValue);
    }
    if (targeName === "preferredLanuage") {
      setPreferredLanguage(targeValue);
    }
    if (targeName === "timezone") {
      var timezoneArray = timeZoneList.filter(function (el) {
        return el["name"] === targeValue;
      });
      setTimezone(targeValue);
      setSelectedTimeZoneList(timezoneArray[0]);
    }
    if (targeName === "preferredCurrency") {
      var preferredCurrencyArray = currencyList.filter(function (el) {
        return el["name"] === targeValue;
      });
      setCurrency(targeValue);
      setSelectedCurrency(preferredCurrencyArray[0]);
    }

    if (targeName === "kobustuLicence") {
      if (targeValue === "yes") {
        setIsKubustuLicence("yes");
      } else {
        handleCheckNo();
        setIsKubustuLicence("no");
      }
    }
    if (targeName === "companyLogo") {
      if (e.target.files[0] !== undefined) {
        if (getFileSize(e.target.files[0])) {
          setCompanyLogo(e.target.files[0]);
        } else {
          setCompanyLogo("");
          alert(
            `${t("common:kycFrom:document")} ${
              e.target.files[0].name
            } ${Math.round(e.target.files[0].size / 1024)} ${t(
              "common:kycFrom:shouldBeLess"
            )}`
          );
        }
      }
    }
    if (targeName === "subDomain") {
      setSubdomain(targeValue);
    }
    if (targeName === "accountHolderName") {
      setAccountHolderName(targeValue);
    }
    if (targeName === "accountNo") {
      setAccountNo(targeValue);
    }
    if (targeName === "bankName") {
      setBankName(targeValue);
    }
    if (targeName === "swiftNo") {
      setSwiftNo(targeValue);
    }
    if (targeName === "bankAddress") {
      setBankAddress(targeValue);
    }
    if (targeName === "refDateOfRegistrationCertificate") {
      setRefDateOfRegistrationCertificate(targeValue);
    }
    if (targeName === "refNatureOfBusiness") {
      setRefNatureOfBusiness(targeValue);
    }
    if (targeName === "incorporationCertificateNo") {
      setIncorporationCertificateNo(targeValue);
    }
  };

  const handleCheckNo = () => {
    setRefDateOfRegistrationCertificate(new Date());
    setRefNatureOfBusiness([]);
    setIncorporationCertificateNo("");
  };

  const captchaOnChange = (value: any) => {
    if (value === "" || value === null) {
      setIsVerfied(false);
    } else {
      setIsVerfied(true);
    }
  };

  const getFileSize = (file: any) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize > 10240) {
      return false;
    } else {
      return true;
    }
  };

  const getTimeZone = () => {
    Auth.getRequestWithoutAuthorization(
      getTimeZonePB,
      (status: number, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            setTimeZoneList(response);
          }
        }
      }
    );
  };

  const closeModal = () => {
    setIsShowPopup(false);
    props.history.push("/");
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const formSave = async (e: any, isValidationRequired: boolean) => {
    e.preventDefault();
    var formData = new FormData();
    saveAsDraft = true;
    populateFormData(formData, saveAsDraft);
    if (validationMessage !== "") {
      scrollToTop();
    }
  };

  const formSubmit = async (e: any, isValidationRequired: boolean) => {
    e.preventDefault();
    var formData = new FormData();
    if (isValidationRequired === true) {
      if (formValidation()) {
        if (subdomain !== "" && subdomain !== null) {
          Auth.getRequestWithoutAuthorization(
            `${getKYCSubdomains}?id=${props.match.params.userId}&subDomain=${subdomain}`,
            (status: number, response: any) => {
              if (status === 200) {
                isSubdomain = response.response_check;
                if (isSubdomain === false) {
                  setValidationMessage(`Subdomain already exits`);
                } else {
                  saveAsDraft = false;
                  populateFormData(formData, saveAsDraft);
                }
              }
            }
          );
        } else {
          populateFormData(formData, saveAsDraft);
        }
      }
    } else {
      populateFormData(formData, saveAsDraft);
    }
    if (validationMessage !== "") {
      scrollToTop();
    }
  };

  const formValidation = () => {
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(userEmail.trim());
    const digits = /^[0-9]+$/;

    let checkKLForm = false;
    scrollToTop(); 
    if (
      userFName.trim() === "" &&
      userLName.trim() === "" &&
      userCompanyName.trim() === "" &&
      userEmail.trim() === "" &&
      userContactNo.trim() === "" &&
      userBillingAdd.trim() === ""
    ) {
      setValidationMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (userFName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onFName")}`);
    } else if (userLName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onLName")}`);
    } else if (email !== true) {
      setValidationMessage(`${t("common:register:errorMessage:onEmail")}`);
    } else if (userCompanyName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onCompanyName")}`);
    } else if (userContactNo.length < 10) {
      setValidationMessage(`${t("common:register:errorMessage:phoneNumber")}`);
    } else if (!userContactNo.match(digits)) {
      setValidationMessage(
        `${t("common:register:errorMessage:onMobileNumber")}`
      );
    } else if (country.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onCountry")}`);
    } else if (userBillingAdd.trim() === "") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onBillingAddress")}`
      );
    } else if (preferredLanguage === "") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onPreferredLanguage")}`
      );
    } else if (currency.trim() === "") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onPreferredCurrency")}`
      );
    } else if (incorporationCertificateNo.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onICN")}`);
    } else if (accountHolderName.trim() === "") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onAccHolderName")}`
      );
    } else if (accountNo.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onAccountNo")}`);
    } else if (bankName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onBankName")}`);
    } else if (bankAddress.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onBankAddress")}`);
    } else if (fileBD != null) {
      if (fileBD.fileValue === "") {
        setValidationMessage(
          `${t("common:kycFrom:errorMessage:onBankDocument")}`
        );
      }else{
        checkKLForm = true;
      }
    } else if (fileIC.fileTypeName === "") {
      if (fileIC.fileTypeName === "") {
        setValidationMessage(
          `${t("common:kycFrom:errorMessage:onIdentityCertificate")}`
        );
      }else{
        checkKLForm = true;
      }
    } else if (fileICO.fileTypeName === "") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onCompanyDocument")}`
      );
    } else if (isKubustuLicence === "none") {
      setValidationMessage(
        `${t("common:kycFrom:errorMessage:onSelectKobustuLicense")}`
      );
    } else if (isKubustuLicence === "yes") {
      if (fileKL) {
        if (fileKL.fileTypeName === "") {
          setValidationMessage(
            `${t("common:kycFrom:errorMessage:onKobustuLicense")}`
          );
        }
      } else {
        setValidationMessage(
          `${t("common:kycFrom:errorMessage:onKobustuLicense")}`
        );
      }
    } else if (isKubustuLicence === "no") {
      if (!refDateOfRegistrationCertificate) {
        setValidationMessage(
          `${t("common:kycFrom:errorMessage:onReferenceDORConReferenceDORC")}`
        );
      }else{
        checkKLForm = true;
      }       
    } else {
      checkKLForm = true;
    }
    return checkKLForm;
  };

  const populateFormData = (formData: any, saveAsDraft: any) => {
    setOpenToast(false);
    if (isVerfied === false) {
      setValidationMessage("Please verify captcha");
    } else {
      setValidationMessage("");
      formData.append("perId", perId);
      formData.set("firstName", userFName);
      formData.append("lastName", userLName);
      formData.append("email", userEmail);
      formData.append("company", userCompanyName);
      formData.append("phone", userContactNo);
      if (selectedCountry != null){
        if (selectedCountry["perId"] === undefined) {
          formData.append("countryDetail.perId", selectedCountrySet["per_id"]);
        } else {
          formData.append("countryDetail.perId", selectedCountry["perId"]);
        }
      }
      formData.append("countryDetail.name", selectedCountry["name"]);
      formData.append("countryDetail.code", selectedCountry["code"]);
      if (selectedCurrency != null){
        if (selectedCurrency["perId"] === undefined) {
          formData.append("preferredCurrency.perId", selectedCurrencySet["per_id"]);
        } else {
          formData.append("preferredCurrency.perId", selectedCurrency["perId"]);
        }
      }
      formData.append("preferredCurrency.name", selectedCurrency["name"]);
      formData.append("preferredCurrency.code", selectedCurrency["code"]);
      formData.append("address", userBillingAdd);
      formData.append("preferredLanguageCode", preferredLanguage);
      if (companyLogo !== "") {
        formData.append("logoFile", companyLogo);
      }
      if (subdomain !== null && subdomain !== "null"){
        formData.append("subdomain", subdomain);
      }else{
        formData.append("subdomain", '');
      }
      formData.append("occupations", occupations);
      formData.append("registered_from", registered);
      formData.append("created_date", createdDate);
      formData.append("last_modified_date", lastModifiedDate);
      formData.append("bankDetail.accountHolderName", accountHolderName);
      formData.append("bankDetail.accountNumber", accountNo);
      formData.append("bankDetail.bankName", bankName);
      if (swiftNo !== "") {
        formData.append("bankDetail.bankSwiftNo", swiftNo);
      }
      formData.append("bankDetail.address", bankAddress);
      formData.append("bankDocumentFile", fileBD.fileValue);
      if (isKubustuLicence === "yes") {
        formData.append("kobutsuLicenseFile", fileKL.fileValue);
      }
      if (isKubustuLicence === "yes") {
        formData.append("hasKobutsuLicense", true);
        selectedHaskobutsulicense = true;
      } else{
        formData.append("hasKobutsuLicense", false);
        selectedHaskobutsulicense = false;
      }

      if (selectedtimeZoneList["perId"] === undefined){
        formData.append("timezone.perId", selectedTimeZoneBydefault["per_Id"]);
      }else{
        formData.append("timezone.perId", selectedtimeZoneList["perId"]);
      }        
      formData.append("identityCertificateFile", fileIC.fileValue);
      formData.append("invitationCode", invitationCode);
      let datestr: any = moment(
        new Date(refDateOfRegistrationCertificate).toLocaleString("en-US")
      ).format("YYYY-MM-DD");
      formData.append("businessDetails.dateOfRegistrationCertificate", datestr);
      formData.append("businessDetails.natureOfBusiness", refNatureOfBusiness);
      formData.append(
        "businessDetails.incorporationCertificateNumber",
        incorporationCertificateNo
      );
      formData.append(
        "businessDetails.incorporationCertificateFile",
        fileICO.fileValue
      );
      formData.append("saveAsDraft", saveAsDraft);
      scrollToTop();
      callAPI(formData);
    }
  };

  const onFormSubmit = (e: any) => {
    formSubmit(e, true);
  };
  const onFormSave = (e: any) => {
    formSave(e, false);
  };

  const callAPI = (formData: any) => {
    setIsLoading(true);
    if (saveAsDraft !== true) {
      setIsButtonSubmit(true);
    }    
    Auth.postRequestForKYC(
      `${kycFilledDataUrl}/admin/kyc/submit`,
      formData,
      (status: number, response: any) => {
        if (status === 200) {
          setIsLoading(false);
          if (response !== undefined) {
            if (response.success !== undefined) {
              setIsLoading(false);
              if (saveAsDraft !== true) {
                setIsShowPopup(true);
              }else{
                setAlertType("success");               
                setShowMessage(
                  `${t("common:kycFrom:errorMessage:saveAsDraft")}`
                );
                setOpenToast(true);
              }
              setCompanyLogoFileName("");
              setIncorporationCertificateFileName("");
              setBankDocumentFileName("");
              setCompanyRegistrationDocumentFileName("");
              setKobutsuLicenseFileName("");
            } else if (response.invalid !== undefined) {
              setIsLoading(false);
              alert(response.invalid);
            } else {
              setIsLoading(false);
              alert(response.error);
            }
          }
        } else if (status > 200 && status < 500) {
          setIsLoading(false);
          alert(`${t("common:invalidRequest")}`);
        } else if (status === 500) {
          alert(
            `${t("common:timeOut:somethingWrong")} ${t(
              "common:timeOut:tryAgain"
            )}`
          );
        }
      }
    );
  };

  const onUploadBtnClick = (uploadedFile: any, fileType: any) => {
    if (fileType === "KL") {
      if (uploadedFile !== undefined) {
        if (getFileSize(uploadedFile)) {
          setFileKL({ fileTypeName: fileType, fileValue: uploadedFile });
        } else {
          setFileKL({ fileTypeName: "", fileValue: "" });
          alert(
            `${t("common:kycFrom:document")} ${uploadedFile.name} ${Math.round(
              uploadedFile.size / 1024
            )} ${t("common:kycFrom:shouldBeLess")}`
          );
        }
      }
    }
    if (fileType === "IC") {
      if (uploadedFile !== undefined) {
        if (getFileSize(uploadedFile)) {
          setFileIC({ fileTypeName: fileType, fileValue: uploadedFile });
        } else {
          setFileIC({ fileTypeName: "", fileValue: "" });
          alert(
            `${t("common:kycFrom:document")} ${uploadedFile.name} ${Math.round(
              uploadedFile.size / 1024
            )} ${t("common:kycFrom:shouldBeLess")}`
          );
        }
      }
    }
    if (fileType === "BD") {
      if (uploadedFile !== undefined) {
        if (getFileSize(uploadedFile)) {
          setFileBD({ fileTypeName: fileType, fileValue: uploadedFile });
        } else {
          setFileBD({ fileTypeName: "", fileValue: "" });
          alert(
            `${t("common:kycFrom:document")} ${uploadedFile.name} ${Math.round(
              uploadedFile.size / 1024
            )} ${t("common:kycFrom:shouldBeLess")}`
          );
        }
      }
    }
    if (fileType === "ICO") {
      if (uploadedFile !== undefined) {
        if (getFileSize(uploadedFile)) {
          setFileICO({ fileTypeName: fileType, fileValue: uploadedFile });
        } else {
          setFileICO({ fileTypeName: "", fileValue: "" });
          alert(
            `${t("common:kycFrom:document")} ${uploadedFile.name} ${Math.round(
              uploadedFile.size / 1024
            )} ${t("common:kycFrom:shouldBeLess")}`
          );
        }
      }
    }
  };

  const InputClasses = useStyles();
  return (
    <>
      <Grid container={true} className="justifiyContent">
        <StyledCoantainerChild item={true} xs={10} className="container">
          <StyledHeader className="header">
            {t("common:kycFrom:kycFromHeading")}
          </StyledHeader>
          <Grid container={true} spacing={0} className="registrationCard">
            <Grid item={true} xs={12}>
              <StyledMessage>{validationMessage}</StyledMessage>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledTextInput>
                <TextField
                  required
                  fullWidth
                  name="userFName"
                  autoFocus={true}
                  id="user_name"
                  label={t("common:kycFrom:fName")}
                  type="text"
                  variant="outlined"
                  value={userFName}
                  onChange={handleChange}
                />
              </StyledTextInput>
            </Grid>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledTextInput>
                <TextField
                  required
                  fullWidth
                  name="userLName"
                  autoFocus={true}
                  id="user_last_name"
                  label={t("common:kycFrom:lName")}
                  type="text"
                  variant="outlined"
                  value={userLName}
                  onChange={handleChange}
                />
              </StyledTextInput>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <StyledEmailContainer
              item={true}
              xs={12}
              md={6}
              sm={6}
              lg={6}
              xl={6}
            >
              <StyledTextInput>
                <TextField
                  disabled
                  fullWidth
                  name="userEmail"
                  autoFocus={true}
                  id="user_email"
                  className="emailBackground"
                  label={t("common:profile:legends:email")}
                  type="text"
                  variant="outlined"
                  value={userEmail}
                />
              </StyledTextInput>
            </StyledEmailContainer>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledTextInput>
                <TextField
                  required
                  fullWidth
                  name="userCompanyName"
                  autoFocus={true}
                  id="user_company_name"
                  label={t("common:profile:legends:companyName")}
                  type="text"
                  variant="outlined"
                  value={userCompanyName}
                  onChange={handleChange}
                />
              </StyledTextInput>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledTextInput>
                <TextField
                  required
                  fullWidth
                  name="userContactNo"
                  autoFocus={true}
                  id="user_contact_no"
                  label={t("common:profile:legends:contactNo")}
                  type="text"
                  inputProps={{ maxLength: 13, minLength: 10 }}
                  variant="outlined"
                  value={userContactNo}
                  onChange={handleChange}
                />
              </StyledTextInput>
            </Grid>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledSelect>
                <FormControl variant="outlined" className="" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("common:register:labels:country")}
                  </InputLabel>
                  <Select
                    id="preferredCountry"
                    onChange={handleChange}
                    name="preferredCountry"
                    value={country}
                    label={t("common:register:labels:country")}
                  >
                    {Object.keys(countryList).map((key: any) => (
                      <MenuItem value={countryList[key]["name"]}>
                        {countryList[key]["name"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledSelect>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledTextInput className="shiiping-add">
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={1}
                  name="userBillingAdd"
                  autoFocus={true}
                  id="user_shipping_add"
                  label={t("common:kycFrom:billingAddress")}
                  type="text"
                  variant="outlined"
                  value={userBillingAdd}
                  onChange={handleChange}
                />
              </StyledTextInput>
            </Grid>
            <Grid
              item={true}
              xs={12}
              md={6}
              sm={6}
              lg={6}
              xl={6}
              className="modeOfCommunication"
            >
              <StyledSelect>
                <FormControl variant="outlined" className="" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("common:kycFrom:preferredCurrency")}
                  </InputLabel>
                  <Select
                    id=""
                    onChange={handleChange}
                    required
                    name="preferredCurrency"
                    value={currency}
                    label={t("common:kycFrom:preferredCurrency")}
                  >
                    {Object.keys(currencyList).map((key: any) => (
                      <MenuItem value={currencyList[key]["name"]}>
                        {currencyList[key]["name"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledSelect>
            </Grid>
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              {/* {occupations.includes("Auctioneer") ? (
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      name="subDomain"
                      autoFocus={true}
                      id="subDomain"
                      label={t("common:kycFrom:subDomain")}
                      type="text"
                      variant="outlined"
                      value={subdomain}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                ) : ( */}
              <StyledTextInput>
                <TextField
                  fullWidth
                  name="subDomain"
                  autoFocus={true}
                  id="subDomain"
                  label={t("common:kycFrom:subDomain")}
                  type="text"
                  variant="outlined"
                  value={subdomain}
                  onChange={handleChange}
                />
              </StyledTextInput>
              {/* )} */}
            </Grid>
            <Grid item={true} xs={12} md={6} lg={6} sm={6} xl={6}>
              <StyledCompanyLogoContainer container={true}>
                <Grid item={true} xs={12} md={7} sm={12} lg={7} xl={7}>
                  <StyledWrapperText>
                    {t("common:kycFrom:companyLogo")}
                  </StyledWrapperText>
                </Grid>
                <Grid item={true} xs={12} md={5} sm={12} lg={5} xl={5}>
                  <input
                    accept="image/png,image/jpeg,image/bmp,image/svg,image/jpg,image/svg+xml,application/pdf"
                    className={InputClasses.input}
                    id="com_logo"
                    type="file"
                    required
                    name="companyLogo"
                    onChange={handleChange}
                    style={{ marginLeft: "17px" }}
                  />
                </Grid>
                {companyLogoFileName !== undefined ? (
                  <StyledWrapperText>{companyLogoFileName}</StyledWrapperText>
                ) : (
                  <></>
                )}
              </StyledCompanyLogoContainer>
            </Grid>
          </Grid>

          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledSelect>
                <FormControl variant="outlined" className="" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("common:kycFrom:preferredLanuage")}
                  </InputLabel>
                  <Select
                    id=""
                    required
                    onChange={handleChange}
                    value={preferredLanguage}
                    name="preferredLanuage"
                    label={t("common:kycFrom:preferredLanuage")}
                  >
                    <MenuItem value="en">EN</MenuItem>
                    <MenuItem value="jp">日本語</MenuItem>
                  </Select>
                </FormControl>
              </StyledSelect>
            </Grid>
            <Grid item={true} xs={12} md={6} lg={6} sm={6} xl={6}>
              <UploadIdentityCertificate
                t={t}
                {...props}
                uploadBtnClick={onUploadBtnClick}
              />
              {incorporationCertificateFileName !== undefined ? (
                <StyledWrapperText>
                  {incorporationCertificateFileName}
                </StyledWrapperText>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>

          {/* TimeZoneSection */}
          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
              <StyledSelect>
                <FormControl variant="outlined" className="" fullWidth>
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t("common:profile:legends:timezone")}
                  </InputLabel>
                  <Select
                    id=""
                    onChange={handleChange}
                    name="timezone"
                    label={t("common:profile:legends:timezone")}
                    value={timeZone}
                  >
                    {Object.keys(timeZoneList).map((key: any) => (
                      <MenuItem value={timeZoneList[key]["name"]}>
                        {timeZoneList[key]["name"]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </StyledSelect>
            </Grid>
          </Grid>

          {/* bank details*/}
          <StyldedContainerMargin container={true} spacing={0}>
            <StyledConHeader>{t("common:kycFrom:bankDetails")}</StyledConHeader>
            <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      required
                      name="accountHolderName"
                      autoFocus={true}
                      id="acc_holder_name"
                      label={t("common:profile:legends:refaccountHolderName")}
                      type="text"
                      variant="outlined"
                      value={accountHolderName}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      required
                      name="bankName"
                      autoFocus={true}
                      id="bank_name"
                      label={t("common:profile:legends:bankName")}
                      type="text"
                      variant="outlined"
                      value={bankName}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      required
                      name="bankAddress"
                      autoFocus={true}
                      id="bank_add"
                      label={t("common:profile:legends:bankAddress")}
                      type="text"
                      variant="outlined"
                      value={bankAddress}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      required
                      name="accountNo"
                      autoFocus={true}
                      id="acc_no"
                      label={t("common:profile:legends:refaccountNo")}
                      type="text"
                      variant="outlined"
                      value={accountNo}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      name="swiftNo"
                      autoFocus={true}
                      id="swift_no"
                      label={t("common:profile:legends:refifscCode")}
                      type="text"
                      variant="outlined"
                      value={swiftNo}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                  <BankdocumentUploads
                    t={t}
                    {...props}
                    uploadBtnClick={onUploadBtnClick}
                  />
                  {bankDocumentFileName !== undefined ? (
                    <StyledWrapperText>
                      {bankDocumentFileName}
                    </StyledWrapperText>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </StyldedContainerMargin>
          <Grid container spacing={0}>
            <StyldedContainerMargin container={true} spacing={0}>
              <StyledConHeader>
                {t("common:kycFrom:kobustuLicense")}
              </StyledConHeader>
              <StyledKobustuLicenceContainer item xs={12}>
                <StyledWrapperText>
                  {t("common:kycFrom:kobustuLicenceQuestion")}
                  <span className="asterik">*</span>
                </StyledWrapperText>
              </StyledKobustuLicenceContainer>
            </StyldedContainerMargin>
          </Grid>

          <FormControl style={{ display: "inline" }} required>
            <StyledRadioGroup
              aria-label="gender"
              name="kobustuLicence"
              defaultValue={selectedHaskobutsulicense === true ? "yes" : "no"}
              onChange={handleChange}
              className=""
            >
              <Grid container={true} spacing={0}>
                <StyledGrid item xs={12} sm={6} md={6} lg={6}>
                  <Grid>
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label={t("common:kycFrom:yes")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label={t("common:kycFrom:no")}
                    />
                  </Grid>
                </StyledGrid>
                <StyledGrid item xs={12} sm={6} md={6} lg={6}>
                  {isKubustuLicence === "yes" ? (
                    <>
                      <UploadButton
                        t={t}
                        {...props}
                        uploadBtnClick={onUploadBtnClick}
                      />
                      <>
                        {kobutsuLicenseFileName !== undefined ? (
                          <StyledWrapperText>
                            {kobutsuLicenseFileName}
                          </StyledWrapperText>
                        ) : (
                          <></>
                        )}
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                </StyledGrid>
              </Grid>
            </StyledRadioGroup>
          </FormControl>

          <StyldedContainerMargin container={true} spacing={0}>
            <StyledConHeader>
              {t("common:kycFrom:businessRegistration:headingName")}
            </StyledConHeader>
            <Grid container spacing={0}>
              <StyledKobustuLicenceContainer item xs={12}>
                <Grid>{t("common:kycFrom:businessRegistration:heading")}</Grid>
              </StyledKobustuLicenceContainer>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}>
                  <StyledTextInput>
                    <TextField
                      fullWidth
                      autoFocus={true}
                      name="incorporationCertificateNo"
                      required
                      id="incorporationCertificateNo"
                      label={t(
                        "common:kycFrom:businessRegistration:iCNumber"
                      )}
                      variant="outlined"
                      value={incorporationCertificateNo}
                      onChange={handleChange}
                    />
                  </StyledTextInput>
                  <StyledSelect>
                    <MultipleSelectCheckmarks
                      options={natureOfBusinessOptions}
                      onChangeHandler={onNatureOfBussinessChangeHandler}
                      selectedOptions={abc}
                    />
                  </StyledSelect>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12} md={12} lg={12} sm={12} xl={12}>
                  <StyledPersonalBankFieldSet>
                    <StyledLegends>
                      {t(
                        "common:kycFrom:businessRegistration:dateOfIncorporation"
                      )}
                    </StyledLegends>
                    <DatePicker
                      name="refDateOfRegistrationCertificate"
                      required
                      id="refDateOfRegistrationCertificate"
                      selected={refDateOfRegistrationCertificate}
                      onChange={(date: any) =>
                        setRefDateOfRegistrationCertificate(date)
                      }
                      dateFormat="yyyy/MM/dd"
                      startDate={refDateOfRegistrationCertificate}
                      className="react-datepicker-wrapper"
                    />
                  </StyledPersonalBankFieldSet>
                  <StyledCompanyLogoContainer container={true}>
                    <Grid
                      item={true}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: "20px" }}
                    >
                      <CompanyRegistrationDocument
                        t={t}
                        {...props}
                        uploadBtnClick={onUploadBtnClick}
                      />
                      {companyRegistrationDocumentFileName !== undefined ? (
                        <StyledWrapperText>
                          {companyRegistrationDocumentFileName}
                        </StyledWrapperText>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </StyledCompanyLogoContainer>
                </Grid>
              </Grid>
              <Grid container={true} spacing={0}>
                <Grid item={true} xs={12}></Grid>
              </Grid>
            </Grid>
          </StyldedContainerMargin>

          <Grid item={true} xs={12}>
            <CaptchaConatiner>
              <ReCAPTCHA
                sitekey="6LcAqFAaAAAAABu6lvUP8NPba-pDejxG9AiMe_qR"
                onChange={captchaOnChange}
                tabindex={2}
                hl={localStorage.getItem("language") === "EN" ? "en" : "ja"}
                size={isExtraSmallDevice ? "compact" : "normal"}
              />
            </CaptchaConatiner>
            <StyledButtonContainer style={{ display: "flex", float: "right" }}>
              <form noValidate={true} name="" onSubmit={onFormSave}>
                <StyledButton onSubmit={onFormSave}>
                  {" "}
                  {t("common:kycFrom:saveAsDraft")}
                </StyledButton>
              </form>
              <form noValidate={true} name="" onSubmit={onFormSubmit}>
                {isButtonSubmit === false ? (
                  <StyledButton
                    isSubmit={isButtonSubmit}
                    onSubmit={onFormSubmit}
                  >
                    {t("common:kycFrom:submit")}
                  </StyledButton>
                ) : (
                  <StyledButton isSubmit={isButtonSubmit}>
                    {t("common:kycFrom:submit")}
                  </StyledButton>
                )}
              </form>
            </StyledButtonContainer>
          </Grid>
          <Grid item={true} spacing={0}>
            {isShowPopup === true && (
              <StyledModal open={isShowPopup} onClose={closeModal}>
                <KycModal t={t} {...props} closeModal={closeModal}></KycModal>
              </StyledModal>
            )}
          </Grid>
        </StyledCoantainerChild>
      </Grid>
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {isLoading === true && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default KycForm;
