import React, { useEffect, useState } from "react";
import { match, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import EllipsisText from "../../../../common/elements/EllipsisText";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { convertedValue } from "../TenderGenericFunc";
import {
  makeLinkClickable,
  openJewelleryCertificateLinkPopup,
} from "../../../../common/GenericFunctions";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import {
  selecLiveCurrentApiStartCount,
  selectLiveItemPerPage,
} from "../../../../redux/slice/commanPagination/commanPaginationSlice";
import CustomFooter from "../../../365Auction/component/CustomFooter";
import PreviewPrebiding from "../bulkWatchlistComponent/PreviewPrebiding";
import Alert from "../../../../common/elements/Alert";

const faHeartProps = faHeart as IconProp;

const StyledLayoutGrid = styled(Grid)`
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
  }
  .MuiTableCell-root {
    padding: 14px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    background: ${(props) => props.theme.colors.base.tableRowBackground};
    max-width: 100px;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .MuiTableCell-footer {
    padding: 1px;
  }

  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;
const StyledCoreDataGridContainer = styled(Grid)``;

export const StyledButtonView = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

export const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;

export const Styledview = styled.img`
  height: 1em;
  width: 1em;
  &:hover {
    cursor: pointer;
  }
`;

const columnIndexes = {
  serialNo: 0,
  productId: 1,
  productCode: 3,
  lab: 4,
  certificate: 5,
  description: 6,
  currency: 7,
  remarks: 8,
  note: 9,
  startingPrice: 10,
  rowColor: 11,
  exchange: 12,
  watched: 13,
  sellerAuctionUserId: 15,
  parcelType: 16,
};

interface IProps {
  products?: any;
  auctionName: string;
  watchlist: any;
  auctionDate: any;
  removeFromWatchlist: any;
  auctionId: string;
  dataSort: (val: any, ordr: any) => void;
  getUpdateData: (start: any, end: any) => void;
  totalElements: any;
  itemPerPageForTable: any;
  endCount: any;
  setexportIds: any;
  selectedExportId: any;
  prebidCall: any;
}

let auctioneerSellerUserId: any = "";
let currentPageNumber = 0;
let isReadOnlyUser: any;
let isSellerAuctionUserId: any;

const initialState = {
  mouseX: null,
  mouseY: null,
};
let sourceFromUrl: any;
let indexsForBulkBidItems: any = [];

const JewelleryTableContainer = (props: IProps) => {
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });
  const {
    auctionName,
    products,
    removeFromWatchlist,
    watchlist,
    auctionId,
    totalElements,
    getUpdateData,
    selectedExportId,
    prebidCall,
  } = props;

  const [auctionProducts, setAuctionProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [isSort, setIsSort] = useState<boolean>(false);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const { t } = useTranslation();
  const history = useHistory();

  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selecLiveCurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectLiveItemPerPage(state)
  );

  useEffect(() => {
    PubSub.subscribe("jwlPageNumber", (msg: any, data: any) => {
      currentPageNumber = data - 1;
    });
    setAuctionProducts(products);
    setIsLoading(true);
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    var sellerAuctionUserId: any;
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (items.perId === auctionId) {
          auctioneerSellerUserId = items.auctionUserId;
        }
        if (auctioneerSellerUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    return () => {
      localStorage.removeItem("pathname");
      setIsAuctionUserId(false);
      localStorage.removeItem("auctioneerUserId");
    };
  }, []);

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable = h - 250;
    if (products.length > 7) {
      return `${heightOfInnerTable}px`;
    } else {
      return `auto`;
    }
  };

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isSellerAuctionUserId = true;
        }
      });
    } else {
      isSellerAuctionUserId = false;
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isSellerAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "S. No",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              title={`Add Product into Wishlist`}
            >
              {isReadOnlyUser ? (
                <FontAwesomeIcon
                  icon={faHeartProps}
                  style={{ color: "gray" }}
                  onClick={() => {
                    renderReadOnlyUserToast(
                      tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                    );
                  }}
                />
              ) : (
                <>
                  {isAuctionUserId ? (
                    <div>
                      {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                      auctioneerSellerUserId ? (
                        <>
                          {getWatchedProduct(
                            tableMeta.rowData[columnIndexes.watched],
                            tableMeta.rowData[columnIndexes.productId],
                            props.auctionId,
                            tableMeta.rowData[1],
                            removeFromWatchlist,
                            watchlist
                          )}
                        </>
                      ) : (
                        <FontAwesomeIcon
                          icon={faHeartProps}
                          style={{ color: "gray" }}
                          onClick={() => {
                            renderReadOnlyUserToast(
                              tableMeta.rowData[
                                columnIndexes.sellerAuctionUserId
                              ]
                            );
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeartProps}
                      style={{ color: "gray" }}
                      onClick={() => {
                        renderReadOnlyUserToast(
                          tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                        );
                      }}
                    />
                  )}
                </>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:sku")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.auctionId,
                  tableMeta.rowData[1],
                  props.auctionDate
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, tableMeta.rowData[1])
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openJewelleryCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "jewelleryDetails",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <EllipsisText
              text={getDescription(value)}
              length={70}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          let isClickableRemark: any;
          isClickableRemark = makeLinkClickable(
            tableMeta.rowData[columnIndexes.remarks]
          );
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {isClickableRemark !== false ? (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      isClickableRemark,
                      "DescriptiveWindowName",
                      "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                    );
                  }}
                >
                  <EllipsisText
                    text={tableMeta.rowData[columnIndexes.remarks]}
                    length={70}
                    tail={"..."}
                  />
                </span>
              ) : (
                <EllipsisText
                  text={tableMeta.rowData[columnIndexes.remarks]}
                  length={70}
                  tail={"..."}
                />
              )}
            </div>
          );
        },
      },
    },
    {
      name: "note",
      label: "Notes",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:startingPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchange][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "yourbid",
      label: `Place Bid`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "160px", maxWidth: "160px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {getPreBidPrice(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchange][0].rate,
                products[tableMeta.rowIndex]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "preBidPrice",
      label: `Your Bid`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "160px", maxWidth: "160px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {value !== 0 || value !== null || value !== undefined
                ? convertedValue(
                    tableMeta.rowData[columnIndexes.currency],
                    value ?? 0,
                    tableMeta.rowData[columnIndexes.exchange][0].rate
                  )
                : "-"}
            </div>
          );
        },
      },
    },
    {
      name: "parcelType",
      label: "",
      options: {
        display: false,
      },
    },
  ];

  const getPreBidPrice = (
    currency: any,
    priceValue: any,
    exchangeRateVal: any,
    items: any
  ) => {
    return (
      <PreviewPrebiding
        {...props}
        items={items}
        count={0}
        apiCall={prebidCall}
        isReadOnlyUser={isReadOnlyUser}
        isMobileUi={false}
      />
    );
  };

  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any,
    removeFromWatchlist: any,
    addToWatchList: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray" }}
          onClick={() => addToWatchList(productId, auctionId, serialNo)}
        />
      );
    }
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    if (sourceFromUrl === "admin") {
      history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}?redirectFromPreview=true`
      );
    } else {
      history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}`
      );
    }
  };
  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
     const language = localStorage.getItem("language");

     const languageCode =
       language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    if (sourceFromUrl === "admin") {
      window.open(
        `/${languageCode}/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}?redirectFromPreview=true`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}`,
        "_blank"
      );
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    rowsSelected: selectedExportId,
    selectableRowsHideCheckboxes: false,
    count: totalElements,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    sort: true,
    page: selectcurrentApiStartCountValue,
    isRowSelectable: (dataIndex: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    onChangePage: (currentPage: number) => {
      currentPageNumber = currentPage;
      getUpdateData(
        currentPage * selectItemPerPageValue,
        selectItemPerPageValue
      );
      localStorage.setItem(
        "currentStart",
        JSON.stringify(currentPage * selectItemPerPageValue)
      );
    },
    customFooter: (
      count,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage,
      textLabels
    ) => {
      return (
        <CustomFooter
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
          textLabels={textLabels}
        />
      );
    },
    onSearchChange: () => {
      //
    },
    setRowProps: () => {
      return { style: { background: "white" } };
    },
    onColumnSortChange: (price, direction) => {
      let order = "";
      if (isSort === false) {
        order = "desc";
      } else {
        order = "asc";
      }
      setIsSort(!isSort);
      props.dataSort(price, order);
    },
    tableBodyHeight: getTableBodyHeight(),
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      rowsSelected.map((indexs: any) => {
        let { sellerAuctionUserId } = products[indexs];
        if (sellerAuctionUserId === auctioneerSellerUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (rowsSelected.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
      props.setexportIds(rowsSelected.slice(0, 10));
    },
  };

  const getDisableRowIndex = () => {
    return products.map((items: any, index: number) => {
      if (isReadOnlyUser) {
        return index;
      } else {
        if (auctioneerSellerUserId === items.sellerAuctionUserId) {
          return index;
        }
      }
    });
  };

  const getDescription = (jewelleryDetails: any) => {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }
    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {};
  return (
    <StyledLayoutGrid container={true}>
      {products.length > 0 ? (
        <>
          <StyledCoreDataGridContainer container={true} spacing={0}>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={""}
                data={products}
                columns={columns}
                options={options}
              />
            </MuiThemeProvider>
          </StyledCoreDataGridContainer>
        </>
      ) : (
        <Grid container={true} spacing={0}>
          <StyledTextWrapper item xs={12}>
            {t("common:preview:noProductFound")}
          </StyledTextWrapper>
        </Grid>
      )}
      <Menu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() =>
            openDetailsView(
              props.auctionId,
              currentProductId,
              props.auctionDate
            )
          }
        >
          Details
        </MenuItem>
      </Menu>
      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isSellerAuctionUserId}
        />
      )}
      {openToast && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
    </StyledLayoutGrid>
  );
};

export default JewelleryTableContainer;
