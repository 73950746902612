import React from "react";
import { useTranslation } from "react-i18next";
import ProductDescription from "../../../../components/shared/ProductDescription";

import {
  StyledProductName,
  StyledItemDesc,
} from "../../PreviewProductDetailsPageStyles";

interface IProps {
  productDetails: any;
}

const ItemDescription = (props: IProps) => {
  const { t } = useTranslation();
  const { productDetails } = props;

  return (
    <StyledItemDesc item={true} xs={12}>
      {t("common:preview:productDetail:itemDescription")}
      {`:`} {" "}
      <StyledProductName>
        <ProductDescription truncateLength={90} data={productDetails} />
      </StyledProductName>
    </StyledItemDesc>
  );
};

export default ItemDescription;
