import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import EllipsisText from "../../../common/elements/EllipsisText";
import { StyledCertificateValue } from "./PerItemDescription";
import { makeLinkClickable, openJewelleryCertificateLinkPopup } from "../../../common/GenericFunctions";

const StyledItemGrid = styled(Grid)``;

const StyledItemContainer = styled(Grid)`
  border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  font-size: 0.8em;
  width: 95%;
  margin: 0 auto;
  min-height: 40px;
  display: flex;
  align-items: center;
  .moveToLandingPageLink {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
`;

const StyledHeading = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
`;

const StyledValue = styled(Grid)`
  font-size: 1em;
  max-height: 100px;
`;

export interface IState {
  showNote: boolean;
}

export interface IProps {
  t: any;
  auctionId: string | number;
  serialNo: string | number;
  code: string;
  carat: string;
  origin: string;
  caratMinimumPrice: number;
  startingPrice: number;
  rapnetPercentage: number | string | null;
  hnc: string | null;
  preBidPrice: any;
  note: null | string;
  sellingPriceUsd: any;
  auctionDate: string;
  exchangeRate: string;
  remarks: string;
  isPDView: boolean;
  onClickSignIn: () => void;
  parcelType: string;
  sieve: string;
  noOfPieces: number | string | null;
  peicesPerCarat: number | string | null;
  averageSize: any;
  productDescription: string;
  jewelleryDetails: any;
  exchangeRates: any;
  currency: any;
  auctionStartDate: string;
  lab: string;
  certificate: string;
  auctionType: any;
}
let prebidAmount: string = "";
let startingAmount: string = "";
let minimumAmount: string = "";
let conversionStartingAmount: string = "";
let conversionMinimumAmount: string = "";
let totalAmount: string = "";
let conversionTotalAmount: string = "";
let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";

const JewelleryPerItemDescription = (props: IProps) => {
  useEffect(() => {
    prebidAmount = "";
  }, []);

  let {
    t,
    serialNo,
    code,
    caratMinimumPrice,
    startingPrice,
    preBidPrice,
    auctionDate,
    remarks,
    isPDView,
    onClickSignIn,
    noOfPieces,
    jewelleryDetails,
    exchangeRates,
    currency,
    lab,
    certificate,
    auctionType,
  } = props;

  startingAmount = new Intl.NumberFormat("us-en").format(startingPrice);
  minimumAmount = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  prebidAmount = new Intl.NumberFormat("us-en").format(preBidPrice);
  let isClickableRemark: any = makeLinkClickable(remarks);
  
  if (prebidAmount === "0" || prebidAmount === "NaN") {
    prebidAmount = "";
  }

  if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  if (
    startingAmount === null ||
    startingAmount === "NaN" ||
    startingAmount === "0"
  ) {
    startingAmount = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPriceS = new Intl.NumberFormat("us-en").format(
        Math.round(startingPrice * exchangeRates[0]["rate"])
      );
      conversionStartingAmount = `(${userCurrencySymbol}${conversionPriceS})`;
    } else {
      conversionStartingAmount = "";
    }
    startingAmount = `${currency["symbol"]}${startingAmount}`;
  }

  if (
    minimumAmount === null ||
    minimumAmount === "NaN" ||
    minimumAmount === "0"
  ) {
    minimumAmount = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = new Intl.NumberFormat("us-en").format(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionMinimumAmount = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionMinimumAmount = "";
    }
    minimumAmount = `${currency["symbol"]}${minimumAmount} ${conversionMinimumAmount}`;

    // if (parcelType !== 'Jewellery') {
    //   let totAmt = caratMinimumPrice * Number(carat)
    //   if (userCurrencyCode !== currency['code']) {
    //     let conversionTotAmt = new Intl.NumberFormat('us-en').format(Math.round(totAmt * exchangeRates[0]['rate']))
    //     conversionTotalAmount = `(${userCurrencySymbol}${conversionTotAmt})`;
    //   } else {
    //     conversionTotalAmount = ""
    //   }
    //   totalAmount = `${currency['symbol']}${new Intl.NumberFormat('us-en').format(Math.round(totAmt))}`
    // }
  }

  return (
    <StyledItemGrid container={true} spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:serialNo")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {serialNo}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:productDescription")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            <EllipsisText
              text={jewelleryDescription ? jewelleryDescription : ""}
              length={80}
              tail={"..."}
            />
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:jewelleryStartingPrice")}
          </StyledHeading>
          {isPDView ? (
            <StyledValue item={true} xs={6}>
              {minimumAmount}{" "}
            </StyledValue>
          ) : (
            <a
              className="moveToLandingPageLink"
              href="#/"
              onClick={onClickSignIn}
            >
              <span className="moveToLandingPageLink">
                {" "}
                {t("common:preview:perItemDescription:signInToView")}
              </span>
            </a>
          )}
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:jewelleryType")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {jewelleryType}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:lab")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {lab}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:noOfPieces")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {noOfPieces === 0 ? "-" : noOfPieces}
          </StyledValue>
        </StyledItemContainer>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {auctionType === "Annual"
              ? `${t("common:preview:perItemDescription:startDate")}`
              : `${t("common:preview:perItemDescription:auctionStartDate")}`}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {auctionDate}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:itemCode")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {code}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:jewelleryCondition")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {jewelleryCondition}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:exchangeRate")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {exchangeRates[0]["rate"]}
          </StyledValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:certificate")}
          </StyledHeading>
          <StyledCertificateValue item={true} xs={6}>
            {lab !== "AGT" &&
            lab !== "Agt" &&
            lab !== "" &&
            lab !== undefined ? (
              <a
                href="#/"
                onClick={() =>
                  openJewelleryCertificateLinkPopup(lab, certificate)
                }
                className="moveToLandingPageLink"
              >
                {certificate}
              </a>
            ) : (
              <>
                <span style={{ color: "black" }}>{certificate}</span>
              </>
            )}
          </StyledCertificateValue>
        </StyledItemContainer>
        <StyledItemContainer item={true} xs={12}>
          <StyledHeading item={true} xs={6}>
            {t("common:preview:perItemDescription:remarks")}
          </StyledHeading>
          <StyledValue item={true} xs={6}>
            {isClickableRemark !== false ? (
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    isClickableRemark,
                    "DescriptiveWindowName",
                    "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                  );
                }}
              >
                {props.remarks !== "" &&
                props.remarks !== undefined &&
                props.remarks !== null ? (
                  <>
                    <EllipsisText text={remarks} length={25} tail={"..."} />
                  </>
                ) : (
                  ""
                )}
              </span>
            ) : (
              <>
                {props.remarks !== "" &&
                props.remarks !== undefined &&
                props.remarks !== null ? (
                  <>
                    <EllipsisText text={remarks} length={25} tail={"..."} />
                  </>
                ) : (
                  ""
                )}
              </>
            )}
          </StyledValue>
        </StyledItemContainer>
      </Grid>
    </StyledItemGrid>
  );
};

export default JewelleryPerItemDescription;
