import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { backgroundGradient } from "../../../common/materials/LinearGradient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { StyledCardContainer } from "../../../common/materials/GenericStyleComponent";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import EllipsisText from "../../../common/elements/EllipsisText";
import ProductDescription from "../../shared/ProductDescription";
import ReactPlayer from "react-player";
import {
  makeLinkClickable,
  openJewelleryCertificateLinkPopup,
} from "../../../common/GenericFunctions";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Checkbox,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import PreviewPrebiding from "./bulkWatchlistComponent/PreviewPrebiding";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
const faHeartProps = faHeart as IconProp;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  display: flex;
  align-items: center;
  height: 90px;
  font-size: 0.7em;
  color: ${(props) => props.theme.colors.base.white};

  .height {
    height: 100%;
  }
`;

const StyledWishContainer = styled(Grid)`
  background: ${backgroundGradient};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  position: relative;
`;

const StyledNotStartedContainer = styled(Grid)`
  cursor: default;
  background: ${(props) => props.theme.colors.base.lightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledTotalSubProducts = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colors.base.lightGrey};
  width: 100%;
  text-align: center;
  opacity: 0.5;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
  padding: 5px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

const StyledLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 1em;
`;

const StyledValueConatiner = styled.div`
  text-align: center;
`;

const StyledBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledRemarks = styled(Grid)`
  display: flex;

  align-items: center;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  font-size: 1em;
  display: flex;
`;

const StyledContainer = styled(Grid)``;

const StyledContDaimond = styled.div`
  padding: 0.2em;
  font-size: 1.2em;
`;

const StyledPrebidBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const StyledDescriptionContainer = styled.div`
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
interface IProps extends IRouterComponentProps {
  t: any;
  perId: string;
  auctionId: string;
  auctionName: String;
  serialNo: string | number;
  code: string;
  startingPrice: string;
  medias: any;
  auctionInvitationId: any;
  noOfPieces: number | string | null;
  jewelleryDetails: any;
  addToWatchList: any;
  removeFromWatchlist: any;
  auctionDate: any;
  remarks: string;
  watched: boolean | null;
  preBidPrice: any;
  subItems: [];
  caratMinimumPrice: any;
  productDescription: string;
  currency: any;
  exchangeRates: any;
  items: any;
  parcelType: any;
  auctionState: any;
  sellerAuctionUserId: string;
  lab: string;
  certificate: string;
  sortingValue: (price: any, val: any) => void;
  index: number;
  selectedExportId: any[];
  selectItemInGridView: (x: any) => void;
  prebidCall: any;
}

let caratMinPrice: string = "";
let bidAmount: string = "";
let state: string | null = "";

let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";

let conversionCaratMinPrice: string = "";
let conversionBidAmount: string = "";
let direction: string = "desc";
let isReadOnlyUser: any;
let isSellerAuctionUserId: any;
let sourceFromUrl: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
const ProductsListGrid = (props: IProps) => {
  const [disableButton, setDisableButton] = useState(false);
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [isSort, setIsSort] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("xs", "md"));

  let {
    t,
    auctionDate,
    medias,
    watched,
    serialNo,
    perId,
    auctionId,
    remarks,
    preBidPrice,
    jewelleryDetails,
    subItems,
    caratMinimumPrice,
    currency,
    exchangeRates,
    parcelType,
    sellerAuctionUserId,
    certificate,
    lab,
    selectedExportId,
    selectItemInGridView,
    index,
    prebidCall,
    items,
  } = props;

  caratMinPrice = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  bidAmount = new Intl.NumberFormat("us-en").format(preBidPrice);
  let isClickableRemark: any = makeLinkClickable(remarks);
  useEffect(() => {
    caratMinPrice = "";
    bidAmount = "";
    setDisableButton(false);
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    return () => localStorage.removeItem("pathname");
  }, []);

  if (
    caratMinimumPrice === null ||
    caratMinPrice === "NaN" ||
    caratMinPrice === "0"
  ) {
    caratMinPrice = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = new Intl.NumberFormat("us-en").format(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionCaratMinPrice = "";
    }
    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;
  }

  if (preBidPrice === null || bidAmount === "NaN" || bidAmount === "0") {
    bidAmount = "-";
    conversionBidAmount = "";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(preBidPrice * exchangeRates[0]["rate"])
      );
      conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
    } else {
      conversionBidAmount = "";
    }
    bidAmount = `${currency["symbol"]}${bidAmount}`;
  }

  if (state !== null) {
    state = localStorage.getItem("auctionState");
  }

  const onPress = (auctionId: string, perId: string, auctionDate: string) => {
    if (sourceFromUrl === "admin") {
      props.history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}?redirectFromPreview=true`
      );
    } else {
      props.history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}`
      );
    }
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    if (sourceFromUrl === "admin") {
      props.history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}?redirectFromPreview=true`
      );
    } else {
      props.history.push(
        `/preview/jewelleryPreview/details/${auctionId}/${perId}/${auctionDate}`
      );
    }
  };

  if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
    if (
      jewelleryDetails.jewelleryType !== undefined &&
      jewelleryDetails.jewelleryType !== null
    ) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }

    if (
      jewelleryDetails.jewelleryCondition !== undefined &&
      jewelleryDetails.jewelleryCondition !== null
    ) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }

    if (
      jewelleryDetails.jewelleryDescription !== undefined &&
      jewelleryDetails.jewelleryDescription !== null
    ) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return <StyledProductImage src={`${medias[0].presignedUrl}`} alt="" />;
  };

  const addProductToWatch = () => {
    props.addToWatchList(perId, auctionId, serialNo);
  };

  const removeProductFromList = () => {
    props.removeFromWatchlist(perId, auctionId);
  };

  const PlayerSlide = ({ url }: { url: string }) => (
    <ReactPlayer
      width="100%"
      height="100%"
      url={url}
      playing={false}
      controls={true}
      muted={true}
      progressInterval={1000}
    />
  );

  const sortingFuction = (price: any, value: any) => {
    props.sortingValue(price, value);
    if (value === "desc") {
      direction = "asc";
    } else {
      direction = "desc";
    }
    setIsSort(!isSort);
  };

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (id === items.auctionUserId) {
          isSellerAuctionUserId = true;
        }
      });
    } else {
      isSellerAuctionUserId = false;
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isSellerAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const rightClickEvent = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  let mediaSection = useMemo(() => {
    if (
      medias !== null ||
      medias !== undefined ||
      medias[0] !== undefined ||
      medias[0] !== null
    ) {
      let index = medias.findIndex((obj: any) => obj.primary === true);
      if (index !== -1) {
        let primaryObj = medias.splice(index, 1)[0];
        medias.unshift(primaryObj);
      }
    }
    return (
      <StyledProductImageContainer
        item={true}
        xs={9}
        onClick={() => onPress(auctionId, perId, auctionDate)}
      >
        {medias === null ||
        medias === undefined ||
        medias[0] === undefined ||
        medias[0] === null ||
        medias[0].presignedUrl === null ||
        medias[0].mediaType === "V360" ? (
          <div>
            {parcelType === "Diamond" ? (
              <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
            ) : (
              <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
            )}
          </div>
        ) : (
          <>
            {medias[0].mediaType === "PHOTO" ? (
              <ShowImage />
            ) : (
              <div>
                {medias[0].mediaType.thumbnailPresignedUrl === null ||
                medias[0].mediaType.presignedUrl === null ? (
                  <div>
                    {parcelType === "Diamond" ? (
                      <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                    ) : (
                      <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
                    )}
                  </div>
                ) : (
                  <PlayerSlide url={`${medias[0].presignedUrl}`} />
                )}
              </div>
            )}
          </>
        )}
        <StyledTotalSubProducts>
          {subItems !== undefined && subItems.length > 0 && (
            // <span>{subItems.length} </span>
            <span>
              {subItems.length} {t("common:preview:products")}
            </span>
          )}
        </StyledTotalSubProducts>
      </StyledProductImageContainer>
    );
  }, [items]);

  const checkBoxrender = () => {
    return (
      <>
        {isReadOnlyUser ? (
          <Checkbox
            id={perId}
            name={perId}
            checked={selectedExportId.indexOf(index) !== -1}
            color="primary"
            disabled
            style={{ color: "grey" }}
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          />
        ) : (
          <Checkbox
            id={perId}
            name={perId}
            checked={selectedExportId.indexOf(index) !== -1}
            color="primary"
            style={{ color: "white" }}
            value={index}
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            onChange={(e: any) => {
              selectItemInGridView(index);
            }}
          />
        )}
      </>
    );
  };

  const videoLinkrender = () => {
    if (isClickableRemark !== false) {
      return (
        <span
          title="Video Link"
          style={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            window.open(
              isClickableRemark,
              "DescriptiveWindowName",
              "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
            );
          }}
        >
          <PlayCircleOutlineIcon />
        </span>
      );
    } else {
      return;
    }
  };

  return (
    <Grid>
      {perId !== undefined && (
        <StyledCardContainer
          id={serialNo.toString()}
          onContextMenu={rightClickEvent}
        >
          <Grid container={true} spacing={0} className="borderBottomBlue">
            <StyledTitleWrapper item={true} xs={10}>
              <Grid container={true} spacing={0} className="height">
                <Grid
                  item={true}
                  xs={2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <>
                    {isReadOnlyUser ? (
                      <Checkbox
                        inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                        disabled
                      />
                    ) : (
                      <>
                        {isAuctionUserId ? (
                          <Checkbox
                            inputProps={{
                              "aria-label": "uncontrolled-checkbox",
                            }}
                            disabled
                          />
                        ) : (
                          checkBoxrender()
                        )}
                      </>
                    )}
                  </>
                  <>{videoLinkrender()}</>
                </Grid>
                <Grid
                  item={true}
                  xs={10}
                  onClick={() => onPress(auctionId, perId, auctionDate)}
                >
                  <StyledDescriptionContainer>
                    <StyledContDaimond>
                      <ProductDescription
                        truncateLength={notSmallDevices ? 65 : 85}
                        data={props.items}
                        isTendor={false}
                      />
                    </StyledContDaimond>
                  </StyledDescriptionContainer>
                </Grid>
                <StyledRemarks item={true} xs={12}>
                  <>
                    <StyledRemarkLbl onClick={() => {
                      if (isClickableRemark !== false) {
                        window.open(
                          isClickableRemark,
                          "DescriptiveWindowName",
                          "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                        );
                      }
                    }}>
                      {t("common:preview:perItemDescription:remarks") + ":"}
                      {props.remarks !== "" &&
                      props.remarks !== undefined &&
                      props.remarks !== null ? (
                        <>
                          <EllipsisText
                            text={remarks}
                            length={40}
                            tail={"..."}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </StyledRemarkLbl>
                  </>
                </StyledRemarks>
              </Grid>
            </StyledTitleWrapper>

            {watched === false || watched === undefined ? (
              <StyledContainer item={true} xs={2}>
                <div>
                  {isReadOnlyUser ? (
                    <StyledNotStartedContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        renderReadOnlyUserToast(sellerAuctionUserId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        className="unwatch imageColor"
                      />
                    </StyledNotStartedContainer>
                  ) : (isAuctionUserId === false ||
                      sellerAuctionUserId === undefined) &&
                    disableButton === false ? (
                    <StyledWishContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        addProductToWatch();
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        className="unwatch"
                      />
                    </StyledWishContainer>
                  ) : (
                    <StyledNotStartedContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        renderReadOnlyUserToast(sellerAuctionUserId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        className="unwatch imageColor"
                      />
                    </StyledNotStartedContainer>
                  )}
                </div>
              </StyledContainer>
            ) : (
              <StyledContainer item={true} xs={2}>
                <div>
                  {isReadOnlyUser ? (
                    <StyledNotStartedContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        renderReadOnlyUserToast(sellerAuctionUserId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        className="unwatch imageColor"
                      />
                    </StyledNotStartedContainer>
                  ) : (isAuctionUserId === false ||
                      sellerAuctionUserId === undefined) &&
                    disableButton === false ? (
                    <StyledWishContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        removeProductFromList();
                      }}
                    >
                      <FontAwesomeIcon icon={faHeartProps} className="watch" />
                    </StyledWishContainer>
                  ) : (
                    <StyledWishContainer
                      item={true}
                      xs={12}
                      onClick={() => {
                        renderReadOnlyUserToast(sellerAuctionUserId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        className="unwatch"
                      />
                    </StyledWishContainer>
                  )}
                </div>
              </StyledContainer>
            )}
          </Grid>
          <Grid container={true} spacing={0}>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <StyledWrapper
                item={true}
                xs={12}
                onClick={() => sortingFuction("caratMinimumPrice", direction)}
              >
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:startingPrice")}
                </StyledLabelWrapper>
                <StyledBidAmount>
                  {/* <span>&#165;</span> */}
                  <span className="fontBold textWrap">
                    {caratMinPrice} {conversionCaratMinPrice}
                  </span>
                  <StyledLabelWrapper className="secondaryLabel fontBold">
                    {" "}
                  </StyledLabelWrapper>
                </StyledBidAmount>
              </StyledWrapper>
            </Grid>
            <Grid container={true} spacing={0} className="borderBottomBlue">
              <Grid item={true} xs={3} className="borderRightBlue">
                <StyledWrapper
                  onClick={() => sortingFuction("serialNo", direction)}
                >
                  <StyledSerialNumberLabelWrapper>
                    {t("common:preview:productsList:serialNo")}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                </StyledWrapper>
                <StyledWrapper>
                  <StyledSerialNumberLabelWrapper
                    onClick={() => sortingFuction("lab", direction)}
                  >
                    {lab}
                  </StyledSerialNumberLabelWrapper>
                  <StyledValueConatiner
                    className="fontBold"
                    style={{ textAlign: "center", fontSize: "0.5em" }}
                  >
                    {lab !== "AGT" &&
                    lab !== "Agt" &&
                    lab !== "" &&
                    lab !== undefined ? (
                      <a
                        href="#/"
                        onClick={() =>
                          openJewelleryCertificateLinkPopup(lab, certificate)
                        }
                        className="moveToLandingPageLink"
                      >
                        {certificate}
                      </a>
                    ) : (
                      <>
                        <span style={{ color: "black" }}>{certificate}</span>
                      </>
                    )}
                  </StyledValueConatiner>
                </StyledWrapper>
              </Grid>
              {mediaSection}
            </Grid>
            <Grid container={true} spacing={0}>
              <StyledWrapper item={true} xs={12}>
                <StyledLabelWrapper className="secondaryLabel fontBold">
                  {t("common:preview:productsList:preBiddingPrice")}
                </StyledLabelWrapper>
                <StyledPrebidBidAmount>
                  <PreviewPrebiding
                    {...props}
                    items={props.items}
                    count={0}
                    apiCall={prebidCall}
                    isReadOnlyUser={isReadOnlyUser}
                    isMobileUi={true}
                  />
                </StyledPrebidBidAmount>
              </StyledWrapper>
            </Grid>
          </Grid>
        </StyledCardContainer>
      )}
      <Menu
        keepMounted
        open={mouseState.mouseY !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          mouseState.mouseY !== null && mouseState.mouseX !== null
            ? { top: mouseState.mouseY, left: mouseState.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => openDetailsView(auctionId, perId, auctionDate)}
        >
          Details
        </MenuItem>
      </Menu>
      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isSellerAuctionUserId}
        />
      )}
    </Grid>
  );
};

export default ProductsListGrid;
