import { Grid, InputBase, withStyles } from "@material-ui/core";
import styled from "styled-components";

// todo: reuse it in every dialog
export const StyledDialogContainer = styled(Grid)`
  position: relative;
  padding: 2em 1em;
`;

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  padding: 1em 0 1em 0.8em;
`;