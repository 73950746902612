class LocalStorageService {
  showLogs = false;
  loggedItems = [];

  private log(value: any) {
    console.log(value);
  }

  showLoggeditems() {
    this.log(JSON.stringify(this.loggedItems));
  }

  private getItem(key: string) {
    const item = localStorage.getItem(key);
    if (this.showLogs) {
      this.log(`logs (getItem) - key: ${key} | val: ${item}`);
    }
    return item;
  }

  setItem(key: string, val: any) {
    if (this.showLogs) {
      this.log(`logs (setItem) - key: ${key} | val: ${val}`);
    }
    // @ts-ignore
    if (!this.loggedItems.includes(key)) {
      // @ts-ignore
      this.loggedItems.push(key);
    }
    return localStorage.setItem(key, val);
  }

  removeItem(key: string) {
    const item = localStorage.getItem(key);
    if (this.showLogs) {
      this.log(`logs (removeItem) - key: ${key} | val: ${item}`);
    }
    localStorage.removeItem(key);
  }

  getIdToken() {
    return this.getItem("id_token");
  }

  getIsNormalUser() {
    return this.getItem("isNormalUser");
  }

  getSubDomain() {
    return this.getItem("subDomain");
  }

  getWSConnectionBreakTime() {
    return this.getItem("WSConnectionBreakTime");
  }

  getAction() {
    return this.getItem("action");
  }

  getAuctionUserId() {
    return this.getItem("AUCTION_USER_ID");
  }
}

const ls = new LocalStorageService();

export default ls;
