import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Colors from "../../../../common/materials/colors";

const useStyles = makeStyles({
  right: {
    transform: "rotate(-90deg)",
  },
  down: {},
  svg: {
    cursor: "pointer",
    "&:hover circle": {
      fill: (props: { filled: any }) =>
        props.filled ? Colors.white : "default",
    },
  },
});

const Arrow = ({
  filled = false,
  direction = "down",
  className,
}: {
  className?: string;
  filled?: boolean;
  direction?: "down" | "right";
}) => {
  const [mouseOver, setMouseOver] = useState(filled);

  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };

  useEffect(() => {
    setMouseOver(filled);
  }, [filled]);

  const classes = useStyles({ filled });

  return (
    <svg
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOver}
      xmlns="http://www.w3.org/2000/svg"
      width="37.351"
      height="37.351"
      viewBox="0 0 37.351 37.351"
      className={`${classes[direction]} ${classes["svg"]}`}
    >
      {mouseOver ? (
        <g
          id="Group_191"
          data-name="Group 191"
          transform="translate(1393.161 -184.249) rotate(90)"
        >
          <circle
            id="Ellipse_244"
            data-name="Ellipse 244"
            cx="17.676"
            cy="17.676"
            r="17.676"
            transform="translate(185.249 1356.809)"
            fill="#293139"
            stroke="#293139"
            strokeWidth="2"
          />
          <g
            id="Group_53"
            data-name="Group 53"
            transform="translate(195.52 1369.943)"
          >
            <line
              id="Line_8"
              data-name="Line 8"
              x2="14.81"
              transform="translate(0 4.542)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="1.78"
            />
            <path
              id="Path_161"
              data-name="Path 161"
              d="M197.832,1364.855l4.542,4.542-4.542,4.542"
              transform="translate(-187.564 -1364.855)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="1.78"
            />
          </g>
        </g>
      ) : (
        <g
          id="Group_70"
          data-name="Group 70"
          transform="translate(1393.161 -184.249) rotate(90)"
        >
          <circle
            id="Ellipse_244"
            data-name="Ellipse 244"
            cx="17.676"
            cy="17.676"
            r="17.676"
            transform="translate(185.249 1356.809)"
            fill="none"
            stroke="#293139"
            strokeWidth="2"
          />
          <g
            id="Group_53"
            data-name="Group 53"
            transform="translate(195.52 1369.943)"
          >
            <line
              id="Line_8"
              data-name="Line 8"
              x2="14.81"
              transform="translate(0 4.542)"
              fill="none"
              stroke="#293139"
              strokeLinecap="round"
              strokeWidth="1.78"
            />
            <path
              id="Path_161"
              data-name="Path 161"
              d="M197.832,1364.855l4.542,4.542-4.542,4.542"
              transform="translate(-187.564 -1364.855)"
              fill="none"
              stroke="#293139"
              strokeLinecap="round"
              strokeWidth="1.78"
            />
          </g>
        </g>
      )}
    </svg>
  );
};

export default Arrow;
