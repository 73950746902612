import React, { useEffect, useRef, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";

import {
  productTextSearch,
  auctionTendorProductUrl,
  productDownload,
  productUrl,
} from "../../../common/config/app.endpoints";

import AppLayout from "../../layouts/AppLayout";
import Alert from "../../../common/elements/Alert";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import ApiTimeout from "../../../modules/ApiTimeout";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import BlockUserModal from "../BlockUser/BlockUserModal";

import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TabLayout from "./TabLayout";
import ProductFilter from "./ProductsFilter";
import ParcelItemFilter from "./ParcelFilter";
import NotesModal from "../BlockUser/NotesModal";
import {
  getAbbreviation,
  makeLinkClickable,
  openCertificateLinkPopup,
} from "../../../common/GenericFunctions";
import EllipsisText from "../../../common/elements/EllipsisText";
import { Menu, MenuItem, useMediaQuery, useTheme } from "@material-ui/core";
import copy from "copy-to-clipboard";
import CoreDataGridOfTenderNew from "./tenderMobileComponets/CoreDataGridOfTenderNew";
import TenderListOfProduct from "./tenderMobileComponets/TenderListOfProduct";
import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRapNet } from "./TenderGenericFunc";
import { StyledHeaderContainer } from "./TendorAuctionView";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import TenderCommanPagination from "../../core-grid/grid-actions/TenderCommanPagination";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";

import {
  resetCurrentApiStartCount,
  reSetJwlIsMybids,
  selectcurrentApiStartCount,
  selectItemPerPage,
  setCurrentApiStartCount,
  setItemPerPage,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import {
  resetTenderIsGridView,
  selectTenderGridView,
  setTenderIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import { setTimeout } from "timers";
import BasicSearchBox from "../../core-grid/grid-actions/BasicSearchBox";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;
interface DetailParams {
  auctionId: string;
  auctionName: string;
  auctionDate: string;
  auctionUserId: string;
}

export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    transition: width 1s;
    width: 100%;
    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
    z-index: 0;
    text-wrap: nowrap;  
  }
  .MuiTableCell-root {
    padding: 14px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    background: ${(props) => props.theme.colors.base.tableRowBackground};
    max-width: 100px;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .MuiTableCell-footer {
    padding: 1px;
  }

  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;
const StyledCoreDataGridContainer = styled(Grid)``;

const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

const StyledContent = styled(Grid)`
  text-align: center;
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledName = styled(Grid)`
  dispaly: flax;
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

export const StyledButtonView = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  margin-left: 10px;
  border: 1px solid transparent;
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;
export const StyledButtonViewFilter = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  margin-left: 5px;
  border: 1px solid transparent;
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

export const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;

export const Styledview = styled.img`
  height: 1em;
  width: 1em;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledCommanDiv = styled.div`
  display: flex;
  width: 100%;
  transition: width 2s;
`;

const StyledBoxContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-top: 4px solid #000f7e;
`;

const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";

let body: any = [];
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 10;
//let seconds: any = '';
let conversionCaratMinPrice: string = "";

let updatePageNumber: any = [];
const columnIndexes = {
  serialNo: 0,
  productId: 1,
  auctionUserId: 2,
  productCode: 4,
  lab: 5,
  certificate: 6,
  description: 7,
  productDescription: 8,
  carat: 9,
  color: 10,
  clarity: 11,
  remarks: 12,
  noOfPieces: 13,
  shape: 14,
  sieve: 15,
  cutGrade: 16,
  polish: 17,
  symmetryAbbreviation: 18,
  flour: 19,
  currency: 20,
  peicePerCt: 21,
  notes: 22,
  repnetPrice: 23,
  rowColor: 24,
  exchangeRate: 25,
  parcelType: 26,
  watched: 27,
  rapnetPercentage: 28,
  sellerAuctionUserId: 29,
  shapeAbbreviation: 30,
  polishAbbreviation: 31,
  flourAbbreviation: 32,
  colorValue: 33,
};

let auctionUserId: string;
let sourceFromUrl: any;
let isReadOnlyUser: any;
let isAuctionUserId: boolean;
const initialState = {
  mouseX: null,
  mouseY: null,
};

const TendorPreviewView = (props: IProductViewProps) => {
  var getMuiTheme = () => createTheme({});
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isShowSearchBox, setShowSearchBox] = useState(true);

  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [userBlock, setUserBlock] = useState(false);
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [optionValue, setOptionValue] = useState("");

  const [isShowParcelItem, setShowParcelItem] = useState(false);
  const [notes, setNotes] = useState();
  const [openNotesPopups, setNotesPopups] = useState(false);
  const [uiBoxView, setUiBoxView] = useState(false);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [exportExcelDownload, setExportExcelDownload] = useState(true);
  const [isSeller, setIsSeller] = useState(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);

  const [isRefresh, setIsRefresh] = useState(false);
  const Auth = new AuthService();
  const { t } = props;

  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  let isAuctionPage: any = false;
  let isCategoryPageType: any = true;
  let auctionUserId: any;

  let tabSelectedVal: number = 1;
  const itemsPerPageUseRef = useRef(10);
  const selectPreviewPageUseRef = useRef(0);

  const isGridView: any = useSelector((state: RootState) =>
    selectTenderGridView(state)
  );

  useEffect(() => {
    setIsLoading(true);
    // localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("currentPageOfJewellery");
    localStorage.removeItem("apiStartCount");
    localStorage.setItem("urlRedirectFromAdmin", window.location.href);
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    if (sourceFromUrl === "admin") {
      setIsSeller(true);
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    setTimeout(() => {
      setIsRefresh(!isRefresh);
    }, 3000);
    return () => {
      localStorage.removeItem("urlRedirectFromAdmin");
    };
  }, []);

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  //redux page selector
  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectcurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "apiStartCount",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    getTendorAucProducts(
      props.match.params.auctionId,
      selectcurrentApiStartCountValue
    );
  }, [selectItemPerPageValue, selectcurrentApiStartCountValue]);

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };

  //getTendorAucProducts function get all filter tender Product

  const getTendorAucProducts = (
    auctionId: number | string,
    currentPage: number
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }
    getBody(auctionId, currentPage, endCount);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number
  ) => {
    payload = {};

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
    }

    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      (filter === null &&
        (filteredValues == null ||
          filteredValues === undefined ||
          filteredValues === "null" ||
          filteredValues.length === 0)) ||
      (filter === null &&
        (filteredParcelValues == null ||
          filteredParcelValues === undefined ||
          filteredParcelValues === "null" ||
          filteredParcelValues.length === 0))
    ) {
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
          ],
        },
        page: {
          start: selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: selectItemPerPageValue,
          sort: {
            orders:
              property === "serialNo"
                ? [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ]
                : [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                  {
                    property: "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ],
          },
        },
      };
    } else {
      if (filteredValues !== null && filteredValues.length > 0) {
        body = filteredValues ? filteredValues : filter;
      } else {
        body = filteredParcelValues ? filteredParcelValues : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });
      if (localStorage.getItem("tabSelectedVal") === "1") {
        body.push({
          match: "exact",
          name: "auctionId",
          value: props.match.params.auctionId,
        });
        payload = {
          filters: {
            and: body,
          },
          page: {
            start: selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: selectItemPerPageValue,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: props.match.params.auctionId,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        payload = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: selectcurrentApiStartCountValue * selectItemPerPageValue,
            count: selectItemPerPageValue,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      }
    }
    var pattern = /^[0-9,]*$/g;
    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      setOptionValue(val);
      let filterOR: any;
      let searchValue: any = val.replace(/ /g, "");
      if (pattern.test(searchValue)) {
        const myArray = searchValue.split(",");
        var filtered = myArray.filter(function (el: string) {
          return el != null && el !== "";
        });
        var convertedArr = filtered.map(function (item: string) {
          return parseInt(item, 10);
        });
        filterOR = [
          {
            match: "in",
            name: "serialNo",
            value: convertedArr,
          },
        ];
      } else {
        filterOR = [
          {
            match: "anywhere",
            name: "productDescription",
            value: val,
          },
          {
            match: "anywhere",
            name: "remarks",
            value: val,
          },
        ];
      }

      payload = {
        filters: {
          or: filterOR,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: selectcurrentApiStartCountValue * selectItemPerPageValue,
          count: selectItemPerPageValue,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(payload);
  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let sourceAuctionProduct =
        sourceFromUrl === "admin"
          ? `${auctionTendorProductUrl}?auctionId=${props.match.params.auctionId}&redirectFromPreview=true`
          : `${auctionTendorProductUrl}?auctionId=${props.match.params.auctionId}`;
      Auth.postRequest(
        sourceAuctionProduct,
        payload,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            totalElement = response.totalElements;
            setAuctionProducts(response.results);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const handleNotes = (productId: any) => {
    setOpenToast(false);
  };
  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    heightOfInnerTable = h - 310;
    if (auctionProducts.length > 7) {
      return `${heightOfInnerTable}px`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    sort: true,
    onChangePage: (currentPage: number) => {
      dispatch(setCurrentApiStartCount({ currentPage }));
    },
    setRowProps: (row) => {
      return { style: { background: "white" } };
    },
    onColumnSortChange: (price, direction) => {
      let order = "";
      if (direction === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
      sort(price, order);
    },
    tableBodyHeight: getTableBodyHeight(),
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any;
    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }

    localStorage.removeItem("apiStartCount");
    if (selectcurrentApiStartCountValue === 0) {
      getTendorAucProducts(props.match.params.auctionId, 0);
    } else {
      dispatch(setCurrentApiStartCount({ currentPage: 0 }));
    }
  };

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: `${t("common:Tender:tableColumnNames:serialNo")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(
                        tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                      );
                    }}
                  />
                ) : (
                  <>
                    {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                      props.match.params.auctionUserId ? (
                      <>
                        {isSeller ? (
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            style={{ color: "gray" }}
                            onClick={() => {
                              renderReadOnlyUserToast(
                                tableMeta.rowData[
                                columnIndexes.sellerAuctionUserId
                                ]
                              );
                            }}
                          />
                        ) : (
                          <>
                            {getWatchedProduct(
                              tableMeta.rowData[columnIndexes.watched],
                              tableMeta.rowData[columnIndexes.productId],
                              props.match.params.auctionId,
                              tableMeta.rowData[1]
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(
                            tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                          );
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(
                    tableMeta.rowData[columnIndexes.productId],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:Itemcode")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, tableMeta.rowData[1])
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "description",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={description(
                tableMeta.rowData[columnIndexes.parcelType],
                tableMeta.rowData[columnIndexes.productDescription],
                tableMeta.rowData[columnIndexes.productCode],
                tableMeta.rowData[columnIndexes.carat],
                tableMeta.rowData[columnIndexes.color],
                tableMeta.rowData[columnIndexes.noOfPieces],
                tableMeta.rowData[columnIndexes.clarity],
                tableMeta.rowData[columnIndexes.cutGrade],
                tableMeta.rowData[columnIndexes.flour],
                tableMeta.rowData[columnIndexes.polish],
                tableMeta.rowData[columnIndexes.symmetryAbbreviation],
                tableMeta.rowData[columnIndexes.shape],
                tableMeta.rowData[columnIndexes.sieve]
              )}
              length={40}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "productDescription",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "carat",
      label: `${t("common:Tender:tableColumnNames:carat")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "color",
      label: `${t("common:Tender:tableColumnNames:color")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <EllipsisText
                text={getAbbreviation(
                  tableMeta.rowData[columnIndexes.colorValue]
                )}
                length={12}
                tail={"..."}
              />
            </>
          );
        },
      },
    },
    {
      name: "clarity",
      label: `${t("common:Tender:tableColumnNames:clarity")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          let isClickableRemark: any;
          if (tableMeta.rowData[columnIndexes.parcelType] === "Parcel") {
            isClickableRemark = makeLinkClickable(
              tableMeta.rowData[columnIndexes.productDescription]
            );
          } else {
            isClickableRemark = makeLinkClickable(
              tableMeta.rowData[columnIndexes.remarks]
            );
          }
          return (
            <>
              {isClickableRemark !== false ? (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      isClickableRemark,
                      "DescriptiveWindowName",
                      "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                    );
                  }}
                >
                  <EllipsisText
                    text={getDescription(
                      tableMeta.rowData[columnIndexes.parcelType],
                      tableMeta.rowData[columnIndexes.productDescription],
                      tableMeta.rowData[columnIndexes.remarks]
                    )}
                    length={20}
                    tail={"..."}
                  />
                </span>
              ) : (
                <EllipsisText
                  text={getDescription(
                    tableMeta.rowData[columnIndexes.parcelType],
                    tableMeta.rowData[columnIndexes.productDescription],
                    tableMeta.rowData[columnIndexes.remarks]
                  )}
                  length={20}
                  tail={"..."}
                />
              )}
            </>
          );
        },
      },
    },
    {
      name: "noOfPieces",
      label: `${t("common:Tender:tableColumnNames:noOfPieces")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== 0 ? value : "-"}</>;
        },
      },
    },
    {
      name: "shape",
      label: `${t("common:Tender:tableColumnNames:shape")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {getAbbreviation(
                tableMeta.rowData[columnIndexes.shapeAbbreviation]
              )}
            </>
          );
        },
      },
    },
    {
      name: "sieve",
      label: `${t("common:Tender:tableColumnNames:Sieve")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== "-" ? value : ""}</>;
        },
      },
    },
    {
      name: "cutGrade",
      label: `${t("common:Tender:tableColumnNames:cutGrade")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <EllipsisText
                text={getAbbreviation(value)}
                length={12}
                tail={"..."}
              />
            </>
          );
        },
      },
    },
    {
      name: "polish",
      label: `${t("common:Tender:tableColumnNames:polish")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {getAbbreviation(
                tableMeta.rowData[columnIndexes.polishAbbreviation]
              )}
            </>
          );
        },
      },
    },
    {
      name: "symmetryAbbreviation",
      label: `${t("common:Tender:tableColumnNames:symmetryAbbreviation")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{getAbbreviation(value)}</>;
        },
      },
    },
    {
      name: "flour",
      label: `${t("common:Tender:tableColumnNames:flour")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {getAbbreviation(
                tableMeta.rowData[columnIndexes.flourAbbreviation]
              )}
            </>
          );
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:pricePct")}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "150px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                textAlign: "center",
              }}
            >
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                handleNotes(tableMeta.rowData[columnIndexes.productId])
              }
            >
              View Notes
            </span>
          );
        },
      },
    },
    {
      name: "rapnetPercentage",
      label: `${t("common:Tender:tableColumnNames:rapOff")}`,
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              {getRapNet(tableMeta.rowData[columnIndexes.rapnetPercentage])}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "parcelType",
      label: "",
      options: {
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "rapnetPercentage",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "shapeAbbreviation",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "polishAbbreviation",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "flourAbbreviation",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "colorValue",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray" }}
          onClick={() => addToWatchList(productId, auctionId, serialNo)}
        />
      );
    }
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number,
    serialNo: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              callApi(payload);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            } else if (status === 403) {
              setUserBlock(true);
            } else if (status === 404) {
              setIsLoading(false);
              setShowAlertMessage(`${t("common:invalidRequest")}`);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            } else if (status === 505) {
              setApiTimeout(true);
              setOnAction(false);
            } else {
              setIsLoading(false);
              setShowAlertMessage(response[0].message);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            }
          } else {
            setIsLoading(false);
            setShowAlertMessage(response);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            callApi(payload);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const shareButtonAction = (perId: any, auctionDate: any) => {
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${perId}/${auctionDate}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getDescription = (
    parcelType: any,
    productDescription: any,
    remarks: any
  ) => {
    if (parcelType === "Diamond") {
      if (remarks !== "" && remarks !== undefined && remarks !== null) {
        return remarks;
      } else {
        return "";
      }
    } else {
      if (
        productDescription !== "" &&
        productDescription !== undefined &&
        productDescription !== null
      ) {
        return productDescription;
      } else {
        return "";
      }
    }
  };

  const description = (
    parcelType: any,
    productDescription: any,
    code: any,
    carat: any,
    color: any,
    noOfPieces: any,
    clarity: any,
    cutGrade: any,
    flour: any,
    polish: any,
    symmetryAbbreviation: any,
    shape: any,
    sieve: any
  ) => {
    if (parcelType === "Diamond") {
      return ` ${carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`
        } ${color === undefined ? "" : `,${color}`} ${clarity === undefined ? "" : `, ${clarity}`
        }, ${cutGrade === undefined ? "" : `, ${cutGrade}`} ${flour === undefined ? "" : `, ${flour}`
        } ${polish === undefined ? "" : `, ${t("common:preview:pol")}= ${polish}`
        } ${symmetryAbbreviation === undefined
          ? ""
          : `, ${t("common:preview:sym")}= ${symmetryAbbreviation}`
        }`;
    } else {
      return `${productDescription === undefined ? " " : `,${productDescription}`
        } ${noOfPieces === undefined &&
          noOfPieces === undefined &&
          noOfPieces === ""
          ? "-"
          : `,${noOfPieces} ${t("common:preview:pieces")}`
        } ${carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`} ${color === undefined ? "" : `,${color}`
        } ${shape === undefined ? "" : `,${shape}`}  ${sieve === undefined && sieve === "" && sieve === null && sieve === 0
          ? ""
          : `,${sieve}`
        }`;
    }
  };

  const closeModal = () => {
    setNotesPopups(false);
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    if (sourceFromUrl === "admin") {
      props.history.push(
        `/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}&redirectFromPreview=true`
      );
    } else {
      props.history.push(
        `/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}`
      );
    }
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    if (sourceFromUrl === "admin") {
      window.open(
        `/${languageCode}/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}&redirectFromPreview=true`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}`,
        "_blank"
      );
    }
  };

  const convertedValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return ``;
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversionPrice = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${currencyCodeVal.symbol}${conversionPrice} (${userCurrencySymbol}${conversion})`;
      } else {
        let conversionPrice = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
        return `${userCurrencySymbol}${conversionPrice}`;
      }
    }
  };

  const exportExcel = () => {
    setExportExcelDownload(false);
    let exportAuctionId = auctionProducts.slice(0, 1).map((item: any) => {
      return item.auctionId;
    });
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportAuctionId}`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          setTimeout(() => {
            setExportExcelDownload(true);
          }, 5000);
        } else {
          //todo
        }
      }
    );
  };

  const autoCompleteSearch = (val: any) => {
    localStorage.setItem("optionValue", val);
    getTendorAucProducts(props.match.params.auctionId, 0);
  };

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  };

  const closeNotesModle = () => {
    setNotesPopups(false);
  };

  const textSearchListing = (value: any) => {
    var pattern = /^[0-9,]*$/g;
    if (!pattern.test(value)) {
      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "remarks",
              value: value,
            },
            {
              match: "anywhere",
              name: "productDescription",
              value: value,
            },
          ],
        },
      };
      if (value === "" || value.length === 1) {
        setTextSearchArray([]);
      } else {
        if (value.length > 1) {
          Auth.postRequest(
            productTextSearch,
            payload,
            (status: any, response: any) => {
              if (status === 200) {
                setTextSearchArray(response.results);
              }
            }
          );
        }
      }
    }
  };

  const tabItemClick = (value: String) => {
    if (value === "2") {
      tabSelectedVal = 2;
      localStorage.setItem("tabSelectedVal", "2");
      setShowParcelItem(true);
    } else {
      tabSelectedVal = 1;
      localStorage.setItem("tabSelectedVal", "1");
      setShowParcelItem(false);
      dispatch(reSetJwlIsMybids());
    }
    getTendorAucProducts(props.match.params.auctionId, 0);
  };

  const filteredAuction = async (
    auctions: any,
    isFilter: boolean,
    values: any,
    isCustomFilter?: any
  ) => {
    localStorage.removeItem("apiStartCount");
    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (values === null) {
      filter = null;
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders:
              property === "serialNo"
                ? [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ]
                : [
                  {
                    property:
                      property !== null && property !== undefined
                        ? property
                        : "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                  {
                    property: "serialNo",
                    direction:
                      direction !== null && direction !== undefined
                        ? direction
                        : "ASC",
                  },
                ],
          },
        },
      };
    } else {
      body = [];
      values.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          values.splice(index, 1);
        }
      });
      body = values;
      if (localStorage.getItem("tabSelectedVal") === "1") {
        body.push({
          match: "exact",
          name: "auctionId",
          value: props.match.params.auctionId,
        });
        payload = {
          filters: {
            and: body,
          },
          page: {
            start: startCount,
            count: endCount,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: props.match.params.auctionId,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        payload = {
          filters: {
            or: body,
            and: andArr,
          },
          page: {
            start: startCount,
            count: endCount,
            sort: {
              orders:
                property === "serialNo"
                  ? [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ]
                  : [
                    {
                      property:
                        property !== null && property !== undefined
                          ? property
                          : "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                    {
                      property: "serialNo",
                      direction:
                        direction !== null && direction !== undefined
                          ? direction
                          : "ASC",
                    },
                  ],
            },
          },
        };
      }

      if (isCustomFilter === undefined) {
        filter = body;
        if (localStorage.getItem("tabSelectedVal") === "1") {
          localStorage.setItem("filteredValues", JSON.stringify(body));
        } else {
          localStorage.setItem("filteredParcelValues", JSON.stringify(body));
        }
      }
    }
    await filterCAllback(payload);
  };

  const filterCAllback = (dataVal: any) => {
    if (selectcurrentApiStartCountValue === 0) {
      callApi(dataVal);
    } else {
      dispatch(setCurrentApiStartCount({ currentPage: 0 }));
    }
  };
  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("currentPageOfPreview", "0");
    setCurrentPage(1);
    dispatch(setItemPerPage({ itemsPerPage }));
    dispatch(resetCurrentApiStartCount());
    itemsPerPageUseRef.current = itemsPerPage;
    setItemsPerPage(itemsPerPage);
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }
  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      //new added storage
      localStorage.setItem("itemPerPageSelected", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("apiStartCount", JSON.stringify(start));
    let currentPage = start / itemsPerPage;
    setIsLoading(true);
    if (currentPage === selectcurrentApiStartCountValue) {
      setIsLoading(false);
    }
    dispatch(setCurrentApiStartCount({ currentPage }));
    dispatch(setItemPerPage({ itemsPerPage }));
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  const setShowBidPopupBidModal = () => {
    // setshowTenderSlider(true);
  };

  const watchlist = () => {
    localStorage.removeItem("selectedFilterSort");
    props.history.push(`/my-page`);
    // props.history.push(
    //   `/watchlist/${props.match.params.auctionId}/${props.match.params.auctionUserId}/${props.match.params.auctionDate}/Popcorn`
    // );
    // dispatch(resetItemPerPage());
    // dispatch(resetCurrentApiStartCount());
  };

  const listGridview = () => {
    selectPreviewPageUseRef.current = selectItemPerPageValue;
    localStorage.setItem(
      "apiStartCount",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    setUiBoxView(true);
    dispatch(resetTenderIsGridView());
  };

  const Gridview = () => {
    dispatch(setTenderIsGridView({ gridView: false }));
    setUiBoxView(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e: any) => {
    const header: any = document.querySelector(".sticky-actionbar");
    const scrollTop = window.scrollY;
    const className =
      scrollTop >= 90
        ? header?.classList.add("is-stickyPreview")
        : header?.classList.remove("is-stickyPreview");
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    // to do
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGridMain>
              <StyledBoxContent title="Click here for advance search">
                <StyledDropDown
                  src="/images/assets/doubledown.png"
                  alt="d"
                  style={{
                    transform: isFiltersOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                  onClick={handleFilters}
                />
              </StyledBoxContent>
              {isFiltersOpen && (
                <div style={{ padding: "20px" }}>
                  <TabLayout {...props} t={t} tabClick={tabItemClick} />
                  {isShowParcelItem === false ? (
                    <ProductFilter
                      {...props}
                      t={t}
                      filteredAuctions={filteredAuction}
                      auctionId={props.match.params.auctionId}
                    />
                  ) : (
                    <ParcelItemFilter
                      {...props}
                      t={t}
                      filteredParcelAuctions={filteredAuction}
                      auctionId={props.match.params.auctionId}
                    />
                  )}
                </div>
              )}
              <StyledCoreDataGridContainer container={true} spacing={0}>
                <StyledActionBarContainer
                  container={true}
                  spacing={0}
                  className="coreActionBarContainer"
                >
                  <StyledContainer>
                    <BasicSearchBox
                      autoCompleteSearch={autoCompleteSearch}
                      textSearchListing={textSearchListing}
                      setTextSearchArray={setTextSearchArray}
                    />
                  </StyledContainer>
                  <StyledButtonHeader onClick={watchlist}>
                    {t("common:newCommanTrans:myPage")}
                  </StyledButtonHeader>
                  {exportExcelDownload ? (
                    <StyledButtonHeader onClick={exportExcel}>
                      {t("common:preview:pagination:export")}
                    </StyledButtonHeader>
                  ) : (
                    <StyledButtonHeader>
                      {t("common:preview:pagination:export")}
                    </StyledButtonHeader>
                  )}
                  <TenderCommanPagination
                    setItemsPerPageHandler={setItemsPerPageHandler}
                    t={t}
                  />
                  {notSmallDevices ? (
                    <>
                      {isGridView === true ? (
                        <StyledButtonView onClick={Gridview}>
                          <Styledview src="/images/view/listView.png" />
                        </StyledButtonView>
                      ) : (
                        <StyledButtonView onClick={listGridview}>
                          <Styledview src="/images/view/gridview.jpeg" />
                        </StyledButtonView>
                      )}
                    </>
                  ) : null}
                </StyledActionBarContainer>
              </StyledCoreDataGridContainer>
              <StyledLayoutGrid
                container={true}
                className="StyledLayoutGridContainer"
              >
                <>
                  {auctionProducts.length > 0 ? (
                    <>
                      <StyledName container={true} spacing={0}>
                        <StyledContent xs={12}>
                          <EllipsisText
                            text={props.match.params.auctionName}
                            length={28}
                            tail={"..."}
                          />
                        </StyledContent>
                      </StyledName>

                      <StyledCoreDataGridContainer
                        container={true}
                        spacing={0}
                      >
                        {isGridView === false && notSmallDevices && (
                          <MuiThemeProvider theme={getMuiTheme()}>
                            {isRefresh ? (
                              <MUIDataTable
                                title={""}
                                data={auctionProducts}
                                columns={columns}
                                options={options}
                              />
                            ) : (
                              <MUIDataTable
                                title={""}
                                data={auctionProducts}
                                columns={columns}
                                options={options}
                              />
                            )}
                          </MuiThemeProvider>
                        )}

                        {isGridView === true
                          ? notSmallDevices && (
                            <>
                              <CoreDataGridOfTenderNew
                                t={t}
                                data={auctionProducts}
                                totalElements={totalElement}
                                itemPerPage={itemPerPage}
                                value={getUpdatedProducts}
                                noProductFound={handleMyBids}
                                itemsPerPageUseRef={selectItemPerPageValue}
                              >
                                {auctionProducts.length > 0 ? (
                                  <TenderListOfProduct
                                    auctionUserId={
                                      props.match.params.auctionUserId
                                    }
                                    {...props}
                                    setShowBidPopupBidModal={
                                      setShowBidPopupBidModal
                                    }
                                    isCategoryPageType={isCategoryPageType}
                                    auctionName={
                                      props.match.params.auctionName
                                    }
                                    auctionDate={
                                      props.match.params.auctionDate
                                    }
                                    watchlist={addToWatchList}
                                    unWatchlist={removeFromWatchlist}
                                    t={t}
                                    isAuctionPage={isAuctionPage}
                                    sortingValue={filterSort}
                                    renderAutomaticBidPopupInMobile={
                                      renderAutomaticBidPopupInMobile
                                    }
                                    renderReadOnlyUserToast={
                                      renderReadOnlyUserToast
                                    }
                                    rightClickEvent={rightClickEvent}
                                  />
                                ) : (
                                  <Grid container={true} spacing={0}>
                                    <StyledTextWrapper item xs={12}>
                                      {t("common:preview:noProductFound")}
                                    </StyledTextWrapper>
                                  </Grid>
                                )}
                              </CoreDataGridOfTenderNew>
                            </>
                          )
                          : null}

                        {notSmallDevices || (
                          <>
                            <CoreDataGridOfTenderNew
                              t={t}
                              data={auctionProducts}
                              totalElements={totalElement}
                              itemPerPage={itemPerPage}
                              value={getUpdatedProducts}
                              noProductFound={handleMyBids}
                              itemsPerPageUseRef={selectItemPerPageValue}
                            >
                              {auctionProducts.length > 0 ? (
                                <TenderListOfProduct
                                  auctionUserId={
                                    props.match.params.auctionUserId
                                  }
                                  {...props}
                                  setShowBidPopupBidModal={
                                    setShowBidPopupBidModal
                                  }
                                  isCategoryPageType={isCategoryPageType}
                                  auctionName={props.match.params.auctionName}
                                  auctionDate={props.match.params.auctionDate}
                                  watchlist={addToWatchList}
                                  unWatchlist={removeFromWatchlist}
                                  t={t}
                                  isAuctionPage={isAuctionPage}
                                  sortingValue={filterSort}
                                  renderAutomaticBidPopupInMobile={
                                    renderAutomaticBidPopupInMobile
                                  }
                                  renderReadOnlyUserToast={
                                    renderReadOnlyUserToast
                                  }
                                  rightClickEvent={rightClickEvent}
                                />
                              ) : (
                                <Grid container={true} spacing={0}>
                                  <StyledTextWrapper item xs={12}>
                                    {t("common:preview:noProductFound")}
                                  </StyledTextWrapper>
                                </Grid>
                              )}
                            </CoreDataGridOfTenderNew>
                          </>
                        )}
                      </StyledCoreDataGridContainer>
                    </>
                  ) : (
                    <Grid
                      container={true}
                      spacing={0}
                      style={{ marginTop: "20px" }}
                    >
                      {notSmallDevices ? (
                        <MuiThemeProvider theme={getMuiTheme()}>
                          <MUIDataTable
                            title={""}
                            data={auctionProducts}
                            columns={columns}
                            options={options}
                          />
                        </MuiThemeProvider>
                      ) : (
                        <StyledTextWrapper item xs={12}>
                          {t("common:preview:noProductFound")}
                        </StyledTextWrapper>
                      )}
                    </Grid>
                  )}
                </>
                <Menu
                  keepMounted
                  open={mouseState.mouseY !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                      ? { top: mouseState.mouseY, left: mouseState.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    onClick={() =>
                      openDetailsView(
                        props.match.params.auctionId,
                        currentProductId,
                        props.match.params.auctionDate
                      )
                    }
                  >
                    Details
                  </MenuItem>
                </Menu>
                {openToast === true && (
                  <Alert
                    showAlertMessage={showAlertMessage}
                    alertType={alertType}
                  />
                )}
                {onAction === true && (
                  <div className="loading">
                    <LoadingSpinner />
                  </div>
                )}
                {userBlock === true && (
                  <StyledModal open={userBlock}>
                    <BlockUserModal t={t} {...props} closeModal={closeModal} />
                  </StyledModal>
                )}
                {openNotesPopups === true &&
                  notes !== undefined &&
                  notes !== "" && (
                    <StyledModal
                      open={openNotesPopups}
                      onClose={closeNotesModle}
                    >
                      <NotesModal
                        t={t}
                        {...props}
                        closeModal={closeModal}
                        notes={notes}
                      />
                    </StyledModal>
                  )}
                {openReadOnlyUserToast === true && (
                  <AlertReadOnlyUser
                    isReadOnlyUser={isReadOnlyUser}
                    isSeller={isAuctionUserId}
                  />
                )}
              </StyledLayoutGrid>
            </StyledLayoutGridMain>
          )}
        </Grid>
      )}
    </AppLayout>
  );
};

export default TendorPreviewView;
