import React, { useState } from "react";
import Styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";

import IRouterComponentProps from "../../models/IRouterComponentProps";
import styled from "styled-components";
import SubdomainForm from "../../modules/SubdomainForm";
import Modal from "@material-ui/core/Modal";
import { useTranslation } from "react-i18next";
import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const StyledDrawer = Styled(Drawer)`
    & {
        width: auto;
        height: 40%;
        .MuiDrawer-paper {
            width: auto;
            height: 25%;
            background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(105,105,105,1) 100%, rgba(255,255,255,1) 100%);
            ${(props) => props.theme.breakpoints.between("xs", "sm")} {
                font-size: 1em;
                height: 15%;
            }
            ${(props) => props.theme.breakpoints.between("sm", "lg")} {
                height: 30%;   
            }
            @media (width: 600px) {
                height: 15%;
            }
            @media (width: 768px) {
                height: 15%;
            }
            @media (width: 1024px) {
                height: 15%;
            }
        }
    }
`;

const StyledBannerContentSecond = styled.div`
  position: absolute;
  top: 15%;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  font-size: 1em;
`;

const StyledBannerFirstText = styled.span`
  color: ${(props) => props.theme.colors.base.white};
  width: 100%;
  font-family: Calibre-Regular;
  text-align: center;
  left: 5%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "lg")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 1.4em;
  }
`;
const StyledBannerbotton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Calibre-Regular;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "lg")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 2em;
  }
`;
const StyledSecondTextWrapperFinal = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
interface ButtonProps {
  selected?: boolean;
}

const StyledBtn = styled.button<ButtonProps>`
  padding: 5px 5px;
  font-size: 0.6em;
  border-radius: 10px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  margin-right: 5px;
  background: #2d75c2;
  border: 1px solid #2d75c2;
  &:hover {
    border: 1px solid #2d75c2;
    cursor: pointer;
  }
  color: ${(props) => props.theme.colors.base.white};
`;
const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IProps extends IRouterComponentProps {
  t: any;
}

const PersonalizedSlider = (props: IProps) => {
  const { t } = useTranslation();
  const [onPressLogin, setOnPressLogin] = useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(true);
  const [isCreateForm, setIsCreateForm] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const [createFromData, setCreateFromData] = useState({
    businessName: "",
    businessType: "",
  });
  function handleDrawerTgl() {
    setMobileOpen(false);
  }
  const webSiteHandler = () => {
    // setOnPressLogin(true);
    setMobileOpen(false);
    setIsCreateForm(true);
  };

  const onClose = () => {
    setOnPressLogin(false);
  };

  const classes = useStyles();
  const [state, setState] = React.useState<{
    category: string | number;
    name: string;
  }>({
    category: "",
    name: "",
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.value as keyof typeof state;
    setCreateFromData({
      ...createFromData,
      businessType: name,
    });
  };

  const createNowBtnHandler = () => {
    let createWebObj = {
      businessName: createFromData.businessName,
      businessType: createFromData.businessType,
    };
    localStorage.setItem("createWebDetails", JSON.stringify(createWebObj));
    setIsCreateForm(false);
    setLoader(true);
    if (
      createFromData.businessName !== "" &&
      createFromData.businessType !== ""
    ) {
      setTimeout(() => {
        setLoader(false);
        props.history.push("/webDemo");
      }, 6000);
    } else if (createFromData.businessName === "") {
    } else if (createFromData.businessType === "") {
    }
  };

  const renderWebsiteLoader = () => {
    return (
      <>
        <Backdrop
          sx={{
            color: "black",
            background: "white",
            zIndex: 1,
          }}
          open={loader}
        >
          <video controls={false} width="100%" autoPlay>
            <source src="/videos/createweb.mp4" type="video/mp4" />
          </video>{" "}
        </Backdrop>
      </>
    );
  };

  function createForm() {
    return (
      <>
        <Dialog
          fullScreen
          open={isCreateForm}
          onClose={() => {
            setIsCreateForm(false);
          }}
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "none",
            },
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setIsCreateForm(false);
            }}
            aria-label="close"
            sx={{
              position: "absolute",
              right: "10px",
              top: "5px",
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box component="div">
              <span
                style={{
                  color: "#2660AD",
                  fontSize: "3.2em",
                  fontWeight: 600,
                  fontFamily: "auto",
                }}
              >
                Create a stunning website in 20 seconds with AI
              </span>
              <div style={{ textAlign: "center" }}>
                <TextField
                  id="businessName"
                  variant="outlined"
                  type="text"
                  label="Name of your business"
                  required
                  onChange={(e) => {
                    setCreateFromData({
                      ...createFromData,
                      businessName: e.target.value,
                    });
                  }}
                  fullWidth
                  style={{ width: "60%" }}
                />
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  style={{ width: "60%" }}
                  required
                >
                  <InputLabel htmlFor="Category">Category</InputLabel>
                  <Select
                    native
                    onChange={handleChange}
                    label="Category"
                    inputProps={{
                      name: "Category",
                      id: "Category",
                    }}
                  >
                    <option aria-label="None" value="" />
                    <option value={"Diamonds"}>Diamonds</option>
                    <option value={"Jewllery"}>Jewllery</option>
                  </Select>
                </FormControl>
              </div>
              <div style={{ textAlign: "center" }}>
                <Button
                  variant="contained"
                  onClick={createNowBtnHandler}
                  style={{ background: "2660AD" }}
                >
                  Create Now
                </Button>
              </div>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <div>
      <StyledDrawer
        variant="temporary"
        anchor="bottom"
        className="styledDrawer"
        open={mobileOpen}
        onClose={handleDrawerTgl}
      >
        <div>
          <StyledBannerContentSecond>
            <StyledSecondTextWrapperFinal>
              <StyledBannerFirstText>
                {t("common:FreePersonalizedWebsites:sliderText:textFirst")}
              </StyledBannerFirstText>
              <StyledBannerFirstText>
                {t("common:FreePersonalizedWebsites:sliderText:textSecond")}
              </StyledBannerFirstText>
              <StyledBannerFirstText>
                {t("common:FreePersonalizedWebsites:sliderText:textThird")}
              </StyledBannerFirstText>
            </StyledSecondTextWrapperFinal>
            <StyledBannerbotton style={{ marginTop: "10px" }}>
              <StyledBtn onClick={webSiteHandler}>
                {t("common:FreePersonalizedWebsites:CreateWebSite")}
              </StyledBtn>
            </StyledBannerbotton>
          </StyledBannerContentSecond>
        </div>
      </StyledDrawer>
      {onPressLogin === true && (
        <StyledModal
          open={onPressLogin}
          onClose={onClose}
          className="loginModal"
        >
          <SubdomainForm t={t} closePopup={onClose} />
        </StyledModal>
      )}
      {loader && renderWebsiteLoader()}
      {isCreateForm && createForm()}
    </div>
  );
};

export default PersonalizedSlider;
