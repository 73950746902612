import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { backgroundGradient } from "../../../../common/materials/LinearGradient";

export const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 1.5em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 400px;
    }
`;

export const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledArrowicon = styled.img`
  height: 1.5em;
  width: 1.5em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledArrowContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

export const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

export const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

export const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 700px;
  }
`;
//main container of Tender live popup
export const StyledGrid = styled(Grid)`
  background: white;
  height: -webkit-fill-available;
  display: bock;
  justify-content: center;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.9em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 1em;
  }
`;

export const StyledNotAvailableImage = styled.div`
    justify-content: center;
`;

export const StyledProductImage = styled('img')`
  width:100%;
`;

export const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 10px;
  border-radius: 3px;
  font-size: 1em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

export const StyledItemGrid = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  box-shadow: 2px 0px 5px 2px #dcdcdc;
  border-radius: 20px;
  padding-top: 10px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
    padding-top: 5px;
    margin-top: 5px;
  }
`;

const StyledDescriptionContainer = styled.div`
  padding: 10px;
  background: lightgray;
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 5px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 5px;
  }
`;

export const StyledplaceBidPopup = styled.div`
  flex-direction: column;
`;

export const StyledItemContainer = styled(Grid)`
  font-size: 1em;
  width: 95%;
  margin: 0 auto;
  min-height: 25px;
  display: flex;
  .moveToLandingPageLink {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    min-height: 25px;
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    min-height: 25px;
    font-size: 0.8em;
  }
`;

export const StyledHeading = styled(Grid)`
    font-size: 1em;
    font-weight: bold;
    text-align: left;
`;

export const StyledValue = styled(Grid)`
    font-size: 1em;
    max-height: 100px;
`;

const StyledComponent = styled(Grid)`
  padding: 5px;
  outline: none;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1.8em;
    // margin-bottom: 5px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 390px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 500px;
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 450px;
    font-size: 0.8em;
  }

  @media (width: 1024px) {
    font-size: 1.2em;
    width: 580px;
    height: 65%;
    padding: 0px;
  }

  @media (width: 320px) {
    font-size: 1em;
    width: 300px;
    height: 80%;
    padding: 0px;
  }

  @media (width: 280px) {
    font-size: 0.8em;
    width: 275px;
    height: 70%;
    padding: 0px;
  }
  @media (min-width: 1380px) {
    height: min-content;
  }
`;

const StyledWrappers = styled(Grid)`
    width: 400px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 350px;
        padding: 1em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
        height: 700px;
    }
`;

const StyledTextWrapper = styled.div`
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 1.5em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  margin-top: 20px;
  background: lightgrey;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .notDisablebutton {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;
const StyledAuctionTimerWrapper = styled(Grid)`
    text-align: center;
    display: flex;
    margin-top: 20px;
    padding: 30px;
    background: lightgrey;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
`;
export const StyledBidWrapper = styled.span`
  text-align: center;
  align-items: center;
  font-size: 1.5em;
  font-family: Calibre-Regular;
  padding: 10px;
  border-radius: 20px;
`;

export const StyledBidWra = styled.span`
  font-size: 1em;
  font-family: Calibre-Regular;
  border-radius: 20px;
`;

export const Styledbidcontainer = styled(Grid)`
  border: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};;
  padding: 7px;
  border-radius:20px;
  ${props => props.theme.breakpoints.between('xs', 'sm')} {
    padding: 4px;
  }
  ${props => props.theme.breakpoints.between('sm', 'md')} {
    padding: 5px;
  }
`;

const StyledButtonWrapper = styled(Grid)`
  margin-top: 10px;
  text-align: center;
  display: flex;

  background: ${(props) => props.theme.colors.base.white};
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 5px;
    padding: 2px 2px;
    font-size: 0.6em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 2px 2px;
    margin-top: 5px;
    font-size: 0.6em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1px 1px;
  }
`;

const StyledPlaceHolderWrapper = styled(Grid)`
  margin-top: 5px;
  text-align: center;
  display: flex;
  justify-content: space-around;

  background: ${(props) => props.theme.colors.base.white};
  align-items: center;
  border-radius: 10px;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 5px;
    padding: 2px 2px;
    font-size: 0.6em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 1px 1px;
    margin-top: 5px;
    font-size: 0.6em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1px 1px;
  }
`;

const StyledCategoryImage = styled.img`
  height: 2.7em;
  width: 2.7em;
  cursor: pointer;
`;
const StyledArrowWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: 15%;
    width: 30px;
    cursor: pointer;
`;
const StyledArrows = styled('img')`
  height: 2.8em;
  width:2.83em;
`;


export {
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledComponent,
  StyledWrappers,
  StyledButtonWrapper,
  StyledCategoryImage,
  StyledAuctionTimerWrapper,
  StyledArrowWrapper,
  StyledArrows,
  StyledDescriptionContainer,
  StyledPlaceHolderWrapper,
};