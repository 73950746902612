import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import { backgroundGradient } from "../../../../../common/materials/LinearGradient";
import { StyledCardContainer } from "../../../../../common/materials/GenericStyleComponent";

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  display: flex;
  align-items: center;
  height: 100px;
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.white};

  .height {
    height: 100%;
  }
`;

const StyledProductImageContainer = styled(Grid) <{ reserved: boolean}>`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) => (!props?.reserved ? "url(/images/reserve/reserveBadge0.png)" : "")};
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: 7em;
    z-index: 2;
    pointer-events: none;
  }
`;

const StyledWrapper = styled(Grid)`
  padding: 5px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
`;


interface IProps {
  cardId: string;
  onCardClick: () => void;
  headerTemplate: any;
  remarksTemplate: any;
  watchListTemplate: any;
  pricingTemplate: any;
  leftColTemplate: any;
  rightColTemplate: any;
  footerTemplate: any;
  checkBoxrender: any;
  reserved: boolean;
  colorCode: string;
}

const BaseCardLayout = (props: IProps) => {
  const {
    cardId,
    onCardClick,
    headerTemplate,
    remarksTemplate,
    watchListTemplate,
    pricingTemplate,
    leftColTemplate,
    rightColTemplate,
    footerTemplate,
    checkBoxrender,
    reserved,
    colorCode
  } = props;

  return (
    <StyledCardContainer id={cardId}>
      <Grid container={true} spacing={0} className="borderBottomBlue fontBold">
        <StyledTitleWrapper item={true} xs={10}>
          <Grid container={true} spacing={0} className="height">
            <Grid item={true} xs={2}>
              {checkBoxrender}
            </Grid>
            <Grid item={true} xs={10} onClick={() => onCardClick()}>
              {headerTemplate}
            </Grid>
            <StyledRemarks item={true} xs={12}>
              {remarksTemplate}
            </StyledRemarks>
          </Grid>
        </StyledTitleWrapper>
        {watchListTemplate}
      </Grid>
      <Grid container={true} spacing={0}>
        <Grid container={true} spacing={0} className="borderBottomBlue">
          <StyledWrapper item={true} xs={12}>
            {pricingTemplate}
          </StyledWrapper>
        </Grid>
        <Grid container={true} spacing={0} className="borderBottomBlue">
          <Grid item={true} xs={3} className="borderRightBlue" style={{
            justifyContent: "space-around",
            display: 'flex',
            flexDirection: "column",
            padding: '10px'
          }}>
            {leftColTemplate}
          </Grid>
          <StyledProductImageContainer item={true} xs={9} onClick={() => onCardClick()} reserved={reserved}>
            {rightColTemplate}
          </StyledProductImageContainer>
        </Grid>
        <Grid container={true} spacing={0}>
          <StyledWrapper item={true} xs={12} style={{ background: colorCode != undefined || colorCode != null ? colorCode : "white" }}>
            {footerTemplate}
          </StyledWrapper>
        </Grid>
      </Grid>
    </StyledCardContainer>
  );
};

export default BaseCardLayout;
