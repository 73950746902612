import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { productTextSearch, searchJewelleryAuctionFilterUrl } from '../../../common/config/app.endpoints';
import { StyledButton } from '../../../common/materials/GenericStyleComponent';
import { buttonGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import AuthService from '../../auth/AuthService';
import JewelleryFilter from './JewelleryFilter';
import JewelleryGrid from './JewelleryGrid';
import JewelleryGridAfterFilter from './JewelleryGridAfterFilter';

const StyledBoxContent = styled.div`
    width: 100%;
    text-align: right;
    height: 60px;
    margin-bottom: 20px;
`;

export const StyledButtonSearch = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    height: 42px;
    margin-bottom: 7px;
    margin-left: 5px;
    border: none;
    font-size: 1em;
    padding: 0.6em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;


interface IProps extends IRouterComponentProps {
    t?: any;
}

const Jewellery = (props: IProps) => {
    const [isShowSearchBox, setShowSearchBox] = useState(true);
    const [textSearchArray, setTextSearchArray] = useState([]);
    const [optionValue, setOptionValue] = useState("")
    const [isFiltered, setIsFiltered] = useState(false);
    const [isFiltersOpen, setIsFiltersOpen] = useState(false);
    const [apiTimeout, setApiTimeout] = useState(false);
    const [isFilteredAuctions, setIsFilteredAuctions] = useState([]);
    const [filteredValues, setFilteredValues] = useState([]);
    const [filteredParcelValues, setFilteredParcelValues] = useState([]);
    const [isFilteredPrcelAuctions, setIsFilteredParcelAuctions] = useState([]);
    const [isParcelFiltered, setIsParcelFiltered] = useState(false);
    const Auth = new AuthService();
    const { t, location } = props;


    useEffect(() => {
        var filteredValues: any = localStorage.getItem("filteredValues");
        filteredValues = JSON.parse(filteredValues);
        localStorage.removeItem("filteredParcelValues");
        localStorage.setItem("urlRedirectFromEmail", window.location.href);
        localStorage.removeItem("urlRedirectFromAdmin");
        var filteredParcelValues: any = localStorage.getItem("filteredParcelValues");
        filteredParcelValues = JSON.parse(filteredParcelValues);

        if (filteredValues !== null) {
            filteredValues.map((item: any, index: number) => {
                if (item.name === "auctionId") {
                    filteredValues.splice(index, 1);
                }
            })
            
        }else if (filteredParcelValues !== null) {
            filteredParcelValues.map((item: any, index: number) => {
                if (item.name === "auctionId") {
                    filteredParcelValues.splice(index, 1);
                }
            })
        }

         const data = {
            "filters": {
                "and": filteredValues !== null ? filteredValues : filteredParcelValues
            },
            "page": {
                "start": 0,
                "count": 20,
                "sort": {
                "orders": [
                    {
                    "property": "serialNo",
                    "direction": "ASC"
                    }
                ]
                }
            }
        }
        let val = localStorage.getItem('optionValue')
        if (val !== "" && val !== 'null' && val !== null) {
            setOptionValue(val)
            autoCompleteSearch(val)
        } else {
            if (filteredValues !== null) {
                Auth.postRequest(searchJewelleryAuctionFilterUrl, data, (status: any, response: any) => {
                if (status === 200) {
                    setIsFiltered(true);
                    setIsFilteredAuctions(response);
                }
                })
            }else if (filteredParcelValues !== null) {
                Auth.postRequest(searchJewelleryAuctionFilterUrl, data, (status: any, response: any) => {
                if (status === 200) {
                    setIsFiltered(true);
                    setIsFilteredParcelAuctions(response);
                }
                })
            } else {
                    setIsFiltered(false);
                }
            }

    }, [])

    const filteredAuction = (auctions: any, isFilter: boolean, values: any) => {
        setIsFiltered(false);
        setFilteredValues(values);
        setIsFilteredAuctions(auctions);
        setIsFiltered(isFilter);        
        setFilteredParcelValues([]);
        setIsFilteredParcelAuctions([]);
    }
    const filteredParcelAuction = (auctions: any, isFilter: boolean, values: any) => {
        setIsParcelFiltered(false);
        setFilteredParcelValues(values);
        setIsFilteredParcelAuctions(auctions);
        setIsFilteredAuctions([]);
        setFilteredValues([]);
        setIsParcelFiltered(isFilter)
    }
    const handleFilters = () => {
        setIsFiltersOpen(!isFiltersOpen);
        setShowSearchBox(!isShowSearchBox);
    }

    const timeout = (timeout: boolean) => {
        if (timeout === true) {
            setApiTimeout(true)
          }
    }

    const textSearchListing = (value: String) => {
        const data = {
            "filters": {
                "or": [
                    {
                        "match": "anywhere",
                        "name": "remarks",
                        "value": value
                    }                    
                ],
                "and": [
                    {
                    "match": "exact",
                    "name": "parcelType",
                    "value": "Jewellery"
                    }
                 ]
            }
        }

        if (value === "" || value.length === 1) {
            setTextSearchArray([])
        } else {
            if (value.length > 1) {
                Auth.postRequest(productTextSearch, data, (status: any, response: any) => {                   
                    if (status === 200) {
                        setTextSearchArray(response.results)
                    }
                })
            }
        }

    }

    const autoCompleteSearch = (val: any) => {
        PubSub.publish("optionValue", val);
        localStorage.setItem("optionValue", val);
        setIsFiltered(false);
    }

    return (
        <>
            <div style={{ "width": "100%" }}>
                <StyledBoxContent>
                    {isShowSearchBox ? (
                        <div
                            style={{
                                flexDirection: "row",
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                            }}
                        >
                            <div style={{ width: 300 }}>
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    options={textSearchArray}
                                    onChange={(e: object, value: any | null) => {
                                        if (value === 'null' || value === null) {
                                            setTextSearchArray([])
                                        }
                                        setOptionValue(value);
                                        autoCompleteSearch(value)
                                    }}
                                    value={optionValue}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t("common:button:searchRemark")}
                                            margin="normal"
                                            variant="outlined"
                                            size="small"
                                            onChange={(e) => textSearchListing(e.target.value)}
                                        />
                                    )}
                                />
                            </div>

                            <StyledButtonSearch onClick={handleFilters}>
                                {t("common:button:advanceSearch")}
                            </StyledButtonSearch>
                        </div>
                    ) : (
                        <StyledButton onClick={handleFilters}>
                            {t("common:button:basicSearch")}
                        </StyledButton>
                    )}
                </StyledBoxContent>
                {isFiltersOpen === true && (
                    <div>
                        <JewelleryFilter
                            {...props}
                            t={t}
                            filterJewelleryAuction={filteredAuction}
                            auctionId={null}
                            isPreviewPage={true}
                        />
                    </div>
                )}
            </div>
             {isFiltered === true || isParcelFiltered === true  ? (
                <Grid item={true} xs={12}>
                  <JewelleryGridAfterFilter
                    {...props}
                    apiTimeout={timeout}
                    filteredAuctions={isFilteredAuctions}
                    filteredValues={filteredValues}
                    filteredParcelValues={filteredParcelValues}
                    filteredParcelActions={isFilteredPrcelAuctions}
                  />
                </Grid>
              ) : (
                <Grid item={true} xs={12}>
                  <JewelleryGrid {...props} apiTimeout={timeout} searchOptionValue={optionValue} />
                </Grid>
              )}
        </>
    )
}

export default Jewellery;