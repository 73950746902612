import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from '../components/layouts/AppLayout';
import IRouterComponentProps from '../models/IRouterComponentProps';

import UserProfile from '../components/profile/UserProfile';

const StyledLayoutGrid = styled(Grid)`
    padding: 0px;
    flexGrow: 1;
    margin: 0px;
    width: 100% !important;
    padding: 0 70px;
    ${props => props.theme.breakpoints.between('xs', 'md')} {
        padding: 0;
    }
`;

const Profile = (props: IRouterComponentProps) => {
    const { t } = props;
    return (
        <AppLayout t={t} {...props}>
            <StyledLayoutGrid container={true} spacing={2} className="carousalWrapper">
                <Grid item={true} xs={12} className="coreGridContainer">
                    <UserProfile t={t} {...props} />
                </Grid>
            </StyledLayoutGrid>
        </AppLayout>
    )
};

export default Profile;