import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import { AxiosError } from "axios";
import api from "../../../services/ApiService";

//import {  } from "../../../common/config/app.endpoints";

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

const initialState = {
  loading: false,
  error: "",
};

const tendorSlice = createSlice({
  name: "tendor",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {},
});

export default tendorSlice.reducer;

// selectors
export const selectTendorLoading = (state: RootState) => state.tendor.loading;
