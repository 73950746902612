import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { StyledButton } from "../../../common/materials/GenericStyleComponent";
// import { AnyAaaaRecord } from 'node:dns';
import { backgroundGradient } from "../../../common/materials/LinearGradient";
import {
  StyledAuctionEndedWrapper,
  StyledComponent,
  StyledTextWrapper,
  StyledWrapper,
} from "../BlockUser/NotesModal";

const StyledButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

export interface IProps extends IRouterComponentProps {
  t: any;
  closeModal: () => void;
  handleBtnValue: (values: any) => void;
  bidval:any;
}

const bidConfirmationpopup = (props: IProps) => {
  const { t ,bidval} = props;

  const handleBtnVal = (value : any) => {
    props.handleBtnValue(bidval);
  }

  const handleCancel = () => {
    props.closeModal();
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {t("common:Tender:bidInfo:bidDescription")} {bidval} {" "}
          </StyledTextWrapper>
          <StyledButtonContainer>
            <StyledButton onClick={handleBtnVal}>yes</StyledButton>
            <StyledButton onClick={handleCancel}>no</StyledButton>
          </StyledButtonContainer>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
};

export default bidConfirmationpopup;
