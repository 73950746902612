import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./strings/en";
import jp from "../localization/strings/jp";
import zh_CN from "./strings/zh_CN";

const resources = {
  en,
  jp,
  zh_CN,
};

var lng = '';
var lang = localStorage.getItem('language');
if (window.location.href.includes("zipang.")) {
  if (lang === 'EN') {
    lng = 'en'
  } else if (lng === "zh_CN") {
    lng = "zh_CN";
  } else if (lang === 'JP') {
    lng = 'en'
  } else {
    lng = "en";
  }
} else {
  if (lang === 'EN') {
    lng = 'en'
  } else if (lng === "zh_CN") {
    lng = "zh_CN";
  } else {
    lng = "jp";
  }
}


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: lng,
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;

const languageCodesArray = window.location.href.includes("zipang.") ? ["en", "jp", "zh_CN"] : ["jp", "en", "zh_CN"];
export { languageCodesArray };