import React, { useState } from "react";

import { createTheme, useMediaQuery, Grid } from "@material-ui/core";
import Flotingicon from "../../products/Flotingicon";
import {
  StyledWebContainer,
  StyledWebLogoWrapperContainer,
  StyledWebResetPaddingContainer,
  StyledWebCompanyName,
  StyledWebTypeImgContainer,
  StyledWebDaimondCon,
  StyledWebDaimondsText,
  StyledWebAuctionTypeImage,
  StyledWebDaimondsTextMobile,
  StyledWebCompanyInfo,
  StyledWebComInfoImage,
  StyledWebCompContent,
  StyledWebName,
  StyledWebAuctionCon,
  StyledCompanyInfo,
  StyledComInfoImage,
  StyledCompContent,
  StyledTitle,
  StyledDescription,
  StyledWebFooter,
  StyledWebTitleSub,
} from "./CreateWebDummyStyle";
import { useTranslation } from "react-i18next";
import { Box, Button } from "@mui/material";
import Logo from "../../../pages/LandingPage/Static/Icons/Logo";
import GoLivePopup from "./GoLivePopup";
import ContactUsDummy from "../../../pages/LandingPage/Footer/ContactUsDummy";
import DummyAddress from "../../../pages/LandingPage/Footer/DummyAddress";

const CreateWebDummy = () => {
  const { t } = useTranslation();
  const theme = createTheme({});
  const [isGoLiveOpen, setIsGoLiveOpen] = useState(false);
  const isSmallDevices = useMediaQuery(theme.breakpoints.down("xs"));
  let businessDetails: any = localStorage.getItem("createWebDetails");
  businessDetails = JSON.parse(businessDetails);
  let companyName = businessDetails.businessName;

  function renderGoLivePopup() {
    return (
      <GoLivePopup
        open={isGoLiveOpen}
        handleClose={() => {
          setIsGoLiveOpen(false);
        }}
      />
    );
  }
  return (
    <StyledWebContainer container={true} spacing={0}>
      <StyledWebLogoWrapperContainer container={true} spacing={0}>
        <StyledWebResetPaddingContainer item xs={12}>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginLeft: "5%",
            }}
          >
            <Logo />
          </div>
          <>
            <StyledWebCompanyName>
              {companyName.toUpperCase()}
            </StyledWebCompanyName>
          </>
          <div
            style={{ alignItems: "center", display: "flex", marginRight: "3%" }}
          >
            <Button
              variant="contained"
              onClick={() => {
                setIsGoLiveOpen(true);
              }}
              style={{ height: "fit-content" }}
            >
              Go Live
            </Button>
          </div>
        </StyledWebResetPaddingContainer>
      </StyledWebLogoWrapperContainer>

      <StyledWebCompanyInfo
        item
        xs={12}
        container
        spacing={0}
        className="justifyContent"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={11}
          container
          spacing={0}
        >
          <StyledWebCompContent
            item
            xs={3}
            style={{ margin: "auto", textAlign: "center" }}
          >
            <StyledWebTitleSub>
              {businessDetails.businessName}
            </StyledWebTitleSub>
            <StyledWebName>
              <>
                Diamonds Shine <br />
                better with us
              </>
            </StyledWebName>
          </StyledWebCompContent>
          <Grid item xs={7} style={{ float: "right", display: "contents" }}>
            <StyledWebComInfoImage src="/images/dummy/firstImage.png" />
          </Grid>
        </Grid>
      </StyledWebCompanyInfo>

      <StyledWebTypeImgContainer item xs={12} container spacing={0}>
        <Grid
          container
          spacing={0}
          item
          xs={12}
          style={{
            textAlign: "center",
            marginBottom: "10px",
            justifyContent: "left",
            fontSize: "2em",
            marginLeft: "10%",
          }}
        >
          <StyledWebName style={{ color: "#2660dd" }}>
            Our Services
          </StyledWebName>
        </Grid>
        <Grid container spacing={0} item xs={12}>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledWebDaimondCon>
                <StyledWebDaimondsText>
                  {t("common:subDomain:daimodsInfo:daimond")}
                </StyledWebDaimondsText>
                <StyledWebAuctionTypeImage
                  className="width-6em"
                  src="/images/dummy/diamond.png"
                />
              </StyledWebDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledWebAuctionTypeImage
                  className="width-6em"
                  src="/images/dummy/diamond.png"
                />
                <StyledWebDaimondsTextMobile>
                  {t("common:subDomain:daimodsInfo:daimond")}
                </StyledWebDaimondsTextMobile>
              </span>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledWebDaimondCon>
                <StyledWebDaimondsText>
                  {t("common:subDomain:daimodsInfo:auction")}
                </StyledWebDaimondsText>
                <StyledWebAuctionTypeImage src="/images/dummy/Auction.png" />
              </StyledWebDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledWebAuctionTypeImage src="/images/dummy/Auction.png" />
                <StyledWebDaimondsTextMobile>
                  {t("common:subDomain:daimodsInfo:auction")}
                </StyledWebDaimondsTextMobile>
              </span>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
            {!isSmallDevices && (
              <StyledWebDaimondCon>
                <StyledWebDaimondsText>
                  {t("common:subDomain:daimodsInfo:jewellery")}
                </StyledWebDaimondsText>
                <StyledWebAuctionTypeImage src="/images/dummy/rings.png" />
              </StyledWebDaimondCon>
            )}
            {isSmallDevices && (
              <span>
                <StyledWebAuctionTypeImage src="/images/dummy/rings.png" />
                <StyledWebDaimondsTextMobile>
                  {t("common:subDomain:daimodsInfo:jewellery")}
                </StyledWebDaimondsTextMobile>
              </span>
            )}
          </Grid>
        </Grid>
      </StyledWebTypeImgContainer>
      <StyledCompanyInfo
        item
        xs={12}
        container
        spacing={0}
        className="justifyContent"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={11}
          xl={11}
          container
          spacing={0}
        >
          <Grid item xs={12} sm={12} lg={5} xl={5} className="alignCenter">
            <StyledComInfoImage src="/images/dummy/diamondmapper.png" />
          </Grid>
          <StyledCompContent item xs={12} sm={12} lg={7} xl={7}>
            <StyledTitle>
              {t("common:nihonauction:companyInfo:about")}
            </StyledTitle>
            <StyledDescription>
              {t("common:nihonauction:companyInfo:companyDescription")}
            </StyledDescription>
          </StyledCompContent>
        </Grid>
      </StyledCompanyInfo>

      <StyledWebAuctionCon item xs={12} container spacing={4}>
        <StyledWebFooter
          container
          spacing={4}
          style={{
            backgroundImage: 'url("/images/sub-domain/auction-img.PNG")',
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            style={{
              border: "1px solid black",
              background: "#46C7C7",
              height: "460px",
              width: "40%",
            }}
          >
            <Box>
              <DummyAddress isMobile={false} />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            style={{
              border: "1px solid black",
              background: "#48CCCD",
              height: "460px",
              width: "40%",
              marginTop: "10px",
            }}
          >
            <Grid item color={"white"}>
              <ContactUsDummy isMobile={false} />
            </Grid>
          </Grid>
        </StyledWebFooter>
      </StyledWebAuctionCon>

      <Flotingicon />
      {isGoLiveOpen && renderGoLivePopup()}
    </StyledWebContainer>
  );
};

export default CreateWebDummy;
