import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";

import { useTranslation } from "react-i18next";
import { buttonGradient, dateBackgroundGradient } from "../../../common/materials/LinearGradient";
import { StyledComponent, StyledTextInput } from "../../../common/materials/GenericStyleComponent";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { StyledFieldLegend, StyledLegendField } from "./UserInformationDetailsStyle";
import { annualTrackingApi, annualupsertTrackingApi, getInvoiceTracking, getShipingCompanyName, postShipingInformation } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import Alert from "../../../common/elements/Alert";
import { useHistory } from "react-router-dom";
import { setTimeout } from "timers";

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 100%;
  padding: 0.4em;
  border-radius: 10px;
  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledTextWrapper = styled(Grid)`
  color: black;
  display: flex;
  padding: 0.2em;  
  grid-gap: 10px;
`;

const StyledTextSubWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const StyledPersonalBankFieldSet = styled.fieldset`
  display: flex;
  height: 250px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledWrapper = styled(Grid)`
  width: 100%;
  display: grid;
  width: max-content;
  border: 1px solid lightgray;
`;

const StyledButton = styled.button`
  float: right;
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.9em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
const ButtonContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.9em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.9em;
  }
`;

const ProductCountContainer = styled(Grid)`
  color: gray;
  font-size: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 16px;
  }
`;

const StyledProfileCard = styled(Grid)`
  margin: 0px 0px 30px 0px;
  width: 100%;
`;

const StyledUserName = styled.h1`
  font-size: 1em;
  margin-top: 20px;
  padding: 0.2em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 2em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 2.3em;
  }
  @media (width: 1024px) {
    font-size: 1.5em;
  }
`;
const StyledLabelName = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledAstrick = styled.span`
  color: red;
`;

const StyledAWBNumber = styled.span`
  color: gray;
  font-size: 0.8em;
  font-weight: bold;
`;

const StyledlabelContainer = styled(Grid)`
  background: lightgray;
  flex-direction: row;
  display: flex;
  height: 60px;
  align-Items: center;
  border-bottom: 1px solid lightgray: 
`;

interface IProps {
  auctionIds: any;
  isAnnualtype: boolean;
  hideTrakingUi: () => void;
}

let selectedInvoiceData: any;

const TrakingProcess = (props: IProps) => {
  const { t } = useTranslation();
  const [company, setCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [companyNameList, setCompanyNameList] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [deliveryCityAddress, setDeliveryCityAddress] = useState("");
  const [deliveryCountryAddress, setDeliveryCountryAddress] = useState("");
  const [countryName, setCountryName] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [blockNo, setBlockNo] = useState("");
  const [streetName, setStreetName] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

  const Auth = new AuthService();

  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  const isUserCurrencyCode = () => {
    return userCurrencyCode && userCurrencyCode === "JPY";
  };

  useEffect(() => {
    getCompanyNameList();
    Auth.getRequest(
      props.isAnnualtype
        ? `${annualTrackingApi}?invoiceId=${props.auctionIds}&invoiceType=${isUserCurrencyCode() ? "SIMPLE" : "INTERNATIONAL"}`
        : `${getInvoiceTracking}?auctionId=${props.auctionIds}`,
      (status: number, response: any) => {
        if (status === 200) {
          selectedInvoiceData = response;
          setRecipientName(response.shippingAddress.reciepientName);
          setCompany(response.shippingCompany);
          setTrackingNumber(response.awbTrackingNumber);
          setIsFormEditable(response.finalChanges);
          if (response.shippingAddress !== undefined) {
            if (response.shippingAddress !== undefined) {
              setCity(response.shippingAddress?.city);
              setState(response.shippingAddress?.state);
              setCountry(response.shippingAddress?.country);
            }
          }
          if (response.shippingAddress !== undefined) {
            setCompanyName(response.shippingAddress.companyName);
            if (response.shippingAddress !== undefined) {
              setStreetName(response.shippingAddress.streetName);
              setBlockNo(response.shippingAddress.blockNo);
              setCountryName(response.shippingAddress.countryDetail.name);
              setDeliveryAddress(response.shippingAddress?.stateDetail?.name);
              setDeliveryCityAddress(
                response.shippingAddress?.cityDetail?.name
              );
              setDeliveryCountryAddress(
                response.shippingAddress?.countryDetail?.name
              );
            }
          }
        }
      }
    );
  }, []);

  const getCompanyNameList = () => {
    Auth.getRequest(
      `${getShipingCompanyName}`,
      (status: number, response: any) => {
        if (status === 200) {
          setCompanyNameList(response);
        }
      }
    );
  }

  const handleChange = (e: any) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;

    if (targetName === "CompanyName") {
      setCompany(targetValue);
    }
    if (targetName === "TRACKING_NUMBER") {
      setTrackingNumber(targetValue);
    }
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    if (company === undefined && trackingNumber === undefined) {
      setValidationMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (company === undefined || company === "") {
      setValidationMessage(
        `${t("common:invoice:tracking:validation:companyNameValidation")}`
      );
    } else if (trackingNumber === undefined || trackingNumber === "") {
      setValidationMessage(
        `${t("common:invoice:tracking:validation:awbNumberValidation")}`
      );
    } else {
      setValidationMessage("");
      const obj = {
        inviteId: props.isAnnualtype ? props.auctionIds : selectedInvoiceData === undefined ? null : selectedInvoiceData.inviteId,
        shippingCompany: company,
        awbTrackingNumber: trackingNumber,
      };
      callApi(obj);
    }
  }
  const callApi = (formData: any) => {
    Auth.postRequest(
      props.isAnnualtype ? `${annualupsertTrackingApi}?invoiceType=${isUserCurrencyCode() ? "SIMPLE" : "INTERNATIONAL"}` : postShipingInformation,
      formData,
      (status: number, response: any) => {
        if (status === 200) {
          setAlertType("success");
          setShowMessage(
            `${t("common:invoice:tracking:trackingForm:detailsSubmit")}`
          );
          setOpenToast(true);

          setTimeout(() => {
            props.hideTrakingUi()
          }, 3000)

        }
      }
    );
  }
  return (
    <StyledComponent>
      <StyledContainerWrapper>
        <StyledlabelContainer>
          <ProductCountContainer>
            {t("common:invoice:tracking:recipientLable")}
          </ProductCountContainer>
        </StyledlabelContainer>
        <StyledAstrick>{validationMessage}</StyledAstrick>
        <Grid container spacing={0}>
          <StyledProfileCard container={true} spacing={0}>
            <Grid item={true} xs={12} sm={4} md={4} lg={4} xl={4}>
              <Grid container spacing={0}>
                <Grid item={true} xs={12} style={{ textAlign: "center" }}>
                  <StyledUserName>
                    <FormControl variant="outlined" className="" fullWidth>
                      <InputLabel id="demo-simple-select-outlined-label">
                        {t(
                          "common:invoice:tracking:trackingForm:shippingCompanyName"
                        )}
                        <StyledAstrick>*</StyledAstrick>
                      </InputLabel>
                      {isFormEditable === false ? (
                        <Select
                          id="CompanyName"
                          name="CompanyName"
                          value={company}
                          required
                          onChange={handleChange}
                          label={t(
                            "common:invoice:tracking:trackingForm:shippingCompanyName"
                          )}
                          style={{ height: "55px" }}
                          fullWidth
                        >
                          {companyNameList?.map((name: any) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <Select
                          disabled
                          id="CompanyName"
                          name="CompanyName"
                          value={company}
                          required
                          onChange={handleChange}
                          label={t(
                            "common:invoice:tracking:trackingForm:shippingCompanyName"
                          )}
                          style={{ height: "55px" }}
                          fullWidth
                        >
                          {companyNameList?.map((name: any) => (
                            <MenuItem key={name} value={name}>
                              {name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </FormControl>
                  </StyledUserName>
                </Grid>
              </Grid>
            </Grid>
            <Grid item={true} xs={12} sm={8} md={8} lg={8} xl={8}>
              <Grid container spacing={0}>
                <StyledTextWrapper
                  xs={12}
                  sm={12}
                  md={9}
                  lg={9}
                  xl={9}
                  style={{ marginTop: "20px" }}
                >
                  <StyledLabelName>
                    <StyledAWBNumber>
                      {t("common:invoice:tracking:trackingForm:awbTrackingNo")}{" "}
                      <StyledAstrick>*</StyledAstrick> :
                    </StyledAWBNumber>
                  </StyledLabelName>
                  <Grid item={true} style={{ textAlign: "center" }}>
                    {isFormEditable === false ? (
                      <TextField
                        name="TRACKING_NUMBER"
                        id="TRACKING_NUMBER"
                        required
                        label=""
                        type="text"
                        variant="outlined"
                        value={trackingNumber}
                        onChange={handleChange}
                        fullWidth
                      />
                    ) : (
                      <TextField
                        disabled
                        name="TRACKING_NUMBER"
                        id="TRACKING_NUMBER"
                        required
                        label=""
                        type="text"
                        variant="outlined"
                        value={trackingNumber}
                        onChange={handleChange}
                        fullWidth
                      />
                    )}
                  </Grid>
                </StyledTextWrapper>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                  <ButtonContainer style={{ marginTop: "20px" }}>
                    {/* <StyledButton>
                      {t(
                        "common:invoice:tracking:invoiceButton:trackYourPackage"
                      )}
                    </StyledButton> */}
                  </ButtonContainer>
                </Grid>
              </Grid>
            </Grid>
          </StyledProfileCard>

          <Grid container={true} spacing={0}>
            <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
              <Grid item={true} xs={12} sm={12} md={12} lg={10} xl={12}>
                <StyledPersonalBankFieldSet>
                  <StyledWrapper>
                    <Grid item xs={12}>
                      <StyledLegendField>
                        <StyledFieldLegend>
                          {t(
                            "common:invoice:tracking:buyerDetails:companyName"
                          )}
                        </StyledFieldLegend>
                        <StyledTextWrapper>{companyName}</StyledTextWrapper>
                      </StyledLegendField>
                      <StyledLegendField>
                        <StyledFieldLegend>
                          {t("common:invoice:tracking:buyerDetails:country")}
                        </StyledFieldLegend>
                        <StyledTextWrapper>{countryName}</StyledTextWrapper>
                      </StyledLegendField>
                      <StyledLegendField>
                        <StyledFieldLegend>
                          {t(
                            "common:invoice:tracking:buyerDetails:deliveryAddress"
                          )}
                        </StyledFieldLegend>
                        <StyledTextWrapper>
                          {blockNo} {streetName}{" "}
                          {deliveryCityAddress !== ""
                            ? deliveryCityAddress
                            : city}{" "}
                          {deliveryAddress !== "" ? deliveryAddress : state}{" "}
                          {deliveryCountryAddress !== ""
                            ? deliveryCountryAddress
                            : country}
                        </StyledTextWrapper>
                      </StyledLegendField>
                      <StyledLegendField>
                        <StyledFieldLegend>
                          {t(
                            "common:invoice:tracking:buyerDetails:RecipientName"
                          )}
                        </StyledFieldLegend>
                        <StyledTextWrapper>{recipientName}</StyledTextWrapper>
                      </StyledLegendField>
                    </Grid>
                  </StyledWrapper>
                </StyledPersonalBankFieldSet>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ButtonContainer>
          {isFormEditable === false ? (
            <StyledButton onClick={formSubmit}>
              {t("common:invoice:tracking:invoiceButton:saveDetails")}
            </StyledButton>
          ) : (
            <StyledButton style={{ background: "gray" }}>
              {t("common:invoice:tracking:invoiceButton:saveDetails")}
            </StyledButton>
          )}
        </ButtonContainer>
      </StyledContainerWrapper>
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
    </StyledComponent>
  );
};
export default TrakingProcess;
