import React, { useEffect } from 'react';
import { ThemeProvider } from "styled-components";
import { StylesProvider, useTheme } from '@material-ui/core/styles';
import { I18nextProvider } from 'react-i18next';
import i18n from './common/localization/i18next';
import AppRouter from './routes/AppRouter';
import theme from "./common/theme/theme";
import NetworkDetector from './helper/NetworkDetector';
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from 'react-redux';
import mixpanel from 'mixpanel-browser';

function App() {
  const muiTheme = useTheme();
  // 66ff2d8c2ccb8867a072828af932ee30
  useEffect(() => {
    mixpanel.init("0b45338f3bd84550f022ec02a963f15a", {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={muiTheme}>
            <ThemeProvider theme={theme}>
              <I18nextProvider i18n={i18n}>
                <AppRouter />
              </I18nextProvider>
            </ThemeProvider>
          </ThemeProvider>
        </StylesProvider>
      </PersistGate>
    </Provider>
  );
}

export default NetworkDetector(App);
