import React, { useEffect, useRef, useState } from "react";
import { match, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";

import { MuiThemeProvider } from "@material-ui/core/styles";
import update from "immutability-helper";

import Countdown from "react-countdown";
import moment from "moment";

import {
  createTheme,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import copy from "copy-to-clipboard";
import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import { Alert } from "reactstrap";
import {
  getBidInfo,
  getAuctionEndTimeUrl,
  getMyLatestBidsForUser,
  tendorBidUrl,
  getMyWatchedProduct,
  productUrl,
  productDownload,
} from "../../common/config/app.endpoints";
import EllipsisText from "../../common/elements/EllipsisText";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import { openCertificateLinkPopup } from "../../common/GenericFunctions";
import {
  StyledButton,
  StyledModal,
} from "../../common/materials/GenericStyleComponent";
import AuthService from "../../components/auth/AuthService";
import {
  getDescription,
  convertedValue,
  getReturnMyLatestStoredBid,
  getRapNet,
  getMyLatestStoredBids,
} from "../../components/products/preview/TenderGenericFunc";
import {
  StyledButtonView,
  Styledview,
} from "../../components/products/preview/TendorPreviewView";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import {
  StyledBtnContainer,
  StyledButtonGray,
  StyledLayoutGrid,
  StyledContent,
  StyledName,
  StyledTimeGridContainer,
  StyledTimer,
  StyledCoreDataGridContainer,
  StyledActionBarContainer,
  StyledButtonHeader,
  StyledTextWrapper,
} from "./WatchlistBiddingStyle";
import { useTranslation } from "react-i18next";
import CoreDataGridOfTender from "../../components/products/preview/tenderMobileComponets/CoreDataGridOfTender";
import BulkBidPopup from "../../components/products/preview/bidPopupComponent/BulkBidPopup";
import TenderSlider from "../../components/products/preview/bidPopupComponent/TenderSlider";
import ViewTenderExtendedModal from "../../components/products/auction/ViewTenderExtendedModal";
import ViewEndedModal from "../../components/products/auction/ViewEndedModal";
import PubSub from "pubsub-js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import { resetItemPerPage, resetCurrentApiStartCount, selectcurrentApiStartCount, selectItemPerPage, setCurrentApiStartCount, setItemPerPage } from "../../redux/slice/commanPagination/commanPaginationSlice";
import { AppDispatch } from "../../redux/store";
import TenderCommanPagination from "../../components/core-grid/grid-actions/TenderCommanPagination";
import { resetIsGridView, resetTenderDiamondGridProductWatchlistIndexes, resetTenderDiamondWatchlistSelectAll, selectGridView, setIsGridView, setTenderDiamondGridProductWatchlistIndexes, setTenderDiamondWatchlistSelectAll } from "../../redux/slice/annual/commanAnnualSlice";
import { StyledSelectAllButtonHeader } from "../../components/365Auction/component/DiamondProductStyle";
import TenderListOfProductwatchlist from "../../components/products/preview/tenderMobileComponets/TenderListOfProductwatchlist";
import AlertReadOnlyUser from "../../common/elements/AlertReadOnlyUser";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;

export const StyledHeaderContainer = styled(Grid)``;
export const StyledHederGrid = styled(Grid)``;

const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }

  .AccordionHeader {
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      top: 48px;
      width: 100% !important;
    }
    ${(props) => props.theme.breakpoints.between("lg", "xl")} {
      .MuiAccordion-root.Mui-expanded:first-child {
        margin-top: -5px;
      }
    }
  }
`;

export const StyledBackImageContianer = styled(Grid)`
  display: flex;
  align-items: center;
  padding: 5px;
`;
export const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;

export const StyledActionsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`;

interface DetailParams {
  auctionId: string;
  auctionUserId: any;
  auctionDate: any;
}
export interface IProps extends IRouterComponentProps {
  match: match<DetailParams>;
  dataObject: any;
  data: any;
  removeFromWatchlist: any;
  handleBackBtns: any;
  auctionState: any;
}

let body: any = [];
let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 10;
let rowSelectedData: any;

let SelectedTableData: any;
let updatedRowData: any;
let updateRowIndex: any;
let auctionProductResult: any;
let getCurenntPerId: any = "";

let seconds: any = "0";

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let auctionName: string = "";

let details: any;
let length: any;
let auctionDate: any;
let timeLeftLableColor: boolean = false;

const columnIndexes = {
  serialNo: 0,
  productId: 1,
  auctionUserId: 2,
  productCode: 4,
  lab: 5,
  certificate: 6,
  description: 7,
  productDescription: 8,
  carat: 9,
  color: 10,
  clarity: 11,
  remarks: 12,
  noOfPieces: 13,
  shape: 14,
  sieve: 15,
  cutGrade: 16,
  polish: 17,
  symmetryAbbreviation: 18,
  flour: 19,
  currency: 20,
  peicePerCt: 21,
  myLatestBids: 22,
  notes: 23,
  currentBidPrice: 24,
  repnetPrice: 25,
  noOfBids: 26,
  rowColor: 27,
  exchangeRate: 28,
  parcelType: 29,
  noOfBidders: 30,
  currentBidValue: 31,
  hieghestBidValue: 32,
  watched: 31,
  sellerAuctionUserId: 32,
  rapnetPercentage: 33,
  previousHighestBidder: 35,
  holdPreviousBidder: 36,
};

let date: string | string[] = "";
let indexsForBulkBidItems: any = [];
let myLatestBidForProduct: any = [];

let isReadOnlyUser: any;
const initialState = {
  mouseX: null,
  mouseY: null,
};
let auctionsState: any;
let isAuctionUserId: boolean;
const DiamondWatchlistBiddingCompnent = (props: IProps) => {
  let { handleBackBtns, auctionState } = props;
  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });

  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openEndedModal, setOpenEndedModal] = useState(false);
  const [openTenderExtended, setOpenTenderExtended] = useState(false);
  const [showTenderSlider, setshowTenderSlider] = useState(false);
  const [showBulkBidPopup, setShowBulkBidPopup] = useState(false);
  const [showBulkBidButton, setShowBulkBidButton] = useState(false);
  const [isDataRefresh, setIsDataRefresh] = useState(false);
  const theme = useTheme();
  const [auctionTenderState, setAuctionTenderState] = useState("");
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const [shouldGetAllData, setShouldGetAllData] = useState(false);
  const [isDataTableRefresh, setIsDataTableRefresh] = useState(false);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [isSort, setIsSort] = useState<boolean>(false);
  const [firstTimeExetendPopup, setFirstTimeExetendPopup] =
    useState<boolean>(false);
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  let isAuctionPage: any = true;
  let isCategoryPageType: any = true;

  let selectedBidCurrentPage: any;
  let history = useHistory();

  const Auth = new AuthService();
  const { t } = useTranslation();
  const eventSource: any = useRef<any>();
  const maxRetryCount = 12;
  const countRef = useRef(0);
  const itemsPerPageUseRef = useRef(10);
  const selectPageUseRef = useRef(0);

  const dispatch: AppDispatch = useDispatch();
  const isGridView: any = useSelector((state: RootState) =>
    selectGridView(state)
  );
  useEffect(() => {
    dispatch(resetTenderDiamondGridProductWatchlistIndexes());
    dispatch(resetTenderDiamondWatchlistSelectAll());
    setIsLoading(true);
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    localStorage.removeItem("dropDownFilterVal");
    localStorage.removeItem("currentPageOfPreview");
    localStorage.removeItem("jwlauctionUserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("currentStart");
    getTendorAucProducts(props.match.params.auctionId, 0);
    //data table api calling
    getAllUpdatedData();

    dispatch(resetItemPerPage());
    dispatch(resetCurrentApiStartCount());

    getAuctionEndTime(props.match.params.auctionId);
    getMyLatestBids(props.match.params.auctionId);
    //note: we are removing currentPageOfTenderAllProductsdiam from localstorage since we already loaded that page in "getTendorAucProducts()"
    localStorage.removeItem("currentPageOfTenderAllProductsdiam");
    return () => {
      if (eventSource.current !== undefined || eventSource.current !== null) {
        if (eventSource.current.close !== undefined) {
          eventSource.current.close();
        }
      }
    };
  }, []);

  //redux page selector
  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectcurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "currentStart",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    getTendorAucProducts(
      props.match.params.auctionId,
      selectcurrentApiStartCountValue
    );
  }, [selectItemPerPageValue, selectcurrentApiStartCountValue]);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  //get All updated data
  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(getBidInfo);
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData !== undefined) {
          if (updatedRowData.alive) {
            //This will listen only for events
          } else {
            if (props.match.params.auctionId === updatedRowData.auctionId) {
              updateRowIndex = auctionProductResult.findIndex(
                (product: any) => product.perId === updatedRowData.perId
              );
              const mapping: any = {};
              if (
                auctionProductResult !== undefined &&
                auctionProductResult.length > 0
              ) {
                auctionProductResult.map((items: any) => {
                  mapping[items.perId] = {
                    watched: items.watched,
                  };
                });
              }
              var updatedArr = update(auctionProductResult, {
                $splice: [[updateRowIndex, 1, updatedRowData]],
              });
              const arr = [...updatedArr];
              // auctionProductResult = updatedArr;
              arr.map((items: any) => {
                if (mapping[items.perId] !== undefined) {
                  items.watched = mapping[items.perId].watched;
                }
              });

              auctionProductResult = arr;
              setAuctionProducts(auctionProductResult);
              setShouldGetAllData(false);
            }
          }
        }
      };
      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        countRef.current = countRef.current + 1;
        setShouldGetAllData(true);
      };
    }
  };

  const getAuctionEndTime = (auctionId: string) => {
    if (!oAuth()) {
      // props.match.params.history.push("/");
    } else {
      Auth.getRequest(
        `${getAuctionEndTimeUrl}/${auctionId}/end_time`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            auctionsState = response.state;
            setFirstTimeExetendPopup(response.tenderExtended);
            getLeftTime(response.endDate);
            setAuctionTenderState(response.state);
            timeLeftLableColor = response.tenderExtended;
            // if (callBack !== undefined) {
            //   callBack(response);
            // }
            if (response.state === "Ended") {
              setOpenEndedModal(true);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  //Note: Get my All LatestBid using this Api
  const getMyLatestBids = (auctionId: string) => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.getRequest(
        `${getMyLatestBidsForUser}?auctionId=${auctionId}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            myLatestBidForProduct = response;

          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage("Internal Server Error");
            setAlertType("error");
            setOpenToast(false);
          }
        }
      );
    }
  };

  const handleBidClick = (tableData: any) => {
    rowSelectedData = tableData.rowData;
    SelectedTableData = tableData.tableData;
    getProductsDetails(SelectedTableData);
    details = tableData.rowIndex;
    setshowTenderSlider(true);
  };

  //rediract from bid now button of product details page -------

  const renderAutomaticBidPopupInMobile = (data: any) => {
    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      const indexOfProduct = auctionProducts.findIndex(
        (item: any) => item.perId === rediractProductId
      );
      rowSelectedData = data;
      SelectedTableData = auctionProducts;
      details = indexOfProduct;
      if (details !== -1) {
        setshowTenderSlider(true);
      }
      localStorage.removeItem("currentProductIdOfBidNow");
    }
  };

  // Get index for tender bid popup
  const getProductsDetails = (productsDetails: any) => {
    length = productsDetails.length;
    productsDetails.map((items: any, index: any) => {
      if (items.perId === rowSelectedData[columnIndexes.productId]) {
        details = index;
      }
    });
    return details;
  };

  //index of Mobile ui component
  const getBoxIndex = (boxIndex: any) => {
    length = auctionProducts.length;
    details = boxIndex;
    setshowTenderSlider(true);
  };

  // Tender bulk bid popup
  const bulkBidPopupHandler = () => {
    setShowBulkBidPopup(true);
  };

  const handleBtnValue = (
    values: number,
    currentBidPrice: any,
    productId: any,
    myBidValues: any
  ) => {
    var data = [
      {
        bidIncreasedBy: values,
        bidPrice: currentBidPrice,
        auctionUserId: props.match.params.auctionUserId,
        auctionId: props.match.params.auctionId,
        productId: productId,
      },
    ];
    let updateRowIndex = myLatestBidForProduct.findIndex(
      (product: any) => product.productId === myBidValues.productId
    );
    if (updateRowIndex === -1) {
      var myLatestBidForProductObjectData: any = [
        ...myLatestBidForProduct,
        myBidValues,
      ];
      myLatestBidForProduct = myLatestBidForProductObjectData;
    } else {
      var updatedArray = update(myLatestBidForProduct, {
        $splice: [[updateRowIndex, 1, myBidValues]],
      });
      myLatestBidForProduct = updatedArray;
    }
    bidProduct(data);
    PubSub.publish("resetGridBoxIndex", []);
  };

  const bulkBidHandler = (values: any[], myBidBulkValues: any) => {
    myLatestBidForProduct = myBidBulkValues;
    bidProduct(values);
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(resetTenderDiamondGridProductWatchlistIndexes());
    dispatch(resetTenderDiamondWatchlistSelectAll());
    setIsSelected(false);
  };

  const bidProduct = (payloadBid: any) => {
    if (!oAuth()) {
      // props.match.params.history.push("/");
    } else {
      Auth.postRequest(
        tendorBidUrl,
        payloadBid,
        (status: any, response: any) => {
          if (status === 200) {
            setIsDataRefresh(!isDataRefresh);
            getMyLatestBids(props.match.params.auctionId);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };


  const getTendorAucProducts = (
    auctionId: number | string,
    currentPage: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    setOpenToast(false);
    body = [];
    filteredValues = "";
    filteredParcelValues = "";

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);

      if (filteredValues !== undefined) {
        if (filteredValues === null || filteredValues.length === 0) {
          filter = null;
        } else {
          filteredValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredValues.splice(index, 1);
            }
          });
          filter = filteredValues;
        }
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }
    getBody(auctionId, currentPage, endCount, isLoadPreSelectedPage);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number,
    isLoadPreSelectedPage?: boolean
  ) => {
    payload = {};
    getCurenntPerId = localStorage.getItem("currentPDItem");

    if (localStorage.getItem("tabSelectedVal") === "1") {
      filteredValues = localStorage.getItem("filteredValues");
      filteredValues = JSON.parse(filteredValues);
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);
    }

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    if (
      (filter === null &&
        (filteredValues == null ||
          filteredValues === undefined ||
          filteredValues === "null" ||
          filteredValues.length === 0)) ||
      (filter === null &&
        (filteredParcelValues == null ||
          filteredParcelValues === undefined ||
          filteredParcelValues === "null" ||
          filteredParcelValues.length === 0))
    ) {
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
          ],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",

                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      if (filteredValues !== null && filteredValues.length > 0) {
        body = filteredValues ? filteredValues : filter;
      } else {
        body = filteredParcelValues ? filteredParcelValues : filter;
      }

      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });

      if (localStorage.getItem("tabSelectedVal") === "1") {
        body.push({
          match: "exact",
          name: "auctionId",
          value: props.match.params.auctionId,
        });
        payload = {
          filters: {
            and: [],
          },
          page: {
            start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
            count: endCount,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "serialNo",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      } else {
        let andArr = [];
        andArr.push(
          {
            match: "exact",
            name: "auctionId",
            value: props.match.params.auctionId,
          },
          {
            match: "exact",
            name: "parcelType",
            value: "Parcel",
          }
        );
        payload = {
          filters: {
            or: body,
            and: [],
          },
          page: {
            start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
            count: endCount,
            sort: {
              orders: [
                {
                  property:
                    property !== null && property !== undefined
                      ? property
                      : "serialNo",
                  direction:
                    direction !== null && direction !== undefined
                      ? direction
                      : "ASC",
                },
              ],
            },
          },
        };
      }
    }
    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "productDescription",
              value: val,
            },
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
          and: [],
        },
        page: {
          start: isLoadPreSelectedPage ? currentPage * 10 : startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(payload);
    setIsLoading(true);
  };

  const callApi = async (payload: any) => {
    setIsTableExpanded(false);
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${getMyWatchedProduct}/${props.match.params.auctionId}/watchlist`,
        payload,
        (status: any, response: any) => {
          if (status === 200) {
            setIsLoading(true);
            if (
              startCount !== 0 &&
              response.results.length === 0
            ) {
              dispatch(resetCurrentApiStartCount());
              selectPageUseRef.current = 0;
              localStorage.setItem("currentStart", '0');
              setAuctionProducts(response.results);
            } else {
              setAuctionProducts(response.results);
              totalElement = response.totalElements;
              setIsLoading(false);
              auctionName = response?.results[0]?.auctionName;
              auctionState = response?.results[0]?.auctionState;
              auctionProductResult = response.results;
              if (response.results.length > 0) {
                auctionDate = response?.results[0]?.auctionStartDate;
                date = auctionDate.split(" ", 2);
              }
              setAuctionProducts(response.results);
              setTimeout(() => {
                setIsLoading(false);
                setIsDataTableRefresh(!isDataTableRefresh);
              }, 3000);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any;
    if (isTableExpanded) {
      heightOfInnerTable = h - 370;
    } else {
      heightOfInnerTable = h - 230;
    }
    if (auctionProducts?.length > 2) {
      return `${heightOfInnerTable}px`;
    } else {
      return `auto`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: "false",
    download: "false",
    print: "false",
    viewColumns: "false",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    selectableRows: "multiple",
    selectableRowsHeader: true,
    selectableRowsOnClick: false,
    selectableRowsHideCheckboxes: false,
    isRowSelectable: (dataIndex: any, selectedRows: any) => {
      const disableRowIndexsArray = getDisableRowIndex();
      return disableRowIndexsArray.includes(dataIndex) ? false : true;
    },
    onChangePage: (currentPage: number) => {
      setIsLoading(true);
      localStorage.setItem(
        "currentStart",
        JSON.stringify(currentPage * itemsPerPageUseRef.current)
      );
      dispatch(setCurrentApiStartCount({ currentPage }));
      // getTendorAucProducts(props.match.params.auctionId, currentPage);
    },
    onSearchChange: (searchTxt: any) => {
      return searchTxt;
    },
    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
      return (
        <div className="mailIconDiv">
          <IconButton color="primary">
            <i className="zmdi zmdi-email"></i>
          </IconButton>
        </div>
      );
    },
    setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
      if (row[columnIndexes.previousHighestBidder] === undefined) {
        if (row[columnIndexes.holdPreviousBidder] === undefined) {
          if (
            row[columnIndexes.auctionUserId] !==
            props.match.params.auctionUserId
          ) {
            return { style: { background: "white" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        } else {
          if (
            props.match.params.auctionUserId ===
            row[columnIndexes.holdPreviousBidder]
          ) {
            return { style: { background: "#ffeb9c" } };
          } else {
            return { style: { background: row[columnIndexes.rowColor] } };
          }
        }
      } else {
        if (
          props.match.params.auctionUserId ===
          row[columnIndexes.previousHighestBidder]
        ) {
          return { style: { background: "#FBE4D5" } };
        } else {
          return { style: { background: row[columnIndexes.rowColor] } };
        }
      }
    },
    onColumnSortChange: (price: any, direction: any) => {
      let order = "";
      if (isSort === false) {
        order = "desc";
      } else {
        order = "asc";
      }
      setIsSort(!isSort);
      sort(price, order);
    },
    onRowSelectionChange: (
      currentRowsSelected: any,
      allRowsSelected: any,
      rowsSelected: any
    ) => {
      setOpenToast(false);
      indexsForBulkBidItems = rowsSelected;
      indexsForBulkBidItems.map((indexs: any) => {
        let {
          sellerAuctionUserId,
          previousHighestBidder,
          auctionUserId,
          holdPreviousBidder,
        } = auctionProducts[indexs];
        if (sellerAuctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        } else if (previousHighestBidder !== undefined) {
          if (props.match.params.auctionUserId === previousHighestBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return { ...options, isRowSelectable: "false" };
          }
        } else if (auctionUserId === props.match.params.auctionUserId) {
          return { ...options, isRowSelectable: "false" };
        }
      });
      if (indexsForBulkBidItems.length > 0) {
        setShowBulkBidButton(true);
      } else {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length === 0) {
        setShowBulkBidButton(false);
      }
      if (indexsForBulkBidItems.length > 10) {
        setShowAlertMessage(
          `${t("common:Tender:validation:productSelection")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    },
    tableBodyHeight: getTableBodyHeight(),
    textLabels: {
      body: {
        noMatch: `${t("common:preview:noProductFound")}`,
      },
    },
  };

  const onGridSelectionChange = (rowsSelected: any) => {
    indexsForBulkBidItems = rowsSelected;
    setOpenToast(false);
    if (indexsForBulkBidItems.length >= 0) {
      setShowBulkBidButton(true);
    } else {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length === 0) {
      setShowBulkBidButton(false);
    }
    if (indexsForBulkBidItems.length > 10) {
      setShowAlertMessage(`${t("common:Tender:validation:productSelection")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const boxColor = (items: any) => {
    const {
      auctionUserId,
      rowColor,
      previousHighestBidder,
      holdPreviousBidder,
    } = items;
    if (previousHighestBidder === undefined) {
      if (holdPreviousBidder === undefined) {
        if (auctionUserId !== props.match.params.auctionUserId) {
          return "white";
        } else {
          return rowColor;
        }
      } else {
        if (props.match.params.auctionUserId === holdPreviousBidder) {
          return "#ffeb9c";
        } else {
          return rowColor;
        }
      }
    } else {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return "#FBE4D5";
      } else {
        return rowColor;
      }
    }
  };

  const getDisableRowIndex = () => {
    return auctionProducts.map((items: any, index: number) => {
      if (auctionState !== "NotStarted") {
        if (isReadOnlyUser) {
          return index;
        } else {
          if (
            items.sellerAuctionUserId === props.match.params.auctionUserId
          ) {
            return index;
          } else if (items.holdPreviousBidder !== undefined) {
            if (
              props.match.params.auctionUserId === items.holdPreviousBidder
            ) {
              return index;
            }
          } else if (items.previousHighestBidder !== undefined) {
            if (
              props.match.params.auctionUserId ===
              items.previousHighestBidder
            ) {
              return index;
            }
          } else if (
            items.auctionUserId === props.match.params.auctionUserId
          ) {
            return index;
          }
        }
      } else {
        return index;
      }
    });
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (isSort === false) {
      order = "desc";
    } else {
      order = "asc";
    }
    setIsSort(!isSort);
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any = [{ property: values, direction: direction }];

    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }

    if (selectcurrentApiStartCountValue === 0) {
      getTendorAucProducts(props.match.params.auctionId, 0);
    } else {
      dispatch(resetCurrentApiStartCount());
    }
    selectPageUseRef.current = 0;
    localStorage.setItem("currentStart", '0');
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionUserId",
      label: "Auction User Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: `${t("common:Tender:tableColumnNames:ActionsTender")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                  props.match.params.auctionUserId ? (
                  <>
                    {getWatchedProduct(
                      tableMeta.rowData[columnIndexes.watched],
                      tableMeta.rowData[columnIndexes.productId],
                      props.match.params.auctionId,
                      tableMeta.rowData[1]
                    )}
                  </>
                ) : (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                  />
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(tableMeta.rowData[columnIndexes.productId])
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:productId")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "150px", maxWidth: "150px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            tableMeta.rowData[columnIndexes.lab] !== "AGT" &&
            tableMeta.rowData[columnIndexes.lab] !== "agt" &&
            tableMeta.rowData[columnIndexes.lab] !== "" &&
            tableMeta.rowData[columnIndexes.lab] !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openCertificateLinkPopup(
                    tableMeta.rowData[columnIndexes.lab],
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "description",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productDescription",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "carat",
      label: `${t("common:Tender:tableColumnNames:carat")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "color",
      label: `${t("common:Tender:tableColumnNames:color")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "clarity",
      label: `${t("common:Tender:tableColumnNames:clarity")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "remarks",
      label: `Remarks`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <EllipsisText
              text={getDescription(
                tableMeta.rowData[columnIndexes.parcelType],
                tableMeta.rowData[columnIndexes.productDescription],
                tableMeta.rowData[columnIndexes.remarks]
              )}
              length={20}
              tail={"..."}
            />
          );
        },
      },
    },
    {
      name: "noOfPieces",
      label: `${t("common:Tender:tableColumnNames:noOfPieces")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== 0 ? value : "-"}</>;
        },
      },
    },
    {
      name: "shape",
      label: `${t("common:Tender:tableColumnNames:shape")}`,
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: "sieve",
      label: `${t("common:Tender:tableColumnNames:Sieve")}`,
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== "-" ? value : ""}</>;
        },
      },
    },
    {
      name: "cutGrade",
      label: `${t("common:Tender:tableColumnNames:cutGrade")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "polish",
      label: `${t("common:Tender:tableColumnNames:polish")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "symmetryAbbreviation",
      label: `${t("common:Tender:tableColumnNames:symmetryAbbreviation")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "flour",
      label: `${t("common:Tender:tableColumnNames:flour")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:pricePct")}`,
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: { minWidth: "120px", maxWidth: "120px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "myLatestBid",
      label: `${t("common:Tender:tableColumnNames:placeBid")}`,
      options: {
        filter: false,
        sort: false,
        setCellProps: () => ({
          style: { minWidth: "130px", maxWidth: "140px" },
        }),
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              {isDataRefresh ? (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      tableMeta.rowData[columnIndexes.productId],
                      tableMeta.rowData[columnIndexes.currency],
                      tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                    )}
                </div>
              ) : (
                <div>
                  {value === undefined || value === null
                    ? 0
                    : handelMyBidsLabel(
                      myLatestBidForProduct,
                      tableMeta.rowData[columnIndexes.productId],
                      tableMeta.rowData[columnIndexes.currency],
                      tableMeta.rowData[columnIndexes.exchangeRate][0].rate
                    )}
                </div>
              )}
              {getBidNowButtonDisable(
                tableMeta.rowData[columnIndexes.sellerAuctionUserId],
                props.match.params.auctionUserId,
                tableMeta.rowData[columnIndexes.auctionUserId],
                tableMeta,
                getReturnMyLatestStoredBid(
                  myLatestBidForProduct,
                  tableMeta.rowData[columnIndexes.productId]
                ),
                tableMeta.rowData[columnIndexes.currentBidPrice],
                tableMeta.rowData[columnIndexes.productId],
                tableMeta.rowData[columnIndexes.previousHighestBidder],
                tableMeta.rowData[columnIndexes.holdPreviousBidder]
              )}
            </>
          );
        },
      },
    },
    {
      name: "notes",
      label: `${t("common:Tender:tableColumnNames:notes")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: true,
        display: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rapnetPercentage",
      label: `${t("common:Tender:tableColumnNames:rapOff")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getRapNet(tableMeta.rowData[columnIndexes.rapnetPercentage])}
            </div>
          );
        },
      },
    },
    {
      name: "noOfBidders",
      label: `${t("common:newCommanTrans:noOfBiddersBids")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {getNoOfBiddersAndNoOfBids(
                tableMeta.rowData[columnIndexes.noOfBids],
                tableMeta.rowData[columnIndexes.noOfBidders]
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "parcelType",
      label: "",
      options: {
        display: false,
      },
    },

    {
      name: "noOfBids",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "rapnetPercentage",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "tenderSecondHighestBid",
      label: `${t("common:Tender:tableColumnNames:liveHighestBid")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchangeRate][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "previousHighestBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "holdPreviousBidder",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  //get bid now button disable
  const getBidNowButtonDisable = (
    sellerId: any,
    auctionUserIdForProduct: any,
    tableAuctionUserId: any,
    tableDataForUIBox: any,
    myBidsForProduct: any,
    currentBidPriceForProduct: any,
    currentproductId: any,
    previousBidderId: any,
    holdPreviousBidder: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentproductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.myLatestBid;
    });

    // renderAutomaticBidPopup(tableDataForUIBox);
    if (auctionState !== "NotStarted") {
      if (isReadOnlyUser) {
        return (
          <StyledBtnContainer onClick={() => {
            renderReadOnlyUserToast(sellerId);
          }}>
            <StyledButtonGray>
              {t("common:inquiry:button:bidNow")}
            </StyledButtonGray>
          </StyledBtnContainer>
        );
      } else {
        if (sellerId === auctionUserIdForProduct) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else if (holdPreviousBidder !== undefined) {
          if (props.match.params.auctionUserId === holdPreviousBidder) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        } else if (previousBidderId !== undefined) {
          if (props.match.params.auctionUserId === previousBidderId) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        } else if (tableAuctionUserId === props.match.params.auctionUserId) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else if (mybidPriceforProduct[0] > currentBidPriceForProduct) {
          return (
            <StyledBtnContainer>
              <StyledButtonGray>
                {t("common:inquiry:button:bidNow")}
              </StyledButtonGray>
            </StyledBtnContainer>
          );
        } else {
          if (tableAuctionUserId === props.match.params.auctionUserId) {
            return (
              <StyledBtnContainer>
                <StyledButtonGray>
                  {" "}
                  {t("common:inquiry:button:bidNow")}
                </StyledButtonGray>
              </StyledBtnContainer>
            );
          } else {
            return (
              <StyledBtnContainer>
                <StyledButton onClick={() => handleBidClick(tableDataForUIBox)}>
                  {t("common:inquiry:button:bidNow")}
                </StyledButton>
              </StyledBtnContainer>
            );
          }
        }
      }
    } else {
      return (
        <StyledBtnContainer>
          <StyledButtonGray>
            {t("common:inquiry:button:bidNow")}
          </StyledButtonGray>
        </StyledBtnContainer>
      );
    }
  };

  //get My All MyBid
  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any, index: number) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  //get Watched Product
  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    return (
      <FontAwesomeIcon
        icon={faHeartProps}
        style={{ color: "red", cursor: "pointer" }}
        onClick={() => {
          removeProductFromList(productId, auctionId);
        }}
      />
    );
  };

  const shareButtonAction = (perId: any) => {
    let languageCode = localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/${perId}/${date[0]}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const getNoOfBiddersAndNoOfBids = (noOfBidders: any, noOfBids: any) => {
    return `${noOfBidders}/${noOfBids}`;
  };

  //this funtion render product details page
  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("auctionUserId", props.match.params.auctionUserId);
    history.push(
      `/preview/tender/details/${auctionId}/${perId}/${date[0]}?isAuction=false&uniqid=${props.match.params.auctionUserId}`
    );
    dispatch(resetItemPerPage());
    dispatch(resetCurrentApiStartCount());
  };
  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    localStorage.setItem("auctionUserId", props.match.params.auctionUserId);
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    window.open(
      `/${languageCode}/preview/tender/details/${auctionId}/${perId}/${date[0]}?isAuction=false&uniqid=${props.match.params.auctionUserId}`,
      "_blank"
    );
    dispatch(resetItemPerPage());
    dispatch(resetCurrentApiStartCount());
  };

  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const getLeftTime = (startDate: any) => {
    let date: string | null = "";
    let time: string | null = "";
    let hour: any = "";
    let minutes: any = "";
    let timeDifference: any = "";
    let timezone: any = "";

    if (startDate !== null || startDate !== undefined) {
      let previewTime = moment.utc(startDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          //let start: any = moment(dateTime);
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          //let end: any = moment(new Date().toLocaleString("en-US", { timeZone: timezone }));
          let start: any = moment(
            new Date().toLocaleString("en-US", {
              timeZone: timezone !== null ? timezone : "",
            })
          );
          hour = start.diff(end, "hours");
          minutes = start.diff(end, "minutes");
          timeDifference = localStorage.getItem("timeDifference");
          timeDifference = timeDifference / 1000;
          timeDifference = parseInt(timeDifference);
          //var timeDiff = start - end;
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
          {
            timeDifference[0] === "-"
              ? (seconds = seconds - timeDifference)
              : (seconds = seconds + timeDifference);
          }
        }
      }
    }
    setTimeout(() => {
      getLeftTime(startDate);
    }, 1000);
  };

  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        getTimePopup();
        return <span>00:00:00:00</span>;
      }
    } else {
      return (
        <span>
          {days}:{hours}:{minutes}:{seconds}
        </span>
      );
    }
  };

  const getTimePopup = () => {
    // const callBack = (response: any) => {
    //   const { state, tenderExtended } = response;
    //   if (
    //     state === "Started" &&
    //     tenderExtended === true &&
    //     isUserClosedExtendedPopup === false
    //   ) {
    //     setOpenTenderExtended(true);
    //   } else if (state === "Ended") {
    //     setOpenEndedModal(true);
    //     setOpenTenderExtended(false);
    //   }
    //   timeLeftLableColor = tenderExtended;
    // };
    if (auctionsState === "Started" && firstTimeExetendPopup === false) {
      setOpenTenderExtended(true);
       timeLeftLableColor = true;
    }
    setTimeout(() => {
      getAuctionEndTime(props.match.params.auctionId);
    }, 2400);
  };

  //Auction end popup redirection funtion
  const TenderTimeExtended = () => {
    getAllUpdatedData();
    getTendorAucProducts(props.match.params.auctionId, 0);
    setOpenTenderExtended(false);
  };

  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("currentPageOfTenderAllProductsdiam", "0");
    setCurrentPage(0);
    setItemsPerPage(itemsPerPage);
    itemsPerPageUseRef.current = itemsPerPage;
    dispatch(setItemPerPage({ itemsPerPage }));
    dispatch(resetCurrentApiStartCount());
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }
  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("tenderitemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("currentStart", JSON.stringify(start));
    selectPageUseRef.current = start;
    let currentPage = start / itemsPerPage;
    dispatch(setCurrentApiStartCount({ currentPage }));
    dispatch(setItemPerPage({ itemsPerPage }));
    onGridSelectionChange(0);
    dispatch(resetTenderDiamondGridProductWatchlistIndexes());
    dispatch(resetTenderDiamondWatchlistSelectAll());
    PubSub.publish("resetGridBoxIndex", []);
  };

  const closebidPopup = () => {
    setshowTenderSlider(false);
    setShowBulkBidPopup(false);
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  //watchlist redirection Fucntion

  const listGridview = () => {
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    console.log(startCount);
    selectPageUseRef.current = startCount;
    dispatch(setIsGridView({ gridView: true }));
  };

  const Gridview = () => {
    onGridSelectionChange(0);
    PubSub.publish("resetGridBoxIndex", []);
    dispatch(resetIsGridView());
  };

  const removeProductFromList = (perId: any, auctionId: any) => {
    setOnAction(true);
    setOpenToast(false);
    setIsLoading(true);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${perId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            // callApi(payload);
            setshowTenderSlider(false);
            getTendorAucProducts(auctionId, selectPageUseRef.current);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const addToWatchList = () => {
    // setDisableButton(true);
    // props.removeFromWatchlist(perId, auctionId, serialNo);
  };

  const TenderauctionEndedModal = () => {
    history.push("/auction");
  };

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };


  const exportExcel = (auctionId: string,) => {
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${auctionId}&auctionProductsType=watched`,
      { ids: '' },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        }
      }
    );
  }
  
  const selectAllInGridView = () => {
    //calling select all function in grid View     
    let arr: any = [];
    auctionProducts.map((productVal: any, index: any) => {
      if (arr.length < 10) {
        if (isReadOnlyUser) {
          //
        } else {
          if (productVal.sellerAuctionUserId === props.match.params.auctionUserId) {
            //
          } else if (productVal.holdPreviousBidder !== undefined) {
            if (props.match.params.auctionUserId === productVal.holdPreviousBidder) {
              ///
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.previousBidderId !== undefined) {
            if (props.match.params.auctionUserId === productVal.previousBidderId) {
              //
            } else {
              arr = [...arr, index];
            }
          } else if (productVal.auctionUserId === props.match.params.auctionUserId) {
            //
          } else {
            if (productVal.auctionUserId === props.match.params.auctionUserId) {
              //
            } else {
              arr = [...arr, index];
            }
          }
        }
      }
    })
    if (isSelected) {
      setIsSelected(false);
      PubSub.publish("resetGridBoxIndex", []);
      arr = [];
      dispatch(resetTenderDiamondGridProductWatchlistIndexes());
      dispatch(resetTenderDiamondWatchlistSelectAll());
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: false, selectedIndexes: arr });
    } else {
      setIsSelected(true);
      dispatch(setTenderDiamondGridProductWatchlistIndexes({ indexes: arr }));
      dispatch(setTenderDiamondWatchlistSelectAll({ isSelected: true }));
      // PubSub.publish("jewellerySelectAllCheckBox", { isItemSelected: true, selectedIndexes: arr });
    }
  }


  return (
    <Grid>
      {isLoading === true ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <StyledLayoutGridMain>
          {isTopElementHide ? null : (
            <Accordion
              onChange={handleChangeAcco("panel1")}
              style={{
                position: "fixed",
                right: "0",
                animation: "fadeMe 1s",
              }}
              className="AccordionHeader"
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                  />
                }
              ></AccordionSummary>
              <div style={{ width: "100%", display: "block" }}>
                <StyledHeaderContainer container={true} spacing={0}>
                  <StyledCoreDataGridContainer container={true} spacing={0}>
                    <StyledActionBarContainer
                      container={true}
                      spacing={0}
                      className="coreActionBarContainer"
                    >
                      <Grid container={true} xs={12} lg={3} xl={2}>
                        <StyledBackImageContianer>
                          <StyledBackBtn
                            src="/images/controls/left_arrow_round_edges.png"
                            onClick={handleBackBtns}
                          />
                        </StyledBackImageContianer>
                      </Grid>
                      <StyledActionsContainer
                        item={true}
                        xs={12}
                        lg={9}
                        xl={10}
                      >
                        {auctionTenderState === "NotStarted" ? "" :
                        <>
                        {notSmallDevices ? (
                          <>
                            {isGridView === true ? (
                              <StyledSelectAllButtonHeader
                                onClick={selectAllInGridView}
                              >
                                {t("common:newCommanTrans:selectAll")}
                              </StyledSelectAllButtonHeader>
                            ) : null}
                          </>
                        ) : (
                          <StyledSelectAllButtonHeader
                            onClick={selectAllInGridView}
                          >
                            {t("common:newCommanTrans:selectAll")}
                          </StyledSelectAllButtonHeader>
                        )}</>}
                        {showBulkBidButton === true ? (
                          <StyledButtonHeader onClick={bulkBidPopupHandler}>
                            {t("common:preview:tendor:bulkBid")}
                          </StyledButtonHeader>
                        ) : (
                          <></>
                        )}
                        <StyledButtonHeader
                          onClick={() =>
                            exportExcel(props.match.params.auctionId)
                          }
                        >
                          {t("common:preview:pagination:export")}
                        </StyledButtonHeader>
                        <TenderCommanPagination
                          setItemsPerPageHandler={setItemsPerPageHandler}
                          t={t}
                        />
                        {notSmallDevices ? (
                          <>
                            {isGridView === true ? (
                              <StyledButtonView onClick={Gridview}>
                                <Styledview src="/images/view/listView.png" />
                              </StyledButtonView>
                            ) : (
                              <StyledButtonView onClick={listGridview}>
                                <Styledview src="/images/view/gridview.jpeg" />
                              </StyledButtonView>
                            )}
                          </>
                        ) : null}
                      </StyledActionsContainer>
                    </StyledActionBarContainer>
                  </StyledCoreDataGridContainer>
                </StyledHeaderContainer>
              </div>
            </Accordion>
          )}

          <StyledLayoutGrid container={true}>
            <>
              <>
                <Grid
                  item={true}
                  xs={12}
                  style={{ paddingTop: isTableExpanded ? "160px" : "20px" }}
                >
                  <Grid
                    container={true}
                    spacing={0}
                    className="actionContainer"
                    style={{ padding: "0px 0px 0 8px" }}
                  >
                    <Grid item xs={6}>
                      <StyledName>
                        {isDataTableRefresh ? (
                          <StyledContent>
                            <EllipsisText
                              text={auctionName}
                              length={28}
                              tail={"..."}
                            />
                          </StyledContent>
                        ) : (
                          <StyledContent>
                            <EllipsisText
                              text={auctionName}
                              length={28}
                              tail={"..."}
                            />
                          </StyledContent>
                        )}
                      </StyledName>
                    </Grid>
                    <Grid item xs={6}>
                      {auctionState === "Started" ? (
                        <>
                          {seconds >= 0 && (
                            <StyledTimeGridContainer
                              container={true}
                              spacing={0}
                            >
                              {timeLeftLableColor ? (
                                <StyledTimer style={{ color: "red" }}>
                                  <span>
                                    {t("common:preview:tendor:timeLeft")}{" "}
                                  </span>
                                  <Countdown
                                    date={Date.now() + seconds}
                                    className="countdownText"
                                    renderer={renderer}
                                    zeroPadTime={2}
                                  />
                                </StyledTimer>
                              ) : (
                                <StyledTimer>
                                  <span>
                                    {t("common:preview:tendor:timeLeft")}{" "}
                                  </span>
                                  <Countdown
                                    date={Date.now() + seconds}
                                    className="countdownText"
                                    renderer={renderer}
                                    zeroPadTime={2}
                                  />
                                </StyledTimer>
                              )}
                            </StyledTimeGridContainer>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {isGridView === false && notSmallDevices && (
                  <MuiThemeProvider theme={getMuiTheme()}>
                    {isDataTableRefresh ? (
                      <MUIDataTable
                        title={""}
                        data={auctionProducts}
                        columns={columns}
                        options={options}
                      />
                    ) : (
                      <MUIDataTable
                        title={""}
                        data={auctionProducts}
                        columns={columns}
                        options={options}
                      />
                    )}
                  </MuiThemeProvider>
                )}
                {isGridView === true
                  ? notSmallDevices && (
                      <>
                        <CoreDataGridOfTender
                          t={t}
                          data={auctionProducts}
                          totalElements={totalElement}
                          itemPerPage={itemPerPage}
                          value={getUpdatedProducts}
                          noProductFound={handleMyBids}
                          itemsPerPageUseRef={itemsPerPageUseRef.current}
                          selectPageUseRef={selectPageUseRef.current}
                        >
                          {auctionProducts.length > 0 ? (
                            <TenderListOfProductwatchlist
                              {...props}
                              watchlist={addToWatchList}
                              setShowBidPopupBidModal={getBoxIndex}
                              isCategoryPageType={isCategoryPageType}
                              auctionName={auctionName}
                              auctionDate={date[0]}
                              t={t}
                              isAuctionPage={isAuctionPage}
                              unWatchlist={removeProductFromList}
                              auctionUserId={props.match.params.auctionUserId}
                              sortingValue={filterSort}
                              boxColor={boxColor}
                              myForProductAllProduct={myLatestBidForProduct}
                              bulkBidIndexHandler={onGridSelectionChange}
                              renderAutomaticBidPopupInMobile={
                                renderAutomaticBidPopupInMobile
                              }
                              propsUserId={props.match.params.auctionUserId}
                              renderReadOnlyUserToast={renderReadOnlyUserToast}
                              rightClickEvent={rightClickEvent}
                            />
                          ) : (
                            <Grid container={true} spacing={0}>
                              <StyledTextWrapper item xs={12}>
                                {t("common:preview:noProductFound")}
                              </StyledTextWrapper>
                            </Grid>
                          )}
                        </CoreDataGridOfTender>
                      </>
                    )
                  : null}
                {notSmallDevices || (
                  <>
                    <CoreDataGridOfTender
                      t={t}
                      data={auctionProducts}
                      totalElements={totalElement}
                      itemPerPage={itemPerPage}
                      value={getUpdatedProducts}
                      noProductFound={handleMyBids}
                      itemsPerPageUseRef={itemsPerPageUseRef.current}
                      selectPageUseRef={selectPageUseRef.current}
                    >
                      {auctionProducts.length > 0 ? (
                        <TenderListOfProductwatchlist
                          {...props}
                          watchlist={addToWatchList}
                          setShowBidPopupBidModal={getBoxIndex}
                          isCategoryPageType={isCategoryPageType}
                          auctionName={auctionName}
                          auctionDate={date[0]}
                          t={t}
                          isAuctionPage={isAuctionPage}
                          unWatchlist={removeProductFromList}
                          auctionUserId={props.match.params.auctionUserId}
                          sortingValue={filterSort}
                          boxColor={boxColor}
                          myForProductAllProduct={myLatestBidForProduct}
                          bulkBidIndexHandler={onGridSelectionChange}
                          renderAutomaticBidPopupInMobile={
                            renderAutomaticBidPopupInMobile
                          }
                          propsUserId={props.match.params.auctionUserId}
                          renderReadOnlyUserToast={renderReadOnlyUserToast}
                          rightClickEvent={rightClickEvent}
                        />
                      ) : (
                        <Grid container={true} spacing={0}>
                          <StyledTextWrapper item xs={12}>
                            {t("common:preview:noProductFound")}
                          </StyledTextWrapper>
                        </Grid>
                      )}
                    </CoreDataGridOfTender>
                  </>
                )}
              </>
            </>
            <Menu
              keepMounted
              open={mouseState.mouseY !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                mouseState.mouseY !== null && mouseState.mouseX !== null
                  ? { top: mouseState.mouseY, left: mouseState.mouseX }
                  : undefined
              }
            >
              <MenuItem
                onClick={() =>
                  openDetailsView(
                    props.match.params.auctionId,
                    currentProductId,
                    props.match.params.auctionDate
                  )
                }
              >
                Details
              </MenuItem>
            </Menu>

            {openToast === true && (
              <Alert
                showAlertMessage={showAlertMessage}
                alertType={alertType}
              />
            )}
            {onAction === true && (
              <div className="loading">
                <LoadingSpinner />
              </div>
            )}

            {openEndedModal === true && (
              <StyledModal
                open={openEndedModal}
                onClose={TenderauctionEndedModal}
              >
                <ViewEndedModal
                  t={t}
                  {...props}
                  closeModal={TenderauctionEndedModal}
                />
              </StyledModal>
            )}
            {openTenderExtended === true && (
              <StyledModal
                open={openTenderExtended}
                onClose={TenderTimeExtended}
              >
                <ViewTenderExtendedModal
                  t={t}
                  {...props}
                  closeModal={TenderTimeExtended}
                />
              </StyledModal>
            )}
            {showTenderSlider === true && (
              <StyledModal open={showTenderSlider} onClose={closebidPopup}>
                <TenderSlider
                  t={t}
                  {...props}
                  items={auctionProducts}
                  currentIndex={details}
                  sec={seconds}
                  timeRender={renderer}
                  PopupClose={closebidPopup}
                  handleBtnValue={handleBtnValue}
                  isFirstButtonDisables={false}
                  auctionDate={date[0]}
                  totalElements={totalElement}
                  endCount={endCount}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  watchedFunction={addToWatchList}
                  unwatched={removeProductFromList}
                  boxColor={boxColor}
                />
              </StyledModal>
            )}
            {showBulkBidPopup === true && (
              <StyledModal open={showBulkBidPopup} onClose={closebidPopup}>
                <BulkBidPopup
                  {...props}
                  items={auctionProducts}
                  popupClose={closebidPopup}
                  handleBtnValue={bulkBidHandler}
                  auctionUserId={props.match.params.auctionUserId}
                  length={length}
                  productIndexs={indexsForBulkBidItems}
                  auctionDate={date[0]}
                  myLatestBidForProducts={myLatestBidForProduct}
                />
              </StyledModal>
            )}
            {openReadOnlyUserToast === true && (
              <AlertReadOnlyUser
                isReadOnlyUser={isReadOnlyUser}
                isSeller={isAuctionUserId}
              />
            )}
          </StyledLayoutGrid>
        </StyledLayoutGridMain>
      )}
    </Grid>
  );
};

export default DiamondWatchlistBiddingCompnent;
