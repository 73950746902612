import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import {
  StyledAttachmentContainer,
  StyledAttachmentFieldSet,
  StyledAttachmentImg,
  StyledAttachmentLegend,
  StyledButton,
  StyledChooseFileCon,
  StyledCloseButton,
  StyledCloseicon,
  StyledComponent,
  StyledConHeader,
  StyledContainerWrapper,
  StyledInquiryWrapper,
  StyledMessage,
  StyledSubmitButton,
  StyledText,
  StyledTextWrapper,
  StyledValidationMessages,
  StyledWrapper,
} from "./InquiryDetailsCss";
import { TextareaAutosize } from "@material-ui/core";
import { enquiryPostdata } from "../../../../common/config/app.endpoints";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import AuthService from "../../../auth/AuthService";
import ConfirmationPopup from "./ConfirmationPopup";
import { StyledModal } from "../../../../common/materials/GenericStyleComponent";
import { StyledApp } from "./GenInquiry";
import { useDropzone } from "react-dropzone";
import Alert from "../../../../common/elements/Alert";

interface IProps {
  t: any;
  data?: any;
  auctionId: string;
  closeModal: () => void;
}
let acceptedFileItems: any;
const ProductDetailsInquiry = (props: any) => {
  const { t, auctionId, closeModal, data } = props;

  useEffect(() => {
    setOpenToast(false);
    setFileNames("");
  }, []);

  const Auth = new AuthService();
  const [description, setDescription] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const [message, setMessage] = useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = useState(false);
  const [fileName, setFileNames] = useState("");
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSize: 5,
  });

  const handleChange = async (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;

    if (targeName === "description") {
      setDescription(targeValue);
    }

    if (targeName === "companyLogo") {
      if (e.target.files[0] !== undefined) {
        let fileObj = e.target;
        let isMediaTypeImage: boolean = await getFileExt(fileObj);

        if (isMediaTypeImage) {
          if (getFileSize(fileObj.files[0])) {
            setCompanyLogo(fileObj.files);
            setSubmitButtonDisable(false);
            acceptedFileItems = fileObj.files;
            for (let i = 0; i < acceptedFileItems.length; i++) {
              let file = acceptedFileItems.item(i);
              let filesName = file.name;
              let fileLength = acceptedFileItems.length - 1;
              setFileNames(
                `${filesName}  ${fileLength === 0 ? "" : `+ ${fileLength}`}`
              );
            }
          } else {
            setCompanyLogo("");
            setAlertType("error");
            setShowMessage(`File size should be less than 5 MB.`);
            setOpenToast(true);
          }
        } else {
          setCompanyLogo("");
          setAlertType("error");
          setShowMessage(`File should be image.`);
          setOpenToast(true);
        }
        setTimeout(() => {
          setOpenToast(false);
        }, 3000);
      }
    }
  };

  const getFileSize = (file: any) => {
    const fsize = file.size;
    const fileSize = Math.round(fsize / 1024);
    if (fileSize > 5000) {
      return false;
    } else {
      return true;
    }
  };

  const getFileExt = (imageData: any) => {
    const fileInput = imageData;
    const filePath = fileInput.value;

    const allowedExtensions: any = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (!allowedExtensions.test(filePath)) {
      return false;
    } else {
      return true;
    }
  };

  const formSubmit = async (e: any) => {
    e.preventDefault();
    var formData = new FormData();
    const { auctionName, perId, serialNo, code } = data;
    if (description.trim() === "") {
      setMessage("Please enter any Query.");
    } else {
      let apiform = {
        description: description,
        section: "Preview",
        auctionId: auctionId,
        auctionName: auctionName,
        productId: perId,
        productSerialNo: serialNo,
        productCode: code,
      };

      var test = JSON.stringify(apiform);
      formData.append("enquiry", test);
      if (companyLogo !== "") {
        for (let i = 0; i < companyLogo.length; i++) {
          if (companyLogo.length <= 5) {
            formData.append("fileToUpload", companyLogo[i]);
          } else {
            setAlertType("error");
            setShowMessage("Attachment should be less than 5 MB");
            setOpenToast(true);
            setTimeout(() => {
              setOpenToast(false);
            }, 2000);
          }
        }
      } else {
        formData.append("fileToUpload", companyLogo);
      }

      if (description.trim() !== "" && companyLogo.length <= 5) {
        callAPI(formData);
      } else {
        //
      }
    }
  };

  const callAPI = (formData: any) => {
    setSubmitButtonDisable(true);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.inquiryPostRequest(
        `${enquiryPostdata}`,
        formData,
        (status: number, response: any) => {
          if (status === 200) {
            // props.saveQuery();
            setConfirmationPopUp(true);
            setFileNames("");
            acceptedFileItems = "";
          } else if (status > 200 && status < 500) {
            setAlertType("error");
            setOpenToast(true);
            setShowMessage("invalidRequest");
            acceptedFileItems = "";
            setFileNames("");
          }
        }
      );
    }
  };

  const handleClosePopup = () => {
    closeModal(false);
  };

  return (
    <StyledComponent>
      <form noValidate={true} name="" onSubmit={formSubmit}>
        <StyledContainerWrapper>
          <StyledWrapper container spacing={0}>
            <StyledMessage container spacing={0}>
              <Grid item xs={11}>
                {t("common:inquiry:submitQueriy")}
              </Grid>
              <Grid>
                <StyledCloseButton onClick={closeModal}>
                  <StyledCloseicon src="/images/close-button2.ico"></StyledCloseicon>
                </StyledCloseButton>
              </Grid>
            </StyledMessage>
            <StyledText item xs={12}>
              {t("common:inquiry:submitDescription")}
            </StyledText>

            <Grid item={true} xs={12} style={{ marginBottom: "10px" }}>
              <StyledValidationMessages>{message}</StyledValidationMessages>
            </Grid>
            <StyledInquiryWrapper item md={12} xs={12} lg={6}>
              <StyledTextWrapper>
                <TextareaAutosize
                  id="description"
                  aria-label="miximum width"
                  required
                  maxLength={500}
                  rowsMin={3}
                  placeholder={t("common:inquiry:placeYourQuery")}
                  style={{ width: "90%", height: "160px" }}
                  name="description"
                  onChange={handleChange}
                />
              </StyledTextWrapper>
            </StyledInquiryWrapper>
            <StyledInquiryWrapper item md={12} xs={12} lg={6}>
              <StyledAttachmentFieldSet>
                <StyledAttachmentLegend>
                  {t("common:inquiry:fields:Attachment")}
                </StyledAttachmentLegend>
                <StyledAttachmentContainer
                  container={true}
                  {...getRootProps({ className: "dropzone" })}
                >
                  <StyledChooseFileCon
                    item={true}
                    xs={12}
                    md={12}
                    sm={12}
                    lg={4}
                    xl={4}
                  >
                    <span>--{t("common:inquiry:fields:ChooseFile")}--</span>
                  </StyledChooseFileCon>
                  <StyledAttachmentImg
                    item={true}
                    xs={12}
                    md={12}
                    sm={12}
                    lg={8}
                    xl={8}
                  >
                    <input
                      {...getInputProps()}
                      accept="image/png, image/gif, image/jpeg, image/jpg"
                      className=""
                      id="com_logo"
                      type="file"
                      required
                      name="companyLogo"
                      onChange={handleChange}
                    />
                    <StyledApp src="/images/attachment.jpeg" />
                  </StyledAttachmentImg>
                </StyledAttachmentContainer>
              </StyledAttachmentFieldSet>
              {fileName}
              <StyledSubmitButton>
                {submitButtonDisable === true ? (
                  <StyledButton disabled style={{ background: "lightgray" }}>
                    {t("common:inquiry:button:submit")}
                  </StyledButton>
                ) : (
                  <StyledButton onSubmit={formSubmit}>
                    {t("common:inquiry:button:submit")}
                  </StyledButton>
                )}
              </StyledSubmitButton>
            </StyledInquiryWrapper>
          </StyledWrapper>
        </StyledContainerWrapper>
      </form>
      {confirmationPopUp === true && (
        <StyledModal open={confirmationPopUp} onClose={handleClosePopup}>
          <ConfirmationPopup t={t} {...props} closeModal={handleClosePopup} />
        </StyledModal>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
    </StyledComponent>
  );
};

export default ProductDetailsInquiry;
