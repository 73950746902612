import React, { useEffect, useState } from "react";
import styled from "styled-components";

const StyledPageSizeSelectorContainer = styled.div`
  border: none;
  select {
    padding: 0.4em 0.4em;
    margin: 0em;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    color: ${(props) => props.theme.colors.base.black};
    width: 100px;

    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.2em 0.3em;
      margin: 0.2em;
    }
    background: url("/images/assets/doubledown.png") no-repeat;
    background-position: calc(100% - 0rem) center !important;
    appearance: none !important;
    background-size: 30px 20px;
    cursor: pointer;
  }
`;

export interface Iprops {
  t: any;
  setItemsPerPageHandler: (pageSize: number) => void;
}

const MybidsAnnualActionPageSize = (props: Iprops) => {
  const { setItemsPerPageHandler } = props;
  const pageCount = [20, 50, 100, 200];
  const items = ``;
  const pageSizeHandler = (e: any) => {
    const pageSize = parseInt(e.target.value);
    setItemsPerPageHandler(pageSize);
  };

  let [selection, setSelection] = useState<number>(20);

  useEffect(() => {
    if (localStorage.getItem("annualitemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("annualitemsPerPage");
      setSelection(parseInt(savedCount));
    } else {
      localStorage.setItem("annualitemsPerPage", "20");
    }
  }, []);
  return (
    <StyledPageSizeSelectorContainer>
      <select onChange={pageSizeHandler}>
        {pageCount.map((count, index) => (
          <option
            key={index}
            selected={count === selection ? true : false}
            value={count}
          >{`${count} ${items}`}</option>
        ))}
      </select>
    </StyledPageSizeSelectorContainer>
  );
};

export default MybidsAnnualActionPageSize;
