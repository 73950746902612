import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api, {
  publicApi,
  postRequestForExcel,
} from "../../../services/ApiService";

import {
  ProductCategorySmalls,
  ProductCategory,
} from "../../../enums/productEnum";
import {
  productTextSearch,
  auctionProductUrl,
  productUrl,
  jewelleryProductDetailsUrl,
  getProductDetailUrl,
  productDownload,
  auctionProductMyBidsUrl,
  jewelleryProductViewUrl,
} from "../../../common/config/app.endpoints";

import {
  IProductSearchRequest,
  IAuctionProductRequest,
  IProductWatchRequest,
  IProductDetailsRequest,
  IProductDetails,
  IProductInitialState,
  IProductPreBidPriceRequest,
  IProductCancelPreBidPriceRequest,
  IProductAddNoteRequest,
  IProductExportExcelRequest,
  IProductMyBidsRequest,
} from "./productSliceInterface";

const productDetails = {
  perId: "",
  auctionId: "",
  auctionName: "",
  auctionType:"",
  serialNo: 0,
  code: "",
  carat: 0,
  color: "",
  colorValue: "",
  clarity: "",
  cutGrade: "",
  t3EX: "",
  flour: "",
  length: 0,
  width: 0,
  height: 0,
  polish: "",
  polishAbbreviation: "",
  symmetry: "",
  symmetryAbbreviation: "",
  shape: "",
  shapeAbbreviation: "",
  origin: "",
  lab: "",
  certificate: "",
  fancyIntensity: "",
  fancyIntensityAbbreviation: "",
  caratMinimumPrice: 0,
  startingPrice: 0,
  sellingPriceUsd: 0,
  rapnetPercentage: 0,
  rapnetPrice: 0,
  remarks: "",
  active: false,
  preBidPrice: 0,
  note: "",
  medias: [],
  advertise: false,
  state: "",
  auctionState: "",
  auctionStartDate: "",
  auctionPreviewStartDate: "",
  sellerAuctionUserId: "",
  parcelType: "",
  noOfPieces: 0,
  averageSize: 0,
  peicesPerCarat: 0,
  currency: {
    id: "",
    name: "",
    symbol: "",
    code: "",
  },
  exchangeRates: [],
  hnc: "",
  subItems: [],
};

const initialState: IProductInitialState = {
  loading: false,
  error: "",
  preview: {
    listName: "auctionProductsList",
  },
  productSearchList: [],
  auctionProductsList: {
    loading: false,
    list: [],
    totalElements: 0,
  },
  productDetails: productDetails,
  myBidsList: {
    loading: false,
    list: [],
    totalElements: 0,
  },
};

export const productSearch = createAsyncThunk<
  IProductSearchRequest,
  Partial<IProductSearchRequest>,
  { rejectValue: any }
>("product/productSearch", async (requestObj, { rejectWithValue }) => {
  try {
    const response = await api.post(productTextSearch, requestObj);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getAuctionProducts = createAsyncThunk<
  IAuctionProductRequest,
  Partial<IAuctionProductRequest>,
  { rejectValue: any }
>("product/getAuctionProducts", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, query, category } = requestObj;
    let url = "";
    if (category === ProductCategory.DIAMONDS) {
      url = `${auctionProductUrl}?auctionId=${auctionId}`;
    } else if (category === ProductCategory.JEWELLERY) {
      url = `${jewelleryProductViewUrl}?auctionId=${auctionId}`;
    }
    const response = await api.post(url, query);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const watchProduct = createAsyncThunk<
  IProductWatchRequest,
  Partial<IProductWatchRequest>,
  { rejectValue: any }
>("product/watchProduct", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, productId } = requestObj;
    const url = `${productUrl}/${auctionId}/${productId}/watch`;
    const response = await api.post(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const unWatchProduct = createAsyncThunk<
  IProductWatchRequest,
  Partial<IProductWatchRequest>,
  { rejectValue: any }
>("product/unWatchProduct", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, productId } = requestObj;
    const url = `${productUrl}/${auctionId}/${productId}/unwatch`;
    const response = await api.post(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getProductDetails = createAsyncThunk<
  IProductDetailsRequest,
  Partial<IProductDetailsRequest>,
  { rejectValue: any }
>("product/getProductdetails", async (requestObj, { rejectWithValue }) => {
  try {
    const { productId, category, isPublicApi } = requestObj;
    const isEndpointPublic = isPublicApi ? isPublicApi : false;
    let url = "";
    let response: any = {
      data: {},
    };
    if (category === ProductCategorySmalls.DIAMONDS) { // For both diamond and parcel item
      url = `${productUrl}/${productId}/detail`;
      if (isEndpointPublic) {
        url = `${getProductDetailUrl}/${productId}/detail`;
      }
    } else if (category === ProductCategorySmalls.JEWELLERY) { // For jewellery 
      url = `${jewelleryProductDetailsUrl}/${productId}/detail`;
      if (isEndpointPublic) {
        url = `${getProductDetailUrl}/jewellery/${productId}/detail`;
      }
    }

    if (isEndpointPublic) {
      response = await publicApi.get(url);
    } else {
      response = await api.get(url);
    }
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const setPreBidPrice = createAsyncThunk<
  IProductPreBidPriceRequest,
  Partial<IProductPreBidPriceRequest>,
  { rejectValue: any }
>("product/setPreBidPrice", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, productId, bidPrice } = requestObj;
    const url = `${productUrl}/${auctionId}/${productId}/setPreBidPrice?amt=${bidPrice}`;
    const response = await api.post(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const cancelPreBidPrice = createAsyncThunk<
  IProductCancelPreBidPriceRequest,
  Partial<IProductCancelPreBidPriceRequest>,
  { rejectValue: any }
>("product/cancelPreBidPrice", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, productId } = requestObj;
    const url = `${productUrl}/${auctionId}/${productId}/cancelPreBidPrice`;
    const response = await api.post(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const addNote = createAsyncThunk<
  IProductAddNoteRequest,
  Partial<IProductAddNoteRequest>,
  { rejectValue: any }
>("product/addNote", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, productId, data } = requestObj;
    const url = `${productUrl}/${auctionId}/${productId}/addNote`;
    const response = await api.post(url, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const exportExcel = createAsyncThunk<
  IProductExportExcelRequest,
  Partial<IProductExportExcelRequest>,
  { rejectValue: any }
>("product/exportExcel", async (requestObj, { rejectWithValue }) => {
  try {
    const response = await postRequestForExcel(productDownload, requestObj);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getMyBids = createAsyncThunk<
  IProductMyBidsRequest,
  Partial<IProductMyBidsRequest>,
  { rejectValue: any }
>("product/getMyBids", async (requestObj, { rejectWithValue }) => {
  try {
    const { auctionId, direction } = requestObj;
    const url = `${auctionProductMyBidsUrl}?auctionId=${auctionId}&order=${direction}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (state: any, action: any) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProductPreviewListName(
      state,
      action: PayloadAction<{ listName: string }>
    ) {
      state.preview.listName = action.payload.listName;
    },
    resetSearchList(state, action: PayloadAction) {
      state.productSearchList = [];
    },
    resetAuctionProductsList(state, action: PayloadAction) {
      state.auctionProductsList = {
        loading: false,
        list: [],
        totalElements: 0,
      };
    },
  },
  extraReducers: (builder: any) => {
    // productSearch
    builder.addCase(productSearch.pending, genericPendingCaseHandler);
    builder.addCase(productSearch.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.productSearchList = action.payload.results;
    });
    builder.addCase(productSearch.rejected, genericRejectedCaseHandler);
    // getAuctionProducts
    builder.addCase(
      getAuctionProducts.pending,
      (state: any, action: PayloadAction) => {
        state.loading = true;
        state.error = "";
        state.auctionProductsList = {
          loading: true,
        };
      }
    );
    builder.addCase(getAuctionProducts.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.auctionProductsList = {
        loading: false,
        list: action.payload.results,
        totalElements: action.payload.totalElements,
      };
    });
    builder.addCase(getAuctionProducts.rejected, (state: any, action: any) => {
      state.error = action.payload || "Error";
      state.loading = false;
      state.auctionProductsList = {
        loading: false,
      };
    });
    // watchProduct
    builder.addCase(watchProduct.pending, genericPendingCaseHandler);
    builder.addCase(watchProduct.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(watchProduct.rejected, genericRejectedCaseHandler);
    // unWatchProduct
    builder.addCase(unWatchProduct.pending, genericPendingCaseHandler);
    builder.addCase(unWatchProduct.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(unWatchProduct.rejected, genericRejectedCaseHandler);
    // getProductDetails
    builder.addCase(getProductDetails.pending, genericPendingCaseHandler);
    builder.addCase(
      getProductDetails.fulfilled,
      (state: any, action: PayloadAction<IProductDetails>) => {
        state.loading = false;
        state.productDetails = action.payload;
      }
    );
    builder.addCase(getProductDetails.rejected, genericRejectedCaseHandler);
    // setPreBidPrice
    builder.addCase(setPreBidPrice.pending, genericPendingCaseHandler);
    builder.addCase(setPreBidPrice.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(setPreBidPrice.rejected, genericRejectedCaseHandler);
    // cancelPreBidPrice
    builder.addCase(cancelPreBidPrice.pending, genericPendingCaseHandler);
    builder.addCase(cancelPreBidPrice.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(cancelPreBidPrice.rejected, genericRejectedCaseHandler);
    // addNote
    builder.addCase(addNote.pending, genericPendingCaseHandler);
    builder.addCase(addNote.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(addNote.rejected, genericRejectedCaseHandler);
    // exportExcel
    builder.addCase(exportExcel.pending, genericPendingCaseHandler);
    builder.addCase(exportExcel.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(exportExcel.rejected, genericRejectedCaseHandler);
    // getMyBids
    builder.addCase(getMyBids.pending, (state: any, action: any) => {
      state.loading = true;
      state.error = "";
      state.myBidsList = {
        loading: true,
      };
    });
    builder.addCase(getMyBids.fulfilled, (state: any, action: any) => {
      state.loading = false;
      state.myBidsList = {
        loading: false,
        list: action.payload.results,
        totalElements: action.payload.totalElements,
      };
    });
    builder.addCase(getMyBids.rejected, (state: any, action: any) => {
      state.error = action.payload || "Error";
      state.loading = false;
      state.myBidsList = {
        loading: false,
      };
    });
  },
});

export const {
  setProductPreviewListName,
  resetSearchList,
  resetAuctionProductsList,
} = productSlice.actions;

export default productSlice.reducer;

// selectors
export const selectProductLoading = (state: RootState) => state.product.loading;
export const selectProductSearchList = (state: RootState) =>
  state.product.productSearchList;
export const selectProductsLists = (state: RootState) => {
  const lists: any = {
    auctionProductsList: state.product.auctionProductsList,
  };
  return lists[state.product.preview.listName];
};
export const selectProductDetails = (state: RootState) =>
  state.product.productDetails;
export const selectMyBidsList = (state: RootState) => state.product.myBidsList;
