import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import AppLayout from "../../../layouts/AppLayout";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import { useHistory, useParams } from "react-router-dom";
import AuthService from "../../../auth/AuthService";
import { annualSellerSoldProduct, annualSoldProduct } from "../../../../common/config/app.endpoints";
import LoadingSpinner from "../../../../common/elements/LoadingSpinner";
import { convertProductPrice } from "../../../pendingProductsTab/TableProps";
import ProductDescription from "../../../shared/ProductDescription";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import CartSection from "./CartSection";

const StyledMuiDataTableLayoutGrid = styled(Grid)`
  padding: 20px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
  }
  .MuiTableCell-root {
    padding: 16px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .MuiTableHead-root {
    position: sticky;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }

  .MuiTableCell-footer {
    padding: 2px;
    font-size: 0.2em;
    height: 40px;
  }

  .MuiTablePagination-actions {
    margin-left: 0px;
  }
`;
const StyledGrid = styled(Grid)`
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding-top: 10px;
  }
`;

interface IPendingProductProps extends IRouterComponentProps {
  t: any;
}

let auctionId: any;

const ProductCartSection = (props: IPendingProductProps) => {
  const { t } = useTranslation();
  let history = useHistory();
  const Auth = new AuthService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSellerCartActive, setIsSellerCartActive] = useState<boolean>(false);
  //@ts-ignore
  const [cartProducts, setCartProducts] = useState<any[]>([]);

  //@ts-ignore
  const { auctionId, categoryType } = useParams();

  useEffect(() => {
    setIsLoading(true);
    tabItemClicks("1");
  }, []);

  const tabItemClicks = (value: String) => {
    setCartProducts(() => []);
    if (value === "2") {
      setIsSellerCartActive(true);
      setCartProducts([]);
      getSellerSoldProductApi();
      localStorage.setItem("tabcartSelectedVal", "2");
    } else {
      setIsSellerCartActive(false);
      setCartProducts([]);
      getSoldProductApi();
      localStorage.setItem("tabcartSelectedVal", "1");
    }
  };

  const getSellerSoldProductApi = () => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${annualSellerSoldProduct}?auctionId=${auctionId}`,
        "",
        (status: any, response: any) => {
          if (status === 200) {
            setCartProducts(response);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const getSoldProductApi = () => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${annualSoldProduct}?auctionId=${auctionId}`,
        "",
        (status: any, response: any) => {
          if (status === 200) {
            setCartProducts(response);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const tableOptions: MUIDataTableOptions = {
    filter: "false",
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    serverSide: false,
    pagination: true,
    search: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      body: {
        noMatch: `${t("common:newCommanTrans:sorrynomatchingrecordsfound")}`,
      },
    },
  };
  const getCodeName = () => {
    if (categoryType === "Jewellery") {
      return `${t("common:newCommanTrans:SKU")}`;
    } else {
      return `${t("common:newCommanTrans:Itemcode")}`;
    }
  };

  const pendingProductTableColumns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: `${t("common:newCommanTrans:productSerialNumber")}`,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "code",
      label: getCodeName(),
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "perId",
      label: "perId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productEndDate",
      label: `${t("common:newCommanTrans:Date")}`,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "description",
      label: `${t("common:newCommanTrans:productdescription")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              <ProductDescription
                truncateLength={60}
                data={tableMeta.tableData[tableMeta.rowIndex]}
                isTendor={false}
              />
            </div>
          );
        },
      },
    },
    {
      name: "state",
      label: `${t("common:newCommanTrans:state")}`,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:newCommanTrans:startingprice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "caratSellingPrice",
      label: `${t("common:newCommanTrans:sellingprice")}`,
      options: {
        filter: false,
        sort: true,
        display: isSellerCartActive ? true : false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:newCommanTrans:finalbidprice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "totalAmount",
      label: `${t("common:newCommanTrans:totalAmount")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rapnetPrice",
      label: `${t("common:newCommanTrans:rapPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {value !== 0 ? new Intl.NumberFormat().format(value) : '-'}
            </div>
          );
        },
      },
    },
  ];

  return (
    <AppLayout {...props}>
      {isLoading ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <StyledGrid style={{ marginTop: "10px" }}>
            <Grid item={true} xs={12}>
              <CartSection {...props} t={t} tabClick={tabItemClicks} />
            </Grid>
          </StyledGrid>
          {cartProducts?.length > 0 ? (
            <>
              {cartProducts !== undefined || cartProducts !== null ? (
                <StyledMuiDataTableLayoutGrid>
                  <MUIDataTable
                    title={"  "}
                    data={cartProducts}
                    columns={pendingProductTableColumns}
                    options={tableOptions}
                  />
                </StyledMuiDataTableLayoutGrid>
              ) : (
                <StyledMuiDataTableLayoutGrid>
                  <MUIDataTable
                    title={"  "}
                    data={cartProducts}
                    columns={pendingProductTableColumns}
                    options={tableOptions}
                  />
                </StyledMuiDataTableLayoutGrid>
              )}
            </>
          ) : (
            <StyledMuiDataTableLayoutGrid>
              <MUIDataTable
                title={"  "}
                data={cartProducts}
                columns={pendingProductTableColumns}
                options={tableOptions}
              />
            </StyledMuiDataTableLayoutGrid>
          )}
        </>
      )}
    </AppLayout>
  );
};

export default ProductCartSection;
