import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
interface IFilters {
  and: any[];
  or?: any[];
}
export interface IPage {
  start: number;
  count: number;
}
interface ISorting {
  orders: any[];
}
interface IPersist {
  fromPage: string;
  category: string;
  filterType: string;
  filtersToApply: any;
}
interface IBasicSearch {
  searchKeyword: string;
}
interface IDiamondFilter {
  appliedFilters: any;
}
interface IParcelItemFilter {
  appliedFilters: any;
}
interface IJewelleryFilter {
  appliedFilters: any;
}
interface IFiltersData {
  basicSearch: IBasicSearch;
  diamondFilter: any;
  parcelItemFilter: any;
  jewelleryFilter: any;
  applySelectedFilters: boolean;
  appliedFilters: any;
  persist: IPersist;
  filters: IFilters;
  page: IPage;
  sort: ISorting;
}

interface IFiltersState {
  preview: IFiltersData;
}

const initialState: IFiltersState = {
  preview: {
    basicSearch: {
      searchKeyword: "",
    },
    diamondFilter: {},
    parcelItemFilter: {},
    jewelleryFilter: {},
    applySelectedFilters: false,
    appliedFilters: {},
    persist: {
      fromPage: "preview",
      category: "diamond",
      filterType: "diamond",
      filtersToApply: {},
    },
    filters: {
      and: [],
      or: [],
    },
    page: {
      start: 0,
      count: 20,
    },
    sort: {
      orders: [],
    },
  },
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setPreviewApplySelectedFilters(
      state,
      action: PayloadAction<{ applySelectedFilters: boolean }>
    ) {
      state.preview.applySelectedFilters = action.payload.applySelectedFilters;
    },
    setPreviewPersist(state, action: PayloadAction<{ persist: IPersist }>) {
      state.preview.persist = action.payload.persist;
    },
    setPreviewAppliedFilters(state, action: PayloadAction<{}>) {
      state.preview.appliedFilters = action.payload;
    },
    setPreviewFilters(state, action: PayloadAction<{ filters: IFilters }>) {
      state.preview.filters = action.payload.filters;
    },
    setPreviewPageCount(state, action: PayloadAction<{ page: IPage }>) {
      state.preview.page = action.payload.page;
    },
    setPreviewSorting(state, action: PayloadAction<{ orders: any[] }>) {
      state.preview.sort = action.payload;
    },
    resetPreviewFilters(state, action: PayloadAction) {
      state.preview.filters = {
        and: [],
        or: [],
      };
      state.preview.appliedFilters = {};
    },
    resetPreviewApplySelectedFilters(state, action: PayloadAction) {
      state.preview.applySelectedFilters = false;
    },
    resetPreviewPageCount(state, action: PayloadAction) {
      state.preview.page = {
        start: 0,
        count: 20,
      };
    },
  },
});

export const {
  setPreviewApplySelectedFilters,
  setPreviewPersist,
  setPreviewAppliedFilters,
  setPreviewFilters,
  setPreviewPageCount,
  setPreviewSorting,
  resetPreviewFilters,
  resetPreviewApplySelectedFilters,
  resetPreviewPageCount,
} = filtersSlice.actions;

export default filtersSlice.reducer;

const getFilterQueryObject = (reqObj: any) => {
  const { filters, page, sort } = reqObj;
  return { filters, page: { ...page, sort } };
};

// selectors

// Preview Selectors
export const selectPreviewApplySelectedFilters = (state: RootState) =>
  state.filters.preview.applySelectedFilters;
export const selectPreviewPersist = (state: RootState) =>
  state.filters.preview.persist;
export const selectPreviewAppliedFilters = (state: RootState) =>
  state.filters.preview.appliedFilters;
export const selectPreviewFilters = (state: RootState) =>
  state.filters.preview.filters;
export const selectPreviewFilterQuery = (state: RootState) =>
  getFilterQueryObject(state.filters.preview);

export const selectPreviewDiamondFilters = (state: RootState) =>
  state.filters.preview.diamondFilter;
export const selectPreviewParcelItemFilters = (state: RootState) =>
  state.filters.preview.parcelItemFilter;
export const selectPreviewJewelleryFilters = (state: RootState) =>
  state.filters.preview.jewelleryFilter;
