import styled from "styled-components";

const StyledMemberBoxContainer = styled.div`
    width: 25%;
    height: 300px;
    background-color:rgb(245, 242, 234);
    box-shadow: 4px 4px 4px red;
    border-radius: 20px;
`;

const StyledCircularImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;



const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ContainerProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: auto;
  width: 100%;
`;

const ProfileField = styled.div`
  width: 100%;
  max-width: 450px;
  height: 450px;
  box-shadow: 3px 5px 2px 1px #D92B3C;
  border-radius: 10px;
  opacity: 1;
  ${(props) => props.theme.breakpoints.only("lg")} {
    height: 370px;
    width: 370px;
  }
  ${(props) => props.theme.breakpoints.only("xs", "sm")} {
    height: 288px;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    height: 288px;
    width: 288px;
  }

  @media (min-width: 1280px) and (max-width: 1368px) {
    height: 260px;
    width: 260px;
  }
`;


const ProfileDescription = styled.span`  
  font-size: 1.6em;
  text-align: center; 
  width: 80%;

  ${(props) => props.theme.breakpoints.only("lg")} {
    font-size: 1.2em;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    font-size: 1.6em;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1em;
  }
`;
export {
  StyledCircularImg,
  ContainerProfile,
  BoxContainer,
  StyledMemberBoxContainer,
  ProfileField,
  ProfileDescription
}