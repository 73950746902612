import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { sideMenuGradient } from "../../common/materials/LinearGradient";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import Hidden from "@material-ui/core/Hidden";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";

interface ButtonProps {
  selected?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  padding: 5px 5px;
  font-size: 1em;
  border-radius: 5px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  margin-right: 5px;
  background: ${sideMenuGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  color: ${(props) => props.theme.colors.base.white};
  scroll-behavior: smooth;
`;

const StyledHeaderContainer = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  position: fixed;
  top: 60px;
  width: 100%;
  z-index: 100;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
`;

const StyledTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-size: 1.5em;
  font-family: SF-Display-Medium;
  @media (width: 320px) {
    font-size: 1.2em;
  }
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StyledResetPaddingContainer = styled(Grid)`
  background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 15%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 10%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 10%;
    }
  }
  @media (width: 768px) {
    img {
      width: 8%;
    }
  }
  display: flex;
`;

const StyledLogoImage = styled.img`
  height: 2em;
  width: 1em;
`;

export interface IProps extends IRouterComponentProps {}

const SubHeader = (props: IProps) => {
  const { t } = props;
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const aboutUsHelper = () => {
    props.history.push("/AboutUs");
  };

  return (
    <StyledHeaderContainer className="headerContainer">
      <Hidden mdUp implementation="css">
        <div>
          <Navbar color="white" light>
            <StyledWrapper container={true} spacing={0}>
              <StyledResetPaddingContainer item xs={10} sm={10} lg={10}>
                <StyledLogoWrapper>
                  <StyledLogoImage src="/favicon.ico" />
                  <StyledTextContainer>
                    {t("common:header:logoText")}
                  </StyledTextContainer>
                </StyledLogoWrapper>
              </StyledResetPaddingContainer>
              <Grid
                item
                xs={2}
                sm={2}
                lg={2}
                style={{ display: "flex", justifyContent: "end" }}
              >
                <NavbarToggler
                  onClick={toggle}
                  style={{ borderRadius: "10px", borderColor: "#4a95f0" }}
                />
              </Grid>
            </StyledWrapper>
            <Collapse isOpen={!isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink
                    href="#whyMyAuction"
                    onClick={toggle}
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {t("common:subHeader:buttons:whyUs")}
                  </NavLink>
                  <NavLink
                    href="#AuctionSections"
                    onClick={toggle}
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {t("common:subHeader:buttons:howItWorks")}
                  </NavLink>
                  <NavLink
                    href="#mediaSection"
                    onClick={toggle}
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {t("common:subHeader:buttons:media")}
                  </NavLink>
                  <NavLink
                    onClick={aboutUsHelper}
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {t("common:subHeader:buttons:aboutUs")}
                  </NavLink>
                  <NavLink
                    href="#contactUs"
                    onClick={toggle}
                    style={{ color: "black", fontWeight: "bold" }}
                  >
                    {t("common:subHeader:buttons:contactUs")}
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </Hidden>
      <Hidden smDown implementation="css">
        <StyledWrapper container={true} spacing={0}>
          <Grid item xs={12} className="buttonsContainer">
            <StyledButtonWrapper>
              <a href="#whyMyAuction">
                <StyledButton>
                  {t("common:subHeader:buttons:whyUs")}
                </StyledButton>
              </a>
              <a href="#AuctionSections">
                <StyledButton>
                  {t("common:subHeader:buttons:howItWorks")}
                </StyledButton>
              </a>
              <a href="#mediaSection">
                <StyledButton>
                  {t("common:subHeader:buttons:media")}
                </StyledButton>
              </a>
              <StyledButton onClick={aboutUsHelper}>
                {t("common:subHeader:buttons:aboutUs")}
              </StyledButton>
              <a href="#contactUs">
                <StyledButton>
                  {t("common:subHeader:buttons:contactUs")}
                </StyledButton>
              </a>
            </StyledButtonWrapper>
          </Grid>
        </StyledWrapper>
      </Hidden>
    </StyledHeaderContainer>
  );
};

export default SubHeader;
