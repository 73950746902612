import * as React from "react";
import Box from "@mui/material/Box";
import styled from "styled-components";

const StyledBoxContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  ${(props) => props.theme.breakpoints.only("xs")} {
    width: 100%;
    flex-direction: column;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    gap: 35px;
    justify-content: center;
  }
`;

const StyledCircularImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export default function FlexDivider({ isSeller }: any) {
  return (
    <>
      {isSeller ? (
        <StyledBoxContainer>
          <StyledCircularImg src="/images/team_member/priceSection/seller.png"></StyledCircularImg>         
        </StyledBoxContainer>
      ) : (
        <StyledBoxContainer>
          <StyledCircularImg src="/images/team_member/priceSection/buyer.png"></StyledCircularImg>          
        </StyledBoxContainer>
      )}
    </>
  );
}
