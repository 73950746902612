import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

import IRouterComponentProps from '../../../models/IRouterComponentProps';

export const StyledComponent = styled(Grid)`
    outline: none;
    .text {
        display: flex;
        text-align: center;
        align-items: center;
        font-size: 2em;
        margin-bottom: 10px;
        font-family: Calibre-Regular;
        color: ${(props) => props.theme.colors.base.grey};
    }
`;

export const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 400px;
    text-align:center;
    padding: 1.5em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        // width: 600px;
    }
`;

export const StyledTextWrapper = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 1.5em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
  overflow-wrap: anywhere;
`;

export const StyledAuctionEndedWrapper = styled(Grid)`
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;
export const StyledCloseicon = styled.img`
  height: 1em;
  width: 1em;
  &:hover {
    cursor: pointer;
  }
`;
export const StyledScrollUp = styled.div`
  margin-left: auto;
  float: right;
`;
export const StyledLegends = styled.legend`
  margin-left: 20px;
  padding: 0.3em;
`;
export interface IProps extends IRouterComponentProps {
    t: any;
    closeModal: () => void;
    notes: any;
}

const NotesModal = React.forwardRef((props: IProps, ref) => {
    const { closeModal, notes } = props;

    const onClickOkButton = () => {
        closeModal();
    }

    return (
      <StyledComponent tabIndex={-1}>
        <StyledLegends>
          <StyledScrollUp onClick={onClickOkButton}>
            <StyledCloseicon src="/images/close-button2.ico" />
          </StyledScrollUp>
        </StyledLegends>
        <StyledWrapper container spacing={0}>
          <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
            <StyledTextWrapper> {notes}</StyledTextWrapper>
          </StyledAuctionEndedWrapper>
        </StyledWrapper>
      </StyledComponent>
    );
})

export default NotesModal;