import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import { textGradient } from '../../common/materials/LinearGradient';

const AuctionOptions = styled(Grid)``;

const FirstTwoAuctionOption = styled.div`
    width: 100%;
`;

const FirstOption=styled.div `
    display: flex;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
`;

const TextContainer = styled.div `
    cursor: pointer;
    width: 50%;
    border-right: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    padding: 1em;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: space-around;
`;

const StyledCategoryName = styled.div`
    padding: 10px 0;
    font-size: 2em;
    font-family: Calibre-Bold;
    color: ${(props) => props.theme.colors.base.lightBlue};
    font-weight: bold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.8em;
    }
    ${props => props.theme.breakpoints.only('sm')} {
        font-size: 1em;
    }
    ${props => props.theme.breakpoints.only('md')} {
        font-size: 1.5em;
    }
`;

const StyledPointersList = styled.li`
    font-family: Calibre-SemiBold;
    color: ${(props) => props.theme.colors.base.white};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {    
        font-size: 1em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {       
        font-size: 2em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        font-size: 1.5em;
    }
`;

const StyledKeyPointersWrapper = styled.div`
    cursor: pointer; 
    background: ${textGradient}; 
    width: 50%; 
    padding: 1em; 
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
`;

const KeyPointersWrapper = styled.div`
    text-align: center; 
    width: 100%;
`;

const StyledKeyPointerWrapper = styled.div `
    cursor: pointer;
    display: flex; 
    flex-direction: column; 
`;

const WhyusOptionsImageStyle = styled('img')`
    width: 100px;
    height: 100px;
    marginBottom: 20px;
`;

interface IProps {
    t: any;
}

const KeyPointers = (props: IProps) => {
    const { t } = props;
    const [keyPointers, setKeyPointers] = useState(false);
    const [keyPointers2, setKeyPointers2] = useState(false);
    const [keyPointers3, setKeyPointers3] = useState(false);
    const [keyPointers4, setKeyPointers4] = useState(false);
    const onClickOptions = () => {
        setKeyPointers(true);
        setKeyPointers3(false);
        setKeyPointers4(false)
        setKeyPointers2(false)
    }
    const onClickOptions2 = () => {
        setKeyPointers2(true);
        setKeyPointers4(false)
        setKeyPointers3(false)
        setKeyPointers(false)
    }
    const onCloseKeyPointers = () => {
        setKeyPointers(false)
    }
    const onCloseKeyPointers2 = () => {
        setKeyPointers2(false)
    }
    const onClickOptions3 = () => {
        setKeyPointers3(true);
        setKeyPointers2(false)
        setKeyPointers4(false)
        setKeyPointers(false)
    }
    const onCloseKeyPointers3 = () => {
        setKeyPointers3(false)
    }
    const onCloseKeyPointers4 = () => {
        setKeyPointers4(false)
    }
    const onClickOptions4 = () => {
        setKeyPointers4(true);
        setKeyPointers3(false)
        setKeyPointers2(false)
        setKeyPointers(false)
    }
    return (
        <AuctionOptions>
            <FirstTwoAuctionOption>
                <FirstOption>
                    {
                        keyPointers === false ?
                            <TextContainer onClick={onClickOptions}>
                                <KeyPointersWrapper>
                                    <WhyusOptionsImageStyle src="/images/landing/privacy.png" />
                                    <StyledCategoryName>{t('common:landingPage:pointers:firstOption')}</StyledCategoryName>
                                </KeyPointersWrapper>
                            </TextContainer>
                            :
                            <StyledKeyPointersWrapper>
                                <StyledKeyPointerWrapper onClick={onCloseKeyPointers}>
                                    <div>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:firstPointer:firstOption')}</StyledPointersList>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:firstPointer:secondOption')}</StyledPointersList>
                                    </div>
                                </StyledKeyPointerWrapper>
                            </StyledKeyPointersWrapper>
                    }
                    {
                        keyPointers2 === false ?
                            <TextContainer onClick={onClickOptions2}>
                                <KeyPointersWrapper>
                                    <WhyusOptionsImageStyle src="/images/landing/minimal_risk.png" />
                                    <StyledCategoryName>{t('common:landingPage:pointers:secondOption')}</StyledCategoryName>
                                </KeyPointersWrapper>
                            </TextContainer> :
                            <StyledKeyPointersWrapper>
                                <StyledKeyPointerWrapper onClick={onCloseKeyPointers2}>
                                    <div>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:secondPointer:firstOption')}</StyledPointersList>
                                    </div>
                                </StyledKeyPointerWrapper>
                            </StyledKeyPointersWrapper>
                    }
                </FirstOption>
            </FirstTwoAuctionOption>
            <FirstTwoAuctionOption>
                <FirstOption>
                    {
                        keyPointers3 === false ?
                            <TextContainer onClick={onClickOptions3}>
                                <KeyPointersWrapper>
                                    <WhyusOptionsImageStyle src="/images/landing/cheapest.png" />
                                    <StyledCategoryName>{t('common:landingPage:pointers:thirdOption')}</StyledCategoryName>
                                </KeyPointersWrapper>
                            </TextContainer>
                            :
                            <StyledKeyPointersWrapper>
                                <StyledKeyPointerWrapper onClick={onCloseKeyPointers3}>
                                    <div>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:thirdPointer:firstOption')}</StyledPointersList>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:thirdPointer:secondOption')}</StyledPointersList>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:thirdPointer:thirdOption')}</StyledPointersList>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:thirdPointer:fourthOption')}</StyledPointersList>
                                    </div>
                                </StyledKeyPointerWrapper>
                            </StyledKeyPointersWrapper>
                    }
                    {
                        keyPointers4 === false ?
                            <TextContainer onClick={onClickOptions4}>
                                <KeyPointersWrapper>
                                    <WhyusOptionsImageStyle src="/images/landing/access_all_your_auctions.png" />
                                    <StyledCategoryName>{t('common:landingPage:pointers:fourthOption')}</StyledCategoryName>
                                </KeyPointersWrapper>
                            </TextContainer>
                            :
                            <StyledKeyPointersWrapper>
                                <StyledKeyPointerWrapper onClick={onCloseKeyPointers4}>
                                    <div>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:fourthPointer:firstOption')} </StyledPointersList>
                                        <StyledPointersList>{t('common:landingPage:keyPointers:fourthPointer:secondOption')} </StyledPointersList>
                                    </div>
                                </StyledKeyPointerWrapper>
                            </StyledKeyPointersWrapper>
                    }
                </FirstOption>
            </FirstTwoAuctionOption>
        </AuctionOptions>
    )
}

export default KeyPointers;