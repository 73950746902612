import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import ReactPlayer from 'react-player';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const VideoContainer = styled(Grid)`
`;

const VideoContainerDiv = styled(Grid)`
  width: 100%;
  .howItWorksText {
    font-family: Calibre-SemiBold;
    color: #2660ad;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      font-size: 10px;
    }
    ${(props) => props.theme.breakpoints.down("lg")} {
      font-size: 1.1em;
    }
    ${(props) => props.theme.breakpoints.between("md", "xl")} {
      font-size: 1.6em;
    }
  }
`;

const StyledGrid = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`;

const StyledPdfContainer = styled(Grid)`
    width: 100%;
    text-align: center;
    ${props => props.theme.breakpoints.down('xs')} {
        text-align: right;
    }
`;

const StyledPdfText = styled.div`
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    font-size: 12px;
    ${props => props.theme.breakpoints.between('xs', 'lg')} {
        margin: 10px 0px;
    }
    ${props => props.theme.breakpoints.down('xl')} {
        font-size: 16px;
    }
`;

const HowItWorksStyle = styled(Grid)`
    background: ${(props) => props.theme.colors.base.transparent};
    border-bottom: 1px solid #2660AD;
    border-radius: 50px;
    padding: 0em 20px;   
`;

const StyledImages = styled('img')`
    ${props => props.theme.breakpoints.between('xs', 'md')} {
        width: 20px;
        height: 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 30px;
        height: 30px;
    }
`;

const PlayerWrapper = styled.div`
    position: relative;
    margin-top: 30px;
    padding-top: 56.25%; 
    width: 100%;
`;

const reactPlayer = {
    position: "absolute",
    top: 0,
    left: 0
} as React.CSSProperties;

interface IProps {
    t: any;
}
let pdfLocalization: string = '';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        cursor: 'pointer',
        color: theme.palette.common.black
    },
    tooltip: {
        fontSize: '12px',
        backgroundColor: theme.palette.common.black
    },
}));

function PDFTooltip(props: JSX.IntrinsicAttributes & TooltipProps) {
    const classes = useStylesBootstrap();
    return <Tooltip arrow classes={classes} {...props} />;
}

const VideoAtHome = (props: IProps) => {
    const { t } = props;
    let language = localStorage.getItem("language");
    if (language === 'EN') {
        pdfLocalization = 'https://d1xs39ogteakr9.cloudfront.net/zipang_how_it_works_en.pdf';
    }
    else {
        pdfLocalization = "https://d1xs39ogteakr9.cloudfront.net/zipang_how_it_works_en.pdf";
    }

    return (
        <VideoContainerDiv item={true}>
            <StyledGrid container={true} spacing={0}>
                <HowItWorksStyle item xs={11} sm={11} md={11} lg={10} xl={8} className="howItWorksText">{t('common:home:videoHeading')}</HowItWorksStyle>
                <StyledPdfContainer item={true} xs={1} sm={1} md={1} lg={2} xl={4}>
                    <PDFTooltip disableTouchListener title={`${t('common:home:downloadPdf')}`}>
                        <StyledPdfText>
                            <a href={pdfLocalization} target="_blank" rel="noopener noreferrer">
                                <StyledImages src='/images/assets/downloadIcon.png' />
                            </a>
                        </StyledPdfText>
                    </PDFTooltip>
                </StyledPdfContainer>
            </StyledGrid>
            <VideoContainer item xs={12} sm={12} lg={12} md={10} xl={10}>
                <PlayerWrapper>
                    <ReactPlayer
                        url={t('common:videoUrl')}
                        controls={true}
                        style={reactPlayer}
                        width='100%'
                        height='90%'
                    />
                </PlayerWrapper>
            </VideoContainer>
        </VideoContainerDiv>
    )
}

export default VideoAtHome;