import React, { useEffect, useState } from 'react';
import styled from "styled-components";


const StyledScrollUp = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  right: 30px;
  top: 90%;
  height: auto;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 40px;
    height: 40px;
  }
`;


const StyledStickeyImage = styled('img')`
    width: 100%;
    margin: 2px;
    height: auto;
`;


const Flotingicon = () => {
    const [showButton, setShowButton] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    useEffect(() => { 
        setTimeout(() => {
            // localStorage.clear();
            let keysToRemove = ["id_token", "filteredValues", "userName", "clockNotSynced", "userId", "auctionState", "joinedAuctions", "AuctionDetails", "action", "watchlistProductsList", "subDomain", "length", "loglevel"];
            for (var key of keysToRemove) {
                localStorage.removeItem(key);
            }
        }, 100);
        window.addEventListener("scroll", () => {
          if (window.pageYOffset > 10) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        });
    }, []);


    return (
        <>
            <StyledScrollUp>
            {showButton && (
                <StyledStickeyImage onClick={scrollToTop} src="/images/Top-to-Scroll.png" />
            )}
            </StyledScrollUp>            
        </>
    )
};

export default Flotingicon;