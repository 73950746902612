import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { backgroundGradient, dateBackgroundGradient } from "../../../../common/materials/LinearGradient";

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 0.4em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 930px;
  }
  @media (width: 768px) {
    width: 730px;
  }

  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledCloseicon = styled.img`
  height: 1.4em;
  width: 1.4em;
  &:hover {
    cursor: pointer;
  }
`;

const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const BulkBidButtonContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
`;
const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
`;
const StyledButtonWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  background: ${(props) => props.theme.colors.base.white};
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 5px;
    padding: 2px 2px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 2px 2px;
    margin-top: 5px;
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    // padding: 2px 2px;
    font-size: 1em;
  }
`;
const StyledAuctionbidWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  .notDisablebutton {
    background: ${backgroundGradient};
    cursor: pointer;
  }
  .disableButton {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
`;


export {
  StyledContainerWrapper,
  StyledAuctionbidWrapper,
  StyledButtonWrapper,
  ProductCountContainer,
  BulkBidButtonContainer,
  ProductNameContainer,
  StyledCloseicon,
};