import { configureStore, Action } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { ThunkAction } from "redux-thunk";
import rootReducer, { RootState } from "./reducer";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export { persistor, store };
