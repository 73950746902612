import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme } from "@material-ui/core/styles";

import IRouterComponentProps from '../models/IRouterComponentProps';
import { sideMenuGradient } from '../common/materials/LinearGradient';
import Collapsable from '../components/products/faq/Collapsable';

const StyledContainer = styled(Grid)`
    .logoText {
        font-size: 1.5em;
    }
    .returnToWebsite {
        font-size: 18px;
    }
`;

const StyledResetPaddingContainer = styled(Grid)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled(Grid)`
    display: inline-block;
    width: 100%;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        img {
            width: 90%;
        };  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        img {
            width: 20%;
        };
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        img {
            width: 20%;
        };
    }
    display: flex;
    align-items: center;
    margin-left: 15px;
`;

const StyledTextContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 5px;
    color: ${(props) => props.theme.colors.base.white};
    font-family: SF-Display-Medium;
`;

const StyledLogoWrapperContainer = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    background: ${(props) => props.theme.colors.base.darkBlue};
    background: ${sideMenuGradient}
`;

const StyledLogoImage = styled.img`
    height: 2.5em;
    width: 1.5em;
`;

interface IProps extends IRouterComponentProps {
    t?: any;
}

const FAQ = (props: IProps) => {
    const { t } = props;
    const theme = createTheme({});
    const notSmallDevices = useMediaQuery(theme.breakpoints.between('sm', 'xl'));

    const returnToWebsite = () => {
        props.history.push('/')
    }

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledLogoWrapperContainer container={true} spacing={0}>
                <StyledResetPaddingContainer item xs={12}>
                    /* <StyledLogoWrapper item xs={3} onClick={returnToWebsite}>
                        <StyledLogoImage src="/images/logo.png" />
                        {notSmallDevices &&
                            <StyledTextContainer className="logoText">{t('common:header:logoText')}</StyledTextContainer>
                        }
                    </StyledLogoWrapper> */
                    <StyledTextContainer className="returnToWebsite" onClick={returnToWebsite}>
                        {t('common:kycFrom:faq:returnToWebsite')}
                    </StyledTextContainer>
                </StyledResetPaddingContainer>
            </StyledLogoWrapperContainer>
            <Collapsable {...props} />
        </StyledContainer>
    )
}

export default FAQ;