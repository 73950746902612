import React from "react";
import AdContainer from "./Ads";

const AdsList = (props: { adsList: { link: string; imgSrc: string }[] }) => {
  const adsList = props.adsList;
  return (
    <>
      {adsList.map((img: { link: string; imgSrc: string }) => (
        <AdContainer link={img.link} imgSrc={img.imgSrc} />
      ))}
    </>
  );
};

export default AdsList;
