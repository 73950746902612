import { Grid, Modal } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";

import LanguageChooser from "../components/shared/languageChooser";
import IRouterComponentProps from "../models/IRouterComponentProps";
import ConfirmNewPassword from "./login/ConfirmNewPassword";
import ForgotPassword from "./login/ForgotPassword";
import LoginForm from "./login/LoginForm";
import RegisterForm from "./login/RegisterForm";
import ViewRegisterModal from "./ViewVideoEnlargeModal";
import Alert from "../common/elements/Alert";
import Flotingicon from "../components/products/Flotingicon";
import SubdomainPageFooter from "../components/footer/SubdomainPageFooter";
import LoginWithEmail from "./login/passwordReset/LoginWithEmail";
import PasswordResetViaOtp from "./login/passwordReset/PasswordResetViaOtp";
import LoginWithOtp from "./login/passwordReset/LoginWithOtp";

interface IProps extends IRouterComponentProps {
  t?: any;
}

interface ButtonProps {
  selected?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  border-radius: 3px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  margin-right: 5px;
  &:hover {
    background: ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${(props) => props.theme.colors.base.white};
      color: ${(props) => props.theme.colors.base.black};
    `}

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    align-items: center;
  }
`;

const StyledContainer = styled(Grid)`
  .logoText {
    font-size: 1.5em;
  }
  .returnToWebsite {
    font-size: 18px;
  }
`;

const StyledResetPaddingContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledLogoWrapper = styled(Grid)`
  display: inline-block;
  width: 60% !important;
  height: 40px !important
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 60% !important;
      height: 40px !important;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 60% !important;
      height: 40px !important;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 40% !important;
    }
  }
  display: flex;
  align-items: center;
`;

const StyledLogoWrapperContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  background: white;
`;

const StyledFooterContainer = styled(Grid)`
  padding: 0 !important;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    margin-top: 50px;
  }
`;

const StyledLogoImage = styled.img`
  width: 70% !important;
  height: 40px !important;
`;

const StyledBannerContianer = styled(Grid)`
  display: flex;
  position: relative;
`;

const StyledBanContent = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  position: absolute;
  font-weight: bold;
  top: 15%;
  left: 15%;

  .firstLine {
    font-size: 1.5em;
    padding-bottom: 0.1em;
  }
  .secondLine {
    font-size: 1em;
    margin-top: 1em;
  }
  @media (width: 768px) {
    top: 20% !important;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    left: 4%;
    top: 15%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    left: 2%;
    top: 20%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }
`;

const StyledButtonContainer = styled.div`
  margin-top: 1em;
`;

const StyledBanBorder = styled(Grid)`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.white};
  opacity: 0.3;
`;
const StyledBidButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 8px;
  font-style: italic;
  background: ${(props) => props.theme.colors.base.textColor};

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    padding: 0.3em;
    border-radius: 4px;
  }

  // border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;








const StyledRelevantPeople = styled(Grid)`
  justify-content: center;
  margin-top: 3em;
  .alignCenter {
    text-align: center;
  }
  .positionRelative {
    position: relative;
  }
  .content {
    position: absolute;
    top: 10%;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 1em;
    padding: 0.8em;
    .content {
      position: relative;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
    .content {
      position: relative;
    }
  }
`;
const StyledContImage = styled.img`
  height: 120%;
  width: 100%;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 160px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 110%;
    width: 100%;
  }
  @media (width: 600px) {
    height: 140%;
    width: 100%;
  }
`;

const StyledRelevateImage = styled.img`
  height: 360px;
  width: 90%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 95%;
    height: 100%;
  }
`;

const StyledButtonWrapper = styled.div`
  float: right;
  padding: 8px;
  display: flex;
`;


const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;



export const StyledMeetReleventName = styled.div`
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.base.textColor};
  font-weight: bold;
  margin-top: 0.4em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
    margin-top: 0.2em;
  }

  @media (width: 320px) {
    font-size: 0.6em;
  }
`;

export const StyledMeetReleventBorderBottom = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.textColor};
`;

export const StyledMeetReleventDescription = styled.div`
  margin-top: 0.6em;
  text-align: justify;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 0.2em;
    font-size: 0.5em;
  }
  @media (width: 320px) {
    font-size: 0.4em;
  }
  @media (width: 280px) {
    font-size: 0.2em;
  }
`;

const StyledQRCode = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 4px;
`;
const StyledQRImage = styled.img`
  height: 20vh;
  ${(props) => props.theme.breakpoints.only("sm")} {
    height: 8vh;
  } ;
`;
const StyledTwiterQRImage = styled.img`
  height: 18vh;
  ${(props) => props.theme.breakpoints.only("sm")} {
    height: 6vh;
  } ;
`;

const StyledTwitterImage = styled.img`
  height: 1.2em;
  width: 5em;
`;

const StyledCategoryName = styled.div`
  font-family: Gilroy-Bold;
  color: black;
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 1.2em;
  }
`;

const StyledTwiterText = styled.div`
    display: flex;
    color: rgb(80, 151, 226);
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 6px;
    font-weight: bold;
    font-family: Gilroy-Bold;
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      font-size: 0.8em;
    }
    ${(props) => props.theme.breakpoints.between("sm", "md")} {
      font-size: 1em;
    }
    ${(props) => props.theme.breakpoints.between("lg", "xl")} {
      font-size: 1.2em;
    }
`;

const StyledQRCodeChild = styled.div`
  text-align: center;
`;
const StyledKiragaStoreImage = styled(Grid)`
  margin-top: 60px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 10px;
  }
`;
const StyledTwiterlink = styled.span`
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.textColor};
  font-weight: bold;
  text-decoration: underline;
`;

const Kiraga = (props: IProps) => {
  const { t } = props;
  const [onPressSignUp, setOnPressSignUp] = useState(false);
  const [onPressLogin, setOnPressLogin] = useState(false);
  const [onPressForgotPassword, setOnPressForgotPassword] = useState(false);
  const [onsuccessfulRegister, setOnsuccessfulRegister] = useState(false);
  const [onConfirmPassword, setOnConfirmPassword] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showLoginWithOTPPopup, setShowLoginWithOTPPopup] = useState(false);

  const unSource: any = useRef<any>();
  let userEmailvalue = useRef<any>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("un");
    unSource.current = userName;

    if (userName !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
    }
  }, []);


  const loginHandler = () => {
    setOnPressLogin(true);
    setOnPressSignUp(false);

    setShowLoginWithOTPPopup(false);
  };

  const onClose = () => {
    setOnPressLogin(false);
    setOnPressSignUp(false);
    setOnPressForgotPassword(false);
    setOnsuccessfulRegister(false);
    setOnConfirmPassword(false);
    setShowLoginWithOTPPopup(false);
  };

  const onCloseForgotPassword = () => {
    setOnPressForgotPassword(false);
    setOnConfirmPassword(true);
  };

  const signUpHandler = () => {
    setOnPressLogin(false);
    setOnPressSignUp(true);
    setShowLoginWithOTPPopup(false);
  };


  const forgotPasswordModalHandler = () => {
    setOnPressLogin(false);
    setOnPressForgotPassword(true);

    setShowLoginWithOTPPopup(false);
  };

  const regiterModalHandler = () => {
    setOnPressSignUp(false);
    setOnsuccessfulRegister(true);

    setShowLoginWithOTPPopup(false);
  };

  const showSuccessfulToast = () => {
    //props.showSuccessfulToast();
    const { t } = props;
    setAlertType("success");
    setShowMessage(
      `${t("common:profile:forgotPassword:validation:invalidOTP")}`
    );
    setOpenToast(true);
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      // props.showTimeoutToast();
      // setOnPressSignUp(false);
      setOnPressSignUp(false);
      setAlertType("error");
      setShowMessage(
        `${t("common:timeOut:somethingWrong")} ${t("common:timeOut:tryAgain")}`
      );
      setOpenToast(true);
    }
  };

  const renderResetPasswordPopup = () => {
    return (
      <StyledModal
        open={showResetPasswordPopup}
        onClose={() => {
          setShowResetPasswordPopup(false);
        }}
      >
        <LoginWithEmail
          closeModal={() => {
            setShowResetPasswordPopup(false);
          }}
          isFromSubDomain={false}
          renderOTPPopup={() => {
            setShowOTPPopup(true);
            setShowResetPasswordPopup(false);
          }}
          subdomainfunc={rhandlechangePlanModal}
        />
      </StyledModal>
    );
  };

  const renderOTPPopup = () => {
    return (
      <StyledModal open={showOTPPopup}>
        <PasswordResetViaOtp
          closeOtpModal={() => {
            setShowOTPPopup(false);
          }}
          openPasswordPop={() => {
            setShowResetPasswordPopup(true);
          }}
        />
      </StyledModal>
    );
  };

  const loginButtonHandler = () => {
    if (unSource.current !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
      loginHandler();
    }
  };


  let isFaxContinerActive: any = false;
  let isEmailContinerActive: any = false;

  const rhandlechangePlanModal = (val: string) => {
    //console.log("hellow");
  };

  return (
    <StyledContainer container={true} spacing={0}>
      <StyledLogoWrapperContainer container={true} spacing={0}>
        <StyledResetPaddingContainer item xs={12}>
          <StyledLogoWrapper item xs={5} sm={6} lg={4}>
            <StyledLogoImage src="/images/sub-domain/kiragaLogo.jpg"></StyledLogoImage>
          </StyledLogoWrapper>
          <Grid item xs={7} sm={6} lg={8} className="buttonsContainer">
            <StyledButtonWrapper>
              <StyledButton
                selected={true}
                style={{ border: " 1px solid #2d75c2" }}
                onClick={loginButtonHandler}
              >
                {t("common:header:buttons:login")}
              </StyledButton>
              <div
                style={{ border: " 1px solid #2d75c2", borderRadius: "3px" }}
              >
                <LanguageChooser {...props} isArrowColor={true} />
              </div>
            </StyledButtonWrapper>
          </Grid>
        </StyledResetPaddingContainer>
      </StyledLogoWrapperContainer>
      <StyledBannerContianer item={true} xs={12}>
        <StyledContImage src="/images/sub-domain/bannernew.gif" />
        <StyledBanContent item xs={7} sm={7} md={7} lg={6} xl={6}>
          <div className="firstLine">
            {t("common:kiraga:banner:companyName")}
          </div>
          <StyledBanBorder />
          <div className="secondLine">
            {t("common:kiraga:banner:companyDescriptionNew")}
          </div>
          <StyledButtonContainer>
            <StyledBidButton onClick={loginButtonHandler} className="isLogin">
              {t("common:kiraga:banner:button")}
            </StyledBidButton>
          </StyledButtonContainer>
        </StyledBanContent>
      </StyledBannerContianer>
      <StyledRelevantPeople
        item
        xs={12}
        container
        spacing={0}
        className="justifyContent"
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          container
          spacing={0}
          style={{ padding: "15px 15px" }}
        >
          <StyledKiragaStoreImage item xs={12} sm={12} md={5} xl={5}>
            <StyledRelevateImage src="/images/sub-domain/kiragaRelevantPeople.jpg" />
          </StyledKiragaStoreImage>
          <Grid item xs={12} sm={12} md={7} xl={7} className="positionRelative">
            <div className="content">
              <StyledMeetReleventName style={{ color: "Black" }}>
                {t("common:kiraga:releventHeader:relevantHeader")}
              </StyledMeetReleventName>
              <StyledMeetReleventBorderBottom />
              <StyledMeetReleventDescription style={{ display: "list-item" }}>
                {t("common:kiraga:releventHeader:firstdescription")}
                <br />
                <StyledTwiterlink>
                  {" "}
                  {t("common:kiraga:releventHeader:seconddescription")}{" "}
                </StyledTwiterlink>
                <br />
                {t("common:kiraga:releventHeader:thirdDescription")}
              </StyledMeetReleventDescription>
              <StyledMeetReleventDescription style={{ display: "list-item" }}>
                {t("common:kiraga:releventHeader:descriptionAdd")}
              </StyledMeetReleventDescription>
              <StyledQRCode>
                <StyledQRCodeChild>
                  <StyledTwiterText>
                    <StyledTwitterImage src="/images/kiragaQRCode/twitterl.png" />
                  </StyledTwiterText>
                  <StyledTwiterQRImage src="/images/kiragaQRCode/kiraga_twitter.jpg" />
                  <StyledTwiterText style={{ fontSize: "0.8em" }}>
                    @kiragajewelry
                  </StyledTwiterText>
                </StyledQRCodeChild>
                <StyledQRCodeChild>
                  <StyledCategoryName>
                    <StyledTwitterImage src="/images/kiragaQRCode/instagramlogo.png" />
                  </StyledCategoryName>
                  <StyledQRImage src="/images/kiragaQRCode/kiraga_instagram.png" />
                </StyledQRCodeChild>
              </StyledQRCode>
            </div>
          </Grid>
        </Grid>
      </StyledRelevantPeople>

      <StyledFooterContainer item={true} xs={12} className="footerContainer">
        <SubdomainPageFooter
          t={t}
          {...props}
          officeNumber={t("common:kiraga:footer:officeNumber")}
          faxNumber={t("common:kiraga:footer:faxNumber")}
          officeAddress={t("common:kiraga:footer:officeAddress")}
          emailId={t("common:kiraga:footer:emailId")}
          isFaxContinerActive={isFaxContinerActive}
          isEmailContinerActive={isEmailContinerActive}
        />
      </StyledFooterContainer>
      <Flotingicon />
      {onPressLogin === true && (
        <StyledModal
          open={onPressLogin}
          onClose={onClose}
          className="loginModal"
        >
          <LoginForm
            forgotPasswordModal={forgotPasswordModalHandler}
            {...props}
            registerModal={signUpHandler}
            isFromSubDomain={true}
            renderLoginWithOTPPopup={forgotPasswordModalHandler}
            userEmailvalue={forgotPasswordModalHandler}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}
      {showLoginWithOTPPopup === true && (
        <StyledModal open={showLoginWithOTPPopup}>
          <LoginWithOtp
            {...props}
            isFromSubDomain={true}
            forgotPasswordModal={forgotPasswordModalHandler}
            registerModal={signUpHandler}
            oncloseModal={() => {
              onClose();
            }}
            loginButtonHandler={loginButtonHandler}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}

      {onPressForgotPassword === true && (
        <StyledModal
          open={onPressForgotPassword}
          onClose={onClose}
          className="forgotPasswordModal"
        >
          <ForgotPassword t={t} closeModal={onCloseForgotPassword} />
        </StyledModal>
      )}
      {onConfirmPassword === true && (
        <StyledModal open={onConfirmPassword}>
          <ConfirmNewPassword
            t={t}
            closeModal={() => onClose()}
            showSuccessfulToast={showSuccessfulToast}
          />
        </StyledModal>
      )}
      {onPressSignUp === true && (
        <StyledModal open={onPressSignUp} className="registerModal">
          <RegisterForm
            t={t}
            closeModal={() => onClose()}
            onSuccessfulRegisterModal={regiterModalHandler}
            loginModal={loginHandler}
            apiTimeout={timeout}
          />
        </StyledModal>
      )}
      {onsuccessfulRegister === true && (
        <StyledModal
          open={onsuccessfulRegister}
          onClose={() => onClose()}
          className="registerModal"
        >
          <ViewRegisterModal openRegisterModal={() => onClose()} t={t} />
        </StyledModal>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {/* {onPromotional === true &&
                <StyledModal open={onPromotional} onClose={onClose}>
                    <Promotional {...props} closeModal={onClose}/>
                </StyledModal>
            } */}
      {showResetPasswordPopup && renderResetPasswordPopup()}
      {showOTPPopup && renderOTPPopup()}
    </StyledContainer>
  );
};

export default Kiraga;
