import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import IRouterComponentProps from '../../../models/IRouterComponentProps';

import { IconProp } from "@fortawesome/fontawesome-svg-core";
const faInfoCircleProps = faInfoCircle as IconProp;

const StyledComponent = styled(Grid)`
    outline: none;
    .iconStyle {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 1.5em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
    }
`;

const StyledTextWrapper = styled.div`
    margin-left: 8px;
    display: flex;
    margin-top: 6px;
    align-items: center;
    font-size: 1.5em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledPauseWrapper = styled(Grid)`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 10px 10px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 10px 20px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 10px 20px;
    }
`;

export interface IProps extends IRouterComponentProps {
    t: any;
    closeModal: () => void;
}

const ViewPausedModal = React.forwardRef((props: IProps, ref) => {
    const { t, closeModal } = props;

    useEffect(() => {
        setTimeout(() => {
            closeModal();
        }, 3000);
    });

    return (
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <StyledPauseWrapper item md={12} xs={12} lg={12}>
            <FontAwesomeIcon icon={faInfoCircleProps} className="iconStyle" />
            <StyledTextWrapper>
              {t("common:auction:auctionPaused:firstLine")}
            </StyledTextWrapper>
          </StyledPauseWrapper>
        </StyledWrapper>
      </StyledComponent>
    );
});

export default ViewPausedModal;