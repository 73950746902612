import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import InvoiceListView from './InvoiceListView';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

interface IProducts {
    name: string;
    productCode: string;
    startDate: any;
    endDate: any;
    totalPrice: string | number;
}

interface IProps {
    t?: any;
    history?: any;
    currentRecords?: IProducts;
    shippingAddress: any;
    sendMessage: (message: string) => void;
    apiTimeout: (timeout: boolean) => void;
    downloadpdf: (invoiceId: string, auctionName: string, onClick: boolean) => void;
    downloadinternationalInvoice: (auctionId: string, auctionName: string, onClick: boolean) => void;
    trakingUIInvoice: (auctionIdTracking: any) => void;
    
}

interface IState {
    products: [];
}

export default class InvoiceList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            products: []
        };
    }

    setProducts(products: any) {
        this.setState(() => ({
            products
        }))
    }

    componentDidMount() {
        this.setProducts(this.props.currentRecords);
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.currentRecords !== this.props.currentRecords) {
            this.setProducts(this.props.currentRecords);
        }
    }

    timeout = (timeout: boolean) => {
        this.props.apiTimeout(timeout);
    }

    sendMessage = (message: string) => {
        this.props.sendMessage(message);
    }

    downloadPdf = (id: string, auctionName: string, onClickDownload: boolean) => {
        if (onClickDownload === true) {
            this.props.downloadpdf(id, auctionName, onClickDownload);
        }
    }
    downloadinternationalInvoice= (auctionId: string, auctionName: string, onClickDownloads: boolean) => {
        if (onClickDownloads === true) {
            this.props.downloadinternationalInvoice(auctionId, auctionName, onClickDownloads);
        }
    }

    trakingUI = (auctionId: string) => {        
        return this.props.trakingUIInvoice(auctionId);
    }

    render() {
        const { t } = this.props;
        return (
            <StyledGrid container={true} spacing={0}>
                {
                    this.state.products.map((product: any, index: number) => {
                        return (
                          <Grid
                            key={index}
                            item={true}
                            xs={12}
                            sm={6}
                            md={6}
                            lg={4}
                            xl={4}
                          >
                            <InvoiceListView
                              {...product}
                              t={t}
                              shippingAddress={this.props.shippingAddress}
                              sendMessage={this.sendMessage}
                              apiTimeout={this.timeout}
                              downloadpdf={this.downloadPdf}
                              downloadinternationalInvoice={
                                this.downloadinternationalInvoice
                              }
                              openTrackingUiWithId={this.trakingUI}
                            />
                          </Grid>
                        );
                    })
                }
            </StyledGrid>
        )
    }
}