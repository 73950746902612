import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import {  getLiveEralierNotifications, getLiveNotifications } from "../../../common/config/app.endpoints";
import AuthService from "../../../components/auth/AuthService";

const initialState = {
  loading: false,
  error: "",
};
export let liveNotificationList: [];
export let lastMonthNotificationList: [];
export let unreadNotification = 0;
const Auth = new AuthService();

let userIdForNotification: string | null;
userIdForNotification = localStorage.getItem("userIdForNotification");
let  payload = {
  filters: {
    and: [
      {
        match: "exact",
        name: "userId",
        value: userIdForNotification,
      },
    ],
  },
  page: {
    start: 0,
    count: 1000,
    sort: {
      orders: [
        {
          property: "createdDate",
          direction: "DESC",
        },
      ],
    },
  },
};


export const getLiveNotificationsFun = () => {
  Auth.postRequest(`${getLiveNotifications}`, payload,(status: number, response: any) => {
    if (status === 200) {
      liveNotificationList = response.results;
      const resultsNotification = response.results.filter((obj: any) => {
        return obj.read === false;
      });
      unreadNotification = resultsNotification.leagth;
    }
  });
};

export const getlastMonthNotificationsFun = () => {
  Auth.postRequest(getLiveEralierNotifications, payload, (status: number, response: any) => {
    if (status === 200) {
      lastMonthNotificationList = response.results;
    }
  });
};

// const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
//   state.loading = true;
//   state.error = "";
// };

// const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
//   state.loading = false;
// };

// const genericRejectedCaseHandler = (
//   state: any,
//   action: PayloadAction<IErrorPayload>
// ) => {
//   state.error = action.payload || "Error";
//   state.loading = false;
// };

const notificationLiveSlice = createSlice({
  name: "notificationLive",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // notify 
  },
});

export default notificationLiveSlice.reducer;

// selectors
export const selectNotificationLoading = (state: RootState) =>
  state.notification.loading;
