import React from 'react';
import {CardElement, ElementsConsumer} from '@stripe/react-stripe-js';
import CardSection from "./CardSection";
import AuthService from '../../components/auth/AuthService';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import { processPayment } from '../../common/config/app.endpoints';
import Grid from '@material-ui/core/Grid';
import PubSub from 'pubsub-js';
import Alert from '../../common/elements/Alert';

const Auth = new AuthService();

class CheckoutForm extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      openToast: false,
      showAlertMessage: '',
      alertType: ''
    };
  }

  handleSubmit = async (event: any) => {
    
    event.preventDefault();
      
    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    
    if (result.error) {
      this.setState({
        alertType: 'error',
        showAlertMessage: result.error.message,
        openToast: true
      })
    } else {
      this.setState({isLoading: true});
      
      var getUserInfo = localStorage.getItem('userInfo');

      if (getUserInfo != null) {
        let userInfo = JSON.parse(getUserInfo)
        const data = {
        description: `${userInfo.firstName} ${userInfo.lastName} | ${userInfo.email}`,
        amount: userInfo.totalAmountToPay,
        currency: 'USD',
        stripeEmail : userInfo.email,
        stripeToken : result.token.id,
        userId : userInfo.userId
        
      }
      
        Auth.postRequestWithoutAuthorization(`${processPayment}`, data, (status: number, response: any) => {
        
          PubSub.publish("PaymentStatus", status);

          this.setState({ isLoading: false });
              if (status === 200) {
                this.setState({ isSuccess: true });
                localStorage.removeItem('userInfo')
                  
              } else if (status === 500) {
                
              }
          })
      }

    }

    const interval = setInterval(() => {
      this.setState({
        openToast: false
      })
      clearInterval(interval)
    }, 3000);

  };

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <Grid container={true} style={{ display: "grid" }}>
            <CardSection />
          </Grid>
          <button disabled={!this.props.stripe} className="btn-pay">
            Pay Now
          </button>
        </form>
        {this.state.isLoading === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
        {this.state.openToast === true && (
          <Alert
            showAlertMessage={this.state.showAlertMessage}
            alertType={this.state.alertType}
          />
        )}
      </div>
    );
  }
}

export default function InjectedCheckoutForm() {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );
}