import { Button, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IRouterComponentProps from '../../models/IRouterComponentProps';
import styled from 'styled-components';


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            // '& > *': {
            //     margin: theme.spacing(1),
            // },
        },
        input: {
            fontSize: '12px'
            
        },

        span: {
            color: 'red',
        },

        Button: {
            color: 'white',
            border: 'none',
            fontSize: '0.8em',
            padding: '0.6em',
            margin: '0.2em',
            borderRadius: '5px',
            background: '#5499E4',
            textTransform: 'capitalize',

            "&:hover": {
                //border: '1px solid #2d75c2',
                cursor: 'pointer',
                fontSize: '0.8em',
                padding: '0.6em',
                margin: '0.2em',
                borderRadius: '5px',
                background: '#5499E4',

            }
        },
    }),
);

const StyledButtonLable = styled(Grid)`
    font-size: 16px;
    width:100%;
    display:flex;
    align-items:center;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledHeaderCon = styled(Grid)`
    margin-left:1em;
`;

const StyledInputContainer = styled(Grid)`
    white-space: nowrap; 
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const UploadButtonContainer = styled(Grid)`
`;

interface IProps extends IRouterComponentProps {
    uploadBtnClick: (uploadedFile: any, fileType: string) => void;
}

const UploadIdentityCertificate = (props: IProps) => {
    //export default class UploadButton extends React.Component<IProps, iState>{}
    const { t, uploadBtnClick } = props;

    const [selectedFile, setSelectedFile] = useState("");

    const uploadBtnList = [
      {
        id: "1",
        btnLabel: `Identity Certificate with Photo (Passport/Driver License/Visa Card/My Number etc.)`,
        fileType: "IC",
        btnText: `${t("common:kycFrom:uploadFiles")}`,
        fileValue: "",
      },
    ];

    const classes = useStyles();

    const handleUpload = (event: any, fileType: any, index: number) => {
        uploadBtnList[index].fileValue = event.target.files[0].name;
        setSelectedFile(event.target.files[0].name);

        uploadBtnClick(event.target.files[0], fileType)
    }

    return (
        <>
            <StyledHeaderCon container={true} spacing={0}>
                {uploadBtnList.map((item: any, index: number) => {
                    return <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                        <UploadButtonContainer container={true} spacing={0}>
                            <StyledButtonLable item={true} xs={12} sm={7} md={7} lg={7} xl={7}>
                                <span>{item.btnLabel} <span className={classes.span}>*</span></span>                                
                            </StyledButtonLable>
                            <StyledInputContainer item={true} xs={12} sm={5} md={5} lg={5} xl={5}>
                                <input
                                    accept="image/png,image/jpeg,image/bmp,image/svg,image/jpg,image/svg+xml,application/pdf"
                                    className={classes.input}
                                    id={item.id}
                                    type="file"
                                    required
                                    name={item.btnLabel}
                                    onChange={(e) => handleUpload(e, item.fileType, index)}
                                />
                            </StyledInputContainer>
                        </UploadButtonContainer>
                    </Grid>
                })}
            </StyledHeaderCon>
        </>
    )
}
export default UploadIdentityCertificate;