import React from "react";
import { useTranslation } from "react-i18next";
import EllipsisText from "../../common/elements/EllipsisText";

interface IProps {
  truncateLength: number;
  data: any;
  isTendor?: boolean;
}

const ProductDescription = (props: IProps) => {
  const { t } = useTranslation();
  const { truncateLength, data, isTendor } = props;
  const {
    code,
    jewelleryDetails,
    parcelType,
    productDescription,
    color,
    carat,
    clarity,
    cutGrade,
    flour,
    polish,
    symmetry,
    sieve,
    shape,
    symmetryAbbreviation,
  } = data;
  const { jewelleryCondition, jewelleryType, jewelleryDescription }: any = {
    ...jewelleryDetails,
  };

  const PARCEL_TYPE_JEWELLERY = "Jewellery";
  const PARCEL_TYPE_DIAMOND = "Diamond";
  const PARCEL_TYPE_PARCEL = "Parcel";

  // JEWELLERY
  const JEWELLERY_TYPE =
    jewelleryType === undefined || jewelleryType === null ? "" : jewelleryType;
  const JEWELLERY_CONDITION =
    jewelleryCondition === undefined || jewelleryCondition === null
      ? ""
      : jewelleryCondition;
  const JEWELLERY_DESCRIPTION =
    jewelleryDescription === undefined || jewelleryDescription === null
      ? ""
      : jewelleryDescription;

  // DIAMONDS
  const CARAT =
    carat === undefined || carat === null
      ? ""
      : `${carat} ${t("common:preview:ct")}`;
  const COLOR = color === undefined || color === null ? "" : color;
  const CLARITY = clarity === undefined || clarity === null ? "" : clarity;
  const CUTGRADE = cutGrade === undefined || cutGrade === null ? "" : cutGrade;
  const FLOUR = flour === undefined || flour === null ? "" : flour;
  const POLISH = polish === undefined || polish === null ? "" : `${polish}`;
  const SYMMETRY =
    symmetry === undefined || symmetry === null ? "" : `${symmetry}`;
  const SYMMETRY_ABBREVATION =
    symmetryAbbreviation === undefined || symmetryAbbreviation === null
      ? ""
      : `${symmetryAbbreviation}`;

  // PARCEL
  const SIEVE_TYPE =
    sieve === undefined || sieve === null || sieve === "" ? "" : sieve;
  const PRODUCT_SHAPE = shape === undefined || shape === null ? "" : shape;
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  // Find all the URLs in the string
  var urls = productDescription ?? "".match(urlRegex);

  const PRODUCT_DESCRIPTION =
    productDescription === undefined || productDescription === null
      ? ""
      : urls
        ? ""
        : productDescription;

  const CARAT_CT =
    carat === undefined || carat === null ? "" : `${carat} ${"Ct"}`;

  const getJewelleryDescription = () => {
    return `${JEWELLERY_TYPE} ${JEWELLERY_CONDITION} ${JEWELLERY_DESCRIPTION}`;
  };

  const getDiamondDescription = () => {
    return `${CARAT} ${COLOR} ${CLARITY} ${PRODUCT_SHAPE} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY} `;
  };

  const getParcelDescription = () => {
    return `${PRODUCT_DESCRIPTION} ${COLOR} ${PRODUCT_SHAPE} ${SIEVE_TYPE} ${CARAT_CT}`;
  };

  const getDescription = () => {
    let description = "";

    if (parcelType === PARCEL_TYPE_JEWELLERY) {
      description = getJewelleryDescription();
    } else if (parcelType === PARCEL_TYPE_DIAMOND) {
      description = getDiamondDescription();
    } else if (parcelType === PARCEL_TYPE_PARCEL) {
      description = getParcelDescription();
    }

    if (isTendor) {
      return ` ${code} ${description} ${SYMMETRY_ABBREVATION}`;
    } else {
      return `${code} ${description}`;
    }
  };

  return (
    <span title={getDescription()}>
      <EllipsisText
        text={getDescription()}
        length={truncateLength}
        tail={"..."}
      />
    </span>
  );
};

export default ProductDescription;
