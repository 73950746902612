import { Grid, Modal } from "@material-ui/core";
import styled, { css } from "styled-components";
import { buttonGradient, dateBackgroundGradient } from "../../../../common/materials/LinearGradient";

const StyledGrid = styled(Grid)``;

const StyledTextWrapper = styled(Grid)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 1em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledDisabledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 0.8em;
  margin-left: 1.2em;
  margin-top: 1em;
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.scrollerColor};
    border-radius: 5px;
  }
`;

const StyledWrapper = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
    overflow-y: scroll;
    height: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    // padding: 0.8em;
    overflow-y: scroll;
    height: 400px;
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 1em;
    width: 400px;
  }
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
`;

const StyledJoinWrapper = styled(Grid)`
  padding: 1em;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

interface IButtonProps {
  participate?: boolean;
  join?: boolean;
  disableParticipateButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.participate &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
  ${(props) =>
    props.join &&
    css`
      width: 40%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 30px;
      margin-left: 10px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
    ${(props) =>
    props.disableParticipateButton &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      cursor: default;
      background: ${dateBackgroundGradient};
    `}
`;

const StyledInput = styled.input`
  padding: 14px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
  width: 100%;
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border-radius: 20px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledCodeWrapper = styled(Grid)``;

const StyledMessage = styled(Grid)`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
`;

const StyledTextButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export {
  StyledTextButtonWrapper,
  StyledMessage,
  StyledCodeWrapper,
  StyledInput,
  StyledButton,
  StyledJoinWrapper,
  StyledParticipateWrapper,
  StyledWrapper,
  StyledModal,
  StyledTextWrapper,
  StyledGrid,
  StyledDisabledMessage  
};
