import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import AuctionCardView from "../views/AuctionCardViewAtPreview";

const StyledGrid = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const StyledDateWrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.base.grey};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 14px;
  }
`;

const StyledDateGrid = styled(Grid)`
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
`;

interface IProducts {
  id: number | string;
  name: string;
  brandName?: string;
  price: number;
  serialNumber: number;
  preBiddingPrice: number;
  auctionType: string;
}

interface IProps {
  t: any;
  selectedPage?: boolean;
  history?: any;
  currentRecords?: IProducts[];
  jewelleryCurrentRecords?: IProducts[];
  value: (value: boolean) => void;
  showListOfProduct: (
    id: number | string,
    name: string,
    auctionStartDateString: string,
    state: string,
    auctionType: string
  ) => void;
}

interface IState {
  products: [];
  diamondAndParcelList: [];
  jewellerylist: [];
}

export default class AuctionListViewHome extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      products: [],
      diamondAndParcelList: [],
      jewellerylist: [],
    };
  }

  filterCategoryWiseAuctionList(auctionList: any) {
    const DiamondAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Diamonds";
    });

    const ParcelAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Parcel";
    });

    const JewelleryAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Jewellery";
    });

    let diamondAndParcelAuctionArray: any;

    diamondAndParcelAuctionArray = [
      ...DiamondAuctionData,
      ...ParcelAuctionData,
    ];
    this.setState(() => ({
      diamondAndParcelList: diamondAndParcelAuctionArray,
      jewellerylist: JewelleryAuctionData,
    }));
  }

  setProducts(products: any) {
    this.setState(() => ({
      products,
    }));
  }

  componentDidMount() {
    this.filterCategoryWiseAuctionList(this.props.currentRecords);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentRecords !== this.props.currentRecords) {
      this.filterCategoryWiseAuctionList(this.props.currentRecords);
    }
  }

  showListOfProduct = (
    productId: string | number,
    name: string,
    auctionStartDateString: string,
    state: string,
    auctionType: string
  ) => {
    this.props.showListOfProduct(
      productId,
      name,
      auctionStartDateString,
      state,
      auctionType
    );
  };

  value = (value: boolean) => {
    if (value === true) {
      this.props.value(true);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledDateGrid item xs={12}>
          {/* <StyledDateWrapper className="fontBold">
            {t("common:newCommanTrans:diamondandParcel")}
          </StyledDateWrapper> */}
        </StyledDateGrid>
        {this.state.diamondAndParcelList.length > 0 &&
          this.state.diamondAndParcelList.map((product: any, index: number) => {
            return (
              <Grid key={index} item={true} xs={12} sm={6} md={6} lg={3} xl={3}>
                <AuctionCardView
                  {...product}
                  t={t}
                  showListOfProducts={this.showListOfProduct}
                  value={this.value}
                />
              </Grid>
            );
          })}
        <StyledDateGrid item xs={12}>
          {/* <StyledDateWrapper className="fontBold">
            {" "}
            {t("common:newCommanTrans:jewellery")}
          </StyledDateWrapper> */}
        </StyledDateGrid>
        {this.state.jewellerylist.length > 0 &&
          this.state.jewellerylist.map((product: any, index: number) => {
            return (
              <Grid key={index} item={true} xs={12} sm={6} md={6} lg={3} xl={3}>
                <AuctionCardView
                  {...product}
                  t={t}
                  showListOfProducts={this.showListOfProduct}
                  value={this.value}
                />
              </Grid>
            );
          })}
      </StyledGrid>
    );
  }
}
