import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import {
  backgroundGradient,
  buttonGradient,
} from "../../../../common/materials/LinearGradient";
import { StyledCardContainer } from "../../../../common/materials/GenericStyleComponent";
import EllipsisText from "../../../../common/elements/EllipsisText";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { Checkbox } from "@material-ui/core";
import { StyledCloseicon } from "../TendorPreviewView";
import { convertedValue, getMyLatestStoredBids } from "../TenderGenericFunc";
import ReactPlayer from "react-player";
import { StyledRapOffLabelWrapper } from "../ProductsListGrid";
import { openCertificateLinkPopup } from "../../../../common/GenericFunctions";
import { match } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import { tenderselectDiamondAllIndexes, tenderselectDiamondAllInGridView } from "../../../../redux/slice/annual/commanAnnualSlice";
const faHeartProps = faHeart as IconProp;

const StyledTitleWrapper = styled(Grid)`
  background: ${backgroundGradient};
  display: flex;
  align-items: center;
  height: 90px;
  font-size: 0.7em;
  color: ${(props) => props.theme.colors.base.white};
  .height {
    height: 100%;
  }
`;

const StyledWishContainer = styled(Grid)`
  background: ${backgroundGradient};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  position: relative;
`;

const StyledNotStartedContainer = styled(Grid)`
  cursor: default;
  background: ${backgroundGradient};
  &:hover {
    border: 1px solid ${backgroundGradient};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
`;

const StyledProductImageContainer = styled(Grid)`
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledTotalSubProducts = styled.div`
  position: absolute;
  bottom: 0;
  background: ${(props) => props.theme.colors.base.lightGrey};
  width: 100%;
  text-align: center;
  opacity: 0.5;
  color: ${(props) => props.theme.colors.base.black};
`;

const StyledProductImage = styled("img")`
  max-width: 100%;
  max-height: 100%;
`;

const StyledWrapper = styled(Grid)`
  padding: 5px;
  padding-top: 5px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: center;
`;

const StyledfooterContaneir = styled(Grid)`
  display: flex;
  grid-gap: 20px;
`;

const StyledLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.8em;
`;

const StyledSerialNumberLabelWrapper = styled.div`
  text-align: center;
  font-size: 0.7em;
`;

const StyledValueConatiner = styled.div`
  text-align: center;
  font-size: 0.8em;
  cursor: pointer;
`;

const StyledBidAmount = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledRemarks = styled(Grid)`
  display: flex;
`;

const StyledRemarkLbl = styled.div`
  margin-left: 0.4em;
  display: flex;
`;

const StyledContainer = styled(Grid)``;

const StyledProductCon = styled(Grid)`
  border-bottom: 1px solid ${(props) => props.theme.colors.base.white};
  .marginLeft {
    margin-left: 1%px;
  }

  .padding {
    padding: 0.3em;
    font-size: 12px;
  }
  .alignCenter {
    text-align: center;
  }
  .marginRight {
    border-right: 1px solid ${(props) => props.theme.colors.base.white};
  }
  .alignRight {
    text-align: right;
  }
`;

const StyledDescription = styled(Grid)`
  // padding:0.4em;
  margin-top: 0.8em;
  widht: 100%;
  // white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledContDaimond = styled.div`
  padding: 0.4em;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.8em;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
interface IChangeColorProps {
  changeColor?: String;
}

export const StyledCurrentBid = styled.div<IChangeColorProps>`
  font-size: 0.8em;
  width: 100%;
  background: ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.changeColor === "white" &&
    css`
      background: white;
    `}
  ${(props) =>
    props.changeColor === "#FFEB9C" &&
    css`
      background: #ffeb9c;
    `}
    ${(props) =>
    props.changeColor === "#ffeb9c" &&
    css`
      background: #ffeb9c;
    `}

    ${(props) =>
    props.changeColor === "#FBE4D5" &&
    css`
      background: #fbe4d5;
    `}
`;

const PlayerSlide = ({ url }: { url: string }) => (
  <ReactPlayer
    width="100%"
    height="100%"
    url={url}
    playing={false}
    controls={true}
    muted={true}
    progressInterval={1000}
  />
);

interface DetailParams {
  auctionId: string;
  auctionName: string;
  auctionDate: string;
  auctionUserId: string;
  auctioneerUserId?: string;
}
interface IProps extends IRouterComponentProps {
  t: any;
  match: match<DetailParams>;
  details: any;
  items: any;
  perId: string;
  auctionId: string;
  serialNo: string | number;
  code: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  flour: string;
  polish: string;
  watched: boolean | null;
  unwatchedList: any;
  polishAbbreviation: string;
  symmetry: string;
  symmetryAbbreviation: any;
  lab: string;
  caratMinimumPrice: any;
  preBidPrice: any;
  addToWatchList: any;
  note: boolean | null | string | number;
  medias: any;
  auctionDate: any;
  remarks: string;
  parcelType: string;
  subItems: [];
  shape: string;
  sieve: string;
  noOfPieces: number | string | null;
  productDescription: string;
  currency: any;
  exchangeRates: any;
  rapnetPercentage: any;
  certificate: string;
  isPage: any;
  setShowBidPopupBidModal: any;
  currentIndex: any;
  currentBidPrice: any;
  myLatestBid: any;
  auctionUserIds: any;
  rowColor: any;
  auctionUserId: any;
  sellerAuctionUserId: any;
  sortingValue: (price: any, val: any) => void;
  boxColor?: (items: any) => string;
  bulkBidHandler?: (val: any[]) => void;
  tenderSecondHighestBid: any;
  myLatestBidForProduct?: any;
  renderAutomaticBidPopupInMobile: (val: any) => void;
  previousHighestBidder: any;
  renderReadOnlyUserToast: (val: any) => void;
  holdPreviousBidder: any;
  rightClickEvent: (e: any, val: any) => void;
}

let caratMinPrice: string = "";
let caratMinPriceTenderLiveHighest: string = "";

let bidAmount: string = "";
let state: string | null = "";
let conversionCaratMinPrice: string = "";
let conversionBidAmount: string = "";
let direction: string = "desc";
let isReadOnlyUser: any;

let gridIndex: any = [];
const TenderMobileUIMybids = (props: IProps) => {
  const [disableButton, setDisableButton] = useState(false);
  const [boxIndex, setBoxIndex] = useState(props.currentIndex);
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [gridIndexArr, setGridIndexArr] = useState<any[]>([]);

  let {
    t,
    auctionDate,
    medias,
    watched,
    serialNo,
    perId,
    auctionId,
    code,
    carat,
    color,
    clarity,
    cutGrade,
    flour,
    polish,
    caratMinimumPrice,
    preBidPrice,
    remarks,
    parcelType,
    subItems,
    shape,
    sieve,
    noOfPieces,
    productDescription,
    currency,
    exchangeRates,
    rapnetPercentage,
    lab,
    certificate,
    isPage,
    setShowBidPopupBidModal,
    currentIndex,
    currentBidPrice,
    auctionUserIds,
    auctionUserId,
    sellerAuctionUserId,
    symmetryAbbreviation,
    rowColor,
    items,
    myLatestBidForProduct,
    renderAutomaticBidPopupInMobile,
    previousHighestBidder,
    renderReadOnlyUserToast,
    holdPreviousBidder,
    rightClickEvent,
  } = props;

  caratMinPrice = new Intl.NumberFormat("us-en").format(caratMinimumPrice);
  bidAmount = new Intl.NumberFormat("us-en").format(preBidPrice);
  caratMinPriceTenderLiveHighest = new Intl.NumberFormat("us-en").format(
    currentBidPrice
  );

  const selectedItemIndexes: any = useSelector((state: RootState) =>
    tenderselectDiamondAllIndexes(state)
  );
  const isSelectedCheckBox: any = useSelector((state: RootState) =>
    tenderselectDiamondAllInGridView(state)
  );


  useEffect(() => {
    caratMinPrice = "";
    bidAmount = "";
    caratMinPriceTenderLiveHighest = "";
    setDisableButton(false);
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      if (
        sellerAuctionUserId ===
        (auctionUserIds === undefined
          ? props.match.params.auctionUserId
          : auctionUserIds)
      ) {
        setIsAuctionUserId(true);
      } else {
        setIsAuctionUserId(false);
      }
    }

    const rediractProductId: any = localStorage.getItem(
      "currentProductIdOfBidNow"
    );
    if (rediractProductId !== null) {
      renderAutomaticBidPopupInMobile(items);
    }

    return () => {
      localStorage.removeItem("pathname");
    };
  }, []);

  // useEffect(() => {
  //   if (gridIndex !== undefined) {
  //     if (gridIndex.length < 10) {
  //       gridIndex = selectedItemIndexes;
  //       setGridIndexArr(gridIndex);
  //       props.bulkBidHandler?.(gridIndex);
  //     }
  //     else if (!isSelectedCheckBox) {
  //       gridIndex = [];
  //       setGridIndexArr(gridIndex);
  //       props.bulkBidHandler?.(gridIndex);
  //     }
  //   }
  // }, [isSelectedCheckBox])


    useEffect(() => {
      if (gridIndex.length < 10) {
        gridIndex = selectedItemIndexes;
        setGridIndexArr(gridIndex);
        props.bulkBidHandler?.(gridIndex);
      } else if (!isSelectedCheckBox) {
        gridIndex = [];
        setGridIndexArr(gridIndex);
        props.bulkBidHandler?.(gridIndex);
      }
    }, [isSelectedCheckBox]);
    
  if (
    caratMinimumPrice === null ||
    caratMinPrice === "NaN" ||
    caratMinPrice === "0"
  ) {
    caratMinPrice = "-";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = new Intl.NumberFormat("us-en").format(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionCaratMinPrice = "";
    }
    caratMinPrice = `${currency["symbol"]}${caratMinPrice}`;
  }

  if (preBidPrice === null || bidAmount === "NaN" || bidAmount === "0") {
    bidAmount = "-";
    conversionBidAmount = "";
  } else {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    if (userCurrencyCode !== currency["code"]) {
      let conversion = new Intl.NumberFormat("us-en").format(
        Math.round(preBidPrice * exchangeRates[0]["rate"])
      );
      conversionBidAmount = `(${userCurrencySymbol}${conversion})`;
    } else {
      conversionBidAmount = "";
    }
    bidAmount = `${currency["symbol"]}${bidAmount}`;
  }

  if (state !== null) {
    state = localStorage.getItem("auctionState");
  }

  const onPress = (auctionId: string, perId: string, auctionDate: string) => {
    if (isPage) {
      props.history.push(
        `/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=true&uniqid=${auctionUserIds === undefined
          ? props.match.params.auctionUserId
          : auctionUserIds
        }`
      );
    } else {
      props.history.push(
        `/preview/tender/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${auctionUserIds === undefined
          ? props.match.params.auctionUserId
          : auctionUserIds
        }`
      );
    }
  };

  const ShowImage = () => {
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        }
      }
    }
    return <StyledProductImage src={`${medias[0].presignedUrl}`} alt="" />;
  };

  const removeProductFromList = () => {
    props.unwatchedList(perId, auctionId);
  };

  const CARAT = carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`;
  const COLOR = color === undefined ? "" : color;
  const CLARITY = clarity === undefined ? "" : clarity;
  const CUTGRADE = cutGrade === undefined ? "" : cutGrade;
  const FLOUR = flour === undefined ? "" : flour;
  const POLISH =
    polish === undefined ? "" : `${t("common:preview:pol")}=${polish}`;
  const SYMMETRY =
    symmetryAbbreviation === undefined
      ? ""
      : `${t("common:preview:sym")}=${symmetryAbbreviation}`;

  // PARCEL
  const SIEVE_TYPE =
    sieve === undefined || sieve === null || sieve === "" ? "" : sieve;
  const PRODUCT_SHAPE = shape === undefined || shape === null ? "" : shape;
  const PRODUCT_DESCRIPTION =
    productDescription === undefined ? "" : productDescription;
  const CARAT_CT = carat === undefined ? "" : `${carat} ${"Ct"}`;

  const getDiamondDescription = () => {
    return `${CARAT} ${COLOR} ${PRODUCT_SHAPE} ${CLARITY} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY} `;
  };

  const getProductDescription = () => {
    return `${PRODUCT_DESCRIPTION} ${COLOR} ${PRODUCT_SHAPE} ${SIEVE_TYPE} ${CARAT_CT}`;
  };

  const addProductToWatch = () => {
    props.addToWatchList(perId, auctionId, serialNo);
  };

  const TenderPlaceBidPopup = () => {
    setShowBidPopupBidModal(boxIndex);
  };
  
  const toggleCheckboxValue = (e: any, indexOfProduct: any) => {
    var rowId = indexOfProduct;
    var checked = e.target.checked;
    PubSub.subscribe("resetGridBoxIndex", (msg: any, data: any) => {
      gridIndex = data;
      setGridIndexArr(gridIndex);
    });
    if (checked) {
      let newArray = [...gridIndex, rowId];
      gridIndex = newArray;
      setGridIndexArr(gridIndex);

    } else {
      gridIndex = gridIndex.filter((x: any) => x !== rowId);
      setGridIndexArr(gridIndex);
    }
    props.bulkBidHandler?.(gridIndex);
  };

  const sortingFuction = (price: any, value: any) => {
    props.sortingValue(price, value);
    if (value === "desc") {
      direction = "asc";
    } else {
      direction = "desc";
    }
  };

  const handelMyBidsLabel = (
    myStoredBids: any,
    currentProductId: any,
    currency: any,
    exchangeRate: any
  ) => {
    const Obj = myLatestBidForProduct.filter(
      (item: any) => item.productId === currentProductId
    );
    const mybidPriceforProduct = Obj.map((item: any) => {
      return item.productId;
    });

    return getMyLatestStoredBids(
      myLatestBidForProduct,
      currentProductId,
      currency,
      exchangeRate,
      mybidPriceforProduct
    );
  };

  const renderRemarkSection = () => {
    return (
      <div>
        <StyledRemarkLbl>
          {t("common:preview:perItemDescription:remarks") + ":"}
          {props.remarks !== "" &&
            props.remarks !== undefined &&
            props.remarks !== null ? (
            <>
              <EllipsisText text={remarks} length={60} tail={"..."} />
            </>
          ) : (
            ""
          )}
        </StyledRemarkLbl>
      </div>
    );
  };



  const renderCheckBox = () => {
    if (sellerAuctionUserId === auctionUserId) {
      return (
        <Checkbox
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          disabled
        />
      );
    } else if (holdPreviousBidder !== undefined) {
      if (props.match.params.auctionUserId === holdPreviousBidder) {
        return (
          <Checkbox
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            disabled
          />
        );
      } else {
        return (
          <Checkbox
            id={perId}
            name={perId}
            checked={gridIndex.includes(currentIndex)}
            color="primary"
            style={{ color: "white" }}
            value={currentIndex}
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            onClick={(e) => toggleCheckboxValue(e, currentIndex)}
          />
        );
      }
    } else if (previousHighestBidder !== undefined) {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return (
          <Checkbox
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            disabled
          />
        );
      } else {
        return (
          <Checkbox
            id={perId}
            name={perId}
            checked={gridIndex.includes(currentIndex)}
            color="primary"
            style={{ color: "white" }}
            value={currentIndex}
            inputProps={{ "aria-label": "uncontrolled-checkbox" }}
            onClick={(e) => toggleCheckboxValue(e, currentIndex)}
          />
        );
      }
    } else if (auctionUserId === props.match.params.auctionUserId) {
      return (
        <Checkbox
          id={perId}
          name={perId}
          checked={gridIndex.includes(currentIndex)}
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          disabled
        />
      );
    } else {
      return (
        <Checkbox
          id={perId}
          name={perId}
          checked={gridIndex.includes(currentIndex)}
          color="primary"
          style={{ color: "white" }}
          value={currentIndex}
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          onClick={(e) => toggleCheckboxValue(e, currentIndex)}
        />
      );
    }
  };

  const renderBidButton = () => {
    if (sellerAuctionUserId === auctionUserId) {
      return (
        <StyledButton
          disabled
          style={{ background: "lightgray", color: "black" }}
        >
          {t("common:Tender:button:placeBid")}
        </StyledButton>
      );
    } else if (holdPreviousBidder !== undefined) {
      if (props.match.params.auctionUserId === holdPreviousBidder) {
        return (
          <StyledButton
            disabled
            style={{ background: "lightgray", color: "black" }}
          >
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        );
      } else {
        return (
          <StyledButton onClick={TenderPlaceBidPopup}>
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        );
      }
    } else if (previousHighestBidder !== undefined) {
      if (props.match.params.auctionUserId === previousHighestBidder) {
        return (
          <StyledButton
            disabled
            style={{ background: "lightgray", color: "black" }}
          >
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        );
      } else {
        return (
          <StyledButton onClick={TenderPlaceBidPopup}>
            {t("common:Tender:button:placeBid")}
          </StyledButton>
        );
      }
    } else if (auctionUserId === props.match.params.auctionUserId) {
      return (
        <StyledButton
          disabled
          style={{ background: "lightgray", color: "black" }}
        >
          {t("common:Tender:button:placeBid")}
        </StyledButton>
      );
    } else {
      return (
        <StyledButton onClick={TenderPlaceBidPopup}>
          {t("common:Tender:button:placeBid")}
        </StyledButton>
      );
    }
  };

    const renderWatchlistButton = () => {
      if (
        sellerAuctionUserId ===
        (auctionUserIds === undefined
          ? props.match.params.auctionUserId
          : auctionUserIds)
      ) {
        return (
          <StyledNotStartedContainer
            item={true}
            xs={12}
            onClick={() => renderReadOnlyUserToast(sellerAuctionUserId)}
          >
            <FontAwesomeIcon
              icon={faHeartProps}
              className="unwatch imageColor"
            />
          </StyledNotStartedContainer>
        );
      } else {
        return (
          <StyledWishContainer
            item={true}
            xs={12}
            onClick={() => {
              addProductToWatch();
            }}
          >
            <FontAwesomeIcon icon={faHeartProps} className="unwatch" />
          </StyledWishContainer>
        );
      }
    };
  
    let PlayerSlideMeMo = useMemo(() => {
      return (
        <StyledProductImageContainer
          item={true}
          xs={9}
          onClick={() => onPress(auctionId, perId, auctionDate)}
        >
          {medias === null ||
          medias === undefined ||
          medias[0] === undefined ||
          medias[0] === null ||
          medias[0].presignedUrl === null ||
          medias[0].mediaType === "V360" ? (
            <div>
              {parcelType === "Diamond" ? (
                <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
              ) : (
                <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
              )}
            </div>
          ) : (
            <>
              {medias[0].mediaType === "PHOTO" ? (
                <ShowImage />
              ) : (
                <div>
                  {medias[0].mediaType.thumbnailPresignedUrl === null ||
                  medias[0].mediaType.presignedUrl === null ? (
                    <div>
                      {parcelType === "Diamond" ? (
                        <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                      ) : (
                        <StyledProductImage src="/images/default-parcel.png"></StyledProductImage>
                      )}
                    </div>
                  ) : (
                    <PlayerSlide url={`${medias[0].presignedUrl}`} />
                  )}
                </div>
              )}
            </>
          )}

          <StyledTotalSubProducts>
            {subItems !== undefined && subItems.length > 0 && (
              <span>
                {subItems.length} {t("common:preview:products")}
              </span>
            )}
          </StyledTotalSubProducts>
        </StyledProductImageContainer>
      );
    }, [items]);

  return (
    <>
      {isPage === false ? (
        <Grid>
          {perId !== undefined && (
            <StyledCardContainer
              id={serialNo.toString()}
              onContextMenu={(event: any) => rightClickEvent(event, perId)}
            >
              <Grid container={true} spacing={0} className="borderBottomBlue">
                <StyledTitleWrapper item={true} xs={10}>
                  <Grid
                    container={true}
                    spacing={0}
                    className="height"
                    onClick={() => onPress(auctionId, perId, auctionDate)}
                  >
                    <Grid item={true} xs={12}>
                      {parcelType === "Diamond" ? (
                        <div>
                          <StyledContDaimond>
                            {code}{" "}
                            <EllipsisText
                              text={getDiamondDescription()}
                              length={68}
                              tail={"..."}
                            />
                          </StyledContDaimond>
                        </div>
                      ) : (
                        <div style={{ fontSize: "16px" }}>
                          <StyledProductCon item spacing={0} container xs={12}>
                            <Grid xs={4} item className="marginRight padding">
                              {code}
                            </Grid>
                            <Grid
                              xs={4}
                              item
                              className="marginRight padding alignCenter"
                            >
                              {noOfPieces === 0
                                ? "-"
                                : `${noOfPieces} ${t("common:preview:pieces")}`}
                            </Grid>
                            <Grid xs={4} item className="padding alignRight">
                              {carat} {"Ct"}
                            </Grid>
                          </StyledProductCon>
                          <StyledDescription item xs={12} className="padding">
                            <EllipsisText
                              text={getProductDescription()}
                              length={68}
                              tail={"..."}
                            />
                          </StyledDescription>
                        </div>
                      )}
                    </Grid>
                    <StyledRemarks item={true} xs={12}>
                      {parcelType === "Diamond" && (
                        <div>
                          <StyledRemarkLbl>
                            {t("common:preview:perItemDescription:remarks") +
                              ":"}
                            {props.remarks !== "" &&
                            props.remarks !== undefined &&
                            props.remarks !== null ? (
                              <>
                                <EllipsisText
                                  text={remarks}
                                  length={25}
                                  tail={"..."}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </StyledRemarkLbl>
                        </div>
                      )}
                    </StyledRemarks>
                  </Grid>
                </StyledTitleWrapper>
                <Grid container xs={2}>
                  {watched === false || watched === undefined ? (
                    <StyledContainer item={true} xs={12}>
                      <div>
                        {isReadOnlyUser ? (
                          <StyledNotStartedContainer
                            item={true}
                            xs={12}
                            onClick={() =>
                              renderReadOnlyUserToast(sellerAuctionUserId)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faHeartProps}
                              className="unwatch imageColor"
                            />
                          </StyledNotStartedContainer>
                        ) : (
                          <>{renderWatchlistButton()}</>
                        )}
                      </div>
                    </StyledContainer>
                  ) : (
                    <StyledContainer item={true} xs={12}>
                      <div>
                        <StyledWishContainer
                          item={true}
                          xs={12}
                          onClick={() => {
                            removeProductFromList();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            className="watch"
                          />
                        </StyledWishContainer>
                      </div>
                    </StyledContainer>
                  )}
                  <StyledContainer item={true} xs={12}>
                    <StyledNotStartedContainer item={true} xs={12}>
                      <StyledCloseicon
                        onClick={() => onPress(auctionId, perId, auctionDate)}
                        src="/images/noteIcons/whiteNotes.png"
                      />
                    </StyledNotStartedContainer>
                  </StyledContainer>
                </Grid>
              </Grid>
              <Grid container={true} spacing={0}>
                <Grid container={true} spacing={0} className="borderBottomBlue">
                  <StyledWrapper
                    item={true}
                    xs={9}
                    onClick={() =>
                      sortingFuction("caratMinimumPrice", direction)
                    }
                  >
                    <StyledLabelWrapper className="secondaryLabel fontBold">
                      {t("common:preview:productsList:startingPrice")}(
                      {t("common:preview:productsList:pct")})
                    </StyledLabelWrapper>
                    <StyledBidAmount>
                      <span className="fontBold textWrap">
                        {caratMinPrice} {conversionCaratMinPrice}
                      </span>
                    </StyledBidAmount>
                  </StyledWrapper>
                  <Grid item={true} xs={3}>
                    <StyledRapOffLabelWrapper
                      className="secondaryLabelTwo fontBold"
                      onClick={() =>
                        sortingFuction("rapnetPercentage", direction)
                      }
                    >
                      {t("common:Tender:rapOff")}
                    </StyledRapOffLabelWrapper>
                    <span
                      className=" textWrap"
                      style={{ justifyContent: "center" }}
                    >
                      {rapnetPercentage === undefined || rapnetPercentage === 0
                        ? "-"
                        : rapnetPercentage}
                    </span>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={0}>
                  <Grid item={true} xs={3} className="borderRightBlue">
                    <StyledWrapper
                      onClick={() => sortingFuction("serialNo", direction)}
                    >
                      <StyledSerialNumberLabelWrapper>
                        {t("common:preview:productsList:serialNo")}
                      </StyledSerialNumberLabelWrapper>
                      <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                    </StyledWrapper>
                    <StyledWrapper>
                      <StyledSerialNumberLabelWrapper
                        style={{ color: "blue" }}
                        onClick={() => sortingFuction("lab", direction)}
                      >
                        {lab}
                      </StyledSerialNumberLabelWrapper>
                      <StyledValueConatiner
                        className="fontBold"
                        style={{ textAlign: "center", fontSize: "0.5em" }}
                      >
                        {lab !== "AGT" &&
                        lab !== "Agt" &&
                        lab !== "" &&
                        lab !== undefined ? (
                          <a
                            href="#/"
                            onClick={() =>
                              openCertificateLinkPopup(lab, certificate)
                            }
                            className="moveToLandingPageLink"
                          >
                            {certificate}
                          </a>
                        ) : (
                          <>
                            <span style={{ color: "black" }}>
                              {certificate}
                            </span>
                          </>
                        )}
                      </StyledValueConatiner>
                    </StyledWrapper>
                  </Grid>
                  {PlayerSlideMeMo}
                </Grid>
              </Grid>
            </StyledCardContainer>
          )}
        </Grid>
      ) : (
        <Grid>
          {perId !== undefined && (
            <StyledCardContainer
              id={serialNo.toString()}
              onContextMenu={(event: any) => rightClickEvent(event, perId)}
            >
              <Grid container={true} spacing={0} className="borderBottomBlue">
                <StyledTitleWrapper item={true} xs={10}>
                  <Grid container={true} spacing={0} className="height">
                    <Grid item={true} xs={2}>
                      {isReadOnlyUser ? (
                        <Checkbox
                          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
                          disabled
                        />
                      ) : (
                        <>
                          {isAuctionUserId ? (
                            <Checkbox
                              inputProps={{
                                "aria-label": "uncontrolled-checkbox",
                              }}
                              disabled
                            />
                          ) : (
                            renderCheckBox()
                          )}
                        </>
                      )}
                    </Grid>
                    <Grid
                      item={true}
                      xs={10}
                      onClick={() => onPress(auctionId, perId, auctionDate)}
                    >
                      {parcelType === "Diamond" ? (
                        <div>
                          <StyledContDaimond>
                            {code}{" "}
                            <EllipsisText
                              text={getDiamondDescription()}
                              length={68}
                              tail={"..."}
                            />
                          </StyledContDaimond>
                        </div>
                      ) : (
                        <div style={{ fontSize: "12px" }}>
                          <StyledProductCon item spacing={0} container xs={12}>
                            <Grid xs={4} item className="marginRight padding">
                              {code}
                            </Grid>
                            <Grid
                              xs={4}
                              item
                              className="marginRight padding alignCenter"
                            >
                              {noOfPieces === 0
                                ? "-"
                                : `${noOfPieces} ${t("common:preview:pieces")}`}
                            </Grid>
                            <Grid xs={4} item className="padding alignRight">
                              {carat} {"Ct"}
                            </Grid>
                          </StyledProductCon>
                          <StyledDescription item xs={12} className="padding">
                            <EllipsisText
                              text={getProductDescription()}
                              length={60}
                              tail={"..."}
                            />
                          </StyledDescription>
                        </div>
                      )}
                    </Grid>
                    <StyledRemarks item={true} xs={12}>
                      {parcelType === "Diamond" && renderRemarkSection()}
                    </StyledRemarks>
                  </Grid>
                </StyledTitleWrapper>
                <Grid container xs={2}>
                  {watched === false || watched === undefined ? (
                    <StyledContainer item={true} xs={12}>
                      <div>
                        {isReadOnlyUser ? (
                          <StyledNotStartedContainer
                            item={true}
                            xs={12}
                            onClick={() =>
                              renderReadOnlyUserToast(sellerAuctionUserId)
                            }
                          >
                            <FontAwesomeIcon
                              icon={faHeartProps}
                              className="unwatch imageColor"
                            />
                          </StyledNotStartedContainer>
                        ) : (
                          <>{renderWatchlistButton()}</>
                        )}
                      </div>
                    </StyledContainer>
                  ) : (
                    <StyledContainer item={true} xs={12}>
                      <div>
                        <StyledWishContainer
                          item={true}
                          xs={12}
                          onClick={() => {
                            removeProductFromList();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            className="watch"
                          />
                        </StyledWishContainer>
                      </div>
                    </StyledContainer>
                  )}
                  <StyledContainer item={true} xs={12}>
                    <StyledNotStartedContainer item={true} xs={12}>
                      <StyledCloseicon
                        onClick={() => onPress(auctionId, perId, auctionDate)}
                        src="/images/noteIcons/whiteNotes.png"
                      />
                    </StyledNotStartedContainer>
                  </StyledContainer>
                </Grid>
              </Grid>
              <Grid container={true} spacing={0}>
                <Grid container={true} spacing={0} className="borderBottomBlue">
                  <StyledWrapper
                    item={true}
                    xs={8}
                    onClick={() => sortingFuction("currentBidPrice", direction)}
                  >
                    <StyledLabelWrapper className="secondaryLabel fontBold">
                      {t("common:Tender:tableColumnNames:liveHighestBid")}
                    </StyledLabelWrapper>
                    <StyledBidAmount>
                      <span className="fontBold textWrap">
                        {convertedValue(
                          currency,
                          currentBidPrice,
                          exchangeRates[0]["rate"]
                        )}
                      </span>
                    </StyledBidAmount>
                  </StyledWrapper>
                  <Grid item={true} xs={4}>
                    <StyledRapOffLabelWrapper
                      className="secondaryLabelTwo fontBold"
                      onClick={() =>
                        sortingFuction("rapnetPercentage", direction)
                      }
                    >
                      {t("common:Tender:rapOff")}
                    </StyledRapOffLabelWrapper>
                    <span
                      className="textWrap"
                      style={{ justifyContent: "center" }}
                    >
                      {rapnetPercentage === undefined || rapnetPercentage === 0
                        ? "-"
                        : rapnetPercentage}
                    </span>
                  </Grid>
                </Grid>
                <Grid container={true} spacing={0} className="borderBottomBlue">
                  <Grid item={true} xs={3} className="borderRightBlue">
                    <StyledWrapper>
                      <StyledSerialNumberLabelWrapper
                        onClick={() => sortingFuction("serialNo", direction)}
                      >
                        {t("common:preview:productsList:serialNo")}
                      </StyledSerialNumberLabelWrapper>
                      <StyledValueConatiner>{serialNo}</StyledValueConatiner>
                    </StyledWrapper>
                    <StyledWrapper>
                      <StyledSerialNumberLabelWrapper
                        style={{ color: "blue" }}
                        onClick={() => sortingFuction("lab", direction)}
                      >
                        {lab}
                      </StyledSerialNumberLabelWrapper>
                      <StyledValueConatiner
                        className="fontBold"
                        style={{ textAlign: "center", fontSize: "0.5em" }}
                      >
                        {lab !== "AGT" &&
                        lab !== "Agt" &&
                        lab !== "" &&
                        lab !== undefined ? (
                          <a
                            href="#/"
                            onClick={() =>
                              openCertificateLinkPopup(lab, certificate)
                            }
                            className="moveToLandingPageLink"
                          >
                            {certificate}
                          </a>
                        ) : (
                          <>
                            <span style={{ color: "black" }}>
                              {certificate}
                            </span>
                          </>
                        )}
                      </StyledValueConatiner>
                    </StyledWrapper>
                  </Grid>
                  {PlayerSlideMeMo}
                </Grid>

                <StyledCurrentBid
                  className="display fontSize"
                  changeColor={
                    props.boxColor ? props.boxColor(items) : rowColor
                  }
                >
                  <Grid
                    container={true}
                    xs={12}
                    spacing={0}
                    style={{ padding: "8px" }}
                  >
                    <StyledfooterContaneir item={true} xs={9}>
                      <Grid
                        container={true}
                        spacing={0}
                        style={{ fontSize: "0.7em" }}
                      >
                        <Grid item={true} xs={12} style={{ display: "flex" }}>
                          <span
                            className="fontBold"
                            onClick={() =>
                              sortingFuction("caratMinimumPrice", direction)
                            }
                          >
                            Starting Price:
                          </span>
                          <span className="fontBold">
                            {caratMinPrice} {conversionCaratMinPrice}{" "}
                            {t("common:preview:productsList:pct")}
                          </span>
                        </Grid>
                        <Grid item={true} xs={12} style={{ display: "flex" }}>
                          <span className="fontBold textWrap">
                            {t("common:Tender:tableColumnNames:yourBid")}
                          </span>
                          <span className="fontBold textWrap">
                            {handelMyBidsLabel(
                              myLatestBidForProduct,
                              perId,
                              currency,
                              exchangeRates[0]["rate"]
                            )}
                          </span>
                        </Grid>
                      </Grid>
                    </StyledfooterContaneir>
                    <Grid item={true} xs={3} style={{ display: "grid" }}>
                      {isAuctionUserId ? (
                        <StyledButton
                          onClick={() =>
                            renderReadOnlyUserToast(sellerAuctionUserId)
                          }
                          style={{ background: "lightgray", color: "black" }}
                        >
                          {t("common:Tender:button:placeBid")}
                        </StyledButton>
                      ) : (
                        <>
                          {isReadOnlyUser ? (
                            <StyledButton
                              onClick={() =>
                                renderReadOnlyUserToast(sellerAuctionUserId)
                              }
                              style={{ background: "gray" }}
                            >
                              {t("common:Tender:button:placeBid")}
                            </StyledButton>
                          ) : (
                            <>{renderBidButton()}</>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </StyledCurrentBid>
              </Grid>
            </StyledCardContainer>
          )}
        </Grid>
      )}
    </>
  );
};

export default TenderMobileUIMybids;
