import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledMyBidButton, StyledMyBidButtons, StyledMyBidsButton } from "../../../products/TendorDetailsViewNew";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";

interface IProps extends IRouterComponentProps {
  auctionId: string;
  perId: string;
  serialNo: string;
  sellerAuctionUserId: any;
  annualProductState: any;
  bidNowRedirection: () => void;
  isReadOnlyUser: boolean;
  isJewellery: boolean;
}

const AnnualBidNowButton = (props: IProps) => {
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [isbidNowButtonActive, setIsbidNowButtonActive] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const {
    sellerAuctionUserId,
    bidNowRedirection,
    annualProductState,
    isReadOnlyUser,
    isJewellery,
  } = props;

  const { t } = useTranslation();
  useEffect(() => {
    var joinedAuctions: any = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any) => {
        if (isJewellery){
          if (items.category === "Jewellery") {
            if (sellerAuctionUserId === items.auctionUserId) {
              setIsAuctionUserId(true);
            }
          }
        }else{
        if (items.category === "Diamonds") {
          if (sellerAuctionUserId === items.auctionUserId) {
            setIsAuctionUserId(true);
          }
        }
      }
      });
    } else {
      setIsAuctionUserId(false);
    }
    setTimeout(() => {
      setIsbidNowButtonActive(true);
    }, 3000);
    return () => setIsAuctionUserId(false);
  }, []);

  const renderDisableBidNowButton = () => {
    if (isReadOnlyUser) {
      return (
        <StyledMyBidButtons
          style={{ background: "lightgray" }}
          onClick={() => renderReadOnlyUserToast()}
        >
          {t("common:inquiry:button:bidNow")}
        </StyledMyBidButtons>
      );
    } else {
      if (isAuctionUserId) {
        return (
          <StyledMyBidButtons
            style={{ background: "lightgray" }}
            onClick={() => renderReadOnlyUserToast()}
          >
            {t("common:inquiry:button:bidNow")}
          </StyledMyBidButtons>
        );
      } else {
        return (
          <StyledMyBidButton
            onClick={() => (isbidNowButtonActive ? bidNowRedirection() : "")}
          >
            {t("common:inquiry:button:bidNow")}
          </StyledMyBidButton>
        );
      }
    }
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  return (
    <>
      {annualProductState === "Started" ||
      annualProductState === "" ||
      annualProductState === "undefined" ||
      annualProductState === null ? (
        <StyledMyBidsButton>{renderDisableBidNowButton()}</StyledMyBidsButton>
      ) : (
        <StyledMyBidsButton>
          <StyledMyBidButtons style={{ background: "lightgray" }}>
            {t("common:inquiry:button:bidNow")}
          </StyledMyBidButtons>
        </StyledMyBidsButton>
      )}
      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
    </>
  );
};

export default AnnualBidNowButton;
