import React from 'react';
import styled from 'styled-components';

const StyledCountContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;

const StyledItemsCount = styled.div`
    font-size: 1em;
    color: ${(props) => props.theme.colors.base.grey};
    font-weight: bold;
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        font-size: 0.8em;
    }
`;

const showResult = (totalRecords: number, pageSize: number, currentPage: number): string => {
    let showInfo;
    let firstVal;
    let secondVal = pageSize * currentPage;
    
    if (currentPage === 1 && totalRecords === 0) {
        firstVal = 0;
    } else if (currentPage === 1) {
        firstVal = currentPage;
    } else {
        let count = currentPage - 1;
        firstVal = count * pageSize + 1;
    }

    showInfo = (secondVal < totalRecords) ? `${firstVal} - ${secondVal}` : `${firstVal} - ${totalRecords}`;
    return showInfo;
};

export interface IProps {
    t: any;
    itemsPerPage: number;
    totalRecords: number;
    currentPage: number;
    currentRecords: any;
}

const ItemsCount = (props: IProps) => {
    const { t, itemsPerPage, totalRecords, currentPage } = props;
    const visibleRecords = showResult(totalRecords, itemsPerPage, currentPage);

    return (
        <StyledCountContainer>
            <StyledItemsCount>{t('common:preview:pagination:showing')} {`${visibleRecords}`} {t('common:preview:pagination:of')} {`${totalRecords}`} {t('common:preview:pagination:results')}</StyledItemsCount>
        </StyledCountContainer>
    )
};

export default ItemsCount;