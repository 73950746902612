import React from "react";

const Logo = ({ width = "40", height = "30" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 59 38.469"
      style={{ cursor: "pointer" }}
    >
      <path
        id="Path_158"
        data-name="Path 158"
        d="M494.107,380.576l-8.742-10.3a1.819,1.819,0,0,0-1.387-.642H461.507a1.819,1.819,0,0,0-1.387.642l-8.742,10.3a1.82,1.82,0,0,0-.023,2.327l11.339,13.92-5.357,6.575-17.612-21.621,7.219-8.508h5.876l3.087-3.639h-9.8a1.819,1.819,0,0,0-1.387.642l-8.742,10.3a1.821,1.821,0,0,0-.024,2.327l19.978,24.525a1.819,1.819,0,0,0,2.821,0l6.3-7.729,0,0,2.3-2.828,0,0L478.725,382.9a1.82,1.82,0,0,0-.023-2.327l-6.2-7.308h10.634l7.219,8.508L472.742,403.4l-3-3.687-.009-.01-2.347,2.881h0l3.947,4.846a1.82,1.82,0,0,0,2.822,0L494.131,382.9A1.821,1.821,0,0,0,494.107,380.576Zm-19.159,1.2-9.909,12.165-9.909-12.165,7.219-8.508h5.381Z"
        transform="translate(-435.539 -369.63)"
        fill="#293139"
      />
    </svg>
  );
};

export default Logo;
