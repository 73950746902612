import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";
import {
  productUrl,
  upcomingProducts,
} from "../../../common/config/app.endpoints";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";
import ItemsCarousel from "react-items-carousel";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { match } from "react-router-dom";
import moment from "moment";

const StyledWrapperID = styled(Grid)``;

const StyledItemDesc = styled(Grid)`
  align-items: center;
`;
const StyledProductName = styled.div`
  color: #2660ad;
  font-size: 1em;
  font-weight: bold;
  border-bottom: 1px solid #2660ad;
  max-width: 20px;
  margin: 5px;
  &:hover {
    cursor: pointer;
  }
  @media (width: 540px) {
    font-size: 0.8em;
  }
  @media (width: 375px) {
    font-size: 0.8em;
  }
  @media (width: 1024px) {
    font-size: 1em;
  }
`;

const StyledProductbutton = styled.span`
  color: ${(props) => props.theme.colors.base.black};
  &.rightIcon {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    display: inline-block;
    margin-left: 0px;
    margin-right: 5px;
  }
`;
const StyledMaindiv = styled.div`
  width: 50%;
  ${(props) => props.theme.breakpoints.down("xs")} {
    width: 60%;
  }
  @media (width: 320px) {
    width: 170px;
  }
  @media (width: 360px) {
    width: 170px;
  }
  @media (width: 540px) {
    width: 60%;
  }
  @media (width: 280px) {
    width: 210px;
  }
`;

const PrevButton = () => {
  return (
    <StyledProductbutton>
      <KeyboardArrowLeftIcon />
    </StyledProductbutton>
  );
};
const NextButton = () => {
  return (
    <StyledProductbutton>
      <ChevronRightIcon />
    </StyledProductbutton>
  );
};

interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: any;
}

export interface IProps extends IRouterComponentProps {
  t?: any;
  match: match<DetailParams>;
  location: any;
  productIds: any;
  productId: any;
  auctionDetail: any;
  serialNo: any;
  parcelType: string;
}

let perId: string = "";
let items: any = [];

const NextProduct = (props: IProps) => {
  let { productIds, serialNo, parcelType } = props;
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [item, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [changeColor, setChangeColor] = useState("");
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const chevronWidth = 20;
  let numberOfCardsCount = 4;
  const Auth = new AuthService();

  let date: any = "";
  let timezone: any = "";

  useEffect(() => {
    getData();

    return () => setIsLoading(false);
  }, []);

  const getData = () => {
    getUpcomingProducts();
  };

  const getUpcomingProducts = () => {
    Auth.getRequest(
      `${upcomingProducts}/${props.match.params.auctionId}/upcomingProducts`,
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            setAuctionProducts(response.results);
          } else {
            setIsLoading(false);
          }
        } else if (status === 403) {
          setUserBlock(true);
        } else if (status === 505) {
          setApiTimeout(true);
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  const getDetails = (perId: string | number) => {
    Auth.getRequest(
      `${productUrl}/${perId}/detail`,
      (status: number, response: any) => {
        if (status === 200) {
          items = response;
          setItems(response);
          setIsLoading(false);
        }
      }
    );
  };

  if (timezone !== null) {
    timezone = localStorage.getItem("timezoneName");
  }
  let auctionDate = props.match.params.auctionDate;
  let previewTime = moment.utc(auctionDate);
  if (previewTime !== null || previewTime !== undefined) {
    let result = moment.tz(previewTime, timezone);
    let resultFormatter = result.format();
    if (resultFormatter !== "Invalid date") {
      let splitDateTime = resultFormatter.split("T", 2);
      date = splitDateTime[0];
    }
  }

  const onPress = (auctionId: string, perId: string, date: string) => {
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    getDetails(perId);
    
    if (parcelType === "Jewellery") {
      window.open(
        `/${languageCode}/preview/jewelleryPreview/details/${auctionId}/${perId}/${date}`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/details/${auctionId}/${perId}/${date}`,
        "_blank"
      );
    }
  };

  if (auctionProducts !== null) {
    auctionProducts.map((item: any, index: number) => {
      if (item.perId < productIds) {
        auctionProducts.splice(item, 1);
        setChangeColor("");
      }
    });
  }

  return (
    <StyledMaindiv style={{ padding: `0 ${chevronWidth}px` }}>
      {auctionProducts.length > 0 ? (
        <StyledWrapperID>
          <StyledItemDesc>
            <ItemsCarousel
              requestToChangeActive={setActiveItemIndex}
              activeItemIndex={activeItemIndex}
              numberOfCards={serialNo > 100 ? 2 : numberOfCardsCount}
              leftChevron={PrevButton()}
              rightChevron={NextButton()}
              chevronWidth={chevronWidth}
              infiniteLoop={false}
              outsideChevron
            >
              {auctionProducts.map((item: any, index: number) => {
                return (
                  <>
                    {item.serialNo === serialNo ? (
                      <StyledProductName
                        style={{ color: "green" }}
                        key={index}
                        onClick={() =>
                          onPress(
                            props.match.params.auctionId,
                            item.perId,
                            date
                          )
                        }
                      >
                        {item.serialNo}
                      </StyledProductName>
                    ) : (
                      <StyledProductName
                        key={index}
                        onClick={() =>
                          onPress(
                            props.match.params.auctionId,
                            item.perId,
                            date
                          )
                        }
                      >
                        {item.serialNo}
                      </StyledProductName>
                    )}
                  </>
                );
              })}
            </ItemsCarousel>
          </StyledItemDesc>
        </StyledWrapperID>
      ) : (
        <> </>
      )}
    </StyledMaindiv>
  );
};

export default NextProduct;
