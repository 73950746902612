import { faRubleSign } from "@fortawesome/free-solid-svg-icons";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";

const initialState = {
  loading: false,
  error: "",
  itemPerPage: 10,
  mypageItemPerPage: 50,
  mysellpageItemPerPage: 50,
  currentApiStartCount: 0,
  jewelleryItemPerPage: 10,
  jewelleryCurrentApiStartCount: 0,
  annualitemPerPage: 20,
  annualcurrentApiStartCount: 0,
  annualjewelleryItemPerPage: 20,
  annualjewelleryCurrentApiStartCount: 0,
  mybidsItemPerPage: 10,
  mybidsStartCount: 0,
  annualMybidsItemPerPage: 20,
  annualMybidsStartCount: 0,
  isMobileDevice: false,
  changePlanEmail: "",
  isMybidstab: false,
  jwlIsMybidstab: false,
  liveItemPerPage: 20,
  liveCurrentApiStartCount: 0,
  filterSort: "",
  participateProductId: "",
  selectedDate: null,
  selectedProductIndexs: [],
  selectedProductIds: [],
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setselectedDate(state: any, action: PayloadAction<{ date: string }>) {
      state.selectedDate = action.payload.date;
    },
    resetSelectedDate(state: any, action: PayloadAction) {
      state.selectedDate = null;
    },
    setParticipateProductId(state: any, action: PayloadAction<{ id: string }>) {
      state.participateProductId = action.payload.id;
    },
    resetParticipateProductId(state: any, action: PayloadAction) {
      state.participateProductId = "";
    },
    setFilterSort(state: any, action: PayloadAction<{ filterSort: string }>) {
      state.filterSort = action.payload.filterSort;
    },
    resetFilterSort(state: any, action: PayloadAction) {
      state.filterSort = "";
    },
    // set and reset Participate Product code
    setLiveAuctionPagination(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.liveItemPerPage = action.payload.itemsPerPage;
    },
    reSetLiveAuctionPagination(state: any, action: PayloadAction) {
      state.liveItemPerPage = 20;
    },
    setLiveCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.liveCurrentApiStartCount = action.payload.currentPage;
    },
    reSetLiveCurrentApiStartCount(state: any, action: PayloadAction) {
      state.liveCurrentApiStartCount = 0;
    },
    //live auction
    setChangePlanEmail(state: any, action: PayloadAction<{ email: boolean }>) {
      state.changePlanEmail = action.payload.email;
    },
    setDevice(state: any, action: PayloadAction<{ isMobile: boolean }>) {
      state.isMobileDevice = action.payload.isMobile;
    },
    setItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.itemPerPage = action.payload.itemsPerPage;
    },
    setCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.currentApiStartCount = action.payload.currentPage;
    },
    setJewelleryItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.jewelleryItemPerPage = action.payload.itemsPerPage;
    },
    setJewelleryCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.jewelleryCurrentApiStartCount = action.payload.currentPage;
    },

    setAnnualItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.annualitemPerPage = action.payload.itemsPerPage;
    },
    setAnnualCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.annualcurrentApiStartCount = action.payload.currentPage;
    },
    setAnnualJewelleryItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.annualjewelleryItemPerPage = action.payload.itemsPerPage;
    },
    setAnnualJewelleryCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.annualjewelleryCurrentApiStartCount = action.payload.currentPage;
    },

    //mybids
    setMybidsItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.mybidsItemPerPage = action.payload.itemsPerPage;
    },
    setMybidsCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.mybidsStartCount = action.payload.currentPage;
    },
    setAnnualMybidsItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.annualMybidsItemPerPage = action.payload.itemsPerPage;
    },
    setAnnualMybidsCurrentApiStartCount(
      state: any,
      action: PayloadAction<{ currentPage: number }>
    ) {
      state.annualMybidsStartCount = action.payload.currentPage;
    },

    resetChangePlanEmail(state: any, action: PayloadAction) {
      state.changePlanEmail = "";
    },
    setIsMybids(state: any, action: PayloadAction<{ isMybidstab: boolean }>) {
      state.isMybidstab = action.payload.isMybidstab;
    },
    reSetIsMybids(state: any, action: PayloadAction) {
      state.isMybidstab = false;
    },
    setJwlIsMybids(
      state: any,
      action: PayloadAction<{ isMybidstab: boolean }>
    ) {
      state.jwlIsMybidstab = action.payload.isMybidstab;
    },
    reSetJwlIsMybids(state: any, action: PayloadAction) {
      state.jwlIsMybidstab = false;
    },

    reSetDevice(state: any, action: PayloadAction) {
      state.isMobileDevice = false;
    },
    //reset
    resetAnnualItemPerPage(state: any, action: PayloadAction) {
      state.annualitemPerPage = 20;
    },
    resetAnnualCurrentApiStartCount(state: any, action: PayloadAction) {
      state.annualcurrentApiStartCount = 0;
    },
    resetAnnualJewelleryItemPerPage(state: any, action: PayloadAction) {
      state.annualjewelleryItemPerPage = 20;
    },
    resetAnnualJewelleryCurrentApiStartCount(
      state: any,
      action: PayloadAction
    ) {
      state.annualjewelleryCurrentApiStartCount = 0;
    },

    resetItemPerPage(state: any, action: PayloadAction) {
      state.itemPerPage = 10;
    },
    resetCurrentApiStartCount(state: any, action: PayloadAction) {
      state.currentApiStartCount = 0;
    },
    resetJewelleryItemPerPage(state: any, action: PayloadAction) {
      state.jewelleryItemPerPage = 10;
    },
    resetJewelleryCurrentApiStartCount(state: any, action: PayloadAction) {
      state.jewelleryCurrentApiStartCount = 0;
    },

    //my bids
    resetmybidsItemPerPage(state: any, action: PayloadAction) {
      state.itemPerPage = 10;
    },
    resetMybidsCurrentApiStartCount(state: any, action: PayloadAction) {
      state.currentApiStartCount = 0;
    },
    resetAnnualMybidsItemPerPage(state: any, action: PayloadAction) {
      state.jewelleryItemPerPage = 20;
    },
    resetAnnualMybidsCurrentApiStartCount(state: any, action: PayloadAction) {
      state.jewelleryCurrentApiStartCount = 0;
    },
    setMyPageItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.mypageItemPerPage = action.payload.itemsPerPage;
    },
    resetmypageItemPerPage(state: any, action: PayloadAction) {
      state.mypageItemPerPage = 50;
    },
    setMySellPageItemPerPage(
      state: any,
      action: PayloadAction<{ itemsPerPage: number }>
    ) {
      state.mysellpageItemPerPage = action.payload.itemsPerPage;
    },
    resetmySellpageItemPerPage(state: any, action: PayloadAction) {
      state.mysellpageItemPerPage = 50;
    },
    setProductIndexs(state: any, action: PayloadAction<{ index: number }>) {
      state.selectedProductIndexs = action.payload.index;
    },
    resetProductIndexs(state: any, action: PayloadAction) {
      state.selectedProductIndexs = [];
    },
    setProductIds(state: any, action: PayloadAction<{ index: number }>) {
      state.selectedProductIds = action.payload.index;
    },
    resetProductIds(state: any, action: PayloadAction) {
      state.selectedProductIds = [];
    },
  },
  extraReducers: (builder: any) => { },
});

export const {
  setItemPerPage,
  setCurrentApiStartCount,
  resetItemPerPage,
  resetCurrentApiStartCount,
  setJewelleryItemPerPage,
  setJewelleryCurrentApiStartCount,
  resetJewelleryItemPerPage,
  resetJewelleryCurrentApiStartCount,
  reSetIsMybids,
  setIsMybids,
  reSetJwlIsMybids,
  setJwlIsMybids,
  //annual
  setAnnualItemPerPage,
  setAnnualCurrentApiStartCount,
  resetAnnualItemPerPage,
  resetAnnualCurrentApiStartCount,
  setAnnualJewelleryItemPerPage,
  setAnnualJewelleryCurrentApiStartCount,
  resetAnnualJewelleryItemPerPage,
  resetAnnualJewelleryCurrentApiStartCount,

  // mybids
  setMybidsItemPerPage,
  setMybidsCurrentApiStartCount,
  setAnnualMybidsItemPerPage,
  setAnnualMybidsCurrentApiStartCount,
  setDevice,
  reSetDevice,
  resetmybidsItemPerPage,
  resetMybidsCurrentApiStartCount,
  resetAnnualMybidsItemPerPage,
  resetAnnualMybidsCurrentApiStartCount,
  resetChangePlanEmail,
  setChangePlanEmail,

  setLiveAuctionPagination,
  reSetLiveAuctionPagination,
  setLiveCurrentApiStartCount,
  reSetLiveCurrentApiStartCount,
  setParticipateProductId,
  resetParticipateProductId,
  setselectedDate,
  resetSelectedDate,
  setMyPageItemPerPage,
  resetmypageItemPerPage,
  setMySellPageItemPerPage,
  resetmySellpageItemPerPage,
  setProductIds,
  resetProductIds,
} = paginationSlice.actions;

export default paginationSlice.reducer;

// selectors
export const selectpaginationSliceLoading = (state: RootState) =>
  state.paginationSlice.loading;
export const selectItemPerPage = (state: RootState) =>
  state.paginationSlice.itemPerPage;


export const selectcurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.currentApiStartCount;

export const selectJewelleryItemPerPage = (state: RootState) =>
  state.paginationSlice.jewelleryItemPerPage;
export const selectJewelleryCurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.jewelleryCurrentApiStartCount;


export const selectAnnualpaginationSliceLoading = (state: RootState) =>
  state.paginationSlice.loading;

export const selectAnnualItemPerPage = (state: RootState) =>
  state.paginationSlice.annualitemPerPage;
export const selectAnnualcurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.annualcurrentApiStartCount;

export const selectAnnualJewelleryItemPerPage = (state: RootState) =>
  state.paginationSlice.annualjewelleryItemPerPage;
export const selectAnnualJewelleryCurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.annualjewelleryCurrentApiStartCount;

export const selectMybidsItemPerPage = (state: RootState) =>
  state.paginationSlice.mybidsItemPerPage;
export const selectMybidscurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.mybidsStartCount;

export const selectAnnualMyBidsItemPerPage = (state: RootState) =>
  state.paginationSlice.annualMybidsItemPerPage;
export const selectAnnualMyBidsCurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.annualMybidsStartCount;

export const selectisMobileDevice = (state: RootState) =>
  state.paginationSlice.isMobileDevice;

export const selectChangePlanEmail = (state: RootState) =>
  state.paginationSlice.changePlanEmail;
export const isMyBidstabSelected = (state: RootState) =>
  state.paginationSlice.isMybidstab;
export const JwlIsMyBidstabSelected = (state: RootState) =>
  state.paginationSlice.jwlIsMybidstab;

export const selectLiveItemPerPage = (state: RootState) =>
  state.paginationSlice.liveItemPerPage;

export const selecLiveCurrentApiStartCount = (state: RootState) =>
  state.paginationSlice.liveCurrentApiStartCount;

export const selecFilterSort = (state: RootState) => state.paginationSlice.filterSort;
export const selectedParticipateId = (state: RootState) => state.paginationSlice.participateProductId;

export const selectedDateValue = (state: RootState) => state.paginationSlice.selectedDate;
export const selectMypageItemPerPage = (state: RootState) =>
  state.paginationSlice.mypageItemPerPage;

export const selectMySellpageItemPerPage = (state: RootState) =>
  state.paginationSlice.mysellpageItemPerPage;

export const selectedProductIdx = (state: RootState) =>
  state.paginationSlice.selectedProductIndexs;

export const selectedmyProductids = (state: RootState) =>
  state.paginationSlice.selectedProductIds;