import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../common/materials/colors";

export const StyledTestimonial = styled(Grid) <{ isMobile?: boolean }>`
  background-color: ${Colors.lightGrey};
  margin-bottom: ${(props) => (props.isMobile ? "0" : "4em")};
  height: ${(props) => (props.isMobile ? "100%" : "auto")};
`;

export const StyledTextContainer = styled(Grid) <{ isMobile?: boolean }>`
  padding: ${(props) => (props.isMobile ? "2em" : "0 4em")};
  position: relative;
`;

export const StyledQuote = styled.span<{ isMobile?: boolean }>`
  position: absolute;
  font-size: ${(props) => (props.isMobile ? "2em" : "10em")};
  left: 4%;
  font-family: "Playfair Display";
  top: -10%;
`;

export const StyledPaper = styled.div`
  padding: 1em;
  display: flex;
  border-radius: 2em;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
  &:hover {
    box-shadow: 1px 3px 5px #ddd;
  }
`;

export const StyledArrow = styled.div`
  position: absolute;
  top: 0;
  right: 1em;
`;
export const StyledMediaText = styled.div`
  position: relative;
  display: flex;
  padding-left: 1em;
  justify-content: end;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledTeamContainer = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

export const StyledTeamPaper = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 50%;
`;

export const StyledTeamCircularContainer = styled.div`
  width: 192px;
  border-radius: 50%;
  overflow: hidden;
  @media (width: 1280px) {
    width: 160px;
  }
  @media (width: 1368px) {
    width: 150px;
  }
`;

export const StyledMobTeamPaper = styled.div`
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 50%;
`;

export const StyledMobTeamCircularContainer = styled.div`
  width: 120px;
  border-radius: 50%;
  overflow: hidden;  
`;

export const StyledCircularImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const StyledLabel = styled.div`
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
`;

export const StyledSubLabel = styled.div`
  text-align: center;
  font-size: 1em;
  font-weight: 500;
`;