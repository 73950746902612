import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #000f7e;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  list-style: none;
  text-decoration: none;
  font-size: 20px;
  height: 40px;
  &:hover {
    color: lightgrey !important;
    cursor: pointer;
  }
  ::selection {
    color: lightgrey !important;
    text-decoration: underline;
  }
`;

const SidebarLabel = styled.span``;

const DropdownLink = styled(Link)`
  // height: 40px;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: 000f7e;
  font-size: 16px !important;
  &:hover {
    color: lightgrey !important;
    cursor: pointer;
  }
  ::selection {
    color: lightgrey !important;
    text-decoration: underline;
  }
`;

var userType: string | null = "";
var isProfileComplete: string | null = "";

const MobileMenus = (props: any) => {
  let { item } = props;
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => setSubnav(!subnav);

  userType = localStorage.getItem("isNormalUser");
  isProfileComplete = localStorage.getItem("profileComplete");

  const clearFilterStorage = () => {
    localStorage.removeItem("filteredValues");
    localStorage.removeItem("filteredParcelValues");
    localStorage.removeItem("currentStart");
    localStorage.removeItem("itemsPerPage");
  };

  let history = useHistory();

  let { t } = useTranslation();

  const renderIconAndText = () => {
    if (item.isAuctiontab === true) {
      return (
        <div onClick={clearFilterStorage}>
          <SidebarLabel>{t(item.title)}</SidebarLabel>
        </div>
      );
    } else {
      return <SidebarLabel>{t(item.title)}</SidebarLabel>;
    }
  };
  if (!item) {
    return <></>
  }

  return (
    <>
      {userType === "normal" ? (
        <SidebarLink
          to={item?.path}
          onClick={item.subNav && showSubnav}
          className={
            history.location.pathname.includes(item.path) ? "is-active" : ""
          }
        >
          {renderIconAndText()}
        </SidebarLink>
      ) : isProfileComplete === "true" ? (
        <SidebarLink
          to={item.path}
          onClick={item.subNav && showSubnav}
          className={
            history.location.pathname.includes(item.path) ? "is-active" : ""
          }
        >
          {renderIconAndText()}
        </SidebarLink>
      ) : (
        <div>
          {item.isAuctiontab ? (
            <>
              {renderIconAndText()}
              {t("common:menu:auction")}
            </>
          ) : (
            <SidebarLink
              to={item.path}
              onClick={item.subNav && showSubnav}
              className={
                history.location.pathname.includes(item.path) ? "is-active" : ""
              }
            >
              {renderIconAndText()}
            </SidebarLink>
          )}
        </div>
      )}

      {subnav &&
        item.subNav.map((item: any, index: any) => {
          return (
            <DropdownLink
              to={item.path}
              key={index}
              className={
                history.location.pathname.includes(item.path) ? "is-active" : ""
              }
            >
              <SidebarLabel>{t(item.title)}</SidebarLabel>
            </DropdownLink>
          );
        })}
    </>
  );
};

export default MobileMenus;
