import React, { Component, ChangeEvent } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  downloadandChangeShippingAddressInvoiceUrl,
  getCity,
  getCountry,
  getState,
  shippingAddressUrl,
} from "../../../../common/config/app.endpoints";
import {
  StyledTitle,
  StyledTitleText,
  StyledShipingTextInput,
  StyledComponent,
} from "../../../../common/materials/GenericStyleComponent";
import {
  buttonGradient,
  dateBackgroundGradient,
} from "../../../../common/materials/LinearGradient";
import IRouterComponentProps from "../../../../models/IRouterComponentProps";
import { oAuth } from "../../../../routes/basic/BasicAppRouter";
import AuthService from "../../../auth/AuthService";

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  outline: none;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
    overflow-y: scroll;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
    overflow-y: scroll;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 500px;
  }
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 14px;
  margin: 15px 0px;
  margin-left: 1.2em;
`;

interface IButtonProps {
  disableButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) =>
    props.disableButton &&
    css`
      cursor: default;
      background: ${dateBackgroundGradient};
    `}
`;

interface IProps extends IRouterComponentProps {
  t?: any;
  getUpdateData: () => void;
  closeModal: () => void;
  apiTimeout: (timeout: boolean) => void;
  auctionId: string;
}

interface IState {
  blockNo: string;
  streetName: string;
  city: string;
  state: string;
  country: string;
  company: string;
  message: string;
  disableButton: boolean;
  countryList: any;
  selectedCountry: any;
  stateList: any;
  selectedState: any;
  cityList: any;
  selectedCity: any;
  selectedStateId: any;
  selectedcityId: any;
  pinCode: any;
  phoneNo: any;
  address: any;
}

export default class AddMoreForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      blockNo: "",
      streetName: "",
      city: "",
      state: "",
      country: "",
      company: "",
      message: "",
      disableButton: false,
      countryList: [],
      selectedCountry: "",
      stateList: [],
      selectedState: "",
      cityList: [],
      selectedCity: "",
      selectedStateId: "",
      selectedcityId: "",
      pinCode: "",
      phoneNo: "",
      address: "",
    };

    this.Auth.getRequestWithoutAuthorization(
      `${getCountry}`,
      (status: number, response: any) => {
        if (status === 200) {
          this.setState({
            countryList: response,
          });
        }
      }
    );
  }

  Auth = new AuthService();

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "blockNo") {
      this.setState({ blockNo: targeValue });
    }
    if (targeName === "streetName") {
      this.setState({ streetName: targeValue });
    }
    if (targeName === "city") {
      this.setState({ city: targeValue });
    }
    if (targeName === "state") {
      this.setState({ state: targeValue });
    }
    if (targeName === "country") {
      this.setState({ country: targeValue });
    }
    if (targeName === "company") {
      this.setState({ company: targeValue });
    }
    if (targeName === "pinCode") {
      this.setState({ pinCode: targeValue });
    }
    if (targeName === "phoneNo") {
      this.setState({ phoneNo: targeValue });
    }
    if (targeName === "address") {
      this.setState({ address: targeValue });
    }
  };
  handleChangeDropDown = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    let val = this.state.countryList[targeValue].name;

    if (targeName === "country") {
      this.setState({
        country: val,
        selectedCountry: this.state.countryList[targeValue],
        selectedStateId: this.state.countryList[targeValue].perId,
      });
      this.getStateId(this.state.countryList[targeValue].perId);
    }
    if (targeName === "state") {
      val = this.state.stateList[targeValue].name;
      this.setState({
        state: val,
        selectedState: this.state.stateList[targeValue],
        selectedcityId: this.state.stateList[targeValue].perId,
      });
      this.getCityId(this.state.stateList[targeValue].perId);
    }
    if (targeName === "city") {
      val = this.state.cityList[targeValue].name;
      this.setState({
        city: val,
        selectedCity: this.state.cityList[targeValue],
      });
    }
  };

  getStateId = (stateId: any) => {
    this.Auth.getRequestWithoutAuthorization(
      `${getState}?id=${stateId}`,
      (status: number, response: any) => {
        if (status === 200) {
          this.setState({
            stateList: response,
          });
        }
      }
    );
  };

  getCityId = (cityId: any) => {
    this.Auth.getRequestWithoutAuthorization(
      `${getCity}?id=${cityId}`,
      (status: number, response: any) => {
        if (status === 200) {
          this.setState({
            cityList: response,
          });
        }
      }
    );
  };

  handleFormSubmit(e: any) {
    const { t } = this.props;
    e.preventDefault();
    this.setState({
      disableButton: true,
    });
    var blockNo = this.state.blockNo.trim();
    var streetName = this.state.streetName.trim();
    var city = this.state.city.trim();
    var state = this.state.state.trim();
    var country = this.state.country.trim();
    var company = this.state.company.trim();
    var address = this.state.address.trim();
    var pincode = this.state.pinCode.trim();
    var phoneno = this.state.phoneNo.trim();

    if (
      blockNo === "" &&
      streetName === "" &&
      city === "" &&
      state === "" &&
      country === ""
    ) {
      this.setState({
        message: `${t("common:allValidations:emptyFields")}`,
        disableButton: false,
      });
    } else if (address === "") {
      this.setState({
        message: `${t("common:allValidations:shipping:address")}`,
        disableButton: false,
      });
    } else if (country === "") {
      this.setState({
        message: `${t("common:allValidations:shipping:country")}`,
        disableButton: false,
      });
    } else if (company === "") {
      this.setState({
        message: `${t("common:allValidations:shipping:company")}`,
        disableButton: false,
      });
    } else {
      const formData = {
        companyName: company,
        cityDetail: this.state.city !== "" ? this.state.selectedCity : null,
        stateDetail: this.state.state !== "" ? this.state.selectedState : null,
        countryDetail: this.state.selectedCountry,
        address: address,
        pincode: pincode,
        phone: phoneno,
      };
      if (!oAuth()) {
        this.props.history.push("/");
      } else {
        this.Auth.postRequest(
          `${downloadandChangeShippingAddressInvoiceUrl}/${this.props.auctionId}/shippingAddress`,
          formData,
          (status: number, response: any) => {
            if (status === 200) {
              this.props.getUpdateData();
              this.props.closeModal();
            } else if (status === 505) {
              this.props.apiTimeout(true);
            } else {
              this.setState({
                message: response[0].message,
              });
            }
          }
        );
      }
    }
  }

  render() {
    const { t } = this.props;
    return (
      <StyledComponent tabIndex={-1}>
        <StyledContainerWrapper>
          <form
            name="shippingAddress"
            noValidate={true}
            onSubmit={this.handleFormSubmit.bind(this)}
            style={{
              width: "100%",
              borderRadius: "10px",
              border: "1px solid #2660AD",
            }}
          >
            <Grid item={true} xs={12} md={12}>
              <StyledTitle className="fontBold">
                <StyledTitleText>
                  {t("common:profile:shippingAddress:title")}
                </StyledTitleText>
              </StyledTitle>
            </Grid>
            <Grid item={true} xs={12}>
              <StyledMessage>{this.state.message}</StyledMessage>
            </Grid>
            <StyledShipingTextInput>
              <TextField
                required
                fullWidth
                id="company"
                type="text"
                label={t("common:profile:shippingAddress:labels:company")}
                variant="outlined"
                name="company"
                onChange={this.handleChange}
              />
            </StyledShipingTextInput>

            <StyledShipingTextInput>
              <TextField
                required
                fullWidth
                id="address"
                type="text"
                label={t("common:profile:shippingAddress:labels:address")}
                variant="outlined"
                name="address"
                onChange={this.handleChange}
              />
            </StyledShipingTextInput>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12} md={6}>
                <StyledShipingTextInput>
                  <FormControl variant="outlined" className="" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("common:register:labels:country")}
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      required
                      id="country"
                      name="country"
                      onChange={this.handleChangeDropDown}
                      label={t("common:register:labels:country")}
                    >
                      {Object.keys(this.state.countryList).map((key) => (
                        <MenuItem value={key}>
                          {this.state.countryList[key].name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledShipingTextInput>
              </Grid>

              <Grid item={true} xs={12} md={6}>
                <StyledShipingTextInput>
                  <FormControl variant="outlined" className="" fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("common:register:labels:state")}
                    </InputLabel>
                    <Select
                      id="state"
                      name="state"
                      onChange={this.handleChangeDropDown}
                      label={t("common:register:labels:state")}
                    >
                      {Object.keys(this.state.stateList).map((key) => (
                        <MenuItem value={key}>
                          {this.state.stateList[key].name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </StyledShipingTextInput>
              </Grid>
            </Grid>
            <StyledShipingTextInput>
              <FormControl variant="outlined" className="" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("common:register:labels:city")}
                </InputLabel>
                <Select
                  id="city"
                  name="city"
                  onChange={this.handleChangeDropDown}
                  label={t("common:register:labels:city")}
                >
                  {Object.keys(this.state.cityList).map((key) => (
                    <MenuItem value={key}>
                      {this.state.cityList[key].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledShipingTextInput>
            <StyledShipingTextInput>
              <TextField
                fullWidth
                id="pinCode"
                type="text"
                label={t("common:profile:shippingAddress:labels:pinCode")}
                variant="outlined"
                name="pinCode"
                onChange={this.handleChange}
              />
            </StyledShipingTextInput>
            <StyledShipingTextInput>
              <TextField
                fullWidth
                id="phoneNo"
                type="number"
                label={t("common:profile:shippingAddress:labels:phoneNo")}
                variant="outlined"
                name="phoneNo"
                onChange={this.handleChange}
              />
            </StyledShipingTextInput>

            <StyledButtonContainer>
              {this.state.disableButton === false ? (
                <StyledButton onSubmit={this.handleFormSubmit}>
                  {t("common:button:save")}
                </StyledButton>
              ) : (
                <StyledButton disableButton={this.state.disableButton}>
                  {t("common:button:save")}
                </StyledButton>
              )}
              <StyledButton onClick={() => this.props.closeModal()}>
                {t("common:button:cancel")}
              </StyledButton>
            </StyledButtonContainer>
          </form>
        </StyledContainerWrapper>
      </StyledComponent>
    );
  }
}
