import React, { useState } from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import PageSizeSelector from '../grid-actions/ActionPageSizeSelector';
import ActionPaginationForProducts from '../grid-actions/ActionPaginationForProducts';
import ItemsCountofProduct from '../grid-actions/ItemsCountofProduct';
import { productDownload } from '../../../common/config/app.endpoints';
import AuthService from '../../auth/AuthService';

const StyledCoreDataGridContainer = styled(Grid)``;

const StyledDataWrapper = styled(Grid)`
    margin-top: 15px;
`;

const StyledGridWrapper = styled.div`
`;

const StyledPaginationContainer = styled(Grid)`
    margin-top: 1em;
    padding: 20px 0;
`;

const StyledActionsContainer = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
`;

const StyledActionBarContainer = styled(Grid)`
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
    &:focus {
        outline: none;
    }
`;

const StyledContainer = styled.div`
display: flex;
align-items: center;
`;

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.grey};
    font-size: 1em;
    padding: 0.4em;
    margin: 0.3em;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
        background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.8em;
    }
`;

const StyledPageSizeSelectorContainer = styled.div`
    
    border: none;
    
    select {
        padding: 0.8em 0.5em;
        margin: 0.3em;
        border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
        background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
        border-radius: 3px;
        font-size: 12px;
        font-weight: bold;
        color: ${(props) => props.theme.colors.base.grey};
        width:100%;

        ${props => props.theme.breakpoints.between('xs', 'sm')} {
            padding: 0.5em 0.3em;
            margin: 0.2em;
        }
    }
`;
export const StyledBackImageContianer = styled(Grid)`
    display: flex;
    align-items: center;
    padding: 5px;
`;
export const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;

interface IProps {
    t?: any;
    data?: any;
    totalElements?: string | number | undefined;
    value: (value: boolean, count: number, itemsPerPage: number) => void;
    itemPerPage: (value: boolean, count: number, itemsPerPage: number) => void;
    handleBackBtns: any;
}

interface IState {
    data: any,
    loading: boolean;
    currentPage: number;
    itemsPerPage: number;
    isExportSheetButtonEnable: boolean;
}

const childElementsWithProps = (props: any) => {
    return React.cloneElement(props.children, { ...props });
};

export default class CoreDataGridOfJewelleryWatchlist extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            currentPage: 1,
            itemsPerPage: 20,
            isExportSheetButtonEnable: true
        }
    }

    Auth = new AuthService();

    async componentDidMount() {
        let Page = localStorage.getItem("currentStart");
        if (Page != null && Page !== "0") {
            let pageNumber: number = parseInt(Page) / 20 + 1;
            this.setCurrentPage(pageNumber);
        }
        if (localStorage.getItem("itemsPerPage") !== null) {
            let savedCount: any = localStorage.getItem("itemsPerPage");
            this.setState({
                itemsPerPage: savedCount
            });
        }
        this.setData(this.props.data);
    }

    async componentDidUpdate(prevProps: any) {
        if (localStorage.getItem("currentStart") != null && localStorage.getItem("currentStart") === "0" && this.state.currentPage !== 1) {
            this.setCurrentPage(1);
        }
        if (prevProps.data !== this.props.data) {
            this.setData(this.props.data);
        }
    }

    setData(data: any) {
        this.setState(() => ({
            data
        }));
    }

    setCurrentPage(currentPage: number) {
        this.setState(() => ({
            currentPage
        }));
    }

    setItemsPerPage(itemsPerPage: number) {
        this.setState(() => ({
            itemsPerPage
        }));
        this.props.itemPerPage(true, 0, itemsPerPage)
    }

    setItemsPerPageHandler = (itemsPerPage: number) => {
        this.setCurrentPage(1);
        this.setItemsPerPage(itemsPerPage);
    }

    paginate = (pageNumber: number) => {
        this.setCurrentPage(pageNumber);
    }

    forApiCalling = (value: boolean, currentPage: number) => {
        if (this.props && this.props.totalElements !== undefined && this.props.totalElements > this.state.itemsPerPage) {
            var count = (currentPage - 1) * this.state.itemsPerPage;
            this.props.value(value, count, this.state.itemsPerPage)
        }
    }

    onClickLowest() {
        let data = this.props.data;
        data.sort(function (x: { caratMinimumPrice: number; }, y: { caratMinimumPrice: number; }) {
            return x.caratMinimumPrice - y.caratMinimumPrice;
        });
        this.setData(data);
    }

    onClickHighest() {
        let data = this.props.data;
        data.sort(function (x: { caratMinimumPrice: number; }, y: { caratMinimumPrice: number; }) {
            return y.caratMinimumPrice - x.caratMinimumPrice;
        });
        this.setData(data);
    }

    onClickLowestCarat() {
        let data = this.props.data;
        data.sort(function (x: { carat: number; }, y: { carat: number; }) {
            return x.carat - y.carat;
        });
        this.setData(data);
    }

    onClickHighestCarat() {
        let data = this.props.data;
        data.sort(function (x: { carat: number; }, y: { carat: number; }) {
            return y.carat - x.carat;
        });
        this.setData(data);
    }

    exportProductToExcel() {
      this.setState({
        isExportSheetButtonEnable: false
      });
        let exportProductIds = this.props.data.slice(0, 1).map((item: any, index: number) => {
            return item.auctionId;
        })
        this.Auth.postRequestForExcel(`${productDownload}?auctionType=Jewellery&auctionId=${exportProductIds}&auctionProductsType=watched`, { "ids": '' }, (status: any, response: any) => {
            if (status === 200) {                
                if (response !== undefined) {
                    const url = window.URL.createObjectURL(
                        new Blob([response], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Products.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                }               
                setTimeout(() => {
                  this.setState({
                    isExportSheetButtonEnable: true
                  });
                }, 5000);
            } else {

            }

        })

    }

    render() {
        const { t } = this.props;
        const selectedPage = true;
        let currentRecords = [];
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        if (this.state.data.length > 0) {
            currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
        }        
        const jpyHandler = (e: any) => {
            const caretdropDown : any = document.getElementById("caretFilter");
            const biddropDown : any = document.getElementById("bidFilter");
            if (caretdropDown && biddropDown) {
                caretdropDown.selectedIndex = 0;
                biddropDown.selectedIndex = 0;
            }
            if (e.target.value === "lowestJpy") {
                this.onClickLowest();
            } else if (e.target.value === "highestJpy") {
                this.onClickHighest();
            } else {
                //todo
            }
        };

        const valueMapping: any = {
          DEFAULT: { property: "serialNo", direction: "ASC" },
          lowestJpy: { property: "caratMinimumPrice", direction: "ASC" },
          highestJpy: { property: "caratMinimumPrice", direction: "DESC" },
          lowestBids: { property: "lowestBids", direction: "ASC" },
          highestBids: { property: "highestBids", direction: "DESC" },
        };

        const onChangeHandler = (e: any) => {
          const value = e.target.value;
          localStorage.setItem("selectedFilterSort", value);
          const selectedOption = {
            target: value,
            option: valueMapping[value],
          };
          onSelection(selectedOption);
        };

        const onSelection = (selectedOptions: any) => {
          const { direction, property } = selectedOptions.option;
          if (property === "caratMinimumPrice") {
            if (selectedOptions.target === "lowestJpy") {
                this.onClickLowest();
            } else if (selectedOptions.target === "highestJpy") {
               this.onClickHighest();
            } else {
              //todo
            }
          } else {
            //todo
          }
        };
        

        return (
          <StyledCoreDataGridContainer container={true} spacing={0}>
            <StyledActionBarContainer
              container={true}
              spacing={0}
              className="coreActionBarContainer"
            >
              <Grid container={true} xs={12} lg={3} xl={2}>
                <StyledBackImageContianer>
                  <StyledBackBtn
                    src="/images/controls/left_arrow_round_edges.png"
                    onClick={this.props.handleBackBtns}
                  />
                </StyledBackImageContianer>
                <Grid>
                  {/* <ItemsCountofProduct
                    t={t}
                    itemsPerPage={this.state.itemsPerPage}
                    totalElements={this.props.totalElements}
                    currentPage={this.state.currentPage}
                  /> */}
                </Grid>
              </Grid>
              <StyledActionsContainer item={true} xs={12} lg={9} xl={10}>
                <StyledContainer>
                {this.state.isExportSheetButtonEnable ? <StyledButton onClick={this.exportProductToExcel.bind(this)}>
                  {t("common:preview:pagination:export")}
                </StyledButton> :<StyledButton>
                    {t("common:preview:pagination:export")}
                  </StyledButton>
                  }
                  <StyledPageSizeSelectorContainer>
                    <select
                      name="sortingControl"
                      onChange={onChangeHandler}
                      value={`${localStorage.getItem("selectedFilterSort")}`}
                    >
                      <option value="DEFAULT">
                        {t("common:preview:pagination:select")}
                      </option>
                      <optgroup label={t("common:preview:pagination:jpy")}>
                        <option value="lowestJpy">
                          {t("common:preview:pagination:lowestJpy")}
                        </option>
                        <option value="highestJpy">
                          {t("common:preview:pagination:highestJpy")}
                        </option>
                      </optgroup>
                    </select>
                  </StyledPageSizeSelectorContainer>
                  <PageSizeSelector
                    setItemsPerPageHandler={this.setItemsPerPageHandler}
                    t={t}
                  />
                </StyledContainer>
              </StyledActionsContainer>
            </StyledActionBarContainer>
            <StyledDataWrapper item={true} xs={12}>
              <StyledGridWrapper>
                {childElementsWithProps({
                  selectedPage,
                  currentRecords,
                  ...this.props,
                })}
              </StyledGridWrapper>
            </StyledDataWrapper>
            {this.props.totalElements && this.props.totalElements > 0 ? (
              <StyledPaginationContainer item={true} xs={12}>
                <ActionPaginationForProducts
                  t={t}
                  totalElements={this.props.totalElements}
                  callbackForApiCalling={this.forApiCalling}
                  currentPage={this.state.currentPage}
                  itemsPerPage={this.state.itemsPerPage}
                  paginateCallback={this.paginate}
                />
              </StyledPaginationContainer>
            ) : null}
          </StyledCoreDataGridContainer>
        );
    }
}
