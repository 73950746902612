import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const size = 120,
  thickness = 4,
  secColor = "#d1d1d1";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant="determinate"
        {...props}
        disableShrink
        thickness={thickness}
        // size="6rem"
        className="mobileSize"
        sx={{
          scale: "-1 1",
          color:
            props.value > 66
              ? "#343a40"
              : props.value > 33
              ? "#F7B801"
              : "#A30000",
          borderRadius: "50%",
          boxShadow: `inset 0 0 0 ${(thickness / 44) * size}px ${secColor}`,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: "2rem" }}
        >{`${
          Math.round(
            props.value !== undefined && props.value !== null
               ? props.value
              : 0
          ) / 10
        }`}</Typography>
      </Box>
    </Box>
  );
}

export default function CircularStatic({ progress }: { progress: number }) {
  return <CircularProgressWithLabel value={progress * 10} />;
}
