import AuthService from "../components/auth/AuthService";
import { CutGradeList } from "../components/products/AbbrevtationLists";
import {
  currentTimeUrl,
  getJoinedAnnualAuctions,
  joinedAuctionsUrl,
  profileUrl,
  timeDifferenceUrl,
  url,
} from "./config/app.endpoints";
import PubSub from "pubsub-js";
import axios from "axios";
const apiKey =
  "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjgyNjYxNDk2LCJqdGkiOiIxZDZkOTQ2OC0yZDM5LTQ2MzgtOWY2OS0wMzA2OWEwMTkwZDciLCJ1c2VyX3V1aWQiOiJjZDMwMzJjNy1hYWEyLTQyODktYTg2Yy1mMDBmN2MyNGY5YTcifQ.ys3zCqLeaZP6SPlHOoeybxyHJpgexEz8fk76MZ2VEWZr59cZ0Etpip0zdX64AtzlQ2lp6TnIVRyTGG-cEnILxg";

const Auth = new AuthService();

export const getProfileData = () => {
  Auth.getRequest(profileUrl, (status: number, response: any) => {
    if (response !== undefined) {
      var timeZone = "";
      if (response.timezone) {
        localStorage.setItem("timezoneName", response.timezone.name);
        timeZone = response.timezone.name;
      }
      localStorage.setItem(
        "defaultPasswordUpdated",
        response.defaultPasswordUpdated
      );
      localStorage.setItem("userFirstName", response.firstName);
      localStorage.setItem("userLastName", response.lastName);
      localStorage.setItem("userEmail", response.email);
      localStorage.setItem("userType", response.userType);
      localStorage.setItem("profileComplete", response.profileComplete);
      localStorage.setItem("isReadOnlyUser", response.readOnlyUser);
      localStorage.setItem("paddleNumber", response.paddleNumber);
      localStorage.setItem("userUniqueId", response.uniqueId);
      localStorage.setItem("companyName", response.companyName);
      localStorage.setItem(
        "userCountry",
        response.countryDetail !== undefined ? response.countryDetail.name : ""
      );
      localStorage.setItem("userIdForNotification", response.perId);
      if (response.preferredCurrency) {
        localStorage.setItem(
          "preferredCurrency",
          response.preferredCurrency.code
        );
        localStorage.setItem(
          "preferredCurrencySymbol",
          response.preferredCurrency.symbol
        );
      } else {
        localStorage.setItem("preferredCurrency", "JPY");
        localStorage.setItem("preferredCurrencySymbol", "¥");
      }
      PubSub.publish("ProfileStatus", "Yes");
    }
  });
};

export const getCalendlyAccessToken = async () => {
  const options: any = {
    method: "POST",
    url: "https://auth.calendly.com/oauth/token",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Basic Og==",
    },
    data: {},
  };

  await axios
    .request(options)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const getAppointmentData = async () => {
  let appointmentData: any;
  let email = localStorage.getItem("email");

  await fetch("https://api.calendly.com/users/me", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${apiKey}`,
    },
  })
    .then((response) => response.json())
    .then(async (data: any) => {
      let organization: any = data.resource.current_organization;
      const options: any = {
        method: "GET",
        url: "https://api.calendly.com/scheduled_events",
        params: {
          organization: organization,
          invitee_email: email,
          status: "active",
          count: "100",
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${apiKey}`,
        },
      };

      await axios
        .request(options)
        .then(function (response: any) {
          appointmentData = response.data.collection;
        })
        .catch(function (error) {
          console.error(error);
        });
    })
    .catch((error) => {
      console.error(error);
    });

  return appointmentData;
};
export const joinAuction = () => {
  Auth.getRequest(joinedAuctionsUrl, (status: any, response: any) => {
    if (status === 200) {
      if (response !== undefined) {
        localStorage.setItem(
          "joinedAuctions",
          JSON.stringify(response.results)
        );
      }
    }
  });
};

export const joinAnnualAuction = () => {
  Auth.getRequest(getJoinedAnnualAuctions, (status: any, response: any) => {
    if (status === 200) {
      if (response !== undefined) {
        localStorage.setItem(
          "joinedAnnualAuctions",
          JSON.stringify(response.results)
        );
        PubSub.publish("AnnualAuctionDataArray", response.results);
      }
    }
  });
};

export const getTime = () => {
  Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
    if (status === 200) {
      if (response !== undefined) {
        var clockTime = (Date.now() - response) / 1000;
        var timeInMilliseconds = Date.now() - response;
        localStorage.setItem("notSyncedTime", JSON.stringify(clockTime));
        localStorage.setItem(
          "timeInMilliSeconds",
          JSON.stringify(timeInMilliseconds)
        );
        if (clockTime < -3 || clockTime > 3) {
          localStorage.setItem("clockNotSynced", "true");
        }
      }
    }
  });
};

export const getTimeDifference = () => {
  let timezone = localStorage.getItem("timezoneName");
  Auth.getRequest(
    `${timeDifferenceUrl}${timezone}&userTimeMillis=${Date.now()}`,
    (status: number, response: any) => {
      localStorage.setItem("timeDifference", JSON.stringify(response));
    }
  );
};

const isAuctionIdFromUrl = (auctionIdFromUrl: string | null) => {
  return (
    auctionIdFromUrl !== undefined &&
    auctionIdFromUrl !== "" &&
    auctionIdFromUrl !== null
  );
};
const isCategoryDiamond = (
  categoryFromUrl: string | null,
  category: string
) => {
  if (
    categoryFromUrl !== undefined &&
    categoryFromUrl !== "" &&
    categoryFromUrl !== null
  ) {
    if (categoryFromUrl.toLowerCase() === category) {
      return true;
    }
  }
  return false;
};
export const autoEmailInvites = (category: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const auctionIdFromUrl = urlParams.get("auctionId");
  const sourceFromUrl = urlParams.get("source");
  const categoryFromUrl = urlParams.get("category");

  if (
    sourceFromUrl === "email" &&
    isAuctionIdFromUrl(auctionIdFromUrl) &&
    isCategoryDiamond(categoryFromUrl, category)
  ) {
    return { isAvailable: true, auctionIdFromUrl };
  } else {
    return { isAvailable: false, auctionIdFromUrl };
  }
};

export const getDiamondCategoryFromUrl = () => {
  return autoEmailInvites("diamonds");
};
export const getJewelleryCategoryFromUrl = () => {
  return autoEmailInvites("jewellery");
};

// Certification link for HRD, CGL, GIA, IGI and AGT will be rendered by this function

export const openCertificateLinkPopup = (lab: any, certificate: any) => {
  let redirectUrl: string = "";

  if (lab === "CGL") {
    redirectUrl = `https://www.cgl.co.jp/diamond-check/`;
  } else if (lab === "HRD") {
    redirectUrl = `https://my.hrdantwerp.com/`;
  } else if (lab === "GIA") {
    redirectUrl = `https://www.gia.edu/report-check?reportno=${certificate}`;
  } else if (lab === "AGT") {
    redirectUrl = `${url}`;
  } else if (lab === "IGI") {
    redirectUrl = `https://www.igi.org/reports/verify-your-report?r=${certificate}`;
  } else if (lab === "DGL") {
    redirectUrl = `https://dgllab.in`;
  } else if (lab === "IGT") {
    redirectUrl = `${url}`;
  } else {
    redirectUrl = `${url}`;
  }
  window.open(
    redirectUrl,
    "DescriptiveWindowName",
    "height=900px,width=800px,scrollbars,toolbar,status,resizable"
  );
};

export const openJewelleryCertificateLinkPopup = (
  lab: any,
  certificate: any
) => {
  let redirectUrl: string = "";

  if (lab === "CGL") {
    redirectUrl = `https://www.cgl.co.jp/diamond-check/`;
  } else if (lab === "HRD") {
    redirectUrl = `https://my.hrdantwerp.com/`;
  } else if (lab === "GIA") {
    redirectUrl = `https://www.gia.edu/report-check?reportno=${certificate}`;
  } else if (lab === "IGI") {
    redirectUrl = `${url}`;
  } else if (lab === "DGL") {
    redirectUrl = `https://dgllab.in`;
  } else if (lab === "IGT") {
    redirectUrl = `${url}`;
  } else {
    redirectUrl = `${url}`;
  }

  window.open(
    redirectUrl,
    "DescriptiveWindowName",
    "height=900px,width=800px,scrollbars,toolbar,status,resizable"
  );
};

export const returnLanguageCode = (languageCode: any) => {
  switch (languageCode) {
    case "EN":
      return "en";
    case "JP":
      return "jp";
    case "zh_CN":
      return "zh_CN";
    default:
      return "jp";
  }
};

export const getRapnetPercentage = (
  rapnetPercentage: any,
  exchangeRates: any,
  currency: any,
  priceAMT: any,
  rapnetPrice: any
) => {
  if (!rapnetPercentage) {
    return "";
  }
  try {
    let amount: number;
    const rate = exchangeRates?.[0]?.["rate"] ?? 1;
    if (currency.code === "JPY") {
      amount = priceAMT * rate;
    } else {
      amount = priceAMT;
    }

    const price = 1 - amount / rapnetPrice || 0;
    const firstTwoNumbers = price * -100;
    const fixed = firstTwoNumbers.toFixed(2);
    return price === 0 ? "" : `(${fixed} rap-off)`;
  } catch (e) {
    return "";
  }
};

export function makeLinkClickable(remark: any) {
  if (remark !== undefined) {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    // Find all the URLs in the string
    var urls = remark.match(urlRegex);
    // Open each URL in a new tab/window
    if (urls) {
      urls.forEach(function (url: any) {
        return url;
      });
      return urls;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export const getAbbreviation = (value: any) => {
  if (value !== undefined || value !== null || value !== "") {
    let indx = CutGradeList.findIndex((item: any) => item.name === value);
    if (indx !== -1) {
      return CutGradeList[indx].abbreviations;
    }
    return value;
  } else {
    return "-";
  }
};


export function commasButtonRender(value: number) {
  return new Intl.NumberFormat("us-en").format(
    Math.round(value)
  );
}

export function removeHttpsLink(str: any) {
  if (str != undefined || str != null) {
    return str.replace(/https:\/\/[^\s]+/, '');
  } else {
    return '';
  }
}
