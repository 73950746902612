import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { backgroundGradient } from "../../../common/materials/LinearGradient";
import AuthService from "../../auth/AuthService";
import { getHomeOngoingAuctionsUrl } from "../../../common/config/app.endpoints";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { userUniqueIds } from "../../../common/constants/Uniqueid";

const StyledPaper = styled(Paper)`
    text-align: center;
    border: 1px solid ${(props) => props.theme.colors.base.gradientLightBlueColor};
    margin-right: 10px;
    background: ${(props) => props.theme.colors.base.white};
    .borderRightBlue {
        border-right: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
    .borderBottomBlue {
        border-bottom: 1px solid ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    }
`;

const StyledArrowWrapper = styled.div`
    position: absolute;
    z-index: 2;
    top: calc(50% - 10px);
    width: 30px;
    cursor: pointer;
`;

const StyledArrows = styled('img')``;


const StyledWrapperGrid = styled(Grid)`
    text-align: center;
    background: ${(props) => props.theme.colors.base.white};
    height: 100%;
`;

const StyledTitleContainer = styled(Grid)`
    background: ${backgroundGradient};
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 1em;
    height: 45px;
    color: ${(props) => props.theme.colors.base.white};
`;

const StyledProductContainer = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 0.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 1em;
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {
        padding: 1em;
    }
    text-align: left;
    cursor: pointer;
    .heading {
        font-size : 0.8em;
        color: ${(props) => props.theme.colors.base.grey};
    } 
    .productsValue {
        font-size: 1em;
    }
    .display {
        display: flex;
    }
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const StyledGrid = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white};
    .textWrap {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const StyledAuctionHeading = styled(Grid)`
    background: ${(props) => props.theme.colors.base.transparent};
    border-bottom: 1px solid #2660AD;
    border-radius: 50px;
    margin-bottom: 30px;
    font-family: Calibre-SemiBold;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.5em;
    }
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 2em;
    }
    padding: 0em 20px;
    color: #2660AD;
`;

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 2em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledCorouselWrapper = styled(Grid)`
`;

interface IProps extends IRouterComponentProps {
    t?: any;
    onActionClick: (auctionId: string, state: string, auctionName: string, proCount: string, category: string, auctionType: string) => void;
}
let mergedObject = {
    "id": "merged_id",
    "name": "365 Days Auction",
    "productCountDiamond": 0,
    "productCountJewellery": 0,
    "startDate": "",
    "previewStartDate": "",
    "state": "Merged",
    "auctionType": "Annual",
    "category": "Combined",
    "tenderExtended": false,
    "testAuction": false
};
const CorouselListAtHome = (props: IProps) => {
    let { onActionClick } = props;
    const [products, setProducts] = useState<any>([]);

    let subdomainType = localStorage.getItem("subDomain");

    const onPress = (item: any) => {
        let { id, state, name, productCount, category, auctionType } = item;
        onActionClick(id, state, name, productCount, category, auctionType);
    }

    const onAnnualPress = ({ id, state, name, productCount, category, auctionType }: any) => {
        onActionClick(id, state, name, productCount, category, auctionType);
    }

    useEffect(() => {
        const Auth = new AuthService();
        setTimeout(() => {
            if (Auth.loggedIn() === null) {
                props.history.push('/');
            } else {
                Auth.getRequest(getHomeOngoingAuctionsUrl, (status: number, response: any) => {
                    if (status === 200) {
                        if (response !== undefined) {
                            if (response.results.length > 0) {
                                let isAnnualAuctionExist: any = response.results.findIndex((obj: any) => obj.auctionType === "Annual");
                                let domainArray: any = ['localhost', 'zipang'];
                                let isSubdomaincontain: boolean = domainArray.includes(subdomainType);

                                if (isAnnualAuctionExist !== -1 && isSubdomaincontain) {
                                    let ongoingAnnual: any = response.results.filter((obj: any) => obj.auctionType !== "Annual");
                                    for (let obj of response.results) {
                                        if (obj.auctionType === 'Annual') {
                                            if (obj.category === 'Diamonds') {
                                                mergedObject.productCountDiamond = obj.productCount;
                                            }
                                            if (obj.category === 'Jewellery') {
                                                mergedObject.productCountJewellery = obj.productCount;
                                            }
                                        }
                                    }
                                    setProducts([mergedObject, ...ongoingAnnual]);
                                } else {
                                    setProducts([...response.results]);
                                }
                            }
                        }
                    }
                })
            }
        });
        return () => setProducts([]);
    }, []);

    const { t } = props;
    return (
        <StyledGrid container={true} spacing={0}>
            <StyledAuctionHeading className="textWrap" item xs={12} md={9} lg={9} xl={6}>{t('common:home:auctionHeading')}</StyledAuctionHeading>
            <StyledCorouselWrapper item xs={12} md={9} lg={9} xl={9}>
                {products.length > 0 ?
                    <Carousel
                        showStatus={false}
                        centerMode={true}
                        infiniteLoop={true}
                        showThumbs={false}
                        showIndicators={false}
                        renderArrowPrev={(onClickHandler, hasPrev, label) => (
                            <StyledArrowWrapper onClick={onClickHandler} title={label} style={{ left: 0 }}>
                                <StyledArrows src="/images/controls/left_arrow_round_edges.png" />
                            </StyledArrowWrapper>
                        )}
                        renderArrowNext={(onClickHandler, hasNext, label) => (
                            <StyledArrowWrapper onClick={onClickHandler} title={label} style={{ right: 0 }}>
                                <StyledArrows src="/images/controls/right_arrow_round_edges.png" />
                            </StyledArrowWrapper>
                        )}
                    >
                        {products.map((item: any, index: number) => (
                            <>{item.auctionType === 'Annual' ?
                                <StyledWrapperGrid item={true} key={index}>
                                    <StyledPaper>
                                        <Grid container={true} spacing={0} className="borderBottomBlue">
                                            <StyledTitleContainer item={true} xs={12} className="fontBold textWrap">{item.name}</StyledTitleContainer>
                                        </Grid>
                                        <Grid container={true} spacing={0}>
                                            <StyledProductContainer item={true} xs={6} className="borderRightBlue" onClick={() => onAnnualPress({ id: item?.id, state: item?.state, name: item.name, productCount: item.productCountDiamond, category: "Diamonds", auctionType: "Annual" })}>
                                                <div className="heading fontBold" >Diamond {t('common:preview:products')}</div>
                                                <div className="productsValue fontBold display">{item.productCountDiamond} {t('common:preview:pieces')}</div>
                                            </StyledProductContainer>
                                            <StyledProductContainer item={true} xs={6} onClick={() => onAnnualPress({ id: item?.id, state: item?.state, name: item.name, productCount: item.productCountJewellery, category: "Jewellery", auctionType: "Annual" })}>
                                                <div className="heading fontBold">Jewellery {t('common:preview:products')}</div>
                                                <div className="productsValue fontBold display">{item.productCountJewellery} {t('common:preview:pieces')}</div>
                                            </StyledProductContainer>
                                        </Grid>
                                    </StyledPaper>
                                </StyledWrapperGrid>
                                :
                                <>
                                    {item.auctionType !== "Annual" && <StyledWrapperGrid item={true} key={index} onClick={() => onPress(item)}>
                                        <StyledPaper>
                                            <Grid container={true} spacing={0} className="borderBottomBlue">
                                                <StyledTitleContainer item={true} xs={12} className="fontBold textWrap">{item.name}</StyledTitleContainer>
                                            </Grid>
                                            <Grid container={true} spacing={0}>
                                                <StyledProductContainer item={true} xs={6}>
                                                    <div className="heading fontBold">{t('common:preview:products')}</div>
                                                    <div className="productsValue fontBold display">{item.productCount} {t('common:preview:pieces')}</div>
                                                </StyledProductContainer>
                                            </Grid>
                                        </StyledPaper>
                                    </StyledWrapperGrid>}
                                </>

                            }
                            </>
                        ))}
                    </Carousel>
                    :
                    <StyledWrapperGrid>
                        <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
                    </StyledWrapperGrid>
                }
            </StyledCorouselWrapper>
        </StyledGrid >
    );
}

export default CorouselListAtHome;