import React from "react";

interface IProps {
  text: string;
  length: number;
  tail?: any;
  tailClassName?: string;
}

const EllipsisText = (props: IProps) => {
  let { text, length, tail, tailClassName, ...others } = props;
  if(text === undefined){
    text = ''
  }
  if (text.length <= length || length < 0) {
    return <span {...others}>{text}</span>;
  } else {
    const tailStyle = {
      cursor: "auto",
    };

    let displayText;
    if (length - tail.length <= 0) {
      displayText = "";
    } else {
      displayText = text?.slice(0, length - tail.length);
    }

    return (
      <span title={text} {...others}>
        {displayText}
        <span style={tailStyle} className={tailClassName}>
          {tail}
        </span>
      </span>
    );
  }
};

export default EllipsisText;
