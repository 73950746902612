import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sideMenuGradient, backgroundGradient } from '../../common/materials/LinearGradient';
import { useTranslation } from 'react-i18next';


const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
   .collapse {
        border-radius: 15px;
        background: ${sideMenuGradient};
        display: flex;
    }
    .iconColor {
        color: ${(props) => props.theme.colors.base.white};
    }
    // .paddingforpdf {
    //     padding: 20px 0px 20px 10px;
    // }
    // .padding {
    //     padding: 10px 20px;
    // }
    .shadow {
        -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey}; 
    }
`;

const StyledWrapper = styled(Grid)`
    padding: 15px 0 5px;
    // max-width: 1000px;
    // -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    // -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    // box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledHeading = styled(Grid)`    
    width: 100%;
    text-align: center;
    color: ${backgroundGradient};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 10px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 22px;
    }   
`;

const StyledCollapsibleWrapper = styled(Grid)`
    padding: 2px;
    color: ${(props) => props.theme.colors.base.white};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 16px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 16px;
    }
    @media (width: 2560px) {
        font-size: 24px;
    }
`;

const StyledCollapsibleContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 2px; 
    text-align: center;
    justify-content: center; 
    color: blue;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 18px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 18px;
    }
    @media (width: 2560px) {
        font-size: 24px;
    }     
`;

const StyledContainer = styled(Grid)`
   
`;

const SellDiamonds = (props: any) => {
    const { t } = useTranslation();

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledGrid item={true} xs={12}>
                <StyledWrapper item={true} xs={12} md={12} lg={12}>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">Personalized Auction</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>
                                        <span style={{ color: 'blue', fontSize: '1em' }}>Access Japan pawn market without being in Japan. Taking Japan Auction Marketplace to world! And bringing world to Japan! Signup as Auctioneer or Both.</span>

                                    </StyledCollapsibleContainer>
                                    <div style={{ display: "flex", flexDirection: "column", fontSize: '1em', textAlign: "justify", padding: "30px" }}>
                                        <ul>
                                            <li>With myauctions, schedule an auction in 3 minutes with your own personalized website and get <span style={{ color: 'blue' }}>live</span> or <span style={{ color: 'blue' }}>popcorn bidding </span> in your auction with over thousands of <span style={{ color: 'blue' }}>diamond</span> and <span style={{ color: 'blue' }}>jewellery </span>  products.</li>
                                            <li>Add images and videos of your stone, <span style={{ color: 'blue' }}>bulk upload </span>certificates. Get <span style={{ color: 'blue' }}>vision360 image</span> and video view support for your precious stones.</li>
                                            <li>Get all your Auction administration sorted at one place. Setup your own invoice settings – commission on purchase and sale, charges, discount, rewards, and attendance fees.</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">©#365DaysAuction</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails >
                                <Grid container spacing={0} className="shadow padding" >
                                    <StyledCollapsibleContainer>You can’t always be the most talented, but you can be the most competitive.</StyledCollapsibleContainer>
                                    
                                    <div style={{ display: "flex", flexDirection: "column", fontSize: '1em', textAlign: "justify", padding: "30px" }}>
                                        <ul>
                                            <li style={{ textAlign: "justify"}}>Schedule your products in an auction with no deadlines at myauctions.jp. Give us the excel of your products, get them picked up and sell your products – get live bidding anytime anywhere. <span style={{ color: 'blue' }}>©#365DaysAuction</span></li>
                                        </ul>
                                    </div>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                </StyledWrapper>
            </StyledGrid>
        </StyledContainer>
    )
}

export default SellDiamonds;