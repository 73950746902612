export enum ProductCategory {
  DIAMONDS = "Diamonds",
  JEWELLERY = "Jewellery",
  BAGS = "Bags",
  ACCESSORIES = "Accessories",
  SHOES = "Shoes",
}

export enum ProductCategorySmalls {
  DIAMONDS = "diamonds",
  JEWELLERY = "jewellery",
  BAGS = "bags",
  ACCESSORIES = "accessories",
  SHOES = "shoes",
}

export enum ParcelType {
  DIAMOND = "Diamond",
  PARCEL = "Parcel",
  JEWELLERY = "Jewellery",
}

export enum MediaType {
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
}

export enum ProductState {
  AVAILABLE = "Available",
}

export enum ProductAction {
  AUCTION_ABOUT_TO_START = "Auction_AboutToStart",
  AUCTION_BREAK = "Auction_Break",
  AUCTION_BREAK_MSG = "Auction_Break_MSG",
  AUCTION_CANCELLED = "Auction_Cancelled",
  AUCTION_ENDED = "Auction_Ended",
  AUCTION_PAUSED = "Auction_Paused",
  AUCTION_RESUMED = "Auction_Resumed",
  BID = "BID",
  NEXT_PRODUCT = "NEXT_PRODUCT",
  CURRENT_STATE = "CURRENT_STATE",
  HOLD = "HOLD",
  SOLD = "SOLD",
  USER_DEACTIVATED = "User_Deactivated",
}