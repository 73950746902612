import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      top: 48px;
      width: 100% !important;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

export const StyledCommanDiv = styled.div`
  display: flex;
  width: 100%;
  transition: width 2s;
`;

export const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-root {
    padding: 7px;
    border: 1px solid lightgray;
  }

  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
    max-width: 100px;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .buttonGray {
    background: ${(props) => props.theme.colors.base.gray};
  }
  .MuiTableCell-footer {
    padding: 2px;
    font-size: 0.2em;
    height: 40px;
  }
`;

export const StyledBtnContainer = styled.div`
    text-align:center;
`;

export const StyledCoreDataGridContainer = styled(Grid)`
`;
export const StyledTextWrapper = styled(Grid)`
    margin:auto;
    text-align: center;
`;
export const StyledActionBarContainer = styled(Grid)`
    align-items: center;
    padding: 10px;
    margin:10px 0px 10px 0px;
    border-radius: 5px;
    box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
    justify-content:flex-end;
    &:focus {
        outline: none;
    }
`;


export const StyledButtonHeader = styled.button`
    color: ${(props) => props.theme.colors.base.grey};
    font-size: 0.9em;
    padding: 0.4em;
    margin: 0.3em;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
        background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 0.8em;
    }
`;

export const StyledTimeGridContainer = styled(Grid)`
    padding: 10px;
    justify-content:flex-end;
`;

export const StyledTimer = styled.div`
    font-size:1.3em;
    font-family: 'Calibre-Regular';
    color:${(props) => props.theme.colors.base.textColor};

    .countdownText {
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .dateTimeStyle {
        width: 100%;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: center;
    }

    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size:1em;
        margin-top:0.5em;
    }
`;

export  const StyledButtonGray = styled.button`
    color: ${(props) => props.theme.colors.base.black};
    border: none;
    font-size: 0.8em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.gray}; 
    border: 1px solid ${(props) => props.theme.colors.base.white};
`;

export const StyledTabLayout = styled(Grid)`
`;

export const StyledContent = styled(Grid)`
  text-align: center;
  font-size: 1.3em;
  font-family: "Calibre-Regular";
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    margin-top: 0.4em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledName = styled(Grid)`
  dispaly: flex;
  padding: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 1px;
  }

  @media (min-width: 1024px) {
    text-align: end;
  }
`;


export const StyledBoxContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-top: 4px solid #000f7e;
`;

export const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;