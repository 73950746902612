import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';

import { StyledTextInput } from '../materials/GenericStyleComponent';

const StyledGrid = styled(Grid)`
  padding: 0px 15px;
`;

const StyledContainer = styled(Grid)`
  ${props => props.theme.breakpoints.between('md', 'xl')} {
    width: 320px;
  }
`;

const StyledWrapper = styled(Grid)`
`;

const StyledOccupationHeading = styled.div`
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    font-size: 12px;
`;

const StyledFieldset = styled.fieldset`
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBorderColor};
`;

const StyledLegend = styled.legend`
    padding: 0.2em 0.5em;
    font-size: 12px;
    margin-left: 0.8em;
`;

export interface IProps {
  t: any;
  occupationType: (type: any) => void;
}

export default function RadioButton(props: IProps) {
  const { t, occupationType } = props;
  const [value, setValue] = React.useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    occupationType((event.target as HTMLInputElement).value);
  };

  return (
    <StyledTextInput>
      <StyledWrapper item xs={12} md={12}>
        <StyledFieldset>
          <StyledLegend>
            <StyledOccupationHeading>{t('common:register:labels:occupation')}</StyledOccupationHeading>
          </StyledLegend>
          <FormControl>
            <RadioGroup aria-label="gender" name="occupation" value={value} onChange={handleChange}>
              <StyledContainer container={true} spacing={0}>
                <StyledGrid item xs={12} md={6} lg={6}>
                  <FormControlLabel value='Auctioneer' control={<Radio color="primary" />} label={`${t('common:register:occupation:auctioneer')}`} />
                </StyledGrid>
                <StyledGrid item xs={12} md={6} lg={6}>
                  <FormControlLabel value='Bidder' control={<Radio color="primary" />} label={`${t('common:register:occupation:bidder')}`} />
                </StyledGrid>
              </StyledContainer>
              <StyledGrid item xs={12} md={12} lg={12}>
                <FormControlLabel value='both' control={<Radio color="primary" />} label={`${t('common:register:occupation:auctioneerAndBidder')}`} />
              </StyledGrid>
            </RadioGroup>
          </FormControl>
        </StyledFieldset>
      </StyledWrapper>
    </StyledTextInput>
  );
}