import React, { useEffect, useRef, useState } from "react";
import IRouterComponentProps from "../models/IRouterComponentProps";
import LandingPageLayout from "../components/layouts/LandingPageLayout";
import styled from "styled-components";
import Modal from "@material-ui/core/Modal";

import RegisterForm from "./login/RegisterForm";
import Banner from "../components/category-carousal/Banner";
import CategoryAtLanding from "../components/products/CategoryAtLanding";
import KeyPointers from "../components/products/KeyPointers";
import LoginForm from "./login/LoginForm";
import ForgotPassword from "./login/ForgotPassword";
import ViewRegisterModal from "./login/ViewRegisterModal";
import Alert from "../common/elements/Alert";
import ConfirmNewPassword from "./login/ConfirmNewPassword";
import WhyMyAuction from "../components/products/WhyMyAuction";
import LoginWithEmail from "./login/passwordReset/LoginWithEmail";
import PasswordResetViaOtp from "./login/passwordReset/PasswordResetViaOtp";

import ProductSlider from '../components/product-slider/landing-product-slider';
import { Grid } from "@material-ui/core";
import ChangePlaneRediractionModal from "./login/ChangePlaneRediractionModal";
import { resetIsChangePlanePopup, resetSubDomainName } from "../redux/slice/commonReact/commonReactSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../redux/store";

const StyledStickeyContainer = styled.div`
  position: fixed;
  right: 0;
  top: 50%;
  height: auto;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 12vh;
    font-size: 0.5em;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: 14vh;
    font-size: 0.5em;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 15vh;
    font-size: 0.6em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 16vh;
    font-size: 0.6em;
  }
`;
const StyledScrollUp = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  right: 30px;
  top: 90%;
  height: auto;
  z-index: 99;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    width: 40px;
    height: 40px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 40px;
    height: 40px;
  }
`;

const StyledStickeyTextContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 16px;
    text-align: center;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    font-size: 15px;
    text-align: center;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

const StyledStickeyImage = styled("img")`
  width: 100%;
  margin: 2px;
  height: auto;
`;

const StyledLayoutGrid = styled(Grid)`
`;


const StyledTitleContainer = styled.h2`
  font-size: 3em;
  text-align: center;
  color: ${(props) => props.theme.colors.base.textColor};
  padding-top: 0.4em;
  font-family: Calibre-Regular;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    padding-top: 1em 0;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    font-size: 1.6em;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    font-size: 2em;
  }
`;

const StyledGrid = styled(Grid)`
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 20px 20px;
    }
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        padding: 50px 50px;
    }
`;

let subdomainName: any = ''

const LandingPage = (props: IRouterComponentProps) => {
  const { t } = props;
  const [onPressSignUp, setOnPressSignUp] = useState(false);
  const [onPressLogin, setOnPressLogin] = useState(false);
  const [onsuccessfulRegister, setOnsuccessfulRegister] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [onPressForgotPassword, setOnPressForgotPassword] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [onConfirmPassword, setOnConfirmPassword] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  // const [onPromotional, setOnPromotional] = useState(true);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showLoginWithOTPPopup, setShowLoginWithOTPPopup] = useState(false);
  const unSource: any = useRef<any>();
  let userEmailvalue = useRef<any>();
  const [isChangePlan, setIsChangePlan] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("un");
    unSource.current = userName;
    const userChangePlan = urlParams.get("changePlan");

    if (userName !== null) {
      setShowResetPasswordPopup(false);
    } else {
      setShowResetPasswordPopup(false);
    }

    if (userChangePlan !== null) {
      setOnPressLogin(true);
    } else {
      setOnPressLogin(false);
    }
    localStorage.removeItem("changePlanEmail");

    localStorage.setItem("timezoneName", "");
    setTimeout(() => {
      // localStorage.clear();
      let keysToRemove = [
        "id_token",
        "filteredValues",
        "userName",
        "clockNotSynced",
        "userId",
        "auctionState",
        "joinedAuctions",
        "AuctionDetails",
        "action",
        "watchlistProductsList",
        "subDomain",
        "length",
        "loglevel",
      ];
      for (var key of keysToRemove) {
        localStorage.removeItem(key);
      }
    }, 100);
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 10) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
    return () => {
      dispatch(resetSubDomainName());
      dispatch(resetIsChangePlanePopup());
    };
  }, []);

  const loginHandler = () => {
    setOnPressLogin(true);
    setOnPressSignUp(false);
    setShowLoginWithOTPPopup(false);
  };

  const onClose = () => {
    setOnPressLogin(false);
    setOnPressSignUp(false);
    setOnPressForgotPassword(false);
    setOnsuccessfulRegister(false);
    setOnConfirmPassword(false);
    setShowLoginWithOTPPopup(false);
    // setOnPromotional(false);
  };

  const onCloseForgotPassword = () => {
    setOnPressForgotPassword(false);
    setOnConfirmPassword(true);
  };

  const signUpHandler = () => {
    setOnPressLogin(false);
    setOnPressSignUp(true);
    setShowLoginWithOTPPopup(false);
  };

  const forgotPasswordModalHandler = () => {
    setOnPressLogin(false);
    setOnPressForgotPassword(true);
    setShowLoginWithOTPPopup(false);
  };

  const regiterModalHandler = () => {
    setOnPressSignUp(false);
    setOnsuccessfulRegister(true);
    setShowLoginWithOTPPopup(false);
  };

  const showSuccessfulToast = () => {
    const { t } = props;
    setAlertType("success");
    setShowMessage(
      `${t("common:profile:forgotPassword:validation:invalidOTP")}`
    );
    setOpenToast(true);
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      setOnPressSignUp(false);
      setAlertType("error");
      setShowMessage(
        `${t("common:timeOut:somethingWrong")} ${t("common:timeOut:tryAgain")}`
      );
      setOpenToast(true);
    }
  };

  const renderResetPasswordPopup = () => {
    return (
      <StyledModal
        open={showResetPasswordPopup}
        onClose={() => {
          setShowResetPasswordPopup(false);
        }}
      >
        <LoginWithEmail
          closeModal={() => {
            setShowResetPasswordPopup(false);
          }}
          isFromSubDomain={false}
          renderOTPPopup={() => {
            setShowOTPPopup(true);
            setShowResetPasswordPopup(false);
          }}
          subdomainfunc={rhandlechangePlanModal}
        />
      </StyledModal>
    );
  };

  const renderOTPPopup = () => {
    return (
      <StyledModal open={showOTPPopup}>
        <PasswordResetViaOtp
          closeOtpModal={() => {
            setShowOTPPopup(false);
          }}
          openPasswordPop={() => {
            setShowResetPasswordPopup(true);
          }}
        />
      </StyledModal>
    );
  };

  //changePlane Value

  const loginButtonHandler = () => {
    if (unSource.current !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
      loginHandler();
    }
  };

  const loginWithOtpModalHandler = () => {
    setOnPressLogin(false);
    setShowLoginWithOTPPopup(true);
  };

  const updateValue = (value: any) => {
    userEmailvalue.current = value;
  };

  const rhandlechangePlanModal = (val: string) => {
    subdomainName = val;
    setIsChangePlan(true);
  };

  return (
    <LandingPageLayout {...props}>
      <Banner t={t} />
      <StyledStickeyContainer onClick={signUpHandler}>
        <StyledStickeyTextContainer >
          {t("common:landingPage:freeTrial")}
        </StyledStickeyTextContainer>
        <StyledStickeyImage src="/images/landing/become-a-member.png" />
      </StyledStickeyContainer>
      <CategoryAtLanding t={t} />
      <WhyMyAuction t={t} />
      <KeyPointers t={t} />
      {/* <VideoPlayer t={t} /> */}
      <StyledLayoutGrid container={true} spacing={0}>
        <StyledGrid item={true} xs={12} md={12} lg={12} xl={12}>
          <StyledTitleContainer>FEATURED</StyledTitleContainer>
          <ProductSlider t={t} onParticipate={loginButtonHandler} />
        </StyledGrid>
      </StyledLayoutGrid>
      <StyledScrollUp>
        {showButton && (
          <StyledStickeyImage
            onClick={scrollToTop}
            src="/images/Top-to-Scroll.png"
          />
        )}
      </StyledScrollUp>
      {onPressLogin === true && (
        <StyledModal
          open={onPressLogin}
          onClose={onClose}
          className="loginModal"
        >
          <LoginForm
            forgotPasswordModal={forgotPasswordModalHandler}
            {...props}
            registerModal={signUpHandler}
            isFromSubDomain={false}
            renderLoginWithOTPPopup={loginWithOtpModalHandler}
            userEmailvalue={updateValue}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}
      {onPressForgotPassword === true && (
        <StyledModal
          open={onPressForgotPassword}
          onClose={onClose}
          className="forgotPasswordModal"
        >
          <ForgotPassword t={t} closeModal={onCloseForgotPassword} />
        </StyledModal>
      )}
      {onConfirmPassword === true && (
        <StyledModal open={onConfirmPassword}>
          <ConfirmNewPassword
            t={t}
            closeModal={() => onClose()}
            showSuccessfulToast={showSuccessfulToast}
          />
        </StyledModal>
      )}
      {onPressSignUp === true && (
        <StyledModal open={onPressSignUp} className="registerModal">
          <RegisterForm
            t={t}
            closeModal={onClose}
            onSuccessfulRegisterModal={regiterModalHandler}
            loginModal={loginHandler}
            apiTimeout={timeout}
          />
        </StyledModal>
      )}
      {onsuccessfulRegister === true && (
        <StyledModal
          open={onsuccessfulRegister}
          onClose={onClose}
          className="registerModal"
        >
          <ViewRegisterModal openRegisterModal={onClose} t={t} />
        </StyledModal>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {/* {onPromotional === true &&
                <StyledModal open={onPromotional} onClose={onClose}>
                    <Promotional {...props} closeModal={onClose}/>
                </StyledModal>
            } */}
      {showResetPasswordPopup && renderResetPasswordPopup()}
      {showOTPPopup && renderOTPPopup()}

      {isChangePlan && (
        <StyledModal
          open={isChangePlan}
          onClose={() => {
            setIsChangePlan(false);
          }}
        >
          <ChangePlaneRediractionModal
            subdomainName={subdomainName}
            updateValue={userEmailvalue.current}
          />
        </StyledModal>
      )}
    </LandingPageLayout>
  );
};

export default LandingPage;
