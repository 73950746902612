import { Grid, makeStyles, Typography } from "@material-ui/core";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  LandingPagePlayFairFont,
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../LandingPage/StyledComponents";
import {
  StyledQuote,
  StyledTestimonial,
  StyledTextContainer,
} from "./StyledComponent";

const testimonials = [
  {
    text: "common:testimonials:1",
    image: "https://d1xs39ogteakr9.cloudfront.net/jw-popup.png",
  },
  {
    text: "common:testimonials:2",
    image: "https://d1xs39ogteakr9.cloudfront.net/jw-popup.png",
  },
];

const useStyles = makeStyles({
  container: {
    padding: "3em 0 !important",
  },
});

export const MobileTestimonials = () => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "nowrap",
        overflowX: "scroll",
        width: "100vw",
        paddingBottom: "1em",
      }}
    >
      {testimonials.map((testimonial) => (
        <div style={{ minWidth: "60vw", marginRight: "1em" }}>
          <Testimonial testimonial={testimonial} isMobile />
        </div>
      ))}
    </div>
  );
};

export const Testimonial = ({ even, testimonial, isMobile }: any) => {
  const { t } = useTranslation();
  return (
    <StyledTestimonial container isMobile={isMobile}>
      {!even && (
        <Grid item xs={12} sm={5} container justifyContent="flex-start">
          <StyledImageCommonContainer style={{ margin: 0 }}>
            <StyledImageCommonComponent src={testimonial.image} />
          </StyledImageCommonContainer>
        </Grid>
      )}
      <StyledTextContainer
        isMobile={isMobile}
        item
        xs={12}
        sm={7}
        container
        justifyContent="flex-start"
        alignItems={isMobile ? "flex-start" : "center"}
      >
        <StyledQuote isMobile={isMobile}>"</StyledQuote>
        <Typography variant={isMobile ? "h6" : "h4"}>
          <LandingPagePlayFairFont>
            {t(testimonial.text)}
          </LandingPagePlayFairFont>
        </Typography>
      </StyledTextContainer>
      {even && (
        <Grid item xs={12} sm={5} container justifyContent="flex-end">
          <StyledImageCommonContainer style={{ margin: 0 }}>
            <StyledImageCommonComponent src={testimonial.image} />
          </StyledImageCommonContainer>
        </Grid>
      )}
    </StyledTestimonial>
  );
};

const Testimonials = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container classes={classes}>
      {testimonials.map((testimonial, index) => {
        const even = index / 2 === 0;
        return <Testimonial even={even} testimonial={testimonial} />;
      })}
    </Grid>
  );
};

export default Testimonials;
