import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import Styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const StyledFooterContainer = styled(Grid)`
  margin-top: 10%;
  background: ${(props) => props.theme.colors.base.veryLightBlue};
  text-align: center;
  margin-top: 20px;
  .padding {
    padding: 16px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .padding {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0px;
    }
  }
`;

const StyledBoxContainer = styled(Box)`
  background: ${(props) => props.theme.colors.base.textColor};
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledSocialIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledSocialIcons = styled.p`
  width: 100px;
  margin: 0 auto;
  text-align: center;
`;

const StyledCompanyName = styled.p`
  color: ${(props) => props.theme.colors.base.white};
  font-family: Calibre-Regular;
  font-size: 5vh;
  display: flex;
  justify-content: center;
  margin-bottom: 0rem;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 2vh;
  }
`;

const StyledNumberContainer = styled.p`
  font-size: 2.7vh;
  font-weight: bold;
  width: 100%;
  font-family: Calibre-Bold;
  color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
  margin-bottom: 0rem;
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const StyledScrollUpImage = styled.img`
  width: 40px;
  height: 40px;
  margin-top: 10px;
  position: fixed;
  margin-top: 80px;
  cursor: pointer;
  top: 700px;
  margin-left: 800px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 40px;
    height: 40px;
    margin-top: 5px;
    top: 900px;
    margin-left: 30%;
  }
`;

const StyledOfficeandEmail = styled.p`
  color: ${(props) => props.theme.colors.base.black};
  font-family: Calibre-Regular;
  font-size: 2.5vh;
  padding: 5px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
    padding: 0em;
  }
  margin-bottom: 0rem;
`;

const StyledCopyRight = styled.span`
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.white};
  padding: 0.2em;
  font-size: 20px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 8px;
  }
`;

const StyledShapeImage = Styled("img")`
    height: 2em;
    width: 2em;
    margin: 10px;
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
        height: 1em;
        width: 1em;
        margin: 5px;
    }
`;

const Footer = () => {
  let { t } = useTranslation();
  let history = useHistory();
  const theme = createTheme({});
  const isSmallDevices = useMediaQuery(theme.breakpoints.down("xs"));

  const onpress = () => {
    history.push("/faq");
  };

  return (
    <StyledFooterContainer id="contactUs">
      <Box p={2} className="padding">
        {!isSmallDevices && (
          <StyledWrapper container>
            <StyledNumberContainer>
              {t("common:footer:officeNumber")} | {t("common:footer:faxNumber")}
            </StyledNumberContainer>
          </StyledWrapper>
        )}
        {isSmallDevices && (
          <Grid container>
            <StyledNumberContainer>
              {t("common:footer:officeNumber")}
            </StyledNumberContainer>
            <StyledNumberContainer>
              {t("common:footer:faxNumber")}
            </StyledNumberContainer>
          </Grid>
        )}
        <StyledOfficeandEmail>
          {t("common:footer:officeAddress")}
        </StyledOfficeandEmail>
        <StyledOfficeandEmail>
          {t("common:footer:emailId")}
        </StyledOfficeandEmail>
        <StyledSocialIconContainer>
          <a
            href="https://wa.me/message/DY37FMYCUSEJN1"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <StyledShapeImage src={`/images/socialMedias/whatsapp.png`} />
          </a>
          <a
            href="https://lin.ee/2wg2q8j"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <StyledShapeImage src={`/images/socialMedias/line.png`} />
          </a>
          <a
            href="https://instagram.com/myauctions.jp?igshid=19dk8l9gy1v2j"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <StyledShapeImage src={`/images/socialMedias/instagram.png`} />
          </a>
          <a
            href="https://www.facebook.com/myauctions.jp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledShapeImage src={`/images/socialMedias/facebook.png`} />
          </a>
          <a
            href="https://www.linkedin.com/company/myauctions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <StyledShapeImage src={`/images/socialMedias/linkdin.png`} />
          </a>
          <a
            href="https://twitter.com/MyauctionsJp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <StyledShapeImage src={`/images/socialMedias/twitter.jpg`} />
          </a>
        </StyledSocialIconContainer>
      </Box>
      <StyledBoxContainer p={2} className="padding">
        <StyledSocialIcons />
        <StyledCompanyName>{t("common:footer:company")}</StyledCompanyName>
        <StyledCopyRight>&copy; {t("common:footer:copyright")}</StyledCopyRight>
        <StyledCopyRight onClick={onpress}>
          {t("common:kycFrom:faq:heading")}
        </StyledCopyRight>
      </StyledBoxContainer>
    </StyledFooterContainer>
  );
};

export default Footer;
