import React, { ChangeEvent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";

import AuthService from "../../auth/AuthService";
import { productUrl } from "../../../common/config/app.endpoints";
import Alert from "../../../common/elements/Alert";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import BlockUserModal from "../BlockUser/BlockUserModal";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import PreBidConfirmationPopup from "./PreBidConfirmationPopup";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";

const StyledContainer = styled(Grid)`
  padding: 10px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 1em;
  padding: 5px 0;
  display: inline-block;
`;

const StyledLabelConversion = styled.label`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
  display: inline-block;
`;

const StyledInput = styled.input`
  padding: 7px;
  width: 100%;
  text-align: right;
  border: none;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 0.8em;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  border-radius: 3px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledGrid = styled(Grid)`
  display: flex;
`;

interface IButtonProps {
  cancel?: boolean;
  solid?: boolean;
  disabled?: boolean;
}

const StyledTextWrapper = styled.div`
  padding: 2px 6px;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0.8em;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.grey};
`;

const StyledButtonWrapper = styled(Grid)`
  display: flex;
`;

const StyledButton = styled.button<IButtonProps>`
  padding: 8px 12px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  margin-right: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.lightBlue};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) => props.theme.colors.base.lightBlue};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledCancelButton = styled.button<IButtonProps>`
  padding: 8px;
  width: 100px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 5px;
  color: ${(props) => props.theme.colors.base.white};
  background: ${(props) => props.theme.colors.base.lightBlue};
  ${(props) =>
    props.cancel &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) => props.theme.colors.base.lightBlue};
    `}
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

interface IProps extends IRouterComponentProps {
  t: any;
  perId: string | number;
  auctionId: string;
  preBidPrice: null | string | number;
  startingPrice: string | number;
  caratMinimumPrice: any;
  preBidAmountSaved: (auctionId: string) => void;
  cancelPreBid: (value: boolean) => void;
  apiTimeout: (timeout: boolean) => void;
  sellerAuctionUserId: string;
  auctionState: string;
  openPreBidPopUpModal: boolean;
  count: number;
  currency: any;
  exchangeRates: any;
  exchangeRate: any;
  parcelType: string;
  isReadOnlyUser: boolean;
  isSeller: boolean;
  carat: any;
}

let joinedAuctions: any = [];
const PreBiddingForm = (props: IProps) => {
  const [biddingPrice, setBiddingPrice] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isPreBidDone, setIsPreBidDone] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openPreBidPopUpModal, setOpenPreBidPopUpModal] = useState(false);
  const [eStore, setEStore] = useState();
  const [preBiddingPrice, setPreBiddingPrice] = useState("");
  const [preBidConversion, setPreBidConversion] = useState("");
  const Auth = new AuthService();
  const { t, auctionState, parcelType, isReadOnlyUser } = props;

  useEffect(() => {
    if (props.isSeller) {
      setIsAuctionUserId(true);
    }
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (props.sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    if (props.preBidPrice === null || props.preBidPrice === undefined) {
      setIsPreBidDone(false);
    } else {
      setIsPreBidDone(true);
    }
    return () => {
      setIsPreBidDone(false);
      setIsAuctionUserId(false);
    };
  }, [props.preBidPrice, props.sellerAuctionUserId]);

  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const onSubmit = (e: any) => {
    if (props.preBidPrice === null || props.preBidPrice === undefined) {
      //handlePreBidSubmit(e);
      e.preventDefault();
      showPreBidPopUp();
      setEStore(e);
    } else {
      handleCancelSubmit(e);
    }
  };

  const getBracketValue = () => {
    let bidingPriceZero: any = "";
    const isJPY = props.currency["code"] === "JPY" ? true : false;
    const bidPrice = props.caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidingPriceZero = "000";
        } else if (bidPrice >= 3000000) {
          bidingPriceZero = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
        bidingPriceZero = "0";
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidingPriceZero = "000";
        } else if (bidPrice >= 3000000) {
          bidingPriceZero = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    }
    return bidingPriceZero;
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setBiddingPrice(e.target.value);

    let bidingPriceZero = await getBracketValue();

    if (parcelType !== "Diamond") {
      if (userCurrencyCode !== props.currency["code"]) {
        let bidPrice = e.target.value + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * props.exchangeRates[0]["rate"])
        );
        setPreBidConversion(`${userCurrencySymbol}${conversion}`);
      } else {
        setPreBidConversion("");
      }
    } else {
      if (userCurrencyCode !== props.currency["code"]) {
        let bidPrice = e.target.value + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * props.exchangeRates[0]["rate"])
        );
        setPreBidConversion(`${userCurrencySymbol}${conversion}`);
      } else {
        setPreBidConversion("");
      }
    }
  };

  const showPreBidPopUp = async () => {
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);

    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    let bidingPriceZero = await getBracketValue();

    if (parcelType !== "Diamond") {
      let bidPrice = biddingPrice + bidingPriceZero;
      if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else if (bidPrice <= props.caratMinimumPrice) {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else {
        if (userCurrencyCode !== props.currency["code"]) {
          let conversion = new Intl.NumberFormat("us-en").format(
            Math.round(Number(bidPrice) * props.exchangeRates[0]["rate"])
          );
          setPreBidConversion(`${userCurrencySymbol}${conversion}`);
        } else {
          setPreBidConversion("");
        }

        setPreBiddingPrice(
          `${props.currency["symbol"]}${new Intl.NumberFormat("us-en").format(
            Number(bidPrice)
          )}`
        );
        setOpenPreBidPopUpModal(true);
      }
    } else {
      let bidPrice = biddingPrice + bidingPriceZero;
      if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else if (bidPrice <= props.caratMinimumPrice) {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else {
        if (userCurrencyCode !== props.currency["code"]) {
          let conversion = new Intl.NumberFormat("us-en").format(
            Math.round(Number(bidPrice) * props.exchangeRates[0]["rate"])
          );
          setPreBidConversion(`${userCurrencySymbol}${conversion}`);
        } else {
          setPreBidConversion("");
        }

        setPreBiddingPrice(
          `${props.currency["symbol"]}${new Intl.NumberFormat("us-en").format(
            Number(bidPrice)
          )}`
        );
        setOpenPreBidPopUpModal(true);
      }
    }
  };

  const preBidModalYesButtonPressed = () => {
    handlePreBidSubmit(eStore);
  };

  const handlePreBidSubmit = async (e: any) => {
    setTimeout(() => {
      setOpenToast(false);
    }, 3000);
    e.preventDefault();
    let bidingPriceZero = await getBracketValue();
    if (parcelType !== "Diamond") {
      let bidPrice = biddingPrice + bidingPriceZero;

      let letters = /^[0-9]+$/;
      if (bidPrice.match(letters) && bidPrice > props.caratMinimumPrice) {
        if (!oAuth()) {
          props.history.push("/");
        } else {
          Auth.postRequest(
            `${productUrl}/${props.auctionId}/${props.perId}/setPreBidPrice?amt=${bidPrice}`,
            "data",
            (status: number, response: any) => {
              setOpenToast(false);
              setOpenPreBidPopUpModal(false);
              if (status === 200) {
                setBiddingPrice("");
                setAlertType("success");
                setShowAlertMessage(response.data.message);
                setOpenToast(true);
                setIsPreBidDone(true);

                props.preBidAmountSaved(props.auctionId);
              } else if (status === 403) {
                setUserBlock(true);
              } else if (status === 404) {
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:invalidRequest")}`);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 412) {
                setTimeout(() => {
                  props.history.push("/preview");
                }, 2000);
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(response);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 505) {
                props.apiTimeout(true);
              } else {
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:somethingWentWrong")}`);
                setAlertType("error");
                setOpenToast(true);
              }
            }
          );
        }
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    } else {
      let bidPrice = biddingPrice + bidingPriceZero;
      let letters = /^[0-9]+$/;
      if (bidPrice.match(letters) && bidPrice > props.caratMinimumPrice) {
        if (!oAuth()) {
          props.history.push("/");
        } else {
          Auth.postRequest(
            `${productUrl}/${props.auctionId}/${props.perId}/setPreBidPrice?amt=${bidPrice}`,
            "data",
            (status: number, response: any) => {
              setOpenToast(false);
              setOpenPreBidPopUpModal(false);
              if (status === 200) {
                setBiddingPrice("");
                setAlertType("success");
                setShowAlertMessage(response.data.message);
                setOpenToast(true);
                setIsPreBidDone(true);

                props.preBidAmountSaved(props.auctionId);
              } else if (status === 403) {
                setUserBlock(true);
              } else if (status === 404) {
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:invalidRequest")}`);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 412) {
                setTimeout(() => {
                  props.history.push("/preview");
                }, 2000);
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(response);
                setAlertType("error");
                setOpenToast(true);
              } else if (status === 505) {
                props.apiTimeout(true);
              } else {
                setIsPreBidDone(false);
                setBiddingPrice("");
                setShowAlertMessage(`${t("common:somethingWentWrong")}`);
                setAlertType("error");
                setOpenToast(true);
              }
            }
          );
        }
      } else if (biddingPrice === "") {
        setAlertType("error");
        setShowAlertMessage(`${t("common:preview:preBidding:enterAmount")}`);
        setOpenToast(true);
      } else {
        setIsPreBidDone(false);
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      }
    }
  };

  const handleCancelSubmit = (e: any) => {
    e.preventDefault();
    setTimeout(() => {
      setOpenToast(false);
    }, 1000);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${props.auctionId}/${props.perId}/cancelPreBidPrice`,
        "data",
        (status: number, response: any) => {
          if (status === 200) {
            setIsPreBidDone(false);
            setShowAlertMessage("Pre-bid price removed successfully");
            setAlertType("success");
            setOpenToast(true);
            props.cancelPreBid(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setAlertType("error");
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOpenToast(true);
          } else if (status === 412) {
            setTimeout(() => {
              props.history.push("/preview");
            }, 2000);
            setIsPreBidDone(false);
            setBiddingPrice("");
            setShowAlertMessage(response);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            props.apiTimeout(true);
          } else {
            setShowAlertMessage(`${t("common:somethingWentWrong")}`);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const renderCurrencyCode = () => {
    let bidZeroText;
    const isJPY = props.currency["code"] === "JPY" ? true : false;
    const bidPrice = props.caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidZeroText = "000";
        } else if (bidPrice >= 3000000) {
          bidZeroText = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = "0";
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }

      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidZeroText = "000";
        } else if (bidPrice >= 3000000) {
          bidZeroText = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = "0";
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    }

    return (
      <>
        {props.currency && props.currency["code"] !== "JPY" ? (
          <>
            <StyledTextWrapper
              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {bidZeroText} $
            </StyledTextWrapper>
          </>
        ) : (
          <>
            <StyledTextWrapper
              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {bidZeroText} ¥
            </StyledTextWrapper>
          </>
        )}
      </>
    );
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any,
    parcelType: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price.replace(/[^0-9]/g, "") * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null ||
      parcelType === "Jewellery"
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(Math.round(amt));
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(Math.round(amt));
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  return (
    <StyledContainer
      container={true}
      spacing={0}
      className="PreBiddingContainer"
    >
      <Grid item={true} xs={12}>
        <StyledLabel>{t("common:preview:preBidding:preBidding")}</StyledLabel>
      </Grid>
      <Grid item={true} xs={12}>
        <form onSubmit={onSubmit}>
          <StyledGrid item={true} xs={12}>
            {parcelType === "Diamond" ? (
              <Grid item xs={12} lg={8} xl={9}>
                {props.preBidPrice === null ||
                  props.preBidPrice === undefined ? (
                  <StyledInputWrapper
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    {auctionState === "NotStarted" ||
                      auctionState === "" ||
                      auctionState === "undefined" ||
                      auctionState === null ? (
                      <Grid item xs={12}>
                        {isReadOnlyUser ? (
                          <StyledInput
                            disabled={true}
                            name="preBiddingPrice"
                            placeholder={`${t(
                              "common:preview:preBidding:placeholderText"
                            )}`}
                            value={biddingPrice}
                          />
                        ) : (
                          <>
                            {isAuctionUserId === false && props.count === 0 ? (
                              <StyledInput
                                type="number"
                                min="0"
                                name="preBiddingPrice"
                                id="preBiddingPrice"
                                placeholder={`${t(
                                  "common:preview:preBidding:placeholderText"
                                )}`}
                                onChange={handleChange}
                                value={biddingPrice}
                              />
                            ) : (
                              <StyledInput
                                disabled={true}
                                name="preBiddingPrice"
                                placeholder={`${t(
                                  "common:preview:preBidding:placeholderText"
                                )}`}
                                value={biddingPrice}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    ) : (
                      <StyledInput
                        disabled={true}
                        name="preBiddingPrice"
                        placeholder={`${t(
                          "common:preview:preBidding:placeholderText"
                        )}`}
                        value={biddingPrice}
                      />
                    )}
                    {renderCurrencyCode()}
                  </StyledInputWrapper>
                ) : (
                  <StyledInputWrapper>
                    <StyledInput
                      name="preBiddingPrice"
                      disabled={true}
                      placeholder={`${t(
                        "common:preview:preBidding:placeholderText"
                      )}`}
                      onChange={handleChange}
                      value={biddingPrice}
                    />
                    {renderCurrencyCode()}
                  </StyledInputWrapper>
                )}
              </Grid>
            ) : (
              <Grid item xs={12} lg={8} xl={9}>
                {props.preBidPrice === null ||
                  props.preBidPrice === undefined ? (
                  <StyledInputWrapper
                    onClick={() => {
                      renderReadOnlyUserToast();
                    }}
                  >
                    {auctionState === "NotStarted" ||
                      auctionState === "" ||
                      auctionState === "undefined" ||
                      auctionState === null ? (
                      <Grid item xs={12}>
                        {isReadOnlyUser ? (
                          <StyledInput
                            disabled={true}
                            name="preBiddingPrice"
                            placeholder={`${t(
                              "common:preview:preBidding:placeholderText"
                            )}`}
                            value={biddingPrice}
                            onClick={() => {
                              setOpenReadOnlyUserToast(true);
                            }}
                          />
                        ) : (
                          <>
                            {isAuctionUserId === false && props.count === 0 ? (
                              <StyledInput
                                type="number"
                                min="0"
                                name="preBiddingPrice"
                                id="preBiddingPrice"
                                placeholder={`${t(
                                  "common:preview:preBidding:placeholderText"
                                )}`}
                                onChange={handleChange}
                                value={biddingPrice}
                              />
                            ) : (
                              <StyledInput
                                disabled={true}
                                name="preBiddingPrice"
                                placeholder={`${t(
                                  "common:preview:preBidding:placeholderText"
                                )}`}
                                value={biddingPrice}
                              />
                            )}
                          </>
                        )}
                      </Grid>
                    ) : (
                      <StyledInput
                        disabled={true}
                        name="preBiddingPrice"
                        placeholder={`${t(
                          "common:preview:preBidding:placeholderText"
                        )}`}
                        value={biddingPrice}
                      />
                    )}
                    {renderCurrencyCode()}
                  </StyledInputWrapper>
                ) : (
                  <StyledInputWrapper>
                    <StyledInput
                      name="preBiddingPrice"
                      disabled={true}
                      placeholder={`${t(
                        "common:preview:preBidding:placeholderText"
                      )}`}
                      onChange={handleChange}
                      value={biddingPrice}
                    />
                    {renderCurrencyCode()}
                  </StyledInputWrapper>
                )}
              </Grid>
            )}
            <StyledButtonWrapper item xl={3} lg={4} sm={4} xs={4} md={4}>
              {props.preBidPrice === null || props.preBidPrice === undefined ? (
                <Grid>
                  {auctionState === "NotStarted" ||
                    auctionState === "" ||
                    auctionState === "undefined" ||
                    auctionState === null ? (
                    <Grid item xs={12}>
                      {isReadOnlyUser ? (
                        <StyledButton disabled={true}>
                          {t("common:button:preBid")}
                        </StyledButton>
                      ) : (
                        <>
                          {isAuctionUserId === false && props.count === 0 ? (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "200px",
                                alignItems: "center",
                              }}
                            >
                              <StyledButton
                                type="submit"
                                solid={true}
                                onSubmit={handlePreBidSubmit}
                              >
                                {t("common:button:preBid")}
                              </StyledButton>
                              <StyledLabelConversion>
                                {preBidConversion}
                              </StyledLabelConversion>
                            </div>
                          ) : (
                            <StyledButton disabled={true}>
                              {t("common:button:preBid")}
                            </StyledButton>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : (
                    <StyledButton disabled={true}>
                      {t("common:button:preBid")}
                    </StyledButton>
                  )}
                </Grid>
              ) : (
                <Grid>
                  {auctionState === "NotStarted" ||
                    auctionState === "" ||
                    auctionState === "undefined" ||
                    auctionState === null ? (
                    <Grid item xs={12}>
                      {isReadOnlyUser ? (
                        <StyledCancelButton disabled={true}>
                          {t("common:button:cancelPreBid")}
                        </StyledCancelButton>
                      ) : (
                        <>
                          {isAuctionUserId === false && props.count === 0 ? (
                            <StyledCancelButton
                              cancel={true}
                              onClick={handleCancelSubmit}
                            >
                              {t("common:button:cancelPreBid")}
                            </StyledCancelButton>
                          ) : (
                            <StyledCancelButton disabled={true}>
                              {t("common:button:cancelPreBid")}
                            </StyledCancelButton>
                          )}
                        </>
                      )}
                    </Grid>
                  ) : (
                    <StyledCancelButton disabled={true}>
                      {t("common:button:cancelPreBid")}
                    </StyledCancelButton>
                  )}
                </Grid>
              )}
            </StyledButtonWrapper>
          </StyledGrid>
        </form>
      </Grid>
      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
      {openToast === true && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal {...props} closeModal={closeModal} />
        </StyledModal>
      )}
      {openPreBidPopUpModal && (
        <StyledModal open={openPreBidPopUpModal}>
          <PreBidConfirmationPopup
            {...props}
            message={t("common:preview:preBidding:preBidPopUpMessage") + ": "}
            biddingAmount={preBiddingPrice}
            conversionAmount={
              preBidConversion === "" ? "" : `(${preBidConversion})`
            }
            onConfirmModal={preBidModalYesButtonPressed}
            onCancelModal={() => setOpenPreBidPopUpModal(false)}
            caratAmount={convertedAmt(
              props.currency,
              preBiddingPrice ?? 0,
              props?.exchangeRates,
              Number(props.carat),
              props.parcelType
            )}
          />
        </StyledModal>
      )}
    </StyledContainer>
  );
};
export default PreBiddingForm;
