import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { buttonGradient } from "../../../common/materials/LinearGradient";

const StyledComponent = styled(Grid)`
  outline: none;
  .text {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 2em;
    margin-bottom: 10px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
  }
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 350px;
  text-align: center;
  padding: 1.5em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 660px;
  }
`;

const StyledTextWrapper = styled.div`
  display: block;
  text-align: center;
  align-items: center;
  font-size: 1.5em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledAuctionEndedWrapper = styled(Grid)`
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 10px 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    padding: 10px 20px;
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 0.8em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  // background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  width: 80px;
  padding: 0.5em 0.5em;
  padding-top: 0.8em;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 10px;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledDescription = styled.span`
  font-weight: 600;
  display: contents;
`;

const StyledAlertMsg = styled.span`
  font-size: 0.7em;
  color: red;
  font-family: Calibre-SemiBold;
`;

const StyledLabelConversion = styled.label`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
  display: inline-block;
`;

const StyledAmountField = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export {
  StyledButton,
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledWrapper,
  StyledComponent,
  StyledDescription,
  StyledAlertMsg,
  StyledLabelConversion,
  StyledAmountField,
};
