
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getDelete, getRead } from "../../config/app.endpoints";
import AuthService from "../../../components/auth/AuthService";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PubSub from 'pubsub-js';

const StyledOptionMenuWrapper = styled.div`
  color: white;
  font-size: 11px;
  display: flex;
  text-align: center;
`;

const StyledIcon = styled.div`
  
`;

export interface IProps {
  itemID: any;
  updatedViaFlotingDotsNotification: any;
  readTrue: any;
}

export default function FlotingDots(props: IProps) {
  const { itemID, readTrue } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const Auth = new AuthService();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectOptionforMarkDefault = (id: any) => {
    let data: any = { id };
    Auth.postRequest(`${getRead}=${id}`, data, (status: any, response: any) => {
      if (status === 200) {
       props.updatedViaFlotingDotsNotification();
      } 
    });
    handleClose();
    props.updatedViaFlotingDotsNotification();   
  };

  const selectOption = (id: any) => {
    let datas: any = { id };
    Auth.postRequest(
      `${getDelete}=${id}`,
      datas,
      (status: any, response: any) => {
        if (status === 200) {
          props.updatedViaFlotingDotsNotification();
          PubSub.publish("refreshNetworkPage", true);
        } 
      }
    );    
    handleClose();
    props.updatedViaFlotingDotsNotification();
  };

  return (
    <StyledOptionMenuWrapper>
      <IconButton onClick={handleClick} style={{ padding: "0px" }}>
        <StyledIcon className="center">
          <MoreVertIcon />
        </StyledIcon>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        style={{ fontSize: "14px" }}
      >
        {readTrue === false ? (
          <MenuItem
            onClick={() => selectOptionforMarkDefault(itemID)}
            style={{ fontSize: "14px" }}
          >
            {t("common:notificaiton:markAsRead")}
          </MenuItem>
        ) : (
          <></>
        )}
        <MenuItem
          onClick={() => selectOption(itemID)}
          style={{ fontSize: "14px" }}
        >
          {t("common:profile:optionMenu:delete")}
        </MenuItem>
      </Menu>
    </StyledOptionMenuWrapper>
  );
}