export const cutOptions = [
    { value: '****', label: '****' },
    { value: 'Excellent', label: 'Excellent' },
    { value: 'Fair', label: 'Fair' },
    { value: 'Good', label: 'Good' },
    { value: 'Very Good', label: 'Very Good' },
    { value: 'Poor', label: 'Poor' }
]
export const polistAndSymmetryOptions = [
    { value: 'EX', label: 'Excellent' },
    { value: 'FR', label: 'Fair' },
    { value: 'GD', label: 'Good' },
    { value: 'VG', label: 'Very Good' },
    { value: 'PR', label: 'Poor' }
]