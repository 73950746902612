import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import SelectDateCalendar from '../../products/invoice/SelectDateCalendar';
import PageSizeSelector from '../grid-actions/ActionPageSizeSelector';
import ActionPaginationForProducts from '../grid-actions/ActionPaginationForProducts';

const StyledCoreDataGridContainer = styled(Grid)``;

const StyledDataWrapper = styled(Grid)`
    margin-top: 15px;
`;

const StyledGridWrapper = styled.div`
`;

const StyledPaginationContainer = styled(Grid)`
`;

const StyledPaginationWrapper = styled(Grid)`
    text-align: right;
`;

const StyledActionBarContainer = styled(Grid)`
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
    &:focus {
        outline: none;
    }
`;

const StyledCalendarContainer = styled(Grid)`
  display: flex;
  align-items: center;
`;

const StyledSelectCalendarContainer = styled(Grid)`
    border-radius: 5px;
    padding: 5px;
`;

interface IProps {
  t: any;
  data?: any;
  selectedDates: (startDate: Date | null, endDate: Date | null) => void;
  totalElements: number;
  value: (value: boolean, count: string | number, itemsPerPage: number) => void;
  isShowPage: boolean;
  itemPerPage: (value: boolean, count: number, itemsPerPage: number) => void;
}

interface IState {
    data: any,
    loading: boolean;
    currentPage: number;
    itemsPerPage: number;
}

const childElementsWithProps = (props: any) => {
    return React.cloneElement(props.children, { ...props });
};

export default class CoreDataofInvoiceProductAuction extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            currentPage: 1,
            itemsPerPage: 20
        }
    }

    async componentDidMount() {
        this.setData(this.props.data);
    }

    async componentDidUpdate(prevProps: any) {
        if (prevProps.data !== this.props.data) {
            this.setData(this.props.data);
        }
    }

    setData(data: any) {
        this.setState(() => ({
            data
        }));
    }

    setCurrentPage(currentPage: number) {
        this.setState(() => ({
            currentPage
        }));
    }

    setItemsPerPage(itemsPerPage: number) {
        this.setState(() => ({
            itemsPerPage
        }));
        this.props.itemPerPage(true, 0, itemsPerPage);
    }

    setItemsPerPageHandler = (itemsPerPage: number) => {
        this.setCurrentPage(1);
        this.setItemsPerPage(itemsPerPage);
    }

    paginate = (pageNumber: number) => {
        this.setCurrentPage(pageNumber);
    }

    onSelectDate = (startDate: Date, endDate: Date) => {
        if (startDate !== null && endDate !== null) {
            this.props.selectedDates(startDate, endDate)
        }
        else if (startDate === null && endDate === null) {
            this.props.selectedDates(null, null)
        }
    }

    forApiCalling = (value: boolean, currentPage: number) => {
        var count = (currentPage - 1) * this.state.itemsPerPage;
        this.props.value(value, count, this.state.itemsPerPage)
    }

    render() {
        const { t } = this.props;
        const selectedPage = true;
        let currentRecords = [];
        if (this.state.data.length > 0) {
            currentRecords = this.state.data;
        }
        return (
            <StyledCoreDataGridContainer container={true} spacing={0}>
                <StyledActionBarContainer container={true} spacing={0} className="coreActionBarContainer">
                    <StyledCalendarContainer item md={9} xs={12} >
                        <StyledSelectCalendarContainer>
                            <SelectDateCalendar dates={this.onSelectDate} t={t} />
                        </StyledSelectCalendarContainer>
                    </StyledCalendarContainer>
                    <StyledPaginationWrapper item={true} xs={12} md={3}>
                        <PageSizeSelector setItemsPerPageHandler={this.setItemsPerPageHandler} t={t} />
                    </StyledPaginationWrapper>
                </StyledActionBarContainer>
                <StyledDataWrapper item={true} xs={12}>
                    <StyledGridWrapper>
                        {childElementsWithProps({ selectedPage, currentRecords, ...this.props })}
                    </StyledGridWrapper>
                </StyledDataWrapper>
                <StyledPaginationContainer item={true} xs={12}>
                   {this.props.isShowPage === false ? <ActionPaginationForProducts
                        t={t}
                        totalElements={this.props.totalElements}
                        callbackForApiCalling={this.forApiCalling}
                        currentPage={this.state.currentPage}
                        itemsPerPage={this.state.itemsPerPage}
                        paginateCallback={this.paginate}
                    />: <></>}
                </StyledPaginationContainer>
            </StyledCoreDataGridContainer>
        )
    }
}
