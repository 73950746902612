import React, { ChangeEvent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";

import AuthService from "../../auth/AuthService";
import { productUrl } from "../../../common/config/app.endpoints";
import Alert from "../../../common/elements/Alert";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import BlockUserModal from "../BlockUser/BlockUserModal";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";

const StyledContainer = styled(Grid)`
  padding: 10px;
`;

const StyledLabel = styled.label`
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 1em;
  padding: 5px 0;
  display: inline-block;
`;

const StyledInput = styled.input`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  padding: 7px;
  width: 100%;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.black};
  font-size: 0.8em;
`;

interface IButtonProps {
  disabled?: boolean;
  solid?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  padding: 8px 12px;
  border-radius: 3px;
  border: none;
  font-size: 0.8em;
  margin-left: 2px;
  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
      background: ${(props) => props.theme.colors.base.lightGrey};
      color: ${(props) => props.theme.colors.base.black};
    `}
  ${(props) =>
    props.solid &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
`;
interface IProps extends IRouterComponentProps {
  t: any;
  perId: string | number;
  auctionId: string | number;
  noteSaved: () => void;
  apiTimeout: (timeout: boolean) => void;
  sellerAuctionUserId: string;
  auctionState: string;
  count: number;
  auctionType: any;
  isReadOnlyUser: boolean;
  isSeller?: boolean;
  serialNo: any;
}

let joinedAuctions: any = [];
let redirectFromLive: any;
const DetailsNotes = (props: IProps) => {
  const [note, setNote] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const Auth = new AuthService();
  const { t, auctionType, isReadOnlyUser } = props;

  useEffect(() => {
    if (props.isSeller) {
      setIsAuctionUserId(true);
    }
    joinedAuctions = localStorage.getItem("joinedAuctions");
    if (joinedAuctions !== null) {
      joinedAuctions = JSON.parse(joinedAuctions);
      if (props.sellerAuctionUserId !== null) {
        joinedAuctions.filter((items: any, index: number) => {
          if (props.sellerAuctionUserId === items.auctionUserId) {
            setIsAuctionUserId(true);
          }
        });
      }
    }
    const urlParams = new URLSearchParams(window.location.search);
    redirectFromLive = urlParams.get("redirectFromLive");
    if (redirectFromLive === "true") {
      setDisableButton(true);
    }
    setNote("");
    return () => {
      setNote("");
      setIsAuctionUserId(false);
    };
  }, []);

  useEffect(() => {
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (props.sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    return () => setIsAuctionUserId(false);
  }, [props.perId]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNote(e.target.value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setOpenToast(false);
    let notes = note.trim();
    const data = {
      note: notes,
    };
    if (notes === "") {
      setAlertType("error");
      setShowAlertMessage(`${t("common:preview:notes:enterNote")}`);
      setOpenToast(true);
    } else {
      if (!oAuth()) {
        props.history.push("/");
      } else {
        if (props.auctionState === "Locked") {
          setAlertType("error");
          setShowAlertMessage(`Not able to add note in locked state.`);
          setOpenToast(true);
          setTimeout(() => {
            setOpenToast(false);
          }, 2000);
        } else {
          Auth.postRequest(
            `${productUrl}/${props.auctionId}/${props.perId}/addNote/${props.serialNo}`,
            data,
            (status: number, response: any) => {
              if (status === 200) {
                setNote("");
                setAlertType("success");
                setShowAlertMessage(response.data.message);
                setOpenToast(true);
                props.noteSaved();
              } else if (status === 403) {
                setUserBlock(true);
              } else if (status === 404) {
                setNote("");
                setAlertType("error");
                setShowAlertMessage(`${t("common:invalidRequest")}`);
                setOpenToast(true);
              } else if (status === 412) {
                setTimeout(() => {
                  props.history.push("/preview");
                }, 2000);
                setNote("");
                setAlertType("error");
                setShowAlertMessage(response);
                setOpenToast(true);
              } else if (status === 505) {
                props.apiTimeout(true);
              } else {
                setNote("");
                setAlertType("error");
                setShowAlertMessage(`${t("common:somethingWentWrong")}`);
                setOpenToast(true);
              }
            }
          );
        }
      }
    }
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  useEffect(() => {
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  }, [openReadOnlyUserToast]);

  return (
    <StyledContainer container={true} spacing={0} className="notesContainer">
      <Grid item={true} xs={12}>
        <StyledLabel>{t("common:preview:notes:notes")}</StyledLabel>
      </Grid>
      <Grid item={true} xs={12}>
        <form onSubmit={handleSubmit}>
          <Grid container={true} spacing={0} className="notesContainer">
            {auctionType === "Popcorn_Bidding" ? (
              <>
                <Grid item={true} xs={9} lg={10}>
                  {isReadOnlyUser ? (
                    <div
                      onClick={() => {
                        setOpenReadOnlyUserToast(true);
                      }}
                    >
                      <StyledInput
                        type="text"
                        title={`${t("common:preview:notes:notesValidation")}`}
                        maxLength={200}
                        disabled={true}
                        name="notes"
                        placeholder={`${t(
                          "common:preview:notes:placeholderText"
                        )}`}
                        value={note}
                      />
                    </div>
                  ) : (
                    <>
                      {isAuctionUserId === false &&
                      props.count === 0 &&
                      disableButton === false ? (
                        <>
                          {props.auctionState === "NotStarted" ? (
                            <StyledInput
                              title={`${t(
                                "common:preview:notes:notesValidation"
                              )}`}
                              type="text"
                              maxLength={200}
                              name="notes"
                              placeholder={`${t(
                                "common:preview:notes:placeholderText"
                              )}`}
                              onChange={handleChange}
                              value={note}
                            />
                          ) : (
                            <StyledInput
                              title={`${t(
                                "common:preview:notes:notesValidation"
                              )}`}
                              type="text"
                              maxLength={200}
                              name="notes"
                              placeholder={`${t(
                                "common:preview:notes:placeholderText"
                              )}`}
                              disabled={true}
                              value={note}
                            />
                          )}
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            setOpenReadOnlyUserToast(true);
                          }}
                        >
                          <StyledInput
                            type="text"
                            title={`${t(
                              "common:preview:notes:notesValidation"
                            )}`}
                            maxLength={200}
                            disabled={true}
                            name="notes"
                            placeholder={`${t(
                              "common:preview:notes:placeholderText"
                            )}`}
                            value={note}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item={true} xs={3} lg={2}>
                  <Grid item xs={12}>
                    {isReadOnlyUser ? (
                      <div
                        onClick={() => {
                          setOpenReadOnlyUserToast(true);
                        }}
                      >
                        <StyledButton disabled={true}>
                          {t("common:preview:notes:save")}
                        </StyledButton>
                      </div>
                    ) : (
                      <>
                        {isAuctionUserId === false && props.count === 0 ? (
                          <>
                            {props.auctionState === "NotStarted" ? (
                              <StyledButton solid={true} onClick={handleSubmit}>
                                {t("common:preview:notes:save")}
                              </StyledButton>
                            ) : (
                              <StyledButton disabled={true}>
                                {t("common:preview:notes:save")}
                              </StyledButton>
                            )}
                          </>
                        ) : (
                          <StyledButton disabled={true}>
                            {t("common:preview:notes:save")}
                          </StyledButton>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item={true} xs={9} lg={10}>
                  {isReadOnlyUser ? (
                    <div
                      onClick={() => {
                        setOpenReadOnlyUserToast(true);
                      }}
                    >
                      <StyledInput
                        type="text"
                        title={`${t("common:preview:notes:notesValidation")}`}
                        maxLength={200}
                        disabled={true}
                        name="notes"
                        placeholder={`${t(
                          "common:preview:notes:placeholderText"
                        )}`}
                        value={note}
                      />
                    </div>
                  ) : (
                    <>
                      {isAuctionUserId === false &&
                      props.count === 0 &&
                      disableButton === false ? (
                        <>
                          {props.auctionState === "NotStarted" ? (
                            <StyledInput
                              title={`${t(
                                "common:preview:notes:notesValidation"
                              )}`}
                              type="text"
                              maxLength={200}
                              name="notes"
                              placeholder={`${t(
                                "common:preview:notes:placeholderText"
                              )}`}
                              onChange={handleChange}
                              value={note}
                            />
                          ) : (
                            <StyledInput
                              title={`${t(
                                "common:preview:notes:notesValidation"
                              )}`}
                              type="text"
                              maxLength={200}
                              name="notes"
                              placeholder={`${t(
                                "common:preview:notes:placeholderText"
                              )}`}
                              disabled={false}
                              value={note}
                            />
                          )}
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            setOpenReadOnlyUserToast(true);
                          }}
                        >
                          <StyledInput
                            type="text"
                            title={`${t(
                              "common:preview:notes:notesValidation"
                            )}`}
                            maxLength={200}
                            disabled={true}
                            name="notes"
                            placeholder={`${t(
                              "common:preview:notes:placeholderText"
                            )}`}
                            value={note}
                          />
                        </div>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item={true} xs={3} lg={2}>
                  <Grid item xs={12}>
                    {isReadOnlyUser ? (
                      <div
                        onClick={() => {
                          setOpenReadOnlyUserToast(true);
                        }}
                      >
                        <StyledButton disabled={true}>
                          {t("common:preview:notes:save")}
                        </StyledButton>
                      </div>
                    ) : (
                      <>
                        {isAuctionUserId === false && props.count === 0 ? (
                          <>
                            {disableButton ? (
                              <StyledButton disabled={true}>
                                {t("common:preview:notes:save")}
                              </StyledButton>
                            ) : (
                              <>
                                {props.auctionState === "NotStarted" ? (
                                  <StyledButton
                                    solid={true}
                                    onClick={handleSubmit}
                                  >
                                    {t("common:preview:notes:save")}
                                  </StyledButton>
                                ) : (
                                  <StyledButton disabled={true}>
                                    {t("common:preview:notes:save")}
                                  </StyledButton>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <div
                            onClick={() => {
                              setOpenReadOnlyUserToast(true);
                            }}
                          >
                            <StyledButton disabled={true}>
                              {t("common:preview:notes:save")}
                            </StyledButton>
                          </div>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </Grid>
      {openToast === true && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}

      {openReadOnlyUserToast === true && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal {...props} closeModal={closeModal} />
        </StyledModal>
      )}
    </StyledContainer>
  );
};
export default DetailsNotes;
