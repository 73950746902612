import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api from "../../../services/ApiService";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import {
  profileUrl,
  changePasswordUrl,
  changePlanUrl,
  bankDetailUrl,
  markAsDefaultBankDetailsUrl,
  deleteBankDetailsUrl,
  shippingAddressUrl,
  markAsDefaultShippingAddresssUrl,
  deleteShippingAddress as deleteShippingAddressUrl,
  updateProfileInfo,
  getTimeZones as getTimeZonesUrl,
  preferredlanguageUrl,
  currentTimeUrl,
  timeDifferenceUrl,
  forgotPasswordUrl,
  resetPasswordUrl,
} from "../../../common/config/app.endpoints";

import {
  Token,
  User,
  UserSliceState,
  IChangePasswordRequest,
  IBankDetailsRequest,
  IDefaultBankRequest,
  IDeleteBankDetails,
  IShippingAddressRequest,
  IDefaulShippingAddressRequest,
  IDeleteShippingAddressRequest,
  IPrefferedLanguageRequest,
  ITimeDifferenceRequest,
  IForgotPasswordRequest,
  IResetPasswordRequest,
} from "./userSliceInterface";

const userData = {
  id: "",
  perId: "",
  uniqueId: "",
  firstName: "",
  lastName: "",
  email: "",
  occupations: null,
  phone: "",
  companyId: "",
  companyName: "",
  companySubdomain: "",
  guarantorName: "",
  guarantorAddress: "",
  address: "",
  city: "",
  state: "",
  country: "",
  countryDetail: {
    perId: "",
    name: "",
    code: "",
  },
  preferredCurrency: {
    perId: "",
    name: "",
    symbol: "",
    code: "",
  },
  timezone: {
    perId: "",
    name: "",
    fullName: "",
    shortName: "",
    gmtOffset: 0,
    abbreviation: "",
  },
  shippingAddresses: null,
  bankDetails: null,
  preferredLanguageCode: "",
  modeOfCommunication: null,
  userType: "",
  subscriptionStartDate: "",
  subscriptionEndDate: "",
  profileComplete: false,
};

const initialState: UserSliceState = {
  loading: false,
  isLoggedIn: false,
  token: "",
  userData: userData,
  timezones: {},
  currentTimeInMillis: 0,
  timeDifference: 0,
  notSyncedTime: 0,
  timeInMilliseconds: 0,
  clockNotSynced: false,
  email: "",
};

const getShuffledArray = (newArr: any, key: string) => {
  let arr: any = [];
  if (newArr !== null && newArr?.length > 0) {
    arr = [...newArr];
    arr.forEach((item: any, index: number) => {
      if (arr.length > 0 && item[key] === true) {
        arr.splice(index, 1);
        arr.unshift(item);
      }
    });
  }
  return arr;
};

const setUserData = (state: any, action: any) => {
  const userData = action.payload;
  const shuffledBankDetails = getShuffledArray(userData.bankDetails, "primary");
  const shuffledShippingAddresses = getShuffledArray(
    userData.shippingAddresses,
    "primary"
  );
  state.userData = {
    id: userData.id,
    perId: userData.perId,
    uniqueId: userData.uniqueId,
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    occupations: userData.occupations,
    phone: userData.phone,
    companyId: userData.companyId,
    companyName: userData.companyName,
    companySubdomain: userData.companySubdomain,
    guarantorName: userData.guarantorName,
    guarantorAddress: userData.guarantorAddress,
    address: userData.address,
    city: userData.city,
    state: userData.state,
    country: userData.country,
    countryDetail: userData.countryDetail,
    preferredCurrency: userData.preferredCurrency,
    timezone: userData.timezone,
    shippingAddresses: shuffledShippingAddresses,
    bankDetails: shuffledBankDetails,
    preferredLanguageCode: userData.preferredLanguageCode,
    modeOfCommunication: userData.modeOfCommunication,
    userType: userData.userType,
    subscriptionStartDate: userData.subscriptionStartDate,
    subscriptionEndDate: userData.subscriptionEndDate,
    profileComplete: userData.profileComplete,
  };
};

export const getUserProfile = createAsyncThunk<
  User,
  Partial<User>,
  { rejectValue: any }
>("user/getProfile", async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(profileUrl);
    const finalresponse = { id: response.data.perId, ...response.data };
    return finalresponse;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const changePassword = createAsyncThunk<
  IChangePasswordRequest,
  Partial<IChangePasswordRequest>,
  { rejectValue: any }
>("user/changePassword", async (data, { rejectWithValue }) => {
  try {
    const { oldPassword, newPassword } = data;
    const url = `${changePasswordUrl}oldPassword=${oldPassword}&newPassword=${newPassword}`;
    const response = await api.post(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const changePlan = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("user/changePlan", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(changePlanUrl);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const addBankDetails = createAsyncThunk<
  IBankDetailsRequest,
  Partial<IBankDetailsRequest>,
  { rejectValue: any }
>("user/addBankDetails", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(bankDetailUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const markAsDefaultBank = createAsyncThunk<
  IDefaultBankRequest,
  Partial<IDefaultBankRequest>,
  { rejectValue: any }
>("user/markAsDefaultBank", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(markAsDefaultBankDetailsUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteBankDetails = createAsyncThunk<
  IDeleteBankDetails,
  Partial<IDeleteBankDetails>,
  { rejectValue: any }
>("user/deleteBankDetails", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(deleteBankDetailsUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const addShippingAddress = createAsyncThunk<
  IShippingAddressRequest,
  Partial<IShippingAddressRequest>,
  { rejectValue: any }
>("user/addShippingAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(shippingAddressUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const markAsDefaultShippingAddress = createAsyncThunk<
  IDefaulShippingAddressRequest,
  Partial<IDefaulShippingAddressRequest>,
  { rejectValue: any }
>("user/markAsDefaultShippingAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(markAsDefaultShippingAddresssUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const deleteShippingAddress = createAsyncThunk<
  IDeleteShippingAddressRequest,
  Partial<IDeleteShippingAddressRequest>,
  { rejectValue: any }
>("user/deleteShippingAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(deleteShippingAddressUrl, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateProfile = createAsyncThunk<
  IBankDetailsRequest,
  Partial<IBankDetailsRequest>,
  { rejectValue: any }
>("user/updateProfile", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(updateProfileInfo, data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getTimezones = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("user/getTimezones", async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(getTimeZonesUrl);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updatePrefferedlanguage = createAsyncThunk<
  IPrefferedLanguageRequest,
  Partial<IPrefferedLanguageRequest>,
  { rejectValue: any }
>("user/updatePrefferedlanguage", async (data, { rejectWithValue }) => {
  try {
    const { lang } = data;
    const response = await api.post(`${preferredlanguageUrl}/${lang}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getCurrentTimeInMillis = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("user/getCurrentTimeInMillis", async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(`${currentTimeUrl}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getTimeDifference = createAsyncThunk<
  ITimeDifferenceRequest,
  Partial<ITimeDifferenceRequest>,
  { rejectValue: any }
>("user/getTimeDifference", async (requestObj, { rejectWithValue }) => {
  try {
    const { timezone } = requestObj;
    const url = `${timeDifferenceUrl}${timezone}&userTimeMillis=${Date.now()}`;
    const response = await api.get(`${url}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const forgotPassword = createAsyncThunk<
  IForgotPasswordRequest,
  Partial<IForgotPasswordRequest>,
  { rejectValue: any }
>("user/forgotPassword", async (requestObj, { rejectWithValue }) => {
  try {
    const { email } = requestObj;
    const url = `${forgotPasswordUrl}?email=${email}`;
    const response = await api.get(`${url}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const resetPassword = createAsyncThunk<
  IResetPasswordRequest,
  Partial<IResetPasswordRequest>,
  { rejectValue: any }
>("user/resetPassword", async (requestObj, { rejectWithValue }) => {
  try {
    const { email, newPassword, otp } = requestObj;
    const url = `${resetPasswordUrl}?email=${email}&newPassword=${newPassword}&otp=${otp}`;
    const response = await api.get(`${url}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setJwtToken(state, action: PayloadAction<Token>) {
      const data = action.payload;
      state.token = data.token;
      state.isLoggedIn = true;
    },
    setUser(state, action: PayloadAction<User>) {
      setUserData(state, action);
    },
    logOut(state) {
      state.isLoggedIn = false;
      state.token = "";
      state.userData = userData;
    },
    redirectToLogin(state, action: PayloadAction<object>) {
      // redirect to login
    },
    setUserEmail(state, action: any) {
      state.email = action.payload;
    },
  },
  extraReducers: (builder: any) => {
    // Get user profile
    builder.addCase(getUserProfile.pending, genericPendingCaseHandler);
    builder.addCase(
      getUserProfile.fulfilled,
      (state: any, action: PayloadAction<User>) => {
        setUserData(state, action);
        state.loading = false;
      }
    );
    builder.addCase(getUserProfile.rejected, genericRejectedCaseHandler);
    // Change password
    builder.addCase(changePassword.pending, genericPendingCaseHandler);
    builder.addCase(changePassword.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(changePassword.rejected, genericRejectedCaseHandler);
    // Change plan
    builder.addCase(changePlan.pending, genericPendingCaseHandler);
    builder.addCase(changePlan.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(changePlan.rejected, genericRejectedCaseHandler);
    // Add Bank Details
    builder.addCase(addBankDetails.pending, genericPendingCaseHandler);
    builder.addCase(addBankDetails.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(addBankDetails.rejected, genericRejectedCaseHandler);
    // Mark as default bank
    builder.addCase(markAsDefaultBank.pending, genericPendingCaseHandler);
    builder.addCase(markAsDefaultBank.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(markAsDefaultBank.rejected, genericRejectedCaseHandler);
    // Delete bank
    builder.addCase(deleteBankDetails.pending, genericPendingCaseHandler);
    builder.addCase(deleteBankDetails.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(deleteBankDetails.rejected, genericRejectedCaseHandler);
    // Add shipping address
    builder.addCase(addShippingAddress.pending, genericPendingCaseHandler);
    builder.addCase(addShippingAddress.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(addShippingAddress.rejected, genericRejectedCaseHandler);
    // Mark as default shipping address
    builder.addCase(
      markAsDefaultShippingAddress.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      markAsDefaultShippingAddress.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(
      markAsDefaultShippingAddress.rejected,
      genericRejectedCaseHandler
    );
    // Delete shipping address
    builder.addCase(deleteShippingAddress.pending, genericPendingCaseHandler);
    builder.addCase(
      deleteShippingAddress.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(deleteShippingAddress.rejected, genericRejectedCaseHandler);
    // Update user profile
    builder.addCase(updateProfile.pending, genericPendingCaseHandler);
    builder.addCase(updateProfile.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(updateProfile.rejected, genericRejectedCaseHandler);
    // Get timezones
    builder.addCase(getTimezones.pending, genericPendingCaseHandler);
    builder.addCase(
      getTimezones.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.timezones = action.payload;
      }
    );
    builder.addCase(getTimezones.rejected, genericRejectedCaseHandler);
    // Set Preffered language
    builder.addCase(updatePrefferedlanguage.pending, genericPendingCaseHandler);
    builder.addCase(
      updatePrefferedlanguage.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(
      updatePrefferedlanguage.rejected,
      genericRejectedCaseHandler
    );
    // Get Current Time In Milliseconds
    builder.addCase(getCurrentTimeInMillis.pending, genericPendingCaseHandler);
    builder.addCase(
      getCurrentTimeInMillis.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.currentTimeInMillis = action.payload;

        // application business logic for time
        const clockTime = (Date.now() - state.currentTimeInMillis) / 1000;
        state.timeInMilliseconds = Date.now() - state.currentTimeInMillis;
        state.notSyncedTime = clockTime;
        if (clockTime < -3 || clockTime > 3) {
          state.clockNotSynced = true;
        } else {
          state.clockNotSynced = false;
        }
      }
    );
    builder.addCase(
      getCurrentTimeInMillis.rejected,
      genericRejectedCaseHandler
    );
    // getTimeDifference
    builder.addCase(getTimeDifference.pending, genericPendingCaseHandler);
    builder.addCase(
      getTimeDifference.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.timeDifference = action.payload;
      }
    );
    builder.addCase(getTimeDifference.rejected, genericRejectedCaseHandler);
    // forgotPassword
    builder.addCase(forgotPassword.pending, genericPendingCaseHandler);
    builder.addCase(forgotPassword.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(forgotPassword.rejected, genericRejectedCaseHandler);
    // resetPassword
    builder.addCase(resetPassword.pending, genericPendingCaseHandler);
    builder.addCase(resetPassword.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(resetPassword.rejected, genericRejectedCaseHandler);
  },
});

export const { setJwtToken, setUser, logOut, setUserEmail } = userSlice.actions;

export default userSlice.reducer;

// selectors
export const selectUserProfileLoading = (state: RootState) =>
  state.user.loading;
export const currentUserSelector = (state: RootState) => state.user.userData;
export const isLoggedInSelector = (state: RootState) => state.user.isLoggedIn;
export const userFirstNameSelector = (state: RootState) =>
  state.user.userData.firstName;
export const userLastNameSelector = (state: RootState) =>
  state.user.userData.lastName;
export const userTypeSelector = (state: RootState) =>
  state.user.userData.userType;
export const profileCompleteSelector = (state: RootState) =>
  state.user.userData.profileComplete;
export const timezoneNameSelector = (state: RootState) =>
  state.user.userData.timezone.name;
export const preferredCurrencySelector = (state: RootState) =>
  state.user.userData.preferredCurrency;
export const preferredCurrencyCodeSelector = (state: RootState) =>
  state.user.userData.preferredCurrency.code;
export const preferredCurrencySymbolSelector = (state: RootState) =>
  state.user.userData.preferredCurrency.symbol;
export const preferredLanguageCodeSelector = (state: RootState) =>
  state.user.userData.preferredLanguageCode;
export const shippingAddressSelector = (state: RootState) =>
  state.user.userData.shippingAddresses;
export const bankDetailsSelector = (state: RootState) =>
  state.user.userData.bankDetails;

// Current time in milliseconds
export const selectCurrentTimeInMillis = (state: RootState) =>
  state.user.currentTimeInMillis;
export const selectTimeDifference = (state: RootState) =>
  state.user.timeDifference;
export const selectNotSyncedTime = (state: RootState) =>
  state.user.notSyncedTime;
export const selectTimeInMilliseconds = (state: RootState) =>
  state.user.timeInMilliseconds;
export const selectClockNotSynced = (state: RootState) =>
  state.user.clockNotSynced;

// Timezones List
export const timezonesSelector = (state: RootState) => state.user.timezones;

// Email
export const selectEmail = (state: RootState) => state.user.email;
