import React, { useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import PageSizeSelector from "../grid-actions/ActionPageSizeSelector";
import ActionPaginationForProducts from "../grid-actions/ActionPaginationForProducts";
import ItemsCountofProduct from "../grid-actions/ItemsCountofProduct";
import { productDownload } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import {
  StyledBackBtn,
  StyledBackImageContianer,
} from "./CoreDataGridOfJewelleryWatchlist";

const StyledCoreDataGridContainer = styled(Grid)``;

const StyledDataWrapper = styled(Grid)`
  margin-top: 15px;
`;

const StyledGridWrapper = styled.div``;

const StyledPaginationContainer = styled(Grid)`
  margin-top: 1em;
  padding: 20px 0;
`;

const StyledActionsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  grid-gap: 5px;
`;

const StyledActionBarContainer = styled(Grid)`
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  &:focus {
    outline: none;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  grid-gap: 5px;
  align-items: center;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.9em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
  }
`;

const StyledPageSizeSelectorContainer = styled.div`
  border: none;
  select {
    padding: 0.8em 0.5em;
    margin: 0.3em;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    border-radius: 3px;
    font-size: 12px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.base.grey};
    width: 100%;

    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.5em 0.3em;
      margin: 0.2em;
    }
  }
`;

interface IProps {
  t?: any;
  data?: any;
  totalElements?: string | number | undefined;
  value: (value: boolean, count: number, itemsPerPage: number) => void;
  itemPerPage: (value: boolean, count: number, itemsPerPage: number) => void;
  handleBackBtns: any;
}

interface IState {
  data: any;
  loading: boolean;
  currentPage: number;
  itemsPerPage: number;
}
const showCarat: any = true;
const childElementsWithProps = (props: any) => {
  return React.cloneElement(props.children, { ...props });
};

export default class CoreDataGridofProduct extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      itemsPerPage: 20,
    };
  }

  Auth = new AuthService();

  async componentDidMount() {
    let Page = localStorage.getItem("currentStart");
    if (Page != null && Page !== "0") {
      let pageNumber: number = parseInt(Page) / 20 + 1;
      this.setCurrentPage(pageNumber);
    }
    if (localStorage.getItem("itemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      this.setState({
        itemsPerPage: savedCount,
      });
    }
    this.setData(this.props.data);
  }

  async componentDidUpdate(prevProps: any) {
    if (
      localStorage.getItem("currentStart") != null &&
      localStorage.getItem("currentStart") === "0" &&
      this.state.currentPage !== 1
    ) {
      this.setCurrentPage(1);
    }
    if (prevProps.data !== this.props.data) {
      this.setData(this.props.data);
    }
  }

  setData(data: any) {
    this.setState(() => ({
      data,
    }));
  }

  setCurrentPage(currentPage: number) {
    this.setState(() => ({
      currentPage,
    }));
  }

  setItemsPerPage(itemsPerPage: number) {
    this.setState(() => ({
      itemsPerPage,
    }));
    this.props.itemPerPage(true, 0, itemsPerPage);
  }

  setItemsPerPageHandler = (itemsPerPage: number) => {
    this.setCurrentPage(1);
    this.setItemsPerPage(itemsPerPage);
  };

  paginate = (pageNumber: number) => {
    this.setCurrentPage(pageNumber);
  };

  forApiCalling = (value: boolean, currentPage: number) => {
    if (
      this.props &&
      this.props.totalElements !== undefined &&
      this.props.totalElements > this.state.itemsPerPage
    ) {
      var count = (currentPage - 1) * this.state.itemsPerPage;
      this.props.value(value, count, this.state.itemsPerPage);
    }
  };

  onClickLowest() {
    let data = this.props.data;
    data.sort(function (
      x: { preBidPrice: number },
      y: { preBidPrice: number }
    ) {
      return x.preBidPrice - y.preBidPrice;
    });
    this.setData(data);
  }

  onClickHighest() {
    let data = this.props.data;
    data.sort(function (
      x: { preBidPrice: number },
      y: { preBidPrice: number }
    ) {
      return y.preBidPrice - x.preBidPrice;
    });
    this.setData(data);
  }

  onClickLowestCarat() {
    let data = this.props.data;
    data.sort(function (x: { carat: number }, y: { carat: number }) {
      return x.carat - y.carat;
    });
    this.setData(data);
  }

  onClickHighestCarat() {
    let data = this.props.data;
    data.sort(function (x: { carat: number }, y: { carat: number }) {
      return y.carat - x.carat;
    });
    this.setData(data);
  }

  onClickLowestBids() {
    let data = this.props.data;

    let tempArr = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].preBidPrice !== undefined) {
        tempArr.push(data[index]);
      }
    }

    if (tempArr !== undefined && tempArr !== null && tempArr.length > 0) {
      tempArr.sort(function (
        x: { preBidPrice: number },
        y: { preBidPrice: number }
      ) {
        return x.preBidPrice - y.preBidPrice;
      });
    }

    this.setData(tempArr);
  }

  onClickHighestBids() {
    let data = this.props.data;

    // let tempArr = [];
    // for (let index = 0; index < data.length; index++) {
    //   if (data[index].preBidPrice !== undefined) {
    //     tempArr.push(data[index]);
    //   }
    // }

    // if (tempArr !== undefined && tempArr !== null && tempArr.length > 0) {
    //   tempArr.sort(function (
    //     x: { preBidPrice: number },
    //     y: { preBidPrice: number }
    //   ) {
    //     return y.preBidPrice - x.preBidPrice;
    //   });
    // } else {
    //   //
    // }

    data.sort(function (
      x: { preBidPrice: number },
      y: { preBidPrice: number }
    ) {
      return y.preBidPrice - x.preBidPrice;
    });
    this.setData(data);
    // this.setData(tempArr);
    // this.props.noProductFound(tempArr);
  }
  
  exportProductToExcel() {
    let exportProductIds = this.props.data
      .slice(0, 1)
      .map((item: any, index: number) => {
        return item.auctionId;
      });
    this.Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportProductIds}&auctionProductsType=watched`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
        } else {
        }
      }
    );
  }

  render() {
    const { t } = this.props;
    const selectedPage = true;
    let currentRecords = [];
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    if (this.state.data.length > 0) {
      currentRecords = this.state.data.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
    }
    const valueMapping: any = {
      DEFAULT: { property: "serialNo", direction: "ASC" },
      lowestCarat: { property: "carat", direction: "ASC" },
      highestCarat: { property: "carat", direction: "DESC" },
      lowestJpy: { property: "caratMinimumPrice", direction: "ASC" },
      highestJpy: { property: "caratMinimumPrice", direction: "DESC" },
      lowestBids: { property: "myBids", direction: "ASC" },
      highestBids: { property: "myBids", direction: "DESC" },
    };

    const onChangeHandler = (e: any) => {
      const value = e.target.value;
      localStorage.setItem("selectedFilterSort", value);
      const selectedOption = {
        target: value,
        option: valueMapping[value],
      };
      onSelection(selectedOption);
    };

    const onSelection = (selectedOptions: any) => {
      const { property } = selectedOptions.option;
      if (property === "caratMinimumPrice") {
        if (selectedOptions.target === "lowestJpy") {
          this.onClickLowest();
        } else if (selectedOptions.target === "highestJpy") {
          this.onClickHighest();
        } else {
          //todo
        }
      } else if (property === "carat") {
        if (selectedOptions.target === "lowestCarat") {
          this.onClickLowestCarat();
        } else if (selectedOptions.target === "highestCarat") {
          this.onClickHighestCarat();
        }
      } else if (property === "myBids") {
        if (selectedOptions.target === "lowestBids") {
          this.onClickLowest();
        } else if (selectedOptions.target === "highestBids") {
          this.onClickHighestBids();
        } else {
          //todo
        }
      } else {
        //todo
      }
    };

    return (
      <StyledCoreDataGridContainer container={true} spacing={0}>
        <StyledActionBarContainer
          container={true}
          spacing={0}
          className="coreActionBarContainer"
        >
          <Grid container={true} xs={12} lg={3} xl={2}>
            <StyledBackImageContianer>
              <StyledBackBtn
                src="/images/controls/left_arrow_round_edges.png"
                onClick={this.props.handleBackBtns}
              />
            </StyledBackImageContianer>
            <Grid>
              {/* <ItemsCountofProduct
                    t={t}
                    itemsPerPage={this.state.itemsPerPage}
                    totalElements={this.props.totalElements}
                    currentPage={this.state.currentPage}
                  /> */}
            </Grid>
          </Grid>
          <StyledActionsContainer item={true} xs={12} lg={9} xl={10}>
            <StyledContainer>
              <StyledButton onClick={this.exportProductToExcel.bind(this)}>
                {t("common:preview:pagination:export")}
              </StyledButton>
            </StyledContainer>
            <StyledContainer>
              <StyledPageSizeSelectorContainer>
                <select
                  name="sortingControl"
                  onChange={onChangeHandler}
                  value={`${localStorage.getItem("selectedFilterSort")}`}
                >
                  <option value="DEFAULT">
                    {t("common:preview:pagination:select")}
                  </option>
                  {showCarat && (
                    <optgroup label={t("common:preview:pagination:carat")}>
                      <option value="lowestCarat">
                        {t("common:preview:pagination:lowestCarat")}
                      </option>
                      <option value="highestCarat">
                        {t("common:preview:pagination:highestCarat")}
                      </option>
                    </optgroup>
                  )}
                  <optgroup label={t("common:preview:pagination:jpy")}>
                    <option value="lowestJpy">
                      {t("common:preview:pagination:lowestJpy")}
                    </option>
                    <option value="highestJpy">
                      {t("common:preview:pagination:highestJpy")}
                    </option>
                  </optgroup>
                  <optgroup label={t("common:preview:pagination:myBids")}>
                    <option value="lowestBids">
                      {t("common:preview:pagination:lowestBids")}
                    </option>
                    <option value="highestBids">
                      {t("common:preview:pagination:highestBids")}
                    </option>
                  </optgroup>
                </select>
              </StyledPageSizeSelectorContainer>
              <PageSizeSelector
                setItemsPerPageHandler={this.setItemsPerPageHandler}
                t={t}
              />
            </StyledContainer>
          </StyledActionsContainer>
        </StyledActionBarContainer>
        <StyledDataWrapper item={true} xs={12}>
          <StyledGridWrapper>
            {childElementsWithProps({
              selectedPage,
              currentRecords,
              ...this.props,
            })}
          </StyledGridWrapper>
        </StyledDataWrapper>
        {this.props.totalElements && this.props.totalElements > 0 ? (
          <StyledPaginationContainer item={true} xs={12}>
            <ActionPaginationForProducts
              t={t}
              totalElements={this.props.totalElements}
              callbackForApiCalling={this.forApiCalling}
              currentPage={this.state.currentPage}
              itemsPerPage={this.state.itemsPerPage}
              paginateCallback={this.paginate}
            />
          </StyledPaginationContainer>
        ) : null}
      </StyledCoreDataGridContainer>
    );
  }
}
