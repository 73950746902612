import { MUIDataTableOptions } from "mui-datatables";

export const tableOptions: MUIDataTableOptions = {
  filter: "false",
  download: "false",
  print: "false",
  viewColumns: "false",
  selectableRows: "none",
  serverSide: true,
  pagination: true,
  search: false,
  // responsive:"standard",
  rowsPerPageOptions: [10, 20, 50, 100],
};

export const columnIndexes = {
  code: 0,
  perId: 1,
  currentBidPrice: 2,
  productCode: 3,
  caratSellingPrice: 4,
  description: 5,
  certificate: 6,
  auctionName: 7,
  auctionId: 8,
  carat: 9,
  caratMinimumPrice: 10,
  caratStr: 11,
  clarity: 12,
  serialNo: 13,
  color: 14,
  colorValue: 15,
  currency: 16,
  cutGrade: 17,
  exchangeRates: 18,
  flour: 19,
  height: 20,
  hnc: 21,
  length: 22,
  parcelType: 23,
  polish: 24,
  shape: 25,
  symmetry: 26,
  symmetryAbbreviation: 27,
  t3EX: 28,
  width: 29,
  productDescription: 30,
  noOfPieces: 31,
  sieve: 32,
  auctionStartDate: 33,
  auctionType: 34,
  negotiated:35,
};

export const showDescription = (data: any) => {
  const {
    parcelType,
    productDescription,
    code,
    carat,
    color,
    noOfPieces,
    clarity,
    cutGrade,
    flour,
    symmetryAbbreviation,
    shape,
    sieve,
    polish,
    jewelleryDetails,
  } = data;
  if (parcelType === "Jewellery") {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }

    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  } else if (parcelType === "Diamond") {
    return `${carat === undefined || carat === null ? "" : `${carat} ct, `}${
      color === undefined || color === null ? "" : `${color},`
    } ${clarity === undefined || clarity === null ? "" : `${clarity},`} ${
      cutGrade === undefined || cutGrade === null ? "" : `${cutGrade},`
    } ${flour === undefined || flour === null ? "" : `${flour},`} ${
      polish === undefined || polish === null ? "" : `${polish},`
    } ${
      symmetryAbbreviation === undefined || symmetryAbbreviation === null
        ? ""
        : ` sym= ${symmetryAbbreviation}`
    } `;
  } else {
    return ` ${
      productDescription === undefined ? " " : `${productDescription}`
    } ${
      noOfPieces === undefined || noOfPieces === null || noOfPieces === ""
        ? ""
        : `${noOfPieces} pieces,`
    } ${carat === undefined || carat === null ? "" : `${carat} ct,`} ${
      color === undefined || color === null ? "" : `${color},`
    } ${shape === undefined || shape === null ? "" : `${shape},`}  ${
      sieve === undefined || sieve === "" || sieve === null || sieve === 0
        ? ""
        : `${sieve}`
    } `;
  }
};

export const getDetailsDescription = (data: any, t?: any) => {
  const {
    parcelType,
    carat,
    color,
    clarity,
    cutGrade,
    flour,
    polish,
    symmetryAbbreviation,
    productDescription,
    jewelleryDetails,
    noOfPieces,
    shape,
    sieve,
  } = data;

  const SIEVE_TYPE = sieve === undefined || sieve === "" ? "" : sieve;
  const PRODUCT_SHAPE = shape !== undefined ? shape : "";
  const PRODUCT_DESCRIPTION =
    productDescription === undefined ? "" : productDescription;
  const CARAT_CT = carat === undefined ? "" : `${carat} ${"Ct"}`;
  const CARAT = carat === undefined ? "" : `${carat} ${t("common:preview:ct")}`;
  const COLOR = color === undefined ? "" : color;
  const CLARITY = clarity === undefined ? "" : clarity;
  const CUTGRADE = cutGrade === undefined ? "" : cutGrade;
  const FLOUR = flour === undefined ? "" : flour;
  const POLISH =
    polish === undefined ? "" : `${polish}`;
  const SYMMETRY_ABBREVATION =
    symmetryAbbreviation === undefined
      ? ""
      : `${symmetryAbbreviation}`;

      
  if (parcelType === "Jewellery") {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }

    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  } else if (parcelType === "Diamond") {    
    return `${CARAT} ${COLOR} ${CLARITY} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY_ABBREVATION} `;
  } else {
    return `${PRODUCT_DESCRIPTION} ${COLOR} ${PRODUCT_SHAPE} ${SIEVE_TYPE} ${CARAT_CT}`;
  }
};

export const convertProductPrice = (data: any, price: any) => {
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
  if (price === undefined || price === null) {
    return `-`;
  } else {
    if (userCurrencyCode !== data.currency.code) {
      let conversion = new Intl.NumberFormat('us-en').format(
        Math.round(price * data.exchangeRates[0].rate)
      );
      let conversions = new Intl.NumberFormat('us-en').format(Math.round(price));
      return conversions !== '0' ? `${data.currency.symbol} ${conversions} (${userCurrencySymbol} ${conversion})` : `-`;
    } else {
      let conversions = new Intl.NumberFormat('us-en').format(Math.round(price));
      return conversions !== '0' ? `${userCurrencySymbol} ${conversions}` : "-";
    }
  }
};
