import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { buttonGradient, sideMenuGradient } from '../../common/materials/LinearGradient';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StyledPromotaionImage } from '../../modules/login/Promotional';

const CorouselDiv = styled(Grid)`
  width: 100%;
  height: auto;
  .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(105,105,105,1) 100%, rgba(255,255,255,1) 100%);
  }
`;

const StyledArrow = styled.img`
`;

const StyledLeftButton = styled.button`
    position: absolute;
    z-index: 3;
    left: 1em;
    right:0;
    border: none;
    background: ${(props) => props.theme.colors.base.transparent};
    cursor: pointer;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 1.8em;
        height: 1.8em; 
        top: calc(50% - 0.8em);  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 1em;
        height: 1em;
        top: calc(50% - 2em);  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        width: 1.5em;
        height: 1.5em; 
        top: calc(50% - 2em); 
    }
`;

const StyledButton = styled.button`
    position: absolute;
    text-align: right;
    border: none;
    background: ${(props) => props.theme.colors.base.transparent};
    cursor: pointer;
    width: 3.5em;
    height: 3.5em;   
    margin-left: 10px;
    display: inline-block;
    &.rightIcon {
        margin-left: 0px;
        margin-right: 3px;
    }
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 1.8em;
        height: 1.8em; 
        top: calc(50% - 0.8em);  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 1em;
        height: 1em;
        top: calc(50% - 2em);  
    }
    ${props => props.theme.breakpoints.between('lg', 'xl')} {       
        width: 1.5em;
        height: 1.5em;   
        top: calc(50% - 2em); 
    }
`;
interface ButtonProps {
    selected?: boolean;
};


interface IProps {
  imgs: any;
}


const PromotionalCarousal = (props: IProps) => {
  let { imgs } = props;
  let { t } = useTranslation();
  return (
    <div>
      <CorouselDiv item={true}>
        <Carousel
          showIndicators={false}
          showStatus={false}
          autoPlay={true}
          transitionTime={1000}
          infiniteLoop
          showThumbs={false}
          renderArrowPrev={(onClickHandler, hasPrev) =>
            hasPrev && (
              <StyledLeftButton
                style={{ left: 5 }}
                onClick={onClickHandler}
                className="form-submit"
              >
                <StyledArrow src="/images/controls/left_arrow_round_edges.png" />
              </StyledLeftButton>
            )
          }
          renderArrowNext={(onClickHandler, hasNext) =>
            hasNext && (
              <StyledButton
                style={{ right: 5 }}
                onClick={onClickHandler}
                className="form-submit"
              >
                <StyledArrow src="/images/controls/right_arrow_round_edges.png" />
              </StyledButton>
            )
          }
        >
          <div>
            <StyledPromotaionImage src="/images/igt_Images/hokuyuJewelryAuction2.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/takeyamaAuction.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/dialusterJewelryAuction.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/hokuyuDiamondAuction1.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/mjdiams1.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/ijt1.png"></StyledPromotaionImage>
          </div>
          <div>
            <StyledPromotaionImage src="/images/igt_Images/ijt2.png"></StyledPromotaionImage>
          </div>
        </Carousel>
      </CorouselDiv>
    </div>
  );
};

export default PromotionalCarousal;
