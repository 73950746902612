import * as React from "react";
import TextField from "@mui/material/TextField";
import { Box } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";

interface Iprops {
  lable?: string;
  setValue: (value: any) => void;
}

export default function SearchWithSuggestion({
  lable,
  setValue,
}: Iprops) {
  return (
    <Box sx={{ width: "-webkit-fill-available;", display: "flex" }}>
      <TextField
        id="input-with-sx"
        fullWidth
        placeholder={lable}
        variant="standard"
        onChange={(event: any) => {
          const myArray = event.target.value.split(",");
          var filtered = myArray.filter(function (el: string) {
            return el != null && el !== "";
          });
          var convertedArr = filtered.map(function (item: string) {
            return parseInt(item, 10);
          });
          let filter: any = {
            match: "in",
            name: "serialNo",
            value: convertedArr,
          };
          if(convertedArr.length === 0){
            filter = undefined
          }
          setValue(filter);
        }}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              <SearchIcon />
            </React.Fragment>
          ),
        }}
      />
    </Box>
  );
}
