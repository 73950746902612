import React, { useEffect, useRef, useState } from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AuthService from "../../auth/AuthService";
import {
  productTextSearch,
  auctionTendorProductUrl,
  productDownload,
  getNotes,
  productUrl,
} from "../../../common/config/app.endpoints";
import AppLayout from "../../layouts/AppLayout";
import Alert from "../../../common/elements/Alert";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import ApiTimeout from "../../../modules/ApiTimeout";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import BlockUserModal from "../BlockUser/BlockUserModal";

import {
  createTheme,
  MuiThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import JewelleryFilter from "./JewelleryFilter";
import CommonFilter from "../CommonFilter";
import NotesModal from "../BlockUser/NotesModal";
import EllipsisText from "../../../common/elements/EllipsisText";

import { Menu, MenuItem, useMediaQuery } from "@material-ui/core";
import TenderListOfProduct from "./tenderMobileComponets/TenderListOfProduct";
import {
  StyledButtonView,
  StyledCommanDiv,
  Styledview,
} from "./TendorPreviewView";
import copy from "copy-to-clipboard";
import {
  faEdit,
  faHeart,
  faShareSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommanCoreDataGridOfTender from "./tenderMobileComponets/CommanCoreDataGridOfTender";
import { openJewelleryCertificateLinkPopup } from "../../../common/GenericFunctions";
import AlertReadOnlyUser from "../../../common/elements/AlertReadOnlyUser";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import ActionTenderPagesizeComman from "../../core-grid/grid-actions/ActionTenderPagesizeComman";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import {
  resetJewelleryCurrentApiStartCount,
  selectJewelleryCurrentApiStartCount,
  selectJewelleryItemPerPage,
  setJewelleryCurrentApiStartCount,
  setJewelleryItemPerPage,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import {
  resetTenderJwlIsGridView,
  selectTenderJwlGridView,
  setTenderJwlIsGridView,
} from "../../../redux/slice/annual/commanAnnualSlice";
import BasicSearchBox from "../../core-grid/grid-actions/BasicSearchBox";

const faEditProps = faEdit as IconProp;
const faHeartProps = faHeart as IconProp;
const faShareSquareProps = faShareSquare as IconProp;
interface DetailParams {
  auctionId: string;
  auctionName: string;
  auctionDate: string;
  auctionUserId: string;
}

export interface IProductViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

const StyledLayoutGridMain = styled(Grid)`
  .MuiIconButton-edgeEnd {
    display: contents !important;
    transition: width 5s !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
    transition: width 4s !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  .AccordionHeader {
    width: 100%;
    transition: width 1s;

    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    ${(props) => props.theme.breakpoints.down("md")} {
      width: 100%;
    }
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledLayoutGrid = styled(Grid)`
  padding: 10px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
    z-index: 0;
  }
  .MuiTableCell-root {
    padding: 19px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }
  .MuiTableBody-root {
    background: white;
    // background: ${(props) => props.theme.colors.base.tableRowBackground};
  }
  .MuiTableCell-root {
    text-align: center;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  .MuiTableCell-footer {
    padding: 2px;
  }
  .MuiIconButton-edgeEnd {
    display: contents !important;
  }
  .MuiAccordionSummary-root {
    min-height: 10px !important;
  }
  .MuiPaper-root {
    box-shadow: none !important;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
`;

const StyledCoreDataGridContainer = styled(Grid)``;
const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 10px;
  margin: 10px 0px 10px 0px;
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  justify-content: flex-end;
  &:focus {
    outline: none;
  }
`;

const StyledButtonHeader = styled.button`
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 1em;
  padding: 0.5em;
  margin: 0.3em;
  border-radius: 5px;

  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const StyledContent = styled(Grid)`
  text-align: center;
  font-size: 1.5em;
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledName = styled(Grid)`
  display: flex;
`;

export const StyledTextWrapper = styled(Grid)`
  margin: auto;
  text-align: center;
`;


const StyledBoxContent = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  border-top: 4px solid #000f7e;
`;

const StyledDropDown = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;

let totalElement: number = 0;
let page: number = 0;

let payload: any = {};
let startCount: number = 0;
let endCount: number = 10;

let filter: any = [];
let filteredValues: any = "";
let filteredParcelValues: any = "";
let body: any = [];
const columnIndexes = {
  serialNo: 0,
  productId: 1,
  productCode: 3,
  lab: 4,
  certificate: 5,
  description: 6,
  currency: 7,
  remarks: 8,
  note: 9,
  startingPrice: 10,
  rowColor: 11,
  exchange: 12,
  watched: 13,
  sellerAuctionUserId: 14,
};
let conversionCaratMinPrice: string = "";
let updatePageNumber: any = [];
let sourceFromUrl: any;
let isReadOnlyUser: any;
let isAuctionUserId: boolean;
const initialState = {
  mouseX: null,
  mouseY: null,
};

const JewelleryTendorPreviewView = (props: IProductViewProps) => {
  var getMuiTheme = () => createTheme({});
  const [auctionProducts, setAuctionProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [isShowSearchBox, setShowSearchBox] = useState(true);

  const [apiTimeout, setApiTimeout] = useState(false);
  const [onAction, setOnAction] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [userBlock, setUserBlock] = useState(false);
  const [textSearchArray, setTextSearchArray] = useState([]);
  const [optionValue, setOptionValue] = useState("");
  const [notes, setNotes] = useState();
  const [openNotesPopups, setNotesPopups] = useState(false);
  const [uiBoxView, setUiBoxView] = useState(false);
  const [isTopElementHide, setIsTopElementHide] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [exportExcelDownload, setExportExcelDownload] = useState(true);
  const [isTableExpanded, setIsTableExpanded] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  const [currentProductId, setCurrentProductId] = useState<string>("");
  const [mouseState, setMouseState] = React.useState<{
    mouseX: null | number;
    mouseY: null | number;
  }>(initialState);
  const Auth = new AuthService();
  const { t } = props;
  const theme = useTheme();
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  let isAuctionPage: any = false;
  let isCategoryPageType: any = false;
  let auctionUserId: any;
  const itemsPerPageUseRef = useRef(10);
  const selectJewelleryPageUseRef = useRef(0);

  const dispatch: AppDispatch = useDispatch();

  const isGridView: any = useSelector((state: RootState) =>
    selectTenderJwlGridView(state)
  );

  useEffect(() => {
    setIsLoading(true);
    setOpenToast(false);
    // localStorage.removeItem("dropDownFilterVal");
    localStorage.setItem("urlRedirectFromAdmin", window.location.href);
    const urlParams = new URLSearchParams(window.location.search);
    sourceFromUrl = urlParams.get("source");
    if (sourceFromUrl === "admin") {
      setIsSeller(true);
    }
    // getTendorAucProducts(props.match.params.auctionId, 0);
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    return () => {
      localStorage.removeItem("urlRedirectFromAdmin");
    };
  }, []);

  const selectcurrentApiStartCountValue: any = useSelector((state: RootState) =>
    selectJewelleryCurrentApiStartCount(state)
  );

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectJewelleryItemPerPage(state)
  );

  useEffect(() => {
    localStorage.setItem(
      "jwlapiStartCount",
      JSON.stringify(selectcurrentApiStartCountValue * selectItemPerPageValue)
    );
    getTendorAucProducts(
      props.match.params.auctionId,
      selectcurrentApiStartCountValue
    );
  }, [selectcurrentApiStartCountValue, selectItemPerPageValue]);

  useEffect(() => {
    let isShowDrawerIcon: any = localStorage.getItem("isShowDrawerIcon");
    isShowDrawerIcon = JSON.parse(isShowDrawerIcon);
    if (isShowDrawerIcon !== null) {
      if (isShowDrawerIcon) {
        const accordionHeader: any = document.querySelector(".AccordionHeader");
        if (accordionHeader !== null) {
          accordionHeader.style.width = isShowDrawerIcon
            ? "calc(100% - 60px)"
            : "calc(100% - 240px)";
        }
      }
    }
  });

  const handleChangeAcco =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      if (isExpanded === false) {
        setIsTableExpanded(false);
      } else {
        setIsTableExpanded(true);
      }
    };

  const getTendorAucProducts = (
    auctionId: number | string,
    currentPage: number
  ) => {
    setOpenToast(false);
    body = [];

    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    if (filteredValues !== undefined) {
      if (filteredValues === null || filteredValues.length === 0) {
        filter = null;
      } else {
        filteredValues.map((item: any, index: number) => {
          if (item.name === "auctionId") {
            filteredValues.splice(index, 1);
          }
        });
        filter = filteredValues;
      }
    } else {
      filteredParcelValues = localStorage.getItem("filteredParcelValues");
      filteredParcelValues = JSON.parse(filteredParcelValues);

      if (filteredParcelValues !== undefined) {
        if (
          filteredParcelValues === null ||
          filteredParcelValues.length === 0
        ) {
          filter = null;
        } else {
          filteredParcelValues.map((item: any, index: number) => {
            if (item.name === "auctionId") {
              filteredParcelValues.splice(index, 1);
            }
          });
          filter = filteredParcelValues;
        }
      }
    }

    getBody(auctionId, currentPage, endCount);
  };

  const getBody = (
    auctionId: string | number,
    currentPage: number,
    endCount: number
  ) => {
    payload = {};

    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);

    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (
      selectcurrentApiStartCountValue !== null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      startCount = selectcurrentApiStartCountValue * selectItemPerPageValue;
    } else {
      startCount = selectcurrentApiStartCountValue;
    }

    if (
      selectcurrentApiStartCountValue != null &&
      selectcurrentApiStartCountValue !== 0
    ) {
      page = selectcurrentApiStartCountValue;
    } else {
      page = 0;
    }

    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      endCount = savedCount;
    }

    if (
      filter === null &&
      (filteredValues == null ||
        filteredValues === undefined ||
        filteredValues === "null" ||
        filteredValues.length === 0)
    ) {
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: auctionId,
            },
            {
              match: "exact",
              name: "parcelType",
              value: "Jewellery",
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      body = filteredValues ? filteredValues : filter;
      body.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          body.splice(index, 1);
        }
      });
      payload = {
        filters: {
          or: body,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      setOptionValue(val);
      payload = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "remarks",
              value: val,
            },
          ],
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    callApi(payload);
  };

  const autoCompleteSearch = (val: any) => {
    localStorage.setItem("optionValue", val);
    getTendorAucProducts(props.match.params.auctionId, 0);
  };

  const handleFilters = () => {
    setIsFiltersOpen(!isFiltersOpen);
    setShowSearchBox(!isShowSearchBox);
  };

  const textSearchListing = (value: String) => {
    payload = {
      filters: {
        or: [
          {
            match: "anywhere",
            name: "remarks",
            value: value,
          },
          {
            match: "anywhere",
            name: "productDescription",
            value: value,
          },
        ],
      },
    };

    if (value === "" || value.length === 1) {
      setTextSearchArray([]);
    } else {
      if (value.length > 1) {
        Auth.postRequest(
          productTextSearch,
          payload,
          (status: any, response: any) => {
            if (status === 200) {
              setTextSearchArray(response.results);
            }
          }
        );
      }
    }
  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let sourceAuctionProduct =
        sourceFromUrl === "admin"
          ? `${auctionTendorProductUrl}?auctionId=${props.match.params.auctionId}&redirectFromPreview=true`
          : `${auctionTendorProductUrl}?auctionId=${props.match.params.auctionId}`;
      Auth.postRequest(
        sourceAuctionProduct,
        payload,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            totalElement = response.totalElements;
            setAuctionProducts(response.results);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const handleNotes = (productId: any) => {
    setOpenToast(false);
    callNotesApi(productId);
    //setNotesPopups(true);
  };

  const callNotesApi = (productId: any) => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getNotes}/${productId}/mynote`,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            if (response !== "" && response !== undefined) {
              setNotes(response);
            } else {
              response = "";
              setNotes(response);
              setShowAlertMessage(`${t("No notes added yet")}`);
              setAlertType("error");
              setOpenToast(true);
            }
            setNotesPopups(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else if (status === 404) {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setAlertType("error");
            setOpenToast(true);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  // page = (startCount * itemsPerPageUseRef.current) / 100;
  // updatePageNumber = endCount;

  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable: any = h - 330;
    if (auctionProducts.length > 7) {
      return `${heightOfInnerTable}px`;
    }
  };

  const options: MUIDataTableOptions = {
    filter: false,
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    count: totalElement,
    page: page,
    serverSide: true,
    search: false,
    rowsPerPage: selectItemPerPageValue,
    rowsPerPageOptions: [0],
    onChangePage: (currentPage: number) => {
      localStorage.setItem(
        "jwlapiStartCount",
        JSON.stringify(currentPage * itemsPerPageUseRef.current)
      );
      dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
    },
    sort: true,
    onSearchChange: () => {
      //
    },
    onColumnSortChange: (price, direction) => {
      let order = "";
      if (direction === "asc") {
        order = "desc";
      } else {
        order = "asc";
      }
      sort(price, order);
    },
    tableBodyHeight: getTableBodyHeight(),
  };

  const filterSort = (price: any, direction: any) => {
    let order = "";
    if (direction === "asc") {
      order = "desc";
    } else {
      order = "asc";
    }
    sort(price, order);
  };

  const sort = (value: any, direction: any) => {
    sortData(value, direction);
  };

  const sortData = (values: any, direction: any) => {
    let tempArr: any;
    if (direction === "asc") {
      tempArr = [{ property: values, direction: "ASC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    } else {
      tempArr = [{ property: values, direction: "DESC" }];
      localStorage.setItem("dropDownFilterVal", JSON.stringify(tempArr));
    }
    localStorage.removeItem("jwlapiStartCount");
    if (selectcurrentApiStartCountValue === 0) {
      getTendorAucProducts(props.match.params.auctionId, 0);
    } else {
      dispatch(setJewelleryCurrentApiStartCount({ currentPage: 0 }));
    }
  };

  const renderReadOnlyUserToast = async (id: any) => {
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (id === items.auctionUserId) {
          isAuctionUserId = true;
        }
      });
    } else {
      if (id === props.match.params.auctionUserId) {
        isAuctionUserId = true;
      } else {
        isAuctionUserId = false;
      }
    }
    await renderPopup();
  };

  const renderPopup = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: "Serial No",
      options: {
        filter: false,
        sort: true,
        display: true,
        setCellProps: () => ({
          style: { minWidth: "100px", maxWidth: "100px" },
        }),
      },
    },
    {
      name: "perId",
      label: "Product Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div
              style={{
                display: "flex",
                gridGap: "10px",
                justifyContent: "center",
              }}
            >
              <div
                title={`Note`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  openProductDetail(
                    props.match.params.auctionId,
                    tableMeta.rowData[1],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faEditProps}
                  style={{ color: "#014a99" }}
                />
              </div>
              <div
                title={`Add Product into Wishlist`}
                style={{ cursor: "pointer" }}
              >
                {isReadOnlyUser ? (
                  <FontAwesomeIcon
                    icon={faHeartProps}
                    style={{ color: "gray" }}
                    onClick={() => {
                      renderReadOnlyUserToast(
                        tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                      );
                    }}
                  />
                ) : (
                  <div>
                    {tableMeta.rowData[columnIndexes.sellerAuctionUserId] !==
                      props.match.params.auctionUserId ? (
                      <>
                        {isSeller ? (
                          <FontAwesomeIcon
                            icon={faHeartProps}
                            style={{ color: "gray" }}
                            onClick={() => {
                              renderReadOnlyUserToast(
                                tableMeta.rowData[
                                columnIndexes.sellerAuctionUserId
                                ]
                              );
                            }}
                          />
                        ) : (
                          <>
                            {getWatchedProduct(
                              tableMeta.rowData[columnIndexes.watched],
                              tableMeta.rowData[columnIndexes.productId],
                              props.match.params.auctionId,
                              tableMeta.rowData[1]
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <FontAwesomeIcon
                        icon={faHeartProps}
                        style={{ color: "gray" }}
                        onClick={() => {
                          renderReadOnlyUserToast(
                            tableMeta.rowData[columnIndexes.sellerAuctionUserId]
                          );
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
              <div
                title={`Share Product`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  shareButtonAction(
                    tableMeta.rowData[columnIndexes.productId],
                    props.match.params.auctionDate
                  )
                }
              >
                <FontAwesomeIcon
                  icon={faShareSquareProps}
                  style={{ color: "#014a99" }}
                />
              </div>
            </div>
          );
        },
      },
    },
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:sku")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                openProductDetail(
                  props.match.params.auctionId,
                  tableMeta.rowData[1],
                  props.match.params.auctionDate
                )
              }
              onContextMenu={(event: any) =>
                rightClickEvent(event, tableMeta.rowData[1])
              }
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "lab",
      label: `${t("common:Tender:tableColumnNames:lab")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          if (
            value !== "AGT" &&
            value !== "agt" &&
            value !== "" &&
            value !== undefined
          ) {
            return (
              <span
                className="linkCss"
                onClick={() =>
                  openJewelleryCertificateLinkPopup(
                    value,
                    tableMeta.rowData[columnIndexes.certificate]
                  )
                }
              >
                {value}
              </span>
            );
          } else {
            return <span>{value}</span>;
          }
        },
      },
    },
    {
      name: "certificate",
      label: `${t("common:Tender:tableColumnNames:certification")}`,
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "jewelleryDetails",
      label: `${t("common:Tender:tableColumnNames:description")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <EllipsisText
              text={getDescription(value)}
              length={70}
              tail={"..."}
            />
          );
          //   <div>{getDescription(value)}</div>
        },
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value.code}</div>;
        },
      },
    },
    {
      name: "remarks",
      label: `${t("common:Tender:tableColumnNames:remark")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span style={{}}>
              <EllipsisText
                text={getRemarks(tableMeta.rowData[columnIndexes.remarks])}
                length={70}
                tail={"..."}
              />
            </span>
          );
        },
      },
    },
    {
      name: "note",
      label: "Notes",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              className="linkCss"
              onClick={() =>
                handleNotes(tableMeta.rowData[columnIndexes.productId])
              }
            >
              View Notes
            </span>
          );
        },
      },
    },
    {
      name: "caratMinimumPrice",
      label: `${t("common:Tender:tableColumnNames:startingPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertedValue(
                tableMeta.rowData[columnIndexes.currency],
                value,
                tableMeta.rowData[columnIndexes.exchange][0].rate
              )}
            </div>
          );
        },
      },
    },
    {
      name: "rowColor",
      label: "Bid Color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "Exchange Rate",
      options: {
        filter: false,
        sort: false,
        display: false,
        customBodyRender: (value: any) => {
          return <div>{value[0].rate}</div>;
        },
      },
    },
    {
      name: "watched",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sellerAuctionUserId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const getWatchedProduct = (
    watched: any,
    productId: any,
    auctionId: any,
    serialNo: any
  ) => {
    if (watched === true) {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => removeFromWatchlist(productId, auctionId)}
        />
      );
    } else {
      return (
        <FontAwesomeIcon
          icon={faHeartProps}
          style={{ color: "gray" }}
          onClick={() => addToWatchList(productId, auctionId)}
        />
      );
    }
  };

  const removeFromWatchlist = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (Auth.loggedIn() === null) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/unwatch`,
        "payload",
        (status: any, response: any) => {
          if (status === 200) {
            callApi(payload);
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response.data.message);
            setAlertType("success");
            setOpenToast(true);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 404) {
            setIsLoading(false);
            setShowAlertMessage(`${t("common:invalidRequest")}`);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setOnAction(false);
          } else {
            setIsLoading(false);
            setOnAction(false);
            setShowAlertMessage(response[0].message);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const shareButtonAction = (perId: any, auctionDate: any) => {
    const { t } = props;
    let languageCode =
      localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
          ? "zh_CN"
          : "jp";
    copy(
      `${window.location.hostname}/${languageCode}/product/jewellery/${perId}/${auctionDate}/detail`
    );
    setAlertType("success");
    setOpenToast(true);
    setShowAlertMessage(`${t("common:preview:linkCopied")}`);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const closeModal = () => {
    setNotesPopups(false);
  };

  const closeNotesModle = () => {
    setNotesPopups(false);
  };

  const getRemarks = (remarks: any) => {
    if (remarks !== "" && remarks !== undefined && remarks !== null) {
      return remarks;
    } else {
      return "";
    }
  };

  const convertedValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return ``;
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversionPrice = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${currencyCodeVal.symbol}${conversionPrice}(${userCurrencySymbol}${conversion})`;
      } else {
        let conversionPrice = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        conversionCaratMinPrice = `(${userCurrencySymbol}${conversionPrice})`;
        return `${userCurrencySymbol}${conversionPrice}`;
      }
    }
  };

  const getDescription = (jewelleryDetails: any) => {
    var jewelleryType: string = "";
    var jewelleryCondition: string = "";
    var jewelleryDescription: string = "";
    if (jewelleryDetails !== undefined && jewelleryDetails !== null) {
      if (
        jewelleryDetails.jewelleryType !== undefined &&
        jewelleryDetails.jewelleryType !== null
      ) {
        jewelleryType = jewelleryDetails.jewelleryType;
      } else {
        jewelleryType = "";
      }

      if (
        jewelleryDetails.jewelleryCondition !== undefined &&
        jewelleryDetails.jewelleryCondition !== null
      ) {
        jewelleryCondition = jewelleryDetails.jewelleryCondition;
      } else {
        jewelleryCondition = "";
      }

      if (
        jewelleryDetails.jewelleryDescription !== undefined &&
        jewelleryDetails.jewelleryDescription !== null
      ) {
        jewelleryDescription = jewelleryDetails.jewelleryDescription;
      } else {
        jewelleryDescription = "";
      }
    }

    return (
      jewelleryType + " " + jewelleryCondition + " " + jewelleryDescription
    );
  };

  const openProductDetail = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    if (sourceFromUrl === "admin") {
      props.history.push(
        `/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}&redirectFromPreview=true`
      );
    } else {
      props.history.push(
        `/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}`
      );
    }
  };

  const openDetailsView = (
    auctionId: string,
    perId: string,
    auctionDate: string
  ) => {
    const language = localStorage.getItem("language");

    const languageCode =
      language === "EN" ? "en" : language === "zh_CN" ? "zh_CN" : "jp";
    if (sourceFromUrl === "admin") {
      window.open(
        `/${languageCode}/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}&redirectFromPreview=true`,
        "_blank"
      );
    } else {
      window.open(
        `/${languageCode}/preview/tender/jewellery/details/${auctionId}/${perId}/${auctionDate}?isAuction=false&uniqid=${props.match.params.auctionUserId}`,
        "_blank"
      );
    }
  };
  const rightClickEvent = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: string
  ) => {
    event.preventDefault();
    setMouseState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
    setCurrentProductId(productId);
  };

  const handleClose = () => {
    setMouseState(initialState);
  };

  const exportExcel = () => {
    let exportProductIds = auctionProducts.slice(0, 1).map((item: any) => {
      return item.auctionId;
    });
    setExportExcelDownload(false);
    Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportProductIds}&auctionType=Jewellery`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          setTimeout(() => {
            setExportExcelDownload(true);
          }, 5000);
        } else {
          //todo
        }
      }
    );
  };

  const filteredAuction = async (
    auctions: any,
    isFilter: boolean,
    values: any,
    isCustomFilter?: any
  ) => {
    localStorage.removeItem("jwlapiStartCount");
    if (
      localStorage.getItem("dropDownFilterVal") !== undefined &&
      localStorage.getItem("dropDownFilterVal") !== null
    ) {
      var tempArr = localStorage.getItem("dropDownFilterVal");
      if (tempArr !== null) var dropDownValues = JSON.parse(tempArr);
      var property = dropDownValues[0].property;
      var direction = dropDownValues[0].direction;
    }

    if (values === null) {
      filter = null;
      payload = {
        filters: {
          and: [
            {
              match: "exact",
              name: "parcelType",
              value: "Jewellery",
            },
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };
    } else {
      body = [];
      values.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          values.splice(index, 1);
        }
      });
      body = values;
      payload = {
        filters: {
          or: body,
          and: [
            {
              match: "exact",
              name: "auctionId",
              value: props.match.params.auctionId,
            },
          ],
        },
        page: {
          start: startCount,
          count: endCount,
          sort: {
            orders: [
              {
                property:
                  property !== null && property !== undefined
                    ? property
                    : "serialNo",
                direction:
                  direction !== null && direction !== undefined
                    ? direction
                    : "ASC",
              },
            ],
          },
        },
      };

      if (isCustomFilter === undefined) {
        filter = body;
        localStorage.setItem("filteredValues", JSON.stringify(body));
        localStorage.setItem("filteredJewelleryValues", JSON.stringify(body));
      }
    }
    await filterCAllback(payload);
  };

  const filterCAllback = (dataVal: any) => {
    if (selectcurrentApiStartCountValue === 0) {
      callApi(dataVal);
    } else {
      dispatch(resetJewelleryCurrentApiStartCount());
    }
  };

  const setItemsPerPageHandler = (itemsPerPage: number) => {
    localStorage.setItem("currentPageOfJewellery", "0");
    setCurrentPage(1);
    itemsPerPageUseRef.current = itemsPerPage;
    setItemsPerPage(itemsPerPage);
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
    dispatch(resetJewelleryCurrentApiStartCount());
  };

  function setCurrentPage(currentPage: number) {
    return currentPage;
  }

  function setItemsPerPage(itemsPerPage: number) {
    return itemPerPage(true, 0, itemsPerPage);
  }

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem(
        "jwlitemPerPageSelected",
        JSON.stringify(itemPerPage)
      );
      getUpdatedProducts(value, start, itemPerPage);
    }
  };

  const getUpdatedProducts = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getProducts(start, endRecords);
    }
  };

  const getProducts = (start: number, itemsPerPage: number) => {
    startCount = start;
    endCount = itemsPerPage;
    localStorage.setItem("jwlapiStartCount", JSON.stringify(start));
    let currentPage = start / itemsPerPage;
    setIsLoading(true);
    if (currentPage === selectcurrentApiStartCountValue) {
      setIsLoading(false);
    }
    dispatch(setJewelleryCurrentApiStartCount({ currentPage }));
    dispatch(setJewelleryItemPerPage({ itemsPerPage }));
    // getBody(props.match.params.auctionId, parseInt(Page), endCount);
  };

  const handleMyBids = (auctionsProduct: any) => {
    if (auctionsProduct.length > 0) {
      setAuctionProducts(auctionsProduct);
    }
  };

  const setShowBidPopupBidModal = () => {
    // setshowTenderSlider(true);
  };

  const addToWatchList = (
    productId: string | number,
    auctionId: string | number
  ) => {
    setOnAction(true);
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${productUrl}/${auctionId}/${productId}/watch`,
        "payload",
        (status: any, response: any) => {
          if (response !== undefined) {
            if (status === 200) {
              callApi(payload);
              setIsLoading(false);
              setOnAction(false);
              setShowAlertMessage(`${t("common:preview:addedToWatchMsg")}`);
              setAlertType("success");
              setOpenToast(true);
            } else if (status === 403) {
              setUserBlock(true);
            } else if (status === 404) {
              setIsLoading(false);
              setShowAlertMessage(`${t("common:invalidRequest")}`);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            } else if (status === 505) {
              setApiTimeout(true);
              setOnAction(false);
            } else {
              setIsLoading(false);
              setShowAlertMessage(response[0].message);
              setOnAction(false);
              setAlertType("error");
              setOpenToast(true);
            }
          } else {
            setIsLoading(false);
            setShowAlertMessage(response);
            setOnAction(false);
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const watchlist = () => {
    localStorage.removeItem("selectedFilterSort");
    props.history.push(`/my-page`);
    // props.history.push(
    //   `/watchlist/${props.match.params.auctionId}/${props.match.params.auctionUserId}/${props.match.params.auctionDate}/Popcorn`
    // );
    // dispatch(resetJewelleryItemPerPage());
    // dispatch(resetJewelleryCurrentApiStartCount());
  };

  const listGridview = () => {
    selectJewelleryPageUseRef.current = startCount;
    setUiBoxView(true);
    dispatch(resetTenderJwlIsGridView());
  };

  const Gridview = () => {
    if (localStorage.getItem("currentPageOfJewellery") !== null) {
      let pageval: any = localStorage.getItem("currentPageOfJewellery");
      page = parseInt(pageval);
    }
    setUiBoxView(false);
    dispatch(setTenderJwlIsGridView({ gridView: false }));
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = () => {
    const header: any = document.querySelector(".sticky-actionbar");
    const scrollTop = window.scrollY;
  };

  const renderAutomaticBidPopupInMobile = (data: any) => {
    // to do
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <StyledLayoutGridMain>
              <StyledBoxContent title="Click here for advance search">
                <StyledDropDown
                  src="/images/assets/doubledown.png"
                  alt="d"
                  style={{
                    transform: isFiltersOpen
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  }}
                  onClick={handleFilters}
                />
              </StyledBoxContent>
              {isFiltersOpen === true && (
                <div
                  style={{
                    padding: '20px'
                  }}
                >
                  <JewelleryFilter
                    {...props}
                    t={t}
                    filterJewelleryAuction={filteredAuction}
                    auctionId={null}
                  />
                </div>
              )}
              <StyledLayoutGrid container={true}>
                <StyledCoreDataGridContainer container={true} spacing={0}>
                  <StyledActionBarContainer
                    container={true}
                    spacing={0}
                    className="coreActionBarContainer"
                  >
                    <StyledContainer>
                      <BasicSearchBox
                        autoCompleteSearch={autoCompleteSearch}
                        textSearchListing={textSearchListing}
                        setTextSearchArray={setTextSearchArray}
                      />
                    </StyledContainer>
                    <StyledButtonHeader onClick={watchlist}>
                      {t("common:newCommanTrans:myPage")}
                    </StyledButtonHeader>
                    {exportExcelDownload ? (
                      <StyledButtonHeader onClick={exportExcel}>
                        {t("common:preview:pagination:export")}
                      </StyledButtonHeader>
                    ) : (
                      <StyledButtonHeader>
                        {t("common:preview:pagination:export")}
                      </StyledButtonHeader>
                    )}
                    <ActionTenderPagesizeComman
                      setItemsPerPageHandler={setItemsPerPageHandler}
                      t={t}
                    />
                    {notSmallDevices ? (
                      <>
                        {isGridView === true ? (
                          <StyledButtonView onClick={Gridview}>
                            <Styledview src="/images/view/listView.png" />
                          </StyledButtonView>
                        ) : (
                          <StyledButtonView onClick={listGridview}>
                            <Styledview src="/images/view/gridview.jpeg" />
                          </StyledButtonView>
                        )}
                      </>
                    ) : null}
                  </StyledActionBarContainer>
                </StyledCoreDataGridContainer>
                <Grid
                  container={true}
                  xs={12}
                  style={{ paddingTop: isTableExpanded ? "195px" : "20px" }}
                >
                  <StyledName container={true} spacing={0}>
                    <StyledContent xs={12}>
                      <EllipsisText
                        text={props.match.params.auctionName}
                        length={28}
                        tail={"..."}
                      />
                    </StyledContent>
                  </StyledName>
                </Grid>

                {isGridView === false && notSmallDevices && (
                  <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                      title={""}
                      data={auctionProducts}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                )}

                {isGridView === true
                  ? notSmallDevices && (
                    <>
                      <CommanCoreDataGridOfTender
                        t={t}
                        data={auctionProducts}
                        totalElements={totalElement}
                        itemPerPage={itemPerPage}
                        value={getUpdatedProducts}
                        noProductFound={handleMyBids}
                        jwlItemPerPageRef={selectItemPerPageValue}
                        selectJwlPageUseRef={
                          selectJewelleryPageUseRef.current
                        }
                      >
                        {auctionProducts.length > 0 ? (
                          <TenderListOfProduct
                            auctionUserId={auctionUserId}
                            {...props}
                            watchlist={addToWatchList}
                            unWatchlist={removeFromWatchlist}
                            setShowBidPopupBidModal={setShowBidPopupBidModal}
                            auctionName={props.match.params.auctionName}
                            auctionDate={props.match.params.auctionDate}
                            t={t}
                            isAuctionPage={isAuctionPage}
                            isCategoryPageType={isCategoryPageType}
                            sortingValue={filterSort}
                            renderAutomaticBidPopupInMobile={
                              renderAutomaticBidPopupInMobile
                            }
                            renderReadOnlyUserToast={renderReadOnlyUserToast}
                            rightClickEvent={rightClickEvent}
                          />
                        ) : (
                          <Grid container={true} spacing={0}>
                            <StyledTextWrapper item xs={12}>
                              {t("common:preview:noProductFound")}
                            </StyledTextWrapper>
                          </Grid>
                        )}
                      </CommanCoreDataGridOfTender>
                    </>
                  )
                  : null}

                {notSmallDevices || (
                  <>
                    <CommanCoreDataGridOfTender
                      t={t}
                      data={auctionProducts}
                      totalElements={totalElement}
                      itemPerPage={itemPerPage}
                      value={getUpdatedProducts}
                      noProductFound={handleMyBids}
                      jwlItemPerPageRef={selectItemPerPageValue}
                      selectJwlPageUseRef={selectJewelleryPageUseRef.current}
                    >
                      {auctionProducts.length > 0 ? (
                        <TenderListOfProduct
                          auctionUserId={auctionUserId}
                          {...props}
                          watchlist={addToWatchList}
                          unWatchlist={removeFromWatchlist}
                          setShowBidPopupBidModal={setShowBidPopupBidModal}
                          auctionName={props.match.params.auctionName}
                          auctionDate={props.match.params.auctionDate}
                          t={t}
                          isAuctionPage={isAuctionPage}
                          isCategoryPageType={isCategoryPageType}
                          sortingValue={filterSort}
                          renderAutomaticBidPopupInMobile={
                            renderAutomaticBidPopupInMobile
                          }
                          renderReadOnlyUserToast={renderReadOnlyUserToast}
                          rightClickEvent={rightClickEvent}
                        />
                      ) : (
                        <Grid
                          container={true}
                          spacing={0}
                          style={{ marginTop: "20px" }}
                        >
                          {notSmallDevices ? (
                            <MuiThemeProvider theme={getMuiTheme()}>
                              <MUIDataTable
                                title={""}
                                data={auctionProducts}
                                columns={columns}
                                options={options}
                              />
                            </MuiThemeProvider>
                          ) : (
                            <StyledTextWrapper item xs={12}>
                              {t("common:preview:noProductFound")}
                            </StyledTextWrapper>
                          )}
                        </Grid>
                      )}
                    </CommanCoreDataGridOfTender>
                  </>
                )}
                <Menu
                  keepMounted
                  open={mouseState.mouseY !== null}
                  onClose={handleClose}
                  anchorReference="anchorPosition"
                  anchorPosition={
                    mouseState.mouseY !== null && mouseState.mouseX !== null
                      ? { top: mouseState.mouseY, left: mouseState.mouseX }
                      : undefined
                  }
                >
                  <MenuItem
                    onClick={() =>
                      openDetailsView(
                        props.match.params.auctionId,
                        currentProductId,
                        props.match.params.auctionDate
                      )
                    }
                  >
                    Details
                  </MenuItem>
                </Menu>
                {openToast === true && (
                  <Alert
                    showAlertMessage={showAlertMessage}
                    alertType={alertType}
                  />
                )}
                {onAction === true && (
                  <div className="loading">
                    <LoadingSpinner />
                  </div>
                )}
                {userBlock === true && (
                  <StyledModal open={userBlock}>
                    <BlockUserModal t={t} {...props} closeModal={closeModal} />
                  </StyledModal>
                )}
                {openNotesPopups === true &&
                  notes !== undefined &&
                  notes !== "" && (
                    <StyledModal
                      open={openNotesPopups}
                      onClose={closeNotesModle}
                    >
                      <NotesModal
                        t={t}
                        {...props}
                        closeModal={closeModal}
                        notes={notes}
                      />
                    </StyledModal>
                  )}
                {openReadOnlyUserToast === true && (
                  <AlertReadOnlyUser
                    isReadOnlyUser={isReadOnlyUser}
                    isSeller={isAuctionUserId}
                  />
                )}
              </StyledLayoutGrid>
            </StyledLayoutGridMain>
          )}
        </Grid>
      )}
    </AppLayout>
  );
};

export default JewelleryTendorPreviewView;
