import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import IRouterComponentProps from '../../models/IRouterComponentProps';

import Header from '../header/header';
import Footer from '../footer/footer';
import Alert from '../../common/elements/Alert';
import SubHeader from '../header/SubHeader';

const StyledHeaderContainer = styled(Grid)`
    padding: 0 !important;
`;

const ChildrenDiv = styled(Grid)`
    padding: 0 !important;
`;

const StyledFooterContainer = styled(Grid)` 
    padding: 0 !important;
`;

export interface ILandingPageLayoutProps extends IRouterComponentProps {
    t?: any;
    children?: any;
}

const LandingPageLayout = (props: ILandingPageLayoutProps) => {
    const [openToast, setOpenToast] = useState(false);
    const [showAlertMessage, setShowAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');

    const { children, t } = props;

    const showSuccessfulToast = () => {
        setShowAlertMessage(`${t('common:profile:forgotPassword:onSuccessful:passwordChange')}`);
        setAlertType('success');
        setOpenToast(true);
    }

    const showTimeoutToast = () => {
        setShowAlertMessage(`${t('common:somethingWentWrong')}`);
        setAlertType('error');
        setOpenToast(true);
    }

    return (
        <Grid container={true} spacing={0} className="mainContainer">
            <StyledHeaderContainer item={true} xs={12} className="headerContainer">
                <Header {...props} showSuccessfulToast={showSuccessfulToast} showTimeoutToast={showTimeoutToast} />
            </StyledHeaderContainer>
            <StyledHeaderContainer item={true} xs={12} className="headerContainer">
                <SubHeader {...props}/>
            </StyledHeaderContainer>
            <ChildrenDiv item={true} xs={12} className="contentContainer">
                {children}
            </ChildrenDiv>
            <StyledFooterContainer item={true} xs={12} className="footerContainer">
                <Footer/>
            </StyledFooterContainer>
            {openToast === true &&
                <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
            }
        </Grid>
    )
}

export default LandingPageLayout;