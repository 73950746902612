import React, { Component, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import {
  StyledTextInput,
  StyledComponent,
  StyledTitleText,
} from "../../common/materials/GenericStyleComponent";
import AuthService from "../../components/auth/AuthService";
import {
  b,
  baseUrlRedirect,
  clockNotInSyncUrl,
  joinedAuctionsUrl,
  sendLoginOTP,
  tokenUrl,
} from "../../common/config/app.endpoints";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import {
  buttonGradient,
  dateBackgroundGradient,
} from "../../common/materials/LinearGradient";
import {
  getProfileData,
  getTime,
  joinAnnualAuction,
} from "../../common/GenericFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { connect } from "react-redux";
import {
  resetAnnualAuctionData,
  resetColorFilter,
  resetDiamondGridProductAllProductIndexes,
  resetDiamondGridProductIndexes,
  resetDiamondGridProductWatchlistIndexes,
  resetGridProductAllProductIndexes,
  resetGridProductIndexes,
  resetGridProductWatchlistIndexes,
  resetIsGridView,
  resetjewelleryAllProductSelectAll,
  resetjewellerySelectAll,
  resetJwlAnnualAuctionData,
  resetJwlIsGridView,
  resetLiveIsGridView,
  resetLiveJwlIsGridView,
  resetNotificationLastMonthLive,
  resetNotificationLive,
  resetTenderDiamondGridProductAllProductIndexes,
  resetTenderDiamondGridProductIndexes,
  resetTenderDiamondGridProductWatchlistIndexes,
  resetTenderGridProductAllProductIndexes,
  resetTenderGridProductIndexes,
  resetTenderGridProductWatchlistIndexes,
  resetTenderIsGridView,
  resetTenderJwlIsGridView,
  setNotificationApiCall,
} from "../../redux/slice/annual/commanAnnualSlice";
import {
  reSetIsMybids,
  reSetLiveAuctionPagination,
  reSetLiveCurrentApiStartCount,
} from "../../redux/slice/commanPagination/commanPaginationSlice";
const faEyeProps = faEye as IconProp;
const eye = <FontAwesomeIcon icon={faEyeProps} />;

const StyledLoginContainer = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 480px;
  }
  .isLogin {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  padding: 0.5em 1em;

  font-size: 2.5em;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  // margin: 20px 0;
`;

const StyledForgotPassword = styled.div`
  color: ${(props) => props.theme.colors.base.textColor};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
  cursor: pointer;
  padding-top: 1.5em;
  padding-left: 1.5em;
  font-weight: bold;
`;

const StyledRegisterWrapper = styled.div`
  color: ${(props) => props.theme.colors.base.black};
  padding: 0.5em;
  padding-left: 1.5em;
  padding-bottom: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 0.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1em;
  }
`;

const StyledRegisterNow = styled.span`
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.textColor};
`;

const StyledPasswordCon = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 14px;
  input {
    padding-right: 2em !important;
  }
  i {
    position: absolute;
    top: 34%;
    right: 2%;
    cursor: pointer;
  }
`;

const StyledotpTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-family: "Calibre-Regular";
  font-size: 1em;
  cursor: pointer;
`;
const StyledInfoWrapper = styled(Grid)`
  padding: 0.5em 1em;
  flex-direction: column;
  display: flex;
`;

const StyledBottomButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export interface IProps {
  t?: any;
  I18n?: {};
  history?: any;
  location?: {};
  match?: {};
  forgotPasswordModal: () => void;
  registerModal: () => void;
  isFromSubDomain: boolean;
  renderLoginWithOTPPopup: () => void;
  userEmailvalue: (value: any) => void;
  updateValue: any;
  subdomainfunc: (value: any) => void;
  test: any;
  subdomainName?: any;
}

export interface IState {
  email: string;
  password: string;
  message: string;
  isLoading: boolean;
  showPassword: boolean;
  showOtpUi: boolean;
}

let localization: string = "";
class LoginForm extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.togglePasswordVisiblity = this.togglePasswordVisiblity.bind(this);
    const urlParams = new URLSearchParams(window.location.search);

    let emailchangePlan: any = urlParams.get("email");
    this.state = {
      email: emailchangePlan !== undefined ? emailchangePlan : "",
      password: "",
      message: "",
      isLoading: false,
      showPassword: false,
      showOtpUi: false,
    };
  }

  Auth = new AuthService();

  resetValue() {
    let email = document.getElementById("user_email") as HTMLInputElement;
    if (email) {
      email.value = "";
      this.setState({
        email: "",
        showOtpUi: false,
      });
    }
    let password = document.getElementById("user_password") as HTMLInputElement;
    if (password) {
      password.value = "";
      this.setState({
        password: "",
      });
    }
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (this.props.updateValue !== undefined) {
      this.setState({
        email: this.props.updateValue.email,
      });
    }
    if (e.target.name === "email") {
      this.setState({
        email: e.target.value,
        showOtpUi: true,
      });
      localStorage.setItem("changePlanEmail", e.target.value);
    }
    if (e.target.name === "password") {
      this.setState({
        password: e.target.value,
      });
    }
  }

  handleFormSubmit(e: any) {
    e.preventDefault();
    const { t } = this.props;

    const email = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(
      this.state.email.trim()
    );
    if (email !== true && this.state.password === "") {
      this.setState({
        message: `${t("common:allValidations:emptyFields")}`,
      });
    } else if (email !== true) {
      this.setState({
        message: `${t("common:register:errorMessage:onEmail")}`,
      });
    } else if (this.state.password === "") {
      this.setState({
        message: `${t("common:login:validation:invalidEmailorPassword")}`,
      });
    } else {
      this.setState({
        isLoading: true,
      });
      const data = {
        username: this.state.email.trim(),
        password: this.state.password.trim(),
      };

      this.Auth.postRequestWithoutAuthorization(
        tokenUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            this.Auth.setToken(response.token);
            localStorage.setItem("email", data.username);
            localStorage.setItem("otpAuth", "false");
            this.joinedAuction();
            this.props.test();
            localStorage.removeItem("changePlanEmail");
          } else if (status > 200 && status < 500) {
            if (response[0].code !== undefined) {
              if (response[0].code === "KB409001") {
                this.setState({
                  message: `${t(
                    "common:register:errorMessage:profileVerification"
                  )}`,
                  isLoading: false,
                });
              } else if (response[0].code === "KB412004") {
                this.setState({
                  message: `${t(
                    "common:login:validation:invalidEmailorPassword"
                  )}`,
                  isLoading: false,
                });
              } else if (response[0].code === "KB401002") {
                this.setState({
                  message: `${t(
                    "common:login:validation:registeredAsAuctioneer"
                  )}`,
                  isLoading: false,
                });
              } else if (response[0].code === "KB404001") {
                this.setState({
                  message: `${t("common:login:validation:userNotRegistered")}`,
                  isLoading: false,
                });
              } else if (response[0].code === "KB401004") {
                this.setState({
                  message: ``,
                  isLoading: false,
                });
                this.props.subdomainfunc(response[0].field);
              } else if (response[0].code === "KB401005") {
                this.setState({
                  message: `You don't have any invitation to access.`,
                  isLoading: false,
                });
              } else {
                this.setState({
                  message: `${t("common:invalidRequest")}`,
                  isLoading: false,
                });
              }
            }
          } else if (status === 500) {
            this.setState({
              message: response[0].message,
              isLoading: false,
            });
          }
          this.setState({
            isLoading: false,
          });
        }
      );
    }
  }

  joinedAuction() {
    getTime();
    getProfileData();
    joinAnnualAuction();
    this.Auth.getRequest(joinedAuctionsUrl, (status: any, response: any) => {
      this.setState({
        isLoading: false,
      });
      let clockSynced = localStorage.getItem("clockNotSynced");
      if (clockSynced === "true") {
        let time: any = localStorage.getItem("timeInMilliSeconds");
        time = parseInt(time);
        this.Auth.postRequest(
          `${clockNotInSyncUrl}${time}`,
          "data",
          (status: any, response: any) => { }
        );
      }
      if (status === 200) {
        if (response !== undefined) {
          var pathname = localStorage.getItem("pathname");
          localStorage.setItem("otpAuth", "false");
          if (response.results.length > 0) {
            const urlRedirectFromEmail = localStorage.getItem(
              "urlRedirectFromEmail"
            );
            const urlRedirectFromAdmin = localStorage.getItem(
              "urlRedirectFromAdmin"
            );
            const urlPendingProductFromEmail = localStorage.getItem(
              "urlPendingProductFromEmail"
            );
            const urlProductAuctionFromEmail = localStorage.getItem(
              "urlProductAuctionFromEmail"
            );
            if (
              urlRedirectFromEmail !== undefined &&
              urlRedirectFromEmail !== "" &&
              urlRedirectFromEmail !== null
            ) {
              pathname = urlRedirectFromEmail;
            }
            if (
              urlRedirectFromAdmin !== undefined &&
              urlRedirectFromAdmin !== "" &&
              urlRedirectFromAdmin !== null
            ) {
              pathname = urlRedirectFromAdmin;
            }
            if (
              urlPendingProductFromEmail !== undefined &&
              urlPendingProductFromEmail !== "" &&
              urlPendingProductFromEmail !== null
            ) {
              pathname = urlPendingProductFromEmail;
            }
            if (
              urlProductAuctionFromEmail !== undefined &&
              urlProductAuctionFromEmail !== "" &&
              urlProductAuctionFromEmail !== null
            ) {
              pathname = urlProductAuctionFromEmail;
            }
            let isSubDomainUser = localStorage.getItem("subDomain");
            let redireactionalUrl = localStorage.getItem("pathname");
            let isSubdomainPreview =
              `/${isSubDomainUser}/preview` === redireactionalUrl;

            if (isSubdomainPreview) {
              pathname = `/preview`;
            }
            var redirectPath = pathname;
            if (redirectPath) {
              this.props.history.push(redirectPath);
            } else {
              if (pathname) {
                const pathArray = pathname.split("/");
                if (
                  pathArray[1].includes("KycForm") ||
                  pathArray[1].includes("PaymentForm") ||
                  pathArray[1].includes("paymentForm")
                ) {
                  redirectPath = "/";
                  localStorage.setItem("pathname", redirectPath);
                }
              }

              this.props.history.push(redirectPath);
            }
          } else {
            // this.props.history.push("/home");
            if (pathname === "/365products") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/jewellery") {
              redirectPath = "/365products/jewellery";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/Diamonds") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/Diamond") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if ((pathname = `/preview`)) {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else {
              this.props.history.push("/home");
            }
          }
          this.resetValue();
        }
      } else {
        this.props.history.push("/home");
      }
      localStorage.removeItem("optionValue");
      //window.location.reload();
    });
  }

  forgotPasswordModal() {
    this.props.forgotPasswordModal();
  }

  otpModal() {
    const { t } = this.props;
    const email = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(
      this.state.email.trim()
    );
    if (this.state.email === "") {
      this.setState({
        message: `${t("common:register:errorMessage:emptyEmail")}`,
      });
    } else if (email !== true) {
      this.setState({
        message: `${t("common:register:errorMessage:onEmail")}`,
      });
    } else {
      this.Auth.postRequestWithoutAuthorization(
        `${sendLoginOTP}?un=${this.state.email}`,
        "data",
        (status: number, response: any) => {
          if (status === 200) {
            this.props.renderLoginWithOTPPopup();
          } else if (status > 200 && status < 500) {
            if (response[0].code === "KB404001") {
              this.setState({
                message: `${t(
                  "common:profile:forgotPassword:validation:notRegisteredEmail"
                )}`,
                isLoading: false,
              });
            }
          } else {
            this.setState({
              isLoading: false,
            });
          }
        }
      );
    }
  }

  togglePasswordVisiblity() {
    if (this.state.showPassword) {
      this.setState({
        showPassword: false,
      });
    } else {
      this.setState({
        showPassword: true,
      });
    }
  }
  genSubDomainURLHandler() {
    let language = localStorage.getItem("language");
    if (language === "EN") {
      localization = "en";
    } else if (language === "zh_CN") {
      localization = "zh_CN";
    } else {
      localization = "jp";
    }
    let url: any = "";
    url = `${baseUrlRedirect.baseUrl}`;
    if (url === "https://myauctionstech.in") {
      window.open("https://myauctionstech.in/admin", "_blank");
    } else if (url === "https://myauctionsdemo.in") {
      window.open("https://myauctionsdemo.in/admin", "_blank");
    } else {
      window.open("https://auctioneer.myauctions.jp/admin/", "_blank");
    }
  }

  render() {
    const { t } = this.props;
    return (
      <StyledComponent tabIndex={-1}>
        <StyledLoginContainer>
          <StyledTitle className="fontBold">
            <StyledTitleText>{t("common:login:title")}</StyledTitleText>
          </StyledTitle>
          <form noValidate={true} onSubmit={this.handleFormSubmit}>
            <StyledMessage>{this.state.message}</StyledMessage>
            <StyledTextInput>
              <TextField
                required
                fullWidth
                name="email"
                autoFocus={true}
                id="user_email"
                label={t("common:login:labels:email")}
                type="text"
                variant="outlined"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </StyledTextInput>
            <StyledTextInput>
              <StyledPasswordCon className="pass-wrapper">
                <TextField
                  required
                  fullWidth
                  name="password"
                  id="user_password"
                  label={t("common:login:labels:password")}
                  type={this.state.showPassword ? "text" : "password"}
                  variant="outlined"
                  onChange={this.handleChange}
                />
                <i onClick={this.togglePasswordVisiblity}>{eye}</i>
              </StyledPasswordCon>
            </StyledTextInput>
            <StyledBottomButton>
              <StyledInfoWrapper>
                {this.state.showOtpUi ? (
                  <StyledotpTextContainer
                    onClick={(e: any) => {
                      this.otpModal();
                      this.props.userEmailvalue(this.state);
                    }}
                  >
                    {t("common:button:otpSignIn")}
                  </StyledotpTextContainer>
                ) : (
                  <StyledotpTextContainer
                    onClick={(e: any) => {
                      this.otpModal();
                      this.props.userEmailvalue(this.state);
                    }}
                  >
                    {t("common:button:otpSignIn")}
                  </StyledotpTextContainer>
                )}
                <StyledotpTextContainer onClick={this.genSubDomainURLHandler}>
                  {t("common:login:signInAuctioneer")}
                </StyledotpTextContainer>
              </StyledInfoWrapper>
              <StyledButtonContainer>
                {this.state.isLoading === false ? (
                  <StyledButton onSubmit={this.handleFormSubmit}>
                    {t("common:button:signIn")}
                  </StyledButton>
                ) : (
                  <StyledButton className="isLogin">
                    {t("common:button:signIn")}
                  </StyledButton>
                )}
              </StyledButtonContainer>
            </StyledBottomButton>
          </form>
          <div>
            <StyledForgotPassword onClick={() => this.forgotPasswordModal()}>
              {t("common:login:forgotPassword")}?
            </StyledForgotPassword>
            {this.props.isFromSubDomain === false && (
              <StyledRegisterWrapper onClick={() => this.props.registerModal()}>
                {t("common:login:dontHaveAccount")}?{" "}
                <StyledRegisterNow>
                  {t("common:login:signInAuctioneer")}
                </StyledRegisterNow>
              </StyledRegisterWrapper>
            )}
          </div>
          {this.state.isLoading === true && (
            <div className="loading">
              <LoadingSpinner />
            </div>
          )}
        </StyledLoginContainer>
      </StyledComponent>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  test: () => {
    dispatch(resetGridProductIndexes());
    dispatch(resetjewellerySelectAll());
    dispatch(resetGridProductAllProductIndexes());
    dispatch(resetjewelleryAllProductSelectAll());
    dispatch(resetTenderDiamondGridProductIndexes());
    dispatch(resetTenderDiamondGridProductAllProductIndexes());
    dispatch(resetTenderDiamondGridProductWatchlistIndexes());
    dispatch(resetTenderGridProductWatchlistIndexes());
    dispatch(resetTenderGridProductAllProductIndexes());

    dispatch(resetGridProductWatchlistIndexes());
    dispatch(resetDiamondGridProductIndexes());
    dispatch(resetDiamondGridProductAllProductIndexes());

    dispatch(resetDiamondGridProductWatchlistIndexes());
    dispatch(resetTenderGridProductIndexes());

    dispatch(resetIsGridView());
    dispatch(resetLiveIsGridView());
    dispatch(resetTenderIsGridView());
    dispatch(resetTenderJwlIsGridView());
    dispatch(resetJwlIsGridView());
    dispatch(resetIsGridView());
    dispatch(resetLiveJwlIsGridView());
    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());
    dispatch(reSetIsMybids());
    dispatch(resetJwlAnnualAuctionData());
    dispatch(resetAnnualAuctionData());
    dispatch(resetNotificationLastMonthLive());
    dispatch(resetNotificationLive());
    dispatch(setNotificationApiCall({ response: true }));
    dispatch(resetColorFilter());
  },
});
export default connect(null, mapDispatchToProps)(LoginForm);
