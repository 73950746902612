import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import AuthService from "../../../components/auth/AuthService";
import { singleProductDetailApi } from "../../../common/config/app.endpoints";

//import {  } from "../../../common/config/app.endpoints";

const Auth = new AuthService();
export let biddingProductDetails: [];

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

export interface IProductDetails {
  productId: string;
  auctionId: string;
}

const initialState = {
  loading: false,
  error: "",
};

export const getBiddingProductDetails = (data: IProductDetails) => {
  let {auctionId,productId} = data;
  Auth.postRequest(`${singleProductDetailApi}/${auctionId}/${productId}/getProduct`,'', (status: number, response: any) => {
    if (status === 200) {
      biddingProductDetails = response;
    }else{
      console.log(status)
    }
  });
};

const commonProductDetailsSlice = createSlice({
  name: "productDetail",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {},
});

export default commonProductDetailsSlice.reducer;

// selectors
export const selectProductDetailsLoading = (state: RootState) => state.commonProductDetailsSlice.loading;