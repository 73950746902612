import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import AppLayout from '../components/layouts/AppLayout';
import IRouterComponentProps from '../models/IRouterComponentProps';
import { currentTimeUrl, joinedAuctionsUrl, rateFromUSD2JPY } from '../common/config/app.endpoints';
import AuthService from '../components/auth/AuthService';
import AuctionList from '../components/products/auction/AuctionList';
import CoreDataGridOfAuction from '../components/core-grid/grid-data/CoreDataGridOfAuction';
import Alert from '../common/elements/Alert';
import LoadingSpinner from '../common/elements/LoadingSpinner';
import ApiTimeout from './ApiTimeout';
import { StyledModal } from '../common/materials/GenericStyleComponent';
import { oAuth } from '../routes/basic/BasicAppRouter';
import { getTimeDifference, joinAuction } from '../common/GenericFunctions';
import BlockUserModal from '../components/products/BlockUser/BlockUserModal';
import PubSub from "pubsub-js";
import JewelleryAuction from './JewelleryAuction';
import AllCategoryAuctionList from '../components/products/auction/AllCategoryAuctionList';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetItemPerPage, resetCurrentApiStartCount, resetJewelleryItemPerPage, resetJewelleryCurrentApiStartCount, reSetJwlIsMybids, reSetIsMybids } from '../redux/slice/commanPagination/commanPaginationSlice';
import { AppDispatch } from '../redux/store';
import { resetColorFilter, resetGridProductAllProductIndexes, resetGridProductIndexes, resetjewelleryAllProductSelectAll, resetjewellerySelectAll, resetTenderDiamondGridProductAllProductIndexes, resetTenderDiamondGridProductIndexes, resetTenderDiamondGridProductWatchlistIndexes, resetTenderGridProductAllProductIndexes, resetTenderGridProductWatchlistIndexes } from '../redux/slice/annual/commanAnnualSlice';

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

const StyledGrid = styled(Grid)`
    padding: 10px 50px;
    display: flex-wrap;
    overflow: auto;
    ::-webkit-scrollbar {display:none;};
    ${props => props.theme.breakpoints.between('xs', 'md')} {
        padding: 10px 10px;
    } 
    ${props => props.theme.breakpoints.between('md', 'lg')} {
        padding: 25px 20px;
    }
`;

interface IProps extends IRouterComponentProps {
    t: any;
}

export interface IState {
    auctions: [];
    openToast: boolean;
    showMessage: '';
    isLoading: boolean;
    counter: any;
    alertType: string;
    openTimerModal: boolean;
    apiTimeout: boolean;
}

let auctionListRefresh: any = null;

const Auction = React.forwardRef((props: IProps, ref) => {
    const [auctions, setAuctions] = useState([]);
    const [openToast, setOpenToast] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [openTimerModal, setOpenTimerModal] = useState(false);
    const [apiTimeout, setApiTimeout] = useState(false);
    const [userBlock, setUserBlock] = useState(false);
    const [isShowJewelley, setShowJewelley] = useState(false);
    const [isHomeCategoryActive, setIsHomeCategoryActive] = useState(true);
    const [allCategoryAuctions, setAllCategoryAuctions] = useState([]);
    const { t } = props;
    const Auth = new AuthService();
    let history = useHistory();
    const dispatch: AppDispatch = useDispatch();

    useEffect(() => {
        PubSub.unsubscribe("StopStartTimer");
        localStorage.setItem("refreshFlag", "false");
        localStorage.setItem("tabSelectedVal", "1");
        localStorage.removeItem("currentPageOfTenderAllProducts");
        localStorage.removeItem("currentProductIdOfBidNow");
        localStorage.removeItem("currentJewelleryProductIdOfBidNow");
        localStorage.removeItem("currentPDItem");
        localStorage.removeItem("currentPageOfJewellery");
        localStorage.removeItem("jwlcurrentPageOfTenderAllProducts");
        localStorage.removeItem("filteredParcelValues");
        localStorage.removeItem("filteredJewelleryValues");
        localStorage.removeItem("NRProductFilterd");
        localStorage.removeItem("filteredValues");
        localStorage.removeItem("annualitemsPerPage");
        localStorage.removeItem("tenderitemsPerPage");
        localStorage.removeItem("annualdropDownFilterVal");
        localStorage.removeItem("itemPerPageSelected");
        localStorage.removeItem("activePageNumber");
        localStorage.removeItem("apiStartCount");
        localStorage.removeItem("jwlitemPerPageSelected");
        localStorage.removeItem("jwlactivePageNumber");
        localStorage.removeItem("jwlapiStartCount");
        localStorage.removeItem("itemsPerPage");
        localStorage.removeItem("selectedFilterSort");
        dispatch(resetItemPerPage());
        dispatch(resetCurrentApiStartCount());
        dispatch(resetJewelleryItemPerPage());
        dispatch(resetJewelleryCurrentApiStartCount());
        dispatch(reSetIsMybids());
        dispatch(reSetJwlIsMybids());
        dispatch(resetColorFilter());
        dispatch(resetGridProductIndexes());
        dispatch(resetjewellerySelectAll());
        dispatch(resetGridProductAllProductIndexes());
        dispatch(resetjewelleryAllProductSelectAll());
        dispatch(resetTenderDiamondGridProductIndexes());
        dispatch(resetTenderDiamondGridProductAllProductIndexes());
        dispatch(resetTenderDiamondGridProductWatchlistIndexes());
        dispatch(resetTenderGridProductWatchlistIndexes());
        dispatch(resetTenderGridProductAllProductIndexes());

        PubSub.subscribe("StopStartTimer", (msg: any, data: any) => {

            if (data === "Stop") {
                if (auctionListRefresh) {
                    clearInterval(auctionListRefresh);
                }
            } else {
                update();
            }

        })

        setIsLoading(true);
        if (!oAuth()) {
            props.history.push('/');
        } else {
            localStorage.removeItem("timeDifference");
            localStorage.removeItem('filteredValues');
            getAuction();
            getAllCategoryAuction();
            // getExchangeRate();
            getTimeDifference();
            joinAuction();
        }
        return () => setIsLoading(false);
    }, []);

    useEffect(() => {
        if (
            history.location.pathname === "/auction" ||
            history.location.pathname === "/auction/home"
        ) {
            handleComponents("HOME");
        } else if (history.location.pathname === "/auction/diamond") {
            handleComponents("DIAMODS");
        } else {
            handleComponents("JEWELLERY");
        }
    }, [history.location.pathname]);

    const getAuction = () => {
        Auth.getRequest(`${joinedAuctionsUrl}?category=${"Diamonds"}`, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    if (!oAuth()) {
                        props.history.push('/');
                    } else {
                        getTime(response.results)
                        if (auctionListRefresh) {
                            clearInterval(auctionListRefresh);
                        }
                        update();
                    }
                    setAuctions(response.results);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }
            else if (status === 403) {
                setUserBlock(true);
            }
            else if (status === 505) {
                setApiTimeout(true);
                setIsLoading(false);
            }
            else {
                setAlertType('error');
                setOpenToast(false);
                // setShowMessage(response.data.message);
                setIsLoading(false);
            }
        })
    }

    const getAllCategoryAuction = () => {
        Auth.getRequest(`${joinedAuctionsUrl}`, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    if (!oAuth()) {
                        props.history.push('/');
                    } else {
                        getTime(response.results)
                        if (auctionListRefresh) {
                            clearInterval(auctionListRefresh);
                        }
                        update();
                    }
                    setAllCategoryAuctions(response.results);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }
            else if (status === 403) {
                setUserBlock(true);
            }
            else if (status === 505) {
                setApiTimeout(true);
                setIsLoading(false);
            }
            else {
                setAlertType('error');
                setOpenToast(false);
                // setShowMessage(response.data.message);
                setIsLoading(false);
            }
        })
    }

    const getExchangeRate = () => {
        Auth.getRequest(rateFromUSD2JPY, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    localStorage.setItem("reverseRate", response.reverseRate);
                    localStorage.setItem("rate", response.rate);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }
            else if (status === 403) {
                setUserBlock(true);
            }
            else if (status === 505) {
                setApiTimeout(true);
                setIsLoading(false);
            }
            else {
                setAlertType('error');
                setOpenToast(false);
                // setShowMessage(response.data.message);
                setIsLoading(false);
            }
        })
    }

    const getTime = (auctions: any) => {
        if (auctions !== undefined) {
            if (auctions.length > 0) {
                Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
                    if (status === 200) {
                        if (response !== undefined) {
                            var timePassedInMilliseconds = (Date.now() - response) / 1000;
                            if (timePassedInMilliseconds < -3 || timePassedInMilliseconds > 3) {
                                setOpenTimerModal(true);
                                setIsLoading(false);
                            }
                        }
                    }
                    else if (status === 403) {
                        setUserBlock(true);
                    }
                    else if (status === 505) {
                        setApiTimeout(true);
                        setIsLoading(false);
                    }
                    else {
                        setAlertType('error');
                        setOpenToast(false);
                        // setShowMessage(response.data.message);
                        setIsLoading(false);
                    }
                })
            }
        }
    }

    const update = () => {
        auctionListRefresh = setInterval(() => {
            if (!oAuth()) {
                props.history.push('/');
            } else {
                getAuction();
                getAllCategoryAuction();
            }
        }, 420000);
    }

    const value = (value: any) => {
        if (value === true) {
            getAuction();
            getAllCategoryAuction();
        }
    }

    const closeModal = () => {
        setOpenTimerModal(false);
    }

    const blockUserModal = () => {
        Auth.logout();
        props.history.replace('/');
    }



    const handleComponents = (categoryType: String) => {
        if (categoryType === "DIAMODS") {
            setShowJewelley(false);
            setIsHomeCategoryActive(false);
        } else if (categoryType === "HOME") {
            setIsHomeCategoryActive(true);
            setShowJewelley(false);
        } else {
            setShowJewelley(true);
            setIsHomeCategoryActive(false);
        }
    }

    return (
        <AppLayout {...props}>
            {apiTimeout === true ?
                <ApiTimeout {...props} /> :
                <Grid>
                    {isLoading === true ?
                        <div className="loader">
                            <LoadingSpinner />
                        </div> :

                        <StyledGrid>
                            {/* <Grid item={true} xs={12}>
                                <CategoryCarousal t={t} handleConponets={handleComponents} />
                            </Grid> */}
                            {isHomeCategoryActive !== false ? (
                                <>
                                    {allCategoryAuctions.length > 0 ?
                                        <CoreDataGridOfAuction t={t} data={allCategoryAuctions}>
                                            <AllCategoryAuctionList {...props} value={value} />
                                        </CoreDataGridOfAuction>
                                        :
                                        <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
                                    }
                                </>
                            ) : (
                                <>
                                    {isShowJewelley === true ?
                                        <JewelleryAuction {...props} />
                                        :
                                        <div>
                                            {auctions.length > 0 ?
                                                <CoreDataGridOfAuction t={t} data={auctions}>
                                                    <AuctionList {...props} value={value} />
                                                </CoreDataGridOfAuction>
                                                :
                                                <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
                                            }
                                        </div>

                                    }
                                </>
                            )}

                        </StyledGrid>
                    }
                </Grid>
            }
            {
                openToast === true &&
                <Alert showAlertMessage={showMessage} alertType={alertType} />
            }
            {userBlock === true &&
                <StyledModal open={userBlock}>
                    <BlockUserModal {...props} closeModal={blockUserModal} />
                </StyledModal>
            }
        </AppLayout>
    )
})

export default Auction;