import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { LandingPagePlayFairFont, StyledLinkBlue } from "../StyledComponents";

import { DialogOption } from "../../../common/elements/Dialog/Dialog";
import {
  StyledDialogContainer,
  StyledForm,
  StyledMessage,
} from "./StyledComponents";
import Button from "../../../common/elements/Button";
import { AppDispatch } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  getCountryList,
  selectAllCountries,
} from "../../../redux/slice/country/countrySlice";
import { RootState } from "../../../redux/reducer";
import ReCaptcha from "../../kyc/components/ReCaptcha";
import Colors from "../../../common/materials/colors";
import { toggleLoginPopup } from "../Utils";
import Cross from "../../LandingPage/Static/Icons/Cross";
import { getCities, getStates, signUp } from "../../LandingPage/Services";

export const SignUpFormDialogOptions: DialogOption = {
  maxWidth: "md",
  fullWidth: true,
  open: true,
};

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: Colors.red,
  },
  container: {
    margin: "1em 0",
  },
  extraPadding: {
    paddingTop: "5em",
    paddingBottom: "1em",
  },
  successContainer: {
    padding: "2em",
  },
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
  noPaddingV: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
  },
  margin: {
    marginTop: "2em",
  },
}));

const formFields = [
  {
    label: "common:register:labels:firstName",
    name: "firstName",
    required: true,
  },
  {
    label: "common:register:labels:lastName",
    name: "lastName",
    required: true,
  },
  {
    label: "common:register:labels:company",
    name: "company",
    required: true,
  },
  {
    label: "common:register:labels:email",
    name: "email",
    required: true,
  },
];

const occupations = [
  {
    value: "Auctioneer",
    label: "common:register:occupation:auctioneer",
  },
  {
    value: "Bidder",
    label: "common:register:occupation:bidder",
  },
  {
    value: "AuctioneerAndBidder",
    label: "common:register:occupation:auctioneerAndBidder",
  },
];

const countryField = {
  label: "common:register:labels:country",
  name: "country",
};
const stateField = {
  label: "common:register:labels:state",
  name: "state",
};

const cityField = {
  label: "common:register:labels:city",
  name: "city",
};
const addressField = {
  label: "common:register:labels:address",
  name: "address",
};

const phoneField = {
  label: "common:register:labels:phone",
  name: "phone",
};

const preferredLanuage = {
  label: "common:kycFrom:preferredLanuage",
  name: "preferredLanguageCode",
};

const languages = [
  { perId: "en", name: "ENGLISH" },
  { perId: "jp", name: "日本語" },
];

const OccupationRadioButtons = ({ isMobile }: { isMobile: boolean }) => {
  // const [selectedValue, setSelectedValue] = React.useState("Auctioneer");

  // const handleChange = (event: {
  //   target: { value: React.SetStateAction<string> };
  // }) => {
  //   setSelectedValue(event.target.value);
  // };

  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <div>
        <Typography variant="h6">
          {t("common:register:labels:occupation")}
        </Typography>
      </div>
      <RadioGroup
        row={!isMobile}
        aria-label="occupation"
        name="occupation"
        defaultValue={""}
      >
        {occupations.map((occupation) => (
          <FormControlLabel
            key={occupation.label}
            value={occupation.value}
            control={<Radio color="primary" />}
            label={t(occupation.label)}
            labelPlacement="end"
          />
        ))}
      </RadioGroup>
    </Grid>
  );
};

const getSelectField = (
  field: { label: string; name: string },
  classes: any,
  t: (s: any) => string,
  state: any,
  onChange: any,
  list: Array<{ name: string; perId: string }>,
  required: boolean,
  hasError = false
) => {
  return (
    <Grid item xs={12} sm={6} className={classes.noPaddingV}>
      <FormControl
        variant="outlined"
        fullWidth
        className={classes.margin}
        error={hasError}
        disabled={list.length === 0}
        required={required}
      >
        <InputLabel classes={classes} id={t(field.label)}>
          {t(field.label)}
        </InputLabel>
        <Select
          id={t(field.label)}
          label={t(field.label)}
          labelId={t(field.label)}
          name={t(field.name)}
          value={state[field.name]}
          onChange={onChange}
          required={required}
        >
          {list.map((country) => (
            <MenuItem key={country.perId} value={country?.perId}>
              {country?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
};
const SignUpFormDialog = ({
  isMobile,
  onSignUp,
  close,
}: {
  isMobile: boolean;
  onSignUp?: () => void;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const classes = useStyles();

  const dispatch: AppDispatch = useDispatch();

  // const [error, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [notARobot, setNotARobot] = useState(false);

  const countriesList = useSelector(
    (state: RootState) =>
      selectAllCountries(state) as Array<{ name: string; perId: string }>
  );

  const [addressState, setAddressState] = useState({
    country: "",
    state: "",
    city: "",
    preferredLanguageCode: "",
  });

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [errorStates, setErrorState] = useState({} as any);

  useEffect(() => {
    // Fetch the data from backend
    dispatch(getCountryList({}));
  }, []);

  useEffect(() => {
    if (addressState.country) {
      getStates(addressState.country).then((_states) => {
        setStates(_states);
      });
    }
  }, [addressState.country]);

  useEffect(() => {
    if (addressState.state) {
      getCities(addressState.state).then((_cities) => {
        setCities(_cities);
      });
    }
  }, [addressState.state]);

  // useEffect(() => {
  //   // Fetch the data from backend
  // }, [countriesList]);

  const handleFormSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const form = formRef.current;
    if (!form) {
      return;
    }
    const formData = new FormData(form);

    const errors = {} as any;
    const values = Object.fromEntries(formData.entries()) as any;
    for (const field in values) {
      const elem = form[field] as {
        value: any;
        type: string;
        getAttribute: any;
      };
      if (elem?.type === "text") {
        const required = elem?.getAttribute("required") === "";
        if (required && !elem.value) {
          errors[field] = true;
        }
      }
    }

    if (values.checkedTerms === undefined) {
      errors.checkedTerms = true;
    } else if (values["g-recaptcha-response"] === "") {
      errors["g-recaptcha-response"] = true;
    } else if (!values.occupation) {
      errors.occupation = true;
    }

    setErrorState(errors);
    const validationErrors = validation(values);
    let hasErrors = false;
    for (let error in { ...errors, ...validationErrors }) {
      hasErrors = true;
      break;
    }

    if (hasErrors || !checkedTerms) {
      return;
    }
    // create the user;
    if (!hasErrors) {
      const country = countriesList.find(
        (country) => country.perId === values.country
      );
      const state = states.find(
        (state: { perId: string }) => state.perId === values.state
      );
      const city = cities.find(
        (city: { perId: string }) => city.perId === values.city
      );
      const updatedValues = {
        ...values,
        country: undefined,
        state: undefined,
        city: undefined,
        countryDetail: { ...country, id: undefined },
        stateDetail: state,
        cityDetail: city,
        occupations: [values.occupation],
        roleIds: ["5ecf5b1e5a7f2b1aa47e67ec"],
        checkedTerms: values.checkedTerms === "",
        "g-recaptcha-response": undefined,
        occupation: undefined,
        preferredLanguageCode:
          values.preferredLanguageCode === ""
            ? undefined
            : values.preferredLanguageCode,
      };
      await initiateSignUp(updatedValues);
    }
  };

  const resetForm = (form: any) => {
    try {
      form.reset();
      (window["grecaptcha" as any] as any).reset();
    } catch (error) {}
  };

  const initiateSignUp = async (formData: {
    [k: string]: FormDataEntryValue;
  }) => {
    setLoading(true);
    const { response, status } = await signUp(formData);
    setLoading(false);
    if (status === 200) {
      setSuccess(true);
      resetForm(formRef.current);
    } else if (status > 200 && status < 500) {
      if (status === 400) {
        setMessage(t("common:somethingWentWrong"));
      } else if (response[0].code !== undefined) {
        if (response[0].code === "KB409001") {
          setMessage(t("common:register:errorMessage:profileVerification"));
        } else if (response[0].code === "KB409002") {
          setMessage(t("common:register:errorMessage:alreadyRegistered"));
        } else if (response[0].code === "KB400001") {
          setMessage(t("common:register:errorMessage:correctInvitationCode"));
        }
      } else {
        setMessage(response[0].message);
      }
    } else if (status === 505) {
      // this.props.apiTimeout(true);
    } else {
      setMessage(t("common:somethingWentWrong"));
    }
  };

  const validation = (formData: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    address: string;
    country: string;
    occupation: string;
    phone: string;
    checkedTerms: boolean;
    "g-recaptcha-response": string;
  }) => {
    const errors = {} as any;
    setMessage(null);
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(formData.email.trim());
    var digits = /^(\+){0,1}[0-9]{8,15}$/;
    if (formData.firstName === "") {
      setMessage(t("common:register:errorMessage:onfirstName"));
    } else if (formData.lastName === "") {
      setMessage(t("common:register:errorMessage:onlastName"));
    } else if (email !== true) {
      errors.email = true;
      setMessage(t("common:register:errorMessage:onEmail"));
    } else if (formData.address === "" || formData.company === "") {
      setMessage(t("common:allValidations:emptyField"));
    } else if (formData.occupation === undefined) {
      setMessage(t("common:register:errorMessage:occupationNotSelect"));
    } else if (formData.phone.length < 10) {
      errors.phone = true;
      setMessage(t("common:register:errorMessage:phoneNumber"));
    } else if (!formData.phone.match(digits)) {
      errors.phone = true;
      setMessage(t("common:register:errorMessage:onMobileNumber"));
    } else if (formData.country === "") {
      errors.country = true;
      setMessage(t("common:allValidations:emptyField"));
    } else if (formData.checkedTerms === undefined) {
      setMessage(t("common:register:errorMessage:onCheckTerms"));
    } else if (!formData["g-recaptcha-response"]) {
      setMessage(t("trans:pleaseVerifyCaptchs"));
    }
    setErrorState(errors);
    return errors;
  };

  const handleTermsCheckbox = () => {
    setCheckedTerms(!checkedTerms);
  };
  const handleNotARobot = () => {
    setNotARobot(!notARobot);
  };

  const handleCountryChange = (e: { target: { value: any } }) =>
    setAddressState({ ...addressState, country: e.target.value });
  const handleStateChange = (e: { target: { value: any } }) =>
    setAddressState({ ...addressState, state: e.target.value });
  const handleCityChange = (e: { target: { value: any } }) =>
    setAddressState({ ...addressState, city: e.target.value });
  const handlePreferredLanuageChange = (e: { target: { value: any } }) =>
    setAddressState({ ...addressState, preferredLanguageCode: e.target.value });

  const handleLogin = () => {
    close();
    toggleLoginPopup();
  };
  return (
    <StyledDialogContainer container>
      <Button onClick={close} className={classes.cross} type="" noMargin>
        <Cross />
      </Button>
      {success ? (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.successContainer}
        >
          <Typography variant="h5" align="center">
            {t("common:register:registerModal")}
          </Typography>
        </Grid>
      ) : (
        <Grid container item>
          <StyledForm ref={formRef} autoComplete="off" noValidate>
            <Grid item sm={12} md={6}>
              <Typography variant="h3">
                <LandingPagePlayFairFont>
                  {t("common:newLandingPage:signUp:formHeading")}
                  <br />
                  {t("common:newLandingPage:signUp:formHeading2")}
                </LandingPagePlayFairFont>
              </Typography>
            </Grid>
            <p></p>
            <Typography paragraph>
              {t("common:register:alreadyAccount")}
              <StyledLinkBlue onClick={handleLogin}>
                {t("common:register:login")}
              </StyledLinkBlue>
            </Typography>
            <Grid item container spacing={4} className={classes.extraPadding}>
              {formFields.map((field) => (
                <Grid key={field.label} item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      InputLabelProps={{
                        classes,
                      }}
                      error={errorStates[field.name]}
                      required={field.required}
                      variant="outlined"
                      label={t(field.label)}
                      type="text"
                      name={field.name}
                    />
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <OccupationRadioButtons isMobile={isMobile} />
            <Grid item container spacing={4}>
              <Grid item xs={12} sm={6} className={classes.noPaddingV}>
                <FormControl fullWidth className={classes.margin}>
                  <TextField
                    InputLabelProps={{
                      classes,
                    }}
                    error={errorStates[addressField.name]}
                    required
                    variant="outlined"
                    label={t(addressField.label)}
                    type="text"
                    name={addressField.name}
                  />
                </FormControl>
              </Grid>
              {getSelectField(
                countryField,
                classes,
                t,
                addressState["country"],
                handleCountryChange,
                countriesList,
                true,
                errorStates["country"]
              )}
              {getSelectField(
                stateField,
                classes,
                t,
                addressState["state"],
                handleStateChange,
                states,
                false,
                errorStates["state"]
              )}
              {getSelectField(
                cityField,
                classes,
                t,
                addressState["city"],
                handleCityChange,
                cities,
                false,
                errorStates["city"]
              )}
              <Grid item xs={12} sm={6} className={classes.noPaddingV}>
                <FormControl fullWidth className={classes.margin}>
                  <TextField
                    InputLabelProps={{
                      classes,
                    }}
                    error={errorStates[phoneField.name]}
                    required
                    variant="outlined"
                    label={t(phoneField.label)}
                    type="text"
                    name={phoneField.name}
                  />
                </FormControl>
              </Grid>
              {getSelectField(
                preferredLanuage,
                classes,
                t,
                addressState["preferredLanguageCode"],
                handlePreferredLanuageChange,
                languages,
                false,
                errorStates["preferredLanguageCode"]
              )}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.container}>
              <StyledMessage>{message}</StyledMessage>
              <p>
                <Checkbox
                  name="checkedTerms"
                  id="checkedTerms"
                  onClick={handleTermsCheckbox}
                  required
                />
                <span>{t("common:register:iAgree")} </span>
                <StyledLinkBlue href="/termsandconditions" target="_blank">
                  {t("common:register:termsandCondition")}
                </StyledLinkBlue>
              </p>
            </Grid>
            <Grid item xs={12} sm={7}>
              <ReCaptcha setIsVerfied={handleNotARobot} />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent={isMobile ? "center" : "flex-end"}
              className={isMobile ? classes.margin : ""}
            >
              <Button
                disabled={loading}
                onClick={handleFormSubmit}
                type="contained-red"
              >
                {t("common:register:buttonText")}
              </Button>
            </Grid>
          </StyledForm>
        </Grid>
      )}
    </StyledDialogContainer>
  );
};

export default SignUpFormDialog;
