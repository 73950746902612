import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api, { getRequestForDownload } from "../../../services/ApiService";

import {
  invoiceUrl,
  userInvoiceDownload,
  downloadandChangeShippingAddressInvoiceUrl,
} from "../../../common/config/app.endpoints";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import {
  InvoiceState,
  InvoiceListRequest,
  IDownloadInvoiceByIdRequest,
  IUpdateShippingAddressRequest,
} from "./invoiceSliceInterface";

const initialState: InvoiceState = {
  loading: false,
  error: "",
  filters: "",
  invoiceList: [],
  totalElements: 0,
};

export const getInvoiceList = createAsyncThunk<
  InvoiceListRequest,
  Partial<InvoiceListRequest>,
  { rejectValue: any }
>("invoice/getInvoiceList", async (requestObj, { rejectWithValue }) => {
  try {
    const response: any = await api.post(invoiceUrl, requestObj);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const downloadInvoice = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("invoice/downloadInvoice", async (data, { rejectWithValue }) => {
  try {
    const response = await getRequestForDownload(userInvoiceDownload);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const downloadInvoiceById = createAsyncThunk<
  IDownloadInvoiceByIdRequest,
  Partial<IDownloadInvoiceByIdRequest>,
  { rejectValue: any }
>("invoice/downloadInvoiceById", async (data, { rejectWithValue }) => {
  try {
    const response = await getRequestForDownload(
      `${downloadandChangeShippingAddressInvoiceUrl}/${data?.id}/download_invoice`
    );
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const updateShippingAddress = createAsyncThunk<
  IUpdateShippingAddressRequest,
  Partial<IUpdateShippingAddressRequest>,
  { rejectValue: any }
>("invoice/updateShippingAddress", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(
      `${downloadandChangeShippingAddressInvoiceUrl}/${data?.auctionId}/shippingAddress`,
      data
    );
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Get invoice list
    builder.addCase(getInvoiceList.pending, genericPendingCaseHandler);
    builder.addCase(
      getInvoiceList.fulfilled,
      (state: any, action: PayloadAction<any>) => {
        state.loading = false;
        state.invoiceList = action.payload.results;
        state.totalElements = action.payload.totalElements;
      }
    );
    builder.addCase(getInvoiceList.rejected, genericRejectedCaseHandler);
    // Download invoice
    builder.addCase(downloadInvoice.pending, genericPendingCaseHandler);
    builder.addCase(downloadInvoice.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(downloadInvoice.rejected, genericRejectedCaseHandler);
    // Download invoice by id
    builder.addCase(downloadInvoiceById.pending, genericPendingCaseHandler);
    builder.addCase(
      downloadInvoiceById.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(downloadInvoiceById.rejected, genericRejectedCaseHandler);
    // Update Shipping Address
    builder.addCase(updateShippingAddress.pending, genericPendingCaseHandler);
    builder.addCase(
      updateShippingAddress.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(updateShippingAddress.rejected, genericRejectedCaseHandler);
  },
});

export default invoiceSlice.reducer;

// selectors
export const selectInvoiceLoading = (state: RootState) => state.invoice.loading;
export const selectInvoiceList = (state: RootState) =>
  state.invoice.invoiceList;
export const selectTotalElements = (state: RootState) =>
  state.invoice.totalElements;
