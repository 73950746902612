import React, { ChangeEvent, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { changeDefaultPassword } from "../../../common/config/app.endpoints";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import {
  StyledTitleText,
  StyledTextInput,
  StyledButton,
  StyledComponent,
} from "../../../common/materials/GenericStyleComponent";
import { dateBackgroundGradient } from "../../../common/materials/LinearGradient";
import AuthService from "../../../components/auth/AuthService";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faEyeProps = faEye as IconProp;
const eye = <FontAwesomeIcon icon={faEyeProps} />;

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 1em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 450px;
  }
  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
`;

const StyledTitle = styled.h1`
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  text-align: center;
  margin: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.2em;
  }
`;

const StyledHeaderTitle = styled.h5`
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  font-size: 0.8em;
  margin-left: 20px;
`;

const StyledButtonContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 12px;
  margin: 10px 0px;
  margin-left: 1.2em;
`;

const StyledPasswordCon = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 14px;
  input {
    padding-right: 2em !important;
  }
  i {
    position: absolute;
    top: 31%;
    right: 3%;
    cursor: pointer;
  }
`;

interface IProps {
  onClose: () => void;
  subDomainPage: () => void;
  onShowPaddleNoModal: () => void;
}
let email: null | string = "";

const NewPassword = (props: IProps) => {
  const { onClose, subDomainPage, onShowPaddleNoModal } = props;
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const Auth = new AuthService();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "newPassword") {
      setNewPassword(targeValue);
    }
    if (targeName === "confirmPassword") {
      setConfirmPassword(targeValue);
    }
  };

  const togglePasswordVisiblity = () => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const updateNewPassword = (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    let newPasswordVariable = newPassword.trim();
    let confirmPasswordVariable = confirmPassword.trim();
    email = localStorage.getItem("email");
    if (newPasswordVariable === "" || confirmPasswordVariable === "") {
      setIsLoading(false);
      setMessage(
        `${t("common:profile:forgotPassword:validation:enterPassword")}`
      );
    } else if (newPasswordVariable !== confirmPasswordVariable) {
      setIsLoading(false);
      setMessage(
        `${t("common:profile:forgotPassword:validation:enterPassword")}`
      );
    } else {
      Auth.postRequest(
        `${changeDefaultPassword}?newPassword=${newPasswordVariable}`,
        "data",
        (status: any) => {
          if (status === 200) {
            setIsLoading(false);
            onClose();
            let userProfileType: any = localStorage.getItem("userType");
            if (userProfileType === "SUB_DOMAIN") {
              subDomainPage();
            } else {
              // for paddle number modal
              onShowPaddleNoModal();
            }
            localStorage.setItem("defaultPasswordUpdated", "true");
          } else if (status === 505) {
            // console.log("succuss");
          } else if (status > 200 && status < 500) {
            // console.log("succuss");
          }
        }
      );
    }
  };


  return (
    <StyledComponent tabIndex={-1}>
      <form onSubmit={updateNewPassword} noValidate={true}>
        <StyledContainerWrapper>
          <Grid item={true} xs={12} md={12}>
            <StyledTitle className="fontBold">
              <StyledTitleText>
                {t("common:button:savePassword")}
              </StyledTitleText>
            </StyledTitle>
          </Grid>
          <Grid item={true} xs={12}>
            <StyledHeaderTitle>
              {t("common:button:savePasswordText")}
            </StyledHeaderTitle>
          </Grid>
          <Grid item={true} xs={12}>
            <StyledMessage>{message}</StyledMessage>
          </Grid>
          <StyledTextInput>
            <StyledPasswordCon className="pass-wrapper">
              <TextField
                fullWidth
                required
                autoFocus={true}
                id="newPassword"
                label={t("common:profile:forgotPassword:labels:newPassword")}
                variant="outlined"
                name="newPassword"
                onChange={handleChange}
                type={showPassword ? "text" : "password"}
              />
              <i onClick={togglePasswordVisiblity}>{eye}</i>
            </StyledPasswordCon>
          </StyledTextInput>
          <StyledTextInput>
            <TextField
              fullWidth
              required
              id="confirmPassword"
              label={t("common:profile:forgotPassword:labels:confirmPassword")}
              variant="outlined"
              type="password"
              name="confirmPassword"
              onChange={handleChange}
            />
          </StyledTextInput>
          <StyledButtonContainer>
            {isLoading === false ? (
              <StyledButton onSubmit={updateNewPassword}>
                {t("common:button:savePassword")}
              </StyledButton>
            ) : (
              <StyledButton className="onClickButton">
                {t("common:button:savePassword")}
              </StyledButton>
            )}
          </StyledButtonContainer>
        </StyledContainerWrapper>
      </form>
      {isLoading === true && (
        <div className="loading">
          <LoadingSpinner />
        </div>
      )}
    </StyledComponent>
  );
};

export default NewPassword;
