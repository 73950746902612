import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { buttonGradient } from "../../../../common/materials/LinearGradient";
import { Box } from "@mui/material";
const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
  padding: 50px 70px;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    padding: 30px 8px 30px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 8px;
  }
`;

const StyledActionContainer = styled(Grid)``;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;
const StyledBackBtnContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  .spanText {
    font-size: 1em;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;
const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 5px;
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 600px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 5px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 10px;
  }
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

const StyledArrowIconsSubItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
  justify-content: end;
  align-items: center;
  display: flex;
`;

const StyledLabel = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  padding-left: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  // margin-top: 0.5em;
  text-align: center;
`;

const StyledArrowWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.1em;
  padding: 0.5em;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
const StyledButtonContainer = styled.div`
  text-align: center;
  margin-top: -50px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-top: -26px;
  }
`;

const StyledTooltipContainer = styled(Grid)``;

const StyledTooltipText = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 12px;
`;

const StyledBidContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.darkGrey};
`;
const StyledMyBidsButton = styled(Grid)`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;
const StyledMyBidButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.4em;
  border-radius: 5px;
  width: 80%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
const StyledMyBidButtons = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.Black};
  border: none;
  font-size: 1em;
  padding: 0.4em;
  border-radius: 5px;
  width: 80%;
  background: ${(props) => props.theme.colors.base.lightgrey};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;


const StyledPrebidingContainer = styled(Box)`
  color: #2660ad;
  border: 4px solid #2660ad;
  background: #bcd2e8;
  padding: 10px;
  margin: 10px;
  font-size: 1.2em;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 1em;
  }
`;
export {
  StyledMyBidButtons,
  StyledMyBidButton,
  StyledBidContainer,
  StyledMyBidsButton,
  StyledTooltipText,
  StyledTooltipContainer,
  StyledButtonContainer,
  StyledButton,
  StyledWrapperID,
  StyledArrowWrapper,
  StyledWrapper,
  StyledLabel,
  StyledName,
  StyledArrowIconsSubItem,
  StyledArrowIconsID,
  StyledArrowIcons,
  StyledContentWrapper,
  StyledGridWrapper,
  StyledItemDesc,
  StyledProductCarousalColumn,
  StyledProductName,
  StyledBrandName,
  StyledBackBtn,
  StyledBackBtnContainer,
  StyledTitleContainer,
  StyledActionContainer,
  StyledLayoutGrid,
  StyledPrebidingContainer,
};