import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { buttonGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

export const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.grey};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    background:${(props) => props.theme.colors.base.transparent};
    border-bottom: 5px solid ${(props) => props.theme.colors.base.grey};
`;

const StyledBtnContainer = styled(Grid)`
    .MuiGrid-container {
        display:none;
    }
    .MuiAppBar-colorPrimary {
        background: #ffffff;
        color:#000000
    }
    .MuiPaper-elevation4 {
        box-shadow : none
    }
    .MuiTabs-root {
        display:block;
    }
    .MuiTabs-fixed {
        width:auto;
    }
    .MuiTabs-indicator {
        background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
        height:3px;
    }
    // .PrivateTabIndicator-colorSecondary-21 {
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
    // .PrivateTabIndicator-root-17{
    //     height:3px;
    // }
    // .PrivateTabIndicator-colorSecondary-19 {
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
    // .PrivateTabIndicator-colorSecondary-34 {
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
    // .PrivateTabIndicator-colorSecondary-24{
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
    // .PrivateTabIndicator-colorSecondary-29{
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
    // button .span {
    //     background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
    // }
`;

const StyledBtnContent = styled(Grid)`
    text-align : center;
`;

const handleTabLayout = () => {

}

interface IProps extends IRouterComponentProps {
    t: any;
    tabClick: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const TabLayout = (props: IProps) => {
    const { t } = props;
    const [isBtnSelected, setIsBtnSelected] = useState(true);
    const classes = useStyles();
    useEffect(() => {
        let val: any = localStorage.getItem('tabSelectedVal');
        if (val !== undefined || val !== null){
            setValue(val);
        }else{
            setValue("1");
            localStorage.setItem("tabSelectedVal", "1");
        }
    },[])
    const [value, setValue] = React.useState('1');

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setValue(newValue);
        props.tabClick(newValue);
    };

    return (
        <StyledBtnContainer container={true} spacing={0}>
            <StyledBtnContent item={true} xs={12}>
                <div className={classes.root}>
                    <TabContext value={value}>
                        <AppBar position="static">
                            <TabList onChange={handleChange} aria-label="simple tabs example">
                                <Tab label={t('common:preview:tabLayout:diamond')} value="1" />
                                <Tab label={t('common:preview:tabLayout:parcelItem')} value="2" />
                            </TabList>
                        </AppBar>
                    </TabContext>
                </div>
            </StyledBtnContent>
        </StyledBtnContainer>

    )
}

export default TabLayout;