import { combineReducers } from "@reduxjs/toolkit";
import localForage from "localforage";
import { persistReducer } from "redux-persist";

// Reducers
import authReducer from "./slice/auth/authSlice";
import advertisementReducer from "./slice/advertisement/adsSlice";
import auctionReducer from "./slice/auction/auctionSlice";
import categoryReducer from "./slice/category/categorySlice";
import commonReducer from "./slice/common/commonSlice";
import countryReducer from "./slice/country/countrySlice";
import currencyReducer from "./slice/currency/currencySlice";
import filtersReducer from "./slice/filters/filtersSlice";
import invoiceReducer from "./slice/invoice/invoiceSlice";
import notificationReducer from "./slice/notification/notificationSlice";
import paymentReducer from "./slice/payment/paymentSlice";
import productReducer from "./slice/product/productSlice";
import tendorReducer from "./slice/tendor/tendorSlice";
import userReducer from "./slice/user/userSlice";
import supportReducer from "./slice/user/userSlice";
import pendingProductReducer from "./slice/pendingProductteppo/pendingProductsSlice";
import paginationReducer from "./slice/commanPagination/commanPaginationSlice";
import commonReactReducer from "./slice/commonReact/commonReactSlice";
import annualInvoiceReducer from "./slice/annual/commanAnnualSlice";
import notificationLiveReducer from "./slice/notificationLive/notificationLiveSlice";
import commonProductDetailsReducer from "./slice/commonProduct/commonProductDetailsSlice";

const persistConfig = {
  key: "root",
  storage: localForage,
};

const combinedReducer = combineReducers({
  auth: authReducer,
  advertisement: advertisementReducer,
  auction: auctionReducer,
  category: categoryReducer,
  common: commonReducer,
  country: countryReducer,
  currency: currencyReducer,
  filters: filtersReducer,
  invoice: invoiceReducer,
  notification: notificationReducer,
  payment: paymentReducer,
  product: productReducer,
  tendor: tendorReducer,
  user: userReducer,
  pendingproduct: pendingProductReducer,
  support: supportReducer,
  paginationSlice: paginationReducer,
  commonReactSlice: commonReactReducer,
  commanAnnualSlice: annualInvoiceReducer,
  notificationLiveSlice: notificationLiveReducer,
  commonProductDetailsSlice: commonProductDetailsReducer
});

const reducer = (state: any, action: any) => {
  if (action.type === "auth/resetReduxState") {
    state.auth = undefined;
    state.advertisement = undefined;
    state.auction = undefined;
    state.category = undefined;
    //state.common = undefined; // NOTE: Not resetting the commonSlice due to default language code.
    state.invoice = undefined;
    state.notification = undefined;
    state.payment = undefined;
    state.product = undefined;
    state.tendor = undefined;
    state.user = undefined;
    state.support = undefined;
    state.pendingproduct = undefined;
    state.paginationSlice = undefined;
    state.commonReactSlice = undefined;
    state.commanAnnualSlice = undefined;
    state.notificationLiveSlice = undefined;
    state.commonProductDetailsSlice = undefined;
  }
  return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof reducer>;
export default persistReducer(persistConfig, reducer);
