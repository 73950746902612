import React, { useEffect, useState } from 'react';
import Grid from "@material-ui/core/Grid";

import AuthService from '../../../auth/AuthService';
import { currentTimeUrl, joinAuctionUrl, participateUrl, previewUrl, sendInviteeParticipationRequest, autoCompleteSearchProduct } from '../../../../common/config/app.endpoints';
import ViewParticipateModal from '../ViewParticipateModal';
import Alert from '../../../../common/elements/Alert';
import LoadingSpinner from '../../../../common/elements/LoadingSpinner';
import IRouterComponentProps from '../../../../models/IRouterComponentProps';
import { oAuth } from '../../../../routes/basic/BasicAppRouter';
import moment from 'moment';
import { getDiamondCategoryFromUrl, getTimeDifference, joinAuction } from '../../../../common/GenericFunctions';
import BlockUserModal from '../../BlockUser/BlockUserModal';
import { StyledButton, StyledGrid, StyledModal, StyledParticipateWrapper, StyledTextWrapper, StyledWrapper } from './HomeAuctionsStyles';
import { useTranslation } from 'react-i18next';
import CoreDataGridOfHome from '../../../core-grid/grid-data/CoreDataGridOfHome';
import AuctionListViewHome from '../AuctionListViewHome';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { reSetLiveAuctionPagination, reSetLiveCurrentApiStartCount } from '../../../../redux/slice/commanPagination/commanPaginationSlice';

export interface IProps extends IRouterComponentProps {
    t?: any;
    apiTimeout: (timeout: boolean) => void;
    searchOptionValue: string;
}

var auctionDate: string | string[] = '';
var auctionId: number | string = '';
let timezone: any = '';
var isCallJoinApi:boolean = false;
let shortBreakTimer: any = '';

const HomePreviewOfallAuctions = (props: IProps) => {
  const [products, setProducts] = useState([]);
  const [openToast, setOpenToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [apiTimeout, setApiTimeout] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openParticipateModal, setOpenParticipateModal] = useState(false);
  const [invitationCode, setInvitationCode] = useState("");
  const [openTimerModal, setopenTimerModal] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const [isShowTendor, setIsShowTendor] = useState("");

  const { t } = useTranslation();
  const Auth = new AuthService();
  let auctionName: any= "";
  const dispatch: AppDispatch = useDispatch();
  
  useEffect(() => {
    setIsLoading(true);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      let val = localStorage.getItem("optionValue");
      if (val !== "" && val !== "null" && val != null) {
        setIsLoading(true);
        localStorage.setItem("optionValue", "");
        getPreviewList();
        joinAuction();
        getTimeDifference();
      } else {
        joinAuction();
        getTimeDifference();
        getPreviewList();
      }
    }

    PubSub.subscribe("optionValue", (msg: any, data: any) => {
      if (data !== "" && data != null) {
        getAutocompleteData(data);
      } else {
        joinAuction();
        getTimeDifference();
        getPreviewList();
      }
    });

    const { isAvailable } = getDiamondCategoryFromUrl();

    if (isAvailable) {
      onClickParticipate();
    }
    return () => {
      // Anything in here is fired on component unmount.
      PubSub.unsubscribe("optionValue");
    };
  }, []);

  const getAutocompleteData = (value: any) => {
    if (value !== "") {
      const data = {
        filters: {
          or: [
            {
              match: "anywhere",
              name: "remarks",
              value: value,
            },

            {
              match: "anywhere",
              name: "productDescription",
              value: value,
            },
          ],
          and: [
            {
              match: "exact",
              name: "parcelType",
              value: "Diamond",
            },
          ],
        },
        page: {
          start: 0,
          count: 20,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };

      Auth.postRequest(
        autoCompleteSearchProduct,
        data,
        (status: any, response: any) => {
          if (status === 200) {
            setProducts(response);
            // //setMessage("");
            setIsLoading(false);
            if (response.length > 0) {
              if (!oAuth()) {
                props.history.push("/");
              } else {
                getTime();
              }
            } else {
              setIsLoading(false);
            }
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            // this.props.apiTimeout(true)
            setApiTimeout(true);
          } else {
            setAlertType("error");
            setOpenToast(true);
            setShowAlertMessage(response);
            setIsLoading(false);
          }
        }
      );
    }
  };

  const getPreviewList = () => {
    Auth.getRequest(`${previewUrl}`, (status: any, response: any) => {
      if (status === 200) {
        if (response.length > 0) {
          setProducts(response);
          //setMessage("");
          setIsLoading(false);
          if (!oAuth()) {
            props.history.push("/");
          } else {
            getTime();
          }
        } else {
          setIsLoading(false);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        // this.props.apiTimeout(true)
        setApiTimeout(true);
      } else {
        setAlertType("error");
        setOpenToast(true);
        setShowAlertMessage(response);
        setIsLoading(false);
      }
    });
  };

  const getTime = () => {
    Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          var timePassedInMilliseconds = (Date.now() - response) / 1000;
          if (timePassedInMilliseconds < -3 || timePassedInMilliseconds > 3) {
            setopenTimerModal(true);
          }
        }
      }
    });
  };

  const showListOfProduct = (
    productId: number | string,
    name: string,
    auctionStartDate: string,
    state: string,
    auctionType: string
  ) => {

    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());

    setIsShowTendor(auctionType);

    localStorage.setItem("auctionState", state);
    let previewTime = moment.utc(auctionStartDate);
    let hour: any = "";
    let time: any = "";
    let minutes: any = "";
    if (timezone !== null) {
      timezone = localStorage.getItem("timezoneName");
    }
    let result = moment.tz(previewTime, timezone);
    let resultFormatter = result.format();
    if (resultFormatter !== "Invalid date") {
      let splitDateTime = resultFormatter.split("T", 2);
      let splitTime: any = "";
      if (splitDateTime[1].includes("+")) {
        splitTime = splitDateTime[1].split("+", 2);
      } else {
        splitTime = splitDateTime[1].split("-", 2);
      }
      auctionDate = splitDateTime[0];
      time = splitTime[0];
      let dateTime = `${auctionDate} ${time}`;
      let start: any = moment(dateTime);
      timezone = localStorage.getItem("timezoneName");
      let end: any = moment(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      hour = start.diff(end, "hours");
      minutes = start.diff(end, "minutes");
    }
    //setMessage("");
    auctionName = name;
    auctionId = productId;
    var joinedAuctions: any = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      if (joinedAuctions.length > 0) {
        joinedAuctions.filter((items: any) => {
          if (items.perId === productId) {
            isCallJoinApi = false;
            localStorage.removeItem("filteredValues");
            localStorage.setItem("filteredValues", "null");
            localStorage.removeItem("filteredParcelValues");
            localStorage.setItem("filteredParcelValues", "null");
            if (items.category !== undefined) {
              if (items.category === "Jewellery") {
                if (auctionType !== undefined && auctionType !== "Live") {
                  props.history.push({
                    pathname: `/preview/tendor/jewellery/products/${auctionId}/${items.auctionUserId}/${auctionName}/${auctionDate}`,
                  });
                } else {
                  props.history.push({
                    pathname: `/preview/jewelleyProducts/${auctionId}/${auctionName}/${auctionDate}`,
                  });
                }
              } else {
                if (auctionType !== undefined && auctionType !== "Live") {
                  props.history.push({
                    pathname: `/preview/tendor/products/${auctionId}/${items.auctionUserId}/${auctionName}/${auctionDate}`,
                  });
                } else {
                  props.history.push({
                    pathname: `/preview/products/${auctionId}/${auctionName}/${auctionDate}`,
                  });
                }
              }
            }
          } else {
            if (state === "NotStarted" && hour <= 0 && minutes < 0) {
            } else {
              isCallJoinApi = true;
            }
          }
        });
      } else {
        isCallJoinApi = true;
      }
    } else {
      isCallJoinApi = true;
    }

    if (isCallJoinApi === true) {
      onClickJoin(auctionType);
    }
  };

  const onClickJoin = (auctionType: string) => {
    // const { t } = this.props;
    setOnSubmit(true);
    setOpenToast(false);
    const data = {
      code: "",
    };

    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());

    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.postRequest(
        `${joinAuctionUrl}/${auctionId}/join`,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            var auctionUserId = response.id;
            shortBreakTimer = response.resumeDateTime;
            setOpenProductModal(false);
            setOnSubmit(false);
            setOpenToast(false);
            joinAuction();
            localStorage.removeItem("filteredValues");
            localStorage.setItem("filteredValues", "null");
            localStorage.removeItem("filteredParcelValues");
            localStorage.setItem("filteredParcelValues", "null");
              if (response.category !== undefined) {
                if (response.category === "Jewellery") {
                  if (auctionType !== undefined && auctionType !== "Live") {
                    props.history.push({
                      pathname: `/preview/tendor/jewellery/products/${auctionId}/${auctionUserId}/${auctionName}/${auctionDate}`,
                    });
                  } else {
                    props.history.push({
                      pathname: `/preview/jewelleyProducts/${auctionId}/${auctionName}/${auctionDate}`,
                    });
                  }
                } else {
                  if (auctionType !== undefined && auctionType !== "Live") {
                    props.history.push({
                      pathname: `/preview/tendor/products/${auctionId}/${auctionUserId}/${auctionName}/${auctionDate}`,
                    });
                  } else {
                    props.history.push({
                      pathname: `/preview/products/${auctionId}/${auctionName}/${auctionDate}`,
                    });
                  }
                }
              }
          } else if (status > 200 && status < 500) {
            if (status === 403) {
              setUserBlock(true);
            }

            if (response[0].code !== undefined) {
              if (response[0].code === "KB400003") {
                setOnSubmit(false);
                setAlertType("error");
                setOpenToast(false);
                setShowAlertMessage(`Participation Mail Sent Already`);
                setOpenProductModal(true);
              } else if (response[0].code === "KB409004") {
                setAlertType("error");
                setOpenToast(true);
                setShowAlertMessage(`Invitaion Mail Already Sent`);
                setOpenProductModal(false);
              }
            } else {
              //setMessage(`${t("common:allValidations:join:mailSentFailed")}`);
              setInvitationCode("");
              setOnSubmit(false);
              setOpenProductModal(true);
            }
          } else if (status === 500) {
            setAlertType("error");
            setOpenToast(true);
            setShowAlertMessage(response[0].message);
            setIsLoading(false);
            setOpenProductModal(false);
            setOnSubmit(false);
          } else {
            setAlertType("error");
            setOpenToast(true);
            setShowAlertMessage(`${t("common:somethingWentWrong")}`);
            setIsLoading(false);
            setOpenProductModal(false);
            setOnSubmit(false);
          }
        }
      );
    }
  };

  const onCloseModal = () => {
    //setMessage(`${t("common:allValidations:join:mailSentFailed")}`);
    setOpenParticipateModal(false);
    setOpenProductModal(false);
  };

  const onClickParticipate = () => {
    // const { t } = this.props;
    setOnSubmit(true);
    dispatch(reSetLiveAuctionPagination());
    dispatch(reSetLiveCurrentApiStartCount());
    setOpenToast(false);
    if (!oAuth()) {
      props.history.push("/");
    } else {
      var urlString = "";

      let userType = localStorage.getItem("userType");
      const { isAvailable, auctionIdFromUrl } = getDiamondCategoryFromUrl();

      if (isAvailable) {
        auctionId = `${auctionIdFromUrl}`;
      }
      urlString =
        userType === "SUBSCRIBED" || "SUB_DOMAIN"
          ? participateUrl
          : sendInviteeParticipationRequest;

      Auth.postRequest(
        `${urlString}?auctionId=${auctionId}`,
        "data",
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              setOpenParticipateModal(true);
              setOpenProductModal(false);
              setOnSubmit(false);
              setOpenToast(false);
            }
          } else if (status > 200 && status < 500) {
            if (status === 403) {
              setUserBlock(true);
            }
            if (response[0].code !== undefined) {
              if (response[0].code === "KB409003") {
                // setMessage(
                //   `${t(
                //     "common:allValidations:participate:alreadySentParticipationMail"
                //   )}`
                // );
                setOnSubmit(false);
                setOpenProductModal(false);
                setAlertType("error");
                setShowAlertMessage(`Participation Mail Sent Already.`);
                setOpenToast(true);
              } else if (response[0].code === "KB409004") {
                //setMessage(
                //   `${t(
                //     "common:allValidations:participate:alreadySentParticipationMail"
                //   )}`
                // );
                setOnSubmit(false);
                setOpenProductModal(false);
                setAlertType("error");
                setShowAlertMessage(`Participation Mail Sent Already.`);
                setOpenToast(true);
                setOpenParticipateModal(false);
              }
            } else if (status === 404) {
              // setMessage(`${t("common:invalidRequest")}`);
              setInvitationCode("");
              setOnSubmit(false);
            } else {
              setOnSubmit(false);
            }
          } else {
            setOpenParticipateModal(false);
            //setMessage(response[0].message);
            setOnSubmit(false);
          }
        }
      );
    }
  };

  const openParticipate = () => {
    setOpenParticipateModal(false);
  };



  const valueFuc = () => {
    getPreviewList();
  };

  const blockCloseModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  return (
    <StyledGrid container={true} spacing={0}>
      {loading === true ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <Grid item xs={12}>
          {products.length > 0 ? (
            <CoreDataGridOfHome t={t} data={products}>
              <AuctionListViewHome
                t={t}
                showListOfProduct={showListOfProduct}
                value={valueFuc}
              />
            </CoreDataGridOfHome>
          ) : (
            <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
              {t("common:preview:noAuctionFound")}
            </StyledTextWrapper>
          )}
        </Grid>
      )}
      {openProductModal === true && (
        <StyledModal open={openProductModal} onClose={onCloseModal}>
          <StyledWrapper container spacing={0}>
            <StyledParticipateWrapper item md={12} xs={12} lg={12}>
              <StyledTextWrapper>
                {t("common:preview:modal:participateDontHaveCode")}
                {t("common:preview:modal:forparticipation")}
              </StyledTextWrapper>
              {onSubmit === false ? (
                <StyledButton
                  participate={true}
                  onClick={() => onClickParticipate()}
                >
                  {t("common:button:participate")}
                </StyledButton>
              ) : (
                <Grid>
                  <StyledButton disableParticipateButton={onSubmit}>
                    {t("common:button:participate")}
                  </StyledButton>
                </Grid>
              )}
            </StyledParticipateWrapper>
          </StyledWrapper>
        </StyledModal>
      )}
      {openParticipateModal === true && (
        <StyledModal open={openParticipateModal} onClose={onCloseModal}>
          <ViewParticipateModal
            openParticipateModal={() => openParticipate()}
            t={t}
          />
        </StyledModal>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal t={t} {...props} closeModal={blockCloseModal} />
        </StyledModal>
      )}
    </StyledGrid>
  );
};

export default HomePreviewOfallAuctions;