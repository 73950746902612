import {
  createTheme,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import IRouterComponentProps from "../models/IRouterComponentProps";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Box, Button, Backdrop, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import styled from "styled-components";

interface IProps extends IRouterComponentProps {
  t?: any;
}

const StyledContainer = styled(Box)`
    position: fixed;
    padding: 10px;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      marginTop: 5,
      marginBottom: 5,
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const SubDomain = (props: IProps) => {
  const theme = createTheme({});
  const { t } = useTranslation();
  const [isCreateForm, setIsCreateForm] = React.useState(false);
  const [loader, setLoader] = useState(false);
    const [validationMessage, setValidationMessage] = useState("");
  let history = useHistory();
  const [createFromData, setCreateFromData] = useState({
    businessName: "",
    businessType: "",
  });

  const classes = useStyles();
  const [state, setState] = React.useState<{
    category: string | number;
    name: string;
  }>({
    category: "",
    name: "",
  });

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const name = event.target.value as keyof typeof state;
    setCreateFromData({
      ...createFromData,
      businessType: name,
    });
  };

  const createNowBtnHandler = () => {
    let createWebObj = {
      businessName: createFromData.businessName,
      businessType: createFromData.businessType,
    };
    localStorage.setItem("createWebDetails", JSON.stringify(createWebObj));
    setIsCreateForm(false);
    // setLoader(true);
    if (
      createFromData.businessName.trim() !== "" &&
      createFromData.businessType.trim() !== ""
    ) {
      setLoader(true);
      setTimeout(() => {
        setLoader(false);
        history.push("/webDemo");
      }, 6000);
    } else if (createFromData.businessName === "" && createFromData.businessType === "") {
      setValidationMessage("Please enter business Details.");
    } else if (createFromData.businessName === "") {
      setValidationMessage("Please Enter business name.");
    } else if (createFromData.businessType === "") {
      setValidationMessage("Please select business type.");
    }
  };

  const renderWebsiteLoader = () => {
    return (
      <>
        <Backdrop
          sx={{
            color: "black",
            background: "white",
            zIndex: 1,
          }}
          open={loader}
        >
          <video controls={false} width="100%" autoPlay>
            <source src="/videos/createweb.mp4" type="video/mp4" />
          </video>{" "}
        </Backdrop>
      </>
    );
  };

  return (
    <StyledContainer component="div">
      <span
        style={{
          color: "#2660AD",
          fontSize: "3.2em",
          fontWeight: 600,
          fontFamily: "auto",
        }}
      >
        Create a stunning website in 20 seconds with AI
      </span>
      <StyledMessage>{validationMessage}</StyledMessage>
      <div style={{ textAlign: "center", width: "60%" }}>
        <TextField
          id="businessName"
          variant="outlined"
          type="text"
          label="Name of your business"
          required
          onChange={(e) => {
            setCreateFromData({
              ...createFromData,
              businessName: e.target.value,
            });
          }}
          fullWidth
          style={{ width: "60%" }}
        />
        <FormControl
          variant="outlined"
          className={classes.formControl}
          fullWidth
          style={{ width: "60%" }}
          required
        >
          <InputLabel htmlFor="Category">Category</InputLabel>
          <Select
            native
            onChange={handleChange}
            label="Category"
            inputProps={{
              name: "Category",
              id: "Category",
            }}
          >
            <option aria-label="None" value="" />
            <option value={"Diamonds"}>Diamonds</option>
            <option value={"Jewllery"}>Jewellery</option>
          </Select>
        </FormControl>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          onClick={createNowBtnHandler}
          style={{ background: "2660AD" }}
        >
          Create Now
        </Button>
      </div>
      {loader && renderWebsiteLoader()}
    </StyledContainer>
  );
};

export default SubDomain;
