import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, useMediaQuery } from "@material-ui/core";
import {
  StyledActionBarContainer,
  StyledActionSecondBarContainer,
  StyledActionsContainer,
  StyledAmountHeading,
  StyledContainer,
  StyledDropDown,
  StyledExportButton,
  StyledMypageGrid,
  StyledReserveImg,
  StyledRoundButton,
} from "./MypageStyle";
import MypageTable from "./components/MypageTable";
import {
  StyledHeaderContainer,
  StyledCoreDataGridContainer,
  StyledSelectAllButtonHeader,
} from "../365Auction/component/DiamondProductStyle";
import SearchWithSuggestion from "./components/SearchWithSuggestion";
import {
  addOrRemoveWatchlist,
  exportProducts,
  myPageSoldPostRequest,
  refreshPage,
} from "./MypageApiService";
import CategoryDropDown from "./components/CategoryDropDown";
import RefreshIcon from "@material-ui/icons/Refresh";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import DoYouWishNegotiatePopup from "../pendingProductsTab/componets/DoYouWishNegotiatePopup";
import MypagePaginationSell from "./components/Details/MypagePaginationSell";
import {
  selectMySellpageItemPerPage,
  setMySellPageItemPerPage,
} from "../../redux/slice/commanPagination/commanPaginationSlice";
import { AppDispatch } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducer";
import Alert from "../../common/elements/Alert";
import MyPageProductsGrid from "./components/grid/MyPageProductsGrid";
import FilterPopup from "./components/FilterPopup";
import ReadOnlyUserModal from "../../common/elements/ReadOnlyUserModal";
import { useHistory } from "react-router";
import AddInventoryModal from "./components/AddInventoryModal";

let isReadOnlyUser: any;
let filteredValues: any = "";
let filter: any = [];

let joinedAuctions: any = [];
let jwljoinedAuctionUserId: string;
let joinedAuctionUserId: string;
const SellMypage = (props: any) => {
  let { t } = useTranslation();
  let [isLoading, setIsLoading] = useState<boolean>(false);
  let [isGridView, setIsGridView] = useState<boolean>(false);
  let [totalAmount, setTotalAmount] = useState<Number>(0);
  let [product, setProduct] = useState([]);
  let [sellProduct, setSellProduct] = useState([]);
  let [sellTotalElements, setSellTotalElements] = useState(0);
  let [selectedSellCategory, setselectedSellCategory] = useState("");
  let [page, setPage] = useState(0);
  let [exportIds, setexportIds] = useState<any[]>([]);
  let [row, setRow] = useState("");
  let [showNegotiateModal, setShowNegotiateModal] = useState<boolean>(false);
  const [readOnly, setReadOnly] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");
  let [filters, setFilters] = useState();
  let [sort, setSort] = useState();
  const [isPageChange, setIsPageChange] = useState(false);
  const [openInventoryModal, setOpenInventoryModal] = useState(false);
  const [isNrFilterSelected, setIsNrFilterSelected] = useState(false);

  const isMobile = useMediaQuery("(max-width: 850px)");
  const dispatch: AppDispatch = useDispatch();
  let history = useHistory();
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectMySellpageItemPerPage(state)
  );

  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  useEffect(() => {
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    if (isReadOnlyUser) {
      setReadOnly(true);
    }
    localStorage.removeItem("myPagefilteredValues");
    localStorage.removeItem("sellPageNrProductFilterd");
    localStorage.removeItem("mypageNrProductFilterd");
    //My-page Re-direction 
    let isSellerBtnClick = localStorage.getItem('sellerBtnClick');
    if (isSellerBtnClick !== null) {
      if (isSellerBtnClick === "true") {
        setOpenInventoryModal(true);
        localStorage.setItem("sellerBtnClick", "false");
      }
    }
    let isNrProductFilter = localStorage.getItem("sellPageNrProductFilterd");
    if (
      isNrProductFilter != undefined &&
      isNrProductFilter != null
    ) {
      setIsNrFilterSelected(true);
    }
    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null || joinedAuctions?.length !== undefined) {
      let diamsArr = joinedAuctions?.filter((items: any) => items.category === "Diamonds");
      let jwlArr = joinedAuctions?.filter((items: any) => items.category === "Jewellery");

      if (jwlArr !== undefined || diamsArr !== undefined) {
        joinedAuctionUserId = diamsArr[0]?.auctionUserId;
        jwljoinedAuctionUserId = jwlArr[0]?.auctionUserId;
      }
    }
    return () => {
      localStorage.removeItem("myPagefilteredValues");
      localStorage.removeItem("sellPageNrProductFilterd");
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setIsPageChange(!isPageChange);
    setPage(0);
    callApi(0);
  }, [selectItemPerPageValue, selectedSellCategory]);

  const callApi = (payload: any) => {
    setPage(payload);
    let filter = null; // Initialize filter here

    if (
      localStorage.getItem("myPagefilteredValues") !== null &&
      localStorage.getItem("myPagefilteredValues") !== undefined
    ) {
      filteredValues = localStorage.getItem("myPagefilteredValues");

      try {
        filteredValues = JSON.parse(filteredValues); // Correct JSON parsing
      } catch (error) {
        console.error("Error parsing JSON:", error);
        filteredValues = null; // Handle JSON parsing error
      }

      if (filteredValues !== null && filteredValues.length > 0) {
        // Use a for loop to avoid modifying the array while iterating
        for (let index = filteredValues.length - 1; index >= 0; index--) {
          const item = filteredValues[index];
          if (item.name === "auctionId") {
            filteredValues.splice(index, 1);
          }
        }
        filter = filteredValues;
      }
      getSellProductList(payload, filter);
    } else {
      getSellProductList(payload);
    }
  };

  const getSellProductList = async (pagenum: any, searching?: any) => {
    let pageCount = pagenum * selectItemPerPageValue;
    const { response, status } = await myPageSoldPostRequest(
      pageCount,
      selectItemPerPageValue,
      selectedSellCategory,
      filters,
      sort,
      searching
    );
    if (status === 200) {
      setSellProduct(response.results);
      if (response.additionalData.totalAmount !== undefined) {
        let total: any = new Intl.NumberFormat("us-en").format(
          Math.round(response.additionalData.totalAmount)
        );
        setTotalAmount(total);
      } else {
        setTotalAmount(0);
      }
      setSellTotalElements(response.totalElements);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handlePrebid = (id: string, data: any, isPrebid: boolean) => {
    return true;
  };

  const handleWatchlist = async (
    auctionId: string | number,
    productId: string | number,
    isWatch: boolean
  ) => {
    let url = isWatch
      ? `${auctionId}/${productId}/watch`
      : `${auctionId}/${productId}/unwatch`;
    const { status } = await addOrRemoveWatchlist(url);
    if (status === 200) {
      const newArr: any = await product.map((obj: any) => {
        if (obj.perId === productId) {
          return {
            ...obj,
            watched: isWatch,
          };
        }
        return obj;
      });
      setProduct(newArr);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const exportExcelSheet = async () => {
    if (exportIds.length !== 0) {
      let ids = exportIds.toString();
      const { status } = await exportProducts(ids);
      if (status === 200) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } else {
      setOpenToast(true);
      setAlertType("error");
      setShowAlertMessage("Select product for export.");
      setTimeout(() => {
        setOpenToast(false);
      }, 2000);
    }
  };
  const setItemsPerPageHandler = (itemsPerPage: number) => {
    setPage(0);
    dispatch(setMySellPageItemPerPage({ itemsPerPage }));
  };

  useEffect(() => {
    const getData = setTimeout(() => {
      callApi(0);
    }, 700);

    return () => clearTimeout(getData);
  }, [filters]);

  const selectAllInGridView = () => {
    if (exportIds.length === sellProduct.length) {
      setexportIds([]);
    } else {
      let arr: any = [];
      sellProduct.map((productVal: any) => {
        arr.push(productVal.perId);
      });
      setexportIds(arr);
    }
  };

  const selectItemInGridView = (id: any) => {
    let arr: any = [];
    if (exportIds.indexOf(id) !== -1) {
      arr = exportIds.filter((x: any) => x !== id);
    } else {
      arr = [...exportIds];
      arr.push(id);
    }
    setexportIds(arr);
  };

  const filterHandler = (isFilter: boolean, data: any) => {
    localStorage.setItem("myPagefilteredValues", JSON.stringify(data));
    getSellProductList(0, data);
  };

  const renderSellandBuyHeader = () => {
    return (
      <StyledMypageGrid style={{ padding: "0 20px 0px" }}>
        <Grid item={true} xs={12}>
          <StyledHeaderContainer container={true} spacing={0}>
            <StyledCoreDataGridContainer container={true} spacing={0}>
              <StyledActionBarContainer
                container={true}
                spacing={0}
                className="coreActionBarContainer"
              >
                <StyledContainer>
                  <StyledExportButton onClick={() => {
                    setOpenInventoryModal(true)
                  }}>
                    {t("common:newCommanTrans:mypage:addInventory")}
                  </StyledExportButton>
                </StyledContainer>
                <StyledContainer>
                  {!isMobile ? (
                    <>
                      {isGridView ? (
                        <StyledSelectAllButtonHeader
                          onClick={selectAllInGridView}
                        >
                          {t("common:newCommanTrans:selectAll")}
                        </StyledSelectAllButtonHeader>
                      ) : null}
                    </>
                  ) : (
                    <StyledSelectAllButtonHeader onClick={selectAllInGridView}>
                      {t("common:newCommanTrans:selectAll")}
                    </StyledSelectAllButtonHeader>
                  )}
                  <StyledRoundButton onClick={refreshPage} style={{ marginRight: "10px" }}>
                    <RefreshIcon />
                  </StyledRoundButton>
                  <MypagePaginationSell
                    setItemsPerPageHandler={setItemsPerPageHandler}
                  />
                </StyledContainer>
                <StyledContainer >
                  <CategoryDropDown
                    setValue={setselectedSellCategory}
                    isSellPage={true}
                  />
                </StyledContainer>
                <StyledContainer>
                  <StyledReserveImg
                    src={isNrFilterSelected ? "/images/reserve/non-reserve-filter-with-Border.png" : "/images/reserve/non-reserve-filter-without-Border.png"}
                    alt="reserve"
                    onClick={() => nonReserveToReserve()}
                    title="reserve"
                  />
                  <StyledExportButton onClick={exportExcelSheet}>
                    {t("common:preview:pagination:export")}
                  </StyledExportButton>

                  <StyledDropDown
                    src="/images/assets/iconDownload.png"
                    alt="exportsheet"
                    onClick={exportExcelSheet}
                    title="Download Export"
                    style={{ marginLeft: "10px" }}
                  />
                  {
                    !isMobile ? (
                      <div
                        onClick={() => {
                          setIsGridView(!isGridView);
                        }}
                      >
                        {isGridView ? (
                          <StyledDropDown src="/images/assets/listViewIcon.png" className="icon" />
                        ) : (
                          <StyledDropDown src="/images/assets/gridViewIcon.png" className="icon" />
                        )}
                      </div>
                    ) : null
                  }
                </StyledContainer >
              </StyledActionBarContainer >
            </StyledCoreDataGridContainer >
          </StyledHeaderContainer >
        </Grid >
      </StyledMypageGrid >
    );
  };

  const renderSearchBar = () => {
    return (
      <StyledMypageGrid
        style={{
          padding: "0px 20px 5px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <StyledActionSecondBarContainer container={true} spacing={0}>
          <Grid
            item={true}
            xs={12}
            lg={3}
            xl={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <StyledAmountHeading>{t("common:newCommanTrans:mypage:totalAmount")}:</StyledAmountHeading>
              <StyledAmountHeading className="totalAmount">
                {userCurrencySymbol}
                {totalAmount}
              </StyledAmountHeading>
            </div>
          </Grid>
          <StyledActionsContainer item={true} xs={12} lg={9} xl={10}>
            <StyledContainer style={{ width: "25%" }}>
              <SearchWithSuggestion lable={t("common:newCommanTrans:mypage:search")} setValue={setFilters} />
            </StyledContainer>
            <StyledContainer>
              <FilterPopup
                filteredpropduct={filterHandler}
                resetfilter={() => {
                  callApi(0);
                }}
                iswatchlist={selectedSellCategory}
                isSellPage={true}
              />
            </StyledContainer>
          </StyledActionsContainer>
        </StyledActionSecondBarContainer>
      </StyledMypageGrid>
    );
  };

  const renderNegotiateModal = () => {
    return (
      <StyledModal
        open={showNegotiateModal}
        onClose={() => {
          setShowNegotiateModal(false);
        }}
      >
        <DoYouWishNegotiatePopup
          productDetails={row}
          closeModal={() => {
            setShowNegotiateModal(false);
          }}
        />
      </StyledModal>
    );
  };

  const handlenegotiate = (row: any) => {
    setRow(row);
    setShowNegotiateModal(true);
  };

  const onWatchListBtnHandler = () => {
    // onWatchListBtnHandler
  };

  const onSortHandler = (selectedOption: any) => {
    if (selectedOption[0] !== undefined) {
      let { field, sort } = selectedOption[0];
      let sortPayload: any = {
        property: field,
        direction: sort.toUpperCase(),
      };
      setSort(sortPayload);
      callApi(0);
    }
  };
  const onCardClickHandler = (product: any) => {
    let { auctionType, auctionStartDate, auctionId, perId, productCategory } = product;
    if (auctionType === "Annual") {
      let auctionDate = auctionStartDate.split(" ", 2);
      // dispatch(setAnnualItemPerPage({ itemsPerPage: 20 }));
      let isMypage: boolean = true;
      if (productCategory === "Jewellery") {
        window.open(
          `/${languageCode}/365products/details/${auctionId}/${jwljoinedAuctionUserId}/${perId}/${auctionDate[0]}/${isMypage}`,
          "detailsWindowName",
          "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
        );
      } else {
        window.open(
          `/${languageCode}/365products/details/${auctionId}/${joinedAuctionUserId}/${perId}/${auctionDate[0]}/${isMypage}`,
          "detailsWindowName",
          "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
        );
      }
    } else {
      window.open(
        `/${languageCode}/my-page/diamonds/details/${product.auctionId}/${product.perId}/${product.auctionStartDate}`,
        "detailsWindowName",
        "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
      );
    }
  };

  const readOnlyUserModal = () => {
    return (
      <ReadOnlyUserModal
        isOpen={readOnly}
        onclose={() => {
          setReadOnly(false);
          history.replace("/home");
        }}
      />
    );
  };

  const renderInventoryModal = () => {
    return (
      <StyledModal
        open={openInventoryModal}
      >
        <AddInventoryModal onCloseModal={() => {
          setOpenInventoryModal(false)
        }} />
      </StyledModal>
    );
  };

  // sellPageNrProductFilterd
  const nonReserveToReserve = () => {
    setIsNrFilterSelected(!isNrFilterSelected);
    if (!isNrFilterSelected) {
      localStorage.setItem("sellPageNrProductFilterd", JSON.stringify({
        "match": "exact",
        "name": "product.reserved",
        "value": false
      }));
    } else {
      localStorage.removeItem("sellPageNrProductFilterd");
    }
    callApi(0);
  }

  return (
    <div>
      {renderSellandBuyHeader()}
      {renderSearchBar()}
      <StyledMypageGrid style={{ padding: "15px 10px", alignItems: "center" }}>
        <Grid item={true} xs={12}>
          {isGridView || isMobile ? (
            <MyPageProductsGrid
              gridData={{
                list: sellProduct,
                totalElements: sellTotalElements,
              }}
              sortingProps={{ showCarat: true }}
              onCardClickHandler={onCardClickHandler}
              fetchAuctionProducts={() => {
                callApi(0);
              }}
              onWatch={onWatchListBtnHandler}
              onSort={onSortHandler}
              itemperpage={selectItemPerPageValue}
              setPage={callApi}
              page={page}
              exportIds={exportIds}
              handleWatchlist={(id: any, productid: any, watch: boolean) => {
                console.log("");
              }}
              selectItemInGridView={selectItemInGridView}
              isPageChange={isPageChange}
              isSellPage={true}
              handleNegotiate={handlenegotiate}
            />
          ) : (
            <MypageTable
              data={sellProduct}
              totalElements={sellTotalElements}
              changePageNum={callApi}
              setPage={setPage}
              isLoading={isLoading}
              page={page}
              isSellPage={true}
              handlePrebid={handlePrebid}
              handleWatchlist={handleWatchlist}
              handleNegotiate={handlenegotiate}
              exportIds={setexportIds}
              onSort={onSortHandler}
              itemperpage={selectItemPerPageValue}
              onDetailsPageAction={onCardClickHandler}
              jwlAuctionerId={jwljoinedAuctionUserId}
              diamondAuctionerId={joinedAuctionUserId}
            />
          )}
        </Grid>
        {showNegotiateModal && renderNegotiateModal()}
        {openToast && (
          <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
        )}
        {readOnly && readOnlyUserModal()}
        {openInventoryModal && renderInventoryModal()}
      </StyledMypageGrid>
    </div>
  );
};

export default React.memo(SellMypage);
