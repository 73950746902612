import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";





import { StyledLayoutGrid } from "../../../pages/preview/PreviewPageStyles";
import PageNotFound from "../../../pages/nopage/PageNotFound";
import { ProductCategorySmalls } from "../../../enums/productEnum";
import CommonDetailsContainer from "./Details/CommonDetailsContainer";



interface IProps {
  redirectFromPage: string;
}

const SingleProductDetails = (props: IProps) => {
  // @ts-ignore
  const { category } = useParams();
  const { redirectFromPage } = props;

  const renderCategoryBasedComponents = () => {
    switch (category) {
      case ProductCategorySmalls.DIAMONDS:
        return <CommonDetailsContainer redirectFromPage={redirectFromPage} />;
      case ProductCategorySmalls.JEWELLERY:
        return <CommonDetailsContainer redirectFromPage={redirectFromPage} />;
      default:
        return <PageNotFound />;
    }
  };

  return (
      <StyledLayoutGrid
        container={true}
        spacing={0}
        className="previewProductDetailsPage"
      >
        <Grid item={true} xs={12}>
          {category && renderCategoryBasedComponents()}
        </Grid>
      </StyledLayoutGrid>
  );
};


export default SingleProductDetails;
