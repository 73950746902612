import React, { useRef, useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import { useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import styled from "styled-components";
import { DialogProvider } from "../../../common/elements/Dialog/Dialog";
import ZipangHeader, { MobileSignIn } from "../Header/ZipangHeader";
import MobileMenus from "../../../components/header/new-Header/MobileMenus";
import { LandingPagePlayFairFont } from "../StyledComponents";
import Footer from "../Footer";
import Button from "../../../common/elements/Button";
import ZipangHero from "../Hero/ZipangHero";
import OurTeamGrid from "./OurTeamGrid";
import { removeRedirectPath } from "../../LandingPage/Utils";
import WhyChooseUsSection from "./WhyChooseUsSection";
import { useHistory } from "react-router";

const LandingPageOpenSansFont = styled.div`
  font-family: Playfair Display;
  overflow-x: hidden;
`;

const OurTeamPage = (props: any) => {
    const howItWorksRef = useRef();
    let history = useHistory();
    localStorage.setItem("paymentConditionAgree", "false");
    localStorage.setItem("refreshFlag", "false");
    window.document.title = "Zipang Auctions";
    const isMobile = useMediaQuery("(max-width: 820px)");
    const { t } = useTranslation();

    const [showSignIn, setShowSignIn] = useState(false);
    const handleClose = () => {
        setShowSignIn(false);
        localStorage.setItem("sellerBtnClick", "false");
    };
    const [upatedIndex, setUpdatedIndex] = useState(0);

    useEffect(removeRedirectPath, []);

    return (
        <DialogProvider>
            <div className="mainContainer">
                {isMobile ? null : <ZipangHeader />}
                <LandingPageOpenSansFont>
                    {isMobile ? <ZipangHeader /> : null}
                    <div>
                        {/* TOP */}
                        <Box
                            id="TOP"
                            sx={{
                                padding: "60px 0px",
                                minHeight: "100vh",
                            }}
                        >
                            <LandingPagePlayFairFont>
                                <ZipangHero isMobile={isMobile} inViewRef={howItWorksRef} setUpdatedIndex={setUpdatedIndex} />
                            </LandingPagePlayFairFont>
                            {isMobile && <MobileMenus />}
                            <Grid
                                container
                                justifyContent="center"
                                alignContent="center"
                                direction={isMobile ? "column" : "row"}
                                style={{ position: "absolute", zIndex: 1001 }}
                            >
                                <Button
                                    isMobile={isMobile}
                                    wide
                                    widePadding
                                    type="contained-new-black"
                                    onClick={() => {
                                        if (window.location.pathname.includes("team-page")) {
                                            history.push('/zipang');
                                        }
                                        window.location.hash = "SELLERS";
                                    }}
                                >
                                    {t("common:zipangLanding:heading:seller")}
                                </Button>
                                <Button
                                    isMobile={isMobile}
                                    wide
                                    widePadding
                                    type="contained-red"
                                    onClick={() => {
                                        if (window.location.pathname.includes("team-page")) {
                                            history.push('/zipang');
                                        }
                                        window.location.hash = "BUYER";
                                    }}
                                >
                                    {t("common:zipangLanding:heading:buyer")}
                                </Button>
                            </Grid>
                        </Box>
                        <OurTeamGrid />
                        <WhyChooseUsSection />
                        <Footer />
                    </div>
                </LandingPageOpenSansFont>
                <MobileSignIn show={showSignIn} onClose={handleClose} />
            </div>
        </DialogProvider>
    );
};

export default OurTeamPage;
