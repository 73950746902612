import { alertAutoHideDuration } from "../config/config";

const triggerEvent = (eventName: any, payload: any) => {
  const event = new CustomEvent(eventName, payload);
  window.dispatchEvent(event);
};

const notifySuccess = (
  message: string,
  autoHideDuration: number = alertAutoHideDuration
) => {
  if (message !== undefined && message !== "") {
    triggerEvent("ALERT_SUCCESS_EVENT", {
      detail: { message, autoHideDuration },
    });
  }
};

const notifyError = (
  message: string,
  autoHideDuration: number = alertAutoHideDuration
) => {
  if (message !== undefined && message !== "") {
    triggerEvent("ALERT_ERROR_EVENT", {
      detail: { message, autoHideDuration },
    });
  }
};

export { notifySuccess, notifyError };
