import { Grid, Modal } from "@material-ui/core";
import styled, { css } from "styled-components";

interface ButtonProps {
  selected?: boolean;
}

const StyledWebButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  border-radius: 3px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  margin-right: 5px;
  &:hover {
    background: ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${(props) => props.theme.colors.base.white};
      color: ${(props) => props.theme.colors.base.black};
    `}
`;

const StyledWebContainer = styled(Grid)`
  .logoText {
    font-size: 1.5em;
  }
  .returnToWebsite {
    font-size: 18px;
  }
`;

const StyledWebResetPaddingContainer = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.theme.colors.base.transparent};
`;

const StyledWebLogoWrapper = styled(Grid)`
  display: inline-block;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 90%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 20%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 20%;
    }
  }
  display: flex;
  align-items: center;
`;

const StyledWebLogoWrapperContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2em;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1001;
`;

const StyledWebFooterContainer = styled(Grid)`
  padding: 0 !important;
`;

const StyledWebBannerContianer = styled(Grid)`
  display: flex;
  position: relative;
`;

const StyledWebBanContent = styled(Grid)`
  color: ${(props) => props.theme.colors.base.white};
  position: absolute;
  font-weight: bold;
  top: 15%;
  left: 15%;

  .firstLine {
    font-size: 1.2em;
    padding-bottom: 0.1em;
  }
  .secondLine {
    font-size: 1em;
    margin-top: 1em;
  }
  @media (width: 768px) {
    top: 20% !important;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    left: 4%;
    top: 15%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    left: 2%;
    top: 20%;
    .firstLine {
      font-size: 0.8em;
    }
    .secondLine {
      font-size: 0.6em;
      margin-top: 0.3em;
    }
  }
`;

const StyledWebButtonContainer = styled.div`
  margin-top: 1em;
`;

const StyledWebBanBorder = styled(Grid)`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.white};
  opacity: 0.3;
`;
const StyledWebBidButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  border-radius: 8px;
  font-style: italic;
  background: #2660dd;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
    padding: 0.3em;
    border-radius: 4px;
  }
  &:hover {
    border: 1px solid #2660dd;
    cursor: pointer;
  }
`;

const StyledWebTypeImgContainer = styled(Grid)`
  text-align: center;
  justify-content: center;
  margin: 2em 0em;

  .width-6em {
    width: 6em;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .width-6em {
      width: 2em;
    }
  }
`;

const StyledWebAuctionTypeImage = styled.img`
  height: 10em;
  width: 12em !important;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 4em;
    width: 4em !important;
  }
`;
const StyledWebCompanyInfo = styled(Grid)`
  margin: 2em 0em;
  justify-content: center;
  .alignCenter {
    text-align: center;
  }
`;

const StyledWebComInfoImage = styled.img`
  height: 90%;
  width: 70%;
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 100%;
    width: 100%;
  }
`;

const StyledWebCompContent = styled(Grid)`
  font-size: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
  }
`;

const StyledWebTitle = styled.div`
  font-weight: bold;
  font-size: 1.4em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
`;
export const StyledWebTitleSub = styled.div`
  font-weight: bold;
  font-size: 2.4em;
  color: #2660dd;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 0.6em;
  }
`;
const StyledWebName = styled.div`
  font-size: 1.4em;
  color: black;
  font-weight: bold;
  margin-top: 0.4em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
    margin-top: 0.2em;
  }

  @media (width: 320px) {
    font-size: 0.6em;
  }
`;
const StyledWebBorderBottom = styled.div`
  border-bottom: 2px solid #2660dd;
`;
const StyledWebDescription = styled.div`
  margin-top: 0.6em;
  text-align: justify;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 0.2em;
    font-size: 0.5em;
  }
  @media (width: 320px) {
    font-size: 0.4em;
  }
  @media (width: 280px) {
    font-size: 0.2em;
  }
`;
const StyledWebFooter = styled(Grid)`
  justify-content: space-around;
  padding: 20px;
  ${(props) => props.theme.breakpoints.down("md")} {
    margin-left: 10px;
  }
`;

const StyledWebAuctionCon = styled(Grid)`
  position: relative;
  justify-content: center;

  img {
    height: 500px;
    width: 100%;
    padding: 30px;
  }

  .paddingLeftRight {
    padding: 0em 1em;
    width: 100%;
  }
  .content {
    position: absolute;
    top: 15%;
    left: 5%;
  }
  .contentsecond {
    position: absolute;
    top: 15%;
    right: 5%;
  }

  .content .border-bottom {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.textColor};
  }

  @media (width: 768px) {
    .content {
      top: 30% !important;
    }
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      height: 100%;
      width: 100%;
    }

    .paddingLeftRight {
      padding: 0em 0.5em;
      width: 100%;
    }
    .content {
      top: 10%;
      left: 5%;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    .content {
      left: 3%;
    }
  }
`;

const StyledWebRelevantPeople = styled(Grid)`
  justify-content: center;
  margin-top: 2em;
  .alignCenter {
    text-align: center;
  }
  .positionRelative {
    position: relative;
  }
  .content {
    position: absolute;
    top: 25%;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 0.8em;
    .content {
      position: relative;
    }
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
    .content {
      position: relative;
    }
  }
`;
const StyledWebContImage = styled.img`
  height: 100%;
  width: 100%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 130px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 120%;
    width: 100%;
  }
`;

const StyledWebRelevateImage = styled.img`
  height: 250px;
  width: 90%;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 95%;
    height: 100%;
  }
`;

const StyledWebButtonWrapper = styled.div`
  float: right;
  padding: 8px;
  display: flex;
`;
const StyledWebCompanyName = styled.div`
  color: black;
  margin-left: 0.5em;
  font-size: 2.8em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    argin-left: 0.4em;
    font-size: 1.2em;
  }
`;

const StyledWebDaimondCon = styled(Grid)`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column-reverse;
  gap: 20px;
`;

const StyledWebDaimondsText = styled.span`
  font-size: 1.5em;
  margin-right: 1em;
  color: black;
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;
const StyledWebDaimondsTextMobile = styled.div`
  font-size: 2em;
  color: black;
  font-weight: bold;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;
const StyledWebModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;
const StyledCompanyInfo = styled(Grid)`
  margin: 2em 0em;
  justify-content: center;
  .alignCenter {
    text-align: center;
  }
`;

const StyledComInfoImage = styled.img`
  height: 90%;
  width: 70%;
  text-align: center;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    height: 100%;
    width: 100%;
  }
`;

const StyledCompContent = styled(Grid)`
  font-size: 1.4em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    padding: 0.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    padding: 0.5em;
  }
`;

const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 1.6em;
  text-align: end;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
  color: #2660dd;
  font-weight: bold;
  margin-top: 0.4em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
    margin-top: 0.2em;
  }

  @media (width: 320px) {
    font-size: 0.6em;
  }
`;
const StyledName = styled.div`
  font-size: 1.5em;
  color: black;
  font-weight: bold;
  margin-top: 0.4em;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
    margin-top: 0.2em;
  }

  @media (width: 320px) {
    font-size: 0.6em;
  }
`;

const StyledBorderBottom = styled.div`
  border-bottom: 2px solid ${(props) => props.theme.colors.base.textColor};
`;
const StyledDescription = styled.div`
  margin-top: 0.6em;
  text-align: justify;

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 0.2em;
    font-size: 0.6em;
  }
  @media (width: 320px) {
    font-size: 0.5em;
  }
`;
export {
  StyledWebModal,
  StyledCompanyInfo,
  StyledComInfoImage,
  StyledCompContent,
  StyledTitle,
  StyledName,
  StyledBorderBottom,
  StyledDescription,
  StyledWebDaimondsTextMobile,
  StyledWebDaimondsText,
  StyledWebDaimondCon,
  StyledWebCompanyName,
  StyledWebButtonWrapper,
  StyledWebRelevateImage,
  StyledWebContImage,
  StyledWebRelevantPeople,
  StyledWebDescription,
  StyledWebAuctionCon,
  StyledWebBorderBottom,
  StyledWebName,
  StyledWebTitle,
  StyledWebCompContent,
  StyledWebComInfoImage,
  StyledWebCompanyInfo,
  StyledWebAuctionTypeImage,
  StyledWebTypeImgContainer,
  StyledWebButtonContainer,
  StyledWebBanBorder,
  StyledWebBidButton,
  StyledWebBanContent,
  StyledWebBannerContianer,
  StyledWebFooterContainer,
  StyledWebLogoWrapperContainer,
  StyledWebResetPaddingContainer,
  StyledWebLogoWrapper,
  StyledWebContainer,
  StyledWebButton,
  StyledWebFooter,
};
