import React from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { Component } from "react";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";

import ShippingAddress from "./ShippingAddress";
import SDEditProfile from './SDEditProfile';
import BankDetails from "./BankDetails";
import ChangePassword from "./ChangePassword";
import ChangePlan from "./ChangePlan";
import {
    changePlanUrl,
    profileUrl,
    userInvoiceDownload,
    getTimeZones
} from "../../common/config/app.endpoints";
import ShippingAddressList from "../products/views/ShippingAddressList";
import BankDetailsList from "../products/views/BankDetailsList";
import SDUserPersonalDetails from "../products/SDUserPersonalDetails";
import AuthService from "../auth/AuthService";
import Alert from "../../common/elements/Alert";
import { buttonGradient } from "../../common/materials/LinearGradient";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import ApiTimeout from "../../modules/ApiTimeout";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import BlockUserModal from "../products/BlockUser/BlockUserModal";

const StyledUserProfileContainer = styled(Grid)``;

const StyledProfileCard = styled(Grid)`
  margin: 10px 0px 30px 0px;
  width: 100%;
  margin-left: 25px;
`;

const StyledUserName = styled.h1`
  font-size: 1em;
  padding: 0.2em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.8em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 2.3em;
  }
  @media (width: 1024px) {
    font-size: 1.5em;
  }
`;

const StyledCompanyNameField = styled.h2`
  color: ${(props) => props.theme.colors.base.grey};
  display: flex;
  padding: 0.2em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1.8em;
  }
`;

const StyledOccupationName = styled.h2`
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  padding: 0.2em;
  display: flex;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1.8em;
  }
`;

const StyledButtonContainer = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

const StyledButtonWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const StyledTextWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

const StyledTextSubWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

const StyledPersonalBankFieldSet = styled.fieldset`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  display: flex;
  border-radius: 8px;
  margin: 1em;
  height: 270px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const StyledShippingFieldSet = styled.fieldset`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  display: flex;
  border-radius: 8px;
  margin: 1em;
  flex-direction: column;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    height: 560px;
  }
`;

const StyledLegend = styled.legend`
  margin-left: 20px;
  padding: 0.3em;
  font-size: 1em;
  color: ${(props) => props.theme.colors.base.grey};
  font-weight: bold;
`;

const StyledWrapper = styled(Grid)`
  width: 100%;
`;

const StyledShippingWrapper = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  padding: 10px 20px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.scrollerColor};
    border-radius: 5px;
  }
`;

const StyledBankWrapper = styled(Grid)`
  overflow-y: scroll;
  height: 220px;
  width: 100%;
  padding: 10px 20px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.scrollerColor};
    border-radius: 5px;
  }
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

const StyledGridWrapper = styled(Grid)`
  padding: 0.5em;
`;

const StyledAddressList = styled(Grid)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledGrid = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledAddMore = styled.div`
  text-align: right;
  cursor: pointer;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  width: 200px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledText = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  width: 200px;
`;

const StyledEditIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
`;

interface IProps extends IRouterComponentProps {
    t?: any;
}

interface IState {
    profileData: any;
    bankDetails: any;
    shippingAddresses: Array<string>;
    isShippingAddressModalOpen: boolean;
    isBankDetailsModalOpen: boolean;
    ischangePasswordModalOpen: boolean;
    isChangePlanModalOpen: boolean;
    openToast: boolean;
    occupation: [];
    showAlertMessage: string;
    isLoading: boolean;
    alertType: string;
    apiTimeout: boolean;
    isPlanChange: boolean;
    userBlock: boolean;
    userType: string;
    startDate: string;
    endDate: string;
    downloadInvoiceLink: string;
    space: string;
    userTypeValue: string;
    isEditProfile: boolean;
    timeZone: any;
}

export default class SubdomainUserProfile extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            profileData: [],
            bankDetails: [],
            shippingAddresses: [],
            isShippingAddressModalOpen: false,
            isBankDetailsModalOpen: false,
            ischangePasswordModalOpen: false,
            isChangePlanModalOpen: false,
            openToast: false,
            occupation: [],
            showAlertMessage: "",
            isLoading: false,
            alertType: "",
            apiTimeout: false,
            isPlanChange: false,
            userBlock: false,
            userType: "",
            startDate: "",
            endDate: "",
            downloadInvoiceLink: "",
            space: " ",
            userTypeValue: "",
            isEditProfile: false,
            timeZone: ''
        };
    }

    Auth = new AuthService();

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        if (!oAuth()) {
            this.props.history.push("/");
        } else {
            this.getProfile();
            localStorage.removeItem("filteredValues");
        }

        this.getTimeZone();

    }

    getTimeZone() {
        this.Auth.getRequest(getTimeZones, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    this.setState({
                        timeZone: response
                    });
                }
            }
        });
    }

    getTranslation = (occuption?: any) => {
        let role = "";
        if (occuption.length === 1) {
            if (occuption[0] === "Bidder") {
                role = role + this.props.t("common:register:occupation:bidder");
            } else if (occuption[0] === "Auctioneer") {
                role = role + this.props.t("common:register.occupation:auctioneer");
            }
        } else {
            role =
                role + this.props.t("common:register:occupation:auctioneerAndBidder");
        }
        return role;
    };

    getProfile() {
        this.Auth.getRequest(profileUrl, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    this.setState({
                        profileData: response,
                        occupation: response.occupations,
                        isLoading: false,
                        apiTimeout: false,
                        userType: response.userType,
                        startDate: response.subscriptionStartDate,
                        endDate: response.subscriptionEndDate,
                        downloadInvoiceLink: response.userType,
                    });

                    localStorage.setItem("profileComplete", response.profileComplete);
                    PubSub.publish("ProfileStatus", "Yes");

                    var userTypeTemp = response.userType;
                    let lang = localStorage.getItem("language");
                    if (lang != "EN") {
                        if (userTypeTemp === "SUBSCRIBED") {
                            userTypeTemp = "登録された";
                        } else {
                            userTypeTemp = "招待された";
                        }
                    }

                    this.setState({
                        userTypeValue: userTypeTemp,
                    });

                    if (response.bankDetails !== undefined) {
                        if (response.bankDetails !== null) {
                            response.bankDetails.forEach((item: any, i: number) => {
                                if (item.primary === true) {
                                    response.bankDetails.splice(i, 1);
                                    response.bankDetails.unshift(item);
                                }
                            });
                            this.setState({
                                bankDetails: response.bankDetails,
                            });
                        }
                    }
                    if (response.shippingAddresses !== undefined) {
                        if (response.shippingAddresses !== null) {
                            response.shippingAddresses.forEach((item: any, i: number) => {
                                if (item.primary === true) {
                                    response.shippingAddresses.splice(i, 1);
                                    response.shippingAddresses.unshift(item);
                                }
                            });
                            this.setState({
                                shippingAddresses: response.shippingAddresses,
                            });
                        }
                    }
                }
            } else if (status === 403) {
                this.setState({ userBlock: true });
            } else if (status === 505) {
                this.setState({ apiTimeout: true, isLoading: false });
            } else {
                this.setState({
                    alertType: "error",
                    openToast: true,
                    showAlertMessage: response.data.message,
                    isLoading: false,
                });
            }
        });
    }

    onAddShippingAddress() {
        this.setState({
            isShippingAddressModalOpen: true,
        });
    }

    onAddBankDetails() {
        this.setState({
            isBankDetailsModalOpen: true,
        });
    }

    onChangePassword() {
        this.setState({
            ischangePasswordModalOpen: true,
        });
    }

    changePlan() {
        if (!oAuth()) {
            this.props.history.push("/");
        } else {
            this.setState({
                isPlanChange: true,
            });
            this.Auth.postRequest(
                changePlanUrl,
                "data",
                (status: number, response: any) => {
                    if (status === 200) {
                        if (response !== undefined) {
                            this.setState({
                                isPlanChange: false,
                                isChangePlanModalOpen: true,
                            });
                        } else {
                            this.setState({
                                isPlanChange: false,
                                alertType: "error",
                                openToast: true,
                                showAlertMessage: response.data.message,
                                isLoading: false,
                            });
                        }
                    } else if (status === 403) {
                        this.setState({ userBlock: true });
                    } else if (status === 505) {
                        this.setState({ apiTimeout: true, isPlanChange: false });
                    } else {
                        this.setState({
                            isPlanChange: false,
                            alertType: "error",
                            openToast: true,
                            showAlertMessage: response[0].message,
                            isLoading: false,
                        });
                    }
                }
            );
        }
    }

    closeModal() {
        this.setState({
            isShippingAddressModalOpen: false,
            isBankDetailsModalOpen: false,
            ischangePasswordModalOpen: false,
            isChangePlanModalOpen: false,
            isEditProfile: false
        });
    }

    closeChangePasswordModal() {
        this.setState({
            openToast: false,
            showAlertMessage: "",
            ischangePasswordModalOpen: false,
        });
    }

    message = (message: string) => {
        this.setState({
            openToast: true,
            showAlertMessage: message,
        });
    };

    showAlert() {
        const { t } = this.props;
        this.setState({
            alertType: "success",
            openToast: true,
            showAlertMessage: `${t("common:profile:toast:passwordUpdate")}`,
        });
    }

    timeout = (timeout: boolean) => {
        this.setState({
            apiTimeout: true,
        });
        this.closeModal();
    };

    blockCloseModal = () => {
        this.Auth.logout();
        this.props.history.replace("/");
    };

    downloadInvoice() {
        this.setState({
            isPlanChange: true,
        });
        const Auth = new AuthService();

        Auth.getRequestForDownload(
            `${userInvoiceDownload}`,
            (status: any, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", `SubscriptionInvoice.pdf`);
                        document.body.appendChild(link);
                        link.click();
                    }
                } else {
                }

                this.setState({
                    isPlanChange: false,
                });
            }
        );
    }

    editProfileButton = () => {
        this.setState({
            isEditProfile: true
        })
    }

    render() {
        const { t } = this.props;
        return (
            <StyledUserProfileContainer
                container={true}
                spacing={0}
                className="userProfileContainer"
            >
                {this.state.apiTimeout === true ? (
                    <ApiTimeout t={t} {...this.props} />
                ) : (
                    <Grid container={true} spacing={0}>
                        {this.state.isLoading === true ? (
                            <div className="loader">
                                <LoadingSpinner />
                            </div>
                        ) : (
                            <Grid container={true} spacing={0} className="coreGridContainer">
                                <StyledProfileCard
                                    container={true}
                                    spacing={0}
                                    className="profileCard"
                                >
                                    <Grid item={true} xs={12} sm={6} md={6} lg={4} xl={3}>
                                        <StyledUserName>
                                            {this.state.profileData.firstName} {this.state.profileData.lastName}
                                        </StyledUserName>
                                    </Grid>
                                    <StyledButtonContainer
                                        item={true}
                                        xs={12}
                                        sm={6}
                                        md={6}
                                        lg={8}
                                        xl={9}
                                    >
                                        <StyledButtonWrapper
                                            item={true}
                                            xs={12}
                                            sm={6}
                                            md={6}
                                            lg={8}
                                            xl={9}
                                        >
                                            <StyledButton onClick={() => this.onChangePassword()}>
                                                {t("common:button:changePassword")}
                                            </StyledButton>
                                            <StyledButton onClick={() => this.changePlan()}>
                                                {t('common:button:changePlan')}
                                            </StyledButton>
                                        </StyledButtonWrapper>
                                    </StyledButtonContainer>
                                </StyledProfileCard>
                                <Grid container={true} spacing={0}>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <StyledShippingFieldSet>
                                                <StyledLegend>
                                                    {t("common:profile:title:personalDetails")}
                                                </StyledLegend>
                                                        <StyledWrapper>
                                                            <div style={{width: '97%', display: 'flex', height: '50px', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                                <StyledEditIcons
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            isEditProfile: true
                                                                        })
                                                                    }}
                                                                    src="/images/edit_profile.png"
                                                                />
                                                            </div>
                                                    <SDUserPersonalDetails
                                                        {...this.state.profileData}
                                                        t={t}
                                                    />
                                                </StyledWrapper>
                                            </StyledShippingFieldSet>
                                        </Grid>
                                        
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <StyledPersonalBankFieldSet>
                                            <StyledLegend>
                                                {t("common:profile:title:shippingAddress")}
                                            </StyledLegend>
                                            <StyledShippingWrapper>
                                                {this.state.shippingAddresses !== null && (
                                                    <div>
                                                        {this.state.shippingAddresses.length > 0 && (
                                                            <Grid container={true} spacing={0}>
                                                                {this.state.shippingAddresses.map(
                                                                    (items: any, index) => (
                                                                        <StyledGridWrapper
                                                                            key={index}
                                                                            item={true}
                                                                            xs={12}
                                                                            sm={12}
                                                                            md={6}
                                                                            lg={6}
                                                                        >
                                                                            <Card>
                                                                                <StyledAddressList
                                                                                    item
                                                                                    xs={12}
                                                                                    sm={12}
                                                                                    md={12}
                                                                                    lg={12}
                                                                                >
                                                                                    <ShippingAddressList
                                                                                        {...this.props}
                                                                                        apiTimeout={this.timeout}
                                                                                        {...items}
                                                                                        t={t}
                                                                                        didMount={() => this.getProfile()}
                                                                                        message={this.message}
                                                                                    />
                                                                                </StyledAddressList>
                                                                            </Card>
                                                                        </StyledGridWrapper>
                                                                    )
                                                                )}
                                                            </Grid>
                                                        )}
                                                    </div>
                                                )}
                                                <StyledGrid>
                                                    <StyledAddMore
                                                        onClick={() => this.onAddShippingAddress()}
                                                    >
                                                        {t("common:profile:addMore")}
                                                    </StyledAddMore>
                                                </StyledGrid>
                                            </StyledShippingWrapper>
                                                </StyledPersonalBankFieldSet>
                                                <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <StyledPersonalBankFieldSet>
                                                        <StyledLegend>
                                                            {t("common:profile:title:bankDetails")}
                                                        </StyledLegend>
                                                        <StyledBankWrapper>
                                                            {this.state.bankDetails !== null && (
                                                                <div>
                                                                    {this.state.bankDetails.length > 0 && (
                                                                        <Grid container={true} spacing={0}>
                                                                            {this.state.bankDetails.map(
                                                                                (items: any, index: number) => (
                                                                                    <StyledGridWrapper
                                                                                        key={index}
                                                                                        item={true}
                                                                                        xs={12}
                                                                                        sm={12}
                                                                                        md={6}
                                                                                        lg={6}
                                                                                    >
                                                                                        <Card>
                                                                                            <StyledAddressList
                                                                                                item
                                                                                                xs={12}
                                                                                                sm={12}
                                                                                                md={12}
                                                                                                lg={12}
                                                                                            >
                                                                                                <BankDetailsList
                                                                                                    {...this.props}
                                                                                                    apiTimeout={this.timeout}
                                                                                                    {...items}
                                                                                                    t={t}
                                                                                                    didMount={() => this.getProfile()}
                                                                                                    message={this.message}
                                                                                                />
                                                                                            </StyledAddressList>
                                                                                        </Card>
                                                                                    </StyledGridWrapper>
                                                                                )
                                                                            )}
                                                                        </Grid>
                                                                    )}
                                                                </div>
                                                            )}
                                                            <StyledGrid>
                                                                <StyledAddMore
                                                                    onClick={() => this.onAddBankDetails()}
                                                                >
                                                                    {t("common:profile:addMore")}
                                                                </StyledAddMore>
                                                            </StyledGrid>
                                                        </StyledBankWrapper>
                                                    </StyledPersonalBankFieldSet>
                                                </Grid>
                                    </Grid>
                                </Grid>
                                {this.state.isPlanChange === true && (
                                    <div className="loading">
                                        <LoadingSpinner />
                                    </div>
                                )}
                            </Grid>
                        )}
                    </Grid>
                )}
                {this.state.isEditProfile && (
                    <StyledModal
                        open={this.state.isEditProfile}
                        onClose={() => this.closeModal()}
                        // className="registerModal"
                    >
                        <SDEditProfile
                            {...this.state.profileData}
                            {...this.props}
                            closeModal={() => this.closeModal()}
                            saveProfile={() => this.getProfile()}
                            apiTimeout={this.timeout}
                            timeZone={this.state.timeZone}
                        />
                    </StyledModal>
                )}
                {this.state.isShippingAddressModalOpen && (
                    <StyledModal
                        open={this.state.isShippingAddressModalOpen}
                        onClose={() => this.closeModal()}
                        className="registerModal"
                    >
                        <ShippingAddress
                            {...this.props}
                            closeModal={() => this.closeModal()}
                            saveAddress={() => this.getProfile()}
                            apiTimeout={this.timeout}
                            companyName={this.state.profileData.companyName}
                        />
                    </StyledModal>
                )}
                {this.state.isBankDetailsModalOpen && (
                    <StyledModal
                        open={this.state.isBankDetailsModalOpen}
                        onClose={() => this.closeModal()}
                        className="registerModal"
                    >
                        <BankDetails
                            {...this.props}
                            closeModal={() => this.closeModal()}
                            saveDetail={() => this.getProfile()}
                            apiTimeout={this.timeout}
                        />
                    </StyledModal>
                )}
                {this.state.ischangePasswordModalOpen && (
                    <StyledModal
                        open={this.state.ischangePasswordModalOpen}
                        onClose={() => this.closeChangePasswordModal()}
                        className="registerModal"
                    >
                        <ChangePassword
                            {...this.props}
                            closeModal={() => this.closeChangePasswordModal()}
                            showAlert={() => this.showAlert()}
                            apiTimeout={this.timeout}
                        />
                    </StyledModal>
                )}
                {this.state.isChangePlanModalOpen && (
                    <StyledModal
                        open={this.state.isChangePlanModalOpen}
                        onClose={() => this.closeModal()}
                        className="registerModal"
                    >
                        <ChangePlan t={t} closeModal={() => this.closeModal()} />
                    </StyledModal>
                )}
                {this.state.openToast === true && (
                    <Alert
                        showAlertMessage={this.state.showAlertMessage}
                        alertType={this.state.alertType}
                    />
                )}
                {this.state.userBlock === true && (
                    <StyledModal open={this.state.userBlock}>
                        <BlockUserModal
                            t={t}
                            {...this.props}
                            closeModal={this.blockCloseModal}
                        />
                    </StyledModal>
                )}
            </StyledUserProfileContainer>
        );
    }
}
