import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import OptionMenuofShippingAddress from '../../shared/OptionMenuofShippingAddress';
import AuthService from '../../auth/AuthService';
import { markAsDefaultShippingAddresssUrl, deleteShippingAddress } from '../../../common/config/app.endpoints';
import { backgroundGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { oAuth } from '../../../routes/basic/BasicAppRouter';

const StyledShippingAddress = styled.div`
`;

const StyledOption = styled.div`
    width: 100%;
    text-align: right;
`;

const StyledGrid = styled(Grid)`
    border: none;
    .padding {
        color: ${(props) => props.theme.colors.base.black};
        padding: 10px 10px;
    }
    .markasdefault {
        padding: 20px 10px;
        color: ${(props) => props.theme.colors.base.white};
        background: ${backgroundGradient}; 
    }
`;

interface IProps extends IRouterComponentProps {
  t?: any;
  id: string;
  blockNo: string;
  streetName: string;
  city: string;
  state: string;
  primary: boolean | null;
  country: string;
  didMount: () => void;
  message: (message: string) => void;
  apiTimeout: (timeout: boolean) => void;
  colorChange: any;
  address: string;
  companyName: string;
  phone: string;
  pincode: string;
}

const ShippingAddressList = (props: IProps) => {

    const Auth = new AuthService();
    const {
      primary,
      blockNo,
      streetName,
      city,
      state,
      country,
      id,
      t,
      address,
      companyName,
      phone,
      pincode,
    } = props;

    const selectOptionforMarkDefault = () => {
        const data = {
          id: id,
          blockNo: blockNo,
          streetName: streetName,
          city: city,
          primary: true,
          state: state,
          country: country,
          companyName: companyName,
          address: address,
          phone: phone,
          pincode: pincode,
        };
        if (!oAuth()) {
            props.history.push('/');
        } else {
            Auth.postRequest(markAsDefaultShippingAddresssUrl, data, (status: number, response: any) => {
                if (status === 200) {
                    props.didMount();

                }
                else if (status === 505) {
                    props.apiTimeout(true)
                }
                else {
                    props.message(`${t('common:somethingWentWrong')}`)
                }
            })
        }
    }

    const selectOptionforDelete = () => {
        const data = {
          id: id,
          blockNo: blockNo,
          streetName: streetName,
          city: city,
          state: state,
          country: country,
          companyName: companyName,
          address: address,
          phone: phone,
          pincode: pincode,
        };
        if (!oAuth()) {
            props.history.push('/');
        } else {
            Auth.postRequest(deleteShippingAddress, data, (status: number, response: any) => {
                if (status === 200) {
                    props.message(response.data.message)
                    props.didMount();
                }
                else if (status === 505) {
                    props.apiTimeout(true)
                }
                else if (status > 200 && status < 500) {
                    props.message(response[0].message)
                }
                else {
                    props.message(`${t('common:somethingWentWrong')}`)
                }
            })
        }
    }

    return (
      <Grid container={true} spacing={0}>
        {primary === true ? (
          <StyledGrid item xs={12}>
            <div className="markasdefault">
              <StyledShippingAddress>{companyName}</StyledShippingAddress>
              <StyledShippingAddress>
                {address === undefined ? "" : address}
              </StyledShippingAddress>
              <StyledShippingAddress>{city}</StyledShippingAddress>
              <StyledShippingAddress>
                {state}, {country}, {pincode}
              </StyledShippingAddress>
              <span>{phone}</span>
            </div>
          </StyledGrid>
        ) : (
          <StyledGrid item xs={12}>
            <div className="padding">
              <StyledOption>
                <OptionMenuofShippingAddress
                  t={t}
                  selectMarkasDefault={selectOptionforMarkDefault}
                  deleteAddress={selectOptionforDelete}
                />
              </StyledOption>
              <StyledShippingAddress>{companyName}</StyledShippingAddress>
              <StyledShippingAddress>
                {address === undefined ? "" : address}
              </StyledShippingAddress>
              <StyledShippingAddress>{city}</StyledShippingAddress>
              <StyledShippingAddress>
                {state}, {country}, {pincode}
              </StyledShippingAddress>
              <span>{phone}</span>
            </div>
          </StyledGrid>
        )}
      </Grid>
    );
}

export default ShippingAddressList;