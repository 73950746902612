import { productUrl } from "../../../../common/config/app.endpoints";
import AuthService from "../../../auth/AuthService";

const Auth = new AuthService();



export const setPreBidPriceApi = async (requestObj: any) => {
  const { auctionId, productId, bidPrice } = requestObj;
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(`${productUrl}/${auctionId}/${productId}/setPreBidPrice?amt=${bidPrice}`, "payload", callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

export const setMultiplePreBidPriceApi = async (requestObj: any) => {
  const { auctionId, bidPayload } = requestObj;
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(
      `${productUrl}/${auctionId}/setMultiplePreBid`,
      bidPayload,
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};
export const cancelPreBidPriceApi = async (requestObj: any) => {
  const { auctionId, productId } = requestObj;
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.postRequest(
      `${productUrl}/${auctionId}/${productId}/cancelPreBidPrice`,
      "payload",
      callBack
    );
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};

// http://sysquareind.localhost:8081/keibai-service/product/{auctionId}/setMultiplePreBid
