import React, { useState } from "react";
import {
  StyledButton,
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledWrapper,
  StyledComponent,
} from "./BuyItNowPopupStyle";
import ProductDescription from "../../../shared/ProductDescription";
import { getRapnetPercentage } from "../../../../common/GenericFunctions";
import { postbuyitNowData } from "../BuiServices";
import Alert from "../../../../common/elements/Alert";
import EllipsisText from "../../../../common/elements/EllipsisText";
import { getDescription, getRapNet } from "../../../products/preview/TenderGenericFunc";

interface IProps {
  itemDetails: any;
  closeModal: () => void;
  auctionUserId: any;
}

const BuyItNowconfirmation = (props: IProps) => {
  const { itemDetails, auctionUserId, closeModal } = props;
  const [openToast, setOpenToast] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isThankyou, setIsThankyou] = useState(false);
  let userCurrencyCode = localStorage.getItem("preferredCurrency");
  let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const handleFormSubmit = async () => {
    const formData = [
      {
        bidIncreasedBy: 0,
        bidPrice: 0,
        auctionUserId: auctionUserId,
        auctionId: itemDetails.auctionId,
        productId: itemDetails.perId,
        buyItNow: true,
      },
    ];

    const { status } = await postbuyitNowData(formData);
    if (status === 200) {
      setIsThankyou(true);
      setTimeout(() => {
        closeModal();
      }, 3000);
    } else {
      setAlertType("error");
      setShowAlertMessage("Something went wrong.");
      setOpenToast(true);
      setTimeout(() => {
        setOpenToast(false);
      }, 2000);
    }
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        const rate = exchangeRate?.[0]?.["rate"] ?? 1;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };
  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price * carat;
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${userCurrencySymbol} (${conversions})`;
      }
    }
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          {isThankyou ? (
            <StyledTextWrapper style={{ fontSize: "1.4em" }}>
              Thank you for your interest, we will get back to you in bits!
            </StyledTextWrapper>
          ) : (
            <>
              <StyledTextWrapper>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    fontWeight: 700,
                    fontSize: "1.6em",
                  }}
                >
                  Are you sure ?
                </div>

                <div
                  style={{
                    textDecoration: "underline",
                    marginTop: "10px",
                    fontSize: "1.4em",
                  }}
                >
                  <ProductDescription
                    truncateLength={70}
                    data={itemDetails}
                    isTendor={true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "10px",
                    justifyContent: "center",
                    fontSize: "1.2em",
                  }}
                >
                  <span>Remarks:</span>
                  {itemDetails !== undefined && itemDetails !== null && (
                    <EllipsisText
                      text={getDescription(
                        itemDetails.parcelType,
                        itemDetails.productDescription,
                        itemDetails.remarks
                      )}
                      length={20}
                      tail={"..."}
                    />
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "5px",
                    marginTop: "10px",
                    justifyContent: "center",
                    fontSize: "1.4em",
                  }}
                >
                  <span>Buy it now Price:</span>
                  {itemDetails !== undefined && itemDetails !== null && (
                    <span>
                      {convertedPlaceHolderValue(
                        itemDetails.currency,
                        itemDetails.buyItNow,
                        itemDetails?.exchangeRates
                      )}
                    </span>
                  )}
                </div>
                {itemDetails?.parcelType !== "Jewellery" ? (
                  <div>
                    {itemDetails !== undefined && itemDetails !== null && (
                      <span>
                        {getRapnetPercentage(
                          itemDetails.rapnetPercentage,
                          itemDetails?.exchangeRates,
                          itemDetails?.currency,
                          itemDetails?.buyItNow,
                          itemDetails?.rapnetPrice
                        )}
                      </span>
                    )}
                  </div>
                ) : null}
                {itemDetails?.parcelType !== "Jewellery" ? (
                  <div>
                    <span>Amount: </span>
                    {itemDetails !== undefined && itemDetails !== null && (
                      <span>
                        {convertedAmt(
                          itemDetails.currency,
                          itemDetails.buyItNow ?? 0,
                          itemDetails?.exchangeRates,
                          Number(itemDetails.carat)
                        )}
                      </span>
                    )}
                  </div>
                ) : null}                  
              </StyledTextWrapper>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <StyledButton onClick={handleFormSubmit}>
                  BUY IT NOW
                </StyledButton>
              </div>
            </>
          )}
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
      {openToast && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
    </StyledComponent>
  );
};

export default React.forwardRef(BuyItNowconfirmation);
