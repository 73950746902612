import React, { useState } from "react";
import styled, { css } from "styled-components";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import { buttonGradient } from "../../../common/materials/LinearGradient";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import ShippingAllAddress from "./shippingAddMore/ShippingAllAddress";

const StyledContainer = styled(Grid)`
  .name {
    font-size: 1.2em;
    font-family: Calibre-SemiBold;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .heading {
    font-size: 0.7em;
    padding: 2px;
    color: ${(props) => props.theme.colors.base.grey};
  }
  .secondary {
    font-size: 1em;
    padding: 5px;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .textWrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .borderBottom {
    border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  .borderLeft {
    border-left: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  }
`;

const StyledContainerWrapper = styled(Grid)`
  padding: 10px;
`;

const StyledGridContainer = styled(Grid)`
  margin: 10px 10px;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 8px 0px
    ${(props) => props.theme.colors.base.lightGrey};
  -moz-box-shadow: 0px 0px 8px 0px
    ${(props) => props.theme.colors.base.lightGrey};
  box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
  &:focus {
    outline: none;
  }
`;

const StyledDateInvoiceContainer = styled(Grid)``;

const StyledInvoiceContainer = styled(Grid)`
  text-align: center;
`;

const StyledDateContainer = styled(Grid)`
  text-align: center;
  padding-top: 10px;
`;

const StyledNameContainer = styled(Grid)`
  padding: 10px;
  text-align: center;
`;

const StyledGrid = styled(Grid)`
  width: 95%;
  margin: 5px;
  text-align: center;
  background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
`;

const StyledButtonContainer = styled(Grid)`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-align: center;
`;

interface IButtonProps {
  selected?: boolean;
  notSelected?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  width: 90%;
  border: none;
  margin-bottom: 10px;
  font-size: 0.8em;
  padding: 0.5em;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors.base.white};
      background: ${(props) =>
        props.theme.colors.base.buttonGradientFirstColor};
    `}
  ${(props) =>
    props.notSelected &&
    css`
      cursor: default;
      color: ${(props) => props.theme.colors.base.grey};
      background: ${(props) => props.theme.colors.base.lightGrey};
    `}
`;

interface IProps extends IRouterComponentProps {
  t: any;
  auctionId: string;
  id: string;
  lastModifiedDate: any;
  amountPaidByBidder: any;
  amountPaidBySeller: any;
  shippingAddress: any;
  auctionName: string;
  purchaseAmount: number;
  targetCurrency: string;
  saleAmount: number;
  sendMessage: (message: string) => void;
  apiTimeout: (timeout: boolean) => void;
  downloadpdf: (
    invoiceId: string,
    auctionName: string,
    onClick: boolean
  ) => void;
  downloadinternationalInvoice: (
    invoiceId: string,
    auctionName: string,
    onClick: boolean
  ) => void;
  openTrackingUiWithId: (autionId: any) => void;
  currency: any;
  amountPaidBySellerWithTax: any;
  amountPaidByBidderWithTax: any;
  sentTaxInvoice: boolean;
}

let date: string | null = "";
let timezone: any = "";
let hour: any = "";

const InvoiceListView = React.forwardRef((props: IProps, ref) => {
  const [openModal, setOpenModal] = useState(false);
  const {
    t,
    targetCurrency,
    currency,
    id,
    auctionId,
    lastModifiedDate,
    amountPaidByBidder,
    amountPaidBySeller,
    shippingAddress,
    auctionName,
    sendMessage,
    downloadpdf,
    amountPaidByBidderWithTax,
    amountPaidBySellerWithTax,
    sentTaxInvoice,
  } = props;
  let previewTime = moment.utc(lastModifiedDate);
  let amountToPay: any;
  let amountToReceive: any;

  if (currency === "USD") {
    amountToPay = Math.round(amountPaidBySeller);
    amountToReceive = Math.round(amountPaidByBidder);
  } else {
    if (targetCurrency === "USD") {
      amountToPay = Math.round(amountPaidBySeller);
      amountToReceive = Math.round(amountPaidByBidder);
    } else {
      amountToPay = Math.round(
        sentTaxInvoice ? amountPaidBySeller : amountPaidBySellerWithTax
      );
      amountToReceive = Math.round(
        sentTaxInvoice ? amountPaidByBidder : amountPaidByBidderWithTax
      );
    }
  }

  amountToPay = new Intl.NumberFormat("us-en").format(amountToPay);
  amountToReceive = new Intl.NumberFormat("us-en").format(amountToReceive);

  const openPopup = () => {
    setOpenModal(true);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const forDownloadInvoice = (id: string, auctionName: string) => {
    downloadpdf(id, auctionName, true);
  };
  const sendAlertMessage = (message: string) => {
    sendMessage(message);
  };

  const timeout = (timeout: boolean) => {
    props.apiTimeout(timeout);
  };

  if (timezone !== null) {
    timezone = localStorage.getItem("timezoneName");
  }
  if (previewTime !== null || previewTime !== undefined) {
    let result = moment.tz(previewTime, timezone);
    let resultFormatter = result.format();
    if (resultFormatter !== "Invalid date") {
      let splitDateTime = resultFormatter.split("T", 2);
      let splitTime: any = "";
      if (splitDateTime[1].includes("+")) {
        splitTime = splitDateTime[1].split("+", 2);
      } else {
        splitTime = splitDateTime[1].split("-", 2);
      }
      date = splitDateTime[0];
      let time = splitTime[0];
      let dateTime = `${date} ${time}`;
      let start: any = moment(dateTime).add(744, "h");
      console.log(start)
      timezone = localStorage.getItem("timezoneName");
      let end: any = moment(
        new Date().toLocaleString("en-US", { timeZone: timezone })
      );
      hour = start.diff(end, "hours");
    }
  }

  const renderDownloadButton = () => {
    return (
      <Grid item xs={4}>
        {hour <= 0 ? (
          <StyledButton
            notSelected={true}
            onClick={() => forDownloadInvoice(id, auctionName)}
          >
            {t("common:invoice:buttons:download")}
          </StyledButton>
        ) : (
          <StyledButton
            selected={true}
            onClick={() => forDownloadInvoice(id, auctionName)}
          >
            {t("common:invoice:buttons:download")}
          </StyledButton>
        )}
      </Grid>
    );
  };

  const renderTrackingButton = () => {
    return (
      <Grid item xs={4}>
        {hour <= 0 ? (
          <StyledButton notSelected={true}>
            {t("common:invoice:buttons:trackingButton")}
          </StyledButton>
        ) : (
          <StyledButton
            selected={true}
            onClick={() => props.openTrackingUiWithId(auctionId)}
          >
            {t("common:invoice:buttons:trackingButton")}
          </StyledButton>
        )}
      </Grid>
    );
  };

  const renderInvoiceCurrencyCode = () => {
    let currencyCode: string;
    if (currency === "USD") {
      currencyCode = currency === "USD" ? "$" : "¥";
    } else {
      if (targetCurrency === "USD") {
        currencyCode = "$";
      } else {
        currencyCode = "¥";
      }
    }
    return currencyCode;
  };

  return (
    <StyledContainer container spacing={0}>
      <StyledGridContainer item xs={12}>
        <StyledContainerWrapper container spacing={0}>
          <Grid item xs={12}>
            <StyledNameContainer>
              {" "}
              <div className="name textWrap">{auctionName}</div>{" "}
            </StyledNameContainer>
          </Grid>
        </StyledContainerWrapper>
        <StyledDateInvoiceContainer item xs={12}>
          <StyledGrid container spacing={0}>
            <StyledDateContainer item xs={12} className="borderBottom">
              <div className="secondary fontBold textWrap">{date}</div>
            </StyledDateContainer>
            <StyledGrid container spacing={0}>
              <StyledInvoiceContainer item xs={6}>
                <div className="heading fontBold">
                  {t("common:invoice:heading:totalAmountToPay")}
                </div>
                <div className="secondary fontBold textWrap">
                  {renderInvoiceCurrencyCode()} {amountToReceive}
                </div>
              </StyledInvoiceContainer>
              <StyledInvoiceContainer item xs={6} className="borderLeft">
                <div className="heading fontBold ">
                  {t("common:invoice:heading:totalAmountToReceive")}
                </div>
                <div className="secondary fontBold textWrap">
                  {renderInvoiceCurrencyCode()}
                  {amountToPay}
                </div>
              </StyledInvoiceContainer>
            </StyledGrid>
          </StyledGrid>
        </StyledDateInvoiceContainer>
      </StyledGridContainer>
      <StyledButtonContainer container spacing={0}>
        <Grid item xs={4}>
          {hour <= 0 ? (
            <StyledButton notSelected={true}>
              {t("common:invoice:buttons:shippingAddress")}
            </StyledButton>
          ) : (
            <Grid onClick={openPopup}>
              <StyledButton selected={true}>
                {t("common:invoice:buttons:shippingAddress")}
              </StyledButton>
            </Grid>
          )}
        </Grid>
        {renderDownloadButton()}
        {renderTrackingButton()}
      </StyledButtonContainer>
      {openModal === true && (
        <StyledModal open={openModal} onClose={onClose}>
          <ShippingAllAddress
            {...props}
            shippingAddress={shippingAddress}
            auctionId={auctionId}
            onClose={onClose}
            isAnnualType={false}
            sendMessage={sendAlertMessage}
            apiTimeout={timeout}
          />
        </StyledModal>
      )}
    </StyledContainer>
  );
});

export default InvoiceListView;
