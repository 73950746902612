import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { buttonGradient } from "../../../../common/materials/LinearGradient";
import TooltipInfo from "../../../../common/elements/TooltipInfo";

import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import { addOrRemoveWatchlist } from "../../../../components/mypage/MypageApiService";

const StyledWishContainer = styled(Grid)`
  display: flex;
  width: auto;
  justify-content: space-around;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.3em;
  width: 100%;
  border-radius: 5px;
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid
      ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  }
  .notAvailable {
    cursor: default;
    background: ${(props) => props.theme.colors.base.lightGrey};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.white};
    &:hover {
      border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    }
  }
`;

const StyledWatchlist = styled(Grid)`
  padding: 0.5em 0em;
  text-align: center;
  padding: 6px 5px;
`;

const StyledWishlistIcon = styled("img")`
  width: 15px;
  height: 15px;
  vertical-align: top;
  margin-top: 4px;
`;

const StyledWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledTooltipText = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 12px;
  padding: 0 5px;
`;

interface IProps {
  productData: any;
  getDetails: (id: string) => void;
  alertType: (
    alertType: string,
    isOpenToast: boolean,
    showMessage: string
  ) => void;
}
let joinedAuctions: any;
let isReadOnlyUser: any;
const WatchButton = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const { productData, getDetails } = props;
  const { auctionId, perId, auctionState, watched, sellerAuctionUserId } =
    productData;

  const [isAuctionUserId, setIsAuctionUserId] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);

    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    return () => setIsAuctionUserId(false);
  }, []);

  useEffect(() => {
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    return () => setIsAuctionUserId(false);
  }, [perId]);

  const addToWatchList = async (perId: string, auctionId: string) => {
    const { status } = await addOrRemoveWatchlist(
      `${auctionId}/${perId}/watch`
    );
    if (status === 200) {
      setDisableButton(false);
      props.alertType(
        "success",
        true,
        `${t("trans:addToWatchlistSuccessfully")}`
      );
      getDetails(perId);
    } else {
      props.alertType("error", true, `${t("common:somethingWentWrong")}`);
    }
  };

  const removeFromWatchlist = async (perId: string, auctionId: string) => {
    setDisableButton(true);
    const { status } = await addOrRemoveWatchlist(
      `${auctionId}/${perId}/unwatch`
    );
    if (status === 200) {
      setDisableButton(false);
      props.alertType(
        "success",
        true,
        `${t("trans:removedFromWatchlistSuccessfully")}`
      );
      getDetails(perId);
    } else {
      if (status === 412) {
        setTimeout(() => {
          history.push("/preview");
        }, 2000);
      }
      props.alertType("error", true, `${t("common:somethingWentWrong")}`);
    }
  };

  const isWatched = () => {
    return watched && watched === true;
  };

  const isAuctionStateNotStarted = () => {
    return true;
  };

  const isButtonClickable = () => {
    return (
      (isAuctionUserId === false || sellerAuctionUserId === undefined) &&
      disableButton === false &&
      isReadOnlyUser === false
    );
  };

  const addCallback = (e: any) => {
    e.preventDefault();
    addToWatchList(perId, auctionId);
  };

  const removeCallback = (e: any) => {
    e.preventDefault();
    removeFromWatchlist(perId, auctionId);
  };

  const renderActionButtonTemplate = (
    image: string,
    label: string,
    callback: (e: any) => void
  ) => {
    return (
      <StyledWishContainer onClick={callback}>
        <StyledWatchlist item xs={12}>
          <StyledWishlistIcon src={`/images/${image}.png`} />{" "}
          {t(`common:preview:${label}`)}
        </StyledWatchlist>
      </StyledWishContainer>
    );
  };

  const renderButtonTemplate = (image: string, label: string) => {
    return (
      <StyledWishContainer
        className="notAvailable"
        onClick={renderReadOnlyUserToast}
      >
        <StyledWatchlist item xs={9}>
          <StyledWishlistIcon src={`/images/${image}.png`} />{" "}
          {t(`common:preview:${label}`)}
        </StyledWatchlist>
      </StyledWishContainer>
    );
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const renderWatchedButton = () => {
    return (
      <>
        {isAuctionStateNotStarted()
          ? isButtonClickable()
            ? renderActionButtonTemplate(
                "heart-grey",
                "addedToWatchList",
                addCallback
              )
            : renderButtonTemplate("heart-grey", "addedToWatchList")
          : renderButtonTemplate("heart-grey", "addedToWatchList")}
      </>
    );
  };

  const renderUnWatchedButton = () => {
    return (
      <>
        {isAuctionStateNotStarted()
          ? isButtonClickable()
            ? renderActionButtonTemplate(
                "heart",
                "addWatchList",
                removeCallback
              )
            : renderButtonTemplate("heart", "addWatchList")
          : renderButtonTemplate("heart", "addWatchList")}
      </>
    );
  };

  return (
    <TooltipInfo
      message={t("common:preview:productDetail:watchTooltip")}
      iconClassName="technicalGlitchAuction"
    >
      <StyledTooltipText>
        <StyledWrapper container={true} spacing={0}>
          <StyledButton>
            {isWatched() ? renderUnWatchedButton() : renderWatchedButton()}
          </StyledButton>
        </StyledWrapper>
      </StyledTooltipText>
      {openReadOnlyUserToast && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
    </TooltipInfo>
  );
};

export default WatchButton;
