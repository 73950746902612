import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@material-ui/icons/Person";
import styled from "styled-components";
import { useHistory } from "react-router";
import AuthService from "../../auth/AuthService";

import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

const StyleText = styled.span`
  font-size: 12px;
`;

let paperObj = {
  elevation: 0,
  sx: {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    mt: 1.5,
    "& .MuiAvatar-root": {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  },
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openLog, setLogOpen] = React.useState(false);
  const open = Boolean(anchorEl);
  const Auth = new AuthService();

  let { t } = useTranslation();

  let firstName = localStorage.getItem("userFirstName");
  let lastName = localStorage.getItem("userLastName");
  let history = useHistory();
  let paddleNumber: any = localStorage.getItem("paddleNumber");
  let userProfileType = localStorage.getItem("userType");
  let userEmail = localStorage.getItem("userEmail");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectOnProfilePage = () => {
    if (userProfileType === "SUB_DOMAIN") {
      history.push("/SubdomainProfile");
    } else {
      history.push("/profile");
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("urlRedirectFromEmail");
    localStorage.removeItem("urlRedirectFromAdmin");
    Auth.logout();
    history.replace("/");
  };

  const handleClickOpen = () => {
    setLogOpen(true);
  };

  const customizedDialogs = () => {
    const handleClose = () => {
      setLogOpen(false);
    };
    return (
      <div>
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={openLog}
        >
          <DialogContent dividers>
            <Typography gutterBottom>
              {t("common:newCommanTrans:surelogout")}
            </Typography>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              autoFocus
              onClick={handleClose}
              color="primary"
              variant="contained"
            >
              {t("common:newCommanTrans:CANCEL")}
            </Button>
            <Button
              autoFocus
              onClick={logoutUser}
              color="primary"
              variant="contained"
            >
              {t("common:newCommanTrans:OK")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          title="User Profile"
        >
          <PersonIcon
            style={{
              fontSize: 22,
              color: "black",
              cursor: "pointer",
            }}
          />
        </IconButton>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperObj}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem style={{ height: "10px", cursor: "default" }}>
          Account
        </MenuItem>
        <Divider />
        <MenuItem style={{ cursor: "default" }}>
          <div style={{ display: "flex", gap: "5px" }}>
            <div>
              <IconButton
                size="small"
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                style={{
                  background: "#000f7e",
                  color: "white",
                  border: "3px solid gray",
                }}
              >
                {paddleNumber !== undefined && paddleNumber !== null
                  ? paddleNumber
                  : "-"}
              </IconButton>{" "}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <StyleText>
                {firstName} {lastName}
              </StyleText>
              <StyleText>{userEmail}</StyleText>
              <StyleText>
                paddle:{" "}
                {paddleNumber !== undefined && paddleNumber !== null
                  ? paddleNumber
                  : "-"}
              </StyleText>
            </div>
          </div>
        </MenuItem>
        <MenuItem
          style={{ height: "20px", fontSize: "14px" }}
          onClick={redirectOnProfilePage}
        >
          Manage account
        </MenuItem>
        <Divider />
        <MenuItem
          style={{ height: "20px", fontSize: "14px" }}
          onClick={handleClickOpen}
        >
          Logout
        </MenuItem>
      </Menu>
      {openLog && customizedDialogs()}
    </React.Fragment>
  );
}
