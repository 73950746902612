import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import { AuctionState } from "../../../../enums/auctionEnum";
import { getNumberFormat } from "../../../../services/NumberFormatService";

import { StyledModal } from "../../../../common/materials/GenericStyleComponent";
import PreBidConfirmationDialog from "./PreBidConfirmationDialog";
import {
  StyledContainer,
  StyledLabel,
  StyledLabelConversion,
  StyledGrid,
  StyledButtonWrapper,
  StyledButton,
  StyledCancelButton,
  StyledInput,
  StyledInputWrapper,
  StyledTextWrapper,
} from "./PreBiddingFormStyles";
import AlertReadOnlyUser from "../../../../common/elements/AlertReadOnlyUser";
import Alert from "../../../../common/elements/Alert";
import {
  cancelPreBidPriceApi,
  setPreBidPriceApi,
} from "../../../../components/products/preview/bulkWatchlistComponent/PrebidService";

interface IProps {
  productData: any;
  count: number;
  getDetails: (id: string) => void;
  PreBiddingDetail: (data: any) => void;
}
let joinedAuctions: any = [];
let isReadOnlyUser: any;

const NewPreBiddingForm = (props: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { productData, count, getDetails } = props;
  const {
    auctionId,
    auctionState,
    caratMinimumPrice,
    currency,
    exchangeRates,
    parcelType,
    perId,
    preBidPrice,
    sellerAuctionUserId,
    carat,
    auctionType,
  } = productData;

  const [isAuctionUserId, setIsAuctionUserId] = useState<boolean>(false);
  const [openPreBidPopUpModal, setOpenPreBidPopUpModal] = useState(false);
  const [biddingPrice, setBiddingPrice] = useState("");
  const [eStore, setEStore] = useState();
  const [preBiddingPrice, setPreBiddingPrice] = useState("");
  const [preBidConversion, setPreBidConversion] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [openReadOnlyUserToast, setOpenReadOnlyUserToast] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");

  const userCurrencyCode = localStorage.getItem("preferredCurrency");
  const userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const prebidPriceIncby = useRef(0);

  useEffect(() => {
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }

    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);

    return () => {
      setIsAuctionUserId(false);
      setBiddingPrice("");
      setPreBiddingPrice("");
      setPreBidConversion("");
    };
  }, []);

  useEffect(() => {
    setBiddingPrice("");
    setPreBiddingPrice("");
    setPreBidConversion("");
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null) {
      joinedAuctions.filter((items: any, index: number) => {
        if (sellerAuctionUserId === items.auctionUserId) {
          setIsAuctionUserId(true);
        }
      });
    } else {
      setIsAuctionUserId(false);
    }
    return () => setIsAuctionUserId(false);
  }, [perId]);

  const getBracketValue = () => {
    let bidingPriceZero: any = "";
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidingPriceZero = "000";
        } else if (bidPrice >= 3000000) {
          bidingPriceZero = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidingPriceZero = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidingPriceZero = "000";
        } else if (bidPrice >= 3000000) {
          bidingPriceZero = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidingPriceZero = "0";
        } else if (bidPrice >= 30000) {
          bidingPriceZero = "000";
        }
      }
    }
    return bidingPriceZero;
  };

  const renderCurrencyCode = () => {
    let bidZeroText;
    const isJPY = currency["code"] === "JPY" ? true : false;
    const bidPrice = caratMinimumPrice; // starting price

    if (parcelType === "Parcel" && parcelType !== undefined) {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidZeroText = "000";
        } else if (bidPrice >= 3000000) {
          bidZeroText = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    } else {
      if (isJPY) {
        if (bidPrice < 10000) {
          bidZeroText = "00";
        } else if (bidPrice >= 10000 && bidPrice < 3000000) {
          bidZeroText = "000";
        } else if (bidPrice >= 3000000) {
          bidZeroText = "00000";
        }
      } else {
        if (bidPrice >= 100 && bidPrice < 30000) {
          bidZeroText = 0;
        } else if (bidPrice >= 30000) {
          bidZeroText = "000";
        }
      }
    }

    return (
      <>
        {currency && currency["code"] !== "JPY" ? (
          <>
            <StyledTextWrapper
              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {bidZeroText}$
            </StyledTextWrapper>
          </>
        ) : (
          <>
            <StyledTextWrapper
              style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {bidZeroText}¥
            </StyledTextWrapper>
          </>
        )}
      </>
    );
  };

  const checkAuctionState = () => {
    return (
      auctionState === AuctionState.NOTSTARTED ||
      auctionState === "" ||
      auctionState === "undefined" ||
      auctionState === null
    );
  };

  const checkAuctionUserIdAndCount = () => {
    return isAuctionUserId === false && count === 0;
  };

  const checkPrebidPrice = () => {
    return preBidPrice === null || preBidPrice === undefined;
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setBiddingPrice(e.target.value);

    let targetVal: any = e.target.value;
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    let bidingPriceZero = await getBracketValue();
    if (parcelType !== "Diamond") {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice))
        );

        setPreBidConversion(
          `${currency["symbol"]} ${conversions} (${userCurrencySymbol} ${conversion})`
        );
      } else {
        setPreBidConversion("");
      }
    } else {
      if (userCurrencyCode !== currency["code"]) {
        let bidPrice = targetVal + bidingPriceZero;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
        );
        setPreBidConversion(`${userCurrencySymbol}${conversion}`);
      } else {
        setPreBidConversion("");
      }
    }
    // when user clear the prebid amount
    if (targetVal === "") {
      setPreBidConversion("");
    }
  };

  const showPreBidPopUp = async () => {
    const validate = (bidPrice: string) => {
      if (biddingPrice === "") {
        setShowAlertMessage(
          `${t(
            parcelType === "Jewellery"
              ? "common:preview:preBidding:jwlEnterAmount"
              : "common:preview:preBidding:enterAmount"
          )}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else if (bidPrice <= caratMinimumPrice) {
        setBiddingPrice("");
        setShowAlertMessage(
          `${t("common:preview:preBidding:validationOnCorrectAmount")}`
        );
        setAlertType("error");
        setOpenToast(true);
      } else {
        if (userCurrencyCode !== currency["code"]) {
          let conversion = new Intl.NumberFormat("us-en").format(
            Math.round(Number(bidPrice) * exchangeRates[0]["rate"])
          );
          setPreBidConversion(`${userCurrencySymbol}${conversion}`);
        } else {
          setPreBidConversion("");
        }
        setPreBiddingPrice(
          `${currency["symbol"]}${getNumberFormat(Number(bidPrice))}`
        );
        setOpenPreBidPopUpModal(true);
      }
    };
    let bidingPriceZero = await getBracketValue();
    const bidPrice = biddingPrice + bidingPriceZero;
    validate(bidPrice);
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    if (checkPrebidPrice() && isAuctionUserId === false) {
      showPreBidPopUp();
      setEStore(e);
    } else {
      if (isAuctionUserId === false) {
        handleCancelSubmit(e);
      }
    }
  };

  const refreshButton = () => {
    setBiddingPrice("");
    prebidPriceIncby.current = 0;
    setPreBidConversion("");
  };

  const preBidModalYesButtonPressed = () => {
    handlePreBidSubmit(eStore);
  };

  const handlePreBidSubmit = async (e: any) => {
    e.preventDefault();
    let bidPrice = "";
    const letters = /^[0-9]+$/;
    let bidingPriceZero = await getBracketValue();
    bidPrice = biddingPrice + bidingPriceZero;

    if (bidPrice.match(letters) && bidPrice > caratMinimumPrice) {
      let { response, status } = await setPreBidPriceApi({
        auctionId,
        productId: perId,
        bidPrice,
      });
      if (status === 200) {
        setOpenPreBidPopUpModal(false);
        setAlertType("success");
        setShowAlertMessage(response.data.message);
        setOpenToast(true);
        refreshButton();
        getDetails(perId);
      } else if (status === 404) {
        setBiddingPrice("");
        setShowAlertMessage(`${t("common:invalidRequest")}`);
        setAlertType("error");
        setOpenToast(true);
      } else if (status === 412) {
        setTimeout(() => {
          history.push("/preview");
        }, 2000);
        setBiddingPrice("");
        setShowAlertMessage(response);
        setAlertType("error");
        setOpenToast(true);
      } else {
        setBiddingPrice("");
        setShowAlertMessage(`${t("common:somethingWentWrong")}`);
        setAlertType("error");
        setOpenToast(true);
      }
    } else if (biddingPrice === "") {
      setAlertType("error");
      setShowAlertMessage(
        `${t(
          parcelType === "Jewellery"
            ? "common:preview:preBidding:jwlEnterAmount"
            : "common:preview:preBidding:enterAmount"
        )}`
      );
      setOpenToast(true);
    } else {
      setBiddingPrice("");
      setShowAlertMessage(
        `${t("common:preview:preBidding:validationOnCorrectAmount")}`
      );
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const handleCancelSubmit = async (e: any) => {
    e.preventDefault();
    let { response, status } = await cancelPreBidPriceApi({
      auctionId,
      productId: perId,
    });
    if (status === 200) {
      setShowAlertMessage("Pre-bid price removed successfully");
      setAlertType("success");
      setOpenToast(true);
      refreshButton();
      setOpenPreBidPopUpModal(false);
      getDetails(perId);
    } else if (status === 404) {
      setAlertType("error");
      setShowAlertMessage(`${t("common:invalidRequest")}`);
      setOpenToast(true);
    } else if (status === 412) {
      setTimeout(() => {
        history.push("/preview");
      }, 2000);
      setBiddingPrice("");
      setShowAlertMessage(response);
      setAlertType("error");
      setOpenToast(true);
    } else {
      setShowAlertMessage(`${t("common:somethingWentWrong")}`);
      setAlertType("error");
      setOpenToast(true);
    }
  };

  const renderInput = (disabled: boolean) => {
    const isDisabled = disabled ? disabled : false;
    return (
      <>
        {auctionType === "Live" ? (
          <StyledInput
            type="number"
            min="0"
            name="preBiddingPrice"
            id="preBiddingPrice"
            placeholder={`${t("common:preview:preBidding:placeholderText")}`}
            disabled={isDisabled}
            onChange={(e: any) => {
              if (!isDisabled) {
                const isJPY = currency["code"] === "JPY" ? true : false;
                let lengthValue: any = isJPY ? 6 : 8;
                if (e.target?.value?.length <= lengthValue) {
                  handleChange(e);
                }
                return;
              }
            }}
            value={biddingPrice}
          />
        ) : (
          <StyledInput
            type="number"
            min="0"
            name="preBiddingPrice"
            id="preBiddingPrice"
            placeholder={`${t("common:preview:preBidding:placeholderText")}`}
            disabled={true}
          />
        )}
      </>
    );
  };

  const renderDisabledInput = (currencyCodeLabel: string) => {
    return (
      <div style={{ display: "flex", color: "white" }}>
        <StyledInput
          style={{ background: "white", border: "1px solid grey" }}
          name="preBiddingPrice"
          disabled={true}
          placeholder={`${t("common:preview:preBidding:placeholderText")}`}
          value={biddingPrice}
        />
        {renderCurrencyCode()}
      </div>
    );
  };

  const renderReadOnlyUserToast = () => {
    if (isReadOnlyUser) {
      setOpenReadOnlyUserToast(true);
    } else if (isAuctionUserId) {
      setOpenReadOnlyUserToast(true);
    } else {
      setOpenReadOnlyUserToast(false);
    }
    setTimeout(() => {
      setOpenReadOnlyUserToast(false);
    }, 3000);
  };

  const renderInputForParcelTypeDiamond = () => {
    return (
      <>
        {checkPrebidPrice() ? (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <Grid item xs={12}>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </Grid>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        ) : (
          renderDisabledInput("jpy")
        )}
      </>
    );
  };

  const renderInputForJewellery = () => {
    return (
      <>
        {checkPrebidPrice() ? (
          <StyledInputWrapper>
            <>
              {checkAuctionState() ? (
                <Grid item xs={12}>
                  {checkAuctionUserIdAndCount()
                    ? renderInput(false)
                    : renderInput(true)}
                </Grid>
              ) : (
                renderInput(true)
              )}
              {renderCurrencyCode()}
            </>
          </StyledInputWrapper>
        ) : (
          renderDisabledInput("jpyJewellery")
        )}
      </>
    );
  };

  const renderSubmitButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {auctionType === "Live" ? (
              <StyledButton
                type="submit"
                solid={true}
                onSubmit={handlePreBidSubmit}
              >
                {t("common:button:preBid")}
              </StyledButton>
            ) : (
              <StyledButton type="submit" solid={false} disabled={true}>
                {t("common:button:preBid")}
              </StyledButton>
            )}
            <StyledLabelConversion>
              {checkPrebidPrice() ? (
                <>
                  {preBidConversion}{" "}
                  {parcelType === "Jewellery"
                    ? ""
                    : preBidConversion !== ""
                      ? "/ct"
                      : ""}
                </>
              ) : (
                <>
                  {convertedPlaceHolderValue(
                    productData.currency,
                    productData.preBidPrice,
                    productData.exchangeRates[0].rate
                  )}
                  {parcelType === "Jewellery" ? "" : "/ct"}
                </>
              )}
            </StyledLabelConversion>
          </div>
        ) : (
          <StyledButton
            style={{ background: "grey" }}
            onClick={() => {
              renderReadOnlyUserToast();
            }}
          >
            {t("common:button:preBid")}
          </StyledButton>
        )}
      </>
    );
  };

  const renderCancelButtonTemplate = () => {
    return (
      <>
        {checkAuctionUserIdAndCount() ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <StyledCancelButton cancel={true} onClick={handleCancelSubmit}>
              {t("common:button:cancelPreBid")}
            </StyledCancelButton>
          </div>
        ) : (
          <StyledButton
            disabled={true}
            onClick={() => {
              renderReadOnlyUserToast();
            }}
          >
            {t("common:button:preBid")}
          </StyledButton>
        )}
      </>
    );
  };

  const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    //get user currency code form local storeage
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === "" ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * exchangeRate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${currencyCodeVal.symbol} ${conversions} (${userCurrencySymbol} ${conversion})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${userCurrencySymbol} ${conversions}`;
      }
    }
  };

  const convertedAmt = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any,
    carat: any,
    parcelType: any
  ) => {
    //get user currency code form local storeage
    let amt: any = price.replace(/[^0-9]/g, "") * carat;

    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      amt === undefined ||
      amt === null ||
      amt === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null ||
      parcelType === "Jewellery"
    ) {
      return "";
    } else {
      const rate = exchangeRate?.[0]?.["rate"] ?? 1;
      if (userCurrencyCode !== currencyCodeVal.code) {
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(amt * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return ` ${conversions} ${currencyCodeVal.symbol}  (${conversion}${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(amt)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };

  return (
    <StyledContainer
      container={true}
      spacing={0}
      className="PreBiddingContainer"
    >
      <Grid item={true} xs={12}>
        <StyledLabel>
          {productData.parcelType === "Jewellery"
            ? "Pre-bid"
            : `${t("common:preview:preBidding:preBidding")}`}
        </StyledLabel>
      </Grid>
      <Grid item={true} xs={12}>
        <form onSubmit={onFormSubmit} name="preBiddingForm" id="preBiddingForm">
          <StyledGrid item={true} xs={12}>
            <Grid item>
              {parcelType !== "Jewellery"
                ? renderInputForParcelTypeDiamond()
                : renderInputForJewellery()}
            </Grid>
            <StyledButtonWrapper item xl={9} lg={9} sm={9} md={9}>
              <Grid>
                <Grid item xs={12}>
                  {checkPrebidPrice() ? (
                    <>
                      {checkAuctionState() ? (
                        renderSubmitButtonTemplate()
                      ) : (
                        <StyledButton
                          style={{ background: "grey", color: "black" }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            renderReadOnlyUserToast();
                          }}
                        >
                          {t("common:button:preBid")}
                        </StyledButton>
                      )}
                    </>
                  ) : (
                    <>
                      {checkAuctionState() ? (
                        renderCancelButtonTemplate()
                      ) : (
                        <StyledCancelButton disabled={true}>
                          {t("common:button:cancelPreBid")}
                        </StyledCancelButton>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </StyledButtonWrapper>
          </StyledGrid>
        </form>
      </Grid>
      {openReadOnlyUserToast && (
        <AlertReadOnlyUser
          isReadOnlyUser={isReadOnlyUser}
          isSeller={isAuctionUserId}
        />
      )}
      {openToast && (
        <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
      )}
      {openPreBidPopUpModal && (
        <StyledModal open={openPreBidPopUpModal}>
          <PreBidConfirmationDialog
            biddingAmount={`${preBiddingPrice} ${parcelType === "Jewellery"
              ? ""
              : preBidConversion !== ""
                ? ""
                : "/ct"
              }`}
            conversionAmount={
              preBidConversion === ""
                ? ""
                : `(${preBidConversion}) ${parcelType === "Jewellery"
                  ? ""
                  : preBidConversion !== ""
                    ? "/ct"
                    : ""
                }`
            }
            onConfirmModal={preBidModalYesButtonPressed}
            onCancelModal={() => setOpenPreBidPopUpModal(false)}
            caratAmount={convertedAmt(
              currency,
              preBiddingPrice ?? 0,
              exchangeRates,
              Number(carat),
              parcelType
            )}
          />
        </StyledModal>
      )}
    </StyledContainer>
  );
};

export default React.memo(NewPreBiddingForm);
