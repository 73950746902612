import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Iprops{
    isOpen: any;
    onclose: ()=> void;
}
export default function ReadOnlyUserModal(props: Iprops) {
 let {isOpen, onclose} =props
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={isOpen}
        onClose={onclose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>You are the read only user.</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            autoFocus
            onClick={onclose}
            variant="contained"
            sx={{ width: "20%", mx: "auto" }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
