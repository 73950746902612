import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { submitFreePersonalized } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledModal } from "../../../common/materials/GenericStyleComponent";
import ConfirmationPopupWebsite from "../../products/ConfirmationPopup";
import { useHistory } from "react-router";

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`;
interface Iprops {
  open: boolean;
  handleClose: any;
}
export default function GoLivePopup(props: Iprops) {
  let { t } = useTranslation();
  const [userFName, setUserFName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompanyName, setUserCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const Auth = new AuthService();
  let businessDetails: any = localStorage.getItem("createWebDetails");
  businessDetails = JSON.parse(businessDetails);
  let history = useHistory();

  const handleClosePopup = () => {
    setConfirmationPopUp(false);
     props.handleClose();
     history.push("/");
  };

  const handleChange = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    setUserFName(businessDetails.businessName);
    setUserCompanyName(businessDetails.businessName);
    setAddress("demo");
    
    if (targeName === "email") {
      setUserEmail(targeValue);
    }
  };

  const handleOpenPopup = async (e: any) => {
    e.preventDefault();
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(userEmail.trim());
    if (
      userFName.trim() === "" &&
      userCompanyName.trim() === "" &&
      userEmail.trim() === "" &&
      address.trim() === ""
    ) {
      setValidationMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (userFName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onFName")}`);
    } else if (email !== true) {
      setValidationMessage(`${t("common:register:errorMessage:onEmail")}`);
    } else if (userCompanyName.trim() === "") {
      setValidationMessage(`${t("common:kycFrom:errorMessage:onCompanyName")}`);
    } else if (address.trim() === "") {
      setValidationMessage("Please fill Address");
    } else {
      setValidationMessage("");
      const obj = {
        name: userFName,
        email: userEmail,
        companyName: userCompanyName,
        address: address,
      };
      Auth.postRequest(
        submitFreePersonalized,
        obj,
        (status: number, response: any) => {
          if (status === 200) {
            setConfirmationPopUp(true);
          }
        }
      );
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Go Live!</DialogTitle>
        <IconButton
          edge="start"
          color="inherit"
          onClick={props.handleClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: "10px",
            top: "5px",
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <StyledMessage>{validationMessage}</StyledMessage>
          <DialogContentText>
            Enter your email address and we will get back to you with next
            steps.
          </DialogContentText>
          <Box
            component={"div"}
            style={{ display: "flex", gap: "10px", marginBottom: "10px" }}
          >
            <TextField
              required
              fullWidth
              name="email"
              id="name"
              label={t("common:login:labels:email")}
              type="text"
              variant="outlined"
              value={userEmail}
              onChange={handleChange}
            />
            <Button variant="contained" onClick={handleOpenPopup}>
              Subscribe
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      {confirmationPopUp === true && (
        <StyledModal open={confirmationPopUp} onClose={handleClosePopup}>
          <ConfirmationPopupWebsite {...props} closeModal={handleClosePopup} />
        </StyledModal>
      )}
    </div>
  );
}
