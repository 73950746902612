import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import {
  StyledImageCommonContainer,
} from "../StyledComponents";
import { StyledImageCommonComponent } from "../StyledComponents";

export const StyledFlexContainer = styled(Grid)`
  margin: 1em;
`;

export const StyledContainer = styled(Grid)`
  padding: 1em;
`;

export const StyledBorderContainer = styled.div`
  border-radius: 10%;
  cursor: pointer;
`;

export const StyledHeading = styled.div`
  font-family: "Playfair Display";
  font-size: 2.5em;
  position: absolute;
  top: 5%;
  word-break: white-space;
  padding: 0 2em 0 0.5em;
`;
export const StyledImageContainer = styled(StyledImageCommonContainer)`
  width: 100%;
  height: 500px;
  position: relative;
  @media (max-width: 1200px) {
    height: 30vh;
  }
`;
export const StyledImage = styled(StyledImageCommonComponent)`
  border-radius: 10%;
  width: 100%;
`;
export const StyledDescriptionContainer = styled.div`
  padding-top: 2em;
`;

export const StyledLink = styled.a`
  position: absolute;
  bottom: 5%;
  right: 5%;
  font-size: 1em;
  color: white;
`;
