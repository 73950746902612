import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import ButtonComp from "../../../common/elements/Button";
import {
  HeaderContainer,
  StyledLeftAlignedContainer,
  StyledRightAlignedContainer,
  StyledLogoContainer,
  HiddenButton,
} from "./StyledComponent";

import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import { Link, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Colors from "../../../common/materials/colors";
import Button from "../../../common/elements/Button";
import { LandingPagePlayFairFont } from "../StyledComponents";
import SignUpDialog, { SignUpModalOptions } from "../Dialogs/SignUp";
import LanguageSelector from "../../LandingPage/Header/LanguageSelector";
import styled from "styled-components";
import Cross from "../../LandingPage/Static/Icons/Cross";
import HeaderSignInMenu, { ZipangSignIn } from "./HeaderSignInMenu";
import { getEmailFromUrlParams } from "../../LandingPage/Header/SignIn";
import ScrollFix from "../../../components/ScrollFix/ScrollFix";
import ScrollSpy from "../../../components/ScrollFix/ScrollSpy";

const StyledLogo = styled.img`
  width: 70px;
  height: 60px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    width: 40px;
    display: flex;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 70px;
  }
`;

const StyledLinkContainer = styled(Link)`
  font-size: 1em;
  cursor: pointer;
  &:hover: {
    color: #d92b3c;
  }
`;

const WebHeader = ({
  handleSellDiamonds,
  handleBuyDiamonds,
  goToHome,
  handleSignUpModal,
}: any) => {
  const { t } = useTranslation();
  const history = useHistory();


  return (
    <HeaderContainer className="headerContainer">
      <ScrollFix />
      <ScrollSpy />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          boxShadow: "rgba(0,0,0,0.6) 0px 0px 56px 0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            marginLeft: "40px",
            cursor: "pointer",
          }}
        >
          <StyledLogo
            src="https://d1xs39ogteakr9.cloudfront.net/zipang/zipangLogo.png"
            alt="zipang image #1"
            onClick={() => {
              if (window.location.pathname.includes("team-page") || window.location.pathname.includes("zipangAuctionslist")) {
                history.push('/zipang');
              }
              window.location.hash = "TOP";
            }}
          />
        </div>
        <StyledLeftAlignedContainer
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            gap: "40px",
            marginTop: "10px",
          }}
        >
          <StyledLinkContainer
            onClick={() => {
              if (window.location.pathname.includes("team-page") || window.location.pathname.includes("zipangAuctionslist")) {
                history.push('/zipang');
              }
              window.location.hash = "UPCOMMING";
            }}
          >
            {t("common:zipangLanding:tab:upcomingAuc")}
          </StyledLinkContainer>
          <StyledLinkContainer
            onClick={() => {
              if (window.location.pathname.includes("team-page") || window.location.pathname.includes("zipangAuctionslist")) {
                history.push('/zipang');
              }
              window.location.hash = "SELLERS";
            }}
          >
            {t("common:zipangLanding:tab:sell")}
          </StyledLinkContainer>
          <StyledLinkContainer
            onClick={() => {
              if (window.location.pathname.includes("team-page")) {
                history.push('/zipang');
              }
              window.location.hash = "BUYER";
            }}
          >
            {t("common:zipangLanding:tab:buy")}
          </StyledLinkContainer>
          <StyledLinkContainer
            onClick={() => {
              history.push("/team-page");
            }}
          >
            {t("common:zipangLanding:tab:ourTeam")}
          </StyledLinkContainer>
          <StyledLinkContainer
            onClick={() => {
              if (window.location.pathname.includes("team-page")) {
                history.push('/zipang');
              }
              window.location.hash = "CONTACT";
            }}
          >
            {t("common:zipangLanding:tab:contact")}
          </StyledLinkContainer>
        </StyledLeftAlignedContainer>

        <HiddenButton
          id="hiddenButton"
          onClick={handleSignUpModal}
        ></HiddenButton>
        <StyledRightAlignedContainer
          style={{ display: "flex", alignItems: "center", gap: '10px' }}
        >
          <LanguageSelector isZipangLandingPage={true} />
          <HeaderSignInMenu />
          <ButtonComp onClick={handleBuyDiamonds} type="contained-register-red">
            {t("common:header:buttons:register")}
          </ButtonComp>
        </StyledRightAlignedContainer>
      </div>
    </HeaderContainer>
  );
};

const useStyles = makeStyles({
  container: {
    padding: "2em 1em",
    background: Colors.white,
    position: "relative",
  },
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
});

const handleMobileSignIn = (
  t: any,
  createDialog: any,
  classes: any,
  handleClose: any,
  showForgotPassword?: boolean,
  maxWidth?: string,
  setMessage?: any,
  onClose?: any
) => {
  createDialog({
    children: (
      <div className={classes.container}>
        <Button
          type=""
          onClick={handleClose}
          className={classes.cross}
          noMargin
        >
          <Cross filled />
        </Button>
        <p>
          {showForgotPassword ? null : (
            <Typography variant="h4" align="center">
              <LandingPagePlayFairFont>
                {t("common:signIntoYourAcc")}
              </LandingPagePlayFairFont>
            </Typography>
          )}
        </p>
        <ZipangSignIn
          handleClose={handleClose}
          setSuccessMessage={setMessage}
          showForgotPassword={showForgotPassword}
        />
      </div>
    ),
    maxWidth: maxWidth || "sm",
    fullWidth: true,
    open: true,
    onClose,
  });
};

export const ZipangForgotPassword = ({
  show,
  onClose,
  setMessage,
}: {
  setMessage?: any;
  onClose?: any;
  show?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createDialog, closeDialogue] = useDialog();

  const handleClose = () => {
    onClose && onClose();
    closeDialogue();
  };

  useEffect(() => {
    if (show) {
      handleMobileSignIn(
        t,
        createDialog,
        classes,
        handleClose,
        true,
        "xs",
        setMessage
      );
    } else {
      closeDialogue();
    }
  }, [show]);

  return null;
};

export const MobileSignIn = ({
  show,
  onClose,
}: {
  onClose?: any;
  show?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [createDialog, closeDialogue] = useDialog();

  const handleClose = () => {
    onClose && onClose();
    closeDialogue();
  };

  useEffect(() => {
    show &&
      handleMobileSignIn(
        t,
        createDialog,
        classes,
        handleClose,
        false,
        "sm",
        null,
        onClose
      );
  }, [show]);

  return null;
};
let mobileSignInShown = false;

export const MobileHeader = ({
  handleBuyDiamonds,
  goToHome,
  greyBg,
  handleSignUpModal,
}: any) => {
  const { t } = useTranslation();

  const [showSignIn, setShowSignIn] = useState(false);

  useEffect(() => {
    const userEmail = getEmailFromUrlParams();
    if (userEmail && !mobileSignInShown) {
      setShowSignIn(true);
      mobileSignInShown = true;
    }
  }, []);

  const handleClose = () => {
    setShowSignIn(false);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        position: "relative",
        height: "60px",
        zIndex: 1001,
        background: greyBg ? Colors.lightGrey : "default",
      }}
    >
      <HiddenButton
        id="hiddenButton"
        onClick={handleSignUpModal}
      ></HiddenButton>
      <MobileSignIn show={showSignIn} onClose={handleClose} />
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ position: "absolute", top: 0, width: "100%" }}
      >
        <StyledLogoContainer
          style={{
            display: "inline-block",
            padding: "1em 2em",
          }}
        >
          <span onClick={goToHome}>
            <img
              src="https://d1xs39ogteakr9.cloudfront.net/zipang/zipangLogo.png"
              alt="zipangLogo#1"
              style={{ height: "58px", width: "60px", cursor: "pointer" }}
              onClick={() => {
                window.location.hash = "TOP";
              }}
            />
          </span>
        </StyledLogoContainer>
        <ButtonComp onClick={handleBuyDiamonds} type="contained-red">
          {t("common:header:buttons:register")}
        </ButtonComp>
      </Grid>
    </Grid>
  );
};

// header
const ZipangHeader = ({ greyBg }: { greyBg?: boolean }) => {
  const isMobile = useMediaQuery("(max-width: 820px)");
  const [createDialog, closeDialog] = useDialog();

  const history = useHistory();

  const handleSellDiamonds = React.useCallback(() => {
    history.push("/sell");
  }, []);

  const goToHome = React.useCallback(() => {
    history.push("/");
  }, []);

  const handleBuyDiamonds = React.useCallback(
    (isMobile) => {
      createDialog({
        children: (
          <SignUpFormDialog
            isMobile={isMobile}
            onSignUp={closeDialog}
            close={closeDialog}
          />
        ),
        ...SignUpFormDialogOptions,
      });
    },
    [isMobile]
  );

  const handleSignUpModal = React.useCallback(
    (isMobile) => {
      createDialog({
        children: (
          <SignUpDialog
            onSignUp={() => handleBuyDiamonds(isMobile)}
            close={closeDialog}
          />
        ),
        ...SignUpModalOptions,
      });
    },
    [isMobile]
  );

  return isMobile ? (
    <div>
      <MobileHeader
        greyBg={greyBg}
        handleBuyDiamonds={handleBuyDiamonds}
        goToHome={goToHome}
        handleSignUpModal={handleSignUpModal}
      />
    </div>
  ) : (
    <WebHeader
      handleBuyDiamonds={handleBuyDiamonds}
      handleSellDiamonds={handleSellDiamonds}
      goToHome={goToHome}
      handleSignUpModal={handleSignUpModal}
    />
  );
};

export default ZipangHeader;
