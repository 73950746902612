import { url } from "../../common/config/app.endpoints";
import { ProductState } from "../../enums/productEnum";

export const getAuctionProductsQuery = (auctionId: string) => {
  const data = {
    filters: {
      and: [
        {
          match: "exact",
          name: "auctionId",
          value: auctionId,
        },
      ],
    },
    page: {
      start: 0,
      count: 5000, // Note: We are getting maximum number of records so no need to give multiple api calls.
      sort: {
        orders: [
          {
            property: "serialNo",
            direction: "ASC",
          },
        ],
      },
    },
  };
  return data;
};

export const onClickSignIn = () => {
  window.open(url, "_blank");
};

export const isProductStateAvailable = (productDetails: any) => {
  return (
    productDetails !== undefined &&
    productDetails?.state &&
    productDetails?.state === ProductState.AVAILABLE
  );
};

export const isSubItemsAvailable = (productDetails: any) => {
  return (
    productDetails &&
    productDetails?.subItems &&
    productDetails?.subItems != null &&
    productDetails?.subItems.length > 0
  );
};

export const getModifiedProductDetailsResponse = (response: any) => {
  let rows = [];
  for (let i = 0; i < response.subItems.length; i++) {
    let obj = response.subItems[i];
    for (let j = 0; j < obj.medias.length; j++) {
      rows.push(obj.medias[j]);
    }
  }

  let objModify = { ...response };
  objModify["medias"] = [...objModify.medias, ...rows];
  return objModify;
};
