import { faRubleSign } from "@fortawesome/free-solid-svg-icons";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducer";

const initialState = {
  loading: false,
  error: "",
  subDomainName: "",
  isChangePlanePopup: false,
};

const commonReactSlice = createSlice({
  name: "commonReact",
  initialState,
  reducers: {
    setSubDomainName(
      state: any,
      action: PayloadAction<{ subDomainName: string }>
    ) {
      state.subDomainName = action.payload.subDomainName;
    },
    resetSubDomainName(state: any, action: PayloadAction) {
      state.subDomainName = "";
    },
    setIsChangePlanePopup(
      state: any,
      action: PayloadAction<{ isChangePlanePopup: boolean }>
    ) {
      state.isChangePlanePopup = action.payload.isChangePlanePopup;
    },
    resetIsChangePlanePopup(state: any, action: PayloadAction) {
      state.resetIsChangePlanePopup = false;
    },
  },
  extraReducers: (builder: any) => {},
});

export const {
  setSubDomainName,
  setIsChangePlanePopup,
  resetSubDomainName,
  resetIsChangePlanePopup,
} = commonReactSlice.actions;

export default commonReactSlice.reducer;

// selectors
export const selectcommaonReactSliceLoading = (state: RootState) =>
  state.commonReactSlice.loading;
  
export const isChangePlanModalOpen = (state: RootState) =>
  state.commonReactSlice.isChangePlanePopup;

export const selectSubDomainName = (state: RootState) =>
  state.commonReactSlice.subDomainName;