import React, { useEffect, useState } from "react";
import AppLayout from "../layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import { useTranslation } from "react-i18next";
import TabSection from "./components/TabSection";
import { Grid } from "@material-ui/core";
import { StyledMypageGrid } from "./MypageStyle";
import SellMypage from "./SellMypage";
import BuyMypage from "./BuyMypage";
import {
  resetmySellpageItemPerPage,
  resetmypageItemPerPage,
} from "../../redux/slice/commanPagination/commanPaginationSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { useHistory } from "react-router";

interface IPendingProductProps extends IRouterComponentProps {
  t: any;
}

const Mypage = (props: IPendingProductProps) => {
  let { t } = useTranslation();
  let [isSelectedTab, setIsSelectedTab] = useState<boolean>(true);
  let history = useHistory();
  const dispatch: AppDispatch = useDispatch();

  const tabClick = (val: string) => {
    if (val === "1") {
      history.replace("/my-page");
      setIsSelectedTab(true);
    } else {
      history.replace("/my-page/buypage");
      setIsSelectedTab(false);
    }
  };

  useEffect(() => {
    if (history.location.pathname !== "/my-page/buypage") {
      dispatch(resetmySellpageItemPerPage());
      setIsSelectedTab(true);
    } else {
      setIsSelectedTab(false);
      dispatch(resetmypageItemPerPage());
    }
  },[]);

  const renderSellandBuyTab = () => {
    return (
      <StyledMypageGrid style={{ marginTop: "10px" }}>
        <Grid item={true} xs={12}>
          <TabSection
            t={t}
            tabClick={tabClick}
            selectedTab={isSelectedTab ? "1" : "2"}
          />
        </Grid>
      </StyledMypageGrid>
    );
  };

  return (
    <AppLayout {...props}>
      <div>
        {renderSellandBuyTab()}
        {isSelectedTab ? <SellMypage /> : <BuyMypage />}
      </div>
    </AppLayout>
  );
};

export default Mypage;
