import React from "react";
import { Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { BoxContainer, ContainerProfile, ProfileDescription, ProfileField } from "./OurTeamStyles";
import { useTranslation } from "react-i18next";

const StyledBoxContainer = styled(Grid)`
  padding: 4em;
  color: black;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 1em;
  }
`;

function OurTeamGrid() {
  let { t } = useTranslation();
  return (
    <>
      {" "}
      <Grid
        container
        style={{
          marginTop: "3em",
          marginBottom: "2em",
          fontFamily: "Calibre-Regular"
        }}
      >
        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: '5%'
          }}
        >
          <Typography variant="h4" fontWeight={700} style={{ fontFamily: 'Playfair Display' }}>
            {t("common:zipangLanding:ourTeam:ourTeamheading")}
          </Typography>
        </div>

        <div
          style={{
            color: "black",
            textAlign: "left",
            alignItems: "center",
            justifyContent: "left",
            width: "100%",
            display: "flex",
            marginBottom: "1em",
            marginLeft: '5%'
          }}
        >
          <Typography variant="h5" fontWeight={600} color={'#D92B3C'} style={{ fontFamily: 'Playfair Display' }}>
            {t("common:zipangLanding:ourTeam:ourteamSubHeading")}
          </Typography>
        </div>

        <StyledBoxContainer
          container
          xs={12}
          sx={{
            py: 0,
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <BoxContainer>
              <ContainerProfile>
                <ProfileField style={{
                  background: "url(/images/team_member/Shobit_Suchanti.jpeg)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}>
                </ProfileField>
                <Typography variant="h5" fontWeight={700} color={'black'}>
                  {t("common:zipangLanding:ourTeam:team:member1:name")}
                </Typography>
                <h4 style={{ color: "#D92B3C" }}>{t("common:zipangLanding:ourTeam:team:member1:title")}</h4>
                <ProfileDescription>
                  {t("common:zipangLanding:ourTeam:team:member1:description")}
                </ProfileDescription>
              </ContainerProfile>
            </BoxContainer>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <BoxContainer>
              <ContainerProfile>
                <ProfileField style={{
                  background: "url(/images/team_member/HARUKA.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}>
                </ProfileField>
                <Typography variant="h5" fontWeight={700} color={'black'}>
                  {t("common:zipangLanding:ourTeam:team:member2:name")}
                </Typography>
                <h4 style={{ color: "#D92B3C" }}>{t("common:zipangLanding:ourTeam:team:member2:title")}</h4>
                <ProfileDescription>
                  {t("common:zipangLanding:ourTeam:team:member2:description")}
                </ProfileDescription>
              </ContainerProfile> */}
          {/* Add more profiles here if needed */}
          {/* </BoxContainer> */}
          {/* </Grid> */}
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <BoxContainer>
              <ContainerProfile>
                <ProfileField style={{
                  background: "url(/images/team_member/Sharthak.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}>
                </ProfileField>
                <Typography variant="h5" fontWeight={700} color={'black'}>
                  {t("common:zipangLanding:ourTeam:team:member3:name")}
                </Typography>
                <h4 style={{ color: "#D92B3C" }}>{t("common:zipangLanding:ourTeam:team:member3:title")}</h4>
                <ProfileDescription>
                  {t("common:zipangLanding:ourTeam:team:member3:description")}
                </ProfileDescription>
              </ContainerProfile>
              {/* Add more profiles here if needed */}
            </BoxContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <BoxContainer>
              <ContainerProfile>
                <ProfileField style={{
                  background: "url(/images/team_member/yuko.png)",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                }}>
                </ProfileField>
                <Typography variant="h5" fontWeight={700} color={'black'}>
                  {t("common:zipangLanding:ourTeam:team:member4:name")}
                </Typography>
                <h4 style={{ color: "#D92B3C" }}>{t("common:zipangLanding:ourTeam:team:member4:title")}</h4>
                <ProfileDescription>
                  {t("common:zipangLanding:ourTeam:team:member4:description")}
                </ProfileDescription>
              </ContainerProfile>
            </BoxContainer>
          </Grid>
        </StyledBoxContainer>
      </Grid>
    </>
  );
}

export default OurTeamGrid;
