import {
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../Static/Icons/Logo";
import Cross from "../Static/Icons/Cross";
import {
  LandingPageOpenSansFont,
  LandingPagePlayFairFont,
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../StyledComponents";

import ButtonComp from "../../../common/elements/Button";
import { DialogOption } from "../../../common/elements/Dialog/Dialog";

const typeStyles = makeStyles({
  h5: {
    margin: "1em 0",
  },
});

const useStyles = makeStyles({
  cross: {
    position: "absolute",
    right: "1em",
    top: "1em",
    margin: 0,
  },
  container: {
    position: "relative",
  },
  rightContainer: {
    padding: "4em 0",
    maxWidth: "400px",
  },
  flexWrap: {
    flexWrap: "nowrap",
  },
});

export const SignUpModalOptions: DialogOption = {
  maxWidth: "md",
  open: false,
};

const SignUpDialog = ({
  onSignUp,
  close,
}: {
  onSignUp?: () => void;
  close: () => void;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();

  const classes = useStyles();
  const typeClasses = typeStyles();

  return (
    <Grid
      container
      justifyContent="center"
      classes={classes}
      className={isMobile ? "" : classes.flexWrap}
    >
      <Grid>
        <StyledImageCommonContainer
          style={
            isMobile ? { height: "420px" } : { height: "410px", width: "350px" }
          }
        >
          <StyledImageCommonComponent
            src="https://d1xs39ogteakr9.cloudfront.net/MyAuctions-trial-popup_image.png"
            style={{ height: "100%" }}
          />
        </StyledImageCommonContainer>
      </Grid>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        className={classes.rightContainer}
      >
        <ButtonComp onClick={close} className={classes.cross} type="" noMargin>
          <Cross filled />
        </ButtonComp>
        <Logo />
        <Grid item>
          <Container>
            <Typography classes={typeClasses} variant="h5" align="center">
              <LandingPagePlayFairFont>
                {t("common:newLandingPage:signUp:heading")}
              </LandingPagePlayFairFont>
            </Typography>
            <Typography classes={typeClasses} paragraph align="center">
              <LandingPageOpenSansFont>
                {t("common:newLandingPage:signUp:subHeading")}
              </LandingPageOpenSansFont>
            </Typography>
          </Container>
        </Grid>
        <Grid item>
          <ButtonComp widePadding type="contained-black" onClick={onSignUp}>
            {t("common:newLandingPage:signUp:buttonText")}
          </ButtonComp>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignUpDialog;
