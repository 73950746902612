import React, { useState } from "react";

const ArrowButton = ({ onClick }: { onClick: any }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const handleMouseOver = () => {
    setMouseOver(!mouseOver);
  };
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="41"
      viewBox="0 0 68 41"
      style={{ border: "1px solid #0a4e9a", background: "#0a4e9a" }}
    >
      <g id="Group_102" data-name="Group 102" transform="translate(-532 -860)">
        <rect
          id="Rectangle_670"
          data-name="Rectangle 670"
          width="68"
          height="41"
          transform="translate(532 860)"
          fill="#0a4e9a"
        />
        <g
          id="Group_103"
          data-name="Group 103"
          transform="translate(558.271 876.458)"
        >
          <line
            id="Line_8"
            data-name="Line 8"
            x2="14.81"
            transform="translate(0 4.542)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="1.78"
          />
          <path
            id="Path_161"
            data-name="Path 161"
            d="M197.832,1364.855l4.542,4.542-4.542,4.542"
            transform="translate(-187.564 -1364.855)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="1.78"
          />
        </g>
      </g>
    </svg>
  );
};

export default ArrowButton;
