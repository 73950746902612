import React from "react";

import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../../common/config/config";
import useDeviceDimensions from "../../../components/shared/useDeviceDimensions";


interface IProps {
  setIsVerfied: (isVerified: boolean) => void;
}

const ReCaptcha = (props: IProps) => {
  const { setIsVerfied } = props;
  const { isExtraSmallDevice } = useDeviceDimensions();

  //enable this code when you are running app with redux
  // const { EN } = LanguageConstants;
  // const { CODE_SMALL_EN, CODE_SMALL_JP } = LanguageCode;

  // const selectedLanguage = useSelector((state: RootState) =>
  //   selectAppLanguage(state)
  // );

  const captchaOnChange = (value: any) => {
    if (value === "" || value === null) {
      setIsVerfied(false);
    } else {
      setIsVerfied(true);
    }
  };

  return (
    <ReCAPTCHA
      sitekey={recaptchaKey}
      onChange={captchaOnChange}
      tabindex={2}
      hl={localStorage.getItem("language") === "EN" ? "en" :localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "ja"}
      size={isExtraSmallDevice ? "compact" : "normal"}
    />
  );
};

export default ReCaptcha;
