import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import { buttonGradient } from "../../common/materials/LinearGradient";

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
  padding: 50px 70px;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    padding: 5px 0px 30px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 8px;
  }
  .loading {
    z-index: 1;
  }
`;

const StyledActionContainer = styled(Grid)`
  .renderShareBtnContainer,
  .renderWatchBtnContainer {
    min-height: 40px;
  }
`;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;
const StyledBackBtnContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  .spanText {
    font-size: 1em;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;
const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledProductCarousalColumn = styled(Grid)`
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 5px;
  }
  @media (width: 768px) {
    height: 500px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

const StyledArrowIconsSubItem = styled.img`
  width: 30px;
  height: 30px;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
`;

const StyledLabel = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  // padding-left: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 1em;
  }
`;

const StyledArrowWrapper = styled(Grid)`
  display: flex;
  justify-content: right;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  padding: 0.5em;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  // background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
const StyledButtonContainer = styled.div`
  text-align: center;
  margin-top: -50px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-top: -26px;
  }
`;

const StyledTooltipContainer = styled(Grid)``;

const StyledTooltipText = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 12px;
  padding: 0 5px;
`;

const StyledBidContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.darkGrey};
  .pb-20 {
    padding-bottom: 20px;
  }
`;

export {
  StyledLayoutGrid,
  StyledActionContainer,
  StyledTitleContainer,
  StyledBackBtnContainer,
  StyledBackBtn,
  StyledBrandName,
  StyledProductName,
  StyledProductCarousalColumn,
  StyledItemDesc,
  StyledGridWrapper,
  StyledContentWrapper,
  StyledArrowIcons,
  StyledArrowIconsID,
  StyledArrowIconsSubItem,
  StyledName,
  StyledLabel,
  StyledWrapper,
  StyledArrowWrapper,
  StyledWrapperID,
  StyledButton,
  StyledButtonContainer,
  StyledTooltipContainer,
  StyledTooltipText,
  StyledBidContainer,
};
