import { auctionProductUrl, getProductDetailUrl, jewelleryProductDetailsUrl, jewelleryProductViewUrl, productUrl } from "../../../common/config/app.endpoints";
import { ProductCategory, ProductCategorySmalls } from "../../../enums/productEnum";
import AuthService from "../../auth/AuthService";

const Auth = new AuthService();

export const getProductDetails = async (requestObj: any) => {
  const { productId, category, isPublicApi } = requestObj;
  const isEndpointPublic = isPublicApi ? isPublicApi : false;
  let url = "";
 
  if (category === ProductCategorySmalls.DIAMONDS) {
    // For both diamond and parcel item
    url = `${productUrl}/${productId}/detail`;
    if (isEndpointPublic) {
      url = `${getProductDetailUrl}/${productId}/detail`;
    }
  } else if (category === ProductCategorySmalls.JEWELLERY) {
    // For jewellery
    url = `${jewelleryProductDetailsUrl}/${productId}/detail`;
    if (isEndpointPublic) {
      url = `${getProductDetailUrl}/jewellery/${productId}/detail`;
    }
  }
  const response = await new Promise(async (res) => {
    const callBack = (status: any, data: any) => {
      res({ status, data });
    };
    Auth.getRequest(`${url}`, callBack);
  });
  const { data, status } = response as { data: any; status: number };
  return { response: data, status: status };
};


export const getAuctionProducts = async (requestObj: any) => {
   const { auctionId, query, category } = requestObj;
  let url = "";
  if (category === ProductCategory.DIAMONDS) {
    url = `${auctionProductUrl}?auctionId=${auctionId}`;
  } else if (category === ProductCategory.JEWELLERY) {
    url = `${jewelleryProductViewUrl}?auctionId=${auctionId}`;
  }
 const response = await new Promise(async (res) => {
   const callBack = (status: any, data: any) => {
     res({ status, data });
   };
   Auth.postRequest(url, query, callBack);
 });
 const { data, status } = response as { data: any; status: number };
 return { response: data, status: status };
};
