import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import { buttonGradient } from "../../common/materials/LinearGradient";
import { useTranslation } from "react-i18next";
import { b, domainName } from "../../common/config/app.endpoints";

const StyledComponent = styled(Grid)`
  outline: none;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 1em 1em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 500px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 444px;
  }
`;

const StyledTextWrapper = styled.div`
  font-size: 1.6em;
  padding-bottom: 1em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 0.5em 0.5em;
  padding-top: 10px;
  text-align: center;
  border-radius: 10px;
  font-family: Calibre-SemiBold;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.7em;
  }
`;

const StyledDivbutton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

interface IProps {
  subdomainName: any;
  updateValue: any;
}
let localization: string = "";

const ChangePlaneRediractionModal =  React.forwardRef((Props: IProps, ref) => {
  let { subdomainName } = Props;
  const { t } = useTranslation();


  const genSubDomainURLHandler = async (isChangePlanbutton: boolean) => {
    let splitUrl = domainName.replace(`${"//"}`, `//${subdomainName}.`);
    b.baseUrl = `${splitUrl}`;
    let language = localStorage.getItem("language");
    if (language === "EN") {
      localization = "en";
    } else if (language === "zh_CN") {
      localization = "zh_CN";
    } else {
      localization = "jp";
    }
    let url: any = "";
    if (subdomainName !== undefined) {
      let splitUrl = domainName.replace(`${"//"}`, `//${subdomainName}.`);
      b.baseUrl = `${splitUrl}`;
    } else {
      b.baseUrl = `${domainName}`;
    }

    if (isChangePlanbutton) {
      url = `${b.baseUrl}/${subdomainName}?changePlan=isChangePlan&email=${localStorage.getItem('changePlanEmail')}`;
    } else {
      url = `${b.baseUrl}/${subdomainName}?changePlan=notChangePlan&email=${localStorage.getItem('changePlanEmail')}`;
    }
    window.location.replace(url);
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledParticipateWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {t("common:register:subdoaminRegisterModal")}
            <br />
            {t("common:register:secondSubdoaminRegisterModal")}
          </StyledTextWrapper>
          <StyledDivbutton>
            <StyledButton onClick={() => genSubDomainURLHandler(true)}>
              CHANGE PLAN
            </StyledButton>
            <StyledButton onClick={() => genSubDomainURLHandler(false)}>
              CONTINUE WITH {subdomainName.toUpperCase()}
            </StyledButton>
          </StyledDivbutton>
        </StyledParticipateWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
});

export default ChangePlaneRediractionModal;
