import styled from "styled-components";

const StyledLegendField = styled.div`
  color: gray;
  display: flex;
  border: 1px solid lightgray;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    justify-content: space-between;
  }
`;

const StyledFieldLegend = styled.div`
  color: black;
  // margin-left: 15px;
  padding: 0.4em;
  border-right: 1px solid lightgray;
  width: 72px;
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 150px;
  }
  font-weight: bold;
  @media (width: 320px) {
    font-size: 1em;
  }
`;

const StyledTextWrapper = styled.div`
  padding: 0.2em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 45%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export { StyledTextWrapper, StyledFieldLegend, StyledLegendField };
