import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../reducer";

interface annualApiResponse {
  auctionSubdomain: "",
  auctionType: "",
  auctionUserId: "",
  category: "",
  description: "",
  name: "",
  perId: "",
  previewStartDate: "",
  productCount: "",
  resumeDateTime: "",
  startDate: "",
  state: "",
  productDetails: [],
}

const initialState = {
  loading: false,
  error: "",
  isRequest: false,
  isGridView: true,
  isJwlGridView: true,
  isTenderGridView: true,
  isTenderJwlGridView: true,
  isLiveGridView: true,
  isLiveJwlGridView: true,
  annualAuctionData: {
    auctionUserId: "",
    perId: "",
    startDate: "",
    category: "",
  },
  jwlAnnualAuctionData: {
    auctionUserId: "",
    perId: "",
    startDate: "",
    category: "",
  },
  isNotificationApiCall: false,
  notificationLive: [],
  notificationLastMonthLive: [],
  colorFilter: "ALL",

  jewellerySelectAll: false,
  gridProductIndexes: [],

  jewelleryAllProductSelectAll: false,
  gridAllProductProductIndexes: [],

  jewelleryWatchlistSelectAll: false,
  gridWatchlistProductIndexes: [],

  allProductSelectAll: false,
  allProdductGridProductIndexes: [],
  diamondSelectAll: false,
  diamondgridProductIndexes: [],

  diamondAllProductSelectAll: false,
  diamondgridAllProductProductIndexes: [],

  diamondyWatchlistSelectAll: false,
  diamondgridWatchlistProductIndexes: [],

  tenderjewellerySelectAll: false,
  tendergridProductIndexes: [],

  tenderjewelleryAllProductSelectAll: false,
  tendergridAllProductProductIndexes: [],

  tenderjewelleryWatchlistSelectAll: false,
  tendergridWatchlistProductIndexes: [],

  tenderallProductSelectAll: false,
  tenderallProdductGridProductIndexes: [],
  tenderdiamondSelectAll: false,
  tenderdiamondgridProductIndexes: [],

  tenderdiamondAllProductSelectAll: false,
  tenderdiamondgridAllProductProductIndexes: [],

  tenderdiamondyWatchlistSelectAll: false,
  tenderdiamondgridWatchlistProductIndexes: [],
  productDetails: [],
  allPreview: [],
};

const commanAnnualSlice = createSlice({
  name: "annual",
  initialState,
  reducers: {
    setAllPreview(state: any, action: PayloadAction<{ preview: any }>) {
      state.allPreview = action.payload.preview;
    },
    resetAllPreview(state: any, action: PayloadAction) {
      state.allPreview = [];
    },
    setjewellerySelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.jewellerySelectAll = action.payload.isSelected;
    },
    resetjewellerySelectAll(state: any, action: PayloadAction) {
      state.jewellerySelectAll = false;
    },
    setGridProductIndexes(state: any, action: PayloadAction<{ indexes: any }>) {
      state.gridProductIndexes = action.payload.indexes;
    },
    resetGridProductIndexes(state: any, action: PayloadAction) {
      state.gridProductIndexes = [];
    },

    //annual product jewellery
    setjewelleryAllProductSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.jewelleryAllProductSelectAll = action.payload.isSelected;
    },
    resetjewelleryAllProductSelectAll(state: any, action: PayloadAction) {
      state.jewelleryAllProductSelectAll = false;
    },

    setGridProductAllProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.gridAllProductProductIndexes = action.payload.indexes;
    },
    resetGridProductAllProductIndexes(state: any, action: PayloadAction) {
      state.gridAllProductProductIndexes = [];
    },

    //annual watchlist product jewellery
    setjewelleryWatchlistSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.jewelleryWatchlistSelectAll = action.payload.isSelected;
    },
    resetjewelleryWatchlistSelectAll(state: any, action: PayloadAction) {
      state.jewelleryWatchlistSelectAll = false;
    },
    setGridProductWatchlistIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.gridWatchlistProductIndexes = action.payload.indexes;
    },
    resetGridProductWatchlistIndexes(state: any, action: PayloadAction) {
      state.gridWatchlistProductIndexes = [];
    },

    // annual product value Diamond mybids
    setDiamondSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.diamondSelectAll = action.payload.isSelected;
    },
    resetDiamondSelectAll(state: any, action: PayloadAction) {
      state.diamondSelectAll = false;
    },
    setDiamondGridProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.diamondgridProductIndexes = action.payload.indexes;
    },
    resetDiamondGridProductIndexes(state: any, action: PayloadAction) {
      state.diamondgridProductIndexes = [];
    },

    //annual product Diamond
    setDiamondAllProductSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.diamondAllProductSelectAll = action.payload.isSelected;
    },
    resetDiamondAllProductSelectAll(state: any, action: PayloadAction) {
      state.diamondAllProductSelectAll = false;
    },

    setDiamondGridProductAllProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.diamondgridAllProductProductIndexes = action.payload.indexes;
    },
    resetDiamondGridProductAllProductIndexes(
      state: any,
      action: PayloadAction
    ) {
      state.diamondgridAllProductProductIndexes = [];
    },

    //annual watchlist product jewellery
    setDiamondWatchlistSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.diamondyWatchlistSelectAll = action.payload.isSelected;
    },
    resetDiamondWatchlistSelectAll(state: any, action: PayloadAction) {
      state.diamondyWatchlistSelectAll = false;
    },
    setDiamondGridProductWatchlistIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.diamondgridWatchlistProductIndexes = action.payload.indexes;
    },
    resetDiamondGridProductWatchlistIndexes(state: any, action: PayloadAction) {
      state.diamondgridWatchlistProductIndexes = [];
    },

    //tender selector

    setTenderjewellerySelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderjewellerySelectAll = action.payload.isSelected;
    },
    resetTenderjewellerySelectAll(state: any, action: PayloadAction) {
      state.tenderjewellerySelectAll = false;
    },
    setTenderGridProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tendergridProductIndexes = action.payload.indexes;
    },
    resetTenderGridProductIndexes(state: any, action: PayloadAction) {
      state.tendergridProductIndexes = [];
    },

    //annual product jewellery
    setTenderjewelleryAllProductSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderjewelleryAllProductSelectAll = action.payload.isSelected;
    },
    resetTenderjewelleryAllProductSelectAll(state: any, action: PayloadAction) {
      state.tenderjewelleryAllProductSelectAll = false;
    },

    setTenderGridProductAllProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tendergridAllProductProductIndexes = action.payload.indexes;
    },
    resetTenderGridProductAllProductIndexes(state: any, action: PayloadAction) {
      state.tendergridAllProductProductIndexes = [];
    },

    //annual watchlist product jewellery
    setTenderjewelleryWatchlistSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderjewelleryWatchlistSelectAll = action.payload.isSelected;
    },
    resetTenderjewelleryWatchlistSelectAll(state: any, action: PayloadAction) {
      state.tenderjewelleryWatchlistSelectAll = false;
    },
    setTenderGridProductWatchlistIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tendergridWatchlistProductIndexes = action.payload.indexes;
    },
    resetTenderGridProductWatchlistIndexes(state: any, action: PayloadAction) {
      state.tendergridWatchlistProductIndexes = [];
    },

    // annual product value Diamond mybids
    setTenderDiamondSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderdiamondSelectAll = action.payload.isSelected;
    },
    resetTenderDiamondSelectAll(state: any, action: PayloadAction) {
      state.tenderdiamondSelectAll = false;
    },
    setTenderDiamondGridProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tenderdiamondgridProductIndexes = action.payload.indexes;
    },
    resetTenderDiamondGridProductIndexes(state: any, action: PayloadAction) {
      state.tenderdiamondgridProductIndexes = [];
    },

    //annual product Diamond
    setTenderDiamondAllProductSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderdiamondAllProductSelectAll = action.payload.isSelected;
    },
    resetTenderDiamondAllProductSelectAll(state: any, action: PayloadAction) {
      state.tenderdiamondAllProductSelectAll = false;
    },

    setTenderDiamondGridProductAllProductIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tenderdiamondgridAllProductProductIndexes = action.payload.indexes;
    },
    resetTenderDiamondGridProductAllProductIndexes(
      state: any,
      action: PayloadAction
    ) {
      state.tenderdiamondgridAllProductProductIndexes = [];
    },

    //annual watchlist product jewellery
    setTenderDiamondWatchlistSelectAll(
      state: any,
      action: PayloadAction<{ isSelected: boolean }>
    ) {
      state.tenderdiamondyWatchlistSelectAll = action.payload.isSelected;
    },
    resetTenderDiamondWatchlistSelectAll(state: any, action: PayloadAction) {
      state.tenderdiamondyWatchlistSelectAll = false;
    },
    setTenderDiamondGridProductWatchlistIndexes(
      state: any,
      action: PayloadAction<{ indexes: any }>
    ) {
      state.tenderdiamondgridWatchlistProductIndexes = action.payload.indexes;
    },
    resetTenderDiamondGridProductWatchlistIndexes(
      state: any,
      action: PayloadAction
    ) {
      state.tenderdiamondgridWatchlistProductIndexes = [];
    },
    setColorFilter(state: any, action: PayloadAction<{ color: boolean }>) {
      state.colorFilter = action.payload.color;
    },
    resetColorFilter(state: any, action: PayloadAction) {
      state.colorFilter = "ALL";
    },
    setNotificationApiCall(
      state: any,
      action: PayloadAction<{ response: boolean }>
    ) {
      state.isNotificationApiCall = action.payload.response;
    },
    resetNotificationApiCall(state: any, action: PayloadAction) {
      state.isNotificationApiCall = false;
    },
    setNotificationLive(state: any, action: PayloadAction<{ response: any }>) {
      state.notificationLive = action.payload.response;
    },
    resetNotificationLive(state: any, action: PayloadAction) {
      state.isNotificationApiCall = [];
    },
    setNotificationLastMonthLive(
      state: any,
      action: PayloadAction<{ response: boolean }>
    ) {
      state.notificationLastMonthLive = action.payload.response;
    },
    resetNotificationLastMonthLive(state: any, action: PayloadAction) {
      state.notificationLastMonthLive = [];
    },
    setAnnualAuctionData(state: any, action: PayloadAction<{ response: any }>) {
      state.annualAuctionData = action.payload.response;
    },
    resetAnnualAuctionData(state: any, action: PayloadAction) {
      state.annualAuctionData = {
        auctionUserId: "",
        perId: "",
        startDate: "",
        category: "",
      };
    },
    setJwlAnnualAuctionData(
      state: any,
      action: PayloadAction<{ response: any }>
    ) {
      state.jwlAnnualAuctionData = action.payload.response;
    },
    resetJwlAnnualAuctionData(state: any, action: PayloadAction) {
      state.jwlAnnualAuctionData = {
        auctionUserId: "",
        perId: "",
        startDate: "",
        category: "",
      };
    },

    setIsRequest(
      state: any,
      action: PayloadAction<{ isRequestEmail: boolean }>
    ) {
      state.isRequest = action.payload.isRequestEmail;
    },
    resetIsRequest(state: any, action: PayloadAction) {
      state.isRequest = false;
    },
    //Diamond Annual
    setIsGridView(state: any, action: PayloadAction<{ gridView: boolean }>) {
      state.isGridView = action.payload.gridView;
    },
    resetIsGridView(state: any, action: PayloadAction) {
      state.isGridView = true;
    },
    //jewellery annual
    setJwlIsGridView(state: any, action: PayloadAction<{ gridView: boolean }>) {
      state.isJwlGridView = action.payload.gridView;
    },
    resetJwlIsGridView(state: any, action: PayloadAction) {
      state.isJwlGridView = false;
    },
    //Tender Diamond
    setTenderIsGridView(
      state: any,
      action: PayloadAction<{ gridView: boolean }>
    ) {
      state.isTenderGridView = action.payload.gridView;
    },
    resetTenderIsGridView(state: any, action: PayloadAction) {
      state.isTenderGridView = true;
    },
    //Tender jewellery
    setTenderJwlIsGridView(
      state: any,
      action: PayloadAction<{ gridView: boolean }>
    ) {
      state.isTenderJwlGridView = action.payload.gridView;
    },
    resetTenderJwlIsGridView(state: any, action: PayloadAction) {
      state.isTenderJwlGridView = true;
    },
    // live auction
    setLiveIsGridView(
      state: any,
      action: PayloadAction<{ isLiveGridView: boolean }>
    ) {
      state.isLiveGridView = action.payload.isLiveGridView;
    },
    resetLiveIsGridView(state: any, action: PayloadAction) {
      state.isLiveGridView = true;
    },
    //Tender jewellery
    setLiveJwlIsGridView(
      state: any,
      action: PayloadAction<{ isLiveJwlGridView: boolean }>
    ) {
      state.isLiveJwlGridView = action.payload.isLiveJwlGridView;
    },
    resetLiveJwlIsGridView(state: any, action: PayloadAction) {
      state.isLiveJwlGridView = true;
    },
  },
  extraReducers: (builder: any) => { },
});

export const {
  setIsRequest,
  resetIsRequest,
  setLiveIsGridView,
  resetLiveIsGridView,
  setLiveJwlIsGridView,
  resetLiveJwlIsGridView,
  setIsGridView,
  resetIsGridView,
  setJwlIsGridView,
  resetJwlIsGridView,
  setTenderIsGridView,
  resetTenderIsGridView,
  setTenderJwlIsGridView,
  resetTenderJwlIsGridView,
  setAnnualAuctionData,
  resetAnnualAuctionData,
  setJwlAnnualAuctionData,
  resetJwlAnnualAuctionData,
  setNotificationApiCall,
  resetNotificationApiCall,
  setNotificationLive,
  resetNotificationLive,
  setNotificationLastMonthLive,
  resetNotificationLastMonthLive,
  resetColorFilter,
  setColorFilter,

  setjewellerySelectAll,
  resetjewellerySelectAll,

  setGridProductIndexes,
  resetGridProductIndexes,

  setjewelleryAllProductSelectAll,
  resetjewelleryAllProductSelectAll,

  setGridProductAllProductIndexes,
  resetGridProductAllProductIndexes,

  setjewelleryWatchlistSelectAll,
  resetjewelleryWatchlistSelectAll,

  setGridProductWatchlistIndexes,
  resetGridProductWatchlistIndexes,

  setDiamondSelectAll,
  resetDiamondSelectAll,
  setDiamondGridProductIndexes,
  resetDiamondGridProductIndexes,

  setDiamondAllProductSelectAll,
  resetDiamondAllProductSelectAll,
  setDiamondGridProductAllProductIndexes,
  resetDiamondGridProductAllProductIndexes,

  setDiamondWatchlistSelectAll,
  resetDiamondWatchlistSelectAll,
  setDiamondGridProductWatchlistIndexes,
  resetDiamondGridProductWatchlistIndexes,


  setTenderjewellerySelectAll,
  resetTenderjewellerySelectAll,

  setTenderGridProductIndexes,
  resetTenderGridProductIndexes,

  setTenderjewelleryAllProductSelectAll,
  resetTenderjewelleryAllProductSelectAll,

  setTenderGridProductAllProductIndexes,
  resetTenderGridProductAllProductIndexes,

  setTenderjewelleryWatchlistSelectAll,
  resetTenderjewelleryWatchlistSelectAll,

  setTenderGridProductWatchlistIndexes,
  resetTenderGridProductWatchlistIndexes,



  setTenderDiamondSelectAll,
  resetTenderDiamondSelectAll,
  setTenderDiamondGridProductIndexes,
  resetTenderDiamondGridProductIndexes,

  setTenderDiamondAllProductSelectAll,
  resetTenderDiamondAllProductSelectAll,
  setTenderDiamondGridProductAllProductIndexes,
  resetTenderDiamondGridProductAllProductIndexes,

  setTenderDiamondWatchlistSelectAll,
  resetTenderDiamondWatchlistSelectAll,
  setTenderDiamondGridProductWatchlistIndexes,
  resetTenderDiamondGridProductWatchlistIndexes,
  setAllPreview,
  resetAllPreview
} = commanAnnualSlice.actions;

export default commanAnnualSlice.reducer;

// selectors
export const selectAnnualInvoiceLoading = (state: RootState) =>
  state.commanAnnualSlice.loading;

export const selectedColorFilter = (state: RootState) =>
  state.commanAnnualSlice.colorFilter;

export const selectIsRequestButtonEnable = (state: RootState) =>
  state.commanAnnualSlice.isRequest;

export const selectGridView = (state: RootState) => state.commanAnnualSlice.isGridView;
export const selectJwlGridView = (state: RootState) =>
  state.commanAnnualSlice.isJwlGridView;

export const selectTenderGridView = (state: RootState) =>
  state.commanAnnualSlice.isTenderGridView;

export const selectTenderJwlGridView = (state: RootState) =>
  state.commanAnnualSlice.isTenderJwlGridView;

export const selectLiveGridView = (state: RootState) =>
  state.commanAnnualSlice.isLiveGridView;

export const selectLiveJwlGridView = (state: RootState) =>
  state.commanAnnualSlice.isLiveJwlGridView;

export const selectAuctionData = (state: RootState) =>
  state.commanAnnualSlice.annualAuctionData;

export const selectJwlAuctionData = (state: RootState) =>
  state.commanAnnualSlice.jwlAnnualAuctionData;

export const selectIsRefresh = (state: RootState) =>
  state.commanAnnualSlice.isNotificationApiCall;

export const selectLiveNotification = (state: RootState) =>
  state.commanAnnualSlice.notificationLive;

export const selectLastNotification = (state: RootState) =>
  state.commanAnnualSlice.notificationLastMonthLive;


// jewellery Annual mybids 
export const selectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.jewellerySelectAll;

export const selectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.gridProductIndexes;

// jewellery Annual AllProduct  
export const annualSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.jewelleryAllProductSelectAll;

export const annualSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.gridAllProductProductIndexes;

// jewellery Annual watchlist  
export const annualWatchlistSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.jewelleryWatchlistSelectAll;

export const annualWatchlistSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.gridWatchlistProductIndexes;




// Diamond Annual mybids 
export const selectDiamondAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.diamondSelectAll;

export const selectDiamondAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.diamondgridProductIndexes;

// Diamond Annual AllProduct  
export const annualDiamondSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.diamondAllProductSelectAll;

export const annualDiamondSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.diamondgridAllProductProductIndexes;

// Diamond Annual watchlist  
export const annualDiamondWatchlistSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.diamondyWatchlistSelectAll;

export const annualDiamondWatchlistSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.diamondgridWatchlistProductIndexes;


//tender selector




// jewellery Annual mybids 
export const tenderselectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderjewellerySelectAll;

export const tenderselectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tendergridProductIndexes;

// jewellery Annual AllProduct  
export const tenderannualSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderjewelleryAllProductSelectAll;

export const tenderannualSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tendergridAllProductProductIndexes;

// jewellery Annual watchlist  
export const tenderannualWatchlistSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderjewelleryWatchlistSelectAll;

export const tenderannualWatchlistSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tendergridWatchlistProductIndexes;




// Diamond Annual mybids 
export const tenderselectDiamondAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondSelectAll;

export const tenderselectDiamondAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondgridProductIndexes;

// Diamond Annual AllProduct  
export const tenderannualDiamondSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondAllProductSelectAll;

export const tenderannualDiamondSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondgridAllProductProductIndexes;

// Diamond Annual watchlist  
export const tenderannualDiamondWatchlistSelectAllInGridView = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondyWatchlistSelectAll;

export const tenderannualDiamondWatchlistSelectAllIndexes = (state: RootState) =>
  state.commanAnnualSlice.tenderdiamondgridWatchlistProductIndexes;

export const selectAllPreviews = (state: RootState) =>
  state.commanAnnualSlice.allPreview;