import axios from "axios";
import getErrorCodes from "./ErrorCodesService";
import Auth from "./auth/AuthorizationService";

const checkHeaders = () => {
  const token = localStorage.getItem("id_token");
  if(token !== null && token !== undefined){
    return Auth.headerObjectWithAuthorization()
  } else {
    return {
      "Content-Type": "application/json",
    }
  }
}

const api = axios.create({
  timeout: 30000,
  transformResponse: [...axios.defaults.transformResponse, (data) => data],
  transformRequest: [(data) => data, ...axios.defaults.transformRequest],
  headers: checkHeaders()
});

export const setToken = (token) => {
  api.interceptors.request.use((c) => {
    const configuration = c;
    configuration.headers = { Authorization: `Bearer ${token}` };
    return configuration;
  });
}

export const getRequestForDownload = (url) => {
  return axios({
    method: "get",
    url: `${url}`,
    headers: Auth.headerObjectWithAuthorization(),
    responseType: "blob",
  });
}

export const postRequestForExcel = (url, data) => {
  return axios({
    method: "post",
    timeout: 30000,
    url: `${url}`,
    data: JSON.stringify(data),
    headers: Auth.headerObjectWithAuthorization(),
    responseType: "arraybuffer",
  });
}

const triggerEvent = (event, payload) => {
  window.dispatchEvent(new CustomEvent(event, payload));
};

api.interceptors.request.use(request => {
  if(!Auth.loggedIn()){
    triggerEvent('API_TOKEN_EXPIRE_EVENT',  { detail: { status: 'authTokenExpire', request }});
  }
  return request;
}, error => {
  return Promise.reject(error);
});

const transformErrorResponse = (error) => {
  const errorCodeTranslationKeyMapping = getErrorCodes();
  const errorCodes = [];          // eg: ['KB400001']
  const codeMapping = {};         // eg: {'KB400001': { code: 'KB400001', field: '', message: ''}}
  const codeFieldMapping = {};    // eg: {'KB400001': field }
  const codeMessageMapping = {};  // eg: {'KB400001': 'original message that comes from API' }
  const codeTransKeyMapping = {}; // eg: {'KB400001': 'translation message key which match with en.ts | jp.ts keys' }
  let isMultipleErrors = false;    // TRUE - In case more than 1 error objects in errors array from API.
  let errorCode = "";
  let errorMessageKey = "";

  if(error && error?.errors?.length > 0){
    let errorArr = error.errors;
    let tempObj = {};
    errorArr.forEach(obj => {
      tempObj = {...obj};
      errorCodes.push(obj.code);
      codeMapping[obj.code] = obj;
      codeFieldMapping[obj.code] = obj.field;
      codeMessageMapping[obj.code] = obj.message;
      codeTransKeyMapping[obj.code] = errorCodeTranslationKeyMapping[obj.code];
    });
    isMultipleErrors = error.errors.length > 1;
    if(!isMultipleErrors){
      errorCode = tempObj.code;
      errorMessageKey = errorCodeTranslationKeyMapping[tempObj.code];
    }
  } else {
    isMultipleErrors = false;
  }

  const TRANSFORMED_ERRORS_RESPONSE = {...error, errorCode, errorCodes, errorMessageKey, codeMapping, codeFieldMapping, codeMessageMapping, codeTransKeyMapping, isMultipleErrors};

  // Dev Note: Uncomment for debugging when we want to see entire error details.
  // console.log("TRANSFORMED_ERRORS_RESPONSE ::", TRANSFORMED_ERRORS_RESPONSE);
  return TRANSFORMED_ERRORS_RESPONSE;
}

const apiErrorHandler = (errorObj) => {
  console.log("ERROR : ", JSON.stringify(errorObj.response));
  console.log("ERROR errorObj : ", errorObj);

  // Transform the actual error object from API to customise it. 
  const errorData = errorObj.response.data;
  const transformedErrorObject = transformErrorResponse(errorData);
  errorObj.response.data = {...transformedErrorObject}; // This will provide the combined error object to error handlers
  const status = errorObj.response.data.status;
  const error = errorObj.response.data;

  if (status === 401) {
    console.log('error from interceptor: code 401', error);
  } else if (status === 403) {
    triggerEvent('DEACTIVATE_USER', { detail :{ status, error }});
  } else if(status === 404) {
    triggerEvent('API_INVALID_REQUEST',  { detail: { status, error }});
  } else if (status === 500) {
    triggerEvent('API_INTERNAL_SERVER_ERROR',  { detail: { status, error }});
  } else if(status === 505) {
    triggerEvent('API_TIMEOUT',  { detail: { status, error }});
  }
  return Promise.reject(errorObj);
}

// error handling
api.interceptors.response.use(
  (response) => {
    return response;
  },
  apiErrorHandler
);

// PUBLIC API

export const publicApi = axios.create({
  timeout: 30000,
  transformResponse: [...axios.defaults.transformResponse, (data) => data],
  transformRequest: [(data) => data, ...axios.defaults.transformRequest],
  headers: Auth.forPublicApisHeader()
});

publicApi.interceptors.request.use(request => {
  return request;
}, error => {
  return Promise.reject(error);
});

publicApi.interceptors.response.use(
  (response) => {
    return response;
  },
  apiErrorHandler
);

export const postRequestWithoutAuthorization = (url, data) => {
  return axios({
    method: "post",
    url: `${url}`,
    data: JSON.stringify(data),
    headers: Auth.forPublicApisHeader(),
  });
}

export const postRequestForKYC = (url, data) => {
  return axios({
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": "applicationmultipart/form-data",
      type: "formData",
      Accept: "application/json",
    }
  });
}

export default api;