import React from "react";
import { Grid } from "@material-ui/core";
import { StyledFooterContainerdummy } from "./StyledComponets";

const DummyAddress = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <StyledFooterContainerdummy isMobile={isMobile}>
      <Grid
        container
        spacing={4}
        style={{ textAlign: "center", display: "block" }}
      >
        <Grid item>
          <span style={{ fontSize: "2em" }}>Address</span>
          <div>
            26985 Brighton Lane, Lake Forest, CA 92630.
            <br />
            (92630)
          </div>
        </Grid>
        <div
          style={{
            borderBottom: "2px solid white",
            borderWidth: "thick",
            textAlign: "center",
            display: "inline-table",
            width: "60%",
          }}
        ></div>
        <Grid item>
          <span style={{ fontSize: "2em" }}>Phone / Fax</span>
          <div style={{ display: "grid" }}>
            <span>Phone: +30-6977664062 </span>
            <span>Fax: +30-2106398905 </span>
          </div>
        </Grid>
        <div
          style={{
            borderBottom: "2px solid white",
            borderWidth: "thick",
            textAlign: "center",
            display: "inline-table",
            width: "60%",
          }}
        ></div>
        <Grid item>
          <span style={{ fontSize: "2em" }}>Email</span>
          <div>contact@lorem.com</div>
        </Grid>
      </Grid>
    </StyledFooterContainerdummy>
  );
};

export default DummyAddress;
