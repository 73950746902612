import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import {
  alpha,
  Badge,
  InputBase,
  OutlinedInput,
  Snackbar,
} from "@material-ui/core";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import NewNotificationComponent from "./NewNotificationComponent";
import { Grid } from "@material-ui/core";
import AuthService from "../../../components/auth/AuthService";
import { useHistory } from "react-router";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import Popper from "@material-ui/core/Popper";
import {
  getAllNotificationsInfo,
  getLiveNotifications,
} from "../../config/app.endpoints";
import { dateBackgroundGradient } from "../../materials/LinearGradient";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import EllipsisText from "../EllipsisText";
import LoadingSpinner from "../LoadingSpinner";

import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import FilterListIcon from "@material-ui/icons/FilterList";
import PubSub from "pubsub-js";
import { RootState } from "../../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsRefresh,
  selectLastNotification,
  selectLiveNotification,
  setNotificationLive,
} from "../../../redux/slice/annual/commanAnnualSlice";
import { AppDispatch } from "../../../redux/store";
import { StyledModal } from "../../materials/GenericStyleComponent";
import BlockUserModal from "../../../components/products/BlockUser/BlockUserModal";
import ReadOnlyUserModal from "../../../components/products/BlockUser/ReadOnlyUserModal";
import UserRemovedModal from "../../../components/products/BlockUser/UserRemovedModal";
import { useTranslation } from "react-i18next";
import { MsgCode } from "../../constants/NotificationMSGConstants";

const StyledStickeyContainerTry = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  .MuiBadge-colorPrimary {
    color: rgba(4, 106, 215, 1);
    background-color: ${(props) => props.theme.colors.base.white};
    transform: scale(1) translate(60%, -40%);
  }
`;

const StyledComponent = styled.div`
  outline: none;
`;

const StyledPopperComponent = styled(Popper)`
  margin-top: 20px;
  @media (width: 320px) {
    margin-top: 60px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 15px;
  }
`;
const StyledSnackbarComponent = styled(Snackbar)``;

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 290px;
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 999;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 200px;
    height: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
    height: 450px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 290px;
  }
  .onClickButton {
    cursor: default;
  }
  .alignRight {
    text-align: right;
  }
`;

const ProductNameContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 6px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 10px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 12px;
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
  }
  .MuiSelect-icon {
    display: none;
  }
  .MuiTypography-body1 {
    font-size: 0.5rem !important;
  }
  .MuiTypography-body1 {
    font-size: 0.5rem;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiSelect-selectMenu {
    width: 0px;
    color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    text-indent: -9999px;
  }
`;

const ProductCountContainer = styled(Grid)`
  color: ${(props) => props.theme.colors.base.black};
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 7px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 12px;
  }
`;

const StyledSubNotificationContainer = styled.div`
  background: white;
  width: 300px;
  height: 468px;
  overflow: hidden;
  overflow-y: auto;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 200px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 290px;
  }
  .onClickButton {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
  .alignRight {
    text-align: right;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledDivHeader = styled.div`
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  flex-direction: row;
  display: flex;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border-radius: 20px 20px 0px 0px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 200px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 290px;
  }
`;
const StyledDivFooter = styled.div`
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  display: grid;
  border-top: 1px solid ${(props) =>
    props.theme.colors.base.buttonGradientFirstColor};
  border-radius: 0px 0px 20px 20px;
  width 290px;
  height: auto;
  overflow: hidden;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 200px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width:  290px;
  }
`;

const StyledSpanHeader = styled.span`
  color: white;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  width: 100%;
  display: flex;
  cursor: pointer;
  grid-gap: 5px;
  padding: 2px;
`;
const StyledSpansub = styled.span`
  width: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 1px;
  margin-left: 6px;
  border: 1px solid white;
  border-radius: 6px;
  linkActive:after {
    border-bottom: 1px solid white;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

const StyledShowAllNotification = styled.p`
  justify-content: center;
  display: flex;
  color: white;
  margin-bottom: 0rem;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "1ch",
      "&:focus": {
        width: "9ch",
      },
    },
  },
}));

let payload: any = {};
let userIdForNotification: string | null;
let updatedRowData: any;
let badgeCountValue: number = 0;


let joinedAuctions: any = [];
let jwljoinedAuctionUserId: string;
let joinedAuctionUserId: string;

const NotificationToggle = (props: any) => {
  const [notificationList, setNotificationList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [userReadOnly, setUserReadOnly] = useState(false);
  const [userRemoved, setUserRemoved] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbarOpen, setSnackbars] = useState(false);
  const [isRefreshBadgeCount, setIsRefreshBadgeCount] = useState(false);
  const [showSnackbarMessage, setShowSnackbarMessage] = useState("");
  const [countOne, setCountOne] = useState(0);

  const classes = useStyles();
  let history = useHistory();
  const Auth = new AuthService();
  let { t } = useTranslation();
  let open: any = Boolean(anchorEl);
  let id: any = open ? "transitions-popper" : undefined;
  const maxRetryCount = 2;
  const countRef = useRef(0);
  const [shouldGetAllData, setShouldGetAllData] = useState(false);
  const storeLiveNotification: any = useRef<any>([]);
  const eventSource: any = useRef<any>();
  userIdForNotification = localStorage.getItem("userIdForNotification");
  let subdomainType = localStorage.getItem("subDomain");
  const isRefresh: any = useSelector((state: RootState) =>
    selectIsRefresh(state)
  );
  const lastMonthNotification: any = useSelector((state: RootState) =>
    selectLastNotification(state)
  );
  const liveNotification: any = useSelector((state: RootState) =>
    selectLiveNotification(state)
  );

  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  const names = [
    { label: `${t("common:newCommanTrans:all")}`, name: "ALL" },
    {
      label: `${t("common:newCommanTrans:soldproduct")}`,
      name: "SOLD_PRODUCT",
    },
    {
      label: `${t("common:newCommanTrans:holdProduct")}`,
      name: "HOLD_PRODUCT",
    },
    {
      label: `${t("common:newCommanTrans:deleteProduct")}`,
      name: "DELETE_PRODUCT",
    },
    { label: `${t("common:newCommanTrans:outBid")}`, name: "OUT_BID" },
    {
      label: `${t("common:newCommanTrans:customMessage")}`,
      name: "CUSTOM_MESSAGE",
    },
  ];

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);

    if (isRefresh !== undefined) {
      if (isRefresh) {
        getAllNotificationByPayload();
      }
    }
    getAllUpdatedData();

    if (liveNotification !== undefined) {
      let readValue = liveNotification.filter((obj: any) => {
        return obj.read === false;
      });
      badgeCountValue = readValue.length;
      setCountOne(badgeCountValue);
    }
    setIsLoading(false);

    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null || joinedAuctions?.length !== undefined) {
      let diamsArr = joinedAuctions?.filter((items: any) => items.category === "Diamonds");
      let jwlArr = joinedAuctions?.filter((items: any) => items.category === "Jewellery");

      if (jwlArr !== undefined || diamsArr !== undefined) {
        joinedAuctionUserId = diamsArr[0]?.auctionUserId;
        jwljoinedAuctionUserId = jwlArr[0]?.auctionUserId;
      }
    }


    return () => {
      if (eventSource.current !== undefined) {
        if (eventSource.current.close !== undefined) {
          eventSource.current.close();
        }
      }
    };
  }, []);

  useEffect(() => {
    if (shouldGetAllData) {
      getAllUpdatedData();
      setShouldGetAllData(false);
    }
  }, [shouldGetAllData]);

  const getAllUpdatedData = () => {
    if (countRef.current < maxRetryCount) {
      eventSource.current = new EventSource(getAllNotificationsInfo);
      eventSource.current.onmessage = (event: any) => {
        updatedRowData = JSON.parse(event.data);
        if (updatedRowData.alive) {
          //This will listen only for events
        } else {
          if (
            updatedRowData.message === "RESCHEDULE_EXTENDED_PRODUCT"
          ) {
            PubSub.publish("REFRESHSCREEN", {
              message: updatedRowData.message,
              auctionId: updatedRowData.auctionId,
              userNotificationType: updatedRowData.userNotificationType,
              productId: updatedRowData.productId,
            });
          }
          if (
            updatedRowData.message === MsgCode.SCHEDULED_EXTENDED_PRODUCTS ||
            updatedRowData.message === MsgCode.MARK_END_MESSAGE ||
            updatedRowData.message === MsgCode.MARKED_END_MESSAGE ||
            updatedRowData.message === MsgCode.PRODUCTS_AVAILABLE ||
            updatedRowData?.itemSoldMsg === MsgCode.ANNUAL_PRODUCTS_SOLD ||
            updatedRowData.message === MsgCode.ANNUAL_PRODUCTS_UPDATES_AVAILABLE
          ) {
            // publish push notification         
            PubSub.publish("updateProduct", {
              message: updatedRowData.itemSoldMsg,
              auctionId: updatedRowData.auctionId,
              userNotificationType: updatedRowData.userNotificationType,
              winnerId: updatedRowData.auctionUserId,
              productDesc: updatedRowData.productDetail,
              suid: updatedRowData.suid,
              productId: updatedRowData.productId,
            });
          } else if (
            updatedRowData.message === MsgCode.USER_REMOVED_FROM_AUCTION ||
            updatedRowData.message === MsgCode.READ_ONLY_USER ||
            updatedRowData.message === MsgCode.USER_DEACTIVATED
          ) {
            if (updatedRowData.userId === userIdForNotification) {
              if (updatedRowData.message === MsgCode.READ_ONLY_USER) {
                localStorage.setItem(
                  "isReadOnlyUser",
                  updatedRowData.userReadOnly
                );
                setUserReadOnly(true);
              } else if (
                updatedRowData.message === MsgCode.USER_REMOVED_FROM_AUCTION
              ) {
                setUserRemoved(true);
              } else {
                setUserBlock(true);
              }
            }
          }

          const requestData: any = [];

          if (
            updatedRowData.userId === userIdForNotification &&
            updatedRowData?.itemSoldMsg !== MsgCode.ANNUAL_PRODUCTS_SOLD
          ) {
            if (updatedRowData.auctionType === "Annual" && subdomainType === "zipang") {
              PubSub.publish("notificationUpdateData", updatedRowData);
              requestData.push(updatedRowData);
              let updateLiveNotify: any = [
                ...storeLiveNotification.current,
                updatedRowData,
              ];
              storeLiveNotification.current = updateLiveNotify;
              updateLiveNotify.unshift(updateLiveNotify.pop());
              dispatch(setNotificationLive({ response: updateLiveNotify }));
              badgeCountValue = badgeCountValue + 1;
              setShowSnackbarMessage(updatedRowData.message);
              setSnackbars(true);
            } else {
              if (updatedRowData.auctionType !== "Annual" && subdomainType !== "zipang") {
                if (updatedRowData.userNotificationType !== "OUT_BID") {
                  PubSub.publish("notificationUpdateData", updatedRowData);
                  requestData.push(updatedRowData);
                  let updateLiveNotify: any = [
                    ...storeLiveNotification.current,
                    updatedRowData,
                  ];
                  storeLiveNotification.current = updateLiveNotify;
                  updateLiveNotify.unshift(updateLiveNotify.pop());
                  dispatch(setNotificationLive({ response: updateLiveNotify }));
                  badgeCountValue = badgeCountValue + 1;
                  setShowSnackbarMessage(updatedRowData.message);
                  setSnackbars(true);
                }
              }
            }

          }

          const updateNotification = (rowData: any, message: any) => {
            requestData.push(rowData);
            let updateLiveNotify = [...storeLiveNotification.current, rowData];
            storeLiveNotification.current = updateLiveNotify;
            updateLiveNotify.unshift(updateLiveNotify.pop());
            dispatch(setNotificationLive({ response: updateLiveNotify }));

            PubSub.publish("notificationUpdateData", rowData);

            badgeCountValue += 1;
            setShowSnackbarMessage(message);
            setSnackbars(true);
            setIsRefreshBadgeCount(!isRefreshBadgeCount);
          };

          if (
            updatedRowData.userId === userIdForNotification &&
            updatedRowData?.itemSoldMsg === MsgCode.ANNUAL_PRODUCTS_SOLD &&
            updatedRowData.suid !== userIdForNotification
          ) {
            if (updatedRowData.auctionType === "Annual" && subdomainType === "zipang") {
              const message = `CONGRATULATIONS! You have won the bid of ${updatedRowData.productDetail} - ${updatedRowData.itemCurrencySymbol} ${updatedRowData.soldPrice}`;
              const rowMsgData = { ...updatedRowData, message };
              updateNotification(rowMsgData, message);
            } else {
              if (updatedRowData.auctionType !== "Annual" && subdomainType !== "zipang") {
                if (updatedRowData.userNotificationType !== "OUT_BID") {
                  const message = `CONGRATULATIONS! You have won the bid of ${updatedRowData.productDetail} - ${updatedRowData.itemCurrencySymbol} ${updatedRowData.soldPrice}`;
                  const rowMsgData = { ...updatedRowData, message };
                  updateNotification(rowMsgData, message);
                }
              }
            }

          } else if (
            updatedRowData.userId !== userIdForNotification &&
            updatedRowData?.itemSoldMsg === MsgCode.ANNUAL_PRODUCTS_SOLD &&
            updatedRowData.suid !== userIdForNotification
          ) {
            if (updatedRowData.auctionType === "Annual" && subdomainType === "zipang") {
              // const message = `CONGRATULATIONS! You have won the bid of ${updatedRowData.productDetail} - ${updatedRowData.itemCurrencySymbol} ${updatedRowData.soldPrice}`;
              // const rowMsgData = { ...updatedRowData, message };
              // updateNotification(rowMsgData, message);
              updateNotification(updatedRowData, updatedRowData.message);
            } else {
              if (updatedRowData.auctionType !== "Annual" && subdomainType !== "zipang") {
                if (updatedRowData.userNotificationType !== "OUT_BID") {
                  updateNotification(updatedRowData, updatedRowData.message);
                }
              }
            }
            getAllNotificationByPayload();
          } else if (
            updatedRowData.suid === userIdForNotification &&
            updatedRowData.userId !== userIdForNotification &&
            updatedRowData?.itemSoldMsg === MsgCode.ANNUAL_PRODUCTS_SOLD
          ) {
            if (updatedRowData.auctionType === "Annual" && subdomainType === "zipang") {
              let message = `CONGRATULATIONS! Your product- ${updatedRowData.productDetail} for 365 Days Auction has been sold for - ${updatedRowData.itemCurrencySymbol} ${updatedRowData.soldPrice}`;
              const rowMsgData = { ...updatedRowData, message };
              updateNotification(rowMsgData, message);
            } else {
              if (updatedRowData.auctionType !== "Annual" && subdomainType !== "zipang") {
                if (updatedRowData.userNotificationType !== "OUT_BID") {
                  let message = `CONGRATULATIONS! Your product- ${updatedRowData.productDetail} for 365 Days Auction has been sold for - ${updatedRowData.itemCurrencySymbol} ${updatedRowData.soldPrice}`;
                  const rowMsgData = { ...updatedRowData, message };
                  updateNotification(rowMsgData, message);
                }
              }
            }
            getAllNotificationByPayload();
          }
        }
        setShouldGetAllData(false);
      };

      eventSource.current.onerror = (err: any) => {
        console.error("EventSource failed:", err);
        if (countRef.current < maxRetryCount) {
          try {
            setInterval(async () => {
              countRef.current = countRef.current + 1;
              setShouldGetAllData(true);
            }, 30000);
          } catch (e) {
            console.log(e);
          }
        } else {
          setShouldGetAllData(false);
        }
      };
    }
  };

  const getAllNotificationByPayload = (callBack?: any) => {
    getBody(callBack);
  };

  const getBody = (callBackType?: any) => {
    //userone static
    payload = {
      filters: {
        and: [
          {
            match: "exact",
            name: "userId",
            value: userIdForNotification,
          },
        ],
      },
      page: {
        start: 0,
        count: 500,
        sort: {
          orders: [
            {
              property: "createdDate",
              direction: "DESC",
            },
          ],
        },
      },
    };
    callApi(payload, callBackType);
  };

  const textSearchListing = (value: any) => {
    const data = {
      filters: {
        or: [
          {
            match: "anywhere",
            name: "message",
            value: value,
          },
        ],
      },
      page: {
        start: 0,
        count: 5000,
        sort: {
          orders: [
            {
              property: "createdDate",
              direction: "DESC",
            },
          ],
        },
      },
    };

    if (value === "" || value.length === 1) {
      getAllNotificationByPayload();
    } else {
      if (value.length > 1) {
        Auth.postRequest(
          getLiveNotifications,
          data,
          (status: any, response: any) => {
            if (status === 200) {
              // setNotificationList(response.results);
              dispatch(setNotificationLive({ response: response.results }));
            }
          }
        );
      }
    }
  };

  const callApi = (payload: any, type?: any) => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${getLiveNotifications}`,
        payload,
        (status: any, response: any) => {
          setIsLoading(false);
          if (status === 200) {
            // setIsLoading(true);
            setNotificationList(response.results);
            storeLiveNotification.current = response.results;
            dispatch(setNotificationLive({ response: response.results }));
            let readValue = response.results.filter((obj: any) => {
              return obj.read === false;
            });
            badgeCountValue = readValue.length;
            setCountOne(badgeCountValue);
            if (type !== undefined) {
              setNotificationList(
                response.results.filter(
                  (item: any) => item.userNotificationType === type
                )
              );
              setTimeout(() => {
                setIsLoading(false);
              }, 2300);
            }
            setIsRefreshBadgeCount(!isRefreshBadgeCount);
          }
        }
      );
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setSnackbars(false);
  };

  const renderNotificationPage = () => {
    history.push("/notificationpage");
  };

  const ITEM_HEIGHT = 48;
  const MenuProps = {
    root: {
      top: "100px",
      right: 0,
    },
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5,
        width: 210,
      },
    },
    list: {
      fontSize: "5px",
    },
  };
  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
  };

  useEffect(() => {
    if (lastMonthNotification !== undefined) {
      lastMonthNotification?.filter((x: any) => renderFiltered(x));
    }
  }, [lastMonthNotification]);

  const newrenderFiltered = (x: any) => {
    return personName.length === 0 || personName.includes("ALL")
      ? true
      : personName.includes(x.userNotificationType);
  };

  const renderFiltered = (x: any) => {
    return personName.length === 0 || personName.includes("ALL")
      ? true
      : personName.includes(x.userNotificationType);
  };

  const closeModal = () => {
    Auth.logout();
  };

  const readOnlyModal = () => {
    setUserReadOnly(false);
    window.location.reload();
  };

  const removedFromtheModal = () => {
    history.push("/home");
    setUserRemoved(false);
  };

  const onCardClickHandler = (item: any) => {
    let { auctionStartDate, auctionId, productId, productCategory, auctionType } = item;
    if (auctionType === "Annual") {
      let isMypage: boolean = true;
      let auctionDate = auctionStartDate.split(" ", 2);
      history.push(
        `/365products/details/${auctionId}/${productCategory === "Diamonds" ? joinedAuctionUserId : jwljoinedAuctionUserId}/${productId}/${auctionDate[0]}/${isMypage}`
      );
    }
  };

  return (
    <>
      <StyledStickeyContainerTry>
        <StyledStickeyContainerTry title={`Notification`}>
          <Badge
            badgeContent={badgeCountValue}
            color="primary"
            overlap="rectangular"
          >
            <button
              aria-describedby={id}
              type="button"
              onClick={handleClick}
              style={{
                backgroundColor: "transparent",
                border: "1px solid transparent",
              }}
            >
              <NotificationsNoneIcon
                fontSize="large"
                style={{ fontSize: 22, color: "black", cursor: "pointer" }}
              />
            </button>
          </Badge>
        </StyledStickeyContainerTry>
      </StyledStickeyContainerTry>
      <StyledPopperComponent
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{
          zIndex: 1000,
          border: "1px solid #5499e4",
          borderRadius: "20px",
          top: "60px",
        }}
        className="top"
      >
        {() => (
          <StyledComponent>
            <StyledDivHeader>
              <ProductCountContainer onClick={handleClick}>
                <NotificationsNoneIcon
                  fontSize="large"
                  style={{ fontSize: 20, color: "white", cursor: "pointer" }}
                />
              </ProductCountContainer>
              <ProductCountContainer
                className="fontBold"
                style={{ color: "white" }}
              >
                {t("common:notificaiton:notification")}
              </ProductCountContainer>
              <ProductNameContainer>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon
                      style={{
                        fontSize: 20,
                        color: "white",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <InputBase
                    placeholder=""
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={(e) => textSearchListing(e.target.value)}
                    inputProps={{ "aria-label": "search" }}
                  />
                </div>
              </ProductNameContainer>
              <ProductNameContainer>
                <div className={classes.search}>
                  <div
                    className={classes.searchIcon}
                    style={{ border: "none" }}
                  >
                    <FilterListIcon
                      style={{
                        fontSize: 25,
                        color: "white",
                        cursor: "pointer",
                        zIndex: 1,
                      }}
                    />
                  </div>
                  <Select
                    labelId="demo-mutiple-name-label"
                    id="demo-mutiple-name"
                    multiple
                    value={personName}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected: any) => selected.join(",")}
                    MenuProps={MenuProps}
                    style={{ height: "37px", fontSize: "5px" }}
                  >
                    {names.map((item) => (
                      <MenuItem
                        key={item.label}
                        value={item.name}
                        style={{
                          fontSize: "5px",
                          width: "210px",
                          padding: "0px",
                        }}
                      >
                        <Checkbox
                          checked={personName.indexOf(item.name) > -1}
                        />
                        <ListItemText
                          primary={item.label}
                          style={{ fontSize: "5px" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </ProductNameContainer>
            </StyledDivHeader>
            <StyledContainerWrapper>
              <StyledSpanHeader>
                <StyledSpansub className="linkActive">
                  {t("common:notificaiton:new")}
                </StyledSpansub>
              </StyledSpanHeader>
              <StyledSubNotificationContainer>
                {liveNotification !== undefined ? (
                  <>
                    {isLoading ? (
                      <div className="loader">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <div>
                        <NewNotificationComponent
                          items={liveNotification.filter((x: any) =>
                            newrenderFiltered(x)
                          )}
                          userId={userIdForNotification}
                          updatedNotification={getAllNotificationByPayload}
                          onCardClickHandler={onCardClickHandler}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  <> </>
                )}
              </StyledSubNotificationContainer>
            </StyledContainerWrapper>
            <StyledDivFooter>
              <StyledShowAllNotification onClick={renderNotificationPage}>
                {t("common:notificaiton:showAll")}
              </StyledShowAllNotification>
            </StyledDivFooter>
          </StyledComponent>
        )}
      </StyledPopperComponent>
      {snackbarOpen === true && (
        <StyledSnackbarComponent
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbarOpen}
          autoHideDuration={2400}
          message={
            <EllipsisText text={showSnackbarMessage} length={35} tail={"..."} />
          }
          className="MuiSnackbar-anchorOriginTopRight"
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
          onClose={handleClose}
        />
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal {...props} closeModal={closeModal} />
        </StyledModal>
      )}
      {userReadOnly === true && (
        <StyledModal open={userReadOnly}>
          <ReadOnlyUserModal {...props} closeModal={readOnlyModal} />
        </StyledModal>
      )}
      {userRemoved === true && (
        <StyledModal open={userRemoved}>
          <UserRemovedModal {...props} closeModal={removedFromtheModal} />
        </StyledModal>
      )}
    </>
  );
};
export default NotificationToggle;
