const changeApplicationLanguage = (lng: string) => {
  const href = window.location.href;
  const pathname = new URL(href).pathname;
  const pathArray = pathname.split("/");

  // Now get the complete url from browser and set the desired language code and replace it in URL.
  const origin = window.location.origin;
  const remainingPathArray = pathArray.slice(2);
  const endPAth = remainingPathArray.join("/");
  const updatedUrl = `${origin}/${lng}/${endPAth}`;
  window.location.replace(updatedUrl);
};

const getFileSize = (file: any) => {
  const fsize = file.size;
  const fileSize = Math.round(fsize / 1024);
  if (fileSize > 10240) {
    return false;
  } else {
    return true;
  }
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

// Certification link for HRD, CGL, GIA, IGI and AGT will be rendered by this function

const openCertificateLinkPopup = (lab: any, certificate: any) => {
  let windowObjectReference: any = "";
  let url: string = "";

  if (lab === "CGL") {
    url = `https://www.cgl.co.jp/diamond-check/`;
  } else if (lab === "HRD") {
    url = `https://my.hrdantwerp.com/`;
  } else if (lab === "GIA") {
    url = `https://www.gia.edu/report-check?reportno=${certificate}`;
  } else if (lab === "AGT") {
    url = "https://myauctionstech.in/en/";
  } else if (lab === "IGI") {
    url = `https://www.igi.org/reports/verify-your-report?r=${certificate}`;
  } else {
    url = "/";
  }

  windowObjectReference = window.open(
    url,
    "DescriptiveWindowName",
    // "resizable,scrollbars,status",
    "height=900px,width=800px,scrollbars,toolbar,status,resizable"
  );
};

export {
  changeApplicationLanguage,
  getFileSize,
  scrollToTop,
  openCertificateLinkPopup,
};
