import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import IRouterComponentProps from "../../../models/IRouterComponentProps";
import AppLayout from "../../../components/layouts/AppLayout";
import styled from "styled-components";
import { createTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import SelectDateCalendarForNotification from "./SelectDateCalendarForNotification";
import { oAuth } from "../../../routes/basic/BasicAppRouter";
import { useHistory } from "react-router";
import AuthService from "../../../components/auth/AuthService";
import { getAllNotifications } from "../../config/app.endpoints";
import LoadingSpinner from "../LoadingSpinner";

const StyledMuiDataTableLayoutGrid = styled(Grid)`
  margin-top: 20px;
  .MuiPaper-root {
    width: 100%;
  }
  .MuiTableCell-head {
    padding: 10px 5px !important;
  }
  .MuiTableCell-root {
    padding: 16px;
    border: 1px solid lightgray;
  }
  .MuiToolbar-regular {
    min-height: 0px;
  }
  .MuiTableCell-head {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    color: white;
    text-align: center;
    font-weight: 700;
    border: 1px solid lightgray;
  }

  .MuiTableCell-root {
    text-align: center;
  }

  .MuiTableCell-head .MuiButton-root {
    color: white;
    text-align: center;
    font-weight: 700;
  }

  .linkCss {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }

  .MuiTableHead-root {
    position: sticky;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }

  .MuiTableCell-footer {
    padding: 2px;
    font-size: 0.2em;
    height: 40px;
  }

  .MuiTablePagination-actions {
    margin-left: 0px;
  }
`;

const StyledTimeGridContainer = styled(Grid)`
  justify-content: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: 700;
  font-size: 2em;
`;

const StyledActionBarContainer = styled(Grid)`
  border-radius: 5px;
  box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
  &:focus {
    outline: none;
  }
`;

const StyledCalendarContainer = styled(Grid)``;

const StyledSelectCalendarContainer = styled(Grid)`
  border-radius: 5px;
  padding: 5px;
`;

interface IPendingProductProps extends IRouterComponentProps {
  t: any;
}

let auctionId: any;

let joinedAuctions: any = [];
let jwljoinedAuctionUserId: string;
let joinedAuctionUserId: string;

let payload: any = {};
let updatedRowData: any;
const NotificationPage = (props: IPendingProductProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allNotification, setAllNotificationList] = useState([]);
  const { t } = useTranslation();
  let history = useHistory();
  const storeLiveNotification: any = useRef<any>([]);
  const Auth = new AuthService();
  let languageCode =
    localStorage.getItem("language") === "EN"
      ? "en"
      : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";

  useEffect(() => {
    setIsLoading(true);
    localStorage.getItem("userIdForNotification");
    getBody();
    PubSub.subscribe("refreshNetworkPage", (msg: any, data: any) => {
      if (data === true) {
        getBody();
      }
    });

    joinedAuctions = localStorage.getItem("joinedAnnualAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    if (joinedAuctions !== null || joinedAuctions?.length !== undefined) {
      let diamsArr = joinedAuctions?.filter((items: any) => items.category === "Diamonds");
      let jwlArr = joinedAuctions?.filter((items: any) => items.category === "Jewellery");

      if (jwlArr !== undefined || diamsArr !== undefined) {
        joinedAuctionUserId = diamsArr[0]?.auctionUserId;
        jwljoinedAuctionUserId = jwlArr[0]?.auctionUserId;
      }
    }

    PubSub.subscribe("notificationUpdateData", (msg: any, data: any) => {
      getEventSourceValue(data);
    });
  }, []);

  const getEventSourceValue = (data: any) => {
    if (data.alive) {
      //
    } else {
      if (data?.userId === localStorage.getItem("userIdForNotification")) {
        storeLiveNotification.current.push(storeLiveNotification.current.unshift(data));
        var updateLiveNotify: any = [...storeLiveNotification.current];
        setAllNotificationList(updateLiveNotify);
      }
    }
  };

  const getBody = () => {
    //userone static
    payload = {
      page: {
        start: 0,
        count: 100000,
        sort: {
          orders: [
            {
              property: "createdDate",
              direction: "DESC",
            },
          ],
        },
      },
    };
    callApi(payload);
  };

  const callApi = (payload: any) => {
    if (!oAuth()) {
      history.push("/");
    } else {
      Auth.postRequest(
        `${getAllNotifications}`,
        payload,
        (status: any, response: any) => {
          setIsLoading(true);
          if (status === 200) {
            let newArrayofUserNotification: any = response.results.filter(
              (item: any) =>
                item.userId === localStorage.getItem("userIdForNotification")
            );
            setAllNotificationList(newArrayofUserNotification);
            storeLiveNotification.current = newArrayofUserNotification;
            setIsLoading(false);
          }
        }
      );
    }
  };
  const getTableBodyHeight = () => {
    var h = window.innerHeight;
    let heightOfInnerTable = h - 290;
    if (allNotification.length > 7) {
      return `${heightOfInnerTable}px`;
    }
  };
  const tableOptions: MUIDataTableOptions = {
    filter: true,
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    serverSide: false,
    pagination: true,
    search: true,
    sort: true,
    rowsPerPageOptions: [10, 20, 50, 100],
    tableBodyHeight: getTableBodyHeight(),
    textLabels: {
      body: {
        noMatch: `${t("common:newCommanTrans:sorrynomatchingrecordsfound")}`,
      },
    },
  };

  const onCardClickHandler = (product: any) => {
    let { auctionStartDate, auctionId, productId, productCategory, auctionType } = product;
    if (auctionType === "Annual") {
      let isMypage: boolean = true;
      let auctionDate = auctionStartDate.split(" ", 2);
      window.open(
        `/${languageCode}/365products/details/${auctionId}/${productCategory === "Diamonds" ? joinedAuctionUserId : jwljoinedAuctionUserId}/${productId}/${auctionDate[0]}/${isMypage}`,
        "detailsWindowName",
        "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
      );
    } else {
      window.open(
        `/${languageCode}/my-page/diamonds/details/${product.auctionId}/${product.productId}/${product.auctionStartDate}`,
        "detailsWindowName",
        "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
      );
    }
  };

  const pendingProductTableColumns: MUIDataTableColumnDef[] = [
    {
      name: "serialNo",
      label: `${t("common:newCommanTrans:serialNo")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                let selectedRowData: any = tableMeta?.tableData[tableMeta.rowIndex];
                onCardClickHandler(selectedRowData);
              }}
            >
              {value}
            </span>
          );
        },
      },
    },
    {
      name: "itemCode",
      label: `${t("common:newCommanTrans:itemSku")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "productId",
      label: `${t("common:newCommanTrans:productID")}`,
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return <>{value !== undefined ? value : "-"}</>;
        },
      },
    },
    {
      name: "createdDate",
      label: `${t("common:newCommanTrans:notificationdate")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "auctionName",
      label: `${t("common:newCommanTrans:auctionname")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "userNotificationType",
      label: `${t("common:newCommanTrans:notificationtype")}`,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "message",
      label: `${t("common:newCommanTrans:description")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  const getMuiTheme = () =>
    createTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            background: "white",
          },
          fixedLeft: {
            background: "white",
          },
        },
      },
    });

  const onSelectDate = () => { };
  return (
    <AppLayout {...props}>
      {isLoading ? (
        <div className="loader">
          <LoadingSpinner />
        </div>
      ) : (
        <div style={{ padding: "10px", marginTop: "20px" }}>
          <StyledActionBarContainer
            container={true}
            spacing={0}
            className="coreActionBarContainer"
          >
            <StyledCalendarContainer item md={9} xs={12}>
              <StyledSelectCalendarContainer>
                <SelectDateCalendarForNotification dates={onSelectDate} t={t} />
              </StyledSelectCalendarContainer>
            </StyledCalendarContainer>
          </StyledActionBarContainer>
          <StyledTimeGridContainer container={true} spacing={0}>
            {t("common:newCommanTrans:notifications")}
          </StyledTimeGridContainer>
          {allNotification.length > 0 ? (
            <StyledMuiDataTableLayoutGrid>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title={"  "}
                  data={allNotification}
                  columns={pendingProductTableColumns}
                  options={tableOptions}
                />
              </MuiThemeProvider>
            </StyledMuiDataTableLayoutGrid>
          ) : (
            <StyledMuiDataTableLayoutGrid>
              <MUIDataTable
                title={"  "}
                data={allNotification}
                columns={pendingProductTableColumns}
                options={tableOptions}
              />
            </StyledMuiDataTableLayoutGrid>
          )}
        </div>
      )}
    </AppLayout>
  );
};

export default NotificationPage;
