import React from "react";

import { match } from "react-router";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import DiamondWatchlistBiddingCompnent from "./DiamondWatchlistBiddingCompnent";
import JewelleryWatchlistBiddingComponet from "./JewelleryWatchlistBiddingComponet";

interface DetailParams {
  auctionId: string;
  auctionUserId: any;
  auctionDate: any;
}
interface IProps extends IRouterComponentProps {
  match: match<DetailParams>;
  category: any;
  data: any;
  totalElements: any;
  itemPerPage: any;
  watchlistObjectdata: any;
  handleBackBtns: any;
  removeFromWatchlist: any;
  auctionState:any;
}

const WatchlistBiddingCompnent = (props: IProps) => {
  let { category, data, watchlistObjectdata, handleBackBtns, auctionState } =
    props;

  return (
    <>
      {category === "Jewellery" ? (
        <JewelleryWatchlistBiddingComponet
          {...props}
          dataObject={watchlistObjectdata}
          data={data}
          removeFromWatchlist={props.removeFromWatchlist}
          handleBackBtns={handleBackBtns}
          auctionState={auctionState}
        />
      ) : (
        <DiamondWatchlistBiddingCompnent
          {...props}
          dataObject={watchlistObjectdata}
          data={data}
          removeFromWatchlist={props.removeFromWatchlist}
          handleBackBtns={handleBackBtns}
          auctionState={auctionState}
        />
      )}
    </>
  );
};

export default WatchlistBiddingCompnent;
