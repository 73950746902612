export const getProductDescription = (t: any, data: any, isCode: boolean) => {
  const {
    code,
    jewelleryDetails,
    parcel_type,
    productDescription,
    color,
    carat,
    clarity,
    cut_grade,
    flour,
    polish,
    sieve,
    shape,
    symmetry_abbreviation,
  } = data;

  const { jewelleryCondition, jewelleryType, jewelleryDescription }: any = {
    ...jewelleryDetails,
  };

  const PARCEL_TYPE_JEWELLERY = "Jewellery";
  const PARCEL_TYPE_DIAMOND = "Diamond";
  const PARCEL_TYPE_PARCEL = "Parcel";

  // JEWELLERY
  const JEWELLERY_TYPE =
    jewelleryType === undefined || jewelleryType === null ? "" : jewelleryType;
  const JEWELLERY_CONDITION =
    jewelleryCondition === undefined || jewelleryCondition === null
      ? ""
      : jewelleryCondition;
  const JEWELLERY_DESCRIPTION =
    jewelleryDescription === undefined || jewelleryDescription === null
      ? ""
      : jewelleryDescription;

  // DIAMONDS
  const CARAT =
    carat === undefined || carat === null
      ? ""
      : `${carat} ${t("common:preview:ct")}`;
  const COLOR = color === undefined || color === null ? "" : color;
  const CLARITY = clarity === undefined || clarity === null ? "" : clarity;
  const CUTGRADE =
    cut_grade === undefined || cut_grade === null ? "" : cut_grade;
  const FLOUR = flour === undefined || flour === null ? "" : flour;
  const POLISH = polish === undefined || polish === null ? "" : `${polish}`;
  const SYMMETRY =
    symmetry_abbreviation === undefined || symmetry_abbreviation === null
      ? ""
      : `${symmetry_abbreviation}`;

  // PARCEL
  const SIEVE_TYPE =
    sieve === undefined || sieve === null || sieve === "" ? "" : sieve;
  const PRODUCT_SHAPE = shape === undefined || shape === null ? "" : shape;
  const PRODUCT_DESCRIPTION =
    productDescription === undefined || productDescription === null
      ? ""
      : productDescription;
  const CARAT_CT =
    carat === undefined || carat === null ? "" : `${carat} ${"Ct"}`;

  const getJewelleryDescription = () => {
    return `${JEWELLERY_TYPE} ${JEWELLERY_CONDITION} ${JEWELLERY_DESCRIPTION}`;
  };

  const getDiamondDescription = () => {
    return `${CARAT} ${COLOR} ${CLARITY} ${PRODUCT_SHAPE} ${CUTGRADE} ${FLOUR} ${POLISH} ${SYMMETRY} `;
  };

  const getParcelDescription = () => {
    return `${PRODUCT_DESCRIPTION} ${COLOR} ${PRODUCT_SHAPE} ${SIEVE_TYPE} ${CARAT_CT}`;
  };
  let description = "";

  if (parcel_type === PARCEL_TYPE_JEWELLERY) {
    description = getJewelleryDescription();
  } else if (parcel_type === PARCEL_TYPE_DIAMOND) {
    description = getDiamondDescription();
  } else if (parcel_type === PARCEL_TYPE_PARCEL) {
    description = getParcelDescription();
  }
  if (isCode) {
    return `${code} ${description}`;
  } else {
    return ` ${description}`;
  }
};


  export const convertedPlaceHolderValue = (
    currencyCodeVal: any,
    price: any,
    exchangeRate: any
  ) => {
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");
    //get user currency code form local storeage
    if (
      currencyCodeVal === undefined ||
      currencyCodeVal === null ||
      price === undefined ||
      price === null ||
      price === 0 ||
      exchangeRate === undefined ||
      exchangeRate === null
    ) {
      return " ";
    } else {
      if (userCurrencyCode !== currencyCodeVal.code) {
        const rate = exchangeRate?.[0]?.["rate"] ?? 1;
        let conversion = new Intl.NumberFormat("us-en").format(
          Math.round(price * rate)
        );
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions}${currencyCodeVal.symbol}  (${conversion} ${userCurrencySymbol})`;
      } else {
        let conversions = new Intl.NumberFormat("us-en").format(
          Math.round(price)
        );
        return `${conversions} ${userCurrencySymbol}`;
      }
    }
  };