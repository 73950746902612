import React, { useState } from "react";
import moment from "moment";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import Alert from "../../../../common/elements/Alert";
import { selectAllPreviews } from "../../../../redux/slice/annual/commanAnnualSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducer";
import { useTranslation } from "react-i18next";
const API_KEY = "AIzaSyBzzAGqTTpG1W4QnvBktzBJslw08jRmpw0";
const CLIENT_ID =
  "45221629526-k9683f8069sivtc12jhf9joo71n33jsr.apps.googleusercontent.com";
const SCOPES = "https://www.googleapis.com/auth/calendar.events";

let timezone: any = "";
let date = "";
let time: any = "";
let endselectedTime: string = "";
let startselectedTime: string = "";

interface Iprops {
  selectedEvent: any;
}
const GoogleEventComponent = (props: Iprops) => {
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  let { t } = useTranslation();
  const previewList: any = useSelector((state: RootState) =>
    selectAllPreviews(state)
  );

  const handleAddEvent = async () => {
    let { name, id } = props.selectedEvent;
    const selectedDateWiseAuctionDetails = await previewList.find(
      (item: any) => item.perId === id
    );
    if (selectedDateWiseAuctionDetails !== undefined) {
      await new Promise((resolve, reject) => {
        (window as any).gapi.load("client:auth2", {
          callback: resolve,
          onerror: reject,
        });
      });

      await (window as any).gapi.client.init({
        clientId: CLIENT_ID,
        scope: SCOPES,
        apiKey: API_KEY,
        plugin_name: "Zipang",
      });

      const authResult = await (window as any).gapi.auth2
        .getAuthInstance()
        .signIn();
      const accessToken = authResult.getAuthResponse().access_token;
      let { startDate, previewStartDate } = selectedDateWiseAuctionDetails;

      date = startDate;
      startselectedTime = previewStartDate;
      let endTime = moment.utc(date);
      let startTime = moment.utc(previewStartDate);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (endTime !== null || endTime !== undefined) {
        let result = moment.tz(endTime, timezone);
        if (result.format() !== "Invalid date") {
          endselectedTime = result.format();
        } else {
        }
        let DateFormate = moment.tz(endTime, timezone);
        endselectedTime = DateFormate.format();
        let resultTime = moment.tz(startTime, timezone);
        startselectedTime = resultTime.format();
      }

      let userCountry = localStorage.getItem("userCountry");
      let response = await fetch(
        "https://www.googleapis.com/calendar/v3/calendars/primary/events",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            summary: name,
            location: userCountry,
            description: name,
            start: {
              dateTime: startselectedTime,
              timeZone: timezone,
            },
            end: {
              dateTime: endselectedTime,
              timeZone: timezone,
            },
          }),
        }
      );

      if (response.ok) {
        setShowMessage("Event Added successfully!");
        setAlertType("success");
        setOpenToast(true);
        const calendarUrl = `https://calendar.google.com/calendar/r`;
        window.open(calendarUrl);
      } else {
        setShowMessage("Not able To Add Event.");
        setAlertType("error");
        setOpenToast(true);
      }
    } else {
      setShowMessage(`${t("common:home:previweNotStarted")}`);
      setAlertType("error");
      setOpenToast(true);
    }
    setTimeout(() => {
      setOpenToast(false);
    }, 2000);
  };
  return (
    <div title="Copy to Calendar">
      <EventAvailableIcon onClick={handleAddEvent} />
      {openToast && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
    </div>
  );
};

export default GoogleEventComponent;
