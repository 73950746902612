import React, { useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import EllipsisText from "../../../common/elements/EllipsisText";
import { makeLinkClickable, openCertificateLinkPopup } from "../../../common/GenericFunctions";

const StyledItemGrid = styled(Grid)`
`;

const StyledItemContainer = styled(Grid)`
    border-bottom: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    font-size: 0.8em;
    width: 95%;
    margin: 0 auto;
    min-height: 40px;
    display: flex;
    align-items: center;
    .moveToLandingPageLink{
      cursor: pointer;
      color: blue;
      text-decoration: underline;
    }
`;

const StyledHeading = styled(Grid)`
    font-size: 1em;
    font-weight: bold;
`;

const StyledValue = styled(Grid)`
    font-size: 1em;
    max-height: 100px;
`;
export const StyledCertificateValue = styled(Grid)`
  font-size: 1em;
  max-height: 100px;
  color: Blue;
`;

export interface IState {
  showNote: boolean;
}

export interface IProps {
  t: any;
  auctionId: string | number;
  serialNo: string | number;
  code: string;
  carat: string;
  color: string;
  clarity: string;
  cutGrade: string;
  t3EX: string;
  flour: string;
  length: number;
  width: number;
  height: number;
  polish: string;
  symmetry: string;
  shape: string;
  origin: string;
  lab: string;
  certificate: string;
  fancyIntensity: string;
  fancyColor: string;
  caratMinimumPrice: number;
  startingPrice: number;
  rapnetPercentage: number | string | null;
  rapnetPrice: any;
  hnc: string | null;
  preBidPrice: any;
  note: null | string;
  sellingPriceUsd: any;
  auctionDate: string;
  exchangeRate: string;
  remarks: string;
  isPDView: boolean;
  onClickSignIn: () => void;
  parcelType: string;
  sieve: string;
  noOfPieces: number | string | null;
  peicesPerCarat: number | string | null;
  averageSize: any;
  productDescription: string;
  currency: any;
  exchangeRates: any;
  fullDataExchangeRate: any;
  auctionType: any;
}
let prebidAmount: string = '';
let startingAmount: string = '';
let conversionStartingAmount: string = '';
let minimumAmount: string = '';
let conversionMinimumAmount: string = '';
let totalAmount: string = '';
let conversionTotalAmount: string = '';
let rapnetAmount: string = '';
const PerItemDescription = (props: IProps) => {

  useEffect(() => {
    prebidAmount = ''
  }, []);

  let {
    t,
    serialNo,
    code,
    carat,
    color,
    clarity,
    cutGrade,
    t3EX,
    flour,
    length,
    width,
    height,
    polish,
    symmetry,
    shape,
    origin,
    lab,
    certificate,
    fancyIntensity,
    fancyColor,
    caratMinimumPrice,
    startingPrice,
    rapnetPercentage,
    rapnetPrice,
    hnc,
    preBidPrice,
    sellingPriceUsd,
    auctionDate,
    exchangeRate,
    remarks,
    isPDView,
    onClickSignIn,
    parcelType,
    sieve,
    noOfPieces,
    peicesPerCarat,
    averageSize,
    productDescription,
    currency,
    exchangeRates,
    auctionType,
  } = props;
  startingAmount = new Intl.NumberFormat('us-en').format(startingPrice);
  minimumAmount = new Intl.NumberFormat('us-en').format(caratMinimumPrice);
  prebidAmount = new Intl.NumberFormat('us-en').format(preBidPrice);
  rapnetAmount = new Intl.NumberFormat("us-en").format(rapnetPrice);
  let isClickableRemark: any = makeLinkClickable(
    parcelType === "Parcel" ? productDescription : remarks
  );

  if (minimumAmount === null || minimumAmount === 'NaN' || minimumAmount === '0') {
    minimumAmount = '-';
  } else {
    let userCurrencyCode = localStorage.getItem('preferredCurrency');
    let userCurrencySymbol = localStorage.getItem('preferredCurrencySymbol');
    if (userCurrencyCode !== currency['code']) {
      let conversionPrice = new Intl.NumberFormat('us-en').format(Math.round(caratMinimumPrice * exchangeRates[0]['rate']));
      conversionMinimumAmount = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionMinimumAmount = ""
    }
    minimumAmount = `${currency["symbol"]}${minimumAmount} ${conversionMinimumAmount}`;

    if (parcelType !== 'Diamond') {
      let totAmt = caratMinimumPrice * Number(carat)
      if (userCurrencyCode !== currency['code']) {
        let conversionTotAmt = new Intl.NumberFormat('us-en').format(Math.round(totAmt * exchangeRates[0]['rate']))
        conversionTotalAmount = `(${userCurrencySymbol}${conversionTotAmt})`;
      } else {
        conversionTotalAmount = ""
      }
      totalAmount = `${currency['symbol']}${new Intl.NumberFormat('us-en').format(Math.round(totAmt))}`
    }

  }

  if (startingAmount === null || startingAmount === 'NaN' || startingAmount === '0') {
    startingAmount = '-';
  } else {
    let userCurrencyCode = localStorage.getItem('preferredCurrency');
    let userCurrencySymbol = localStorage.getItem('preferredCurrencySymbol');
    if (userCurrencyCode !== currency['code']) {
      let conversionPriceS = new Intl.NumberFormat('us-en').format(Math.round(startingPrice * exchangeRates[0]['rate']));
      conversionStartingAmount = `(${userCurrencySymbol}${conversionPriceS})`;
    } else {
      conversionStartingAmount = ""
    }
    startingAmount = `${currency['symbol']}${startingAmount}`

  }

  if (prebidAmount === '0' || prebidAmount === 'NaN') {
    prebidAmount = '';
  }

  return (
    <StyledItemGrid container={true} spacing={0}>
      {parcelType === "Diamond" ? (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:serialNo")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {serialNo}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:startingPrice")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {minimumAmount}{" "}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:auctionStartDate")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {auctionDate}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:itemCode")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {code}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:noOfPieces")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {noOfPieces === 0 ? "-" : noOfPieces}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:carat")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {carat}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:color")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {color}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:clarity")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {clarity}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:cutGrade")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {cutGrade}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:ex")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {t3EX}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:hnc")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {hnc}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:flour")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {flour}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:lengthWidthHeight")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {length}*{width}*{height}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:shape")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {shape}
            </StyledValue>
          </StyledItemContainer>
          {/* <StyledItemContainer item={true} xs={12}>
                    <StyledHeading item={true} xs={6}>{t('common:button:note')}</StyledHeading>
                    <StyledValue item={true} xs={6}>{note}</StyledValue>
                </StyledItemContainer> */}
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:serialNo")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {serialNo}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:startingPrice")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {minimumAmount}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {" "}
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:shape")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {shape}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:sieve")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {sieve}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:noOfPieces")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {noOfPieces === 0 ? "-" : noOfPieces}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:piecesPCT")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {peicesPerCarat === 0 ? "-" : peicesPerCarat}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:pricePctUsd")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {sellingPriceUsd === 0 ? "-" : sellingPriceUsd}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {" "}
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:totalAmount")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {totalAmount} {conversionTotalAmount}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
        </Grid>
      )}
      {parcelType === "Diamond" ? (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:origin")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {origin}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:polish")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {polish}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:symmetry")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {symmetry}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:lab")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {lab}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:certificate")}
            </StyledHeading>
            <StyledCertificateValue item={true} xs={6}>
              {lab !== "AGT" &&
                lab !== "Agt" &&
                lab !== "" &&
                lab !== undefined ? (
                <a
                  href="#/"
                  onClick={() => openCertificateLinkPopup(lab, certificate)}
                  className="moveToLandingPageLink"
                >
                  {certificate}
                </a>
              ) : (
                <>
                  <span style={{ color: "black" }}>{certificate}</span>
                </>
              )}
            </StyledCertificateValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:fancyIntensity")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {fancyIntensity}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:fancyColor")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {fancyColor}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:amountOfStartingPrice")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {startingAmount} {conversionStartingAmount}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:pricePerCarat")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {sellingPriceUsd === 0 ? "-" : sellingPriceUsd}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:rap")}
            </StyledHeading>
            {isPDView ? (
              <StyledValue item={true} xs={6}>
                {rapnetPercentage}
              </StyledValue>
            ) : (
              <a
                className="moveToLandingPageLink"
                href="#/"
                onClick={onClickSignIn}
              >
                <span className="moveToLandingPageLink">
                  {" "}
                  {t("common:preview:perItemDescription:signInToView")}
                </span>
              </a>
            )}
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:rapPrice")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {rapnetAmount === "NaN" ? "-" : rapnetAmount}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:exchangeRate")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {exchangeRates === undefined ? "" : exchangeRates[0]["rate"]}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:remarks")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {isClickableRemark !== false ? (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      isClickableRemark,
                      "DescriptiveWindowName",
                      "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                    );
                  }}
                >
                  {props.remarks !== "" &&
                    props.remarks !== undefined &&
                    props.remarks !== null ? (
                    <>
                      <EllipsisText text={remarks} length={25} tail={"..."} />
                    </>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                <>
                  {props.remarks !== "" &&
                    props.remarks !== undefined &&
                    props.remarks !== null ? (
                    <>
                      <EllipsisText text={remarks} length={25} tail={"..."} />
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </StyledValue>
          </StyledItemContainer>
        </Grid>
      ) : (
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {auctionType === "Annual"
                ? `${t("common:preview:perItemDescription:startDate")}`
                : `${t(
                  "common:preview:perItemDescription:auctionStartDate"
                )}`}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {auctionDate}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:itemCode")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {code}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:color")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {color}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:totalCts")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {carat}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:avgSize")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {averageSize === "Infinity" || averageSize === 0
                ? "-"
                : averageSize}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:remarks")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {isClickableRemark !== false ? (
                <span
                  style={{ color: "blue", cursor: "pointer" }}
                  onClick={() => {
                    window.open(
                      isClickableRemark,
                      "DescriptiveWindowName",
                      "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                    );
                  }}
                >
                  {productDescription !== "" &&
                    productDescription !== undefined &&
                    productDescription !== null ? (
                    <>
                      <EllipsisText
                        text={productDescription}
                        length={25}
                        tail={"..."}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                <>
                  {productDescription !== "" &&
                    productDescription !== undefined &&
                    productDescription !== null ? (
                    <>
                      <EllipsisText
                        text={productDescription}
                        length={25}
                        tail={"..."}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </StyledValue>
          </StyledItemContainer>
          <StyledItemContainer item={true} xs={12}>
            <StyledHeading item={true} xs={6}>
              {t("common:preview:perItemDescription:exchangeRate")}
            </StyledHeading>
            <StyledValue item={true} xs={6}>
              {exchangeRate}
            </StyledValue>
          </StyledItemContainer>
        </Grid>
      )}
    </StyledItemGrid>
  );
}

export default PerItemDescription;