import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Colors from "../../../common/materials/colors";
import Section from "../Section/Section";
import {
  StyledAnchor,
  StyledFooterComponent,
  StyledFooterContainer,
  StyledFooterLink,
  StyledFooterLinkContainer,
  StyledGrid,
} from "./StyledComponets";

import Logo from "../Static/Icons/Logo";
import ContactUsForm from "./ContactUs";
import {
  Box,
  Link as Anchor,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import {
  redirectToOtherDomain,
  setRedirectPathPostAuth,
  toggleLoginPopup,
} from "../Utils";
import { MobileSignIn } from "../Header/Header";

const LinkList = ({ list }: { list: Array<{ text: string; url: string }> }) => {
  const { t } = useTranslation();
  return list.map((link, i) => (
    <StyledFooterLink key={i}>
      {i === 0 ? (
        <strong>
          <Link to={link.url}>{t(link.text)}</Link>
        </strong>
      ) : (
        <Link to={link.url}>{t(link.text)}</Link>
      )}
    </StyledFooterLink>
  )) as unknown as JSX.Element;
};

const list1: { text: string; url: string; bold?: boolean }[] = [
  {
    text: "common:header:buttons:ea",
    url: "/",
    bold: true,
  },
  {
    text: "common:header:menu:jpa",
    url: "/",
  },
  {
    text: "common:header:menu:oa",
    url: "/",
  },
  {
    text: "common:header:menu:365a",
    url: "/zipang",
  },
  {
    text: "common:header:menu:ma",
    url: "/",
  },
];
export const list2: { text: string; url: string }[] = [
  {
    text: "common:subHeader:buttons:aboutUs",
    url: "/newaboutus",
  },

  {
    text: "common:subHeader:buttons:team",
    url: "/newaboutus#team",
  },
  {
    text: "common:subHeader:buttons:whyUs",
    url: "/newaboutus#whyus",
  },
  {
    text: "common:subHeader:buttons:testimonials",
    url: "/newaboutus#testimonials",
  },

  {
    text: "common:subHeader:buttons:media",
    url: "/newaboutus#media",
  },
];
const list3: { text: string; url: string }[] = [
  {
    text: "common:header:buttons:login",
    url: "/",
  },
  {
    text: "common:header:buttons:register",
    url: "/",
  },
];

const otherImportantLinks = [
  {
    label: "ToS",
    link: "/termsandconditions",
  },
  {
    label: "FAQ",
    link: "/faq",
  },
];

const socials = [
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/sm-icons/FB-mobile.svg",
    link: "https://www.facebook.com/myauctions.jp",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/sm-icons/IG-mobile.svg",
    link: "https://instagram.com/myauctions.jp?igshid=19dk8l9gy1v2j",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/sm-icons/LI-mobile.svg",
    link: "https://www.linkedin.com/company/myauctions/",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/sm-icons/Line-mobile.svg",
    link: "https://lin.ee/2wg2q8j",
  },
  {
    url: "https://d1xs39ogteakr9.cloudfront.net/sm-icons/WhatsApp-mobile.svg",
    link: "https://wa.me/message/DY37FMYCUSEJN1",
  },
];

const Footer = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const { t } = useTranslation();
  const d = new Date();
  const thisYear = d.getFullYear();

  const history = useHistory();

  const goToHome = React.useCallback(() => {
    history.push("/");
  }, []);

  const [createDialog, closeDialog] = useDialog();
  const [showMobileSignIn, setShowMobileSignIn] = useState(false);
  const handleLoginClose = () => setShowMobileSignIn(!showMobileSignIn);

  const handleRedirect = (path?: string) => {
    path && setRedirectPathPostAuth(path);
    if (isMobile) {
      setShowMobileSignIn(true);
    } else {
      toggleLoginPopup();
    }
  };

  const handleRegister = () => {
    createDialog({
      children: (
        <SignUpFormDialog
          onSignUp={closeDialog}
          close={closeDialog}
          isMobile={isMobile}
        />
      ),
      ...SignUpFormDialogOptions,
    });
  };

  const handlePageScrollToEA = () => {
    goToHome();
    setTimeout(() => {
      const inView = document.getElementById("eaSection");
      if (!inView) return;
      const headerOffset = 90;
      const elementPosition = inView.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }, 100);
  };

  return (
    <StyledFooterComponent>
      <MobileSignIn show={showMobileSignIn} onClose={handleLoginClose} />
      <StyledGrid container isMobile={isMobile}>
        <Grid item container sm={8}>
          <Section
            color={Colors.lightGrey}
            alignItems="center"
            justifyContent="space-between"
          >
            {isMobile ? (
              <Grid container onClick={goToHome}>
                <Logo />
              </Grid>
            ) : null}
            <StyledFooterContainer isMobile={isMobile} xs={12}>
              <Grid
                container
                xs={12}
                justifyContent="space-around"
                alignItems="flex-start"
              >
                {isMobile ? null : (
                  <Grid xs={2}>
                    <Logo />
                  </Grid>
                )}
                <Grid xs={6} sm={3}>
                  <StyledAnchor>
                    <strong onClick={handlePageScrollToEA}>
                      {t(list1[0].text)}
                    </strong>
                  </StyledAnchor>
                  <StyledAnchor onClick={() => redirectToOtherDomain("zipang")}>
                    {t(list1[1].text)}
                  </StyledAnchor>
                  <StyledAnchor onClick={() => handleRedirect("preview")}>
                    {t(list1[2].text)}
                  </StyledAnchor>
                  <StyledAnchor onClick={() => redirectToOtherDomain("zipang")}>
                    {t(list1[3].text)}
                  </StyledAnchor>
                  <StyledAnchor
                    onClick={() => redirectToOtherDomain("auctioneer")}
                  >
                    {t(list1[4].text)}
                  </StyledAnchor>
                </Grid>
                <Grid xs={6} sm={3}>
                  <LinkList list={list2} />
                  {isMobile ? (
                    <>
                      <StyledAnchor onClick={() => handleRedirect()}>
                        <strong>{t(list3[0].text)}</strong>
                      </StyledAnchor>
                      <StyledAnchor onClick={handleRegister}>
                        <strong>{t(list3[1].text)}</strong>
                      </StyledAnchor>
                    </>
                  ) : null}
                </Grid>
                {isMobile ? null : (
                  <Grid xs={4} sm={3}>
                    <StyledAnchor onClick={toggleLoginPopup}>
                      <strong>{t(list3[0].text)}</strong>
                    </StyledAnchor>
                    <StyledAnchor onClick={handleRegister}>
                      <strong>{t(list3[1].text)}</strong>
                    </StyledAnchor>
                  </Grid>
                )}
              </Grid>
            </StyledFooterContainer>
            <StyledFooterContainer
              isMobile
              item
              container
              alignItems="flex-end"
              justifyContent="space-between"
            >
              {isMobile ? null : (
                <Grid item xs={12} sm={6}>
                  <Typography
                    align="center"
                    style={{ marginBottom: isMobile ? "1em" : "0" }}
                  >
                    © {thisYear}-{thisYear + 1} {t('common:footer:copyrightFooter')}
                  </Typography>
                </Grid>
              )}
              <Grid xs={12} sm={6} item container direction="row">
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-evenly"
                  style={{ marginBottom: "2em" }}
                >
                  {socials.map((social) => {
                    return (
                      <span>
                        <a target="_blank" href={social.link}>
                          <img alt="social" src={social.url} />
                        </a>
                      </span>
                    );
                  })}
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent="space-around"
                >
                  {otherImportantLinks.map((link) => (
                    <StyledFooterLinkContainer>
                      <StyledFooterLink style={{ padding: 0 }}>
                        <Link to={link.link}>{link.label}</Link>
                      </StyledFooterLink>
                    </StyledFooterLinkContainer>
                  ))}
                </Grid>
              </Grid>
              {isMobile ? (
                <Grid item xs={12} sm={6}>
                  <p></p>
                  <Typography
                    align="center"
                    style={{ marginBottom: isMobile ? "1em" : "0" }}
                  >
                    © {thisYear}-{thisYear + 1} All Rights Reserved.
                  </Typography>
                </Grid>
              ) : null}
            </StyledFooterContainer>
          </Section>
        </Grid>
        <Grid item sm={4} color={Colors.white}>
          <ContactUsForm isMobile={isMobile} />
        </Grid>
      </StyledGrid>
    </StyledFooterComponent>
  );
};

export default Footer;
