import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import AuthService from "../../../auth/AuthService";
import ActionPaginationForProducts from "../../../core-grid/grid-actions/ActionPaginationForProducts";

const StyledCoreDataGridContainer = styled(Grid)``;

const StyledDataWrapper = styled(Grid)`
  margin-top: 15px;
`;

const StyledGridWrapper = styled.div``;

const StyledPaginationContainer = styled(Grid)`
  margin-top: 1em;
  padding: 20px 0;
`;
interface IProps {
  t?: any;
  data?: any;
  totalElements?: string | number | undefined;
  value: (value: boolean, count: number, itemsPerPage: number) => void;
  noProductFound: (noProductFound: any) => void;
  itemPerPage: (value: boolean, count: number, itemsPerPage: number) => void;
  itemsPerPageUseRef?: any;
  selectPageUseRef?: any;
}

interface IState {
  data: any;
  loading: boolean;
  currentPage: number;
  itemsPerPage: number;
  noProductFound: any;
}

const childElementsWithProps = (props: any) => {
  return React.cloneElement(props.children, { ...props });
};

export default class ProductGridDataParentMybids extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      itemsPerPage: 20,
      noProductFound: [],
    };
  }

  Auth = new AuthService();

  async componentDidMount() {
    let Page = localStorage.getItem("onlyMybidsCurrentStart");
    if (Page != null && Page !== "0") {
      let pageNumber: number =
        parseInt(Page) / this.props.itemsPerPageUseRef + 1;
      this.setCurrentPage(pageNumber);
    }
    // if (localStorage.getItem("itemsPerPage") !== null) {
    //   let savedCount: any = localStorage.getItem("itemsPerPage");
    //   this.setState({
    //     itemsPerPage: savedCount,
    //   });
    // }
    if (localStorage.getItem("annualitemsPerPage") !== null) {
      let savedCount: any = localStorage.getItem("annualitemsPerPage");
      this.setState({
        itemsPerPage: savedCount,
      });
    }
    if (this.props.itemsPerPageUseRef !== null) {
      this.setState({
        itemsPerPage: this.props.itemsPerPageUseRef,
      });
    }
    this.setData(this.props.data);
  }

  async componentDidUpdate(prevProps: any) {
    if (
      localStorage.getItem("onlyMybidsCurrentStart") != null &&
      localStorage.getItem("onlyMybidsCurrentStart") === "0" &&
      this.state.currentPage !== 1
    ) {
      this.setCurrentPage(1);
    }
    if (prevProps.data !== this.props.data) {
      this.setData(this.props.data);
    }
    if (prevProps.itemsPerPageUseRef !== this.props.itemsPerPageUseRef) {
      this.setItemsPerPage(this.props.itemsPerPageUseRef);
    }
  }

  setData(data: any) {
    this.setState(() => ({
      data,
    }));
  }

  setCurrentPage(currentPage: number) {
    this.setState(() => ({
      currentPage,
    }));
  }

  setItemsPerPage(itemsPerPage: number) {
    this.setState(() => ({
      itemsPerPage,
    }));
    this.props.itemPerPage(true, 0, itemsPerPage);
  }

  setItemsPerPageHandler = (itemsPerPage: number) => {
    this.setCurrentPage(1);
    this.setItemsPerPage(itemsPerPage);
  };

  paginate = (pageNumber: number) => {
    this.setCurrentPage(pageNumber);
    localStorage.setItem(
      "currentPageOfTenderAllProducts",
      JSON.stringify(pageNumber - 1)
    );
    localStorage.setItem(
      "currentPageOfPreview",
      JSON.stringify(pageNumber - 1)
    );
  };

  forApiCalling = (value: boolean, currentPage: number) => {
    if (
      this.props &&
      this.props.totalElements !== undefined &&
      this.props.totalElements > this.state.itemsPerPage
    ) {
      var count = (currentPage - 1) * this.state.itemsPerPage;
      this.props.value(value, count, this.state.itemsPerPage);
    }
  };

  render() {
    const { t } = this.props;
    const selectedPage = true;
    let currentRecords = [];
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    if (this.state.data.length > 0) {
      currentRecords = this.state.data.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
    }

    return (
      <StyledCoreDataGridContainer container={true} spacing={0}>
        <StyledDataWrapper item={true} xs={12}>
          <StyledGridWrapper>
            {childElementsWithProps({
              selectedPage,
              currentRecords,
              ...this.props,
            })}
          </StyledGridWrapper>
        </StyledDataWrapper>
        {this.props.totalElements && this.props.totalElements > 0 ? (
          <StyledPaginationContainer item={true} xs={12}>
            <ActionPaginationForProducts
              t={t}
              totalElements={this.props.totalElements}
              callbackForApiCalling={this.forApiCalling}
              currentPage={this.state.currentPage}
              itemsPerPage={this.state.itemsPerPage}
              paginateCallback={this.paginate}
            />
          </StyledPaginationContainer>
        ) : null}
      </StyledCoreDataGridContainer>
    );
  }
}
