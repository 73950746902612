import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import { publicApi } from "../../../services/ApiService";
import { IErrorPayload } from "../../interfaces/ErrorInterface";

import { getCountry } from "../../../common/config/app.endpoints";
export interface ICountry {
  readonly perId: string;
  readonly name: string;
  readonly code: string;
}

const countryAdapter = createEntityAdapter();
const initialState = countryAdapter.getInitialState({
  loading: false,
  error: "",
});

const updateListItemWithIdProperty = (list: ICountry[]) => {
  return list.map((item) => {
    return { ...item, id: item.perId };
  });
};

export const getCountryList = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("country/getCountryList", async (data, { rejectWithValue }) => {
  try {
    const response = await publicApi.get(getCountry);
    return updateListItemWithIdProperty(response.data);
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Get country list
    builder.addCase(
      getCountryList.pending,
      (state: any, action: PayloadAction) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getCountryList.fulfilled,
      (state: any, action: PayloadAction) => {
        countryAdapter.setAll(state, action);
        state.loading = false;
      }
    );
    builder.addCase(
      getCountryList.rejected,
      (state: any, action: PayloadAction<IErrorPayload>) => {
        state.error = action.payload || "Error";
        state.loading = false;
        countryAdapter.removeAll(state);
      }
    );
  },
});

export default countrySlice.reducer;

export const {
  selectById: selectCountryById,
  selectIds: selectCountryIds,
  selectEntities: selectCountryEntities,
  selectAll: selectAllCountries,
  selectTotal: selectTotalCountry,
} = countryAdapter.getSelectors<RootState>((state: RootState) => state.country);

// selectors
export const selectCountryLoading = (state: RootState) => state.country.loading;
