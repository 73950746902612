import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import React, { useState } from 'react';

interface IProps {
    t: any;
}
const StyledContainer = styled(Grid)`

`;

const StyledTitleContainer = styled.h2`
  margin-top: 5%;
  font-size: 3em;
  text-align: center;
  color: ${(props) => props.theme.colors.base.textColor};
  padding-top: 1em;
  font-family: Calibre-Regular;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1.1em;
    padding-top: 1em 0;
    margin-top: 30%;
  }
  ${(props) => props.theme.breakpoints.only("sm")} {
    font-size: 1.6em;
    margin-top: 20%;
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    font-size: 2em;
    margin-top: 20%;
  }
  @media (width: 320px) {
    margin-top: 35%;
  }
  @media (width: 280px){
      margin-top: 35%;
  }
`;

const WhyMyAuction = (props: IProps) => {
    const { t } = props;

    return (
        <StyledContainer container spacing={0} id="whyMyAuction">
            <Grid item={true} xs={12} md={12}>
                <StyledTitleContainer>{t('common:landingPage:whyMyAuction:heading')}</StyledTitleContainer>
                {/* <StyledImageFristCont xs={12} item>
                    {lang === 'EN' ?
                    <StyledImageFirst src="/images/whyMyAuction/whyMyAuction_1.png"></StyledImageFirst>
                    :
                    <StyledImageFirst src="/images/whyMyAuction/whyMyAuctionJP_1.png"></StyledImageFirst>
                    }
                    
                    <StyledImageTxt>{t('common:landingPage:whyMyAuction:firstLine')}</StyledImageTxt>
                </StyledImageFristCont>
                <StyledImageFristCont xs={12} item>
                    {lang === 'EN' ?
                    <StyledImageSecond src="/images/whyMyAuction/whyMyAuction_2.png"></StyledImageSecond>
                    :
                    <StyledImageSecond src="/images/whyMyAuction/whyMyAuctionJP_2.png"></StyledImageSecond>
                    }
                    
                    <StyledImageTxt>{t('common:landingPage:whyMyAuction:secondLine')}</StyledImageTxt>
                </StyledImageFristCont> */}
            </Grid>
        </StyledContainer>
    )
}

export default WhyMyAuction;