import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const StyledSelectorContainer = styled.div`
  border: none;
  select {
    padding: 0.3em 0.3em;
    margin: 0em;
    border: none;
    border-radius: 3px;
    font-size: 1em;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    color: ${(props) => props.theme.colors.base.black};
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.2em 0.3em;
      margin: 0.2em;
    }
    background: url("/images/assets/doubledown.png") no-repeat;
    background-position: calc(100% - 0rem) center !important;
    appearance: none !important;
    background-size: 30px 20px;
  }
`;

interface Iprops {
  setValue: (name: string) => void;
  isSellPage: boolean;
}
let sellPageCategory = ["All", "Sold", "Hold", "Unsold"];
let buyPageCategory = ["WatchList&Prebid", "Sold", "Hold", "Unsold"];

export default function CategoryDropDown(props: Iprops) {
  let { setValue, isSellPage } = props;
  let { t } = useTranslation();

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  return (
    <StyledSelectorContainer>
      {isSellPage ? (
        <select onChange={handleChange} style={{ width: '100px' }}>
          {sellPageCategory.map((item: any, index: any) => (
            <option
              key={index}
              style={{ fontSize: "14px" }}
            >
              {item}
            </option>
          ))}
        </select>
      ) : (
        <select onChange={handleChange} style={{ width: '160px' }}>
          {buyPageCategory.map((item: any, index: any) => (
            <option
              key={index}
              style={{ fontSize: "14px" }}
            >{item}</option>
          ))}
        </select>
      )}
    </StyledSelectorContainer>
  );
}
