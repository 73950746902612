import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { buttonGradient } from '../../../common/materials/LinearGradient';

const StyledComponent = styled(Grid)`
    outline: none;
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    padding: 1.2em 0.5em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 400px;
    }
`;

const StyledTextWrapper = styled.div`
    font-size: 1.5em;
    padding-bottom: 2em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

const StyledParticipateWrapper = styled(Grid)`
    text-align: center;
    padding: 1em 2em;
`;

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    font-size: 1.2em;
    padding: 0.5em 0.5em;
    padding-top: 15px;
    text-align: center;
    width: 80px;
    border-radius: 10px;
    font-family: Calibre-SemiBold;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;

export interface IProps {
    t: any;
    openParticipateModal: () => void;
}

const ViewParticipateModal = (props: IProps) => {
    const { t, openParticipateModal } = props;

    const onClickParticipate = () => {
        openParticipateModal()
    }

    return (
        <StyledComponent tabIndex={-1}>
            <StyledWrapper container spacing={0}>
                <StyledParticipateWrapper item md={12} xs={12} lg={12}>
                    <StyledTextWrapper> {t(localStorage.getItem("userType") == "SUBSCRIBED" || "SUB_DOMAIN" ? 'common:preview:modal:participateText' : 'common:preview:modal:participateTextInvitee')}</StyledTextWrapper>
                    <StyledButton onClick={onClickParticipate}>{t('common:button:ok')}</StyledButton>
                </StyledParticipateWrapper>
            </StyledWrapper>
        </StyledComponent>
    );
}

export default ViewParticipateModal;