const ShapesList = [
    {
        id: '1',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round',
        value: "Round",
        selectedValue: false
    }, {
        id: '2',
        image: {
            colored: 'emerald_color.png',
            grey: 'emerald_grey.png'
        },
        name: 'Emerald Cut',
        value: "Emerald Cut",
        selectedValue: false
    }, {
        id: '3',
        image: {
            colored: 'marquis_color.png',
            grey: 'marquis_grey.png'
        },
        name: 'Marquize',
        value: "Marquize",
        selectedValue: false
    }, {
        id: '4',
        image: {
            colored: 'princess_color.png',
            grey: 'princess_grey.png'
        },
        name: 'Princess',
        value: "Princess",
        selectedValue: false
    }, {
        id: '5',
        image: {
            colored: 'oval_color.png',
            grey: 'oval_grey.png'
        },
        name: 'Oval',
        value: "Oval",
        selectedValue: false
    }, {
        id: '6',
        image: {
            colored: 'pear_color.png',
            grey: 'pear_grey.png'
        },
        name: 'Pear Shape',
        value: "Pear Shape",
        selectedValue: false
    }, {
        id: '7',
        image: {
            colored: 'asscher_color.png',
            grey: 'asscher_grey.png'
        },
        name: 'Asscher',
        value: "Asscher",
        selectedValue: false
    }, {
        id: '8',
        image: {
            colored: 'radiant_color.png',
            grey: 'radiant_grey.png'
        },
        name: 'Radiant',
        value: "Radiant",
        selectedValue: false
    },  {
        id: '9',
        image: {
            colored: 'hear_color.png',
            grey: 'hear_grey.png'
        },
        name: 'Heart Shape',
        value: "Heart Shape",
        selectedValue: false
    }, {
        id: '10',
        image: {
            colored: 'cushion_color.png',
            grey: 'cushion_grey.png'
        },
        name: 'Cushion Brilliant',
        value: "Cushion Brilliant",
        selectedValue: false
    }, {
        id: '11',
        image: {
            colored: 'asscher_color.png',
            grey: 'asscher_grey.png'
        },
        name: 'Baguette',
        value: "Baguette",
        selectedValue: false
    }, {
        id: '12',
        image: {
            colored: 'radiant_color.png',
            grey: 'radiant_grey.png'
        },
        name: 'Rectangular',
        value: "Rectangular",
        selectedValue: false
    }, {
        id: '13',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round Brilliant',
        value: "Round Brilliant",
        selectedValue: false
    }, {
        id: '14',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round Brilliant Modified',
        value: "Round Brilliant Modified",
        selectedValue: false
    }, {
        id: '15',
        image: {
            colored: 'square_color.png',
            grey: 'square_grey.png'
        },
        name: 'Square',
        value: "Square",
        selectedValue: false
    }, {
        id: '16',
        image: {
            colored: 'tapered_baguette_color.png',
            grey: 'tapered_baguette_grey.png'
        },
        name: 'Tapered Baguette',
        value: "Tapered Baguette",
        selectedValue: false
    }, {
        id: '17',
        image: {
            colored: 'trilliant_color.png',
            grey: 'trilliant_grey.png'
        },
        name: 'Trilliant',
        value: "Trilliant",
        selectedValue: false
    }, {
        id: '18',
        image: {
            colored: 'unknown_color.png',
            grey: 'unknown_grey.png'
        },
        name: 'Unknown',
        value: "Unknown",
        selectedValue: false
    }
];

export default ShapesList;