import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

const StyledLeftArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 20px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledRightArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  margin-right: 0px;
`;
interface IProps {
  initialProductIndex: number;
  auctionProducts: any;
  getDetails: (id: string) => void;
}

const NavigationControls = (props: IProps) => {
  const history = useHistory();
  const routeMatch = useRouteMatch();
  // @ts-ignore
  const { category, auctionId, auctionDate } = useParams();
  const { initialProductIndex, auctionProducts, getDetails } = props;
  const iconPath = "/images/controls/";

  const [productIndex, setProductIndex] = useState<number>(initialProductIndex);
  const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
  const [productList, setProductList] = useState<any[]>([]);

  useEffect(() => {
    setProductIndex(initialProductIndex);
    setProductList(auctionProducts);

    return () => {
      window.removeEventListener("keydown", keyHandling);
    };
  }, []);

  useEffect(() => {
    setProductIndex(initialProductIndex);
  }, [initialProductIndex]);

  useEffect(() => {
    setProductList(auctionProducts);
  }, [auctionProducts]);

  useEffect(() => {
    setIsNextDisabled(checkIsNextDisabled());
  }, [productList]);

  const checkIsNextDisabled = () => {
    if (productList.length === productIndex) {
      return true;
    } else if (productList.length - 1 === productIndex) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setIsPrevDisabled(productIndex === 0);
    setIsNextDisabled(checkIsNextDisabled());
    setProductIndex(productIndex);
    
  }, [productIndex]);

  const keyHandling = (e: any) => {
    if (e.keyCode === 37) {
      previousButtonHandler();
    } else if (e.keyCode === 39) {
      nextButtonHandler();
    }
  };

  const previousButtonHandler = () => {
    productList &&
      productList.map((product: any, index: number) => {
        if (productIndex > 0) {
          var updatedIndex = productIndex - 1;
          if (updatedIndex === index) {
            setProductIndex(index);
            getDetails(product.perId);

            if (
              routeMatch.path ===
              "/watchlist/details/:auctionId/:productId/:auctionDate"
            ) {
              history.push({
                pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
              });
            } else if (
              routeMatch.path ===
              "/preview/details/:auctionId/:productId/:auctionDate"
            ) {
              history.push({
                pathname: `/preview/details/${auctionId}/${product.perId}/${auctionDate}`,
              });
            } else {
              history.push({
                pathname: `/home/details/${auctionId}/${product.perId}/${auctionDate}`,
              });
            }
          }
        }
      });
  };

  const nextButtonHandler = () => {
    const updatedIndex = productIndex + 1;
    productList &&
      productList.map((product: any, index: number) => {
        if (updatedIndex === index) {
          setProductIndex(index);
          getDetails(product.perId);
          if (
            routeMatch.path ===
            "/watchlist/details/:auctionId/:productId/:auctionDate"
          ) {
            history.push({
              pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else if (
            routeMatch.path ===
            "/preview/details/:auctionId/:productId/:auctionDate"
          ) {
            history.push({
              pathname: `/preview/details/${auctionId}/${product.perId}/${auctionDate}`,
            });
          } else {
            history.push({
              pathname: `/home/details/${auctionId}/${product.perId}/${auctionDate}`,
            });
          }
        }
      });
  };

  const getPrevIcon = () => {
    return isPrevDisabled
      ? "left_arrow_round_edges_disabled.png"
      : "left_arrow_round_edges.png";
  };
  const getNextIcon = () => {
    return isNextDisabled
      ? "right_arrow_round_edges_disabled.png"
      : "right_arrow_round_edges.png";
  };

  return (
    <>
      <StyledLeftArrowIcons
        onClick={previousButtonHandler}
        src={`${iconPath}/${getPrevIcon()}`}
      />
      <StyledRightArrowIcons
        onClick={nextButtonHandler}
        src={`${iconPath}/${getNextIcon()}`}
      />
    </>
  );
};

export default NavigationControls;
