const JewlleryGradingList = [
  {
    id: "1",
    name: "GIA",
    selectedValue: false,
  },
  {
    id: "2",
    name: "CGL",
    selectedValue: false,
  },
  {
    id: "3",
    name: "HRD",
    selectedValue: false,
  },
  {
    id: "4",
    name: "AGT",
    selectedValue: false,
  },
  {
    id: "5",
    name: "DGL",
    selectedValue: false,
  },
  {
    id: "6",
    name: "IGI",
    selectedValue: false,
  },
  {
    id: "7",
    name: "IGT",
    selectedValue: false,
  },
  {
    id: "8",
    name: "OTHER",
    selectedValue: false,
  },
];


export default JewlleryGradingList;
