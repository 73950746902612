import decode from "jwt-decode";
import jwt_decode from "jwt-decode";
import { domainName } from "../../common/config/app.endpoints";

import LocalStorageService from "../LocalStorageService";

let decoded: any = "";
let ifCompanyIdExist: any = {};
let subdomain: any = String;
let finalDomainName: any = "";

class AuthorizationService {
  settings: any;
  callbacks: any;

  constructor() {
    subdomain = window.location.hostname;
    finalDomainName = `${domainName}`.replace(/(^\w+:|^)\/\//, "");
  }

  init(settings: any) {
    this.settings = settings || {};
    this.callbacks = settings.callbacks || {};
  }

  private triggerCallback(functionName: string, eventData?: any): void {
    if (
      this.callbacks &&
      this.callbacks[functionName] !== undefined &&
      typeof this.callbacks[functionName] === "function"
    ) {
      this.callbacks[functionName](eventData);
    } else {
      console.log("AuthorizationService - callbacks not found");
    }
  }

  forPublicApisHeader() {
    subdomain = subdomain.replace(`.${finalDomainName}`, "");
    if (subdomain !== null && subdomain !== "") {
      if (subdomain.toString().toLowerCase().startsWith("www.")) {
        subdomain = subdomain.substring(4);
      } else if (subdomain.toString().toLowerCase() === "www") {
        subdomain = "";
      }
    }
    if (subdomain.includes(".") || subdomain.includes("localhost")) {
      if (subdomain === "sysquareind.localhost") {
        LocalStorageService.setItem("isNormalUser", "subdomain");
        subdomain = "sysquareind";
      } else {
        LocalStorageService.setItem("isNormalUser", "normal");
      }
    } else {
      LocalStorageService.setItem("isNormalUser", "subdomain");
    }

    if (subdomain === "localhost") {
      return {
        "Content-Type": "application/json",
      };
    } else {
      return {
        "Content-Type": "application/json",
        subdomain: subdomain,
      };
    }
  }

  companyId() {
   const token = localStorage.getItem("id_token");
    if (token !== null) {
      decoded = jwt_decode(token);
      decoded = decoded.companyId;
      return decoded;
    }
  }

  headerObjectWithAuthorization() {
    subdomain = subdomain.replace(`${finalDomainName}`, "");
    if (subdomain.lastIndexOf(".") === subdomain.length - 1) {
      subdomain = subdomain.substring(0, subdomain.length - 1);
    }

    if (subdomain !== null && subdomain !== "") {
      if (subdomain.toString().toLowerCase().startsWith("www.")) {
        subdomain = subdomain.substring(4);
      } else if (subdomain.toString().toLowerCase() === "www") {
        subdomain = "";
      }
    }

    localStorage.setItem("subDomain", subdomain);
    ifCompanyIdExist = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("id_token"),
    };
    if (
      decoded !== undefined &&
      subdomain !== undefined &&
      subdomain !== "" &&
      subdomain !== window.location.hostname
    ) {
      Object.assign(ifCompanyIdExist, {
        company_id: this.companyId(),
        subdomain: subdomain,
      });
    } else if (
      decoded === undefined &&
      subdomain !== undefined &&
      subdomain !== window.location.hostname &&
      subdomain !== ""
    ) {
      Object.assign(ifCompanyIdExist, { subdomain: subdomain });
    } else if (
      decoded !== undefined &&
      subdomain === undefined &&
      subdomain === "" &&
      subdomain === window.location.hostname
    ) {
      Object.assign(ifCompanyIdExist, {
        company_id: this.companyId(),
      });
    } else {
      Object.assign(ifCompanyIdExist);
    }
    return ifCompanyIdExist;
  }

  setToken(idToken: any) {
    if (idToken !== null) {
      localStorage.setItem("id_token", idToken);
    }
  }

  getToken() {
    return localStorage.getItem("id_token");
  }

  loggedIn() {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token: any) {
    try {
      const decoded: any = decode(token);
      // Check if token is expired.
      if (decoded.exp < Date.now() / 1000) {
        this.triggerCallback("tokenExpireCallback");
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  }

  logout() {
    LocalStorageService.removeItem("id_token");
    this.triggerCallback("logoutCallback");
  }

  forinquiryApisHeaders() {
    return {
      ...this.headerObjectWithAuthorization(),
      type: "formData",
    };
  }
}
const Auth = new AuthorizationService();

export default Auth;
