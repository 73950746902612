import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import ActionPagination from "../grid-actions/ActionPagination";
import ItemsCount from '../grid-actions/ItemsCount';
import PageSizeSelector from '../grid-actions/ActionPageSizeSelector';

const StyledCoreDataGridContainer = styled(Grid)`
`;

const StyledDataWrapper = styled(Grid)`
    margin-top: 15px;
`;

const StyledGridWrapper = styled.div`
`;

const StyledPaginationContainer = styled(Grid)`
    margin-top: 1em;
    padding: 20px 0;
`;

const StyledActionsContainer = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
`;

const StyledActionBarContainer = styled(Grid)`
    padding: 10px;
    border-radius: 5px;
    box-shadow: 2px 0px 5px 2px ${(props) => props.theme.colors.base.lightGrey};
    &:focus {
        outline: none;
    }
`;


const StyledContainer = styled.div``;

interface IProps {
    t: any;
    data?: any;
}

interface IState {
    data: any,
    loading: boolean;
    currentPage: number;
    itemsPerPage: number;
}

const childElementsWithProps = (props: any) => {
    return React.cloneElement(props.children, { ...props });
};

export default class CoreDataGridOfHome extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            currentPage: 1,
            itemsPerPage: 20
        }
    }

    async componentDidMount() {
        this.setData(this.props.data);
    }

    async componentDidUpdate(prevProps: any) {
        if (prevProps.data !== this.props.data) {
            this.props.data.sort(function (x: { startDate: string | number; }, y: { startDate: string | number; }) {
                var dateA = new Date(x.startDate).getTime();
                var dateB = new Date(y.startDate).getTime();
                return dateA > dateB ? 1 : -1;
            });
            this.setData(this.props.data);
        }
    }

    setData(data: any) {
        this.setState(() => ({
            data
        }));
    }

    setCurrentPage(currentPage: number) {
        this.setState(() => ({
            currentPage
        }));
    }

    setItemsPerPage(itemsPerPage: number) {
        this.setState(() => ({
            itemsPerPage
        }));
    }

    setItemsPerPageHandler = (itemsPerPage: number) => {
        this.setCurrentPage(1);
        this.setItemsPerPage(itemsPerPage);
    }

    paginate = (pageNumber: number) => {
        this.setCurrentPage(pageNumber);
    }

    onClickLowest() {
        let data = this.props.data;
        data.sort(function (x: { totalPrice: number; }, y: { totalPrice: number; }) {
            return x.totalPrice - y.totalPrice;
        });
        this.setData(data);
    }

    onClickHighest() {
        let data = this.props.data;
        data.sort(function (x: { totalPrice: number; }, y: { totalPrice: number; }) {
            return y.totalPrice - x.totalPrice;
        });
        this.setData(data);
    }

    render() {
        const selectedPage = true;
        let totalRecords = this.state.data.length;
        let currentRecords = [];
        const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
        if (this.state.data.length > 0) {            
            totalRecords = this.state.data.length;
            currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
        }
        const { t } = this.props;
        return (
            <StyledCoreDataGridContainer container={true} spacing={0}>
                <StyledActionBarContainer container={true} spacing={0} className="coreActionBarContainer">
                    <Grid item={true} xs={12} lg={3} xl={2}>
                        <ItemsCount t={t} itemsPerPage={this.state.itemsPerPage} currentRecords={currentRecords} totalRecords={totalRecords} currentPage={this.state.currentPage} />
                    </Grid>
                    <StyledActionsContainer item={true} xs={12} lg={9} xl={10}>
                        <StyledContainer>
                        </StyledContainer>
                        <PageSizeSelector setItemsPerPageHandler={this.setItemsPerPageHandler} t={t} />
                    </StyledActionsContainer>
                </StyledActionBarContainer>
                <StyledDataWrapper item={true} xs={12}>
                    <StyledGridWrapper>
                        {childElementsWithProps({ selectedPage, currentRecords, ...this.props })}
                    </StyledGridWrapper>                    
                </StyledDataWrapper>
                <StyledPaginationContainer item={true} xs={12}>
                    <ActionPagination
                        t={t}
                        currentPage={this.state.currentPage}
                        itemsPerPage={this.state.itemsPerPage}
                        totalRecords={totalRecords}
                        paginateCallback={this.paginate}
                    />
                </StyledPaginationContainer>
            </StyledCoreDataGridContainer>
        )
    }
}
