export const bidButtonHandler = (data: any) => {
  const isJPY = data.currency.code === "JPY" ? true : false;
  const bidPrice = data.currentBidPrice;

  var button1 = 0;
  var button2 = 0;
  var button3 = 0;

  var isFirstBtnEnable = false;

  // For Parcel type Auction 
  if (data.parcelType === "Parcel" && data.parcelType !== undefined) {
    if (isJPY) {
      if (bidPrice < 10000) {
        button1 = 100;
        button2 = 500;
        button3 = 1000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 10000 && bidPrice < 100000) {
        button1 = 100;
        button2 = 500;
        button3 = 1000;
      } else if (bidPrice >= 100000 && bidPrice < 3000000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
      } else if (bidPrice >= 3000000 && bidPrice < 10000000) {
        button1 = 100000;
        button2 = 500000;
        button3 = 1000000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 10000000) {
        button1 = 100000;
        button2 = 500000;
        button3 = 1000000;
      }
    } else {
      if (bidPrice < 100) {
        button1 = 1;
        button2 = 5;
        button3 = 10;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 100 && bidPrice < 1000) {
        button1 = 1;
        button2 = 5;
        button3 = 10;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 1000 && bidPrice < 30000) {
        button1 = 10;
        button2 = 50;
        button3 = 100;
      } else if (bidPrice >= 30000 && bidPrice < 100000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 100000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
      }
    }
  } else {
    // For Diamond And Jewellery Auction
    if (isJPY) {
      if (bidPrice < 10000) {
        button1 = 100;
        button2 = 500;
        button3 = 1000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 10000 && bidPrice < 100000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 100000 && bidPrice < 3000000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
      } else if (bidPrice >= 3000000 && bidPrice < 10000000) {
        button1 = 100000;
        button2 = 500000;
        button3 = 1000000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 10000000) {
        button1 = 100000;
        button2 = 500000;
        button3 = 1000000;
      }
    } else {
      if (bidPrice < 100) {
        button1 = 1;
        button2 = 5;
        button3 = 10;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 100 && bidPrice < 1000) {
        button1 = 10;
        button2 = 50;
        button3 = 100;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 1000 && bidPrice < 30000) {
        button1 = 10;
        button2 = 50;
        button3 = 100;
      } else if (bidPrice >= 30000 && bidPrice < 100000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
        isFirstBtnEnable = true;
      } else if (bidPrice >= 100000) {
        button1 = 1000;
        button2 = 5000;
        button3 = 10000;
      }
    }
  }

  return { button1, button2, button3, isFirstBtnEnable };
};