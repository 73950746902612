import { Chip, Grid } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';
import FlotingDots from './FlotingDots';

const StyledMessageContainer = styled.div`
  overflow-wrap: anywhere;
  border-top: 1px solid lightgray;
  background: white;
  
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
   .MuiChip-label{
      font-size: 0.8em;
    }
  }
`;

const StyledMessageText = styled.span`
  color: #5499e4;
  display: inline-block;
  font-size: 12px; 
`;


const StyledBtnContainer = styled.div`
  text-align: end;
`;


const StyledMsgGridContainer = styled(Grid)`
  padding: 10px;
`;

interface IProps {
  userId: string | null;
  items?: any;
  updatedNotification: any;
  onCardClickHandler: (item: any) => void;
}

const NewNotificationComponent = (props: IProps) => {
  const { items, userId, onCardClickHandler } = props;
  return (
    <>
      {items
        .filter((item: any) => item.userId === userId)
        .map((item: any, index: any) => {
          if (item.read === true) {
            return (
              <StyledMessageContainer key={item.id + item.message}>
                <Grid container={true} spacing={0}>
                  {item.userNotificationType === "OUT_BID" ?
                    <StyledMsgGridContainer item xs={11} style={{
                      display: 'flex', flexDirection: 'column',
                    }}>
                      <StyledMessageText> {item.message} </StyledMessageText>
                      <StyledBtnContainer>
                        <Chip
                          variant="outlined"
                          size="small"
                          color="primary"
                          label="Increase Your Bid "
                          style={{ cursor: "pointer", color: "#2660ad" }}
                          onClick={() => {
                            onCardClickHandler(item);
                          }}
                        />
                      </StyledBtnContainer>
                    </StyledMsgGridContainer> :
                    <StyledMsgGridContainer item xs={11}>
                      <StyledMessageText>{item.message}</StyledMessageText>
                    </StyledMsgGridContainer>
                  }
                  <Grid item xs={1}>
                    <FlotingDots
                      itemID={item.id === undefined || item.id === null ? item.userId : item.id}
                      updatedViaFlotingDotsNotification={
                        props.updatedNotification
                      }
                      readTrue={item.read}
                    />
                  </Grid>
                </Grid>
              </StyledMessageContainer>
            );
          } else {
            return (
              <StyledMessageContainer
                key={item.id + item.message}
                style={{
                  background: "lightblue",
                  borderBottom: "1px solid white",
                }}
              >
                <Grid container={true} spacing={0}>
                  {/* <Grid item xs={11}>
                    <StyledMessageText>{item.message}</StyledMessageText>
                  </Grid> */}
                  {item.userNotificationType === "OUT_BID" ?
                    <StyledMsgGridContainer item xs={11} style={{
                      display: 'flex', flexDirection: 'column',
                    }}>
                      <StyledMessageText> {item.message} </StyledMessageText>
                      <StyledBtnContainer>
                        <Chip
                          variant="outlined"
                          size="small"
                          color="primary"
                          label="Increase Your Bid "
                          style={{ cursor: "pointer", color: "#2660ad" }}
                          onClick={() => {
                            onCardClickHandler(item);
                          }}
                        />
                      </StyledBtnContainer>
                    </StyledMsgGridContainer> :
                    <StyledMsgGridContainer item xs={11}>
                      <StyledMessageText>{item.message}</StyledMessageText>
                    </StyledMsgGridContainer>
                  }
                  <Grid item xs={1}>
                    <FlotingDots
                      itemID={item.id === undefined || item.id === null ? item.userId : item.id}
                      updatedViaFlotingDotsNotification={
                        props.updatedNotification
                      }
                      readTrue={item.read}
                    />
                  </Grid>
                </Grid>
              </StyledMessageContainer>
            );
          }
        })}
    </>
  );
}

export default NewNotificationComponent;