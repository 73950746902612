import React, { Component, ChangeEvent } from 'react';
import Grid from "@material-ui/core/Grid";
import styled, { css } from 'styled-components';
import { TextField } from '@material-ui/core';

import { StyledTextInput, StyledTitle, StyledComponent, StyledTitleText } from '../../common/materials/GenericStyleComponent';
import AuthService from '../auth/AuthService';
import { bankDetailUrl } from '../../common/config/app.endpoints';
import { dateBackgroundGradient } from '../../common/materials/LinearGradient';
import IRouterComponentProps from '../../models/IRouterComponentProps';
import { oAuth } from '../../routes/basic/BasicAppRouter';

const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;
    padding: 1em;
    border-radius: 10px; 
    outline: none;  
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 350px;
        overflow-y: scroll;
        height: 350px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
        overflow-y: scroll;
        height: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 600px;
    }
`;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 14px;
    margin: 15px 0px;
    margin-left: 1.2em;
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    margin: 20px 0;
`;

interface IButtonProps {
    disableButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
    ${props => props.disableButton && css`
        cursor: default;
        background: ${dateBackgroundGradient};
    `}
`;

interface IProps extends IRouterComponentProps {
    t?: any;
    closeModal: () => void;
    saveDetail: () => void;
    apiTimeout: (timeout: boolean) => void;
}

interface IState {
    accountHolderName: string;
    accountNumber: string;
    ifscCode: string;
    bankName: string;
    address: string;
    message: string;
    disableButton: boolean;
}

export default class BankDetails extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            accountHolderName: "",
            accountNumber: "",
            ifscCode: "",
            bankName: "",
            address: "",
            message: '',
            disableButton: false
        }
    }

    Auth = new AuthService();

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targeName = e.target.name;
        const targeValue = e.target.value;
        if (targeName === 'accountHolderName') { this.setState({ accountHolderName: targeValue }) }
        if (targeName === 'accountNumber') { this.setState({ accountNumber: targeValue }) }
        if (targeName === 'ifscCode') { this.setState({ ifscCode: targeValue }) }
        if (targeName === 'bankName') { this.setState({ bankName: targeValue }) }
        if (targeName === 'address') { this.setState({ address: targeValue }) }
    }

    handleFormSubmit(e: any) {
        const { t } = this.props;
        e.preventDefault();
        this.setState({
            disableButton: true
        })
        var accountHolderName = this.state.accountHolderName.trim();
        var accountNumber = this.state.accountNumber.trim();
        var ifscCode = this.state.ifscCode.trim();
        var bankName = this.state.bankName.trim();
        var address = this.state.address.trim();
        if (accountHolderName === '' && accountNumber === '' && ifscCode === '' && bankName === '' && address === '') {
            this.setState({
                message: `${t('common:allValidations:emptyFields')}`,
                disableButton: false
            })
        }
        else if (accountHolderName === '') {
            this.setState({
                message: `${t('common:allValidations:bank:accountHolderName')}`,
                disableButton: false
            })
        }
        else if (accountNumber === '') {
            this.setState({
                message: `${t('common:allValidations:bank:accountNumber')}`,
                disableButton: false
            })
        }
        else if (ifscCode === '') {
            this.setState({
                message: `${t('common:allValidations:bank:ifscCode')}`,
                disableButton: false
            })
        }
        else if (bankName === '') {
            this.setState({
                message: `${t('common:allValidations:bank:bankName')}`,
                disableButton: false
            })
        }
        else if (address === '') {
            this.setState({
                message: `${t('common:allValidations:bank:address')}`,
                disableButton: false
            })
        }
        else {
            const formData = {
                accountHolderName: accountHolderName,
                accountNumber: accountNumber,
                ifscCode: ifscCode,
                stabankNamete: bankName,
                address: address
            }
            if (!oAuth()) {
                this.props.history.push('/');
            } else {
                this.Auth.postRequest(`${bankDetailUrl}`, formData, (status: number, response: any) => {
                    if (status === 200) {
                        this.props.saveDetail();
                        this.props.closeModal();
                    }
                    else if (status === 505) {
                        this.props.apiTimeout(true)
                    }
                    else if (status > 200 && status < 500) {
                        this.setState({
                            message: response[0].message
                        })
                    }
                    else {
                        this.setState({
                            message: `${t('common:somethingWentWrong')}`
                        })
                    }
                })
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <StyledComponent tabIndex={-1}>
                <StyledContainerWrapper>
                    <form noValidate={true} onSubmit={this.handleFormSubmit.bind(this)}>
                        <Grid item={true} xs={12} md={12}>
                            <StyledTitle className="fontBold"><StyledTitleText>{t('common:profile:bankDetails:title')}</StyledTitleText></StyledTitle>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <StyledMessage>
                                {this.state.message}
                            </StyledMessage>
                        </Grid>
                        <StyledTextInput>
                            <TextField
                                required
                                fullWidth
                                autoFocus={true}
                                id="accountHolderName"
                                label={t('common:profile:bankDetails:labels:holderName')}
                                variant="outlined"
                                name="accountHolderName"
                                onChange={this.handleChange}
                            />
                        </StyledTextInput>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="accountNumber"
                                        label={t('common:profile:bankDetails:labels:acNumber')}
                                        variant="outlined"
                                        name="accountNumber"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="ifscCode"
                                        label={t('common:profile:bankDetails:labels:ifscCode')}
                                        variant="outlined"
                                        name="ifscCode"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>
                            </Grid>
                        </Grid>
                        <StyledTextInput>
                            <TextField
                                required
                                fullWidth
                                id="bankName"
                                label={t('common:profile:bankDetails:labels:bankName')}
                                variant="outlined"
                                name="bankName"
                                onChange={this.handleChange}
                            />
                        </StyledTextInput>
                        <StyledTextInput>
                            <TextField
                                required
                                fullWidth
                                id="address"
                                label={t('common:profile:bankDetails:labels:bankAddress')}
                                variant="outlined"
                                name="address"
                                onChange={this.handleChange}
                            />
                        </StyledTextInput>
                        <StyledButtonContainer>
                            {this.state.disableButton === false ?
                                <StyledButton onSubmit={this.handleFormSubmit.bind(this)}>
                                    {t('common:button:save')}
                                </StyledButton> :
                                <StyledButton disableButton={this.state.disableButton}>
                                    {t('common:button:save')}
                                </StyledButton>
                            }
                            <StyledButton onClick={() => this.props.closeModal()}>
                                {t('common:button:cancel')}
                            </StyledButton>
                        </StyledButtonContainer>
                    </form>
                </StyledContainerWrapper>
            </StyledComponent>
        )
    }
}