import React, { Component, ChangeEvent } from 'react';
import Grid from "@material-ui/core/Grid";
import styled, { css } from 'styled-components';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { StyledTextInput, StyledComponent, StyledTitleText, StyledSelect } from '../../common/materials/GenericStyleComponent';
import { updateProfileInfo, getCurrency, getCountry } from '../../common/config/app.endpoints';
import AuthService from '../auth/AuthService';
import { buttonGradient, dateBackgroundGradient } from '../../common/materials/LinearGradient';
import IRouterComponentProps from '../../models/IRouterComponentProps';
import { oAuth } from '../../routes/basic/BasicAppRouter';
import MOCSelectCheckmark from '../../common/elements/MOCSelectCheckmark';

const StyledContainerWrapper = styled.div`
  background: ${(props) => props.theme.colors.base.white};
  width: 400px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  outline: none;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
    max-height: 90vh;
    overflow-y: scroll;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
    max-height: 96vh;
    overflow-y: scroll;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    width: 600px;
    max-height: 90vh;
    overflow-y: scroll;
  }
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    // margin: 20px 0;
`;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 14px;
    margin: 15px 0px;
    margin-left: 1.2em;
`;

interface IButtonProps {
    disableButton?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
    ${props => props.disableButton && css`
        cursor: default;
        background: ${dateBackgroundGradient};
    `}
`;

const StyledTitle = styled.div`
  color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    margin-top: 50px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    margin: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-top: 50px;
  }
`;

const StyledForm = styled.form`
  @media (max-width: 500px) {
    margin-top: 150px;
  }
  @media (min-width: 1024px) {
    margin-top: 110px;
  }
  ${(props) => props.theme.breakpoints.up("xl")} {
    margin-top: 0px;
  }
`;

interface IProps extends IRouterComponentProps {
    t?: any;
    closeModal: () => void;
    saveProfile: () => void;
    apiTimeout: (timeout: boolean) => void;
    timeZone: any;
    companyName: string;
    contactNo: string;
    phone: string;
    address: string;
    guarantorAddress: string;
    guarantorName: string;
    timezone: any;
    modeOfCommunication: any;
    countryDetail: any;
    preferredCurrency: any;
}

interface IState {
  companyName: string;
  companyAddress: string;
  guarantorName: string;
  guarantorAddress: string;
  contactNo: string;
  timezone: string;
  modeOfContact: string;
  disableButton: boolean;
  message: string;
  countryList: any;
  selectedCountry: any;
  currencyList: any;
  selectedCurrency: any;
  selectedOptionModeofCommunicationValue:any;
}
let modeofCommunicationOption: any;
let optionModeofCommunicationValue: string[];
export default class SDEditProfile extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      companyName: this.props.companyName,
      companyAddress: this.props.address,
      guarantorName: this.props.guarantorName,
      guarantorAddress: this.props.guarantorAddress,
      contactNo: this.props.phone,
      timezone: this.props.timezone["perId"],
      modeOfContact:
        this.props.modeOfCommunication === undefined
          ? ""
          : this.props.modeOfCommunication,

      disableButton: false,
      message: "",
      countryList: [],
      selectedCountry:
        this.props.countryDetail === undefined ? "" : this.props.countryDetail,
      currencyList: [],
      selectedCurrency:
        this.props.preferredCurrency === undefined
          ? ""
          : this.props.preferredCurrency,
      selectedOptionModeofCommunicationValue:
        this.props.modeOfCommunication === undefined
          ? ""
          : this.props.modeOfCommunication,
    };
    

    this.Auth.getRequestWithoutAuthorization(
      `${getCurrency}`,
      (status: number, response: any) => {
        if (status === 200) {
          this.setState({
            currencyList: response,
          });
        }
      }
    );

    this.Auth.getRequestWithoutAuthorization(
      `${getCountry}`,
      (status: number, response: any) => {
        if (status === 200) {
          this.setState({
            countryList: response,
          });
        }
      }
    );
  }

  Auth = new AuthService();

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "companyName") {
      this.setState({ companyName: targeValue });
    }
    if (targeName === "companyAddress") {
      this.setState({ companyAddress: targeValue });
    }
    if (targeName === "guarantorName") {
      this.setState({ guarantorName: targeValue });
    }
    if (targeName === "guarantorAddress") {
      this.setState({ guarantorAddress: targeValue });
    }
    if (targeName === "contactNo") {
      this.setState({ contactNo: targeValue });
    }
  };

  handleChangeDropDown = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    if (targeName === "timezone") {
      this.setState({ timezone: targeValue });
    }
    if (targeName === "modeOfContact") {
      this.setState({ modeOfContact: targeValue });
    }
    if (targeName === "country") {
      var filterArray = this.state.countryList.filter(function (el: any) {
        return el["name"] === targeValue;
      });
      this.setState({
        selectedCountry: filterArray[0],
      });
    }
    if (targeName === "currency") {
      var filterArrayC = this.state.currencyList.filter(function (el: any) {
        return el["name"] === targeValue;
      });
      this.setState({
        selectedCurrency: filterArrayC[0],
      });
    }
  };

  handleFormSubmit(e: any) {
    this.setState({
      disableButton: true,
    });
    const { t } = this.props;
    e.preventDefault();
    
    var mob_regex = /^(\+){0,1}[0-9]{8,15}$/;

    // var companyName = this.state.companyName.trim();
    var companyAddress = this.state.companyAddress.trim();
    var guarantorName = this.state.guarantorName.trim();
    var guarantorAddress = this.state.guarantorAddress.trim();
    var contactNo =
      this.state.contactNo !== undefined ? this.state.contactNo.trim() : "";
    var timezone = this.state.timezone.trim();
    var modeOfContact = modeofCommunicationOption === undefined ? this.state.modeOfContact : modeofCommunicationOption;
    console.log("modeOfContact", modeOfContact);
    if (
      guarantorName === "" &&
      guarantorAddress === "" &&
      timezone === "" &&
      modeOfContact.length === 0
    ) {
      this.setState({
        message: `${t("common:allValidations:emptyFields")}`,
        disableButton: false,
      });
    }
    //  else if (companyName === "") {
    //   this.setState({
    //     message: `${t("common:profile:legends:validation:enterCompanyName")}`,
    //     disableButton: false,
    //   });
    // } 
    else if (guarantorName === "") {
      this.setState({
        message: `${t("common:profile:legends:validation:enterReferenceName")}`,
        disableButton: false,
      });
    } else if (guarantorAddress === "") {
      this.setState({
        message: `${t(
          "common:profile:legends:validation:enterReferenceAddress"
        )}`,
        disableButton: false,
      });
    } else if (timezone === "") {
      this.setState({
        message: `${t("common:profile:legends:validation:enterTimeZone")}`,
        disableButton: false,
      });
    } else if (modeOfContact.length === 0) {
      this.setState({
        message: `${t("common:profile:legends:validation:enterModeOfContact")}`,
        disableButton: false,
      });
    } else if (
      this.state.selectedCountry === "" ||
      this.state.selectedCountry === undefined
    ) {
      this.setState({
        message: `${t("common:profile:legends:validation:enterCountry")}`,
        disableButton: false,
      });
    } else if (
      this.state.selectedCurrency === "" ||
      this.state.selectedCurrency === undefined
    ) {
      this.setState({
        message: `${t("common:profile:legends:validation:enterCurrency")}`,
        disableButton: false,
      });
    } else {
       const formData = {
         companyName: this.state.companyName,
         address: companyAddress,
         guarantorName: guarantorName,
         guarantorAddress: guarantorAddress,
         phone: contactNo,
         timezone: {
           perId: this.state.timezone,
           name: this.props.timeZone[this.state.timezone],
         },
         modeOfCommunication:
           modeofCommunicationOption === undefined
             ? modeOfContact
             : modeofCommunicationOption,
         preferredCurrency: this.state.selectedCurrency,
         countryDetail: this.state.selectedCountry,
       };

      if (contactNo !== "") {
        if (contactNo.length >= 8 && contactNo.length <= 15) {
          if (mob_regex.test(contactNo)) {
            this.updateProfileCall(formData);
          } else {
            this.setState({
              message: `${t(
                "common:profile:legends:validation:enterContactNoValregex"
              )}`,
              disableButton: false,
            });
          }
        } else {
          this.setState({
            message: `${t(
              "common:profile:legends:validation:enterContactNoValBtw"
            )}`,
            disableButton: false,
          });
        }
      } else {
        this.updateProfileCall(formData);
      }
    }
  }

  updateProfileCall = (data: any) => {
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.postRequest(
        updateProfileInfo,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            this.props.saveProfile();
            this.props.closeModal();
            localStorage.setItem("profileComplete", "true");
            PubSub.publish("ProfileStatus", "Yes");
          } else if (status === 505) {
            this.props.apiTimeout(true);
          } else {
            this.setState({
              message: response[0].message,
            });
          }
        }
      );
    }
  }

  onNatureOfBussinessChangeHandler = (options: string[]) => {
    modeofCommunicationOption= options;
    optionModeofCommunicationValue = options;
  };

  render() {
    const { t } = this.props;
    const natureOfBusinessOptions = [
      "Line",
      "Whatsapp",
      "Email",
      "WeChat",
      "Sms",
    ];
    return (
      <StyledComponent>
        <StyledContainerWrapper>
          <StyledForm
            noValidate={true}
            onSubmit={this.handleFormSubmit.bind(this)}
          >
            <Grid item={true} xs={12} md={12}>
              <StyledTitle className="fontBold">
                <StyledTitleText>
                  {t("common:profile:legends:editProfile")}
                </StyledTitleText>
              </StyledTitle>
            </Grid>
            <Grid item={true} xs={12}>
              <StyledMessage>{this.state.message}</StyledMessage>
            </Grid>
            <Grid container={true} spacing={0}>
              <Grid item={true} xs={12} md={6}>
                <StyledTextInput>
                  <TextField
                    required
                    autoFocus={true}
                    fullWidth
                    disabled
                    aria-disabled                    
                    id="companyName"
                    type="text"
                    label={t("common:profile:legends:companyName")}
                    variant="outlined"
                    name="companyName"
                    value={this.state.companyName}
                  />
                </StyledTextInput>
              </Grid>

              <Grid item={true} xs={12} md={6}>
                <StyledTextInput>
                  <TextField
                    fullWidth
                    id="contactNo"
                    type="text"
                    label={t("common:profile:legends:contactNo")}
                    variant="outlined"
                    name="contactNo"
                    placeholder={t("common:register:placeholder:regexphone")}
                    onChange={this.handleChange}
                    value={this.state.contactNo}
                  />
                </StyledTextInput>
              </Grid>
            </Grid>

            <StyledTextInput>
              <TextField
                fullWidth
                id="companyAddress"
                type="text"
                label={t("common:profile:legends:companyAddress")}
                variant="outlined"
                name="companyAddress"
                onChange={this.handleChange}
                value={this.state.companyAddress}
              />
            </StyledTextInput>
            <StyledTextInput>
              <TextField
                required
                fullWidth
                id="guarantorName"
                type="text"
                label={t("common:profile:legends:referenceName")}
                variant="outlined"
                name="guarantorName"
                onChange={this.handleChange}
                value={this.state.guarantorName}
              />
            </StyledTextInput>
            <StyledTextInput>
              <TextField
                required
                fullWidth
                id="guarantorAddress"
                type="text"
                label={t("common:profile:legends:referenceAddress")}
                variant="outlined"
                name="guarantorAddress"
                onChange={this.handleChange}
                value={this.state.guarantorAddress}
              />
            </StyledTextInput>

            <StyledSelect>
              <FormControl variant="outlined" className="" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("common:profile:legends:timezone")}
                </InputLabel>
                <Select
                  id="timezone"
                  name="timezone"
                  onChange={this.handleChangeDropDown}
                  value={this.state.timezone}
                  label={t("common:profile:legends:timezone")}
                >
                  {Object.keys(this.props.timeZone).map((key) => (
                    <MenuItem value={key}>{this.props.timeZone[key]}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledSelect>
            <StyledSelect>
              <MOCSelectCheckmark
                options={natureOfBusinessOptions}
                onChangeHandler={this.onNatureOfBussinessChangeHandler}
                selectedOptions={
                  this.props.modeOfCommunication === undefined ||
                  this.props.modeOfCommunication === null
                    ? this.state.selectedOptionModeofCommunicationValue
                    : this.props.modeOfCommunication
                }
              />
            </StyledSelect>
            <StyledSelect>
              <FormControl variant="outlined" className="" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("common:register:labels:country")}
                </InputLabel>
                <Select
                  id="country"
                  name="country"
                  onChange={this.handleChangeDropDown}
                  value={this.state.selectedCountry.name}
                  label={t("common:register:labels:country")}
                >
                  {Object.keys(this.state.countryList).map((key) => (
                    <MenuItem value={this.state.countryList[key].name}>
                      {this.state.countryList[key].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledSelect>
            <StyledSelect>
              <FormControl variant="outlined" className="" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t("common:kycFrom:currency")}
                </InputLabel>
                <Select
                  id="currency"
                  name="currency"
                  onChange={this.handleChangeDropDown}
                  value={this.state.selectedCurrency.name}
                  label={t("common:kycFrom:currency")}
                >
                  {Object.keys(this.state.currencyList).map((key) => (
                    <MenuItem value={this.state.currencyList[key].name}>
                      {this.state.currencyList[key].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </StyledSelect>
            <StyledButtonContainer>
              {this.state.disableButton === false ? (
                <StyledButton onSubmit={this.handleFormSubmit}>
                  {t("common:button:save")}
                </StyledButton>
              ) : (
                <StyledButton disableButton={this.state.disableButton}>
                  {t("common:button:save")}
                </StyledButton>
              )}
              <StyledButton onClick={() => this.props.closeModal()}>
                {t("common:button:cancel")}
              </StyledButton>
            </StyledButtonContainer>
          </StyledForm>
        </StyledContainerWrapper>
      </StyledComponent>
    );
  }
}