import React from "react";
import ReactAudioPlayer from "react-audio-player";

const Sound = (formBtn: any) => {
  return (
    <>
      <ReactAudioPlayer
        src={formBtn === true ? "/audio/4.mp3" : "/audio/5.mp3"}
        autoPlay
      />
    </>
  );
};

export default Sound;
