import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import { deleteBankDetailsUrl, markAsDefaultBankDetailsUrl } from '../../../common/config/app.endpoints';
import AuthService from '../../auth/AuthService';
import OptionMenuofBankDetail from '../../shared/OptionMenuofBankDetail';
import { backgroundGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { oAuth } from '../../../routes/basic/BasicAppRouter';

const StyledOption = styled(Grid)`
    width: 100%;
    text-align: right;
`;

const StyledGrid = styled(Grid)`
    border: none;
    width: 100%;
    display: flex-wrap;
    overflow: auto;
    ::-webkit-scrollbar {display:none;};
    .padding {
        color: ${(props) => props.theme.colors.base.black};
        padding: 10px 10px;
    }
    .markasdefault {
        padding: 10px 10px;
        color: ${(props) => props.theme.colors.base.white};
        background: ${backgroundGradient}; 
    }
`;

const StyledLegendField = styled(Grid)`
    margin: 0.2em;
    display: flex;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        justify-content: space-between;
    }
    border-radius: 10px;
`;

const StyledTextWrapper = styled.div`
    padding: 0.2em;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;

interface IProps extends IRouterComponentProps {
    t?: any;
    id: string;
    bankName: string;
    type: null | boolean | string | number;
    zonal: null | string | number;
    primary: boolean | null;
    accountHolderName: string;
    accountNumber: string;
    ifscCode: string;
    address: string;
    didMount: () => void;
    message: (message: string) => void;
    apiTimeout: (timeout: boolean) => void;
}

export default class BankDetailsList extends Component<IProps> {

    Auth = new AuthService();

    selectOptionforMarkDefault = () => {
        const { bankName, accountHolderName, accountNumber, ifscCode, address, id, zonal, t } = this.props;
        const data = {
            id: id,
            zonal: zonal,
            primary: true,
            bankName: bankName,
            accountHolderName: accountHolderName,
            accountNumber: accountNumber,
            ifscCode: ifscCode,
            address: address
        }
        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            this.Auth.postRequest(markAsDefaultBankDetailsUrl, data, (status: number, response: any) => {
                if (status === 200) {
                    this.props.didMount();
                }
                else if (status > 200 && status < 500) {
                    this.props.message(response[0].message)
                }
                else {
                    this.props.message(`${t('common:somethingWentWrong')}`)
                }
            })
        }
    }

    selectOptionforDelete() {
        const { bankName, accountHolderName, accountNumber, ifscCode, address, id, primary, zonal, t } = this.props;
        const data = {
            id: id,
            zonal: zonal,
            primary: primary,
            bankName: bankName,
            accountHolderName: accountHolderName,
            accountNumber: accountNumber,
            ifscCode: ifscCode,
            address: address
        }
        if ( !oAuth()) {
            this.props.history.push('/');
        } else {
            this.Auth.postRequest(deleteBankDetailsUrl, data, (status: number, response: any) => {
                if (status === 200) {
                    this.props.message(response.data.message)
                    this.props.didMount();
                }
                else if (status === 505) {
                    this.props.apiTimeout(true)
                }
                else if (status === 412) {
                    this.props.message(response[0].message)
                }
                else {
                    this.props.message(`${t('common:somethingWentWrong')}`)
                }
            })
        }
    }

    render() {
        const { t, bankName, accountHolderName, accountNumber, ifscCode, address, primary } = this.props;
        return (
            <Grid container={true} spacing={0}>
                {primary === true ?
                    <StyledGrid item xs={12}>
                        <div className="markasdefault">
                            <StyledLegendField>
                                <StyledTextWrapper>{bankName}</StyledTextWrapper>
                            </StyledLegendField>
                            <StyledLegendField>
                                <StyledTextWrapper>{accountHolderName}</StyledTextWrapper>
                            </StyledLegendField>
                            <StyledLegendField>
                                <StyledTextWrapper>{accountNumber}</StyledTextWrapper>
                            </StyledLegendField>
                            <StyledLegendField>
                                <StyledTextWrapper>{ifscCode}</StyledTextWrapper>
                            </StyledLegendField>
                            <StyledLegendField>
                                <StyledTextWrapper>{address}</StyledTextWrapper>
                            </StyledLegendField>
                        </div>
                    </StyledGrid> :
                    <StyledGrid>
                        <Grid container={true} spacing={0}>
                            <StyledGrid>
                                <StyledOption>
                                    <OptionMenuofBankDetail t={t} selectMarkasDefault={() => this.selectOptionforMarkDefault()} deleteAddress={() => this.selectOptionforDelete()} />
                                </StyledOption>
                                <StyledLegendField>
                                    <StyledTextWrapper>{bankName}</StyledTextWrapper>
                                </StyledLegendField>
                                <StyledLegendField>
                                    <StyledTextWrapper>{accountHolderName}</StyledTextWrapper>
                                </StyledLegendField>
                                <StyledLegendField>
                                    <StyledTextWrapper>{accountNumber}</StyledTextWrapper>
                                </StyledLegendField>
                                <StyledLegendField>
                                    <StyledTextWrapper>{ifscCode}</StyledTextWrapper>
                                </StyledLegendField>
                                <StyledLegendField>
                                    <StyledTextWrapper>{address}</StyledTextWrapper>
                                </StyledLegendField>
                            </StyledGrid>
                        </Grid>
                    </StyledGrid>
                }
            </Grid>
        );
    }
}
