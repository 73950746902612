import React from "react";
import { match } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import AppLayout from "../layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import DetailsCarousel, {
  StyledGrid,
  StyledNotAvailableImage,
  StyledProductImage,
} from "./details/DetailsCarousel";
import DetailsNotes from "./details/DetailsNotes";
import AuthService from "../auth/AuthService";
import {
  auctionProductUrl,
  watchlistUrl,
  jewelleryProductDetailsUrl,
} from "../../common/config/app.endpoints";
import Alert from "../../common/elements/Alert";
import LoadingSpinner from "../../common/elements/LoadingSpinner";
import ApiTimeout from "../../modules/ApiTimeout";
import AddorRemoveWatchlist from "./preview/AddorRemoveWatchlist";
import { oAuth } from "../../routes/basic/BasicAppRouter";
import BlockUserModal from "./BlockUser/BlockUserModal";
import { StyledModal } from "../../common/materials/GenericStyleComponent";
import { buttonGradient } from "../../common/materials/LinearGradient";
import ShareProductLinkView from "./ShareProductLinkView";
import copy from "copy-to-clipboard";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import JewelleryPerItemDescription from "./details/JewelleryPerItemDescription";
import JewelleryPreBiddingForm from "./details/JewelleryPreBiddingForm";
import { StyledInquiryContainer } from "./details/inquiry/InquiryDetailsCss";
import ProductDetailsInquiry from "./details/inquiry/ProductDetailsInquiry";
import BasicPopover from "./preview/exratemarquee/BasicPopover";
import ProductDescription from "../shared/ProductDescription";

const StyledLayoutGrid = styled(Grid)`
  flexgrow: 1;
  width: 100% !important;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  background: ${(props) => props.theme.colors.base.white};
  padding: 50px 70px;
  ${(props) => props.theme.breakpoints.between("xs", "lg")} {
    padding: 30px 30px 30px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding-left: 8px;
  }
`;

const StyledActionContainer = styled(Grid)``;

const StyledTitleContainer = styled(Grid)`
  padding: 20px 0;
  ${(props) => props.theme.breakpoints.down("xs")} {
    padding: 15px 10px 10px;
  }
`;
const StyledBackBtnContainer = styled(Grid)`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  .spanText {
    font-size: 1em;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const StyledBackBtn = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
`;
const StyledBrandName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1.5em;
  font-weight: bold;
`;

const StyledProductName = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 1em;
`;

const StyledProductCarousalColumn = styled(Grid)`
  padding-right: 20px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 0px;
  }

  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    height: 700px;
  }
`;

const StyledItemDesc = styled(Grid)`
  border: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.grey};
  padding: 8px;
  border-radius: 3px;
  font-size: 0.8em;
  margin-top: 10px;
  flex-direction: row;
  display: flex;
`;

const StyledGridWrapper = styled(Grid)`
  display: flex;
  border-bottom: 1px solid
    ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledContentWrapper = styled(Grid)`
  padding-top: 20px;
`;

const StyledArrowIcons = styled.img`
  width: 2em;
  height: 2em;
  cursor: pointer;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 30px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 20px;
  }
`;

const StyledArrowIconsID = styled.img`
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    margin-right: 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    margin-right: 5px;
  }
`;

const StyledName = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  text-align: right;
`;

const StyledLabel = styled(Grid)`
  font-size: 1em;
  font-weight: bold;
  padding-left: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.5em;
  text-align: center;
`;

const StyledArrowWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const StyledWrapperID = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.1em;
  padding: 0.5em;
  border-radius: 5px;
  width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledTooltipContainer = styled(Grid)``;

const StyledTooltipText = styled.div`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  font-size: 12px;
`;

const StyledBidContainer = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.darkGrey};
`;

interface DetailParams {
  auctionId: string;
  productId: string;
  auctionDate: string;
}

interface IState {
  items: any;
  openToast: boolean;
  showAlertMessage: string;
  index: number;
  isLoading: boolean;
  alertType: string;
  auctionProducts: Array<any>;
  totalElements: number;
  apiTimeout: boolean;
  userBlock: boolean;
  openShareModal: boolean;
  isPDView: boolean;
  count: number;
  fullData: any;
  openInquiry: boolean;
}

interface IProductDetailsViewProps extends IRouterComponentProps {
  match: match<DetailParams>;
  t?: any;
  location: any;
}

let auctionDate: string = "";
let productList: [] = [];
let filteredValues: any = "";
let joinedAuctions: any = [];
let body: any = "";
let data = {};
var filter: [] | null | string = [];
var productID: any = "";
let isAuctionEnd: any;
let isReadOnlyUser: any;
let redirectfromAdmin: any;

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    cursor: "pointer",
    color: "#2d75c2",
  },
  tooltip: {
    fontSize: "12px",
    backgroundColor: "#2d75c2",
  },
}));


function TooltipContant(props: JSX.IntrinsicAttributes & TooltipProps) {
  const classes = useStylesBootstrap();
  return <Tooltip arrow classes={classes} {...props} />;
}
export default class ProductDetailsView extends React.Component<
  IProductDetailsViewProps,
  IState
> {
  constructor(props: IProductDetailsViewProps) {
    super(props);
    this.state = {
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: 0,
      apiTimeout: false,
      userBlock: false,
      openShareModal: false,
      isPDView: true,
      count: 0,
      fullData: "",
      openInquiry: false,
    };
  }

  Auth = new AuthService();

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    data = {};
    body = "";
    filter = [];
    if (this.props.match.params.auctionDate !== undefined) {
      auctionDate = this.props.match.params.auctionDate;
    }
    filteredValues = localStorage.getItem("filteredValues");
    filteredValues = JSON.parse(filteredValues);
    if (filteredValues !== null) {
      filteredValues.map((item: any, index: number) => {
        if (item.name === "auctionId") {
          filteredValues.splice(index, 1);
        }
      });
    }
    isReadOnlyUser = localStorage.getItem("isReadOnlyUser");
    isReadOnlyUser = JSON.parse(isReadOnlyUser);
    joinedAuctions = localStorage.getItem("joinedAuctions");
    joinedAuctions = JSON.parse(joinedAuctions);
    this.auctionProducts();
    this.getDetails(this.props.match.params.productId);
    window.addEventListener("keydown", this.keyHandling);
    const urlParams = new URLSearchParams(window.location.search);
    isAuctionEnd = urlParams.get("isAuctionend");
    redirectfromAdmin = urlParams.get("redirectFromPreview");
  }

  componentWillUnmount() {
    this.setState({
      items: "",
      openToast: false,
      showAlertMessage: "",
      index: 0,
      isLoading: false,
      alertType: "",
      auctionProducts: [],
      totalElements: 0,
      apiTimeout: false,
    });
    localStorage.removeItem("pathname");
    window.removeEventListener("keydown", this.keyHandling);
  }

  auctionProducts() {
    this.setState({
      openToast: false,
    });
    body =
      filteredValues === "null" || filteredValues === null
        ? filter
        : filteredValues;
    body.push({
      match: "exact",
      name: "auctionId",
      value: this.props.match.params.auctionId,
    });
    data = {
      filters: {
        or: body,
      },
      page: {
        start: 0,
        count: 1000,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Jewellery",
            },
          ],
        },
        page: {
          start: 0,
          count: 1000,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
      localStorage.removeItem("optionValue");
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "notUpdatedProducts");
    }
  }

  getUpdatedProducts = (records: number) => {
    data = {
      filters: {
        and: body,
      },
      page: {
        start: records,
        count: 1000,
        sort: {
          orders: [
            {
              property: "serialNo",
              direction: "ASC",
            },
          ],
        },
      },
    };
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Jewellery",
            },
          ],
        },
        page: {
          start: records,
          count: 1000,
          sort: {
            orders: [
              {
                property: "serialNo",
                direction: "ASC",
              },
            ],
          },
        },
      };
    }
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.callApi(data, "updatedProducts");
    }
  };

  callApi(data: any, product: string) {
    const { t } = this.props;
    var url = "";
    if (
      this.props.match.path ===
      "/watchlist/details/:auctionId/:productId/:auctionDate"
    ) {
      url =
        watchlistUrl +
        `?auctionId=${this.props.match.params.auctionId}&auctionType=Jewellery`;
    } else {
       const urlParams = new URLSearchParams(window.location.search);
       redirectfromAdmin = urlParams.get("redirectFromPreview");
      if (redirectfromAdmin === "true") {
        url =
          auctionProductUrl +
          `?auctionId=${this.props.match.params.auctionId}&redirectFromPreview=true`;
      } else {
       url =
         auctionProductUrl + `?auctionId=${this.props.match.params.auctionId}`;
      }
      
    }
    this.Auth.postRequest(`${url}`, data, (status: any, response: any) => {
      if (status === 200) {
        if (product === "updatedProducts") {
          var local: any = {};
          local = response.results;
           const moreProduct = this.state.auctionProducts.concat(local);
           this.setState({
             auctionProducts: moreProduct,
           });
        } else {
          this.setState({
            auctionProducts: response.results,
          });
        }
        if (response.results.length === 0) {
         if (isAuctionEnd === null || isAuctionEnd === undefined || isAuctionEnd === "") {
            this.props.history.push("/preview");
          }
        }
        this.setState({
          totalElements: response.totalElements,
        });
        this.getProducts(this.state.auctionProducts);
      } else if (status === 403) {
        this.setState({ userBlock: true });
      } else if (status === 404) {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:invalidRequest")}`,
          isLoading: false,
        });
      } else if (status === 505) {
        this.setState({
          apiTimeout: true,
        });
      } else {
        this.setState({
          alertType: "error",
          openToast: true,
          showAlertMessage: `${t("common:somethingWentWrong")}`,
          isLoading: false,
        });
      }
    });
  }

  getProducts(products: any) {
    productList = products;
    if (this.state.auctionProducts.length < this.state.totalElements) {
      this.getUpdatedProducts(this.state.auctionProducts.length);
    } else {
      this.checkProductId();
    }
  }

  checkProductId() {
    if (productList !== null) {
      productList.map((product: any, index: number) => {
        if (product.perId === this.props.match.params.productId) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          productID = product.perId;
        }
      });
    }
  }

  getDetails = (productId: string | number) => {
    // this.setState({
    //     openToast: false,
    // })
    if (!oAuth()) {
      this.props.history.push("/");
    } else {
      this.Auth.getRequest(
        `${jewelleryProductDetailsUrl}/${productId}/detail`,
        (status: number, response: any) => {
          localStorage.setItem("auctionState", response.auctionState);
          this.setState({
            items: "",
          });
          if (status === 200) {
            if (response.subItems) {
              var rows = [];

              for (var i = 0; i < response.subItems.length; i++) {
                let obj = response.subItems[i];
                if (obj.medias !== undefined){
                  for (var j = 0; j < obj.medias.length; j++) {
                    rows.push(obj.medias[j]);
                  }
                }
              }

              var objModify = response;
              objModify.medias = objModify.medias.concat(rows);

              this.setState({
                items: objModify,
                isLoading: false,
                fullData: response,
                count: 0,
              });
            } else {
              this.setState({
                items: response,
                isLoading: false,
                fullData: response,
                count: 0,
              });
            }
          } else if (status === 403) {
            this.setState({ userBlock: true });
          } else if (status === 505) {
            this.setState({
              apiTimeout: true,
              isLoading: false,
            });
          } else {
            this.setState({
              alertType: "error",
              openToast: true,
              showAlertMessage: response,
              isLoading: false,
            });
          }
        }
      );
    }
  };

  keyHandling = (e: any) => {
    if (e.keyCode === 37) {
      this.previousButton();
    } else if (e.keyCode === 39) {
      this.nextArrow();
    }
  };

  previousButton = () => {
    this.setState({
      openToast: false,
    });
    this.state.auctionProducts.map((product: any, index: number) => {
      if (this.state.index > 0) {
        var updatedIndex = this.state.index - 1;
        if (updatedIndex === index) {
          this.setState({
            index: index,
          });
          this.getDetails(product.perId);
          if (
            this.props.match.path ===
            "/watchlist/details/:auctionId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else if (
            this.props.match.path ===
            "/preview/jewelleryPreview/details/:auctionId/:productId/:auctionDate"
          ) {
            this.props.history.push({
              pathname: `/preview/jewelleryPreview/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
            });
          } else {
            this.props.history.push({
              pathname: `/home/jewellery/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
            });
          }
        }
      }
    });
  };

  handleBackBtn = () => {
    if (this.state.items && this.state.items.serialNo) {
      localStorage.setItem(
        "currentPDItem",
        JSON.stringify(this.state.items.serialNo)
      );
    }
    let filteredValue = this.state.auctionProducts.filter(
      (value) => value.serialNo === this.state.items.serialNo
    );
    let index = this.state.auctionProducts.indexOf(filteredValue[0]);
    // login to check if user has increased items per page
    if (localStorage.getItem("itemsPerPage")) {
      let savedCount: any = localStorage.getItem("itemsPerPage");
      let endCount: number = parseInt(savedCount);
      let sum = (index + 1) / endCount;
      let page = Math.ceil(sum);
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    } else {
      let page = Math.ceil((index + 1) / 20);
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }

    let val = localStorage.getItem("optionValue");
    if (val !== "" && val !== "null" && val != null) {
      let page = 1;
      localStorage.setItem("currentPdPage", JSON.stringify(page));
    }
    this.props.history.push(`/preview`);
    if (redirectfromAdmin === "true") {
      this.props.history.push({
        pathname: `/preview/jewelleyProducts/${
          this.props.match.params.auctionId
        }/${this.state.items.auctionName}/${auctionDate}?source=admin`,
      });
    } else {
     this.props.history.push({
       pathname: `/preview/jewelleyProducts/${
         this.props.match.params.auctionId
       }/${this.state.items.auctionName}/${auctionDate}`,
     });
    }
   
  };

  nextArrow = () => {
    this.setState({
      openToast: false,
    });
    var updatedIndex = this.state.index + 1;
    this.state.auctionProducts.map((product: any, index: number) => {
      if (updatedIndex === index) {
        this.setState({
          index: index,
        });
        if (
          this.state.auctionProducts.length === index + 1 &&
          this.state.totalElements !== this.state.auctionProducts.length
        ) {
          if (updatedIndex < this.state.totalElements) {
            this.getUpdatedProducts(index + 1);
          }
        }
        this.getDetails(product.perId);
        if (
          this.props.match.path ===
          "/watchlist/details/:auctionId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/watchlist/details/${product.auctionId}/${product.perId}/${auctionDate}`,
          });
        } else if (
          this.props.match.path ===
          "/preview/jewelleryPreview/details/:auctionId/:productId/:auctionDate"
        ) {
          this.props.history.push({
            pathname: `/preview/jewelleryPreview/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
          });
        } else {
          this.props.history.push({
            pathname: `/home/jewellery/details/${this.props.match.params.auctionId}/${product.perId}/${auctionDate}`,
          });
        }
      }
    });
  };

  nextButtonSubItem = () => {
    if (this.state.fullData.subItems.length > this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count],
        count: this.state.count + 1,
      });
    }
  };

  previousButtonSubItem = () => {
    if (this.state.count === 1) {
      this.setState({
        items: this.state.fullData,
        count: this.state.count - 1,
      });
    } else if (this.state.fullData.subItems.length === this.state.count) {
      this.setState({
        items: this.state.fullData.subItems[this.state.count - 2],
        count: this.state.count - 1,
      });
    } else {
      if (this.state.count > 0) {
        this.setState({
          items: this.state.fullData.subItems[this.state.count - 2],
          count: this.state.count - 1,
        });
      }
    }
  };

  timeout = (timeout: boolean) => {
    if (timeout === true) {
      this.setState({
        apiTimeout: true,
      });
    }
  };

  alertType = (alertType: string, openToast: boolean, showMessage: string) => {
    this.setState({
      alertType: alertType,
      openToast: openToast,
      showAlertMessage: showMessage,
    });
  };

  blockCloseModal = () => {
    this.Auth.logout();
    this.props.history.replace("/");
  };

  shareButtonAction() {
    const { t } = this.props;
    let languageCode = localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    copy(`${window.location.hostname}/${languageCode}/product/jewellery/${this.props.match.params.productId}/${auctionDate}/detail`);

    this.setState({
      alertType: "success",
      openToast: true,
      showAlertMessage: `${t("common:preview:linkCopied")}`,
      isLoading: false,
    });
  }

  onCloseModal = () => {
    this.setState({
      openShareModal: false,
      openInquiry: false,
    });
  };

  onCopyLink = () => {
    this.setState({
      openShareModal: false,
    });
  };

  getProductDetailDescription = () => {
    const code = this.state.fullData.code;
    let typeConditionDescription = "";
    if (
      this.state.fullData.jewelleryDetails !== undefined &&
      this.state.fullData.jewelleryDetails != null
    ) {
      typeConditionDescription = `${this.state.fullData.jewelleryDetails.jewelleryType} ${this.state.fullData.jewelleryDetails.jewelleryCondition} ${this.state.fullData.jewelleryDetails.jewelleryDescription}`;
    }
    return `${code} ${typeConditionDescription}`;
  };

  oninquiry = () => {
    this.setState({
      openInquiry: true,
    });
  };

  getMediasForCarosuel = () => {
    const medias = [...this.state.items.medias];
    for (let i = 0; i < medias.length; i++) {
      let mediaItem = medias[i];
      if (mediaItem.mediaType === "PHOTO") {
        if (mediaItem.primary === true) {
          medias.splice(i, 1);
          medias.unshift(mediaItem);
          break;
        } else {
          // medias.splice(i, 1);
          // medias.unshift(mediaItem);
        }
      }
    }
    return medias;
  };

  render() {
    const { t } = this.props;
    return (
      <AppLayout {...this.props}>
        {this.state.apiTimeout === true ? (
          <ApiTimeout t={t} {...this.props} />
        ) : (
          <Grid>
            {this.state.isLoading === true ? (
              <div className="loader">
                <LoadingSpinner />
              </div>
            ) : (
              <StyledLayoutGrid container={true} spacing={0}>
                <StyledGridWrapper item={true} xs={12} xl={12} lg={12} md={12}>
                  <Grid container={true} spacing={0}>
                    <StyledBackBtnContainer item={true} xs={12}>
                      <StyledBackBtn
                        onClick={this.handleBackBtn}
                        src="/images/controls/left_arrow_round_edges.png"
                      />
                      <span className="spanText" onClick={this.handleBackBtn}>
                        {t("common:preview:showAllProduct")}
                      </span>
                    </StyledBackBtnContainer>

                    <StyledTitleContainer
                      item={true}
                      xs={12}
                      sm={12}
                      xl={8}
                      lg={8}
                      md={12}
                    >
                      <StyledBrandName>
                        <ProductDescription
                          truncateLength={100}
                          data={this.state.items}
                          isTendor={false}
                        />
                      </StyledBrandName>
                    </StyledTitleContainer>
                    <StyledActionContainer
                      item={true}
                      xs={12}
                      sm={12}
                      xl={4}
                      lg={4}
                      md={12}
                    >
                      <StyledWrapper container={true} spacing={0}>
                        <Grid item xs={8} sm={6} md={6} lg={6}>
                          <Grid container={true} spacing={0}>
                            {this.state.items !== "" && this.state.count === 0 && (
                              <StyledTooltipContainer item={true} xs={6}>
                                <TooltipContant
                                  placement="top"
                                  disableTouchListener
                                  title={t(
                                    "common:preview:productDetail:jewelleryShareTooltip"
                                  )}
                                >
                                  <StyledTooltipText>
                                    <StyledButton
                                      onClick={this.shareButtonAction.bind(
                                        this
                                      )}
                                    >
                                      {t("common:preview:productDetail:share")}
                                    </StyledButton>
                                  </StyledTooltipText>
                                </TooltipContant>
                              </StyledTooltipContainer>
                            )}

                            <Grid item={true} xs={6}>
                              {this.state.items !== "" &&
                                this.state.count === 0 && (
                                  <TooltipContant
                                    placement="top"
                                    disableTouchListener
                                    title={t(
                                      "common:preview:productDetail:watchTooltip"
                                    )}
                                  >
                                    <StyledTooltipText>
                                      <AddorRemoveWatchlist
                                        {...this.props}
                                        t={t}
                                        {...this.state.items}
                                        getDetails={this.getDetails}
                                        alertType={this.alertType}
                                        apiTimeout={this.timeout}
                                        isReadOnlyUser={isReadOnlyUser}
                                        isSeller={
                                          redirectfromAdmin === "true"
                                            ? true
                                            : false
                                        }
                                      />
                                    </StyledTooltipText>
                                  </TooltipContant>
                                )}
                            </Grid>
                          </Grid>
                        </Grid>
                        <StyledArrowWrapper item xs={4} sm={6} md={6} lg={6}>
                          {this.state.index === 0 ? (
                            <StyledArrowIcons
                              onClick={this.previousButton}
                              src="/images/controls/left_arrow_round_edges_disabled.png"
                            />
                          ) : (
                            <StyledArrowIcons
                              onClick={this.previousButton}
                              src="/images/controls/left_arrow_round_edges.png"
                            />
                          )}
                          {productList.length - 1 === this.state.index ? (
                            <StyledArrowIcons
                              onClick={this.nextArrow}
                              src="/images/controls/right_arrow_round_edges_disabled.png"
                            />
                          ) : (
                            <StyledArrowIcons
                              onClick={this.nextArrow}
                              src="/images/controls/right_arrow_round_edges.png"
                            />
                          )}
                        </StyledArrowWrapper>
                      </StyledWrapper>
                    </StyledActionContainer>
                  </Grid>
                </StyledGridWrapper>
                <Grid item={true} xs={12}>
                  <StyledBidContainer container={true} spacing={0}>
                    <Grid item={true} xs={12} sm={6}>
                      <Grid container={true} spacing={0}>
                        <Grid item={true} xs={12} xl={6} lg={6}>
                          {this.state.items !== "" && (
                            <DetailsNotes
                              t={t}
                              {...this.props}
                              {...this.state.items}
                              count={this.state.count}
                              apiTimeout={this.timeout}
                              noteSaved={() =>
                                this.getDetails(
                                  this.props.match.params.productId
                                )
                              }
                              isSeller={
                                redirectfromAdmin === "true" ? true : false
                              }
                              auctionType={this.state.fullData.auctionType}
                              isReadOnlyUser={isReadOnlyUser}
                            />
                          )}
                          {this.state.fullData.note !== "" && (
                            <StyledLabel>
                              {this.state.fullData.note}
                            </StyledLabel>
                          )}
                        </Grid>
                        <Grid item={true} xs={12} xl={6} lg={6}>
                          {this.state.items !== "" && (
                            <JewelleryPreBiddingForm
                              {...this.props}
                              t={t}
                              {...this.state.items}
                              count={this.state.count}
                              apiTimeout={this.timeout}
                              cancelPreBid={() =>
                                this.getDetails(
                                  this.props.match.params.productId
                                )
                              }
                              preBidAmountSaved={() =>
                                this.getDetails(
                                  this.props.match.params.productId
                                )
                              }
                              isSeller={
                                redirectfromAdmin === "true" ? true : false
                              }
                              isReadOnlyUser={isReadOnlyUser}
                            />
                          )}
                          {this.state.fullData.preBidPrice != null && (
                            <StyledLabel>
                              {this.state.fullData.currency["symbol"]}
                              {Intl.NumberFormat("us-en").format(
                                Number(this.state.fullData.preBidPrice)
                              )}{" "}
                              {localStorage.getItem("preferredCurrency") !==
                              this.state.fullData.currency["code"] ? (
                                <>
                                  {`(${localStorage.getItem(
                                    "preferredCurrencySymbol"
                                  )}`}
                                  {Intl.NumberFormat("us-en").format(
                                    Math.round(
                                      Number(this.state.fullData.preBidPrice) *
                                        this.state.fullData.exchangeRates[0][
                                          "rate"
                                        ]
                                    )
                                  )}
                                  {")"}
                                </>
                              ) : (
                                ""
                              )}
                            </StyledLabel>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <StyledName item={true} xs={12} sm={6}>
                      {this.state.items.auctionName}
                    </StyledName>
                    <div style={{ width: "100%", height: "20px" }}> </div>
                  </StyledBidContainer>
                </Grid>
                <Grid item={true} xs={12}>
                  <StyledContentWrapper container={true} spacing={0}>
                    <StyledProductCarousalColumn
                      item={true}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={5}
                      xl={4}
                    >
                      {this.state.items.medias !== undefined &&
                      this.state.items.medias.length > 0 ? (
                        <DetailsCarousel
                          // {...this.state.items}
                          medias={this.getMediasForCarosuel()}
                          t={t}
                          parcelType={this.state.items.parcelType}
                        />
                      ) : (
                        <StyledGrid container={true} spacing={0}>
                          <StyledNotAvailableImage>
                            <StyledProductImage src="/images/default-diamond.png"></StyledProductImage>
                          </StyledNotAvailableImage>
                        </StyledGrid>
                      )}
                    </StyledProductCarousalColumn>
                    <Grid item={true} xs={12} sm={6} md={6} lg={7} xl={8}>
                      <Grid item={true} xs={12}>
                        <StyledWrapperID item={true} xs={12}>
                          <StyledItemDesc item={true} xs={12}>
                            {t("common:preview:productDetail:itemDescription")}
                            {": "}
                            {this.state.fullData !== "" && (
                              <StyledProductName>
                                <ProductDescription
                                  truncateLength={90}
                                  data={this.state.items}
                                  isTendor={false}
                                />
                              </StyledProductName>
                            )}
                          </StyledItemDesc>
                          {this.state.fullData.subItems != null && (
                            <>
                              {this.state.count === 0 ? (
                                <StyledArrowIconsID
                                  onClick={this.previousButtonSubItem}
                                  src="/images/controls/left_arrow_round_edges_disabled.png"
                                />
                              ) : (
                                <StyledArrowIconsID
                                  onClick={this.previousButtonSubItem}
                                  src="/images/controls/left_arrow_round_edges.png"
                                />
                              )}
                              {this.state.fullData.subItems.length ===
                              this.state.count ? (
                                <StyledArrowIconsID
                                  onClick={this.nextButtonSubItem}
                                  src="/images/controls/right_arrow_round_edges_disabled.png"
                                />
                              ) : (
                                <StyledArrowIconsID
                                  onClick={this.nextButtonSubItem}
                                  src="/images/controls/right_arrow_round_edges.png"
                                />
                              )}
                            </>
                          )}
                        </StyledWrapperID>
                        {this.state.items !== "" && (
                          <JewelleryPerItemDescription
                            t={t}
                            {...this.state.items}
                            auctionDate={auctionDate}
                            isPDView={this.state.isPDView}
                            onClickSignIn={() => {
                              this.props.history.replace("/");
                            }}
                          />
                        )}
                        <StyledInquiryContainer>
                          <BasicPopover oninquiryFun={this.oninquiry} />
                        </StyledInquiryContainer>
                      </Grid>
                    </Grid>
                  </StyledContentWrapper>
                </Grid>
              </StyledLayoutGrid>
            )}
          </Grid>
        )}

        {this.state.openToast === true && (
          <Alert
            showAlertMessage={this.state.showAlertMessage}
            alertType={this.state.alertType}
          />
        )}
        {this.state.userBlock === true && (
          <StyledModal open={this.state.userBlock}>
            <BlockUserModal
              t={t}
              {...this.props}
              closeModal={this.blockCloseModal}
            />
          </StyledModal>
        )}
        {this.state.openShareModal === true && (
          <StyledModal
            open={this.state.openShareModal}
            onClose={this.onCloseModal}
          >
            <ShareProductLinkView
              onCopyModal={this.onCopyLink}
              onCancelModal={this.onCloseModal}
              t={t}
            />
          </StyledModal>
        )}
        {this.state.openInquiry === true && (
          <StyledModal
            open={this.state.openInquiry}
            onClose={this.onCloseModal}
          >
            <ProductDetailsInquiry
              t={t}
              data={this.state.items}
              closeModal={this.onCloseModal}
              auctionId={this.props.match.params.auctionId}
            />
          </StyledModal>
        )}
      </AppLayout>
    );
  }
}
