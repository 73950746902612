import React from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import ActionPaginationForProducts from "../grid-actions/ActionPaginationForProducts";
import { productDownload } from "../../../common/config/app.endpoints";
import AuthService from "../../auth/AuthService";
import PubSub from "pubsub-js";
import ActionPageSizeSelectorLive from "../grid-actions/ActionPageSizeSelectorLive";

import { StyledSelectAllButtonHeader } from "../../365Auction/component/DiamondProductStyle";
import ActionAuctionState from "../grid-actions/ActionAuctionState";
import BasicSearchBox from "../grid-actions/BasicSearchBox";

const StyledCoreDataGridContainer = styled(Grid)`
  .icon {
    width: 2.8em;
    height: 2.8em;
  }
`;
const StyledDataWrapper = styled(Grid)`
  margin-top: 10px;
`;

const StyledGridWrapper = styled.div``;

const StyledPaginationContainer = styled(Grid)`
  margin-top: 1em;
  padding: 20px 0;
`;

const StyledActionsContainer = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    justify-content: start;
  }
  @media (min-width: 560px) and (max-width: 959.95px) {
    justify-content: right;
  }

  @media (width: 540px) {
    justify-content: start;
  }
`;

const StyledActionBarContainer = styled(Grid)`
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
 
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 5px;
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  padding: 0.4em;
  margin: 0.3em;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  background: #2660ad;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

export const StyledPageSizeSelectorContainer = styled.div`
  border: none;
  select {
    padding: 0.6em 0.5em;
    margin: 0.3em;
    font-size: 1.2em;
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    background: ${(props) => props.theme.colors.base.veryVeryLightGrey};
    border-radius: 3px;
    color: ${(props) => props.theme.colors.base.grey};
    width: 100%;
    ${(props) => props.theme.breakpoints.between("xs", "sm")} {
      padding: 0.5em 0.3em;
      margin: 0.2em;
    }
  }
`;

const StyledAuctionName = styled(Grid)`
  font-size: 1.6em;
  font-weight: bold;
  color: #2660ad;
  text-align: center;
  white-space: pre;
`;
const StyledAuctionStateAction = styled(Grid)`
  font-size: 1.6em;
  color: ${(props) => props.theme.colors.base.grey};
  text-align: center;
`;
const StyledDropDown = styled.img`
  width: 2.2em;
  height: 2.2em;
  cursor: pointer;
`;

const StyledReserveImg = styled.img`
  width: 2.5em;
  height: 2.5em;
  cursor: pointer;
`;

const StyledAuctionNameContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.8em;
  }
`;

interface IProps {
  t?: any;
  data?: any;
  totalElements?: number | undefined;
  value: (value: boolean, count: number, itemsPerPage: number) => void;
  noProductFound: (noProductFound: any) => void;
  itemPerPage: (value: boolean, count: number, itemsPerPage: number) => void;
  sortingValue: (value: any) => void;
  wathlistPage: (value: any) => void;
  Gridview?: (value: any) => void;
  listGridview?: (value: any) => void;
  uiBoxView?: any;
  isJewllery?: any;
  filteredAuction: any;
  reduxItemPerPage: any;
  isDesktopDevices: any;
  myPageRedireaction: any;
  selectAllInGridView: () => void;
  prebidEndTime: any;
  autoCompleteSearch: (value: any) => void;
  textSearchListing: (value: any) => void;
  setTextSearchArray: (value: any) => void;
  onReserveBtnHandler: () => void;
}

interface IState {
  data: any;
  loading: boolean;
  currentPage: number;
  itemsPerPage: number;
  noProductFound: any;
  sortingValue: any;
  countries: any;
  colours: any;
  isExportSheetButtonEnable: boolean;
  isNrFilterSelected: boolean;
}

const childElementsWithProps = (props: any) => {
  return React.cloneElement(props.children, { ...props });
};
export default class CoreDataGridofProductLive extends React.Component<
  IProps,
  IState
> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      currentPage: 1,
      itemsPerPage: 20,
      noProductFound: [],
      sortingValue: String,
      countries: [],
      colours: {},
      isExportSheetButtonEnable: true,
      isNrFilterSelected: false
    };
  }

  Auth = new AuthService();

  async componentDidMount() {
    let Page = localStorage.getItem("currentStart");
    if (Page != null && Page !== "0") {
      let pageNumber: number = parseInt(Page) / this.props.reduxItemPerPage + 1;
      this.setCurrentPage(pageNumber);
    }
    if (localStorage.getItem("itemsPerPage") !== null) {
      let savedCount: any = this.props.reduxItemPerPage;
      this.setState({
        itemsPerPage: savedCount,
      });
    }
    let isNrProductFilter = localStorage.getItem("NRProductFilterd");
    if (
      isNrProductFilter != undefined &&
      isNrProductFilter != null
    ) {
      this.setState({
        isNrFilterSelected: true
      });
    }
    this.setData(this.props.data);
  }

  async componentDidUpdate(prevProps: any) {
    if (
      localStorage.getItem("currentStart") != null &&
      localStorage.getItem("currentStart") === "0" &&
      this.state.currentPage !== 1
    ) {
      this.setCurrentPage(1);
    }
    if (prevProps.data !== this.props.data) {
      this.setData(this.props.data);
    }
  }

  setData(data: any) {
    this.setState(() => ({
      data,
    }));
  }

  setCurrentPage(currentPage: number) {
    this.setState(() => ({
      currentPage,
    }));
  }

  setItemsPerPage(itemsPerPage: number) {
    this.setState(() => ({
      itemsPerPage,
    }));
    this.props.itemPerPage(true, 0, itemsPerPage);
  }

  setItemsPerPageHandler = (itemsPerPage: number) => {
    this.setCurrentPage(1);
    this.setItemsPerPage(itemsPerPage);
  };

  paginate = (pageNumber: number) => {
    this.setCurrentPage(pageNumber);
    PubSub.publish("pageNumber", pageNumber);
  };

  forApiCalling = (value: boolean, currentPage: number) => {
    if (
      this.props &&
      this.props.totalElements !== undefined &&
      this.props.totalElements > this.state.itemsPerPage
    ) {
      var count = (currentPage - 1) * this.state.itemsPerPage;
      this.props.value(value, count, this.state.itemsPerPage);
    }
  };

  onClickLowest() {
    let data = this.props.data;
    data.sort(function (
      x: { caratMinimumPrice: number },
      y: { caratMinimumPrice: number }
    ) {
      return x.caratMinimumPrice - y.caratMinimumPrice;
    });
    this.setData(data);
  }

  onClickHighest() {
    let data = this.props.data;
    data.sort(function (
      x: { caratMinimumPrice: number },
      y: { caratMinimumPrice: number }
    ) {
      return y.caratMinimumPrice - x.caratMinimumPrice;
    });
    this.setData(data);
  }

  onClickLowestCarat() {
    let data = this.props.data;
    data.sort(function (x: { carat: number }, y: { carat: number }) {
      return x.carat - y.carat;
    });
    this.setData(data);
  }

  onClickHighestCarat() {
    let data = this.props.data;
    data.sort(function (x: { carat: number }, y: { carat: number }) {
      return y.carat - x.carat;
    });
    this.setData(data);
  }

  onClickLowestBids() {
    let data = this.props.data;

    let tempArr = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].preBidPrice !== undefined) {
        tempArr.push(data[index]);
      }
    }

    if (tempArr !== undefined && tempArr !== null && tempArr.length > 0) {
      tempArr.sort(function (
        x: { preBidPrice: number },
        y: { preBidPrice: number }
      ) {
        return x.preBidPrice - y.preBidPrice;
      });
    } else {
      this.props.noProductFound([]);
    }

    this.setData(tempArr);
    this.props.noProductFound(tempArr);
  }

  onClickHighestBids() {
    let data = this.props.data;

    let tempArr = [];
    for (let index = 0; index < data.length; index++) {
      if (data[index].preBidPrice !== undefined) {
        tempArr.push(data[index]);
      }
    }

    if (tempArr !== undefined && tempArr !== null && tempArr.length > 0) {
      tempArr.sort(function (
        x: { preBidPrice: number },
        y: { preBidPrice: number }
      ) {
        return y.preBidPrice - x.preBidPrice;
      });
    } else {
      this.props.noProductFound([]);
    }

    this.setData(tempArr);
    this.props.noProductFound(tempArr);
  }

  exportProductToExcel() {
    this.setState({
      isExportSheetButtonEnable: false,
    });
    let exportProductIds = this.props.data
      .slice(0, 1)
      .map((item: any, index: number) => {
        return item.auctionId;
      });
    this.Auth.postRequestForExcel(
      `${productDownload}?auctionId=${exportProductIds}`,
      { ids: "" },
      (status: any, response: any) => {
        if (status === 200) {
          if (response !== undefined) {
            const url = window.URL.createObjectURL(
              new Blob([response], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Products.xlsx`);
            document.body.appendChild(link);
            link.click();
          }
          setTimeout(() => {
            this.setState({
              isExportSheetButtonEnable: true,
            });
          }, 5000);
        } else {
        }
      }
    );
  }

  nonReserveToReserve() {
    this.setState({
      isNrFilterSelected: !this.state.isNrFilterSelected
    })
    if (!this.state.isNrFilterSelected) {
      localStorage.setItem("NRProductFilterd", JSON.stringify({
        "match": "exact",
        "name": "product.reserved",
        "value": false
      }));
    } else {
      localStorage.removeItem("NRProductFilterd");
    }
    this.props.onReserveBtnHandler();
  }

  render() {
    const { t } = this.props;
    const selectedPage = true;
    let currentRecords = [];
    const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
    if (this.state.data.length > 0) {
      currentRecords = this.state.data.slice(
        indexOfFirstRecord,
        indexOfLastRecord
      );
    }

    return (
      <StyledCoreDataGridContainer container={true} spacing={0}>
        <StyledActionBarContainer
          container={true}
          spacing={0}
          className="coreActionBarContainer"
        >
          <Grid item={true} xs={12} lg={5} xl={5}>
            <StyledAuctionNameContainer>
              <StyledAuctionName>
                {this.props.data !== undefined &&
                  this.props.data !== null &&
                  this.props.data.length !== 0 ? (
                  <> {this.props.data[0]?.auctionName} : </>
                ) : (
                  <></>
                )}
              </StyledAuctionName>
              <StyledAuctionStateAction>
                {this.props.data !== undefined &&
                  this.props.data !== null &&
                  this.props.data.length !== 0 ? (
                  <ActionAuctionState
                    product={this.props.data}
                    prebidEndTime={this.props.prebidEndTime}
                  />
                ) : (
                  <></>
                )}
              </StyledAuctionStateAction>
            </StyledAuctionNameContainer>
          </Grid>
          <StyledActionsContainer item={true} xs={12} lg={7} xl={7}>
            <StyledContainer style={{ marginRight: '5px' }}>
              <BasicSearchBox
                autoCompleteSearch={this.props.autoCompleteSearch}
                textSearchListing={this.props.textSearchListing}
                setTextSearchArray={this.props.setTextSearchArray}
              />
            </StyledContainer>
            <StyledContainer>
              {this.props.uiBoxView === true ? (
                <StyledSelectAllButtonHeader
                  onClick={this.props.selectAllInGridView}
                >
                  {t("common:newCommanTrans:selectAll")}
                </StyledSelectAllButtonHeader>
              ) : null}

              <ActionPageSizeSelectorLive
                setItemsPerPageHandler={this.setItemsPerPageHandler}
                t={t}
              />
              <StyledButton
                onClick={this.props.myPageRedireaction}
                title="Redirect to the My-page"
              >
                {t("common:newCommanTrans:myPage")}
              </StyledButton>

              <StyledReserveImg
                src={this.state.isNrFilterSelected ? "/images/reserve/non-reserve-filter-with-Border.png" : "/images/reserve/non-reserve-filter-without-Border.png"}
                alt="reserve"
                onClick={this.nonReserveToReserve.bind(this)}
                title="Download Export"
              />

              {this.state.isExportSheetButtonEnable ? (
                <StyledDropDown
                  src="/images/assets/iconDownload.png"
                  alt="exportsheet"
                  onClick={this.exportProductToExcel.bind(this)}
                  title="Download Export"
                />
              ) : (
                <StyledDropDown
                  src="/images/assets/iconDownload.png"
                  alt="d"
                  title="Download Export"
                  onClick={this.exportProductToExcel.bind(this)}
                />
              )}

              <StyledDropDown
                src="/images/assets/bidIcon.png"
                alt="bidIcon"
                title="Select items to bulk prebid or watchlist"
                onClick={this.props.wathlistPage}
              />
            </StyledContainer>
            <StyledContainer>
              {this.props.isDesktopDevices ? (
                <>
                  {this.props.uiBoxView ? (
                    <StyledDropDown
                      src="/images/assets/listViewIcon.png"
                      onClick={this.props.Gridview}
                      className="icon"
                      title="List view of Product"
                    />
                  ) : (
                    <StyledDropDown
                      src="/images/assets/gridViewIcon.png"
                      onClick={this.props.listGridview}
                      title="Grid view of Product"
                      className="icon"
                    />
                  )}
                </>
              ) : null}
            </StyledContainer>
          </StyledActionsContainer>
        </StyledActionBarContainer>
        <StyledDataWrapper item={true} xs={12}>
          <StyledGridWrapper>
            {childElementsWithProps({
              selectedPage,
              currentRecords,
              ...this.props,
            })}
          </StyledGridWrapper>
        </StyledDataWrapper>
        {this.props.totalElements && this.props.totalElements > 0 ? (
          <StyledPaginationContainer item={true} xs={12}>
            {this.props.uiBoxView === true ? (
              <ActionPaginationForProducts
                t={t}
                totalElements={this.props.totalElements}
                callbackForApiCalling={this.forApiCalling}
                currentPage={this.state.currentPage}
                itemsPerPage={this.state.itemsPerPage}
                paginateCallback={this.paginate}
              />
            ) : (
              <></>
            )}
          </StyledPaginationContainer>
        ) : null}
      </StyledCoreDataGridContainer>
    );
  }
}
