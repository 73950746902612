const getErrorCodes = () => {
  // 400 - Bad Request Errors
  const STATUS_400 = {
    KB400001: "common:register:errorMessage:correctInvitationCode",
    KB400002: "trans:errorMessage:operationNotAllowed", // Operation not allowed
    KB400003: "common:allValidations:join:invalidCodeorMail",
  };

  // 401 - Unauthorized Errors
  const STATUS_401 = {
    KB401001: "trans:errorMessage:tokenExpired", // Token Exired
    KB401002: "common:login:validation:registeredAsAuctioneer",
    KB401004: "trans:errorMessage:notAllowedToAccess", // You are not allowed to access myauctions , Please subscribe for complete access.
    KB401005: "trans:errorMessage:dontHaveInvitationToAccess", // You don't have any invitation to access.
  };

  // 403 Forbidden
  const STATUS_403 = {
    KB403001: "trans:errorMessage:accessRestrictedContactUs", //"We have restricted your access to MyAuctions.jp. \\n Please contact MyAuctions or mail us at contact@myauctions.jp"
  };

  // 404 - Not Found Errors
  const STATUS_404 = {
    KB404001: "common:profile:forgotPassword:validation:notRegisteredEmail",
  };

  // 409 Conflict
  const STATUS_409 = {
    KB409001: "common:register:errorMessage:profileVerification",
    KB409002: "common:register:errorMessage:alreadyRegistered",
    KB409003: "common:allValidations:participate:alreadySentParticipationMail",
    KB409004: "common:allValidations:participate:alreadySentInviationMail",
    KB409005: "trans:errorMessage:paymentSucess", // ("error.payment.success", ""),
  };

  // 412 Precondition Failed
  const STATUS_412 = {
    KB412001: "common:profile:changePassword:validation:incorrectOldPassword",
    KB412002: "trans:errorMessage:otpExpired", // ("error.otp.expired", ""),
    KB412003: "common:profile:forgotPassword:validation:invalidOTP",
    KB412004: "common:login:validation:invalidEmailorPassword",
  };

  // 500 Internal Server Error
  const STATUS_500 = {
    KB500001: "trans:errorMessage:unknownErrorServerSideException", // ("error.unknown.server.side.exception", ""),
  };

  // 502 Payment Error
  const STATUS_502 = {
    KB500002: "trans:errorMessage:userPaymentFail", // ("error.user.payment.fail", "error occurred while payment");
  };

  return {
    ...STATUS_400,
    ...STATUS_401,
    ...STATUS_403,
    ...STATUS_404,
    ...STATUS_409,
    ...STATUS_412,
    ...STATUS_500,
    ...STATUS_502,
  };
};

export default getErrorCodes;
