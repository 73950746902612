import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import styled, { css } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';

import { signupUrl, getCountry, getState, getCity, getTimeZonePB } from '../../common/config/app.endpoints';
import { StyledTextInput, StyledTitleText } from '../../common/materials/GenericStyleComponent';
import AuthService from '../../components/auth/AuthService';
import RadioButton from '../../common/elements/RadioButton';
import { buttonGradient } from '../../common/materials/LinearGradient';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const StyledComponent = styled.div`
    outline: none;
`;


interface IButtonProps {
    isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    background: ${buttonGradient};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
    ${props => props.isSubmit && css`
        cursor: default;
        background: ${(props) => props.theme.colors.base.lightGrey}; 
        border: 1px solid ${(props) => props.theme.colors.base.white};
        color:${(props) => props.theme.colors.base.white};
        &:hover {
            cursor: default;
            border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
        }
    `}
`;

const StyledRegisterWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    border-radius: 10px;
    &:focus {
        outline: none;
    }
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 20px; 
        overflow-y: scroll;
        height: 550px;
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 20px; 
        overflow-y: scroll;
        height: 650px;
        width: 300px;
    }   
    ${props => props.theme.breakpoints.down('md')} {
        overflow-y: scroll;
        padding: 40px; 
        width: 350px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        overflow-y: scroll;
        padding: 20px; 
        width: 700px;
        max-height: 550px;
    }
    .secondary{
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }  
    ::-webkit-validation-bubble-message { display: none; }
`;

const StyledTitle = styled.h2`
    color:  ${(props) => props.theme.colors.base.black};
`;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 1em;
    margin-top: 2em;
    margin-left: 1.2em;
`;

const StyledPolicyWrapper = styled.div`
    padding: 10px;
    margin: 1.5em 1em;
    display: flex;
    width: 100%;
    align-items: center;
    color: ${(props) => props.theme.colors.base.black};
    font-size: 12px;
    #policyAgreementCheckbox {
        margin-right: 10px;
    }
    a {
        text-decoration: underline;
    }
`;

const StyledAlreadyLoginWrapper = styled(Grid)`
    padding: 10px;
`;

const StyledLogin = styled.div` 
    padding: 10px;
    margin: 1em;
    display: flex;
    width: 100%;
    align-items: center;
    color:${(props) => props.theme.colors.base.grey}; 
    font-size: 1em;
    cursor: pointer;
`;

const StyledButtonContainer = styled.div`
    text-align: right;
    margin: 20px 0;
`;

const StyledSelect = styled.div`
    margin: 1em;
    
    fieldset {
        border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
        border-radius: 30px;
    }

    .MuiInputLabel-formControl{
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    }

   .MuiOutlinedInput-root {
        border-radius: 30px;   
        //border: 1px solid ${(props) => props.theme.colors.base.fieldSetBlueColor};
   }

   .MuiInputLabel-outlined {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
        transform: translate(14px, 20px) scale(1);
        &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
        }
   }
   .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
        background : ${(props) => props.theme.colors.base.transparent};
    } 
    
    .MuiSelect-outlined {
        color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
        background : ${(props) => props.theme.colors.base.transparent};
    }
    
`;

export interface IProps {
    t?: any;
    I18n?: {};
    history?: any;
    location?: {};
    match?: {};
    closeModal: () => void;
    onSuccessfulRegisterModal: () => void;
    loginModal: () => void;
    apiTimeout: (timeout: boolean) => void;
}

interface IState {
  firstName: string;
  lastName: string;
  email: string;
  occupation: any;
  company: string;
  address: string;
  city: string;
  state: string;
  country: string;
  invitationCode: string;
  phone: string;
  checkedTerms: boolean;
  roleIds: string[];
  message: string;
  isButtonSubmit: boolean;
  isLoading: boolean;
  countryList: any;
  selectedCountry: any;
  stateList: any;
  selectedState: any;
  cityList: any;
  selectedCity: any;
  selectedStateId: any;
  selectedcityId: any;
  timeZoneList: any;
  timezone: any;
  selectedTimezone: any;
  preferredLanuage:any;
}

var formData = {};
let sid: any;
export default class SignUp extends Component<IProps, IState> {

    Auth = new AuthService();

    constructor(props: IProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleCancelRegistration = this.handleCancelRegistration.bind(this);
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            occupation: '',
            company: '',
            address: '',
            city: '',
            state: '',
            country: '',
            invitationCode: '',
            phone: '',
            checkedTerms: false,
            roleIds: ['5ecf5b1e5a7f2b1aa47e67ec'],
            message: '',
            isButtonSubmit: false,
            isLoading: false,
            countryList: [],
            selectedCountry: '',
            stateList: [],
            selectedState: '',
            cityList: [],
            selectedCity: "",
            selectedStateId: '',
            selectedcityId:'',
            timeZoneList: [],
            timezone: '',
            selectedTimezone:'',
            preferredLanuage: ''    
        }

        this.Auth.getRequestWithoutAuthorization(`${getCountry}`, (status: number, response: any) => {
            if (status === 200) {
                this.setState({
                    countryList: response
                })
            }
        })

        this.Auth.getRequestWithoutAuthorization(getTimeZonePB, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    this.setState({
                      timeZoneList: response,
                    });
                }
            }
        });

    }

    handleChange(e: any) {
        this.setState({
            isButtonSubmit: false
        })
        const targeName = e.target.name;
        const targeValue = e.target.value;
        if (targeName === 'firstName') { this.setState({ firstName: targeValue.trim() }) }
        if (targeName === 'lastName') { this.setState({ lastName: targeValue.trim() }) }
        if (targeName === 'email') { this.setState({ email: targeValue.trim() }) }
        if (targeName === 'company') { this.setState({ company: targeValue.trim() }) }
        if (targeName === 'address') { this.setState({ address: targeValue.trim() }) }
        if (targeName === 'city') { this.setState({ city: targeValue.trim() }) }
        if (targeName === 'state') { this.setState({ state: targeValue.trim() }) }
        if (targeName === 'timezone') { this.setState({ timezone: targeValue.trim() }) }
        if (targeName === 'phone') { this.setState({ phone: targeValue.trim() }) }
        if (targeName === 'preferredLanuage') { this.setState({ preferredLanuage: targeValue.trim() }); }
        if (targeName === 'invitationCode') { this.setState({ invitationCode: targeValue }) }
    }

    handleChangeDropDown = (e: any) => {       
        const targeName = e.target.name;
        const targeValue = e.target.value;
        let val = this.state.countryList[targeValue].name;
        console.log({ val, targeValue });
        if (targeName === 'country') {
            this.setState({
                country: val,
                selectedCountry: this.state.countryList[targeValue],
                selectedStateId: this.state.countryList[targeValue].perId
            })
            this.getStateId(this.state.countryList[targeValue].perId);
        }
        if (targeName === "state") {
            val = this.state.stateList[targeValue].name;
          this.setState({
            state: val,
            selectedState: this.state.stateList[targeValue],
            selectedcityId: this.state.stateList[targeValue].perId,
          });
          this.getCityId(this.state.stateList[targeValue].perId);
        }
        if (targeName === "city") {
            val = this.state.cityList[targeValue].name;
          this.setState({
            city: val,
            selectedCity: this.state.cityList[targeValue],
          });
        }

        if (targeName === "timezone") {
          val = this.state.timeZoneList[targeValue].name;
          this.setState({
            timezone: val,
            selectedTimezone: this.state.timeZoneList[targeValue],
          });
        }
    }

    getStateId = (stateId: any) =>{
        this.Auth.getRequestWithoutAuthorization(
          `${getState}?id=${stateId}`,
          (status: number, response: any) => {
            if (status === 200) {
              this.setState({
                stateList: response,
              });
            }
          }
        );
    }

    getCityId = (cityId: any) =>{
        this.Auth.getRequestWithoutAuthorization(
          `${getCity}?id=${cityId}`,
          (status: number, response: any) => {
            if (status === 200) {
              this.setState({
                cityList: response,
              });
            }
          }
        );
    }

    handleFormSubmit(e: any) {
        this.setState({
            message: "",
            isButtonSubmit: true,
            isLoading: true
        })
        formData = {};
        e.preventDefault();
        var digits = /^(\+){0,1}[0-9]{8,15}$/;
        if (this.state.firstName !== '') {
            Object.assign(formData, { "firstName": this.state.firstName, roleIds: this.state.roleIds });
        }
        if (this.state.lastName !== '') {
            Object.assign(formData, { "lastName": this.state.lastName });
        }
        if (this.state.email !== '') {
            Object.assign(formData, { "email": this.state.email });
        }
        if (this.state.company !== '') {
            Object.assign(formData, { "company": this.state.company });
        }
        if (this.state.address !== '') {
            Object.assign(formData, { "address": this.state.address });
        }        
        if (this.state.occupation !== '') {
            Object.assign(formData, { "occupations": this.state.occupation });
        }
        if (this.state.country !== '') {
            Object.assign(formData, { "countryDetail": this.state.selectedCountry });
        }
        if (this.state.phone.match(digits)) {
            Object.assign(formData, { "phone": this.state.phone });
        }
        if (this.state.timezone !== '') {
            Object.assign(formData, { "timezone": this.state.selectedTimezone });
        }
        if (this.state.checkedTerms !== false) {
            Object.assign(formData, { "checkedTerms": this.state.checkedTerms });
        }
        if (this.state.preferredLanuage !== false) {
            Object.assign(formData, { "preferredLanguageCode": this.state.preferredLanuage });
        }
        if (this.state.invitationCode !== '') {
            Object.assign(formData, { "invitationCode": this.state.invitationCode.trim() });
        }
        if (this.state.city !== '') {
            Object.assign(formData, { "cityDetail": this.state.selectedCity });
        }else{
            Object.assign(formData, { "cityDetail": null });
        }
        if (this.state.state !== '') {
            Object.assign(formData, { "stateDetail": this.state.selectedState });
        }else{
            Object.assign(formData, { "stateDetail": null });
        }    
        
        this.validation();
    }

    validation() {
        const { t } = this.props;
        const email = new RegExp(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g).test(this.state.email.trim())
        var digits = /^(\+){0,1}[0-9]{8,15}$/;
        if (this.state.firstName === '' && this.state.lastName === '' && this.state.company === '' && this.state.address === '' && email !== true && this.state.country === '' && this.state.state === '' && this.state.occupation === '' && this.state.phone.length !== 10 && this.state.checkedTerms === false) {
            this.setState({
                message: `${t('common:allValidations:emptyFields')}`,
                isLoading: false
            })
        }
        else if (this.state.firstName === '') {
            this.setState({
                message: `${t('common:register:errorMessage:onfirstName')}`,
                isLoading: false
            })
        }
        else if (this.state.lastName === '') {
            this.setState({
                message: `${t('common:register:errorMessage:onlastName')}`,
                isLoading: false
            })
        }
        else if (email !== true) {
            this.setState({
                message: `${t('common:register:errorMessage:onEmail')}`,
                isLoading: false
            })
        }
        else if (this.state.company === '') {
            this.setState({
                message: `${t('common:allValidations:emptyField')}`,
                isLoading: false
            })
        }
        else if (this.state.address === '') {
            this.setState({
                message: `${t('common:allValidations:emptyField')}`,
                isLoading: false
            })
        }
        // else if (this.state.state === '') {
        //     this.setState({
        //         message: `${t('common:allValidations:emptyField')}`,
        //         isLoading: false
        //     })
        // }
        else if (this.state.country === '') {
            this.setState({
                message: `${t('common:allValidations:emptyField')}`,
                isLoading: false
            })
        }
        else if (this.state.occupation === '') {
            this.setState({
                message: `${t('common:register:errorMessage:occupationNotSelect')}`,
                isLoading: false
            })
        }
        else if (this.state.phone.length < 10) {
            this.setState({
                message: `${t('common:register:errorMessage:phoneNumber')}`,
                isLoading: false
            })
        }
        else if (!this.state.phone.match(digits)) {
            this.setState({
                message: `${t('common:register:errorMessage:onMobileNumber')}`,
                isLoading: false
            })
        }
        else if (this.state.checkedTerms === false) {
            this.setState({
                isLoading: false,
                message: `${t('common:register:errorMessage:onCheckTerms')}`
            })
        }
        else if (this.state.preferredLanuage === '') {
            this.setState({
                isLoading: false,
                message: `${t("common:kycFrom:errorMessage:onPreferredLanguage")}`
            })
        }
        else if (!this.state.invitationCode.match(digits) && this.state.invitationCode !== '') {
            this.setState({
                message: `${t('common:register:errorMessage:invitationCode')}`,
                isLoading: false
            })
        }
        else if (this.state.invitationCode !== '' && this.state.occupation[0] !== 'Bidder') {
            this.setState({
                message: `${t('common:register:errorMessage:notSeletBidder')}`,
                isLoading: false
            })
        }
        // else if (this.state.timezone === '') {
        //     this.setState({
        //         message: `${t('common:profile:legends:validation:enterTimeZone')}`,
        //         isLoading: false
        //     })
        // }
        else {
            this.onPress();
        }
    }

    onPress() {
        const { t } = this.props;
        this.Auth.postRequestWithoutAuthorization(signupUrl, formData, (status: number, response: any) => {
            if (status === 200) {
                this.setState({
                    isLoading: false
                })
                this.props.onSuccessfulRegisterModal()
            }
            else if (status > 200 && status < 500) {
                this.setState({
                    isLoading: false
                })
                if (response[0].code !== undefined) {
                    if (response[0].code === "KB409001") {
                        this.setState({
                            message: `${t('common:register:errorMessage:profileVerification')}`
                        })
                    }
                    else if (response[0].code === 'KB409002') {
                        this.setState({
                            message: `${t('common:register:errorMessage:alreadyRegistered')}`
                        })
                    }
                    else if (response[0].code === 'KB400001') {
                        this.setState({
                            message: `${t('common:register:errorMessage:correctInvitationCode')}`
                        })
                    }
                }
                else {
                    this.setState({
                        message: response[0].message
                    })
                }
            }
            else if (status === 505) {
                this.props.apiTimeout(true);
            }
            else {
                this.setState({
                    message: `${t('common:somethingWentWrong')}`
                })
            }
        })
    }

    handleClick(e: any) {
        this.setState({
            isButtonSubmit: false
        })
        if (e === 'Occupation') {
        }
        else if (e === 'Auctioneer') {
            this.setState({ occupation: ['Auctioneer'], invitationCode:'' })
        }
        else if (e === 'Bidder') {
            this.setState({ occupation: ['Bidder'] })
        }
        else {
            this.setState({
                occupation: ['Auctioneer', 'Bidder'],
                invitationCode:''
            })
        }
    }

    handleCheck = (event: any) => {
        this.setState({
            isButtonSubmit: false
        })
        this.setState({ checkedTerms: event.target.checked })
    };

    handleCancelRegistration() {
        this.props.closeModal();
    }

    loginModal() {
        this.props.loginModal();
    }

    handle = (e: any) => {
        e.preventDefault();
    }

    render() {
        const { t } = this.props;
        return (
            <StyledComponent tabIndex={-1}>
                <StyledRegisterWrapper>
                    <form noValidate={true} name="registration" onSubmit={this.state.isButtonSubmit === false ? this.handleFormSubmit.bind(this) : this.handle.bind(this)}>
                        <Grid container={true} spacing={0} className="registrationCard">
                            <Grid item={true} xs={12}>
                            <StyledTitle className= "fontBold"><StyledTitleText>{t('common:register:title')}</StyledTitleText></StyledTitle>
                            </Grid>
                        </Grid>
                        <Grid container={true} spacing={0} className="registrationCard">
                            <Grid item={true} xs={12}>
                                <StyledMessage>
                                    {this.state.message}
                                </StyledMessage>
                            </Grid>
                        </Grid>
                        <Grid container={true} spacing={0}>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        autoFocus={true}
                                        id="firstName"
                                        multiline={false}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:firstName')}
                                        label={t('common:register:labels:firstName')}
                                        variant="outlined"
                                        name="firstName"
                                        inputProps={{ maxLength: 64 }}
                                        onChange={this.handleChange}

                                    />
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:lastName')}
                                        label={t('common:register:labels:lastName')}
                                        variant="outlined"
                                        name="lastName"
                                        inputProps={{ maxLength: 64 }}
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:email')}
                                        label={t('common:register:labels:email')}
                                        variant="outlined"
                                        name="email"
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="company"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:company')}
                                        label={t('common:register:labels:company')}
                                        variant="outlined"
                                        name="company"
                                        inputProps={{ maxLength: 64 }}
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <RadioButton occupationType={this.handleClick.bind(this)} t={t} />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="address"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:address')}
                                        label={t('common:register:labels:address')}
                                        inputProps={{ maxLength: 64 }}
                                        variant="outlined"
                                        name="address"
                                        type="text"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>                                
                                 <StyledSelect>
                                    <FormControl variant="outlined" className="" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('common:register:labels:country')}</InputLabel>
                                        <Select
                                            id="country"
                                            name="country"
                                            onChange={this.handleChangeDropDown}
                                            //value={this.state.country}
                                            label={t('common:register:labels:country')}>
                                            {Object.keys(this.state.countryList).map(key => (
                                                <MenuItem value={key}>{this.state.countryList[key].name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledSelect>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    <FormControl variant="outlined" className="" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('common:register:labels:state')}</InputLabel>
                                        <Select
                                            id="state"
                                            name="state"
                                            onChange={this.handleChangeDropDown}
                                            //value={this.state.country}
                                            label={t('common:register:labels:state')}>
                                            {Object.keys(this.state.stateList).map(key => (
                                                <MenuItem value={key}>{this.state.stateList[key].name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledTextInput>
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                               <StyledTextInput>
                                    <FormControl variant="outlined" className="" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('common:register:labels:city')}</InputLabel>
                                        <Select
                                            id="city"
                                            name="city"
                                            onChange={this.handleChangeDropDown}
                                            //value={this.state.country}
                                            label={t('common:register:labels:city')}>
                                            {Object.keys(this.state.cityList).map(key => (
                                                <MenuItem value={key}>{this.state.cityList[key].name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledTextInput>
                            </Grid>
                            <Grid container={true} spacing={0}>
                                <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
                                    <StyledTextInput>
                                    <TextField
                                        required
                                        fullWidth
                                        id="phone"
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        placeholder={t('common:register:placeholder:phone')}
                                        inputProps={{ maxLength: 16, minLength: 10 }}
                                        label={t('common:register:labels:phone')}
                                        variant="outlined"
                                        name="phone"
                                        onChange={this.handleChange}
                                    />
                                </StyledTextInput>            
                                </Grid>
                                <Grid item={true} xs={12} md={6} sm={6} lg={6} xl={6}>
                                    {/* <StyledSelect>
                                    <FormControl variant="outlined" className="" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">{t('common:profile:legends:timezone')}</InputLabel>
                                        <Select
                                            id="timezone"
                                            name="timezone"
                                            onChange={this.handleChangeDropDown}
                                            label={t('common:profile:legends:timezone')}>
                                            {Object.keys(this.state.timeZoneList).map(key => (
                                                <MenuItem value={key}>{this.state.timeZoneList[key].name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </StyledSelect>  */}
                                <StyledSelect>
                                    <FormControl variant="outlined" className="" fullWidth>
                                        <InputLabel id="demo-simple-select-outlined-label">
                                        {t("common:kycFrom:preferredLanuage")}
                                        </InputLabel>
                                        <Select
                                        id=""
                                        onChange={this.handleChange}
                                        name="preferredLanuage"
                                        label={t("common:kycFrom:preferredLanuage")}
                                        >
                                        <MenuItem value="en">EN</MenuItem>
                                        <MenuItem value="jp">日本語</MenuItem>
                                        </Select>
                                    </FormControl>
                                </StyledSelect>
                                </Grid>
                            </Grid>
                            {/* <Grid item={true} xs={12} md={6}>
                                <StyledTextInput>
                                    {this.state.occupation[0] === 'Bidder' &&
                                        <TextField
                                            fullWidth
                                            id="invitationCode"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            placeholder={t('common:register:placeholder:invitationCode')}
                                            label={t('common:register:labels:invitationCode')}
                                            variant="outlined"
                                            name="invitationCode"
                                            type="text"
                                            inputProps={{ maxLength: 6, minLength: 6 }}
                                            onChange={this.handleChange}
                                        />
                                    }
                                </StyledTextInput>
                            </Grid> */}
                            <Grid item={true} xs={12} md={6}>
                                <StyledPolicyWrapper>
                                    <Checkbox
                                        checked={this.state.checkedTerms}
                                        onChange={this.handleCheck}
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                    />
                                    <span>{t('common:register:iAgree')} <a href="/termsandconditions" target="_blank">{t('common:register:termsandCondition')}</a></span>
                                </StyledPolicyWrapper>
                            </Grid>
                            <StyledAlreadyLoginWrapper item={true} xs={12} md={6}>
                                <StyledLogin>{t('common:register:alreadyAccount')}<span className="secondary" onClick={() => this.loginModal()}>&nbsp;&nbsp;{t('common:register:login')}</span></StyledLogin>
                            </StyledAlreadyLoginWrapper>
                            <Grid item={true} xs={12}>
                                <StyledButtonContainer>
                                    {this.state.isButtonSubmit === false ?
                                        <StyledButton isSubmit={this.state.isButtonSubmit} onSubmit={this.handleFormSubmit}>
                                            {t('common:button:createAccount')}
                                        </StyledButton>
                                        :
                                        <StyledButton isSubmit={this.state.isButtonSubmit}>
                                            {t('common:button:createAccount')}
                                        </StyledButton>
                                    }
                                    <StyledButton onClick={this.handleCancelRegistration}>
                                        {t('common:button:cancel')}
                                    </StyledButton>
                                </StyledButtonContainer>
                            </Grid>
                        </Grid>
                    </form>
                    {this.state.isLoading === true &&
                        <div className="loading">
                            <LoadingSpinner />
                        </div>
                    }
                </StyledRegisterWrapper>
            </StyledComponent>
        );
    }
}