import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import { ParcelType } from "../../../../enums/productEnum";
import EllipsisText from "../../../../common/elements/EllipsisText";
import ItemDetail, { ItemDetailWithSignIn } from "./ItemDetail";
import { getNumberFormat } from "../../../../services/NumberFormatService";

import { makeLinkClickable } from "../../../../common/GenericFunctions";

const StyledItemGrid = styled(Grid)``;

let prebidAmount: string = "";
let startingAmount: string = "";
let conversionStartingAmount: string = "";
let minimumAmount: string = "";
let conversionMinimumAmount: string = "";
let totalAmount: string = "";
let conversionTotalAmount: string = "";
interface IProps {
  isPDView: boolean;
  auctionDate: string;
  productData: any;
  onClickSignIn: () => void;
}

const ProductDetails = (props: IProps) => {
  const { isPDView, auctionDate, productData, onClickSignIn } = props;

  // selectors
  let preferredCurrency = localStorage.getItem("preferredCurrency");
  let preferredCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const {
    serialNo,
    code,
    carat,
    color,
    clarity,
    cutGrade,
    t3EX,
    flour,
    length,
    width,
    height,
    polish,
    symmetry,
    shape,
    origin,
    lab,
    certificate,
    fancyIntensity,
    fancyColor,
    caratMinimumPrice,
    startingPrice,
    rapnetPercentage,
    rapnetPrice,
    hnc,
    preBidPrice,
    sellingPriceUsd,
    remarks,
    parcelType,
    sieve,
    noOfPieces,
    peicesPerCarat,
    averageSize,
    productDescription,
    currency,
    exchangeRates,
  } = productData;
  const userCurrencyCode = preferredCurrency;
  const userCurrencySymbol = preferredCurrencySymbol;

  let isClickableRemark: any = makeLinkClickable(
    parcelType === "Parcel" ? productDescription : remarks
  );

  useEffect(() => {
    prebidAmount = "";
  }, []);

  startingAmount = getNumberFormat(startingPrice);
  minimumAmount = getNumberFormat(caratMinimumPrice);
  prebidAmount = getNumberFormat(preBidPrice);

  const checkAmount = (amount: any) => {
    return amount === null || amount === "NaN" || amount === "0";
  };

  const isParcelTypeDiamond = () => {
    return parcelType === ParcelType.DIAMOND;
  };

  const isParcelTypeParcel = () => {
    return parcelType === ParcelType.PARCEL;
  };

  if (checkAmount(minimumAmount)) {
    minimumAmount = "-";
  } else {
    if (userCurrencyCode !== currency["code"]) {
      let conversionPrice = getNumberFormat(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionMinimumAmount = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionMinimumAmount = "";
    }
    minimumAmount = `${currency["symbol"]}${minimumAmount} ${conversionMinimumAmount}`;

    if (isParcelTypeParcel()) {
      let totAmt = caratMinimumPrice * Number(carat);
      if (userCurrencyCode !== currency["code"]) {
        let conversionTotAmt = getNumberFormat(
          Math.round(totAmt * exchangeRates[0]["rate"])
        );
        conversionTotalAmount = `(${userCurrencySymbol}${conversionTotAmt})`;
      } else {
        conversionTotalAmount = "";
      }
      totalAmount = `${currency["symbol"]}${getNumberFormat(
        Math.round(totAmt)
      )}`;
    }
  }

  if (checkAmount(startingAmount)) {
    startingAmount = "-";
  } else {
    if (userCurrencyCode !== currency["code"]) {
      let conversionPriceS = getNumberFormat(
        Math.round(startingPrice * exchangeRates[0]["rate"])
      );
      conversionStartingAmount = `(${userCurrencySymbol} ${conversionPriceS})`;
    } else {
      conversionStartingAmount = "";
    }
    startingAmount = `${currency["symbol"]} ${startingAmount}`;
  }

  if (prebidAmount === "0" || prebidAmount === "NaN") {
    prebidAmount = "";
  }

  const lengthWidthHeight = `${length ? length : ""} * ${
    width ? width : ""
  } * ${height ? height : ""}`;

  const renderLeftColParcelTypeDiamond = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:serialNo"}
          value={serialNo}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:startingPrice"}
          value={`${minimumAmount} ${" "}`}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:auctionStartDate"}
          value={auctionDate}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:itemCode"}
          value={code}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:noOfPieces"}
          value={noOfPieces === 0 ? "-" : noOfPieces}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:carat"}
          value={carat}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:color"}
          value={color}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:clarity"}
          value={<EllipsisText text={clarity} length={30} tail={"..."} />}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:cutGrade"}
          value={<EllipsisText text={cutGrade} length={30} tail={"..."} />}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:ex"}
          value={t3EX}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:hnc"}
          value={hnc}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:flour"}
          value={flour}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:lengthWidthHeight"}
          value={lengthWidthHeight}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:shape"}
          value={shape}
        />
      </>
    );
  };

  const renderRightColParcelTypeDiamond = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:origin"}
          value={origin}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:polish"}
          value={polish}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:symmetry"}
          value={symmetry}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:lab"}
          value={lab}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:certificate"}
          value={certificate}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:fancyIntensity"}
          value={fancyIntensity}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:fancyColor"}
          value={fancyColor}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:amountOfStartingPrice"}
          value={`${startingAmount} ${" "} ${
            minimumAmount === "-" ? "-" : conversionStartingAmount
          }`}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:pricePerCarat"}
          value={sellingPriceUsd === 0 ? "-" : sellingPriceUsd}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:rap"}
          value={rapnetPercentage}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:rapPrice"}
          value={rapnetPrice}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:exchangeRate"}
          value={exchangeRates[0]["rate"]}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:remarks"}
          value={remarks}
        >
          {isClickableRemark !== false ? (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  isClickableRemark,
                  "DescriptiveWindowName",
                  "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                );
              }}
            >
              {remarks && (
                <EllipsisText text={remarks} length={70} tail={"..."} />
              )}
            </span>
          ) : (
            <>
              {remarks && (
                <EllipsisText text={remarks} length={70} tail={"..."} />
              )}
            </>
          )}
        </ItemDetail>
      </>
    );
  };

  const renderLeftColParcelTypeParcel = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:serialNo"}
          value={serialNo}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:startingPrice"}
          value={`${minimumAmount} ${" "}`}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:shape"}
          value={<EllipsisText text={shape} length={30} tail={"..."} />}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:sieve"}
          value={sieve}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:noOfPieces"}
          value={noOfPieces === 0 ? "-" : noOfPieces}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:piecesPCT"}
          value={peicesPerCarat === 0 ? "-" : peicesPerCarat}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:pricePctUsd"}
          value={sellingPriceUsd === 0 ? "-" : sellingPriceUsd}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:totalAmount"}
          value={
            minimumAmount === "-"
              ? "-"
              : `${totalAmount} ${" "} ${conversionTotalAmount}`
          }
          onClickSignIn={onClickSignIn}
        />
      </>
    );
  };

  const renderRightColParcelTypeParcel = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:auctionStartDate"}
          value={auctionDate}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:itemCode"}
          value={code}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:color"}
          value={<EllipsisText text={color} length={30} tail={"..."} />}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:totalCts"}
          value={carat}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:avgSize"}
          value={
            averageSize === "Infinity" || averageSize === 0 ? "-" : averageSize
          }
        />
        <ItemDetail
          label={"common:preview:perItemDescription:remarks"}
          value={null}
        >
          {isClickableRemark !== false ? (
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  isClickableRemark,
                  "DescriptiveWindowName",
                  "height=700px,width=1024px,scrollbars,toolbar,status,resizable"
                );
              }}
            >
              {productDescription && (
                <EllipsisText
                  text={productDescription}
                  length={70}
                  tail={"..."}
                />
              )}
            </span>
          ) : (
            <>
              {productDescription && (
                <EllipsisText
                  text={productDescription}
                  length={70}
                  tail={"..."}
                />
              )}
            </>
          )}
        </ItemDetail>
        <ItemDetail
          label={"common:preview:perItemDescription:exchangeRate"}
          value={exchangeRates[0]["rate"]}
        />
      </>
    );
  };

  return (
    <StyledItemGrid container={true} spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {isParcelTypeDiamond()
          ? renderLeftColParcelTypeDiamond()
          : isParcelTypeParcel() && renderLeftColParcelTypeParcel()}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {isParcelTypeDiamond()
          ? renderRightColParcelTypeDiamond()
          : isParcelTypeParcel() && renderRightColParcelTypeParcel()}
      </Grid>
    </StyledItemGrid>
  );
};

export default ProductDetails;
