const ParcelTypeShapesList = [
    {
        id: '1',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round',
        value: "RD",
        selectedValue: false
    }, 
    {
        id: '2',
        image: {
            colored: 'emerald_color.png',
            grey: 'emerald_grey.png'
        },
        name: 'Fancy Mix',
        value: "FM",
        selectedValue: false
    },{
        id: '3',
        image: {
            colored: 'emerald_color.png',
            grey: 'emerald_grey.png'
        },
        name: 'CAR',
        value: "CAR",
        selectedValue: false
    },{
        id: '4',
        image: {
            colored: 'emerald_color.png',
            grey: 'emerald_grey.png'
        },
        name: 'CAR & BG',
        value: "CAR&BG",
        selectedValue: false
    },{
        id: '5',
        image: {
            colored: 'emerald_color.png',
            grey: 'emerald_grey.png'
        },
        name: 'Emerald Cut',
        value: "EM",
        selectedValue: false
    }, {
        id: '6',
        image: {
            colored: 'marquis_color.png',
            grey: 'marquis_grey.png'
        },
        name: 'Marquize',
        value: "MQ",
        selectedValue: false
    }, {
        id: '7',
        image: {
            colored: 'princess_color.png',
            grey: 'princess_grey.png'
        },
        name: 'Princess',
        value: "PR",
        selectedValue: false
    }, {
        id: '8',
        image: {
            colored: 'oval_color.png',
            grey: 'oval_grey.png'
        },
        name: 'Oval',
        value: "OV",
        selectedValue: false
    }, {
        id: '9',
        image: {
            colored: 'pear_color.png',
            grey: 'pear_grey.png'
        },
        name: 'Pear Shape',
        value: "PS",
        selectedValue: false
    }, {
        id: '10',
        image: {
            colored: 'asscher_color.png',
            grey: 'asscher_grey.png'
        },
        name: 'Asscher',
        value: "Asscher",
        selectedValue: false
    }, {
        id: '11',
        image: {
            colored: 'radiant_color.png',
            grey: 'radiant_grey.png'
        },
        name: 'Radiant',
        value: "RAD",
        selectedValue: false
    },  {
        id: '12',
        image: {
            colored: 'hear_color.png',
            grey: 'hear_grey.png'
        },
        name: 'Heart Shape',
        value: "HS",
        selectedValue: false
    }, {
        id: '13',
        image: {
            colored: 'cushion_color.png',
            grey: 'cushion_grey.png'
        },
        name: 'Cushion Brilliant',
        value: "CB",
        selectedValue: false
    }, {
        id: '14',
        image: {
            colored: 'asscher_color.png',
            grey: 'asscher_grey.png'
        },
        name: 'Baguette',
        value: "BG",
        selectedValue: false
    }, {
        id: '15',
        image: {
            colored: 'radiant_color.png',
            grey: 'radiant_grey.png'
        },
        name: 'Rectangular',
        value: "RCT",
        selectedValue: false
    }, {
        id: '16',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round Brilliant',
        value: "RB",
        selectedValue: false
    }, {
        id: '17',
        image: {
            colored: 'round_color.png',
            grey: 'round_grey.png'
        },
        name: 'Round Brilliant Modified',
        value: "RMB",
        selectedValue: false
    }, {
        id: '18',
        image: {
            colored: 'square_color.png',
            grey: 'square_grey.png'
        },
        name: 'Square',
        value: "SQ",
        selectedValue: false
    }, {
        id: '19',
        image: {
            colored: 'tapered_baguette_color.png',
            grey: 'tapered_baguette_grey.png'
        },
        name: 'Tapered Baguette',
        value: "TBAG",
        selectedValue: false
    }, {
        id: '20',
        image: {
            colored: 'trilliant_color.png',
            grey: 'trilliant_grey.png'
        },
        name: 'Trilliant',
        value: "TRL",
        selectedValue: false
    }, {
        id: '21',
        image: {
            colored: 'trilliant_color.png',
            grey: 'trilliant_grey.png'
        },
        name: 'TP',
        value: "TP",
        selectedValue: false
    }, {
        id: '22',
        image: {
            colored: 'trilliant_color.png',
            grey: 'trilliant_grey.png'
        },
        name: 'Single Cut',
        value: "SingleCut",
        selectedValue: false
    }, {
        id: '23',
        image: {
            colored: 'trilliant_color.png',
            grey: 'trilliant_grey.png'
        },
        name: 'Rose Cut',
        value: "RoseCut",
        selectedValue: false
    }, {
        id: '24',
        image: {
            colored: 'unknown_color.png',
            grey: 'unknown_grey.png'
        },
        name: 'Unknown',
        value: "X",
        selectedValue: false
    }
];

export default ParcelTypeShapesList;