import React from 'react';
import Styled from 'styled-components';

const StyledFieldset = Styled.fieldset`
    border: 1px solid ${(props) => props.theme.colors.base.fieldSetBorderColor};
`;

const StyledLegend = Styled.legend`
    padding: 0.2em 0.5em;
    font-size: 90%;
    text-align: left;
    margin-left: 0.5em;
    width: auto;
`;

interface IProps {
    t?: any;
    legend: string;
    children: any;
}

const FieldSet = (props: IProps) => {
    const { legend, children } = props;
    return (
        <StyledFieldset>
            <StyledLegend>
                {legend}
            </StyledLegend>
            {children}
        </StyledFieldset>
    )
};

export default FieldSet;