import React from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import ReactPlayer from 'react-player';

const StyledComponent = styled(Grid)`
    outline: none;
`;

const StyledWrapper = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 0.5em 0.5em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 350px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 600px;
    height: 450px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 50vw;
    height: 70vh;
  }
`;


const StyledParticipateWrapper = styled(Grid)`
    text-align: center;
    padding: 10px 20px;
`;

// const StyledFrame = styled.iframe`
//   width: 100%;
//   height: 100%;
//   border: 0px;
//   ${(props) => props.theme.breakpoints.between("xs", "md")} {
//     height: 300px;
//     width: 90%;
//   }
//   ${(props) => props.theme.breakpoints.between("md", "lg")} {
//     height: 90%;
//   }
//   ${(props) => props.theme.breakpoints.between("lg", "xl")} {
//     height: 90%;
//   }
// `;

const StyledPlayerSlide = styled.div`
  width: 100%;
  height: 100%;
  border: 0px;
  display: flex;
  align-items: center;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    height: 300px;
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    height: 100%;
  }
`;
const StyledFrame = styled.iframe`
  width: 100%;
  height: 360px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 160px;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    height: 370px;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 580px;
  }
`;

const PlayerSlide = ({ url }: { url: string }) => (
  <ReactPlayer
    width="100%"
    height="100%"
    url={url}
    playing={false}
    controls={true}
    muted={true}
    progressInterval={1000}
    style={{maxHeight:"80vh"}}
  />
);  

let mediaTypeProps: string | null;

const ViewRegisterModal = (props: any) => {
    const { videoUrl, medias, mediaTypeForFullView } = props;
    if(medias !== undefined){
        // const { mediaType } = medias[0];
        mediaTypeProps = mediaTypeForFullView;
    }
    return (
      <StyledComponent tabIndex={-1}>
        <StyledWrapper container spacing={0}>
          <StyledParticipateWrapper item md={12} xs={12} lg={12}>
            {mediaTypeProps === "VIDEO" ? (
              <StyledPlayerSlide>
                {" "}
                <PlayerSlide url={`${videoUrl}`} />
              </StyledPlayerSlide>
            ) : (
              <StyledFrame src={`${videoUrl}&sr=100`} />
            )}
          </StyledParticipateWrapper>
        </StyledWrapper>
      </StyledComponent>
    );
}

export default ViewRegisterModal;