import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styled from 'styled-components';
import ItemsCarousel from 'react-items-carousel';

import { Grid } from '@material-ui/core';
import AuthService from '../auth/AuthService';
import { productPublicApi } from '../../common/config/app.endpoints';
import ProductInSliderHomePage from '../home-product-slider/ProductInSliderHomePage';

const StyledIconContainer = styled.span`
    width: 33px;
    height: 33px;
    margin-left: 10px;
    display: inline-block;
    &.rightIcon {
        margin-left: 0px;
        margin-right: 5px;
    }
`;

const StyledIcon = styled('img')`
    width: 100%;
    height: auto;
    cursor: pointer;
`;

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.textColor}
`;

const PrevButton = () => {
    return (
        <StyledIconContainer>
            <StyledIcon src='/images/controls/left_arrow_round_edges.png' />
        </StyledIconContainer>
    )
};

const NextButton = () => {
    return (
        <StyledIconContainer className="rightIcon">
            <StyledIcon src='/images/controls/right_arrow_round_edges.png' />
        </StyledIconContainer>
    )
};

export interface IProps {
    t: any;
    onParticipate: () => void;
}

let previewDate: string = '';
let auctionDate: string = '';

export default (props: IProps) => {
    const { t } = props;
    const [activeItemIndex, setActiveItemIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [auctions, setAuctions] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const chevronWidth = 40;
    let numberOfCardsCount = 1;

    const theme = useTheme();
    const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmallDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const isMediumDevice = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLargeDevice = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isExtraLargeDevice = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

    if (isExtraSmallDevice) {
        numberOfCardsCount = 1;
    } else if (isSmallDevice) {
        numberOfCardsCount = 2;
    } else if (isMediumDevice) {
        numberOfCardsCount = 4;
    } else if (isLargeDevice) {
        numberOfCardsCount = 4;
    } else if (isExtraLargeDevice) {
        numberOfCardsCount = 6;
    }

    useEffect(() => {
        const Auth = new AuthService();
        setTimeout(() => {
            Auth.getRequestWithoutAuthorization(productPublicApi, (status: number, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        if(response !== ""){
                            setProducts(response.results);
                            setIsLoading(false);
                        }else{
                            setProducts([]);
                            setIsLoading(false);
                        }
                    }
                }
            })
        }, 2000);
        return () => setAuctions([]);
    }, []);

    const onPress = () => {
        props.onParticipate();
    }

    const auctionFunction = (item: any) => {
        previewDate = (item.auctionPreviewStartDate).split(" ", 2);
        auctionDate = (item.auctionStartDate).split(" ", 2);
    }

    return (
        <div style={{ padding: `0 ${chevronWidth}px` }}>
            {auctions !== undefined ?
                <div>
                    {products.length > 0 ?
                        <ItemsCarousel
                            requestToChangeActive={setActiveItemIndex}
                            activeItemIndex={activeItemIndex}
                            numberOfCards={numberOfCardsCount}
                            leftChevron={PrevButton()}
                            rightChevron={NextButton()}
                            chevronWidth={chevronWidth}
                            infiniteLoop={true}
                            outsideChevron
                        >
                            {products.map((item: any, index: number) => {
                                auctionFunction(item);
                                return <div key={index}>
                                    <ProductInSliderHomePage  
                                        {...item}
                                        items= {products}
                                        onParticipate={onPress}
                                        joinedAuctions={[]}
                                    />
                                </div>
                            }
                            )}
                        </ItemsCarousel> :
                        <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{isLoading ? "Loading..." : `${t("common:home:noProductsAvailable")}`}</StyledTextWrapper>
                    }
                </div>
                : <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{isLoading ? "Loading..." : `${t("common:home:noProductsAvailable")}`}</StyledTextWrapper>
            }
        </div>

    );
};
