import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api from "../../../services/ApiService";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import { getCurrency } from "../../../common/config/app.endpoints";
export interface ICurrency {
  perId: string;
  name: string;
  symbol: string;
  code: string;
}

const currencyAdapter = createEntityAdapter();
const initialState = currencyAdapter.getInitialState({
  loading: false,
  error: "",
});

const updateListItemWithIdProperty = (list: ICurrency[]) => {
  return list.map((item) => {
    return { ...item, id: item.perId };
  });
};

export const getCurrencyList = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("currency/getCurrencyList", async (data, { rejectWithValue }) => {
  try {
    const response = await api.get(getCurrency);
    return updateListItemWithIdProperty(response.data);
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // Get country list
    builder.addCase(
      getCurrencyList.pending,
      (state: any, action: PayloadAction) => {
        state.loading = true;
        state.error = "";
      }
    );
    builder.addCase(
      getCurrencyList.fulfilled,
      (state: any, action: PayloadAction) => {
        currencyAdapter.setAll(state, action);
        state.loading = false;
      }
    );
    builder.addCase(
      getCurrencyList.rejected,
      (state: any, action: PayloadAction<IErrorPayload>) => {
        state.error = action.payload || "Error";
        state.loading = false;
        currencyAdapter.removeAll(state);
      }
    );
  },
});

export default currencySlice.reducer;

export const {
  selectById: selectCurrencyById,
  selectIds: selectCurrencyIds,
  selectEntities: selectCurrencyEntities,
  selectAll: selectAllCurrency,
  selectTotal: selectTotalCurrency,
} = currencyAdapter.getSelectors<RootState>(
  (state: RootState) => state.currency
);

// selectors
export const selectCurrencyLoading = (state: RootState) =>
  state.currency.loading;
