export enum AuctionState {
  PAUSED = "Paused",
  STARTED = "Started",
  NOTSTARTED = "NotStarted",
  AUCTIONBREAK = "Auction_Break",
}

export enum MediaType {
  PHOTO = "Photo",
}

export enum AuctionCategory {
  AUCTION = "auction",
  JEWELLERYAUCTION = "jewelleryAuction",
}

export enum AuctionType {
  LIVE = "Live",
  POPCORNBIDDING = "Popcorn_Bidding",
}
