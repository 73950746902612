import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { sideMenuGradient, backgroundGradient } from '../../common/materials/LinearGradient';
import { useTranslation } from 'react-i18next';


const StyledGrid = styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
   .collapse {
        border-radius: 15px;
        background: ${sideMenuGradient};
        display: flex;
    }
    .iconColor {
        color: ${(props) => props.theme.colors.base.white};
    }

    .shadow {
        -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
        box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey}; 
    }
`;

const StyledWrapper = styled(Grid)`
    padding: 15px 0 5px;
    // max-width: 1000px;
    -webkit-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    -moz-box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
    box-shadow: 0px 0px 8px 0px ${(props) => props.theme.colors.base.lightGrey};
`;

const StyledHeading = styled(Grid)`    
    width: 100%;
    text-align: center;
    color: ${backgroundGradient};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 10px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 22px;
    }
    @media (width: 2560px) {
        font-size: 24px;
    }
`;

const StyledCollapsibleWrapper = styled(Grid)`
    padding: 2px;
    color: ${(props) => props.theme.colors.base.white};
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 16px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 16px;
    } 
    @media (width: 2560px) {
        font-size: 24px;
    }
`;

const StyledCollapsibleContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 2px;  
    text-align: center;
    justify-content: center;
    color: blue;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 18px;
    } 
    ${props => props.theme.breakpoints.between('sm', 'xl')} {
        font-size: 18px;
    } 
    @media (width: 2560px) {
        font-size: 24px;
    }
`;

const StyledContainer = styled(Grid)`

`;

const BuyDiamonds = (props: any) => {
    const { t } = useTranslation();

    return (
        <StyledContainer container={true} spacing={0}>
            <StyledGrid item={true} xs={12}>
                <StyledWrapper item={true} xs={12} md={12} lg={12}>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">Personalized Auction</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer>
                                        Bid It to Win It!
                                    </StyledCollapsibleContainer>
                                    <div style={{ display: "flex", flexDirection: "column", fontSize:'1em',textAlign: "justify", padding: "30px"}}>
                                        <ul>
                                            <li><span style={{ color: 'blue' }}>Sign up as the Bidder or Both.</span> Complete your KYC and get verified to get subscription to MyAuctions.</li>
                                            <li>Choose the auction you want to bid in – <span style={{ color: 'blue' }}>Live</span> or <span style={{ color: 'blue' }}>Popcorn Bidding</span>. Bid, bid, and bid to win, win, and win.</li>
                                            <li>Do <span style={{ color: 'blue' }}>bulk bidding</span> and proxy bidding.</li>
                                            <li>View your <span style={{ color: 'blue' }}>invoices</span> instantly after auction ends and pay for your products. Track the <span style={{ color: 'blue' }}> shipment</span> and enjoy!</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                    <StyledCollapsibleWrapper item xs={12}>
                        <Accordion>
                            <AccordionSummary className="collapse" expandIcon={<ExpandMoreIcon className="iconColor" />}>
                                <StyledCollapsibleWrapper className="fontBold">©#365DaysAuction</StyledCollapsibleWrapper>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={0} className="shadow padding">
                                    <StyledCollapsibleContainer style={{ fontSize:'1em'}}>Auction is not about one time bidding, its about finding the infinity in bidding.</StyledCollapsibleContainer>
                                    <div style={{ display: "flex", flexDirection: "column", fontSize:'1em', textAlign: "justify", padding: "30px"}}>
                                        <ul>
                                            <li>Get bidding on variety of diamond and jewellery products from Japan Pawan market and from different sellers.</li>
                                            <li>Get <span style={{ color: 'blue' }}>simultaneously invoice generation</span> of won products in your <span style={{ color: 'blue' }}>product cart</span>.<span style={{ color: 'blue' }}>Request the invoice</span> and pay for your products.</li>
                                            <li>Track the <span style={{ color: 'blue' }}>shipment </span> and enjoy!</li>
                                        </ul>
                                    </div>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </StyledCollapsibleWrapper>
                </StyledWrapper>
            </StyledGrid>
        </StyledContainer>
    )
}

export default BuyDiamonds;