import React, { useRef, useState } from "react";
import {
  FormControl,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  StyledFooterContainerdummy,
  StyledFormdummy,
  StyledMessage,
} from "./StyledComponets";
import Button from "../../../common/elements/Button";

import ReCaptcha from "../../kyc/components/ReCaptcha";
import { contactUs } from "../Services";
import Alert from "../../../common/elements/Alert";

const useStyles = makeStyles({
  stdMargin: {
    marginBottom: "",
  },
  marginTop: {
    marginTop: "0.2em",
  },
});

const ContactUsDummy = ({ isMobile }: { isMobile: boolean }) => {
  const { t } = useTranslation();
  const contactUsFormRef = useRef(null);

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [errorMessage, setErrorShowMessage] = useState("");
  const classes = useStyles();

  const handelFormSubmit = async () => {
    setErrorShowMessage("");
    const form = contactUsFormRef.current;
    if (!form) {
      return;
    }
    const errors = {} as any;
    const formData = new FormData(form);
    const values = Object.fromEntries(formData.entries()) as any;
    const { name, email, phone, message } = values;

    for (const field in values) {
      const elem = form[field] as {
        value: any;
        type: string;
        getAttribute: any;
      };
      if (elem?.type) {
        const required = elem?.getAttribute("required") === "";
        if (required && !elem.value) {
          errors[field] = true;
        }
      }
    }

    let hasErrors = false;
    const validDatonError = validation({
      name,
      email,
      phone,
      checked,
      message,
    });
    for (let error in errors) {
      hasErrors = true;
      break;
    }
    if (hasErrors || !checked || validDatonError) {
      return setErrorShowMessage(validDatonError);
    }
    initiateSend({
      name,
      email,
      phone,
      message,
    });
  };

  const validation = (formData: {
    email: string;
    name: string;
    phone: string;
    checked: boolean;
    message: boolean;
  }) => {
    const email = new RegExp(
      /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,20}/g
    ).test(formData.email.trim());
    var digits = /^(\+){0,1}[0-9]{8,15}$/;
    if (formData.name === "") {
      return t("common:register:errorMessage:onfirstName");
    } else if (email !== true) {
      return t("common:register:errorMessage:onEmail");
    } else if (formData.phone.length < 10) {
      return t("common:register:errorMessage:phoneNumber");
    } else if (!formData.phone.match(digits)) {
      return t("common:register:errorMessage:onMobileNumber");
    } else if (!formData.checked) {
      return t("trans:pleaseVerifyCaptchs");
    } else if (!formData.message) {
      return t("common:inquiry:typeMessege");
    }
    return "";
  };

  const initiateSend = async (formData: {
    [k: string]: FormDataEntryValue;
  }) => {
    setLoading(true);
    const { response, status } = await contactUs(formData);
    setLoading(false);
    if (status === 200) {
      setShowMessage('We have received your request. Our team will contact you through email shortly.');
      setTimeout(() => {
        setShowMessage('');
      }, 2000);
      (contactUsFormRef.current as any).reset();
      (window["grecaptcha" as any] as any).reset();
    } else if (status > 200 && status < 500) {
      if (status === 400) {
        setShowMessage(t("common:somethingWentWrong"));
      } else if (response[0].code !== undefined) {
        if (response[0].code === "KB409001") {
          setShowMessage(t("common:register:errorMessage:profileVerification"));
        } else if (response[0].code === "KB409002") {
          setShowMessage(t("common:register:errorMessage:alreadyRegistered"));
        } else if (response[0].code === "KB400001") {
          setShowMessage(
            t("common:register:errorMessage:correctInvitationCode")
          );
        }
      } else {
        setShowMessage(response[0].message);
      }
    } else {
      setShowMessage(t("common:somethingWentWrong"));
    }
   
  };

  return (
    <StyledFooterContainerdummy isMobile={isMobile}>
      {showMessage && <Alert showAlertMessage={showMessage} alertType={""} />}
      <span>In case of any queries,feel free to reach out to us at</span>
      <StyledFormdummy ref={contactUsFormRef} noValidate autoComplete="off">
        <div style={{ marginBottom: "5px" }}>
          <FormControl fullWidth>
            <TextField
              required
              label={t("common:kycFrom:name")}
              type="text"
              name="name"
              style={{ background: "white", color: "grey" }}
            />
          </FormControl>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <FormControl fullWidth>
            <TextField
              required
              label={t("common:register:labels:email")}
              type="text"
              name="email"
              style={{ background: "white", color: "grey" }}
            />
          </FormControl>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <FormControl fullWidth>
            <TextField
              required
              label={t("common:register:labels:phone")}
              type="text"
              name="phone"
              style={{ background: "white", color: "grey" }}
            />
          </FormControl>
        </div>
        <div style={{ marginBottom: "5px" }}>
          <FormControl fullWidth>
            <TextField
              required
              multiline
              label={t("common:message")}
              name="message"
              rows={4}
              style={{ background: "white", color: "grey" }}
            />
          </FormControl>
        </div>
      </StyledFormdummy>
      {errorMessage && <StyledMessage>{errorMessage}</StyledMessage>}
      <Grid container className={classes.stdMargin}>
        <Grid
          className={classes.stdMargin}
          container
          style={{ justifyContent: "center" }}
        >
          <Grid style={{ marginTop: "5px" }}>
            <ReCaptcha setIsVerfied={setChecked} />
          </Grid>
          <Button
            noMargin
            type="contained-white"
            onClick={handelFormSubmit}
            disabled={loading}
          >
            {t("common:kycFrom:submit")}
          </Button>
        </Grid>
      </Grid>
    </StyledFooterContainerdummy>
  );
};

export default ContactUsDummy;
