import React, { useEffect, useState } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

const StyledGrid = styled(Grid)`
    .alert {
        background: ${(props) => props.theme.colors.base.red};
    }
    .MuiSnackbar-anchorOriginBottomCenter {
        bottom:85% !important;
    }
`;

export interface IProps {
  isReadOnlyUser: boolean;
  isSeller: boolean;
}

const AlertReadOnlyUser = (props: IProps) => {
    const [alertMessage, setAlertMessage] = useState('');
    const [openToast, setOpenToast] = useState(false);
    const [alertType, setAlertType] = useState("error");
    const { isReadOnlyUser,isSeller } = props;

    useEffect(() => {
      if(isReadOnlyUser && isSeller){
        setOpenToast(true);
        setAlertMessage("You are the read only user and seller");
      }else if(isReadOnlyUser){
        setOpenToast(true);
        setAlertMessage("You are the read only user");
      }else if(isSeller){
        setOpenToast(true);
        setAlertMessage("You are the seller");
      }else{
        setOpenToast(false);
      }        
    }, []);

    function Alert(props: AlertProps) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenToast(false);
    };

    return (
        <StyledGrid>
            <Snackbar open={openToast} autoHideDuration={3000} onClose={handleClose}>
                {alertType === 'error' ?
                    <Alert onClose={handleClose} className="alert">
                        {alertMessage}
                    </Alert> 
                    :
                    <Alert onClose={handleClose} severity="success">
                        {alertMessage}
                    </Alert>
                }
            </Snackbar>
        </StyledGrid>
    )
}

export default AlertReadOnlyUser;