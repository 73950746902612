import { sellPostUrl } from "../../common/config/app.endpoints";
import AuthService from "../../components/auth/AuthService";

const Auth = new AuthService();

export const postSellPageData = async (data: any) => {
  return (await new Promise((res) => {
    const callback = (status: number, data: any) => {
      res({ response: data.data, status });
    };
    Auth.postRequestForSellPage(sellPostUrl, data, callback);
  })) as unknown as Promise<{
    response: any;
    status: number;
  }>;
};
