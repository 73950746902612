import React from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import InvoiceListView from './InvoiceListView';

const StyledGrid = styled(Grid)`
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

const StyledDateWrapper = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.base.grey};
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 14px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    font-size: 14px;
  }
`;

const StyledDateGrid = styled(Grid)`
  width: 100%;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
`;

interface IProducts {
    name: string;
    productCode: string;
    startDate: any;
    endDate: any;
    totalPrice: string | number;
}

interface IProps {
    t?: any;
    history?: any;
    currentRecords?: IProducts;
    shippingAddress: any;
    sendMessage: (message: string) => void;
    apiTimeout: (timeout: boolean) => void;
    downloadpdf: (invoiceId: string, auctionName: string, onClick: boolean) => void;
    downloadinternationalInvoice: (auctionId: string, auctionName: string, onClick: boolean) => void;
    trakingUIInvoice: (auctionIdTracking: any) => void;
    
}

interface IState {
  products: [];
  diamondAndParcelList: [];
  jewellerylist: [];
}

export default class InvoiceListHome extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      products: [],
      diamondAndParcelList: [],
      jewellerylist: [],
    };
  }

  filterCategoryWiseAuctionList(auctionList: any) {
    const DiamondAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Diamonds";
    });

    const ParcelAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Parcel";
    });

    const JewelleryAuctionData = auctionList.filter((obj: any) => {
      return obj.category === "Jewellery";
    });

    let diamondAndParcelAuctionArray: any;

    diamondAndParcelAuctionArray = [
      ...DiamondAuctionData,
      ...ParcelAuctionData,
    ];
    this.setState(() => ({
      diamondAndParcelList: diamondAndParcelAuctionArray,
      jewellerylist: JewelleryAuctionData,
    }));
  }

  setProducts(products: any) {
    this.setState(() => ({
      products,
    }));
  }

  componentDidMount() {
    // this.setProducts(this.props.currentRecords);
    this.filterCategoryWiseAuctionList(this.props.currentRecords);
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.currentRecords !== this.props.currentRecords) {
    //   this.setProducts(this.props.currentRecords);
      this.filterCategoryWiseAuctionList(this.props.currentRecords);
    }
  }

  timeout = (timeout: boolean) => {
    this.props.apiTimeout(timeout);
  };

  sendMessage = (message: string) => {
    this.props.sendMessage(message);
  };

  downloadPdf = (id: string, auctionName: string, onClickDownload: boolean) => {
    if (onClickDownload === true) {
      this.props.downloadpdf(id, auctionName, onClickDownload);
    }
  };
  downloadinternationalInvoice = (
    auctionId: string,
    auctionName: string,
    onClickDownloads: boolean
  ) => {
    if (onClickDownloads === true) {
      this.props.downloadinternationalInvoice(
        auctionId,
        auctionName,
        onClickDownloads
      );
    }
  };

  trakingUI = (auctionId: string) => {
    return this.props.trakingUIInvoice(auctionId);
  };

  render() {
    const { t } = this.props;
    return (
      <StyledGrid container={true} spacing={0}>
        <StyledDateGrid item xs={12}>
          <StyledDateWrapper className="fontBold">
            {t("common:newCommanTrans:diamondandParcel")}
          </StyledDateWrapper>
        </StyledDateGrid>
        {this.state.diamondAndParcelList.map((product: any, index: number) => {
          return (
            <Grid key={index} item={true} xs={12} sm={6} md={6} lg={4} xl={4}>
              <InvoiceListView
                {...product}
                t={t}
                shippingAddress={this.props.shippingAddress}
                sendMessage={this.sendMessage}
                apiTimeout={this.timeout}
                downloadpdf={this.downloadPdf}
                downloadinternationalInvoice={this.downloadinternationalInvoice}
                openTrackingUiWithId={this.trakingUI}
              />
            </Grid>
          );
        })}
        <StyledDateGrid item xs={12}>
          <StyledDateWrapper className="fontBold">
            {" "}
            {t("common:newCommanTrans:jewellery")}
          </StyledDateWrapper>
        </StyledDateGrid>
        {this.state.jewellerylist.map((product: any, index: number) => {
          return (
            <Grid key={index} item={true} xs={12} sm={6} md={6} lg={4} xl={4}>
              <InvoiceListView
                {...product}
                t={t}
                shippingAddress={this.props.shippingAddress}
                sendMessage={this.sendMessage}
                apiTimeout={this.timeout}
                downloadpdf={this.downloadPdf}
                downloadinternationalInvoice={this.downloadinternationalInvoice}
                openTrackingUiWithId={this.trakingUI}
              />
            </Grid>
          );
        })}
      </StyledGrid>
    );
  }
}