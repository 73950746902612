import React from "react";
import moment from "moment";
import Countdown from "react-countdown";
import { useHistory } from "react-router";
import styled from "styled-components";

const StyledText = styled.span`
`;

const SubTimecontainer = styled.div`
  display: flex;
  gap: 10px;
`;
interface Iprops {
  product: any;
  prebidEndTime: any;
}

let seconds: any = "";

const ActionAuctionState = (props: Iprops) => {
  let { product, prebidEndTime } = props;
  let history = useHistory();
  const productTimeLeft = (items: any, isPrebidingTime: boolean) => {
    let { auctionStartDate } = items[0];
    let date: string | null = "";
    let time: string | null = "";
    let timezone: any = "";
    let startTime = isPrebidingTime ? prebidEndTime : auctionStartDate;
    if (startTime !== null || startTime !== undefined) {
      let previewTime = moment.utc(startTime);
      if (timezone !== null) {
        timezone = localStorage.getItem("timezoneName");
      }
      if (previewTime !== null || previewTime !== undefined) {
        let result = moment.tz(previewTime, timezone);
        let resultFormatter = result.format();
        if (resultFormatter !== "Invalid date") {
          let splitDateTime = resultFormatter.split("T", 2);
          let splitTime: any = "";
          if (splitDateTime[1].includes("+")) {
            splitTime = splitDateTime[1].split("+", 2);
          } else {
            splitTime = splitDateTime[1].split("-", 2);
          }
          date = splitDateTime[0];
          time = splitTime[0];
          let dateTime = `${date} ${time}`;
          let end: any = moment(dateTime);
          timezone = localStorage.getItem("timezoneName");
          let start: any = moment(
            new Date().toLocaleString("en-US", { timeZone: timezone })
          );
          var timeDiff = end - start;
          seconds = Math.round(timeDiff);
        }
      }
    }
    return (
      <>
        {isPrebidingTime ? (
          <Countdown
            date={Date.now() + seconds}
            className="countdownText"
            renderer={prebidrenderer}
            zeroPadTime={2}
          />
        ) : (
          <Countdown
            date={Date.now() + seconds}
            className="countdownText"
            renderer={renderer}
            zeroPadTime={2}
          />
        )}
      </>
    );
  };

  const prebidrenderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        return <span>Pre-bidding Locked</span>;
      }
    } else {
      return (
        <span>
          <StyledText>Prebidding Ends in:</StyledText> {days}:{hours}:{minutes}:
          {seconds}
        </span>
      );
    }
  };

  const renderer = ({
    formatted: { days, hours, minutes, seconds },
    completed,
  }: any) => {
    if (completed) {
      if (
        days === "00" &&
        hours === "00" &&
        minutes === "00" &&
        seconds === "00"
      ) {
        return <span>Live Bidding is Ongoing</span>;
      }
    } else {
      return (
        <span>
          <StyledText>Live Bidding Starts in :</StyledText> {days}:{hours}:
          {minutes}:{seconds}
        </span>
      );
    }
  };

  const returnAuctionStateWiseAction = (product: any) => {
    let { auctionState } = product[0];
    let actionRender: any;

    switch (auctionState) {
      case "NotStarted":
        actionRender = productTimeLeft(product, true);
        break;
      case "Started":
        actionRender = (
          <>
            Live bidding from here 
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push("/auction");
              }}
            > Link</span>
          </>
        );
        break;
      case "Paused":
        actionRender = "Auction is paused";
        break;
      case "Auction_Break":
        actionRender = (
          <>
            Auction will resume is
            <span
              style={{ color: "blue", cursor: "pointer" }}
              onClick={() => {
                history.push("/auction");
              }}
            > Link</span>
          </>
        );
        break;
      case "Locked":
        actionRender = productTimeLeft(product, false);
        break;
      default:
        actionRender = "";
        break;
    }
    return actionRender;
  };

  return (
    <>
      {props.product.length > 0 ? (
        <>
          <SubTimecontainer>
            {returnAuctionStateWiseAction(product)}
          </SubTimecontainer>
        </>
      ) : (
        <>{returnAuctionStateWiseAction(product)}</>
      )}
    </>
  );
};

export default React.memo(ActionAuctionState);
