export const getDescription = (
  parcelType: any,
  productDescription: any,
  remarks: any
) => {
  if (parcelType === "Diamond") {
    if (remarks !== "" && remarks !== undefined && remarks !== null) {
      return remarks;
    } else {
      return "";
    }
  } else {
    if (
      productDescription !== "" &&
      productDescription !== undefined &&
      productDescription !== null
    ) {
      return productDescription;
    } else {
      return "";
    }
  }
};
