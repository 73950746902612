import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";
import {
  buttonGradient,
  dateBackgroundGradient,
} from "../../common/materials/LinearGradient";

const StyledLayoutGrid = styled(Grid)`
  padding: 10px 50px;
  display: flex-wrap;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 10px 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    padding: 10px 30px;
  }
  .loading {
    z-index: 1;
  }
`;

const StyledBoxContent = styled.div`
  width: 100%;
  text-align: right;
  height: 60px;
  margin-bottom: 20px;
`;

const StyledValue = styled.input`
  border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
  padding: 10px;
  width: 20%;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.base.grey};
  font-size: 0.8em;
`;

const StyledBoxMainContainer = styled.div`
  width: 100%;
`;

const styles = (theme: any) => ({
  root: {
    "& $notchedOutline": {
      borderWidth: 0,
    },
    "&:hover $notchedOutline": {
      borderWidth: 0,
    },
    "&$focused $notchedOutline": {
      borderWidth: 0,
    },
  },
  focused: {},
  notchedOutline: {},
});

const StyledTextWrapper = styled(Grid)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 1em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const StyledSearchBarContainer = styled.div`
  flex-direction: row;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const StyledAutoCompleteWrapper = styled.div`
  width: 300px;
`;

const StyledAdvanceButtonWrapper = styled.div`
  padding-top: 15px;
`;

const StyledWrapper = styled(Grid)`
  outline: none;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 2em;
  border-radius: 10px;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 400px;
  }
`;

const StyledParticipateWrapper = styled(Grid)`
  text-align: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    border-bottom: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    //border-right: 2px solid ${(props) => props.theme.colors.base.lightGrey};
  }
`;

interface IButtonProps {
  participate?: boolean;
  join?: boolean;
  disableParticipateButton?: boolean;
}

const StyledActionButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1.2em;
  text-align: center;
  font-family: Calibre-SemiBold;
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  ${(props) =>
    props.participate &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
  ${(props) =>
    props.join &&
    css`
      width: 40%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 30px;
      margin-left: 10px;
      &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
      }
    `}
    ${(props) =>
    props.disableParticipateButton &&
    css`
      width: 90%;
      padding: 0.5em 0.5em;
      padding-top: 0.8em;
      border-radius: 50px;
      cursor: default;
      background: ${dateBackgroundGradient};
    `}
`;

export {
  StyledLayoutGrid,
  StyledBoxContent,
  StyledValue,
  StyledBoxMainContainer,
  styles,
  StyledTextWrapper,
  StyledSearchBarContainer,
  StyledAutoCompleteWrapper,
  StyledAdvanceButtonWrapper,
  StyledWrapper,
  StyledParticipateWrapper,
  StyledActionButton,
};
