import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useDeviceDimensions = () => {
  const theme = useTheme();
  const isExtraSmallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmallDevice = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const isMediumDevice = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeDevice = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isExtraLargeDevice = useMediaQuery(
    theme.breakpoints.between("lg", "xl")
  );
  const notSmallDevices = useMediaQuery(theme.breakpoints.between("md", "xl"));
  const deviceBetWeenSMXL = useMediaQuery(
    theme.breakpoints.between("sm", "xl")
  );

  return {
    theme,
    isExtraSmallDevice,
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isExtraLargeDevice,
    notSmallDevices,
    deviceBetWeenSMXL,
  };
};

export default useDeviceDimensions;
