import React, { useEffect, useState } from "react";
import AppLayout from "../layouts/AppLayout";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import { useTranslation } from "react-i18next";
import {
  columnIndexes,
  convertProductPrice,
} from "./TableProps";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import {
  StyledMuiDataTableBtnContainer,
  StyledMuiDataTableLayoutGrid,
} from "./PendingProductsStyle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducer";
import {
  selectPendingProductList,
  resetPendingProductList,
  getPendingProductListReact,
  getPendingProductSortingList,
  productList,
} from "../../redux/slice/pendingProductteppo/pendingProductsSlice";
import {
  StyledButton,
  StyledModal,
} from "../../common/materials/GenericStyleComponent";
import DoYouWishNegotiatePopup from "./componets/DoYouWishNegotiatePopup";
import { notifyError } from "../../common/elements/AlertNotify";
import ProductDescription from "../shared/ProductDescription";

interface IPendingProductProps extends IRouterComponentProps {
  t: any;
}

let auctionId: any;

const PendingProductPage = React.forwardRef((props: IPendingProductProps, ref) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  //@ts-ignore
  // const { auctionId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  auctionId = urlParams.get("auctionId");

  const [showNegotiateModal, setShowNegotiateModal] = useState<boolean>(false);
  const [pendingProducts, setPendingProducts] = useState<any[]>([]);
  const [pendingProductsEmpty, setPendingProductsEmpty] = useState<any>();
  const [negotiableProduct, setNegotiableProduct] = useState<any[]>([]);
  const [isRefreshNegotiateBtn, setIsRefreshNegotiateBtn] = useState<boolean>(false);

  const pendingProductList = useSelector((state: RootState) =>
    selectPendingProductList(state)
  );

  useEffect(() => {
    // fetchPendingProducts(); // Fetch pending Product List for Negotiate
    fetchPendingProductsReactFunc();
    return () => {
      // localStorage.removeItem("urlPendingProductFromEmail"); // Anything in here is fired on component unmount.
      dispatch(resetPendingProductList());
    };
  }, []);

  useEffect(() => {
    if (pendingProductList !== undefined || pendingProductList !== null) {
      setPendingProducts(pendingProductList);
    }
  }, [pendingProductList]);

  useEffect(() => {
    if (productList !== undefined || productList !== null) {
      setPendingProducts(productList);
    }
  }, [productList]);

    const fetchPendingProductsReactFunc = () => {
        let sortField = "createdDate";
        let sortOrder = false;
        getPendingProductListReact({ auctionId, sortField, sortOrder });
        setTimeout(() => {
          setIsRefreshNegotiateBtn(!isRefreshNegotiateBtn);
        }, 3000);
    };

  // const fetchPendingProducts = async () => {
  //   let sortField = "createdDate";
  //   let sortOrder = false;
  //   const resultAction: any = await dispatch(
  //     getPendingProductList({ auctionId, sortField, sortOrder })
  //   );
  //   if (getPendingProductList.fulfilled.match(resultAction)) {
  //     // see pendingProductsList for data
  //   } else if (resultAction.payload) {
  //     const { isMultipleErrors, errorMessageKey } = resultAction.payload;
  //     if (!isMultipleErrors) {
  //       notifyError(t(`${errorMessageKey}`));
  //     } else {
  //       notifyError(t("common:somethingWentWrong"));
  //     }
  //   }
  // };

  const tableOptions: MUIDataTableOptions = {
    filter: "false",
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    serverSide: false,
    pagination: true,
    search: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    onColumnSortChange: (columnName, direction) => {
      if (direction === "asc") {
        sortingHandler(columnName, false);
      } else {
        sortingHandler(columnName, true);
      }
    },
    textLabels: {
      body: {
        noMatch: `${t("common:newCommanTrans:sorrynomatchingrecordsfound")}`,
      },
    },
  };

  const pendingProductTableColumns: MUIDataTableColumnDef[] = [
    {
      name: "code",
      label: `${t("common:Tender:tableColumnNames:productId")}`,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "perId",
      label: "perId",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "description",
      label: `${t("common:pendingProduct:HeaderName:description")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div style={{ cursor: "pointer" }}>
              <ProductDescription
                truncateLength={65}
                data={tableMeta.tableData[tableMeta.rowIndex]}
                isTendor={false}
              />
            </div>
          );
        },
      },
    },
    {
      name: "currentBidPrice",
      label: `${t("common:pendingProduct:HeaderName:biddingPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "caratSellingPrice",
      label: `${t("common:pendingProduct:HeaderName:sellingPrice")}`,
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <div>
              {convertProductPrice(
                tableMeta.tableData[tableMeta.rowIndex],
                value
              )}
            </div>
          );
        },
      },
    },
    {
      name: "auctionName",
      label: `${t("common:pendingProduct:HeaderName:auctionName")}`,
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "auctionId",
      label: "auction Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "carat",
      label: "carat",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caratMinimumPrice",
      label: "caratMinimumPrice",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "caratStr",
      label: "caratStr",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "clarity",
      label: "clarity",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "serialNo",
      label: "serialNo",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "color",
      label: "color",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "colorValue",
      label: "colorValue",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "currency",
      label: "currency",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "cutGrade",
      label: "cutGrade",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "exchangeRates",
      label: "exchangeRates",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "flour",
      label: "flour",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "height",
      label: "height",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "hnc",
      label: "hnc",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "length",
      label: "length",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "parcelType",
      label: "parcelType",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "polish",
      label: "polish",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "polishAbbreviation",
      label: "polishAbbreviation",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "shape",
      label: "shape",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "symmetry",
      label: "symmetry",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "symmetryAbbreviation",
      label: "symmetryAbbreviation",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "t3EX",
      label: "t3EX",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "width",
      label: "width",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productDescription",
      label: "productDescription",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "noOfPieces",
      label: "noOfPieces",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "sieve",
      label: "sieve",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "Negotiate",
      // label: "",
      label: `${t("common:pendingProduct:HeaderName:negotiate")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <StyledMuiDataTableBtnContainer>
              {tableMeta.rowData[columnIndexes.negotiated] === true ? (
                <StyledButton
                  className="disable"
                  style={{ background: "lightgrey" }}
                >
                  {t("common:pendingProduct:button:negotiate")}
                </StyledButton>
              ) : (
                <StyledButton
                  onClick={() => {
                    setShowNegotiateModal(true);
                    setNegotiableProduct(
                      tableMeta.tableData[tableMeta.rowIndex]
                    );
                  }}
                >
                  {t("common:pendingProduct:button:negotiate")}
                </StyledButton>
              )}
            </StyledMuiDataTableBtnContainer>
          );
        },
      },
    },
    {
      name: "auctionStartDate",
      label: "auctionStartDate",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionType",
      label: "auctionType",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "negotiated",
      label: "negotiated",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  // table column sorting functio get Sorted Array
  const sortingHandler = async (sortField: any, sortOrder: any) => {
    const resultAction: any = await dispatch(
      getPendingProductSortingList({ sortField, sortOrder })
    );
    if (getPendingProductSortingList.fulfilled.match(resultAction)) {
      // see pendingProductsList for data
    } else if (resultAction.payload) {
      const { isMultipleErrors, errorMessageKey } = resultAction.payload;
      if (!isMultipleErrors) {
        notifyError(t(`${errorMessageKey}`));
      } else {
        notifyError(t("common:somethingWentWrong"));
      }
    }
  };

  const renderNegotiateModal = () => {
    return (
      <StyledModal
        open={showNegotiateModal}
        onClose={() => {
          setShowNegotiateModal(false);
          fetchPendingProductsReactFunc();          
        }}
      >
        <DoYouWishNegotiatePopup
          productDetails={negotiableProduct}
          closeModal={() => {
            setShowNegotiateModal(false);
            fetchPendingProductsReactFunc();            
          }}
        />
      </StyledModal>
    );
  };

  return (
    <AppLayout {...props}>
      {pendingProducts?.length > 0 ? (
        <>
          {pendingProducts !== undefined || pendingProducts !== null ? (
            <StyledMuiDataTableLayoutGrid>
              {isRefreshNegotiateBtn ? <MUIDataTable
                title={"  "}
                data={pendingProducts}
                columns={pendingProductTableColumns}
                options={tableOptions}
              />:
              <MUIDataTable
                title={"  "}
                data={pendingProducts}
                columns={pendingProductTableColumns}
                options={tableOptions}
              />}
            </StyledMuiDataTableLayoutGrid>
          ) : (
            <StyledMuiDataTableLayoutGrid>
              <MUIDataTable
                title={"  "}
                data={pendingProductsEmpty}
                columns={pendingProductTableColumns}
                options={tableOptions}
              />
            </StyledMuiDataTableLayoutGrid>
          )}
        </>
      ) : (
        <StyledMuiDataTableLayoutGrid>
          <MUIDataTable
            title={"  "}
            data={pendingProductsEmpty}
            columns={pendingProductTableColumns}
            options={tableOptions}
          />
        </StyledMuiDataTableLayoutGrid>
      )}
      {showNegotiateModal && renderNegotiateModal()}
    </AppLayout>
  );
});

export default PendingProductPage;
