import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { createTheme } from "@material-ui/core/styles";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import { url } from "../../common/config/app.endpoints";

const StyledFooterContainer = styled(Grid)`
  background: ${(props) => props.theme.colors.base.veryLightBlue};
  text-align: center;
  margin-top: 20px;
  .padding {
    padding: 10px;
  }
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    .padding {
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 0px;
    }
  }
`;

const StyledBoxContainer = styled(Box)`
  background: ${(props) => props.theme.colors.base.textColor};
  padding-top: 0.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCompanyName = styled.p`
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.base.white};
  font-family: Calibre-Regular;
  font-size: 1.8vh;
  display: flex;
  justify-content: center;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 8px;
  }
`;

const StyledNumberContainer = styled.p`
  font-size: 2.4vh;
  font-weight: bold;
  width: 100%;
  font-family: Calibre-Bold;
  color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};

  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
  }
`;

const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  width: 100%;
`;


const StyledOfficeandEmail = styled.p`
  color: ${(props) => props.theme.colors.base.black};
  font-family: Calibre-Regular;
  font-size: 2.2vh;
  padding: 0.5vh;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.6em;
    padding: 0em;
  }
`;

const StyledCopyRight = styled.span`
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.white};
  padding: 0.2em;
  font-size: 1.8vh;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 8px;
  }
`;


interface IProps extends IRouterComponentProps {
  t: any;
  officeNumber: string;
  faxNumber: string;
  officeAddress: string;
  emailId: string;
  isFaxContinerActive: boolean;
  isEmailContinerActive: boolean;
}

const SubdomainPageFooter = (props: IProps) => {
  const {
    t,
    officeNumber,
    faxNumber,
    officeAddress,
    emailId,
    isFaxContinerActive,
    isEmailContinerActive,
  } = props;
  const theme = createTheme({});
  const isSmallDevices = useMediaQuery(theme.breakpoints.down("xs"));


  const onpress = () => {
    props.history.push("/faq");
  };
  
  const renderMyAuctionLandingPage = () => {
    let languageCode = localStorage.getItem("language") === "EN"
        ? "en"
        : localStorage.getItem("language") === "zh_CN"
        ? "zh_CN"
        : "jp";
    window.open(`${url}/${languageCode}`, "_blank");
  };

  return (
    <>
      <StyledFooterContainer>
        <Box p={2} className="padding">
          {!isSmallDevices && (
            <StyledWrapper container>
              <StyledNumberContainer>
                {officeNumber}{" "}
                {isFaxContinerActive !== false ? `| ${faxNumber}` : ""}
              </StyledNumberContainer>
            </StyledWrapper>
          )}
          {isSmallDevices && (
            <Grid container>
              <StyledNumberContainer>{officeNumber}</StyledNumberContainer>
              {isFaxContinerActive !== false ? (
                <StyledNumberContainer>{faxNumber}</StyledNumberContainer>
              ) : (
                ""
              )}
            </Grid>
          )}
          <StyledOfficeandEmail>{officeAddress}</StyledOfficeandEmail>
          {isEmailContinerActive !== false ? (
            <StyledOfficeandEmail>{emailId}</StyledOfficeandEmail>
          ) : (
            ""
          )}
        </Box>
        <StyledBoxContainer p={2} className="padding">
          <StyledCompanyName onClick={renderMyAuctionLandingPage}>
            {t("common:subdomainFooter:company")}
          </StyledCompanyName>
          <StyledCopyRight>
            &copy; {t("common:footer:copyright")}
          </StyledCopyRight>
          <StyledCopyRight onClick={onpress}>
            {t("common:kycFrom:faq:heading")}
          </StyledCopyRight>
        </StyledBoxContainer>
      </StyledFooterContainer>
    </>
  );
};

export default SubdomainPageFooter;
