import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@mui/material/Paper";
import styled from "styled-components";
import Button from "../../../common/elements/Button";
import {
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../StyledComponents";

export const StyledBoxContainer = styled(Grid)`
  margin: 0;
`;

export const StyledListContainer = styled.div`
  margin-bottom: 1em;
`;

export const StyledShadowContainer = styled(Paper)`
  cursor: pointer;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    box-shadow: 1px 3px 5px #bbb;
  }
`;
export const StyledProductImageContainer = styled(StyledImageCommonContainer)`
  position: relative;
  height: 300px;
`;
export const StyledProductImage = styled(StyledImageCommonComponent)`
  top: 0;
`;
export const StyledProductTime = styled.div`
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: medium;
  font-size: 1.2em;
  svg {
    margin-top: -0.3em;
  }
`;

export const StyledBidButton = styled(Button)`
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: 1000;
  margin: 0;
  padding: 0.8em 1.5em;
`;

export const StyledArrowButton = styled.span`
  position: absolute;
  bottom: 0%;
  right: 0%;
  z-index: 1000;
`;
export const StyledSubheading = styled.div`
  color: ${(props) => props.theme.colors.base.grey};
`;
export const StyledProductHeading = styled.div`
  font-size: 1.2em;
  min-height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledFaTag = styled(FontAwesomeIcon)`
  rotate: calc(90deg);
  margin-right: 0.2em;
  font-size: 0.6em;
  color: ${(props) => props.theme.colors.base.lightBlue};
`;

export const StyledPaddingContainer = styled.div`
  padding: 1em;
`;

export const StyledInfoContainer = styled.div`
  padding-top: 1em;
  width: 100%;
`;

export const StyledPrice = styled.div<{ fixedHeight?: boolean }>`
  font-weight: bold;
  font-size: 1.5em;
  line-height: 0.9em;
  /* height: ${(props) => (props.fixedHeight ? "60px" : "auto")}; */
  padding-right: ${(props) => (props.fixedHeight ? ".25em" : "0")};
`;

export const StyledStartingPrice = styled.div`
  font-size: 0.8em;
  margin-top: 0.5em;
`;

export const StyledRapnetContainer = styled.div`
  min-width: 60px;
  text-align: right;
`;

export const StyledBox = styled(Box)`
  width: 100%;
`;
