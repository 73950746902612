export  const intensityOptions = [
    { value: 'Faint', label: 'Faint' },
    { value: 'Fancy', label: 'Fancy' },
    { value: 'Fancy Dark', label: 'Fancy Dark' },
    { value: 'Fancy Deep', label: 'Fancy Deep' },
    { value: 'Fancy Intense', label: 'Fancy Intense' },
    { value: 'Fancy Light', label: 'Fancy Light' },
    { value: 'Fancy Vivid', label: 'Fancy Vivid' },
    { value: 'Light', label: 'Light' },
    { value: 'Very Light', label: 'Very Light' }
]

export const fancyColorOptions = [
    { value: 'Black', label: 'Black' },
    { value: 'Blue', label: 'Blue' },
    { value: 'Brown', label: 'Brown' },
    { value: 'Gray', label: 'Gray' },
    { value: 'Green', label: 'Green' },
    { value: 'OrangeO', label: 'Orange' },
    { value: 'Pink', label: 'Pink' },
    { value: 'Purple', label: 'Purple' },
    { value: 'Red', label: 'Red' },
    { value: 'Violet', label: 'Violet' },
    { value: 'White', label: 'White' },
    { value: 'Yellow', label: 'Yellow' },
    { value: 'Other', label: 'Other' }
]