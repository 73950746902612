import { Grid } from "@material-ui/core";
import styled from "styled-components";
import Colors from "../../../common/materials/colors";

export const StyledHeroContainer = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? "70vh" : "590px")};
  min-width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? "-60px" : "0")};
  margin-bottom: ${(props) => (props.isMobile ? "2em" : "0")};
`;

export const StyledCarouselContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  /* height: 100%; */
  z-index: 999;
`;

export const StyledHeroCenterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
`;

export const StyledHeroButtonsContainer = styled(Grid)<{
  isMobile: boolean;
}>`
  margin-top: 4em;
  padding: ${(props) => (props.isMobile ? "0 1em" : "0 10%")};
`;
export const StyledHeroBannerText = styled.div<{
  isMobile: boolean;
  align?: string;
}>`
  width: 100%;
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => (props.isMobile ? "0 1em" : "0 10%")};
  margin: ${(props) => (props.isMobile ? "3em 0 0 0" : "0")};
  font-size: ${(props) => (props.isMobile ? "2em" : "4em")};
`;

export const StyledHeroBannerSubText = styled.div<{
  isMobile: boolean;
  align?: string;
}>`
  width: 100%;
  text-align: ${(props) => props.align || "center"};
  font-size: ${(props) => (props.isMobile ? "1.25em" : "1.5em")};
  padding: ${(props) => (props.isMobile ? "1em 3em 0" : "0 10%")};
  color: ${(props) => props.theme.colors.base.darkGrey};
`;

export const StyledArrowDownContainer = styled.div<{ isMobile: boolean }>`
  position: ${(props) => (props.isMobile ? "relative" : "absolute")};
  bottom: ${(props) => (props.isMobile ? "0" : "1em")};
  padding-top: ${(props) => (props.isMobile ? "0" : "4em")};
`;
