import React, { useEffect, useRef, useState } from "react";
import Alert from "../../../common/elements/Alert";
import {
  StyledInfoMessage,
  StyledLink,
  StyledMessage,
  StyledSignInContainer,
  StyledTextFieldWrapper,
  StyledTimerText,
} from "../../zipang/Header/StyledComponent";
import SignInIcon from "../Static/Icons/SignIn";
import {
  makeStyles,
  Popover,
  TextField,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Button from "../../../common/elements/Button";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ChangePlaneRediractionModal from "../../../modules/login/ChangePlaneRediractionModal";
import {
  getProfileData,
  getTime,
  joinAnnualAuction,
} from "../../../common/GenericFunctions";

import {
  resetGridProductIndexes,
  resetjewellerySelectAll,
  resetGridProductAllProductIndexes,
  resetjewelleryAllProductSelectAll,
  resetTenderDiamondGridProductIndexes,
  resetTenderDiamondGridProductAllProductIndexes,
  resetTenderDiamondGridProductWatchlistIndexes,
  resetTenderGridProductWatchlistIndexes,
  resetTenderGridProductAllProductIndexes,
  resetGridProductWatchlistIndexes,
  resetDiamondGridProductIndexes,
  resetDiamondGridProductAllProductIndexes,
  resetDiamondGridProductWatchlistIndexes,
  resetTenderGridProductIndexes,
  resetIsGridView,
  resetLiveIsGridView,
  resetTenderIsGridView,
  resetTenderJwlIsGridView,
  resetJwlIsGridView,
  resetLiveJwlIsGridView,
  resetJwlAnnualAuctionData,
  resetAnnualAuctionData,
  resetNotificationLastMonthLive,
  resetNotificationLive,
  setNotificationApiCall,
  resetColorFilter,
} from "../../../redux/slice/annual/commanAnnualSlice";
import {
  reSetLiveAuctionPagination,
  reSetLiveCurrentApiStartCount,
  reSetIsMybids,
} from "../../../redux/slice/commanPagination/commanPaginationSlice";
import { useDispatch } from "react-redux";
import { History } from "history";
import { useDialog } from "../../../common/elements/Dialog/Dialog";
import Colors from "../../../common/materials/colors";
import AuthService from "../../../components/auth/AuthService";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SignUpForm, { SignUpFormDialogOptions } from "../Dialogs/SignUpForm";
import { joinedAuctions, login, forgotPassword, resendOTP, signInOtop, resetPassword } from "../Services";
import { postJoinAuction, redirectToOtherDomain } from "../Utils";

import mixpanel from "mixpanel-browser";

const useStyles = makeStyles((theme) => ({
  asterisk: {
    color: Colors.red,
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  input: {
    background: Colors.white,
  },
  margin: {
    margin: theme.spacing(1),
  },
  signInButton: {
    width: "44px",
    height: "44px",
    position: "relative",
  },
}));

const ForgetPassword = ({
  emailRef,
  otpSentForgotPassword,
  passwordRef,
  otpRef,
  newPasswordRef,
}: {
  emailRef: React.MutableRefObject<null>;
  passwordRef: React.MutableRefObject<null>;
  otpRef: React.MutableRefObject<null>;
  newPasswordRef: React.MutableRefObject<null>;
  otpSentForgotPassword: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" justifyContent="center">
      {otpSentForgotPassword ? (
        <Grid alignItems="center" justifyContent="center">
          <StyledTextFieldWrapper
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <TextField
              InputProps={{ className: classes.input }}
              classes={classes}
              label={t("common:profile:forgotPassword:labels:newPassword")}
              type="password"
              required
              inputRef={newPasswordRef}
              variant="outlined"
              id="new-password"
              autoComplete="off"
            />
          </StyledTextFieldWrapper>
          <StyledTextFieldWrapper
            fullWidth
            className={classes.margin}
            variant="outlined"
          >
            <TextField
              classes={classes}
              InputProps={{ className: classes.input }}
              label={t("common:profile:forgotPassword:labels:confirmPassword")}
              type="password"
              required
              inputRef={passwordRef}
              variant="outlined"
              id="old-password"
              autoComplete="off"
            />
          </StyledTextFieldWrapper>
          <Typography align="center" paragraph>
            {t("common:newLandingPage:otpText")}
          </Typography>
          <TextField
            InputProps={{ className: classes.input }}
            classes={classes}
            label={t("common:profile:forgotPassword:labels:otp")}
            type="text"
            required
            inputRef={otpRef}
            variant="outlined"
            id="otp"
            autoComplete="off"
          />
        </Grid>
      ) : (
        <StyledTextFieldWrapper fullWidth className={classes.margin}>
          <TextField
            type="email"
            label={t("common:login:labels:email")}
            inputRef={emailRef}
            variant="outlined"
            id="email"
          />
        </StyledTextFieldWrapper>
      )}
    </Grid>
  );
};

export const updateStore = (
  dispatch: (arg0: {
    payload: { response: boolean } | undefined;
    type: string;
  }) => void
) => {
  dispatch(resetGridProductIndexes());
  dispatch(resetjewellerySelectAll());
  dispatch(resetGridProductAllProductIndexes());
  dispatch(resetjewelleryAllProductSelectAll());
  dispatch(resetTenderDiamondGridProductIndexes());
  dispatch(resetTenderDiamondGridProductAllProductIndexes());
  dispatch(resetTenderDiamondGridProductWatchlistIndexes());
  dispatch(resetTenderGridProductWatchlistIndexes());
  dispatch(resetTenderGridProductAllProductIndexes());
  dispatch(resetGridProductWatchlistIndexes());
  dispatch(resetDiamondGridProductIndexes());
  dispatch(resetDiamondGridProductAllProductIndexes());
  dispatch(resetDiamondGridProductWatchlistIndexes());
  dispatch(resetTenderGridProductIndexes());
  dispatch(resetIsGridView());
  dispatch(resetLiveIsGridView());
  dispatch(resetTenderIsGridView());
  dispatch(resetTenderJwlIsGridView());
  dispatch(resetJwlIsGridView());
  dispatch(resetIsGridView());
  dispatch(resetLiveJwlIsGridView());
  dispatch(reSetLiveAuctionPagination());
  dispatch(reSetLiveCurrentApiStartCount());
  dispatch(reSetIsMybids());
  dispatch(resetJwlAnnualAuctionData());
  dispatch(resetAnnualAuctionData());
  dispatch(resetNotificationLastMonthLive());
  dispatch(resetNotificationLive());
  dispatch(setNotificationApiCall({ response: true }));
  dispatch(resetColorFilter());
};

export const joinedAuction = async (history: string[] | History<unknown>) => {
  getTime();
  getProfileData();
  joinAnnualAuction();
  const { status, response } = await joinedAuctions();
  postJoinAuction(status, response, history);
};

export const getEmailFromUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("un");
};

export const SignInForm = ({
  showSignInWithOTP,
  emailRef,
  passwordRef,
  otpRef,
  emailSubmitted,
}: {
  emailSubmitted: boolean;
  showSignInWithOTP: boolean;
  emailRef: React.MutableRefObject<null>;
  otpRef: React.MutableRefObject<null>;
  passwordRef: React.MutableRefObject<null>;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [email, setEmail] = useState(getEmailFromUrlParams());
  const [showPassword, setShowPassword] = useState<Boolean>(false);

  const handleChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setEmail(value);
  };


  return (
    <Grid container>
      {emailSubmitted ? null : (
        <StyledTextFieldWrapper
          fullWidth
          className={classes.margin}
          variant="outlined"
        >
          <TextField
            InputProps={{ className: classes.input }}
            type="email"
            label={t("common:login:labels:email")}
            inputRef={emailRef}
            variant="outlined"
            id="email"
            value={email}
            onChange={handleChange}
            autoComplete="off"
          />
        </StyledTextFieldWrapper>
      )}
      {showSignInWithOTP ? (
        emailSubmitted ? (
          <Grid container justifyContent="center">
            <Typography align="center" style={{ fontSize: "0.8em" }} paragraph>
              {t("common:newLandingPage:otpText")}
            </Typography>
            <TextField
              InputProps={{ className: classes.input }}
              classes={classes}
              label={t("common:profile:forgotPassword:labels:otp")}
              type="text"
              required
              inputRef={otpRef}
              variant="outlined"
              id="new-password"
              autoComplete="off"
            />
          </Grid>
        ) : null
      ) : (
        <StyledTextFieldWrapper
          fullWidth
          className={classes.margin}
          variant="outlined"
        >
          <TextField
            // InputProps={{ className: classes.input }}
            label={t("common:login:labels:password")}
            // type="password"
            type={showPassword ? "text" : "password"}
            inputRef={passwordRef}
            variant="outlined"
            id="password"
            autoComplete="off"
            InputProps={{
              className: classes.input,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      onMouseDown={() => {
                        setShowPassword(!showPassword);
                      }}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </>
              ),
            }}
          />
        </StyledTextFieldWrapper>
      )}
    </Grid>
  );
};

export const SignIn = ({
  handleClose,
  setSuccessMessage,
  showForgotPassword = false,
}: {
  showForgotPassword?: boolean;
  handleClose: any;
  setSuccessMessage?: any;
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { t } = useTranslation();
  const [showSignInWithOTP, setShowSignInWithOTP] = React.useState(false);
  const [showForgetPassword, setShowForgetPassword] =
    React.useState(showForgotPassword);
  const [emailSubmitted, setEmailSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [otpSentForgotPassword, setOtpSentForgotPassword] =
    React.useState(false);
  const [message, setMessage] = React.useState("");
  const [infoMessage, setInfoMessage] = React.useState("");
  const [resetEnabled, setResetEnabled] = React.useState(true);
  const [timer, setTimer] = React.useState(30);
  // const [subdomainName, setSubdomainName] = React.useState("");
  const emailRef = useRef<any>(null);
  const passwordRef = useRef<any>(null);
  const forgotEmailRef = useRef<any>(null);
  const otpRef = useRef<any>(null);
  const newPasswordRef = useRef<any>(null);

  const handleForgetPassword = () => {
    setMessage("");
    setShowForgetPassword(true);
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const [createDialog, closeDialog] = useDialog();

  const validateEmail = (email: string) =>
    new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(email);

  const emailValidations = (email: string, password: string) => {
    const validEmail = validateEmail(email);
    if (email === "") {
      return t("common:register:errorMessage:emptyEmail");
    } else if (!validEmail) {
      return `${t("common:register:errorMessage:newonEmail")}`;
    } else if (email === "" && password === "") {
      return `${t("common:allValidations:emptyFields")}`;
    } else if (password === "") {
      return `${t("common:login:validation:invalidEmailorPassword")}`;
    }
    return "";
  };

  const handleChangePlan = React.useCallback((subdomainName: any) => {
    const email = localStorage.getItem("changePlanEmail");
    handleClose();
    createDialog({
      children: (
        <ChangePlaneRediractionModal
          subdomainName={subdomainName}
          updateValue={emailRef?.current?.value || email}
        />
      ),
      maxWidth: "sm",
      open: true,
    });
  }, []);

  const createSingUpDialog = () => {
    createDialog({
      children: (
        <SignUpForm
          onSignUp={closeDialog}
          close={closeDialog}
          isMobile={isMobile}
        />
      ),
      ...SignUpFormDialogOptions,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setInfoMessage("");
    }, 2000);
  }, [infoMessage]);

  const processLoginResponse = (status: number, response: any) => {
    if (status === 200) {
      updateStore(dispatch);
      // call joined auction function here. add the function to the services method and add callback here.
      joinedAuction(history);
      showSignInWithOTP && setEmailSubmitted(true);
      return;
    }
    if (status > 200 && status < 500) {
      if (response[0].code !== undefined) {
        if (response[0].code === "KB409001") {
          setMessage(
            `${t("common:register:errorMessage:profileVerification")}`
          );
        } else if (response[0].code === "KB412004") {
          setMessage(
            `${t("common:login:validation:newinvalidEmailorPassword")}`
          );
        } else if (response[0].code === "KB401002") {
          setMessage(`${t("common:login:validation:registeredAsAuctioneer")}`);
        } else if (response[0].code === "KB412003") {
          setMessage(
            `${t("common:profile:forgotPassword:validation:newinvalidOTP")}`
          );
        } else if (response[0].code === "KB404001") {
          setMessage(
            `${t("common:login:validation:newinvalidEmailorPassword")}`
          );
        } else if (response[0].code === "KB401004") {
          setMessage(``);
          let email: any = localStorage.getItem("email");
          localStorage.setItem("changePlanEmail", email);
          handleChangePlan(response[0].field);
        } else if (response[0].code === "KB401005") {
          setMessage(`You don't have any invitation to access.`);
        } else {
          setMessage(`${t("common:invalidRequest")}`);
        }
      }
    } else if (status === 500) {
      setMessage(response[0].message);
    }
  };

  const handleLogin = async () => {
    let username = emailRef.current?.value;
    if (username !== null) {
      username = username.toLowerCase();
    }
    const password = passwordRef.current?.value;
    const errorMessage = emailValidations(username, password);
    setMessage(errorMessage);
    //temp zipang logo fix
    localStorage.setItem("isZipangDomain", "false");
    localStorage.setItem("isGlowstarDomain", "false");

    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(username);

    // // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
    mixpanel.track('Sign Up', {
      'Signup Type': 'Referral'
    })
    mixpanel.people.set({
      $email: username,
      $name: username,
    });

    mixpanel.track("User Login");

    if (!errorMessage) {
      const { status, response } = await login({ username, password });
      localStorage.setItem("email", username.toLowerCase());
      processLoginResponse(status, response);
    }
  };
  const handleForgotPasswordEmailSubmit = async (_email?: string) => {
    setMessage("");
    setInfoMessage("");
    let email = forgotEmailRef.current?.value || _email;
    if (email !== null) {
      email = email.toLowerCase();
    }
    const validEmail = validateEmail(email);
    if (validEmail) {
      setIsLoading(true);
      const { status, response } = await forgotPassword(email);
      setIsLoading(false);
      if (status === 200) {
        localStorage.setItem("email", email.toLowerCase());
        setInfoMessage(response.data.message);
        setOtpSentForgotPassword(true);
      } else if (status > 200 && status < 500) {
        if (response[0].code === "KB404001") {
          setIsLoading(false);
          setMessage(
            `${t(
              "common:profile:forgotPassword:validation:notRegisteredEmail"
            )}`
          );
        }
      } else {
        setIsLoading(false);
      }
    } else {
      setMessage(`${t("common:register:errorMessage:onEmail")}`);
    }
  };

  const handleSendCode = async (email: string) => {
    setIsLoading(true);
    const { status, response } = await resendOTP(email.toLowerCase());
    if (status > 200 && status < 500) {
      if (response[0].code === "KB404001") {
        setIsLoading(false);
        setMessage(
          `${t("common:profile:forgotPassword:validation:notRegisteredEmail")}`
        );
        return false;
      }
    }
    setIsLoading(false);
    return true;
  };

  const showSignInWithOTPState = () => {
    setMessage("");
    setShowSignInWithOTP(true);
  };

  const handleEmailSubmitOfOTPLogin = async () => {
    setMessage("");
    let email = emailRef.current?.value;
    if (email === "") {
      return setMessage(t("common:register:errorMessage:emptyEmail"));
    }
    if (email !== null) {
      email = email.toLowerCase();
    }
    const validEmail = validateEmail(email);
    if (validEmail) {
      localStorage.setItem("email", email);
      const success = await handleSendCode(email);
      success && setEmailSubmitted(true);
    } else {
      setMessage(t("common:register:errorMessage:onEmail"));
    }
  };

  const handleLoginWithOTP = async () => {
    const otp = otpRef.current.value;
    const email: any = localStorage.getItem("email");
    localStorage.setItem("changePlanEmail", email.toLowerCase());
    const { status, response } = await signInOtop({
      username: email.toLowerCase(),
      password: otp,
      type: "OTP",
    });
    if (status === 200) {
      Auth.setToken(response.token);
      localStorage.setItem("otpAuth", "true");
    }
    processLoginResponse(status, response);
  };

  const handleReSendCode = async () => {
    setResetEnabled(false);
    const email = emailRef.current?.value || localStorage.getItem("email");
    if (!email) {
      setMessage(`${t("common:register:errorMessage:onEmail")}`);
      return;
    }
    const success = await (showForgetPassword
      ? forgotPassword(email.toLowerCase())
      : handleSendCode(email.toLowerCase()));
    if (success) {
      setOtpSentForgotPassword(true);
    } else {
      setOtpSentForgotPassword(false);
    }
  };

  const handleNewPassword = async () => {
    let digits = /^[0-9]+$/;
    const otp = otpRef.current.value;
    let newPasswordVariable = newPasswordRef.current?.value?.trim();
    let confirmPasswordVariable = passwordRef.current?.value?.trim();
    let email = localStorage.getItem("email") || "";
    if (newPasswordVariable === "") {
      return setMessage(
        `${t("common:profile:changePassword:validation:enterNewPassword")}`
      );
    } else if (confirmPasswordVariable === "") {
      return setMessage(
        `${t("common:profile:changePassword:validation:enterOldPassword")}`
      );
    } else if (newPasswordVariable !== confirmPasswordVariable) {
      return setMessage(
        `${t("common:profile:validation:newAndConfirmPasswordNotMatch")}`
      );
    } else if (email === null || email === "") {
      return setMessage(
        `${t("common:profile:forgotPassword:validation:notRegisteredEmail")}`
      );
    } else if (!otp.match(digits)) {
      return setMessage(
        `${t("common:profile:forgotPassword:validation:correctOTP")}`
      );
    }
    setIsLoading(true);
    const { status, response } = await resetPassword(
      email.toLowerCase(),
      newPasswordVariable,
      otp
    );

    setIsLoading(false);

    if (status === 200) {
      handleClose();
      setSuccessMessage && setSuccessMessage(response.data.message);
    } else if (status > 200 && status < 500) {
      if (response[0].code !== undefined) {
        if (response[0].code === "KB412003") {
          setMessage(
            `${t("common:profile:forgotPassword:validation:invalidOTP")}`
          );
        } else {
          setMessage(response[0].message);
        }
      } else {
        setMessage(response[0].message);
      }
    } else {
      setMessage(response.data.message);
    }
  };

  const handleRegister = () => {
    handleClose();
    createSingUpDialog();
  };

  useEffect(() => {
    if (!resetEnabled) {
      setTimeout(() => {
        setResetEnabled(true);
      }, 30000);
    }
  }, [resetEnabled]);

  useEffect(() => {
    if (timer === 0) {
      setResetEnabled(true);
    }
    if (!resetEnabled) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [resetEnabled, timer]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        {showForgetPassword ? (
          <ForgetPassword
            emailRef={forgotEmailRef}
            passwordRef={passwordRef}
            newPasswordRef={newPasswordRef}
            otpRef={otpRef}
            otpSentForgotPassword={otpSentForgotPassword}
          />
        ) : (
          <SignInForm
            otpRef={otpRef}
            emailSubmitted={emailSubmitted}
            showSignInWithOTP={showSignInWithOTP}
            emailRef={emailRef}
            passwordRef={passwordRef}
          />
        )}

        <Grid container>
          <StyledMessage>{message}</StyledMessage>
          <StyledInfoMessage>{infoMessage}</StyledInfoMessage>
        </Grid>

        {showForgetPassword && (
          <Grid container justifyContent="space-between">
            {!otpSentForgotPassword && (
              <Button
                disabled={isLoading}
                type="contained-dark"
                noMargin
                onClick={handleForgotPasswordEmailSubmit}
              >
                {t("common:kycFrom:submit")}
              </Button>
            )}
            {otpSentForgotPassword && (
              <Button
                disabled={isLoading}
                type="contained-dark"
                noMargin
                onClick={handleNewPassword}
              >
                {t("common:profile:forgotPassword:button:update")}
              </Button>
            )}
            {otpSentForgotPassword && resetEnabled && (
              <StyledLink onClick={handleReSendCode}>
                {t("common:button:resendOtp")}
              </StyledLink>
            )}
            {!resetEnabled && (
              <StyledTimerText>
                {" "}
                {t("common:button:resendOtpText")} {timer} &nbsp;
                {t("common:button:resendOtpSeconds")}
              </StyledTimerText>
            )}
          </Grid>
        )}
        {!showForgetPassword && (
          <Grid container justifyContent="space-between" alignItems="center">
            {showSignInWithOTP && !emailSubmitted && (
              <Grid>
                <Button
                  disabled={isLoading}
                  type="contained-dark"
                  noMargin
                  onClick={handleEmailSubmitOfOTPLogin}
                >
                  {t("common:kycFrom:submit")}
                </Button>
              </Grid>
            )}
            {showSignInWithOTP && emailSubmitted && (
              <Grid container justifyContent="space-between">
                <Button
                  disabled={isLoading}
                  type="contained-dark"
                  noMargin
                  onClick={handleLoginWithOTP}
                >
                  {t("common:header:buttons:login")}
                </Button>

                {resetEnabled ? (
                  <StyledLink onClick={handleReSendCode}>
                    {t("common:button:resendOtp")}
                  </StyledLink>
                ) : (
                  <StyledTimerText>
                    {t("common:button:resendOtpText")} ({timer}{" "}
                    {t("common:button:seconds")})
                  </StyledTimerText>
                )}
              </Grid>
            )}
            {!showSignInWithOTP && !emailSubmitted && (
              <Button type="contained-dark" noMargin onClick={handleLogin}>
                {t("common:login:title")}
              </Button>
            )}
            {!showSignInWithOTP && emailSubmitted && (
              <Button
                type="contained-dark"
                noMargin
                onClick={handleLoginWithOTP}
              >
                {t("common:login:title")}
              </Button>
            )}
            <div>
              {!showSignInWithOTP && (
                <StyledLink onClick={showSignInWithOTPState}>
                  {t("common:button:otpSignIn")}
                </StyledLink>
              )}
              <br />
              {!showSignInWithOTP && (
                <StyledLink onClick={() => redirectToOtherDomain("auctioneer")}>
                  {t("common:button:signInAsAuction")}
                </StyledLink>
              )}
              <br />
              {!showSignInWithOTP && (
                <StyledLink onClick={handleForgetPassword}>
                  {t("common:login:forgotPassword")}
                </StyledLink>
              )}
              <br />
              {!showSignInWithOTP && (
                <StyledLink onClick={handleRegister}>
                  {t("common:login:registerNow")}
                </StyledLink>
              )}
            </div>
          </Grid>
        )}
      </Grid>
    </>
  );
};
const Auth = new AuthService();
const HeaderSignInMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [message, setMessage] = useState("");
  const menuSpace = useRef(null);
  const classes = useStyles();
  const handleClick = () => {
    setAnchorEl(menuSpace.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const userEmail = getEmailFromUrlParams();
    if (userEmail) {
      handleClick();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setMessage("");
    }, 3000);
  }, [message]);

  const isLoggedInUser = Auth.loggedIn();
  return (
    <div>
      <Button
        type="icon"
        noMargin
        className={classes.signInButton}
        onClick={handleClick}
      >
        {message && <Alert showAlertMessage={message} alertType={""} />}
        <SignInIcon id="sign-in" />
      </Button>
      <div>
        <span ref={menuSpace}></span>
        {isLoggedInUser ? null : (
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <StyledSignInContainer elevation={3}>
              <SignIn
                handleClose={handleClose}
                setSuccessMessage={setMessage}
              />
            </StyledSignInContainer>
          </Popover>
        )}
      </div>
    </div>
  );
};

export default React.memo(HeaderSignInMenu);
