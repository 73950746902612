import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

import IRouterComponentProps from "../models/IRouterComponentProps";
import AppLayout from "../components/layouts/AppLayout";
import InvoiceList from "../components/products/invoice/InvoiceList";
import CoreDataofInvoice from "../components/core-grid/grid-data/CoreDataofInvoice";
import {
  downloadandChangeShippingAddressInvoiceUrl,
  downloadandInternationalInvoiceUrl,
  invoiceUrl,
  profileUrl,
} from "../common/config/app.endpoints";
import AuthService from "../components/auth/AuthService";
import Alert from "../common/elements/Alert";
import LoadingSpinner from "../common/elements/LoadingSpinner";
import ApiTimeout from "./ApiTimeout";
import { oAuth } from "../routes/basic/BasicAppRouter";
import { StyledModal } from "../common/materials/GenericStyleComponent";
import BlockUserModal from "../components/products/BlockUser/BlockUserModal";
import moment from "moment";
import TrakingProcess from "../components/products/invoice/TrakingProcess";
import InvoiceListHome from "../components/products/invoice/InvoiceListHome";
import { useHistory } from "react-router";

const StyledLayoutGrid = styled(Grid)`
  padding: 30px 80px;
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    padding: 27px 10px;
  }
  ${(props) => props.theme.breakpoints.between("md", "lg")} {
    padding: 10px 30px;
  }
`;

const StyledTextWrapper = styled(Grid)`
  width: 100%;
  text-align: center;
  font-size: 1.5em;
  padding: 1em;
  font-family: Calibre-Regular;
  color: ${(props) => props.theme.colors.base.grey};
`;

const SyledHeader = styled(Grid)`
  font-size: 0.8em;
  padding: 0.8em;
  color: ${(props) => props.theme.colors.base.red};

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    font-size: 0.5em;
    padding: 0.5em;
  }
`;

interface IProps extends IRouterComponentProps {
  t?: any;
}
let data: any = [];
let auctionIdForTrackingRecord: any;
const Invoice = (props: IProps) => {
  const [invoice, setInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [shippingAddress, setShippingAddress] = useState([]);
  const [apiTimeout, setApiTimeout] = useState(false);
  const [totalElements, setTotalElements] = useState("");
  const [isUpdatedData, setIsUpdatedData] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isShowJewelley, setShowJewelley] = useState(false);
  const [isShowTracking, setIsShowTracking] = useState(true);
  const [isHomeCategoryActive, setIsHomeCategoryActive] = useState(true);

  const Auth = new AuthService();
  const { t } = props;
  let history = useHistory();

  useEffect(() => {
    localStorage.removeItem("auctionUserId");
    localStorage.removeItem("userId");
    localStorage.removeItem("currentProductIdOfBidNow");
    localStorage.removeItem("currentJewelleryProductIdOfBidNow");
    localStorage.removeItem("currentPDItem");
    localStorage.removeItem("annualdropDownFilterVal");
    setIsShowTracking(false);
    return () => {
      localStorage.removeItem("itemsPerPage");
      setIsLoading(false);
      setInvoice([]);
      setIsShowTracking(true);
    };
  }, []);

  useEffect(() => {
    if (
      history.location.pathname === "/invoice" ||
      history.location.pathname === "/invoice/home"
    ) {
      handleComponents("HOME");
    } else if (history.location.pathname === "/invoice/diamond") {
      handleComponents("DIAMODS");
    } else if (
      history.location.pathname === "/invoice/365DaysAuctionsinvoice"
    ) {
      history.push("/invoice/365DaysAuctionsinvoice");
    } else {
      handleComponents("JEWELLERY");
    }
  }, [history.location.pathname]);

  const getUpdatedInvoiceList = (value: any, start: any, endRecords: any) => {
    if (value === true) {
      getList(start, endRecords);
    }
  };

  const getList = (start: string | number, end: number) => {
    if (localStorage.getItem("itemsPerPage") !== null) {
      let pageCount: any = localStorage.getItem("itemsPerPage");
      end = parseInt(pageCount);
      localStorage.removeItem("itemsPerPage");
    }
    if (startDate !== null && endDate !== null) {
      data = {
        filters: {
          and: [
            {
              match: "between",
              name: "lastModifiedDate",
              gte: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
              lte: moment(endDate).add(1, "d").format("YYYY-MM-DDTHH:mm:ss"),
            },
            {
              match: "exact",
              name: "category",
              value: isShowJewelley === true ? "Jewellery" : "Diamonds",
            },
          ],
        },
        page: {
          start: start,
          count: end,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };
    } else {
      if (isHomeCategoryActive) {
        data = {
          page: {
            start: start,
            count: end,
            sort: {
              orders: [
                {
                  property: "lastModifiedDate",
                  direction: "DESC",
                },
              ],
            },
          },
        };
      } else {
        data = {
          filters: {
            and: [
              {
                match: "exact",
                name: "category",
                value: isShowJewelley === true ? "Jewellery" : "Diamonds",
              },
            ],
          },
          page: {
            start: start,
            count: end,
            sort: {
              orders: [
                {
                  property: "lastModifiedDate",
                  direction: "DESC",
                },
              ],
            },
          },
        };
      }
    }

    if (!oAuth()) {
      props.history.push("/");
    } else {
      getInvoiceList(data);
      setIsShowTracking(false);
    }
  };

  const onSelectDate = (startDate: any, endDate: any) => {
    if (startDate !== null && endDate !== null) {
      setStartDate(startDate);
      setEndDate(endDate);
      data = {
        filters: {
          and: [
            {
              match: "between",
              name: "lastModifiedDate",
              gte: moment(startDate).format("YYYY-MM-DDTHH:mm:ss"),
              lte: moment(endDate).add(1, "d").format("YYYY-MM-DDTHH:mm:ss"),
            },
          ],
        },
        page: {
          start: 0,
          count: 50,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };
    } else if (startDate === null && endDate === null) {
      data = {
        page: {
          start: 0,
          count: 50,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };
    }
    if (!oAuth()) {
      props.history.push("/");
    } else {
      if (startDate > endDate) {
        setAlertType("error");
        setOpenToast(true);
        setShowMessage("End date should be greater than start date");

        setTimeout(() => {
          setOpenToast(false);
        }, 2000);
      } else {
        getInvoiceList(data);
        setIsShowTracking(false);
      }
    }
  };

  const getProfileData = () => {
    Auth.getRequest(profileUrl, (status: number, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          if (response.shippingAddresses !== undefined) {
            if (response.shippingAddresses !== null) {
              response.shippingAddresses.forEach((item: any, i: number) => {
                if (item.primary === true) {
                  response.shippingAddresses.splice(i, 1);
                  response.shippingAddresses.unshift(item);
                }
              });
              setShippingAddress(response.shippingAddresses);
            }
          }
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 505) {
        setApiTimeout(true);
      } else {
        setAlertType("error");
        setOpenToast(false);
        setShowMessage(response);
        setIsLoading(false);
      }
    });
  };

  const getInvoiceList = (data: any) => {
    setIsUpdatedData(true);
    const { t } = props;
    Auth.postRequest(invoiceUrl, data, (status: any, response: any) => {
      if (status === 200) {
        if (response !== undefined) {
          setTotalElements(response.totalElements);
          setInvoice(response.results);
          setIsLoading(false);
          setIsUpdatedData(false);
        } else {
          setAlertType("error");
          setShowMessage(response);
          setOpenToast(true);
          setIsLoading(false);
          setIsUpdatedData(false);
        }
      } else if (status === 403) {
        setUserBlock(true);
      } else if (status === 404) {
        setAlertType("error");
        setShowMessage(`${t("common:invalidRequest")}`);
        setOpenToast(true);
        setIsLoading(false);
        setIsUpdatedData(false);
      } else if (status === 505) {
        setApiTimeout(true);
        setIsUpdatedData(false);
      } else {
        setAlertType("error");
        setShowMessage(response[0].message);
        setIsLoading(false);
        setOpenToast(false);
        setIsUpdatedData(false);
      }
    });
  };

  const sendMessage = (message: string) => {
    setShowMessage(message);
    setOpenToast(false);
    setAlertType("success");
    setOpenToast(true);
    setIsLoading(false);
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      setApiTimeout(true);
    }
  };

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const forDownloadInvoice = (
    id: string,
    auctionName: string,
    onClickDownloadButton: boolean
  ) => {
    setIsUpdatedData(true);
    const Auth = new AuthService();
    if (onClickDownloadButton === true) {
      Auth.getRequestForDownload(
        `${downloadandChangeShippingAddressInvoiceUrl}/${id}/download_invoice`,
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${auctionName}.pdf`);
              document.body.appendChild(link);
              link.click();
            }
            setIsUpdatedData(false);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsUpdatedData(false);
          } else {
            setAlertType("error");
            setShowMessage(response);
            setOpenToast(false);
            setIsUpdatedData(false);
          }
        }
      );
    }
  };

  const internationalInvoice = (
    auctionId: string,
    auctionName: string,
    onClickDownloadButton: boolean
  ) => {
    setIsUpdatedData(true);
    const Auth = new AuthService();
    if (onClickDownloadButton === true) {
      Auth.getRequestForDownload(
        `${downloadandInternationalInvoiceUrl}?auctionId=${auctionId}`,
        (status: any, response: any) => {
          if (status === 200) {
            if (response !== undefined) {
              const url = window.URL.createObjectURL(new Blob([response]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", `${auctionName}.pdf`);
              document.body.appendChild(link);
              link.click();
            }
            setIsUpdatedData(false);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsUpdatedData(false);
          } else {
            setAlertType("error");
            setShowMessage(response);
            setOpenToast(false);
            setIsUpdatedData(false);
          }
        }
      );
    }
  };

  const handleComponents = (categoryType: String) => {
    setStartDate(null);
    setEndDate(null);
    if (categoryType === "DIAMODS") {
      setShowJewelley(false);
      setIsHomeCategoryActive(false);
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Diamonds",
            },
          ],
        },

        page: {
          start: 0,
          count: 50,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };

      //call api
      getInvoiceList(data);
      setIsShowTracking(false);
    } else if (categoryType === "HOME") {
      setShowJewelley(false);
      setIsHomeCategoryActive(true);
      data = {
        page: {
          start: 0,
          count: 50,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };

      //call api
      getInvoiceList(data);
      setIsShowTracking(false);
    } else {
      setShowJewelley(true);
      setIsHomeCategoryActive(false);
      data = {
        filters: {
          and: [
            {
              match: "exact",
              name: "category",
              value: "Jewellery",
            },
          ],
        },
        page: {
          start: 0,
          count: 50,
          sort: {
            orders: [
              {
                property: "lastModifiedDate",
                direction: "DESC",
              },
            ],
          },
        },
      };

      //call api
      getInvoiceList(data);
      setIsShowTracking(false);
    }
  };

  const getTrackingUIForInvoice = (auctionId: string) => {
    auctionIdForTrackingRecord = auctionId;
    setIsShowTracking(true);
  };
  const get365DayAuctionInvoice = () => {
    history.push("/365DaysAuctionsinvoice");
  };

  const itemPerPage = (value: boolean, start: number, itemPerPage: number) => {
    if (value === true) {
      localStorage.setItem("itemsPerPage", JSON.stringify(itemPerPage));
      getUpdatedInvoiceList(value, start, itemPerPage);
    }
  };

  const trackingButtonHandler = () => {
    setIsShowTracking(false);
  };

  return (
    <AppLayout t={t} {...props}>
      {apiTimeout === true ? (
        <ApiTimeout t={t} {...props} />
      ) : (
        <Grid>
          {isLoading === true ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <Grid container={true} spacing={0}>
              <StyledLayoutGrid xs={12} item={true}>
                <SyledHeader xs={12} item>
                  {t("common:invoice:header")}
                </SyledHeader>
                {invoice.length > 0 ? (
                  <>
                    {isShowTracking === false ? (
                      <CoreDataofInvoice
                        t={t}
                        data={invoice}
                        itemPerPage={itemPerPage}
                        selectedDates={onSelectDate}
                        totalElements={totalElements}
                        value={getUpdatedInvoiceList}
                        isShowPage={isShowTracking}
                        get365DayAuctionInvoice={get365DayAuctionInvoice}
                      >
                        {isHomeCategoryActive ? (
                          <InvoiceListHome
                            t={t}
                            shippingAddress={shippingAddress}
                            sendMessage={sendMessage}
                            apiTimeout={timeout}
                            downloadpdf={forDownloadInvoice}
                            downloadinternationalInvoice={internationalInvoice}
                            trakingUIInvoice={getTrackingUIForInvoice}
                          />
                        ) : (
                          <InvoiceList
                            t={t}
                            shippingAddress={shippingAddress}
                            sendMessage={sendMessage}
                            apiTimeout={timeout}
                            downloadpdf={forDownloadInvoice}
                            downloadinternationalInvoice={internationalInvoice}
                            trakingUIInvoice={getTrackingUIForInvoice}
                          />
                        )}
                      </CoreDataofInvoice>
                    ) : (
                      <TrakingProcess
                        auctionIds={auctionIdForTrackingRecord}
                        isAnnualtype={false}
                        hideTrakingUi={trackingButtonHandler}
                      />
                    )}
                  </>
                ) : (
                  <StyledTextWrapper item={true} xs={12} lg={12} md={12}>
                    {t("common:invoice:noInvoice")}
                  </StyledTextWrapper>
                )}
              </StyledLayoutGrid>
              {isUpdatedData === true && (
                <div className="loader">
                  <LoadingSpinner />
                </div>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {openToast === true && (
        <Alert showAlertMessage={showMessage} alertType={alertType} />
      )}
      {userBlock === true && (
        <StyledModal open={userBlock}>
          <BlockUserModal t={t} {...props} closeModal={closeModal} />
        </StyledModal>
      )}
    </AppLayout>
  );
};

export default Invoice;
