import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";

import IRouterComponentProps from '../models/IRouterComponentProps';
import { currentTimeUrl, joinedAuctionsUrl, rateFromUSD2JPY } from '../common/config/app.endpoints';
import AuthService from '../components/auth/AuthService';
import CoreDataGridOfAuction from '../components/core-grid/grid-data/CoreDataGridOfAuction';
import Alert from '../common/elements/Alert';
import { StyledModal } from '../common/materials/GenericStyleComponent';
import { oAuth } from '../routes/basic/BasicAppRouter';
import { getTimeDifference } from '../common/GenericFunctions';
import BlockUserModal from '../components/products/BlockUser/BlockUserModal';
import PubSub from "pubsub-js";
import JewelleryAuctionList from '../components/products/auction/JewelleryAuctionList';
import { useDispatch } from 'react-redux';
import { resetItemPerPage, resetCurrentApiStartCount, resetJewelleryItemPerPage, resetJewelleryCurrentApiStartCount } from '../redux/slice/commanPagination/commanPaginationSlice';
import { AppDispatch } from '../redux/store';

const StyledTextWrapper = styled(Grid)`
    width: 100%;
    text-align: center;
    font-size: 1.5em;
    padding: 1em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey};
`;

interface IProps extends IRouterComponentProps {
    t: any;
}

export interface IState {
    auctions: [];
    openToast: boolean;
    showMessage: '';
    isLoading: boolean;
    counter: any;
    alertType: string;
    openTimerModal: boolean;
    apiTimeout: boolean;
}

let auctionListRefresh: any = null;

const JewelleryAuction = React.forwardRef((props: IProps, ref) => {
    const [auctions, setAuctions] = useState([]);
    const [openToast, setOpenToast] = useState(false);
    const [showMessage, setShowMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [openTimerModal, setOpenTimerModal] = useState(false);
    const [apiTimeout, setApiTimeout] = useState(false);
    const [userBlock, setUserBlock] = useState(false);
    const { t } = props;
    const Auth = new AuthService();
    const dispatch: AppDispatch = useDispatch();
    
    useEffect(() => {
        dispatch(resetItemPerPage());
        dispatch(resetCurrentApiStartCount());
        dispatch(resetJewelleryItemPerPage());
        dispatch(resetJewelleryCurrentApiStartCount());
        PubSub.unsubscribe("StopStartTimer");

        PubSub.subscribe("StopStartTimer", (msg: any, data: any) => {

            if (data === "Stop") {
                if (auctionListRefresh) {
                    clearInterval(auctionListRefresh);
                }
            } else {
                update();
            }

        })

        setIsLoading(true);
        if (!oAuth()) {
            props.history.push('/');
        } else {
            localStorage.removeItem("auctionType");
            localStorage.removeItem("timeDifference");
            localStorage.removeItem('filteredParcelValues');
            localStorage.removeItem("filteredParcelValues");
            getAuction();
            // getExchangeRate();
            getTimeDifference();
        }
        return () => setIsLoading(false);
    }, []);

    const getAuction = () => {
        Auth.getRequest(`${joinedAuctionsUrl}?category=${"Jewellery"}`, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    if (!oAuth()) {
                        props.history.push('/');
                    } else {
                        getTime(response.results)
                        if (auctionListRefresh) {
                            clearInterval(auctionListRefresh);
                        }
                        update();
                    }
                    setAuctions(response.results);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }
            else if (status === 403) {
                setUserBlock(true);
            }
            else if (status === 505) {
                setApiTimeout(true);
                setIsLoading(false);
            }
            else {
                setAlertType('error');
                setOpenToast(false);
                // setShowMessage(response.data.message);
                setIsLoading(false);
            }
        })
    }

    const getExchangeRate = () => {
        Auth.getRequest(rateFromUSD2JPY, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    localStorage.setItem("reverseRate", response.reverseRate);
                    localStorage.setItem("rate", response.rate);
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                }
            }
            else if (status === 403) {
                setUserBlock(true);
            }
            else if (status === 505) {
                setApiTimeout(true);
                setIsLoading(false);
            }
            else {
                setAlertType('error');
                setOpenToast(false);
                // setShowMessage(response.data.message);
                setIsLoading(false);
            }
        })
    }

    const getTime = (auctions: any) => {
        if (auctions !== undefined) {
            if (auctions.length > 0) {
                Auth.getRequest(currentTimeUrl, (status: number, response: any) => {
                    if (status === 200) {
                        if (response !== undefined) {
                            var timePassedInMilliseconds = (Date.now() - response) / 1000;
                            if (timePassedInMilliseconds < -3 || timePassedInMilliseconds > 3) {
                                setOpenTimerModal(true);
                                setIsLoading(false);
                            }
                        }
                    }
                    else if (status === 403) {
                        setUserBlock(true);
                    }
                    else if (status === 505) {
                        setApiTimeout(true);
                        setIsLoading(false);
                    }
                    else {
                        setAlertType('error');
                        setOpenToast(false);
                        // setShowMessage(response.data.message);
                        setIsLoading(false);
                    }
                })
            }
        }
    }

    const update = () => {
        auctionListRefresh = setInterval(() => {
            if (!oAuth()) {
                props.history.push('/');
            } else {
                getAuction();
            }
        }, 420000);
    }

    const value = (value: any) => {
        if (value === true) {
            getAuction();
        }
    }

    const closeModal = () => {
        setOpenTimerModal(false);
    }

    const blockUserModal = () => {
        Auth.logout();
        props.history.replace('/');
    }


    return (
        <Grid xs={12}>
             <div>
             {auctions.length > 0 ?
                <CoreDataGridOfAuction t={t} data={auctions}>
                    <JewelleryAuctionList {...props} value={value} />
                </CoreDataGridOfAuction>
                :
                <StyledTextWrapper item={true} xs={12} lg={12} md={12}>{t('common:preview:noAuctionFound')}</StyledTextWrapper>
            }
            </div>
            {
                openToast === true &&
                <Alert showAlertMessage={showMessage} alertType={alertType} />
            }
            {userBlock === true &&
                <StyledModal open={userBlock}>
                    <BlockUserModal {...props} closeModal={blockUserModal} />
                </StyledModal>
            }
        </Grid>

    )
})

export default JewelleryAuction;