import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import styled, { css } from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createTheme } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";

import LoginForm from "../../modules/login/LoginForm";
import RegisterForm from "../../modules/login/RegisterForm";
import { sideMenuGradient } from "../../common/materials/LinearGradient";
import IRouterComponentProps from "../../models/IRouterComponentProps";
import LanguageChooser from "../shared/languageChooser";
import ForgotPassword from "../../modules/login/ForgotPassword";
import ViewRegisterModal from "../../modules/login/ViewRegisterModal";
import ConfirmNewPassword from "../../modules/login/ConfirmNewPassword";
import LoginWithEmail from "../../modules/login/passwordReset/LoginWithEmail";
import PasswordResetViaOtp from "../../modules/login/passwordReset/PasswordResetViaOtp";
import LoginWithOtp from "../../modules/login/passwordReset/LoginWithOtp";
import ChangePlaneRediractionModal from "../../modules/login/ChangePlaneRediractionModal";
import { useDispatch } from "react-redux";
import { resetIsChangePlanePopup, resetSubDomainName } from "../../redux/slice/commonReact/commonReactSlice";
import { AppDispatch } from "../../redux/store";

interface ButtonProps {
  selected?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  background: ${(props) => props.theme.colors.base.transparent};
  padding: 5px 5px;
  color: ${(props) => props.theme.colors.base.white};
  font-size: 1em;
  border-radius: 3px;
  font-family: SF-Display-Medium;
  outline: none;
  display: flex;
  border: 1px solid ${(props) => props.theme.colors.base.white};
  margin-right: 4px;
  &:hover {
    background: ${(props) => props.theme.colors.base.white};
    color: ${(props) => props.theme.colors.base.hoverTextFieldColor};
  }
  ${(props) =>
    props.selected &&
    css`
      background: ${(props) => props.theme.colors.base.white};
      color: ${(props) => props.theme.colors.base.black};
    `}
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 0.9em;
  }
  @media (width: 320px) {
    font-size: 0.7em;
  }
  justify-content: space-between;
  align-items: center;
`;

const StyledHeaderContainer = styled.div`
  padding: 0.5em;
  background: ${(props) => props.theme.colors.base.darkBlue};
  background: ${sideMenuGradient};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;



const StyledButtonWrapper = styled.div`
  float: right;
  padding: 8px;
  display: flex;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    // width: 100%;
    width: auto;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    // width: 100%;
    padding: 3.5px;
    width: auto;
  }
`;


const StyledWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;


export interface IProps extends IRouterComponentProps {
  children?: any;
  showSuccessfulToast: () => void;
  showTimeoutToast: () => void;
}
let subdomainName: any = ''
const Header = (props: IProps) => {
  const { t } = props;

  const [onPressSignUp, setOnPressSignUp] = useState(false);
  const [onPressLogin, setOnPressLogin] = useState(false);
  const [onPressForgotPassword, setOnPressForgotPassword] = useState(false);
  const [onsuccessfulRegister, setOnsuccessfulRegister] = useState(false);
  const [onConfirmPassword, setOnConfirmPassword] = useState(false);
  const [showResetPasswordPopup, setShowResetPasswordPopup] = useState(false);
  // const [onPromotional, setOnPromotional] = useState(true);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [isChangePlan, setIsChangePlan] = useState(false);
  const [showLoginWithOTPPopup, setShowLoginWithOTPPopup] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const unSource: any = useRef<any>();
  let userEmailvalue = useRef<any>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName = urlParams.get("un");
    const userChangePlan = urlParams.get("changePlan");
    unSource.current = userName;
    if (userName !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
    }

    if (userChangePlan !== null) {
      setOnPressLogin(true);
    } else {
      setOnPressLogin(false);
    }
    localStorage.removeItem("changePlanEmail");
    return () => {
      dispatch(resetSubDomainName());
      dispatch(resetIsChangePlanePopup());
    };
  }, []);

  const loginHandler = () => {
    setOnPressLogin(true);
    setOnPressSignUp(false);
    setShowLoginWithOTPPopup(false);
  };

  const onClose = () => {
    setOnPressLogin(false);
    setOnPressSignUp(false);
    setOnPressForgotPassword(false);
    setOnsuccessfulRegister(false);
    setOnConfirmPassword(false);
    setShowLoginWithOTPPopup(false);
    setIsChangePlan(false);
  };

  const onCloseForgotPassword = () => {
    setOnPressForgotPassword(false);
    setOnConfirmPassword(true);
  };

  const signUpHandler = () => {
    setOnPressLogin(false);
    setOnPressSignUp(true);
    setShowLoginWithOTPPopup(false);
  };

  const forgotPasswordModalHandler = () => {
    setOnPressLogin(false);
    setOnPressForgotPassword(true);
    setShowLoginWithOTPPopup(false);
  };

  const loginWithOtpModalHandler = () => {
    setOnPressLogin(false);
    setShowLoginWithOTPPopup(true);
  };

  const regiterModalHandler = () => {
    setOnPressSignUp(false);
    setOnsuccessfulRegister(true);
    setShowLoginWithOTPPopup(false);
  };

  const showSuccessfulToast = () => {
    props.showSuccessfulToast();
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      props.showTimeoutToast();
      setOnPressSignUp(false);
    }
  };
  const theme = createTheme({});

  const renderResetPasswordPopup = () => {
    return (
      <StyledModal
        open={showResetPasswordPopup}
        onClose={() => {
          setShowResetPasswordPopup(false);
        }}
      >
        <LoginWithEmail
          closeModal={() => {
            setShowResetPasswordPopup(false);
          }}
          isFromSubDomain={false}
          renderOTPPopup={() => {
            setShowOTPPopup(true);
            setShowResetPasswordPopup(false);
          }}
          subdomainfunc={rhandlechangePlanModal}
        />
      </StyledModal>
    );
  };

  const renderOTPPopup = () => {
    return (
      <StyledModal open={showOTPPopup}>
        <PasswordResetViaOtp
          closeOtpModal={() => {
            setShowOTPPopup(false);
          }}
          openPasswordPop={() => {
            setShowResetPasswordPopup(true);
          }}
        />
      </StyledModal>
    );
  };

  const loginButtonHandler = () => {
    if (unSource.current !== null) {
      setShowResetPasswordPopup(true);
    } else {
      setShowResetPasswordPopup(false);
      loginHandler();
    }
  };

  const updateValue = (value: any) => {
    userEmailvalue.current = value;
  };

  //changePlane Value
  const rhandlechangePlanModal = (val: string) => {
    subdomainName = val;
    setIsChangePlan(true);
  };

  return (
    <StyledHeaderContainer className="headerContainer">
      <StyledWrapper container={true} spacing={0}>
        {/* <StyledResetPaddingContainer item xs={3} sm={4} lg={4} xl={4}>
          <StyledLogoWrapper>
            {notSmallDevices && <StyledLogoImage src="/images/logo.png" />}
            {notSmallDevices && (
              <StyledTextContainer>
                {t("common:header:logoText")}
              </StyledTextContainer>
            )}
          </StyledLogoWrapper>
        </StyledResetPaddingContainer> */}
        <Grid item xs={9} sm={8} lg={6} xl={6} className="buttonsContainer">
          <StyledButtonWrapper>
            <StyledButton selected={true} onClick={loginButtonHandler}>
              {t("common:header:buttons:login")}
            </StyledButton>
            <StyledButton onClick={signUpHandler}>
              {t("common:header:buttons:register")}
            </StyledButton>
            <LanguageChooser {...props} />
          </StyledButtonWrapper>
        </Grid>
      </StyledWrapper>
      {onPressLogin === true && (
        <StyledModal
          open={onPressLogin}
          onClose={onClose}
          className="loginModal"
        >
          <LoginForm
            forgotPasswordModal={forgotPasswordModalHandler}
            {...props}
            registerModal={signUpHandler}
            isFromSubDomain={false}
            renderLoginWithOTPPopup={loginWithOtpModalHandler}
            userEmailvalue={updateValue}
            updateValue={
              userEmailvalue.current
            }
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}

      {showLoginWithOTPPopup === true && (
        <StyledModal open={showLoginWithOTPPopup}>
          <LoginWithOtp
            {...props}
            isFromSubDomain={false}
            forgotPasswordModal={forgotPasswordModalHandler}
            registerModal={signUpHandler}
            oncloseModal={() => {
              onClose();
            }}
            loginButtonHandler={loginButtonHandler}
            updateValue={userEmailvalue.current}
            subdomainfunc={rhandlechangePlanModal}
          />
        </StyledModal>
      )}

      {onPressForgotPassword === true && (
        <StyledModal
          open={onPressForgotPassword}
          onClose={onClose}
          className="forgotPasswordModal"
        >
          <ForgotPassword t={t} closeModal={onCloseForgotPassword} />
        </StyledModal>
      )}
      {onConfirmPassword === true && (
        <StyledModal open={onConfirmPassword}>
          <ConfirmNewPassword
            t={t}
            closeModal={() => onClose()}
            showSuccessfulToast={showSuccessfulToast}
          />
        </StyledModal>
      )}
      {onPressSignUp === true && (
        <StyledModal open={onPressSignUp} className="registerModal">
          <RegisterForm
            t={t}
            closeModal={() => onClose()}
            onSuccessfulRegisterModal={regiterModalHandler}
            loginModal={loginHandler}
            apiTimeout={timeout}
          />
        </StyledModal>
      )}
      {onsuccessfulRegister === true && (
        <StyledModal
          open={onsuccessfulRegister}
          onClose={() => onClose()}
          className="registerModal"
        >
          <ViewRegisterModal openRegisterModal={() => onClose()} t={t} />
        </StyledModal>
      )}
      {showResetPasswordPopup && renderResetPasswordPopup()}
      {showOTPPopup && renderOTPPopup()}
      {isChangePlan && (
        <StyledModal
          open={isChangePlan}
          onClose={() => {
            setIsChangePlan(false);
          }}
        >
          <ChangePlaneRediractionModal
            subdomainName={subdomainName}
            updateValue={userEmailvalue.current}
          />
        </StyledModal>
      )}
    </StyledHeaderContainer>
  );
};

export default Header;
