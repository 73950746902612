import React from "react";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import MuiTablePagination from "@mui/material/TablePagination";
import styled from "styled-components";

const StyledContainer = styled.div`
  .MuiTablePagination-displayedRows {
    margin: 0px !important;
  }
`;

const CustomFooter = (props) => {
  const handleRowChange = (event) => {
    props.changeRowsPerPage(event.target.value);
  };

  const handlePageChange = (_, page) => {
    props.changePage(page);
  };

  const { count, textLabels, rowsPerPage, page } = props;
  return (
    <TableFooter>
      <TableRow>
        <TableCell colSpan={1000}>
          <StyledContainer>
            <MuiTablePagination
              onPageChange={handlePageChange}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={textLabels.rowsPerPage}
              labelDisplayedRows={
                ({ from, to, count }) =>
                  `${from}-${to} ${textLabels.displayRows} ${count}`
              }
              backIconButtonProps={{
                "aria-label": textLabels.previous,
              }}
              nextIconButtonProps={{
                "aria-label": textLabels.next,
              }}
              rowsPerPageOptions={[]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowChange}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            />
          </StyledContainer>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
export default CustomFooter;
