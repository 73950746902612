import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "../../../static/css/eventsCalendar.css";

import ja from 'date-fns/locale/ja';
import en_US from 'date-fns/locale/en-US';
import zh_CN from 'date-fns/locale/zh-CN';
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import { selectedDateValue } from "../../../redux/slice/commanPagination/commanPaginationSlice";

setDefaultLocale('en-US');
registerLocale('ja', ja);
registerLocale('en-US', en_US);
registerLocale('zh-CN', zh_CN);

const StyledWrapper = styled(Grid)`
    display: flex;
    .secondary{
        padding: 5px;
        ${props => props.theme.breakpoints.between('xs', 'sm')} {
            width: 150px;
        }
    }
`;

const StyledGrid = styled(Grid)`
    margin: 5px;
`;

interface IProps {
  dates: any;
  t: any;
}
let localization: string = "";

const SelectDateCalendar = (props: IProps) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { t } = props;
  let history = useHistory();
  useEffect(() => {
    let language = localStorage.getItem("language");
    if (language === "EN") {
      localization = "en-US";
    } else {
      localization = "ja";
    }
  }, [])

  const selectedDate: any = useSelector((state: RootState) =>
    selectedDateValue(state)
  );


  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
  }, [selectedDate])

  useEffect(() => {
    if (
      history.location.pathname === "/invoice" ||
      history.location.pathname === "/invoice/home"
    ) {
      setStartDate(null);
      setEndDate(null)
    } else if (history.location.pathname === "/invoice/365DaysAuctionsinvoice") {
      setStartDate(null);
      setEndDate(null)
    } else if (history.location.pathname === "/invoice/diamond") {
      setStartDate(null);
      setEndDate(null)
    } else {
      setStartDate(null);
      setEndDate(null)
    }
  }, [history.location.pathname]);

  const onChangeStartDate = (date: any) => {
    setStartDate(date);
    props.dates(date, endDate)
  }

  const onChange = (date: any) => {
    setEndDate(date)
    props.dates(startDate, date)
  }

  return (
    <StyledWrapper>
      <StyledGrid>
        <DatePicker
          selected={startDate}
          onChange={onChangeStartDate}
          locale={localization}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={new Date()}
          showDisabledMonthNavigation
          placeholderText={t("common:invoice:filter:startDate")}
          className="secondary react-datepicker"
        />
      </StyledGrid>
      <StyledGrid>
        <DatePicker
          selected={endDate}
          onChange={onChange}
          locale={localization}
          selectsEnd
          startDate={startDate}
          maxDate={new Date()}
          endDate={endDate}
          showDisabledMonthNavigation
          placeholderText={t("common:invoice:filter:endDate")}
          className="secondary react-datepicker"
        />
      </StyledGrid>
    </StyledWrapper>
  );
}

export default SelectDateCalendar;
