import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { preferredlanguageUrl } from "../../../common/config/app.endpoints";
import Button from "../../../common/elements/Button";
import {
  DialogOption,
  useDialog,
} from "../../../common/elements/Dialog/Dialog";
import Colors from "../../../common/materials/colors";
import AuthService from "../../../components/auth/AuthService";
import { changeApplicationLanguage } from "../../../utils/utility";
import SignUpFormDialog, {
  SignUpFormDialogOptions,
} from "../Dialogs/SignUpForm";
import { list2 } from "../Footer/Footer";
import Chevron from "../Static/Icons/Chevron";
import Cross from "../Static/Icons/Cross";
import Globe from "../Static/Icons/Globe";
import Menu from "../Static/Icons/Menu";
import SignIn from "../Static/Icons/SignIn";
import { LandingPagePlayFairFont } from "../StyledComponents";
import { redirectToOtherDomain, setRedirectPathPostAuth } from "../Utils";
import { MobileHeader, MobileSignIn } from "./Header";

const useStyles = makeStyles({
  menu: {
    position: "relative",
    // height: "99vh",
    width: "99vw",
    overflowX: "hidden",
  },
  container: {
    padding: "0em 4em 0",
  },
  paddingV: {
    padding: "3em 0",
  },
  menuItem: {
    padding: "0.5em 0",
    marginLeft: "1em",
    borderBottom: `1px solid ${Colors.black}`,
  },
  scale: {
    transform: "scale(1.5)",
  },
  accordionSummary: {
    flexDirection: "row-reverse",
  },
});

const Items = ({
  items,
  handleLogin,
  handleClose,
}: {
  handleLogin?: any;
  handleClose?: any;
  items: { text: string; url: string }[];
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const handleRedirect = (url: string) => {
    if (!url) {
      return;
    }
    if (url.includes("/newaboutus")) {
      history.push(url);
    }
    if (url.includes("zipang") || url.includes("auctioneer")) {
      return redirectToOtherDomain(url);
    }
    setRedirectPathPostAuth(url);
    handleLogin && handleLogin();
  };
  return (
    <Grid direction="row" container>
      {items.map((item) => (
        <Grid item xs={12} key={item.text} className={classes.menuItem}>
          <Typography onClick={() => handleRedirect(item.url)} variant="h6">
            {t(item.text)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};

const headerMenuItems = [
  { text: "common:header:menu:jpa", url: "zipang" },
  { text: "common:header:menu:oa", url: "preview" },
  { text: "common:header:menu:365a", url: "365products" },
  { text: "common:header:menu:ma", url: "auctioneer" },
];

const MobileMenuModal = ({
  onClose,
  onLogin,
}: {
  onClose: () => any;
  onLogin: () => any;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [createDialog, closeDialog] = useDialog();

  const changeLanguage = (lng: string) => {
    var lang: any = "";
    if (lng === "en") {
      lang = "en";
      window.localStorage.setItem("language", "EN");
    } else if (lng === 'zh_CN') {
      lang = "zh_CN";
      window.localStorage.setItem("language", "zh_CN");
    } else {
      lang = "jp";
      window.localStorage.setItem("language", "日本語");
    }
    var token = localStorage.getItem("id_token");
    if (token !== null) {
      const Auth = new AuthService();
      if (token === null) {
        history.push("/");
      } else {
        Auth.postRequest(
          `${preferredlanguageUrl}/${lang}`,
          "data",
          (status: number, response: any) => {
            changeApplicationLanguage(lng);
          }
        );
      }
    } else {
      changeApplicationLanguage(lng);
    }
    onClose();
  };

  const handleRegister = React.useCallback(() => {
    createDialog({
      children: (
        <SignUpFormDialog
          isMobile={true}
          onSignUp={closeDialog}
          close={closeDialog}
        />
      ),
      ...SignUpFormDialogOptions,
    });
  }, []);

  const handleSellDiamonds = React.useCallback(() => {
    history.push("/sell");
  }, []);

  return (
    <Grid container className={classes.menu}>
      <MobileHeader handleBuyDiamonds={handleRegister} />
      <span
        onClick={onClose}
        style={{ position: "fixed", bottom: "1em", left: "1em" }}
      >
        <Cross filled classes={classes.scale} />
      </span>
      <Grid container justifyContent="center" className={classes.paddingV}>
        <Typography variant="h3">
          <LandingPagePlayFairFont>
            {t("common:menuText")}
          </LandingPagePlayFairFont>
        </Typography>
      </Grid>
      <Grid container classes={classes}>
        <Accordion defaultExpanded={true} elevation={0}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<Chevron />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">
              {t("common:header:buttons:ea")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Items
              items={headerMenuItems}
              handleLogin={onLogin}
              handleClose={onClose}
            />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container classes={classes}>
        <Accordion elevation={0}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<Chevron />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">
              {t("common:subHeader:buttons:aboutUs")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Items items={list2} />
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid container classes={classes}>
        <Accordion elevation={0} style={{ width: "100%" }}>
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<Chevron />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{ margin: 0 }}
          >
            <Globe
              // onClick={handleClick}
              width="80"
              height="80"
              style={{ marginLeft: "-24px", height: "60px" }}
            />
          </AccordionSummary>
          <AccordionDetails style={{ width: "50vw" }}>
            <Grid container justifyContent="space-between">
              <strong onClick={() => changeLanguage("en")}>ENGLISH</strong>
              <strong onClick={() => changeLanguage("jp")}>日本語</strong>
              <strong onClick={() => changeLanguage("zh_CN")}>中文</strong>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.paddingV}
      >
        <Button onClick={handleSellDiamonds} type="contained-dark">
          {t("common:header:buttons:sellYDiamonds")}
        </Button>
      </Grid>
    </Grid>
  );
};

const MobilMenu = () => {
  const [createDialog, closeDialog] = useDialog();
  const [showSignIn, setShowSignIn] = useState(false);

  const handleLogin = () => setShowSignIn(true);
  const handleClose = () => setShowSignIn(false);

  const options = {
    fullScreen: true,
  } as unknown as DialogOption;
  const handleMenuClick = React.useCallback(() => {
    createDialog({
      children: <MobileMenuModal onClose={closeDialog} onLogin={handleLogin} />,
      ...options,
    });
  }, []);

  return (
    <Grid container alignItems="center" justifyContent="space-between">
      <MobileSignIn show={showSignIn} onClose={handleClose} />
      <Button type="null" onClick={handleMenuClick}>
        <Menu />
      </Button>
      <Button type="null" onClick={handleLogin}>
        <SignIn id="sign-in" />
      </Button>
    </Grid>
  );
};

export default MobilMenu;
