import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Carousel } from "react-responsive-carousel";
import "../../../static/css/carousel.css";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { GlassMagnifier } from "react-image-magnifiers";
import Modal from "@material-ui/core/Modal";

import ZoomedCarousel from "../../auction-version-2/auction/components/details/ZoomedCarousel";

const StyledArrowWrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 100px;
  width: 30px;
  ${(props) => props.theme.breakpoints.down("xl")} {
    top: 100px;
  }
  cursor: pointer;
`;

const StyledArrows = styled("img")``;

const StyledWrapperGrid = styled(Grid)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.colors.base.white};
`;

const StyledWrapperGrid1 = styled(Grid)`
  width: 99%;
  height: auto;
  ${(props) => props.theme.breakpoints.down("xs")} {
    width: 90%;
  }
`;

const StyledGrid = styled(Grid) <{ reserved: any }>`
  background: ${(props) => props.theme.colors.base.white};
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: ${(props) =>
    !props?.reserved ? "url(/images/reserve/reserveBadge2.png)" : ""};
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 6em;
    ${(props) => props.theme.breakpoints.between("xs", "md")} {
      background-size: 5em;
    }
    z-index: 2;
    pointer-events: none;
  }
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  height: 320px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 300px;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    #wrapfream {
      width: 463px;
      height: 340px;
      padding: 0;
      margin-left: 70px;
      overflow: hidden;
    }
  }
  ${(props) => props.theme.breakpoints.only("md")} {
    #wrapfream {
      width: 463px;
      height: 340px;
      padding: 0;
      margin-left: 70px;
      overflow: hidden;
    }
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    #wrapfream {
      width: 614px;
      height: 440px;
      padding: 0;
      margin-left: 70px;
      overflow: hidden;
    }
  }
  @media screen and (width: 768px) {
    height: 360px;
  }

  @media screen and (max-width: 600px) {
    #wrapfream {
      width: 447px !important;
    }
    #scaled-frame {
      zoom: 1;
    }
  }

  @media (width: 375px) {
    height: 275px;
    #wrapfream {
      width: 413px !important;
    }
    #scaled-frame {
      zoom: 0.93;
    }
  }

  @media screen and (width: 820px) {
    height: 400px;
  }
  @media screen and (width: 912px) {
    height: 400px;
  }
  @media screen and (width: 1024px) {
    height: 400px;
  }
`;

const StyledGridVideo = styled(Grid)`
  background: ${(props) => props.theme.colors.base.white};
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
  height: 340px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 320px;
  }
  ${(props) => props.theme.breakpoints.only("xs")} {
    height: 300px;
  }
  ${(props) => props.theme.breakpoints.only("lg")} {
    height: 420px;
  }
  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 480px;
  }
  @media (width: 768px) {
    height: 320px;
  }
`;
const StyledNotAvailableImage = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
  justify-content: center;
  align-items: flex-start;
  background: ${(props) => props.theme.colors.base.white};
  ${(props) => props.theme.breakpoints.only("lg")} {
    height: 340px;
  }
`;

const StyledCorouselWrapper = styled(Grid)`
  width: 100%;
`;

const StyledImageNotAvailable = styled("img")``;

const StyledFrame = styled.iframe`
  width: 100%;
  height: 360px;
  ${(props) => props.theme.breakpoints.down("xs")} {
    height: 160px;
  }
  ${(props) => props.theme.breakpoints.down("lg")} {
    height: 370px;
  }

  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 580px;
  }

  @media (min-width: 1270px) and (max-width: 1980px) {
    #wrapfream {
      width: 455px;
      height: 300px;
      padding: 0;
      margin-left: 86px;
    }

    #scaled-frame {
      zoom: 1.15;
    }
  }
`;

const StyledGridForImages = styled(Grid)`
  gap: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  @media screen and (width: 768px) {
    width: 90%;
    height: 90%;
    margin-left: 20px;
  }

  @media screen and (width: 820px) {
    width: 80%;
    height: 80%;
    margin-left: 60px;
  }
  @media screen and (width: 1024px) {
    width: 80%;
    height: 80%;
    margin-left: 10%;
  }
  @media screen and (width: 540px) {
    width: 80%;
    height: 80%;
    margin-left: 10%;
  }
  @media screen and (width: 912px) {
    width: 80%;
    height: 80%;
    margin-left: 10%;
  }
`;

const StyledLargeTxtImg = styled("img")`
  width: 20px !important;
  height: 20px !important;
`;

const StyledLargeTxtCon = styled(Grid)`
  display: flex;
  justify-content: end;
  right: 25%;
  position: relative;
  padding: 4px;
  align-items: center;
  ${(props) => props.theme.breakpoints.down("xs")} {
    right: 25%;
  }
  @media screen and (width: 375px) {
    padding: 0px;
  }
`;
const StyledModal = styled(Modal)`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  medias: any;
  parcelType: any;
  isReserved: any;
}

const BidingDetailsCarousel = (props: IProps) => {
  const { medias, parcelType, isReserved } = props;
  const [zoomedCarousel, setZoomedCarousel] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0); // Start with the third media as active

  const imageLargePopup = (url: string) => {
    setZoomedCarousel(true);
  };

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === "Escape") {
        setZoomedCarousel(false);
      }
    };
    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  const ReactSlide = ({ url }: { url: string }) => (
    <StyledGridVideo>
      <ReactPlayer
        width="50%"
        height="70%"
        url={url}
        playing={false}
        controls={true}
        muted={true}
        progressInterval={1000}
      />
    </StyledGridVideo>
  );

  const videoLargePopup = (url: any, mediaTypeFullview?: any) => {
    setZoomedCarousel(true);
  };

  const onClose = () => {
    setZoomedCarousel(false);
  };

  const memoizedListOfMovies = useMemo(() => {
    if (medias !== null && medias !== undefined) {
      return (
        <Carousel
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          thumbWidth={40}
          swipeable={false}
          onChange={(newIndex: number) => {
            setActiveIndex(newIndex);
          }}
          renderArrowPrev={(onClickHandler, hasPrev, label) => (
            <StyledArrowWrapper
              onClick={onClickHandler}
              title={label}
              style={{ left: 0 }}
            >
              {hasPrev ? (
                <StyledArrows src="/images/controls/left_arrow_round_edges.png" />
              ) : (
                <StyledArrows src="/images/controls/left_arrow_round_edges_disabled.png" />
              )}
            </StyledArrowWrapper>
          )}
          renderArrowNext={(onClickHandler, hasNext, label) => (
            <StyledArrowWrapper
              onClick={onClickHandler}
              title={label}
              style={{ right: 5 }}
            >
              {hasNext ? (
                <StyledArrows src="/images/controls/right_arrow_round_edges.png" />
              ) : (
                <StyledArrows src="/images/controls/right_arrow_round_edges_disabled.png" />
              )}
            </StyledArrowWrapper>
          )}
        >
          {medias.map((item: any, index: number) => (
            <StyledWrapperGrid item={true} key={index}>
              {item.mediaType === "PHOTO" ? (
                <div>
                  {item.thumbnailPresignedUrl !== null ? (
                    <StyledGridForImages>
                      <StyledWrapperGrid1>
                        <GlassMagnifier
                          imageSrc={`${item.thumbnailPresignedUrl}`}
                          magnifierSize="50%"
                          largeImageSrc={`${item.thumbnailPresignedUrl}`}
                          style={{
                            maxWidth: "100%",
                          }}
                        />
                      </StyledWrapperGrid1>
                      <div
                        style={{ cursor: "pointer", zIndex: 9 }}
                        onClick={() => {
                          imageLargePopup(`${item.presignedUrl}`);
                        }}
                      >
                        <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                      </div>
                    </StyledGridForImages>
                  ) : (
                    <StyledGrid
                      container={true}
                      spacing={0}
                      reserved={isReserved}
                    >
                      <StyledNotAvailableImage>
                        {parcelType === "Diamond" ? (
                          <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
                        ) : (
                          <StyledImageNotAvailable src="/images/default-parcel.png"></StyledImageNotAvailable>
                        )}
                      </StyledNotAvailableImage>
                    </StyledGrid>
                  )}
                </div>
              ) : (
                <div>
                  {item.presignedUrl === null ? (
                    <StyledGrid
                      container={true}
                      spacing={0}
                      reserved={isReserved}
                    >
                      <StyledNotAvailableImage>
                        <StyledImageNotAvailable src="/images/video_not_available.png"></StyledImageNotAvailable>
                      </StyledNotAvailableImage>
                    </StyledGrid>
                  ) : (
                    <Grid container={true} spacing={0}>
                      <StyledLargeTxtCon item={true} xs={12}>
                        <div
                          onClick={() =>
                            videoLargePopup(
                              `${item.presignedUrl}`,
                              item.mediaType
                            )
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <StyledLargeTxtImg src="/images/video-enlarge.png"></StyledLargeTxtImg>
                        </div>
                      </StyledLargeTxtCon>
                      {item.mediaType === "VIDEO" ? (
                        <ReactSlide url={`${item.presignedUrl}`} />
                      ) : (
                        <div id="wrapfream">
                          <StyledFrame
                            id="scaled-frame"
                            src={`${item.presignedUrl}`}
                            style={{ margin: "0px" }}
                          />
                        </div>
                      )}
                    </Grid>
                  )}
                </div>
              )}
            </StyledWrapperGrid>
          ))}
        </Carousel>
      );
    }
  }, [medias !== undefined]);

  return (
    <StyledGrid container={true} spacing={0} reserved={isReserved}>
      <Grid item={true} xs={12}>
        {medias !== undefined ? (
          <div>
            {medias !== null && medias.length > 0 ? (
              <StyledCorouselWrapper>
                {memoizedListOfMovies}
              </StyledCorouselWrapper>
            ) : (
              <StyledGrid container={true} spacing={0} reserved={isReserved}>
                <StyledNotAvailableImage>
                  <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
                </StyledNotAvailableImage>
              </StyledGrid>
            )}
          </div>
        ) : (
          <StyledGrid container={true} spacing={0} reserved={isReserved}>
            <StyledNotAvailableImage>
              <StyledImageNotAvailable src="/images/default-diamond.png"></StyledImageNotAvailable>
            </StyledNotAvailableImage>
          </StyledGrid>
        )}
      </Grid>

      {zoomedCarousel && (
        <StyledModal open={zoomedCarousel} onClose={onClose}>
          <ZoomedCarousel
            medias={medias}
            onClose={onClose}
            parcelType={parcelType}
            activeIndex={activeIndex}
          />
        </StyledModal>
      )}
    </StyledGrid>
  );
};
export default BidingDetailsCarousel;
