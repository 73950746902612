import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/reducer";
import { selectAnnualJewelleryItemPerPage } from "../../../redux/slice/commanPagination/commanPaginationSlice";
import { StyledPageSizeSelectorContainer } from "../../365Auction/component/DiamondProductStyle";

export interface Iprops {
  t: any;
  setItemsPerPageHandler: (pageSize: number) => void;
}

const JewelleryAnnualActionPage = (props: Iprops) => {
  const { t, setItemsPerPageHandler } = props;
  const pageCount = [20, 50, 100, 200];
  const items = ``;
  const pageSizeHandler = (e: any) => {
    const pageSize = parseInt(e.target.value);
    setItemsPerPageHandler(pageSize);
  };
  let [selection, setSelection] = useState<number>(20);

  const selectItemPerPageValue: any = useSelector((state: RootState) =>
    selectAnnualJewelleryItemPerPage(state)
  );

  useEffect(() => {
    if (selectItemPerPageValue !== null) {
      let savedCount: any = selectItemPerPageValue;
      setSelection(savedCount);
    } else {
      setSelection(selectItemPerPageValue);
    }
  }, []);
  return (
    <StyledPageSizeSelectorContainer>
      <select onChange={pageSizeHandler}>
        {pageCount.map((count, index) => (
          <option
            key={index}
            selected={count === selection ? true : false}
            value={count}
          >{`${count} ${items}`}</option>
        ))}
      </select>
    </StyledPageSizeSelectorContainer>
  );
};

export default JewelleryAnnualActionPage;
