import { useMediaQuery } from "@material-ui/core";
import React from "react";
import {
  StyledImageCommonComponent,
  StyledImageCommonContainer,
} from "../StyledComponents";
import { StyledAdsContainer } from "./StyledComponent";

const AdContainer = ({ imgSrc, link }: { imgSrc: string; link: string }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  if (!imgSrc) {
    return null;
  }
  return (
    <StyledAdsContainer>
      <StyledImageCommonContainer
        onClick={() => window.open(link, "_blank")}
        style={{
          cursor: "pointer",
          height: isMobile ? "300px" : "600px",
          width: isMobile ? "300px" : "300px",
        }}
      >
        <StyledImageCommonComponent src={imgSrc} />
      </StyledImageCommonContainer>
    </StyledAdsContainer>
  );
};

export default AdContainer;
