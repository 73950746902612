import React from 'react';
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';

import ShippingAddress from './ShippingAddress';
import BankDetails from './BankDetails';
import ChangePassword from './ChangePassword';
import ChangePlan from './ChangePlan';
import { changePlanUrl, profileUrl, userInvoiceDownload } from '../../common/config/app.endpoints';
import ShippingAddressList from '../products/views/ShippingAddressList';
import BankDetailsList from '../products/views/BankDetailsList';
import UserPersonalDetails from '../products/UserPersonalDetails';
import AuthService from '../auth/AuthService';
import Alert from '../../common/elements/Alert';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import IRouterComponentProps from '../../models/IRouterComponentProps';
import ApiTimeout from '../../modules/ApiTimeout';
import { oAuth } from '../../routes/basic/BasicAppRouter';
import BlockUserModal from '../products/BlockUser/BlockUserModal';

const StyledUserProfileContainer = styled(Grid)`
`;

const StyledProfileCard = styled(Grid)`
    margin: 10px 0px 30px 0px;  
    width: 100%;
    margin-left: 25px;
`;

const StyledUserName = styled.h1`
    font-size: 1em;
    padding: 0.2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1.5em;  
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 1.8em;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        font-size: 2.3em;
    }
    @media (width: 1024px) {
        font-size: 1.5em;
    }    

`;

const StyledCompanyNameField = styled.h2`
    color: ${(props) => props.theme.colors.base.grey};
    display: flex;   
    padding: 0.2em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        font-size: 1em; 
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        font-size: 1.5em;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        font-size: 1.8em;
    }
    @media (width: 1024px) {
        font-size: 1.5em;
      }
`;

const StyledOccupationName = styled.h2`
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  padding: 0.2em;
  display: flex;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    font-size: 1em;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    font-size: 1.5em;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    font-size: 1.8em;
  }
  @media (width: 1024px) {
    font-size: 1.5em;
  }
`;

const StyledButtonContainer = styled(Grid)`
    display: flex; 
    justify-content: space-between;
`;

const StyledButtonWrapper = styled(Grid)`
    display: flex; 
    flex-direction: column;
    
`;

const StyledTextWrapper = styled(Grid)`
    display: flex; 
    flex-direction: column;
    justify-content: space-evenly;
`;

const StyledTextSubWrapper = styled(Grid)`
    display: flex; 
    flex-direction: row;
`;

const StyledPersonalBankFieldSet = styled.fieldset`
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    display: flex;
    border-radius: 8px;
    margin: 1em;
    height: 300px;
    flex-direction: column; 
    justify-content: space-around; 
    align-items: center;
`;

const StyledShippingFieldSet = styled.fieldset`
    border: 1px solid ${(props) => props.theme.colors.base.lightGrey};
    display: flex;
    border-radius: 8px;
    margin: 1em;
    flex-direction: column; 
    align-items: center;
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        height: 560px;
    }
`;

const StyledLegend = styled.legend`
    margin-left: 20px;
    padding: 0.3em;
    font-size: 1em;
    color: ${(props) => props.theme.colors.base.grey};
    font-weight: bold;  
    width: auto;
`;

const StyledWrapper = styled(Grid)`
    width: 100%;
`;

const StyledShippingWrapper = styled.div`
  max-height: 500px;
  overflow-y: scroll;
  width: 100%;
  padding: 10px 20px;
  ::-webkit-scrollbar {
    width: 3px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border-radius: 5px;
  }
`;

const StyledBankWrapper = styled(Grid)`
    overflow-y: scroll;
    height: 220px;
    width: 100%;
    padding: 10px 20px;
    ::-webkit-scrollbar {
        width: 3px;
    }
    ::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.colors.base.scrollerColor};
        border-radius: 5px;
    }
`;

const StyledModal = styled(Modal)`
    display: flex;
    outline: none;
    align-items: center;
    justify-content: center;
`;

const StyledGridWrapper = styled(Grid)`
    padding: 0.5em;
`;

const StyledAddressList = styled(Grid)`
    // white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledGrid = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledAddMore = styled.div`
  text-align: right;
  cursor: pointer;
  margin-right: 10px;
  color: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
`;

const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.white};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    margin: 0.3em;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
    border: 1px solid ${(props) => props.theme.colors.base.white};
    &:hover {
        border: 1px solid ${(props) => props.theme.colors.base.textColor};
        cursor: pointer;
    }
`;


interface IProps extends IRouterComponentProps {
    t?: any;
}

interface IState {
    profileData: any;
    bankDetails: any;
    shippingAddresses: Array<string>;
    isShippingAddressModalOpen: boolean;
    isBankDetailsModalOpen: boolean;
    ischangePasswordModalOpen: boolean;
    isChangePlanModalOpen: boolean;
    openToast: boolean;
    occupation: [];
    showAlertMessage: string;
    isLoading: boolean;
    alertType: string;
    apiTimeout: boolean;
    isPlanChange: boolean;
    userBlock: boolean;
    userType: string;
    startDate: string;
    endDate: string;
    downloadInvoiceLink: string;
    space: string;
    userTypeValue: string;
    paddleNumber: string;
}

export default class UserProfile extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            profileData: [],
            bankDetails: [],
            shippingAddresses: [],
            isShippingAddressModalOpen: false,
            isBankDetailsModalOpen: false,
            ischangePasswordModalOpen: false,
            isChangePlanModalOpen: false,
            openToast: false,
            occupation: [],
            showAlertMessage: '',
            isLoading: false,
            alertType: '',
            apiTimeout: false,
            isPlanChange: false,
            userBlock: false,
            userType: '',
            startDate: '',
            endDate: '',
            downloadInvoiceLink: '',
            space: ' ',
            userTypeValue: '',
            paddleNumber: ''
        }
    }

    Auth = new AuthService();

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        localStorage.removeItem("currentProductIdOfBidNow");
        localStorage.removeItem("currentJewelleryProductIdOfBidNow");
        localStorage.removeItem("currentPDItem");
        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            this.getProfile();
            localStorage.removeItem('filteredValues');
        }
    }

    getTranslation = (occuption?: any) => {
        let role = '';
        if (occuption.length === 1) {
            if (occuption[0] === "Bidder") {
                role = role + this.props.t("common:register:occupation:bidder");
            } else if (occuption[0] === "Auctioneer") {
                role = role + this.props.t("common:register.occupation:auctioneer");
            }
        } else {
            role = role + this.props.t("common:register:occupation:auctioneerAndBidder");
        }
        return role;
    }

    getProfile() {
        this.Auth.getRequest(profileUrl, (status: number, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    this.setState({
                        profileData: response,
                        occupation: response.occupations,
                        isLoading: false,
                        apiTimeout: false,
                        userType: response.userType,
                        startDate: response.subscriptionStartDate,
                        endDate: response.subscriptionEndDate,
                        downloadInvoiceLink: response.userType,
                        paddleNumber: response.paddleNumber
                    })

                    var userTypeTemp = response.userType;
                    let lang = localStorage.getItem('language');
                    if (lang !== "EN") {
                        if (userTypeTemp === "SUBSCRIBED") {
                            userTypeTemp = "登録された"
                        } else {
                            userTypeTemp = "招待された"
                        }
                    }

                    this.setState({
                        userTypeValue: userTypeTemp
                    })

                    if (response.bankDetails !== undefined) {
                        if (response.bankDetails !== null) {
                            response.bankDetails.forEach((item: any, i: number) => {
                                if (item.primary === true) {
                                    response.bankDetails.splice(i, 1);
                                    response.bankDetails.unshift(item);
                                }
                            });
                            this.setState({
                                bankDetails: response.bankDetails
                            })
                        }
                    }
                    if (response.shippingAddresses !== undefined) {
                        if (response.shippingAddresses !== null) {
                            response.shippingAddresses.forEach((item: any, i: number) => {
                                if (item.primary === true) {
                                    response.shippingAddresses.splice(i, 1);
                                    response.shippingAddresses.unshift(item);
                                }
                            });
                            this.setState({
                                shippingAddresses: response.shippingAddresses
                            })
                        }
                    }
                }
            }
            else if (status === 403) {
                this.setState({ userBlock: true })
            }
            else if (status === 505) {
                this.setState({ apiTimeout: true, isLoading: false })
            }
            else {
                this.setState({
                    alertType: 'error',
                    openToast: true,
                    showAlertMessage: response.data.message,
                    isLoading: false
                })
            }
        })
    }

    onAddShippingAddress() {
        this.setState({
            isShippingAddressModalOpen: true
        })
    }

    onAddBankDetails() {
        this.setState({
            isBankDetailsModalOpen: true
        })
    }

    onChangePassword() {
        this.setState({
            ischangePasswordModalOpen: true
        })
    }

    changePlan() {
        if (!oAuth()) {
            this.props.history.push('/');
        } else {
            this.setState({
                isPlanChange: true
            })
            this.Auth.postRequest(changePlanUrl, 'data', (status: number, response: any) => {
                if (status === 200) {
                    if (response !== undefined) {
                        this.setState({
                            isPlanChange: false,
                            isChangePlanModalOpen: true
                        })
                    }
                    else {
                        this.setState({
                            isPlanChange: false,
                            alertType: 'error',
                            openToast: true,
                            showAlertMessage: response.data.message,
                            isLoading: false
                        })
                    }
                }
                else if (status === 403) {
                    this.setState({ userBlock: true })
                }
                else if (status === 505) {
                    this.setState({ apiTimeout: true, isPlanChange: false })
                }
                else {
                    this.setState({
                        isPlanChange: false,
                        alertType: 'error',
                        openToast: true,
                        showAlertMessage: response[0].message,
                        isLoading: false
                    })
                }
            })
        }
    }

    closeModal() {
        this.setState({
            isShippingAddressModalOpen: false,
            isBankDetailsModalOpen: false,
            ischangePasswordModalOpen: false,
            isChangePlanModalOpen: false
        })
    }

    closeChangePasswordModal() {
        this.setState({
            openToast: false,
            showAlertMessage: '',
            ischangePasswordModalOpen: false
        })
    }

    message = (message: string) => {
        this.setState({
            openToast: true,
            showAlertMessage: message
        })
    }

    showAlert() {
        const { t } = this.props;
        this.setState({
            alertType: 'success',
            openToast: true,
            showAlertMessage: `${t('common:profile:toast:passwordUpdate')}`
        })
    }

    timeout = (timeout: boolean) => {
        this.setState({
            apiTimeout: true
        })
        this.closeModal()
    }

    blockCloseModal = () => {
        this.Auth.logout();
        this.props.history.replace('/');
    }

    downloadInvoice() {
        this.setState({
            isPlanChange: true,
        })
        const Auth = new AuthService();

        Auth.getRequestForDownload(`${userInvoiceDownload}`, (status: any, response: any) => {
            if (status === 200) {
                if (response !== undefined) {
                    const url = window.URL.createObjectURL(new Blob([response]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `SubscriptionInvoice.pdf`);
                    document.body.appendChild(link);
                    link.click();
                }

            } else {

            }

            this.setState({
                isPlanChange: false,
            })

        })


    }

    render() {
        const { t } = this.props;
        return (
            <StyledUserProfileContainer container={true} spacing={0} className="userProfileContainer">
                {this.state.apiTimeout === true ?
                    <ApiTimeout t={t} {...this.props} /> :
                    <Grid container={true} spacing={0}>
                        {this.state.isLoading === true ?
                            <div className="loader">
                                <LoadingSpinner />
                            </div> :
                            <Grid container={true} spacing={0} className="coreGridContainer">
                                <StyledProfileCard container={true} spacing={0} className="profileCard">
                                    <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid container spacing={0}>
                                            <Grid item={true} xs={6}>
                                                <StyledUserName>
                                                    {this.state.profileData.firstName} {this.state.profileData.lastName}
                                                </StyledUserName>

                                                <StyledOccupationName>
                                                    {this.getTranslation(this.state.occupation)}
                                                </StyledOccupationName>
                                                <StyledCompanyNameField>
                                                    {this.state.profileData.company}
                                                </StyledCompanyNameField>
                                            </Grid>
                                            <StyledButtonContainer item={true} xs={6}>
                                                <StyledButtonWrapper item={true} xs={12} sm={12} md={12} lg={8} xl={8}>
                                                    <StyledButton onClick={() => this.onChangePassword()}>
                                                        {t('common:button:changePassword')}
                                                    </StyledButton>
                                                    <StyledButton onClick={() => this.changePlan()}>
                                                        {t('common:button:changePlan')}
                                                    </StyledButton>
                                                </StyledButtonWrapper>
                                            </StyledButtonContainer>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid container spacing={0}>
                                            <StyledTextWrapper item={true} xs={7} sm={7} md={7} lg={7} xl={7}>
                                                <StyledTextSubWrapper>
                                                    <div style={{color: '#014a99', fontWeight: 'bold', whiteSpace: 'break-spaces' }}>{t('common:profile:userType')}</div>
                                                    <div>{this.state.userTypeValue}</div>
                                                </StyledTextSubWrapper>
                                            </StyledTextWrapper>
                                            <Grid xs={5} sm={5} md={5} lg={5} xl={5}>
                                                {/* {this.state.userType === 'SUBSCRIBED' && */}
                                                    <StyledButton onClick={() => this.downloadInvoice()}>
                                                        {t('common:profile:downloadInvoice')}
                                                    </StyledButton>
                                                {/* // } */}
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0}>
                                            <StyledTextWrapper item={true} xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <StyledTextSubWrapper>
                                                    <div style={{ marginTop: '17px', color: '#014a99', fontWeight: 'bold', whiteSpace: 'break-spaces' }}>{t('common:profile:startDate')}</div>
                                                    <div style={{ marginTop: '17px' }}>{this.state.startDate}</div>
                                                </StyledTextSubWrapper>
                                            </StyledTextWrapper>
                                            <StyledTextWrapper item={true} xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <StyledTextSubWrapper>
                                                    <div style={{ marginTop: '17px', color: '#014a99', fontWeight: 'bold', whiteSpace: 'break-spaces' }}>{t('common:profile:endDate')}</div>
                                                    <div style={{ marginTop: '17px' }}>{this.state.endDate}</div>
                                                </StyledTextSubWrapper>
                                            </StyledTextWrapper>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Grid container spacing={0}>
                                            <StyledTextWrapper item={true} xs={6} sm={6} md={6} lg={6} xl={6}>
                                                <StyledTextSubWrapper>
                                                     <StyledOccupationName> Paddle Number: {this.state.paddleNumber}</StyledOccupationName>
                                                </StyledTextSubWrapper>
                                            </StyledTextWrapper>
                                        </Grid>
                                    </Grid>
                                </StyledProfileCard>
                                <Grid container={true} spacing={0}>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <StyledPersonalBankFieldSet>
                                                <StyledLegend>{t('common:profile:title:personalDetails')}</StyledLegend>
                                                <StyledWrapper>
                                                    <UserPersonalDetails {...this.state.profileData} t={t} />
                                                </StyledWrapper>
                                            </StyledPersonalBankFieldSet>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <StyledPersonalBankFieldSet>
                                                <StyledLegend>{t('common:profile:title:bankDetails')}</StyledLegend>
                                                <StyledBankWrapper>
                                                    {this.state.bankDetails !== null &&
                                                        <div>
                                                            {this.state.bankDetails.length > 0 &&
                                                                <Grid container={true} spacing={0}>
                                                                    {this.state.bankDetails.map((items: any, index: number) => (
                                                                        <StyledGridWrapper key={index} item={true} xs={12} sm={12} md={6} lg={6}>
                                                                            <Card>
                                                                                <StyledAddressList item xs={12} sm={12} md={12} lg={12}>
                                                                                    <BankDetailsList {...this.props} apiTimeout={this.timeout} {...items} t={t} didMount={() => this.getProfile()} message={this.message} />
                                                                                </StyledAddressList>
                                                                            </Card>
                                                                        </StyledGridWrapper>
                                                                    ))}
                                                                </Grid>
                                                            }
                                                        </div>
                                                    }
                                                    <StyledGrid>
                                                        <StyledAddMore onClick={() => this.onAddBankDetails()}>{t('common:profile:addMore')}</StyledAddMore>
                                                    </StyledGrid>
                                                </StyledBankWrapper>
                                            </StyledPersonalBankFieldSet>
                                        </Grid>
                                    </Grid>
                                    <Grid item={true} xs={12} sm={12} md={12} lg={6} xl={6}>
                                        <StyledShippingFieldSet>
                                            <StyledLegend>{t('common:profile:title:shippingAddress')}</StyledLegend>
                                            <StyledShippingWrapper>
                                                {this.state.shippingAddresses !== null &&
                                                    <div>
                                                        {this.state.shippingAddresses.length > 0 &&
                                                            <Grid container={true} spacing={0}>
                                                                {this.state.shippingAddresses.map((items: any, index) => (
                                                                    <StyledGridWrapper key={index} item={true} xs={12} sm={12} md={6} lg={6}>
                                                                        <Card>
                                                                            <StyledAddressList item xs={12} sm={12} md={12} lg={12}>
                                                                                <ShippingAddressList {...this.props} apiTimeout={this.timeout} {...items} t={t} didMount={() => this.getProfile()} message={this.message} />
                                                                            </StyledAddressList>
                                                                        </Card>
                                                                    </StyledGridWrapper>
                                                                ))}
                                                            </Grid>
                                                        }
                                                    </div>
                                                }
                                                <StyledGrid>
                                                    <StyledAddMore onClick={() => this.onAddShippingAddress()}>{t('common:profile:addMore')}</StyledAddMore>
                                                </StyledGrid>
                                            </StyledShippingWrapper>
                                        </StyledShippingFieldSet>
                                    </Grid>
                                </Grid>
                                {this.state.isPlanChange === true &&
                                    <div className="loading">
                                        <LoadingSpinner />
                                    </div>
                                }
                            </Grid>
                        }
                    </Grid>
                }
                {
                    this.state.isShippingAddressModalOpen &&
                    <StyledModal open={this.state.isShippingAddressModalOpen} onClose={() => this.closeModal()} className="registerModal">
                        <ShippingAddress {...this.props} closeModal={() => this.closeModal()} saveAddress={() => this.getProfile()} apiTimeout={this.timeout} companyName={this.state.profileData.companyName} />
                    </StyledModal>
                }
                {
                    this.state.isBankDetailsModalOpen &&
                    <StyledModal open={this.state.isBankDetailsModalOpen} onClose={() => this.closeModal()} className="registerModal">
                        <BankDetails {...this.props} closeModal={() => this.closeModal()} saveDetail={() => this.getProfile()} apiTimeout={this.timeout} />
                    </StyledModal>
                }
                {
                    this.state.ischangePasswordModalOpen &&
                    <StyledModal open={this.state.ischangePasswordModalOpen} onClose={() => this.closeChangePasswordModal()} className="registerModal">
                        <ChangePassword {...this.props} closeModal={() => this.closeChangePasswordModal()} showAlert={() => this.showAlert()} apiTimeout={this.timeout} />
                    </StyledModal>
                }
                {
                    this.state.isChangePlanModalOpen &&
                    <StyledModal open={this.state.isChangePlanModalOpen} onClose={() => this.closeModal()} className="registerModal">
                        <ChangePlan t={t} closeModal={() => this.closeModal()} />
                    </StyledModal>
                }
                {this.state.openToast === true &&
                    <Alert showAlertMessage={this.state.showAlertMessage} alertType={this.state.alertType} />
                }
                {this.state.userBlock === true &&
                    <StyledModal open={this.state.userBlock}>
                        <BlockUserModal t={t}{...this.props} closeModal={this.blockCloseModal} />
                    </StyledModal>
                }
            </StyledUserProfileContainer>
        )
    }
};

