import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const StyledHeroContainer = styled.div<{ isMobile: boolean }>`
  height: ${(props) => (props.isMobile ? "75vh" : "500px")};
  min-width: 100%;
  max-width: 100%;
  position: relative;
  margin-top: ${(props) => (props.isMobile ? "-60px" : "0")};
  margin-bottom: ${(props) => (props.isMobile ? "2em" : "0")};
  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    top: 50px;
  }
  ${(props) => props.theme.breakpoints.only("xl")} {
    height: 600px;
  }

  @media (min-width: 800px) and (max-width: 1024px) {
    height: 620px;
  }
  @media (width: 820px) {
    height: 800px;
  }
`;

export const StyledCarouselContainer = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -999;
`;

export const StyledHeroCenterContainer = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  position: absolute;
  z-index: 999;

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    z-index: -99;
    height: 70%;
    top: 45%;
    justify-content: flex-start;
  }

  @media (min-width: 820px) {
    justify-content: center;
  }

  @media (width: 820px) {
    z-index: -99;
    height: 70%;
    top: 40%;
    justify-content: flex-start;
  }

  @media (width: 1024px) {
    z-index: -99;
    height: 50%;
    top: 40%;
    justify-content: center;
  }

  @media (min-width: 1025px) {
    justify-content: center;
  }
`;


export const StyledHeroSecondCenterContainer = styled.div`
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  position: absolute;
  z-index: 999;

  ${(props) => props.theme.breakpoints.between("xs", "md")} {
    z-index: -99;
    height: 70%;
    top: 2%;
    justify-content: flex-start;
  }

  @media (min-width: 820px) {
    z-index: -99;
    height: 70%;
    top: 8%;
    justify-content: flex-start;
  }

  @media (min-width: 1025px) {
    justify-content: flex-start;
    margin-top: 5%;
  }
`;

export const StyledHeroTopContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;
export const StyledHeroButtonsContainer = styled(Grid) <{
  isMobile: boolean;
}>`
  margin-top: 4em;
  padding: ${(props) => (props.isMobile ? "0 1em" : "0 10%")};
`;
export const StyledHeroBannerText = styled.div<{
  isMobile: boolean;
  align?: string;
}>`
  width: 100%;
  text-align: ${(props) => props.align || "center"};
  padding: ${(props) => (props.isMobile ? "0 1em" : "0 10%")};
  margin: ${(props) => (props.isMobile ? "1em 0 0 0" : "0")};
  font-size: ${(props) => (props.isMobile ? "1.6em" : "3em")};
  color: #d92b3c;
  font-family: "Playfair Display";
  @media (min-width: 768px) and (max-width: 830px) {
    font-size: 2.8em;
  }
  @media (width: 820px) {
    font-size: 1.6em;
  }
  @media (min-width: 820px) {
    font-size: 2.6em;
  }
    
`;

export const StyledHeroBannerSubText = styled.div<{
  isMobile: boolean;
  align?: string;
}>`
  width: 100%;
  text-align: ${(props) => props.align || "center"};
  font-size: ${(props) => (props.isMobile ? "1.25em" : "1.5em")};
  padding: ${(props) => (props.isMobile ? "1em 3em 0" : "0 10%")};
  color: ${(props) => props.theme.colors.base.black};
  @media (min-width: 768px) and (max-width: 830px) {
    font-size: 2em;
  }

  @media (width: 820px) {
    font-size: 1.6em;
  }
`;

export const StyledArrowDownContainer = styled.div<{ isMobile: boolean }>`
  position: ${(props) => (props.isMobile ? "relative" : "absolute")};
  bottom: ${(props) => (props.isMobile ? "0" : "1em")};
  padding-top: ${(props) => (props.isMobile ? "0" : "4em")};
`;

export const StyledSellerButtonsContainer = styled(Grid) <{
  upatedIndex: boolean;
}>`
  justify-content: center;
  align-content: center;
  direction: row;
  margin-top: ${(props) => (props.upatedIndex ? "20px" : "20px")};
  @media (min-width: 768px) and (max-width: 830px) {
    margin-top: 60px;
    direction: column;
  }
  @media (width: 1024px) {
    margin-top: -30px;
  }

  @media (height: 1366px) {
    margin-top: 80px;
  }
`;
