import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import LanguageConstants, {
  LanguageCode,
} from "../../../common/constants/LanguageConstants";
import { publicApi, postRequestForKYC } from "../../../services/ApiService";
import {
  url as API_URL,
  b,
  kycFilledDataUrl,
  postErrorLog,
} from "../../../common/config/app.endpoints";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import {
  ILanguage,
  IEmailUrl,
  IAdminUrl,
  IKycRequest,
  ICommonSliceState,
  IKycSubmitDetailsRequest,
  IKycSubdomainRequest,
  IPostErrorLogsRequest,
} from "./commonSliceInterface";

const { JP } = LanguageConstants;
const { CODE_SMALL_JP } = LanguageCode;

const initialState: ICommonSliceState = {
  loading: false,
  error: "",
  appLanguage: {
    code: CODE_SMALL_JP,
    language: JP,
  },
  redirectFrom: {
    email: "",
    admin: "",
  },
  timezones: [],
  kyc: {
    data: {
      first_name: "",
      last_name: "",
      email: "",
      company: "",
      occupations: "",
      address: "",
      state: "",
      city: "",
      country_detail: {
        name: "",
      },
      phone: "",
      per_id: "",
      registered_from: "",
      last_modified_date: "",
      created_date: "",
      bank_detail: {
        account_holder_name: "",
        account_number: "",
        bank_name: "",
        bank_swift_no: "",
        address: "",
      },
      reference_details: {
        contact_person: "",
        contact_number: "",
        address: "",
        email_id: "",
        country: "",
        industry_association: "",
        polygon: "",
        memberships: "",
      },
      timezone: {
        name: "",
      },
      preferred_language_code: "",
      invitation_code: "",
    },
  },
};

export const getTimezones = createAsyncThunk<
  {},
  Partial<{}>,
  { rejectValue: any }
>("common/getTimezones", async (data, { rejectWithValue }) => {
  try {
    // TODO: get the url from app.endpoints
    const url = `${b.baseUrl}/pb/list/timezones`;
    const response = await publicApi.get(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getKycData = createAsyncThunk<
  IKycRequest,
  Partial<IKycRequest>,
  { rejectValue: any }
>("common/getKycData", async (data, { rejectWithValue }) => {
  try {
    const url = `${kycFilledDataUrl}/admin/kyc/signup?id=${data.userId}`;
    const response = await publicApi.get(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const submitKycDetails = createAsyncThunk<
  IKycSubmitDetailsRequest,
  Partial<IKycSubmitDetailsRequest>,
  { rejectValue: any }
>("common/submitKycDetails", async (data, { rejectWithValue }) => {
  try {
    const url = `${kycFilledDataUrl}/admin/kyc/submit`;
    const response = await postRequestForKYC(url, data.formData);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const getKycSubdomainsByUserId = createAsyncThunk<
  IKycSubdomainRequest,
  Partial<IKycSubdomainRequest>,
  { rejectValue: any }
>("common/getKycSubdomainsByUserId", async (data, { rejectWithValue }) => {
  try {
    const { userId, subDomain } = data;
    const getKYCSubdomains = `${API_URL}/admin/kyc/checkSubDomain`; // TODO: Get it from app.endpoints once code merged from dev
    const url = `${getKYCSubdomains}?id=${userId}&subDomain=${subDomain}`;
    const response = await publicApi.get(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const postErrorLogs = createAsyncThunk<
  IPostErrorLogsRequest,
  Partial<IPostErrorLogsRequest>,
  { rejectValue: any }
>("common/postErrorLogs", async (data, { rejectWithValue }) => {
  try {
    const response = await publicApi.post(postErrorLog, data.data);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setlanguage(state, action: PayloadAction<ILanguage>) {
      const { code, language } = action.payload;
      state.appLanguage = {
        code,
        language,
      };
    },
    setEmailRedirectUrl(state, action: PayloadAction<IEmailUrl>) {
      state.redirectFrom.email = action.payload.url;
    },
    setAdminRedirectUrl(state, action: PayloadAction<IAdminUrl>) {
      state.redirectFrom.admin = action.payload.url;
    },
    resetEmailRedirectionUrl(state, action) {
      state.redirectFrom.email = "";
    },
    resetAdminRedirectUrl(state, action) {
      state.redirectFrom.admin = "";
    },
  },
  extraReducers: (builder: any) => {
    // Get timezones from public API. Protected API used in userSlice.
    builder.addCase(getTimezones.pending, genericPendingCaseHandler);
    builder.addCase(
      getTimezones.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.timezones = action.payload;
      }
    );
    builder.addCase(getTimezones.rejected, genericRejectedCaseHandler);
    // Get KYC data
    builder.addCase(getKycData.pending, genericPendingCaseHandler);
    builder.addCase(
      getKycData.fulfilled,
      (state: any, action: PayloadAction) => {
        state.loading = false;
        state.kyc.data = action.payload;
      }
    );
    builder.addCase(getKycData.rejected, genericRejectedCaseHandler);
    // Submit Kyc Details
    builder.addCase(submitKycDetails.pending, genericPendingCaseHandler);
    builder.addCase(submitKycDetails.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(submitKycDetails.rejected, genericRejectedCaseHandler);
    // getKycSubdomainsByUserId
    builder.addCase(
      getKycSubdomainsByUserId.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      getKycSubdomainsByUserId.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(
      getKycSubdomainsByUserId.rejected,
      genericRejectedCaseHandler
    );
    // postErrorLogs
    builder.addCase(postErrorLogs.pending, genericPendingCaseHandler);
    builder.addCase(postErrorLogs.fulfilled, genericFullfilledCaseHandler);
    builder.addCase(postErrorLogs.rejected, genericRejectedCaseHandler);
  },
});

export const {
  setlanguage,
  setEmailRedirectUrl,
  setAdminRedirectUrl,
  resetEmailRedirectionUrl,
  resetAdminRedirectUrl,
} = commonSlice.actions;

export default commonSlice.reducer;

// selectors
export const selectAppLanguage = (state: RootState) => state.common.appLanguage;
export const selectAppLanguageByCode = (state: RootState) =>
  state.common.appLanguage.code;
export const selectAppLanguageByLanguage = (state: RootState) =>
  state.common.appLanguage.language;

export const selectEmailRedirectUrl = (state: RootState) =>
  state.common.redirectFrom?.email;
export const selectAdminRedirectUrl = (state: RootState) =>
  state.common.redirectFrom.admin;

// Timezones List
export const timezonesSelector = (state: RootState) => state.common.timezones;

// KYC
export const kycDataSelector = (state: RootState) => state.common.kyc.data;
