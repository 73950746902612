import colors from "../materials/colors";
const theme = {
  spacing: [0, 4, 8],
  colors: {
    base: {
      black: colors.black,
      white: colors.white,
      red: colors.red,
      blue: colors.blue,
      transparent: colors.transparent,
      yellow: colors.yellow,
      grey: colors.grey,
      darkGrey: colors.darkGrey,
      lightGrey: colors.lightGrey,
      veryLightGrey: colors.veryLightGrey,
      veryVeryLightGrey: colors.veryVeryLightGrey,
      veryDarkGrey: colors.veryDarkGrey,
      fieldSetBorderColor: colors.fieldSetBorderColor,
      fieldSetBlueColor: colors.fieldSetBlueColor,
      darkBlue: colors.darkBlue,
      lightBlue: colors.lightBlue,
      veryLightBlue: colors.veryLightBlue,
      textColor: colors.textColor,
      bannnerFirstColor: colors.bannnerFirstColor,
      bannerSecondColor: colors.bannerSecondColor,
      bannerThirdColor: colors.bannerThirdColor,
      gradientDarkBlueColor: colors.gradientDarkBlueColor,
      gradientLightBlueColor: colors.gradientLightBlueColor,
      hoverTextFieldColor: colors.hoverTextFieldColor,
      buttonGradientFirstColor: colors.buttonGradientFirstColor,
      buttonGradientSecondColor: colors.buttonGradientSecondColor,
      buttonGradientThirdColor: colors.buttonGradientThirdColor,
      borderColor: colors.borderColor,
      bannerbackground: colors.bannerbackground,
      backgroundColorofList: colors.backgroundColorofList,
      scrollerColor: colors.scrollerColor,
      green: colors.green
    },
  }
};
export default theme;
