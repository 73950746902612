import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface IProps {}

const LoadingSpinner = (props: IProps) => {
  return (
    <div className="loading">
      <Loader type="ThreeDots" color="#2660AD" height={100} width={100} />
    </div>
  );
};

export default LoadingSpinner;
