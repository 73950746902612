const JewelleryType = [
    {
        id: '1',
        image: {
            colored: 'bracelet.png',
            grey: 'braceletwhite.png'
        },
        name: 'Bracelets',
        value: "Bracelets",
        selectedValue: false
    }, 
    {
        id: '2',
        image: {
            colored: 'brooches.png',
            grey: 'broocheswhite.png'
        },
        name: 'Brooches & Pins',
        value: "Brooches&Pins",
        selectedValue: false
    },
    {
        id: '3',
        image: {
            colored: 'pendants.png',
            grey: 'pendantswhite.png'
        },
        name: 'Pendants & Charms',
        value: "Pendants&Charms",
        selectedValue: false
    },
    {
        id: '4',
        image: {
            colored: 'cufflinks.png',
            grey: 'cufflinkswhite.png'
        },
        name: 'Cuff Links',
        value: "Cuff Links",
        selectedValue: false
    },
    {
        id: '5',
        image: {
            colored: 'earrings.png',
            grey: 'earringswhite.png'
        },
        name: 'Earrings',
        value: "Earrings",
        selectedValue: false
    },
    {
        id: '6',
        image: {
            colored: 'necklace.png',
            grey: 'necklacewhite.png'
        },
        name: 'Necklaces',
        value: "Necklaces",
        selectedValue: false
    },
    {
        id: '7',
        image: {
            colored: 'Rings.png',
            grey: 'Ringswhite.png'
        },
        name: 'Rings',
        value: "Rings",
        selectedValue: false
    },
    {
        id: '8',
        image: {
            colored: 'Watches.png',
            grey: 'Watcheswhite.png'
        },
        name: 'Watches',
        value: "Watches",
        selectedValue: false
    },
    {
        id: '9',
        image: {
            colored: 'Others.png',
            grey: 'Otherswhite.png'
        },
        name: 'Others',
        value: "Other",
        selectedValue: false
    }
];

export default JewelleryType;