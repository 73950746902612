import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextField } from "@material-ui/core";
import { notifyError } from "../../../common/elements/AlertNotify";
import { convertProductPrice, getDetailsDescription } from "../TableProps";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { negotiateHoldProduct } from "../../../redux/slice/pendingProductteppo/pendingProductsSlice";
import {
  StyledButton,
  StyledAuctionEndedWrapper,
  StyledTextWrapper,
  StyledWrapper,
  StyledComponent,
  StyledDescription,
  StyledAlertMsg,
  StyledLabelConversion,
  StyledAmountField,
} from "./NegotiatePopupStyle";
import ProductDescription from "../../shared/ProductDescription";


interface IProps {
  closeModal: () => void;
  negotiableProductDetails: any;
}

const NegotiateConfirmation = (props: IProps) => {
  const { t } = useTranslation();
  const { negotiableProductDetails } = props;
  const [userNagotiatePrice, setUserNagotiatePrice] = useState("");
  const [description, setDescription] = useState("");
  const [biddingPrice, setBiddingPrice] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [productId, setProductId] = useState("");
  const [auctionId, setAuctionId] = useState("");
  const [message, setMessage] = useState<string>("");
  const [exchangeRate, setExchangeRate] = useState<any>();
  const [negotiablePriceConversion, setNegotiablePriceConversion] =
    useState<string>("");

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    getNegotiableProductDetails(negotiableProductDetails);
  }, [negotiableProductDetails]);

  const getNegotiableProductDetails = (data: any) => {
    if (data !== undefined || data !== null) {
      const { currentBidPrice, auctionId, description, perId, caratSellingPrice, exchangeRates } = data;
      setExchangeRate(exchangeRates);
      setDescription(description);
      setBiddingPrice(currentBidPrice);
      setSellingPrice(caratSellingPrice);
      setProductId(perId);
      setAuctionId(auctionId);
    }
  };

  const handleChange = (e: any) => {
    const targeName = e.target.name;
    const targeValue = e.target.value;
    let userCurrencyCode = localStorage.getItem("preferredCurrency");
    let userCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

    if (targeName === "negotiationPrice") {
      setUserNagotiatePrice(targeValue);
    }
    const { currency, exchangeRates } = negotiableProductDetails;
    if (userCurrencyCode !== currency['code']) {
      let conversion = new Intl.NumberFormat('us-en').format(Math.round(Number(e.target.value) * exchangeRates[0]['rate']));
      setNegotiablePriceConversion(`${userCurrencySymbol}${conversion}`);
    } else {
      setNegotiablePriceConversion("")
      // let conversion = new Intl.NumberFormat('us-en').format(
      //   Math.round(Number(e.target.value) * exchangeRates[0]["rate"])
      // );
      // setNegotiablePriceConversion(`${userCurrencySymbol}${conversion}`);
    }
  };

  const handleValidations = () => {
    if (userNagotiatePrice === "") {
      setMessage(`${t("common:pendingProduct:text:enterNegotiateValue")}`);
    } else if (userNagotiatePrice < biddingPrice) {
      setMessage(`${t("common:pendingProduct:text:enterNegotiateValuegreater")}`);
    } else {
      setMessage('');
      return true;
    }
    return false;
  };

  const handleFormSubmit = async (e: any) => {
    e.preventDefault();
    if (handleValidations()) {
      setMessage("");
      const formData = {
        negotiatePrice: userNagotiatePrice,
        productDescription: getDetailsDescription(negotiableProductDetails, t),
        perId: productId,
        accept: false,
        auctionId: auctionId,
      };

      const resultAction: any = await dispatch(negotiateHoldProduct(formData));
      if (negotiateHoldProduct.fulfilled.match(resultAction)) {
        closeModal();
      } else if (resultAction.payload) {
        closeModal();
        const { isMultipleErrors, errorMessageKey } = resultAction.payload;
        if (!isMultipleErrors) {
          setMessage(t(`${errorMessageKey}`));
        } else {
          notifyError(t("common:somethingWentWrong"));
        }
      }
    }
  };

  const { closeModal } = props;

  const onClickOkButton = () => {
    closeModal();
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledWrapper container spacing={0}>
        <StyledAuctionEndedWrapper item md={12} xs={12} lg={12}>
          <StyledTextWrapper>
            {t("common:pendingProduct:text:descriptionFirstText")}{" "}
            <ProductDescription
              truncateLength={70}
              data={negotiableProductDetails}
              isTendor={true}
            />
            {t("common:pendingProduct:text:descriptionSecondText")}
            <StyledDescription>
              {" "}
              {convertProductPrice(negotiableProductDetails, biddingPrice)}{" "}
            </StyledDescription>
            {t("common:pendingProduct:text:descriptionThird")}
            <StyledDescription>
              {convertProductPrice(negotiableProductDetails, sellingPrice)}
            </StyledDescription>
            .
          </StyledTextWrapper>
          <StyledAmountField>
            <StyledTextWrapper>
              <TextField
                required
                fullWidth
                name="negotiationPrice"
                id="negotiationPrice"
                label={t("common:pendingProduct:text:enterNegotiateValue")}
                type="number"
                variant="outlined"
                value={userNagotiatePrice}
                onChange={handleChange}
              />
            </StyledTextWrapper>
            <StyledLabelConversion>
              {negotiablePriceConversion}
            </StyledLabelConversion>
          </StyledAmountField>
          <StyledAlertMsg>{message}</StyledAlertMsg>
          <div style={{ display: "flex" }}>
            <StyledButton onClick={handleFormSubmit}>
              {t("common:pendingProduct:button:ok")}
            </StyledButton>
            <StyledButton onClick={onClickOkButton}>
              {t("common:pendingProduct:button:cancel")}
            </StyledButton>
          </div>
        </StyledAuctionEndedWrapper>
      </StyledWrapper>
    </StyledComponent>
  );
};

export default React.forwardRef(NegotiateConfirmation);
