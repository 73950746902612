import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Header from "../LandingPage/Header/Header";
import ProductList from "../LandingPage/ProductCard/ProductList";
import { IProductCardProps } from "../LandingPage/ProductCard/ProductCard";
import AdContainer from "../LandingPage/Ads/Ads";
import { DialogProvider } from "../../common/elements/Dialog/Dialog";
import Section from "../LandingPage/Section/Section";
import {
  getAdverstisements,
  getEndedProducts,
  getProducts,
} from "../LandingPage/Services";
import Filters from "./Filters";
import { demoProducts, removeRedirectPath } from "../LandingPage/Utils";
import AdsList from "../LandingPage/Ads/AdsList";
import { useMediaQuery } from "@material-ui/core";
import Footer from "../LandingPage/Footer/Footer";
import MobileFilters from "./MobileFilters";
import ScrollFix from "../../components/ScrollFix/ScrollFix";

const AuctionList = (props: any) => {
  const { t, match } = props;
  const { params } = match;
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [productsList, setProductList] = useState([] as IProductCardProps[]);
  const [adImageSources, setAdImageSources] = useState([]);
  const listRef = useRef([] as IProductCardProps[]);
  const [isLiveAuction, setLiveAuction] = useState(false);

  const [values, setValues] = React.useState([0, 0] as number[]);

  const getProductsList = async () => {
    const type = params["auctionType"];
    const list = await (type === "live" ? getProducts : getEndedProducts)();
    listRef.current = list.results;
    setProductList(list.results);
  };

  const getAdvertisementsList = async () => {
    const AdSource = `${params["auctionType"]}_auction_products`;
    const { response } = await getAdverstisements();
    const adSources = response
      .filter((ad: { section: string }) =>
        ad.section?.toLocaleLowerCase().includes(AdSource)
      )
      .map((adSource: { link: string; media: { presignedUrl: any } }) => ({
        imgSrc: adSource?.media?.presignedUrl,
        link: adSource?.link,
      }));
    const imageSources = adSources;
    setAdImageSources(imageSources);
  };

  useEffect(() => {
    getProductsList();
    setProductList(demoProducts);
  }, []);

  useEffect(() => {
    getAdvertisementsList();
  }, []);

  useEffect(() => {
    const isLiveAuction = params["auctionType"].includes("live");
    setLiveAuction(isLiveAuction);
  }, []);

  useEffect(removeRedirectPath, []);

  useEffect(() => {
    if (productsList.length) {
      // const min = Math.min.apply(
      //   Math,
      //   productsList.map((prod) => Number(prod.caratMinimumPrice))
      // );
      const max = Math.max.apply(
        Math,
        productsList.map((prod) => Number(prod.caratMinimumPrice))
      );

      // console.log([min, max]);
      setValues([max - 1000, max]);
    }
  }, [productsList]);

  const handleFiltersChange = (price: any, item: any) => {
    if (price === "" && item === "") {
      setProductList(listRef.current);
      return;
    }
    let filteredProductList = [...listRef.current];
    //filter on basis of item
    if (item) {
      filteredProductList = filteredProductList.filter(
        (product) => product.parcelType === item
      );
    }

    // sort on basis of price
    if (price !== "") {
      const ascending = price === "Low to High";
      const metric = params["auctionType"].includes("previous")
        ? "currentBidPrice"
        : "caratMinimumPrice";
      filteredProductList = filteredProductList.sort((a, b) => {
        return ascending ? a[metric] - b[metric] : b[metric] - a[metric];
      });
    }
    setProductList([...filteredProductList]);
  };

  return (
    <DialogProvider>
      <ScrollFix />
      <Grid container className="mainContainer">
        <Grid container item xs={12}>
          <Header />
        </Grid>
        {isMobile ? null : (
          <Section container>
            <Filters onChange={handleFiltersChange} values={values} isZipang={false} />
          </Section>
        )}
        <Section
          container
          isMobile={isMobile}
          label={`${t("common:" + params["auctionType"])} ${t("common:auc")}`}
          alignItems="flex-start"
          LinkComponent={() =>
            isMobile ? <MobileFilters onChange={handleFiltersChange} isZipang={false} /> : null
          }
        >
          <Grid xs={12} sm={9} item>
            <ProductList
              hideBidButton={!isLiveAuction}
              fixedHeightCards
              isMobile={isMobile}
              ads={adImageSources}
              productsList={productsList}
              isZipang={false}
              isLandingPage={true}
            />
          </Grid>
          {isMobile ? null : (
            <Grid xs={3} item>
              <AdsList adsList={adImageSources} />
            </Grid>
          )}
        </Section>
      </Grid>
      <Footer />
    </DialogProvider>
  );
};

export default AuctionList;
