const JewelleryCondition = [
    {
        id: '1',
        name: 'N',
        selectedValue: false
    }, {
        id: '2',
        name: 'S',
        selectedValue: false
    }, {
        id: '3',
        name: 'A',
        selectedValue: false
    }, {
        id: '4',
        name: 'AB',
        selectedValue: false
    }, {
        id: '5',
        name: 'B',
        selectedValue: false
    }, {
        id: '6',
        name: 'BC',
        selectedValue: false
    }, {
        id: '7',
        name: 'C',
        selectedValue: false
    }, {
        id: '8',
        name: 'D',
        selectedValue: false
    }
];

export default JewelleryCondition;