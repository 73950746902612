import React, { ChangeEvent, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {
  tokenUrl,
  joinedAuctionsUrl,
  clockNotInSyncUrl,
  sendLoginOTP,
} from "../../../common/config/app.endpoints";
import LoadingSpinner from "../../../common/elements/LoadingSpinner";
import { getTime, getProfileData } from "../../../common/GenericFunctions";
import {
  StyledTextInput,
  StyledComponent,
} from "../../../common/materials/GenericStyleComponent";
import {
  dateBackgroundGradient,
  buttonGradient,
} from "../../../common/materials/LinearGradient";
import AuthService from "../../../components/auth/AuthService";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Grid } from "@material-ui/core";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { getLiveNotificationsFun } from "../../../redux/slice/notificationLive/notificationLiveSlice";

const faEyeProps = faEye as IconProp;
const eye = <FontAwesomeIcon icon={faEyeProps} />;

const StyledLoginContainer = styled.div`
  border-radius: 10px;
  background: ${(props) => props.theme.colors.base.white};
  width: 300px;
  padding: 1em;
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    width: 300px;
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    width: 400px;
  }
  ${(props) => props.theme.breakpoints.between("md", "xl")} {
    width: 480px;
  }
  .isLogin {
    cursor: default;
    background: ${dateBackgroundGradient};
  }
`;

const StyledButton = styled.button`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.6em;
  margin: 0.3em;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  background: ${buttonGradient};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;

const StyledMessage = styled.div`
  color: ${(props) => props.theme.colors.base.red};
  font-size: 1em;
  margin-left: 1.2em;
  margin-right: 1.2em;
`;

const StyledPasswordCon = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 14px;
  input {
    padding-right: 2em !important;
  }
  i {
    position: absolute;
    top: 34%;
    right: 2%;
    cursor: pointer;
  }
`;

const StyledLogoWrapper = styled.div`
  ${(props) => props.theme.breakpoints.between("xs", "sm")} {
    img {
      width: 7%;
    }
  }
  ${(props) => props.theme.breakpoints.between("sm", "md")} {
    img {
      width: 7%;
    }
  }
  ${(props) => props.theme.breakpoints.between("lg", "xl")} {
    img {
      width: 7%;
    }
  }
  display: flex;
  gap: 5px;
`;

const StyledLogoImage = styled.img`
  height: 1.2em;
  width: 0.8em;
`;

const StyledTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-family: "Calibre-Regular";
  font-size: 1.2em;
`;
const StyledotpTextContainer = styled.span`
  background: ${(props) => props.theme.colors.base.transparent};
  color: #4a95f0;
  font-family: "Calibre-Regular";
  font-size: 1em;
  cursor: pointer;
`;
const StyledInfoWrapper = styled(Grid)`
  padding: 0.5em 1em;
`;

const StyledBottomButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

interface IProps {
  I18n?: {};
  isFromSubDomain: boolean;
  closeModal: () => void;
  renderOTPPopup: () => void;
  subdomainfunc: (value: any) => void;
}

const LoginWithEmail = (props: IProps) => {
  const { closeModal, renderOTPPopup } = props;
  const { t } = useTranslation();
  let history = useHistory();

  const [email, setEmail] = useState<string>("");
  const [splitEmail, setSplitEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const Auth = new AuthService();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userName: any = urlParams.get("un");
    if (userName !== null || userName !== undefined) {
      let arr = userName.split("jx4jxwfsaz8vjmen7ngs");
      if (arr[1] !== undefined) {
        setSplitEmail(arr[1]);
      } else {
        setSplitEmail(userName);
      }
      setEmail(userName);
    } else {
      setEmail("");
    }
    // if (urlParams.get("email") !== undefined) {
    //   let emailchangePlan: any = urlParams.get("email");
    //   setEmail(emailchangePlan);
    // }
  }, []);

  const resetValue = () => {
    let email = document.getElementById("user_email") as HTMLInputElement;
    if (email) {
      email.value = "";
      setEmail("");
    }
    let password = document.getElementById("user_password") as HTMLInputElement;
    if (password) {
      password.value = "";
      setPassword("");
    }
  };

  const redirectToApp = () => {
    history.push("/home");
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
      localStorage.setItem("changePlanEmail", e.target.value);
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    const isEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(
      email
    );
    localStorage.setItem("changePlanEmail", email);
    if (email === "" && password === "") {
      setMessage(`${t("common:allValidations:emptyFields")}`);
    } else if (email === "") {
      setMessage(`${t("common:register:errorMessage:onEmail")}`);
    } else if (password === "") {
      setMessage(`${t("common:login:validation:invalidEmailorPassword")}`);
    } else {
      setIsLoading(true);
      const data = {
        username: email.trim(),
        password: password.trim(),
      };
      Auth.postRequestWithoutAuthorization(
        tokenUrl,
        data,
        (status: number, response: any) => {
          if (status === 200) {
            Auth.setToken(response.token);
            joinedAuction();
          } else if (status > 200 && status < 500) {
            if (response[0].code !== undefined) {
              if (response[0].code === "KB409001") {
                setMessage(
                  `${t("common:register:errorMessage:profileVerification")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB412004") {
                setMessage(
                  `${t("common:login:validation:invalidEmailorPassword")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB401002") {
                setMessage(
                  `${t("common:login:validation:registeredAsAuctioneer")}`
                );
                setIsLoading(false);
              } else if (response[0].code === "KB404001") {
                setMessage(`${t("common:login:validation:userNotRegistered")}`);
                setIsLoading(false);
              } else if (response[0].code === "KB401004") {
                setMessage(``);
                setIsLoading(false);
                props.subdomainfunc(response[0].field);
              } else if (response[0].code === "KB401005") {
                setMessage(`You don't have any invitation to access.`);
                setIsLoading(false);
              } else {
                setMessage(`${t("common:invalidRequest")}`);
                setIsLoading(false);
              }
            }
          } else if (status === 500) {
            setMessage(response[0].message);
            setIsLoading(false);
          }
          setIsLoading(false);
        }
      );
    }
  };

  const joinedAuction = () => {
    getTime();
    getProfileData();
    Auth.getRequest(joinedAuctionsUrl, (status: any, response: any) => {
      setIsLoading(false);
      let clockSynced = localStorage.getItem("clockNotSynced");
      if (clockSynced === "true") {
        let time: any = localStorage.getItem("timeInMilliSeconds");
        time = parseInt(time);
        Auth.postRequest(`${clockNotInSyncUrl}${time}`, "data", () => { });
      }
      if (status === 200) {
        if (response !== undefined) {
          localStorage.setItem("otpAuth", "false");
          localStorage.removeItem("changePlanEmail");
          var pathname = localStorage.getItem("pathname");
          if (response.results.length > 0) {
            const urlRedirectFromEmail = localStorage.getItem(
              "urlRedirectFromEmail"
            );
            const urlRedirectFromAdmin = localStorage.getItem(
              "urlRedirectFromAdmin"
            );
            const urlPendingProductFromEmail = localStorage.getItem(
              "urlPendingProductFromEmail"
            );
            const urlProductAuctionFromEmail = localStorage.getItem(
              "urlProductAuctionFromEmail"
            );

            if (
              urlRedirectFromEmail !== undefined &&
              urlRedirectFromEmail !== "" &&
              urlRedirectFromEmail !== null
            ) {
              pathname = urlRedirectFromEmail;
            }
            if (
              urlRedirectFromAdmin !== undefined &&
              urlRedirectFromAdmin !== "" &&
              urlRedirectFromAdmin !== null
            ) {
              pathname = urlRedirectFromAdmin;
            }

            if (
              urlPendingProductFromEmail !== undefined &&
              urlPendingProductFromEmail !== "" &&
              urlPendingProductFromEmail !== null
            ) {
              pathname = urlPendingProductFromEmail;
            }

            if (
              urlProductAuctionFromEmail !== undefined &&
              urlProductAuctionFromEmail !== "" &&
              urlProductAuctionFromEmail !== null
            ) {
              pathname = urlProductAuctionFromEmail;
            }
            if (
              urlProductAuctionFromEmail !== undefined &&
              urlProductAuctionFromEmail !== "" &&
              urlProductAuctionFromEmail !== null
            ) {
              pathname = urlProductAuctionFromEmail;
            }

            let isSubDomainUser = localStorage.getItem("subDomain");
            let redireactionalUrl = localStorage.getItem("pathname");
            let isSubdomainPreview = `/${isSubDomainUser}/preview` === redireactionalUrl;

            if (isSubdomainPreview) {
              pathname = `/preview`;
            }
            var redirectPath = pathname;
            if (redirectPath) {
              history.push(redirectPath);
            } else {
              if (pathname) {
                const pathArray = pathname.split("/");
                if (
                  pathArray[1].includes("KycForm") ||
                  pathArray[1].includes("PaymentForm") ||
                  pathArray[1].includes("paymentForm")
                ) {
                  redirectPath = "/";
                  localStorage.setItem("pathname", redirectPath);
                }
              }
              // history.push(redirectPath);
              redirectToApp();
            }
          } else {
            if (pathname === "/365products") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/jewellery") {
              redirectPath = "/365products/jewellery";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/Diamonds") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if (pathname === "/365products/Diamond") {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else if ((pathname = `/preview`)) {
              redirectPath = "/365products";
              localStorage.setItem("pathname", redirectPath);
            } else {
              redirectToApp();
            }
          }
          resetValue();
        }
      } else {
        history.push("/home");
      }
      localStorage.removeItem("optionValue");
      //window.location.reload();
    });
  };

  const togglePasswordVisiblity = () => {
    setShowPassword(!showPassword);
  };

  const callRederOtpPopup = async () => {
    Auth.postRequestWithoutAuthorization(
      `${sendLoginOTP}?un=${email}`,
      "data",
      (status: number, response: any) => {
        if (status === 200) {
          // resetValue();
        } else if (status > 200 && status < 500) {
          if (response[0].code === "KB404001") {
            setIsLoading(false);
            setMessage(
              `${t(
                "common:profile:forgotPassword:validation:notRegisteredEmail"
              )}`
            );
          }
        } else {
          setIsLoading(false);
        }
      }
    );
    const result = await closeModal();
  };

  return (
    <StyledComponent tabIndex={-1}>
      <StyledLoginContainer>
        <StyledInfoWrapper>
          <StyledLogoWrapper>
            <StyledLogoImage src="/favicon.ico" />
            <StyledTextContainer>
              {t("common:header:logoText")}
            </StyledTextContainer>
          </StyledLogoWrapper>
        </StyledInfoWrapper>
        <form noValidate={true} onSubmit={handleFormSubmit}>
          <StyledMessage>{message}</StyledMessage>
          <StyledTextInput>
            <TextField
              disabled
              fullWidth
              name="email"
              autoFocus={true}
              id="email"
              className="emailBackground"
              label={t("common:profile:legends:email")}
              type="text"
              variant="outlined"
              value={splitEmail}
            />
          </StyledTextInput>
          <StyledTextInput>
            <StyledPasswordCon className="pass-wrapper">
              <TextField
                required
                fullWidth
                name="password"
                id="user_password"
                label={t("common:login:labels:password")}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={handleChange}
              />
              <i onClick={togglePasswordVisiblity}>{eye}</i>
            </StyledPasswordCon>
          </StyledTextInput>
          <StyledBottomButton>
            <StyledInfoWrapper
              onClick={() => {
                renderOTPPopup();
                callRederOtpPopup();
              }}
            >
              <StyledotpTextContainer>
                {t("common:button:otpSignIn")}
              </StyledotpTextContainer>
            </StyledInfoWrapper>
            <div style={{ display: "flex" }}>
              {isLoading === false ? (
                <StyledButton onSubmit={handleFormSubmit}>
                  {t("common:button:signIn")}
                </StyledButton>
              ) : (
                <StyledButton className="onClickButton">
                  {t("common:button:signIn")}
                </StyledButton>
              )}
            </div>
          </StyledBottomButton>
        </form>
        {isLoading === true && (
          <div className="loading">
            <LoadingSpinner />
          </div>
        )}
      </StyledLoginContainer>
    </StyledComponent>
  );
};

export default LoginWithEmail;
