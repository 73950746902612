import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

import EllipsisText from "../../../../common/elements/EllipsisText";
import ItemDetail, { ItemDetailWithSignIn } from "./ItemDetail";
import { getNumberFormat } from "../../../../services/NumberFormatService";

const StyledItemGrid = styled(Grid)``;

let prebidAmount: string = "";
let startingAmount: string = "";
let conversionStartingAmount: string = "";
let minimumAmount: string = "";
let conversionMinimumAmount: string = "";

let jewelleryType: string | null = "";
let jewelleryCondition: string | null = "";
let jewelleryDescription: string | null = "";

interface IProps {
  isPDView: boolean;
  auctionDate: string;
  productData: any;
  onClickSignIn: () => void;
}

const JewelleryDetails = (props: IProps) => {
  const { isPDView, auctionDate, productData, onClickSignIn } = props;

  // selectors
 let preferredCurrency = localStorage.getItem("preferredCurrency");
 let preferredCurrencySymbol = localStorage.getItem("preferredCurrencySymbol");

  const {
    serialNo,
    code,
    caratMinimumPrice,
    startingPrice,
    preBidPrice,
    remarks,
    noOfPieces,
    currency,
    exchangeRates,
    jewelleryDetails,
  } = productData;

  const userCurrencyCode = preferredCurrency;
  const userCurrencySymbol = preferredCurrencySymbol;

  useEffect(() => {
    prebidAmount = "";
  }, []);

  startingAmount = getNumberFormat(startingPrice);
  minimumAmount = getNumberFormat(caratMinimumPrice);
  prebidAmount = getNumberFormat(preBidPrice);

  if (prebidAmount === "0" || prebidAmount === "NaN") {
    prebidAmount = "";
  }

  const isJewelleryDetailsavailable = () => {
    return jewelleryDetails !== undefined && jewelleryDetails !== null;
  };

  const checkProperty = (property: string) => {
    return (
      jewelleryDetails[property] !== undefined &&
      jewelleryDetails[property] !== null
    );
  };

  if (isJewelleryDetailsavailable()) {
    if (checkProperty("jewelleryType")) {
      jewelleryType = jewelleryDetails.jewelleryType;
    }
    if (checkProperty("jewelleryCondition")) {
      jewelleryCondition = jewelleryDetails.jewelleryCondition;
    }
    if (checkProperty("jewelleryDescription")) {
      jewelleryDescription = jewelleryDetails.jewelleryDescription;
    }
  }

  const checkAmount = (amount: any) => {
    return amount === null || amount === "NaN" || amount === "0";
  };

  if (checkAmount(startingAmount)) {
    startingAmount = "-";
  } else {
    if (userCurrencyCode != currency["code"]) {
      let conversionPriceS = getNumberFormat(
        Math.round(startingPrice * exchangeRates[0]["rate"])
      );
      conversionStartingAmount = `(${userCurrencySymbol}${conversionPriceS})`;
    } else {
      conversionStartingAmount = "";
    }
    startingAmount = `${currency["symbol"]}${startingAmount}`;
  }

  if (checkAmount(minimumAmount)) {
    minimumAmount = "-";
  } else {
    if (userCurrencyCode != currency["code"]) {
      let conversionPrice = getNumberFormat(
        Math.round(caratMinimumPrice * exchangeRates[0]["rate"])
      );
      conversionMinimumAmount = `(${userCurrencySymbol}${conversionPrice})`;
    } else {
      conversionMinimumAmount = "";
    }
    minimumAmount = `${currency["symbol"]}${minimumAmount} ${conversionMinimumAmount}`;
  }

  const renderLeftCol = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:serialNo"}
          value={serialNo}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:productDescription"}
          value={""}
        >
          {jewelleryDescription && (
            <EllipsisText
              text={jewelleryDescription ? jewelleryDescription : ""}
              length={70}
              tail={"..."}
            />
          )}
        </ItemDetail>
        <ItemDetailWithSignIn
          isPDView={isPDView}
          label={"common:preview:perItemDescription:jewelleryStartingPrice"}
          value={`${minimumAmount} ${" "}`}
          onClickSignIn={onClickSignIn}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:jewelleryType"}
          value={jewelleryType}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:noOfPieces"}
          value={noOfPieces === 0 ? "-" : noOfPieces}
        />
      </>
    );
  };

  const renderRightCol = () => {
    return (
      <>
        <ItemDetail
          label={"common:preview:perItemDescription:auctionStartDate"}
          value={auctionDate}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:itemCode"}
          value={code}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:jewelleryCondition"}
          value={jewelleryCondition}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:exchangeRate"}
          value={exchangeRates === undefined ? 0: exchangeRates[0]["rate"]}
        />
        <ItemDetail
          label={"common:preview:perItemDescription:remarks"}
          value={remarks}
        >
          {remarks && <EllipsisText text={remarks} length={70} tail={"..."} />}
        </ItemDetail>
      </>
    );
  };

  return (
    <StyledItemGrid container={true} spacing={0}>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {renderLeftCol()}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {renderRightCol()}
      </Grid>
    </StyledItemGrid>
  );
};

export default JewelleryDetails;
