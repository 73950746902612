import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import api from "../../../services/ApiService";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import { clockNotInSyncUrl } from "../../../common/config/app.endpoints";

interface INotifyClockNotInSyncRequest {
  time: number;
}

const initialState = {
  loading: false,
  error: "",
};

export const notifyClockNotInSync = createAsyncThunk<
  INotifyClockNotInSyncRequest,
  Partial<INotifyClockNotInSyncRequest>,
  { rejectValue: any }
>("notification/notifyClockNotInSync", async (data, { rejectWithValue }) => {
  try {
    const { time } = data;
    const response = await api.post(`${clockNotInSyncUrl}${time}`);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // notifyClockNotInSync
    builder.addCase(notifyClockNotInSync.pending, genericPendingCaseHandler);
    builder.addCase(
      notifyClockNotInSync.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(notifyClockNotInSync.rejected, genericRejectedCaseHandler);
  },
});

export default notificationSlice.reducer;

// selectors
export const selectNotificationLoading = (state: RootState) =>
  state.notification.loading;
