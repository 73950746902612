import React, { useEffect } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';

const StyledComponent = styled(Grid)`
    outline: none;
    .secondaryLabel{
        font-size: 2em;
        font-weight: bold;
        padding-bottom: 20px;
        color: ${(props) => props.theme.colors.base.buttonGradientFirstColor}; 
    }
`;

const StyledWrapper = styled(Grid)`
    background: ${(props) => props.theme.colors.base.white}; 
    width: 300px;
    text-align:center;
    padding: 2em;
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 400px;
    }
`;

const StyledTextWrapper = styled.div`
    font-size: 3em;
    font-family: Calibre-Regular;
    color: ${(props) => props.theme.colors.base.grey}
`;

const StyledSoldOutWrapper = styled(Grid)`
    text-align: center;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        padding: 1em 1em;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        padding: 1em 2em;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        padding: 1em 2em;
    }
`;

export interface IProps {
    t: any;
    closeModal: () => void;
    currentBidPrice: any;
    auctionCurrency: any;
}

const ViewSoldOutModal = React.forwardRef((props: IProps, ref) => {
    const { t, closeModal, currentBidPrice, auctionCurrency } = props;
    
    useEffect(() => {
        setTimeout(() => {
            closeModal();
        }, 1800);
    });

    return (
        <StyledComponent tabIndex={-1}>
            <StyledWrapper container spacing={0}>
                <StyledSoldOutWrapper item md={12} xs={12} lg={12}>
                    <StyledTextWrapper className="fontBold">{t('common:home:modal:soldOut')}</StyledTextWrapper>
                    <div className="secondaryLabel">@</div>
                    <div className="secondaryLabel"> <span>{auctionCurrency}</span><span>{currentBidPrice}</span></div>
                </StyledSoldOutWrapper>
            </StyledWrapper>
        </StyledComponent>
    );
});

export default ViewSoldOutModal;