import React from "react";
import Grid from "@material-ui/core/Grid";
import { StyledLayoutGrid } from "../../365Auction/component/DiamondProductStyle";
import NewDiamondDetailsContainer from "./container/NewDiamondDetailsContainer";
import AppLayout from "../../layouts/AppLayout";
import { useTranslation } from "react-i18next";

const NewProductDetailsPage = (props: any) => { 
  let {t} = useTranslation()
  return (
    <AppLayout t={t} {...props}>
      <StyledLayoutGrid
        container={true}
        spacing={0}
        className="previewProductDetailsPage"
      >
        <Grid item={true} xs={12}>
          <NewDiamondDetailsContainer redirectFromPage={"/preview"} />
        </Grid>
      </StyledLayoutGrid>
    </AppLayout>
  );
};

export default NewProductDetailsPage;
