import React from 'react';
import Grid from '@material-ui/core/Grid';

import { StyledLegendField, StyledTextWrapper, StyledFieldLegend } from '../../common/materials/GenericStyleComponent';

interface IProps {
    t: any;
    email: string;
    companyName: string;
    phone: string;
    timezone: any;
    modeOfCommunication: [];
    address: string;
    guarantorAddress: string;
    guarantorName: string;
    countryDetail: any;
    preferredCurrency: any;
}

const SDUserPersonalDetails = (props: IProps) => {
    const { t, email, companyName, phone, timezone, modeOfCommunication, address, guarantorAddress, guarantorName, countryDetail, preferredCurrency } = props;
    return (
        <Grid item xs={12}>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:email')} :</StyledFieldLegend>
                <StyledTextWrapper>{email}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:companyName')} :</StyledFieldLegend>
                <StyledTextWrapper>{companyName}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:companyAddress')} :</StyledFieldLegend>
                <StyledTextWrapper>{address}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:referenceName')} :</StyledFieldLegend>
                <StyledTextWrapper>{guarantorName}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:referenceAddress')} :</StyledFieldLegend>
                <StyledTextWrapper>{guarantorAddress}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:contactNo')} :</StyledFieldLegend>
                <StyledTextWrapper>{phone}</StyledTextWrapper>
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:register:labels:country')} :</StyledFieldLegend>
                {countryDetail !== undefined &&
                    <StyledTextWrapper>{countryDetail.name}</StyledTextWrapper>
                }
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:kycFrom:currency')} :</StyledFieldLegend>
                {preferredCurrency !== undefined &&
                    <StyledTextWrapper>{preferredCurrency.name}</StyledTextWrapper>
                }
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:timezone')} :</StyledFieldLegend>
                {timezone !== undefined &&
                    <StyledTextWrapper>{timezone.name}</StyledTextWrapper>
                }
            </StyledLegendField>
            <StyledLegendField>
                <StyledFieldLegend>{t('common:profile:legends:modeOfContact')} :</StyledFieldLegend>
                {modeOfCommunication !== undefined &&
                    <StyledTextWrapper>{modeOfCommunication.join(' & ')}</StyledTextWrapper>
                }
            </StyledLegendField>
        </Grid>
    );
}

export default SDUserPersonalDetails;