import React, { useEffect, useState } from "react";
import styled from "styled-components";

import AppLayout from "../components/layouts/AppLayout";
import IRouterComponentProps from "../models/IRouterComponentProps";
import ApiTimeout from "./ApiTimeout";
import AuthService from "../components/auth/AuthService";
import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from "mui-datatables";
import { oAuth } from "../routes/basic/BasicAppRouter";
import { getSupportApiData } from "../common/config/app.endpoints";
import { StyledModal } from "../common/materials/GenericStyleComponent";
import Alert from "../common/elements/Alert";
import ReplyPopup from "./ReplyPopup";
import ReplyIcon from "@material-ui/icons/Reply";
import GenInquiry from "../components/products/details/inquiry/GenInquiry";
import {
  StyledLayoutGrid,
  StyledLink,
  StyledReplyBox,
  StyledTimeGridContainer,
} from "../components/products/details/inquiry/InquiryDetailsCss";
import EllipsisText from "../common/elements/EllipsisText";
import AttachmentPopup from "../components/modals/AttachmentPopup";

interface IButtonProps {
  isSubmit?: boolean;
}

const StyledButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.base.white};
  border: none;
  font-size: 1em;
  padding: 0.5em;
  border-radius: 5px;
  // width: 100%;
  background: ${(props) => props.theme.colors.base.buttonGradientFirstColor};
  border: 1px solid ${(props) => props.theme.colors.base.white};
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.base.textColor};
    cursor: pointer;
  }
`;
interface IPreviewProps extends IRouterComponentProps {
  t: any;
}

const columnIndexes = {
  section: 0,
  query: 1,
  attachment: 2,
  status: 3,
  auction: 4,
  comment: 5,
  id: 6,
  fromUniqueId: 7,
  medias: 8,
  auctionId:9,
  auctionName:10,
  productCode:11
};

let downloadIndex = 0;
let SelectedTableData: any;

const Inquiry = React.forwardRef((props: IPreviewProps, ref) => {
  const [apiTimeout, setApiTimeout] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userBlock, setUserBlock] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState<boolean>(false);
  const [selectedAttachment, setSelectedAttachment] = useState<any>({});

  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [openReplyPopup, setReplyPopup] = useState(false);
  const [comment, setComment] = useState([]);
  const [openInquiryPopup, setInquiryyPopup] = useState(false);
  const [userQueryId, setUserQueryId] = useState();
  const [useruniqId, setUseruniqId] = useState();
  const [columnTableData, setColumnTableData] = useState();
  const [tableData, setTableData] = useState<any[]>([]);
  const [commentIndex, setCommentIndex] = useState(0);

  const { t } = props;
  const Auth = new AuthService();

  useEffect(() => {
    getAllData();
  }, []);

  const closeModal = () => {
    Auth.logout();
    props.history.replace("/");
  };

  const getAllData = () => {
    if (!oAuth()) {
      props.history.push("/");
    } else {
      Auth.getRequest(
        `${getSupportApiData}`,
        (status: number, response: any) => {
          if (status === 200) {
            setIsLoading(false);
            setTableData([...response]);
          } else if (status === 403) {
            setUserBlock(true);
          } else if (status === 505) {
            setApiTimeout(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setTableData([]);
            setShowAlertMessage("error");
            setAlertType("error");
            setOpenToast(true);
          }
        }
      );
    }
  };

  const handleClick = (tableData: any) => {
    setColumnTableData(tableData);
    SelectedTableData = tableData.tableData;
    getAllDataCallBack(SelectedTableData);
    var comment = tableData.rowData[columnIndexes.comment];
    var userQueryId = tableData.rowData[columnIndexes.id];
    var fromUniqueIds = tableData.rowData[columnIndexes.fromUniqueId];
    setComment(comment);
    setUserQueryId(userQueryId);
    setUseruniqId(fromUniqueIds);
    setCommentIndex(tableData.rowIndex);
    
    setReplyPopup(true);
  };

  const getAllDataCallBack = (inquiryProduct: any) => {
    inquiryProduct.map((items: any, index: any) => {
      if (items.id === SelectedTableData[columnIndexes.id]) {
        setCommentIndex(index);
      }
    });
    return commentIndex;
  };

  const getAllDataCallBackfun = () => {
    getAllData();
  };

  const handleInquiryyPopup = () => {
    setInquiryyPopup(true);
  };

  const handleClosePopup = () => {
    setReplyPopup(false);
  };
  const handleClose = () => {
    setInquiryyPopup(false);
  };

  const handleReplyClick = (tableData: any) => {
    setInquiryyPopup(true);
  };

  const timeout = (timeout: boolean) => {
    if (timeout === true) {
      setApiTimeout(true);
    }
  };

  const attachmentUploadShow = (tableData: any) => {
    if (tableData.rowData[8] === undefined) {
      return `-`;
    } else {
      return (
        <span
          className="linkCss"
          onClick={() => {
            setSelectedAttachment({medias: tableData.rowData[columnIndexes.medias], rowData: tableData.rowData});
            setShowAttachmentDialog(true)}
          }
        >
         {t("common:inquiry:fields:AttachmentLink")}
        </span>
      );
    }
  };
 
  
  const actionReplyView = (tableData: any) => {
    if (tableData.rowData[columnIndexes.status] === "REPLIED") {
      return (
        <StyledLink onClick={() => handleClick(tableData)}>
          {t("common:inquiry:button:Reply")} <ReplyIcon />
        </StyledLink>
      );
    } else {
      return `-`;
    }
  };

  //Note: get Product Details with Auction name & product in section name 
  const sectionDetails = (tableData: any) => {
    if (tableData.rowData[columnIndexes.auctionId] !== undefined) {
      return (
        <StyledLink onClick={() => handleClick(tableData)}>
          {tableData.rowData[columnIndexes.auctionName]}_{tableData.rowData[columnIndexes.productCode]}
        </StyledLink>
      );
    } else {
      return (
        <StyledLink onClick={() => handleClick(tableData)}>
          {tableData.rowData[columnIndexes.section]}
        </StyledLink>
      );
    }
  };

  const getDescription = (description: any) => {
    return <EllipsisText text={description} length={25} tail={"..."} />;
    
  }
  const columns: MUIDataTableColumnDef[] = [
    {
      name: "section",
      label: `${t("common:inquiry:fields:Section")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return sectionDetails(tableMeta);
        },
      },
    },
    {
      name: "description",
      label: `${t("common:inquiry:fields:Query")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return getDescription(value);
        },
      },
    },
    {
      name: "attachment",
      label: `${t("common:inquiry:fields:Attachment")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return attachmentUploadShow(tableMeta);
        },
      },
    },
    {
      name: "status",
      label: `${t("common:inquiry:fields:Status")}`,
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "",
      label: `${t("common:inquiry:fields:Action")}`,
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          return actionReplyView(tableMeta);
        },
      },
    },
    {
      name: "comments",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "id",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "fromUniqueId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "medias",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionId",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "auctionName",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "productCode",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const options: MUIDataTableOptions = {
    filter: "false",
    download: "false",
    print: "false",
    viewColumns: "false",
    selectableRows: "none",
    serverSide: false,
    pagination: true,
    search: false,
    rowsPerPageOptions: [10, 20, 50, 100],
    textLabels: {
      body: {
        noMatch: `${t("common:newCommanTrans:sorrynomatchingrecordsfound")}`,
      },
    },
  };

  return (
    <AppLayout {...props}>
      {apiTimeout === true ? (
        <ApiTimeout {...props} />
      ) : (
        <StyledLayoutGrid
          container={true}
          spacing={0}
          className="carousalWrapper"
        >
          <StyledTimeGridContainer container={true} spacing={0}>
            <StyledButton onClick={handleInquiryyPopup}>
              {t("common:inquiry:Inquiry")}
            </StyledButton>
          </StyledTimeGridContainer>
          <>
            <MUIDataTable
              title={""}
              data={tableData}
              columns={columns}
              options={options}
            />
          </>
          {openToast === true && (
            <Alert showAlertMessage={showAlertMessage} alertType={alertType} />
          )}
          {openReplyPopup === true && (
            <StyledModal open={openReplyPopup} onClose={handleClosePopup}>
              <ReplyPopup
                {...props}
                closeModal={closeModal}
                saveQuery={getAllDataCallBackfun}
                comment={comment}
                userQueryId={userQueryId}
                fromId={useruniqId}
                activeData={tableData}
                commetUpdateIndex={commentIndex}
                handleClosePopup={handleClosePopup}
              />
            </StyledModal>
          )}
          {openInquiryPopup === true && (
            <StyledReplyBox open={openInquiryPopup} onClose={handleClose}>
              <GenInquiry
                saveQuery={() => getAllData()}
                closeModal={handleClose}
                t={t}
              />
            </StyledReplyBox>
          )}

          {showAttachmentDialog && <StyledModal open={showAttachmentDialog} onClose={() => setShowAttachmentDialog(false)}>
              <AttachmentPopup selectedAttachment={selectedAttachment} columnIndexes={columnIndexes} />
            </StyledModal>}
        </StyledLayoutGrid>
      )}
    </AppLayout>
  );
});

export default Inquiry;
