import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { buttonGradient } from '../../../common/materials/LinearGradient';
import IRouterComponentProps from '../../../models/IRouterComponentProps';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducer';
import { isMyBidstabSelected, JwlIsMyBidstabSelected } from '../../../redux/slice/commanPagination/commanPaginationSlice';

export const StyledButton = styled.button`
    color: ${(props) => props.theme.colors.base.grey};
    border: none;
    font-size: 1em;
    padding: 0.6em;
    background:${(props) => props.theme.colors.base.transparent};
    border-bottom: 5px solid ${(props) => props.theme.colors.base.grey};
`;

const StyledBtnContainer = styled(Grid)`
    .MuiGrid-container {
        display:none;
    }
    .MuiAppBar-colorPrimary {
        background: #ffffff;
        color:#000000
    }
    .MuiPaper-elevation4 {
        box-shadow : none
    }
    .MuiTabs-root {
        display:block;
    }
    .MuiTabs-fixed {
        width:auto;
    }
    .MuiTabs-indicator {
        background-color: ${(props) => props.theme.colors.base.gradientDarkBlueColor};   
        height:3px;
    }
`;

const StyledBtnContent = styled(Grid)`
    text-align : center;
`;

interface IProps extends IRouterComponentProps {
  t: any;
  tabClick: (value: string) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

const TenderTabLayout = (props: IProps) => {
  const { t } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState("1");

  const isMyBidstab: any = useSelector((state: RootState) =>
    JwlIsMyBidstabSelected(state)
  );

  useEffect(() => {
    if (isMyBidstab) {
      setValue("2");
      props.tabClick("2");
    } else {
      setValue("1");
      props.tabClick("1");
    }
  }, [isMyBidstab]);
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    props.tabClick(newValue);
  };

  return (
    <StyledBtnContainer container={true} spacing={0}>
      <StyledBtnContent item={true} xs={12}>
        <div className={classes.root}>
          <TabContext value={value}>
            <AppBar position="static">
              <TabList onChange={handleChange} aria-label="simple tabs example">
                <Tab label={t("common:preview:tendor:allProducts")} value="1" />
                <Tab label={t("common:preview:tendor:myBids")} value="2" />
              </TabList>
            </AppBar>
          </TabContext>
        </div>
      </StyledBtnContent>
    </StyledBtnContainer>
  );
};

export default TenderTabLayout;