import React, { ChangeEvent, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import { StyledTextInput, StyledButton, StyledComponent, StyledTitleText } from '../../common/materials/GenericStyleComponent';
import { forgotPasswordUrl } from '../../common/config/app.endpoints';
import AuthService from '../../components/auth/AuthService';
import LoadingSpinner from '../../common/elements/LoadingSpinner';
import { dateBackgroundGradient } from '../../common/materials/LinearGradient';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const faInfoCircleProps = faInfoCircle as IconProp;

const StyledContainerWrapper = styled.div`
    background: ${(props) => props.theme.colors.base.white};
    width: 400px;
    padding: 1em; 
    border-radius: 10px;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
        width: 300px;
    }
    ${props => props.theme.breakpoints.between('sm', 'md')} {
        width: 400px;
    }
    ${props => props.theme.breakpoints.between('md', 'xl')} {
        width: 450px;
    }
    .onClickButton {
        cursor: default;
        background: ${dateBackgroundGradient};
    }
`;

const StyledTitle = styled.h1`
    color: ${(props) => props.theme.colors.base.fieldSetBlueColor};
    text-align: center;
    margin: 1em;
    ${props => props.theme.breakpoints.between('xs', 'sm')} {
      font-size: 1.5em;
    }
`;

const StyledButtonContainer = styled.div`
    text-align: center;
    margin: 20px 0;
 `;

const StyledMessage = styled.div`
    color: ${(props) => props.theme.colors.base.red};
    font-size: 12px;
    margin: 10px 0px;
    margin-left: 1.2em;
`;

const StyledNote = styled(Grid)`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.base.veryLightGrey};
`;

const StyledNoteText = styled.div`
    margin-left: 8px;
    font-size: 12px;
    color: ${(props) => props.theme.colors.base.darkBlue};
`;

const StyledInfoWrapper = styled(Grid)`
    padding: 0.5em 1em;
`;

interface IProps {
    t?: any;
    closeModal: () => void;
}

const ForgotPassword = (props: IProps) => {

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const Auth = new AuthService();
    const { t } = props;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targeName = e.target.name;
        const targeValue = e.target.value;
        if (targeName === 'email') { setEmail(targeValue) }
    }

    const onPressContinue = (e: any) => {
        e.preventDefault();
        setMessage('');
        setIsLoading(true);
        const emailValidation = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}/g).test(email.trim())
        if (emailValidation !== true) {
            setIsLoading(false);
            setMessage(`${t('common:register:errorMessage:onEmail')}`);
        }
        else {
            Auth.getRequestWithoutAuthorization(`${forgotPasswordUrl}?email=${email}`, (status: number, response: any) => {
                if (status === 200) {
                    localStorage.setItem("email", email);
                    resetValue();
                    props.closeModal();
                }
                else if (status > 200 && status < 500) {
                    if (response[0].code === "KB404001") {
                        setIsLoading(false);
                        setMessage(`${t('common:profile:forgotPassword:validation:notRegisteredEmail')}`)
                    }
                }
                else {
                    setIsLoading(false);
                    setMessage(response.data.message)
                }
            })
        }
    }

    const resetValue = () => {
        let email = (document.getElementById('email') as HTMLInputElement);
        if (email) {
            email.value = '';
            setEmail('');
        }
    }

    return (
        <StyledComponent tabIndex={-1}>
            <form onSubmit={onPressContinue} noValidate={true}>
                <StyledContainerWrapper>
                    <Grid item={true} xs={12} md={12}>
                        <StyledTitle className="fontBold"><StyledTitleText>{t('common:profile:forgotPassword:title')}</StyledTitleText></StyledTitle>
                    </Grid>
                    <StyledInfoWrapper>
                        <StyledNote item={true} xs={12}>
                            <FontAwesomeIcon icon={faInfoCircleProps} />
                            <StyledNoteText>
                                {t('common:profile:forgotPassword:note:emailNote')}
                            </StyledNoteText>
                        </StyledNote>
                    </StyledInfoWrapper>
                    <Grid item={true} xs={12}>
                        <StyledMessage>
                            {message}
                        </StyledMessage>
                    </Grid>
                    <StyledTextInput>
                        <TextField
                            fullWidth
                            required
                            autoFocus={true}
                            id="email"
                            label={t('common:profile:forgotPassword:labels:email')}
                            variant="outlined"
                            name="email"
                            onChange={handleChange}
                        />
                    </StyledTextInput>
                    <StyledButtonContainer>
                        {
                            isLoading === false ?
                                <StyledButton onSubmit={onPressContinue}>
                                    {t('common:profile:forgotPassword:button:continue')}
                                </StyledButton> :
                                <StyledButton className='onClickButton'>
                                    {t('common:profile:forgotPassword:button:continue')}
                                </StyledButton>
                        }
                    </StyledButtonContainer>
                </StyledContainerWrapper>
                {isLoading === true &&
                    <div className="loading">
                        <LoadingSpinner />
                    </div>
                }
            </form>
        </StyledComponent>
    )
}

export default ForgotPassword;