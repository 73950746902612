import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../../redux/reducer";
import {
  publicApi,
  postRequestWithoutAuthorization,
} from "../../../services/ApiService";

import {
  getUserDetailPayment,
  processPayment as processPaymentUrl,
} from "../../../common/config/app.endpoints";
import { IErrorPayload } from "../../interfaces/ErrorInterface";
import { User } from "../user/userSliceInterface";

interface IProcessPaymentRequest {
  description: string;
  amount: string;
  currency: string;
  stripeEmail: string;
  stripeToken: string;
  userId: string;
}

interface IGetUserDetailsForPaymentRequest {
  userId: string;
}

const initialState = {
  loading: false,
  error: "",
};

export const getuserDetailsForPayment = createAsyncThunk<
  IGetUserDetailsForPaymentRequest,
  Partial<IGetUserDetailsForPaymentRequest>,
  { rejectValue: any }
>("payment/getUserDetails", async (data, { rejectWithValue }) => {
  try {
    const { userId } = data;
    const url = `${getUserDetailPayment}/${userId}/detail`;
    const response = await publicApi.get(url);
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

export const processPaymentRequest = createAsyncThunk<
  IProcessPaymentRequest,
  Partial<IProcessPaymentRequest>,
  { rejectValue: any }
>("payment/processPayment", async (data, { rejectWithValue }) => {
  try {
    const response = await postRequestWithoutAuthorization(
      processPaymentUrl,
      data
    );
    return response.data;
  } catch (error) {
    const err: any = error;
    if (!err.response) {
      throw err;
    }
    return rejectWithValue(err.response.data);
  }
});

const genericPendingCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = true;
  state.error = "";
};

const genericFullfilledCaseHandler = (state: any, action: PayloadAction) => {
  state.loading = false;
};

const genericRejectedCaseHandler = (
  state: any,
  action: PayloadAction<IErrorPayload>
) => {
  state.error = action.payload || "Error";
  state.loading = false;
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder: any) => {
    // getuserDetailsForPayment
    builder.addCase(
      getuserDetailsForPayment.pending,
      genericPendingCaseHandler
    );
    builder.addCase(
      getuserDetailsForPayment.fulfilled,
      (state: any, action: PayloadAction<User>) => {
        //setUserData(state, action);
        state.loading = false;
      }
    );
    builder.addCase(
      getuserDetailsForPayment.rejected,
      genericRejectedCaseHandler
    );
    // processPaymentRequest
    builder.addCase(processPaymentRequest.pending, genericPendingCaseHandler);
    builder.addCase(
      processPaymentRequest.fulfilled,
      genericFullfilledCaseHandler
    );
    builder.addCase(processPaymentRequest.rejected, genericRejectedCaseHandler);
  },
});

export default paymentSlice.reducer;

// selectors
export const selectPaymentLoading = (state: RootState) => state.payment.loading;
